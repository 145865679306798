import {
  Box, Tooltip,
  Typography
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import React from "react";
import { useHistory } from "react-router";

import styles from "./Header.module.css";
import LOGO from "../../Assets/icons/images/TodacTribe Logo.png";
import { signOut } from "@firebase/auth";


// for logout temporary
import { auth } from "../../firebase";

// redux
import { useDispatch, useSelector } from "react-redux";

// changes

import Dropdown from "./Dropdown";

const Header = () => {

  let history = useHistory();
  let dispatch = useDispatch();
  let { user, venture } = useSelector(state => {

    return { ...state };
  
  });

  const logout = () => {

    signOut(auth);
    dispatch({
      type   : "LOGOUT",
      payload: null
    });
    // dispatch({
    //   type: "FLUSHING_STORAGE",
    //   payload: null,
    // });
    history.push("/login");
  
  };

  return (
    <AppBar id={ styles.navbar } position="static">
      <Toolbar>
        <Box
          target="_blank"
          component="a"
          href={
            window.location.host === "app.todactribe.com"
              ? "https://todactribe.com/"
              : "https://dev.todactribe.com/"
          }
          sx={ { textDecoration: "none" } }
        >
          {/* <p id={styles.H6} component="span" onClick={() => history.push("/")}>
          <p id={styles.H6} component="span">
            TODAC
            <br />
            Tribe.
          </p>*/}
          <img
            src={ LOGO }
            alt="logo"
            style={ { width: "90px", height: "50px" } }
          />
        </Box>
        <div style={ { display: "flex", flexGrow: "1" } }>
          { venture.details &&
            Object.keys(venture.details).length !== 0 &&
            venture.details.name &&
              <Tooltip title="Go to Dashboard">
                <Typography
                  noWrap
                  id={ styles.titlename }
                  variant="h6"
                  component="span"
                  onClick={ () => {

                    history.push("/dashboard");
                  
                  } }
                >
                  { venture.details.name }
                </Typography>
              </Tooltip>
          }
          {/* { !history.location.pathname.includes("conceptvision") &&
            Object.keys(venture.conceptVision).length !== 0 && (
              <>
                <span className={ styles.divider1 } />
                <Tooltip title="View Concept & Vision">
                  <Typography
                    noWrap
                    id={ styles.titlename }
                    variant="h6"
                    component="span"
                    onClick={ () => {
                      history.push({
                        pathname: `/editconceptvisiondetails`,
                        state: { intendedurl: history.location.pathname },
                      });
                    } }
                  >
                    Concept & Vision
                  </Typography>
                </Tooltip>
              </>
            ) } */}
        </div>
        {/* {user.token && (
          <Button variant="contained" id={styles.upgradebtn}>
            Upgrade
          </Button>
        )} */}
        {/* <TextField
          sx={{
            marginTop: "5px",
            marginRight: "20px",
            "& .MuiOutlinedInput-root": { borderRadius: "10px" },
          }}
          id={styles.outlinedbasic}
          placeholder="Search"
          variant="outlined"
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />*/}
        { user.token && !history.location.pathname.includes("registerdetails") &&
          <Tooltip title={ "Logout" }>
            {/* <IconButton
              onClick={() => {
                logout();
              }}
            >
              <AccountCircleIcon fontSize="large" />
            </IconButton> */}
            <Dropdown logout={ logout } />
          </Tooltip>
        }
      </Toolbar>
    </AppBar>
  );

};

export default Header;
