/* eslint-disable react/jsx-key */
/* eslint-disable camelcase */
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box, CircularProgress, Grid, Tooltip
} from "@mui/material";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BackNextButton from "../../../../components/BackNextButton";
import IndividualSectionHeaders from "../../../../components/IndividualSectionHeaders";
import ModuleSections from "../../../../components/ModuleSections";
import Datas from "../../../../Data/Finance.json";
import { createOrupdateFinancialBalanceSheet, createOrupdateFinancialCashFlow, getFinancials } from "../../../../services/financials";
import FinancialsNavBar from "../FinancialsNavBar";
import styles from "./Insights.module.css";
import {
  createFinancialsComment,
  deleteFinancialsComment,
  updateFinancialsComment
} from "../../../../services/comment";

import CustomerBaseGrowth from "./ChartComponents/PlOutlookCharts/CustomerBaseGrowth";
import GrossAndOperationMargin from "./ChartComponents/PlOutlookCharts/GrossAndOperationMargin";
import MarginProgressionByYear from "./ChartComponents/PlOutlookCharts/MarginProgressionByYear";
import OperationCostBreakDown from "./ChartComponents/PlOutlookCharts/OperationCostBreakDown";
import OverHeadProgression from "./ChartComponents/PlOutlookCharts/OverHeadProgression";
import RevenueProgression from "./ChartComponents/PlOutlookCharts/RevenueProgression";
import RevenueProgressionByYear from "./ChartComponents/PlOutlookCharts/RevenueProgressionByYear";

import Config from "../../../../config/Config";
import BalSheetBreakDownByYear from "./ChartComponents/BalanceSheetCharts/BalSheetBreakDownByYear";
import CashFlowBreakDownByYear from "./ChartComponents/CashFlowCharts/CashFlowBreakDownByYear";
import TotalCashFlowByYear from "./ChartComponents/CashFlowCharts/TotalCashFlowByYear";
import BurnRateVsFundBal from "./ChartComponents/FrOutlookCharts/BurnRateVsFundBal";
import RunWayVsFundBal from "./ChartComponents/FrOutlookCharts/RunWayVsFundBal";
import _ from "lodash";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

const InsightsDashboard = () => {

  const [ loading, setLoading ] = useState(false);
  // const [autosaving, setAutosaving] = useState(false);
  const [ dialogbox, setDialog ] = useState(false);
  const [ collapseComment, setCollapseComment ] = useState(false);
  // const [errorDialog, setErrorDialog] = useState(false);
  // const [isExpand, setIsExpand] = useState(false);
  const [ financials, setFinancials ] = useState({
    plOutlooks          : [],
    frOutlooks          : [],
    cashFlow            : "",
    balanceSheet        : "",
    currentFundingSource: [],
    fundRaising         : {
      totalFundingNeed : 0,
      fundingAllocation: ""
    },
    totalCurrentFunding: 0,
    plOutlooksCurrency : "",
    frOutlooksCurrency : "",
    currency           : ""
  });
  const [ yearList, setYearList ] = useState([]);
  const [ plOutTableData, setPlOutTableData ] = useState([]);
  const [ frOutlookTableData, setFrOutlookTableData ] = useState([]);
  const [ balanceSheetTable, setBalanceSheetTable ] = useState([]);
  const [ cashflowTable, setCashflowTable ] = useState([]);
  const [ comments, setComments ] = useState([]);
  // const [isedit, setIsedit] = useState(true);
  // const [isDisable, setIsDisable] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [ selectedindex, setSelectedindex ] = useState(-1);
  const history = useHistory();
  const [ currency, setcurrency ] = useState();
  const { user, venture } = useSelector(state => ({ ...state }));
  const [ currentSlide, setCurrentSlide ] = useState(0);
  // const [showDots, setShowDots] = useState(false);

  // useEffect(() => {
  //   const isMobile = Config.mobileViewCheck.test(
  //     navigator.userAgent || navigator.vendor || window.opera
  //   );
  //   setShowDots(isMobile);
  // }, []);

  const chartTitle = Config?.chartTitleList;
  const chartMoreDetails = Config?.chartMoreDetails;

  const collapse_comment_function = () => {

    if (collapseComment) {

      setCollapseComment(false);
    
    } else {

      setCollapseComment(true);
    
    }
  
  };

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  const slides = [
    <div className="tribe-inside-chart-div-con">
      <div className="tribe-financial-insight-dashboard-charts-sub-con" >
        <div className="tribe-financial-insight-dashboard-charts-div">
          <RevenueProgression
            table={plOutTableData}
            currency={currency}
            year={yearList}
            chartTitle={chartTitle[0]}
            moreDetails={chartMoreDetails.plOutLook}
          />
        </div >
        <div className="tribe-financial-insight-dashboard-charts-div">
          <GrossAndOperationMargin
            table={plOutTableData}
            currency={currency}
            year={yearList}
            chartTitle={chartTitle[1]}
            moreDetails={chartMoreDetails.plOutLook}
          />
        </div>
      </div>
    </div>,
    <div className="tribe-inside-chart-div-con">
      <div className="tribe-financial-insight-dashboard-charts-sub-con" >
        <div className="tribe-financial-insight-dashboard-charts-div">
          <CustomerBaseGrowth
            table={plOutTableData}
            currency={currency}
            year={yearList}
            chartTitle={chartTitle[2]}
            moreDetails={chartMoreDetails.plOutLook}
          />
        </div>
        <div className="tribe-financial-insight-dashboard-charts-div">
          <OverHeadProgression
            table={plOutTableData}
            currency={currency}
            year={yearList}
            chartTitle={chartTitle[4]}
            moreDetails={chartMoreDetails.plOutLook}
          />
        </div>
      </div>
    </div>,
    <div className="tribe-inside-chart-div-con">
      <div className="tribe-financial-insight-dashboard-charts-sub-con" >
        <div className="tribe-financial-insight-dashboard-charts-full-div">
          <OperationCostBreakDown
            table={plOutTableData}
            currency={currency}
            year={yearList}
            chartTitle={chartTitle[3]}
            moreDetails={chartMoreDetails.plOutLook}
          />
        </div>
      </div>
    </div>,
    <div className="tribe-inside-chart-div-con">
      <div className="tribe-financial-insight-dashboard-charts-sub-con" >

        <div className="tribe-financial-insight-dashboard-charts-div">
          <RevenueProgressionByYear
            table={plOutTableData}
            currency={currency}
            year={yearList}
            chartTitle={chartTitle[5]}
            moreDetails={chartMoreDetails.plOutLook}
          />
        </div>
        <div className="tribe-financial-insight-dashboard-charts-div">
          <MarginProgressionByYear
            table={plOutTableData}
            currency={currency}
            year={yearList}
            chartTitle={chartTitle[6]}
            moreDetails={chartMoreDetails.plOutLook}
          />
        </div>
      </div>
    </div>,
    <div className="tribe-inside-chart-div-con">
      <div className="tribe-financial-insight-dashboard-charts-sub-con" >
        <div className="tribe-financial-insight-dashboard-charts-full-div">
          <BurnRateVsFundBal
            table={frOutlookTableData}
            currency={currency}
            year={yearList}
            chartTitle={chartTitle[7]}
            moreDetails={chartMoreDetails.frOutLook}
          />
        </div>
      </div>
    </div>,
    <div className="tribe-inside-chart-div-con">
      <div className="tribe-financial-insight-dashboard-charts-sub-con" >
        <div className="tribe-financial-insight-dashboard-charts-full-div">
          <RunWayVsFundBal
            table={frOutlookTableData}
            currency={currency}
            year={yearList}
            chartTitle={chartTitle[8]}
            moreDetails={chartMoreDetails.frOutLook}
          />
        </div>
      </div>
    </div>,
    <div className="tribe-inside-chart-div-con">
      <div className="tribe-financial-insight-dashboard-charts-sub-con" >
        <div className="tribe-financial-insight-dashboard-charts-div">
          <TotalCashFlowByYear
            table={cashflowTable}
            currency={currency}
            year={yearList}
            chartTitle={chartTitle[9]}
            moreDetails={chartMoreDetails.cashFlow}
          />

        </div >
        <div className="tribe-financial-insight-dashboard-charts-div">
          <CashFlowBreakDownByYear
            table={cashflowTable}
            currency={currency}
            year={yearList}
            chartTitle={chartTitle[10]}
            moreDetails={chartMoreDetails.cashFlow}
          />
        </div>
      </div>
    </div>,
    <div className="tribe-inside-chart-div-con">
      <div className="tribe-financial-insight-dashboard-charts-sub-con" >
        <div className="tribe-financial-insight-dashboard-charts-full-div">
          <BalSheetBreakDownByYear
            table={balanceSheetTable}
            currency={currency}
            year={yearList}
            chartTitle={chartTitle[11]}
            moreDetails={chartMoreDetails.balanceSheet}
          />
        </div>
      </div>
    </div>
  ];

  useEffect(() => {

    fetchFinancialDataTable();
  
  }, []);

  const fetchFinancialDataTable = async () => {

    setLoading(true);

    getFinancials(venture.financials, venture._id).then(response => {

      const financeData = response?.data?.finance;

      if (financeData) {

        setFinancials(financeData);
        setcurrency(financeData?.currency);
        setComments(financeData?.comments?.insightsDashboard);

        const yearArray = financeData?.financialYear;

        setYearList(yearArray);
        const balanceSheetIdFromDoc = financeData?.balanceSheet;
        const cashFlowIdFromDoc = financeData?.cashFlow;

        const plOutTableData = financeData.plOutlooks;

        setPlOutTableData(plOutTableData || []);

        const frOutLookTableData = financeData?.frOutlooks;

        setFrOutlookTableData(frOutLookTableData || []);

        if (yearArray && yearArray.length) {

          createOrupdateFinancialCashFlow(venture.financials, venture._id, cashFlowIdFromDoc, yearArray).then(res => {

            if (res) {

              const tableData = res?.data?.table;

              setCashflowTable(tableData || []);

            } else {

              setCashflowTable([]);

            }

          }).catch(error => { console.error(error); });

          setLoading(true);
          createOrupdateFinancialBalanceSheet(venture.financials, venture._id, balanceSheetIdFromDoc, yearArray).then(res => {

            if (res) {

              const tableData = res?.data?.table;

              setBalanceSheetTable(tableData || []);
              setLoading(false);

            } else {

              setLoading(false);
              setBalanceSheetTable([]);

            }

          }).catch(error => { console.error(error); setLoading(false); });

        }

        setLoading(false);

      }

    }).catch(error => {

      setFinancials({
        plOutlooks          : [],
        frOutlooks          : [],
        cashFlow            : "",
        balanceSheet        : "",
        currentFundingSource: [],
        fundRaising         : {
          totalFundingNeed : 0,
          fundingAllocation: ""
        },
        totalCurrentFunding: 0,
        plOutlooksCurrency : "",
        frOutlooksCurrency : "",
        currency           : ""
      });

      setLoading(false);
      console.error(error);
    
    });

  };

  const handleWheel = e => {

    if (e.deltaY > 0) {

      setCurrentSlide(prevSlide => prevSlide === 7 ? 7 : (prevSlide + 1) % slides.length);
    
    } else if (e.deltaY < 0) {

      setCurrentSlide(prevSlide => (prevSlide === 0 ? 0 : prevSlide - 1 + slides.length) % slides.length);
    
    }

  };

  const debouncedHandleWheel = useCallback(
    _.debounce(handleWheel, 150),
    []
  );

  useEffect(() => {

    window.addEventListener("wheel", debouncedHandleWheel);

  }, []);

  const handleScroll = () => {

    return () => {

      window.removeEventListener("wheel", debouncedHandleWheel);
    
    };

  };

  const Dots = ({ itemsCount, activeIndex, onDotClick }) => {

    const dots = [];

    for (let i = 0; i < itemsCount; i++) {

      dots.push(
        <span
          key={i}
          className={`fin-dot ${i === activeIndex ? "fin-active" : ""}`}
          onClick={() => onDotClick(i)}
        ></span>
      );
    
    }
    
    return <div className="dots">{dots}</div>;
  
  };

  const handleDotClick = index => {

    setCurrentSlide(index);
  
  };

  const handlePrevClick = () => {

    setCurrentSlide(currentSlide > 0 ? currentSlide - 1 : 0);
  
  };

  const handleNextClick = () => {

    setCurrentSlide(currentSlide < 7 ? currentSlide + 1 : 7);
  
  };


  // To get the updated usestate value to save in useEffect cleanup
  const financialsRef = useRef(financials);

  financialsRef.current = financials;

  const addComment = async (commentText, replyTo) => {

    try {

      const response = await createFinancialsComment(
        commentText,
        user._id,
        "insightsDashboard",
        "",
        venture.financials,
        replyTo,
        window.location.href,
        "Finance > Insights Dashboard"
      );

      setComments(response?.data?.comments?.insightsDashboard);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateComment = async (commentid, commentText) => {

    try {

      const response = await updateFinancialsComment(
        commentText,
        user._id,
        commentid,
        venture._id,
        window.location.href,
        "Finance > Insights Dashboard"
      );

      setComments(response?.data?.comments?.insightsDashboard);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const deleteComment = async commentid => {

    try {

      const response = await deleteFinancialsComment(
        "insightsDashboard",
        "",
        venture.financials,
        commentid,
        user._id,
        window.location.href,
        "Finance > Insights Dashboard"
      );

      setComments(response?.data?.comments?.insightsDashboard);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  return (
    <ModuleSections
      Collapsecomment={collapseComment}
      comments={comments}
      addComment={addComment}
      deleteComment={deleteComment}
      updateComment={updateComment}
    >
      <div className="box-display-style-for-next-and-back-button-with-justify">

        <div className="box-indiv-header-con" style={{ height: "14%", background: "white" }}>
          {loading ?
            <></>
            :
            <>
              <FinancialsNavBar />
              <IndividualSectionHeaders
                mainheading={"Finance"}
                subheading={"Insights Dashboard"}
                headingcontent={
                  "View your company's overall financial insights here"
                }
                isGtm={false}
                Dialogopen={Dialogopen}
                Dialogclose={Dialogclose}
                dialogbox={dialogbox}
                collapse_comment_function={collapse_comment_function}
                content={Datas.insightsDashboard}
                notGuide={true}
              />
            </>
          }
        </div>
        <Grid
          item
          id={styles.maingrid}
          xs={12}
          sx={{
            height        : "64%",
            width         : "100%",
            padding       : "0px 60px 0px",
            display       : "flex",
            justifyContent: "center",
            flexDirection : "column"
          }}
          onClick={() => {

            setSelectedindex(-1);
          
          }}>
          {loading ?
            <Grid
              container
              justifyContent="center"
              height="100%"
              alignItems="center">
              <Grid item>
                <CircularProgress size="60px" thickness={3} />
              </Grid>
            </Grid>
            :
            <>
              <Box
                sx={{
                  display       : "flex",
                  justifyContent: "space-between",
                  alignItems    : "center"
                }}>
                <div
                  className="tribe-financial-insight-dashboard-charts-con"
                  onScroll={handleScroll}
                >
                  <div className="tribe-vertical-carousel">
                    <div style={{ transform: `translateY(-${currentSlide * 55}vh)`, transition: "transform 0.5s" }}>
                      {slides.concat(slides)}
                    </div>
                  </div>
                </div>
              </Box>
              {<div className="tribe-slide-vertical-car-nav-con">
                <Tooltip title="Previous">
                  <NavigateBeforeIcon className="tribe-slide-vertical-car-nav-btn " onClick={handlePrevClick}>
                    &lt;
                  </NavigateBeforeIcon>
                </Tooltip>
                <Dots itemsCount={slides.length} activeIndex={currentSlide} onDotClick={handleDotClick} />
                <Tooltip title="Next">
                  <NavigateNextIcon className="tribe-slide-vertical-car-nav-btn" onClick={handleNextClick}>
                    &gt;
                  </NavigateNextIcon>
                </Tooltip>
              </div>
              }
            </>
          }
        </Grid>
        {/* <div style={{ width: "100%" }}> */}
        <div className="ttribe-back-next-button-con">
          {loading ?
            <></>
            :
            <BackNextButton
              restrictedBackClick={() => {

                history.push("/balancesheet");
              
              }}
              backClick={() => {

                history.push("/balancesheet");
              
              }}
              restrictedNextClick={() => {

                history.push("/financialssummary");
              
              }}
              nextClick={() => {

                // handleSubmit();
                history.push("/financialssummary");
              
              }
              }
            />
          }
        </div>
      </div>
    </ModuleSections>
  );

};

export default InsightsDashboard;
