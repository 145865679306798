/* eslint-disable camelcase */
import {
  Box, Grid, Typography
} from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import IndividualSectionHeaders from "../../../components/IndividualSectionHeaders";
import ModuleSections from "../../../components/ModuleSections";
import NextButton from "../../../components/NextButton";
import Data from "../../../Data/Gtm.json";

import ArrowRightSharpIcon from "@mui/icons-material/ArrowRightSharp";

import { makeStyles } from "@mui/styles";
import styles from "./GTM.module.css";

const useStyles = makeStyles(theme => ({
  boxgrid: {
    height      : "60%",
    alignContent: "center",
    marginBottom: theme.spacing(2),
    marginTop   : theme.spacing(2)
  },
  box1: {
    "&::after": {
      content  : "\"\"",
      borderTop: "dashed 2px grey",
      position : "absolute",
      height   : "0px",
      width    : "95%",
      top      : "45%"
    },
    position: "relative"
  },
  individualheading: {
    fontSize  : "16px",
    color     : "#393939",
    fontWeight: "bold",
    height    : "50px"
  },
  individualdescription: {
    fontSize: "14px",
    color   : "#393939"
  },
  boximg: {
    width: "90px"
  },
  arrowicon: {
    position: "absolute",
    top     : "20%",
    left    : "90%",
    color   : "#071AFF",
    fontSize: "50px",
    zIndex  : 100
  }
}));

const GTMMultiplemodules = () => {

  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const [ dialogbox, setDialog ] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  const pathname = history.location.pathname.substring(1);
  const content = Data[pathname];

  // let { venture } = useSelector((state) => {
  //   return { ...state };
  // });

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);
    
    } else {

      setCollapsecomment(true);
    
    }
  
  };

  
  return (
    <ModuleSections Collapsecomment={ Collapsecomment }>
      <div className="box-display-style-for-next-and-back-button-with-justify">
        <div className="box-indiv-header-con">
          <IndividualSectionHeaders
            mainheading={ content.title }
            subheading={ content.subtitle }
            headingcontent={ content.description }
            isGtm={ true }
            Dialogopen={ Dialogopen }
            Dialogclose={ Dialogclose }
            dialogbox={ dialogbox }
            collapse_comment_function={ collapse_comment_function }
            content={ content }
            // ---------------
            // isGtmChevron={pathname.includes("gtm") ? true : false}
            isGtmChevron={ "none" }
            isexpand={ true }
          />
        </div>
        <Grid item xs={ 12 } id={ styles.maingrid }>
          <Grid container className={ classes.boxgrid }>
            { content.sections.map((section, index) => {

              return index === 2 ?
                <Grid item md={ 4 } sx={ { textAlign: "center" } }>
                  <Typography className={ classes.individualheading }>
                    { section.title }
                  </Typography>
                  { pathname.match("gtmpositioning") ?
                    <Box
                      onClick={ () => history.push("/gtmpositioningmessaging") }
                      sx={ { position: "relative", cursor: "pointer" } }>
                      <img
                        src={ `${process.env.REACT_APP_AWS_ASSETS_URL}${section.imagesrc}` }
                        className={ classes.boximg }
                      />
                    </Box>
                    :
                    <Box sx={ { position: "relative" } }>
                      <img
                        src={ `${process.env.REACT_APP_AWS_ASSETS_URL}${section.imagesrc}` }
                        className={ classes.boximg }
                      />
                    </Box>
                  }

                  {/* <Box sx={{ position: "relative" }}>
                  <img
                    src={`${process.env.REACT_APP_AWS_ASSETS_URL}${section.imagesrc}`}
                    className={classes.boximg}
                  />
                </Box> */}

                  <Box sx={ { width: "80%", marginLeft: "10%" } }>
                    <Typography
                      gutterBottom
                      id={ styles.multipleModuleIndividualDescription }
                      className={ classes.individualdescription }>
                      { section.description }
                    </Typography>
                  </Box>
                </Grid>
                :
                <Grid item md={ 4 } sx={ { textAlign: "center" } }>
                  <Typography className={ classes.individualheading }>
                    { section.title }
                  </Typography>
                  { section.title === "Define Positioning Statements" ?
                    <Box
                      onClick={ () =>
                        history.push("/gtmpositioninglongerdescription")
                      }
                      sx={ { cursor: "pointer" } }
                      className={ classes.box1 }>
                      <img
                        src={ `${process.env.REACT_APP_AWS_ASSETS_URL}${section.imagesrc}` }
                        className={ classes.boximg }
                      />
                      <ArrowRightSharpIcon className={ classes.arrowicon } />
                    </Box>
                    : section.title ===
                    "Craft Your ‘Hero Transformation’ Story" ?
                      <Box
                        onClick={ () => history.push("/gtmpositioningstory") }
                        sx={ { cursor: "pointer" } }
                        className={ classes.box1 }>
                        <img
                          src={ `${process.env.REACT_APP_AWS_ASSETS_URL}${section.imagesrc}` }
                          className={ classes.boximg }
                        />
                        <ArrowRightSharpIcon className={ classes.arrowicon } />
                      </Box>
                      :
                      <Box className={ classes.box1 }>
                        <img
                          src={ `${process.env.REACT_APP_AWS_ASSETS_URL}${section.imagesrc}` }
                          className={ classes.boximg }
                        />
                        <ArrowRightSharpIcon className={ classes.arrowicon } />
                      </Box>
                  }

                  {/* <Box className={classes.box1}>
                  <img
                    src={`${process.env.REACT_APP_AWS_ASSETS_URL}${section.imagesrc}`}
                    className={classes.boximg}
                  />
                  <ArrowRightSharpIcon className={classes.arrowicon} />
                </Box> */}
                  <Box sx={ { width: "80%", marginLeft: "10%" } }>
                    <Typography
                      gutterBottom
                      id={ styles.multipleModuleIndividualDescription }
                      className={ classes.individualdescription }>
                      { section.description }
                    </Typography>
                  </Box>
                </Grid>
              ;
            
            }) }
          </Grid>
        </Grid>
        <div className="ttribe-back-next-button-con">
          <NextButton
            restrictedNextClick={ () => history.push(`/${content.nextURL}`) }
            nextClick={ () => history.push(`/${content.nextURL}`) }
          />
        </div>
      </div>
    </ModuleSections>
  );

};

export default GTMMultiplemodules;
