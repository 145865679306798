import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Typography, Box, LinearProgress } from "@mui/material";
import axiosService from "../../../../services/axiosService";
import { useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import ErrorMessage from "../../../../components/ErrorMessage";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import styles from "./MarketSize.module.css";

const UploadFile = props => {

  const [ loading, setLoading ] = useState(false);
  const [ isFileTooLarge, setIsFileTooLarge ] = useState(false);
  const [ progress, setProgress ] = useState(0);
  const [ successmsg, setSuccessmsg ] = useState("");
  const [ errormsg, setErrormsg ] = useState("");

  const { venture } = useSelector(state => ({ ...state }));
  // console.log(user);

  let history = useHistory();

  const onDrop = async (acceptedFiles, rejectionFiles) => {

    setLoading(true);
    try {

      // console.log(acceptedFiles, rejectionFiles);
      if (rejectionFiles.length > 0 && rejectionFiles[0].file.size > 2097152) {

        setIsFileTooLarge(true);
        setLoading(false);
        setTimeout(() => {

          setIsFileTooLarge(false);
        
        }, 5000);
        
        return;
      
      }
      const formdata = new FormData();

      formdata.append("excel", acceptedFiles[0]);
      formdata.append("marketId", venture.market);
      const response = await axiosService.post(
        `${process.env.REACT_APP_ADDRESS}/upload_marketexcel`,
        formdata,
        {
          onUploadProgress: ProgressEvent => {

            const { loaded, total } = ProgressEvent;
            const percentage = Math.floor(
              ((loaded / 1000) * 100) / (total / 1000)
            );

            setProgress(percentage);
          
          }
        }
      );

      setSuccessmsg(response.data);
      props.fetchMarketdetails();
      setTimeout(() => {

        history.push("/marketsize");
      
      }, 2000);
    
    } catch (error) {

      setErrormsg("File type not accepted");
      setLoading(false);
      setTimeout(() => {

        setErrormsg("");
      
      }, 5000);
    
    }
  
  };

  return (
    <Dropzone
      onDrop={onDrop}
      accept=" application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      minSize={0}
      maxSize={2097152}
    >
      {({
        getRootProps,
        getInputProps,
        isDragActive,
        isDragReject
        // fileRejections
      }) => {

        return (
          <Grid
            container
            id={styles.uploadgrid}
            sx={{ marginTop: "30px" }}
            {...getRootProps()}
          >
            <Box id={styles.uploadbox} component="span">
              {!isDragActive &&
                <Typography sx={{ textAlign: "center", fontSize: "12px" }}>
                  Upload your files.
                </Typography>
              }
              {isDragActive && !isDragReject &&
                <Typography>Drop Here</Typography>
              }
              {isDragReject &&
                <ErrorMessage errormsg={"File type not accepted"} />
              }
              {loading && !isFileTooLarge && !errormsg ?
                <LinearProgress variant="determinate" value={progress} />
                : errormsg ?
                  <ErrorMessage errormsg={"File type not accepted"} />
                  : isFileTooLarge ?
                    <ErrorMessage
                      errormsg={"File is too large. Upload a file under 2mb size "}
                    />
                    :
                    <img
                      src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/import.png`}
                      id={styles.imgicon}
                    />
              }

              {successmsg ?
                <>
                  <CheckCircleOutlineIcon
                    sx={{ color: "#108E00", marginLeft: "40%" }}
                    fontSize="large"
                  />
                  <Typography sx={{ fontSize: "12px" }}>
                    {successmsg}
                  </Typography>
                </>
                :
                <Typography
                  sx={{
                    fontSize     : "12px",
                    color        : "#071AFF",
                    display      : "flex",
                    flexDirection: "column"
                  }}
                >
                  <span>Click to Upload file</span>
                  Limit 1 file. File must be .xlsx format & under 2MB.
                </Typography>
              }
            </Box>
            <input {...getInputProps()} />
          </Grid>
        );
      
      }}
    </Dropzone>
  );

};

export default UploadFile;
