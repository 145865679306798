import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box, Button, Grid, IconButton, InputAdornment, LinearProgress, OutlinedInput,
  Tooltip, Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, updatePassword } from "firebase/auth";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import ErrorMessage from "../../../components/ErrorMessage";
import {
  strengthColor,
  strengthIndicator,
  strengthText
} from "../../../components/PasswordStrength";
import SuccessMessage from "../../../components/SuccessMessage";
import MyAccount from "./MyAccount";
import styles from "./MyAccount.module.css";

const useStyles = makeStyles(() => ({
  customInput: {
    "& .MuiInputBase-input": {
      borderRadius: 4,
      fontSize    : 14,
      width       : "15vw",
      padding     : "6px 12px"
    }
  },
  customWidth: {
    maxWidth       : 200,
    fontSize       : 10,
    backgroundColor: "white",
    color          : "#707070",
    boxShadow      : "0px 3px 6px #00000029",
    border         : "1px solid #707070",
    borderRadius   : 10
  }
}));

export default function LoginSecurity () {

  const [ currentPassword, setCurrentPassword ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ confirmpassword, setConfirmpassword ] = useState("");
  const [ showpassword, setShowPassword ] = useState({
    currentPassword: false,
    password       : false,
    confirmPassword: false
  });
  const [ loginDetails, setLoginDetails ] = useState("");
  const [ errormsg, setErrormsg ] = useState("");
  const [ successmsg, setSuccessmsg ] = useState("");
  const history = useHistory();
  const { user } = useSelector(state => ({ ...state }));
  const classes = useStyles();

  const strength = strengthIndicator(password);
  const strengthcolor = strengthColor(strength);
  const strengthtext = strengthText(strength);
  const normalise = strength => ((strength - 1) * 100) / (5 - 1);

  const auth = getAuth();
  const userDetails = auth.currentUser;

  auth.onAuthStateChanged(async user => {

    if (user) {

      // console.log("user", user.providerData[0].providerId);
      setLoginDetails(user.providerData[0].providerId);
    
    }
  
  });

  const reauthenticate = currentPassword => {

    const user = auth.currentUser;
    const cred = EmailAuthProvider.credential(
      user.email,
      currentPassword
    );

    
    return reauthenticateWithCredential(userDetails, cred);
  
  };

  const changePassword = async () => {

    if (
      password !== "" &&
      confirmpassword !== "" &&
      password === confirmpassword &&
      currentPassword !== password &&
      password.match(
        "(?!^[0-9]*$)(?!^[a-zA-Z]*$)^([a-zA-Z0-9!#@$%^&*)(+=._-]{6,15})$"
      )
    ) {

      // const user = auth.currentUser;

      try {

        // reauthenticating
        await reauthenticate(currentPassword);
        // updating password
        await updatePassword(userDetails, password);
        setSuccessmsg("Your password has been changed successfully!");
        setConfirmpassword("");
        setPassword("");
        setCurrentPassword("");
        setTimeout(() => {

          setSuccessmsg("");
        
        }, 5000);
      
      } catch (err) {

        console.error(err);
        
        setErrormsg("Invalid current password");
        setTimeout(() => {

          setErrormsg("");
        
        }, 5000);
      
      }
    
    } else {

      if (password !== confirmpassword || password === "") {

        setErrormsg("The two passwords don't match. Please re-enter them");
      
      } else if (
        !password.match(
          "(?!^[0-9]*$)(?!^[a-zA-Z]*$)^([a-zA-Z0-9!#@$%^&*)(+=._-]{6,15})$"
        )
      ) {

        setErrormsg("Password does not meet requirements");
      
      } else if (password === currentPassword) {

        setErrormsg(
          "Your new password cannot be the same as your current password"
        );
      
      }
      setTimeout(() => {

        setErrormsg("");
      
      }, 5000);
    
    }
  
  };

  return (
    <MyAccount securityColor={ true }>
      <Box id={ styles.loginSecurityBox }>
        {/* google.com */ }
        { loginDetails === "google.com" ?
          <Box sx={ { marginTop: "15px" } }>
            <Typography id={ styles.headTypoG }>Login & Security</Typography>

            <Box id={ styles.currentEmailBoxG }>
              <Typography id={ styles.typoHead }>Current Email</Typography>
              <Typography id={ styles.typoTitle }>{ user.email }</Typography>
            </Box>

            <Box sx={ { marginTop: "20px" } }>
              <Typography id={ styles.typoHead }>Password</Typography>
              <Box id={ styles.TitleGTypo }>
                <Box sx={ { marginBottom: "1px" } }>
                  <Typography id={ styles.typoTitle }>
                    You're logged in with Google
                  </Typography>
                </Box>

                <Box sx={ { marginLeft: "10px" } }>
                  <img
                    src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/google_icon.png` }
                    style={ { width: "15px", height: "15px" } }
                    alt=""
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          :
          <Box sx={ { marginTop: "15px" } }>
            <Box>
              <Typography id={ styles.securityHeadTypo }>
                Login & Security
              </Typography>
            </Box>

            <Box id={ styles.currentEmailBox }>
              <Typography id={ styles.securityFieldHeadTypo }>
                Current Email
              </Typography>
              <Typography id={ styles.emailTypo }>{ user.email }</Typography>
            </Box>
            <Box id={ styles.changePassBox }>
              <Typography id={ styles.securityFieldHeadTypo }>
                Change Password
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                id={ styles.currentPassTypo }
              >
                Current password
              </Typography>
              <OutlinedInput
                name="currentpassword"
                type={ showpassword.currentPassword ? "text" : "password" }
                value={ currentPassword }
                onChange={ e => {

                  setCurrentPassword(e.target.value);
                
                } }
                className={ classes.customInput }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={ () => {

                        setShowPassword({
                          ...showpassword,
                          currentPassword: !showpassword.currentPassword
                        });
                      
                      } }
                      edge="end"
                    >
                      { showpassword.currentPassword ?
                        <Visibility />
                        :
                        <VisibilityOff />
                      }
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Box
                id={ styles.forgotPassBox }
                onClick={ () => {

                  history.push("/forgotpasswordprofile");
                
                } }
              >
                <Typography id={ styles.forgotPassTypo }>
                  Forgot password?
                </Typography>
              </Box>
            </Box>

            <Box id={ styles.newPassBox }>
              <Typography id={ styles.currentPassTypo }>New password</Typography>
              <Tooltip
                disableFocusListener
                title="Password must have at least 6 characters. For a strong password, use upper & lower case letters, numbers, and symbols like !@#$%&*"
                placement="bottom"
                classes={ { tooltip: classes.customWidth } }
              >
                <OutlinedInput
                  name="password"
                  type={ showpassword.password ? "text" : "password" }
                  value={ password }
                  onChange={ e => {

                    setPassword(e.target.value);
                  
                  } }
                  className={ classes.customInput }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={ () => {

                          setShowPassword({
                            ...showpassword,
                            password: !showpassword.password
                          });
                        
                        } }
                        edge="end"
                      >
                        { showpassword.password ?
                          <Visibility />
                          :
                          <VisibilityOff />
                        }
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Tooltip>
              { password &&
                <Grid
                  sx={ {
                    position    : "absolute",
                    left        : "280px",
                    top         : "-20px",
                    margin      : "10px",
                    padding     : "20px 10px",
                    width       : "200px",
                    border      : "solid 1px #cccccc",
                    borderRadius: "10px"
                  } }
                >
                  <Typography sx={ { fontSize: "13px", marginBottom: "10px" } }>
                    Password strength : { strengthtext }
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={ normalise(strength) }
                    sx={ {
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: strengthcolor
                      },
                      borderRadius: "10px"
                    } }
                  />
                </Grid>
              }
            </Box>

            <Box id={ styles.ConfirmNewPassBox }>
              <Typography id={ styles.currentPassTypo }>
                Confirm new password
              </Typography>
              <Tooltip
                disableFocusListener
                title="Password must have at least 6 characters. For a strong password, use upper & lower case letters, numbers, and symbols like !@#$%&*"
                placement="bottom"
                classes={ { tooltip: classes.customWidth } }
              >
                <OutlinedInput
                  name="confirmPassword"
                  type={ showpassword.confirmPassword ? "text" : "password" }
                  value={ confirmpassword }
                  onChange={ e => {

                    setConfirmpassword(e.target.value);
                  
                  } }
                  className={ classes.customInput }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={ () => {

                          setShowPassword({
                            ...showpassword,
                            confirmPassword: !showpassword.confirmPassword
                          });
                        
                        } }
                        edge="end"
                      >
                        { showpassword.confirmPassword ?
                          <Visibility />
                          :
                          <VisibilityOff />
                        }
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Tooltip>
            </Box>
            <Box id={ styles.btnBox }>
              <Button
                style={ { textTransform: "none" } }
                variant="contained"
                onClick={ () => {

                  changePassword(currentPassword);
                
                } }
              >
                Change password
              </Button>
            </Box>
            { errormsg && <ErrorMessage errormsg={ errormsg } /> }
            { successmsg && <SuccessMessage successmsg={ successmsg } /> }
          </Box>
        }

        {/* --------------------- */ }

        <Box>
          <Box id={ styles.footerTextContainer }>
            <Typography sx={ { fontSize: "14px", color: "#707070" } }>
              For account issues, please
            </Typography>
            <Typography
              id={ styles.contactTypo }
              component="a"
              target="_blank"
              href="/support"
            >
              Contact Support
            </Typography>
          </Box>
        </Box>
      </Box>
    </MyAccount>
  );

}
