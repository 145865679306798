import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  Dialog, DialogContent, IconButton, Typography
} from "@mui/material";
import React, { useState } from "react";
import DeleteCancel from "../../../components/DeleteCancel";


const GridTitleColumn = props => {

  const [ deletedialogbox, setDeleteDialog ] = useState(false);

  const deleteDialogopen = () => {

    setDeleteDialog(true);
  
  };
  const deleteDialogclose = () => {

    setDeleteDialog(false);
  
  };

  const other = props.other;
  const index = props.index;
  const isdelete = props.isdelete;

  return (
    <>
      <Typography
        sx={ {
          color    : "#393939",
          flex     : "0 0 250px",
          textAlign: "center"
        } }
      >
        { isdelete &&
          <IconButton
            sx={ { padding: "0px" } }
            onClick={ e => {

              e.stopPropagation();
              deleteDialogopen();
            
            } }
          >
            <RemoveCircleIcon
              fontSize="small"
              sx={ {
                color: "#ff7f5c"
              } }
            />
          </IconButton>
        }
        { other.name }
      </Typography>
      <Dialog
        open={ deletedialogbox }
        PaperProps={ {
          style: {
            borderRadius  : "20px",
            backdropFilter: "blur(45px)",
            background    : "#FFFFFF 0% 0% no-repeat padding-box",
            boxShadow     : "0px 3px 6px #00000029",
            border        : "1px solid #E0E1E2"
          }
        } }
      >
        <DialogContent>
          <DeleteCancel
            name={ "Are you sure you want to delete this column?" }
            deleteClick={ async e => {

              e.stopPropagation();
              await props.removeColumn(index);
              deleteDialogclose();
            
            } }
            cancelClick={ () => {

              deleteDialogclose();
            
            } }
          />
        </DialogContent>
      </Dialog>
    </>
  );

};

export default GridTitleColumn;
