/* eslint-disable camelcase */
import {
  Box, Button, Dialog,
  DialogContent, Grid, IconButton, Slide, Tooltip, Typography
} from "@mui/material";
import moment from "moment";
import React, { forwardRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { components } from "react-select";
import BackNextButton from "../../../../components/BackNextButton";
import IndividualSectionHeaders from "../../../../components/IndividualSectionHeaders";
import ModuleSections from "../../../../components/ModuleSections";
import Data from "../../../../Data/Gtm.json";
import {
  createDigitalCampaign, getDigitalMarket
} from "../../../../services/digitalmarket";
import { getSegments } from "../../../../services/persona";

import HelpIcon from "@mui/icons-material/Help";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import { makeStyles } from "@mui/styles";
import CustomMultilineTextfield from "../../../../components/CustomMultilineTextfield";
import CustomMultiSelect from "../../../../components/CustomMultiSelect";
import ErrorMessage from "../../../../components/ErrorMessage";
import SegmentsPersonasDialog from "../../Customer/TargetSegments/SegmentsPersonasDialog";
import CampaignCalendar from "./CampaignCalendar";
import CreateCampaign from "./CreateCampaign";
import styles from "./DigitalMarketing.module.css";
import EditDigitalCampaign from "./EditDigitalCampaign";
import IndividualDetailsBox from "./IndividualDetailsBox";

import {
  createGtmComment,
  deleteGtmComment,
  updateGtmComment
} from "../../../../services/comment";

const useStyles = makeStyles(() => ({
  customWidth: {
    width          : 200,
    fontSize       : 10,
    backgroundColor: "white",
    color          : "#707070",
    boxShadow      : "0px 3px 6px #00000029",
    border         : "1px solid #707070",
    borderRadius   : 10
  },
  createcustomWidth: {
    width          : 300,
    fontSize       : 10,
    backgroundColor: "white",
    color          : "#707070",
    boxShadow      : "0px 3px 6px #00000029",
    border         : "1px solid #707070",
    borderRadius   : 10,
    padding        : 10
  },
  input: {
    border                                              : "1px solid #CCCCCC",
    backgroundColor                                     : "#ffffff",
    borderRadius                                        : 5,
    fontSize                                            : 14,
    "@media (min-height: 600px) and (max-height: 767px)": {
      "& .MuiOutlinedInput-input": {
        padding: "3px 14px"
      }
    }
  },
  createinput: {
    height         : 25,
    border         : "1px solid #CCCCCC",
    backgroundColor: "#ffffff",
    borderRadius   : 5
  },
  individualfields: {
    marginBottom                                        : "8px",
    "@media (min-height: 600px) and (max-height: 767px)": {
      marginBottom: "5px"
    }
  }
}));

const Transition = forwardRef(function Transition (props, ref) {

  return <Slide direction="up" ref={ ref } { ...props } />;

});

const DigitalCampaign = () => {

  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const [ segmentsDialog, setSegmentsDialog ] = useState([]);
  const [ dialogbox, setDialog ] = useState(false);
  const [ editdialogbox, setEditDialog ] = useState(false);
  const [ segmentdialogbox, setSegmentDialog ] = useState(false);
  const [ events, setEvents ] = useState([]);
  const [ comments, setComments ] = useState([]);
  const [ calendardialogbox, setCalendarDialog ] = useState(false);
  const [ isexecutionmethodcreated, setIsexecutionmethodcreated ] =
    useState(false);
  const [ errormsg, setErrormsg ] = useState("");

  const history = useHistory();
  const classes = useStyles();
  const content = Data.gtmdigitalmarketing;
  const hashvalue = history.location.hash.substring(1);
  const [ details, setDetails ] = useState({
    name           : "",
    objective      : "",
    executionMethod: [
      {
        title    : "Email Marketing",
        start    : "",
        end      : "",
        isCreated: false
      },
      {
        title    : "LinkedIn Messaging",
        start    : "",
        end      : "",
        isCreated: false
      },
      {
        title    : "Facebook Ad",
        start    : "",
        end      : "",
        isCreated: false
      },
      {
        title    : "Instagram Ad",
        start    : "",
        end      : "",
        isCreated: false
      },
      {
        title    : "",
        start    : "",
        end      : "",
        isCreated: false
      }
    ],
    kpiMetric      : [],
    expectedOutcome: ""
  });
  const [ contentlist, setContentlist ] = useState([]);

  useEffect(() => {

    details.executionMethod.map(method => {

      if (method.isCreated) {

        setIsexecutionmethodcreated(true);
        
        return;
      
      }
    
    });
  
  });

  useEffect(() => {

    fetchSegments();
  
  }, []);

  const fetchSegments = async () => {

    try {

      const response = await getSegments(venture.customer);
      const customer = response.data;

      setSegmentsDialog(customer.segmentsPersonas.segments);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const handleContentDetailsChange = (name, value, contentindex) => {

    const newdetails = { ...details };

    newdetails["executionMethod"] = details.executionMethod.map(
      (content, index) =>
        index === contentindex
          ? {
            ...content,
            [name]: value
          }
          : content
    );
    setDetails(newdetails);
  
  };

  const handleChange = (name, value) => {

    setDetails(values => ({ ...values, [name]: value }));
  
  };

  const handleKPIChange = e => {

    let newkpimetric = Array.isArray(e) ? e.map(x => x.value) : [];

    setDetails(values => ({ ...values, ["kpiMetric"]: newkpimetric }));
  
  };

  let { user, venture } = useSelector(state => {

    return { ...state };
  
  });

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  const editDialogopen = () => {

    setEditDialog(true);
  
  };
  const editDialogclose = () => {

    setEditDialog(false);
  
  };

  const calendarDialogopen = () => {

    setCalendarDialog(true);
  
  };
  const calendarDialogclose = () => {

    setCalendarDialog(false);
  
  };

  const segmentDialogopen = () => {

    setSegmentDialog(true);
  
  };
  const segmentDialogclose = () => {

    setSegmentDialog(false);
  
  };

  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);
    
    } else {

      setCollapsecomment(true);
    
    }
  
  };

  //   const formatOptionLabel = ({ value, label, customtooltip }) => (
  //     <div style={{ display: "flex", alignItems: "center" }}>
  //       <div>{label}</div>
  //       <Tooltip
  //         title={customtooltip}
  //         disableFocusListener
  //         placement="right-start"
  //         classes={{ tooltip: classes.customWidth }}
  //       >
  //         <IconButton sx={{ padding: "0px", marginLeft: "5px" }}>
  //           <HelpIcon style={{ color: "#071AFF",fontSize: "16px" }}></HelpIcon>
  //         </IconButton>
  //       </Tooltip>
  //     </div>
  //   );

  const Option = props => {

    return (
      <div>
        <components.Option { ...props }>
          <input
            type="checkbox"
            checked={ props.isSelected }
            onChange={ () => null }
          />{ " " }
          <label>{ props.label }</label>
          <Tooltip
            title={ props.data.customtooltip }
            disableFocusListener
            placement="right-start"
            classes={ { tooltip: classes.customWidth } }>
            <IconButton sx={ { padding: "0px", marginLeft: "5px" } }>
              <HelpIcon
                style={ { color: "#071AFF", fontSize: "16px" } }></HelpIcon>
            </IconButton>
          </Tooltip>
        </components.Option>
      </div>
    );
  
  };

  const metricOptions = [
    {
      value: "Web traffic sources",
      label: "Web traffic sources",
      customtooltip:
        "The Web Traffic Sources metric measures which traffic sources are driving visitors to your website, and provides a comparison of each of those sources. The three main traffic sources are direct, referral, and search, although your website may also have traffic from campaigns such as banner ads or paid search."
    },
    {
      value        : "Pages per Visit",
      label        : "Pages per Visit",
      customtooltip: "# of web pages browsed per visit by the user"
    },
    {
      value: "Average Time on Site/Page",
      label: "Average Time on Site/Page",
      customtooltip:
        "Time spent (secs, mins) browsing the website/page. Formula - Total Time spent by users/Total visitors "
    },
    {
      value: "Engagements",
      label: "Engagements",
      customtooltip:
        "The number of interactions your content received from users (likes, comments, shares, saves, etc.)"
    },
    {
      value: "Impressions",
      label: "Impressions",
      customtooltip:
        "The number of times your content is displayed to someone’s feed"
    },
    {
      value        : "Reach",
      label        : "Reach",
      customtooltip: "The number of people who see your content"
    },
    {
      value: "Followers",
      label: "Followers",
      customtooltip:
        "The number of users/people following your social media page"
    },
    {
      value: "Posts",
      label: "Posts",
      customtooltip:
        "Total number of social media feed posted from your company page"
    },
    {
      value: "Subscribed Users",
      label: "Subscribed Users",
      customtooltip:
        "Total number of users subscribed to your product/application"
    },
    {
      value: "Active Users",
      label: "Active Users",
      customtooltip:
        "Total numbers of converted Leads/wins from trial campaigns/subscriptions"
    },
    {
      value: "Trial Conversions",
      label: "Trial Conversions",
      customtooltip:
        "Measure the cost-effectiveness of marketing campaigns. Formula - Total cost of campaign / # of leads generated"
    },
    {
      value: "Website traffic leads",
      label: "Website traffic leads",
      customtooltip:
        "Number of website visitors convert to leads. #Leads/Total Visitors * 100"
    },
    {
      value        : "Returning visitors",
      label        : "Returning visitors",
      customtooltip: "Number of times users have visited the website"
    },
    {
      value        : "Inbound Leads",
      label        : "Inbound Leads",
      customtooltip: "Number of leads from Digital or Website content"
    },
    {
      value: "Sales Qualified Leads",
      label: "Sales Qualified Leads",
      customtooltip:
        "Number of actionable leads from campaigns, website, content"
    },
    {
      value: "Marketing Qualified Leads",
      label: "Marketing Qualified Leads",
      customtooltip:
        "Number of Information only leads from campaigns, website, content"
    },
    {
      value: "Lead conversion rates",
      label: "Lead conversion rates",
      customtooltip:
        "Total number of leads that are converted into sales. Formula - (Leads converted to Sales/Total Leads)x100"
    },
    {
      value: "Click thru rate",
      label: "Click thru rate",
      customtooltip:
        " Total number of times search engine/social campaigns prompts users to click and navigate to your page/content. Formula - Visitors that click on page result/Visitors that view the page result"
    },
    {
      value: "Customer lifetime value LTV",
      label: "Customer lifetime value LTV",
      customtooltip:
        "Amount of gross profit that is generated from a customer over the entire time they do business with a company. Formula - Gross margin (%) x Length of lifetime in pay periods x Revenue per subscription per pay period"
    },
    {
      value: "Return on Marketing Investment (ROI)",
      label: "Return on Marketing Investment (ROI)",
      customtooltip:
        "Measure the ability of marketing campaigns to generate new revenue. Formula - (Sales Growth (attributable revenue) – Marketing Cost) x 100 / Marketing Investment"
    },
    {
      value: "Customer Attrition",
      label: "Customer Attrition",
      customtooltip:
        "Rate at which company loses customers over time. Formula - (Customers lost in a given time period/Total number of customers)x100"
    },
    {
      value: "Customer Acquisition Cost (CAC)",
      label: "Customer Acquisition Cost (CAC)",
      customtooltip:
        "Total amount of capital it costs to gain a new customer. Formula - Total marketing spend on customer acquisition/Total new customers"
    },
    {
      value: "Response Rate",
      label: "Response Rate",
      customtooltip:
        "Total number of users who respond back to your communication efforts/marketing campagins. Formula - (Customers responding/Customers exposed to communications)x100"
    },
    {
      value: "Marketing Originated Customers",
      label: "Marketing Originated Customers",
      customtooltip:
        " total share of all customers that come directly from marketing efforts. Formula - Customers that started out as a marketing lead/Total Customers"
    },
    {
      value: "Content Downloads",
      label: "Content Downloads",
      customtooltip:
        " Total number of pieces of content that have been downloaded from Website"
    },
    {
      value: "Pageviews per Session",
      label: "Pageviews per Session",
      customtooltip:
        "average number of pages a user views during a single session. Formula - (Pageviews per session with users )/Total number of user sessions"
    },
    {
      value: "Dormancy Rate",
      label: "Dormancy Rate",
      customtooltip:
        "Total number of users not using application/ product in a given time period. Formula - (Customers not using the product within time period/Total number of customers)x100"
    },
    {
      value: "Bounce Rate",
      label: "Bounce Rate",
      customtooltip:
        "Number of visitors that arrive at your site and leave without navigating to any other page on your site. Formula - (Visitors leaving the site after only viewing one page/ Total site visitors)x100"
    },
    {
      value: "Net Promoter Score",
      label: "Net Promoter Score",
      customtooltip:
        "Retrieving data. Wait a few seconds and try to cut or copy again."
    }
  ];

  useEffect(() => {

    fetchGtmdigitalmarketing();
  
  }, []);

  const fetchGtmdigitalmarketing = async () => {

    try {

      const response = await getDigitalMarket(venture.gtm);
      // console.log(response.data);

      setContentlist(response.data.digitalMarketing.digitalCampaign);
      let newEvents = [],
        digitalcampaign = [];

      response.data.digitalMarketing.digitalCampaign.map(campaign => {

        campaign.executionMethod.map(event => {

          digitalcampaign.push({
            title        : event.title,
            start        : moment(event.start).format(),
            end          : moment(event.end).format(),
            color        : event.color,
            isCompleted  : event.isCompleted,
            description  : event.description,
            id           : event._id,
            extendedProps: {
              name           : campaign.name,
              objective      : campaign.objective,
              kpiMetric      : campaign.kpiMetric,
              expectedOutcome: campaign.expectedOutcome
            }
          });
        
        });
      
      });
      const Events = newEvents.concat(digitalcampaign);
      // console.log(Events);

      setEvents(Events);
      setComments(response.data.comments.digitalMarketing.digitalCampaign);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const handleCreate = async () => {

    try {

      if (contentlist.length < 20) {

        await createDigitalCampaign(
          details,
          venture.gtm,
          venture._id
        );

        setDetails({
          name           : "",
          objective      : "",
          executionMethod: [
            {
              title    : "Email Marketing",
              start    : "",
              end      : "",
              isCreated: false
            },
            {
              title    : "LinkedIn Messaging",
              start    : "",
              end      : "",
              isCreated: false
            },
            {
              title    : "Facebook Ad",
              start    : "",
              end      : "",
              isCreated: false
            },
            {
              title    : "Instagram Ad",
              start    : "",
              end      : "",
              isCreated: false
            },
            {
              title    : "",
              start    : "",
              end      : "",
              isCreated: false
            }
          ],
          kpiMetric      : [],
          expectedOutcome: ""
        });
        setIsexecutionmethodcreated(false);
        fetchGtmdigitalmarketing();
      
      } else {

        setErrormsg("Limit is 20. please delete to create new campaign");
        setTimeout(() => {

          setErrormsg("");
        
        }, 5000);
      
      }
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const addContent = (name, value, contentindex) => {

    const newdetails = { ...details };

    newdetails["executionMethod"] = details.executionMethod.map(
      (content, index) =>
        index === contentindex
          ? {
            ...content,
            [name]: value
          }
          : content
    );
    if (contentindex < 5) {

      newdetails["executionMethod"].push({
        title         : "",
        start         : "",
        end           : "",
        recurrence    : 1,
        frequency     : "",
        weekpublishday: {
          sunday   : false,
          monday   : false,
          tuesday  : false,
          wednesday: false,
          thursday : false,
          friday   : false,
          saturday : false
        },
        monthweek   : "",
        monthweekday: "",
        isCreated   : false
      });
    
    }
    setDetails(newdetails);
  
  };

  const addComment = async (commentText, replyTo) => {

    try {

      const response = await createGtmComment(
        commentText,
        user._id,
        "digitalMarketing",
        "digitalCampaign",
        venture.gtm,
        replyTo,
        window.location.href,
        "GTM > Digital Marketing > DDigital Campaign & Calendar"
      );

      setComments(response.data.comments.digitalMarketing.digitalCampaign);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateComment = async (commentid, commentText) => {

    try {

      const response = await updateGtmComment(
        commentText,
        user._id,
        commentid,
        venture._id,
        window.location.href,
        "GTM > Digital Marketing > Digital Campaign & Calendar"
      );

      setComments(response.data.comments.digitalMarketing.digitalCampaign);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const deleteComment = async commentid => {

    try {

      const response = await deleteGtmComment(
        "digitalMarketing",
        "digitalCampaign",
        venture.gtm,
        commentid,
        user._id,
        window.location.href,
        "GTM > Digital Marketing > Digital Campaign & Calendar"
      );

      setComments(response.data.comments.digitalMarketing.digitalCampaign);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  // console.log("main content", details);
  const pathname = history.location.pathname.substring(1);

  return (
    <ModuleSections
      Collapsecomment={ Collapsecomment }
      comments={ comments }
      addComment={ addComment }
      deleteComment={ deleteComment }
      updateComment={ updateComment }>
      <div className="box-display-style-for-next-and-back-button-with-justify">
        <div className="box-indiv-header-con">
          <IndividualSectionHeaders
            mainheading={ "Digital Marketing" }
            subheading={ "Digital Campaign & Calendar" }
            headingcontent={
              "Create campaign and Lead Gen plans to drive user adoption & traction for your product."
            }
            isGtm={ true }
            // isGtmChevron={true}
            Dialogopen={ Dialogopen }
            Dialogclose={ Dialogclose }
            dialogbox={ dialogbox }
            collapse_comment_function={ collapse_comment_function }
            content={ content }
            marginBottom={ "10px" }
            isGtmChevron={ pathname.includes("gtm") ? "gtmV" : "customer" }
          />
        </div>
        <Grid item xs={ 12 } id={ styles.maingrid }>
          <Typography
            variant="subtitle2"
            sx={ { color: "#071AFF", cursor: "pointer", fontWeight: "bold" } }
            component="span"
            onClick={ () => {

              segmentDialogopen();
            
            } }>
            &lt; List of Segments and Personas
          </Typography>
          <Dialog
            open={ segmentdialogbox }
            TransitionComponent={ Transition }
            keepMounted
            PaperProps={ {
              style: {
                borderRadius   : "20px",
                backdropFilter : "blur(10px)",
                backgroundColor: "rgba(255,255,255,0.80)",
                margin         : "20px",
                padding        : "0% 2%",
                height         : "100%"
              }
            } }
            maxWidth="md"
            fullWidth>
            <Box sx={ { alignSelf: "flex-end" } }>
              <IconButton component="span" onClick={ segmentDialogclose }>
                <HighlightOffIcon />
              </IconButton>
            </Box>
            <SegmentsPersonasDialog segments={ segmentsDialog } />
          </Dialog>
          <Box id={ styles.mainbox }>
            <Grid container>
              <Grid
                item
                md={ 8 }
                sx={ { borderRight: "solid 1px #cccccc", paddingRight: "5%" } }>
                <Grid
                  container
                  id={ styles.dialogContentCon }
                  className={ classes.individualfields }>
                  <Grid item md={ 4 }>
                    <Typography variant="body2" fontWeight="bold">
                      Campaign Name*
                    </Typography>
                  </Grid>
                  <Grid item md={ 8 }>
                    {/* <TextField
                    placeholder="Mass Outreach"
                    type="text"
                    size="small"
                    InputProps={{
                      className: classes.input,
                    }}
                    sx={{ width: "100%" }}
                    value={details.name}
                    name="name"
                    onChange={(e) => {
                      handleChange(e.target.name, e.target.value.trimStart());
                    }}
                  /> */}
                    <CustomMultilineTextfield
                      padding={ "2px 15px" }
                      // fontSize={"14px"}
                      width={ "100%" }
                      border={ "1px solid #CCCCCC" }
                      placeholder="Mass Outreach"
                      value={ details.name }
                      name="name"
                      handledetailsChange={ e => {

                        handleChange(e.target.name, e.target.value.trimStart());
                      
                      } }
                    />
                  </Grid>
                </Grid>
                <Grid container id={ styles.dialogContentCon }>
                  <Grid item md={ 4 }>
                    <Typography variant="body2" fontWeight="bold">
                      Overall Objective
                    </Typography>
                  </Grid>
                  <Grid item md={ 8 }>
                    {/* <TextField
                    placeholder="Target qualified leads , increased adoption etc."
                    type="text"
                    size="small"
                    InputProps={{
                      className: classes.input,
                    }}
                    sx={{ width: "100%" }}
                    value={details.objective}
                    name="objective"
                    onChange={(e) => {
                      handleChange(e.target.name, e.target.value);
                    }}
                  /> */}
                    <CustomMultilineTextfield
                      padding={ "2px 15px" }
                      // fontSize={"14px"}
                      width={ "100%" }
                      border={ "1px solid #CCCCCC" }
                      placeholder="Target qualified leads , increased adoption etc."
                      value={ details.objective }
                      name="objective"
                      handledetailsChange={ e => {

                        handleChange(e.target.name, e.target.value);
                      
                      } }
                    />
                  </Grid>
                </Grid>
                <Grid container id={ styles.dialogContentCon }>
                  <Grid item md={ 4 }>
                    <Typography variant="body2" fontWeight="bold">
                      Execution Method*
                    </Typography>
                  </Grid>
                  <Grid item md={ 8 } id={ styles.contenttypebtnsgrid }>
                    <Grid container rowGap={ 1 } columnGap={ 2 }>
                      { details.executionMethod.map((content, index) => {

                        return <CreateCampaign
                          addContent={ addContent }
                          handleContentDetailsChange={
                            handleContentDetailsChange
                          }
                          details={ content }
                          index={ index }
                        />;
                      
                      }) }
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  id={ styles.dialogContentCon }
                  className={ classes.individualfields }>
                  <Grid item md={ 4 }>
                    <Typography variant="body2" fontWeight="bold">
                      KPI Metric
                    </Typography>
                  </Grid>
                  <Grid item md={ 8 }>
                    {/* <Select
                    placeholder="Select your Metric"
                    options={metricOptions}
                    // formatOptionLabel={formatOptionLabel}
                    components={{
                      Option,
                    }}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    filterOption={createFilter({
                      matchFrom: "start",
                    })}
                    styles={{
                      control: (styles) => ({
                        ...styles,
                        border: "2px solid #CCCCCC",
                        backgroundColor: "#ffffff",
                        borderRadius: 5,
                      }),
                      valueContainer: (styles) => ({
                        ...styles,
                        maxHeight: 50,
                        overflowY: "auto",
                      }),
                    }}
                    isMulti
                    maxMenuHeight={150}
                    value={metricOptions.filter((obj) =>
                      details.kpiMetric.includes(obj.value)
                    )}
                    onChange={handleKPIChange}
                  /> */}
                    <CustomMultiSelect
                      placeholder="Select your Metric"
                      options={ metricOptions }
                      components={ {
                        Option
                      } }
                      closeMenuOnSelect={ false }
                      hideSelectedOptions={ false }
                      isClearable={ false }
                      maxMenuHeight={ 150 }
                      value={ metricOptions.filter(obj =>
                        details.kpiMetric.includes(obj.value)
                      ) }
                      handleChange={ handleKPIChange }
                    />
                  </Grid>
                </Grid>
                <Grid container id={ styles.dialogContentCon }>
                  <Grid item md={ 4 }>
                    <Typography variant="body2" fontWeight="bold">
                      Expected Outcome
                    </Typography>
                  </Grid>
                  <Grid item md={ 8 }>
                    {/* <TextField
                    placeholder="1m users, 10k downloads etc."
                    type="text"
                    size="small"
                    InputProps={{
                      className: classes.input,
                    }}
                    sx={{ width: "100%" }}
                    value={details.expectedOutcome}
                    name="expectedOutcome"
                    onChange={(e) => {
                      handleChange(e.target.name, e.target.value);
                    }}
                  /> */}
                    <CustomMultilineTextfield
                      padding={ "2px 15px" }
                      // fontSize={"14px"}
                      width={ "100%" }
                      border={ "1px solid #CCCCCC" }
                      placeholder="1m users, 10k downloads etc."
                      value={ details.expectedOutcome }
                      name="expectedOutcome"
                      handledetailsChange={ e => {

                        handleChange(e.target.name, e.target.value);
                      
                      } }
                    />
                  </Grid>
                </Grid>
                <Box id={ styles.addbox }>
                  { errormsg &&
                    <ErrorMessage
                      errormsg={ errormsg }
                      width={ "70%" }
                      padding={ "5px 15px" }
                    />
                  }
                  <Button
                    variant="outlined"
                    size="medium"
                    disabled={ details.name === "" || !isexecutionmethodcreated }
                    id={ styles.addbtn }
                    onClick={ handleCreate }>
                    Add Campaign
                  </Button>
                </Box>
              </Grid>
              <Grid item md={ 4 } sx={ { paddingLeft: "5%", paddingRight: "3%" } }>
                <Grid container id={ styles.griditem2 }>
                  <Box id={ styles.contentlistgrid }>
                    <Typography>List of Campaign Concepts</Typography>
                    <Grid container direction="column" pl={ 2 } pr={ 2 }>
                      { contentlist.map(content => {

                        return <IndividualDetailsBox
                          name={ "campaign" }
                          editDialogopen={ editDialogopen }
                          details={ content }
                          fetchGtmdigitalmarketing={ fetchGtmdigitalmarketing }
                        />;
                      
                      }) }
                    </Grid>
                    <Dialog
                      open={ editdialogbox }
                      PaperProps={ {
                        style: {
                          borderRadius   : "20px",
                          backdropFilter : "blur(30px)",
                          padding        : "50px",
                          backgroundColor: "initial",
                          boxShadow      : "none",
                          border         : "1px solid #707070",
                          width          : "100%"
                        }
                      } }
                      BackdropProps={ { invisible: true } }>
                      <Box sx={ { alignSelf: "flex-end" } }>
                        <IconButton component="span" onClick={ editDialogclose }>
                          <HighlightOffIcon />
                        </IconButton>
                      </Box>
                      <DialogContent>
                        <EditDigitalCampaign
                          id={ hashvalue }
                          editDialogclose={ editDialogclose }
                          fetchGtmdigitalmarketing={ fetchGtmdigitalmarketing }
                        />
                      </DialogContent>
                    </Dialog>
                  </Box>
                  <Box>
                    <Button
                      variant="outlined"
                      size="medium"
                      id={ styles.calendarbtn }
                      onClick={ calendarDialogopen }>
                      View Calendar
                    </Button>
                    <Dialog
                      TransitionComponent={ Transition }
                      keepMounted
                      open={ calendardialogbox }
                      PaperProps={ {
                        style: {
                          borderRadius: "20px",
                          background  : "white",
                          boxShadow   : "0px 3px 6px #00000029",
                          border      : "1px solid #707070"
                        }
                      } }
                      fullWidth
                      maxWidth="xl">
                      <Box sx={ { alignSelf: "flex-end" } }>
                        <IconButton
                          component="span"
                          onClick={ calendarDialogclose }>
                          <HighlightOffIcon />
                        </IconButton>
                      </Box>

                      <DialogContent>
                        <CampaignCalendar events={ events } />
                      </DialogContent>
                    </Dialog>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <div className="ttribe-back-next-button-con">
          <BackNextButton
            restrictedBackClick={ () => {

              history.push("/gtmdigitalcontent");
            
            } }
            backClick={ () => {

              history.push("/gtmdigitalcontent");
            
            } }
            restrictedNextClick={ () => {

              history.push("/digitalmarketingsummary");
            
            } }
            nextClick={ () => {

              history.push("/digitalmarketingsummary");
            
            } }
          />
        </div>
      </div>
    </ModuleSections>
  );

};

export default DigitalCampaign;
