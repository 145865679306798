import React from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import LoadingToRedirect from "./LoadingToRedirect";

const UserRoute = ({ ...rest }) => {

  // private routing -> refer doc in react router

  const { user, venture } = useSelector(state => ({ ...state }));

  const allowedRoutes = [
    "/welcomepage", "/createventure", "/editventure/:id", "/myprofile", "/loginsecurity", "/mysubscriptions", "/billingsummary", "/billinghistory", "/forgotpasswordprofile"
  ];

  return user && user.token ?
    venture && venture._id ?
      <Route { ...rest } />
      : allowedRoutes.includes(rest.path) ?
        <Route { ...rest } />
        :
        <LoadingToRedirect url="/welcomepage" />
    
    :
    <LoadingToRedirect url="/login" />
  ;

};

export default UserRoute;
