import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box, TextField, Typography
} from "@mui/material";
import React, { useRef } from "react";
import ImageOptions from "./ImageOptions";
import styles from "./IndividualTeamMember.module.css";

export default function IndividualTeamMember({
  isEdit,
  teamMember,
  teamMemberIndex,
  slide,
  handleTeamChange,
  removeTeamMember,
  uploadImages,
  profileImg,
  deleteUploadedImgs,
  updateIschange
  // ...props
}) {

  // for uploading profileImg
  const profileImgFileInput = useRef(null);

  const profileImghandleClick = () => {

    profileImgFileInput.current.click();
  
  };

  return (
    <Box
      id={styles.individualTeamBox}
    >
      {isEdit ?
        <Box
          id={styles.editProfileBox}
        >
          {profileImg?.imgUrl || teamMember.profileImg ?
            <>
              <img
                src={
                  profileImg?.imgUrl
                    ? profileImg?.imgUrl
                    : teamMember.profileImg
                      ? teamMember.profileImg
                      : profileImg?.imgUrl
                }
                style={{ width: "100%", height: "100%" }}
                alt="profileImg"
              />
              <Box
                sx={{
                  position       : "absolute",
                  left           : "40px",
                  bottom         : "0px",
                  display        : "flex",
                  justifyContent : "space-evenly",
                  alignItems     : "center",
                  backgroundColor: "#ffffff"
                }}
              >
                <ImageOptions
                  IconSize="14px"
                  handleClick={profileImghandleClick}
                  image={profileImg?.imgUrl || teamMember.profileImg}
                  deleteUploadedImgs={() => {

                    updateIschange();
                    deleteUploadedImgs(
                      `${slide.renderName}.${teamMemberIndex}`,
                      teamMember.profileImg ? true : false
                    );
                    if (teamMember.profileImg)
                      handleTeamChange("profileImg", "", teamMemberIndex);
                  
                  }}
                />
              </Box>
            </>
            :
            <Box
              sx={{
                width         : "100%",
                height        : "100%",
                display       : "flex",
                alignItems    : "center",
                justifyContent: "center"
              }}
              onClick={profileImghandleClick}
            >
              <img
                src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/upload.png`}
                style={{
                  width : "25px",
                  height: "25px"
                }}
                alt="profileImg"
              />
            </Box>
          }
          <input
            type="file"
            id="coverImg"
            ref={profileImgFileInput}
            onChange={e => {

              uploadImages(
                `${slide.renderName}.${teamMemberIndex}`,
                e.target.files[0]
              );
              updateIschange();
            
            }}
            onClick={e => {

              e.target.value = null;
            
            }}
            style={{ display: "none" }}
            accept="image/*"
          />
          {isEdit && teamMemberIndex > 2 &&
            <Box
              id={styles.closeIconBox}
              onClick={() => {

                removeTeamMember(teamMemberIndex);
              
              }}
            >
              <CloseIcon
                id={styles.closeIcon}
              />
            </Box>
          }
        </Box>
        : teamMember.profileImg ?
          <Box
            id={styles.profileBox}
          >
            <img
              src={teamMember.profileImg}
              style={{ width: "100%", height: "100%" }}
              alt="profileImg"
            />
          </Box>
          :
          <Box
            id={styles.profileBox}
          >
            <AccountBoxIcon
              id={styles.profileIcon}
            />
          </Box>
      }

      <Box
        id={styles.nameTitleBox}
      >
        {isEdit ?
          <Box sx={{ marginBottom: "3px" }}>
            <TextField
              value={teamMember.name}
              onChange={e => {

                handleTeamChange("name", e.target.value, teamMemberIndex);
              
              }}
              fullWidth
              inputProps={{ maxLength: 20 }}
              sx={{
                "& .MuiInputBase-input": {
                  fontSize  : "16px",
                  padding   : "1px 10px",
                  border    : "none",
                  color     : "#393939",
                  textAlign : "center",
                  fontWeight: "bold"
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "2px dotted #B4B4FA"
                }
              }}
            />
          </Box>
          :
          <Box sx={{}}>
            <Typography
              id={styles.nameTypo}
            >
              {teamMember.name}
            </Typography>
          </Box>
        }

        {isEdit ?
          <TextField
            value={teamMember.title}
            onChange={e => {

              handleTeamChange("title", e.target.value, teamMemberIndex);
            
            }}
            fullWidth
            inputProps={{ maxLength: 20 }}
            sx={{
              "& .MuiInputBase-input": {
                fontSize  : "14px",
                padding   : "1px 10px",
                border    : "none",
                color     : "#707070",
                textAlign : "center",
                fontWeight: "bold"
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "2px dotted #B4B4FA"
              }
            }}
          />
          :
          <Typography
            id={styles.titleTypo}
          >
            {teamMember.title}
          </Typography>
        }
      </Box>
      {isEdit ?
        <TextField
          value={teamMember.profileInfo}
          onChange={e => {

            handleTeamChange("profileInfo", e.target.value, teamMemberIndex);
          
          }}
          placeholder="Brief profile description"
          fullWidth
          multiline
          maxRows={2}
          minRows={2}
          inputProps={{ maxLength: 55 }}
          sx={{
            "& .MuiOutlinedInput-root": {
              padding: "10px 5px"
            },
            "& .MuiInputBase-input": {
              fontSize  : "13px",
              color     : "#707070",
              textAlign : "center",
              whiteSpace: "pre-line"
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "2px dotted #919AFF"
            }
          }}
        />
        :
        <div
          style={{
            width       : "100%",
            border      : "1px solid #BEBEBE",
            height      : "100%",
            borderRadius: "5px",
            overflowY   : "auto",
            margin      : "auto"
          }}
        >
          <div
            style={{
              margin   : "0.5vh 1vh",
              wordWrap : "break-word",
              textAlign: "center"
            }}
          >
            <Typography
              sx={{
                fontSize  : "1.6vh",
                color     : "#707070",
                lineHeight: "23px",
                whiteSpace: "pre-line"
              }}
            >
              {teamMember.profileInfo}
            </Typography>
          </div>
        </div>
      }
    </Box>
  );

}
