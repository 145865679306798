import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
  Box, Grid, InputBase, TextField
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { useSelector } from "react-redux";

import { useLocation } from "react-router-dom";
import HelpIconTooltip from "../../../../components/HelpIconTooltip";
import "./PlOutlook.module.css";

const CustomInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3)
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position    : "relative",
    // backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",

    border: "1px solid #ced4da",

    fontSize  : 11,
    fontWeight: "bold",
    width     : 60,

    padding   : "5px 6px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow"
    ]),
    // Use the system font instead of the default Roboto font.

    "&:focus": {
      boxShadow  : `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    }
  }
}));

const CustomInputStyl = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3)
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position    : "relative",

    backgroundColor: "#E3EFFF",

    border: "1px solid #CCCCCC",

    fontWeight: "bold",

    fontSize                 : 11,
    width                    : 60,
    padding                  : "5px 6px",
    "-webkit-text-fill-color": "black !important",
    color                    : "black !important",
    transition               : theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow"
    ]),
    // Use the system font instead of the default Roboto font.

    "&:focus": {
      boxShadow  : `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    },
    "& .MuiInputBase-input.MuiInput-input.Mui-disabled": {
      "-webkit-text-fill-color": "black"
    }
  }
}));

// const useStyles = makeStyles({
//   monthRow: {
//     // width: 76,
//     width     : 76,
//     textAlign : "center",
//     color     : "#393939",
//     fontWeight: "bold"
//   },
//   cellRow: {
//     width   : 120,
//     height  : 20,
//     fontSize: 13,
//     color   : "#393939"
//   }
// });

// const textFieldSX = {
//   input: {
//     "-webkit-text-fill-color": "black !important",
//     color                    : "black !important"
//   }
// };

const PlTable = React.memo( ({
  table,
  handleChange,
  financials,
  handleChangeRowHeaders,
  isedit,
  selectedindex,
  setIsedit,
  setSelectedindex
}) => {

  // const classes = useStyles();

  const { venture } = useSelector(state => ({ ...state }));


  const numberWithCommasWithCurrency = num => {

    if (financials.plOutlooksCurrency || financials.currency) {

      let newNum = num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      
      return financials?.currency ? `${financials?.currency.split(" ")[0].trim()} ${newNum}` : `${financials?.plOutlooksCurrency.split(" ")[0].trim()} ${newNum}`;
    
    } else {

      return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    
    }
  
  };
  const numberWithComma = num => {

    return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  };

  // const handleClick = event => {

  //   if (event.button !== 0) {
  //     return;
  //   }
  //   setIsDragging(true);
  //   setScrollTop(containerRef.current.scrollTop);
  // };

  const location = useLocation();

  return (
    // <div className="scrollable-container"
    //   ref={containerRef}
    //   onMouseDown={handleMouseDown}
    //   onMouseMove={handleMouseMove}
    //   onMouseUp={handleMouseUp}
    //   onMouseLeave={handleMouseUp}
    //   onClick={handleClick}
    //   >
    <Grid
      direction="column"
      container
      rowSpacing={1}
      className="tribe-pl-table-scrollable-container"
      // ref={containerRef}
      // onMouseDown={handleMouseDown}
      // onMouseMove={handleMouseMove}
      // onMouseUp={handleMouseUp}
      // onClick={handleClick}
      sx={{
        // height: "fit-content",
        // width: "100%",
        overflowY    : "unset",
        overflowX    : "unset",
        flexDirection: "row"
      }}
    >
      <Grid item>
        <Grid
          container
          direction="row"
          wrap="nowrap"
          justifyContent="space-between"
        >
          <div style={{ width: "150px" }}></div>

          <div className={"tribe-pl-table-fin-header-col"}>
            Jan
          </div>
          <div className={"tribe-pl-table-fin-header-col"}>
            Feb
          </div>
          <div className={"tribe-pl-table-fin-header-col"}>
            Mar
          </div>
          <div className="tribe-pl-table-fin-qtr-con">
            Q1
          </div>
          <div className={"tribe-pl-table-fin-header-col"}>
            Apr
          </div>
          <div className={"tribe-pl-table-fin-header-col"}>
            May
          </div>
          <div className={"tribe-pl-table-fin-header-col"}>
            Jun
          </div>
          <div className="tribe-pl-table-fin-qtr-con">
            Q2
          </div>
          <div className={"tribe-pl-table-fin-header-col"}>
            July
          </div>
          <div className={"tribe-pl-table-fin-header-col"}>
            Aug
          </div>
          <div className={"tribe-pl-table-fin-header-col"}>
            Sep
          </div>
          <div className="tribe-pl-table-fin-qtr-con">
            Q3
          </div>
          <div className={"tribe-pl-table-fin-header-col"}>
            Oct
          </div>
          <div className={"tribe-pl-table-fin-header-col"}>
            Nov
          </div>
          <div className={"tribe-pl-table-fin-header-col"}>Dec</div>
          <div className="tribe-pl-table-fin-qtr-con">
            Q4
          </div>
          <div style={{ fontSize: "15px" }} className={"tribe-pl-table-fin-header-col"}>
            Total Year
          </div>
        </Grid>
      </Grid>
      {table?.slice(0, 1).map((row, rowindex) => {

        return (
          <Grid item>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
            >
              {isedit && selectedindex === rowindex ?
                <Box sx={{ width: "150px" }}>
                  <TextField
                    type="text"
                    sx={{
                      "& .MuiInputBase-input": {
                        fontSize: 11,
                        padding : "6px"
                      }
                    }}
                    onClick={e => {

                      e.stopPropagation();
                    
                    }}
                    value={row.rowName}
                    onChange={e => {

                      e.stopPropagation();
                      handleChangeRowHeaders(e.target.value, rowindex);
                    
                    }}
                  />
                </Box>
                :
                <div
                  // item
                  // alignItems="center"
                  className="tribe-pl-table-fin-header-row"
                  onClick={e => {

                    e.stopPropagation();
                    if (venture.userRole !== "Commenter" && !location.pathname.includes("pitchdeckintro")) {

                      setIsedit(true);
                      setSelectedindex(rowindex);
                    
                    }
                  
                  }}
                >
                  {row.rowName}
                </div>
              }
              {row.rowVal.map((col, colindex) => {

                return (
                  <Grid item >
                    {location.pathname.includes("pitchdeckintro") ?
                      <CustomInputStyl
                        disabled={true}
                        value={numberWithComma(col.value)}
                        type="text"
                      /> : col.isOutput && venture.userRole !== "Commenter" ?
                        <CustomInput
                          type="text"
                          value={numberWithComma(col.value)}
                          onChange={e => {

                            handleChange(e.target.value, rowindex + 0, colindex);
                          
                          }}
                        />
                        :
                        <CustomInputStyl
                          disabled={true}
                          value={numberWithComma(col.value)}
                          type="text"
                        />
                    }
                  </Grid>
                );
              
              })}
            </Grid>
          </Grid>
        );
      
      })}
      <Grid
        item
        xs={12}
        sx={{
          color: "#888888"
        }}
      >
        <Box
          sx={{
            color: "#393939"
          }}
        >
          <strong>P&L Projections</strong>
        </Box>
      </Grid>
      {table.slice(1, 5).map((row, rowindex) => {

        return (
          <Grid item>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
            >
              {isedit && selectedindex === rowindex + 1 ?
                <Box sx={{ width: "150px" }}>
                  <TextField
                    type="text"
                    sx={{
                      "& .MuiInputBase-input": {
                        // borderRadius: 4,
                        // position: "relative",

                        // border: "1px solid #ced4da",

                        fontSize: 11,

                        padding: "6px"
                      }
                    }}
                    onClick={e => {

                      e.stopPropagation();
                    
                    }}
                    value={row.rowName}
                    onChange={e => {

                      e.stopPropagation();
                      handleChangeRowHeaders(e.target.value, rowindex + 1);
                    
                    }}
                  />
                </Box>
                :
                <div
                  // item
                  // alignItems="center"
                  className={"tribe-pl-table-fin-header-row"}
                  onClick={e => {

                    e.stopPropagation();
                    if (venture.userRole !== "Commenter" && !location.pathname.includes("pitchdeckintro")) {

                      setIsedit(true);
                      setSelectedindex(rowindex + 1);
                    
                    }
                  
                  }}
                >
                  {row.rowName}
                  {row.rowName === "Gross Profit" &&
                    <HelpIconTooltip
                      tooltipdata={
                        "Gross Profit = Revenues - COGS"}
                      width="=100%" />
                  }
                  {row.rowName === "Gross Profit %" &&
                    <HelpIconTooltip
                      tooltipdata={
                        " Gross Profit % = ( Gross Profit / Revenues )  * 100"}
                      width="=100%" />
                  }
                </div>
              }
              {row.rowVal.map((col, colindex) => {

                return (
                  <Grid item >
                    {location.pathname.includes("pitchdeckintro") ?
                      <CustomInputStyl
                        disabled={true}
                        value={numberWithCommasWithCurrency(col.value)}
                        type="text"
                      /> : col.isOutput && venture.userRole !== "Commenter" ?
                        <CustomInput
                          type="text"
                          value={numberWithCommasWithCurrency(col.value)}
                          onChange={e => {

                            handleChange(e.target.value, rowindex + 1, colindex);
                          
                          }}
                        />
                        :
                        <CustomInputStyl
                          disabled={true}
                          value={
                            rowindex === 3
                              ? col.value
                                ? `${col.value} %`
                                : "0 %"
                              : numberWithCommasWithCurrency(col.value)
                          }
                          type="text"
                        />
                    }
                  </Grid>
                );
              
              })}
            </Grid>
          </Grid>
        );
      
      })}
      <Grid
        item
        alignItems="center"
        xs={12}
        sx={{
          color: "#393939"
        }}
      >
        <Box>
          <strong>Operating Costs</strong>
        </Box>
      </Grid>
      {table.slice(5).map((row, rowindex) => {

        return (
          <Grid key={rowindex} item>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
            >
              {isedit && selectedindex === (rowindex + 5) ?

                <Box sx={{ width: "150px" }}>
                  <TextField
                    type="text"
                    sx={{
                      "& .MuiInputBase-input": {
                        fontSize: 11,
                        padding : "6px"
                      }
                    }}
                    onClick={e => {

                      e.stopPropagation();
                    
                    }}
                    value={row.rowName}
                    onChange={e => {

                      e.stopPropagation();
                      handleChangeRowHeaders(e.target.value, rowindex + 5);
                    
                    }}
                  />
                </Box>
                :
                <div
                  // item
                  // alignItems="center"
                  className={"tribe-pl-table-fin-header-row"}
                  onClick={e => {

                    e.stopPropagation();
                    if (venture.userRole !== "Commenter" && !location.pathname.includes("pitchdeckintro")) {

                      setIsedit(true);
                      setSelectedindex(rowindex + 5);
                    
                    }
                  
                  }}
                >
                  {row.rowName}
                  {row.rowName === "Total Ops Costs" &&
                    <HelpIconTooltip
                      tooltipdata={
                        "Total Ops Costs = Talent + T&E + Biz Ops +Buffer"}
                      width="100%" />
                  }
                  {row.rowName === "EBIT" &&
                    <HelpIconTooltip
                      tooltipdata={
                        "EBIT = Gross Profit  - Total Ops Costs"}
                      width="100%" />
                  }
                  {row.rowName === "Operating Margin %" &&
                    <HelpIconTooltip
                      tooltipdata={
                        "Operating Margin %= ( EBIT / Revenues ) * 100"}
                      width="100%" />
                  }
                  {row.rowName === "Net Income" &&
                    <HelpIconTooltip
                      tooltipdata={
                        "Net Income = EBIT - ( Taxes + Interest )"}
                      width="100%" />
                  }
                </div>
              }
              {row.rowVal.map((col, colindex) => {

                return (
                  <Grid item >
                    {location.pathname.includes("pitchdeckintro") ?
                      <CustomInputStyl
                        disabled={true}
                        value={
                          rowindex === 6
                            ? col.value
                              ? `${col.value} %`
                              : "0 %"
                            : numberWithCommasWithCurrency(col.value)
                        }
                        type="text"
                      /> : col.isOutput && venture.userRole !== "Commenter" ?
                        <CustomInput
                          type="text"
                          value={
                            rowindex === 6
                              ? col.value
                                ? `${col.value} %`
                                : "0 %"
                              : numberWithCommasWithCurrency(col.value)
                          }
                          onChange={e => {

                            handleChange(
                              e.target.value,
                              rowindex + 5,
                              colindex
                            );
                          
                          }}
                        />
                        :
                        <CustomInputStyl
                          disabled={true}
                          value={
                            rowindex === 6
                              ? col.value
                                ? `${col.value} %`
                                : "0 %"
                              : numberWithCommasWithCurrency(col.value)
                          }
                          type="text"
                        />
                    }
                  </Grid>
                );
              
              })}
            </Grid>
          </Grid>
        );
      
      })}
    </Grid>
    // </div>
  );

});

export default PlTable;
