// import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid, InputAdornment, OutlinedInput,
  TextField
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

import { useLocation } from "react-router-dom";
import "./CashFlow.module.css";
import HelpIconTooltip from "../../../../components/HelpIconTooltip";

// const useStyles = makeStyles({
//   monthRow: {
//     // width: 7
//     width     : "20%",
//     textAlign : "center",
//     color     : "#393939",
//     fontWeight: "bold"
//     // marginRight: "1em"
//   },
//   cellRow: {
//     width      : "20%",
//     height     : "100%",
//     fontSize   : 12,
//     color      : "#393939",
//     wordBreak  : "break-all",
//     marginRight: "10px"
//   }
// });

// const textFieldSX = {
//   input: {
//     "-webkit-text-fill-color": `black !important`,
//     color: `black !important`,
//   },
// };

const CashFlowTable = ({
  table,
  handleChange,
  financials,
  handleChangeRowHeaders,
  isedit,
  selectedindex,
  setIsedit,
  setSelectedindex,
  yearList
}) => {

  // const classes = useStyles();

  const { venture } = useSelector(state => ({ ...state }));

  const numberWithComma = num => {

    const numberWithComma = num && num.toString().replaceAll(",", "").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

    
    return numberWithComma;

  };

  const HandleYearRow = () => {

    if (yearList && yearList.length) {

      return <>{yearList?.map(year =>
        <div className="tribe-table-fin-header-col">
          {year}
        </div>
      )}</>;

    } else {

      return <>
        <div className="tribe-table-fin-header-col">1</div>
        <div className="tribe-table-fin-header-col">2</div>
        <div className="tribe-table-fin-header-col">3</div>
        <div className="tribe-table-fin-header-col">4</div>
        <div className="tribe-table-fin-header-col">5</div>
      </>;
    
    }

  };

  const location = useLocation();

  return (
    <div>
      <Grid
        container
        direction="column"
        rowSpacing={1}
        sx={{
          height: "100%",
          width : "100%"
          // overflowY: "auto",
          // overflowX: "auto",
        }}
      >
        <Grid item>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            justifyContent="space-between"
          >
            <Grid item sx={{ width: "20%" }}></Grid>
            {<HandleYearRow />}
          </Grid>
        </Grid>

        {table?.slice(0, 1).map((row, rowindex) => {

          return (
            <Grid item>
              <Grid
                container
                direction="row"
                wrap="nowrap"
                justifyContent="space-between"
                alignItems="center"
              >
                {isedit && selectedindex === rowindex ?
                  <Box sx={{ width: "20%" }}>
                    <TextField
                      type="text"
                      sx={{
                        "& .MuiInputBase-input": {
                          fontSize: 11,
                          padding : "6px"
                        }
                      }}
                      onClick={e => {

                        e.stopPropagation();
                      
                      }}
                      value={row.rowLabel}
                      onChange={e => {

                        e.stopPropagation();
                        handleChangeRowHeaders(e.target.value, rowindex);
                      
                      }}
                    />
                  </Box>
                  :
                  <Grid
                    sx={{
                      width      : "20%",
                      height     : "100%",
                      fontSize   : 12,
                      color      : "#393939",
                      wordBreak  : "break-all",
                      marginRight: "10px"
                    }}
                    item
                    alignItems="center"
                    // className={classes.cellRow}
                    onClick={e => {

                      e.stopPropagation();
                      if (
                        venture.userRole !== "Commenter" &&
                        !location.pathname.includes("pitchdeckintro")
                      ) {

                        setIsedit(true);
                        setSelectedindex(rowindex);
                      
                      }
                    
                    }}
                  >
                    {row.rowLabel}
                  </Grid>
                }
                {row.rowVal.map((col, colindex) => {

                  return (
                    <Grid sx={{ width: "20%" }} item >
                      {location.pathname.includes("pitchdeckintro") ?
                        <OutlinedInput
                          className="cus-cash-flow-row"
                          disabled={true}
                          startAdornment={
                            <InputAdornment position="start">
                              {financials.currency.split("")[0]}
                            </InputAdornment>
                          }
                          value={numberWithComma(col.value)}
                          type="text"
                        />
                        : col.isOutput && venture.userRole !== "Commenter" ?
                          <OutlinedInput
                            className="cus-cash-flow-row"
                            type="text"
                            startAdornment={
                              <InputAdornment position="start">
                                {financials.currency.split("")[0]}
                              </InputAdornment>
                            }
                            value={numberWithComma(col.value)}
                            onChange={e => {

                              handleChange(e.target.value, rowindex, colindex);
                            
                            }}
                          />
                          :
                          <OutlinedInput
                            className="cus-cash-flow-row"
                            disabled={true}
                            value={numberWithComma(col.value)}
                            type="text"
                          />
                      }
                    </Grid>
                  );
                
                })}
              </Grid>
            </Grid>
          );
        
        })}
        <Grid
          item
          xs={12}
          sx={{
            color: "#888888"
          }}
        >
          <Box
            sx={{
              color: "#393939"
            }}
          >
            <strong>Cash Flow from Operations</strong>
          </Box>
        </Grid>
        {table && table.length && table?.slice(1, 10).map((row, rowindex) => {

          return (
            <Grid item>
              <Grid
                container
                direction="row"
                wrap="nowrap"
                justifyContent="space-between"
                alignItems="center"
              >
                {isedit && selectedindex === rowindex + 1 ?
                  <Box sx={{ width: "20%" }}>
                    <TextField
                      type="text"
                      sx={{
                        "& .MuiInputBase-input": {
                          fontSize: 11,
                          padding : "6px"
                        }
                      }}
                      onClick={e => {

                        e.stopPropagation();
                      
                      }}
                      value={row.rowLabel}
                      onChange={e => {

                        e.stopPropagation();
                        handleChangeRowHeaders(e.target.value, rowindex + 1);
                      
                      }}
                    />
                  </Box>
                  :
                  <Grid
                    sx={{
                      width      : "20%",
                      height     : "100%",
                      fontSize   : 12,
                      color      : "#393939",
                      wordBreak  : "break-all",
                      marginRight: "10px"
                    }}
                    item
                    alignItems="center"
                    // className={classes.cellRow}
                    onClick={e => {

                      e.stopPropagation();
                      if (
                        venture.userRole !== "Commenter" &&
                        !location.pathname.includes("pitchdeckintro")
                      ) {

                        setIsedit(true);
                        setSelectedindex(rowindex + 1);
                      
                      }
                    
                    }}
                  >
                    {row.rowLabel}
                    {row.rowName === "Net_Cash_Flow_from_Operations" &&
                      <HelpIconTooltip
                        tooltipdata={"Sum of all the above values in 'Operations'"}
                        width="60%" />
                    }
                  </Grid>
                }
                {row.rowVal.map((col, colindex) => {

                  return (
                    <Grid sx={{ width: "20%" }} item>
                      {location.pathname.includes("pitchdeckintro") ?
                        <OutlinedInput
                          className="cus-cash-flow-row"
                          disabled={true}
                          startAdornment={
                            <InputAdornment position="start">
                              {financials.currency.split("")[0]}
                            </InputAdornment>
                          }
                          value={numberWithComma(col.value)}
                          type="text"
                        />
                        : col.isOutput && venture.userRole !== "Commenter" ?
                          <OutlinedInput
                            className="cus-cash-flow-row"
                            type="text"
                            value={numberWithComma(col.value)}
                            startAdornment={
                              <InputAdornment position="start">
                                {financials?.currency?.split("")[0]}
                              </InputAdornment>
                            }
                            onChange={e => {

                              handleChange(
                                e.target.value,
                                rowindex + 1,
                                colindex
                              );
                            
                            }}
                          />
                          :
                          <OutlinedInput
                            className="cus-cash-flow-row-disabled"
                            disabled={true}
                            startAdornment={
                              <InputAdornment position="start">
                                {financials?.currency?.split("")[0]}
                              </InputAdornment>
                            }
                            value={numberWithComma(col.value)}
                            type="text"
                          />
                      }
                    </Grid>
                  );
                
                })}
              </Grid>
            </Grid>
          );
        
        })}
        <Grid
          item
          alignItems="center"
          xs={12}
          sx={{
            color: "#393939"
          }}
        >
          <Box>
            <strong>Investing Activities</strong>
          </Box>
        </Grid>
        {table && table.length && table.slice(10, 18).map((row, rowindex) => {

          return (
            <Grid item>
              <Grid
                container
                direction="row"
                wrap="nowrap"
                justifyContent="space-between"
                alignItems="center"
              >
                {isedit && selectedindex === rowindex + 10 ?
                  <Box sx={{ width: "20%" }}>
                    <TextField
                      type="text"
                      sx={{
                        "& .MuiInputBase-input": {
                          fontSize: 11,
                          padding : "6px"
                        }
                      }}
                      onClick={e => {

                        e.stopPropagation();
                      
                      }}
                      value={row.rowLabel}
                      onChange={e => {

                        e.stopPropagation();
                        handleChangeRowHeaders(e.target.value, rowindex + 10);
                      
                      }}
                    />
                  </Box>
                  :
                  <Grid
                    sx={{
                      width      : "20%",
                      height     : "100%",
                      fontSize   : 12,
                      color      : "#393939",
                      wordBreak  : "break-all",
                      marginRight: "10px"
                    }}
                    item
                    alignItems="center"
                    // className={classes.cellRow}
                    onClick={e => {

                      e.stopPropagation();
                      if (
                        venture.userRole !== "Commenter" &&
                        !location.pathname.includes("pitchdeckintro")
                      ) {

                        setIsedit(true);
                        setSelectedindex(rowindex + 10);
                      
                      }
                    
                    }}
                  >
                    {row.rowLabel}
                    {row.rowName === "Net_Cash_Flow_from_Investing_Activities" &&
                      <HelpIconTooltip
                        tooltipdata={"Sum of all the above values in 'Investing Activities'"}
                        width="400px" />
                    }
                  </Grid>
                }
                {row.rowVal.map((col, colindex) => {

                  return (
                    <Grid sx={{ width: "20%" }} item >
                      {location.pathname.includes("pitchdeckintro") ?
                        <OutlinedInput
                          className="cus-cash-flow-row"
                          disabled={true}
                          startAdornment={
                            <InputAdornment position="start">
                              {financials.currency.split("")[0]}
                            </InputAdornment>
                          }
                          value={numberWithComma(col.value)}
                          type="text"
                        />
                        : col.isOutput && venture.userRole !== "Commenter" ?
                          <OutlinedInput
                            className="cus-cash-flow-row"
                            type="text"
                            startAdornment={
                              <InputAdornment position="start">
                                {financials.currency.split("")[0]}
                              </InputAdornment>
                            }
                            value={numberWithComma(col.value)}
                            onChange={e => {

                              handleChange(
                                e.target.value,
                                rowindex + 10,
                                colindex
                              );
                            
                            }}
                          />
                          :
                          <OutlinedInput
                            disabled={true}
                            className="cus-cash-flow-row-disabled"
                            startAdornment={
                              <InputAdornment position="start">
                                {financials.currency.split("")[0]}
                              </InputAdornment>
                            }
                            value={numberWithComma(col.value)}
                            type="text"
                          />
                      }
                    </Grid>
                  );
                
                })}
              </Grid>
            </Grid>
          );
        
        })}
        <Grid
          item
          alignItems="center"
          xs={12}
          sx={{
            color: "#393939"
          }}
        >
          <Box>
            <strong>Financing Activities</strong>
          </Box>
        </Grid>
        {table && table.length && table.slice(18, 27).map((row, rowindex) => {

          return (
            <Grid item>
              <Grid
                container
                direction="row"
                wrap="nowrap"
                justifyContent="space-between"
                alignItems="center"
              >
                {isedit && selectedindex === rowindex + 18 ?
                  <Box sx={{ width: "20%" }}>
                    <TextField
                      type="text"
                      sx={{
                        "& .MuiInputBase-input": {
                          fontSize: 11,
                          padding : "6px"
                        }
                      }}
                      onClick={e => {

                        e.stopPropagation();
                      
                      }}
                      value={row.rowLabel}
                      onChange={e => {

                        e.stopPropagation();
                        handleChangeRowHeaders(e.target.value, rowindex + 18);
                      
                      }}
                    />
                  </Box>
                  :
                  <Grid
                    sx={{
                      width      : "20%",
                      height     : "100%",
                      fontSize   : 12,
                      color      : "#393939",
                      wordBreak  : "break-all",
                      marginRight: "10px"
                    }}
                    item
                    alignItems="center"
                    // className={classes.cellRow}
                    onClick={e => {

                      e.stopPropagation();
                      if (
                        venture.userRole !== "Commenter" &&
                        !location.pathname.includes("pitchdeckintro")
                      ) {

                        setIsedit(true);
                        setSelectedindex(rowindex + 18);
                      
                      }
                    
                    }}
                  >
                    {row.rowLabel}
                    {row.rowName === "Net_Cash_Flow_from_Financing_Activities" &&
                      <HelpIconTooltip
                        tooltipdata={"Sum of all the above values in 'Financing Activities'"}
                        width="60%" />
                    }
                    {row.rowName === "Net_Cash_Flow_for_the_Year" &&
                      <HelpIconTooltip
                        tooltipdata={"Net Cash Flow for the Year = Cash Flow from Operations + Investing Activities + Financing Activities"}
                        width="60%" />
                    }
                    {row.rowName === "Total_Cash_Flow_for_the_Year" &&
                      <HelpIconTooltip
                        tooltipdata={"Net Cash Flow for the Year = Net Cash Flow for the Year + Cash at Beginning of Year"}
                        width="60%" />
                    }
                  </Grid>
                }
                {row.rowVal.map((col, colindex) => {

                  return (
                    <Grid item sx={{ width: "20%" }}>
                      {location.pathname.includes("pitchdeckintro") ?
                        <OutlinedInput
                          disabled={true}
                          className="cus-cash-flow-row-diss"
                          startAdornment={
                            <InputAdornment position="start">
                              {financials.currency.split("")[0]}
                            </InputAdornment>
                          }
                          value={numberWithComma(col.value)}
                          type="text"
                        />
                        : col.isOutput && venture.userRole !== "Commenter" ?
                          <OutlinedInput
                            type="text"
                            className="cus-cash-flow-row"
                            startAdornment={
                              <InputAdornment position="start">
                                {financials.currency.split("")[0]}
                              </InputAdornment>
                            }
                            value={numberWithComma(col.value)}
                            onChange={e => {

                              handleChange(
                                e.target.value,
                                rowindex + 18,
                                colindex
                              );
                            
                            }}
                          />
                          :
                          <OutlinedInput
                            className="cus-cash-flow-row-disabled"
                            disabled={true}
                            startAdornment={
                              <InputAdornment position="start">
                                {financials.currency.split("")[0]}
                              </InputAdornment>
                            }
                            value={numberWithComma(col.value)}
                            type="text"
                          />
                      }
                    </Grid>
                  );
                
                })}
              </Grid>
            </Grid>
          );
        
        })}
      </Grid>
    </div>
  );

};

export default CashFlowTable;
