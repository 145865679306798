/* eslint-disable react/react-in-jsx-scope */
import {
  FormControl, FormControlLabel, Grid, Radio, RadioGroup,
  Slider, Typography
} from "@mui/material";
// import React, { useState } from "react";
// import { useHistory } from "react-router-dom";
import ErrorMessage from "../../../../components/ErrorMessage";
import HelpIconTooltip from "../../../../components/HelpIconTooltip";

// import { makeStyles } from "@mui/styles";
// import BackNextButton from "../../../../components/BackNextButton";
import CustomSelect from "../../../../components/CustomSelect";
import styles from "./TargetSegments.module.css";


const Demographics1 = props => {

  // const history = useHistory();
  // const [Collapsecomment, setCollapsecomment] = useState(false);
  // const [dialogbox, setDialog] = useState(false);
  // const [ errormsg, setErrormsg ] = useState("");
  const errormsg = "";

  // const classes = useStyles();

  // let { venture } = useSelector((state) => {
  //   return { ...state };
  // });

  // const pathname = history.location.pathname.substring(1);
  // const guideicon = Data.gtmtargetpersonas;

  // const Dialogopen = () => {
  //   setDialog(true);
  // };
  // const Dialogclose = () => {
  //   setDialog(false);
  // };

  // const collapse_comment_function = () => {
  //   if (Collapsecomment) {
  //     setCollapsecomment(false);
  //   } else {
  //     setCollapsecomment(true);
  //   }
  // };
  // const educationArr = [
  //   { value: "", label: "None" },
  //   { value: "Bachelor's Degree", label: "Bachelor's Degree" },
  //   { value: "Professional Degree", label: "Professional Degree" },
  //   { value: "Master's Degree", label: "Master's Degree" },
  //   { value: "Doctorate", label: "Doctorate" },
  //   { value: "Other", label: "Other" },
  // ];

  const educationArr = [
    { value: "", label: "-None-" },
    { value: "Secondary or High School", label: "Secondary or High School" },
    { value: "Vocational qualification", label: "Vocational qualification" },
    { value: "Bachelor’s degree", label: "Bachelor’s degree" },
    { value: "Master’s degree", label: "Master’s degree" },
    { value: "Doctorate or higher", label: "Doctorate or higher" },
    { value: "Professional degree", label: "Professional degree" },
    { value: "Other", label: "Other" }
  ];

  // const sortedEducationArr = educationArr.sort((a, b) =>
  //   a.label > b.label ? 1 : -1
  // );

  // const occupationArr = [
  //   { value: "Senior Software engineer", label: "Senior Software engineer" },
  //   { value: "Professional Degree", label: "Professional Degree" },
  //   { value: "Master's Degree", label: "Master's Degree" },
  //   { value: "Doctorate", label: "Doctorate" },
  //   { value: "Other", label: "Other" },
  // ];

  const occupationArr = [
    { value: "Accountant", label: "Accountant" },
    { value: "Analyst", label: "Analyst" },
    { value: "Executive ", label: "Executive " },
    { value: "Manager", label: "Manager" },
    { value: "Investment Banker", label: "Investment Banker" },
    { value: "Clerk", label: "Clerk" },
    { value: "Officer", label: "Officer" },
    { value: "Homemaker", label: "Homemaker" },
    { value: "Planner", label: "Planner" },
    { value: "Architect", label: "Architect" },
    { value: "Engineer", label: "Engineer" },
    { value: "Teacher", label: "Teacher" },
    { value: "Lecturer", label: "Lecturer" },
    { value: "Professor ", label: "Professor " },
    { value: "Principal", label: "Principal" },
    { value: "Administrator", label: "Administrator" },
    { value: "Counselor", label: "Counselor" },
    { value: "Legal Attorney", label: "Legal Attorney" },
    { value: "Medical Practitioner", label: "Medical Practitioner" },
    { value: "Doctor", label: "Doctor" },
    { value: "Therapist", label: "Therapist" },
    { value: "Scientist", label: "Scientist" },
    { value: "Technical Assistant", label: "Technical Assistant" },
    { value: "Intern / Trainee", label: "Intern / Trainee" },
    { value: "Systems Analyst", label: "Systems Analyst" },
    { value: "Systems Engineer", label: "Systems Engineer" },
    { value: "Software Engineer", label: "Software Engineer" },
    { value: "Software Architect", label: "Software Architect" },
    { value: "Programmer Analyst", label: "Programmer Analyst" },
    { value: "Developer", label: "Developer" },
    { value: "Project Manager", label: "Project Manager" },
    { value: "Business Analyst", label: "Business Analyst" },
    { value: "Quality Assurance Analyst", label: "Quality Assurance Analyst" },
    { value: "Exec. & Management Leader", label: "Exec. & Management Leader" },
    { value: "CXO", label: "CXO" },
    { value: "President", label: "President" },
    {
      value: "Founder / Managing Partner",
      label: "Founder / Managing Partner"
    },
    { value: "Event Planner", label: "Event Planner" },
    { value: "Chef", label: "Chef" },
    { value: "General Manager", label: "General Manager" },
    { value: "Assistant / Worker", label: "Assistant / Worker" },
    { value: "Consultant", label: "Consultant" },
    { value: "Contractor", label: "Contractor" },
    { value: "Technician / Operator", label: "Technician / Operator" },
    { value: "Trainer / Tutor", label: "Trainer / Tutor" },
    { value: "Coach", label: "Coach" },
    {
      value: "Customer service / Help desk Executive",
      label: "Customer service / Help desk Executive"
    },
    { value: "Sales Executive", label: "Sales Executive" },
    { value: "Sales representative", label: "Sales representative" },
    { value: "Marketing Executive", label: "Marketing Executive" },
    { value: "Chauffeur / Driver", label: "Chauffeur / Driver" },
    { value: "Geologist", label: "Geologist" },
    { value: "Research Analyst", label: "Research Analyst" },
    { value: "Designer", label: "Designer" },
    { value: "Writer", label: "Writer" },
    { value: "Journalist", label: "Journalist" },
    { value: "Artist", label: "Artist" },
    { value: "Farmer & Grower", label: "Farmer & Grower" },
    { value: "Unemployed", label: "Unemployed" }
  ];

  const sortedoccupationArr = occupationArr.sort((a, b) =>
    a.label > b.label ? 1 : -1
  );

  const marks = [
    { value: 1, label: "<12" },
    { value: 2, label: "13-19" },
    { value: 3, label: "20-35" },
    { value: 4, label: "36-60" },
    { value: 5, label: ">60" }
  ];

  const handleAgeChange = event => {

    marks.map(mark => {

      if (mark.value === event.target.value) {

        props.handleChange("age", mark.label);
      
      }
    
    });
  
  };

  const handleAgeValue = value => {

    let newvalue;

    marks.map(mark => {

      if (mark.label === value) {

        newvalue = mark.value;
      
      }
    
    });
    
    return newvalue;
  
  };

  const details = props.details;

  return <Grid id={ styles.maingrid2 } style={ { position: "relative" } }>
    <div style={ { position: "absolute", top: "0%", left: "37%" } }>
      { errormsg && <ErrorMessage errormsg={ errormsg } /> }
    </div>
    <Grid container id={ styles.gridContainer }>
      <Grid item xs={ 12 } md={ 5 }>
        <Typography
          variant="body2"
          sx={ { color: errormsg ? "#FF0000" : "#393939" } }>
          {/* What is their gender predominantly?* */ }
              What is the predominant gender for this <br />
              persona?*
          <HelpIconTooltip
            tooltipdata={
              "This factor is usually more relevant for B2C products where you may see strong difference in user or buyer preferences across different genders.\nFor e.g. Majority of purchase decisions for children – toys, clothes, education etc. - are made by mothers/ women, vs. if you’re selling beard shaving products, the predominant gender you’d have to focus on are likely male.  "
            }
            width="400px"
          />
        </Typography>
      </Grid>
      <Grid item xs={ 12 } md={ 6 }>
        <FormControl error={ errormsg ? true : false }>
          <RadioGroup
            row
            aria-label="gender"
            defaultValue={ "Mostly Male" }
            name="gender"
            value={ details.gender }
            onChange={ e =>
              props.handleChange(e.target.name, e.target.value)
            }>
            <FormControlLabel
              value="Mostly male"
              control={ <Radio size="small" /> }
              label="Mostly male"
            />
            <FormControlLabel
              value="Mostly female"
              control={ <Radio size="small" /> }
              label="Mostly female"
            />
            <FormControlLabel
              value="Other"
              control={ <Radio size="small" /> }
              label="Other"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
    <Grid container id={ styles.gridContainer }>
      <Grid item xs={ 12 } md={ 5 }>
        <Typography variant="body2" sx={ { color: "#393939" } }>
              What is their age range?
          <HelpIconTooltip
            tooltipdata={
              "More applicable for B2C products where sometimes age may be relevant to how you position or message your product’s value, or what channels you use for reaching those users, etc. ​\nFor e.g. your older users may care less about customization features in your product,  may not be active on certain social media platforms, etc., while your young college grads audience may love gamification tactics, use Instagram more than LinkedIn, and so on."
            }
            width="400px"
          />
        </Typography>
      </Grid>
      <Grid item xs={ 12 } md={ 6 }>
        <Slider
          size="small"
          max={ 6 }
          defaultValue={ 3 }
          sx={ {
            "& .MuiSlider-rail": {
              backgroundColor: "grey !important"
            },
            "& .MuiSlider-mark": {
              backgroundColor: "grey !important",
              height         : "15px",
              width          : "1px"
            }
          } }
          onChange={ handleAgeChange }
          step={ null }
          marks={ marks }
          track={ false }
          value={ handleAgeValue(details.age) }
        />
      </Grid>
    </Grid>
    <Grid container id={ styles.gridContainer }>
      <Grid item xs={ 12 } md={ 5 }>
        <Typography variant="body2" sx={ { color: "#393939" } }>
              What is their educational qualification?
          <HelpIconTooltip
            tooltipdata={
              "Most applicable for B2C products or solutions to categorize and position based on education level of the persona. \nFor e.g. An e-learning portal catering to tech professionals may need to push different offerings on different channels and using different positioning/ messaging when marketing to professionals with advanced or multiple degrees vs. those with a single college degree. "
            }
            width="400px"
          />
        </Typography>
      </Grid>
      <Grid item xs={ 12 } md={ 6 }>
        {/* <Select
              options={educationArr}
              placeholder="Select educational qualification"
              value={
                details.qualification && {
                  value: details.qualification,
                  label: details.qualification,
                }
              }
              onChange={(e) => {
                props.handleChange("qualification", e.value);
              }}
            /> */}
        <CustomSelect
          options={ educationArr }
          placeholder="Select educational qualification"
          value={ details.qualification }
          handleChange={ e => {

            props.handleChange("qualification", e.value);
            
          } }
        />
      </Grid>
    </Grid>
    <Grid container id={ styles.gridContainer }>
      <Grid item xs={ 12 } md={ 5 }>
        <Typography variant="body2" sx={ { color: "#393939" } }>
              What is their occupation/title?
          <HelpIconTooltip
            tooltipdata={
              "Most applicable for B2B products or solutions to customize positioning based on occupation type of the persona. \nFor e.g. An e-learning portal catering to tech professionals will need to customize it’s messaging to freshers looking for learning new hard skills and salary growth, differently from advanced career professionals who may be looking for intellectual growth and leadership coaching. "
            }
            width="400px"
          />
        </Typography>
      </Grid>
      <Grid item xs={ 12 } md={ 6 }>
        {/* <Select
              options={[
                { value: "", label: "-None-" },
                ...sortedoccupationArr,
                { value: "Other", label: "Other" },
              ]}
              placeholder="Select occupation"
              maxMenuHeight={125}
              value={
                details.occupation && {
                  value: details.occupation,
                  label: details.occupation,
                }
              }
              onChange={(e) => {
                props.handleChange("occupation", e.value);
              }}
            /> */}
        <CustomSelect
          options={ [
            { value: "", label: "-None-" },
            ...sortedoccupationArr,
            { value: "Other", label: "Other" }
          ] }
          placeholder="Select occupation"
          value={ details.occupation }
          handleChange={ e => {

            props.handleChange("occupation", e.value);
            
          } }
          maxMenuHeight={ 125 }
        />
      </Grid>
    </Grid>
    <Grid container id={ styles.gridContainer }>
      <Typography sx={ { fontSize: "11px", fontStyle: "italic" } }>
          *Answer all questions that could be relevant for your product or
          brand. It’s ok to skip questions you can’t answer at the moment. As
          you learn more about your target users, return to these pages to
          further develop the persona.
      </Typography>
    </Grid>
  </Grid>;

};

export default Demographics1;
