import React from "react";
import {
  Box, Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";

import ImageIcon from "@mui/icons-material/Image";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import styles from "./IndividualInvitedBox.module.css";
import EyeSlashIcon from "../../Assets/icons/eye-slash-solid.svg";
import EyeIcon from "../../Assets/icons/eye-solid.svg";

const LightTooltip = styled(({ className, ...props }) =>
  <Tooltip {...props} classes={{ popper: className }} />
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    width          : "250px",
    boxShadow      : theme.shadows[1],
    fontSize       : "11px",
    color          : "#FF7F00",
    border         : "1px solid #FF7F00",
    paddingTop     : "5px 10px",
    borderRadius   : "10px",
    fontWeight     : "bold"
  }
}));

const IndividualInvitedBox = props => {

  const sharedventure = props?.sharedventure;
  const inviteDate = new Date(sharedventure.inviteSentAt);
  const formattedDateString = `${inviteDate.getDate()} ${new Intl.DateTimeFormat("en-US", { month: "short" }).format(inviteDate)} ${inviteDate.getFullYear()} ${inviteDate.toLocaleString("en-US", { hour: "numeric", minute: "numeric", second: "numeric", hour12: true }).replace(",", "")}`;

  return sharedventure?.venture?.parentUser?.access === "freetier" ?
    <LightTooltip
      placement="right"
      title={"You no longer have access to this project. Please contact the project owner."}
      PopperProps={{
        modifiers: [
          {
            name   : "offset",
            options: {
              offset: [ -10, -100 ]
            }
          }
        ]
      }}
    >
      <Box
        key={props.key}
        id={styles.invitedMainBox}
        sx={{ cursor: "default" }}
      >
        <Box id={styles.headBox}>
          <Box sx={{ marginLeft: "5px" }}>
            <Typography id={styles.nameTypo}>
              Shared by {sharedventure?.venture.parentUser.firstname}
              {sharedventure?.venture.parentUser.lastname}
            </Typography>
            <Typography id={styles.nameTypo}>
              {formattedDateString}
            </Typography>
          </Box>
          <Box>
            {sharedventure?.collaboratorRole === "Commenter" ?
              <Box id={styles.isCommenterBox}>
                <Typography id={styles.commenterTypo}>Mentor</Typography>
              </Box>
              :
              <Box id={styles.editorBox}>
                <Typography
                  sx={{
                    fontSize: "10px",
                    // padding: "2px 0px 0px 0px",

                    color: "white"
                  }}
                >
                  Cofounder
                </Typography>
              </Box>
            }
          </Box>
        </Box>

        {/* image box */}
        <Box id={styles.imageMainBox}>
          {sharedventure?.venture.details.logo ?
            // <Box id={styles.imgBox}>
            <img
              id={styles.sharedVenturelogo}
              alt=""
              style={{
                width       : "50%",
                height      : "76%",
                // border: "1px solid #e1e2e9",
                borderRadius: "50%"
              }}
              src={sharedventure?.venture.details.logo}
            ></img>
            :
            // </Box>
            <Box id={styles.imgBox}>
              <ImageIcon
                id={styles.imgIcon}
              // sx={{ fontSize: "35px", color: "gray" }}
              />
            </Box>
          }
        </Box>
        {/* ---------- */}
        <Box sx={{ borderBottom: "1px solid #e1e2e9", marginTop: "3%" }}></Box>

        <Box id={styles.ventureBox}>
          <Box>
            <Typography
              id={styles.ventureTypo}
            // sx={{ fontSize: "14px", color: "#5F5E5E", fontWeight: "bold" }}
            >
              {sharedventure?.venture.details.name}
            </Typography>

            {/* new box */}
            {sharedventure?.isNewventure ?
              <Box id={styles.newBox}>
                <Typography sx={{ fontSize: "11px", color: "white" }}>
                  New
                </Typography>
              </Box>
              :
              <></>
            }
          </Box>

          {/* ---------- */}
          <Box></Box>
        </Box>
      </Box>
    </LightTooltip> : <>

      {props.isFromHideDialog ? <>
        <Box
          id={styles.invitedMainBox}
          sx={{ cursor: "pointer" }}
          onClick={e => {

            props.handleSharedVenturesChange(e, sharedventure?.venture._id);
          
          }}
        >
          <Box id={styles.headBox}>
            <Box sx={{ marginLeft: "5px" }}>
              <Typography id={styles.nameTypo}>
                {`Shared by ${sharedventure?.venture?.parentUser?.firstname} ${sharedventure?.venture?.parentUser?.lastname}`}
              </Typography>
              <Typography id={styles.nameTypo}>
                {formattedDateString}
              </Typography>
            </Box>
            <Box>
              {sharedventure?.collaboratorRole === "Commenter" ?
                <Box id={styles.isCommenterBox}>
                  <Typography id={styles.commenterTypo}>Mentor</Typography>
                </Box>
                :
                <Box id={styles.editorBox}>
                  <Typography
                    sx={{
                      fontSize: "10px",
                      color   : "white"
                    }}
                  >
                    Cofounder
                  </Typography>
                </Box>
              }
            </Box>
          </Box>

          {/* image box */}
          <Box id={styles.imageMainBox}>
            {sharedventure?.venture.details.logo ?
              // <Box id={styles.imgBox}>
              <img
                id={styles.sharedVenturelogo}
                alt=""
                src={sharedventure?.venture.details.logo}
              ></img>
              :
              // </Box>
              <Box id={styles.imgBox}>
                <ImageIcon
                  id={styles.imgIcon}
                />
              </Box>
            }
          </Box>
          {/* ---------- */}
          <Box sx={{ borderBottom: "1px solid #e1e2e9", marginTop: "3%" }}></Box>

          <Box id={styles.ventureBox}>
            <div className="tribe-invite-card-name-con">
              <Box>
                <Typography
                  id={styles.ventureTypo}
                >
                  {sharedventure?.venture.details.name}
                </Typography>

                {/* new box */}
                {sharedventure?.isNewventure ?
                  <Box id={styles.newBox}>
                    <Typography sx={{ fontSize: "11px", color: "white" }}>
                      New
                    </Typography>
                  </Box>
                  :
                  <></>
                }

              </Box>
              <div className="tribe-invite-hide-icon-con">
                <Tooltip title={`Click here to show the ${sharedventure?.venture.details.name} in the shared venture list`} placement="bottom">
                  <img onClick={e => {

                    e.stopPropagation();
                    // props.handleHideVenture(false, sharedventure._id)
                    // props.setIsShowVentureDialogOpen(false);
                    props.setIsDialogOpen(true);
                    props.setCurrentSharedVentureDetails(
                      {
                        isHide                 : false,
                        selectedSharedVentureId: sharedventure._id,
                        currentVentureName     : sharedventure?.venture.details.name,
                        sharedBy               : `${sharedventure?.venture?.parentUser?.firstname} ${sharedventure?.venture?.parentUser?.lastname}`
                      });
                  
                  }
                  }
                  className="tribe-invite-hide-icon"
                  alt=""
                  src={EyeIcon}
                  />
                </Tooltip>
              </div>
            </div>

            {/* ---------- */}
            <Box></Box>
          </Box>
        </Box>
      </> : <>
        <Box
          id={styles.invitedMainBox}
          sx={sharedventure?.isHide ? { pointerEvents: "none", background: "#dfdada" } : { cursor: "pointer" }}
          onClick={e => {

            props.handleSharedVenturesChange(e, sharedventure?.venture._id);
          
          }}
        >
          <Box id={styles.headBox}>
            <Box sx={{ marginLeft: "5px" }}>
              <Typography id={styles.nameTypo}>
                {`Shared by ${sharedventure?.venture?.parentUser?.firstname} ${sharedventure?.venture?.parentUser?.lastname}`}
              </Typography>
              <Typography id={styles.nameTypo}>
                {formattedDateString}
              </Typography>
            </Box>
            <Box>
              {sharedventure?.collaboratorRole === "Commenter" ?
                <Box id={styles.isCommenterBox}>
                  <Typography id={styles.commenterTypo}>Mentor</Typography>
                </Box>
                :
                <Box id={styles.editorBox}>
                  <Typography
                    sx={{
                      fontSize: "10px",
                      // padding: "2px 0px 0px 0px",

                      color: "white"
                    }}
                  >
                    Cofounder
                  </Typography>
                </Box>
              }
            </Box>
          </Box>

          {/* image box */}
          <Box id={styles.imageMainBox}>
            {sharedventure?.venture.details.logo ?
              <img
                id={styles.sharedVenturelogo}
                alt=""
                src={sharedventure?.venture.details.logo}
              ></img>
              :
              <Box id={styles.imgBox}>
                <ImageIcon
                  id={styles.imgIcon}
                />
              </Box>
            }
          </Box>
          {/* ---------- */}
          <Box sx={{ borderBottom: "1px solid #e1e2e9", marginTop: "3%" }}></Box>

          <Box id={styles.ventureBox}>
            <div className="tribe-invite-card-name-con">
              <Box>
                <Typography
                  id={styles.ventureTypo}
                >
                  {sharedventure?.venture.details.name}
                </Typography>

                {/* new box */}
                {sharedventure?.isNewventure ?
                  <Box id={styles.newBox}>
                    <Typography sx={{ fontSize: "11px", color: "white" }}>
                      New
                    </Typography>
                  </Box>
                  :
                  <></>
                }

              </Box>
              <div className="tribe-invite-hide-icon-con">
                <Tooltip title={`Click here to hide the ${sharedventure?.venture.details.name} in the shared venture list`} placement="bottom">
                  <img onClick={e => {

                    e.stopPropagation();
                    props.setIsDialogOpen(true);
                    props.setCurrentSharedVentureDetails(
                      {
                        isHide                 : true,
                        selectedSharedVentureId: sharedventure._id,
                        currentVentureName     : sharedventure?.venture.details.name,
                        sharedBy               : `${sharedventure?.venture?.parentUser?.firstname} ${sharedventure?.venture?.parentUser?.lastname}`
                      });
                  
                  }
                  }
                  className="tribe-invite-hide-icon"
                  alt=""
                  src={EyeSlashIcon}
                  />
                </Tooltip>
              </div>
            </div>

            {/* ---------- */}
            <Box></Box>
          </Box>
        </Box></>}
    </>

  ;

};

export default IndividualInvitedBox;
