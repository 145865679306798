import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box, Button,
  Dialog, DialogContent, IconButton, Tooltip, Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import HelpIconTooltip from "../components/HelpIconTooltip";

// import { makeStyles } from "@mui/styles";

const LightTooltip = styled(({ className, ...props }) =>
  <Tooltip { ...props } classes={ { popper: className } } />
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    // color: "rgba(0, 0, 0, 0.87)",
    boxShadow      : theme.shadows[1],
    fontSize       : "14px",
    color          : "#FF7F00",
    border         : "1px solid #FF7F00",
    paddingTop     : "5px 10px",
    borderRadius   : "10px",
    fontWeight     : "bold"
  }
}));

// const useStyles = makeStyles(theme => ({
//   customWidth: {
//     maxWidth       : 500,
//     fontSize       : 10,
//     backgroundColor: "white",
//     color          : "#707070",
//     boxShadow      : "0px 3px 6px #00000029",
//     border         : "1px solid #707070",
//     borderRadius   : 10
//   }
// }));

const IndividualSectionHeaders = props => {

  const [ isSuccess, setIssuccess ] = useState(false);
  // const classes = useStyles();
  const history = useHistory();
  const content = props.content;
  const pathname = history.location.pathname.substring(1);

  const { venture } = useSelector(state => ({ ...state }));

  return (
    <>
      <Box
        sx={ {
          display       : "flex",
          justifyContent: "space-between"
        } }
      >
        <Box sx={ { display: "flex", alignItems: "center" } }>
          {/* ------------- */ }

          { props.isGtmChevron === "gtmV" ?
            <>
              <Typography
                sx={ { fontWeight: "bold", fontSize: "20px", color: "#5F5E5E" } }
              >
                GTM
              </Typography>
              <ChevronRightIcon sx={ { color: "#707070", margin: "0px 10px" } } />
            </>
            : props.isGtmChevron === "none" ?
              <></>
              : props.isGtmChevron === "customer" ?
                <>
                  <Typography
                    sx={ { fontWeight: "bold", fontSize: "20px", color: "#5F5E5E" } }
                  >Customer
                  </Typography>
                  <ChevronRightIcon sx={ { color: "#707070", margin: "0px 10px" } } />
                </>
                :
                <></>
          }
          {/* --------------- */ }
          <Typography
            // sx={{ fontWeight: "bold", fontSize: "20px", color: "#5F5E5E" }}
            sx={ { fontWeight: "bold", fontSize: "20px", color: "#5F5E5E" } }
          >
            { props.mainheading }
          </Typography>
          { props.mainheading ?
            <ChevronRightIcon sx={ { color: "#707070", margin: "0px 10px" } } />
            :
            <></>
          }
          <Typography sx={ { fontSize: "16px", color: "#5F5E5E" } }>
            { props.subheading }
          </Typography>
        </Box>
        <Box sx={ { display: "flex", alignItems: "center" } }>
          { props.isGtm ?
            <Typography
              sx={ {
                color         : "#071AFF",
                textDecoration: "underline",
                marginRight   : "1vw"
              } }
            >
              <Button
                onClick={ () => {

                  history.push("/gotomarketcanvas");
                
                } }
                sx={ {
                  textTransform: "unset",
                  fontWeight   : "bold",
                  color        : "#071AFF"
                } }
              >
                GTM Canvas
              </Button>
            </Typography>
            : props.isFinance ?
              <Typography
                sx={ {
                  color         : "#071AFF",
                  textDecoration: "underline",
                  marginRight   : "1vw"
                } }
              >
                <Button
                  onClick={ () => {

                    history.push("/insightsdashboard");
                  
                  } }
                  sx={ {
                    textTransform: "unset",
                    fontWeight   : "bold",
                    color        : "#071AFF"
                  } }
                >
                Insights Dashboard
                </Button>
              </Typography>
              : <></> }

          { props.isSaveButton &&
            <Box sx={ { marginRight: "10px", position: "relative" } }>
              { isSuccess &&
                <Box sx={ { position: "absolute", top: "32px", left: "10px" } }>
                  <Typography sx={ { color: "#08910B", fontSize: "14px" } }>
                    Saved
                  </Typography>
                </Box>
              }
              <Button
                variant="contained"
                color="primary"
                sx={ { padding: "3px 12px", textTransform: "capitalize" } }
                onClick={ async () => {

                  setIssuccess(true);
                  await props.handleSave();
                  setTimeout(() => {

                    setIssuccess(false);
                  
                  }, 3000);

                } }
                disabled={ venture.userRole === "Commenter" || props.isSaveBtnDisable }
              >
                Save
              </Button>
            </Box>
          }

          { props.notGuide ?
            <></>
            :
            <>
              {/* <Tooltip title="View Guide">
                <img
                  src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/post_add-24px.png`}
                  style={{
                    marginRight: "1vw",
                    cursor: "pointer",
                  }}
                  onClick={props.Dialogopen}
                />
              </Tooltip> */}
              { pathname.includes("milestones") ||
                pathname.includes("team") ||
                pathname.includes("businessoperations") ?
                <LightTooltip placement="top" title="Coming soon">
                  <img
                    src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/post_add-24px.png` }
                    alt=""
                    style={ {
                      marginRight: "1vw",
                      cursor     : "pointer"
                    } }
                  // onClick={props.Dialogopen}
                  />
                </LightTooltip>
                :
                <Tooltip title="View Guide">
                  <img
                    src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/post_add-24px.png` }
                    alt=""
                    style={ {
                      marginRight: "1vw",
                      cursor     : "pointer"
                    } }
                    onClick={ props.Dialogopen }
                  />
                </Tooltip>
              }
              <Dialog
                open={ props.dialogbox }
                PaperProps={ {
                  style: {
                    borderRadius  : "20px",
                    backdropFilter: "blur(45px)",
                    background    : "initial",
                    boxShadow     : "0px 3px 6px #00000029",
                    border        : "1px solid #707070"
                  }
                } }
                BackdropProps={ { invisible: true } }
                fullWidth
                maxWidth="md"
              >
                <Box sx={ { alignSelf: "flex-end" } }>
                  <IconButton component="span" onClick={ props.Dialogclose }>
                    <HighlightOffIcon />
                  </IconButton>
                </Box>

                <DialogContent>
                  { content.dialogpurposetitle ?
                    <Typography fontWeight="900">
                      { content.dialogpurposetitle }
                    </Typography>
                    :
                    <Typography fontWeight="900">
                      What are we doing here? Why? What's the Purpose?
                    </Typography>
                  }

                  <Typography sx={ { whiteSpace: "pre-wrap" } } gutterBottom>
                    { content.dialogpurpose }
                  </Typography>
                  { content.dialoganalogytitle ?
                    <Typography
                      sx={ { whiteSpace: "pre-wrap" } }
                      fontWeight="900"
                    >
                      { content.dialoganalogytitle }
                    </Typography>
                    :
                    <Typography fontWeight="900">Here's an analogy</Typography>
                  }

                  <Typography sx={ { whiteSpace: "pre-wrap" } } gutterBottom>
                    { content.dialoganalogy }
                  </Typography>
                  { content.dialogguide.length > 0 &&
                    <>
                      <Typography fontWeight="900">Guide</Typography>
                      <Typography>
                        {pathname.includes("cashflow") || pathname.includes("balancesheet") || pathname.includes("insightsdashboard") ? "" : "Before you begin updating the canvas below here are some steps to follow:"}

                      </Typography>
                      { content.dialogguide.map(guide => {

                        return <Typography>{ guide }</Typography>;
                      
                      }) }
                    </>
                  }
                </DialogContent>
              </Dialog>
            </>
          }

          { props.isexpand ?
            <></>
            :
            <img
              src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/Icon awesome-comment-dots.png` }
              alt=""
              style={ {
                cursor: "pointer"
              } }
              onClick={ () => props.collapse_comment_function() }
            />
          }
        </Box>
      </Box>
      <Box
        sx={ {
          display       : "flex",
          justifyContent: "space-between",
          marginTop     : "5px",
          marginBottom  : props.marginBottom ? props.marginBottom : "20px"
        } }
      >
        <Box>
          <Typography
            variant="body2"
            sx={ { fontSize: "12px", whiteSpace: "pre-wrap" } }
          >
            { props.headingcontent }
            { props.tooltip &&
              <HelpIconTooltip
                width={ props.tooltipWidth }
                tooltipdata={ props.tooltip }
              />
            }

            <span style={ { fontWeight: "bold" } }>
              { props.headingBoldContent }
            </span>
          </Typography>
          { props.seconddescription &&
            <Typography sx={ { fontSize: "10px" } }>
              { props.seconddescription }
            </Typography>
          }
        </Box>
        { props.isShare &&
          <Box sx={ { display: "flex", alignItems: "center" } }>
            <Button
              variant="outlined"
              size="small"
              sx={ {
                textTransform: "capitalize",
                color        : "#071AFF",
                borderColor  : "#071AFF"
              } }
            >
              Share
            </Button>
          </Box>
        }
      </Box>
    </>
  );

};

export default IndividualSectionHeaders;
