import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  Box, Dialog, DialogContent, Grid, IconButton, Typography
} from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import CustomMultilineTextfield from "../../../components/CustomMultilineTextfield";
import DeleteCancel from "../../../components/DeleteCancel";
import HelpIconTooltip from "../../../components/HelpIconTooltip";
import styles from "./Solution.module.css";

const DefensibilityAddRows = props => {

  const [ deletedialogbox, setDeleteDialog ] = useState(false);
  const detail = props.detail;
  const index = props.index;
  const isdelete = props.isdelete;

  const deleteDialogopen = () => {

    setDeleteDialog(true);
  
  };
  const deleteDialogclose = () => {

    setDeleteDialog(false);
  
  };

  const location = useLocation();

  return (
    <Box sx={ { display: "flex", alignItems: "center" } }>
      { isdelete &&
        <Box>
          <IconButton
            sx={ { padding: "0px", marginBottom: "16px", marginRight: "10px" } }
            onClick={ e => {

              e.stopPropagation();
              deleteDialogopen();
            
            } }
          >
            <RemoveCircleIcon
              fontSize="small"
              sx={ {
                color: "#ff7f5c"
              } }
            />
          </IconButton>
          <Dialog
            open={ deletedialogbox }
            PaperProps={ {
              style: {
                borderRadius  : "20px",
                backdropFilter: "blur(45px)",
                background    : "#FFFFFF 0% 0% no-repeat padding-box",
                boxShadow     : "0px 3px 6px #00000029",
                border        : "1px solid #E0E1E2"
              }
            } }
          >
            <DialogContent>
              <DeleteCancel
                name={ "Are you sure you want to delete this strategy?" }
                deleteClick={ async e => {

                  e.stopPropagation();
                  await props.removeRow(index);
                  deleteDialogclose();
                
                } }
                cancelClick={ () => {

                  deleteDialogclose();
                
                } }
              />
            </DialogContent>
          </Dialog>
        </Box>
      }
      <Grid container columnGap={ 5 } mb={ 2 }>
        <Grid item md={ 3 } id={ styles.otherrow }>
          <Typography id={ styles.defensibilityname }>
            { detail.key }
            { detail.tooltipdata &&
              <HelpIconTooltip
                tooltipdata={ detail.tooltipdata }
                width={ "700px" }
              />
            }
          </Typography>
        </Grid>
        <Grid item md={ 8 }>
          {/* <TextField
            type="text"
            multiline
            maxRows={2}
            minRows={2}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-input.MuiInputBase-input": {
                fontSize: "14px",
              },
            }}
            value={detail.value}
            onChange={(e) => {
              props.handleChange(e.target.value, index);
            }}
          /> */}
          <CustomMultilineTextfield
            width={ "100%" }
            // fontSize={"14px"}
            maxRows={ 2 }
            minRows={ 2 }
            value={ detail.value }
            handledetailsChange={ e => {

              props.handleChange(e.target.value, index);
            
            } }
            disabled={ location.pathname.includes("pitchdeckintro") }
          />
        </Grid>
      </Grid>
    </Box>
  );

};

export default DefensibilityAddRows;
