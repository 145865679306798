import axios from "./axiosService";

// import axios from "axios";

export const getPlans = async () => {

  return await axios.get(`${process.env.REACT_APP_ADDRESS}/fetch_plans`);

};

export const createSubscription = async (
  details,
  standardPlanId,
  userEmail,
  status,
  userId,
  offerCode
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/create_subscription`,
    {
      details,
      standardPlanId,
      userEmail,
      status,
      userId,
      offerCode
    }
  );

};

export const verifyPayment = async requestOpt => {

  return await axios.post(`${process.env.REACT_APP_ADDRESS}/verify_payment`, {
    requestOpt
  });

};

export const getSubscriptionById = async subscriptionId => {

  return await axios.get(
    `${process.env.REACT_APP_ADDRESS}/fetch_subscription/${subscriptionId}`
  );

};

export const cancelSubscription = async (userId, status) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/cancel_subscription`,
    {
      userId,
      status
    }
  );

};

export const updateUserAccess = async userId => {

  return await axios.get(
    `${process.env.REACT_APP_ADDRESS}/update_user_access/${userId}`
  );

};

export const getSubscriptionsByUserId = async userId => {

  return await axios.get(
    `${process.env.REACT_APP_ADDRESS}/fetch_subscriptions/${userId}`
  );

};

export const getInvoicesByUserId = async userId => {

  return await axios.get(
    `${process.env.REACT_APP_ADDRESS}/fetch_invoices/${userId}`
  );

};

export const getRefundsByUserId = async userId => {

  return await axios.get(
    `${process.env.REACT_APP_ADDRESS}/fetch_refunds/${userId}`
  );

};

export const validateCoupon = async (standardPlanId, offerCode, userId) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/validata_coupon`,
    {
      standardPlanId, offerCode, userId
    }
  );

};
