/* eslint-disable camelcase */
import {
  Grid, Typography
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BackNextButton from "../../../../components/BackNextButton";
import CustomMultilineTextfield from "../../../../components/CustomMultilineTextfield";
import IndividualSectionHeaders from "../../../../components/IndividualSectionHeaders";
import ModuleSections from "../../../../components/ModuleSections";
import Data from "../../../../Data/Gtm.json";
import { getGtm, updateGtmPositioningValues } from "../../../../services/gtm";


import styles from "./Positioning.module.css";

import { makeStyles } from "@mui/styles";
import HelpIconTooltip from "../../../../components/HelpIconTooltip";

import { useUnmount } from "../../../../components/useUnmount";
import {
  createGtmComment,
  deleteGtmComment,
  updateGtmComment
} from "../../../../services/comment";

const useStyles = makeStyles(() => ({
  customWidth: {
    maxWidth       : 200,
    fontSize       : 10,
    backgroundColor: "white",
    color          : "#707070",
    boxShadow      : "0px 3px 6px #00000029",
    border         : "1px solid #707070",
    borderRadius   : 10,
    whiteSpace     : "pre-wrap"
  },
  headingtypography: {
    textAlign : "center",
    color     : "#393939",
    fontWeight: "bold",
    margin    : "1em 0px"
  },
  multifield: {
    width                  : "100%",
    "& .MuiInputBase-input": {
      fontSize: "12px"
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px"
    }
  },
  helpicon: {
    color   : "#071AFF",
    fontSize: "15px"
  },
  doublechevronicon: {
    position: "absolute",
    top     : "50%",
    width   : "30px",
    left    : "100%"
  },
  firstgriditem: {
    position: "relative"
  }
}));

const BeforeAfterState = () => {

  const [ details, setDetails ] = useState({
    beforeState             : "",
    negativeConsequences    : "",
    afterState              : "",
    positiveBusinessOutcomes: ""
  });
  const [ comments, setComments ] = useState([]);
  const [ dialogbox, setDialog ] = useState(false);
  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  const content = Data.gtmpositioning;
  const data = Data.gtmpositioningbeforeafter;
  const [ isDataLoading, setIsDataLoading ] = useState(true);

  let { user, venture } = useSelector(state => {

    return { ...state };
  
  });

  useEffect(() => {

    fetchGtm();
  
  }, []);

  const fetchGtm = async () => {

    setIsDataLoading(true);

    try {

      const response = await getGtm(venture.gtm);
      // console.log(response.data);

      setDetails(response.data.positioning.beforeAfterState);
      setComments(response.data.comments.positioning.heroTransformation);
      setIsDataLoading(false);
    
    } catch (error) {

      console.error(error);
      setIsDataLoading(false);
    
    }
  
  };

  // To get the updated usestate value to save in useEffect cleanup
  const detailsRef = useRef(details);

  detailsRef.current = details;

  let isSubmitting = false;

  // useUnmount => custom hook to save while navigating
  useUnmount(() => {

    if (isDataLoading == false && !isSubmitting) {

      handleSave();
    
    }
  
  });

  const handleSave = async () => {

    try {

      await updateGtmPositioningValues(
        venture.gtm,
        "beforeAfterState",
        detailsRef.current
      );
      
      return true;
    
    } catch (error) {

      console.error(error);
      
      return false;
    
    }
  
  };

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);
    
    } else {

      setCollapsecomment(true);
    
    }
  
  };

  const handleChange = e => {

    setDetails(values => ({ ...values, [e.target.name]: e.target.value }));
  
  };

  const handleSubmit = async () => {

    try {

      isSubmitting = true;
      if(isDataLoading == false) {

        await updateGtmPositioningValues(
          venture.gtm,
          "beforeAfterState",
          details
        );

      }
      history.push("/gtmpositioningresearch");
      isSubmitting = false;
    
    } catch (error) {

      isSubmitting = false;
      console.error(error);
    
    }
  
  };
  const pathname = history.location.pathname.substring(1);

  const addComment = async (commentText, replyTo) => {

    try {

      const response = await createGtmComment(
        commentText,
        user._id,
        "positioning",
        "heroTransformation",
        venture.gtm,
        replyTo,
        window.location.href,
        "GTM > Positioning > ‘Hero Transformation’ Story"
      );

      setComments(response.data.comments.positioning.heroTransformation);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateComment = async (commentid, commentText) => {

    try {

      const response = await updateGtmComment(
        commentText,
        user._id,
        commentid,
        venture._id,
        window.location.href,
        "GTM > Positioning > ‘Hero Transformation’ Story"
      );

      setComments(response.data.comments.positioning.heroTransformation);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const deleteComment = async commentid => {

    try {

      const response = await deleteGtmComment(
        "positioning",
        "heroTransformation",
        venture.gtm,
        commentid,
        user._id,
        window.location.href,
        "GTM > Positioning > ‘Hero Transformation’ Story"
      );

      setComments(response.data.comments.positioning.heroTransformation);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  return (
    <ModuleSections
      Collapsecomment={ Collapsecomment }
      comments={ comments }
      addComment={ addComment }
      deleteComment={ deleteComment }
      updateComment={ updateComment }>
      <div className="box-display-style-for-next-and-back-button-with-justify">
        <div className="box-indiv-header-con">
          <IndividualSectionHeaders
            mainheading={ "Positioning" }
            subheading={ "‘Hero Transformation’ Story" }
            // subheading={"Before-After state"}
            headingcontent={ "Review your hero’s transformation story" }
            isGtm={ true }
            Dialogopen={ Dialogopen }
            Dialogclose={ Dialogclose }
            dialogbox={ dialogbox }
            collapse_comment_function={ collapse_comment_function }
            content={ content }
            // isGtmChevron={true}
            isGtmChevron={ pathname.includes("gtm") ? "gtmV" : "customer" }
            isSaveButton={ true }
            handleSave={ handleSave }
          />
        </div>
        <Grid item xs={ 12 } id={ styles.maingrid }>
          <Grid container columnGap={ 4 } mb={ 2 } justifyContent="center">
            <Grid item md={ 5 } className={ classes.firstgriditem }>
              <Typography className={ classes.headingtypography }>
                { data.beforestate.heading }

                <HelpIconTooltip tooltipdata={ data.beforestate.tooltip } />
              </Typography>
              <CustomMultilineTextfield
                width="100%"
                borderRadius="5px"
                // fontSize="12px"
                maxRows={ 4 }
                minRows={ 4 }
                value={ details[data.beforestate.name] }
                name={ data.beforestate.name }
                handledetailsChange={ handleChange }
              // placeholder={data.beforestate.placeholder}
              />
              <img
                src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/Icon feather-chevrons-right.jpg` }
                alt="double-chevron"
                className={ classes.doublechevronicon }
              />
            </Grid>
            <Grid item md={ 5 }>
              <Typography className={ classes.headingtypography }>
                { data.negativeconsequences.heading }

                <HelpIconTooltip
                  tooltipdata={ data.negativeconsequences.tooltip }
                />
              </Typography>
              <CustomMultilineTextfield
                width="100%"
                borderRadius="5px"
                // fontSize="12px"
                maxRows={ 4 }
                minRows={ 4 }
                value={ details[data.negativeconsequences.name] }
                name={ data.negativeconsequences.name }
                handledetailsChange={ handleChange }
              // placeholder={data.negativeconsequences.placeholder}
              />
            </Grid>
          </Grid>
          <Grid container columnGap={ 4 } mb={ 2 } justifyContent="center">
            <Grid item md={ 5 } className={ classes.firstgriditem }>
              <Typography className={ classes.headingtypography }>
                { data.afterstate.heading }

                <HelpIconTooltip tooltipdata={ data.afterstate.tooltip } />
              </Typography>
              <CustomMultilineTextfield
                width="100%"
                borderRadius="5px"
                // fontSize="12px"
                maxRows={ 4 }
                minRows={ 4 }
                value={ details[data.afterstate.name] }
                name={ data.afterstate.name }
                handledetailsChange={ handleChange }
              // placeholder={data.afterstate.placeholder}
              />
              <img
                src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/Icon feather-chevrons-right.jpg` }
                alt="double-chevron"
                className={ classes.doublechevronicon }
              />
            </Grid>
            <Grid item md={ 5 }>
              <Typography className={ classes.headingtypography }>
                { data.positivebusinessoutcomes.heading }

                <HelpIconTooltip
                  tooltipdata={ data.positivebusinessoutcomes.tooltip }
                />
              </Typography>
              <CustomMultilineTextfield
                width="100%"
                borderRadius="5px"
                // fontSize="12px"
                maxRows={ 4 }
                minRows={ 4 }
                value={ details[data.positivebusinessoutcomes.name] }
                name={ data.positivebusinessoutcomes.name }
                handledetailsChange={ handleChange }
              // placeholder={data.positivebusinessoutcomes.placeholder}
              />
            </Grid>
          </Grid>
        </Grid>
        <div className="ttribe-back-next-button-con">
          <BackNextButton
            restrictedBackClick={ () => {

              history.push("/gtmpositioningstory");
            
            } }
            backClick={ () => {

              history.push("/gtmpositioningstory");
            
            } }
            restrictedNextClick={ () => {

              history.push("/gtmpositioningresearch");
            
            } }
            nextClick={ () => {

              handleSubmit();
            
            } }
          />
        </div>
      </div>
    </ModuleSections>
  );

};

export default BeforeAfterState;
