/* eslint-disable no-constant-condition */
import React from "react";
// mui
import { Button, Divider, Grid, Typography } from "@mui/material";
import Grow from "@mui/material/Grow";
import { Box } from "@mui/system";
// mui icons
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import styles from "./LandingPage.module.css";

const Landingpage = ({ history }) => {

  return (
    <div id={ styles.maingrid }>
      <Box id={ styles.box1 }>
        <Box>
          <Typography variant="body2" id={ styles.title1 }>
            TODAC
          </Typography>
          <Typography variant="body2" id={ styles.title1 }>
            Tribe.
          </Typography>
        </Box>
        <Box id={ styles.grid2 }>
          <Typography id={ styles.textwithclick }>about </Typography>
          <Divider orientation="vertical" id={ styles.dividerstyle } />
          <Typography id={ styles.textwithclick }>pricing </Typography>
          <Divider orientation="vertical" id={ styles.dividerstyle } />

          <Typography id={ styles.textwithclick }>blog </Typography>
          <Divider orientation="vertical" id={ styles.dividerstyle } />

          <Typography id={ styles.textwithclick }>contact </Typography>
          <Button
            onClick={ () => {

              history.push("/login");
            
            } }
            id={ styles.loginbutton1 }
          >
            <Typography id={ styles.loginbuttontext }>Login</Typography>
          </Button>
        </Box>
      </Box>
      <Box sx={ { height: "79vh" } }>
        <Grid container sx={ { height: "100%" } }>
          <Grid xs={ 7 } id={ styles.grid3 }>
            <Box id={ styles.gridbox1 }>
              <Grow
                in={ true }
                style={ { transformOrigin: "0 0 0" } }
                { ...(true ? { timeout: 1000 } : {}) }
              >
                <Typography id={ styles.bigtext }>Bringing your</Typography>
              </Grow>
              <Grow
                in={ true }
                style={ { transformOrigin: "0 0 0" } }
                { ...(true ? { timeout: 2500 } : {}) }
              >
                <Typography id={ styles.bigtext }>ideas to life</Typography>
              </Grow>

              <Box id={ styles.gridbox2 } style={ { marginTop: "5.85vw" } }>
                <Button
                  id={ styles.buttonorangecontained }
                  onClick={ () => {

                    history.push("/register");
                  
                  } }
                >
                  <Typography id={ styles.textmediumwhite }>Sign Up</Typography>
                </Button>
                <Button id={ styles.buttonorangeoutlined }>
                  <PlayArrowIcon sx={ { color: "#FF721A", fontSize: "3vw" } } />
                  <Typography id={ styles.textmediumblack }>
                    watch demo
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid xs={ 5 } id={ styles.gridbox2 }>
            <img
              src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/rocket.png` }
              id={ styles.img }
            ></img>
          </Grid>
        </Grid>
      </Box>
    </div>
  );

};

export default Landingpage;
