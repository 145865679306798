import {
  Dialog,
  DialogContent,
  IconButton, Menu, MenuItem, TableCell, TableRow
} from "@mui/material";
// import InputBase from "@mui/material/InputBase";
// import { styled } from "@mui/material/styles";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import styles from "./Milestone.module.css";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useLocation } from "react-router-dom";
import CustomMUISelect from "../../../../components/CustomMUISelect";
import CustomTextField from "../../../../components/CustomTextfield";
import DeleteCancel from "../../../../components/DeleteCancel";

// const BootstrapInputActivity = styled(InputBase)(({ theme }) => ({
//   "label + &": {
//     marginTop: theme.spacing(3)
//   },
//   "& .MuiInputBase-input": {
//     "&:hover": {
//       border: "1px solid black"
//     },
//     borderRadius: 4,
//     position    : "relative",
//     // backgroundColor: "#edeeff",
//     // backgroundColor: "#f2f3ff",
//     border      : "1px solid #e3e5ff",
//     fontSize    : 12,
//     width       : "7vw",
//     padding     : "5px 0px 0px 12px",
//     transition  : theme.transitions.create([ "border-color", "box-shadow" ])
//   }
// }));

// const BootstrapInputChild = styled(InputBase)(({ theme }) => ({
//   "label + &": {
//     marginTop: theme.spacing(3)
//   },
//   "& .MuiInputBase-input": {
//     borderRadius: 4,
//     position    : "relative",
//     // backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
//     // backgroundColor: "#edeeff",
//     // backgroundColor: "#f2f3ff",
//     // border: "1px solid #ced4da",
//     border      : "1px solid #e3e5ff",
//     fontSize    : 11,
//     // width: "9vw",
//     // padding: "10px 12px",
//     // fontWeight: "bold",
//     padding     : "5px 6px"
//   }
// }));

export default function IndividualActivity (props) {

  const [ deleteactivitydialogbox, setDeleteActivityDialog ] = useState(false);

  const [ anchorElChild, setAnchorElChild ] = useState(null);
  const openChild = Boolean(anchorElChild);

  const { venture } = useSelector(state => ({ ...state }));

  const handleClickChild = event => {

    setAnchorElChild(event.currentTarget);
  
  };
  const handleCloseChild = () => {

    setAnchorElChild(null);
  
  };

  const deleteActivityDialogopen = () => {

    setDeleteActivityDialog(true);
  
  };
  const deleteActivityDialogclose = () => {

    setDeleteActivityDialog(false);
  
  };
  const activity = props.activity;
  const milestoneindex = props.milestoneindex;
  const activityindex = props.activityindex;

  // const childStatusOptions = [
  //   {
  //     value: "Not Started",
  //   },
  //   {
  //     value: "In-Progress",
  //   },
  //   {
  //     value: "Completed",
  //   },
  //   {
  //     value: "On-Hold",
  //   },
  // ];

  const location = useLocation();
  
  return (
    <TableRow id={ styles.activityTableRow }>
      <TableCell id={ styles.tableCell }>
        {/* {isactivitydelete &&
                    selectedmilestoneindex === milestoneindex && (
                      <>
                        <Button
                          onClick={(e) => {
                            // e.stopPropagation();
                            // deleteActivityDialogopen();
                          }}
                        >
                          <RemoveCircleIcon
                            fontSize="small"
                            id={styles.removeCircleIconRow}
                          />
                        </Button>
                        <Dialog
                          open={deleteactivitydialogbox}
                          PaperProps={{
                            style: {
                              borderRadius: "20px",
                              backdropFilter: "blur(45px)",
                              background: "#FFFFFF 0% 0% no-repeat padding-box",
                              boxShadow: "0px 3px 6px #00000029",
                              border: "1px solid #E0E1E2",
                            },
                          }}
                        >
                          <DialogContent>
                            <DeleteCancel
                              name={
                                "Are you sure you want to delete this activity?"
                              }
                              deleteClick={async (e) => {
                                e.stopPropagation();
                                props.removeActivity(
                                  milestoneindex,
                                  activityindex
                                );
                                deleteActivityDialogclose();
                              }}
                              cancelClick={() => {
                                deleteActivityDialogclose();
                              }}
                            />
                          </DialogContent>
                        </Dialog>
                      </>
                    )} */}
      </TableCell>

      <TableCell align="right" id={ styles.tableCellChildFirst }>
        {/* <BootstrapInputChild
          value={activity.name}
          name="name"
          type="text"
          onChange={(e) =>
            props.handleActivitiesChange(
              e.target.name,
              e.target.value,
              milestoneindex,
              activityindex
            )
          }
        /> */}
        <CustomTextField
          width={ "87%" }
          value={ activity.name }
          border={ "1px solid #e3e5ff" }
          name="name"
          fontSize={ "11px" }
          padding={ "2px 0px" }
          // padding={"15px 7px"}
          handledetailsChange={ e =>
            props.handleActivitiesChange(
              e.target.name,
              e.target.value,
              milestoneindex,
              activityindex
            )
          }
          disabled={ location.pathname.includes("pitchdeckintro") }
        />
        {/* <IconButton>
                    <MoreVertIcon sx={{ color: "#868686" }} />
                  </IconButton> */}
      </TableCell>
      <TableCell id={ styles.tableCell }>
        { " " }
        {/* <BootstrapInputChild
          type="date"
          value={moment(activity.startDate).format("YYYY-MM-DD")}
          name="startDate"
          onChange={(e) =>
            props.handleActivitiesChange(
              e.target.name,
              e.target.value,
              milestoneindex,
              activityindex
            )
          }
        /> */}
        <CustomTextField
          // width={"87%"}
          value={ moment(activity.startDate).format("YYYY-MM-DD") }
          type="date"
          border={ "1px solid #e3e5ff" }
          name="startDate"
          fontSize={ "11px" }
          padding={ "2px 0px" }
          handledetailsChange={ e => {

            if (
              activity.endDate &&
              // new Date(e.target.value).valueOf() <
              //   new Date(activity.endDate).valueOf()
              new Date(activity.endDate).valueOf() <
              new Date(e.target.value).valueOf()
            ) {

              props.setErrormsg("End date should be greater than start date");
              setTimeout(() => {

                props.setErrormsg("");
              
              }, 5000);
              
              return;
            
            }
            props.handleActivitiesChange(
              e.target.name,
              e.target.value,
              milestoneindex,
              activityindex
            );
          
          } }
          disabled={ location.pathname.includes("pitchdeckintro") }
        />
      </TableCell>
      <TableCell id={ styles.tableCell }>
        { " " }
        {/* <BootstrapInputChild
          type="date"
          value={moment(activity.endDate).format("YYYY-MM-DD")}
          name="endDate"
          onChange={(e) =>
            props.handleActivitiesChange(
              e.target.name,
              e.target.value,
              milestoneindex,
              activityindex
            )
          }
        /> */}
        <CustomTextField
          // width={"87%"}
          value={ moment(activity.endDate).format("YYYY-MM-DD") }
          type="date"
          border={ "1px solid #e3e5ff" }
          name="endDate"
          fontSize={ "11px" }
          padding={ "2px 0px" }
          handledetailsChange={ e => {

            if (
              activity.startDate &&
              new Date(e.target.value).valueOf() <
              new Date(activity.startDate).valueOf()
            ) {

              props.setErrormsg("End date should be greater than start date");
              setTimeout(() => {

                props.setErrormsg("");
              
              }, 5000);
              
              return;
            
            }
            props.handleActivitiesChange(
              e.target.name,
              e.target.value,
              milestoneindex,
              activityindex
            );
          
          } }
          disabled={ location.pathname.includes("pitchdeckintro") }
        />
      </TableCell>
      <TableCell id={ styles.tableCellChildLast }>
        { " " }
        {/* <FormControl sx={{ m: 1 }} variant="outlined">
          <Select
            value={activity.status}
            name="status"
            onChange={(e) =>
              props.handleActivitiesChange(
                e.target.name,
                e.target.value,
                milestoneindex,
                activityindex
              )
            }
            sx={{
              "& .MuiInputBase-input": {
                "&:hover": {
                  // border: "1px solid black",
                },
                // borderRadius: 4,
                position: "relative",
                // backgroundColor: "#edeeff",
                // backgroundColor: "#f2f3ff",
                // border: "1px solid #e3e5ff",
                fontSize: 12,
                width: "7vw",
                padding: "0px 0px 0px 12px",
                // transition: theme.transitions.create([
                //   "border-color",
                //   "box-shadow",
                // ]),
              },
            }}
            // input={<BootstrapInputActivity />}
          >
            <MenuItem sx={{ fontSize: "12px" }} value={"Not Started"}>
              Not Started
            </MenuItem>
            <MenuItem sx={{ fontSize: "12px" }} value={"In-Progress"}>
              In-Progress
            </MenuItem>
            <MenuItem sx={{ fontSize: "12px" }} value={"Completed"}>
              Completed
            </MenuItem>
            <MenuItem sx={{ fontSize: "12px" }} value={"On-Hold"}>
              On Hold
            </MenuItem>
          </Select>
        </FormControl> */}
        {/* <CustomSelect
          options={childStatusOptions}
          fontSize={"12px"}
          width={"11vw"}
          value={activity.status}
          name="status"
          handleChange={(e) =>
            props.handleActivitiesChange(
              "status",
              e.value,
              milestoneindex,
              activityindex
            )
          }
        /> */}
        <CustomMUISelect
          disabled={ location.pathname.includes("pitchdeckintro") }
          value={ activity.status }
          name="status"
          // options={childStatusOptions}
          handleChange={ e =>
            props.handleActivitiesChange(
              e.target.name,
              e.target.value,
              milestoneindex,
              activityindex
            )
          }
          fontSize={ "12px" }
          // width={"7vw"}
          padding={ "7px 0px 7px 12px" }
          // border={"1px solid #e3e5ff"}
          muiOptions={ props.statusOptions }
          optionFontSize={ "12px" }
          border={ "1px solid #e3e5ff" }
        />
        <IconButton
          onClick={ e => {

            handleClickChild(e);
          
          } }
          disabled={
            venture.userRole === "Commenter" ||
            location.pathname.includes("pitchdeckintro")
          }
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={ anchorElChild }
          open={ openChild }
          onClose={ () => {

            handleCloseChild();
          
          } }
          anchorOrigin={ {
            vertical  : "top",
            horizontal: "right"
          } }
          style={ { top: 5 } }
          sx={ {
            "& .MuiMenu-list": {
              padding        : "0px",
              backgroundColor: "#707070",
              color          : "white"
            }
          } }
        //   transformOrigin={{
        //     vertical: "top",
        //     horizontal: "right",
        //   }}
        >
          <MenuItem
            sx={ { fontSize: "12px" } }
            onClick={ e => {

              e.stopPropagation();
              deleteActivityDialogopen();
              handleCloseChild();
            
            } }
          >
            Delete
          </MenuItem>
        </Menu>
        <Dialog
          open={ deleteactivitydialogbox }
          PaperProps={ {
            style: {
              borderRadius  : "20px",
              backdropFilter: "blur(45px)",
              background    : "#FFFFFF 0% 0% no-repeat padding-box",
              boxShadow     : "0px 3px 6px #00000029",
              border        : "1px solid #E0E1E2"
            }
          } }
        >
          <DialogContent>
            <DeleteCancel
              name={ "Are you sure you want to delete this activity?" }
              deleteClick={ async e => {

                e.stopPropagation();
                props.removeActivity(milestoneindex, activityindex);
                deleteActivityDialogclose();
              
              } }
              cancelClick={ () => {

                deleteActivityDialogclose();
              
              } }
            />
          </DialogContent>
        </Dialog>
      </TableCell>
    </TableRow>
  );

}
