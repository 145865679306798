/* eslint-disable camelcase */
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box, Button, Dialog,
  DialogContent, Grid, IconButton, Table, TableCell, TableContainer, TableHead,
  TableRow, Tooltip, Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
// import { tooltipClasses } from "@mui/material/Tooltip";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BackNextButton from "../../../../components/BackNextButton";
import IndividualSectionHeaders from "../../../../components/IndividualSectionHeaders";
import ModuleSections from "../../../../components/ModuleSections";
import {
  getOperations,
  updateOperations
} from "../../../../services/operations";
import styles from "./Team.module.css";
import TeamTable from "./TeamTable";

import { useUnmount } from "../../../../components/useUnmount";
import {
  createOperationsComment,
  deleteOperationsComment,
  updateOperationsComment
} from "../../../../services/comment";
import ApexChart from "./TeamChart";

const GraphicalViewButton = styled(Button)({
  boxShadow      : "none",
  textTransform  : "none",
  fontSize       : 12,
  padding        : "6px 12px",
  border         : "1px solid",
  lineHeight     : 1.5,
  backgroundColor: "#white",
  borderColor    : "#071AFF",
  color          : "#071AFF",
  width          : 190,
  height         : 41,

  "&:hover": {
    backgroundColor: "#0069d9",
    borderColor    : "#071AFF",
    boxShadow      : "none",
    color          : "white"
  },
  "&:active": {
    boxShadow      : "none",
    backgroundColor: "#071AFF",
    borderColor    : "#071AFF"
  }
  // "&:focus": {
  //   boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  // },
});

// const LightTooltip = styled(({ className, ...props }) =>
//   <Tooltip { ...props } classes={ { popper: className } } />
// )(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.common.white,
//     // color: "rgba(0, 0, 0, 0.87)",
//     boxShadow      : theme.shadows[1],
//     fontSize       : "14px",
//     color          : "#FF7F00",
//     border         : "1px solid #FF7F00",
//     paddingTop     : "5px 10px",
//     borderRadius   : "10px",
//     fontWeight     : "bold"
//   }
// }));

const OpTeam = () => {

  const [ team, setTeam ] = useState([
    {
      name           : "",
      toBeHired      : false,
      type           : "Founder",
      title          : "",
      expertise      : "",
      shareholder    : false,
      sharePercentage: 0,
      otherDetails   : "",
      linkedinProfile: ""
    }
  ]);
  const [ comments, setComments ] = useState([]);
  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const [ dialogbox, setDialog ] = useState(false);
  const [ sharePercentagechartDialog, setsharePercentageChartDialog ] =
    useState(false);
  const [ teamDistributionchartDialog, setteamDistributionChartDialog ] =
    useState(false);
  const [ isdelete, setIsdelete ] = useState(false);
  const [ chartData, setChartdata ] = useState([]);
  const [ chartLabel, setChartlabel ] = useState([]);
  const [ isDataLoading, setIsDataLoading ] = useState(true);

  const { user, venture } = useSelector(state => ({ ...state }));

  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);
    
    } else {

      setCollapsecomment(true);
    
    }
  
  };

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  const sharePercentageChartDialogopen = () => {

    setsharePercentageChartDialog(true);
  
  };
  const sharePercentageChartDialogclose = () => {

    setsharePercentageChartDialog(false);
  
  };

  const teamDistributionChartDialogopen = () => {

    setteamDistributionChartDialog(true);
  
  };
  const teamDistributionChartDialogclose = () => {

    setteamDistributionChartDialog(false);
  
  };
  const history = useHistory();

  useEffect(() => {

    fetchOperations();
  
  }, []);

  const fetchOperations = async () => {

    setIsDataLoading(true);

    try {

      const response = await getOperations(venture.operations);

      setTeam(response.data.team);
      setComments(response.data.comments.team);
      setIsDataLoading(false);
    
    } catch (error) {

      console.error(error);
      setIsDataLoading(false);
    
    }
  
  };

  // To get the updated usestate value to save in useEffect cleanup
  const detailsRef = useRef(team);

  detailsRef.current = team;

  let isSubmitting = false;

  // useUnmount => custom hook to save while navigating
  useUnmount(() => {

    if ( isDataLoading == false && !isSubmitting) {

      handleSave();
    
    }
  
  });

  const handleSave = async () => {

    try {

      await updateOperations(venture.operations, "team", detailsRef.current);
      
      return true;
    
    } catch (error) {

      console.error(error);
      
      return false;
    
    }
  
  };

  const addMember = async () => {

    let newTeam = [ ...team ];

    newTeam.push({
      name           : "",
      toBeHired      : false,
      type           : "Founder",
      title          : "",
      expertise      : "",
      shareholder    : false,
      sharePercentage: 0,
      otherDetails   : "",
      linkedinProfile: ""
    });
    setTeam(newTeam);
    await updateOperations(venture.operations, "team", newTeam);
  
  };

  const removeMember = async memberindex => {

    let newTeam = team.filter((member, index) => index !== memberindex);

    setTeam(newTeam);
    setIsdelete(false);
    await updateOperations(venture.operations, "team", newTeam);
  
  };

  const handleMembersChange = (name, value, memberindex) => {

    let newTeam = team.map((member, index) => {

      if (index === memberindex) {

        return {
          ...member,
          [name]: value
        };
      
      } else {

        return member;
      
      }
    
    });

    setTeam(newTeam);
  
  };

  const handleSubmit = async () => {

    try {

      isSubmitting = true;
      if(isDataLoading == false){

        await updateOperations(venture.operations, "team", team);

      }
      history.push("/businessoperations");
      isSubmitting = false;
    
    } catch (error) {

      isSubmitting = false;
      console.error(error);
    
    }
  
  };

  const generateSharePercentageChartData = () => {

    let data = [];
    let label = [];
    let totalPercent = 0;

    team.map(member => {

      if (member.shareholder) {

        totalPercent += member.sharePercentage * 1;
        data.push(member.sharePercentage * 1);
        label.push(member.name);
      
      }
    
    });
    data.push((100 - totalPercent) * 1);
    label.push("Remaining share %");
    setChartdata(data);
    setChartlabel(label);
  
  };

  const generateTeamDistributionChartData = () => {

    let data = [];
    let types = [
      "Founder",
      "Advisor",
      "Employee",
      "Investor",
      "Board",
      "Vendor",
      "Contractor",
      "Consultant",
      "Management"
    ];

    types.map(type => {

      let count = 0;

      team.map(member => {

        if (type === member.type) {

          count += 1;
        
        }
      
      });
      data.push(count);
    
    });
    setChartdata(data);
    setChartlabel(types);
  
  };

  const addComment = async (commentText, replyTo) => {

    try {

      const response = await createOperationsComment(
        commentText,
        user._id,
        "team",
        "",
        venture.operations,
        replyTo,
        window.location.href,
        "Operations > Team"
      );

      setComments(response.data.comments.team);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateComment = async (commentid, commentText) => {

    try {

      const response = await updateOperationsComment(
        commentText,
        user._id,
        commentid,
        venture._id,
        window.location.href,
        "Operations > Team"
      );

      setComments(response.data.comments.team);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const deleteComment = async commentid => {

    try {

      const response = await deleteOperationsComment(
        "team",
        "",
        venture.operations,
        commentid,
        user._id,
        window.location.href,
        "Operations > Team"
      );

      setComments(response.data.comments.team);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  return (
    <ModuleSections
      Collapsecomment={ Collapsecomment }
      comments={ comments }
      addComment={ addComment }
      deleteComment={ deleteComment }
      updateComment={ updateComment }>
      <div style={ { width: "100%" } }>
        <div className="box-indiv-header-con">
          <IndividualSectionHeaders
            mainheading={ "Operations" }
            subheading={ "Team" }
            headingcontent={
              "Create your venture's A-team who will make your idea a reality"
            }
            isGtm={ false }
            Dialogopen={ Dialogopen }
            Dialogclose={ Dialogclose }
            dialogbox={ dialogbox }
            collapse_comment_function={ collapse_comment_function }
            content={ {
              dialogpurpose: "",
              dialoganalogy: "",
              dialogguide  : []
            } }
            //   isexpand={isexpand}
            isSaveButton={ true }
            handleSave={ handleSave }
          />
        </div>
      </div>
      <Grid
        item
        id={ styles.teamMainGrid }
        xs={ 12 }
        onClick={ () => {

          setIsdelete(false);
        
        } }>
        {/* table */ }
        <Box id={ styles.teamTableBox }>
          <TableContainer id={ styles.tableCon }>
            <Table
              size="small"
              // id="table"
              id={ styles.tableStyle }>
              <TableHead>
                <TableRow>
                  { isdelete && <TableCell></TableCell> }
                  <TableCell id={ styles.tableCell }>
                    <Typography align="center" id={ styles.tableHeadText }>
                      #
                    </Typography>
                  </TableCell>
                  <TableCell id={ styles.tableCell }>
                    <Typography id={ styles.tableHeadText }>
                      {/* Name */ }
                      <Box sx={ { marginLeft: "25%" } }>Name</Box>
                    </Typography>
                  </TableCell>
                  <TableCell id={ styles.tableCell }>
                    <Typography align="center" id={ styles.tableHeadText }>
                      To be Hired
                    </Typography>
                  </TableCell>
                  <TableCell id={ styles.tableCell }>
                    <Typography align="center" id={ styles.tableHeadText }>
                      Type
                    </Typography>
                  </TableCell>
                  <TableCell id={ styles.tableCell }>
                    <Typography align="center" id={ styles.tableHeadText }>
                      Title
                    </Typography>
                  </TableCell>
                  <TableCell id={ styles.tableCell }>
                    <Typography align="center" id={ styles.tableHeadText }>
                      Expertise
                    </Typography>
                  </TableCell>
                  <TableCell id={ styles.tableCell }>
                    <Typography align="center" id={ styles.tableHeadText }>
                      Share holder
                    </Typography>
                  </TableCell>
                  <TableCell id={ styles.tableCell }>
                    <Typography align="center" id={ styles.tableHeadText }>
                      Share%
                    </Typography>
                  </TableCell>
                  <TableCell id={ styles.tableCell }>
                    <Typography align="center" id={ styles.tableHeadText }>
                      Other details
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography align="center" id={ styles.tableHeadText }>
                      Linkedin Profile
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              { team.map((member, memberindex) => {

                return<TeamTable
                  member={ member }
                  memberindex={ memberindex }
                  isdelete={ isdelete }
                  setIsdelete={ setIsdelete }
                  handleMembersChange={ handleMembersChange }
                  addMember={ addMember }
                  removeMember={ removeMember }
                />;
              
              }) }
            </Table>

            {/* button to add rows to the table */ }
            {/* <Box id={styles.tableButtonBox}>
              <Button id={styles.addMemberButton} onClick={addMember}>
                <AddCircleIcon fontSize="small" sx={{ marginRight: "10px" }}>
                </AddCircleIcon>
                Add Member
              </Button>
              <Button
                id={
                  isdelete
                    ? styles.disableddelMemberButton
                    : styles.delMemberButton
                }
                onClick={(e) => {
                  e.stopPropagation();
                  setIsdelete(true);
                }}
              >
                <RemoveCircleIcon
                  fontSize="small"
                  sx={{ marginRight: "10px" }}
                />
                Delete Member
              </Button>
            </Box> */}
          </TableContainer>
          {/* <Box sx={{ height: "5px" }}></Box> */ }
        </Box>
        {/* ----------- */ }
        <Box id={ styles.tableButtonBox }>
          {/* <Button id={styles.addMemberButton} onClick={addMember}>
            <AddCircleIcon fontSize="small" sx={{ marginRight: "10px" }}>
              
            </AddCircleIcon>
            Add Member
          </Button> */}
          <Tooltip title="Add Member" placement="right">
            <IconButton
              id={
                venture.userRole === "Commenter"
                  ? styles.disabledaddMemberButton
                  : styles.addMemberButton
              }
              onClick={ addMember }
              disabled={ venture.userRole === "Commenter" }>
              <AddCircleIcon sx={ { fontSize: "35px" } }> </AddCircleIcon>
            </IconButton>
          </Tooltip>
          {/* <Button
            id={
              isdelete ? styles.disableddelMemberButton : styles.delMemberButton
            }
            onClick={(e) => {
              e.stopPropagation();
              setIsdelete(true);
            }}
          >
            <RemoveCircleIcon fontSize="small" sx={{ marginRight: "10px" }} />
            Delete Member
          </Button> */}
        </Box>
        {/* ---------------- */ }
        <hr></hr>
        <Typography variant="body2" id={ styles.graphicalViewHead }>
          Click any of the below buttons for a graphical view
        </Typography>
        <Grid
          sx={ { margin: "2em 0px" } }
          container
          justifyContent="space-around"
          align="center"
          justify="center"
          alignItems="center"
        // sx={{ mb: 10 }}
        >
          <Grid item xs={ 6 }>
            <GraphicalViewButton
              onClick={ () => {

                generateSharePercentageChartData();
                sharePercentageChartDialogopen();
              
              } }>
              Shareholder Distribution
            </GraphicalViewButton>
            <Dialog
              open={ sharePercentagechartDialog }
              onClose={ sharePercentageChartDialogclose }
              PaperProps={ {
                style: {
                  borderRadius   : "20px",
                  backdropFilter : "blur(100px)",
                  backgroundColor: "white",
                  boxShadow      : "0px 3px 6px #00000029",
                  border         : "1px solid #b8bddb"
                }
              } }
              BackdropProps={ { invisible: true } }
              maxWidth="md"
              fullWidth>
              <Box sx={ { alignSelf: "flex-end" } }>
                <IconButton
                  component="span"
                  onClick={ sharePercentageChartDialogclose }>
                  <HighlightOffIcon />
                </IconButton>
              </Box>
              <DialogContent>
                <ApexChart
                  chartData={ chartData }
                  chartLabel={ chartLabel }
                  name={"Shareholder Distribution"}
                />
              </DialogContent>
            </Dialog>
          </Grid>
          <Grid item xs={ 6 }>
            <GraphicalViewButton
              onClick={ () => {

                generateTeamDistributionChartData();
                teamDistributionChartDialogopen();
              
              } }>
              Team Distribution
            </GraphicalViewButton>
            <Dialog
              open={ teamDistributionchartDialog }
              onClose={ teamDistributionChartDialogclose }
              PaperProps={ {
                style: {
                  borderRadius   : "20px",
                  backdropFilter : "blur(100px)",
                  backgroundColor: "white",
                  boxShadow      : "0px 3px 6px #00000029",
                  border         : "1px solid #b8bddb"
                }
              } }
              BackdropProps={ { invisible: true } }
              maxWidth="md"
              fullWidth>
              <Box sx={ { alignSelf: "flex-end" } }>
                <IconButton
                  component="span"
                  onClick={ teamDistributionChartDialogclose }>
                  <HighlightOffIcon />
                </IconButton>
              </Box>
              <DialogContent>
                <ApexChart
                  chartData={ chartData }
                  chartLabel={ chartLabel }
                  name={"Team Distribution"}
                />
              </DialogContent>
            </Dialog>
          </Grid>
        </Grid>
      </Grid>
      <div style={ { width: "100%" } }>
        <div className="ttribe-back-next-button-con">
          <BackNextButton
            restrictedBackClick={ () => {

              history.push("/milestones");
            
            } }
            backClick={ () => {

              history.push("/milestones");
            
            } }
            restrictedNextClick={ () => {

              history.push("/businessoperations");
            
            } }
            nextClick={ () => {

              handleSubmit();
            
            } }
          />
        </div>
      </div>
    </ModuleSections>
  );

};

export default OpTeam;
