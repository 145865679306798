import axios from "./axiosService";

// import axios from "axios";

export const createPersona = async (
  details,
  customerid,
  segmentid,
  personaid,
  parentVenture
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/create_or_update_persona`,
    { details, customerid, segmentid, personaid, parentVenture }
  );

};

export const updatePersona = async (personaid, persona, parentVenture) => {

  return await axios.post(`${process.env.REACT_APP_ADDRESS}/update_persona`, {
    persona,
    personaid,
    parentVenture
  });

};

export const createSegment = async (name, customerid, parentVenture) => {

  return await axios.post(`${process.env.REACT_APP_ADDRESS}/create_segment`, {
    name,
    customerid,
    parentVenture
  });

};

export const getSegments = async customerid => {

  return await axios.post(`${process.env.REACT_APP_ADDRESS}/get_segments`, {
    customerid
  });

};

export const updateSegment = async (segmentname, segmentid) => {

  return await axios.post(`${process.env.REACT_APP_ADDRESS}/update_segment`, {
    segmentname,
    segmentid
  });

};

export const getPersona = async (personaid, problemsolutionid) => {

  return await axios.post(`${process.env.REACT_APP_ADDRESS}/get_persona`, {
    personaid,
    problemsolutionid
  });

};

export const updateDemographics = async (details, personaid) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/update_demographics`,
    { details, personaid }
  );

};

export const updatePsychographics = async (details, personaid) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/update_psychographics`,
    { details, personaid }
  );

};

export const updateBuyingBehaviour = async (details, personaid) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/update_buyingbehaviour`,
    { details, personaid }
  );

};

export const updateIndividualCharacteristics = async (details, personaid) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/update_individualcharacteristics`,
    { details, personaid }
  );

};

export const deletePersona = async (personaid, segmentid, customerid) => {

  return await axios.post(`${process.env.REACT_APP_ADDRESS}/delete_persona`, {
    personaid,
    segmentid,
    customerid
  });

};

export const deleteSegment = async (segmentid, customerid) => {

  return await axios.post(`${process.env.REACT_APP_ADDRESS}/delete_segment`, {
    segmentid,
    customerid
  });

};

export const downloadPersona = async (personaid, problemsolutionid) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/download_persona`,
    { personaid, problemsolutionid },
    {
      responseType: "arraybuffer",
      headers     : {
        "Content-Type": "application/json"
      }
    }
  );

};
