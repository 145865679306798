import React, { useEffect, useState } from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box, Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import CustomMultilineTextfield from "../../../../components/CustomMultilineTextfield";
import HelpIconTooltip from "../../../../components/HelpIconTooltip";
import { getProblemSolution } from "../../../../services/problemsolution";

export default function PitchProblemHypothesis() {

  const [ details, setDetails ] = useState({ problem: "", rootCause: "" });

  let { venture } = useSelector(state => {

    return { ...state };
  
  });

  useEffect(() => {

    fetchProblemSolution();
  
  }, []);

  const fetchProblemSolution = async () => {

    try {

      const response = await getProblemSolution(venture.problemSolution);

      setDetails(response.data.hypothesis);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  return (
    <Box>
      <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
        <Box>
          <Typography
            sx={{ fontSize: "20px", color: "#5F5E5E", fontWeight: "bold" }}
          >
            Problem
          </Typography>
        </Box>
        <ChevronRightIcon sx={{ color: "gray" }} />
        <Box>
          <Typography>Hypothesis</Typography>
        </Box>
      </Box>
      <Box>
        <Typography sx={{ fontSize: "12px" }}>
          Define the overarching problem that you've identified in the market.
        </Typography>
      </Box>

      {/* --------- */}
      <Box sx={{ width: "100%", display: "flex" }}>
        <Box sx={{ width: "45%" }}>
          <Box sx={{}}>
            <Typography
              sx={{
                padding   : "15px 30px",
                fontSize  : "18px",
                fontWeight: "bold"
              }}
            >
              The Problem
            </Typography>
            <Box sx={{ border: "1px solid #707070", borderRadius: "13px" }}>
              <Box
                sx={{
                  borderBottom: "1px solid #707070",
                  borderRadius: "13px",
                  width       : "100%"
                }}
              >
                <Typography
                  sx={{
                    fontSize  : "14px",
                    fontWeight: "bold",
                    padding   : "20px "
                  }}
                >
                  What problem have you discovered in the market?
                  <HelpIconTooltip
                    tooltipdata={
                      "Elaborate on the problem that you've identified in the market. Convert the problem into a story that will make your potential customer hear you out. The problem statements you define must make your target customer segments realize that you are the go-to solution to their needs. But, ensure your stick to the customer's or market's problem alone not your solution and go deep to get to the root cause of the same. As much as possible, use words that your target users /customers would use to describe their problems."
                    }
                    width="500px"
                  />
                </Typography>
              </Box>

              <Box sx={{}}>
                <CustomMultilineTextfield
                  // fontSize={"12px"}
                  width={"100%"}
                  border={"none"}
                  maxRows={9}
                  minRows={9}
                  value={details.problem}
                  disabled={true}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width         : "10%",
            // border: 1,
            display       : "flex",
            justifyContent: "center",
            alignItems    : "center"
          }}
        >
          <ArrowRightAltIcon sx={{ fontSize: "50px", color: "#071AFF" }} />
        </Box>
        <Box sx={{ width: "45%" }}>
          <Box sx={{}}>
            <Typography
              sx={{
                padding   : "15px 30px",
                fontSize  : "18px",
                fontWeight: "bold"
              }}
            >
              The Root Cause
            </Typography>
            <Box sx={{ border: "1px solid #707070", borderRadius: "13px" }}>
              <Box
                sx={{
                  borderBottom: "1px solid #707070",
                  borderRadius: "13px",
                  width       : "100%"
                }}
              >
                <Typography
                  sx={{
                    fontSize  : "14px",
                    fontWeight: "bold",
                    padding   : "20px "
                  }}
                >
                  Why do these Problems exist?
                  <HelpIconTooltip
                    tooltipdata={
                      "List out: Why is the problem under-solved or not solved, or why do these deficiencies/barriers exist?"
                    }
                    width="500px"
                  />
                </Typography>
              </Box>

              <Box sx={{}}>
                <CustomMultilineTextfield
                  // fontSize={"12px"}
                  width={"100%"}
                  border={"none"}
                  maxRows={9}
                  minRows={9}
                  value={details.rootCause}
                  disabled={true}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );

}
