import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DeleteCancel from "../../../../components/DeleteCancel";
import { updateSegment } from "../../../../services/persona";

import styles from "./TargetSegments.module.css";

const IndividualSegments = props => {

  const history = useHistory();
  const [ deletedialog, setDeletedialog ] = useState(false);
  const [ isdeletepersonaindex, setIsdeletepersonaindex ] = useState(0);
  const [ personadeletedialog, setPersonaDeletedialog ] = useState(false);
  const [ isupdate, setIsupdate ] = useState(false);

  let { venture } = useSelector(state => {

    return { ...state };
  
  });

  const deleteDialogopen = () => {

    setDeletedialog(true);
  
  };
  const deleteDialogclose = () => {

    setDeletedialog(false);
  
  };

  const personadeleteDialogopen = () => {

    setPersonaDeletedialog(true);
  
  };
  const personadeleteDialogclose = () => {

    setPersonaDeletedialog(false);
  
  };

  const segment = props.segment;
  const segmentindex = props.segmentindex;
  const isremovesegment = props.isremovesegment;
  const isremovepersona = props.isremovepersona;
  const isdeleteindex = props.isdeleteindex;

  const [ name, setName ] = useState(segment.name);

  const updateSegmentName = async () => {

    try {

      await updateSegment(name, segment._id);
      await props.fetchSegments();
      setIsupdate(false);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  return (
    <>
      <Grid container id={styles.gridcontainer}>
        {isremovesegment &&
          <>
            <IconButton
              sx={{
                color   : "#FF7F00",
                position: "absolute",
                left    : "10px",
                padding : 0
              }}
              onClick={e => {

                e.stopPropagation();
                deleteDialogopen();
              
              }}>
              <RemoveCircleIcon sx={{ fontSize: "20px" }} />
            </IconButton>
            <Dialog
              open={deletedialog}
              PaperProps={{
                style: {
                  borderRadius  : "20px",
                  backdropFilter: "blur(45px)",
                  background    : "#FFFFFF 0% 0% no-repeat padding-box",
                  boxShadow     : "0px 3px 6px #00000029",
                  border        : "1px solid #E0E1E2"
                }
              }}>
              <DialogContent>
                <DeleteCancel
                  name={
                    "Deleting the segment will also delete the personas associated with it. Are you sure you want to continue?"
                  }
                  deleteClick={async e => {

                    e.stopPropagation();
                    await props.removeSegment(segment._id);
                    deleteDialogclose();
                  
                  }}
                  cancelClick={() => {

                    deleteDialogclose();
                  
                  }}
                />
              </DialogContent>
            </Dialog>
          </>
        }
        <Grid item md={3}>
          {isupdate ?
            <TextField
              variant="standard"
              size="small"
              value={name}
              onChange={e => {

                setName(e.target.value);
              
              }}
              onKeyDown={e => {

                if (e.key === "Enter") {

                  e.preventDefault();
                  updateSegmentName();
                
                }
              
              }}
              onBlur={() => {

                updateSegmentName();
              
              }}
              sx={{
                "& .MuiInputBase-input": {
                  textAlign: "center"
                }
              }}
            />
            : venture.userRole !== "Commenter" ?
              <Tooltip placement="bottom-start" title={"Click to edit"}>
                <Typography
                  sx={{
                    fontWeight   : "bold",
                    fontSize     : "18px",
                    textTransform: "capitalize",
                    marginLeft   : "25px"
                  }}
                  onClick={() => {

                    setIsupdate(true);
                  
                  }}>
                  {segment.name}
                </Typography>
              </Tooltip>
              :
              <Typography
                sx={{
                  fontWeight   : "bold",
                  fontSize     : "18px",
                  textTransform: "capitalize",
                  marginLeft   : "25px"
                }}>
                {segment.name}
              </Typography>
          }
        </Grid>
        {segment.personas.length > 0 ?
          <Grid item md={5} id={styles.personagrid}>
            {segment.personas.map((persona, personaindex) => {

              return (
                <Box
                  // key={personaindex}
                  component="span"
                  // id={styles.persona}
                  sx={{
                    cursor   : isremovepersona ? "default" : "pointer",
                    textAlign: "center",
                    flex     : "0 0 80px",
                    padding  : "10px 0px",
                    position : "relative",
                    "&:hover": {
                      backgroundColor: isremovepersona ? "none" : "#c7c6c6",
                      boxShadow      : isremovepersona
                        ? "none"
                        : "0 4px 12px rgb(0 0 0 / 15%)"
                    },
                    marginLeft: "3px"
                  }}
                  onClick={() => {

                    if (props.pathname.includes("gtm")) {

                      history.push(`/gtmuserpersonadetails/${persona?._id}`);
                    
                    } else {

                      history.push(`/userpersonadetails/${persona?._id}`);
                    
                    }
                  
                  }}>
                  <Tooltip
                    placement="bottom"
                    title={" Click to view/edit persona."}>
                    <Box>
                      <img
                        src={`${process.env.REACT_APP_AWS_ASSETS_URL}${persona?.avatar}`}
                        width="50px"
                        height="50px"
                        alt=""
                      />
                      <Typography>{persona?.name}</Typography>
                    </Box>
                  </Tooltip>
                  {isremovepersona && isdeleteindex === segmentindex &&
                    <>
                      <IconButton
                        sx={{
                          color   : "#FF7F00",
                          position: "absolute",
                          top     : 0,
                          right   : 0,
                          padding : 0
                        }}
                        onClick={e => {

                          e.stopPropagation();
                          setIsdeletepersonaindex(personaindex);
                          personadeleteDialogopen();
                        
                        }}>
                        <RemoveCircleIcon sx={{ fontSize: "20px" }} />
                      </IconButton>
                      {isdeletepersonaindex === personaindex &&
                        <Dialog
                          open={personadeletedialog}
                          PaperProps={{
                            style: {
                              borderRadius  : "20px",
                              backdropFilter: "blur(45px)",
                              background    : "#FFFFFF 0% 0% no-repeat padding-box",
                              boxShadow     : "0px 3px 6px #00000029",
                              border        : "1px solid #E0E1E2"
                            }
                          }}>
                          <DialogContent>
                            <DeleteCancel
                              name={
                                "Are you sure you want to delete this persona?"
                              }
                              deleteClick={async e => {

                                e.stopPropagation();
                                await props.removePersona(
                                  persona?._id,
                                  segment?._id
                                );
                                personadeleteDialogclose();
                              
                              }}
                              cancelClick={e => {

                                e.stopPropagation();
                                props.setIsremovepersona(false);
                                personadeleteDialogclose();
                              
                              }}
                            />
                          </DialogContent>
                        </Dialog>
                      }
                    </>
                  }
                </Box>
              );
            
            })}
          </Grid>
          :
          <Grid
            item
            md={5}
            sx={{
              height        : "80px",
              display       : "flex",
              alignItems    : "center",
              borderLeft    : "solid 2px #cccccc",
              borderRight   : "solid 2px #cccccc",
              justifyContent: "center"
            }}>
            <Typography>No Personas Created</Typography>
          </Grid>
        }
        <Grid
          item
          md={4}
          sx={{ display: "flex", justifyContent: "space-around" }}>
          <Box
            sx={{
              color:
                segment.personas.length < 3 && venture.userRole !== "Commenter"
                  ? "#071AFF"
                  : "#b0b0b0",
              display: "flex",
              cursor:
                segment.personas.length < 3 && venture.userRole !== "Commenter"
                  ? "pointer"
                  : "default",
              width: "120px"
            }}
            component="span"
            onClick={() => {

              if (
                segment.personas.length < 3 &&
                venture.userRole !== "Commenter"
              ) {

                props.createPersona(segment._id, segment.name);
              
              }
            
            }}>
            <AddCircleIcon />
            <Typography
              sx={{
                color:
                  segment.personas.length < 3 &&
                  venture.userRole !== "Commenter"
                    ? "#071AFF"
                    : "#b0b0b0",
                marginLeft: "10px"
              }}>
              Add a Persona
            </Typography>
          </Box>

          <Box
            sx={{
              color:
                isremovepersona ||
                segment.personas.length === 0 ||
                venture.userRole === "Commenter"
                  ? "#B0B0B0"
                  : "#FF7F00",
              display: "flex",
              cursor:
                isremovepersona ||
                segment.personas.length === 0 ||
                venture.userRole === "Commenter"
                  ? "default"
                  : "pointer",
              width: "120px"
            }}
            component="span"
            onClick={e => {

              e.stopPropagation();
              if (
                !isremovesegment &&
                !isremovepersona &&
                venture.userRole !== "Commenter"
              ) {

                props.setIsdeleteindex(segmentindex);
                props.setIsremovepersona(true);
              
              }
            
            }}>
            <RemoveCircleIcon />
            <Typography
              sx={{
                color:
                  isremovepersona ||
                  segment.personas.length === 0 ||
                  venture.userRole === "Commenter"
                    ? "#B0B0B0"
                    : "#FF7F00",
                marginLeft: "10px"
              }}>
              Delete a Persona
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );

};

export default IndividualSegments;
