import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box,
  // DialogContent,
  // Button,
  // Tooltip,
  // TextField,
  Dialog,
  Grid, IconButton, Slide, Typography
} from "@mui/material";
import React, { forwardRef, useState } from "react";
import { useHistory } from "react-router-dom";
import CustomMultilineTextfield from "../../../components/CustomMultilineTextfield";
import PersonaDetails from "../Customer/TargetSegments/PersonaDetails";
import styles from "./Problem.module.css";

const Transition = forwardRef(function Transition (props, ref) {

  return <Slide direction="up" ref={ ref } { ...props } />;

});

const PersonaRowGrid = props => {

  const [ personadialogbox, setPersonaDialog ] = useState(false);
  const eachPersona = props.eachPersona;
  const eachSegment = props.eachSegment;
  const segmentindex = props.segmentindex;
  const personaindex = props.personaindex;
  const history = useHistory();
  const personaDialogopen = () => {

    setPersonaDialog(true);
  
  };
  const personaDialogclose = () => {

    setPersonaDialog(false);
  
  };

  
  return (
    <Grid item sx={ { display: "flex" } } md={ 12 } columnGap={ 3 }>
      <Box
        component="span"
        id={ styles.persona }
        onClick={ () => personaDialogopen() }
      >
        <img
          src={ `${process.env.REACT_APP_AWS_ASSETS_URL}${eachPersona.avatar}` }
          width="50px"
          height="50px"
        />
        <Typography sx={ { color: "#393939", textTransform: "capitalize" } }>
          { eachPersona.name }
        </Typography>
      </Box>
      <Dialog
        open={ personadialogbox }
        TransitionComponent={ Transition }
        keepMounted
        PaperProps={ {
          style: {
            borderRadius   : "20px",
            backdropFilter : "blur(10px)",
            backgroundColor: "rgba(255,255,255,0.80)",
            margin         : "20px"
          }
        } }
        maxWidth="md"
        fullWidth
      // fullScreen
      >
        <Box sx={ { alignSelf: "flex-end" } }>
          <IconButton
            component="span"
            onClick={ () => {

              history.push({ hash: "" });
              personaDialogclose();
            
            } }
          >
            <HighlightOffIcon />
          </IconButton>
        </Box>
        <PersonaDetails
          eachPersona={ eachPersona }
          segment={ eachSegment }
        />
      </Dialog>
      <Box id={ styles.problemMapCauseAndProbGrid }>

        <CustomMultilineTextfield
          width="100%"
          backgroundColor="white"
          // flex="0 0 351px"
          fontSize="14px"
          maxRows={ 3 }
          minRows={ 3 }
          value={ eachPersona?.SolutionGrid?.problemOrNeed }
          name={ "problemOrNeed" }
          handledetailsChange={ e => {

            props.handleChange(
              "problemOrNeed",
              e.target.value,
              segmentindex,
              personaindex
            );
          
          } }
          placeholder={ "" }
        />
      </Box>
      <Box id={ styles.problemMapCauseAndProbGrid }>
        <CustomMultilineTextfield
          width="100%"
          backgroundColor="white"
          // flex="0 0 351px"
          fontSize="14px"
          maxRows={ 3 }
          minRows={ 3 }
          value={ eachPersona?.SolutionGrid?.rootCause }
          name={ "rootCause" }
          handledetailsChange={ e => {

            props.handleChange(
              "rootCause",
              e.target.value,
              segmentindex,
              personaindex
            );
          
          } }
          placeholder={ "" }
        />

      </Box>
    </Grid>
  );

};

export default PersonaRowGrid;
