import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box, Button, Dialog,
  DialogContent,
  IconButton, Menu, MenuItem, TextField, Tooltip,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./IndividualPricing.module.css";

export default function IndividualPricing(props) {

  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ dialog, setDialog ] = useState(false);
  const { venture } = useSelector(state => ({ ...state }));

  const open = Boolean(anchorEl);

  const history = useHistory();

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  const handleClick = event => {

    setAnchorEl(event.currentTarget);
  
  };
  const handleClose = () => {

    setAnchorEl(null);
  
  };

  const location = useLocation();

  // const textvalue = "fwefrf";

  return (
    <Box id={styles.individualPricingMainBox}>
      <Box id={styles.headerMainBox}>
        <Box id={styles.revenueStreamTypoMainBox}>
          <Box id={styles.revenueStreamTypoBox}>
            <Typography id={styles.revenueTypo}>Revenue Stream:</Typography>
            <Typography id={styles.streamDescriptionTypo}>
              {props.revenueStream.name}
            </Typography>
          </Box>
        </Box>
        <Box id={styles.createPricingButtonBox}>
          <Button
            disabled={location.pathname.includes("pitchdeckintro")}
            id={styles.createPricingButton}
            onClick={() => {

              history.push({
                pathname: "/createpricingcomponentnew",

                search: `?index=${props.revenueStreamindex}&hashvalue=customerWorth`
              });
            
            }}
          >
            Create/Edit Pricing
          </Button>
        </Box>
      </Box>

      {/* textfield img flex */}
      <Box id={styles.textFieldImgBox}>
        <Box id={styles.textFieldBox}>
          <TextField
            value={props.revenueStream.pricingPlan}
            disabled
            placeholder="Click [Create/ Edit Pricing] above to get started. ​"
            fullWidth
            multiline
            maxRows={5}
            minRows={5}
            sx={{
              "& .MuiOutlinedInput-root": {
                padding: "8px 21px"
              },
              "& .MuiInputBase-input": {
                fontSize  : "0.75em",
                color     : "#707070",
                lineHeight: "17px"
                // "-webkit-text-fill-color": `black !important`,
                // color: `black !important`,
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none"
              },
              // "& .MuiInputBase-root.Mui-disabled": {
              //   backgroundColor: "black",
              // },
              "& .MuiInputBase-input.Mui-disabled": {
                // WebkitTextFillColor: textvalue ? "#707070" : "black",
                WebkitTextFillColor: "grey"
              }
            }}
          />
        </Box>

        <Box id={styles.mainImgBox}>
          <Tooltip title="Click to view" placement="left">
            <Box
              id={styles.imgBox}
              onClick={() => {

                if (props.revenueStream.pricingImage) Dialogopen();
              
              }}
            >
              {props.revenueStream.pricingImage &&
                <img
                  alt=""
                  src={props.revenueStream.pricingImage}
                  style={{ width: "100%", height: "100%" }}
                />
              }
            </Box>
          </Tooltip>
          <Dialog
            open={dialog}
            onClose={Dialogclose}
            PaperProps={{
              style: {
                borderRadius   : "20px",
                backdropFilter : "blur(100px)",
                backgroundColor: "#ffffff",
                boxShadow      : "0px 3px 6px #00000029",
                border         : "1px solid #707070",
                width          : "60%"
              }
            }}
            BackdropProps={{ invisible: true }}
            //
            maxWidth="false"
            fullWidth
          >
            <DialogContent sx={{ padding: "10px" }}>
              <Box
                sx={{
                  width         : "100%",
                  height        : "60vh",
                  display       : "flex",
                  alignItems    : "center",
                  justifyContent: "center"
                }}
              >
                <img
                  alt=""
                  src={props.revenueStream.pricingImage}
                  style={{ width: "500px", height: "300px" }}
                />
              </Box>
            </DialogContent>
          </Dialog>

          <Box id={styles.moreVertIconBox}>
            <IconButton
              onClick={e => {

                handleClick(e);
              
              }}
              disabled={
                venture.userRole === "Commenter" ||
                location.pathname.includes("pitchdeckintro")
              }
              style={{ padding: "0px" }}
            >
              <MoreVertIcon sx={{ fontSize: "30px" }} />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={() => {

                handleClose();
              
              }}
              style={{ top: -34, left: -10 }}
              anchorOrigin={{
                vertical  : "top",
                horizontal: "right"
              }}
              sx={{
                "& .MuiMenu-list": {
                  padding        : "0px 6px",
                  backgroundColor: "#707070",
                  color          : "white"
                }
              }}
            >
              <MenuItem
                onClick={() => {

                  history.push({
                    pathname: "/createpricingcomponentnew",

                    search: `?index=${props.revenueStreamindex}&hashvalue=pricingImage`
                  });
                
                }}
                sx={{ fontSize: "12px", padding: "3px", textAlign: "center" }}
              >
                Edit Pricing <br /> Summary
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Box>
    </Box>
  );

}
