import HelpIcon from "@mui/icons-material/Help";
import { IconButton, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles(() => ({
  customWidth: {
    maxWidth       : props => props.width ? props.width : "200px",
    fontSize       : 12,
    // backgroundColor: "white",
    // color: "#707070",
    backgroundColor: "#707070",
    color          : "white",
    boxShadow      : "0px 3px 6px #00000029",
    border         : "1px solid #707070",
    borderRadius   : 10,
    whiteSpace     : "pre-wrap"
  },
  helpicon: {
    color   : "#071AFF",
    fontSize: "15px"
  }
}));

const HelpIconTooltip = props => {

  const classes = useStyles({ width: props.width });

  
  return (
    <Tooltip
      enterTouchDelay={ 0 }
      disableFocusListener
      title={ props.tooltipdata }
      placement={ props.placement ? props.placement : "right-start" }
      classes={ { tooltip: classes.customWidth } }
      style={ { padding: props.padding ? props.padding : "" } }
    >
      <IconButton disableTouchRipple disableFocusRipple>
        <HelpIcon className={ classes.helpicon }></HelpIcon>
      </IconButton>
    </Tooltip>
  );

};

export default HelpIconTooltip;
