import React from "react";
import {
  Box, TextField, Typography
} from "@mui/material";
// import { useHistory } from "react-router-dom";

import { useSelector } from "react-redux";
import HelpIconTooltip from "../../../../../components/HelpIconTooltip";
import styles from "./PriceSensitive.module.css";

export default function PriceSensitive(props) {

  const { venture } = useSelector(state => ({ ...state }));

  const revenueStreams = props.revenueStreams;
  const revenueStreamIndex = props.revenueStreamIndex;

  const handleChangePurchasingDecisionFactors = (
    revenueStreamIndex,
    name,
    value
  ) => {

    let newRevenueStreams = [ ...revenueStreams ];

    newRevenueStreams[revenueStreamIndex]["purchasingDecisionFactors"][name] =
      value;

    props.setRevenueStreams(newRevenueStreams);
  
  };

  // const history = useHistory();

  
  return (
    // <CreatePricingComponent barHighlight="bar4">
    <Box>
      {/* top typo */}
      <Box>
        <Typography
          id={styles.topTypo}
        //   sx={{ color: "#5F5E5E" }}
        >
          How price sensitive are your buyers? What factors will affect their
          purchasing decision?
        </Typography>
        <Typography
          id={styles.topSubTypo}
        //   sx={{ color: "#868686", fontSize: "13px" }}
        >
          Price sensitivity is a measure of a customer’s willingness to buy a
          product at different price points. In other words, it explains how
          much of your product – if at all - they will buy, depending on how you
          price it. Let’s consider a few factors that might help influence a
          customer’s decision.
          <HelpIconTooltip
            width={"400px"}
            tooltipdata={
              "Your pricing strategy will have to make a tradeoff between maximizing volume sold vs. maximizing revenue, and an understanding of price sensitivity will help you with your pricing decisions. Different customer segments will usually have different price sensitivity.\nFor e.g. the buyer of luxury cars may be less price sensitive than a buyer in the small, utility car market, because the former may value exclusivity and brand affinity more, while the latter may be a lot more cost-conscious and be willing to switch to alternatives more willingly."
            }
          />
        </Typography>
      </Box>

      {/* typo text field main box */}
      <Box
        id={styles.typoTextFieldMainBox}
      //   sx={{ width: "100%", marginTop: "20px", border: "1px solid #ffffff" }}
      >
        {/* ---1----- */}
        <Box
          id={styles.individualTypoTextFieldBox}
        //   sx={{ width: "100%", marginTop: "20px" }}
        >
          <Box
            id={styles.individualTypoBox}
          //   sx={{
          //     display: "flex",
          //     // justifyContent: "center",
          //     alignItems: "center",
          //     marginLeft: "16px",
          //   }}
          >
            <Typography
              id={styles.individualTypo}
            // sx={{ fontSize: "14px", color: "#5F5E5E", fontWeight: "bold" }}
            >
              Ability to pay
            </Typography>

            <Box>
              <HelpIconTooltip
                placement="right"
                width={"400px"}
                tooltipdata="Income levels/ economic status could vary by customer segment. Tighter budgets with limited access to alternate funds, will impact a customer’s purchase decision. How important will these factors be for your product, keeping your target customer personas in mind? Note that this factor could also be considered at a broader level, for example, during an economic recession or instability in markets. ​"
              />
            </Box>
          </Box>
          {/* text field box */}
          <Box
            id={styles.textFieldBox}
          // sx={{ width: "100%", marginTop: "10px" }}
          >
            <TextField
              onChange={e => {

                handleChangePurchasingDecisionFactors(
                  revenueStreamIndex,
                  "abilityToPay",
                  e.target.value
                );
              
              }}
              disabled={venture.userRole === "Commenter" ? true : false}
              value={
                revenueStreams[revenueStreamIndex]["purchasingDecisionFactors"][
                  "abilityToPay"
                ]
              }
              placeholder="E.g. Small business owners are a key target segment for our product. Most operate on fairly low margins. They will be very cost conscious. ​"
              fullWidth
              multiline
              maxRows={4}
              minRows={4}
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "14.5px 16px"
                },
                "& .MuiInputBase-input": {
                  fontSize  : "12px",
                  color     : "#707070",
                  lineHeight: "17px"
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #E1E2E9"
                }
              }}
            />
          </Box>
        </Box>
        {/* ---------- */}
        {/* ---2----- */}
        <Box
          id={styles.individualTypoTextFieldBox}
        //   sx={{ width: "100%", marginTop: "20px" }}
        >
          <Box
            id={styles.individualTypoBox}
          //   sx={{ display: "flex", alignItems: "center", marginLeft: "16px" }}
          >
            <Typography
              id={styles.individualTypo}
            // sx={{ fontSize: "14px", color: "#5F5E5E", fontWeight: "bold" }}
            >
              Uniqueness
            </Typography>
            <Box>
              <HelpIconTooltip
                width={"400px"}
                tooltipdata={
                  "This could come from unique product features that customers would care about, or strong brand recognition/ strength. How “unique” will you seem to a customer? How much will a typical customer really value that uniqueness in this market?"
                }
              />
            </Box>
          </Box>
          <Box
            id={styles.textFieldBox}
          // sx={{ width: "100%", marginTop: "10px" }}
          >
            <TextField
              onChange={e => {

                handleChangePurchasingDecisionFactors(
                  revenueStreamIndex,
                  "uniqueness",
                  e.target.value
                );
              
              }}
              disabled={venture.userRole === "Commenter" ? true : false}
              value={
                revenueStreams[revenueStreamIndex]["purchasingDecisionFactors"][
                  "uniqueness"
                ]
              }
              placeholder="E.g. Our product has xyz unique features that our early adopter customers have liked. Our brand recognition is currently low and customers to have alternatives that meet ~80% of their needs. ​"
              fullWidth
              multiline
              maxRows={4}
              minRows={4}
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "14.5px 16px"
                },
                "& .MuiInputBase-input": {
                  fontSize  : "12px",
                  color     : "#707070",
                  lineHeight: "17px"
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #E1E2E9"
                }
              }}
            />
          </Box>
        </Box>
        {/* ---- */}
        {/* ---3--- */}
        <Box
          id={styles.individualTypoTextFieldBox}
        //   sx={{ width: "100%", marginTop: "20px" }}
        >
          <Box
            id={styles.individualTypoBox}
          //   sx={{ display: "flex", alignItems: "center", marginLeft: "16px" }}
          >
            <Typography
              id={styles.individualTypo}
            // sx={{ fontSize: "14px", color: "#5F5E5E", fontWeight: "bold" }}
            >
              Reference price
            </Typography>
            <Box>
              <HelpIconTooltip
                width={"400px"}
                tooltipdata={
                  "Think of the price anchors you analyzed in the previous step. How likely are your customers to consider those as the “going rate”? How will your customers benchmark and evaluate your price against other alternatives?"
                }
              />
            </Box>
          </Box>
          <Box
            id={styles.textFieldBox}
          //   sx={{ width: "100%", marginTop: "10px" }}
          >
            <TextField
              onChange={e => {

                handleChangePurchasingDecisionFactors(
                  revenueStreamIndex,
                  "referencePrice",
                  e.target.value
                );
              
              }}
              disabled={venture.userRole === "Commenter" ? true : false}
              value={
                revenueStreams[revenueStreamIndex]["purchasingDecisionFactors"][
                  "referencePrice"
                ]
              }
              fullWidth
              multiline
              maxRows={4}
              minRows={4}
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "14.5px 16px"
                },
                "& .MuiInputBase-input": {
                  fontSize  : "12px",
                  color     : "#707070",
                  lineHeight: "17px"
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #E1E2E9"
                }
              }}
            />
          </Box>
        </Box>
        {/* ------ */}
        {/* ----4---- */}
        <Box
          id={styles.individualTypoTextFieldBox}
        //   sx={{ marginTop: "20px", width: "100%" }}
        >
          <Box
            id={styles.individualTypoBox}
          //   sx={{ display: "flex", alignItems: "center", marginLeft: "16px" }}
          >
            <Typography
              id={styles.individualTypo}
            // sx={{ color: "#5F5E5E", fontSize: "14px", fontWeight: "bold" }}
            >
              Ease of switching
            </Typography>
            <Box>
              <HelpIconTooltip
                width={"400px"}
                tooltipdata={
                  "If it’s easy for a customer to find an equally effective alternate to your product, and easily switch to it, then they are likely to be be quite price sensitive. How much do you think factor a risk for your product?"
                }
              />
            </Box>
          </Box>
          <Box
            id={styles.textFieldBox}
          // sx={{ width: "100%", marginTop: "10px" }}
          >
            <TextField
              onChange={e => {

                handleChangePurchasingDecisionFactors(
                  revenueStreamIndex,
                  "easeOfSwitching",
                  e.target.value
                );
              
              }}
              disabled={venture.userRole === "Commenter" ? true : false}
              value={
                revenueStreams[revenueStreamIndex]["purchasingDecisionFactors"][
                  "easeOfSwitching"
                ]
              }
              fullWidth
              multiline
              maxRows={4}
              minRows={4}
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "14.5px 16px"
                },
                "& .MuiInputBase-input": {
                  fontSize  : "12px",
                  color     : "#707070",
                  lineHeight: "17px"
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #E1E2E9"
                }
              }}
            />
          </Box>
        </Box>
        {/* -------- */}
        {/* ----5--- */}
        <Box
          id={styles.individualTypoTextFieldBox}
        //   sx={{ marginTop: "20px", width: "100%" }}
        >
          <Box
            id={styles.individualTypoBox}
          //   sx={{ display: "flex", alignItems: "center", marginLeft: "16px" }}
          >
            <Typography
              id={styles.individualTypo}
            // sx={{ fontSize: "14px", color: "#5F5E5E", fontWeight: "bold" }}
            >
              Any other factors?
            </Typography>

            <Box></Box>
          </Box>
          <Box
            id={styles.textFieldBox}
          // sx={{ width: "100%", marginTop: "10px" }}
          >
            <TextField
              onChange={e => {

                handleChangePurchasingDecisionFactors(
                  revenueStreamIndex,
                  "otherFactors",
                  e.target.value
                );
              
              }}
              disabled={venture.userRole === "Commenter" ? true : false}
              value={
                revenueStreams[revenueStreamIndex]["purchasingDecisionFactors"][
                  "otherFactors"
                ]
              }
              fullWidth
              multiline
              maxRows={4}
              minRows={4}
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "14.5px 16px"
                },
                "& .MuiInputBase-input": {
                  fontSize  : "12px",
                  color     : "#707070",
                  lineHeight: "17px"
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #E1E2E9"
                }
              }}
            />
          </Box>
        </Box>
        {/* ------- */}
      </Box>
    </Box>
    // </CreatePricingComponent>
  );

}
