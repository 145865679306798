
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PitchDeckOnePage from "../../PitchDeck/PitchDeckOnePage";

import { CircularProgress, Grid } from "@mui/material";

import { getPublishedPitchDeckDetails } from "../../../../services/pitchdeck";


const OnePagePresentation = props => {

  const [ loading, setLoading ] = useState(false);
  const [ onePageData, setOnePageData ] = useState(false);

  let location = useLocation();

  const useQuery = () => {

    return new URLSearchParams(location.search);
  
  };
  const query = useQuery();

  const id = query.get("id");

  useEffect(() => {

    fetchDetails();
  
  }, []);

  const fetchDetails = async () => {

    setLoading(true);

    try {

      const response = await getPublishedPitchDeckDetails(id || props.id, true);
      const data = response?.data;

      if (data) {

        if (data.onePageData) {

          setOnePageData(data.onePageData);
          setLoading(false);
        
        } else {

          setOnePageData([]);
          setLoading(false);

        }

      } else {

        setOnePageData([]);
        setLoading(false);
      
      }

    } catch (error) {

      console.error(error);
    
    }
  
  };


  return <>
    { loading ? <>
      <Grid
        container
        justifyContent="center"
        height="90vh"
        alignItems="center"
      >
        <Grid item>
          <CircularProgress size="60px" thickness={ 3 } />
        </Grid>
      </Grid>
    </> : <>
      <Grid className="tribe-one-page-present-main-con">
        <PitchDeckOnePage onePageData={ onePageData } />
      </Grid>
    </> }

  </>;

};

export default OnePagePresentation;
