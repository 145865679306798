import React from "react";
import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
// import { useSelector } from "react-redux";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  card: {
    padding        : theme.spacing(1, 1, 1, 2),
    margin         : theme.spacing(1),
    wordWrap       : "break-word",
    zIndex         : "-100",
    backgroundColor: "white"
    // "&:hover": {
    //   backgroundColor: "#EBECF0",
    // },
  },
  delete: {
    position       : "absolute",
    right          : 0,
    zIndex         : 1000,
    top            : 0,
    backgroundColor: "#EBECF0"
  },
  select: {
    width: "100%"
  },
  button: {
    backgroundColor: "#071AFF",
    marginTop      : theme.spacing(1)
  },
  iconBtn: {
    float: "right"
  },
  name: {
    fontSize  : "17px",
    fontWeight: "bold"
  },
  heading: {
    fontWeight: "bold"
  },
  subContent: {
    fontSize: "15px"
  }
}));

const Card = ({ activity, activityindex }) => {

  const classes = useStyles({});

  // let { user, venture } = useSelector(state => {

  //   return { ...state };
  
  // });

  return (

    <div
      key={activityindex}
    >
      <Paper
        className={classes.card}
      >
        <div style={{ position: "relative" }}>
          <div className={classes.name}>{activity.name}</div>
          <div className={classes.subContent}><span className={classes.heading}>Date:</span> {moment(activity.startDate).format("l")} - {moment(activity.endDate).format("l")}</div>
          <div className={classes.subContent}><span className={classes.heading}>Status:</span> {activity.status}</div>
        </div>
      </Paper>
    </div>
  );

};

export default Card;

{
  /**/
}
