import React, { useEffect, useState } from "react";
import {
  Box, Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import CustomMultilineTextfield from "../../../../components/CustomMultilineTextfield";
// import ConceptVisionData from "../../../../Data/ConceptVision.json";
import { getVetureById } from "../../../../services/venture";

export default function PitchProblemConceptVision() {

  const [ details, setDetails ] = useState({
    businessIdea   : "",
    longTerm       : "",
    productBuild   : "",
    target         : "",
    describeSuccess: ""
  });

  const { venture } = useSelector(state => ({ ...state }));

  useEffect(() => {

    fetchVenture();
  
  }, []);

  const fetchVenture = async () => {

    try {

      const response = await getVetureById(venture._id);

      setDetails(response.data.venture.conceptVision);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  // const datas = ConceptVisionData["first"];


  return (
    <Box>
      <Box>
        <Typography sx={{ fontWeight: "bold" }}>Concept & Vision</Typography>

        <Box sx={{ width: "100%", marginTop: "10px" }}>
          <Box>
            <Typography sx={{ fontWeight: "bold" }}>
              Describe your venture in 1 or 2 sentences.
            </Typography>
            <Typography
              sx={{ fontSize: "12px", fontStyle: "italic", marginTop: "15px" }}
            >
              Think of this as your 30 second elevator pitch to a potential
              investor or a strategic customer. Write a precise and catchy
              introduction for what your venture intends to do, and why
            </Typography>
          </Box>

          <Box sx={{ marginTop: "10px" }}>
            <CustomMultilineTextfield
              width="-webkit-fill-available"
              borderRadius="10px"
              // fontSize="12px"
              lineHeight="15px"
              height="60px"
              padding="10px 14px"
              maxRows={4}
              minRows={4}
              //   name={data.name}
              value={details.businessIdea}
              //   handledetailsChange={handledetailsChange}
              //   placeholder={data.placeholder}
              disabled={true}
            />
          </Box>
        </Box>
      </Box>

      <Box sx={{ width: "100%", marginTop: "20px" }}>
        <Box>
          <Typography sx={{ fontWeight: "bold" }}>
            Where do you see your venture in the long term?
          </Typography>
          <Typography
            sx={{ fontSize: "12px", fontStyle: "italic", marginTop: "15px" }}
          >
            This is your vision statement – the 'north star’ for your venture.
            It should seem achievable yet bold & emotional. Set a strong sense
            of purpose for you and your stakeholders – customers, investors, and
            employees.
          </Typography>
        </Box>

        <Box sx={{ marginTop: "10px" }}>
          <CustomMultilineTextfield
            width="-webkit-fill-available"
            borderRadius="10px"
            // fontSize="12px"
            lineHeight="15px"
            height="60px"
            padding="10px 14px"
            maxRows={4}
            minRows={4}
            //   name={data.name}
            value={details.longTerm}
            //   handledetailsChange={handledetailsChange}
            //   placeholder={data.placeholder}
            disabled={true}
          />
        </Box>
      </Box>
    </Box>
  );

}
