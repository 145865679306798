import {
  Box, Button,
  Grid, Typography
} from "@mui/material";
import React from "react";
import BoxWithoutIcon from "../../../components/BoxWithoutIcon";

const ResendLink = ({ history }) => {

  const hashvalue = history.location.hash.substring(1);


  return (
    <BoxWithoutIcon>
      <Grid
        style={ {
          display      : "flex",
          flexDirection: "column",
          alignItems   : "center",
          paddingTop   : "50px",
          height       : "100%"
        } }
      >
        <Box
          style={ {
            display      : "flex",
            alignItems   : "center",
            flexDirection: "column"
          } }
        >
          <img
            src="images/undraw_message_sent_re_q2kl.svg"
            alt="resend img"
            style={ { width: "150px", height: "150px", margin: "10px 0px" } }
          ></img>
          <Typography variant="subtitle1" gutterBottom component="div" fontWeight="bold">
            Verify your email by clicking on the link sent to your account.
          </Typography>
          <Button
            style={ { cursor: "pointer", color: "#071AFF", marginTop: "10px", textTransform: "lowercase", fontWeight: "bold" } }
            onClick={ () => { hashvalue === "forgotpassword" ? history.push({ pathname: "/resendpage", hash: "forgotpassword" }) : history.push("/resendpage"); } }
          >
            resend link
          </Button>
        </Box>
      </Grid>
    </BoxWithoutIcon>
  );

};

export default ResendLink;
