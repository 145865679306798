import React, { useEffect, useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box, Table, TableCell, TableContainer,
  TableHead,
  TableRow, Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import { getOperations } from "../../../../services/operations";
import TeamTable from "../../Operations/Team/TeamTable";
import styles from "./PitchTeam.module.css";

export default function PitchTeam() {

  const [ team, setTeam ] = useState([
    {
      name           : "",
      toBeHired      : false,
      type           : "Founder",
      title          : "",
      expertise      : "",
      shareholder    : false,
      sharePercentage: 0,
      otherDetails   : "",
      linkedinProfile: ""
    }
  ]);

  const { venture } = useSelector(state => ({ ...state }));

  useEffect(() => {

    fetchOperations();
  
  }, []);

  const fetchOperations = async () => {

    try {

      const response = await getOperations(venture.operations);

      setTeam(response.data.team);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  return (
    <Box>
      <Box
        sx={{
          width     : "100%",
          display   : "flex",
          alignItems: "center",

          position: "relative"
        }}
      >
        <Box>
          <Typography
            sx={{ fontSize: "20px", color: "#5F5E5E", fontWeight: "bold" }}
          >
            Operations
          </Typography>
        </Box>

        <ChevronRightIcon sx={{ color: "gray" }} />
        <Box>
          <Typography>Team</Typography>
        </Box>
      </Box>

      <Box>
        <Typography sx={{ fontSize: "12px" }}>
          Create your venture's A-team who will make your idea a reality
        </Typography>
      </Box>

      <Box id={styles.teamTableBox}>
        <TableContainer>
          <Table
            size="small"
            // id="table"
            id={styles.tableStyle}
          >
            <TableHead>
              <TableRow>
                {/* {isdelete && <TableCell></TableCell>} */}
                <TableCell id={styles.tableCell}>
                  <Typography align="center" id={styles.tableHeadText}>
                    #
                  </Typography>
                </TableCell>
                <TableCell id={styles.tableCell}>
                  <Typography id={styles.tableHeadText}>
                    {/* Name */}
                    <Box sx={{ marginLeft: "25%" }}>Name</Box>
                  </Typography>
                </TableCell>
                <TableCell id={styles.tableCell}>
                  <Typography align="center" id={styles.tableHeadText}>
                    To be Hired
                  </Typography>
                </TableCell>
                <TableCell id={styles.tableCell}>
                  <Typography align="center" id={styles.tableHeadText}>
                    Type
                  </Typography>
                </TableCell>
                <TableCell id={styles.tableCell}>
                  <Typography align="center" id={styles.tableHeadText}>
                    Title
                  </Typography>
                </TableCell>
                <TableCell id={styles.tableCell}>
                  <Typography align="center" id={styles.tableHeadText}>
                    Expertise
                  </Typography>
                </TableCell>
                <TableCell id={styles.tableCell}>
                  <Typography align="center" id={styles.tableHeadText}>
                    Share holder
                  </Typography>
                </TableCell>
                <TableCell id={styles.tableCell}>
                  <Typography align="center" id={styles.tableHeadText}>
                    Share%
                  </Typography>
                </TableCell>
                <TableCell id={styles.tableCell}>
                  <Typography align="center" id={styles.tableHeadText}>
                    Other details
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography align="center" id={styles.tableHeadText}>
                    Linkedin Profile
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            {team.map((member, memberindex) => {

              return <TeamTable member={member} memberindex={memberindex} />;
            
            })}
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );

}
