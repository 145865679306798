import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box, Button,
  Dialog,
  DialogContent, IconButton
} from "@mui/material";
import React, { useState } from "react";
import FlexTextfieldImg from "../FlexTextfieldImg";
import PitchOpportunityMarket from "../PitchReferenceLinks/PitchOpportunityMarket";
import ReferenceLinkTooltip from "../PitchReferenceLinks/ReferenceLinkTooltip";

export default function Opportunity ({
  logoimg,
  slides,
  isRender,
  setSlides,
  isPublished,
  ...props
}) {

  const [ marketFactsDialog, setMarketFactsDialog ] = useState(false);

  const marketFactsDialogopen = () => {

    setMarketFactsDialog(true);
  
  };
  const marketFactsDialogclose = () => {

    setMarketFactsDialog(false);
  
  };

  const slide = slides[isRender];

  const handleChange = (name, value, columnindex) => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    let newopportunities = [ ...newSlides[isRender]["opportunities"] ];

    newopportunities.map((opportunity, opportunityindex) => {

      if (opportunityindex === columnindex) {

        newopportunities[opportunityindex][name] = value;
      
      }
    
    });
    newSlides[isRender]["opportunities"] = newopportunities;
    setSlides(newSlides);
  
  };

  const handleDetailsChange = (name, value) => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    newSlides[isRender][name] = value;
    setSlides(newSlides);
  
  };

  
  return (
    <Box sx={ { position: "relative" } }>
      <FlexTextfieldImg
        isEdit={ props.isEdit }
        Collapsecomment={ props.Collapsecomment }
        collapseCommentFunction={ props.collapseCommentFunction }
        mainTitle={ slide.name }
        onTitleChange={ handleDetailsChange }
        leftTitle={ slide.opportunities[0].name }
        midTitle={ slide.opportunities[1].name }
        rightTitle={ slide.opportunities[2].name }
        logoimg={ logoimg }
        imgOne={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/pitchOpportunityStats.png` }
        imgTwo={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/pitchOpportunityProducts.png` }
        imgThree={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/pitchOpportunityTrends.png` }
        leftTxtFieldPlaceholder={
          "Enter financial & other relevant statistics about the industry you are dealing with"
        }
        midTxtFieldPlaceholder={
          "Jot down key characteristics that help describe your industry (e.g. key demand-supply activities, value chain, industry maturity, etc.)"
        }
        rightTxtFieldPlaceholder={
          "Highlight any factors or trends that can impact this market or industry segment (e.g. Technology, Regulatory, Social, Economic, etc.)"
        }
        // midTypoBoxWidth={"200px"}
        leftValue={ slide.opportunities[0].value }
        midValue={ slide.opportunities[1].value }
        rightValue={ slide.opportunities[2].value }
        handleValueChange={ handleChange }
        isPublished={ isPublished }
        opportunitySlide={ true }
      />

      { props.isEdit ?
        <Box sx={ { position: "absolute", bottom: "1px", left: "30px" } }>
          <ReferenceLinkTooltip
            title={ "Revisit the notes created in the Market Module." }
          >
            <Button
              style={ {
                padding       : "0px",
                textTransform : "none",
                fontSize      : "12px",
                fontWeight    : "bold",
                textDecoration: "underline"
              } }
              onClick={ marketFactsDialogopen }
            >
              Market Facts & Figures
            </Button>
          </ReferenceLinkTooltip>
          <Dialog
            open={ marketFactsDialog }
            onClose={ marketFactsDialogclose }
            PaperProps={ {
              style: {
                borderRadius   : "20px",
                backdropFilter : "blur(100px)",
                backgroundColor: "#ffffff",
                boxShadow      : "0px 3px 6px #00000029",
                // border: "1px solid #707070",
                width          : "70%"
              }
            } }
            // BackdropProps={{ invisible: true }}
            //
            maxWidth="false"
            fullWidth
          >
            <DialogContent sx={ { padding: "40px" } }>
              <Box
                sx={ {
                  width   : "100%",
                  // height: "60vh",
                  // display: "flex",
                  // alignItems: "center",
                  // justifyContent: "center",
                  position: "relative"
                } }
              >
                <PitchOpportunityMarket />
                <IconButton
                  onClick={ () => {

                    marketFactsDialogclose();
                  
                  } }
                  sx={ {
                    position: "absolute",
                    right   : "-40px",
                    top     : "-40px"
                  } }
                >
                  <HighlightOffIcon />
                </IconButton>
              </Box>
            </DialogContent>
          </Dialog>
        </Box>
        :
        <></>
      }
    </Box>
  );

}
