import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Data from "../Data/data.json";
import { getVetureById } from "../services/venture";
import BoxWithIcon from "./BoxWithIcon";
import DialogComponent from "./DialogComponent";

const ModulesIntro = () => {

  const data = Data.modulesintro;
  const history = useHistory();
  const pathname = history.location.pathname.substring(1);
  const content = data[pathname];
  const [ details, setDetails ] = useState(content ? content.details : {});

  let { venture } = useSelector(state => {

    return { ...state };
  
  });

  useEffect(() => {

    getVenture();
  
  }, []);


  const getVenture = async () => {

    try {

      const response = await getVetureById(venture._id);
      const Venture = response.data.venture;
      
      if(content.modulename === "Concept & Vision") {
        
        setDetails(Venture.percentValue["conceptvision"]);
        
      } else {
        
        setDetails(Venture.percentValue[content.modulename]);
        
      }

    
    } catch (error) {

      console.error(error);
    
    }
  
  };
  
  console.info(`${content.modulename.toLowerCase()}summary`, pathname);

  return (
    <BoxWithIcon>
      <Grid container sx={ { height: "99%" } }>
        <Grid
          item
          xs={ 12 }
          md={ 6 }
          sx={ {
            display       : "flex",
            alignItems    : "center",
            justifyContent: "center"
          } }
        >
          <Box
            sx={ {
              height        : "90%",
              width         : "80%",
              display       : "flex",
              flexDirection : "column",
              justifyContent: "space-between"
            } }
          >
            <Box>
              <Typography
                gutterBottom
                component="div"
                style={ {
                  fontWeight  : "bold",
                  color       : "#5F5E5E",
                  fontSize    : "25px",
                  marginBottom: "80px"
                } }
              >
                { content.title }
              </Typography>
              <Box>
                <Typography
                  gutterBottom
                  component="div"
                  mt={ 4 }
                  style={ {
                    fontWeight: "bold",
                    color     : "#5F5E5E",
                    fontSize  : "18px"
                  } }
                >
                  { content.subtitle }
                </Typography>
                <Typography
                  gutterBottom
                  style={ {
                    marginBottom: "20px",
                    color       : "#393939",
                    fontSize    : "16px"
                  } }
                >
                  { content.content }
                </Typography>
              </Box>
            </Box>
            <Box>
              <Button
                variant="contained"
                style={ {
                  border         : "1px solid #071AFF",
                  backgroundColor: "#FFFFFF",
                  color          : "blue",
                  marginRight    : "30px",
                  borderRadius   : "8px",
                  textTransform  : "capitalize",
                  padding        : "6px 30px",
                  fontSize       : "18px"
                } }
                disableElevation
                onClick={ () => {

                  history.push("/dashboard");
                
                } }
              >
                Dashboard
              </Button>
              <Button
                style={ {
                  marginRight    : "30px",
                  borderRadius   : "8px",
                  backgroundColor: "#071AFF",
                  textTransform  : "capitalize",
                  padding        : "6px 20px",
                  fontSize       : "18px"
                } }
                disableElevation
                variant="contained"
                onClick={ () =>
                  history.push(
                    `/${content.images[0].imagetitle
                      .toLowerCase()
                      .replace(/[^\w]/g, "")}`
                  )
                }
              >
                Get Started
                <NavigateNextIcon />
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={ 12 }
          md={ 6 }
          sx={ {
            display       : "flex",
            alignItems    : "center",
            justifyContent: "center"
          } }
        >
          <Box
            sx={ {
              display      : "flex",
              flexDirection: "column",
              width        : "80%",
              height       : "90%",
              justifyContent:
                content.images.length > 2 ? "space-between" : "flex-start",
              alignItems: "center"
            } }
          >
            <Typography
              m={ 1 }
              sx={ {
                color     : "#5f5e5e",
                fontWeight: "bold",
                textAlign : "center",
                fontSize  : "14px"
              } }
            >
              You may make changes to the status of this module{ " " }
              <span
                style={ { color: "blue", cursor: "pointer" } }
                onClick={ () => {

                  if(pathname ===  "conceptvisionintro") {
                    
                    history.push("conceptvisionsummary");
                    
                  } else {
                    
                    history.push(`${content.modulename.toLowerCase()}summary`);
                    
                  }

                }
                }
              >
                here
              </span>
            </Typography>
            { content.images.map((data, index) => {

              return (
                // <div key={index}>
                <DialogComponent
                  title={ data.imagetitle }
                  imgsrc={ data.imagesrc }
                  sectionname={ data.sectionname }
                  details={ details }
                  index={ index }
                  length={ content.images.length }
                />
                // </div>
              );
            
            }) }
          </Box>
        </Grid>
      </Grid>
    </BoxWithIcon>
  );

};

export default ModulesIntro;
