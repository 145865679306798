import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import BoxWithoutIcon from "../../../components/BoxWithoutIcon";
import styles from "./ProfileResendLink.module.css";

const ResendLink = () => {

  return (
    <BoxWithoutIcon>
      <Grid id={ styles.mainGrid }>
        <Box id={ styles.contentBox }>
          <img
            src="images/undraw_message_sent_re_q2kl.svg"
            alt="resend img"
            id={ styles.image }
          ></img>
          <Typography
            variant="subtitle1"
            gutterBottom
            component="div"
            fontWeight="bold"
          >
            Verify your email by clicking on the link sent to your account.
          </Typography>
          <Button id={ styles.button }>resend link</Button>
        </Box>
      </Grid>
    </BoxWithoutIcon>
  );

};

export default ResendLink;
