import React, { useRef, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box, Button, Dialog,
  DialogContent, IconButton, TextField, Typography
} from "@mui/material";
import ErrorMessage from "../../../../components/ErrorMessage";
import styles from "./Cover.module.css";

import ImageOptions from "../ImageOptions";
import CoverEditVenture from "../PitchReferenceLinks/CoverEditVenture";
import CoverOneLiner from "../PitchReferenceLinks/CoverOneLiner";
import ReferenceLinkTooltip from "../PitchReferenceLinks/ReferenceLinkTooltip";

export default function Cover ({
  logoimg,
  slides,
  logo,
  coverImg,
  isRender,
  setSlides,
  // uploadedImgs,
  uploadImages,
  deleteUploadedImgs,
  setLogoimg,
  // isPublished,
  ...props
}) {

  const [ dialog, setDialog ] = useState(false);
  const [ profileDialog, setProfileDialog ] = useState(false);
  const slide = slides[isRender];

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };
  const ProfileDialogopen = () => {

    setProfileDialog(true);
  
  };
  const ProfileDialogclose = () => {

    setProfileDialog(false);
  
  };

  // for uploading logos
  const logoFileInput = useRef(null);

  const logohandleClick = () => {

    // console.log("clicking");
    logoFileInput.current.click();
  
  };

  // for uploading coverImg
  const coverImgFileInput = useRef(null);

  const coverImghandleClick = () => {

    props.setErrormsg("");
    coverImgFileInput.current.click();
  
  };

  // const preview = useMemo(() => {
  //   return logo ? URL.createObjectURL(logo) : null;
  // }, [logo]);

  // const previewCoverImg = useMemo(() => {
  //   return coverImg ? URL.createObjectURL(coverImg) : null;
  // }, [coverImg]);

  const handleChange = (name, value) => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    newSlides[isRender][name] = value;
    setSlides(newSlides);
  
  };

  // console.log("slided details", slide);

  return (
    <Box
      id={ styles.mainBox }
    // sx={{
    //   display: "flex",
    //   justifyContent: "space-between",
    //   // border: 1,
    //   height: "72vh",
    // }}
    >
      <Box
        id={ styles.leftContentBox }
      // sx={{
      //   display: "flex",
      //   justifyContent: "space-between",
      //   flexDirection: "column",
      //   marginTop: "3vh",
      //   marginBottom: "3vh",
      // }}
      >
        {/* tag and venture name box */ }
        { props.isEdit ?
          <>
            <Box
              // id={styles.iconBox}
              id={ styles.editIconBox }
            // sx={{
            //   width: "100px",
            //   height: "100px",
            //   border: "2px dotted #B4B4FA",
            //   borderRadius: "10px",
            //   // boxShadow: "0px 3px 14px #00000029",
            //   marginLeft: "5px",
            // }}
            // onClick={logohandleClick}
            >
              { logo?.imgUrl || logoimg ?
                <>
                  <Box
                    id={ styles.iconSubBox }
                  // sx={{ position: "absolute", right: "1px", top: "-1px" }}
                  >
                    <ImageOptions
                      IconSize="16px"
                      handleClick={ logohandleClick }
                      image={ logo?.imgUrl || logoimg }
                      deleteUploadedImgs={ () => {

                        deleteUploadedImgs("logo", logoimg ? true : false);
                        if (logoimg) setLogoimg("");
                        let newSlides = [ ...slides ];

                        newSlides.map((slide, slideIndex) => {

                          newSlides[slideIndex]["isChanged"] = true;
                        
                        });
                        setSlides(newSlides);
                      
                      } }
                    />
                  </Box>
                  {/* <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  > */}
                  <img
                    src={
                      logo?.imgUrl
                        ? logo?.imgUrl
                        : logoimg
                          ? logoimg
                          : logo?.imgUrl
                    }
                    style={ {
                      width       : "100%",
                      // height: "100%",
                      // objectFit: "cover",
                      borderRadius: "8px",
                      height      : "auto"
                    } }
                  />
                  {/* </Box> */ }
                </>
                :
                <Box
                  sx={ {
                    width         : "100%",
                    height        : "100%",
                    display       : "flex",
                    alignItems    : "center",
                    justifyContent: "center",
                    cursor        : "pointer"
                  } }
                  onClick={ logohandleClick }
                >
                  <img
                    src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/upload.png` }
                    style={ {
                      width : "50px",
                      height: "50px"
                    } }
                  />
                </Box>
              }
            </Box>
            <input
              type="file"
              id="logo"
              ref={ logoFileInput }
              onChange={ e => {

                uploadImages("logo", e.target.files[0]);
                let newSlides = [ ...slides ];

                newSlides.map((slide, slideIndex) => {

                  newSlides[slideIndex]["isChanged"] = true;
                
                });
                setSlides(newSlides);
              
              } }
              onClick={ e => {

                e.target.value = null;
              
              } }
              style={ { display: "none" } }
              accept="image/*"
            />
          </>
          :
          <Box
            // id={styles.iconBox}
            sx={ {
              width         : "80px",
              height        : "80px",
              border        : logoimg ? "" : "2px solid #B4B4FA",
              // border: "2px solid #B4B4FA",
              borderRadius  : "10px",
              // boxShadow: "0px 3px 14px #00000029",
              marginLeft    : "5px",
              display       : "flex",
              justifyContent: "center",
              alignItems    : "center"
            } }
          >
            { logo?.imgUrl || logoimg ?
              <img
                src={ logo?.imgUrl || logoimg }
                style={ {
                  width       : "100%",
                  height      : "auto",
                  // objectFit: "cover",
                  borderRadius: "8px"
                } }
              />
              :
              <Box
                id={ styles.subIconBox }
              // sx={{ width: "90%", textAlign: "center" }}
              >
                <Typography
                  id={ styles.logoBoxTypo }
                // sx={{ fontSize: "10px", fontWeight: "bold" }}
                >
                  Your logo goes here
                </Typography>
              </Box>
            }
          </Box>
        }

        <Box
          id={ styles.ventureBox }
        // sx={{
        //   // border: 1,
        //   paddingLeft: "5px",
        // }}
        >
          { props.isEdit ?
            <Box sx={ { width: "70%" } }>
              <TextField
                name="ventureName"
                onChange={ e => {

                  handleChange(e.target.name, e.target.value);
                
                } }
                value={ slide.ventureName }
                fullWidth
                inputProps={ { maxLength: 25 } }
                sx={ {
                  // "& .MuiOutlinedInput-root": {
                  //   padding: "0px 0px",
                  // },
                  "& .MuiInputBase-input": {
                    fontSize: "40px",
                    padding : "1px 10px",
                    border  : "none",
                    color   : "#393939"
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "2px dotted #B4B4FA"
                  }
                } }
              />
            </Box>
            :
            <Box sx={ { width: "70%" } }>
              <Typography
                id={ styles.ventureTypo }
              // sx={{ fontSize: "32px", color: "#707070" }}
              >
                { slide.ventureName }
              </Typography>
            </Box>
          }
          { props.isEdit ?
            <Box
              // id={styles.productTypoBox}
              sx={ {
                width       : "40vw",
                marginTop   : "1vh",
                marginBottom: "3.5vh"
                // border: 1,
              } }
            >
              <Box sx={ { width: "100%", position: "relative" } }>
                <TextField
                  name="oneLineDescription"
                  onChange={ e => {

                    handleChange(e.target.name, e.target.value);
                  
                  } }
                  value={ slide.oneLineDescription }
                  inputProps={ { maxLength: 90 } }
                  fullWidth
                  multiline
                  maxRows={ 3 }
                  minRows={ 3 }
                  sx={ {
                    "& .MuiOutlinedInput-root": {
                      padding: "0px 10px",
                      // border: "2px dotted #B4B4FA",
                      border : "none"
                    },
                    "& .MuiInputBase-input": {
                      fontSize: "22px",
                      // padding: "7px 10px",
                      // border: "none",
                      color   : "#393939"
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "2px dotted #B4B4FA"
                    }
                  } }
                />

                <ReferenceLinkTooltip
                  title={ "Revisit the notes created in Positioning Section." }
                >
                  <Button
                    style={ {
                      position      : "absolute",
                      textTransform : "none",
                      textDecoration: "underline",
                      fontSize      : "12px",
                      top           : "-21px",
                      right         : "0px",
                      padding       : "0px",
                      fontWeight    : "bold"
                    } }
                    onClick={ Dialogopen }
                  >
                    Positioning
                  </Button>
                </ReferenceLinkTooltip>

                <Dialog
                  open={ dialog }
                  onClose={ Dialogclose }
                  PaperProps={ {
                    style: {
                      borderRadius   : "20px",
                      backdropFilter : "blur(100px)",
                      backgroundColor: "#ffffff",
                      boxShadow      : "0px 3px 6px #00000029",
                      // border: "1px solid #707070",
                      width          : "70%"
                    }
                  } }
                  // BackdropProps={{ invisible: true }}
                  //
                  maxWidth="false"
                  fullWidth
                >
                  <DialogContent sx={ { padding: "40px" } }>
                    <Box
                      sx={ {
                        width   : "100%",
                        // height: "60vh",
                        // display: "flex",
                        // alignItems: "center",
                        // justifyContent: "center",
                        // border: 1,
                        position: "relative"
                      } }
                    >
                      <IconButton
                        onClick={ () => {

                          Dialogclose();
                        
                        } }
                        sx={ {
                          position: "absolute",
                          right   : "-40px",
                          top     : "-40px"
                        } }
                      >
                        <HighlightOffIcon />
                      </IconButton>
                      <CoverOneLiner />
                    </Box>
                  </DialogContent>
                </Dialog>
              </Box>
            </Box>
            :
            <Box
              id={ styles.productTypoBox }
            // sx={{
            //   width: "78%",
            //   marginTop: "1vh",
            //   marginBottom: "3.5vh",
            //   // border: 1,
            // }}
            >
              <Box sx={ { width: "85%" } }>
                <Typography
                  id={ styles.productTypo }
                // sx={{ fontSize: "22px", color: "#707070" }}
                >
                  { slide.oneLineDescription }
                </Typography>
              </Box>
            </Box>
          }

          <Box sx={ { width: "90%" } }>
            {/* {props.isEdit ? (
              <Box>
                <TextField
                  fullWidth
                  sx={{
                    // "& .MuiOutlinedInput-root": {
                    //   padding: "0px 0px",
                    // },
                    "& .MuiInputBase-input": {
                      fontSize: "14px",
                      padding: "1px 10px",
                      border: "none",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "2px dotted #B4B4FA",
                    },
                  }}
                />
              </Box>
            ) : (
              <Box>
                <Typography sx={{ fontSize: "14px" }}>
                  Website : some text
                </Typography>
              </Box>
            )} */}
            <Box sx={ { display: "flex", width: "100%" } }>
              <Box sx={ { width: "73px", marginBottom: "3px" } }>
                <Typography sx={ { fontSize: "14px", fontWeight: "bold" } }>
                  { " " }
                  Website :{ " " }
                </Typography>
              </Box>
              { props.isEdit ?
                <Box
                  sx={ { width: "65%", marginLeft: "3px", position: "relative" } }
                >
                  <TextField
                    name="website"
                    onChange={ e => {

                      handleChange(e.target.name, e.target.value);
                    
                    } }
                    inputProps={ { maxLength: 40 } }
                    placeholder="www.companyname.com"
                    value={ slide.website }
                    fullWidth
                    sx={ {
                      // "& .MuiOutlinedInput-root": {
                      //   padding: "0px 0px",
                      // },
                      "& .MuiInputBase-input": {
                        fontSize  : "14px",
                        padding   : "1px 10px",
                        border    : "none",
                        color     : "#707070",
                        fontWeight: "bold"
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "2px dotted #B4B4FA"
                      }
                    } }
                  />
                  <Button
                    style={ {
                      position      : "absolute",
                      textTransform : "none",
                      textDecoration: "underline",
                      fontSize      : "12px",
                      top           : "-20px",
                      right         : "-10px",
                      padding       : "0px",
                      fontWeight    : "bold"
                    } }
                    onClick={ ProfileDialogopen }
                  >
                    Profile
                  </Button>
                  <Dialog
                    open={ profileDialog }
                    onClose={ ProfileDialogclose }
                    PaperProps={ {
                      style: {
                        borderRadius   : "20px",
                        backdropFilter : "blur(100px)",
                        backgroundColor: "#ffffff",
                        boxShadow      : "0px 3px 6px #00000029",
                        // border: "1px solid #707070",
                        width          : "70%"
                      }
                    } }
                    // BackdropProps={{ invisible: true }}
                    //
                    maxWidth="false"
                    fullWidth
                  >
                    <DialogContent sx={ { padding: "40px" } }>
                      <Box
                        sx={ {
                          width   : "100%",
                          // height: "60vh",
                          // display: "flex",
                          // alignItems: "center",
                          // justifyContent: "center",
                          // border: 1,
                          position: "relative"
                        } }
                      >
                        <CoverEditVenture />

                        <IconButton
                          onClick={ () => {

                            ProfileDialogclose();
                          
                          } }
                          sx={ {
                            position: "absolute",
                            right   : "-40px",
                            top     : "-50px"
                          } }
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      </Box>
                    </DialogContent>
                  </Dialog>
                </Box>
                :
                <Box sx={ { width: "25vw" } }>
                  <Typography sx={ { fontSize: "14px", fontWeight: "bold" } }>
                    { " " }
                    { slide.website }
                  </Typography>
                </Box>
              }
            </Box>

            <Box sx={ { display: "flex" } }>
              <Box sx={ { width: "73px" } }>
                <Typography sx={ { fontSize: "14px", fontWeight: "bold" } }>
                  Email :{ " " }
                </Typography>
              </Box>
              { props.isEdit ?
                <Box sx={ { width: "65%", marginLeft: "3px" } }>
                  <TextField
                    name="email"
                    onChange={ e => {

                      handleChange(e.target.name, e.target.value);
                    
                    } }
                    inputProps={ { maxLength: 40 } }
                    value={ slide.email }
                    placeholder="info@companyname.com"
                    fullWidth
                    sx={ {
                      // "& .MuiOutlinedInput-root": {
                      //   padding: "0px 0px",
                      // },
                      "& .MuiInputBase-input": {
                        fontSize  : "14px",
                        padding   : "1px 10px",
                        border    : "none",
                        color     : "#707070",
                        fontWeight: "bold"
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "2px dotted #B4B4FA"
                      }
                    } }
                  />
                </Box>
                :
                <Box sx={ { width: "25vw" } }>
                  <Typography sx={ { fontSize: "14px", fontWeight: "bold" } }>
                    { " " }
                    { slide.email }
                  </Typography>
                </Box>
              }
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        {/* image  box */ }
        <Box
          id={ styles.imgBox }
        // sx={{
        //   width: "18vw",
        //   border: "1px solid #B4B4FA",
        //   // height: "100%",
        //   marginRight: "10px",
        //   borderRadius: "5px",
        //   backgroundColor: "#B4B4FA",
        //   marginTop: "3vh",
        //   marginBottom: "3vh",
        //   height: "65.9vh",
        // }}
        >
          { props.isEdit ?
            <>
              { coverImg?.imgUrl || slide.coverImg ?
                <Box
                  sx={ {
                    display       : "flex",
                    justifyContent: "center",
                    alignItems    : "center",
                    width         : "100%",
                    height        : "100%"
                  } }
                >
                  <img
                    src={
                      coverImg?.imgUrl
                        ? coverImg?.imgUrl
                        : slide.coverImg
                          ? slide.coverImg
                          : coverImg?.imgUrl
                    }
                    style={ {
                      width    : "100%",
                      height   : "100%",
                      objectFit: "cover"
                    } }
                  />
                  <Box
                    id={ styles.iconSubBox }
                  // sx={{
                  //   position: "absolute",
                  //   top: "10px",
                  //   left: "10px",
                  //   // border: 1,
                  //   width: "50%",
                  //   borderRadius: "5px",
                  //   display: "flex",
                  //   justifyContent: "space-evenly",
                  //   alignItems: "center",
                  //   backgroundColor: "#FFFFFF",
                  //   borderRadius: "5px",
                  // }}
                  // onClick={coverImghandleClick}
                  >
                    <ImageOptions
                      IconSize="22px"
                      handleClick={ coverImghandleClick }
                      image={ coverImg?.imgUrl || slide.coverImg }
                      deleteUploadedImgs={ () => {

                        let newSlides = [ ...slides ];

                        newSlides[isRender]["isChanged"] = true;
                        setSlides(newSlides);
                        deleteUploadedImgs(
                          `${slide.renderName}.coverImg`,
                          slide.coverImg ? true : false
                        );
                        if (slide.coverImg) handleChange("coverImg", "");
                      
                      } }
                    />
                  </Box>
                </Box>
                :
                <Box
                  sx={ {
                    width         : "100%",
                    height        : "100%",
                    display       : "flex",
                    flexDirection : "column",
                    alignItems    : "center",
                    justifyContent: "center"
                  } }
                >
                  { props.errorMsg &&
                    <ErrorMessage errormsg={ props.errorMsg } />

                  }
                  <Typography>Upload a cover image.</Typography>
                  <Button
                    variant="contained"
                    style={ {
                      fontSize     : "14px",
                      textTransform: "none",
                      // padding: "10px 19px",
                      margin       : "10px"
                    } }
                    disabled={ props.isEdit ? false : true }
                    onClick={ coverImghandleClick }
                  >
                    Upload Image
                  </Button>

                  <Typography sx={ { fontSize: "12px" } }>
                    { "Image must be .png or .jpg format and < 10MB." }
                  </Typography>
                </Box>
              }

              <input
                type="file"
                id="coverImg"
                ref={ coverImgFileInput }
                onChange={ e => {

                  uploadImages(
                    `${slide.renderName}.coverImg`,
                    e.target.files[0]
                  );
                  let newSlides = [ ...slides ];

                  newSlides[isRender]["isChanged"] = true;
                  setSlides(newSlides);
                
                } }
                onClick={ e => {

                  e.target.value = null;
                
                } }
                style={ { display: "none" } }
                accept="image/*"
              />
            </>
            : slide.coverImg ?
              <Box
                sx={ {
                  display       : "flex",
                  justifyContent: "center",
                  alignItems    : "center",
                  width         : "100%",
                  height        : "100%"
                } }
              >
                <img
                  src={ slide.coverImg }
                  style={ {
                  // sdsdsdsd
                    width    : "100%",
                    height   : "100%",
                    objectFit: "cover"
                  } }
                />
              </Box>
              :
              <></>
          }
        </Box>
      </Box>

      {/* --copyright message--- */ }
    </Box>
  );

}
