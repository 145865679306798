import {
  Box, Button, Dialog,
  DialogContent, LinearProgress,
  Menu, MenuItem, TextField, Tooltip,
  Typography
} from "@mui/material";
import React, { useRef, useState } from "react";
import axiosService from "../../../../../services/axiosService";


import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ErrorMessage from "../../../../../components/ErrorMessage";
import {
  deleteRevenueStreamImages
} from "../../../../../services/monetisation";
import styles from "./ProposePricingPlan.module.css";

const LightTooltip = styled(({ className, ...props }) =>
  <Tooltip {...props} classes={{ popper: className }} />
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    // color: "rgba(0, 0, 0, 0.87)",
    boxShadow      : theme.shadows[1],
    fontSize       : "14px",
    color          : "#FF7F00",
    border         : "1px solid #FF7F00",
    paddingTop     : "5px 10px",
    borderRadius   : "10px",
    fontWeight     : "bold"
  }
}));

export default function ProposePricingPlan(props) {

  const [ dialog, setDialog ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ isFileTooLarge, setIsFileTooLarge ] = useState(false);
  const [ progress, setProgress ] = useState(0);
  const [ successmsg, setSuccessmsg ] = useState("");
  const [ errormsg, setErrormsg ] = useState("");
  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ imgDialog, setImgDialog ] = useState(false);

  const open = Boolean(anchorEl);

  const {  venture } = useSelector(state => ({ ...state }));

  const revenueStreams = props?.revenueStreams;
  const revenueStreamIndex = props?.revenueStreamIndex;

  const handleChangePricingPlan = (e, revenueStreamIndex) => {

    const value = e.target.value;
    let newRevenueStreams = [ ...revenueStreams ];

    newRevenueStreams[revenueStreamIndex]["pricingPlan"] = value;
    props.setRevenueStreams(newRevenueStreams);
  
  };

  const history = useHistory();

  let dropzoneRef = useRef(null);

  const imgDialogopen = () => {

    setImgDialog(true);
  
  };
  const imgDialogclose = () => {

    setImgDialog(false);
  
  };

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  const handleClick = event => {

    setAnchorEl(event.currentTarget);
  
  };
  const handleClose = () => {

    setAnchorEl(null);
  
  };

  const onDrop = async (acceptedFiles, rejectionFiles) => {

    setLoading(true);
    try {

      if (rejectionFiles.length > 0 && rejectionFiles[0].file.size > 2097152) {

        setIsFileTooLarge(true);
        setLoading(false);
        setTimeout(() => {

          setIsFileTooLarge(false);
        
        }, 5000);
        
        return;
      
      }
      const formdata = new FormData();

      formdata.append("pricingImage", acceptedFiles[0]);
      formdata.append("monetisationId", venture.monetisation);
      formdata.append("revenueStreamIndex", revenueStreamIndex);
      const response = await axiosService.post(
        `${process.env.REACT_APP_ADDRESS}/upload_revenuestream_pricingimage`,
        formdata,
        {
          onUploadProgress: ProgressEvent => {

            const { loaded, total } = ProgressEvent;
            const percentage = Math.floor(
              ((loaded / 1000) * 100) / (total / 1000)
            );

            setProgress(percentage);
          
          }
        }
      );

      setSuccessmsg(response.data);
      props.handleUpdateMonetisationDetails();
      await props.fetchMonetisation();
      setLoading(false);
      setSuccessmsg("");
      Dialogclose();
    
    } catch (error) {

      console.error(error);
      setErrormsg("File type not accepted");
      setLoading(false);
      setTimeout(() => {

        setErrormsg("");
      
      }, 5000);
    
    }
  
  };

  const deleteImage = async () => {

    try {

      await deleteRevenueStreamImages(revenueStreamIndex, venture.monetisation);
      props.handleUpdateMonetisationDetails();
      props.fetchMonetisation();
      handleClose();
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  return (
    // <CreatePricingComponent barHighlight="bar7">
    <Box>
      {/* top typo box */}
      <Box>
        <Typography
          id={styles.topHeadTypo}
        // sx={{ color: "#5F5E5E" }}
        >
          Propose a pricing plan
        </Typography>
        <Box
          id={styles.topTypoBox}
        // sx={{ width: "100%" }}
        >
          <Typography
            id={styles.topTypo}
          // sx={{ color: "#868686", fontSize: "13px" }}
          >
            At this point you should feel ready to propose a pricing model with
            price points for your revenue stream. Summarize it in the box below,
            and you may also upload an image that shows more details of your
            pricing plan.
          </Typography>
        </Box>
      </Box>

      {/* text field button box */}
      <Box
        id={styles.textFieldButtonFlex}
      // sx={{ width: "100%", display: "flex", marginTop: "2vh" }}
      >
        <Box
          id={styles.textFieldBox}
        // sx={{ width: "80%" }}
        >
          <TextField
            onChange={e => {

              handleChangePricingPlan(e, revenueStreamIndex);
            
            }}
            disabled={venture.userRole === "Commenter" ? true : false}
            value={revenueStreams[revenueStreamIndex]["pricingPlan"]}
            fullWidth
            multiline
            maxRows={13}
            minRows={13}
            sx={{
              "& .MuiOutlinedInput-root": {
                padding: "14.5px 16px"
              },
              "& .MuiInputBase-input": {
                fontSize  : "12px",
                color     : "#707070",
                lineHeight: "17px"
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #E1E2E9"
              }
            }}
          />
        </Box>
        <Box
          id={styles.buttonMainBox}
        >
          {/* ------- */}
          {/* top button box */}
          <Box
            id={styles.topButtonBox}
          >
            <Box
              id={styles.topSubBox}
            >
              <Box>
                <LightTooltip placement="top" title="Coming soon">
                  <Button
                    id={styles.downloadTempButton}
                  >
                    Download Template
                  </Button>
                </LightTooltip>
              </Box>
            </Box>
            <Box
              id={styles.uploadFileBox}
            >
              <Box>
                {revenueStreams[revenueStreamIndex]["pricingImage"] ?
                  <>
                    <Button
                      id={styles.uploadFileButton}
                      onClick={e => {

                        handleClick(e);
                      
                      }}
                    >
                      {revenueStreams[revenueStreamIndex]["pricingImageName"]}
                    </Button>

                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={() => {

                        handleClose();
                      
                      }}
                      style={{ top: -34, left: -10 }}
                      anchorOrigin={{
                        vertical  : "top",
                        horizontal: "right"
                      }}
                      sx={{
                        "& .MuiMenu-list": {
                          padding        : "0px 6px",
                          backgroundColor: "#707070",
                          color          : "white"
                        }
                      }}
                    >
                      <MenuItem
                        onClick={() => {

                          venture.userRole === "Commenter"
                            ? console.info("commentor")
                            : deleteImage();
                        
                        }}
                        sx={{
                          fontSize    : "12px",
                          padding     : "3px",
                          textAlign   : "center",
                          borderBottom: "1px solid #ffffff",
                          color:
                            venture.userRole === "Commenter"
                              ? "#A0A0A0"
                              : "#ffffff"
                        }}
                      >
                        Delete File
                      </MenuItem>

                      <MenuItem
                        onClick={() => {

                          imgDialogopen();
                        
                        }}
                        sx={{
                          padding       : "3px",
                          textAlign     : "center",
                          display       : "flex",
                          justifyContent: "center"
                        }}
                      >
                        <Typography sx={{ fontSize: "12px", color: "#ffffff" }}>
                          View File
                        </Typography>
                      </MenuItem>

                      <Dialog
                        open={imgDialog}
                        onClose={imgDialogclose}
                        PaperProps={{
                          style: {
                            borderRadius   : "20px",
                            backdropFilter : "blur(100px)",
                            backgroundColor: "#ffffff",
                            boxShadow      : "0px 3px 6px #00000029",
                            border         : "1px solid #707070",
                            width          : "60%"
                          }
                        }}
                        BackdropProps={{ invisible: true }}
                        //
                        maxWidth="false"
                        fullWidth
                      >
                        <DialogContent sx={{ padding: "10px" }}>
                          <Box
                            sx={{
                              width         : "100%",
                              height        : "60vh",
                              display       : "flex",
                              alignItems    : "center",
                              justifyContent: "center"
                            }}
                          >
                            <img
                              src={
                                revenueStreams[revenueStreamIndex].pricingImage
                              }
                              alt=""
                              style={{ width: "500px", height: "300px" }}
                            />
                          </Box>
                        </DialogContent>
                      </Dialog>
                    </Menu>
                  </>
                  :
                  <Button
                    id={styles.uploadFileButton}
                    onClick={() => {

                      Dialogopen();
                    
                    }}
                    disabled={venture.userRole === "Commenter" ? true : false}
                  >
                    <CloudUploadIcon
                      id={styles.uploadIcon}
                    />
                    Upload a File
                  </Button>
                }

                {/* dialog for image---------- */}
                <Dialog
                  open={dialog}
                  // onClick={Dialogclose}
                  onClose={Dialogclose}
                  PaperProps={{
                    style: {
                      borderRadius   : "20px",
                      backdropFilter : "blur(100px)",
                      backgroundColor: "#ffffff",
                      boxShadow      : "0px 3px 6px #00000029",
                      border         : "1px solid #707070"
                    }
                  }}
                  maxWidth="xs"
                  fullWidth
                >
                  <DialogContent sx={{ padding: "20px" }}>
                    <Dropzone
                      ref={node => {

                        dropzoneRef = node;
                      
                      }}
                      onDrop={onDrop}
                      accept="image/*"
                      minSize={0}
                      maxSize={2097152}
                    >
                      {({
                        getRootProps,
                        getInputProps
                        // isDragActive,
                        // isDragReject,
                        // fileRejections
                      }) => {

                        return (
                          <Box
                            sx={{
                              height        : "108px",
                              border        : "1px dotted blue",
                              borderRadius  : "5px",
                              display       : "flex",
                              justifyContent: "center",
                              alignItems    : "center"
                            }}
                            {...getRootProps()}
                          >
                            {loading && !isFileTooLarge && !errormsg ?
                              <LinearProgress
                                variant="determinate"
                                value={progress}
                              />
                              : errormsg ?
                                <ErrorMessage
                                  errormsg={"File type not accepted"}
                                />
                                : isFileTooLarge ?
                                  <ErrorMessage
                                    errormsg={
                                      "File is too large. Upload a file under 2mb size "
                                    }
                                  />
                                  :
                                  <Typography
                                    sx={{ fontSize: "12px", fontWeight: "bold" }}
                                  >
                                drop your files here
                                  </Typography>
                            }

                            {successmsg &&
                              <Box
                                sx={{
                                  display       : "flex",
                                  width         : "100%",
                                  alignItems    : "center",
                                  justifyContent: "center"
                                }}
                              >
                                <CheckCircleOutlineIcon
                                  sx={{ color: "#108E00" }}
                                  fontSize="small"
                                />
                                <Typography
                                  sx={{ fontSize: "13px", marginLeft: "10px" }}
                                >
                                  {successmsg}
                                </Typography>
                              </Box>
                            }
                            <input {...getInputProps()} />
                          </Box>
                        );
                      
                      }}
                    </Dropzone>

                    <Box
                      sx={{
                        display       : "flex",
                        justifyContent: "center",
                        alignItems    : "center",
                        marginTop     : "10px",
                        marginBottom  : "10px"
                      }}
                    >
                      <Typography sx={{ fontWeight: "bold", fontSize: "13px" }}>
                        OR
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display       : "flex",
                        justifyContent: "center",
                        alignItems    : "center",
                        flexDirection : "column"
                      }}
                    >
                      <Button
                        variant="contained"
                        style={{ textTransform: "none" }}
                        onClick={e => {

                          e.preventDefault();
                          dropzoneRef.open();
                        
                        }}
                      >
                        Upload File
                      </Button>
                      <Typography
                        sx={{
                          fontSize : "10px",
                          color    : "#5F5E5E",
                          marginTop: "7px"
                        }}
                      >
                        Supports: .png,.jpeg upto 2MB
                      </Typography>
                    </Box>
                  </DialogContent>
                </Dialog>
                {/* ------------- */}
              </Box>
            </Box>
          </Box>
          {/* ------- */}
          <Box
            id={styles.doneButtonBox}
          // sx={{
          //   display: "flex",
          //   justifyContent: "flex-end",
          //   margin: "0px 8px",
          // }}
          >
            <Button
              id={styles.doneButton}
              variant="contained"
              // sx={{ textTransform: "none", padding: "4px 30px" }}
              onClick={() => {

                props.handleUpdateMonetisationDetails();
                history.push("/pricing");
              
              }}
            >
              Done
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
    // </CreatePricingComponent>
  );

}
