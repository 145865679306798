import AddIcon from "@mui/icons-material/Add";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";

import {
  createNewList, getCardsByProblemSolutionId,
  getListsByProblemSolutionId, updateCardById, updateListById
} from "../../../../services/stickynotes";

import styles from "../Problem.module.css";

import AddItem from "./AddItem";
import InputCard from "./InputCard";
import midString from "./ordering";
import List from "./StickyNotesList";

const useStyles = makeStyles(theme => ({
  root: {
    flexDirection: "column",
    overflow     : "auto",
    OverflowY    : "hidden",

    height: props => props.isexpand ? "90%" : "70%"
  },
  listContainer: {
    display  : "flex",
    marginTop: theme.spacing(0.5)
  },
  wrapper: {
    marginTop: theme.spacing(1)
  },
  editable: {
    marginLeft     : theme.spacing(1),
    height         : "38px",
    padding        : theme.spacing(0, 1, 0, 1),
    boxShadow      : "inset 0 0 0 2px #0079bf",
    borderRadius   : 6,
    backgroundColor: "#EBECF0",
    position       : "fixed",
    marginTop      : theme.spacing(4.5)
  }
}));

const StickyNotesBoard = props => {

  const classes = useStyles({ isexpand: props.isexpand });
  const [ lists, setLists ] = useState([]);
  const [ cards, setCards ] = useState([]);
  const [ initialData, setInitialData ] = useState({});
  const [ initDone, setInitDone ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const addFlag = useRef(true);
  const [ addListFlag, setAddListFlag ] = useState(false);
  const [ listTitle, setListTitle ] = useState("");

  let { venture } = useSelector(state => {

    // console.log(state);
    return { ...state };
  
  });

  useEffect(() => {

    fetchListsCards();
  
  }, []);

  useEffect(() => {

    if (!loading) {

      const prevState = { tasks: {}, columns: {}, columnOrder: [] };
      const getTaskIds = id => {

        const filteredTasks = _.filter(cards, { stickylistId: id });
        const sortedTasks = _.orderBy(filteredTasks, [ "order" ], [ "asc" ]);
        const taskIds = [];

        sortedTasks.forEach(task => taskIds.push(task._id));
        
        return taskIds;
      
      };

      const setContent = () => {

        cards.forEach(card => {prevState.tasks[card._id] = card;});
        const sortedLists = _.orderBy(lists, [ "order" ], [ "asc" ]);

        sortedLists.forEach(list => {

          prevState.columns[list._id] = {
            ...list,
            taskIds: getTaskIds(list._id)
          };
          prevState.columnOrder.push(list._id);
        
        });
      
      };

      setContent();
      setInitialData({ ...prevState });
      setInitDone(true);
    
    }
  
  }, [
    setInitDone, loading, setInitialData, cards, lists
  ]);

  const fetchListsCards = async () => {

    try {

      setLoading(true);
      const listresponse = await getListsByProblemSolutionId(
        venture.problemSolution
      );

      setLists(listresponse.data);
      const cardresponse = await getCardsByProblemSolutionId(
        venture.problemSolution
      );

      setCards(cardresponse.data);
      setLoading(false);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const onDragEnd = async result => {

    var newOrder;
    const { destination, source, draggableId, type } = result;

    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    if (type === "list") {

      const listOrder = initialData.columnOrder;
      // dragged towards the first position

      if (destination.index === 0) {

        newOrder = midString("", initialData.columns[listOrder[0]].order);
      
      } else if (destination.index === listOrder.length - 1) {

        // dragged towards the last  position

        newOrder = midString(
          initialData.columns[listOrder[destination.index]].order,
          ""
        );
      
      } else if (destination.index < source.index) {

        // dragged towards the  left position

        newOrder = midString(
          initialData.columns[listOrder[destination.index - 1]].order,
          initialData.columns[listOrder[destination.index]].order
        );
      
      } else {

        // dragged towards the  right position

        newOrder = midString(
          initialData.columns[listOrder[destination.index]].order,
          initialData.columns[listOrder[destination.index + 1]].order
        );
      
      }
      const newListOrder = Array.from(initialData.columnOrder);
      const destinationColumn = initialData.columns[draggableId];

      destinationColumn.order = newOrder;
      newListOrder.splice(source.index, 1);
      newListOrder.splice(destination.index, 0, draggableId);
      const newData = {
        ...initialData,
        columnOrder: newListOrder,
        columns    : {
          ...initialData.columns,
          draggableId: destinationColumn
        }
      };
      // console.log("order =====>", newOrder, newListOrder);

      setInitialData(newData);
      await updateListById({ order: newOrder }, draggableId);
      
      return;
    
    }
    const startList = initialData.columns[source.droppableId];
    const endList = initialData.columns[destination.droppableId];

    if (startList === endList) {

      const column = startList;

      if (destination.index === 0)
        newOrder = midString("", initialData.tasks[column.taskIds[0]].order);
      else if (destination.index === column.taskIds.length - 1)
        newOrder = midString(
          initialData.tasks[column.taskIds[destination.index]].order,
          ""
        );
      else if (destination.index < source.index)
        newOrder = midString(
          initialData.tasks[column.taskIds[destination.index - 1]].order,
          initialData.tasks[column.taskIds[destination.index]].order
        );
      else
        newOrder = midString(
          initialData.tasks[column.taskIds[destination.index]].order,
          initialData.tasks[column.taskIds[destination.index + 1]].order
        );
      const newTaskIds = Array.from(column.taskIds);

      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);
      const destinationTask = initialData.tasks[draggableId];

      destinationTask.order = newOrder;
      const newColumn = {
        ...column,
        taskIds: newTaskIds
      };
      const newData = {
        ...initialData,
        columns: {
          ...initialData.columns,
          [newColumn._id]: newColumn
        },
        tasks: {
          ...initialData.tasks,
          draggableId: destinationTask
        }
      };

      setInitialData(newData);
      await updateCardById({ order: newOrder }, draggableId);
      
      return;
    
    }

    // Move from one list to another
    if (endList.taskIds.length === 0) newOrder = "n";
    else if (destination.index === 0) {

      newOrder = midString("", initialData.tasks[endList.taskIds[0]].order);
    
    } else if (destination.index === endList.taskIds.length)
      newOrder = midString(
        initialData.tasks[endList.taskIds[destination.index - 1]].order,
        ""
      );
    else
      newOrder = midString(
        initialData.tasks[endList.taskIds[destination.index - 1]].order,
        initialData.tasks[endList.taskIds[destination.index]].order
      );
    const startTaskIds = Array.from(startList.taskIds);

    startTaskIds.splice(source.index, 1);
    const newStartList = {
      ...startList,
      taskIds: startTaskIds
    };
    const destinationTask = initialData.tasks[draggableId];

    destinationTask.order = newOrder;
    const endTaskIds = Array.from(endList.taskIds);

    endTaskIds.splice(destination.index, 0, draggableId);
    const newEndList = {
      ...endList,
      taskIds: endTaskIds
    };
    const newData = {
      ...initialData,
      columns: {
        ...initialData.columns,
        [newStartList._id]: newStartList,
        [newEndList._id]  : newEndList
      },
      tasks: {
        ...initialData.tasks,
        draggableId: destinationTask
      }
    };

    setInitialData(newData);
    await updateCardById(
      { order: newOrder, stickylistId: endList._id },
      draggableId
    );
  
  };

  const handleChange = e => {

    e.preventDefault();
    setListTitle(e.target.value);
  
  };

  const submitHandler = async () => {

    if (listTitle === "") return;
    const text = listTitle.trim().replace(/\s+/g, " ");

    if (text === "") {

      setListTitle(listTitle);
      
      return;
    
    }
    const totalLists = initialData.columnOrder.length;
    const postListReq = {
      name             : text,
      problemsolutionId: venture.problemSolution,
      parentVenture    : venture._id,
      order:
        totalLists === 0
          ? "n"
          : midString(
            initialData.columns[initialData.columnOrder[totalLists - 1]]
              .order,
            ""
          )
    };

    await createNewList(postListReq);
    setListTitle("");
    fetchListsCards();
  
  };

  const handleKeyDown = e => {

    if (e.key === "Enter") {

      e.preventDefault();
      submitHandler();
    
    }
  
  };

  const closeButtonHandler = () => {

    setAddListFlag(false);
    addFlag.current = true;
    setListTitle("");
  
  };

  const handleAddition = () => {

    setAddListFlag(true);
    addFlag.current = false;
  
  };

  // console.log(lists, cards);
  // console.log(initialData);

  return (
    <Grid container id={ styles.notesBoardMainGrid } >
      <DragDropContext onDragEnd={ onDragEnd }>
        <Droppable droppableId="all-columns" direction="horizontal" type="list">
          { provided =>
            <div
              className={ classes.listContainer }
              { ...provided.droppableProps }
              ref={ provided.innerRef }
            >
              { initDone &&
                initialData.columnOrder.map((columnId, index) => {

                  const column = initialData.columns[columnId];
                  const tasks = column.taskIds.map(
                    taskId => initialData.tasks[taskId]
                  );

                  
                  return (
                    <List
                      key={ column._id }
                      column={ column }
                      tasks={ tasks }
                      index={ index }
                      fetchListsCards={ fetchListsCards }
                    />
                  );
                
                }) }
              <div className={ classes.wrapper }>
                { addFlag.current &&
                  <AddItem
                    handleClick={ handleAddition }
                    btnText="Add a list"
                    type="list"
                    icon={ <AddIcon /> }
                    width="256px"
                    color="#5E5E5E"
                    isdisabled={ venture.userRole === "Commenter" }
                  />
                }
                { addListFlag &&
                  <InputCard
                    value={ listTitle }
                    changedHandler={ handleChange }
                    itemAdded={ submitHandler }
                    closeHandler={ closeButtonHandler }
                    keyDownHandler={ handleKeyDown }
                    type="list"
                    btnText="Add List"
                    placeholder="Enter list title..."
                    width="230px"
                    marginLeft="1"
                  />
                }
              </div>
              { provided.placeholder }
            </div>
          }
        </Droppable>
      </DragDropContext>
    </Grid>
  );

};

export default StickyNotesBoard;
