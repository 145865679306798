import {
  Box, Button,
  Grid, TextField, Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ErrorMessage from "../../../components/ErrorMessage";
import { auth } from "../../../firebase";
import { sendVerificationEmail, userVerified } from "../../../services/auth";

import styles from "./Register.module.css";

const ResendPage = ({ history }) => {

  const [ errorflag, setErrorflag ] = useState(false);
  const hashvalue = history.location.hash.substring(1);
  const useremail = window.localStorage.getItem("emailForRegistration");
  const [ email, setEmail ] = useState(
    hashvalue === "forgotpassword" ? "" : useremail ? useremail : ""
  );
  const [ errormsg, setErrormsg ] = useState("");

  const config = {
    url            : `${window.location.origin}${process.env.REACT_APP_REGISTER_REDIRECT_URL}`,
    handleCodeInApp: true
  };

  useEffect(() => {

    if (!errorflag) {

      setErrormsg("");
    
    }
  
  }, [ errorflag ]);

  const handleSubmit = async e => {

    try {

      // preventing browser from reload
      e.preventDefault();
      if (email && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {

        const response = await userVerified(email);

        if (response.data === "User Verified Already") {

          setErrormsg("An account already exists with this email ID.");
          setErrorflag(true);
          
          return;
        
        }
        await sendVerificationEmail(email, config);
        // save user email in local storage - Redoing again without asking email again

        window.localStorage.setItem("emailForRegistration", email);

        history.push("/resendlink");
      
      } else {

        setErrormsg("Please enter a valid email ID.");
        setErrorflag(true);
      
      }
    
    } catch (error) {

      console.error(error);
      setErrormsg("Please enter a valid email ID.");
      setErrorflag(true);
    
    }
  
  };

  const forgothandleSubmit = async e => {

    try {

      e.preventDefault();
      if (email && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {

        const response = await userVerified(email);

        if (response.data === "User Not Verified") {

          setErrormsg(
            "We couldn’t find an account with that email ID. Please try a different ID."
          );
          setErrorflag(true);
          
          return;
        
        }
        const config = {
          url            : `${window.location.origin}${process.env.REACT_APP_FORGOT_PASSWORD_REDIRECT}`,
          handleCodeInApp: true
        };

        await auth
          .sendPasswordResetEmail(email, config)
          .then(() => {

            //
            setEmail("");
          
          })
          .catch(error => {

            console.error(error.message);
          
          });
        history.push({
          pathname: "/resendlink",
          hash    : "forgotpassword"
        });
      
      } else {

        setErrormsg("Please enter a valid email ID.");
        setErrorflag(true);
      
      }
    
    } catch (error) {

      console.error(error);
      setErrormsg("Please enter a valid email ID.");
      setErrorflag(true);
    
    }
    //
  
  };

  return (
    <div style={ { height: "100%" } }>
      <Box id={ styles.mainbox }>
        <Grid container id={ styles.dialogmaingrid }>
          { errormsg && <ErrorMessage errormsg={ errormsg } width="375px" /> }
          <Box
            component="form"
            onSubmit={
              hashvalue === "forgotpassword" ? forgothandleSubmit : handleSubmit
            }
            sx={ {
              "& .MuiTextField-root": {
                width : "375px",
                height: "50px"
              },
              marginTop: "30px"
            } }
            noValidate
            autoComplete="off"
          >
            <Typography
              variant="h5"
              gutterBottom
              sx={ { fontWeight: "bold", marginBottom: "20px" } }
            >
              { hashvalue === "forgotpassword"
                ? "Resend Password Reset Link"
                : "Resend Verification Link" }
            </Typography>
            <div>
              <TextField
                error={ errormsg ? true : false }
                type="email"
                label="Your email address"
                autoFocus
                fullWidth
                value={ email }
                onChange={ e => {

                  setErrorflag(false);
                  setEmail(e.target.value);
                
                } }
              />
            </div>
            <Button
              id={ styles.dialogbtn }
              variant="contained"
              disabled={ !email }
              type="submit"
            >
              { hashvalue === "forgotpassword"
                ? "Resend Password Reset Link"
                : "Resend Verification Link" }
            </Button>
          </Box>
        </Grid>
      </Box>
    </div>
  );

};

export default ResendPage;
