const Config = {

  chartMoreDetails: {
    plOutLook   : { "page": "P&L Outlook", "url": "/ploutlook" },
    frOutLook   : { "page": "Funding & Runway", "url": "/fundingrunway" },
    cashFlow    : { "page": "Cash Flow", "url": "/cashflow" },
    balanceSheet: { "page": "Balance Sheet", "url": "/balancesheet" }
  },
  chartTitleList: [
    "Gross Revenue Progression",
    "Gross Vs Operation Margin by Months",
    "Customer Base Growth",
    "Operating Costs Breakdown",
    "Total Cost by Months",
    "Revenue Progression by Years",
    "Gross Vs Operation Margin by Years",
    "Burn Rate Vs Funding Balance",
    "Runway Vs Funding Balance",
    "Total Cash Flow Progression",
    "Cash Flow Break Down",
    "Balance sheet"
  ],
  userAccess: {
    freetier: "freetier",
    ideation: "ideation",
    pitch   : "pitch",
    admin   : "admin",
    pro     : "pro"
  },

  mobileViewCheck: /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i



};

export default Config;
