import React, { useEffect, useState } from "react";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box, Button, Grid, Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import CustomMultilineTextfield from "../../../../components/CustomMultilineTextfield";
import HelpIconTooltip from "../../../../components/HelpIconTooltip";
import jsondata from "../../../../Data/Market.json";
import { getMarketdetails } from "../../../../services/market";

export default function PitchOpportunityMarket() {

  const [ details, setDetails ] = useState({
    tabOne: {
      products    : "",
      primaryUsers: "",
      keyProducers: ""
    },
    tabTwo: {
      financialStats   : "",
      productionVolumes: "",
      otherStates      : ""
    },
    tabThree: {
      technology   : "",
      social       : "",
      political    : "",
      regulatory   : "",
      economic     : "",
      environmental: ""
    }
  });

  const [ factFigureRender, setFactFigureRender ] = useState(0);
  const { venture } = useSelector(state => ({ ...state }));

  const data = jsondata.marketfactsfigures;
  const content = data["tabThree"];

  // console.log(content);

  useEffect(() => {

    fetchMarketdetails();
  
  }, []);

  const fetchMarketdetails = async () => {

    try {

      const response = await getMarketdetails(venture.market);
      const market = response.data.market;

      setDetails(market.factsFigures);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  // console.log(details);

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box>
          <Typography
            sx={{ fontSize: "20px", color: "#707070", fontWeight: "bold" }}
          >
            Market
          </Typography>
        </Box>

        <ChevronRightIcon sx={{ color: "gray" }} />

        <Box>
          <Typography>Facts & Figures</Typography>
        </Box>
      </Box>

      <Box>
        <Typography sx={{ fontSize: "12px" }}>
          Jot down some key characteristics that help describe the industry.
        </Typography>
      </Box>

      {factFigureRender === 0 &&
        <Box sx={{ width: "100%", height: "46vh" }}>
          <Box sx={{ width: "100%", display: "flex", marginTop: "20px" }}>
            <Box sx={{ width: "40%" }}>
              <Typography sx={{ fontSize: "13px" }}>
                What products/ services are traded in this industry?
                <HelpIconTooltip
                  tooltipdata={
                    "Also consider mentioning these points: \n1. Can you describe at a high level, the value chain, i.e. the primary activities involved in producing, supplying, delivering the products/ services?\n2. How mature is the industry?"
                  }
                  width="500px"
                />
              </Typography>
            </Box>
            <Box sx={{ width: "60%" }}>
              <CustomMultilineTextfield
                width="100%"
                borderRadius="10px"
                // fontSize="14px"
                maxRows={2}
                minRows={2}
                // name={data.name}
                value={details.tabOne.products}
                // handledetailsChange={handledetailsChange}
                disabled={true}
              />
            </Box>
          </Box>
          <Box sx={{ width: "100%", display: "flex", marginTop: "10px" }}>
            <Box sx={{ width: "40%" }}>
              <Typography sx={{ fontSize: "13px" }}>
                Describe the primary buyers/ users?
                <HelpIconTooltip
                  tooltipdata={
                    "Highlight key characteristics of buyers in this industry for e.g.\n- Who and how are they making purchasing decisions? \n- Are users of the products/ services different from the buyers?\n- What sort of influence / power do they have in how the industry operates and goods/services traded?"
                  }
                  width="500px"
                />
              </Typography>
            </Box>
            <Box sx={{ width: "60%" }}>
              <CustomMultilineTextfield
                width="100%"
                borderRadius="10px"
                // fontSize="14px"
                maxRows={2}
                minRows={2}
                // name={data.name}
                value={details.tabOne.primaryUsers}
                // handledetailsChange={handledetailsChange}
                disabled={true}
              />
            </Box>
          </Box>
          <Box sx={{ width: "100%", display: "flex", marginTop: "10px" }}>
            <Box sx={{ width: "40%" }}>
              <Typography sx={{ fontSize: "13px" }}>
                Who are the key producers / suppliers & their relative market
                power?
                <HelpIconTooltip
                  tooltipdata={
                    "Consider questions such as:\n- Is the product/supply dominated by a few? What is their relative power and how does it influence industry dynamics?\n- Where and how do they operate?\n- What is the nature of competition between them?"
                  }
                  width="500px"
                />
              </Typography>
            </Box>
            <Box sx={{ width: "60%" }}>
              <CustomMultilineTextfield
                width="100%"
                borderRadius="10px"
                // fontSize="14px"
                maxRows={2}
                minRows={2}
                // name={data.name}
                value={details.tabOne.keyProducers}
                // handledetailsChange={handledetailsChange}
                disabled={true}
              />
            </Box>
          </Box>
        </Box>
      }

      {/* ---------------------1---------------- */}

      {factFigureRender === 1 &&
        <Box sx={{ width: "100%", height: "46vh" }}>
          <Box sx={{ width: "100%", display: "flex", marginTop: "20px" }}>
            <Box sx={{ width: "40%" }}>
              <Typography sx={{ fontSize: "13px" }}>
                Key financial stats
                <HelpIconTooltip
                  tooltipdata={
                    "Financial snapshot of the industry, incl.\n- Revenues\n- Profits\n- Annual growth\n- Investment levels (R&D, capital etc.)\n- Key funding sources"
                  }
                  width="500px"
                />
              </Typography>
            </Box>
            <Box sx={{ width: "60%" }}>
              <CustomMultilineTextfield
                width="100%"
                borderRadius="10px"
                // fontSize="14px"
                maxRows={2}
                minRows={2}
                // name={data.name}
                value={details.tabTwo.financialStats}
                // handledetailsChange={handledetailsChange}
                disabled={true}
              />
            </Box>
          </Box>
          <Box sx={{ width: "100%", display: "flex", marginTop: "10px" }}>
            <Box sx={{ width: "40%" }}>
              <Typography sx={{ fontSize: "13px" }}>
                Production/ Service volumes
                <HelpIconTooltip
                  tooltipdata={
                    "For e.g. Production volumes Export/import volumes # of buyers or users"
                  }
                  width="500px"
                />
              </Typography>
            </Box>
            <Box sx={{ width: "60%" }}>
              <CustomMultilineTextfield
                width="100%"
                borderRadius="10px"
                // fontSize="14px"
                maxRows={2}
                minRows={2}
                // name={data.name}
                value={details.tabTwo.productionVolumes}
                // handledetailsChange={handledetailsChange}
                disabled={true}
              />
            </Box>
          </Box>
          <Box sx={{ width: "100%", display: "flex", marginTop: "10px" }}>
            <Box sx={{ width: "40%" }}>
              <Typography sx={{ fontSize: "13px" }}>
                Any other relevant statistics
                <HelpIconTooltip
                  tooltipdata={
                    "For e.g. - Export/Import volumes/value\n- Distribution across different markets\n- Employment stats\n- M&A activity\n- Level of private investment"
                  }
                  width="500px"
                />
              </Typography>
            </Box>
            <Box sx={{ width: "60%" }}>
              <CustomMultilineTextfield
                width="100%"
                borderRadius="10px"
                // fontSize="14px"
                maxRows={2}
                minRows={2}
                // name={data.name}
                value={details.tabTwo.otherStates}
                // handledetailsChange={handledetailsChange}
                disabled={true}
              />
            </Box>
          </Box>
        </Box>
      }

      {factFigureRender === 2 &&
        <Grid
          container
          columnGap={12}
          sx={{ height: "46vh", marginTop: "20px" }}
        >
          {content.questions.map(data => {

            return (
              <Grid mb={1} item md={5}>
                <Typography
                  sx={{
                    backgroundColor: "#f0f0f0",
                    border         : "1px solid #70707072",
                    borderRadius   : "5px",
                    height         : "5vh",
                    padding        : "0px 10px",
                    color          : "#393939",
                    fontSize       : "12px"
                  }}
                >
                  {data.question}
                  <HelpIconTooltip tooltipdata={data.tooltip} />
                </Typography>

                <CustomMultilineTextfield
                  width={"100%"}
                  name={data.name}
                  value={details[content.subName][data.name]}
                  // handledetailsChange={props.handledetailsChange}
                  padding={"6px 5px"}
                  maxRows={2}
                  minRows={2}
                  // fontSize={"13px"}
                  border={{ borderTop: "none" }}
                  disabled={true}
                />
              </Grid>
            );
          
          })}
        </Grid>
      }

      {/* back next */}

      <Box
        sx={{
          display       : "flex",
          justifyContent: "space-between"
        }}
      >
        <Button
          sx={{ padding: "0px", textTransform: "none", fontWeight: "bold" }}
          disabled={factFigureRender === 0 && true}
          onClick={() => {

            if (factFigureRender === 0) {

              setFactFigureRender(0);
            
            } else {

              setFactFigureRender(factFigureRender - 1);
            
            }
          
          }}
        >
          <ArrowLeftIcon sx={{ fontSize: "35px" }} />
          back
        </Button>
        <Button
          sx={{ padding: "0px", textTransform: "none", fontWeight: "bold" }}
          disabled={factFigureRender === 2 && true}
          onClick={() => {

            if (factFigureRender === 2) {

              setFactFigureRender(2);
            
            } else {

              setFactFigureRender(factFigureRender + 1);
            
            }
          
          }}
        >
          next
          <ArrowRightIcon sx={{ fontSize: "35px" }} />
        </Button>
      </Box>
    </Box>
  );

}
