/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from "react";
import {
  Box, Button, CircularProgress, Dialog,
  DialogContent, Grid, IconButton,
  Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BackNextButton from "../../../../components/BackNextButton";
import { currencies } from "../../../../components/Currencies";
import HelpIconTooltip from "../../../../components/HelpIconTooltip";
import IndividualSectionHeaders from "../../../../components/IndividualSectionHeaders";
import ModuleSections from "../../../../components/ModuleSections";
import Datas from "../../../../Data/Finance.json";
import styles from "./BalanceSheet.module.css";
import FinancialsNavBar from "../FinancialsNavBar";
import {
  getFinancials, updateFinancials, createOrupdateFinancialBalanceSheet, updateBalanceSheet
} from "../../../../services/financials";
import BalanceSheetTable from "./BalanceSheetTable";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CustomSelect from "../../../../components/CustomSelect";
import {
  createFinancialsComment,
  deleteFinancialsComment,
  updateFinancialsComment
} from "../../../../services/comment";


const BalanceSheet = () => {

  const [ loading, setLoading ] = useState(false);
  const [ autosaving, setAutosaving ] = useState(false);
  const [ dialogbox, setDialog ] = useState(false);
  const [ collapseComment, setCollapseComment ] = useState(false);
  const [ errorDialog, setErrorDialog ] = useState(false);
  const [ isExpand, setIsExpand ] = useState(false);
  const [ financials, setFinancials ] = useState({
    plOutlooks          : [],
    frOutlooks          : [],
    cashFlow            : "",
    balanceSheet        : "",
    currentFundingSource: [],
    fundRaising         : {
      totalFundingNeed : 0,
      fundingAllocation: ""
    },
    totalCurrentFunding: 0,
    plOutlooksCurrency : "",
    frOutlooksCurrency : "",
    currency           : ""
  });
  const [ comments, setComments ] = useState([]);
  const [ table, setTable ] = useState(Datas?.balanceSheet?.initialData);
  const [ isedit, setIsedit ] = useState(true);
  const [ isDisable, setIsDisable ] = useState(true);
  const [ selectedindex, setSelectedindex ] = useState(-1);
  const history = useHistory();
  const [ currency, setcurrency ] = useState();
  const { user, venture } = useSelector(state => ({ ...state }));
  const [ isYearExist, setisYearExist ] = useState(false);
  const [ yearList, setYearList ] = useState([]);
  const [ isDataLoading, setIsDataLoading ] = useState(true);

  const collapse_comment_function = () => {

    if (collapseComment) {

      setCollapseComment(false);
    
    } else {

      setCollapseComment(true);
    
    }
  
  };

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  const errorDialogopen = () => {

    setErrorDialog(true);
  
  };
  const errorDialogclose = () => {

    setErrorDialog(false);
  
  };

  useEffect(() => {

    setTable(Datas?.balanceSheet?.initialData);
    fetchFinancials();
  
  }, []);

  const fetchFinancials = async () => {

    setIsDataLoading(true);

    setLoading(true);
    setIsDisable(true);

    getFinancials(venture.financials, venture._id).then(response => {

      const finance = response.data.finance;

      setFinancials(finance);
      setcurrency(finance?.currency);
      setComments(finance?.comments?.balanceSheet);

      const yearArray = finance.financialYears;
      const balanceSheetIdFromDoc = response?.data?.finance?.balanceSheet;

      if (finance.financialYears && finance.financialYears.length) {

        setisYearExist(true);

        setYearList(finance.financialYears);

        createOrupdateFinancialBalanceSheet(venture.financials, venture._id, balanceSheetIdFromDoc, yearArray).then(table => {

          if (table) {

            const tableData = table?.data?.table;

            setFinancials(s => { return { ...s, balanceSheet: table?.data?._id }; });
            setTable(tableData);
            setLoading(false);

          } else {

            // setTable([]);
            setLoading(false);

          }

        }).catch(error => { console.error(error); });

      } else {
          
        setisYearExist(false);
        // setLoading(false);
        
      }

      setLoading(false);
      setIsDisable(false);

      setIsDataLoading(false);

    }).catch(error => {

      setFinancials({
        plOutlooks          : [],
        frOutlooks          : [],
        cashFlow            : "",
        balanceSheet        : "",
        currentFundingSource: [],
        fundRaising         : {
          totalFundingNeed : 0,
          fundingAllocation: ""
        },
        totalCurrentFunding: 0,
        plOutlooksCurrency : "",
        frOutlooksCurrency : "",
        currency           : ""
      });

      setLoading(false);
      setIsDisable(false);

      console.error(error);

      setIsDataLoading(false);
    
    });

  };

  // To get the updated usestate value to save in useEffect cleanup
  const financialsRef = useRef(financials);

  financialsRef.current = financials;

  const NumberConversionLogic = num => {

    const numString = num.toString();

    let formatedNumber = numString.length > 0 ? numString.charAt(0) === "0" ? numString.slice(1, numString.length) : numString : "0";

    formatedNumber = formatedNumber.replaceAll(",", "").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,").replace(/[^-0-9]/g, "");

    if (formatedNumber.charAt(0) === "-") {

      return `-${formatedNumber.slice(1, formatedNumber.length).replaceAll("-", "")}`;

    } else {

      return formatedNumber.slice(0, formatedNumber.length).replaceAll("-", "");

    }

  };

  const numberWithComma = num => {

    return num.toString().replaceAll(",", "").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

  };

  const removeCommaForCalculation = num => {

    return num && num.toString().replaceAll(",", "");

  };

  const initialRender = useRef(true);

  useEffect(() => {

    if (initialRender.current) {

      initialRender.current = false;
    
    } else {

      if (table?.length !== 0) {

        const timeoutId = setTimeout(async () => {

          setAutosaving(true);
          setIsDisable(true);
          if (financialsRef.current.balanceSheet) {

            updateBalanceSheet(financialsRef.current.balanceSheet, table, venture._id).then(res => {

              if (res) {

                setAutosaving(false);
                setIsDisable(false);
                setTable(res?.data);
                
                return true;

              }
            
            });
          
          }

        }, 10000);

        return () => clearTimeout(timeoutId);

      }
    
    }
  
  }, [ table, financials ]);

  const handleChange = (value, rowIndex, col) => {

    if (financials.currency === "") {

      errorDialogopen();
      
      return;
    
    }

    let newTable = [ ...table ];

    // update value
    const numbers = NumberConversionLogic(value);

    newTable[rowIndex]["rowVal"][col]["value"] = numbers;

    let total_current_assets = [];
    let total_fixed_assets = [];
    let total_other_assets = [];
    let total_assets = [];
    let total_current_liabilities = [];
    let total_long_term_liabilities = [];
    let total_equity = [];
    let total_liabilities_and_owners_Equity = [];
    let total_liabilities = [];

    newTable.map(ta => {

      if (ta.rowName === "ca_cash") {

        total_current_assets.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "ca_accounts_receivable") {

        total_current_assets.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "ca_investments") {

        total_current_assets.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "ca_nventory") {

        total_current_assets.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "ca_repaid_expenses") {

        total_current_assets.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "ca_other") {

        total_current_assets.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "total_current_assets") {

        const totalCurrentAssets = total_current_assets && total_current_assets.length && total_current_assets.reduce((a, b) => {

          const aa = removeCommaForCalculation(a);
          const bb = removeCommaForCalculation(b);

          
          return Number(aa) + Number(bb);
        
        });
        // ta.rowVal[col]["value"] = isNaN(totalCurrentAssets) ? 0 : totalCurrentAssets

        ta.rowVal[col]["value"] = numberWithComma(totalCurrentAssets);
        total_assets.push(totalCurrentAssets);

      } else if (ta.rowName === "fa_property_plant") {

        total_fixed_assets.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "fa_Long_term_investments") {

        total_fixed_assets.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "fa_machinery_equipment") {

        total_fixed_assets.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "fa_intangible_assets") {

        total_fixed_assets.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "fa_Other") {

        total_fixed_assets.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "total_fixed_assets") {

        const totalFixedAssets = total_fixed_assets && total_fixed_assets.length && total_fixed_assets.reduce((a, b) => {

          const aa = removeCommaForCalculation(a);
          const bb = removeCommaForCalculation(b);

          
          return Number(aa) + Number(bb);
        
        });
        // ta.rowVal[col]["value"] = isNaN(totalFixedAssets) ? 0 : totalFixedAssets

        ta.rowVal[col]["value"] = numberWithComma(totalFixedAssets);
        total_assets.push(totalFixedAssets);

      } else if (ta.rowName === "oa_deferred_income_tax") {

        total_other_assets.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "oa_other") {

        total_other_assets.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "total_other_assets") {

        const totalOtherAssets = total_other_assets && total_other_assets.length && total_other_assets.reduce((a, b) => {

          const aa = removeCommaForCalculation(a);
          const bb = removeCommaForCalculation(b);

          
          return Number(aa) + Number(bb);
        
        });
        // ta.rowVal[col]["value"] = isNaN(totalOtherAssets) ? 0 : totalOtherAssets

        ta.rowVal[col]["value"] = numberWithComma(totalOtherAssets);
        total_assets.push(totalOtherAssets);

      } else if (ta.rowName === "total_assets") {

        const totalOtherAssets = total_assets && total_assets.length && total_assets.reduce((a, b) => {

          const aa = removeCommaForCalculation(a);
          const bb = removeCommaForCalculation(b);

          
          return Number(aa) + Number(bb);
        
        });
        // ta.rowVal[col]["value"] = isNaN(totalOtherAssets) ? 0 : totalOtherAssets

        ta.rowVal[col]["value"] = numberWithComma(totalOtherAssets);

      } else if (ta.rowName === "cl_accounts_payable") {

        total_current_liabilities.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "cl_short_term_loans") {

        total_current_liabilities.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "cl_income_taxes_payable") {

        total_current_liabilities.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "cl_accrued_salaries_and_wages") {

        total_current_liabilities.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "cl_unearned_revenue") {

        total_current_liabilities.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "cl_other") {

        total_current_liabilities.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "total_current_liabilities") {

        const totalCurrentLiabilities = total_current_liabilities && total_current_liabilities.length && total_current_liabilities.reduce((a, b) => {

          const aa = removeCommaForCalculation(a);
          const bb = removeCommaForCalculation(b);

          
          return Number(aa) + Number(bb);
        
        });
        // ta.rowVal[col]["value"] = isNaN(totalCurrentLiabilities) ? 0 : totalCurrentLiabilities

        ta.rowVal[col]["value"] = numberWithComma(totalCurrentLiabilities);
        total_liabilities_and_owners_Equity.push(totalCurrentLiabilities);
        total_liabilities.push(totalCurrentLiabilities);

      } else if (ta.rowName === "ltl_loan_payable") {

        total_long_term_liabilities.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "ltl_long_term_debt") {

        total_long_term_liabilities.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "ltl_other") {

        total_long_term_liabilities.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "total_long_term_liabilities") {

        const totalLongTermLiabilities = total_long_term_liabilities && total_long_term_liabilities.length && total_long_term_liabilities.reduce((a, b) => {

          const aa = removeCommaForCalculation(a);
          const bb = removeCommaForCalculation(b);

          
          return Number(aa) + Number(bb);
        
        });
        // ta.rowVal[col]["value"] = isNaN(totalLongTermLiabilities) ? 0 : totalLongTermLiabilities

        ta.rowVal[col]["value"] = numberWithComma(totalLongTermLiabilities);
        total_liabilities_and_owners_Equity.push(totalLongTermLiabilities);
        total_liabilities.push(totalLongTermLiabilities);

      } else if (ta.rowName === "oe_capital_investment") {

        total_equity.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "oe_retained_earnings") {

        total_equity.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "oe_other") {

        total_equity.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "total_equity") {

        const totalEquity = total_equity && total_equity.length && total_equity.reduce((a, b) => {

          const aa = removeCommaForCalculation(a);
          const bb = removeCommaForCalculation(b);

          
          return Number(aa) + Number(bb);
        
        });
        // ta.rowVal[col]["value"] = isNaN(totalEquity) ? 0 : totalEquity

        ta.rowVal[col]["value"] = numberWithComma(totalEquity);
        total_liabilities_and_owners_Equity.push(totalEquity);

      } else if (ta.rowName === "total_liabilities_and_owners_Equity") {

        const toalLiabilitiesAndOwnersEquity = total_liabilities_and_owners_Equity && total_liabilities_and_owners_Equity.length && total_liabilities_and_owners_Equity.reduce((a, b) => {

          const aa = removeCommaForCalculation(a);
          const bb = removeCommaForCalculation(b);

          
          return Number(aa) + Number(bb);
        
        });

        ta.rowVal[col]["value"] = numberWithComma(toalLiabilitiesAndOwnersEquity);
        // total_liabilities_and_owners_Equity.push(totalEquity);

      } else if (ta.rowName === "total_liabilities") {

        const toalLiabilitiesAndOwnersEquity = total_liabilities && total_liabilities.length && total_liabilities.reduce((a, b) => {

          const aa = removeCommaForCalculation(a);
          const bb = removeCommaForCalculation(b);

          
          return Number(aa) + Number(bb);
        
        });

        ta.rowVal[col]["value"] = numberWithComma(toalLiabilitiesAndOwnersEquity);
        // total_liabilities_and_owners_Equity.push(totalEquity);

      }

    });

    setTable(newTable);
  
  };

  const handleSubmit = async para => {

    if (para && isDataLoading === false) {

      if (financialsRef?.current?.balanceSheet) {

        setIsDisable(true);
        updateBalanceSheet(financialsRef.current.balanceSheet, table, venture._id).then(data => {

          if (data) {

            setIsDisable(false);
            // history.push("/fundingrunway");

            return true;

          }
        
        }).catch(error => {

          setIsDisable(false);
          console.error(error);
        
        });

      }

    } else {

      if (financialsRef?.current?.balanceSheet) {

        setIsDisable(true);
        updateBalanceSheet(financialsRef.current.balanceSheet, table, venture._id).then(data => {

          if (data) {

            setIsDisable(false);
            // history.push("/fundingrunway");

            return false;

          }

        }).catch(error => {

          setIsDisable(false);
          console.error(error);

        });

      }

    }


  };

  const tableRef = useRef(table);

  tableRef.current = table;

  useEffect(() => {

    return () => {

      handleSave();

    };

  }, []);

  const handleSave = async () => {

    setIsDisable(true);

    if (financialsRef.current.balanceSheet) {

      const tableData = tableRef.current;

      updateBalanceSheet(financialsRef.current.balanceSheet, tableData, venture._id).then(res => {

        if (res) {

          setIsDisable(false);
          setTable(res?.data);

          return true;

        }
      
      }).catch(error => {

        console.error(error);
        setIsDisable(false);
      
      }
      );

    }

    // handleSubmit();

  };

  const handleChangeRowHeaders = (value, rowindex) => {

    if (financials.currency === "") {

      errorDialogopen();
      
      return;
    
    }

    let newTable = [ ...table ];

    newTable.map(t => {

      if (rowindex === t.rowIndex) {

        t.rowLabel = value;

      }
    
    });

    setTable(newTable);
  
  };

  const handleCurrencyChange = e => {

    setIsDisable(true);

    let newfinancials = { ...financials };

    if (e.value) {

      setcurrency(e.value);
      newfinancials["currency"] = e.value;
      setFinancials(newfinancials);
      setIsDisable(false);

    }

    if (newfinancials["currency"] === e.value)

      setIsDisable(true);

    updateFinancials(venture.financials, newfinancials).then(res => {

      if (res.data) {

        setFinancials(res.data);
        setIsDisable(false);

      }

    }).catch(error => {

      setFinancials({
        plOutlooks          : [],
        frOutlooks          : [],
        cashFlow            : "",
        currentFundingSource: [],
        fundRaising         : {
          totalFundingNeed : 0,
          fundingAllocation: ""
        },
        totalCurrentFunding: 0,
        plOutlooksCurrency : "",
        frOutlooksCurrency : "",
        currency           : ""
      });

      console.error(error);

    });


  };

  const currencyOptions = currencies.map(currency => ({
    value: `${currency.symbol} ${currency.code}`,
    label: `${currency.symbol} ${currency.code}`
  }));

  const addComment = async (commentText, replyTo) => {

    try {

      const response = await createFinancialsComment(
        commentText,
        user._id,
        "balanceSheet",
        "",
        venture.financials,
        replyTo,
        window.location.href,
        "Finance > Balance Sheet"
      );

      setComments(response?.data?.comments?.balanceSheet);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateComment = async (commentid, commentText) => {

    try {

      const response = await updateFinancialsComment(
        commentText,
        user._id,
        commentid,
        venture._id,
        window.location.href,
        "Finance > Balance Sheet"
      );

      setComments(response?.data?.comments?.balanceSheet);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const deleteComment = async commentid => {

    try {

      const response = await deleteFinancialsComment(
        "balanceSheet",
        "",
        venture.financials,
        commentid,
        user._id,
        window.location.href,
        "Finance > Balance Sheet"
      );

      setComments(response?.data?.comments?.balanceSheet);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  return (
    <ModuleSections
      isexpand={isExpand}
      Collapsecomment={collapseComment}
      comments={comments}
      addComment={addComment}
      deleteComment={deleteComment}
      updateComment={updateComment}>
      <div className="box-display-style-for-next-and-back-button-with-justify">
        <div className="box-indiv-header-con" style={{ height: "14%", background: "white" }}>
          {loading ?
            <></>
            :
            <>
              <FinancialsNavBar />
              <IndividualSectionHeaders
                mainheading={"Finance"}
                subheading={"Balance Sheet"}
                headingcontent={
                  "Derive your company's Balance Sheet"
                }
                isGtm={false}
                Dialogopen={Dialogopen}
                Dialogclose={Dialogclose}
                dialogbox={dialogbox}
                collapse_comment_function={collapse_comment_function}
                content={Datas.balanceSheet}
                isexpand={isExpand}
                isSaveButton={true}
                isSaveBtnDisable={isDisable}
                handleSave={handleSubmit}
                isFinance={ venture.userRole === "Commenter" ? false : true}
              />
            </>
          }
        </div>
        <Grid
          item
          id={styles.maingrid}
          xs={12}
          sx={{
            height   : "64%",
            width    : "100%",
            overflowY: "auto",
            overflowX: "auto",
            padding  : "0px 60px 0px"
          }}
          onClick={() => {

            // setIsedit(false);
            setSelectedindex(-1);
          
          }}>
          {loading ?
            <Grid
              container
              justifyContent="center"
              height="100%"
              alignItems="center">
              <Grid item>
                <CircularProgress size="60px" thickness={3} />
              </Grid>
            </Grid>
            :
            <>

              {isYearExist ?
                <>
                  <Box
                    sx={{
                      display       : "flex",
                      justifyContent: "space-between",
                      alignItems    : "center",
                      margin        : "10px 0px"
                    }}>
                    <Box id={styles.currencyDropdownBox}>
                      <Box id={styles.currencyTypoBox}>
                        <Typography id={styles.currencyTypo}>
                          Select currency
                          <HelpIconTooltip
                            tooltipdata={
                              "Note: Choosing a currency here will only change the symbol on your P&L outlook numbers below. It will not do currency conversion. Please review your numbers if you change your currency setting."
                            }
                          />
                        </Typography>
                      </Box>
                      <Box>
                        <CustomSelect
                          placeholder={"Currency"}
                          disabled={isDisable}
                          fontSize={"14px"}
                          value={currency}
                          name="plOutlooksCurrency"
                          handleChange={e => handleCurrencyChange(e)}
                          options={currencyOptions}></CustomSelect>
                      </Box>
                    </Box>
                    <Box id={styles.currencyTypoBox}>
                      {autosaving &&
                        <Typography sx={{ marginRight: "20px" }}>
                          Auto Saving....
                        </Typography>
                      }
                      <IconButton
                        component="span"
                        onClick={() => {

                          setIsExpand(!isExpand);
                        
                        }}>
                        {isExpand ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
                      </IconButton>
                    </Box>
                  </Box>
                  <BalanceSheetTable
                    table={table}
                    handleChange={handleChange}
                    financials={financials}
                    handleChangeRowHeaders={handleChangeRowHeaders}
                    isedit={isedit}
                    yearList={yearList}
                    selectedindex={selectedindex}
                    setIsedit={setIsedit}
                    setSelectedindex={setSelectedindex}
                  />
                  <Dialog
                    open={errorDialog}
                    PaperProps={{
                      style: {
                        borderRadius  : "20px",
                        backdropFilter: "blur(45px)",
                        background    : "#FFFFFF 0% 0% no-repeat padding-box",
                        boxShadow     : "0px 3px 6px #00000029",
                        border        : "1px solid #707070"
                      }
                    }}
                  // BackdropProps={{ invisible: true }}
                  >
                    <DialogContent>
                      <Box sx={{ textAlign: "center" }}>
                        <Typography gutterBottom>
                            Please enter the financial planning <b>‘Start Year'</b> in the <b>Year</b> drop-down OR select <b>‘Currency’</b> to begin the planning process.
                            Note: The Year update will impact all pages
                        </Typography>
                        <Button
                          variant="contained"
                          size="medium"
                          sx={{
                            backgroundColor: "#071AFF",
                            marginLeft     : "10px"
                          }}
                          onClick={() => {

                            errorDialogclose();
                          
                          }}>
                          OK
                        </Button>
                      </Box>
                    </DialogContent>
                  </Dialog>
                </> : <><Dialog
                  open={!isYearExist}
                  PaperProps={{
                    style: {
                      borderRadius  : "20px",
                      backdropFilter: "blur(45px)",
                      background    : "#FFFFFF 0% 0% no-repeat padding-box",
                      boxShadow     : "0px 3px 6px #00000029",
                      border        : "1px solid #707070"
                    }
                  }}
                >
                  <DialogContent>
                    <Box sx={{ textAlign: "center" }}>
                      {venture.userRole === "Commenter" ?
                        <Typography gutterBottom>
                            No content available to display. Contact the project owner
                        </Typography> :
                        <Typography gutterBottom>
                            Please enter the financial <b>‘Start Year'</b> in the <b> P&L Outlook page</b> to begin the process.
                        </Typography>
                      }
                      <Button
                        variant="contained"
                        size="medium"
                        sx={{
                          backgroundColor: "#071AFF",
                          marginLeft     : "10px"
                        }}
                        onClick={() => {

                          venture.userRole === "Commenter" ? history.push("/dashboard") : history.push("/ploutlook");
                        
                        }}>
                        OK
                      </Button>
                    </Box>
                  </DialogContent>
                </Dialog></>}

              {/* back and next button code */}
            </>
          }
        </Grid>
        {/* <div style={{ width: "100%" }}> */}
        <div className="ttribe-back-next-button-con">
          {loading ?
            <></>
            :
            <BackNextButton
              restrictedBackClick={() => {

                history.push("/cashflow");
              
              }}
              backClick={() => {

                handleSubmit();
                history.push("/cashflow");
              
              }}
              restrictedNextClick={() => {

                history.push("/insightsdashboard");
              
              }}
              nextClick={() => {

                handleSubmit();
                history.push("/insightsdashboard");
              
              }
              }
            />
          }
        </div>
        {/* </div> */}
      </div>
    </ModuleSections>
  );

};

export default BalanceSheet;
