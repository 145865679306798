import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import ErrorMessage from "../../../components/ErrorMessage";
import SuccessMessage from "../../../components/SuccessMessage";
import { auth } from "../../../firebase";
import { userVerified } from "../../../services/auth";
import styles from "./ForgotPasswordMyProfile.module.css";

export default function ForgotPasswordMyProfile () {

  const [ email, setEmail ] = useState("");
  const [ errormsg, setErrormsg ] = useState("");
  const [ successmsg, setSuccessmsg ] = useState("");
  const [ errorflag, setErrorflag ] = useState(false);
  const history = useHistory();

  const { user } = useSelector(state => {

    return { ...state };
  
  });

  useEffect(() => {

    if (user && user.token) setEmail(user.email);
  
  }, [ user ]);

  useEffect(() => {

    if (!errorflag) {

      setErrormsg("");
    
    }
  
  }, [ errorflag ]);

  const handleSubmit = async e => {

    e.preventDefault();
    if (email && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {

      const response = await userVerified(email);

      if (response.data === "User Not Verified") {

        setErrormsg(
          "We couldn’t find an account with that email ID. Please try a different ID."
        );
        setErrorflag(true);
        
        return;
      
      }
      const config = {
        url            : `${window.location.origin}${process.env.REACT_APP_FORGOT_PASSWORD_REDIRECT}`,
        handleCodeInApp: true
      };

      await auth
        .sendPasswordResetEmail(email, config)
        .then(() => {

          //
          setSuccessmsg("Check your email for password reset link");
          setTimeout(() => {

            setSuccessmsg("");
          
          }, 5000);
        
        })
        .catch(error => {

          console.error(error.message);
        
        });
      //
    
    } else {

      setErrormsg("Please enter a valid email ID.");
      setErrorflag(true);
    
    }
  
  };

  
  return (
    <div style={ { height: "100%" } }>
      <Box id={ styles.mainbox }>
        <Grid container id={ styles.maingrid }>
          { errormsg && <ErrorMessage errormsg={ errormsg } width="375px" /> }
          { successmsg &&
            <SuccessMessage successmsg={ successmsg } width="375px" />
          }
          <Box
            component="form"
            onSubmit={ handleSubmit }
            sx={ {
              "& .MuiTextField-root": { width: "375px", height: "50px" },
              marginTop             : "20px"
            } }
            noValidate
            autoComplete="off"
          >
            <Box
              id={ styles.backbtn }
              onClick={ () => {

                history.push("/loginsecurity");
              
              } }
            >
              back
            </Box>
            <Typography id={ styles.forgotPassTypo } variant="h5">
              Forgot Password
            </Typography>
            <div>
              <TextField
                type="email"
                placeholder="Your email"
                autoFocus
                fullWidth
                value={ email }
                onChange={ e => {

                  setErrorflag(false);
                  setEmail(e.target.value);
                
                } }
              />
            </div>
            <Button
              id={ styles.btn }
              variant="contained"
              disabled={ !email }
              type="submit"
            >
              send reset link
            </Button>
          </Box>

          <Typography id={ styles.linkTypo }>
            Use the link sent to your email to reset the password
          </Typography>
        </Grid>
      </Box>
    </div>
  );

}
