import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Button, Grid, Popover, TextField, Typography
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { updateCampaign } from "../../../../services/digitalmarket";

import { makeStyles } from "@mui/styles";
import ErrorMessage from "../../../../components/ErrorMessage";
import styles from "./DigitalMarketing.module.css";

const useStyles = makeStyles(() => ({
  createcustomWidth: {
    width          : 300,
    fontSize       : 10,
    backgroundColor: "white",
    color          : "#707070",
    boxShadow      : "0px 3px 6px #00000029",
    border         : "1px solid #707070",
    borderRadius   : 10,
    padding        : 10
  },
  createinput: {
    height                : 25,
    // border: "1px solid #CCCCCC",
    backgroundColor       : "#ffffff",
    // borderRadius: 5,
    "& input::placeholder": {
      fontSize: 14
    }
  }
}));

const CreateCampaign = props => {

  const classes = useStyles();
  const [ anchorEl, setAnchorEl ] = React.useState(null);
  const [ errormsg, setErrormsg ] = useState("");

  let { venture } = useSelector(state => {

    return { ...state };
  
  });

  const handleClick = event => {

    setAnchorEl(event.currentTarget);
  
  };

  const handleClose = () => {

    setAnchorEl(null);
  
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const details = props.details;
  const index = props.index;

  const createContentType = async () => {

    if (
      details.title === "" ||
      details.description === "" ||
      details.start === "" ||
      details.end === ""
    ) {

      setErrormsg("please fill required fields");
      setTimeout(() => {

        setErrormsg("");
      
      }, 3000);
    
    } else if (
      new Date(details.end).valueOf() < new Date(details.start).valueOf()
    ) {

      setErrormsg("End date should be greater than start date");
      setTimeout(() => {

        setErrormsg("");
      
      }, 3000);
    
    } else {

      if (props.id) {

        await updateCampaign(details, props.campaignid);
        // console.log(response.data);

        handleClose();
      
      } else {

        if (
          details.title !== "Email Marketing" &&
          details.title !== "LinkedIn Messaging" &&
          details.title !== "Facebook Ad" &&
          details.title !== "Instagram Ad" &&
          index < 6
        ) {

          props.addContent("isCreated", true, index);
          handleClose();
        
        } else {

          props.handleContentDetailsChange("isCreated", true, index);
          handleClose();
        
        }
      
      }
    
    }
  
  };

  return (
    <>
      <Button
        aria-describedby={ id }
        sx={ { fontSize: "9px" } }
        variant="outlined"
        onClick={ handleClick }
        id={ props.id ? styles.contenttypeeditbtn : styles.contenttypebtn }
        disabled={ venture.userRole === "Commenter" && !props.id }
      >
        { props.id ?
          ""
          : details.isCreated ?
            <CheckCircleIcon
              sx={ { color: "#008105", fontSize: "15px", marginRight: "5px" } }
              fontSize="small"
            />
            :
            ""
        }
        { details.title === "Email Marketing" ||
          details.title === "LinkedIn Messaging" ||
          details.title === "Facebook Ad" ||
          details.title === "Instagram Ad"
          ? details.title
          : details.isCreated
            ? details.title
            : "other" }
        { props.id ?
          <AccessTimeIcon
            sx={ { color: "#707070", marginLeft: "5px" } }
            fontSize="small"
          />
          :
          ""
        }
      </Button>
      <Popover
        id={ id }
        open={ open }
        anchorEl={ anchorEl }
        onClose={ handleClose }
        anchorOrigin={ {
          vertical  : "bottom",
          horizontal: "left"
        } }
        sx={ { "& .MuiPaper-root": { padding: "10px" } } }
      >
        <Grid>
          <Grid container mt={ 1 }>
            <Grid item md={ 4 }>
              <Typography sx={ { fontSize: "12px" } }>Title*</Typography>
            </Grid>
            <Grid item md={ 8 }>
              <TextField
                size="small"
                InputProps={ {
                  className: classes.createinput
                } }
                type="text"
                value={ details.title }
                onChange={ e => {

                  props.handleContentDetailsChange(
                    "title",
                    e.target.value.trimStart(),
                    index
                  );
                
                } }
                disabled={ venture.userRole === "Commenter" }
              />
            </Grid>
          </Grid>
          <Grid container mt={ 1 }>
            <Grid item md={ 4 }>
              <Typography sx={ { fontSize: "12px" } }>Description*</Typography>
            </Grid>
            <Grid item md={ 8 }>
              <TextField
                multiline
                size="small"
                sx={ {
                  // backgroundColor: props.backgroundColor,
                  width                     : "100%",
                  "& .MuiOutlinedInput-root": {
                    padding: "7px 14px"
                  },
                  "& .MuiInputBase-input": {
                    fontSize  : "14px",
                    lineHeight: "15px",
                    height    : "40px !important"
                  }
                } }
                type="text"
                value={ details.description }
                onChange={ e => {

                  props.handleContentDetailsChange(
                    "description",
                    e.target.value.trimStart(),
                    index
                  );
                
                } }
                disabled={ venture.userRole === "Commenter" }
              />
            </Grid>
          </Grid>
          <Grid container mt={ 1 }>
            <Grid item md={ 4 }>
              <Typography sx={ { fontSize: "12px" } }>Start date*</Typography>
            </Grid>
            <Grid item md={ 8 }>
              <TextField
                size="small"
                InputProps={ {
                  className: classes.createinput,
                  shrink   : true
                } }
                type="date"
                sx={ { width: "100%" } }
                value={ moment(details.start).format("YYYY-MM-DD") }
                onChange={ e => {

                  props.handleContentDetailsChange(
                    "start",
                    e.target.value,
                    index
                  );
                
                } }
                disabled={ venture.userRole === "Commenter" }
              />
            </Grid>
          </Grid>
          <Grid container mt={ 1 }>
            <Grid item md={ 4 }>
              <Typography sx={ { fontSize: "12px" } }>End date*</Typography>
            </Grid>
            <Grid item md={ 8 }>
              <TextField
                size="small"
                InputProps={ {
                  className: classes.createinput,
                  shrink   : true
                } }
                type="date"
                sx={ { width: "100%" } }
                value={ moment(details.end).format("YYYY-MM-DD") }
                onChange={ e => {

                  props.handleContentDetailsChange(
                    "end",
                    e.target.value,
                    index
                  );
                
                } }
                disabled={ venture.userRole === "Commenter" }
              />
            </Grid>
          </Grid>
          { errormsg && <ErrorMessage errormsg={ errormsg } /> }
          <Button
            sx={ { padding: "0px", color: "#071AFF", float: "right" } }
            onClick={ () => {

              createContentType();
            
            } }
            disabled={ venture.userRole === "Commenter" }
          >
            { props.id ? "Update" : "Create" }
          </Button>
        </Grid>
      </Popover>
    </>
  );

};

export default CreateCampaign;
