/* eslint-disable camelcase */
import {
  Box, Dialog, Grid, IconButton, Slide, Typography
} from "@mui/material";
import React, { forwardRef, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import IndividualSectionHeaders from "../../../../components/IndividualSectionHeaders";
import Data from "../../../../Data/Gtm.json";
import PersonaDetails from "./PersonaDetails";
import styles from "./TargetSegments.module.css";

const Transition = forwardRef(function Transition (props, ref) {

  return <Slide direction="up" ref={ ref } { ...props } />;

});

const SegmentsPersonasDialog = props => {

  const segments = props.segments;
  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const [ dialogbox, setDialog ] = useState(false);
  const [ personadialogbox, setPersonaDialog ] = useState(false);
  const [ personaindex, setPersonaindex ] = useState(0);
  const [ segmentindex, setSegmentindex ] = useState(0);
  const history = useHistory();

  const pathname = history.location.pathname.substring(1);
  const guideicon = Data.gtmtargetpersonas;


  useEffect(() => {
    // setSegments();
    // fetchSegments();
  }, []);

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);
    
    } else {

      setCollapsecomment(true);
    
    }
  
  };

  // const fetchSegments = async () => {
  //   try {
  //     const response = await getSegments(venture.customer);
  //     const customer = response.data;
  //     setSegments(customer.segmentsPersonas.segments);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  return (
    <Grid item xs={ 12 }>
      <IndividualSectionHeaders
        marginBottom={ "15px" }
        // mainheading={pathname.includes("gtm") ? "GTM" : "Customer"}
        subheading={
          pathname.includes("gtm")
            ? "Segments & Personas"
            : "Segments & Personas"
        }
        // headingcontent={
        //   "Segment your customers into groups and create realistic user personas."
        // }
        // isGtm={pathname.includes("gtm") ? true : false}
        Dialogopen={ Dialogopen }
        Dialogclose={ Dialogclose }
        dialogbox={ dialogbox }
        collapse_comment_function={ collapse_comment_function }
        content={ guideicon }
        isGtmChevron={ "none" }
        isexpand={ true }
        notGuide={ true }
      />
      <Grid sx={ { height: "60%" } }>
        { segments && segments.length > 0 ?
          segments.map((eachSegment, eachSegmentIndex) => {

            return (
              <Grid
                // key={eachSegmentIndex}
                container id={ styles.gridcontainer }>
                <Grid item md={ 3 } sx={ { textAlign: "center" } }>
                  <Typography>{ eachSegment.name }</Typography>
                </Grid>
                <Grid
                  item
                  md={ 9 }
                  display="flex"
                  columnGap={ 8 }
                  id={ styles.personagridDialog }
                  sx={ {
                    borderRight   : "none !important",
                    justifyContent: "none !important"
                  } }
                >
                  { eachSegment?.personas.map((eachPersona, index) => {

                    return (
                      <Box
                        // key={ index }
                        component="span"
                        sx={ {
                          cursor    : "pointer",
                          marginLeft: "5px",
                          // border: 1,
                          textAlign : "center",
                          flex      : "0 0 80px",

                          padding  : "10px 0px",
                          position : "relative",
                          "&:hover": {
                            backgroundColor: "#c7c6c6",
                            boxShadow      : "0 4px 12px rgb(0 0 0 / 15%)"
                          }
                        } }
                        onClick={ () => {

                          setPersonaindex(index + 1);
                          setSegmentindex(eachSegmentIndex + 1);
                          setPersonaDialog(true);
                        
                        } }
                      >
                        <img
                          src={ `${process.env.REACT_APP_AWS_ASSETS_URL}${eachPersona.avatar}` }
                          width="50px"
                          height="50px"
                        />
                        <Typography>{ eachPersona.name }</Typography>

                        <Dialog
                          open={ personadialogbox && segmentindex === eachSegmentIndex + 1 && personaindex === index + 1 }
                          TransitionComponent={ Transition }
                          keepMounted
                          PaperProps={ {
                            style: {
                              borderRadius   : "20px",
                              backdropFilter : "blur(10px)",
                              backgroundColor: "rgba(255,255,255,0.80)",
                              margin         : "20px"
                            }
                          } }
                          maxWidth="md"
                          fullWidth
                        // fullScreen
                        >
                          <Box sx={ { alignSelf: "flex-end" } }>
                            <IconButton
                              component="span"
                              onClick={ e => {

                                e.stopPropagation();
                                history.push({ hash: "" });
                                setPersonaindex(0);
                                setSegmentindex(0);
                                setPersonaDialog(false);
                              
                              } }
                            >
                              <HighlightOffIcon />
                            </IconButton>
                          </Box>
                          <PersonaDetails
                            segment={ eachSegment }
                            eachPersona={ eachPersona }
                          />
                        </Dialog>
                      </Box>
                    );
                  
                  }) }
                </Grid>
              </Grid>
            );
          
          })
          :
          <Grid container>
            <Grid
              item
              xs={ 12 }
              sx={ {
                textAlign     : "center",
                minHeight     : "40vh",
                display       : "flex",
                justifyContent: "center",
                alignItems    : "center"
              } }
            >
              <Typography fontWeight="900">
                No valid user personas found. Kindly create relevant user
                segments and personas in the Customer module.
              </Typography>
            </Grid>
          </Grid>
        }
      </Grid>
    </Grid>
  );

};

export default SegmentsPersonasDialog;
