import React, { useState, useEffect } from "react";
import { Grid, Button, Dialog, DialogContent } from "@mui/material";
import DataTable from "react-data-table-component";

import Img from "../../../../Assets/icons/images/Operations_Milestones - undraw_project_completed_re_pqqq.svg";
import GanttChart from "../../Operations/Milestone/GanttChart";

const customStyles = {

  headRow: {
    style: {
      background  : "#ece8f7",
      fontSize    : "1.375em",
      borderRadius: "10px",
      minHeight   : "6vh"

    }
  },
  headCells: {
    style: {
      fontSize  : "1.8vh",
      fontWeight: 500,
      color     : "#000000de"
    }
  },
  rows: {
    style: {
      minHeight: "6vh",
      fontSize : "1.6vh"
    }
  }

};

const expandCustomStyles = {

  headRow: {
    style: {
      display: "none"
      // background: "#ece8f7",
      // fontSize: "1.375em",
      // borderRadius: "10px",
      // minHeight: "6vh",
    }
  },
  headCells: {
    style: {
      fontSize  : "1.6vh",
      fontWeight: 500,
      color     : "#000000de"
    }
  },
  rows: {
    style: {
      minHeight: "6vh",
      fontSize : "1.6vh"
    }
  }

};

const MilesStones = props => {

  const [ milesStonesData, setMilesStonesData ] = useState();
  const [ chartDialog, setChartDialog ] = useState(false);
  const [ chartdata, setChartdata ] = useState([]);
  const [ milestoneRanges, setMilestoneranges ] = useState([]);

  useEffect(() => {

    const data1 = props?.milesStonesData;

    setMilesStonesData(data1);

  }, []);

  const columns = [
    {
      id      : "name",
      name    : "Name",
      selector: row => { return row.name; },
      sortable: true,
      center  : true
    },
    {
      id      : "startDate",
      name    : "Start Date",
      selector: row => { return new Date(row.startDate).toLocaleDateString(); },
      sortable: true,
      center  : true
    },
    {
      id      : "endDate",
      name    : "End Date",
      selector: row => { return new Date(row.endDate).toLocaleDateString(); },
      sortable: true,
      center  : true
    },
    {
      id      : "status",
      name    : "Status",
      selector: row => row.status,
      sortable: true,
      center  : true
    }
  ];

  const exColumns = [
    {
      // id: "name",
      // name: "Name",
      selector: row => { return row.name; },
      sortable: true,
      center  : true
    },
    {
      // id: "startDate",
      // name: "Start Date",
      selector: row => { return new Date(row.startDate).toLocaleDateString(); },
      sortable: true,
      center  : true
    },
    {
      // id: "endDate",
      // name: "End Date",
      selector: row => { return new Date(row.endDate).toLocaleDateString(); },
      sortable: true,
      center  : true
    },
    {
      // id: "status",
      // name: "Status",
      selector: row => row.status,
      sortable: true,
      center  : true
    }
  ];

  const expandedData = row => {

    const expandData = row?.data?.activities || [];

    return <DataTable className="tribe-mils-expand-table-com" data={expandData} paginationPerPage="5" noHeader={true} defaultSortAsc={false} columns={exColumns} customStyles={expandCustomStyles} striped pagination />;

  };

  const ChartDialogopen = () => {

    setChartDialog(true);

  };

  const ChartDialogclose = () => {

    setChartDialog(false);

  };

  const generateGanttChartData = () => {

    let newchartdata = [];
    let ranges = [];

    milesStonesData.map(milestone => {

      newchartdata.push({
        name       : milestone.name,
        status     : milestone.status,
        isMilestone: true,
        data       : [
          {
            x: milestone.name,
            y: [
              new Date(milestone.startDate).getTime(),
              new Date(milestone.endDate).getTime()
            ]
          }
        ]
      });
      milestone.activities.map(activity => {

        newchartdata.push({
          name       : activity.name,
          status     : activity.status,
          isMilestone: false,
          data       : [
            {
              x: milestone.name,
              y: [
                new Date(activity.startDate).getTime(),
                new Date(activity.endDate).getTime()
              ]
            }
          ]
        });
      
      });
    
    });
    // console.log(newchartdata);
    setChartdata(newchartdata);
    setMilestoneranges(ranges);
  
  };

  return <div className="tribe-summary-venture-profile-main-con" >
    <Grid className="tribe-summary-venture-profile-con" container spacing={2}>
      <Grid className="tribe-summary-venture-img-con" xs={4}>
        <Button onClick={() => { generateGanttChartData(); ChartDialogopen(); }} className="tribe-summary-view-chart">
          <span>
                        View Gantt Chart
          </span>
        </Button>
        <div>
          <img className="tribe-summary-milestone-img" src={Img} alt="" />
        </div>
      </Grid>
      <Grid xs={8}>
        <div className="tribe-summary-milestones-table-con">
          <DataTable className="tribe-milestons-table" data={milesStonesData || []} defaultSortAsc={false} defaultSortFieldId="startDate" striped customStyles={customStyles} columns={columns} expandableRows pagination fixedHeader expandableRowsComponent={expandedData} />
        </div>
      </Grid>
    </Grid>
    <Dialog
      open={chartDialog}
      onClose={ChartDialogclose}
      PaperProps={{
        style: {
          borderRadius   : "20px",
          backdropFilter : "blur(100px)",
          backgroundColor: "white",
          boxShadow      : "0px 3px 6px #00000029",
          border         : "1px solid #b8bddb"
        }
      }}
      BackdropProps={{ invisible: true }}
      maxWidth="lg"
      fullWidth
    >
      <DialogContent>
        <GanttChart
          series={chartdata}
          milestoneRanges={milestoneRanges}
        />
      </DialogContent>
    </Dialog>
  </div>;

};

export default MilesStones;