import { Box, Button, Grid, Slider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import BackButton from "../../../components/BackButton";
import BoxWithoutIcon from "../../../components/BoxWithoutIcon";
import Data from "../../../Data/Gtm.json";
import { getVetureById, updatePercent } from "../../../services/venture";
import styles from "./GTM.module.css";

const GTMSummary = () => {

  const history = useHistory();
  const pathname = history.location.pathname.substring(1);
  const [ value, setValue ] = useState(0);

  const split = pathname.split("summary");
  // console.log(split[0]);

  const data = Data.GTMSummary[split[0]];
  // console.log(data);

  const { venture } = useSelector(state => ({ ...state }));

  useEffect(() => {

    getVenture();
  
  }, []);

  const getVenture = async () => {

    try {

      const response = await getVetureById(venture._id);
      // console.log(response.data);
      const Venture = response.data.venture;
      // console.log(Venture.percentValue["gotomarket"][data["sectionname"]]);

      setValue(Venture.percentValue["gotomarket"][data["sectionname"]]);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateValue = async newvalue => {

    await updatePercent(
      venture._id,
      "gotomarket",
      data["sectionname"],
      newvalue
    );
    setValue(newvalue);
  
  };

  const location = useLocation();
  // console.log(location.pathname);

  return (
    <BoxWithoutIcon>
      <Grid item sx={ { height: "100%", padding: "30px 60px 0px" } }>
        <Grid container justifyContent="space-between" sx={ { height: "85%" } }>
          <Grid item md={ 6 } id={ styles.griditemfinal }>
            <Box>
              <Typography sx={ { fontSize: "30px", fontWeight: "bold" } }>
                Congratulations!
              </Typography>
              <Typography sx={ { fontSize: "16px", fontWeight: "bold" } }>
                You have reached the end of the { data["modulename"] } module
              </Typography>
            </Box>
            <Typography sx={ { fontSize: "16px" } }>
              Use the slider on the right, to indicate how much of this section
              you have completed. This will help you and your team keep track of
              progress and prioritize sections to work on.
            </Typography>
            <Box sx={ { position: "relative" } }>
              <Button
                id={ styles.gtmcanvasbtn }
                onClick={ () => {

                  history.push("/gotomarketcanvas");
                
                } }>
                GTM Canvas <ArrowRightIcon style={ { fontSize: "xx-large" } } />
              </Button>

              {/* gtm last section button */ }
              { location.pathname.includes("costbudgetsummary") &&
                // <Button
                //   style={{
                //     border: "1px solid #071AFF",
                //     backgroundColor: "#F8F9FF",
                //     color: "blue",
                //     textTransform: "capitalize",
                //     padding: "10px 30px",
                //     marginRight: "30px",
                //     borderRadius: "8px",
                //     boxShadow: "none",
                //     position: "absolute",
                //     right: "-200px",
                //   }}
                //   onClick={() => {
                //     history.push("/dashboard");
                //   }}
                // >
                //   Dashboard
                // </Button>
                <Button
                  style={ {
                    backgroundColor: "#071aff",
                    textTransform  : "capitalize",
                    padding        : "11px 30px",
                    marginRight    : "30px",
                    borderRadius   : "8px",
                    boxShadow      : "none",
                    position       : "absolute",
                    right          : "-240px"
                    // top: "-100px",
                    // display:
                    //   data.nextModule === "solutionintro" &&
                    //     user.access === "freetier"
                    //     ? "none"
                    //     : "initial",
                  } }
                  variant="contained"
                  onClick={ () => {

                    history.push("/pitchdeckintro");
                  
                  } }>
                  Next Module{ " " }
                  <ArrowForwardIosIcon
                    sx={ { fontSize: "16px", marginLeft: "5px" } }
                  />
                </Button>
              }
            </Box>
          </Grid>
          <Grid item md={ 5 } id={ styles.griditem2 }>
            <img
              src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/undraw_target_kriv-1.png` }
              alt="final"
            />
            <Box id={ styles.sliderbox }>
              <Slider
                value={ value }
                onChangeCommitted={ (e, newValue) => {

                  updateValue(newValue);
                
                } }
                valueLabelFormat={ value => <div>{ value + "%" }</div> }
                step={ 25 }
                size="small"
                valueLabelDisplay="on"
                sx={ {
                  "& .MuiSlider-rail": {
                    color: "#00132E"
                  },
                  "& .MuiSlider-valueLabelOpen": {
                    borderRadius   : "5px",
                    color          : "blue",
                    backgroundColor: "#ffffff",
                    boxShadow      : "0px 3px 6px #00000029"
                  }
                } }
                disabled={ venture.userRole === "Commenter" }
              />
            </Box>
          </Grid>
        </Grid>
        { location.pathname.includes("costbudgetsummary") ?
          <></>
          :
          <BackButton
            restrictedBackClick={ () => {

              history.push(`/${data["backurl"]}`);
            
            } }
            backClick={ () => {

              history.push(`/${data["backurl"]}`);
            
            } }
          />
        }
        { location.pathname.includes("costbudgetsummary") &&
          <Box sx={ { width: "100%" } }>
            <Box
              sx={ {
                width         : "350px",
                // width: "30%",
                display       : "flex",
                justifyContent: "space-between",
                alignItems    : "center",
                position      : "relative"
              } }>
              {/* <Button
                style={{
                  border: "1px solid #071AFF",
                  backgroundColor: "#F8F9FF",
                  color: "blue",
                  textTransform: "capitalize",
                  padding: "6px 30px",
                  marginRight: "30px",
                  borderRadius: "8px",
                  boxShadow: "none",
                }}
                onClick={() => {
                  history.push("/dashboard");
                }}
              >
                Dashboard
              </Button> */}

              <BackButton
                restrictedBackClick={ () => {

                  history.push(`/${data["backurl"]}`);
                
                } }
                backClick={ () => {

                  history.push(`/${data["backurl"]}`);
                
                } }
              />
              {/* <Button
                style={{
                  backgroundColor: "#071aff",
                  textTransform: "capitalize",
                  padding: "6px 30px",
                  marginRight: "30px",
                  borderRadius: "8px",
                  boxShadow: "none",
                  // position: "absolute",
                  // right: "0px",
                  // top: "-100px",
                  // display:
                  //   data.nextModule === "solutionintro" &&
                  //     user.access === "freetier"
                  //     ? "none"
                  //     : "initial",
                }}
                variant="contained"
                onClick={() => {
                  history.push("/pitchdeckintro");
                }}
              >
                Next Module
                <ArrowForwardIosIcon
                  sx={{ fontSize: "16px", marginLeft: "5px" }}
                />
              </Button> */}
            </Box>
          </Box>
        }

        {/* <BackButton
          restrictedBackClick={() => {
            history.push(`/${data["backurl"]}`);
          }}
          backClick={() => {
            history.push(`/${data["backurl"]}`);
          }}
        /> */}
      </Grid>
    </BoxWithoutIcon>
  );

};

export default GTMSummary;
