/* eslint-disable react/react-in-jsx-scope */
import CloseIcon from "@mui/icons-material/Close";
import {
  Box, TextField, Typography
} from "@mui/material";
// import { useHistory } from "react-router-dom";
import styles from "./Sections/Milestone.module.css";

export default function IndividualMilestone({
  isEdit,
  milestoneindex,
  milestone,
  handleChange,
  removeMilestone
  // ...props
}) {

  // const history = useHistory();
  // const pathname = history.location.pathname.substring(1);
  return (
    <>
      <Box
        id={styles.individualMilestoneBox}
      >
        <Box
          id={styles.milestoneNoBox}
        >
          <Typography
            id={styles.milestoneNoTypo}
          >
            {milestoneindex + 1}
          </Typography>
        </Box>
        {isEdit ?
          <Box
            sx={{ marginTop: "5px", marginBottom: "5px", position: "relative" }}
          >
            <TextField
              value={milestone.name}
              onChange={e => {

                handleChange("name", e.target.value, milestoneindex);
              
              }}
              fullWidth
              inputProps={{ maxLength: 15 }}
              sx={{
                "& .MuiInputBase-input": {
                  fontSize  : "16px",
                  padding   : "6px 10px",
                  border    : "none",
                  color     : "#393939",
                  textAlign : "center",
                  fontWeight: "bold"
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "2px dotted #B4B4FA"
                }
              }}
            />
            {milestoneindex > 2 &&
              <Box
                sx={{
                  width          : "15px",
                  height         : "15px",
                  border         : "1px solid #838383",
                  borderRadius   : "50%",
                  zIndex         : 20,
                  position       : "absolute",
                  right          : "-7px",
                  top            : "-7px",
                  display        : "flex",
                  justifyContent : "center",
                  alignItems     : "center",
                  backgroundColor: "#FFFFFF"
                }}
                onClick={() => {

                  removeMilestone(milestoneindex);
                
                }}
              >
                <CloseIcon sx={{ fontSize: "15px", color: "#838383" }} />
              </Box>
            }
          </Box>
          :
          <Box
            sx={{
              width       : "100%",
              textAlign   : "center",
              marginTop   : "5px",
              marginBottom: "5px"
            }}
          >
            <Typography
              id={styles.milestoneTitle}
            >
              {milestone.name}
            </Typography>
          </Box>
        }

        <Box
          id={styles.textFieldBox}
        >
          {isEdit ?
            <TextField
              value={milestone.value}
              onChange={e => {

                handleChange("value", e.target.value, milestoneindex);
              
              }}
              placeholder="Enter a key milestone: a goal you have set for your business, with target date & other relevant details."
              fullWidth
              multiline
              maxRows={6}
              minRows={6}
              inputProps={{ maxLength: 100 }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "7px 10px"
                },
                "& .MuiInputBase-input": {
                  fontSize  : "14px",
                  color     : "#707070",
                  whiteSpace: "pre-line"
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "2px dotted #B4B4FA"
                }
              }}
            />
            :
            <Box
              sx={{
                width       : "100%",
                border      : "1px solid #BEBEBE",
                height      : "25vh",
                borderRadius: "5px",
                overflowY   : "auto",
                display     : "flex",
                alignItems  : "center"
              }}
            >
              <Box
                sx={{
                  margin  : "7px 10px",
                  wordWrap: "break-word"
                }}
              >
                <Typography
                  sx={{
                    fontSize  : "1.9vh",
                    color     : "#707070",
                    lineHeight: "1.5",
                    whiteSpace: "pre-line",
                    padding   : "5px"
                  }}
                >
                  {milestone.value}
                </Typography>
              </Box>
            </Box>
          }
        </Box>
      </Box>
    </>
  );

}
