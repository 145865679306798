import React, { useEffect, useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box, Grid,
  Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import { getProblemSolution } from "../../../../services/problemsolution";
import DefensibilityAddRows from "../../Solution/DefensibilityAddRows";

export default function PitchCompAdvDefensibility() {

  const [ details, setDetails ] = useState([]);

  let { venture } = useSelector(state => {

    return { ...state };
  
  });

  useEffect(() => {

    fetchSolution();
  
  }, []);

  const fetchSolution = async () => {

    try {

      const response = await getProblemSolution(venture.problemSolution);

      setDetails(response.data.defensibityStrategy);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  return (
    <Box>
      <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
        <Box>
          <Typography
            sx={{ fontSize: "20px", color: "#5F5E5E", fontWeight: "bold" }}
          >
            Solution
          </Typography>
        </Box>

        <ChevronRightIcon sx={{ color: "gray" }} />
        <Box>
          <Typography>Defensibility Strategy</Typography>
        </Box>
      </Box>

      <Box>
        <Typography sx={{ fontSize: "12px" }}>
          Discover your strategy to make your product defensible and gain
          sustained competitive advantage.
        </Typography>
      </Box>

      <Box sx={{ width: "100%" }}>
        <Grid container columnGap={5} mb={1} mt={2}>
          <Grid
            item
            md={3}
            sx={{
              border      : "1px solid #071aff",
              borderRadius: "5px",
              padding     : "10px 20px",
              paddingRight: "0px"
            }}
          >
            <Typography sx={{ fontWeight: "bold", color: "#393939" }}>
              Defensibility Strategy
            </Typography>
          </Grid>
          <Grid
            item
            md={8}
            sx={{
              border      : "1px solid #071aff",
              borderRadius: "5px",
              padding     : "10px 20px",
              paddingRight: "0px"
            }}
          >
            <Typography sx={{ color: "#5F5E5E", fontSize: "14px" }}>
              How would you employ your chosen strategy to give your project an
              edge over the competition?
            </Typography>
          </Grid>
        </Grid>
        {details.map((detail, index) => {

          return <DefensibilityAddRows
            //   isdelete={isdelete}
            detail={detail}
            index={index}
            //   handleChange={handleChange}
            //   removeRow={removeRow}
          />;
        
        })}
      </Box>
    </Box>
  );

}
