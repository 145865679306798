import {
  Grid, Typography
} from "@mui/material";
import { Country, State } from "country-state-city";
import React from "react";
// import { useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
// import Data from "../../../../Data/Gtm.json";

// import { makeStyles } from "@mui/styles";
// import BackNextButton from "../../../../components/BackNextButton";
import CustomSelect from "../../../../components/CustomSelect";
import styles from "./TargetSegments.module.css";

// const useStyles = makeStyles(theme => ({
//   customWidth: {
//     width          : "100%",
//     fontSize       : 10,
//     backgroundColor: "white",
//     color          : "#707070",
//     boxShadow      : "0px 3px 6px #00000029",
//     border         : "1px solid #707070",
//     borderRadius   : 10
//   }
// }));

// const bar = {
//   height         : "4px",
//   width          : "50px",
//   borderRadius   : "50px",
//   backgroundColor: "grey",
//   margin         : "5px 20px",
//   marginLeft     : "0px"
// };
// const bar_blue = {
//   height         : "4px",
//   width          : "50px",
//   borderRadius   : "50px",
//   backgroundColor: "blue",
//   margin         : "5px 20px",
//   marginLeft     : "0px"
// };

const Geographics = props => {

  // const history = useHistory();
  // const [ Collapsecomment, setCollapsecomment ] = useState(false);
  // const [ dialogbox, setDialog ] = useState(false);

  // const classes = useStyles();

  // let { venture } = useSelector(state => {

  //   return { ...state };
  
  // });

  // const pathname = history.location.pathname.substring(1);
  // const guideicon = Data.gtmtargetpersonas;

  // const Dialogopen = () => {

  //   setDialog(true);
  
  // };
  // const Dialogclose = () => {

  //   setDialog(false);
  
  // };

  // const collapse_comment_function = () => {

  //   if (Collapsecomment) {

  //     setCollapsecomment(false);
    
  //   } else {

  //     setCollapsecomment(true);
    
  //   }
  
  // };

  const countries = Country.getAllCountries();
  const updatedCountries = countries.map(country => ({
    label: country.name,
    value: country.name,
    ...country
  }));

  const updatedStates = countryId => {

    let statesOptions = [
      {
        label: "-None-",
        value: ""
      }
    ];

    State.getStatesOfCountry(countryId).map(state => {

      statesOptions.push({
        label: state.name,
        value: state.name,
        ...state
      });
    
    });
    
    return statesOptions;
  
  };

  const details = props.details;

  return (
    <Grid id={ styles.maingrid2 }>
      <Grid container mb={ 5 } mt={ 2 } id={ styles.gridContainer }>
        <Grid item xs={ 12 } md={ 5 }>
          <Typography variant="body2" sx={ { color: "#393939" } }>
              Where are they located?
          </Typography>
        </Grid>
        <Grid item xs={ 12 } md={ 6 }>
          <CustomSelect
            placeholder="Select country"
            options={ [ { value: "", label: "-None-" }, ...updatedCountries ] }
            value={ details.location }
            handleChange={ e => {

              props.handleLocationChange("location", e);
            
            } }
          />
        </Grid>
      </Grid>
      <Grid container id={ styles.gridContainer }>
        <Grid item xs={ 12 } md={ 5 }>
          <Typography variant="body2" sx={ { color: "#393939" } }>
              What is their state/region?
          </Typography>
        </Grid>
        <Grid item xs={ 12 } md={ 6 }>
          <CustomSelect
            placeholder="Select state/region"
            value={ details.state }
            handleChange={ e => {

              props.handleChange("state", e.value);
            
            } }
            options={ updatedStates(
              details.isoCode ? details.isoCode : null
            ) }
            noOptionsMessage={ () => "Not Available" }
            maxMenuHeight={ 200 }
          />
        </Grid>
      </Grid>
      <Grid container id={ styles.gridContainer }>
        <Typography sx={ { fontSize: "11px", fontStyle: "italic" } }>
          *Answer all questions that could be relevant for your product or
          brand. It’s ok to skip questions you can’t answer at the moment. As
          you learn more about your target users, return to these pages to
          further develop the persona.
        </Typography>
      </Grid>
    </Grid>
  );

};

export default Geographics;
