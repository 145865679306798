import React from "react";
import {
  Box
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const LightTooltip = styled(({ className, ...props }) =>
  <Tooltip {...props} classes={{ popper: className }} />
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color          : "rgba(0, 0, 0, 0.87)",
    boxShadow      : theme.shadows[1],
    fontSize       : 11,
    width          : "200px",
    // width: "245px",
    height         : "105px",
    // height: "140px",
    border         : "1px solid blue",
    display        : "flex",
    justifyContent : "center",
    alignItems     : "center",
    cursor         : "pointer"
  }
}));

export default function IndividualHoverBarResponsive(props) {

  return (
    <LightTooltip
      placement="top"
      title={
        <Box
          onClick={e => {

            e.stopPropagation();
            props.setCurrentSlide(props.index);
          
          }}
          sx={{
            // border: 1,
            width         : "100%",
            height        : "100%",
            display       : "flex",
            justifyContent: "center",
            alignItems    : "center",
            position      : "relative"
          }}
        >
          <img
            src={props.img.imageUrl}
            // src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/${props.slide.thumbnailImg}`}
            alt=""
            style={{ width: "100%", height: "100%" }}
          />

          {/* <Box sx={{ position: "absolute", left: "0px", top: "0px" }}>
            <Typography sx={{ fontSize: "11px", fontWeight: "bold" }}>
              {props.slide.name}
            </Typography>
          </Box> */}
        </Box>
      }
    >
      <Box
        sx={{
          // border: 1,
          display       : "flex",
          justifyContent: "center",
          alignItems    : "center",
          width         : "100%",
          height        : "30px",
          cursor        : "pointer"
        }}
        onClick={e => {

          e.stopPropagation();
          props.setCurrentSlide(props.index);
        
        }}
      >
        <Box
          sx={{
            // border: 1,
            // width: "4.5%",
            width          : "100%",
            height         : "4px",
            backgroundColor: "#071AFF",
            // backgroundColor:
            //   props.isRender === props.index ? "#071AFF" : "#071AFF",
            opacity        : props.currentSlide === props.index ? "1" : "0.4",
            // border: 1,
            position       : "relative",
            // cursor: "pointer",
            borderRadius   : "3px"
          }}
        ></Box>
      </Box>
    </LightTooltip>
  );

}
