/* eslint-disable camelcase */
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EventIcon from "@mui/icons-material/Event";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box, Button, Dialog,
  DialogContent, Grid, IconButton, Table, TableCell, TableContainer, TableHead,
  TableRow, Tooltip, Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BackNextButton from "../../../../components/BackNextButton";
import IndividualSectionHeaders from "../../../../components/IndividualSectionHeaders";
import ModuleSections from "../../../../components/ModuleSections";
import styles from "./Milestone.module.css";
import MilestoneTable from "./MilestoneTable";

import { tableCellClasses } from "@mui/material/TableCell";
// import { tooltipClasses } from "@mui/material/Tooltip";
import { useUnmount } from "../../../../components/useUnmount";
import {
  createOperationsComment,
  deleteOperationsComment,
  updateOperationsComment
} from "../../../../services/comment";
import {
  getOperations,
  updateOperations
} from "../../../../services/operations";
import GanttChart from "./GanttChart";
import Board from "./kanban/Board";

const GraphicalViewButton = styled(Button)({
  boxShadow      : "none",
  textTransform  : "none",
  fontSize       : 12,
  padding        : "6px 12px",
  border         : "1px solid",
  lineHeight     : 1.5,
  backgroundColor: "#white",
  borderColor    : "#071AFF",
  color          : "#071AFF",
  width          : 190,
  height         : 41,

  "&:hover": {
    backgroundColor: "#0069d9",
    borderColor    : "#071AFF",
    boxShadow      : "none",
    color          : "white"
  },
  "&:active": {
    boxShadow      : "none",
    backgroundColor: "#071AFF",
    borderColor    : "#071AFF"
  }
  // "&:focus": {
  //   boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  // },
});

// const LightTooltip = styled(({ className, ...props }) =>
//   <Tooltip { ...props } classes={ { popper: className } } />
// )(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.common.white,
//     // color: "rgba(0, 0, 0, 0.87)",
//     boxShadow      : theme.shadows[1],
//     fontSize       : "14px",
//     color          : "#FF7F00",
//     border         : "1px solid #FF7F00",
//     paddingTop     : "5px 10px",
//     borderRadius   : "10px",
//     fontWeight     : "bold"
//   }
// }));

export default function OpMilestone () {

  const [ milestones, setMilestones ] = useState([
    {
      name      : "",
      startDate : "",
      endDate   : "",
      status    : "Not Started",
      activities: [
        {
          name     : "",
          startDate: "",
          endDate  : "",
          status   : "Not Started"
        }
      ]
    }
  ]);
  const [ comments, setComments ] = useState([]);
  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const [ dialogbox, setDialog ] = useState(false);
  const [ chartDialog, setChartDialog ] = useState(false);
  const [ kanbanDialog, setKanbanDialog ] = useState(false);
  const [ ismilestonedelete, setIsmilestonedelete ] = useState(false);
  const [ isactivitydelete, setIsactivitydelete ] = useState(false);
  const [ selectedmilestoneindex, setSelectedmilestoneindex ] = useState(0);
  const [ chartdata, setChartdata ] = useState([]);
  const [ milestoneRanges, setMilestoneranges ] = useState([]);
  const [ isDataLoading, setIsDataLoading ] = useState(true);

  const [ errormsg, setErrormsg ] = useState("");

  const { user, venture } = useSelector(state => ({ ...state }));

  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);
    
    } else {

      setCollapsecomment(true);
    
    }
  
  };

  const ChartDialogopen = () => {

    setChartDialog(true);
  
  };
  const ChartDialogclose = () => {

    setChartDialog(false);
  
  };

  const KanbanDialogopen = () => {

    setKanbanDialog(true);
  
  };
  const KanbanDialogclose = () => {

    setKanbanDialog(false);
  
  };

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };
  const history = useHistory();

  useEffect(() => {

    fetchOperations();
  
  }, []);

  const fetchOperations = async () => {

    setIsDataLoading(true);

    try {

      const response = await getOperations(venture.operations);

      setMilestones(response.data.milestones);
      setComments(response.data.comments.milestones);
      setIsDataLoading(false);
    
    } catch (error) {

      console.error(error);
      setIsDataLoading(false);
    
    }
  
  };

  // To get the updated usestate value to save in useEffect cleanup
  const detailsRef = useRef(milestones);

  detailsRef.current = milestones;

  let isSubmitting = false;

  // useUnmount => custom hook to save while navigating
  useUnmount(() => {

    if ( isDataLoading == false && !isSubmitting) {

      handleSave();
    
    }
  
  });

  const handleSave = async () => {

    try {

      await updateOperations(
        venture.operations,
        "milestones",
        detailsRef.current
      );
      
      return true;
    
    } catch (error) {

      console.error(error);
      
      return false;
    
    }
  
  };

  const removeMilestone = async milestoneindex => {

    let newMilestones = milestones.filter(
      (milestone, index) => index !== milestoneindex
    );

    setMilestones(newMilestones);
    setIsmilestonedelete(false);
    await updateOperations(venture.operations, "milestones", newMilestones);
  
  };

  const removeActivity = async (milestoneindex, activityindex) => {

    let newMilestones = milestones.map((milestone, index) => {

      if (index === milestoneindex) {

        return {
          ...milestone,
          ["activities"]: milestone.activities.filter(
            (activity, index) => index !== activityindex
          )
        };
      
      } else {

        return milestone;
      
      }
    
    });

    setMilestones(newMilestones);
    setIsactivitydelete(false);
    await updateOperations(venture.operations, "milestones", newMilestones);
  
  };

  const addMilestone = async () => {

    let newMilestones = [ ...milestones ];

    newMilestones.push({
      name      : "",
      startDate : "",
      endDate   : "",
      status    : "Not Started",
      activities: []
    });
    setMilestones(newMilestones);
    await updateOperations(venture.operations, "milestones", newMilestones);
  
  };

  const addActivity = async milestoneindex => {

    let newMilestones = milestones.map((milestone, index) => {

      if (index === milestoneindex) {

        return {
          ...milestone,
          ["activities"]: [
            ...milestone.activities,
            {
              name     : "",
              startDate: "",
              endDate  : "",
              status   : "Not Started"
            }
          ]
        };
      
      } else {

        return milestone;
      
      }
    
    });

    setMilestones(newMilestones);
    await updateOperations(venture.operations, "milestones", newMilestones);
  
  };

  const handleMilestonesChange = (name, value, milestoneindex) => {

    let newMilestones = milestones.map((milestone, index) => {

      if (index === milestoneindex) {

        return {
          ...milestone,
          [name]: value
        };
      
      } else {

        return milestone;
      
      }
    
    });

    setMilestones(newMilestones);
  
  };

  const handleActivitiesChange = (
    name,
    value,
    milestoneindex,
    activityindex
  ) => {

    let newMilestones = milestones.map((milestone, index) => {

      if (index === milestoneindex) {

        let newmilestone = milestone;

        newmilestone.activities = milestone.activities.map(
          (activity, index) => {

            if (index === activityindex) {

              return {
                ...activity,
                [name]: value
              };
            
            } else {

              return activity;
            
            }
          
          }
        );
        
        return newmilestone;
      
      } else {

        return milestone;
      
      }
    
    });

    setMilestones(newMilestones);
  
  };

  const handleSubmit = async () => {

    try {

      isSubmitting = true;
      if(isDataLoading == false){
        
        await updateOperations(
          venture.operations,
          "milestones",
          milestones
        );

      }
      history.push("/team");
      isSubmitting = false;
    
    } catch (error) {

      isSubmitting = false;
      console.error(error);
    
    }
  
  };

  // const generateGanttChartData = () => {
  //   let newchartdata = milestones.map((milestone, milestoneindex) => {
  //     return {
  //       id: `${milestoneindex + 1}`,
  //       name: milestone.name,
  //       actualStart: milestone.startDate,
  //       actualEnd: milestone.endDate,
  //       status: milestone.status,
  //       children: milestone.activities.map((activity, activityindex) => {
  //         return {
  //           id: `${milestoneindex + 1}_${activityindex + 1}`,
  //           name: activity.name,
  //           actualStart: activity.startDate,
  //           actualEnd: activity.endDate,
  //           status: activity.status,
  //         };
  //       }),
  //     };
  //   });
  //   console.log(newchartdata);
  //   setChartdata(newchartdata);
  // };

  const generateGanttChartData = () => {

    let newchartdata = [];
    let ranges = [];

    milestones.map(milestone => {

      newchartdata.push({
        name       : milestone.name,
        status     : milestone.status,
        isMilestone: true,
        data       : [
          {
            x: milestone.name,
            y: [
              new Date(milestone.startDate).getTime(),
              new Date(milestone.endDate).getTime()
            ]
          }
        ]
      });
      milestone.activities.map(activity => {

        newchartdata.push({
          name       : activity.name,
          status     : activity.status,
          isMilestone: false,
          data       : [
            {
              x: milestone.name,
              y: [
                new Date(activity.startDate).getTime(),
                new Date(activity.endDate).getTime()
              ]
            }
          ]
        });
      
      });
    
    });
    // console.log(newchartdata);
    setChartdata(newchartdata);
    setMilestoneranges(ranges);
  
  };

  const addComment = async (commentText, replyTo) => {

    try {

      const response = await createOperationsComment(
        commentText,
        user._id,
        "milestones",
        "",
        venture.operations,
        replyTo,
        window.location.href,
        "Operations > Milestones"
      );

      setComments(response.data.comments.milestones);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateComment = async (commentid, commentText) => {

    try {

      const response = await updateOperationsComment(
        commentText,
        user._id,
        commentid,
        venture._id,
        window.location.href,
        "Operations > Milestones"
      );

      setComments(response.data.comments.milestones);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const deleteComment = async commentid => {

    try {

      const response = await deleteOperationsComment(
        "milestones",
        "",
        venture.operations,
        commentid,
        user._id,
        window.location.href,
        "Operations > Milestones"
      );

      setComments(response.data.comments.milestones);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  // console.log(milestones);

  return (
    <ModuleSections
      Collapsecomment={ Collapsecomment }
      comments={ comments }
      addComment={ addComment }
      deleteComment={ deleteComment }
      updateComment={ updateComment }>
      <div className="box-display-style-for-next-and-back-button-with-justify">
        <div className="box-indiv-header-con">
          <IndividualSectionHeaders
            mainheading={ "Operations" }
            subheading={ "Milestones" }
            headingcontent={ "Create an actionable roadmap for your product" }
            isGtm={ false }
            Dialogopen={ Dialogopen }
            Dialogclose={ Dialogclose }
            dialogbox={ dialogbox }
            collapse_comment_function={ collapse_comment_function }
            content={ {
              dialogpurpose: "",
              dialoganalogy: "",
              dialogguide  : []
            } }
            isSaveButton={ true }
            handleSave={ handleSave }
          //   isexpand={isexpand}
          />
        </div>
        <Grid
          item
          xs={ 12 }
          id={ styles.milstoneMainGrid }
          onClick={ () => {

            setIsmilestonedelete(false);
            setIsactivitydelete(false);
          
          } }>
          <Box id={ styles.tableBox }>
            <TableContainer>
              <Table
                sx={ {
                  [`& .${tableCellClasses.root}`]: {
                    borderBottom: "none"
                  }
                } }
                size="small">
                <TableHead>
                  <TableRow id={ styles.tableRowHead }>
                    <TableCell id={ styles.tableCell }>
                      <Typography
                        align="center"
                        id={ styles.tableTypographyHead }></Typography>
                    </TableCell>
                    <TableCell id={ styles.tableCell }>
                      <Typography id={ styles.tableTypographyHeadFirst }>
                        Milestone
                      </Typography>
                    </TableCell>
                    <TableCell align="left" id={ styles.tableCell }>
                      <Typography id={ styles.tableTypographyHead2 }>
                        <EventIcon></EventIcon>
                        Start Date
                      </Typography>
                    </TableCell>
                    <TableCell align="left" id={ styles.tableCell }>
                      <Typography id={ styles.tableTypographyHead2 }>
                        <EventIcon></EventIcon>
                        End Date
                      </Typography>
                    </TableCell>
                    <TableCell align="left" id={ styles.tableCell }>
                      <Typography id={ styles.tableTypographyHead }>
                        Status
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

                {/* Table body */ }
                { milestones.map((milestone, milestoneindex) => {

                  return <MilestoneTable
                    milestone={ milestone }
                    milestoneindex={ milestoneindex }
                    ismilestonedelete={ ismilestonedelete }
                    isactivitydelete={ isactivitydelete }
                    removeMilestone={ removeMilestone }
                    removeActivity={ removeActivity }
                    addMilestone={ addMilestone }
                    addActivity={ addActivity }
                    handleMilestonesChange={ handleMilestonesChange }
                    handleActivitiesChange={ handleActivitiesChange }
                    setIsactivitydelete={ setIsactivitydelete }
                    setSelectedmilestoneindex={ setSelectedmilestoneindex }
                    selectedmilestoneindex={ selectedmilestoneindex }
                    setErrormsg={ setErrormsg }
                    errormsg={ errormsg }
                  />;
                
                }) }
              </Table>
            </TableContainer>

            <Box id={ styles.buttonBox }>
              {/* <Button
              id={styles.addMilestoneButton}
              onClick={() => {
                addMilestone();
              }}
            >
              <AddCircleIcon fontSize="small" id={styles.milestoneAddIcon}>
              </AddCircleIcon>
              Add Milestone
            </Button> */}
              <Tooltip title="Add Milestone" placement="right">
                <IconButton
                  id={
                    venture.userRole === "Commenter"
                      ? styles.disabledaddMilestoneButton
                      : styles.addMilestoneButton
                  }
                  onClick={ () => {

                    addMilestone();
                  
                  } }
                  disabled={ venture.userRole === "Commenter" }>
                  <AddCircleIcon id={ styles.milestoneAddIcon }> </AddCircleIcon>
                </IconButton>
              </Tooltip>
              {/* {errormsg && (
              <ErrorMessage errormsg={errormsg} padding="10px 20px" />
            )}*/}

              {/* <Button
              id={
                ismilestonedelete
                  ? styles.disableddelMilestoneButton
                  : styles.delMilestoneButton
              }
              onClick={(e) => {
                if (!ismilestonedelete && !isactivitydelete) {
                  e.stopPropagation();
                  setIsmilestonedelete(true);
                }
              }}
            >
              <RemoveCircleIcon
                fontSize="small"
                id={styles.milestoneRemoveIcon}
              />
              Delete Milestone
            </Button> */}
            </Box>
            <Box sx={ { height: "3px" } }></Box>
          </Box>
          <hr></hr>
          {/* graphical view button */ }
          <Typography variant="body2" id={ styles.milestoneGraphicalHead }>
            Click any of the below buttons for a graphical view
          </Typography>
          <Grid
            id={ styles.graphicalViewButtonGrid }
            container
            justifyContent="space-around"
            align="center"
            justify="center"
            alignItems="center">
            <Grid item xs={ 6 }>
              {/* <LightTooltip placement="top" title="Coming soon">*/ }
              <GraphicalViewButton
                onClick={ () => {

                  generateGanttChartData();
                  ChartDialogopen();
                
                } }>
                Gantt Chart Visual
              </GraphicalViewButton>
              {/* </LightTooltip>*/ }
              <Dialog
                open={ chartDialog }
                onClose={ ChartDialogclose }
                PaperProps={ {
                  style: {
                    borderRadius   : "20px",
                    backdropFilter : "blur(100px)",
                    backgroundColor: "white",
                    boxShadow      : "0px 3px 6px #00000029",
                    border         : "1px solid #b8bddb"
                  }
                } }
                BackdropProps={ { invisible: true } }
                maxWidth="lg"
                fullWidth>
                <DialogContent>
                  <GanttChart
                    series={ chartdata }
                    milestoneRanges={ milestoneRanges }
                  />
                </DialogContent>
              </Dialog>
            </Grid>
            <Grid item xs={ 6 }>
              <GraphicalViewButton
                onClick={ () => {

                  KanbanDialogopen();
                
                } }>
                Kanban View
              </GraphicalViewButton>
              <Dialog
                open={ kanbanDialog }
                onClose={ KanbanDialogclose }
                PaperProps={ {
                  style: {
                    borderRadius   : "20px",
                    backdropFilter : "blur(100px)",
                    backgroundColor: "white",
                    boxShadow      : "0px 3px 6px #00000029",
                    border         : "1px solid #b8bddb",
                    height         : "500px"
                  }
                } }
                BackdropProps={ { invisible: true } }
                maxWidth="lg"
                fullWidth>
                <Box id={ styles.dialogOffIcon }>
                  <IconButton component="span" onClick={ KanbanDialogclose }>
                    <HighlightOffIcon />
                  </IconButton>
                </Box>
                <DialogContent>
                  <Board milestones={ milestones } />
                </DialogContent>
              </Dialog>
            </Grid>

            {/* <Grid item xs={4}>
            <GraphicalViewButton
              onClick={() => {
                ChartDialogopen();
              }}
            >
              Some text
            </GraphicalViewButton>
          </Grid> */}
          </Grid>

          <hr></hr>
        </Grid>
        <div className="ttribe-back-next-button-con">
          <BackNextButton
            restrictedBackClick={ () => {

              history.push("/operationsintro");
            
            } }
            backClick={ () => {

              history.push("/operationsintro");
            
            } }
            restrictedNextClick={ () => {

              history.push("/team");
            
            } }
            nextClick={ () => {

              handleSubmit();
            
            } }
          />
        </div>
      </div>
    </ModuleSections>
  );

}
