import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ErrorMessage from "../../../components/ErrorMessage";
import { auth } from "../../../firebase";
// import { userVerified } from "../../../services/auth";
import { sendPasswordResetEmail } from "firebase/auth";
import styles from "./ForgotPassword.module.css";

const ForgotPassword = ({ history }) => {

  const [ email, setEmail ] = useState("");
  const [ errormsg, setErrormsg ] = useState("");
  const [ errorflag, setErrorflag ] = useState(false);

  const { user } = useSelector(state => {

    return { ...state };
  
  });

  useEffect(() => {

    if (user && user.token) history.push("/");
  
  }, [ user, history ]);
  // user as dependency

  useEffect(() => {

    if (!errorflag) {

      setErrormsg("");
    
    }
  
  }, [ errorflag ]);

  const handleSubmit = async e => {

    e.preventDefault();
    if (email && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {

      // const response = await userVerified(email);

      // if (response.data === "User Not Verified") {

      //   setErrormsg(
      //     "We couldn’t find an account with that email ID. Please try a different ID."
      //   );
      //   setErrorflag(true);
        
      //   return;
      
      // }

      const config = {
        url            : `${window.location.origin}${process.env.REACT_APP_FORGOT_PASSWORD_REDIRECT}`,
        handleCodeInApp: true
      };

      await sendPasswordResetEmail(auth, email, config).then(() => {

        setEmail("");
        
      }).catch(error => {

        console.error(error.message);
        
      });
      
      history.push({
        pathname: "/resendlink",
        hash    : "forgotpassword"
      });
    
    } else {

      setErrormsg("Please enter a valid email ID.");
      setErrorflag(true);
    
    }
  
  };

  
  return (
    <div style={ { height: "100%" } }>
      <Box id={ styles.mainbox }>
        <Grid container id={ styles.maingrid }>
          { errormsg && <ErrorMessage errormsg={ errormsg } width="375px" /> }
          <Box
            component="form"
            onSubmit={ handleSubmit }
            sx={ {
              "& .MuiTextField-root": { width: "375px", height: "50px" },
              marginTop             : "20px"
            } }
            noValidate
            autoComplete="off"
          >
            <Button
              id={ styles.backbtn }
              onClick={ () => {

                history.push("/login");
              
              } }
            >
              back to Login
            </Button>
            <Typography
              variant="h5"
              sx={ {
                fontWeight  : "bold",
                marginBottom: "16px",
                fontSize    : "20px"
              } }
            >
              Forgot Password
            </Typography>
            <div>
              <TextField
                type="email"
                placeholder="Your email"
                autoFocus
                fullWidth
                value={ email }
                onChange={ e => {

                  setErrorflag(false);
                  setEmail(e.target.value);
                
                } }
              />
            </div>
            <Button
              id={ styles.btn }
              variant="contained"
              disabled={ !email }
              type="submit"
            >
              send reset link
            </Button>
          </Box>
          <Typography sx={ { fontSize: "14px", marginTop: "20px" } }>
            Use the link sent to your email to reset the password
          </Typography>
        </Grid>
      </Box>
    </div>
  );

};

export default ForgotPassword;
