import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box, Button, Dialog,
  DialogContent, Grid, IconButton, Slide, Typography
} from "@mui/material";
import React, { forwardRef, useState } from "react";
import { useSelector } from "react-redux";
import EditBeforeAfterState from "./EditBeforeAfterState";
import EditDifferentiation from "./EditDifferentiation";
import EditUsecases from "./EditUsecases";

import styles from "./Positioning.module.css";

const Transition = forwardRef(function Transition (props, ref) {

  return <Slide direction="up" ref={ ref } { ...props } />;

});

const IndividualBoard = props => {

  const [ helpdialogbox, sethelpDialog ] = useState(false);
  const [ maindialog, setMaindialog ] = useState(false);
  const [ isSuccess, setIssuccess ] = useState(false);

  let { venture } = useSelector(state => {

    return { ...state };
  
  });

  // const helpDialogopen = () => {

  //   sethelpDialog(true);
  
  // };
  const helpDialogclose = () => {

    sethelpDialog(false);
  
  };

  const mainDialogopen = () => {

    setMaindialog(true);
  
  };
  const mainDialogclose = () => {

    setMaindialog(false);
  
  };

  const board = props.board;
  const positioning = props.positioning;

  const handleSave = async () => {

    try {

      await props.handleSubmit();
      
      return true;
    
    } catch (error) {

      console.error(error);
      
      return false;
    
    }
  
  };

  return (
    <Grid item md={ 5 } id={ styles.individualbox }>
      <Dialog
        open={ maindialog }
        TransitionComponent={ Transition }
        keepMounted
        PaperProps={ {
          style: {
            backgroundColor: "#ffffff",
            boxShadow      : "0px 3px 6px #00000029",
            border         : "1px solid #CCCCCC",
            borderRadius   : "15px"
          }
        } }
        // BackdropProps={{ invisible: true }}
        maxWidth="md"
        fullWidth
      >
        <Box
          style={ {
            display       : "flex",
            justifyContent: "flex-end",
            alignItems    : "center"
          } }
        >
          <Box sx={ { marginRight: "10px", position: "relative" } }>
            { isSuccess &&
              <Box sx={ { position: "absolute", top: "32px", left: "10px" } }>
                <Typography sx={ { color: "#08910B", fontSize: "14px" } }>
                  Saved
                </Typography>
              </Box>
            }
            <Button
              variant="contained"
              color="primary"
              sx={ { padding: "3px 12px", textTransform: "capitalize" } }
              onClick={ async e => {

                e.stopPropagation();
                const isSaved = await handleSave();

                if (isSaved) {

                  setIssuccess(true);
                  setTimeout(() => {

                    setIssuccess(false);
                  
                  }, 3000);
                
                }
              
              } }
              disabled={ venture.userRole === "Commenter" }
            >
              Save
            </Button>
          </Box>
          <IconButton
            onClick={ () => {

              props.handleSubmit();
              mainDialogclose();
            
            } }
          >
            <HighlightOffIcon id={ styles.highlightofficon } />
          </IconButton>
        </Box>
        <DialogContent>
          { board.sectionname === "usecases" ?
            <EditUsecases
              usecases={ positioning.usecases }
              handleUsecases={ props.handleUsecases }
              addRow={ props.addRow }
              removeRow={ props.removeRow }
            />
            : board.sectionname === "differentiation" ?
              <EditDifferentiation
                details={ positioning.differentiation }
                handleDifferentiation={ props.handleDifferentiation }
              />
              :
              <EditBeforeAfterState
                question={ board.maindialogheading }
                name={ board.sectionname }
                value={ positioning.beforeAfterState[board.sectionname] }
                handleChange={ props.handleChange }
                // -------------
                // helpDialogopen={helpDialogopen}
                dialog={ board.dialog }
              />
          }
        </DialogContent>
      </Dialog>
      <span id={ styles.gridnumber }>{ board.number }</span>
      <Typography id={ styles.title }>
        { board.title }
        {/* <IconButton
          sx={{ padding: "0px", marginLeft: "10px" }}
          onClick={helpDialogopen}
        >
          <HelpIcon id={styles.icon}></HelpIcon>
        </IconButton> */}
        <Dialog
          open={ helpdialogbox }
          PaperProps={ {
            style: {
              borderRadius   : "20px",
              backdropFilter : "blur(45px)",
              // backgroundColor: "initial",
              backgroundColor: "white",
              boxShadow      : "0px 3px 6px #00000029",
              border         : "1px solid #707070",
              height         : "70%"
            }
          } }
          // BackdropProps={{ invisible: true }}
          maxWidth="md"
          fullWidth
        >
          <Box style={ { alignSelf: "flex-end" } }>
            <IconButton onClick={ helpDialogclose }>
              <HighlightOffIcon id={ styles.highlightofficon } />
            </IconButton>
          </Box>

          <DialogContent>
            <Typography sx={ { whiteSpace: "pre-wrap" } }>
              { board.dialog }
            </Typography>
          </DialogContent>
        </Dialog>{ " " }
      </Typography>
      {/* changes---------------------------------------- */ }
      <Box
        sx={ {
          // border: 1,
          display       : "flex",
          justifyContent: "center",
          alignItems    : "center",
          flexDirection : "column"
        } }
      >
        <Box sx={ { height: "65px", textAlign: "center" } }>
          { " " }
          <Typography sx={ { fontSize: "14px" } }>{ board.description }</Typography>
        </Box>

        <img
          src={ `${process.env.REACT_APP_AWS_ASSETS_URL}${board.imgsrc}` }
          alt="sb-1"
          id={ styles.image }
          onClick={ mainDialogopen }
        />
      </Box>
      {/* ---------------------------------------------------------- */ }
      {/* <Typography>{board.description}</Typography> */ }
    </Grid>
  );

};

export default IndividualBoard;
