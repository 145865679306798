/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import ErrorMessage from "../../../../components/ErrorMessage";
import HelpIconTooltip from "../../../../components/HelpIconTooltip";
import IndividualSectionHeaders from "../../../../components/IndividualSectionHeaders";
import ModuleSections from "../../../../components/ModuleSections";
import Demographics1 from "./Demographics1";

// import { makeStyles } from "@mui/styles";
import BackNextButton from "../../../../components/BackNextButton";
import CustomSelect from "../../../../components/CustomSelect";
import CustomTextfield from "../../../../components/CustomTextfield";
import Data from "../../../../Data/data.json";
import gtmData from "../../../../Data/Gtm.json";
import { createPersona, getPersona } from "../../../../services/persona";
import Demographics2 from "./Demographics2";
import Geographics from "./Geographics";
import IndividualCharacteristicsthird from "./IndividualCharacteristicsthird";
import OtherDetails from "./OtherDetails";
import styles from "./TargetSegments.module.css";

// const useStyles = makeStyles(theme => ({
//   customWidth: {
//     width          : "100%",
//     fontSize       : 10,
//     backgroundColor: "white",
//     color          : "#707070",
//     boxShadow      : "0px 3px 6px #00000029",
//     border         : "1px solid #707070",
//     borderRadius   : 10
//   }
// }));

const bar = {
  height         : "3px",
  width          : "55px",
  borderRadius   : "50px",
  backgroundColor: "#B1AAAA",
  margin         : "5px 20px",
  marginLeft     : "0px"
};
const bar_blue = {
  height         : "3px",
  width          : "55px",
  borderRadius   : "50px",
  backgroundColor: "blue",
  margin         : "5px 20px",
  marginLeft     : "0px"
};

const UserPersona = () => {

  const [ details, setDetails ] = useState({
    type                 : "",
    name                 : "",
    avatar               : "/images/avatar1.png",
    gender               : "",
    age                  : "20-35",
    qualification        : "",
    occupation           : "",
    ethnicity            : "",
    industry             : "",
    size                 : "",
    income               : "$100k-200K",
    demographicfactors   : "",
    location             : "",
    state                : "",
    isoCode              : "",
    personalityTraits    : "",
    opinionsOrBeliefs    : "",
    inspiresOrLifeGoals  : "",
    interestOrHobbies    : "",
    psychographicFactors : "",
    buySolution          : "",
    oftenBuy             : "",
    loyal                : "",
    influences           : "",
    buyingBehaviours     : "",
    outcomes             : "",
    goalsOrObjectives    : "",
    motivations          : "",
    decisions            : "",
    jobResponsibilities  : "",
    jobOutcome           : "",
    report               : "",
    tools                : "",
    socialMediaActiveness: [
      {
        icon     : "",
        ispresent: false
      },
      {
        icon     : "",
        ispresent: false
      },
      {
        icon     : "",
        ispresent: false
      },
      {
        icon     : "",
        ispresent: false
      },
      {
        icon     : "",
        ispresent: false
      }
    ],
    informationNeeds       : "",
    relevantCharacteristics: ""
  });
  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const [ dialogbox, setDialog ] = useState(false);
  const [ errormsg, setErrormsg ] = useState("");
  const history = useHistory();
  const location = useLocation();
  // const dispatch = useDispatch();

  // const classes = useStyles();

  let { venture } = useSelector(state => {

    return { ...state };
  
  });

  let hash = location.hash.substring(1);
  const pathname = history.location.pathname.substring(1);
  const segmentname = localStorage.getItem("name");
  const personaid = localStorage.getItem("personaid");
  let newhash = parseInt(hash);

  const data = Data.userpersona[hash];
  const avatars = Data.avatarimages;
  const guideicon = gtmData.gtmtargetpersonas;

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);
    
    } else {

      setCollapsecomment(true);
    
    }
  
  };

  useEffect(() => {

    fetchPersona();
  
  }, []);

  const fetchPersona = async () => {

    const personaid = localStorage.getItem("personaid");

    if (personaid) {

      const response = await getPersona(personaid, venture.problemSolution);
      let persona = response.data.persona;
      let newdetails = {
        type                : persona.type,
        name                : persona.name,
        avatar              : persona.avatar,
        gender              : persona.demographics.gender,
        age                 : persona.demographics.age,
        qualification       : persona.demographics.qualification,
        occupation          : persona.demographics.occupation,
        ethnicity           : persona.demographics.ethnicity,
        industry            : persona.demographics.industry,
        size                : persona.demographics.size,
        income              : persona.demographics.income,
        demographicfactors  : persona.demographics.demographicfactors,
        location            : persona.geographics.location,
        state               : persona.geographics.state,
        isoCode             : persona.geographics.isoCode,
        personalityTraits   : persona.psychographics.personalityTraits,
        opinionsOrBeliefs   : persona.psychographics.opinionsOrBeliefs,
        inspiresOrLifeGoals : persona.psychographics.inspiresOrLifeGoals,
        interestOrHobbies   : persona.psychographics.interestOrHobbies,
        psychographicFactors: persona.psychographics.psychographicFactors,
        buySolution         : persona.buyingBehaviour.buySolution,
        oftenBuy            : persona.buyingBehaviour.oftenBuy,
        loyal               : persona.buyingBehaviour.loyal,
        influences          : persona.buyingBehaviour.influences,
        buyingBehaviours    : persona.buyingBehaviour.buyingBehaviours,
        outcomes            : persona.individualCharacteristics.outcomes,
        goalsOrObjectives   : persona.individualCharacteristics.goalsOrObjectives,
        motivations         : persona.individualCharacteristics.motivations,
        decisions           : persona.individualCharacteristics.decisions,
        jobResponsibilities:
          persona.individualCharacteristics.jobResponsibilities,
        jobOutcome: persona.individualCharacteristics.jobOutcome,
        report    : persona.individualCharacteristics.report,
        tools     : persona.individualCharacteristics.tools,
        socialMediaActiveness:
          persona.individualCharacteristics.socialMediaActiveness,
        informationNeeds: persona.individualCharacteristics.informationNeeds,
        relevantCharacteristics:
          persona.individualCharacteristics.relevantCharacteristics
      };

      setDetails(newdetails);
    
    }
  
  };

  // To get the updated usestate value to save in useEffect cleanup
  const detailsRef = useRef(details);

  detailsRef.current = details;

  useEffect(() => {

    return async () => {

      await handleSave();
      localStorage.removeItem("name");
      // localStorage.removeItem("segmentid");
      // localStorage.removeItem("personaid");
    
    };
  
  }, []);

  const handleSave = async () => {

    try {

      if (detailsRef.current.name !== "" && detailsRef.current.type !== "") {

        const segmentid = localStorage.getItem("segmentid");
        const personaid = localStorage.getItem("personaid");
        const response = await createPersona(
          detailsRef.current,
          venture.customer,
          segmentid,
          personaid,
          venture._id
        );
        const Persona = response.data.persona;

        if (!personaid) {

          localStorage.setItem("personaid", Persona._id);
        
        }
      
      }
      
      return true;
    
    } catch (error) {

      console.error(error);
      
      return false;
    
    }
  
  };

  const handleChange = (name, value) => {

    setErrormsg("");
    setDetails(values => ({ ...values, [name]: value }));
  
  };

  const handleLocationChange = (name, e) => {

    setDetails(values => ({
      ...values,
      [name]     : e.value,
      ["isoCode"]: e.isoCode,
      ["state"]  : ""
    }));
  
  };

  const handleSocialMediaChange = (e, socialindex, value) => {

    let newdetails = { ...details };
    let newsocialMediaActiveness = details.socialMediaActiveness.map(
      (socialmedia, index) => {

        if (socialindex === index) {

          if (e.target.checked) {

            return {
              icon     : value,
              ispresent: true
            };
          
          } else {

            return {
              icon     : "",
              ispresent: false
            };
          
          }
        
        } else {

          return socialmedia;
        
        }
      
      }
    );

    newdetails.socialMediaActiveness = newsocialMediaActiveness;
    setDetails(newdetails);
  
  };

  const personaArr = [
    { value: "Introducer", label: "Introducer" },
    { value: "User", label: "User" },
    { value: "Buyer", label: "Buyer" },
    { value: "Decision Maker", label: "Decision Maker" },
    { value: "Influencer", label: "Influencer" },
    { value: "Gatekeeper", label: "Gatekeeper" }
  ];

  const barArr = [
    "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"
  ];

  const handleSubmit = async () => {

    try {

      if (details.name !== "" && details.type !== "") {

        setErrormsg("");
        const segmentid = localStorage.getItem("segmentid");
        const personaid = localStorage.getItem("personaid");
        const response = await createPersona(
          details,
          venture.customer,
          segmentid,
          personaid,
          venture._id
        );

        const Persona = response.data.persona;

        if (!personaid) {

          localStorage.setItem("personaid", Persona._id);
        
        }
      
      }
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  return (
    <>
      <ModuleSections Collapsecomment={Collapsecomment}>
        <div className="box-display-style-for-next-and-back-button-with-justify">
          <div className="box-indiv-header-con" style={{ height: "15%" }}>
            <Grid container className="grid-nav-dash">
              {barArr.map(barid => {

                return barid === hash ?
                  <Grid item md={1}>
                    <h6 style={bar_blue} />
                  </Grid>
                  :
                  <Grid item md={1}>
                    <h6 style={bar} />
                  </Grid>
                ;
              
              })}
            </Grid>
            {hash === "0" ?
              <IndividualSectionHeaders
                mainheading={pathname.includes("gtm") ? "GTM" : "Customer"}
                subheading={
                  pathname.includes("gtm")
                    ? " Build a User Persona"
                    : "Build a User Persona"
                }
                // headingcontent={`Let’s create a persona for customer segment: ${segmentname}`}
                headingcontent={"Let’s create a persona for customer segment: "}
                isGtm={pathname.includes("gtm") ? true : false}
                Dialogopen={Dialogopen}
                Dialogclose={Dialogclose}
                dialogbox={dialogbox}
                collapse_comment_function={collapse_comment_function}
                content={guideicon}
                isGtmChevron={"none"}
                headingBoldContent={segmentname}
                isexpand={true}
                isSaveButton={true}
                handleSave={() => {

                  if (details.type !== "" && details.name.length > 0) {

                    handleSave();
                    
                    return true;
                  
                  } else {

                    setErrormsg("Please fill required fields");
                    setTimeout(() => {

                      setErrormsg("");
                    
                    }, 5000);
                    
                    return false;
                  
                  }
                
                }}
              />
              : hash === "1" ?
                <IndividualSectionHeaders
                  mainheading={"Build a User Persona"}
                  subheading={"Demographics 1/2"}
                  headingcontent={
                    "Define basic demographic details for this persona. "
                  }
                  isGtm={false}
                  Dialogopen={Dialogopen}
                  Dialogclose={Dialogclose}
                  dialogbox={dialogbox}
                  collapse_comment_function={collapse_comment_function}
                  content={guideicon}
                  marginBottom={"0px"}
                  isexpand={true}
                  isGtmChevron={pathname.includes("gtm") ? "gtmV" : "customer"}
                  isSaveButton={true}
                  handleSave={handleSave}
                />
                : hash === "2" ?
                  <IndividualSectionHeaders
                    mainheading={"Build a User Persona"}
                    subheading={"Demographics 2/2"}
                    // headingcontent={
                    //   "Define specific demographics to classify your user personas better"
                    // }
                    headingcontent={
                      "Define basic demographic details for this persona. "
                    }
                    isGtm={false}
                    Dialogopen={Dialogopen}
                    Dialogclose={Dialogclose}
                    dialogbox={dialogbox}
                    collapse_comment_function={collapse_comment_function}
                    content={guideicon}
                    marginBottom={"0px"}
                    isexpand={true}
                    isGtmChevron={pathname.includes("gtm") ? "gtmV" : "customer"}
                    isSaveButton={true}
                    handleSave={handleSave}
                  />
                  : hash === "3" ?
                    <IndividualSectionHeaders
                      mainheading={"Build a User Persona"}
                      subheading={"Geographics"}
                      headingcontent={" Define their geographic details"}
                      tooltip={
                        "Customers fitting similar demographic profiles but in different geographies may have very different value perception, severity of need, and willingness to pay. \nGeography could impact your product design, pricing and payments, your marketing/ sales strategies, and logistics, among various other variables."
                      }
                      isGtm={false}
                      Dialogopen={Dialogopen}
                      Dialogclose={Dialogclose}
                      dialogbox={dialogbox}
                      collapse_comment_function={collapse_comment_function}
                      content={guideicon}
                      marginBottom={"0px"}
                      isexpand={true}
                      isGtmChevron={pathname.includes("gtm") ? "gtmV" : "customer"}
                      tooltipWidth={"500px"}
                      isSaveButton={true}
                      handleSave={handleSave}
                    />
                    : hash === "10" ?
                      <IndividualSectionHeaders
                        mainheading={"Build a User Persona"}
                        subheading={"Individual Characteristics 3/3"}
                        headingcontent={"Define their individual characteristics"}
                        tooltip={
                          "Let’s try to dig a bit deeper into what this persona does, what they are responsible for, what decisions they control or influence, and what ambitions they may have either professionally or personally. Some of this analysis may seem to be overlap with what you’ve already thought through in the previous screens, but the purpose here is to step into their shoes and look at their professional and personal lives from their point of view, so you can empathize more with this persona."
                        }
                        isGtm={false}
                        isexpand={true}
                        Dialogopen={Dialogopen}
                        Dialogclose={Dialogclose}
                        dialogbox={dialogbox}
                        collapse_comment_function={collapse_comment_function}
                        content={guideicon}
                        marginBottom={"0px"}
                        isGtmChevron={pathname.includes("gtm") ? "gtmV" : "customer"}
                        tooltipWidth={"500px"}
                        isSaveButton={true}
                        handleSave={handleSave}
                      />
                      :
                      <IndividualSectionHeaders
                        mainheading={"Build a User Persona"}
                        subheading={`${data.title} ${data.sidetitle}`}
                        headingcontent={data.description}
                        tooltip={data.tooltip}
                        isGtm={false}
                        Dialogopen={Dialogopen}
                        Dialogclose={Dialogclose}
                        dialogbox={dialogbox}
                        collapse_comment_function={collapse_comment_function}
                        content={guideicon}
                        marginBottom={"0px"}
                        isexpand={true}
                        tooltipWidth={"500px"}
                        isGtmChevron={pathname.includes("gtm") ? "gtmV" : "customer"}
                        isSaveButton={true}
                        handleSave={handleSave}
                      />
            }
          </div>
          <div style={{ position: "relative", height: "5px" }}>
            <div style={{ position: "absolute", top: "-2%", left: "39%" }}>
              {errormsg && errormsg.length && <ErrorMessage errormsg={errormsg} />}
            </div>
          </div>
          {hash === "0" ?
            <>
              <Grid id={styles.maingrid2}>
                <Grid container id={styles.gridContainer}>
                  <Grid item xs={12} md={4}>
                    <Typography variant="body2" sx={{ color: "#393939" }}>
                      Choose persona type*
                      <HelpIconTooltip
                        tooltipdata={
                          "A persona’s ‘type’ is their ‘role’ in the buying process. Understanding this will help you customize your product’s positioning & value messaging to help get their buy-in. \n• Introducer - shows the initial interest and begins the buying process \n• User - will use your product or solution on a day-to-day basis \n• Buyer - has the budget and makes the purchase \n• Decision maker - gives final purchase approval or drives the buying initiative within an    organization \n• Influencer – with ability to convince other stakeholders that a product or solution is needed/  desirable \n• Gatekeeper – skeptical users, inclined to question or doubt accepted opinions"
                        }
                        width={"500px"}
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    {/* <Select
                    options={personaArr}
                    placeholder="Select a persona type"
                    value={
                      details.type && {
                        value: details.type,
                        label: details.type,
                      }
                    }
                    styles={{
                      control: (styles) => ({
                        ...styles,
                        borderColor: errormsg ? "#FF0000" : styles.borderColor,
                      }),
                    }}
                    onChange={(e) => {
                      handleChange("type", e.value);
                    }}
                  /> */}
                    <CustomSelect
                      options={personaArr}
                      placeholder="Select a persona type"
                      value={details.type}
                      handleChange={e => {

                        handleChange("type", e.value);
                      
                      }}
                      error={errormsg}
                    />
                  </Grid>
                </Grid>
                <Grid container id={styles.gridContainer}>
                  <Grid item xs={12} md={4}>
                    <Typography variant="body2" sx={{ color: "#393939" }}>
                      Name the persona*
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    {/* <TextField
                    error={errormsg ? true : false}
                    size="small"
                    sx={{ width: "100%" }}
                    name="name"
                    value={details.name}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value.trimStart())
                    }
                  /> */}
                    <CustomTextfield
                      error={errormsg ? true : false}
                      width={"100%"}
                      padding={"4px 2px"}
                      name="name"
                      value={details.name}
                      handledetailsChange={e =>
                        handleChange(e.target.name, e.target.value.trimStart())
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container id={styles.gridContainer}>
                  <Grid item xs={12} md={4}>
                    <Typography variant="body2" sx={{ color: "#393939" }}>
                      Choose an avatar for them*
                      <HelpIconTooltip
                        tooltipdata={
                          "Put a face to the persona you’re building. Choose an avatar that feels like the closest fit. "
                        }
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Grid
                      sx={{ display: "flex", alignItems: "center" }}
                      container>
                      <Grid
                        item
                        md={3}
                        sx={{
                          display       : "flex",
                          justifyContent: "center"
                        }}
                        id={styles.avatarGrid}>
                        <img
                          src={`${process.env.REACT_APP_AWS_ASSETS_URL}${details.avatar}`}
                          alt=""
                          // src="./images/AvatarsHighResolution/avatar4.png"
                          id={styles.avatarImg}
                        />
                      </Grid>
                      <Grid item md={9}>
                        <Grid id={styles.grpImgBox} container>
                          {avatars.map(avatar => {

                            return (
                              <Grid
                                // key={index}
                                item>
                                <img
                                  src={`${process.env.REACT_APP_AWS_ASSETS_URL}${avatar}`}
                                  alt=""
                                  // src="./images/adult5.png"
                                  // src={avatar}
                                  id={styles.groupimgs}
                                  onClick={() => {

                                    handleChange("avatar", avatar);
                                  
                                  }}
                                />
                              </Grid>
                            );
                          
                          })}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Typography sx={{ fontSize: "11px", fontStyle: "italic" }}>
                  *Answer all questions that could be relevant for your product
                  or brand. It’s ok to skip questions you can’t answer at the
                  moment. As you learn more about your target users, return to
                  these pages to further develop the persona.
                </Typography>
              </Grid>
            </>
            : hash === "1" ?
              <Demographics1
                details={details}
                handleChange={handleChange}
                Dialogopen={Dialogopen}
                Dialogclose={Dialogclose}
                dialogbox={dialogbox}
                collapse_comment_function={collapse_comment_function}
                handleSubmit={handleSubmit}
                handleSave={handleSave}
              />
              : hash === "2" ?
                <Demographics2
                  details={details}
                  errormsg={errormsg}
                  handleChange={handleChange}
                  Dialogopen={Dialogopen}
                  Dialogclose={Dialogclose}
                  dialogbox={dialogbox}
                  collapse_comment_function={collapse_comment_function}
                  handleSubmit={handleSubmit}
                  handleSave={handleSave}
                />
                : hash === "3" ?
                  <Geographics
                    details={details}
                    handleChange={handleChange}
                    handleLocationChange={handleLocationChange}
                    Dialogopen={Dialogopen}
                    Dialogclose={Dialogclose}
                    dialogbox={dialogbox}
                    collapse_comment_function={collapse_comment_function}
                    handleSubmit={handleSubmit}
                    handleSave={handleSave}
                  />
                  : hash === "10" ?
                    <IndividualCharacteristicsthird
                      details={details}
                      handleChange={handleChange}
                      handleSocialMediaChange={handleSocialMediaChange}
                      handleSubmit={handleSubmit}
                      Dialogopen={Dialogopen}
                      Dialogclose={Dialogclose}
                      dialogbox={dialogbox}
                      collapse_comment_function={collapse_comment_function}
                      handleSave={handleSave}
                    />
                    :
                    <OtherDetails
                      data={data}
                      details={details}
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      Dialogopen={Dialogopen}
                      Dialogclose={Dialogclose}
                      dialogbox={dialogbox}
                      collapse_comment_function={collapse_comment_function}
                      handleSave={handleSave}
                    />
          }

          {
            hash === "0" ? <div className="ttribe-back-next-button-con">
              <BackNextButton
                restrictedBackClick={() =>
                  pathname.includes("gtm")
                    ? history.push("/gtmsegmentspersonas")
                    : history.push("/segmentspersonas")
                }
                backClick={() =>
                  pathname.includes("gtm")
                    ? history.push("/gtmsegmentspersonas")
                    : history.push("/segmentspersonas")
                }
                restrictedNextClick={() => {

                  if (details.type !== "" && details.name.length > 0) {

                    handleSubmit();
                    history.push({ hash: "1" });
                  
                  } else {

                    setErrormsg("Please fill required fields");
                    setTimeout(() => {

                      setErrormsg("");
                    
                    }, 5000);
                  
                  }
                
                }}
                nextClick={() => {

                  if (details.type !== "" && details.name.length > 0) {

                    handleSubmit();
                    history.push({ hash: "1" });
                  
                  } else {

                    setErrormsg("Please fill required fields");
                    setTimeout(() => {

                      setErrormsg("");
                    
                    }, 5000);
                  
                  }
                
                }}
              />
            </div> :
              hash === "1" ? <div className="ttribe-back-next-button-con">
                <BackNextButton
                  // backClick={() => history.push({ pathname: "/userpersona", hash: "0" })}
                  restrictedBackClick={() => history.push({ hash: "0" })}
                  backClick={() => history.push({ hash: "0" })}
                  restrictedNextClick={() => {

                    if (details.gender !== "") {

                      handleSubmit();
                      history.push({ hash: "2" });
                    
                    } else {

                      setErrormsg("Please fill required fields");
                      // setTimeout(() => {
                      //   setErrormsg("");
                      // }, 5000);
                    
                    }
                  
                  }}
                  nextClick={() => {

                    if (details.gender !== "" && details.gender.length > 0) {

                      handleSubmit();
                      history.push({ hash: "2" });
                    
                    } else {

                      setErrormsg("Please fill required fields");
                      // setTimeout(() => {
                      //   setErrormsg("");
                      // }, 5000);
                    
                    }

                  }}
                />
              </div> :
                hash === "2" ? <div className="ttribe-back-next-button-con">
                  <BackNextButton
                    backClick={() => history.push({ hash: "1" })}
                    restrictedBackClick={() => history.push({ hash: "1" })}
                    restrictedNextClick={() => {

                      handleSubmit();
                      history.push({ hash: "3" });
                    
                    }}
                    nextClick={() => {

                      handleSubmit();
                      history.push({ hash: "3" });
                    
                    }}
                  />
                </div> :
                  hash === "3" ? <div className="ttribe-back-next-button-con">
                    <BackNextButton
                      backClick={() => history.push({ hash: "2" })}
                      restrictedBackClick={() => history.push({ hash: "2" })}
                      restrictedNextClick={() => {

                        // props.handleSubmit();
                        history.push({ hash: "4" });
                      
                      }}
                      nextClick={() => {

                        handleSubmit();
                        history.push({ hash: "4" });
                      
                      }}
                    />
                  </div> :
                    hash === "10" ? <div className="ttribe-back-next-button-con">
                      <BackNextButton
                        // className="ttribe-back-next-button-inside-module-con"
                        restrictedBackClick={() => history.push({ hash: "9" })}
                        backClick={() => history.push({ hash: "9" })}
                        restrictedNextClick={() => {

                          // props.handleSubmit();
                          // localStorage.removeItem("name");
                          // localStorage.removeItem("segmentid");
                          // localStorage.removeItem("personaid");
                          history.push(
                            pathname.includes("gtm")
                              ? `/gtmuserpersonadetails/${personaid}`
                              : `/userpersonadetails/${personaid}`
                          );
                        
                        }}
                        nextClick={() => {

                          handleSubmit();
                          history.push(
                            pathname.includes("gtm")
                              ? `/gtmuserpersonadetails/${personaid}`
                              : `/userpersonadetails/${personaid}`
                          );
                          // localStorage.removeItem("name");
                          // localStorage.removeItem("segmentid");
                          // localStorage.removeItem("personaid");
                        
                        }}
                      />
                    </div> :
                      <div className="ttribe-back-next-button-con">

                        <BackNextButton
                          restrictedBackClick={() => history.push({ hash: `${newhash - 1}` })}
                          backClick={() => history.push({ hash: `${newhash - 1}` })}
                          restrictedNextClick={() => {

                            // handleSubmit();
                            history.push({ hash: `${newhash + 1}` });
                          
                          }}
                          nextClick={() => {

                            handleSubmit();
                            history.push({ hash: `${newhash + 1}` });
                          
                          }}
                        />
                      </div>
          }
        </div>
      </ModuleSections>
    </>
  );

};

export default UserPersona;
