import React, { useState } from "react";

import {
  Box, Dialog,
  DialogContent, TextField, Typography
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import styles from "./CustomerWorth.module.css";
import { useSelector } from "react-redux";

export default function CustomerWorth(props) {

  const [ dialog, setDialog ] = useState(false);


  const revenueStream = props.revenueStreams[props.revenueStreamIndex];

  const { venture } = useSelector(state => ({ ...state }));

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  const handleCustomerWorthChange = (index, name, value) => {

    let newRevenueStreams = [ ...props.revenueStreams ];

    newRevenueStreams[index][name] = value;
    props.setRevenueStreams(newRevenueStreams);
  
  };

  return (
    // <CreatePricingComponent barHighlight="bar1">
    <Box sx={{}}>
      <Box>
        <Typography
          id={styles.headTypo}
        //   sx={{ color: "#5F5E5E" }}
        >
          What is it worth to a customer?
        </Typography>
      </Box>
      {/* sub typo box */}
      <Box>
        <Typography
          id={styles.subTypo}
        //   sx={{ fontSize: "12px", color: "#868686" }}
        >
          Let’s try to quantify the value of your solution from a customer’s
          point of view. In some cases you will be able to directly tie it to
          increased revenue or lower costs for the customer. However, more
          often, the “value” a customer assigns to your product will be more
          ‘perceived’ or ‘experienced’ value that could be hard for you to
          quantify. But, think about this - when a customer makes a purchasing
          decision for your product or service, they will quantify this value in
          some way in their minds, even if they do it subconsciously.
        </Typography>
      </Box>

      {/* img typo flex */}
      <Box
        id={styles.typoImgFlex}
      // sx={{ width: "100%", display: "flex", marginTop: "15px" }}
      >
        {/* typo box */}
        <Box
          id={styles.flexTypo}
        //   sx={{ width: "70%", fontSize: "12px", color: "#868686" }}
        >
          So, put yourself in their shoes, and think of how they’ll evaluate
          your offering, and remember to look at factors more than just rational
          assessments. Consider how aspirational, socio-cultural, or emotional
          factors also will impact their sense of “value”.
        </Box>
        {/* img box */}
        <Box
          id={styles.blankImgBox}
        //   sx={{ width: "30%" }}
        >
          {/* <Box sx={{ border: 1, width: "173px", height: "153px" }}></Box> */}
        </Box>
      </Box>

      {/* img textfield box */}
      <Box
        id={styles.textfieldImgBox}
      // sx={{ width: "100%", marginTop: "15px", display: "flex" }}
      >
        <Box
          id={styles.textFieldMainBox}
        // sx={{
        //   width: "60%",
        //   display: "flex",
        //   //   justifyContent: "center",
        //   alignItems: "center",
        // }}
        >
          {/* textfield box */}
          <Box
            id={styles.textFieldBox}
          // sx={{ width: "100%" }}
          >
            <TextField
              onChange={e => {

                handleCustomerWorthChange(
                  props.revenueStreamIndex,
                  "customerWorth",
                  e.target.value
                );
              
              }}
              disabled={venture.userRole === "Commenter" ? true : false}
              value={
                // props.revenueStreams[props.revenueStreamIndex].customerWorth
                revenueStream.customerWorth
              }
              fullWidth
              multiline
              maxRows={11}
              minRows={11}
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "4px 16px"
                },
                "& .MuiInputBase-input": {
                  fontSize  : "12px",
                  color     : "#707070",
                  lineHeight: "17px"
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #E1E2E9"
                }
              }}
            />
          </Box>
        </Box>
        <Box
          id={styles.imgTypoFlex}
        //   sx={{ width: "40%" }}
        >
          <Box
            id={styles.imgBox}
          // sx={{ height: "80px", position: "relative" }}
          >
            <Box
              id={styles.img}
            // sx={{
            //   width: "170px",
            //   height: "153px",
            //   border: 1,
            //   backgroundColor: "red",
            //   position: "absolute",
            //   right: "30px",
            //   //   right: "63px",
            //   top: "-80px",
            // }}
            >
              <img
                src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/monetisationCustomerWorth.svg`}
                style={{ width: "170px", height: "153px" }}
                alt=""
              />
            </Box>
          </Box>
          <Box
            id={styles.mainTypoBoxImgflex}
          // sx={{ padding: "10px" }}
          >
            <Typography
              id={styles.typoBoxImgFlex}
            //   sx={{ fontSize: "12px" }}
            >
              <span
                id={styles.somePointersTypo}
              //   style={{
              //     color: "#5F5E5E",
              //     fontWeight: "bold",
              //     marginRight: "4px",
              //   }}
              >
                Some Pointers:
              </span>
              For typical B2B businesses, perceived value may be a combination
              of multiple factors. Some may seem harder to quantify but look at
              how a customer will put a value to those benefits, and consider
              proxies or comparable products/services that they may use to
              benchmark your offering.
              <span
                style={{
                  color     : "#071AFF",
                  fontWeight: "bold",
                  marginLeft: "4px",
                  cursor    : "pointer"
                }}
                onClick={() => {

                  Dialogopen();
                
                }}
              >
                See Examples
              </span>
              <Dialog
                open={dialog}
                // onClick={Dialogclose}
                onClose={Dialogclose}
                PaperProps={{
                  style: {
                    borderRadius   : "20px",
                    backdropFilter : "blur(100px)",
                    backgroundColor: "#5F5E5E",
                    boxShadow      : "0px 3px 6px #00000029",
                    border         : "1px solid #707070",
                    width          : "50%"
                  }
                }}
                //
                //   maxWidth="xs"
                maxWidth="false"
                fullWidth
              >
                <DialogContent sx={{ padding: "10px" }}>
                  <Box
                    sx={{
                      display       : "flex",
                      justifyContent: "space-between"
                    }}
                  >
                    <Typography sx={{ fontSize: "14px", color: "#ffffff" }}>
                      {/* More Revenue Stream Examples */}
                    </Typography>
                    <Box
                      sx={{
                        width          : "21px",
                        height         : "21px",
                        backgroundColor: "#ffffff",
                        display        : "flex",
                        justifyContent : "center",
                        alignItems     : "center",
                        borderRadius   : "50%"
                      }}
                      onClick={Dialogclose}
                    >
                      <CloseIcon sx={{ fontSize: "16px" }} />
                    </Box>
                  </Box>

                  <Box sx={{ margin: "10px" }}>
                    <Typography
                      id={styles.dialogTopTypo}
                    // sx={{ fontSize: "14px", color: "#ffffff" }}
                    >
                      -
                      <span
                        id={styles.dialogTypoSpan}
                      // style={{ fontWeight: "bold" }}
                      >
                        Revenue increase
                      </span>
                      – e.g. increase conversion rates, raise per-unit price,
                      access a new customer segment etc.
                      <br />-
                      <span
                        id={styles.dialogTypoSpan}
                      // style={{ fontWeight: "bold" }}
                      >
                        Cost reduction
                      </span>
                      – e.g. reduce resource usage, cheaper resources, reduce
                      errors, faster churn, etc.
                      <br />-
                      <span
                        id={styles.dialogTypoSpan}
                      // style={{ fontWeight: "bold" }}
                      >
                        Employee satisfaction/retention
                      </span>
                      <br />-
                      <span
                        id={styles.dialogTypoSpan}
                      // style={{ fontWeight: "bold" }}
                      >
                        Brand value / perception benefits
                      </span>
                      <br />-
                      <span
                        id={styles.dialogTypoSpan}
                      // style={{ fontWeight: "bold" }}
                      >
                        Customer satisfaction
                      </span>
                      <br />-
                      <span
                        id={styles.dialogTypoSpan}
                      // style={{ fontWeight: "bold" }}
                      >
                        Risk reduction
                      </span>
                      <br />-
                      <span
                        id={styles.dialogTypoSpan}
                      // style={{ fontWeight: "bold" }}
                      >
                        Regulatory compliance
                      </span>
                    </Typography>

                    <Box
                      id={styles.dialogBottomTypoBox}
                    // sx={{ marginTop: "20px" }}
                    >
                      <Typography
                        id={styles.dialogBottomTypo}
                      // sx={{ fontSize: "14px", color: "#ffffff" }}
                      >
                        In typical B2C businesses, sometimes the value of your
                        product’s benefits could be harder to quantify directly.
                        Here you will have to look at multiple factors, since a
                        customer may combine rational + emotional + aspirational
                        + cultural factors to assign a value to the benefit they
                        will get from your product or service. <br />
                        For e.g. it could improve quality of life, improve
                        health, save them time/ money/ effort, increase safety,
                        make them more informed or educated, or in many cases,
                        it could simply make them “feel” more happy, healthy,
                        beautiful, safe, or comfortable. Either way, most
                        customers will consciously or subconsciously assign some
                        quantified value to their purchase decision. From your
                        persona analyses, consider what comparable products or
                        services they use, how they make purchasing decisions,
                        and their emotions & aspirations.
                      </Typography>
                    </Box>
                  </Box>
                </DialogContent>
              </Dialog>
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* back next box */}
      {/* <Box
        id={styles.backNextButtonBox}
      // sx={{ border: "1px solid #ffffff" }}
      >
       
      </Box> */}
    </Box>
    // </CreatePricingComponent>
  );

}
