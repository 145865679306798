/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Box, Button, CircularProgress, Dialog,
  DialogContent, FormControl, Grid, IconButton, InputBase, InputLabel, ListSubheader, MenuItem, Select, Table, TableContainer, TableHead, Typography
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
// import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { currencies } from "../../../../components/Currencies";
import IndividualSectionHeaders from "../../../../components/IndividualSectionHeaders";
import ModuleSections from "../../../../components/ModuleSections";
import Datas from "../../../../Data/Finance.json";
import {
  getFinancials,
  updateFinancialFroutlook,
  updateFinancialPloutlook,
  updateFinancials,
  updateFroutlook
} from "../../../../services/financials";
// import FrChart from "./FrChart";
import styles from "./FrOutlook.module.css";

import BackNextButton from "../../../../components/BackNextButton";
import CustomMultilineTextfield from "../../../../components/CustomMultilineTextfield";
import CustomSelect from "../../../../components/CustomSelect";
import CustomTextField from "../../../../components/CustomTextfield";
import HelpIconTooltip from "../../../../components/HelpIconTooltip";
import {
  createFinancialsComment,
  deleteFinancialsComment,
  updateFinancialsComment
} from "../../../../services/comment";
import IndividualFundingSource from "./IndividualFundingSource";
import FinancialsNavBar from "../FinancialsNavBar";

// const useStyles = makeStyles(() => ({
//   customWidth: {
//     // maxWidth: 200,
//     fontSize       : 10,
//     backgroundColor: "white",
//     color          : "#707070",
//     boxShadow      : "0px 3px 6px #00000029",
//     border         : "1px solid #707070",
//     borderRadius   : 10,
//     padding        : 10
//   },
//   table: {},

//   mainGridSize: {
//     height   : "100%",
//     overflowY: "auto",
//     padding  : "40px 50px"
//   }
// }));

// const BootstrapInputCurrency = styled(InputBase)(({ theme }) => ({
//   // "label + &": {
//   //   marginTop: theme.spacing(3),
//   // },
//   "& .MuiInputBase-input": {
//     borderRadius: 4,
//     position: "relative",
//     // backgroundColor: theme.palette.background.paper,
//     border: "1px solid #ced4da",
//     fontSize: 16,
//     padding: "10px 26px 10px 12px",
//     width: "4vw",
//     // transition: theme.transitions.create(["border-color", "box-shadow"]),
//     // width: "7vw",
//     // Use the system font instead of the default Roboto font.
//   },
// }));

const BootstrapInputCurrentFunding = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3)
  },
  "& .MuiInputBase-input": {
    textAlign      : "center",
    borderRadius   : 4,
    position       : "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    // border: "1px solid #ced4da",
    fontSize       : 14,
    // width: "auto",
    fontWeight     : "bold",
    width          : "90%",
    padding        : "10px 0px"
  }
}));

const CustomInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3)
  },
  "& .MuiInputBase-input": {
    textAlign   : "center",
    borderRadius: 4,
    position    : "relative",
    // backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",

    border    : "1px solid #ced4da",
    // fontSize: 12,
    fontSize  : 11,
    width     : 44,
    fontWeight: "bold",
    // padding: "5px 6px",
    padding   : "5px 6px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow"
    ]),
    // Use the system font instead of the default Roboto font.

    "&:focus": {
      boxShadow  : `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    }
  }
}));

const CustomInputStyl = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3)
  },
  "& .MuiInputBase-input": {
    textAlign      : "center",
    borderRadius   : 4,
    position       : "relative",
    // backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    backgroundColor: "#E3EFFF",

    border    : "1px solid #CCCCCC",
    // fontSize: 12,
    fontSize  : 11,
    fontWeight: "bold",
    width     : 44,
    padding   : "5px 6px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow"
    ]),
    // Use the system font instead of the default Roboto font.

    "&:focus": {
      boxShadow  : `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    }
  }
}));

const RunawayGreen = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3)
  },
  "& .MuiInputBase-input": {
    textAlign                : "center",
    borderRadius             : 4,
    position                 : "relative",
    // backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    backgroundColor          : "#008105",
    "-webkit-text-fill-color": "white !important",
    color                    : "white !important",

    border    : "1px solid #CCCCCC",
    // fontSize: 12,
    fontSize  : 11,
    width     : 44,
    padding   : "5px 6px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow"
    ]),
    // Use the system font instead of the default Roboto font.

    "&:focus": {
      boxShadow  : `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    }
  }
}));

const RunawayRed = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3)
  },
  "& .MuiInputBase-input": {
    textAlign                : "center",
    borderRadius             : 4,
    position                 : "relative",
    // backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    backgroundColor          : "#D00000",
    "-webkit-text-fill-color": "white !important",
    color                    : "white !important",

    border    : "1px solid #CCCCCC",
    // fontSize: 12,
    fontSize  : 11,
    width     : 44,
    padding   : "5px 6px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow"
    ]),
    // Use the system font instead of the default Roboto font.

    "&:focus": {
      boxShadow  : `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    }
  }
}));

// const GraphicalViewButton = styled(Button)({
//   boxShadow      : "none",
//   textTransform  : "none",
//   fontSize       : 12,
//   padding        : "6px 12px",
//   border         : "1px solid",
//   lineHeight     : 1.5,
//   backgroundColor: "#white",
//   borderColor    : "#071AFF",
//   color          : "#071AFF",
//   width          : 233,
//   height         : 41,

//   "&:hover": {
//     backgroundColor: "#0069d9",
//     borderColor    : "#071AFF",
//     boxShadow      : "none",
//     color          : "white"
//   },
//   "&:active": {
//     boxShadow      : "none",
//     backgroundColor: "#071AFF",
//     borderColor    : "#071AFF"
//   }
//   // "&:focus": {
//   //   boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
//   // },
// });

// const TableTextField = styled(InputBase)(({ theme }) => ({
//   "label + &": {
//     marginTop: theme.spacing(3)
//   },
//   "& .MuiInputBase-input": {
//     textAlign      : "center",
//     borderRadius   : 5,
//     position       : "relative",
//     backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
//     // border: "1px solid #ced4da",
//     fontSize       : 16,
//     minWidth       : 10,
//     width          : "100%",
//     fontWeight     : "bold",
//     padding        : "5px 6px"
//   }
// }));

const textFieldSX = {
  input: {
    "-webkit-text-fill-color": "#707070 !important",
    color                    : "#707070 !important"
    // color: `black !important`,
  }
};

const FrOutlook = () => {

  // const classes = useStyles();
  const [ autosaving, setAutosaving ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const [ dialogbox, setDialog ] = useState(false);
  // const [ chartDialog, setChartDialog ] = useState(false);
  const [ financials, setFinancials ] = useState({
    plOutlooks          : [],
    frOutlooks          : [],
    currentFundingSource: [],
    fundRaising         : {
      totalFundingNeed : 0,
      fundingAllocation: ""
    },
    totalCurrentFunding: 0,
    plOutlooksCurrency : "",
    frOutlooksCurrency : ""
  });
  const [ comments, setComments ] = useState([]);
  const [ currentfroutlook, setCurrentFroutlook ] = useState(
    Datas.froutlook.initialdata
  );
  const [ selectedyear, setSelectedyear ] = useState(0);
  const [ year, setYear ] = useState("");
  const [ years, setYears ] = useState([]);
  const [ yearDialog, setYearDialog ] = useState(false);
  const [ errorDialog, setErrorDialog ] = useState(false);
  const [ isDataLoading, setIsDataLoading ] = useState(true);
  // const [ chartdataset1, setChartdataset1 ] = useState([]);
  // const [ chartdataset2, setChartdataset2 ] = useState([]);
  // const [ selectedchart, setSelectedchart ] = useState("");
  // const [ label1, setLabel1 ] = useState("");
  // const [ label2, setLabel2 ] = useState("");
  const monthNameRow = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  const history = useHistory();

  const { user, venture } = useSelector(state => ({ ...state }));
  const [ currency, setcurrency ] = useState();
  // console.log(user.token);

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  // function for Chart Dialog

  // const ChartDialogopen = () => {

  //   setChartDialog(true);
  
  // };
  // const ChartDialogclose = () => {

  //   setChartDialog(false);
  
  // };

  const yearDialogopen = () => {

    setYearDialog(true);
  
  };
  const yearDialogclose = () => {

    setYearDialog(false);
  
  };

  const errorDialogopen = () => {

    setErrorDialog(true);
  
  };
  const errorDialogclose = () => {

    setErrorDialog(false);
  
  };

  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);
    
    } else {

      setCollapsecomment(true);
    
    }
  
  };

  useEffect(() => {

    fetchFinancials();
  
  }, []);

  const fetchFinancials = async () => {

    setIsDataLoading(true);

    try {

      setLoading(true);
      const response = await getFinancials(venture.financials, venture._id);
      // console.log(response.data);

      setComments(response.data.finance.comments.fundingRunwayoutlook);
      if (response.data.finance.frOutlooks.length > 0) {

        let newyears = [];
        let newfinancials = { ...response.data.finance };
        let previousfunding = newfinancials["totalCurrentFunding"];

        newfinancials.frOutlooks.map(fr => {

          newyears.push(fr.year);
          fr.frOutlook["monthlyBurnRate"].map((burnrate, brindex) => {

            fr.frOutlook["fundingBalance"][brindex] =
              previousfunding - fr.frOutlook["monthlyBurnRate"][brindex];
            let runawayMonth = Math.round(
              fr.frOutlook["fundingBalance"][brindex] /
              fr.frOutlook["monthlyBurnRate"][brindex]
            );
            // console.log("RUNAWAY MONTH", runawayMonth);

            fr.frOutlook["runawayMonth"][brindex] =
              runawayMonth !== Infinity ? runawayMonth : 0;
            previousfunding = fr.frOutlook["fundingBalance"][brindex];
          
          });
        
        });
        setFinancials(newfinancials);
        setYears(newyears);
        setYear(response.data.finance.frOutlooks[0].year);
        setCurrentFroutlook(response.data.finance.frOutlooks[0].frOutlook);
        setcurrency(response?.data?.finance?.currency ? response?.data?.finance?.currency : response?.data?.finance?.frOutlooksCurrency);
      
      } else {

        setFinancials(response.data.finance);
      
      }
      setLoading(false);

      setIsDataLoading(false);
    
    } catch (error) {

      console.error(error);

      setIsDataLoading(false);

    }
  
  };

  // To get the updated usestate value to save in useEffect cleanup
  const financialsRef = useRef(financials);

  financialsRef.current = financials;

  useEffect(() => {

    return handleSave;
  
  }, []);

  const handleSave = async () => {

    try {

      await Promise.all(
        financialsRef.current.frOutlooks.map(froutlook => {

          return updateFroutlook(
            froutlook.frOutlook._id,
            froutlook.frOutlook,
            venture._id
          );
        
        })
      );
      await updateFinancials(venture.financials, financialsRef.current);
      
      return true;
    
    } catch (error) {

      console.error(error);
      
      return false;
    
    }
  
  };

  const handleSelect = value => {

    setYear(value);
    financials.frOutlooks.map(froutlook => {

      if (froutlook.year === value) {

        setCurrentFroutlook(froutlook.frOutlook);
        
        return;
      
      }
    
    });
  
  };
  const removeNonNumeric = num => num.toString().replace(/[^0-9]/g, "");

  const initialRender = useRef(true);

  useEffect(() => {

    if (initialRender.current) {

      initialRender.current = false;
    
    } else {

      const timeoutId = setTimeout(async () => {

        setAutosaving(true);
        await Promise.all(
          financials.frOutlooks.map(froutlook => {

            return updateFroutlook(
              froutlook.frOutlook._id,
              froutlook.frOutlook,
              venture._id
            );
          
          })
        );
        await updateFinancials(venture.financials, financials);
        setAutosaving(false);
      
      }, 5000);

      
      return () => clearTimeout(timeoutId);
    
    }
  
  }, [ financials ]);

  // console.log(autosaving);

  const handleFundingSourceChange = (name, value, index) => {

    if (financials.currency === "") {

      errorDialogopen();
      
      return;
    
    }
    const newfinancials = { ...financials };
    let newtotalCurrentFunding = 0;

    newfinancials.currentFundingSource = financials.currentFundingSource.map(
      (fundingsource, fundingsourceindex) => {

        if (fundingsourceindex === index) {

          name === "source"
            ? newtotalCurrentFunding += Number(removeNonNumeric(value))
            : newtotalCurrentFunding += Number(fundingsource.source);
          
          return {
            ...fundingsource,
            [name]: name === "source" ? Number(removeNonNumeric(value)) : value
          };
        
        } else {

          newtotalCurrentFunding += Number(fundingsource.source);
          
          return fundingsource;
        
        }
      
      }
    );
    newfinancials.totalCurrentFunding = newtotalCurrentFunding;
    let previousfunding = newfinancials["totalCurrentFunding"];

    newfinancials.frOutlooks.map(fr => {

      fr.frOutlook["monthlyBurnRate"].map((burnrate, brindex) => {

        fr.frOutlook["fundingBalance"][brindex] =
          previousfunding - fr.frOutlook["monthlyBurnRate"][brindex];
        let runawayMonth = Math.round(
          fr.frOutlook["fundingBalance"][brindex] /
          fr.frOutlook["monthlyBurnRate"][brindex]
        );

        fr.frOutlook["runawayMonth"][brindex] =
          runawayMonth !== Infinity ? runawayMonth : 0;
        previousfunding = fr.frOutlook["fundingBalance"][brindex];
      
      });
    
    });
    setFinancials(newfinancials);
    handleSelect(year);
  
  };

  const handleFundRaising = (name, value) => {

    if (financials.currency === "") {

      errorDialogopen();
      
      return;
    
    }
    const newfinancials = { ...financials };

    if (name === "totalFundingNeed") {

      newfinancials.fundRaising[name] = Number(removeNonNumeric(value));
    
    } else {

      newfinancials.fundRaising[name] = value;
    
    }
    setFinancials(newfinancials);
    handleSelect(year);
  
  };

  const handleMonthlyBurnRate = (value, index) => {

    if (
      financials.frOutlooks.length === 0 ||
      financials.currency === ""
    ) {

      errorDialogopen();
      
      return;
    
    }
    const newfinancials = { ...financials };
    let previousfunding = newfinancials["totalCurrentFunding"];

    newfinancials.frOutlooks.map(fr => {

      if (year === fr.year) {

        fr.frOutlook["monthlyBurnRate"][index] = Number(
          removeNonNumeric(value)
        );
      
      }
      fr.frOutlook["monthlyBurnRate"].map((burnrate, brindex) => {

        fr.frOutlook["fundingBalance"][brindex] =
          previousfunding - fr.frOutlook["monthlyBurnRate"][brindex];
        let runawayMonth = Math.round(
          fr.frOutlook["fundingBalance"][brindex] /
          fr.frOutlook["monthlyBurnRate"][brindex]
        );

        fr.frOutlook["runawayMonth"][brindex] =
          runawayMonth !== Infinity ? runawayMonth : 0;
        previousfunding = fr.frOutlook["fundingBalance"][brindex];
      
      });
    
    });
    setFinancials(newfinancials);
    handleSelect(year);
  
  };

  const addRow = () => {

    const newfinancials = { ...financials };

    newfinancials.currentFundingSource.push({
      name  : "",
      source: 0
    });
    setFinancials(newfinancials);
  
  };

  const removeRow = (index, source) => {

    const newfinancials = { ...financials };

    newfinancials.totalCurrentFunding -= Number(source);
    newfinancials.currentFundingSource = financials.currentFundingSource.filter(
      (fundingsource, i) => index !== i
    );
    let previousfunding = newfinancials["totalCurrentFunding"];

    newfinancials.frOutlooks.map(fr => {

      fr.frOutlook["monthlyBurnRate"].map((burnrate, brindex) => {

        fr.frOutlook["fundingBalance"][brindex] =
          previousfunding - fr.frOutlook["monthlyBurnRate"][brindex];
        let runawayMonth = Math.round(
          fr.frOutlook["fundingBalance"][brindex] /
          fr.frOutlook["monthlyBurnRate"][brindex]
        );

        fr.frOutlook["runawayMonth"][brindex] =
          runawayMonth !== Infinity ? runawayMonth : 0;
        previousfunding = fr.frOutlook["fundingBalance"][brindex];
      
      });
    
    });
    setFinancials(newfinancials);
  
  };

  const updateYear = async () => {

    await updateFinancialFroutlook(
      selectedyear,
      venture.financials,
      venture._id
    );
    await updateFinancialPloutlook(
      selectedyear,
      venture.financials,
      venture._id
    );

    fetchFinancials();
  
  };

  const handleSubmit = async () => {

    try {

      if(isDataLoading == false) {

        await Promise.all(
          financials.frOutlooks.map(froutlook => {
  
            return updateFroutlook(
              froutlook.frOutlook._id,
              froutlook.frOutlook,
              venture._id
            );
          
          })
        );
        await updateFinancials(venture.financials, financials);
        history.push("/cashflow");

      }

    } catch (error) {

      console.error(error);
    
    }
  
  };

  const numberWithCommas = num => {

    if (financials.frOutlooksCurrency || financials.currency === "") {

      let newNum = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      
      return financials.currency ? `${financials.currency.split(" ")[0].trim()} ${newNum}` : `${financials.frOutlooksCurrency.split(" ")[0].trim()} ${newNum}`;
    
    } else {

      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    
    }
  
  };

  const currencyOptions = currencies.map(currency => ({
    value: `${currency.symbol} ${currency.code}`,
    label: `${currency.symbol} ${currency.code}`
  }));

  const addComment = async (commentText, replyTo) => {

    try {

      const response = await createFinancialsComment(
        commentText,
        user._id,
        "fundingRunwayoutlook",
        "",
        venture.financials,
        replyTo,
        window.location.href,
        "Finance > Funding & Runway Outlook"
      );

      setComments(response.data.comments.fundingRunwayoutlook);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateComment = async (commentid, commentText) => {

    try {

      const response = await updateFinancialsComment(
        commentText,
        user._id,
        commentid,
        venture._id,
        window.location.href,
        "Finance > Funding & Runway Outlook"
      );

      setComments(response.data.comments.fundingRunwayoutlook);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const deleteComment = async commentid => {

    try {

      const response = await deleteFinancialsComment(
        "fundingRunwayoutlook",
        "",
        venture.financials,
        commentid,
        user._id,
        window.location.href,
        "Finance > Funding & Runway Outlook"
      );

      setComments(response.data.comments.fundingRunwayoutlook);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  // console.log(currentfroutlook);
  return (
    <div>
      <ModuleSections
        Collapsecomment={Collapsecomment}
        comments={comments}
        addComment={addComment}
        deleteComment={deleteComment}
        updateComment={updateComment}>
        <div className="box-display-style-for-next-and-back-button-with-justify">

          <div className="box-indiv-header-con" style={{ height: "14%", background: "white" }}>
            {loading ? <></> : <>
              <FinancialsNavBar />
              <IndividualSectionHeaders
                mainheading={"Finance"}
                subheading={"Funding & Runway"}
                // subheading={"Funding & Runway"}
                headingcontent={
                  "Analyse your funding and runway to know when to raise the next round"
                }
                isGtm={false}
                Dialogopen={Dialogopen}
                Dialogclose={Dialogclose}
                dialogbox={dialogbox}
                collapse_comment_function={collapse_comment_function}
                content={Datas.froutlook}
                isFinance={true}
                isSaveButton={true}
                handleSave={handleSave}
              // isSaveButton={true}
              // handleSave={handleSave}
              />
            </>}

          </div>
          <Grid
            xs={12}
            item
            sx={{
              height   : "64%",
              width    : "100%",
              overflowY: "auto",
              overflowX: "auto",
              padding  : "0px 60px 0px"
            }}
            id={styles.mainGridSize}>
            {loading ?
              <Grid
                container
                justifyContent="center"
                height="100%"
                alignItems="center">
                <Grid item>
                  <CircularProgress size="60px" thickness={3} />
                </Grid>
              </Grid>
              :
              <>
                <Box>
                  <Box id={styles.currencyDropdownBox}>
                    <Box id={styles.currencyTypoBox}>
                      <Typography id={styles.currencyTypo}>
                        Select currency
                        <HelpIconTooltip
                          tooltipdata={
                            "Note: Choosing a currency here will only change the symbol on your funding & runway numbers below. It will not do currency conversion. Please review your numbers if you change your currency setting."
                          }
                        />
                      </Typography>
                    </Box>
                    <Box>
                      {/* <FormControl variant="standard">
                      <Select
                        input={<BootstrapInputCurrency />}
                        displayEmpty
                        renderValue={
                          financials.frOutlooksCurrency !== ""
                            ? undefined
                            : () => (
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                    marginTop: "3px",
                                    color: "#B8B8B8",
                                  }}
                                >
                                  Currency
                                </Typography>
                              )
                        }
                        value={financials.frOutlooksCurrency}
                        name="frOutlooksCurrency"
                        onChange={(e) => {
                          let newfinancials = { ...financials };
                          newfinancials[e.target.name] = e.target.value;
                          console.log(newfinancials);
                          setFinancials(newfinancials);
                        }}
                      >
                        {currencies.map((currency) => {
                          return (
                            <MenuItem
                              value={`${currency.symbol} ${currency.code}`}
                            >
                              {currency.symbol} {currency.code}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl> */}
                      <CustomSelect
                        fontSize={"14px"}
                        placeholder={"Currency"}
                        value={currency}
                        name="frOutlooksCurrency"
                        handleChange={e => {

                          setcurrency(e.value);
                          // console.log(e);
                          let newfinancials = { ...financials };

                          newfinancials["currency"] = e.value;
                          // console.log(newfinancials);
                          setFinancials(newfinancials);
                        
                        }}
                        options={currencyOptions}
                      />
                    </Box>
                  </Box>
                </Box>
                <Grid
                  container
                  xs={12}
                  sx={{ marginTop: "0px", marginBottom: "10px" }}>
                  <Grid
                    item
                    xs={5}
                    sx={{ display: "flex", alignItems: "center" }}>
                    <Typography id={styles.typography3}>
                      Current Funding Sources
                    </Typography>
                    <HelpIconTooltip
                      tooltipdata={
                        "List out all external sources that have funded your venture so far, including your own investment. Here are some examples:\n• Founders/Co-founders\n• Friends & Family\n• Angel investor\n• Incubator\n• Accelerator\n• VC / PE\n• Bank loan\n• Government grant\n• Pitch competition prize\n• Crowdfunding"
                      }
                    />
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid
                    item
                    xs={5}
                    sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontSize: "14px" }}>
                      How much new funding are you trying to raise?
                    </Typography>
                  </Grid>
                </Grid>

                {/* table code after that */}
                <Box>
                  <Grid
                    xs={12}
                    container
                    sx={{
                      marginBottom: 6,
                      position    : "relative"
                    }}>
                    {/* left side grid */}
                    <Grid
                      container
                      justifyContent="center"
                      xs={5}
                      sx={{
                        border      : "1px solid #A8A8A8",
                        borderRadius: 2
                      }}>
                      <TableContainer
                        id={styles.tableCon}
                        sx={{
                          // height: "330px",
                          height   : "250px",
                          overflowY: "auto"
                        }}>
                        <Table>
                          <TableHead>
                            {financials.currentFundingSource.map(
                              (fundingsource, index) => {

                                return <IndividualFundingSource
                                  fundingsource={fundingsource}
                                  handleFundingSourceChange={
                                    handleFundingSourceChange
                                  }
                                  numberWithCommas={numberWithCommas}
                                  removeRow={removeRow}
                                  index={index}
                                />;
                              
                              }
                            )}
                          </TableHead>
                        </Table>
                        <IconButton
                          onClick={addRow}
                          disabled={venture.userRole === "Commenter"}>
                          <AddCircleIcon
                            sx={{
                              color:
                                venture.userRole === "Commenter"
                                  ? "#b0b0b0"
                                  : "#071Aff"
                            }}
                          />
                        </IconButton>
                      </TableContainer>

                      <Grid
                        container
                        xs={12}
                        // justifyContent="space-around"
                        // align="center"
                        // justify="center"
                        alignItems="center"
                        sx={{ borderTop: "1px solid #A8A8A8" }}>
                        <Grid
                          item
                          xs={6}
                          sx={{
                            borderRight  : "1px solid #A8A8A8",
                            fontWeight   : "bold",
                            marginLeft   : "14px",
                            color        : "#707070",
                            width        : "100%",
                            // padding: "10px 0px",
                            textAlign    : "center",
                            fontSize     : "14px",
                            // border: 1,
                            paddingTop   : "10px",
                            paddingBottom: "10px",
                            paddingRight : "22px"
                          }}>
                          Total
                        </Grid>
                        <Grid item xs={4}>
                          {/* <TableTextField
                          sx={textFieldSX}
                          value={numberWithCommas(
                            financials.totalCurrentFunding
                          )}
                          disabled={true}
                        /> */}
                          <Box
                            sx={{
                              display       : "flex",
                              justifyContent: "center",
                              width         : "9vw",
                              marginLeft    : "5px"
                            }}>
                            <Typography
                              sx={{
                                marginLeft: "12px",

                                padding   : "10px 0px",
                                fontWeight: "bold",
                                color     : "#707070",
                                fontSize  : "14px",
                                textAlign : "center"
                              }}>
                              {numberWithCommas(financials.totalCurrentFunding)}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* spacing */}
                    <Grid container xs={2}></Grid>

                    {/* right side grid */}
                    <Grid container xs={5}>
                      <Grid xs={12} item>
                        {/* <Box sx={{ marginBottom: "6px" }}>
                        <Typography sx={{ fontSize: "14px" }}>
                          How much new funding are you trying to raise?
                        </Typography>
                      </Box> */}
                        {/* <TextField
                        sx={textFieldSX}
                        fullWidth
                        type="text"
                        inputProps={{
                          style: { fontSize: 14 },
                        }}
                        value={
                          financials.fundRaising.totalFundingNeed
                            ? numberWithCommas(
                                financials.fundRaising.totalFundingNeed
                              )
                            : numberWithCommas(0)
                        }
                        onChange={(e) => {
                          handleFundRaising("totalFundingNeed", e.target.value);
                        }}
                      /> */}
                        <CustomTextField
                          fontSize={"14px"}
                          width={"100%"}
                          padding={"7px 5px"}
                          value={
                            financials.fundRaising.totalFundingNeed
                              ? numberWithCommas(
                                financials.fundRaising.totalFundingNeed
                              )
                              : numberWithCommas(0)
                          }
                          handledetailsChange={e => {

                            handleFundRaising("totalFundingNeed", e.target.value);
                          
                          }}
                        />
                      </Grid>
                      <Grid sx={{ marginTop: "11%" }} xs={12}>
                        <Box sx={{ marginBottom: "6px" }}>
                          <Typography sx={{ fontSize: "14px" }}>
                            What key programs do you plan to use these funds for?
                          </Typography>
                        </Box>
                        {/* <TextField
                        // sx={textFieldSX}
                        fullWidth
                        multiline
                        inputProps={{
                          style: { fontSize: 14 },
                        }}
                        // sx={{ whiteSpace: "pre-wrap" }}
                        rows={5}
                        value={financials.fundRaising.fundingAllocation}
                        placeholder={
                          "Some examples:\n• Digital marketing – primarily online ads & campaigns\n• Hire 4 full stack developers\n• Build an enterprise sales team"
                        }
                        onChange={(e) => {
                          handleFundRaising(
                            "fundingAllocation",
                            e.target.value
                          );
                        }}
                      /> */}
                        <CustomMultilineTextfield
                          // fontSize={"14px"}
                          width={"100%"}
                          maxRows={5}
                          minRows={5}
                          value={financials.fundRaising.fundingAllocation}
                          placeholder={
                            "Some examples:\n• Digital marketing – primarily online ads & campaigns\n• Hire 4 full stack developers\n• Build an enterprise sales team "
                          }
                          handledetailsChange={e => {

                            handleFundRaising(
                              "fundingAllocation",
                              e.target.value
                            );
                          
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <hr></hr>

                {/* funding and runaway outlook */}

                <Box sx={{ marginTop: 4, color: "grey" }}>
                  <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                    Funding & Runaway Outlook
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display       : "flex",
                    justifyContent: "space-between",
                    textAlign     : "center",
                    marginTop     : 1
                  }}>
                  <Box
                    sx={{
                      border      : "1px solid #bdc9c1",
                      borderRadius: 1,
                      fontSize    : 14,
                      // padding: 1,
                      fontWeight  : "bold",
                      // width: "300px",
                      padding     : "5px 0px 5px 5px "
                    }}>
                    <span style={{ marginRight: "20px", color: "#707070" }}>
                      Total Current Funding
                    </span>

                    <BootstrapInputCurrentFunding
                      value={numberWithCommas(financials.totalCurrentFunding)}
                      disabled={true}
                    />
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {autosaving &&
                      <Typography sx={{ marginRight: "20px" }}>
                        Auto Saving....
                      </Typography>
                    }
                    <Box
                      sx={{
                        width:
                          financials.frOutlooks.length > 0 ? "200px" : "initial",
                        display       : "flex",
                        alignItems    : "center",
                        justifyContent: "space-evenly",
                        marginRight   : "5px"
                      }}>
                      {financials.frOutlooks.length > 0 ?
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Year
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Year"
                            value={year}
                            onChange={e => {

                              handleSelect(e.target.value);
                            
                            }}
                            disabled={venture.userRole === "Commenter"}>
                            <ListSubheader>
                              <Typography
                                sx={{ fontWeight: "bold", color: "#393939" }}>
                                Projected Years
                              </Typography>
                            </ListSubheader>
                            {years.map(year => {

                              return <MenuItem value={year}>{year}</MenuItem>;
                            
                            })}
                            <ListSubheader>
                              <Typography
                                sx={{ fontWeight: "bold", color: "#393939" }}>
                                Start Year(Editable)
                              </Typography>
                              <Box>
                                {/* <TextField
                                // size="small"
                                type="number"
                                // sx={textFieldSX}
                                autoFocus
                                fullWidth
                                onChange={(e) =>
                                  setSelectedyear(e.target.value)
                                }
                                onKeyDown={(e) => {
                                  if (e.key !== "Escape") {
                                    // Prevents autoselecting item while typing (default Select behaviour)
                                    e.stopPropagation();
                                  }
                                }}
                              /> */}
                                <CustomTextField
                                  width={"100%"}
                                  padding={"3px 5px"}
                                  type="number"
                                  handledetailsChange={e =>
                                    setSelectedyear(e.target.value)
                                  }
                                  onKeyDown={e => {

                                    if (e.key !== "Escape") {

                                      // Prevents autoselecting item while typing (default Select behaviour)
                                      e.stopPropagation();
                                    
                                    }
                                  
                                  }}
                                />
                                <Button
                                  variant="contained"
                                  size="small"
                                  sx={{
                                    // color: "#071AFF",
                                    color       : "white",
                                    marginBottom: "20px"
                                  }}
                                  onClick={yearDialogopen}>
                                  ADD
                                </Button>
                              </Box>
                              <Dialog
                                open={yearDialog}
                                PaperProps={{
                                  style: {
                                    borderRadius  : "20px",
                                    backdropFilter: "blur(45px)",
                                    background:
                                      "#FFFFFF 0% 0% no-repeat padding-box",
                                    boxShadow: "0px 3px 6px #00000029",
                                    border   : "1px solid #707070"
                                  }
                                }}
                              // BackdropProps={{ invisible: true }}
                              >
                                <DialogContent>
                                  <Box sx={{ textAlign: "center" }}>
                                    <Typography gutterBottom>
                                      Do you want to continue to edit the year?
                                    </Typography>
                                    <Typography gutterBottom>
                                      Note: Changing the editable year may result in a data loss. Make sure you have a copy of the data for the years that will be impacted due to this change. And, this change will impact all the pages in ‘Financials’ module
                                    </Typography>
                                    <Button
                                      variant="contained"
                                      size="medium"
                                      sx={{
                                        backgroundColor: "#071AFF",
                                        marginLeft     : "10px"
                                      }}
                                      onClick={() => {

                                        updateYear();
                                        yearDialogclose();
                                      
                                      }}>
                                      Yes
                                    </Button>
                                    <Button
                                      variant="contained"
                                      size="medium"
                                      sx={{
                                        backgroundColor: "#071AFF",
                                        marginLeft     : "10px"
                                      }}
                                      onClick={() => {

                                        yearDialogclose();
                                      
                                      }}>
                                      No
                                    </Button>
                                  </Box>
                                </DialogContent>
                              </Dialog>
                            </ListSubheader>
                          </Select>
                        </FormControl>
                        :
                        <>
                          {/* <TextField
                          inputProps={{
                            style: { fontSize: 14 },
                          }}
                          size="small"
                          sx={{ width: "60%" }}
                          placeholder="Enter Start Year"
                          variant="outlined"
                          type="number"
                          onChange={(e) => setSelectedyear(e.target.value)}
                        /> */}
                          <CustomTextField
                            width={"60%"}
                            placeholder="Enter Start Year"
                            padding={"3px 5px"}
                            type="number"
                            handledetailsChange={e =>
                              setSelectedyear(e.target.value)
                            }
                          />
                          <Button
                            variant="contained"
                            size="medium"
                            disableElevation
                            sx={{
                              // color: "#071AFF",
                              color: "white"
                            }}
                            onClick={updateYear}
                            disabled={venture.userRole === "Commenter"}>
                            ADD
                          </Button>
                        </>
                      }
                    </Box>
                  </Box>
                </Box>

                <Dialog
                  open={errorDialog}
                  PaperProps={{
                    style: {
                      borderRadius  : "20px",
                      backdropFilter: "blur(45px)",
                      background    : "#FFFFFF 0% 0% no-repeat padding-box",
                      boxShadow     : "0px 3px 6px #00000029",
                      border        : "1px solid #707070"
                    }
                  }}
                // BackdropProps={{ invisible: true }}
                >
                  <DialogContent>
                    <Box sx={{ textAlign: "center" }}>
                      <Typography gutterBottom>
                        Please enter the financial planning 'start year', in the
                        Year drop-down or select currency to begin the planning
                        process
                      </Typography>
                      <Button
                        variant="contained"
                        size="medium"
                        sx={{
                          backgroundColor: "#071AFF",
                          marginLeft     : "10px"
                        }}
                        onClick={() => {

                          errorDialogclose();
                        
                        }}>
                        OK
                      </Button>
                    </Box>
                  </DialogContent>
                </Dialog>

                {/* funding and runaway outlook table */}
                <Box>
                  <Grid
                    container
                    direction="column"
                    sx={{
                      marginTop   : 5,
                      marginBottom: 6,
                      // marginRight: 10,

                      width: "100%"
                    }}
                    rowSpacing={1}>
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        wrap="nowrap"
                        justifyContent="space-between">
                        <Grid item sx={{ width: 90, height: 20 }}></Grid>
                        {monthNameRow.map((name, index) => {

                          return (
                            <Grid
                              item
                              sx={{
                                width     : 50,
                                textAlign : "center",
                                fontWeight: "bold",

                                fontSize: 12,
                                color   : "#707070"
                              }}
                              key={index}>
                              {name}
                            </Grid>
                          );
                        
                        })}
                      </Grid>
                    </Grid>
                    {/* 2nd row */}
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        wrap="nowrap"
                        justifyContent="space-between">
                        <Grid item alignItems="center" className={styles.rowHead}>
                          {/* <Typography sx={{ fontSize: "13px", color: "#707070" }}>
                          Monthly Burn Rate
                        </Typography> */}
                          <span style={{ color: "#707070" }}>
                            Monthly Burn Rate
                          </span>
                          {/* Monthly Burn Rate */}
                          <HelpIconTooltip
                            tooltipdata={
                              "Enter the total monthly costs or overheads for your venture in this row. Ensure that these numbers are in sync with what you used for P&L Outlook calculations in the previous section."
                            }
                          />
                        </Grid>
                        {currentfroutlook.monthlyBurnRate.map(
                          (burnrate, index) => {

                            return (
                              <Grid
                                item
                                className={styles.rowGridSize}
                                key={index}>
                                {venture.userRole === "Commenter" ?
                                  <CustomInputStyl
                                    disabled={true}
                                    sx={textFieldSX}
                                    value={numberWithCommas(burnrate)}
                                  />
                                  :
                                  <CustomInput
                                    sx={textFieldSX}
                                    value={numberWithCommas(burnrate)}
                                    onChange={e => {

                                      handleMonthlyBurnRate(
                                        e.target.value,
                                        index
                                      );
                                    
                                    }}
                                  />
                                }
                              </Grid>
                            );
                          
                          }
                        )}
                      </Grid>
                    </Grid>
                    {/* 3rd row */}
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        wrap="nowrap"
                        justifyContent="space-between">
                        <Grid item alignItems="center" className={styles.rowHead}>
                          <span style={{ color: "#707070" }}>
                            Funding Balance
                          </span>
                          {/* Funding Balance */}
                        </Grid>
                        {currentfroutlook.fundingBalance.map(
                          (fundingbalance, index) => {

                            return (
                              <Grid
                                item
                                className={styles.rowGridSize}
                                key={index}>
                                <CustomInputStyl
                                  sx={textFieldSX}
                                  disabled={true}
                                  value={numberWithCommas(fundingbalance)}
                                />
                              </Grid>
                            );
                          
                          }
                        )}
                      </Grid>
                    </Grid>
                    {/* 4th row */}
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        wrap="nowrap"
                        justifyContent="space-between">
                        <Grid item alignItems="center" className={styles.rowHead}>
                          <span style={{ color: "#707070" }}>
                            Runway (months)
                          </span>
                          {/* Runway (months) */}
                        </Grid>
                        {currentfroutlook.runawayMonth.map((month, index) => {

                          // console.log(isFinite(month));
                          return (
                            <Grid item className={styles.rowGridSize} key={index}>
                              {!isFinite(month) || month === 0 ?
                                <CustomInputStyl
                                  sx={textFieldSX}
                                  disabled={true}
                                  value={"0"}
                                />
                                : month > 6 ?
                                  <RunawayGreen
                                    type="number"
                                    value={month}
                                    disabled={true}
                                  />
                                  :
                                  <RunawayRed
                                    type="number"
                                    value={month}
                                    disabled={true}
                                  />
                              }
                            </Grid>
                          );
                        
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                {/* <hr></hr> */}
                {/* click to view graphical representation */}
                {/* <Box sx={ { marginTop: 4, marginBottom: 5, color: "grey" } }>
                <Typography sx={ { fontWeight: "bold", fontSize: "14px" } }>
                  Click to view graphical representation
                </Typography>
              </Box> */}

                {/* <Grid
                container
                justifyContent="space-around"
                align="center"
                justify="center"
                alignItems="center"
                sx={ { mb: 10 } }>
                <Grid item xs={ 3 }>
                  <GraphicalViewButton
                    onClick={ () => {
                      setLabel1("Monthly Burn Rate");
                      setLabel2("Funding Balance");
                      setChartdataset1(currentfroutlook.monthlyBurnRate);
                      setChartdataset2(currentfroutlook.fundingBalance);
                      setSelectedchart(`${year} Burn Rate Vs Funding Balance`);
                      ChartDialogopen();
                    } }>
                    Burn Rate Vs Funding Balance
                  </GraphicalViewButton>
                  <Dialog
                    open={ chartDialog }
                    onClose={ ChartDialogclose }
                    PaperProps={ {
                      style: {
                        borderRadius: "20px",
                        backdropFilter: "blur(100px)",
                        backgroundColor: "white",
                        boxShadow: "0px 3px 6px #00000029",
                        border: "1px solid #b8bddb",
                        height: "470px",
                      },
                    } }
                    maxWidth="lg"
                    fullWidth>
                    <Grid
                      container
                      sx={ {
                        height: "650px",
                        width: "1200px",
                      } }>
                      <FrChart
                        dataset1={ chartdataset1 }
                        dataset2={ chartdataset2 }
                        title={ selectedchart }
                        currency={ financials.plOutlooksCurrency }
                        label1={ label1 }
                        label2={ label2 }
                      />
                    </Grid>
                  </Dialog>
                </Grid>
                <Grid item xs={ 3 }>
                  <GraphicalViewButton
                    onClick={ () => {
                      setLabel1("Funding Balance");
                      setLabel2("Runway (months)");
                      setChartdataset1(currentfroutlook.fundingBalance);
                      setChartdataset2(currentfroutlook.runawayMonth);
                      setSelectedchart(`${year} Runaway Vs Funding Balance`);
                      ChartDialogopen();
                    } }>
                    Runaway Vs Funding Balance
                  </GraphicalViewButton>
                </Grid>
              </Grid> */}
              </>
            }
          </Grid>

          {/* <div style={ { width: "100%" } }> */}
          <div className="ttribe-back-next-button-con">
            {/* back and next code */}
            {loading ? <></> : <BackNextButton
              restrictedBackClick={() => {

                history.push("/ploutlook");
              
              }}
              backClick={() => {

                history.push("/ploutlook");
              
              }}
              restrictedNextClick={() => {

                history.push("/cashflow");
                // history.push("/financialssummary");
              
              }}
              nextClick={() => {

                handleSubmit();
              
              }}
            />}

            {/* </div> */}
          </div>
        </div>
      </ModuleSections>
    </div>
  );

};

export default FrOutlook;
