import React, { useState } from "react";
import {
  TextField,
  Typography,
  Box,
  Dialog,
  DialogContent
} from "@mui/material";
import styles from "./Dashboard2.module.css";
import moment from "moment";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DeleteCancel from "../../../components/DeleteCancel";
import { useSelector } from "react-redux";

const IndividualProjectNotes = ({ projectNote, deleteComment, updateComment }) => {

  const [ isEdit, setIsedit ] = useState(false);
  const [ deletedialog, setDeletedialog ] = useState(false);
  const [ commentText, setCommentText ] = useState("");

  const { user, venture } = useSelector(state => ({ ...state }));

  const deleteDialogopen = () => {

    setDeletedialog(true);
  
  };
  const deleteDialogclose = () => {

    setDeletedialog(false);
  
  };

  return (
    <Box id={styles.commentBox}>
      {projectNote.user.profileIcon ?
        <img alt="" src={projectNote.user.profileIcon} id={styles.imgIcon} />
        :
        <AccountCircleIcon id={styles.defaultProfile} />
      }
      <Box sx={{ width: "90%" }}>
        <Box id={styles.commentDetails}>
          <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>{`${projectNote.user.firstname} ${projectNote.user.lastname}`}</Typography>
          <Typography id={styles.postedText}>Posted On <span id={styles.dateText}>{moment(projectNote.createdAt).format("D MMM YYYY [at] hh:mm a")}</span></Typography>
        </Box>
        {
          isEdit ?
            <TextField
              multiline
              type="text"
              maxRows={3}
              minRows={3}
              sx={{
                width                  : "100%",
                margin                 : "10px 0px",
                "& .MuiInputBase-input": {
                  fontSize  : "14px",
                  lineHeight: "20px"
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "9px"
                }
              }}
              value={commentText}
              onChange={e => {

                setCommentText(e.target.value.trimStart());
              
              }}
              onBlur={() => {

                if (commentText !== "") {

                  updateComment(projectNote._id, commentText);
                  setIsedit(false);
                
                }
              
              }}
              onKeyDown={e => {

                if (e.key === "Enter") {

                  e.preventDefault();
                  if (commentText !== "") {

                    updateComment(projectNote._id, commentText);
                    setIsedit(false);
                  
                  }
                
                }
              
              }}
              autoFocus
            />
            :
            <Typography id={styles.commentText}>{projectNote.text}</Typography>
                    
        }
        {user._id === projectNote.user._id && !isEdit ?
          <Box id={styles.commentBtns}>
            <Typography id={styles.editText} onClick={() => {

              setCommentText(projectNote.text);
              setIsedit(true);
            
            }}>Edit</Typography>
            <Typography id={styles.deleteText} onClick={deleteDialogopen}>Delete</Typography>
          </Box>
          : venture.userRole === "Owner" && !isEdit ?
            <Box id={styles.commentBtns}>
              <Typography id={styles.deleteText} onClick={deleteDialogopen}>Delete</Typography>
            </Box>
            : <></>
        }
      </Box>
      <Dialog
        open={deletedialog}
        PaperProps={{
          style: {
            borderRadius  : "20px",
            backdropFilter: "blur(45px)",
            background    : "#FFFFFF 0% 0% no-repeat padding-box",
            boxShadow     : "0px 3px 6px #00000029",
            border        : "1px solid #E0E1E2"
          }
        }}
      >
        <DialogContent>
          <DeleteCancel
            name={
              "Do you want to delete this comment?"
            }
            deleteClick={async e => {

              e.stopPropagation();
              await deleteComment(projectNote._id);
              deleteDialogclose();
            
            }}
            cancelClick={() => {

              deleteDialogclose();
            
            }}
          />
        </DialogContent>
      </Dialog>
    </Box >
  );

};

export default IndividualProjectNotes;