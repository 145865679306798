import React, { useEffect, useRef, useState } from "react";
import Monetisation from "../../Monetisation";
import {
  Box,
  Typography,
  TextField
} from "@mui/material";
import HelpIconTooltip from "../../../../../components/HelpIconTooltip";
// import BackNextButton from "../../../../../components/BackNextButton";
// import { useHistory } from "react-router-dom";
import styles from "./BusinessGoals.module.css";
import {
  getMonetisationDetails,
  updateMonetisationDetails
} from "../../../../../services/monetisation";
import { useSelector } from "react-redux";
import { useUnmount } from "../../../../../components/useUnmount";

export default function BusinessGoals() {

  const [ businessGoals, setBusinessGoals ] = useState("");
  const [ selectedChannels, setSelectedChannels ] = useState([]);
  const [ comments, setComments ] = useState([]);
  let isSubmitting = false;
  const { venture } = useSelector(state => ({ ...state }));
  const [ isDataLoading, setIsDataLoading ] = useState(true);
  // const history = useHistory();

  // To get the updated usestate value to save in useEffect cleanup
  const detailsRef = useRef(businessGoals);

  detailsRef.current = businessGoals;

  // useUnmount => custom hook to save while navigating
  useUnmount(() => {

    if ( isDataLoading == false && !isSubmitting) {

      handleSave();
    
    }
  
  });

  const handleSave = async () => {

    try {

      await updateMonetisationDetails(
        "businessGoals",
        detailsRef.current,
        venture.monetisation
      );
      
      return true;
    
    } catch (error) {

      console.error(error);
      
      return false;
    
    }
  
  };

  useEffect(() => {

    fetchMonetisation();
  
  }, []);

  const fetchMonetisation = async () => {

    setIsDataLoading(true);

    try {

      const response = await getMonetisationDetails(venture.monetisation);
      // console.log(response);

      setBusinessGoals(response.data.businessGoals);
      setSelectedChannels(response.data.channels.selectedChannels);
      setComments(response.data.comments.commercialModel.businessGoals);
      setIsDataLoading(false);
    
    } catch (error) {

      console.error("error while fetching monetisation", error);
      setIsDataLoading(false);
    
    }
  
  };

  const handleBusinessGoalsChange = value => {

    let newBusinessGoals = [ ...businessGoals ];

    newBusinessGoals = value;
    setBusinessGoals(newBusinessGoals);
  
  };

  const handleSubmit = async () => {

    try {

      isSubmitting = true;
      await updateMonetisationDetails(
        "businessGoals",
        businessGoals,
        venture.monetisation
      );
      isSubmitting = false;
    
    } catch (error) {

      isSubmitting = false;
      console.error(error);
    
    }
  
  };

  // console.log(businessGoals);

  return (
    <Monetisation
      barFour={true}
      mainheading="Monetisation"
      subheading="Commercial Model"
      comments={comments}
      sectionName="commercialModel"
      subSectionName="businessGoals"
      handleSave={handleSave}
      handleSubmit={handleSubmit}
      selectedChannels={selectedChannels}

    >
      <Box>
        <Box id={styles.topTypoBox}>
          <Typography id={styles.topTypo}>Business Goals</Typography>
          <HelpIconTooltip
            placement="top-start"
            width={"450px"}
            tooltipdata={
              "Some examples: \n• ​Gain at least 15% market share in 1yr\n• ​Maximize profit margins\n• ​Elevate brand’s reputation as premium / luxury\n• ​Match competitor prices​"
            }
          />
        </Box>

        {/* business goal content typo */}
        <Box id={styles.typoImgbox}>
          <Box id={styles.businessGoalDescBox}>
            <Typography id={styles.businessGoalTypo}>
              What is your main business goal? This will influence how you price
              your revenue streams. You may list more than 1 goal but do think
              through potential conflicts between these goals and prioritize
              them thoughtfully.
            </Typography>
          </Box>
          {/* image box */}
          <Box id={styles.mainImgBox}>
            <Box id={styles.imgBox}>
              <img
                id={styles.img}
                src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/monetisationBusinessGoals.svg`}
                alt=""
              />
            </Box>
          </Box>
        </Box>

        {/* text area main box */}
        <Box id={styles.textFieldBox}>
          <TextField
            onChange={e => {

              handleBusinessGoalsChange(e.target.value);
            
            }}
            disabled={venture.userRole === "Commenter" ? true : false}
            value={businessGoals}
            placeholder=" Some examples:
            -​Gain at least 15% market share in 1yr

            -​Maximize profit margin

            -​Elevate brand’s reputation as premium / luxury

            -​Match competitor prices​"
            fullWidth
            multiline
            maxRows={12}
            minRows={12}
            InputLabelProps={{
              shrink: true
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                padding: "14px 16px"
              },
              "& .MuiInputBase-input": {
                fontSize  : "0.75em",
                color     : "#707070",
                lineHeight: "17px"
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #B4B4FA"
              }
            }}
          />
        </Box>
      </Box>

    </Monetisation>
  );

}
