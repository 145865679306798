const userState = {
  firstname       : "",
  lastname        : "",
  email           : "",
  token           : "",
  subscription    : "",
  access          : "",
  profileIcon     : "",
  userVerified    : "",
  organizationName: "",
  location        : "",
  createdAt       : "",
  _id             : ""
};

export const userReducer = (state = userState, action) => {

  // the state can visible in redux chrome
  switch (action.type) {

  case "LOGGED_IN_USER":
    return action.payload;
  case "LOGOUT":
    return userState;
  default:
    return state;
  
  }

};
