import axios from "./axiosService";

// import axios from "axios";

export const getPitchDeckDetails = async pitchdeckid => {

  return await axios.get(
    `${process.env.REACT_APP_ADDRESS}/get_pitchdeck/${pitchdeckid}`
  );

};

export const getPublishedPitchDeckDetails = async (pitchdeckid, onePage = false) => {

  return await axios.get(
    `${process.env.REACT_APP_ADDRESS}/get_publishedpitchdeck/${pitchdeckid}${onePage ? "/1" : ""}`
  );

};

export const updatePitchDeckDetails = async formdata => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/update_pitchdeck`,
    formdata
  );

};

export const updatePublishPitchDeckDetails = async (
  pitchdeckid,
  publishedSlideImages,
  slides,
  changedSlides
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/update_publishedpitchdeck`,
    { pitchdeckid, publishedSlideImages, slides, changedSlides }
  );

};

export const fetchPdf = async ventureId => {

  return await axios.get(
    `${process.env.REACT_APP_ADDRESS}/get_uploadedpitch/${ventureId}`
  );

};

export const exportPitchDeck = async pitchdeckId => {

  return await axios.get(
    `${process.env.REACT_APP_ADDRESS}/export_pitchdeck/${pitchdeckId}`,
    {
      responseType: "arraybuffer"
      // headers: {
      //   "Content-Type": "application/json",
      // },
    }
  );

};

export const exportPitchDeckOnePage = pitchdeckid => {

  return new Promise((resolve, reject) => {

    if (!pitchdeckid) {

      return resolve({});
    
    }

    axios
      .post(
        `${process.env.REACT_APP_ADDRESS}/export-pitchdeck-one-page/`,
        { pitchdeckid },
        {
          responseType: "arraybuffer"
          // headers: {
          //   "Content-Type": "application/pdf",
          // }
        }
      )
      .then(response => {

        return resolve(response);
      
      }, reject)
      .catch(error => {

        return error;
      
      });
  
  });

};

// export const delUploadedPdf = async (ventureId) => {
//    return await axios.delete(
//     `${process.env.REACT_APP_ADDRESS}/delete_uploadedpitch/${ventureId}`
//    )
// }
