import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box, Divider, TextField, Typography
} from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import SectionHeader from "../SectionHeader";
import IndividualFeedback from "./IndividualFeedback";
import styles from "./Traction.module.css";

export default function Traction({
  logoimg,
  slides,
  isRender,
  setSlides,
  uploadedImgs,
  uploadImages,
  id,
  removeUploadImage,
  deleteUploadedImgs,
  isPublished,
  ...props
}) {

  const slide = slides[isRender];

  const history = useHistory();
  const pathname = history.location.pathname.substring(1);

  const handleTractionChange = (value, columnindex) => {

    // console.log(value, columnindex);
    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    let newtractions = [ ...newSlides[isRender]["tractions"] ];

    newtractions.map((traction, tractionindex) => {

      if (tractionindex === columnindex) {

        newtractions[tractionindex] = value;
      
      }
      
      return traction;
    
    });
    newSlides[isRender]["tractions"] = newtractions;

    setSlides(newSlides);
  
  };

  const addTraction = () => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    newSlides[isRender]["tractions"].push("");
    setSlides(newSlides);
  
  };

  const removeTraction = columnindex => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    let newtractions = newSlides[isRender]["tractions"].filter(
      (traction, tractionindex) => tractionindex !== columnindex
    );

    newSlides[isRender]["tractions"] = newtractions;
    setSlides(newSlides);
  
  };

  const handleFeedbackChange = (name, value, columnindex) => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;

    let newfeedbacks = [ ...newSlides[isRender]["feedbacks"] ];

    newfeedbacks.map((feedback, feedbackindex) => {

      if (feedbackindex === columnindex) {

        newfeedbacks[feedbackindex][name] = value;
      
      }
      
      return feedback;
    
    });
    newSlides[isRender]["feedbacks"] = newfeedbacks;
    setSlides(newSlides);
  
  };

  const addFeedback = () => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    newSlides[isRender]["feedbacks"].push({
      name      : `person ${slide.feedbacks.length + 1}`,
      feedback  : "",
      profileImg: ""
    });
    setSlides(newSlides);
  
  };

  const removeFeedback = columnindex => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    let newfeedbacks = newSlides[isRender]["feedbacks"].filter(
      (feedback, feedbackindex) => feedbackindex !== columnindex
    );

    newSlides[isRender]["feedbacks"] = newfeedbacks;
    removeUploadImage(`${slide.renderName}.${columnindex}`);

    setSlides(newSlides);
  
  };

  const handleDetailsChange = (name, value) => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    newSlides[isRender][name] = value;

    setSlides(newSlides);
  
  };

  const updateIschange = () => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    setSlides(newSlides);
  
  };

  const tractionPlaceholders = [
    "E.g. 1000+ paid subscribers",
    "E.g. $150,000 MRR ",
    "E.g. 500+ new users/month"
  ];

  return (
    <Box>
      <SectionHeader
        isEdit={props.isEdit}
        Collapsecomment={props.Collapsecomment}
        collapseCommentFunction={props.collapseCommentFunction}
        mainHead={slide.name}
        handleTitleChange={handleDetailsChange}
        logoimg={logoimg}
        isPublished={isPublished}
      />
      <Box
        id={styles.tractionTitleBox}
      >
        <Box
          id={styles.tractionTitleSubBox}
        >
          {/* 1 */}
          {slide.tractions.map((traction, tractionindex) => {

            return (
              <Box
                id={styles.tractionTitleColorBox}
              >
                <Box
                  id={styles.tractionTitleWhiteBox}
                >
                  {props.isEdit ?
                    <>
                      <TextField
                        value={traction}
                        onChange={e => {

                          handleTractionChange(e.target.value, tractionindex);
                        
                        }}
                        placeholder={tractionPlaceholders[tractionindex]}
                        fullWidth
                        inputProps={{ maxLength: 25 }}
                        sx={{
                          "& .MuiInputBase-input": {
                            fontSize  : "16px",
                            padding   : "20px 10px",
                            border    : "none",
                            textAlign : "center",
                            color     : "#393939",
                            fontWeight: "bold"
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "2px dotted #B4B4FA"
                          }
                        }}
                      />
                      {tractionindex > 1 &&
                        <Box
                          sx={{
                            width          : "15px",
                            height         : "15px",
                            border         : "1px solid #838383",
                            borderRadius   : "50%",
                            zIndex         : 20,
                            position       : "absolute",
                            right          : "-5px",
                            top            : "-11px",
                            display        : "flex",
                            justifyContent : "center",
                            alignItems     : "center",
                            backgroundColor: "#FFFFFF"
                          }}
                          onClick={() => {

                            removeTraction(tractionindex);
                          
                          }}
                        >
                          <CloseIcon
                            sx={{ fontSize: "15px", color: "#838383" }}
                          />
                        </Box>
                      }
                    </>
                    :
                    <Typography
                      id={styles.tractionTitleTypo}
                    >
                      {traction
                        ? traction
                        : isPublished
                          ? ""
                          : tractionPlaceholders[tractionindex]}
                    </Typography>
                  }
                </Box>
              </Box>
            );
          
          })}

          {props.isEdit ?
            <Box
              sx={{
                width         : "36px",
                height        : "36px",
                display       : "flex",
                justifyContent: "center",
                alignItems    : "center",
                borderRadius  : "50%",
                position      : "absolute",
                bottom        : "-40px",
                right         : "-5px",
                backgroundColor:
                  slide.tractions.length === 3 ? "#b0b0b0" : "#071AFF",
                cursor: slide.tractions.length === 3 ? "default" : "pointer"
              }}
              onClick={() => {

                if (slide.tractions.length < 3) addTraction();
              
              }}
            >
              <AddIcon sx={{ fontSize: "32px", color: "#FFFFFF" }} />
            </Box>
            :
            <></>
          }
        </Box>
      </Box>
      {/* ----- */}
      <Box
        id={styles.dividerBox}
      >
        <Divider
          id={styles.divider}
        />
      </Box>
      {/* ----- */}
      <Box
        id={styles.CommentHeadBox}
      >
        <Box
          sx={{
            width         : "50%",
            display       : "flex",
            justifyContent: "center"
          }}
        >
          {props.isEdit ?
            <TextField
              value={slide.feedbackHeading}
              onChange={e => {

                handleDetailsChange("feedbackHeading", e.target.value);
              
              }}
              fullWidth
              inputProps={{ maxLength: 45 }}
              sx={{
                "& .MuiInputBase-input": {
                  fontSize  : "19px",
                  padding   : "1px 10px",
                  border    : "none",
                  color     : "#393939",
                  textAlign : "center",
                  fontWeight: "bold"
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "2px dotted #B4B4FA"
                }
              }}
            />
            :
            <Typography
              id={styles.commentHeadTypo}
            >
              {slide.feedbackHeading}
            </Typography>
          }
        </Box>
      </Box>
      {/* ----- */}
      <Box
        id={styles.commentMainBox}
      >
        {slide.feedbacks.map((feedback, feedbackindex) => {

          return <IndividualFeedback
            pathname={pathname}
            slide={slide}
            isEdit={props.isEdit}
            feedback={feedback}
            handleFeedbackChange={handleFeedbackChange}
            feedbackindex={feedbackindex}
            removeFeedback={removeFeedback}
            uploadImages={uploadImages}
            profileImg={
              id
                ? {}
                : uploadedImgs.find(
                  img =>
                    img.imgName === `${slide.renderName}.${feedbackindex}`
                )
            }
            deleteUploadedImgs={deleteUploadedImgs}
            updateIschange={updateIschange}
          />;
        
        })}

        {/* ------ */}
        {props.isEdit ?
          <Box
            sx={{
              width         : "36px",
              height        : "36px",
              display       : "flex",
              justifyContent: "center",
              alignItems    : "center",
              borderRadius  : "50%",
              position      : "absolute",
              bottom        : "22px",
              right         : "-5px",
              backgroundColor:
                slide.feedbacks.length === 3 ? "#b0b0b0" : "#071AFF",
              cursor: slide.feedbacks.length === 3 ? "default" : "pointer"
            }}
            onClick={() => {

              if (slide.feedbacks.length < 3) addFeedback();
            
            }}
          >
            <AddIcon sx={{ fontSize: "32px", color: "#FFFFFF" }} />
          </Box>
          :
          <></>
        }
      </Box>
    </Box>
  );

}
