import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ImageIcon from "@mui/icons-material/Image";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box, Dialog,
  DialogContent, IconButton, Menu,
  MenuItem, Typography
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DeleteCancel from "../../components/DeleteCancel";
import { deleteVenture } from "../../services/venture";
import styles from "./WelcomePage.module.css";

const IndividualVentureBox = props => {

  const [ anchorEl, setAnchorEl ] = React.useState(null);
  const [ deletedialog, setDeletedialog ] = useState(false);

  const deleteDialogopen = () => {

    setDeletedialog(true);
  
  };
  const deleteDialogclose = () => {

    setDeletedialog(false);
  
  };
  const open = Boolean(anchorEl);
  const handleClick = event => {

    setAnchorEl(event.currentTarget);
  
  };
  
  const handleClose = () => {

    setAnchorEl(null);
  
  };
  
  let { user } = useSelector(state => {

    return { ...state };
  
  });
  const history = useHistory();
  const venture = props?.venture;

  const removeVenture = async () => {

    try {

      await deleteVenture(venture._id, user._id, venture.details.name);
      props.fetchProjects();
      handleClose();
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  return (
    <Box
      id={styles.projectBox}
      onClick={() => {

        props.handleChange(venture._id);
      
      }}
    >
      <Box id={styles?.projectSubBox}>
        {venture?.details?.logo ?
          // <Box id={styles.projectImgBoxVisible}>
          <img
            style={{
              // width: "33px",
              width       : "50%",
              height      : "90%",
              // border: "1px solid gray",
              borderRadius: "50px"
              // marginTop: "4%",
            }}
            alt=""
            src={venture.details.logo}
          ></img>
          :
          // </Box>
          <Box id={styles.projectImgBox}>
            <ImageIcon sx={{ fontSize: "35px", color: "gray" }} />
          </Box>
        }
      </Box>

      <Box id={styles.ventureNameBox}>
        <Box sx={{ marginLeft: "15px" }}>
          <Typography id={styles.ventureNameTypo}>
            {venture?.details?.name}
          </Typography>
        </Box>
        <IconButton
          sx={{ padding: "0px" }}
          onClick={e => {

            e.stopPropagation();
            handleClick(e);
          
          }}
        ><MoreVertIcon id={styles?.MoreVert} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={e => {

            e.stopPropagation();
            handleClose();
          
          }}
          anchorOrigin={{
            vertical  : "top",
            horizontal: "right"
          }}
          sx={{
            "& .MuiMenu-list": {
              padding        : "0px",
              backgroundColor: "#707070",
              color          : "white"
            }
          }}
        // transformOrigin={{
        //   vertical: 'top',
        //   horizontal: 'start',
        // }}
        >
          <MenuItem
            sx={{ fontSize: "12px" }}
            disableGutters="true"
            onClick={e => {

              e.stopPropagation();
              history.push({
                pathname: `/editventure/${venture?._id}`,
                state   : {
                  intendedurl: history.location.pathname,
                  userRole   : "Owner"
                }
              });
            
            }}
          >
            <EditIcon id={styles.editIcon} />
            Edit
          </MenuItem>
          <MenuItem
            sx={{ fontSize: "12px", borderTop: "solid 1px" }}
            disableGutters="true"
            onClick={e => {

              e.stopPropagation();
              deleteDialogopen();
            
            }}
          >
            <DeleteIcon id={styles.editIcon} />
            <Box sx={{ paddingRight: "7px" }}>Delete</Box>
            {/* Delete */}
          </MenuItem>
        </Menu>
        <Dialog
          open={deletedialog}
          PaperProps={{
            style: {
              borderRadius  : "20px",
              backdropFilter: "blur(45px)",
              background    : "#FFFFFF 0% 0% no-repeat padding-box",
              boxShadow     : "0px 3px 6px #00000029",
              border        : "1px solid #E0E1E2"
            }
          }}
        >
          <DialogContent>
            <DeleteCancel
              name={"Are you sure you want to delete this venture ?"}
              deleteClick={async e => {

                e.stopPropagation();
                removeVenture();
                deleteDialogclose();
              
              }}
              cancelClick={e => {

                e.stopPropagation();
                deleteDialogclose();
                handleClose();
              
              }}
            />
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );

};

export default IndividualVentureBox;
