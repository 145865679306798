import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  IconButton, Menu, MenuItem, TableCell, TableRow
} from "@mui/material";
// import { styled } from "@mui/material/styles";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CustomTextField from "../../../../components/CustomTextfield";

// const TableTextField = styled(InputBase)(({ theme }) => ({
//   "label + &": {
//     marginTop: theme.spacing(3)
//   },
//   "& .MuiInputBase-input": {
//     textAlign      : "center",
//     borderRadius   : 5,
//     position       : "relative",
//     backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
//     // border: "1px solid #ced4da",
//     fontSize       : 14
//     // minWidth: 10,
//     // width: "100%",
//     // fontWeight: "bold",
//     // padding: "5px 6px",
//   }
// }));

// const textFieldSX = {
//   input: {
//     "-webkit-text-fill-color": "#707070 !important",
//     color                    : "#707070 !important"
//     // color: `black !important`,
//   }
// };

const IndividualFundingSource = props => {

  const [ anchorEl, setAnchorEl ] = React.useState(null);

  const { venture } = useSelector(state => ({ ...state }));

  const open = Boolean(anchorEl);
  const handleClick = event => {

    setAnchorEl(event.currentTarget);
  
  };
  const handleClose = () => {

    setAnchorEl(null);
  
  };
  const fundingsource = props.fundingsource;
  const index = props.index;

  const location = useLocation();

  
  return (
    <TableRow key={ index }>
      <TableCell
        padding="none"
        align="center"
        sx={ {
          borderRight: "1px solid #A8A8A8",
          width      : "54%"
        } }
      >
        {/* <TableTextField
          value={fundingsource.name}
          fullWidth
          placeholder="Enter a funding source"
          onChange={(e) => {
            props.handleFundingSourceChange("name", e.target.value, index);
          }}
        /> */}
        <CustomTextField
          border={ "none" }
          value={ fundingsource.name }
          width={ "100%" }
          placeholder="Enter a funding source"
          handledetailsChange={ e => {

            props.handleFundingSourceChange("name", e.target.value, index);
          
          } }
          fontSize={ "14px" }
          disabled={ location.pathname.includes("pitchdeckintro") }
        />
      </TableCell>
      <TableCell padding="none">
        {/* <TableTextField
          // type="text"
          sx={textFieldSX}
          value={props.numberWithCommas(fundingsource.source)}
          onChange={(e) => {
            props.handleFundingSourceChange("source", e.target.value, index);
          }}
        /> */}
        <CustomTextField
          border={ "none" }
          value={ props.numberWithCommas(fundingsource.source) }
          width={ "100%" }
          placeholder="Enter a funding source"
          handledetailsChange={ e => {

            props.handleFundingSourceChange("source", e.target.value, index);
          
          } }
          fontSize={ "14px" }
          textAlign={ "center" }
          disabled={ location.pathname.includes("pitchdeckintro") }
        />
      </TableCell>
      { venture.userRole !== "Commenter" &&
        <TableCell padding="none">
          { location.pathname.includes("/pitchdeckintro") ?
            <></>
            :
            <IconButton
              onClick={ e => {

                handleClick(e);
              
              } }
            >
              <MoreVertIcon />
            </IconButton>
          }
          {/* <IconButton
            onClick={(e) => {
              handleClick(e);
            }}
          >
            <MoreVertIcon />
          </IconButton> */}
          <Menu
            anchorEl={ anchorEl }
            open={ open }
            onClose={ () => {

              handleClose();
            
            } }
            anchorOrigin={ {
              vertical  : "top",
              horizontal: "right"
            } }
            sx={ {
              "& .MuiMenu-list": {
                padding        : "0px",
                backgroundColor: "#707070",
                color          : "white"
              }
            } }
          // transformOrigin={{
          //   vertical: 'top',
          //   horizontal: 'start',
          // }}
          >
            <MenuItem
              sx={ { fontSize: "12px" } }
              onClick={ () => {

                props.removeRow(index, fundingsource.source);
                handleClose();
              
              } }
            >
              Delete
            </MenuItem>
          </Menu>
        </TableCell>
      }
    </TableRow>
  );

};

export default IndividualFundingSource;
