/* eslint-disable camelcase */
import {
  Grid, Typography
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BackNextButton from "../../../../components/BackNextButton";
import CustomMultilineTextfield from "../../../../components/CustomMultilineTextfield";
import IndividualSectionHeaders from "../../../../components/IndividualSectionHeaders";
import ModuleSections from "../../../../components/ModuleSections";
import Data from "../../../../Data/Gtm.json";
import { getGtm, updateGtmPositioningValues } from "../../../../services/gtm";

import styles from "./Positioning.module.css";

import { makeStyles } from "@mui/styles";
import HelpIconTooltip from "../../../../components/HelpIconTooltip";
import { useUnmount } from "../../../../components/useUnmount";
import {
  createGtmComment,
  deleteGtmComment,
  updateGtmComment
} from "../../../../services/comment";

const useStyles = makeStyles(() => ({
  customWidth: {
    maxWidth       : 200,
    fontSize       : 10,
    backgroundColor: "white",
    color          : "#707070",
    boxShadow      : "0px 3px 6px #00000029",
    border         : "1px solid #707070",
    borderRadius   : 10,
    whiteSpace     : "pre-wrap"
  },
  helpicon: {
    color   : "#071AFF",
    fontSize: "15px"
  },
  question: {
    color   : "#393939",
    fontSize: "15px"
    // fontWeight: "bold",
  }
}));

const Research = () => {

  const [ details, setDetails ] = useState({
    customersSearchQueries      : "",
    customersOnlineResearchTools: ""
  });
  const [ comments, setComments ] = useState([]);
  const [ dialogbox, setDialog ] = useState(false);
  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  const [ isDataLoading, setIsDataLoading ] = useState(true);
  const content = Data.gtmpositioning;

  let { user, venture } = useSelector(state => {

    return { ...state };
  
  });

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);
    
    } else {

      setCollapsecomment(true);
    
    }
  
  };

  useEffect(() => {

    fetchGtm();
  
  }, []);

  const fetchGtm = async () => {

    setIsDataLoading(true);

    try {

      const response = await getGtm(venture.gtm);
      // console.log(response.data);

      setDetails(response.data.positioning.research);
      setComments(response.data.comments.positioning.research);
      setIsDataLoading(false);
    
    } catch (error) {

      console.error(error);
      setIsDataLoading(false);
    
    }
  
  };

  // To get the updated usestate value to save in useEffect cleanup
  const detailsRef = useRef(details);

  detailsRef.current = details;

  let isSubmitting = false;

  // useUnmount => custom hook to save while navigating
  useUnmount(() => {

    if ( isDataLoading == false && !isSubmitting) {

      handleSave();
    
    }
  
  });

  const handleSave = async () => {

    try {

      await updateGtmPositioningValues(
        venture.gtm,
        "research",
        detailsRef.current
      );
      
      return true;
    
    } catch (error) {

      console.error(error);
      
      return false;
    
    }
  
  };

  const handleChange = e => {

    setDetails(values => ({ ...values, [e.target.name]: e.target.value }));
  
  };

  const handleSubmit = async () => {

    try {

      isSubmitting = true;
      if(isDataLoading == false){

        await updateGtmPositioningValues(venture.gtm, "research", details);
        
      }
      history.push("/gtmpositioningusecases");
      isSubmitting = false;
    
    } catch (error) {

      isSubmitting = false;
      console.error(error);
    
    }
  
  };
  const pathname = history.location.pathname.substring(1);

  const addComment = async (commentText, replyTo) => {

    try {

      const response = await createGtmComment(
        commentText,
        user._id,
        "positioning",
        "research",
        venture.gtm,
        replyTo,
        window.location.href,
        "GTM > Positioning > Research"
      );

      setComments(response.data.comments.positioning.research);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateComment = async (commentid, commentText) => {

    try {

      const response = await updateGtmComment(
        commentText,
        user._id,
        commentid,
        venture._id,
        window.location.href,
        "GTM > Positioning > Research"
      );

      setComments(response.data.comments.positioning.research);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const deleteComment = async commentid => {

    try {

      const response = await deleteGtmComment(
        "positioning",
        "research",
        venture.gtm,
        commentid,
        user._id,
        window.location.href,
        "GTM > Positioning > Research"
      );

      setComments(response.data.comments.positioning.research);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  return (
    <ModuleSections
      Collapsecomment={ Collapsecomment }
      comments={ comments }
      addComment={ addComment }
      deleteComment={ deleteComment }
      updateComment={ updateComment }>
      <div className="box-display-style-for-next-and-back-button-with-justify">
        <div className="box-indiv-header-con">
          <IndividualSectionHeaders
            mainheading={ "Positioning" }
            subheading={ "Research" }
            headingcontent={
              "How are your target customers finding the solutions or products they need?"
            }
            isGtm={ true }
            // isGtmChevron={true}
            Dialogopen={ Dialogopen }
            Dialogclose={ Dialogclose }
            dialogbox={ dialogbox }
            collapse_comment_function={ collapse_comment_function }
            content={ content }
            isGtmChevron={ pathname.includes("gtm") ? "gtmV" : "customer" }
            isSaveButton={ true }
            handleSave={ handleSave }
          />
        </div>
        <Grid item xs={ 12 } id={ styles.maingrid }>
          <Grid container mb={ 3 }>
            <Grid item md={ 5 }>
              <Typography className={ classes.question }>
                Top search queries that your customers use{ " " }
                {/* <Tooltip
                disableFocusListener
                title={
                  "Some keywords and search terms associated with your product needs that your customers are looking online. This helps highlight common terms and phrases associated with your product or solution that you can potentially use in your messaging. \nExample: \ne.g. Online information or channels like Quora, G2Crowd, Capterra, LinkedIn Groups, or industry-centric websites…"
                }
                placement="right-start"
                classes={{ tooltip: classes.customWidth }}
              >
                <IconButton>
                  <HelpIcon className={classes.helpicon}></HelpIcon>
                </IconButton>
              </Tooltip> */}
                <HelpIconTooltip
                  width="400px"
                  tooltipdata={
                    "List out several keywords and search terms that your customers may be using on the internet, to either further research their problem/ need or to find solutions for the same. Think of everything that could be directly or indirectly related to your product and the needs that it addresses. ​This list can help you optimize your SEO, help target online ads, integrate into your product messaging etc.\n\nFor example, if your venture is an online wellness platform focused on drug-free pain management solutions, common terms your target users may be searching for online may include pain, pain medicine, opioids, ibuprofen, back ache, physical therapy, migraine, inflammation, injury, pain balm, alternative pain management, fibromyalgia, cancer pain, acupuncture, among many others. "
                  }
                />
              </Typography>
            </Grid>
            <Grid item md={ 6 }>
              <CustomMultilineTextfield
                width="100%"
                borderRadius="5px"
                // fontSize="12px"
                minRows={ 4 }
                maxRows={ 4 }
                value={ details.customersSearchQueries }
                name={ "customersSearchQueries" }
                handledetailsChange={ handleChange }
              // placeholder={
              //   "e.g. Online information or channels like Quora, G2Crowd, Capterra, LinkedIn Groups, or industry-centric websites…"
              // }
              />
            </Grid>
          </Grid>
          <Grid container mb={ 3 }>
            <Grid item md={ 5 }>
              <Typography className={ classes.question }>
                Online research tools that customers use{ " " }
                {/* <Tooltip
                disableFocusListener
                title={
                  "Where are your buyers/target users looking out for solutions to the problems currently? To gain deeper insights on their needs, pain areas and can be used in your messaging to target them better. \nExample: \ne.g. social media - LinkedIn, Facebook, Youtube, Web, online forums, groups ,etc"
                }
                placement="right-start"
                classes={{ tooltip: classes.customWidth }}
              >
                <IconButton>
                  <HelpIcon className={classes.helpicon}></HelpIcon>
                </IconButton>
              </Tooltip> */}
                <HelpIconTooltip
                  width="400px"
                  tooltipdata={
                    "What online resources are your buyers/target users using to research their problems/needs or searching for solutions?\nFor example: web search (Google etc.), social media sites (list out which ones you think they’re using the most), online web forums/groups/communities, specialized websites like Quora, industry association sites, market research databases, etc. "
                  }
                />
              </Typography>
            </Grid>
            <Grid item md={ 6 }>
              <CustomMultilineTextfield
                width="100%"
                borderRadius="5px"
                // fontSize="12px"
                minRows={ 4 }
                maxRows={ 4 }
                value={ details.customersOnlineResearchTools }
                name={ "customersOnlineResearchTools" }
                handledetailsChange={ handleChange }
              // placeholder={
              //   "e.g. social media - LinkedIn, Facebook, Youtube, Web, online forums, groups ,etc"
              // }
              />
            </Grid>
          </Grid>
        </Grid>
        <div className="ttribe-back-next-button-con">
          <BackNextButton
            restrictedBackClick={ () => {

              history.push("/gtmpositioningbeforeafter");
            
            } }
            backClick={ () => {

              history.push("/gtmpositioningbeforeafter");
            
            } }
            restrictedNextClick={ () => {

              history.push("/gtmpositioningusecases");
            
            } }
            nextClick={ () => {

              handleSubmit();
            
            } }
          />
        </div>
      </div>
    </ModuleSections>
  );

};

export default Research;
