import React from "react";
import { useSelector } from "react-redux";
import Select, { createFilter } from "react-select";
import { useHistory } from "react-router-dom";
import { Tooltip, Box } from "@mui/material";

const CustomSelect = props => {

  const history = useHistory();
  const pathname = history.location.pathname.substring(1);

  const { venture } = useSelector(state => ({ ...state }));

  
  return (
    <>
      <label
        htmlFor={props.name}
        style={pathname === "createventure" ? {
          color       : "#707070",
          fontSize    : props.labelfontsize,
          marginBottom: "0px"
        } : {
          color       : "#707070",
          fontSize    : props.labelfontsize,
          marginBottom: "5px"
        }}
      >
        {props.label}
      </label>
      {

        props.tooltipdata ? <Tooltip title={props.tooltipdata} placement="top">

          <Box>

            <Select
              isDisabled={props.disabled || venture.userRole === "Commenter"}
              value={
                props.value && {
                  value: props.value,
                  label: props.value
                }
              }
              onChange={props.handleChange}
              options={props.options}
              placeholder={props.placeholder}
              maxMenuHeight={props.maxMenuHeight}
              filterOption={createFilter({
                matchFrom: "start"
              })}
              styles={{
                control: styles => ({
                  ...styles,
                  height     : "0px",
                  borderColor: props.error ? "#FD1515" : styles.borderColor,
                  // marginBottom: "10px",
                  fontSize   : props.fontSize,
                  // width: "11vw",
                  // border: "1px solid #e3e5ff",
                  // minHeight: "33px",
                  border     : props.border,
                  // minHeight: props.minHeight,
                  width      : props.width
                })
              }}
              noOptionsMessage={props.noOptionsMessage}
              onBlur={props.onBlur}
              name={props.name}
            />

          </Box>

        </Tooltip>
          : <Select
            isDisabled={props.disabled || venture.userRole === "Commenter"}
            value={
              props.value && {
                value: props.value,
                label: props.value
              }
            }
            onChange={props.handleChange}
            options={props.options}
            placeholder={props.placeholder}
            maxMenuHeight={props.maxMenuHeight}
            filterOption={createFilter({
              matchFrom: "start"
            })}
            styles={{
              control: styles => ({
                ...styles,
                height     : "0px",
                borderColor: props.error ? "#FD1515" : styles.borderColor,
                // marginBottom: "10px",
                fontSize   : props.fontSize,
                // width: "11vw",
                // border: "1px solid #e3e5ff",
                // minHeight: "33px",
                border     : props.border,
                // minHeight: props.minHeight,
                width      : props.width
              })
            }}
            noOptionsMessage={props.noOptionsMessage}
            onBlur={props.onBlur}
            name={props.name}
          />

      }
      {/* <Select
        isDisabled={ props.disabled || venture.userRole === "Commenter" }
        value={
          props.value && {
            value: props.value,
            label: props.value,
          }
        }
        onChange={ props.handleChange }
        options={ props.options }
        placeholder={ props.placeholder }
        maxMenuHeight={ props.maxMenuHeight }
        filterOption={ createFilter({
          matchFrom: "start",
        }) }
        styles={ {
          control: (styles) => ({
            ...styles,
            height: "0px",
            borderColor: props.error ? "#FD1515" : styles.borderColor,
            // marginBottom: "10px",
            fontSize: props.fontSize,
            // width: "11vw",
            // border: "1px solid #e3e5ff",
            // minHeight: "33px",
            border: props.border,
            // minHeight: props.minHeight,
            width: props.width,
          }),
        } }
        noOptionsMessage={ props.noOptionsMessage }
        onBlur={ props.onBlur }
        name={ props.name }
      /> */}
    </>
  );

};

export default CustomSelect;
