import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const BackButton = props => {

  const { venture } = useSelector(state => ({ ...state }));

  
  return (
    <Grid
      sx={ {
        display       : "flex",
        justifyContent: "flex-start",
        margin        : props.margin ? props.margin : "20px 0px"
      } }
    >
      <Box
        sx={ {
          display   : "flex",
          alignItems: "center",
          cursor    : "pointer"
        } }
        onClick={ () => {

          if (venture.userRole === "Commenter") {

            props.restrictedBackClick();
          
          } else {

            props.backClick();
          
          }
        
        } }
      // onClick={props.backClick}
      >
        <img
          src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/back-icon.png` }
          alt="left-arrow"
          style={ { width: "8px", marginRight: "10px" } }
        />
        {/* <ArrowLeftIcon style={{ fontSize: "xx-large", color: "#071AFF" }} />*/ }
        <Typography
          sx={ { color: "#071AFF", textTransform: "none", fontWeight: "bold" } }
        >
          back
        </Typography>
      </Box>
    </Grid>
  );

};

export default BackButton;
