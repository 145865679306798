import {
  Grid, Slider, Typography
} from "@mui/material";
import React from "react";
// import { useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import HelpIconTooltip from "../../../../components/HelpIconTooltip";
// import Data from "../../../../Data/Gtm.json";

// import { makeStyles } from "@mui/styles";
// import BackNextButton from "../../../../components/BackNextButton";
import CustomMultilineTextfield from "../../../../components/CustomMultilineTextfield";
import CustomSelect from "../../../../components/CustomSelect";
import styles from "./TargetSegments.module.css";

// const useStyles = makeStyles(() => ({
//   customWidth: {
//     width          : "100%",
//     fontSize       : 10,
//     backgroundColor: "white",
//     color          : "#707070",
//     boxShadow      : "0px 3px 6px #00000029",
//     border         : "1px solid #707070",
//     borderRadius   : 10
//   }
// }));

// const bar = {
//   height         : "4px",
//   width          : "50px",
//   borderRadius   : "50px",
//   backgroundColor: "grey",
//   margin         : "5px 20px",
//   marginLeft     : "0px"
// };
// const bar_blue = {
//   height         : "4px",
//   width          : "50px",
//   borderRadius   : "50px",
//   backgroundColor: "blue",
//   margin         : "5px 20px",
//   marginLeft     : "0px"
// };

const Demographics2 = props => {

  // const history = useHistory();
  // const [ Collapsecomment, setCollapsecomment ] = useState(false);
  // const [ dialogbox, setDialog ] = useState(false);

  // const classes = useStyles();

  // let { venture } = useSelector(state => {

  //   return { ...state };
  
  // });

  // const pathname = history.location.pathname.substring(1);
  // const guideicon = Data.gtmtargetpersonas;

  // const Dialogopen = () => {

  //   setDialog(true);
  
  // };
  // const Dialogclose = () => {

  //   setDialog(false);
  
  // };

  // const collapse_comment_function = () => {

  //   if (Collapsecomment) {

  //     setCollapsecomment(false);
    
  //   } else {

  //     setCollapsecomment(true);
    
  //   }
  
  // };
  const ethnicityArr = [
    { value: "", label: "-None-" },
    { value: "East/ Southeast Asian", label: "East/ Southeast Asian" },
    { value: "South Asian", label: "South Asian" },
    { value: "Black/ African", label: "Black/ African" },
    { value: "Caucasian", label: "Caucasian" },
    { value: "Hispanic/ Latino", label: "Hispanic/ Latino" },
    { value: "Native American", label: "Native American" },
    { value: "Pacific Islander", label: "Pacific Islander" },
    { value: "Mixed Race", label: "Mixed Race" },
    { value: "Other", label: "Other" }
  ];

  const industryArr = [
    { value: "Retail", label: "Retail" },
    { value: "Consumer Goods", label: "Consumer Goods" },
    { value: "E-Commerce", label: "E-Commerce" },
    { value: "Automotive", label: "Automotive" },
    {
      value: "Construction & Real estate",
      label: "Construction & Real estate"
    },
    { value: "Information & Education", label: "Information & Education" },
    { value: "Insurance", label: "Insurance" },
    {
      value: "Banking & Financial services",
      label: "Banking & Financial services"
    },
    { value: "Healthcare services", label: "Healthcare services" },
    {
      value: "Life Sciences, Pharmaceuticals & Biotech",
      label: "Life Sciences, Pharmaceuticals & Biotech"
    },
    { value: "Travel & Hospitality", label: "Travel & Hospitality" },
    {
      value: "Mechanical and electrical engineering",
      label: "Mechanical and electrical engineering"
    },
    { value: "Chemicals, Oil & Power", label: "Chemicals, Oil & Power" },
    {
      value: "Manufacturing, Supply chain & Logistics",
      label: "Manufacturing, Supply chain & Logistics"
    },
    { value: "Transport", label: "Transport" },
    { value: "Telecommunications", label: "Telecommunications" },
    {
      value: "Technology, Software & Services",
      label: "Technology, Software & Services"
    },
    { value: "Textile", label: "Textile" },
    { value: "Media & Entertainment", label: "Media & Entertainment" },
    { value: "Utilities  ", label: "Utilities  " },
    { value: "Govt. & Public services", label: "Govt. & Public services" },
    {
      value: "Non Govt. Private Services",
      label: "Non Govt. Private Services"
    },
    { value: "Agriculture", label: "Agriculture" }
  ];

  const sortedIndustryArr = industryArr.sort((a, b) =>
    a.label > b.label ? 1 : -1
  );

  const sizeArr = [
    { value: "", label: "-None-" },
    { value: "Self Employed", label: "Self Employed" },
    { value: "Micro  1-10 employees", label: "Micro  1-10 employees" },
    { value: "Small  11-250 employees", label: "Small  11-250 employees" },
    { value: "Medium  251-500 employees", label: "Medium  251-500 employees" },
    { value: "Large  501-1000 employees", label: "Large  501-1000 employees" },
    {
      value: "Enterprise  >1,001 employees",
      label: "Enterprise  >1,001 employees"
    }
  ];

  const marks = [
    { value: 1, label: "<$50k" },
    { value: 2, label: "$50k-100K" },
    { value: 3, label: "$100k-200K" },
    { value: 4, label: ">$200k" }
  ];

  const handleIncomeChange = event => {

    marks.map(mark => {

      if (mark.value === event.target.value) {

        props.handleChange("income", mark.label);
      
      }
    
    });
  
  };

  const handleIncomeValue = value => {

    let newvalue;

    marks.map(mark => {

      if (mark.label === value) {

        newvalue = mark.value;
      
      }
    
    });
    
    return newvalue;
  
  };

  const details = props.details;

  return (
    <Grid id={ styles.maingrid2 }>
      <Grid container sx={ { marginTop: "15px" } }>
        <Grid item xs={ 12 } md={ 5 }>
          <Typography variant="body2" sx={ { color: "#393939" } }>
              To which racial or ethnic group would they most identify?
          </Typography>
        </Grid>
        <Grid item xs={ 12 } md={ 6 }>
          {/* <Select
              options={ethnicityArr}
              placeholder="Select ethnicity"
              value={
                details.ethnicity && {
                  value: details.ethnicity,
                  label: details.ethnicity,
                }
              }
              onChange={(e) => {
                props.handleChange("ethnicity", e.value);
              }}
            /> */}
          <CustomSelect
            options={ ethnicityArr }
            placeholder="Select ethnicity"
            value={ details.ethnicity }
            handleChange={ e => {

              props.handleChange("ethnicity", e.value);
            
            } }
            maxMenuHeight={ 150 }
          />
        </Grid>
      </Grid>
      <Grid container id={ styles.gridContainer }>
        <Grid item xs={ 12 } md={ 5 }>
          <Typography variant="body2" sx={ { color: "#393939" } }>
              What industry do they work in?
            <HelpIconTooltip
              tooltipdata={
                "Most applicable to B2B products, where industry dynamics and culture may correlate with your target users’ willingness to adopt a solution like yours, and what type of product design will work best for them. \nFor e.g. If you are designing a job portal app that is designed for blue and purple collar workforce in the manufacturing industry, then you likely have to design for someone who is less tech savvy and may prefer a mobile app over web app since they’re usually not stuck to a desk. Industry dynamics will also strongly factor into your pricing & marketing decisions. "
              }
              width="400px"
            />
          </Typography>
        </Grid>
        <Grid item xs={ 12 } md={ 6 }>
          {/* <Select
              options={[
                { value: "", label: "-None-" },
                ...sortedIndustryArr,
                { value: "Other", label: "Other" },
              ]}
              placeholder="Select industry"
              value={
                details.industry && {
                  value: details.industry,
                  label: details.industry,
                }
              }
              onChange={(e) => {
                props.handleChange("industry", e.value);
              }}
            /> */}
          <CustomSelect
            options={ [
              { value: "", label: "-None-" },
              ...sortedIndustryArr,
              { value: "Other", label: "Other" }
            ] }
            placeholder="Select industry"
            value={ details.industry }
            handleChange={ e => {

              props.handleChange("industry", e.value);
            
            } }
            maxMenuHeight={ 150 }
          />
        </Grid>
      </Grid>
      <Grid container id={ styles.gridContainer }>
        <Grid item xs={ 12 } md={ 5 }>
          <Typography variant="body2" sx={ { color: "#393939" } }>
              What is the size of their organization?
            <HelpIconTooltip
              tooltipdata={
                "Most applicable for B2B products or solutions where organization size could impact a target persona's role and motivations, how the organization makes purchasing decisions, and other such relevant insights. \nFor e.g. A large organization may be very process-driven with several functional hierarchies, and the persona’s role may have strict boundaries. "
              }
              width="400px"
            />
          </Typography>
        </Grid>
        <Grid item xs={ 12 } md={ 6 }>
          {/* <Select
              options={sizeArr}
              placeholder="Select organization size"
              value={
                details.size && {
                  value: details.size,
                  label: details.size,
                }
              }
              onChange={(e) => {
                props.handleChange("size", e.value);
              }}
            /> */}
          <CustomSelect
            options={ sizeArr }
            placeholder="Select organization size"
            value={ details.size }
            handleChange={ e => {

              props.handleChange("size", e.value);
            
            } }
          />
        </Grid>
      </Grid>
      <Grid container id={ styles.gridContainer }>
        <Grid item xs={ 12 } md={ 5 }>
          <Typography variant="body2" sx={ { color: "#393939" } }>
              What is their annual income?
            <HelpIconTooltip
              tooltipdata={
                "Most applicable for B2C products or solutions, where income level may often correlate with where they like to shop, where/how they learn about products, their aspirations, how they evaluate products, etc. \nThis could inform how you reach these customers, how you price your product, and how to position/ message your solutions to them."
              }
              width="400px"
            />
          </Typography>
        </Grid>
        <Grid item xs={ 12 } md={ 6 }>
          <Slider
            size="small"
            max={ 5 }
            defaultValue={ 3 }
            sx={ {
              "& .MuiSlider-rail": {
                backgroundColor: "grey !important"
              },
              "& .MuiSlider-mark": {
                backgroundColor: "grey !important",
                height         : "15px",
                width          : "1px"
              }
            } }
            step={ null }
            marks={ marks }
            track={ false }
            value={ handleIncomeValue(details.income) }
            onChange={ handleIncomeChange }
          />
        </Grid>
      </Grid>
      <Grid container id={ styles.gridContainer }>
        <Grid item xs={ 12 } md={ 5 }>
          <Typography variant="body2" sx={ { color: "#393939" } }>
              Other relevant demographic factors?
            {/* <HelpIconTooltip
                tooltipdata={
                  "Feel free to add any other applicable demographics details to suit your product or solution line."
                }
              /> */}
          </Typography>
        </Grid>
        <Grid item xs={ 12 } md={ 6 }>
          {/* <TextField
              multiline
              variant="outlined"
              maxRows={2}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-input.MuiInputBase-input": {
                  fontSize: "14px",
                },
              }}
              size="small"
              name="demographicfactors"
              value={details.demographicfactors}
              onChange={(e) => {
                props.handleChange(e.target.name, e.target.value);
              }}
            /> */}
          <CustomMultilineTextfield
            width={ "100%" }
            maxRows={ 2 }
            padding={ "8px 10px" }
            // fontSize={"14px"}
            name="demographicfactors"
            value={ details.demographicfactors }
            handledetailsChange={ e => {

              props.handleChange(e.target.name, e.target.value);
            
            } }
          />
        </Grid>
      </Grid>
      <Grid container id={ styles.gridContainer }>
        <Typography sx={ { fontSize: "11px", fontStyle: "italic" } }>
          *Answer all questions that could be relevant for your product or
          brand. It’s ok to skip questions you can’t answer at the moment. As
          you learn more about your target users, return to these pages to
          further develop the persona.
        </Typography>
      </Grid>
    </Grid>
  );

};

export default Demographics2;
