import React, { useState } from "react";

import {
  Box, IconButton, Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import Divider from "@mui/material/Divider";

import "./Dropdown.css";

export default function Dropdown (props) {

  const history = useHistory();
  const [ isActive, setIsActive ] = useState(false);

  const onClick = () => setIsActive(!isActive);

  let { user } = useSelector(state => {

    return { ...state };
  
  });

  return (
    <Box>
      <Box className="menuContainer">
        <IconButton
          onClick={ () => {

            onClick();
          
          } }
          onBlur={ () => {

            // onClick();

            setIsActive(false);
          
          } }
        >
          { user.profileIcon ?
            <img src={ user.profileIcon } id={ "profileImg" } />
            :
            <AccountCircleIcon fontSize="large" />
          }
        </IconButton>
        <Box className={ `menu ${isActive ? "active" : "inactive"}` }>
          <Box id="contentBox">
            <Box
              className="myProjects"
              onClick={ () => {

                history.push("/welcomepage");
                setIsActive(false);
              
              } }
            >
              <img
                className="iconImage"
                src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/myProjects.png` }
                alt=""
              />

              <Typography id="myProTitleTypo">My Projects</Typography>
            </Box>
            <Divider />
            <Box
              className="titleBox"
              // onClick={() => {
              //   history.push("/myprofile");
              //   setIsActive(false);
              // }}
              component="a"
              target="_blank"
              href="/myprofile"
              sx={ { textDecoration: "none" } }
            >
              <img
                className="iconImage"
                src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/myAccount.png` }
                alt=""
              />

              <Typography id="myAccountTypo">My Account</Typography>
            </Box>
            <Divider />
            <Box
              // onClick={() => {
              //   history.push("/feedback");
              //   setIsActive(false);
              // }}
              className="titleBox"
              component="a"
              target="_blank"
              href="/feedback"
              sx={ { textDecoration: "none" } }
            >
              <img
                className="iconImage"
                src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/giveFeedback.png` }
                alt=""
              />

              <Typography id="feedbackTypo">Give Feedback</Typography>
            </Box>
            <Divider />
            <Box
              // onClick={() => {
              //   history.push("/support");
              //   setIsActive(false);
              // }}
              component="a"
              target="_blank"
              href="/support"
              sx={ { textDecoration: "none" } }
              className="titleBox"
            >
              <img
                className="iconImage"
                src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/contactSupport.png` }
                alt=""
              />

              <Typography id="supportTypo">Contact Support</Typography>
            </Box>
            <Divider />
            <Box className="titleBox" onClick={ props.logout }>
              <img
                className="iconImage"
                src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/logoutDropdown.png` }
                alt=""
              />

              <Typography id="logoutTypo">Logout</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );

}
