import React, { useEffect } from "react";
import { Switch, Route, withRouter } from "react-router-dom";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import Header from "./pages/Header/Header";
import Landingpage from "./pages/LandingPage/LandingPage";
import Login from "./pages/Auth/Login/Login";
// import Register from "./pages/Auth/Register/Register";
// new register
import NewRegister from "./pages/Auth/NewRegister/NewRegister";
import ResendLink from "./pages/Auth/Register/ResendLink";
import ResendPage from "./pages/Auth/Register/ResendPage";
import RegisterDetails from "./pages/Auth/Register/RegisterDetails";
import WelcomePage from "./pages/WelcomePage/WelcomePage";
import ForgotPassword from "./pages/Auth/ForgotPassword/ForgotPassword";
import RedirectPage from "./pages/Auth/RedirectPage";
import ResetPassword from "./pages/Auth/ForgotPassword/ResetPassword";
import CreateVenture from "./pages/User/CreateVenture/CreateVenture";
// import ConceptVision from "./pages/User/ConceptVision/ConceptVision";
import ConceptVisionDetails from "./pages/User/ConceptVision/ConceptVisionDetails";
import ConceptVisionFinish from "./pages/User/ConceptVision/FinishPage";
import ModulesIntro from "./components/ModulesIntro";
import ModulesSummary from "./components/ModulesSummary";
import MarketFactsFigures from "./pages/User/Market/MarketFactsFigures/MarketFactsFigures";
import MarketCompetition from "./pages/User/Market/MarketCompetition/MarketCompetition";
import MarketSize from "./pages/User/Market/MarketSize/MarketSize";
import UserJourneyMap from "./pages/User/Customer/UserJourneyMap/UserJourneyMap";
import FlowchartUpload from "./pages/User/Customer/UserJourneyMap/FlowchartUpload";
import GTMIntro from "./pages/User/GTM/GTMIntro";
import GTMDashboard from "./pages/User/GTM/GTMDashboard";
import GTMSinglemodules from "./pages/User/GTM/GTMSinglemodules";
import GTMMultiplemodules from "./pages/User/GTM/GTMMultiplemodules";

import UserPersona from "./pages/User/Customer/TargetSegments/UserPersona";
import SegmentsPersonas from "./pages/User/Customer/TargetSegments/SegmentsPersonas";
import UserPersonaDetails from "./pages/User/Customer/TargetSegments/UserPersonaDetails";
import DigitalContent from "./pages/User/GTM/DigitalMarketing/DigitalContent";
import DigitalCampaign from "./pages/User/GTM/DigitalMarketing/DigitalCampaign";
import GTMContentCalendar from "./pages/User/GTM/DigitalMarketing/ContentCalendar";
import GTMCampaignCalendar from "./pages/User/GTM/DigitalMarketing/CampaignCalendar";
import Nutshell from "./pages/User/Solution/Nutshell";
import Defensibility from "./pages/User/Solution/Defensibility";
import PersonaSolutionMap from "./pages/User/Solution/PersonaSolutionMap";
import SolutionsGrid from "./pages/User/Solution/SolutionsGrid";
import Hypothesis from "./pages/User/Problem/Hypothesis";
import PersonaProblemMap from "./pages/User/Problem/PersonaProblemMap";
import UserInterviews from "./pages/User/Problem/UserInterviews";
import AffinityMapping from "./pages/User/Problem/AffinityMapping";
import StickyNotes from "./pages/User/Problem/StickyNotes/StickyNotes";
import PlOutlook from "./pages/User/Financials/PlOutlook/PlOutlook";
// import Financials from "./pages/User/Financials/FinancialsNavBar"
import CashFlow from "./pages/User/Financials/CashFlow/CashFlow";
import BalanceSheet from "./pages/User/Financials/BalanceSheet/BalanceSheet";
import InsightsDashboard from "./pages/User/Financials/InsightsDashboard/InsightsDashboard";
import FrOutlook from "./pages/User/Financials/FrOutlook/FrOutlook";
import UploadFinanceExcels from "./pages/User/Financials/PlOutlook/UploadFinanceExcels";
import GTMSummary from "./pages/User/GTM/GTMSummary";
import GTMBuyerJourney from "./pages/User/GTM/GTMBuyerJourney";
import PositioningStoryBoard from "./pages/User/GTM/Positioning/PositioningStoryBoard";
import BeforeAfterState from "./pages/User/GTM/Positioning/BeforeAfterState";
import PositioningResearch from "./pages/User/GTM/Positioning/Research";
import PositioningUsecases from "./pages/User/GTM/Positioning/Usecases";
import PositioningDifferentiation from "./pages/User/GTM/Positioning/Differentiation";
// import ProgressImage from "./pages/User/GTM/Positioning/ProgressImage";
import ProductPositioning from "./pages/User/GTM/Positioning/ProductPositioning";
import ProductMessaging from "./pages/User/GTM/Positioning/ProductMessaging";

import UserRoute from "./routes/UserRoute";

import { auth } from "./firebase";
import { useDispatch, useSelector } from "react-redux";
import { currentUser } from "./services/auth";

import "./App.css";

import OpMilestone from "./pages/User/Operations/Milestone/OpMilestone";
import OpTeam from "./pages/User/Operations/Team/OpTeam";
import OpBizOps from "./pages/User/Operations/BizOps/OpBizOps";
import Collaborators from "./pages/User/Collaborators/Collaborators";
import Dashboard from "./pages/User/Dashboard/Dashboard";

// new screens
// import MyAccount from "./pages/User/MyAccount/MyAccount";
import MyProfile from "./pages/User/MyAccount/MyProfile";
import LoginSecurity from "./pages/User/MyAccount/LoginSecurity";
import ForgotPasswordMyProfile from "./pages/User/MyAccount/ForgotPasswordMyProfile";
import ProfileResendLink from "./pages/User/MyAccount/ProfileResendLink";

// feedback

import Feedback from "./pages/User/FeedbackSupport/Feedback";
import Support from "./pages/User/FeedbackSupport/Support";

// gtm progress
import GtmPositioningProgress from "../src/pages/User/GTM/Positioning/GtmPositioningProgress";
import CollaborationLogin from "./pages/Auth/Login/CollaborationLogin";
// import { useMediaQuery } from "react-responsive";
import WarningMessage from "./components/WarningMessage";

// pitch deck

import PitchDeck from "./pages/User/PitchDeck/PitchDeck";

// pitch presentation

// import PitchPresentation from "./pages/User/PitchPresentation/PitchPresentation";

import PitchOnePagePresentation from "./pages/User/PitchPresentation/OnePageSharePresentation/OnePagePresentation";

// subscriptions

// import MySubscriptions from "./pages/User/MyAccount/MySubscriptions/MySubscriptions";
// import BillingSummary from "./pages/User/MyAccount/BillingSummary/BillingSummary";
// import BillingHistory from "./pages/User/MyAccount/BillingHistory/BillingHistory";
import PitchPresentationResponsive from "./pages/User/PitchPresentation/PitchPresentationResponsive/PitchPresentationResponsive";
import PitchPresentationResponsive2 from "./pages/User/PitchPresentation/PitchPresentationResponsive/PitchPresentationResponsive2";
// import Monetisation from "./pages/User/Monetisation/Monetisation";
import CommercialModel from "./pages/User/Monetisation/CommercialModel/CommercialModel";
import Channels from "./pages/User/Monetisation/CommercialModel/Channels/Channels";
import PartnershipDetails from "./pages/User/Monetisation/CommercialModel/PartnershipDetails/PartnershipDetails";
import BusinessGoals from "./pages/User/Monetisation/CommercialModel/BusinessGoals/BusinessGoals";
import MonetisationPricing from "./pages/User/Monetisation/Pricing/MonetisationPricing";

import CustomerWorth from "./pages/User/Monetisation/Pricing/CreateEditPricing/CustomerWorth";
import PricingModel from "./pages/User/Monetisation/Pricing/CreateEditPricing/PricingModel";
import PriceAnchors from "./pages/User/Monetisation/Pricing/CreateEditPricing/PriceAnchors";
import PriceSensitive from "./pages/User/Monetisation/Pricing/CreateEditPricing/PriceSensitive";
import PriceDiscrimination from "./pages/User/Monetisation/Pricing/CreateEditPricing/PriceDiscrimination";
import Costs from "./pages/User/Monetisation/Pricing/CreateEditPricing/Costs";
import ProposePricingPlan from "./pages/User/Monetisation/Pricing/CreateEditPricing/ProposePricingPlan";
import CreatePricingComponentNew from "./pages/User/Monetisation/Pricing/CreatePricingComponentNew";
import PitchPdfViewer from "./pages/User/PitchDeck/UploadPitchTest/PitchPdfViewer";
import PitchPdfPresentation from "./pages/User/PitchDeck/UploadPitchDeck/PitchPdfPresentation";


const theme = createTheme({
  palette: {
    error: {
      main: "#FD1515"
    },
    primary: { main: "#071AFF" }
  }
  // components: {
  //   MuiTextField: {
  //     variants: [
  //       // variants will help you define the props given by Mui and the styles you want to apply for it
  //       {
  //         props: { variant: "outlined", disabled: true },
  //         style: {
  //           backgroundColor: "lightgrey",
  //           // borderRadius: "10px"
  //         },
  //       },
  //     ],
  //   },
  // },
});

const App = ({ location }) => {

  const dispatch = useDispatch();

  const { venture } = useSelector(state => ({ ...state }));

  // const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1023px)" });
  const isTabletOrMobile = false;

  // to check firebase auth state
  useEffect(() => {

    const unsubscribe = auth.onAuthStateChanged(async user => {

      if (user) {

        let idTokenResult = await user.getIdTokenResult();
        
        let token = idTokenResult.token;

        // const expiresMS = new Date(idTokenResult.expirationTime).getTime();
        
        // if (expiresMS - Date.now() < 300000) {
        //   // if the token will expire in the next 5 minutes,
        //   // forcefully grab a fresh token
        //   token = await user.getIdToken(true);
        // }
        currentUser(venture._id)
          .then(res => {

            dispatch({
              type   : "LOGGED_IN_USER",
              payload: {
                firstname       : res.data.firstname,
                lastname        : res.data.lastname,
                email           : res.data.email,
                subscription    : res.data.subscription,
                token           : token,
                access          : res.data.access,
                profileIcon     : res.data.profileIcon,
                userVerified    : res.data.userVerified,
                organizationName: res.data.organizationName,
                location        : res.data.location,
                createdAt       : res.data.createdAt,
                _id             : res.data._id
              }
            });
          
          })
          .catch(err => {

            console.error(err.message);
          
          }
          );
      
      }
    
    });
    // cleanup

    return () => unsubscribe();
  
  }, [ dispatch ]);

  return (
    <>
      <ThemeProvider theme={theme}>
        {isTabletOrMobile &&
					!location.pathname.includes("/pitchpresentation") &&
					!location.pathname.includes("/pitchresponsive") &&
					!location.pathname.includes("/pitchpdfpresentation") ?
          <WarningMessage /> : <>
            {location.pathname !== "/" &&
							!location.pathname.includes("/pitchpresentation") &&
							!location.pathname.includes("/pitchresponsive") &&
							!location.pathname.includes("/pitch-one-page-presentation") &&
							!location.pathname.includes("/pitchpdfpresentation") &&
								<Header />
            }
            {/* {location.pathname !== "/" && <Header />} */}
            <Switch>
              <Route exact path="/" component={Landingpage} />
              <Route exact path="/login" component={Login} />
              <Route
                exact
                path="/collaborationlogin"
                component={CollaborationLogin}
              />
              {/* <Route exact path="/register" component={Register} /> */}
              <Route exact path="/register" component={NewRegister} />
              <Route exact path="/resendlink" component={ResendLink} />
              <Route exact path="/resendpage" component={ResendPage} />
              <Route
                exact
                path="/registerdetails"
                component={RegisterDetails}
              />
              <Route exact path="/forgotpassword" component={ForgotPassword} />
              <Route exact path="/resetpassword" component={ResetPassword} />
              <Route exact path="/redirect" component={RedirectPage} />

              <UserRoute exact path="/welcomepage" component={WelcomePage} />
              <UserRoute
                exact
                path="/createventure"
                component={CreateVenture}
              />
              <UserRoute
                exact
                path="/editventure/:id"
                component={CreateVenture}
              />
              <UserRoute
                exact
                path="/conceptvision"
                component={ConceptVisionDetails}
              />
              {/* <UserRoute
                exact
                path="/conceptvisiondetails"
                component={  }
              /> */}
              <UserRoute
                exact
                path="/editconceptvisiondetails"
                component={ConceptVisionDetails}
              />
              <UserRoute
                exact
                path="/venturefinish"
                component={ConceptVisionFinish}
              />
              <UserRoute exact path="/conceptvisionintro" component={ModulesIntro} />
              <UserRoute exact path="/marketintro" component={ModulesIntro} />
              <UserRoute
                exact
                path="/conceptvisionsummary"
                component={ModulesSummary}
              />
              <UserRoute
                exact
                path="/marketsummary"
                component={ModulesSummary}
              />
              <UserRoute
                exact
                path="/factsfigures"
                component={MarketFactsFigures}
              />
              <UserRoute
                exact
                path="/competition"
                component={MarketCompetition}
              />
              <UserRoute exact path="/marketsize" component={MarketSize} />
              <UserRoute exact path="/customerintro" component={ModulesIntro} />
              <UserRoute
                exact
                path="/customersummary"
                component={ModulesSummary}
              />
              <UserRoute
                exact
                path="/userjourneymaps"
                component={UserJourneyMap}
              />
              <UserRoute
                exact
                path="/flowchartupload"
                component={FlowchartUpload}
              />
              <UserRoute exact path="/gotomarketintro" component={GTMIntro} />
              <UserRoute
                exact
                path="/gotomarketcanvas"
                component={GTMDashboard}
              />
              <UserRoute
                exact
                path="/gtmobjectives"
                component={GTMSinglemodules}
              />
              <UserRoute
                exact
                path="/gtmchannels"
                component={GTMSinglemodules}
              />
              <UserRoute
                exact
                path="/gtmoutcomes"
                component={GTMSinglemodules}
              />
              <UserRoute
                exact
                path="/gtmcostbudget"
                component={GTMSinglemodules}
              />
              <UserRoute
                exact
                path="/gtmtargetpersonas"
                component={GTMMultiplemodules}
              />
              <UserRoute
                exact
                path="/gtmvalueproposition"
                component={GTMMultiplemodules}
              />
              <UserRoute
                exact
                path="/gtmpositioning"
                component={GTMMultiplemodules}
              />
              <UserRoute
                exact
                path="/gtmdigitalmarketing"
                component={GTMMultiplemodules}
              />
              <UserRoute
                exact
                path="/gtmsegmentspersonas"
                component={SegmentsPersonas}
              />
              <UserRoute exact path="/gtmuserpersona" component={UserPersona} />
              <UserRoute
                exact
                path="/userpersonadetails/:id"
                component={UserPersonaDetails}
              />
              <UserRoute
                exact
                path="/gtmuserpersonadetails/:id"
                component={UserPersonaDetails}
              />
              <UserRoute
                exact
                path="/segmentspersonas"
                component={SegmentsPersonas}
              />
              <UserRoute exact path="/userpersona" component={UserPersona} />
              <UserRoute
                exact
                path="/gtmdigitalcontent"
                component={DigitalContent}
              />
              <UserRoute
                exact
                path="/gtmdigitalcampaign"
                component={DigitalCampaign}
              />
              <UserRoute
                exact
                path="/gtmcontentcalendar"
                component={GTMContentCalendar}
              />
              <UserRoute
                exact
                path="/gtmcampaigncalendar"
                component={GTMCampaignCalendar}
              />
              <UserRoute exact path="/solutionintro" component={ModulesIntro} />
              <UserRoute
                exact
                path="/solutionsummary"
                component={ModulesSummary}
              />
              <UserRoute exact path="/inanutshell" component={Nutshell} />
              <UserRoute
                exact
                path="/defensibilitystrategy"
                component={Defensibility}
              />
              <UserRoute
                exact
                path="/personasolutionmap"
                component={PersonaSolutionMap}
              />
              <UserRoute exact path="/solutiongrid" component={SolutionsGrid} />
              <UserRoute exact path="/gtminanutshell" component={Nutshell} />
              <UserRoute
                exact
                path="/gtmpersonasolutionmap"
                component={PersonaSolutionMap}
              />
              <UserRoute
                exact
                path="/gtmdefensibilitystrategy"
                component={Defensibility}
              />
              <UserRoute exact path="/problemintro" component={ModulesIntro} />
              <UserRoute
                exact
                path="/problemsummary"
                component={ModulesSummary}
              />
              <UserRoute exact path="/hypothesis" component={Hypothesis} />
              <UserRoute
                exact
                path="/personaproblemmap"
                component={PersonaProblemMap}
              />
              <UserRoute
                exact
                path="/userinterviews"
                component={UserInterviews}
              />
              <UserRoute
                exact
                path="/analysissynthesis"
                component={AffinityMapping}
              />
              <UserRoute
                exact
                path="/analysissynthesisnotes"
                component={StickyNotes}
              />
              <UserRoute
                exact
                path="/financialsintro"
                component={ModulesIntro}
              />
              <UserRoute
                exact
                path="/financialssummary"
                component={ModulesSummary}
              />
              <UserRoute exact path="/ploutlook" component={PlOutlook} />
              <UserRoute exact path="/cashflow" component={CashFlow} />
              <UserRoute exact path="/insightsdashboard" component={InsightsDashboard} />
              <UserRoute exact path="/balancesheet" component={BalanceSheet} />
              <UserRoute exact path="/fundingrunway" component={FrOutlook} />
              <UserRoute
                exact
                path="/financeexcelsupload"
                component={UploadFinanceExcels}
              />
              <UserRoute
                exact
                path="/objectivessummary"
                component={GTMSummary}
              />
              <UserRoute exact path="/channelssummary" component={GTMSummary} />
              <UserRoute exact path="/outcomessummary" component={GTMSummary} />
              <UserRoute
                exact
                path="/costbudgetsummary"
                component={GTMSummary}
              />
              <UserRoute
                exact
                path="/buyerjourneysummary"
                component={GTMSummary}
              />
              <UserRoute
                exact
                path="/targetpersonassummary"
                component={GTMSummary}
              />
              <UserRoute
                exact
                path="/valuepropositionsummary"
                component={GTMSummary}
              />
              <UserRoute
                exact
                path="/positioningsummary"
                component={GTMSummary}
              />
              <UserRoute
                exact
                path="/digitalmarketingsummary"
                component={GTMSummary}
              />
              <UserRoute
                exact
                path="/gtmbuyerjourney"
                component={GTMBuyerJourney}
              />
              <UserRoute
                exact
                path="/gtmpositioningstory"
                component={PositioningStoryBoard}
              />
              <UserRoute
                exact
                path="/gtmpositioningbeforeafter"
                component={BeforeAfterState}
              />
              <UserRoute
                exact
                path="/gtmpositioningresearch"
                component={PositioningResearch}
              />
              <UserRoute
                exact
                path="/gtmpositioningusecases"
                component={PositioningUsecases}
              />
              <UserRoute
                exact
                path="/gtmpositioningdifferentiation"
                component={PositioningDifferentiation}
              />
              {/* ------------ */}
              <UserRoute
                exact
                path="/gtmpositioningprogressone"
                // component={ProgressImage}
                component={GtmPositioningProgress}
              />
              <UserRoute
                exact
                path="/gtmpositioningprogresstwo"
                // component={ProgressImage}
                component={GtmPositioningProgress}
              />
              {/* ------------ */}
              <UserRoute
                exact
                path="/gtmpositioninglongerdescription"
                component={ProductPositioning}
              />
              <UserRoute
                exact
                path="/gtmpositioningshorterdescription"
                component={ProductPositioning}
              />
              <UserRoute
                exact
                path="/gtmpositioningoneliner"
                component={ProductPositioning}
              />
              <UserRoute
                exact
                path="/gtmpositioningmessaging"
                component={ProductMessaging}
              />
              {/* changes */}
              <UserRoute
                exact
                path="/operationsintro"
                component={ModulesIntro}
              />
              <UserRoute exact path="/milestones" component={OpMilestone} />
              <UserRoute exact path="/team" component={OpTeam} />
              <UserRoute
                exact
                path="/businessoperations"
                component={OpBizOps}
              />
              <UserRoute
                exact
                path="/operationssummary"
                component={ModulesSummary}
              />

              {/* collaborators */}
              <UserRoute
                exact
                path="/collaborators"
                component={Collaborators}
              />

              {/* my profile */}
              <UserRoute exact path="/myprofile" component={MyProfile} />
              <UserRoute
                exact
                path="/loginsecurity"
                component={LoginSecurity}
              />
              <UserRoute
                exact
                path="/forgotpasswordprofile"
                component={ForgotPasswordMyProfile}
              />
              <UserRoute
                exact
                path="/profileresendlink"
                component={ProfileResendLink}
              />
              <UserRoute exact path="/dashboard" component={Dashboard} />

              {/* feedback and support */}
              <Route exact path="/feedback" component={Feedback} />
              <Route exact path="/support" component={Support} />

              {/* PitchDeck */}
              <UserRoute exact path="/pitchdeckintro" component={PitchDeck} />
              {/* pitch presentation */}
              <Route
                exact
                path="/pitchpresentation"
                component={PitchPresentationResponsive2}
              />
              <Route
                exact
                path="/pitch-one-page-presentation"
                component={PitchOnePagePresentation}
              />

              {/* subscriptions */}
              {/* <UserRoute
                exact
                path="/mysubscriptions"
                component={MySubscriptions}
              /> */}

              {/* billing summary */}
              {/* <UserRoute exact path="/billingsummary" component={BillingSummary} /> */}

              {/* billing history */}
              {/* <UserRoute exact path="/billinghistory" component={BillingHistory} /> */}
              {/* pitch responsive */}

              <Route
                exact
                path="/pitchresponsive"
                component={PitchPresentationResponsive}
              />
              <Route
                exact
                path="/pitchresponsive2"
                component={PitchPresentationResponsive2}
              />

              {/* Monetisation */}
              <UserRoute
                exact
                path="/monetisationintro"
                component={ModulesIntro}
              />
              <UserRoute
                exact
                path="/commercialmodel"
                component={CommercialModel}
              />
              {/* <UserRoute
                exact
                path="/monetisatiocommercialmodel"
                component={CommercialModel}
              /> */}
              <UserRoute exact path="/channels" component={Channels} />
              <UserRoute
                exact
                path="/partnershipdetails"
                component={PartnershipDetails}
              />
              <UserRoute
                exact
                path="/businessgoals"
                component={BusinessGoals}
              />
              <UserRoute
                exact
                path="/pricing"
                component={MonetisationPricing}
              />

              <UserRoute
                exact
                path="/customerworth"
                component={CustomerWorth}
              />
              <UserRoute exact path="/pricingmodel" component={PricingModel} />
              <UserRoute exact path="/priceanchors" component={PriceAnchors} />
              <UserRoute
                exact
                path="/pricesensitive"
                component={PriceSensitive}
              />
              <UserRoute
                exact
                path="/pricediscrimination"
                component={PriceDiscrimination}
              />
              <UserRoute exact path="/costs" component={Costs} />
              <UserRoute
                exact
                path="/proposepricingplan"
                component={ProposePricingPlan}
              />
              <UserRoute
                exact
                path="/createpricingcomponentnew"
                component={CreatePricingComponentNew}
              />
              <UserRoute
                exact
                path="/monetisationsummary"
                component={ModulesSummary}
              />
              <UserRoute exact path="/pitchpdf" component={PitchPdfViewer} />
              <Route
                exact
                path="/pitchpdfpresentation"
                component={PitchPdfPresentation}
              />
            </Switch>
          </>
        }
      </ThemeProvider>
    </>
  );

};

export default withRouter(App);
