import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box, Button,
  Dialog,
  DialogContent, IconButton, TextField, Typography
} from "@mui/material";
// import { useHistory } from "react-router-dom";
import PitchCompAdvDefensibility from "../PitchReferenceLinks/PitchCompAdvDefensibility";
import PitchCompAdvMarket from "../PitchReferenceLinks/PitchCompAdvMarket";
import ReferenceLinkTooltip from "../PitchReferenceLinks/ReferenceLinkTooltip";
import SectionHeader from "../SectionHeader";
import styles from "./CompetitiveAdvantage.module.css";

export default function CompetitiveAdvantage ({
  logoimg,
  slides,
  isRender,
  setSlides,
  isPublished,
  ...props
}) {

  const [ pitchCompAdvMarket, setPitchCompAdvMarket ] = useState(false);
  const [ pitchCompAdvDefensibility, setPitchCompAdvDefensibility ] =
    useState(false);

  const PitchCompAdvMarketDialogopen = () => {

    setPitchCompAdvMarket(true);
  
  };
  const PitchCompAdvMarketDialogclose = () => {

    setPitchCompAdvMarket(false);
  
  };
  const PitchCompAdvDefensibilityDialogopen = () => {

    setPitchCompAdvDefensibility(true);
  
  };
  const PitchCompAdvDefensibilityDialogclose = () => {

    setPitchCompAdvDefensibility(false);
  
  };

  const slide = slides[isRender];

  const handleChange = (name, value, columnindex) => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;

    let newstratergies = [ ...newSlides[isRender]["stratergies"] ];

    newstratergies.map((stratergy, stratergyindex) => {

      if (stratergyindex === columnindex) {

        newstratergies[stratergyindex][name] = value;
      
      }
    
    });
    newSlides[isRender]["stratergies"] = newstratergies;
    setSlides(newSlides);
  
  };

  const addStartergy = () => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    newSlides[isRender]["stratergies"].push({
      name: `Competitive Strategy #${newSlides[isRender]["stratergies"].length + 1
      }`,
      value: ""
    });
    setSlides(newSlides);
  
  };

  const removeStartergy = columnindex => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;

    let newstratergies = newSlides[isRender]["stratergies"].filter(
      (stratergy, stratergyindex) => stratergyindex !== columnindex
    );

    newSlides[isRender]["stratergies"] = newstratergies;

    setSlides(newSlides);
  
  };

  const handleDetailsChange = (name, value) => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    newSlides[isRender][name] = value;
    setSlides(newSlides);
  
  };

  // const history = useHistory();
  // const pathname = history.location.pathname.substring(1);

  return (
    <Box>
      <SectionHeader
        isEdit={ props.isEdit }
        Collapsecomment={ props.Collapsecomment }
        collapseCommentFunction={ props.collapseCommentFunction }
        mainHead={ slide.name }
        handleTitleChange={ handleDetailsChange }
        logoimg={ logoimg }
        isPublished={ isPublished }
      />
      <Box
        id={ styles.compAdvMainBox }
      >
        <Box
          id={ styles.imgBox }
        >
          <img
            id={ styles.img }
            crossOrigin="anonymous"
            src={ `${process.env.REACT_APP_AWS_ASSETS_URL
            }/images/pitchCompetitiveAdvantage.png?time=${new Date().valueOf()}` }
            alt="pitchCompetitiveAdvantage"
          />
        </Box>
        {/* ------ */ }
        <Box
          sx={ {
            width         : "75%",
            display       : "flex",
            justifyContent: "center",
            alignItems    : "center",
            flexWrap      : "wrap",
            columnGap     : "1vw",
            position      : "relative"
          } }
        >
          {/* -----1------- */ }
          { slide.stratergies.map((stratergy, stratergyindex) => {

            return (
              <Box sx={ { width: "43%", position: "relative" } }>
                <Box
                  id={ styles.compTypoBox }
                >
                  { props.isEdit ?
                    <Box sx={ { width: "100%" } }>
                      <TextField
  
                        value={ stratergy.name }
                        onChange={ e => {

                          handleChange("name", e.target.value, stratergyindex);
                        
                        } }
                        fullWidth
                        inputProps={ { maxLength: 30 } }
                        sx={ {
                          "& .MuiInputBase-input": {
                            fontSize  : "16px",
                            padding   : "1px 10px",
                            border    : "none",
                            // color: "#707070",
                            textAlign : "center",
                            color     : "#393939",
                            fontWeight: "bold"
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "2px dotted #B4B4FA"
                          }
                        } }
                      />
                    </Box>
                    :
                    <Typography
                      id={ styles.compTypo }
                    >
                      { stratergy.name }
                    </Typography>
                  }
                </Box>
                { props.isEdit ?
                  <TextField
                    value={ stratergy.value }
                    onChange={ e => {

                      handleChange("value", e.target.value, stratergyindex);
                    
                    } }
                    placeholder="A strategy you will pursue to differentiate your offering from alternatives in the market, and help customers choose you over others. "
                    fullWidth
                    multiline
                    inputProps={ { maxLength: 120 } }
                    maxRows={ 4 }
                    minRows={ 4 }
                    sx={ {
                      "& .MuiOutlinedInput-root": {
                        padding: "7px 10px"
                      },
                      "& .MuiInputBase-input": {
                        fontSize  : "14px",
                        color     : "#707070",
                        whiteSpace: "pre-line"
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "2px dotted #B4B4FA"
                      }
                    } }
                  />
                  :
                  <Box
                    sx={ {
                      width       : "100%",
                      border      : "1px solid #BEBEBE",
                      height      : "15vh",
                      borderRadius: "5px",
                      overflowY   : "auto",
                      display     : "flex",
                      alignItems  : "center"
                    } }
                  >
                    <Box
                      sx={ {
                        margin  : "7px 10px",
                        wordWrap: "break-word"
                      } }
                    >
                      <Typography
                        sx={ {
                          fontSize  : "1.5vh",
                          color     : "#707070",
                          lineHeight: "23px",
                          whiteSpace: "pre-line"
                        } }
                      >
                        { stratergy.value }
                      </Typography>
                    </Box>
                  </Box>
                }
                { props.isEdit && stratergyindex > 1 &&
                  <Box
                    sx={ {
                      width          : "15px",
                      height         : "15px",
                      border         : "1px solid #838383",
                      borderRadius   : "50%",
                      zIndex         : 20,
                      position       : "absolute",
                      right          : "-8px",
                      top            : "-8px",
                      display        : "flex",
                      justifyContent : "center",
                      alignItems     : "center",
                      backgroundColor: "#FFFFFF"
                    } }
                    onClick={ () => {

                      removeStartergy(stratergyindex);
                    
                    } }
                  >
                    <CloseIcon sx={ { fontSize: "15px", color: "#838383" } } />
                  </Box>
                }
              </Box>
            );
          
          }) }

          {/* add icon */ }
          { props.isEdit ?
            <Box
              sx={ {
                width         : "36px",
                height        : "36px",
                // border: 1,
                display       : "flex",
                justifyContent: "center",
                alignItems    : "center",
                borderRadius  : "50%",
                position      : "absolute",
                bottom        : "10px",
                right         : "-5px",
                backgroundColor:
                  slide.stratergies.length === 4 ? "#b0b0b0" : "#071AFF",
                cursor: slide.stratergies.length === 4 ? "default" : "pointer"
              } }
              onClick={ () => {

                if (slide.stratergies.length < 4) addStartergy();
              
              } }
            >
              <AddIcon sx={ { fontSize: "32px", color: "#FFFFFF" } } />
            </Box>
            :
            <></>
          }

          { props.isEdit ?
            <Box sx={ { position: "absolute", bottom: "0px", left: "40px" } }>
              <ReferenceLinkTooltip
                title={
                  "Revisit the notes created in Market module’s Competition section"
                }
              >
                <Button
                  style={ {
                    textTransform : "none",
                    textDecoration: "underline",
                    fontSize      : "12px",
                    padding       : "0px",
                    fontWeight    : "bold"
                  } }
                  onClick={ PitchCompAdvMarketDialogopen }
                >
                  Market
                </Button>
              </ReferenceLinkTooltip>
              <Dialog
                open={ pitchCompAdvMarket }
                onClose={ PitchCompAdvMarketDialogclose }
                PaperProps={ {
                  style: {
                    borderRadius   : "20px",
                    backdropFilter : "blur(100px)",
                    backgroundColor: "#ffffff",
                    boxShadow      : "0px 3px 6px #00000029",
                    width          : "70%",
                    height         : "80vh"
                  }
                } }
                maxWidth="false"
                fullWidth
              >
                <DialogContent sx={ { padding: "40px" } }>
                  <Box
                    sx={ {
                      width   : "100%",
                      position: "relative"
                    } }
                  >
                    <PitchCompAdvMarket />
                    <IconButton
                      onClick={ () => {

                        PitchCompAdvMarketDialogclose();
                      
                      } }
                      sx={ {
                        position: "absolute",
                        right   : "-40px",
                        top     : "-40px"
                      } }
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </Box>
                </DialogContent>
              </Dialog>
            </Box>
            :
            <></>
          }

          { props.isEdit ?
            <Box sx={ { position: "absolute", bottom: "0px", left: "130px" } }>
              <ReferenceLinkTooltip
                title={
                  "Revisit the notes created in Positioning module’s Defensibility section"
                }
              >
                <Button
                  style={ {
                    textTransform : "none",
                    textDecoration: "underline",
                    fontSize      : "12px",
                    padding       : "0px",
                    fontWeight    : "bold"
                  } }
                  onClick={ PitchCompAdvDefensibilityDialogopen }
                >
                  Defensibility
                </Button>
              </ReferenceLinkTooltip>

              <Dialog
                open={ pitchCompAdvDefensibility }
                onClose={ PitchCompAdvDefensibilityDialogclose }
                PaperProps={ {
                  style: {
                    borderRadius   : "20px",
                    backdropFilter : "blur(100px)",
                    backgroundColor: "#ffffff",
                    boxShadow      : "0px 3px 6px #00000029",
                    width          : "70%",
                    height         : "80vh"
                  }
                } }
                maxWidth="false"
                fullWidth
              >
                <DialogContent sx={ { padding: "40px" } }>
                  <Box
                    sx={ {
                      width   : "100%",
                      position: "relative"
                    } }
                  >
                    {/* <CoverEditVenture /> */ }
                    <PitchCompAdvDefensibility />
                    <IconButton
                      onClick={ () => {

                        PitchCompAdvDefensibilityDialogclose();
                      
                      } }
                      sx={ {
                        position: "absolute",
                        right   : "-40px",
                        top     : "-40px"
                      } }
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </Box>
                </DialogContent>
              </Dialog>
            </Box>
            :
            <></>
          }
        </Box>
        {/* -------- */ }
      </Box>
    </Box>
  );

}
