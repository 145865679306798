import {
  Box, Button,
  Select, TextField, Typography
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ErrorMessage from "../../../components/ErrorMessage";
import SuccessMessage from "../../../components/SuccessMessage";
import { sendSupportMail } from "../../../services/feedbacksupport";
import styles from "./Feedback.module.css";

const useStyles = makeStyles(() => ({
  customInput: {
    "& .MuiInputBase-input": {
      borderRadius: 4,
      fontSize    : 14,
      padding     : "6px 12px"
    }
  },
  singleInput: {
    "& .MuiInputBase-input": {
      borderRadius: 4,
      fontSize    : 14,
      padding     : "8px 12px"
    }
  },
  customSelect: {
    "& .MuiInputBase-input": {
      padding: "10px 26px 10px 12px"
    }
  },
  multilintCustomInput: {
    "& .MuiInputBase-input": {
      borderRadius: 4,
      fontSize    : 14,
      lineHeight  : "20px"
    },
    "& .MuiOutlinedInput-root": {
      padding: "12px"
    }
  }
}));

export default function Support () {

  const [ details, setDetails ] = useState({
    name   : "",
    email  : "",
    help   : "",
    subject: "",
    message: ""
  });
  const [ loading, setLoading ] = useState(false);
  const [ file, setFile ] = useState(null);
  const [ errormsg, setErrormsg ] = useState("");
  const [ successmsg, setSuccessmsg ] = useState("");
  const { user } = useSelector(state => ({ ...state }));

  const hiddenFileInput = useRef(null);

  const handleClick = () => {

    hiddenFileInput.current.click();
  
  };

  useEffect(() => {

    if (user.token) {

      setDetails(values => ({
        ...values,
        ["email"]: user.email,
        ["name"] : `${user.firstname} ${user.lastname}`
      }));
    
    }
  
  }, [ user ]);

  const handledetailsChange = event => {

    const name = event.target.name;
    const value = event.target.value.trimStart();

    setDetails(values => ({ ...values, [name]: value }));
  
  };

  const handleSubmit = async () => {

    if (
      details.email !== "" &&
      details.name !== "" &&
      details.help !== "" &&
      details.message !== "" &&
      details.subject !== ""
    ) {

      try {

        if (
          details.email &&
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(details.email)
        ) {

          setLoading(true);
          const formdata = new FormData();

          formdata.append("details", JSON.stringify(details));
          formdata.append("file", file);
          await sendSupportMail(formdata);

          setLoading(false);
          setSuccessmsg("Your support mail sent successfully!");
          setDetails({
            name   : "",
            email  : "",
            help   : "",
            subject: "",
            message: ""
          });
          setFile(null);
          setTimeout(() => {

            setSuccessmsg("");
          
          }, 5000);
        
        } else {

          setLoading(false);
          setErrormsg("Please enter a valid email ID.");
          setTimeout(() => {

            setErrormsg("");
          
          }, 5000);
        
        }
      
      } catch (error) {

        setLoading(false);
        setErrormsg("Something went wrong. Try again later");
        setTimeout(() => {

          setErrormsg("");
        
        }, 5000);
      
      }
    
    } else {

      setErrormsg("Please fill required (*) fields");
      setTimeout(() => {

        setErrormsg("");
      
      }, 5000);
    
    }
  
  };

  const classes = useStyles();
  let history = useHistory();

  return (
    <Box sx={ { height: "100%" } }>
      <Box id={ styles.mainBox }>
        {/* leftside Box */ }
        <Box id={ styles.leftBox }>
          <Box id={ styles.leftTypoBox }>
            <Typography id={ styles.supportLeftHeadTypo }>
              Do you have a<br></br> question?
            </Typography>
          </Box>
          <Box sx={ { marginLeft: "20px" } }>
            <img
              id={ styles.leftImg }
              src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/support-team.PNG` }
              alt=""
            />
          </Box>
        </Box>
        {/* right side box */ }
        <Box id={ styles.rightBox }>
          <Box id={ styles.mainContentBox }>
            {/* head typo box */ }
            <Box id={ styles.headTypoBox }>
              <Typography id={ styles.headTypo }>We are here to help!</Typography>
              <Box id={ styles.subHeadTypoBox }>
                <Typography id={ styles.subHeadTypo }>
                  <i>if you have suggestions to help us improve, please</i>
                </Typography>
                <Box id={ styles.hyperlinkTypoBox }>
                  <Typography
                    id={ styles.hyperlinkTypoSupport }
                    onClick={ () => {

                      history.push("/feedback");
                    
                    } }
                  >
                    { " " }
                    <i>Send us Feedback</i>
                  </Typography>
                </Box>
              </Box>
            </Box>

            {/* name email box */ }
            <Box id={ styles.nameEmailBox }>
              <Box id={ styles.nameBox }>
                <Box id={ styles.nameTypoBox }>
                  <Typography id={ styles.nameTypo }>Name*</Typography>
                </Box>
                <TextField
                  fullWidth
                  className={ classes.customInput }
                  name="name"
                  value={ details.name }
                  onChange={ handledetailsChange }
                  error={
                    errormsg ? details.name === "" ? true : false : false
                  }
                />
              </Box>
              <Box id={ styles.emailBox }>
                <Box id={ styles.emailTypoBox }>
                  <Typography id={ styles.emailTypo }>Email*</Typography>
                </Box>
                <TextField
                  fullWidth
                  className={ classes.customInput }
                  name="email"
                  value={ details.email }
                  onChange={ handledetailsChange }
                  error={
                    errormsg
                      ? details.email === "" ||
                        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                          details.email
                        )
                        ? true
                        : false
                      : false
                  }
                />
              </Box>
            </Box>

            {/* looking for help box */ }
            <Box id={ styles.forHelpBox }>
              <Typography id={ styles.forHelpTypo }>
                I am looking for help with*
              </Typography>

              <Box sx={ { marginTop: "10px" } }>
                <Select
                  fullWidth
                  placeholder="Select topic"
                  className={ classes.customSelect }
                  name="help"
                  value={ details.help }
                  onChange={ handledetailsChange }
                  error={
                    errormsg ? details.help === "" ? true : false : false
                  }
                >
                  <MenuItem value={ "Report a Bug" }>Report a Bug</MenuItem>
                  <MenuItem value={ "Website/ Blog COntent" }>
                    Website/ Blog Content
                  </MenuItem>
                  <MenuItem value={ "Enterprise Offers" }>
                    Enterprise Offers
                  </MenuItem>
                  <MenuItem value={ "Special Customization" }>
                    Special Customization
                  </MenuItem>
                  <MenuItem value={ "New Feature Request" }>
                    New Feature Request
                  </MenuItem>
                  <MenuItem value={ "Workshop/ Consulting Support" }>
                    Workshops/ Consulting
                  </MenuItem>
                  <MenuItem value={ "Accounts/ Billing" }>
                    Accounts/ Billing
                  </MenuItem>
                  <MenuItem value={ "Other" }>Other</MenuItem>
                </Select>
              </Box>
            </Box>

            {/* subject */ }

            <Box id={ styles.subjectBox }>
              {/* <Box> */ }
              <Typography id={ styles.subjectTypo }>Subject*</Typography>
              <Box id={ styles.textInputBox }>
                <TextField
                  fullWidth
                  className={ classes.singleInput }
                  name="subject"
                  value={ details.subject }
                  onChange={ handledetailsChange }
                  error={
                    errormsg ? details.subject === "" ? true : false : false
                  }
                />
              </Box>
              {/* </Box> */ }
            </Box>

            {/* message box */ }
            <Box id={ styles.msgBox }>
              {/* <Box> */ }
              <Typography id={ styles.msgTypo }>Message*</Typography>
              {/* </Box> */ }
              <TextField
                placeholder="Please share some details"
                fullWidth
                multiline
                maxRows={ 4 }
                minRows={ 4 }
                className={ classes.multilintCustomInput }
                name="message"
                value={ details.message }
                onChange={ handledetailsChange }
                error={
                  errormsg ? details.message === "" ? true : false : false
                }
              />
            </Box>
            {/* attachments box */ }

            <Box id={ styles.attachmentBox }>
              {/* <Box> */ }
              <Typography id={ styles.attachmentTypo }>
                Attachments (optional)
              </Typography>
              <Typography id={ styles.chooseFileSubTypo }>
                Attaching screenshot may help us diagnose the issue faster.
              </Typography>
              {/* </Box> */ }
              <Box id={ styles.chooseFileBox }>
                <Button
                  variant="outlined"
                  id={ styles.chooseFileBtn }
                  onClick={ handleClick }
                >
                  Choose file
                </Button>
                <input
                  type="file"
                  id="profileIcon"
                  ref={ hiddenFileInput }
                  onChange={ e => {

                    setFile(e.target.files[0]);
                  
                  } }
                  style={ { display: "none" } }
                  accept="image/*"
                />
                { file &&
                  <>
                    <span
                      style={ {
                        borderLeft: "1px solid #707070",
                        margin    : "0px 15px"
                      } }
                    />
                    <Box sx={ { display: "flex", alignItems: "center" } }>
                      <Typography sx={ { fontSize: "13px" } }>
                        { file.name }
                      </Typography>
                    </Box>
                  </>
                }
              </Box>
            </Box>
            { loading &&
              <Typography sx={ { textAlign: "center", fontWeight: "bold" } }>
                Sending...
              </Typography>
            }
            { errormsg && <ErrorMessage errormsg={ errormsg } /> }
            { successmsg && <SuccessMessage successmsg={ successmsg } /> }
            {/* submit cancel box */ }
            <Box id={ styles.submitCancelBox }>
              <Box id={ styles.submitBox }>
                <Button
                  id={ styles.submitButton }
                  variant="contained"
                  onClick={ handleSubmit }
                  disabled={ loading }
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );

}
