import React, { useEffect, useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box, Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import { getMonetisationDetails } from "../../../../services/monetisation";
import IndividualPricing from "../../Monetisation/Pricing/IndividualPricing";

export default function PitchBusinessModelMonetisation() {

  const [ revenueStreams, setRevenueStreams ] = useState([]);
  const { venture } = useSelector(state => ({ ...state }));

  useEffect(() => {

    fetchMonetisation();
  
  }, []);

  const fetchMonetisation = async () => {

    const response = await getMonetisationDetails(venture.monetisation);

    setRevenueStreams(response.data.revenueStreams);
  
  };

  return (
    <Box>
      <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
        <Box>
          <Typography
            sx={{ fontSize: "20px", color: "#5F5E5E", fontWeight: "bold" }}
          >
            Problem
          </Typography>
        </Box>
        <ChevronRightIcon sx={{ color: "gray" }} />
        <Box>
          <Typography>Hypothesis</Typography>
        </Box>
      </Box>

      <Box
        sx={{
          width       : "100%",
          display     : "flex",
          marginTop   : "10px",
          marginBottom: "10px"
        }}
      >
        <Box sx={{ width: "20%" }}>
          <Typography sx={{ color: "#5f5e5e", fontWeight: "bold" }}>
            Core Pricing Mantra:
          </Typography>
        </Box>
        <Box sx={{ width: "80%" }}>
          <Typography sx={{ color: "#868686", fontSize: "12px" }}>
            Customer Perceived Value should be net positive, i.e. Customer
            Perceived Benefits &gt; Customer Perceived Price, but not by too
            much (10-15%).
          </Typography>
        </Box>
      </Box>

      <Box sx={{ height: "50vh", overflow: "auto" }}>
        {revenueStreams.map((revenueStream, revenueStreamindex) => {

          return <IndividualPricing
            revenueStream={revenueStream}
            revenueStreamindex={revenueStreamindex}
          />;
        
        })}
      </Box>
    </Box>
  );

}
