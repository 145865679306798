import axios from "./axiosService";

// import axios from "axios";

export const getFinancials = async (financialsid, ventureid) => {

  return await axios.post(`${process.env.REACT_APP_ADDRESS}/get_financials`, {
    financialsid,
    ventureid
  });

};

export const updateFinancials = async (financialsid, details) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/update_financials`,
    {
      financialsid,
      details
    }
  );

};

export const updateCashFlow = (cashFlowId, table, parentVenture) => {

  return new Promise((resolve, reject) => {

    if (!cashFlowId) { return resolve({}); }
    if (!parentVenture) { return resolve({}); }

    axios.post(`${process.env.REACT_APP_ADDRESS}/update_cash_flow`, {
      cashFlowId,
      table,
      parentVenture
    }).then(response => {

      return resolve(response.data);

    }, reject);

  });

};

export const updateBalanceSheet = (balanceSheetId, table, parentVenture) => {

  return new Promise((resolve, reject) => {

    if (!balanceSheetId) { return resolve({}); }
    if (!parentVenture) { return resolve({}); }

    axios.post(`${process.env.REACT_APP_ADDRESS}/update_balance_sheet`, {
      balanceSheetId,
      table,
      parentVenture
    }).then(response => {

      return resolve(response.data);

    }, reject);


  });

};

export const updatePloutlook = async (ploutlookid, table, parentVenture) => {

  return await axios.post(`${process.env.REACT_APP_ADDRESS}/update_ploutlook`, {
    ploutlookid,
    table,
    parentVenture
  });

};

export const updateFroutlook = async (froutlookid, details, parentVenture) => {

  return await axios.post(`${process.env.REACT_APP_ADDRESS}/update_froutlook`, {
    froutlookid,
    details,
    parentVenture
  });

};

export const deleteExcel = async (index, financialsid) => {

  return await axios.post(`${process.env.REACT_APP_ADDRESS}/delete_excel`, {
    financialsid,
    index
  });

};

export const updateFinancialPloutlook = async (year, financialsid, parentVenture) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/update_financials_ploutlook`,
    {
      year,
      financialsid,
      parentVenture
    }
  );

};

export const createOrupdateFinancialCashFlow = (financialsid, parentVenture, cashFlowId, year) => {

  return new Promise((resolve, reject) => {

    if (!financialsid) { return resolve({}); }
    if (!parentVenture) { return resolve({}); }

    axios.post(`${process.env.REACT_APP_ADDRESS}/update_financials_cash_flow`, {
      financialsid,
      parentVenture,
      cashFlowId,
      year
    }).then(response => {

      return resolve(response?.data);

    }, reject);

  });

};

export const createOrupdateFinancialBalanceSheet = (financialsid, parentVenture, balanceSheetId, year) => {

  return new Promise((resolve, reject) => {

    if (!financialsid) { return resolve({}); }
    if (!parentVenture) { return resolve({}); }

    axios.post(`${process.env.REACT_APP_ADDRESS}/update_financials_balance_sheet`, {
      financialsid,
      parentVenture,
      balanceSheetId,
      year
    }).then(response => {

      return resolve(response?.data);

    }, reject);

  });

};

export const updateFinancialFroutlook = async (year, financialsid, parentVenture) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/update_financials_froutlook`,
    {
      year,
      financialsid,
      parentVenture

    }
  );

};
