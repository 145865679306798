/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  Typography,
  Box,
  IconButton
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";

// icons

// import ArrowRightIcon from "@mui/icons-material/ArrowRight";
// import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
// import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
// import InsertCommentRoundedIcon from "@mui/icons-material/InsertCommentRounded";
// import HighlightOffIcon from "@mui/icons-material/HighlightOff";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import jsondata from "../../../../Data/Market.json";
// import HelpIconTooltip from "../../../../components/HelpIconTooltip";
import ModuleSections from "../../../../components/ModuleSections";
import IndividualSectionHeaders from "../../../../components/IndividualSectionHeaders";
import TitleColumn from "./TitleColumn";
import styles from "./MarketCompetition.module.css";

import { makeStyles } from "@mui/styles";
import BackNextButton from "../../../../components/BackNextButton";
import ErrorMessage from "../../../../components/ErrorMessage";
import CustomMultilineTextfield from "../../../../components/CustomMultilineTextfield";
import {
  getMarketdetails,
  updateMarketDetails
} from "../../../../services/market";
import {
  createMarketComment,
  deleteMarketComment,
  updateMarketComment
} from "../../../../services/comment";
import { useUnmount } from "../../../../components/useUnmount";

const useStyles = makeStyles(() => ({
  customWidth: {
    maxWidth       : "200px",
    fontSize       : 10,
    backgroundColor: "white",
    color          : "#707070",
    boxShadow      : "0px 3px 6px #00000029",
    border         : "1px solid #707070",
    borderRadius   : 10,
    whiteSpace     : "pre-wrap"
  },
  helpicon: {
    color      : "#071AFF",
    fontSize   : "15px",
    paddingLeft: "5px"
  }
}));

const MarketCompetition = ({ history }) => {

  const [ details, setDetails ] = useState({
    marketPresence: {
      productOfferedToday: [],
      marketReach        : [],
      channels           : [],
      digitalPresence    : [],
      otherMarketPlayers : []
    },
    solutionOfferings: {
      technologyPlatform: [],
      uspValue          : [],
      customersLike     : [],
      customersNotLike  : []
    },
    businessHealth: {
      businessModel               : [],
      pricing                     : [],
      businessGoals               : [],
      historicalGrowth            : [],
      annualRevnues               : [],
      funding                     : [],
      subscribersBaseVsActiveUsage: [],
      growthStrategy              : []
    },
    competitiveThreat: {
      chances     : [],
      acquisitions: [],
      niches      : [],
      weaknesses  : [],
      predictions : []
    }
  });
  const [ comments, setComments ] = useState({
    marketPresence   : [],
    solutionOfferings: [],
    businessHealth   : [],
    competitiveThreat: []
  });
  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const [ dialogbox, setDialog ] = useState(false);
  const [ isdelete, setIsdelete ] = useState(false);
  const [ errormsg, setErrormsg ] = useState("");
  const [ isDataLoading, setIsDataLoading ] = useState(true);
  const refScrollUp = useRef();

  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);
    
    } else {

      setCollapsecomment(true);
    
    }
  
  };

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  const data = jsondata.marketcompetition;
  const guideicon = data[4];
  const classes = useStyles();
  // const dispatch = useDispatch();
  const gridref = useRef(null);

  const { user, venture } = useSelector(state => ({ ...state }));
  // console.log(venture);
  // let ventureid = venture._id;

  const hashvalue = Number(history.location.hash.substring(1));
  const content = data[hashvalue];
  // console.log(content);

  useEffect(() => {

    fetchMarketdetails();
  
  }, []);

  useEffect(() => {

    setErrormsg("");
  
  }, [ details ]);

  // To get the updated usestate value to save in useEffect cleanup
  const detailsRef = useRef(details);
  detailsRef.current = details;

  let isSubmitting = false;

  // useUnmount => custom hook to save while navigating
  useUnmount(() => {

    if ( isDataLoading == false && !isSubmitting) {

      handleSave();
    
    }
  
  });

  const handleSave = async () => {

    try {

      await updateMarketDetails(
        detailsRef.current,
        venture.market,
        "competition"
      );
      
      return true;
    
    } catch (error) {

      console.error(error);
      
      return false;
    
    }
  
  };

  const fetchMarketdetails = async () => {

    setIsDataLoading(true);

    try {

      const response = await getMarketdetails(venture.market);
      const market = response.data.market;
      setDetails(market.competition);
      setComments(market.comments.competition);
      setIsDataLoading(false);
    
    } catch (error) {

      console.error(error);
      setIsDataLoading(false);
    
    }
  
  };

  const handleAddColumn = async () => {

    const newDetails = { ...details };
    await Promise.all(
      Object.keys(details).map(detailskey => {

        Object.keys(details[detailskey]).map(individualdetailkey => {

          newDetails[detailskey][individualdetailkey].push({
            name : "",
            value: ""
          });
        
        });
      
      })
    );
    setDetails(newDetails);
    gridref.current.scrollLeft += gridref.current.scrollWidth;
  
  };

  const handleRemoveColumn = async columnindex => {

    const newDetails = { ...details };
    await Promise.all(
      Object.keys(details).map(detailskey => {

        Object.keys(details[detailskey]).map(individualdetailkey => {

          newDetails[detailskey][individualdetailkey] = details[detailskey][
            individualdetailkey
          ].filter((eachTitle, index) => index !== columnindex);
        
        });
      
      })
    );
    setDetails(newDetails);
    setIsdelete(false);
    gridref.current.scrollLeft += gridref.current.scrollWidth;
  
  };

  const handleTitleChange = (value, titleindex) => {

    const newDetails = { ...details };
    Object.keys(newDetails).map(detailskey => {

      Object.keys(newDetails[detailskey]).map(individualdetailkey => {

        newDetails[detailskey][individualdetailkey] = newDetails[detailskey][
          individualdetailkey
        ].map((eachTitle, index) => {

          if (index === titleindex) {

            return {
              ...eachTitle,
              ["name"]: value
            };
          
          } else {

            return eachTitle;
          
          }
        
        });
      
      });
    
    });
    setDetails(newDetails);
  
  };

  const handleChange = (name, value, titleindex) => {

    const newDetails = { ...details };
    Object.keys(details[content.name]).map(key => {

      if (name === key) {

        newDetails[content.name][key] = details[content.name][key].map(
          (eachTitle, index) => {

            if (index === titleindex) {

              return {
                ...eachTitle,
                ["value"]: value
              };
            
            } else {

              return eachTitle;
            
            }
          
          }
        );
      
      }
    
    });
    setDetails(newDetails);
  
  };

  const handleSubmit = async () => {

    try {

      isSubmitting = true;
      if(isDataLoading == false){

        await updateMarketDetails(
          details,
          venture.market,
          "competition"
        );

      }

      isSubmitting = false;
    
    } catch (error) {

      isSubmitting = false;
      console.error(error);
    
    }
  
  };

  const handleScrollUp = () => {

    refScrollUp.current.scrollTop = 0;
  
  };

  const addComment = async (commentText, replyTo) => {

    try {

      const response = await createMarketComment(
        commentText,
        user._id,
        "competition",
        content.name,
        venture.market,
        replyTo,
        window.location.href,
        "Market > Competition"
      );
      setComments(response.data.comments.competition);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateComment = async (commentid, commentText) => {

    try {

      const response = await updateMarketComment(
        commentText,
        user._id,
        commentid,
        venture._id,
        window.location.href,
        "Market > Competition"
      );
      setComments(response.data.comments.competition);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const deleteComment = async commentid => {

    try {

      const response = await deleteMarketComment(
        "competition",
        content.name,
        venture.market,
        commentid,
        user._id,
        window.location.href,
        "Market > Competition"
      );
      setComments(response.data.comments.competition);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  // console.log(details);

  return (
    <ModuleSections
      Collapsecomment={Collapsecomment}
      comments={comments[content.name]}
      addComment={addComment}
      deleteComment={deleteComment}
      updateComment={updateComment}>
      <div className="box-display-style-for-next-and-back-button-with-justify">
        <div className="box-indiv-header-con">
          <Box id={styles.box1}>
            {data
              .slice(0, 4)
              .map((eachdata, index) =>
                hashvalue === index ?
                  <h6 id={styles.barblue} />
                  :
                  <h6 id={styles.bar} />
                
              )}
          </Box>
          <IndividualSectionHeaders
            mainheading={"Market"}
            subheading={"Competition"}
            headingcontent={content.description}
            isGtm={false}
            Dialogopen={Dialogopen}
            Dialogclose={Dialogclose}
            dialogbox={dialogbox}
            collapse_comment_function={collapse_comment_function}
            content={guideicon}
            isSaveButton={true}
            handleSave={handleSave}
          />
        </div>
        <Grid
          // className="box-display-style-for-next-and-back-button"
          item
          xs={12}
          id={styles.maingrid}
          style={{ position: "relative" }}
          ref={refScrollUp}
          onClick={() => {

            setIsdelete(false);
          
          }}>
          <div style={{ position: "absolute", top: "0%", left: "37%" }}>
            {errormsg && <ErrorMessage errormsg={errormsg} />}
          </div>
          <Grid>
            {content.title === "Market Presence" &&
              <>
                <Tooltip
                  enterTouchDelay={0}
                  title="Remove Substitute"
                  disableFocusListener
                  placement="left-start"
                  classes={{ tooltip: classes.customWidth }}>
                  <IconButton
                    disabled={isdelete || venture.userRole === "Commenter"}
                    sx={{ padding: "0px", float: "right" }}
                    onClick={e => {

                      e.stopPropagation();
                      setIsdelete(true);
                    
                    }}>
                    <RemoveCircleIcon
                      sx={{
                        fontSize: "30px",
                        color:
                          isdelete || venture.userRole === "Commenter"
                            ? "#B0B0B0"
                            : "#ff7f5c"
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  enterTouchDelay={0}
                  title="Add Substitute"
                  disableFocusListener
                  placement="left-start"
                  classes={{ tooltip: classes.customWidth }}>
                  <IconButton
                    sx={{ padding: "0px", float: "right", marginRight: "20px" }}
                    onClick={handleAddColumn}
                    disabled={venture.userRole === "Commenter"}>
                    <AddCircleIcon
                      sx={{
                        fontSize: "30px",
                        color:
                          venture.userRole === "Commenter"
                            ? "#b0b0b0"
                            : "#071AFF"
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </>
            }

            <Grid container id={styles.gridcontainer} ref={gridref} rowGap={3}>
              <Grid item sx={{ display: "flex" }} columnGap={3}>
                <Box id={styles.firsttitle}>
                  <Typography>{content.title}</Typography>
                </Box>
                {details[content.name][content.questions[0].name].map(
                  (title, index) => {

                    return (
                      <TitleColumn
                        content={content}
                        title={title}
                        index={index}
                        isdelete={isdelete}
                        handleTitleChange={handleTitleChange}
                        handleRemoveColumn={handleRemoveColumn}
                      />
                    );
                  
                  }
                )}
              </Grid>
              {content.questions.map(eachQuestion => {

                return (
                  <Grid
                    item
                    sx={{ display: "flex", marginBottom: "10px" }}
                    columnGap={3}>
                    <Box id={styles.firstcolumn}>
                      <Typography id={styles.columntypography}>
                        {eachQuestion.question}
                        {eachQuestion.tooltip &&
                          <Tooltip
                            enterTouchDelay={0}
                            disableFocusListener
                            title={eachQuestion.tooltip}
                            placement={"right-start"}
                            classes={{ tooltip: classes.customWidth }}>
                            <IconButton
                              disableTouchRipple
                              disableFocusRipple
                              disableRipple
                              sx={{ padding: "0px" }}>
                              <HelpIcon className={classes.helpicon}></HelpIcon>
                            </IconButton>
                          </Tooltip>
                        }
                      </Typography>
                    </Box>
                    {details[content.name][eachQuestion.name].map(
                      (row, index) => {

                        return (
                          <Box id={styles.othertitles}>
                            <CustomMultilineTextfield
                              width="100%"
                              backgroundColor="white"
                              flex="0 0 300px"
                              // fontSize="14px"
                              maxRows={3}
                              minRows={3}
                              value={row.value}
                              name={eachQuestion.name}
                              handledetailsChange={e => {

                                handleChange(
                                  e.target.name,
                                  e.target.value,
                                  index
                                );
                              
                              }}
                              placeholder={""}
                            />
                          </Box>
                        );
                      
                      }
                    )}
                  </Grid>
                );
              
              })}
            </Grid>
          </Grid>
        </Grid>

        <div className="ttribe-back-next-button-con">
          <BackNextButton
            restrictedBackClick={() => {

              if (hashvalue) {

                history.push({ hash: `${hashvalue - 1}` });
                handleScrollUp();
              
              } else {

                // handleSubmit();
                history.push({ pathname: "/factsfigures", hash: "tabThree" });
              
              }
            
            }}
            backClick={() => {

              if (hashvalue) {

                history.push({ hash: `${hashvalue - 1}` });
                handleScrollUp();
              
              } else {

                handleSubmit();
                history.push({ pathname: "/factsfigures", hash: "tabThree" });
              
              }
            
            }}
            restrictedNextClick={async () => {

              if (hashvalue === 3) {

                // handleSubmit();
                history.push("/marketsize");
              
              } else {

                if (content.title === "Market Presence") {

                  let iserror = false;
                  Object.keys(details[content.name]).map(
                    (individualdetailkey, index) => {

                      if (individualdetailkey === "comments") return;
                      details[content.name][individualdetailkey].map(
                        (column, columnindex) => {

                          if (column.value !== "" && column.name === "") {

                            iserror = true;
                            setErrormsg(
                              "Please enter competitor name before you can continue."
                            );
                          
                          }
                          if (
                            index ===
                              Object.keys(details[content.name]).length - 2 &&
                            columnindex ===
                              details[content.name][individualdetailkey]
                                .length -
                                1 &&
                            !iserror
                          ) {

                            // console.log("entered...");
                            // handleSubmit();
                            history.push({ hash: `${hashvalue + 1}` });
                            handleScrollUp();
                          
                          }
                        
                        }
                      );
                    
                    }
                  );
                
                } else {

                  // handleSubmit();
                  history.push({ hash: `${hashvalue + 1}` });
                  handleScrollUp();
                
                }
              
              }
            
            }}
            nextClick={async () => {

              if (hashvalue === 3) {

                handleSubmit();
                history.push("/marketsize");
              
              } else {

                if (content.title === "Market Presence") {

                  let iserror = false;
                  Object.keys(details[content.name]).map(
                    (individualdetailkey, index) => {

                      if (individualdetailkey === "comments") return;
                      details[content.name][individualdetailkey].map(
                        (column, columnindex) => {

                          if (column.value !== "" && column.name === "") {

                            iserror = true;
                            setErrormsg(
                              "Please enter competitor name before you can continue."
                            );
                          
                          }
                          if (
                            index ===
                              Object.keys(details[content.name]).length - 2 &&
                            columnindex ===
                              details[content.name][individualdetailkey]
                                .length -
                                1 &&
                            !iserror
                          ) {

                            // console.log("entered...");
                            handleSubmit();
                            history.push({ hash: `${hashvalue + 1}` });
                            handleScrollUp();
                          
                          }
                        
                        }
                      );
                    
                    }
                  );
                
                } else {

                  handleSubmit();
                  history.push({ hash: `${hashvalue + 1}` });
                  handleScrollUp();
                
                }
              
              }
            
            }}
          />
        </div>
      </div>
    </ModuleSections>
  );

};

export default MarketCompetition;
