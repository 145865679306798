import { Box, Typography } from "@mui/material";
import React from "react";

import { makeStyles } from "@mui/styles";
import CustomMultilineTextfield from "../../../../components/CustomMultilineTextfield";
import HelpIconTooltip from "../../../../components/HelpIconTooltip";

const useStyles = makeStyles(() => ({
  mainbox: {
    margin: "0px 60px"
  },
  question: {
    fontWeight: "bold"
    // marginBottom: "20px",
  },
  textfield: {
    width       : "100%",
    marginBottom: "50px"
    // "& .MuiOutlinedInput-root": {
    //   padding: "15px 0px",
    // },
    // "& .MuiOutlinedInput-notchedOutline": {
    //   border: "none",
    // },
  }
}));

const EditBeforeAfterState = props => {

  const classes = useStyles();

  
  return (
    <Box className={ classes.mainbox }>
      <Box
        sx={ {
          display   : "flex",
          alignItems: "center",

          marginBottom: "10px"
        } }
      >
        <Typography className={ classes.question }>{ props.question }</Typography>
        {/* <IconButton
          sx={{ padding: "0px", marginLeft: "10px" }}
          onClick={props.helpDialogopen}
        >
          <HelpIcon sx={{ fontSize: "16px", color: "#071aff" }}></HelpIcon>
        </IconButton> */}
        <HelpIconTooltip tooltipdata={ props.dialog } />
      </Box>

      {/* <TextField
        className={classes.textfield}
        autoFocus
        onFocus={(e) => {
          let val = e.target.value;
          e.target.value = "";
          e.target.value = val;
        }}
        multiline
        maxRows={10}
        minRows={10}
        value={props.value}
        name={props.name}
        onChange={(e) => {
          props.handleChange(e.target.value, e.target.name);
        }}
      /> */}
      <Box sx={ { marginBottom: "50px" } }>
        <CustomMultilineTextfield
          maxRows={ 10 }
          minRows={ 10 }
          autoFocus={ "true" }
          width={ "100%" }
          value={ props.value }
          name={ props.name }
          handledetailsChange={ e => {

            props.handleChange(e.target.value, e.target.name);
          
          } }
          onFocus={ e => {

            let val = e.target.value;

            e.target.value = "";
            e.target.value = val;
          
          } }
        />
      </Box>
    </Box>
  );

};

export default EditBeforeAfterState;
