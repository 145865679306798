import axios from "./axiosService";

// import axios from "axios";

export const sendVerificationEmail = async (email, mailConfig) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/sendVerificationEmail`,
    { email, mailConfig }
  );

};

export const createOrUpdateUser = async details => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/create-or-update-user`,
    { details }
  );

};

export const UpdateUserDetails = async details => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/update-user-details`,
    { details }
  );

};

export const currentUser = async ventureid => {

  return await axios.post(`${process.env.REACT_APP_ADDRESS}/current-user`, {
    ventureid
  });

};

export const userVerified = async email => {

  return await axios.post(`${process.env.REACT_APP_ADDRESS}/user_verified`, {
    email
  });

};

export const getUserById = async userid => {

  return await axios.get(`${process.env.REACT_APP_ADDRESS}/get_user/${userid}`);

};

export const inviteCollaborator = async (email, ventureId, access, userid) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/user_collaborator`,
    { email, ventureId, access, userid }
  );

};

export const updateCollaboratorAccess = async (
  key,
  value,
  ventureid,
  userid,
  isSendmail
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/change_collaborator_access`,
    { ventureid, key, value, userid, isSendmail }
  );

};

export const deleteCollaboratorAccess = async (
  ventureid,
  userid,
  parentuserid,
  isSendmail
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/delete_collaborator`,
    { ventureid, userid, parentuserid, isSendmail }
  );

};

export const deleteUserProfileIcon = async userid => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/delete_user_profileicon`,
    { userid }
  );

};

export const uploadUserProfileIcon = async formdata => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/upload_user_profileicon`,
    formdata
  );

};

export const updateUserSharedVenture = (userId, sharedVentureId, isHide ) => {

  return new Promise((resolve, reject) => {

    if (!userId) { return resolve({}); }
    // if (!parentVenture) { return resolve({}) }
    if (!sharedVentureId) { return resolve({}); }

    axios.post(`${process.env.REACT_APP_ADDRESS}/update_user_shared_venture`, {
      userId,
      sharedVentureId,
      isHide
    }).then(response => {

      return resolve(response.data);

    }, reject);

  });

};

export const getBuisnessPlanSummary = ventureId => {

  return new Promise((resolve, reject) => {

    if (!ventureId) { return resolve({}); }


    axios.post(`${process.env.REACT_APP_ADDRESS}/get-buisness-plan-summary`, {
      ventureId
    }).then(response => {

      return resolve(response.data);

    }, reject);

  });

};

export const exportBuisnessPlanSummary = (ventureId, selectedYear) => {

  return new Promise((resolve, reject) => {

    // if (!ventureId) { return resolve({}); }

    axios.post(`${process.env.REACT_APP_ADDRESS}/export-buisness-plan-summary`, {
      ventureId,
      selectedYear
    }).then(response => {

      return resolve(response.data);

    }, reject);

  });

};
