import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box, Dialog,
  DialogContent, Grid, IconButton, Menu,
  MenuItem, Typography
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import CustomMultilineTextfield from "../../../../components/CustomMultilineTextfield";
import DeleteCancel from "../../../../components/DeleteCancel";
import HelpIconTooltip from "../../../../components/HelpIconTooltip";
import styles from "./BizOps.module.css";

const BizOp = props => {

  const [ deletedialogbox, setDeleteDialog ] = useState(false);
  const [ anchorEl, setAnchorEl ] = useState(null);

  const { venture } = useSelector(state => ({ ...state }));
  const open = Boolean(anchorEl);

  const handleClick = event => {

    setAnchorEl(event.currentTarget);
  
  };
  const handleClose = () => {

    setAnchorEl(null);
  
  };

  const deleteDialogopen = () => {

    setDeleteDialog(true);
  
  };
  const deleteDialogclose = () => {

    setDeleteDialog(false);
  
  };

  const { bizop, bizopindex } = props;

  
  return (
    <Box
      sx={ {
        display     : "flex",
        alignItems  : "center",
        marginBottom: "30px"
        // border: 1,
      } }
    >
      {/* {isdelete && (
        <Box>
          <IconButton
            sx={{
              padding: "0px",
              marginBottom: "16px",
              marginRight: "10px",
            }}
            onClick={(e) => {
              e.stopPropagation();
              deleteDialogopen();
            }}
          >
            <RemoveCircleIcon
              fontSize="small"
              sx={{
                color: "#ff7f5c",
              }}
            />
          </IconButton>
          <Dialog
            open={deletedialogbox}
            PaperProps={{
              style: {
                borderRadius: "20px",
                backdropFilter: "blur(45px)",
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                boxShadow: "0px 3px 6px #00000029",
                border: "1px solid #E0E1E2",
              },
            }}
          >
            <DialogContent>
              <DeleteCancel
                name={"Are you sure you want to delete this bizops?"}
                deleteClick={(e) => {
                  e.stopPropagation();
                  props.removeRow(bizopindex);
                  deleteDialogclose();
                }}
                cancelClick={() => {
                  deleteDialogclose();
                }}
              />
            </DialogContent>
          </Dialog>
        </Box>
      )} */}

      <Box sx={ { marginTop: "2%" } }>
        <IconButton
          sx={ {
            padding    : "0px",
            // marginBottom: "16px",
            marginRight: "10px"
          } }
          // onClick={(e) => {
          //   e.stopPropagation();
          //   deleteDialogopen();
          // }}
          onClick={ e => {

            handleClick(e);
          
          } }
          disabled={ venture.userRole === "Commenter" }
        >
          {/* <RemoveCircleIcon
            fontSize="small"
            sx={{
              color: "#ff7f5c",
            }}
          /> */}
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={ anchorEl }
          open={ open }
          onClose={ () => {

            handleClose();
          
          } }
          anchorOrigin={ {
            vertical  : "top",
            horizontal: "right"
          } }
          style={ { top: -2 } }
          sx={ {
            "& .MuiMenu-list": {
              padding        : "0px",
              backgroundColor: "#707070",
              color          : "white"
            }
          } }
        // transformOrigin={{
        //   vertical: 'top',
        //   horizontal: 'start',
        // }}
        >
          <MenuItem
            sx={ { fontSize: "12px" } }
            onClick={ e => {

              e.stopPropagation();
              deleteDialogopen();
              handleClose();
            
            } }
          >
            Delete
          </MenuItem>
        </Menu>
        <Dialog
          open={ deletedialogbox }
          PaperProps={ {
            style: {
              borderRadius  : "20px",
              backdropFilter: "blur(45px)",
              background    : "#FFFFFF 0% 0% no-repeat padding-box",
              boxShadow     : "0px 3px 6px #00000029",
              border        : "1px solid #E0E1E2"
            }
          } }
        >
          <DialogContent>
            <DeleteCancel
              name={ "Are you sure you want to delete this bizops?" }
              deleteClick={ e => {

                e.stopPropagation();
                props.removeRow(bizopindex);
                deleteDialogclose();
              
              } }
              cancelClick={ () => {

                deleteDialogclose();
              
              } }
            />
          </DialogContent>
        </Dialog>
      </Box>
      <Grid container xs={ 12 } index={ bizopindex }>
        <Grid item xs={ 12 }>
          <Typography id={ styles.titleTypo }>
            { bizop.key }
            { bizop.tooltipData &&
              <HelpIconTooltip
                width={ "400px" }
                tooltipdata={ bizop.tooltipData }
              />
            }
          </Typography>
        </Grid>
        <Grid item xs={ 12 }>
          <CustomMultilineTextfield
            width="100%"
            borderRadius="6px"
            fontSize="0.75em"
            maxRows={ 4 }
            minRows={ 4 }
            name={ bizop.key }
            value={ bizop.value }
            handledetailsChange={ e => {

              props.handleTextChange(e.target.value, bizopindex);
            
            } }
            border={ "1px solid #E8E8E8" }
          />
        </Grid>
      </Grid>
    </Box>
  );

};

export default BizOp;
