/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import {
  Box, Button, Grid, Typography
} from "@mui/material";
import IndividualSectionHeaders from "../../../../components/IndividualSectionHeaders";
import ModuleSections from "../../../../components/ModuleSections";
import IndividualPricing from "./IndividualPricing";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BackNextButton from "../../../../components/BackNextButton";
import {
  createMonetisationComment,
  deleteMonetisationComment,
  updateMonetisationComment
} from "../../../../services/comment";
import { getMonetisationDetails } from "../../../../services/monetisation";
import styles from "./MonetisationPricing.module.css";

export default function MonetisationPricing() {

  // eslint-disable-next-line no-unused-vars
  const [ isexpand, setIsexpand ] = useState(false);
  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const [ dialogbox, setDialog ] = useState(false);
  // const [ pricing, setPricing ] = useState([]);
  const [ revenueStreams, setRevenueStreams ] = useState([]);
  const [ comments, setComments ] = useState([]);

  const { user, venture } = useSelector(state => ({ ...state }));

  useEffect(() => {

    fetchMonetisation();
  
  }, []);

  const fetchMonetisation = async () => {

    const response = await getMonetisationDetails(venture.monetisation);

    setRevenueStreams(response.data.revenueStreams);
    setComments(response.data.comments.pricing.revenueStreams);
  
  };

  // console.log(revenueStreams);

  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);
    
    } else {

      setCollapsecomment(true);
    
    }
  
  };

  const history = useHistory();

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  // console.log(pricing);

  const addComment = async (commentText, replyTo) => {

    try {

      const response = await createMonetisationComment(
        commentText,
        user._id,
        "pricing",
        "revenueStreams",
        venture.monetisation,
        replyTo,
        window.location.href,
        "Monetisation > Pricing"
      );

      setComments(response.data.comments.pricing.revenueStreams);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateComment = async (commentid, commentText) => {

    try {

      const response = await updateMonetisationComment(
        commentid,
        commentText,
        user._id,
        venture._id,
        window.location.href,
        "Monetisation > Pricing"
      );

      setComments(response.data.comments.pricing.revenueStreams);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const deleteComment = async commentid => {

    try {

      const response = await deleteMonetisationComment(
        user._id,
        commentid,
        venture.monetisation,
        "pricing",
        "revenueStreams",
        window.location.href,
        "Monetisation > Pricing"
      );

      setComments(response.data.comments.pricing.revenueStreams);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  return (
    <ModuleSections
      isexpand={isexpand}
      Collapsecomment={Collapsecomment}
      comments={comments}
      addComment={addComment}
      deleteComment={deleteComment}
      updateComment={updateComment}
    >
      <div className="box-display-style-for-next-and-back-button-with-justify">
        <div className="box-indiv-header-con">
          <IndividualSectionHeaders
            mainheading={"Monetisation"}
            subheading={"Pricing"}
            headingcontent={""}
            isGtm={false}
            Dialogopen={Dialogopen}
            Dialogclose={Dialogclose}
            dialogbox={dialogbox}
            collapse_comment_function={collapse_comment_function}
            isexpand={isexpand}
            content={{
              dialogpurposetitle: "What are we doing in this section?",
              dialogpurpose:
                  "With the previous section you got clarity on what your revenue streams will be, and how your customers will procure your product or service. Now you need to define a pricing model, with specific price points, by taking into consideration various factors, such as, your product’s value to the customer, your competitors, general market & economy trends, et cetera.\nFor this section, we’ve designed a neat and simple pricing wizard, where each step will help seed your brain with important inputs. Start with some hypothesis – i.e. pricing model & price points you think might work, then as you gather a bit more insight or data with each step in the wizard, refine this hypothesis. By the end of the wizard, you should feel comfortable enough to jot down a pricing model with either specific price points, or price ranges that you may want to test in the market.  ",
              dialoganalogytitle:
                  "Why do I need to go through this exercise?",
              dialoganalogy:
                  "Many companies spend little effort on pricing, assigning pricing to marketing.  Marketing is usually focused on enhancing demand, rather than maximizing profits through price.  Yet, the return from pricing investment on a venture’s profitability often exceeds returns seen from marketing or product design investments.  If you’re in a lower-margin businesses, pricing becomes more crucial since a small change in price can have a significant impact on profitability. If you’re in a high-margin business, it becomes important to know if and how you’ll sustain that in the long-run and maintain high customer loyalty.\nPricing is both a science and an art. The longer you are in business, the more data-driven you can get with your pricing. Be prepared to go through multiple iterations as you learn more about the market or get direct customer feedback. Return to the pricing wizard as often as you need to help you review and refresh the fundamental inputs that influenced your pricing decision.",
              dialogguide: []
            }}
          />
        </div>

        <Grid item xs={12} id={styles.pricingMainGrid}>
          <Box sx={{}}>
      
            {/* pricing mantra typo */}
            <Box id={styles.pricingMantraMainBox}>
              <Box id={styles.pricingMantraTypoBox}>
                <Typography id={styles.pricingMantraTypo}>
                Core Pricing Mantra:
                </Typography>
              </Box>
              <Box id={styles.mantraDescriptionBox}>
                <Typography id={styles.mantraDescriptionTypo}>
                Customer Perceived Value should be net positive, i.e. Customer
                Perceived Benefits &gt; Customer Perceived Price, but not by too
                much (10-15%).
                </Typography>
              </Box>
            </Box>

            {/* mapping content main box */}
            <Box
              sx={{
                height:
                revenueStreams.length > 0
                  ? revenueStreams.length === 1
                    ? "35vh"
                    : ""
                  : "30vh"
              }}
            >
              {revenueStreams.map((revenueStream, revenueStreamindex) => {

                return<IndividualPricing
                  revenueStream={revenueStream}
                  revenueStreamindex={revenueStreamindex}
                />;
              
              })}
            </Box>

            {/* button box */}
            <Box id={styles.buttonBox}>
              <Button
                id={styles.addRevenueStreamButton}
                onClick={() => {

                  history.push("/commercialmodel");
                
                }}
                disabled={venture.userRole === "Commenter" ? true : false}
              >
              Add/Edit Revenue Streams
              </Button>
            </Box>

            <Box sx={{}}></Box>

          </Box>
        </Grid>

        <div className="ttribe-back-next-button-con">

          {/* <NextButton /> */}
          <BackNextButton
            restrictedBackClick={() => {

              history.push("/businessgoals");
            
            }}
            backClick={() => {

              history.push("/businessgoals");
            
            }}
            restrictedNextClick={() => {

              history.push("/monetisationsummary");
            
            }}
            nextClick={() => {

              history.push("/monetisationsummary");
            
            }}
          />
        </div>
      </div>
     
    </ModuleSections>
  );

}
