import { IconButton, InputBase, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";

import AddItem from "./AddItem";
import InputCard from "./InputCard";
import midString from "./ordering";
import Card from "./StickyNotesCard";

import {
  createNewCard, deleteListById, updateListById
} from "../../../../services/stickynotes";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const useStyles = makeStyles(theme => ({
  root: {
    width          : "100%",
    backgroundColor: "#EBECF0",
    marginLeft     : theme.spacing(1),
    wordWrap       : "break-word"
  },
  scroll: {
    maxHeight             : "500px",
    overflow              : "auto",
    overflowX             : "hidden",
    // overflowY: 'auto',
    margin                : 0,
    padding               : 0,
    listStyle             : "none",
    height                : "100%",
    "&::-webkit-scrollbar": {
      width: "0.4em"
    },
    "&::-webkit-scrollbar-track": {
      boxShadow      : "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline        : "1px solid green"
    }
  },
  title: {
    padding   : theme.spacing(1, 1, 1, 1),
    marginLeft: theme.spacing(1.5),
    fontWeight: "bold"
  },
  wrapper: {
    flex       : props => props.list ? "0 0 300px" : "0 0 0px",
    marginRight: props => props.list ? theme.spacing(2) : "0px",
    marginTop  : theme.spacing(1)
  },
  editable: {
    marginLeft  : theme.spacing(-1),
    wordWrap    : "break-word",
    padding     : theme.spacing(0, 1, 0, 1),
    boxShadow   : "inset 0 0 0 2px #0079bf",
    // width: "100%",
    borderRadius: 4
  }
}));

const StickyNotesList = ({ column, tasks, index, fetchListsCards }) => {

  const [ cardContent, setCardContent ] = useState("");
  const [ listTitle, setListTitle ] = useState(column.name);
  const [ addCardFlag, setAddCardFlag ] = useState(false);
  const [ editable, setEditable ] = useState(false);
  const [ list, setList ] = useState(true);
  const [ showDelete, setShowDelete ] = useState(false);
  let { venture } = useSelector(state => {

    // console.log(state);
    return { ...state };
  
  });
  const classes = useStyles({ list });

  const handleChange = e => {

    e.preventDefault();
    setCardContent(e.target.value);
  
  };

  const submitHandler = () => {

    if (cardContent === "") return;
    const text = cardContent.trim().replace(/\s+/g, " ");

    setCardContent(text);
    const totalTasks = tasks.length;
    const postCardReq = {
      content          : text,
      problemsolutionId: column.problemsolutionId,
      parentVenture    : venture._id,
      stickylistId     : column._id,
      order:
        totalTasks === 0 ? "n" : midString(tasks[totalTasks - 1].order, "")
    };

    createNewCard(postCardReq);
    setCardContent("");
    fetchListsCards();
  
  };

  const handleAddition = () => {

    setAddCardFlag(true);
  
  };
  const closeButtonHandler = () => {

    setAddCardFlag(false);
    setCardContent("");
  
  };
  const changedHandler = e => {

    e.preventDefault();
    setListTitle(e.target.value);
  
  };
  const handleKeyDown = e => {

    if (e.key === "Enter") {

      e.preventDefault();
      submitHandler();
    
    }
  
  };

  const updateListTitle = async () => {

    const text = listTitle.trim().replace(/\s+/g, " ");

    if (text === "") {

      setListTitle(column.name);
      setEditable(false);
      
      return;
    
    }
    setListTitle(text);
    await updateListById({ name: listTitle }, column._id);
    column.name = text;
    setEditable(false);
  
  };

  return (
    <div className={ classes.wrapper }>
      { list &&
        <Draggable
          draggableId={ column._id }
          index={ index }
          isDragDisabled={ venture.userRole === "Commenter" }
        >
          { provided =>
            <div { ...provided.draggableProps } ref={ provided.innerRef }>
              <Paper
                elevation={ 0 }
                onMouseEnter={ () => {

                  if (venture.userRole !== "Commenter") setShowDelete(true);
                
                } }
                onMouseLeave={ () => {

                  if (venture.userRole !== "Commenter") setShowDelete(false);
                
                } }
                className={ classes.root }
                { ...provided.dragHandleProps }
              >
                <div
                  className={ classes.title }
                  onClick={ () => {

                    if (venture.userRole !== "Commenter") setEditable(true);
                  
                  } }
                >
                  { !editable &&
                    <div style={ { position: "relative" } }>
                      <div>{ column.name }</div>
                      { showDelete &&
                        <IconButton
                          size="small"
                          style={ {
                            right          : 0,
                            top            : 0,
                            position       : "absolute",
                            backgroundColor: "#EBECF0",
                            zIndex         : 100
                          } }
                          onClick={ async () => {

                            setList(false);
                            await deleteListById(column._id);
                          
                          } }
                        >
                          <DeleteIcon
                            fontSize="small"
                            style={ { backgroundColor: "#EBECF0" } }
                          />
                        </IconButton>
                      }
                    </div>
                  }
                  { editable &&
                    <div className={ classes.editable }>
                      <InputBase
                        onChange={ changedHandler }
                        multiline
                        fullWidth
                        value={ listTitle }
                        style={ { fontWeight: "bold" } }
                        autoFocus
                        onFocus={ e => {

                          const val = e.target.value;

                          e.target.value = "";
                          e.target.value = val;
                        
                        } }
                        onBlur={ updateListTitle }
                      />
                    </div>
                  }
                </div>
                <Droppable droppableId={ column._id } type="card">
                  {
                    provided =>
                      <div ref={ provided.innerRef } { ...provided.droppableProps }>
                        <div className={ classes.scroll }>
                          { tasks.map((task, index) =>
                            <Card
                              key={ task._id }
                              task={ task }
                              index={ index }
                              fetchListsCards={ fetchListsCards }
                            />
                          ) }
                          { addCardFlag &&
                            <InputCard
                              value={ cardContent }
                              changedHandler={ handleChange }
                              itemAdded={ submitHandler }
                              closeHandler={ closeButtonHandler }
                              keyDownHandler={ handleKeyDown }
                              type="card"
                              btnText="Add Card"
                              placeholder="Enter the notes for this card"
                            // width="100%"
                            />
                          }
                          { provided.placeholder }
                        </div>
                        { !addCardFlag &&
                          <AddItem
                            handleClick={ handleAddition }
                            icon={ <AddIcon /> }
                            // btnText="Add another card"
                            type="card"
                            // width="100%"
                            isdisabled={ venture.userRole === "Commenter" }
                          />
                        }
                      </div>
                    
                  }
                </Droppable>
              </Paper>
            </div>
          }
        </Draggable>
      }
    </div>
  );

};

export default StickyNotesList;
