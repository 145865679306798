import HelpIcon from "@mui/icons-material/Help";
import {
  Button, Grid, IconButton, Tooltip, Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { components } from "react-select";
import {
  getDigitalContent,
  updateDigitalContent
} from "../../../../services/digitalmarket";

import { makeStyles } from "@mui/styles";
import CustomMultiSelect from "../../../../components/CustomMultiSelect";
import CustomTextField from "../../../../components/CustomTextfield";
import CreateContent from "./CreateContent";
import styles from "./DigitalMarketing.module.css";

const useStyles = makeStyles(() => ({
  customWidth: {
    width          : 200,
    fontSize       : 10,
    backgroundColor: "white",
    color          : "#707070",
    boxShadow      : "0px 3px 6px #00000029",
    border         : "1px solid #707070",
    borderRadius   : 10
  },
  createcustomWidth: {
    width          : 300,
    fontSize       : 10,
    backgroundColor: "white",
    color          : "#707070",
    boxShadow      : "0px 3px 6px #00000029",
    border         : "1px solid #707070",
    borderRadius   : 10,
    padding        : 10
  },
  input: {
    height         : 35,
    // border: "1px solid #CCCCCC",
    backgroundColor: "#ffffff",
    // borderRadius: 5,
    fontSize       : 14,
    paddingLeft    : 10
  },
  createinput: {
    height         : 25,
    // border: "1px solid #CCCCCC",
    backgroundColor: "#ffffff"
    // borderRadius: 5,
  }
}));

const EditDigitalContent = props => {

  const [ details, setDetails ] = useState({
    name       : "",
    objective  : "",
    contentType: [
      {
        title         : "blog",
        description   : "",
        startDate     : "",
        endDate       : "",
        recurrence    : 1,
        frequency     : "",
        weekpublishday: {
          sunday   : false,
          monday   : false,
          tuesday  : false,
          wednesday: false,
          thursday : false,
          friday   : false,
          saturday : false
        },
        monthweek   : "",
        monthweekday: "",
        isCreated   : false
      },
      {
        title         : "post",
        description   : "",
        startDate     : "",
        endDate       : "",
        recurrence    : 1,
        frequency     : "",
        weekpublishday: {
          sunday   : false,
          monday   : false,
          tuesday  : false,
          wednesday: false,
          thursday : false,
          friday   : false,
          saturday : false
        },
        monthweek   : "",
        monthweekday: "",
        isCreated   : false
      },
      {
        title         : "video",
        description   : "",
        startDate     : "",
        endDate       : "",
        recurrence    : 1,
        frequency     : "",
        weekpublishday: {
          sunday   : false,
          monday   : false,
          tuesday  : false,
          wednesday: false,
          thursday : false,
          friday   : false,
          saturday : false
        },
        monthweek   : "",
        monthweekday: "",
        isCreated   : false
      },
      {
        title         : "",
        description   : "",
        startDate     : "",
        endDate       : "",
        recurrence    : 1,
        frequency     : "",
        weekpublishday: {
          sunday   : false,
          monday   : false,
          tuesday  : false,
          wednesday: false,
          thursday : false,
          friday   : false,
          saturday : false
        },
        monthweek   : "",
        monthweekday: "",
        isCreated   : false
      }
    ],
    kpiMetric      : [],
    expectedOutcome: ""
  });
  const id = props.id;
  const classes = useStyles();
  const history = useHistory();

  const handleContentDetailsChange = (name, value, contentindex) => {

    // console.log(name, value, contentindex);
    const newdetails = { ...details };

    if (name === "frequency") {

      if (value === "daily") {

        newdetails["contentType"] = details.contentType.map((content, index) =>
          index === contentindex
            ? {
              ...content,
              [name]            : value,
              ["weekpublishday"]: {
                sunday   : false,
                monday   : false,
                tuesday  : false,
                wednesday: false,
                thursday : false,
                friday   : false,
                saturday : false
              }
            }
            : content
        );
      
      } else {

        newdetails["contentType"] = details.contentType.map((content, index) =>
          index === contentindex
            ? {
              ...content,
              [name]             : value,
              ["dailypublishday"]: {
                sunday   : false,
                monday   : true,
                tuesday  : true,
                wednesday: true,
                thursday : true,
                friday   : true,
                saturday : false
              }
            }
            : content
        );
      
      }
    
    } else {

      newdetails["contentType"] = details.contentType.map((content, index) =>
        index === contentindex
          ? {
            ...content,
            [name]: value
          }
          : content
      );
    
    }
    setDetails(newdetails);
  
  };

  const handleDateChange = (event, contentindex, publishday) => {

    const newdetails = { ...details };

    newdetails["contentType"] = details.contentType.map((content, index) =>
      index === contentindex
        ? {
          ...content,
          [publishday]: {
            ...content[publishday],
            [event.target.name]: event.target.checked
          }
        }
        : content
    );
    setDetails(newdetails);
  
  };

  const handleChange = (name, value) => {

    setDetails(values => ({ ...values, [name]: value }));
  
  };

  const handleKPIChange = e => {

    let newkpimetric = Array.isArray(e) ? e.map(x => x.value) : [];

    setDetails(values => ({ ...values, ["kpiMetric"]: newkpimetric }));
  
  };

  let { venture } = useSelector(state => {

    return { ...state };
  
  });

  useEffect(() => {

    fetchContentType();
  
  }, []);

  const fetchContentType = async () => {

    try {

      const response = await getDigitalContent(id);
      // console.log(response.data);
      const content = response.data;

      setDetails({
        name           : content.name,
        objective      : content.objective,
        contentType    : content.contentType,
        kpiMetric      : content.kpiMetric,
        expectedOutcome: content.expectedOutcome
      });
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateContentType = async () => {

    try {

      await updateDigitalContent(details, id, venture._id);
      // console.log(response.data);

      history.push("/gtmdigitalcontent");
      props.editDialogclose();
      props.fetchGtmdigitalmarketing();
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  // const formatOptionLabel = ({ value, label, customtooltip }) => (
  //   <div style={{ display: "flex", alignItems: "center" }}>
  //     <div>{label}</div>
  //     <Tooltip
  //       title={customtooltip}
  //       disableFocusListener
  //       placement="right-start"
  //       classes={{ tooltip: classes.customWidth }}
  //     >
  //       <IconButton sx={{ padding: "0px", marginLeft: "5px" }}>
  //         <HelpIcon style={{ color: "#071AFF" }} fontSize="small"></HelpIcon>
  //       </IconButton>
  //     </Tooltip>
  //   </div>
  // );

  const Option = props => {

    return (
      <div>
        <components.Option { ...props }>
          <input
            type="checkbox"
            checked={ props.isSelected }
            onChange={ () => null }
          />{ " " }
          <label>{ props.label }</label>
          <Tooltip
            title={ props.data.customtooltip }
            disableFocusListener
            placement="right-start"
            classes={ { tooltip: classes.customWidth } }
          >
            <IconButton sx={ { padding: "0px", marginLeft: "5px" } }>
              <HelpIcon
                style={ { color: "#071AFF", fontSize: "16px" } }
              ></HelpIcon>
            </IconButton>
          </Tooltip>
        </components.Option>
      </div>
    );
  
  };

  const metricOptions = [
    {
      value: "Web traffic sources",
      label: "Web traffic sources",
      customtooltip:
        "The Web Traffic Sources metric measures which traffic sources are driving visitors to your website, and provides a comparison of each of those sources. The three main traffic sources are direct, referral, and search, although your website may also have traffic from campaigns such as banner ads or paid search."
    },
    {
      value        : "Pages per Visit",
      label        : "Pages per Visit",
      customtooltip: "# of web pages browsed per visit by the user"
    },
    {
      value: "Average Time on Site/Page",
      label: "Average Time on Site/Page",
      customtooltip:
        "Time spent (secs, mins) browsing the website/page. Formula - Total Time spent by users/Total visitors "
    },
    {
      value: "Engagements",
      label: "Engagements",
      customtooltip:
        "The number of interactions your content received from users (likes, comments, shares, saves, etc.)"
    },
    {
      value: "Impressions",
      label: "Impressions",
      customtooltip:
        "The number of times your content is displayed to someone’s feed"
    },
    {
      value        : "Reach",
      label        : "Reach",
      customtooltip: "The number of people who see your content"
    },
    {
      value: "Followers",
      label: "Followers",
      customtooltip:
        "The number of users/people following your social media page"
    },
    {
      value: "Posts",
      label: "Posts",
      customtooltip:
        "Total number of social media feed posted from your company page"
    },
    {
      value: "Subscribed Users",
      label: "Subscribed Users",
      customtooltip:
        "Total number of users subscribed to your product/application"
    },
    {
      value: "Active Users",
      label: "Active Users",
      customtooltip:
        "Total numbers of converted Leads/wins from trial campaigns/subscriptions"
    },
    {
      value: "Trial Conversions",
      label: "Trial Conversions",
      customtooltip:
        "Measure the cost-effectiveness of marketing campaigns. Formula - Total cost of campaign / # of leads generated"
    },
    {
      value: "Website traffic leads",
      label: "Website traffic leads",
      customtooltip:
        "Number of website visitors convert to leads. #Leads/Total Visitors * 100"
    },
    {
      value        : "Returning visitors",
      label        : "Returning visitors",
      customtooltip: "Number of times users have visited the website"
    },
    {
      value        : "Inbound Leads",
      label        : "Inbound Leads",
      customtooltip: "Number of leads from Digital or Website content"
    },
    {
      value: "Sales Qualified Leads",
      label: "Sales Qualified Leads",
      customtooltip:
        "Number of actionable leads from campaigns, website, content"
    },
    {
      value: "Marketing Qualified Leads",
      label: "Marketing Qualified Leads",
      customtooltip:
        "Number of Information only leads from campaigns, website, content"
    },
    {
      value: "Lead conversion rates",
      label: "Lead conversion rates",
      customtooltip:
        "Total number of leads that are converted into sales. Formula - (Leads converted to Sales/Total Leads)x100"
    },
    {
      value: "Click thru rate",
      label: "Click thru rate",
      customtooltip:
        " Total number of times search engine/social campaigns prompts users to click and navigate to your page/content. Formula - Visitors that click on page result/Visitors that view the page result"
    },
    {
      value: "Customer lifetime value LTV",
      label: "Customer lifetime value LTV",
      customtooltip:
        "Amount of gross profit that is generated from a customer over the entire time they do business with a company. Formula - Gross margin (%) x Length of lifetime in pay periods x Revenue per subscription per pay period"
    },
    {
      value: "Return on Marketing Investment (ROI)",
      label: "Return on Marketing Investment (ROI)",
      customtooltip:
        "Measure the ability of marketing campaigns to generate new revenue. Formula - (Sales Growth (attributable revenue) – Marketing Cost) x 100 / Marketing Investment"
    },
    {
      value: "Customer Attrition",
      label: "Customer Attrition",
      customtooltip:
        "Rate at which company loses customers over time. Formula - (Customers lost in a given time period/Total number of customers)x100"
    },
    {
      value: "Customer Acquisition Cost (CAC)",
      label: "Customer Acquisition Cost (CAC)",
      customtooltip:
        "Total amount of capital it costs to gain a new customer. Formula - Total marketing spend on customer acquisition/Total new customers"
    },
    {
      value: "Response Rate",
      label: "Response Rate",
      customtooltip:
        "Total number of users who respond back to your communication efforts/marketing campagins. Formula - (Customers responding/Customers exposed to communications)x100"
    },
    {
      value: "Marketing Originated Customers",
      label: "Marketing Originated Customers",
      customtooltip:
        " total share of all customers that come directly from marketing efforts. Formula - Customers that started out as a marketing lead/Total Customers"
    },
    {
      value: "Content Downloads",
      label: "Content Downloads",
      customtooltip:
        " Total number of pieces of content that have been downloaded from Website"
    },
    {
      value: "Pageviews per Session",
      label: "Pageviews per Session",
      customtooltip:
        "average number of pages a user views during a single session. Formula - (Pageviews per session with users )/Total number of user sessions"
    },
    {
      value: "Dormancy Rate",
      label: "Dormancy Rate",
      customtooltip:
        "Total number of users not using application/ product in a given time period. Formula - (Customers not using the product within time period/Total number of customers)x100"
    },
    {
      value: "Bounce Rate",
      label: "Bounce Rate",
      customtooltip:
        "Number of visitors that arrive at your site and leave without navigating to any other page on your site. Formula - (Visitors leaving the site after only viewing one page/ Total site visitors)x100"
    },
    {
      value: "Net Promoter Score",
      label: "Net Promoter Score",
      customtooltip:
        "Retrieving data. Wait a few seconds and try to cut or copy again."
    }
  ];

  return (
    <Grid>
      <Grid container mt={ 1 }>
        <Grid item md={ 4 }>
          <Typography variant="body2">Content Name</Typography>
        </Grid>
        <Grid item md={ 8 }>
          {/* <TextField
            placeholder="e.g. Company DNA"
            type="text"
            size="small"
            InputProps={{
              className: classes.input,
            }}
            sx={{ width: "100%" }}
            value={details.name}
            name="name"
            onChange={(e) => {
              handleChange(e.target.name, e.target.value);
            }}
          /> */}
          <CustomTextField
            width={ "100%" }
            padding={ "1px 1px" }
            fontSize={ "14px" }
            placeholder="e.g. Company DNA"
            value={ details.name }
            name="name"
            handledetailsChange={ e => {

              handleChange(e.target.name, e.target.value);
            
            } }
          />
        </Grid>
      </Grid>
      <Grid container mt={ 1 }>
        <Grid item md={ 4 }>
          <Typography variant="body2">Overall Objective</Typography>
        </Grid>
        <Grid item md={ 8 }>
          {/* <TextField
            placeholder="e.g. Enhance company brand image"
            type="text"
            size="small"
            InputProps={{
              className: classes.input,
            }}
            sx={{ width: "100%" }}
            value={details.objective}
            name="objective"
            onChange={(e) => {
              handleChange(e.target.name, e.target.value);
            }}
          /> */}
          <CustomTextField
            width={ "100%" }
            padding={ "1px 1px" }
            fontSize={ "14px" }
            placeholder="e.g. Enhance company brand image"
            value={ details.objective }
            name="objective"
            handledetailsChange={ e => {

              handleChange(e.target.name, e.target.value);
            
            } }
          />
        </Grid>
      </Grid>
      <Grid container mt={ 1 }>
        <Grid item md={ 4 }>
          <Typography variant="body2">Content Type</Typography>
        </Grid>
        <Grid
          item
          md={ 8 }
          sx={ { display: "flex", justifyContent: "space-evenly" } }
        >
          <>
            { details.contentType.map((content, index) => {

              return <CreateContent
                handleContentDetailsChange={ handleContentDetailsChange }
                handleDateChange={ handleDateChange }
                details={ content }
                index={ index }
                id={ id }
              />;
            
            }) }
          </>
        </Grid>
      </Grid>
      <Grid container mt={ 1 }>
        <Grid item md={ 4 }>
          <Typography variant="body2">KPI Metric</Typography>
        </Grid>
        <Grid item md={ 8 }>
          {/* <Select
            placeholder="Select your Metric"
            options={metricOptions}
            formatOptionLabel={formatOptionLabel}
            filterOption={createFilter({
              matchFrom: "start",
            })}
            styles={{
              control: (styles) => ({
                ...styles,
                border: "2px solid #CCCCCC",
                backgroundColor: "#ffffff",
                borderRadius: 5,
              }),
              valueContainer: (styles) => ({
                ...styles,
                maxHeight: 50,
                overflowY: "auto",
              }),
            }}
            isMulti
            maxMenuHeight={150}
            value={metricOptions.filter((obj) =>
              details.kpiMetric.includes(obj.value)
            )}
            onChange={handleKPIChange}
            />*/}
          <CustomMultiSelect
            placeholder="Select your Metric"
            options={ metricOptions }
            components={ {
              Option
            } }
            closeMenuOnSelect={ false }
            hideSelectedOptions={ false }
            isClearable={ false }
            maxMenuHeight={ 150 }
            value={ metricOptions.filter(obj =>
              details.kpiMetric.includes(obj.value)
            ) }
            handleChange={ handleKPIChange }
          />
        </Grid>
      </Grid>
      <Grid container mt={ 1 }>
        <Grid item md={ 4 }>
          <Typography variant="body2">Expected Outcome</Typography>
        </Grid>
        <Grid item md={ 8 }>
          {/* <TextField
            placeholder="1m users, 10k downloads etc."
            type="text"
            size="small"
            InputProps={{
              className: classes.input,
            }}
            sx={{ width: "100%" }}
            value={details.expectedOutcome}
            name="expectedOutcome"
            onChange={(e) => {
              handleChange(e.target.name, e.target.value);
            }}
          /> */}
          <CustomTextField
            width={ "100%" }
            padding={ "1px 1px" }
            fontSize={ "14px" }
            placeholder="1m users, 10k downloads etc."
            value={ details.expectedOutcome }
            name="expectedOutcome"
            handledetailsChange={ e => {

              handleChange(e.target.name, e.target.value);
            
            } }
          />
        </Grid>
      </Grid>
      <Button
        id={ styles.updatebtn }
        variant="outlined"
        onClick={ () => {

          updateContentType();
        
        } }
        disabled={ venture.userRole === "Commenter" }
      >
        Update
      </Button>
    </Grid>
  );

};

export default EditDigitalContent;
