import React, { useEffect, useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box, Grid, Table, TableContainer, TableHead, Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import CustomMultilineTextfield from "../../../../components/CustomMultilineTextfield";
import CustomTextField from "../../../../components/CustomTextfield";
import HelpIconTooltip from "../../../../components/HelpIconTooltip";
import { getFinancials } from "../../../../services/financials";
import IndividualFundingSource from "../../Financials/FrOutlook/IndividualFundingSource";

export default function PitchFinancialsFunding() {

  // const [ loading, setLoading ] = useState(false);

  const [ financials, setFinancials ] = useState({
    plOutlooks          : [],
    frOutlooks          : [],
    currentFundingSource: [],
    fundRaising         : {
      totalFundingNeed : 0,
      fundingAllocation: ""
    },
    totalCurrentFunding: 0,
    plOutlooksCurrency : "",
    frOutlooksCurrency : ""
  });

  const { venture } = useSelector(state => ({ ...state }));

  useEffect(() => {

    fetchFinancials();
  
  }, []);

  const fetchFinancials = async () => {

    try {

      // setLoading(true);
      const response = await getFinancials(venture.financials, venture._id);
      //   setComments(response.data.finance.comments.fundingRunwayoutlook);

      if (response.data.finance.frOutlooks.length > 0) {

        let newyears = [];
        let newfinancials = { ...response.data.finance };
        let previousfunding = newfinancials["totalCurrentFunding"];

        newfinancials.frOutlooks.map(fr => {

          newyears.push(fr.year);
          fr.frOutlook["monthlyBurnRate"].map((burnrate, brindex) => {

            fr.frOutlook["fundingBalance"][brindex] =
              previousfunding - fr.frOutlook["monthlyBurnRate"][brindex];
            let runawayMonth = Math.round(
              fr.frOutlook["fundingBalance"][brindex] /
              fr.frOutlook["monthlyBurnRate"][brindex]
            );

            fr.frOutlook["runawayMonth"][brindex] =
              runawayMonth !== Infinity ? runawayMonth : 0;
            previousfunding = fr.frOutlook["fundingBalance"][brindex];
          
          });
        
        });
        setFinancials(newfinancials);
        // setYears(newyears);
        // setYear(response.data.finance.frOutlooks[0].year);
        // setCurrentFroutlook(response.data.finance.frOutlooks[0].frOutlook);
      
      } else {

        setFinancials(response.data.finance);
      
      }
      // setLoading(false);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const numberWithCommas = num => {

    if (financials.frOutlooksCurrency) {

      let newNum = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      
      return `${financials.frOutlooksCurrency.split(" ")[0].trim()} ${newNum}`;
    
    } else {

      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    
    }
  
  };

  return (
    <Box>
      <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
        <Box>
          <Typography
            sx={{ fontSize: "20px", color: "#5F5E5E", fontWeight: "bold" }}
          >
            Finance
          </Typography>
        </Box>

        <ChevronRightIcon sx={{ color: "gray" }} />
        <Box>
          <Typography>Funding & Runway Outlook</Typography>
        </Box>
      </Box>

      <Box>
        <Typography sx={{ fontSize: "12px" }}>
          Derive the Profit and Loss progression for your company
        </Typography>
      </Box>

      <Grid container xs={12} sx={{ marginTop: "20px" }}>
        <Grid item xs={5} sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
            Current Funding Sources
          </Typography>
          <HelpIconTooltip
            tooltipdata={
              "List out all external sources that have funded your venture so far, including your own investment. Here are some examples:\n• Founders/Co-founders\n• Friends & Family\n• Angel investor\n• Incubator\n• Accelerator\n• VC / PE\n• Bank loan\n• Government grant\n• Pitch competition prize\n• Crowdfunding"
            }
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={5} sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ fontSize: "14px" }}>
            How much new funding are you trying to raise?
          </Typography>
        </Grid>
      </Grid>

      {/* ------ */}
      <Box>
        <Grid
          xs={12}
          container
          sx={{
            // marginBottom: 6,
            position: "relative"
          }}
        >
          {/* left side grid */}
          <Grid
            container
            justifyContent="center"
            xs={5}
            sx={{
              border      : "1px solid #A8A8A8",
              borderRadius: 2
            }}
          >
            <TableContainer
              sx={{
                // height: "330px",
                height   : "250px",
                overflowY: "auto"
              }}
            >
              <Table>
                <TableHead>
                  {financials.currentFundingSource.map(
                    (fundingsource, index) => {

                      return <IndividualFundingSource
                        fundingsource={fundingsource}
                        //   handleFundingSourceChange={handleFundingSourceChange}
                        numberWithCommas={numberWithCommas}
                        //   removeRow={removeRow}
                        index={index}
                      />;
                    
                    }
                  )}
                </TableHead>
              </Table>
              {/* <IconButton
              // onClick={addRow}
              // disabled={venture.userRole === "Commenter"}
              >
                <AddCircleIcon
                  sx={{
                    color:
                      venture.userRole === "Commenter" ? "#b0b0b0" : "#071Aff",
                  }}
                />
              </IconButton> */}
            </TableContainer>

            <Grid
              container
              xs={12}
              // justifyContent="space-around"
              // align="center"
              // justify="center"
              alignItems="center"
              sx={{ borderTop: "1px solid #A8A8A8" }}
            >
              <Grid
                item
                xs={6}
                sx={{
                  borderRight  : "1px solid #A8A8A8",
                  fontWeight   : "bold",
                  marginLeft   : "14px",
                  color        : "#707070",
                  width        : "100%",
                  // padding: "10px 0px",
                  textAlign    : "center",
                  fontSize     : "14px",
                  // border: 1,
                  paddingTop   : "10px",
                  paddingBottom: "10px",
                  paddingRight : "22px"
                }}
              >
                Total
              </Grid>
              <Grid item xs={4}>
                {/* <TableTextField
                          sx={textFieldSX}
                          value={numberWithCommas(
                            financials.totalCurrentFunding
                          )}
                          disabled={true}
                        /> */}
                <Box
                  sx={{
                    display       : "flex",
                    justifyContent: "center",
                    width         : "9vw",
                    marginLeft    : "5px"
                  }}
                >
                  <Typography
                    sx={{
                      marginLeft: "12px",

                      padding   : "10px 0px",
                      fontWeight: "bold",
                      color     : "#707070",
                      fontSize  : "14px",
                      textAlign : "center"
                    }}
                  >
                    {numberWithCommas(financials.totalCurrentFunding)}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          {/* spacing */}
          <Grid container xs={2}></Grid>

          {/* right side grid */}
          <Grid container xs={5}>
            <Grid xs={12} item>
              {/* <Box sx={{ marginBottom: "6px" }}>
                        <Typography sx={{ fontSize: "14px" }}>
                          How much new funding are you trying to raise?
                        </Typography>
                      </Box> */}
              {/* <TextField
                        sx={textFieldSX}
                        fullWidth
                        type="text"
                        inputProps={{
                          style: { fontSize: 14 },
                        }}
                        value={
                          financials.fundRaising.totalFundingNeed
                            ? numberWithCommas(
                                financials.fundRaising.totalFundingNeed
                              )
                            : numberWithCommas(0)
                        }
                        onChange={(e) => {
                          handleFundRaising("totalFundingNeed", e.target.value);
                        }}
                      /> */}
              <CustomTextField
                fontSize={"14px"}
                width={"100%"}
                padding={"7px 5px"}
                value={
                  financials.fundRaising.totalFundingNeed
                    ? numberWithCommas(financials.fundRaising.totalFundingNeed)
                    : numberWithCommas(0)
                }
                disabled={true}
              // handledetailsChange={(e) => {
              //   handleFundRaising("totalFundingNeed", e.target.value);
              // }}
              />
            </Grid>
            <Grid sx={{ marginTop: "11%" }} xs={12}>
              <Box sx={{ marginBottom: "6px" }}>
                <Typography sx={{ fontSize: "14px" }}>
                  What key programs do you plan to use these funds for?
                </Typography>
              </Box>
              {/* <TextField
                        // sx={textFieldSX}
                        fullWidth
                        multiline
                        inputProps={{
                          style: { fontSize: 14 },
                        }}
                        // sx={{ whiteSpace: "pre-wrap" }}
                        rows={5}
                        value={financials.fundRaising.fundingAllocation}
                        placeholder={
                          "Some examples:\n• Digital marketing – primarily online ads & campaigns\n• Hire 4 full stack developers\n• Build an enterprise sales team"
                        }
                        onChange={(e) => {
                          handleFundRaising(
                            "fundingAllocation",
                            e.target.value
                          );
                        }}
                      /> */}
              <CustomMultilineTextfield
                // fontSize={"14px"}
                width={"100%"}
                maxRows={5}
                minRows={5}
                value={financials.fundRaising.fundingAllocation}
                placeholder={
                  "Some examples:\n• Digital marketing – primarily online ads & campaigns\n• Hire 4 full stack developers\n• Build an enterprise sales team "
                }
                disabled={true}
              // handledetailsChange={(e) => {
              //   handleFundRaising("fundingAllocation", e.target.value);
              // }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );

}
