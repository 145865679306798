import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Popover, TextField, ToggleButton, ToggleButtonGroup, Typography
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { updateContent } from "../../../../services/digitalmarket";

import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import ErrorMessage from "../../../../components/ErrorMessage";
import styles from "./DigitalMarketing.module.css";

const useStyles = makeStyles(() => ({
  createcustomWidth: {
    width          : 300,
    fontSize       : 10,
    backgroundColor: "white",
    color          : "#707070",
    boxShadow      : "0px 3px 6px #00000029",
    border         : "1px solid #707070",
    borderRadius   : 10,
    padding        : 10
  },
  createinput: {
    height                : 25,
    // border: "1px solid #CCCCCC",
    backgroundColor       : "#ffffff",
    // borderRadius: 5,
    "& input::placeholder": {
      fontSize: 14
    }
  }
}));

const SIcon = styled("span")(() => ({
  borderRadius   : 3,
  width          : 20,
  height         : 20,
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/s.png)`,
  backgroundSize : "cover"
}));

const SCheckedIcon = styled(SIcon)({
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/letter-s.png)`
});

const MIcon = styled("span")(() => ({
  borderRadius   : 3,
  width          : 20,
  height         : 20,
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/m.png)`,
  backgroundSize : "cover"
}));

const MCheckedIcon = styled(SIcon)({
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/letter-m.png)`
});

const TIcon = styled("span")(() => ({
  borderRadius   : 3,
  width          : 20,
  height         : 20,
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/t.png)`,
  backgroundSize : "cover"
}));

const TCheckedIcon = styled(SIcon)({
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/letter-t.png)`
});

const WIcon = styled("span")(() => ({
  borderRadius   : 3,
  width          : 20,
  height         : 20,
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/w.png)`,
  backgroundSize : "cover"
}));

const WCheckedIcon = styled(SIcon)({
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/letter-w.png)`
});

const FIcon = styled("span")(() => ({
  borderRadius   : 3,
  width          : 20,
  height         : 20,
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/f.png)`,
  backgroundSize : "cover"
}));

const FCheckedIcon = styled(SIcon)({
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/letter-f.png)`
});

const CreateContent = props => {

  const classes = useStyles();
  const [ anchorEl, setAnchorEl ] = React.useState(null);
  const [ errormsg, setErrormsg ] = useState("");

  let { venture } = useSelector(state => {

    return { ...state };
  
  });

  const handleClick = event => {

    setAnchorEl(event.currentTarget);
  
  };

  const handleClose = () => {

    setAnchorEl(null);
  
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const details = props.details;
  const index = props.index;

  const createContentType = async () => {

    if (
      details.title === "" ||
      details.description === "" ||
      details.start === "" ||
      details.end === "" ||
      details.frequency === ""
    ) {

      setErrormsg("please fill required fields");
      setTimeout(() => {

        setErrormsg("");
      
      }, 3000);
    
    } else if (
      new Date(details.end).valueOf() < new Date(details.start).valueOf()
    ) {

      setErrormsg("End date should be greater than start date");
      setTimeout(() => {

        setErrormsg("");
      
      }, 3000);
    
    } else if (
      details.frequency === "weekly" &&
      details.weekpublishday.sunday === false &&
      details.weekpublishday.monday === false &&
      details.weekpublishday.tuesday === false &&
      details.weekpublishday.wednesday === false &&
      details.weekpublishday.thursday === false &&
      details.weekpublishday.friday === false &&
      details.weekpublishday.saturday === false
    ) {

      setErrormsg("please select weekdays");
      setTimeout(() => {

        setErrormsg("");
      
      }, 3000);
    
    } else if (
      details.frequency === "monthly" &&
      (details.monthweek === "" || details.monthweekday === "")
    ) {

      setErrormsg("please select week and weekdays");
      setTimeout(() => {

        setErrormsg("");
      
      }, 3000);
    
    } else if (
      Number(details.recurrence) === 0 ||
      Number(details.recurrence) > 10
    ) {

      setErrormsg("Please select recurrence between 1 to 10");
      setTimeout(() => {

        setErrormsg("");
      
      }, 3000);
    
    } else {

      if (props.id) {

        await updateContent(details, props.id, index, venture._id);
        // console.log(response.data);

        handleClose();
      
      } else {

        if (
          details.title !== "blog" &&
          details.title !== "video" &&
          details.title !== "post" &&
          index < 6
        ) {

          props.addContent("isCreated", true, index);
          handleClose();
        
        } else {

          props.handleContentDetailsChange("isCreated", true, index);
          handleClose();
        
        }
      
      }
    
    }
  
  };

  return (
    <>
      <Button
        aria-describedby={ id }
        variant="outlined"
        onClick={ handleClick }
        id={ props.id ? styles.contenttypeeditbtn : styles.contenttypebtn }
        size="small"
        disabled={ venture.userRole === "Commenter" && !props.id }
      >
        { props.id ?
          ""
          : details.isCreated ?
            <CheckCircleIcon
              sx={ { color: "#008105", fontSize: "15px", marginRight: "5px" } }
              fontSize="small"
            />
            :
            ""
        }
        { details.title === "blog" ||
          details.title === "post" ||
          details.title === "video"
          ? details.title
          : details.isCreated
            ? details.title
            : "other" }
        { props.id ?
          <AccessTimeIcon
            sx={ { color: "#707070", marginLeft: "5px" } }
            fontSize="small"
          />
          :
          ""
        }
      </Button>
      <Popover
        id={ id }
        open={ open }
        anchorEl={ anchorEl }
        onClose={ handleClose }
        anchorOrigin={ {
          vertical  : "bottom",
          horizontal: "left"
        } }
        sx={ { "& .MuiPaper-root": { padding: "10px" } } }
      >
        <Grid>
          <Grid container mt={ 1 }>
            <Grid item md={ 4 }>
              <Typography sx={ { fontSize: "12px" } }>Title*</Typography>
            </Grid>
            <Grid item md={ 8 }>
              <TextField
                size="small"
                InputProps={ {
                  className: classes.createinput
                } }
                type="text"
                value={ details.title }
                onChange={ e => {

                  props.handleContentDetailsChange(
                    "title",
                    e.target.value.trimStart(),
                    index
                  );
                
                } }
                disabled={ venture.userRole === "Commenter" }
              />
            </Grid>
          </Grid>
          <Grid container mt={ 1 }>
            <Grid item md={ 4 }>
              <Typography sx={ { fontSize: "12px" } }>Description*</Typography>
            </Grid>
            <Grid item md={ 8 }>
              <TextField
                multiline
                size="small"
                type="text"
                sx={ {
                  // backgroundColor: props.backgroundColor,
                  width                     : "100%",
                  "& .MuiOutlinedInput-root": {
                    padding: "7px 14px"
                  },
                  "& .MuiInputBase-input": {
                    fontSize  : "14px",
                    lineHeight: "15px",
                    height    : "40px !important"
                  }
                } }
                value={ details.description }
                onChange={ e => {

                  props.handleContentDetailsChange(
                    "description",
                    e.target.value.trimStart(),
                    index
                  );
                
                } }
                disabled={ venture.userRole === "Commenter" }
              />
            </Grid>
          </Grid>
          <Grid container mt={ 1 }>
            <Grid item md={ 4 }>
              <Typography sx={ { fontSize: "12px" } }>Start date*</Typography>
            </Grid>
            <Grid item md={ 8 }>
              <TextField
                size="small"
                InputProps={ {
                  className: classes.createinput,
                  shrink   : true
                } }
                type="date"
                sx={ { width: "100%" } }
                value={ details.start }
                onChange={ e => {

                  props.handleContentDetailsChange(
                    "start",
                    e.target.value,
                    index
                  );
                
                } }
                disabled={ venture.userRole === "Commenter" }
              />
            </Grid>
          </Grid>
          <Grid container mt={ 1 }>
            <Grid item md={ 4 }>
              <Typography sx={ { fontSize: "12px" } }>End date*</Typography>
            </Grid>
            <Grid item md={ 8 }>
              <TextField
                size="small"
                InputProps={ {
                  className: classes.createinput,
                  shrink   : true
                } }
                type="date"
                sx={ { width: "100%" } }
                value={ details.end }
                onChange={ e => {

                  props.handleContentDetailsChange(
                    "end",
                    e.target.value,
                    index
                  );
                
                } }
                disabled={ venture.userRole === "Commenter" }
              />
            </Grid>
          </Grid>
          <Grid container mt={ 1 }>
            <Grid item md={ 4 }>
              <Typography sx={ { fontSize: "12px" } }>Recurrence*</Typography>
            </Grid>
            <Grid item md={ 8 }>
              <TextField
                size="small"
                type="number"
                InputProps={ {
                  className: classes.createinput
                } }
                sx={ { width: "100%" } }
                value={ details.recurrence }
                onChange={ e => {

                  props.handleContentDetailsChange(
                    "recurrence",
                    e.target.value,
                    index
                  );
                
                } }
                disabled={ venture.userRole === "Commenter" }
              />
            </Grid>
          </Grid>
          <Grid container mt={ 1 } direction="column">
            <Typography sx={ { fontSize: "12px" } } gutterBottom>
              Frequency*
            </Typography>
            <ToggleButtonGroup
              color="primary"
              exclusive
              value={ details.frequency }
              onChange={ e => {

                props.handleContentDetailsChange(
                  "frequency",
                  e.target.value,
                  index
                );
              
              } }
              sx={ { justifyContent: "center", height: "30px" } }
            >
              <ToggleButton
                value="daily"
                id={ styles.individualtogglebtn }
                sx={ { marginRight: "20px" } }
                disabled={ venture.userRole === "Commenter" }
              >
                Daily
              </ToggleButton>
              <ToggleButton
                value="weekly"
                id={ styles.individualtogglebtn }
                sx={ { marginRight: "20px" } }
                disabled={ venture.userRole === "Commenter" }
              >
                Weekly
              </ToggleButton>
              <ToggleButton
                value="monthly"
                id={ styles.individualtogglebtn }
                disabled={ venture.userRole === "Commenter" }
              >
                Monthly
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          { details.frequency === "daily" &&
            <Grid container mt={ 1 } direction="column">
              <Typography sx={ { fontSize: "12px" } } gutterBottom>
                Publish Day
              </Typography>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={ venture.userRole === "Commenter" }
                      checkedIcon={ <SCheckedIcon /> }
                      icon={ <SIcon /> }
                      name="sunday"
                      checked={ details.dailypublishday.sunday }
                      onChange={ e => {

                        props.handleDateChange(e, index, "dailypublishday");
                      
                      } }
                      disableRipple
                    />
                  }
                  label=""
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checkedIcon={ <MCheckedIcon /> }
                      icon={ <MIcon /> }
                      name="monday"
                      checked={ details.dailypublishday.monday }
                      disabled
                      disableRipple
                    />
                  }
                  label=""
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checkedIcon={ <TCheckedIcon /> }
                      icon={ <TIcon /> }
                      name="tuesday"
                      checked={ details.dailypublishday.tuesday }
                      disabled
                      disableRipple
                    />
                  }
                  label=""
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checkedIcon={ <WCheckedIcon /> }
                      icon={ <WIcon /> }
                      name="wednesday"
                      checked={ details.dailypublishday.wednesday }
                      disabled
                      disableRipple
                    />
                  }
                  label=""
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checkedIcon={ <TCheckedIcon /> }
                      icon={ <TIcon /> }
                      name="thursday"
                      checked={ details.dailypublishday.thursday }
                      disabled
                      disableRipple
                    />
                  }
                  label=""
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checkedIcon={ <FCheckedIcon /> }
                      icon={ <FIcon /> }
                      name="friday"
                      checked={ details.dailypublishday.friday }
                      disabled
                      disableRipple
                    />
                  }
                  label=""
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={ venture.userRole === "Commenter" }
                      checkedIcon={ <SCheckedIcon /> }
                      icon={ <SIcon /> }
                      name="saturday"
                      checked={ details.dailypublishday.saturday }
                      onChange={ e => {

                        props.handleDateChange(e, index, "dailypublishday");
                      
                      } }
                      disableRipple
                    />
                  }
                  label=""
                />
              </FormGroup>
            </Grid>
          }
          { details.frequency === "weekly" &&
            <Grid container mt={ 1 } direction="column">
              <Typography sx={ { fontSize: "12px" } } gutterBottom>
                Publish Day
              </Typography>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={ venture.userRole === "Commenter" }
                      checkedIcon={ <SCheckedIcon /> }
                      icon={ <SIcon /> }
                      name="sunday"
                      checked={ details.weekpublishday.sunday }
                      onChange={ e => {

                        props.handleDateChange(e, index, "weekpublishday");
                      
                      } }
                      disableRipple
                    />
                  }
                  label=""
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={ venture.userRole === "Commenter" }
                      checkedIcon={ <MCheckedIcon /> }
                      icon={ <MIcon /> }
                      name="monday"
                      checked={ details.weekpublishday.monday }
                      onChange={ e => {

                        props.handleDateChange(e, index, "weekpublishday");
                      
                      } }
                      disableRipple
                    />
                  }
                  label=""
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={ venture.userRole === "Commenter" }
                      checkedIcon={ <TCheckedIcon /> }
                      icon={ <TIcon /> }
                      name="tuesday"
                      checked={ details.weekpublishday.tuesday }
                      onChange={ e => {

                        props.handleDateChange(e, index, "weekpublishday");
                      
                      } }
                      disableRipple
                    />
                  }
                  label=""
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={ venture.userRole === "Commenter" }
                      checkedIcon={ <WCheckedIcon /> }
                      icon={ <WIcon /> }
                      name="wednesday"
                      checked={ details.weekpublishday.wednesday }
                      onChange={ e => {

                        props.handleDateChange(e, index, "weekpublishday");
                      
                      } }
                      disableRipple
                    />
                  }
                  label=""
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={ venture.userRole === "Commenter" }
                      checkedIcon={ <TCheckedIcon /> }
                      icon={ <TIcon /> }
                      name="thursday"
                      checked={ details.weekpublishday.thursday }
                      onChange={ e => {

                        props.handleDateChange(e, index, "weekpublishday");
                      
                      } }
                      disableRipple
                    />
                  }
                  label=""
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={ venture.userRole === "Commenter" }
                      checkedIcon={ <FCheckedIcon /> }
                      icon={ <FIcon /> }
                      name="friday"
                      checked={ details.weekpublishday.friday }
                      onChange={ e => {

                        props.handleDateChange(e, index, "weekpublishday");
                      
                      } }
                      disableRipple
                    />
                  }
                  label=""
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={ venture.userRole === "Commenter" }
                      checkedIcon={ <SCheckedIcon /> }
                      icon={ <SIcon /> }
                      name="saturday"
                      checked={ details.weekpublishday.saturday }
                      onChange={ e => {

                        props.handleDateChange(e, index, "weekpublishday");
                      
                      } }
                      disableRipple
                    />
                  }
                  label=""
                />
              </FormGroup>
            </Grid>
          }
          { details.frequency === "monthly" &&
            <Grid container mt={ 1 } direction="column">
              <Typography sx={ { fontSize: "12px" } } gutterBottom>
                Publish Day
              </Typography>
              <Box display="flex">
                <FormControl fullWidth>
                  <select
                    value={ details.monthweek }
                    onChange={ e => {

                      props.handleContentDetailsChange(
                        "monthweek",
                        e.target.value,
                        index
                      );
                    
                    } }
                    id={ styles.individualselectbtn }
                    disabled={ venture.userRole === "Commenter" }
                  >
                    <option value="" disabled selected hidden>
                      Week
                    </option>
                    <option value={ 1 }>first</option>
                    <option value={ 2 }>second</option>
                    <option value={ 3 }>third</option>
                    <option value={ 4 }>fourth</option>
                    <option value={ 5 }>fifth</option>
                  </select>
                </FormControl>
                <FormControl fullWidth>
                  <select
                    value={ details.monthweekday }
                    id={ styles.individualselectbtn }
                    onChange={ e => {

                      props.handleContentDetailsChange(
                        "monthweekday",
                        e.target.value,
                        index
                      );
                    
                    } }
                    disabled={ venture.userRole === "Commenter" }
                  >
                    <option value="" disabled selected hidden>
                      WeekDay
                    </option>
                    <option value={ 0 }>Sunday</option>
                    <option value={ 1 }>Monday</option>
                    <option value={ 2 }>Tuesday</option>
                    <option value={ 3 }>Wednesday</option>
                    <option value={ 4 }>Thursday</option>
                    <option value={ 5 }>Friday</option>
                    <option value={ 6 }>Saturday</option>
                  </select>
                </FormControl>
              </Box>
            </Grid>
          }
          { errormsg && <ErrorMessage errormsg={ errormsg } /> }
          <Button
            sx={ { padding: "0px", color: "#071AFF", float: "right" } }
            onClick={ () => {

              createContentType();
            
            } }
            disabled={ venture.userRole === "Commenter" }
          >
            { props.id ? "Update" : "Create" }
          </Button>
        </Grid>
      </Popover>
    </>
  );

};

export default CreateContent;
