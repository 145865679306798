import { Box, Button } from "@mui/material";
import React from "react";

const SaveCancelButton = props => {

  return (
    <Box sx={ { display: "flex", justifyContent: "flex-end" } }>
      <Button
        variant="contained"
        disabled={ props.disabled ? props.disabled : false }
        disableElevation
        sx={ {
          height         : "30px",
          width          : "100px",
          marginRight    : "20px",
          backgroundColor: "#071AFF",
          textTransform  : "none"
        } }
        onClick={ props.saveClick }
      >
        Save
      </Button>
      <Button
        variant="outlined"
        sx={ {
          height         : "30px",
          width          : "100px",
          backgroundColor: "#F0F1FF",
          borderColor    : "#071AFF",
          color          : "#071AFF",
          textTransform  : "none"
        } }
        onClick={ props.cancelClick }
      >
        Close
      </Button>
    </Box>
  );

};

export default SaveCancelButton;
