import React, { useEffect, useState } from "react";
import {
  Box, Button, Typography
} from "@mui/material";
// import { Country } from "country-state-city";
import { useSelector } from "react-redux";
import CustomSelect from "../../../../components/CustomSelect";
import CustomTextField from "../../../../components/CustomTextfield";
import { getVetureById } from "../../../../services/venture";

export default function CoverEditVenture() {

  const [ details, setDetails ] = useState({
    name            : "",
    location        : "",
    website         : "",
    linkedin        : "",
    otherSocialMedia: "",
    businessEmail   : "",
    businessContact : ""
  });

  const [ ventureLogo, setVentureLogo ] = useState(null);

  const { venture } = useSelector(state => ({ ...state }));

  useEffect(() => {

    if (venture._id) {

      fetchVentureDetails();
    
    }
  
  }, [ venture._id ]);

  const fetchVentureDetails = () => {

    getVetureById(venture._id).then(response => {

      const { venture, logo } = response.data;

      setVentureLogo(logo);
      setDetails({
        name            : venture.details.name,
        location        : venture.details.location,
        website         : venture.details.website,
        linkedin        : venture.details.linkedin,
        otherSocialMedia: venture.details.otherSocialMedia,
        businessEmail   : venture.details.businessEmail,
        businessContact : venture.details.businessContact
      });
    
    });
  
  };

  // const countries = Country.getAllCountries();
  // const updatedCountries = countries.map(country => ({
  //   label: country.name,
  //   value: country.name,
  //   ...country
  // }));

  return (
    <Box>
      <Box>
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          style={{ marginTop: "10px", fontWeight: "bold" }}
        >
          Let's gather some basic information about your venture
        </Typography>
      </Box>

      <Box
        sx={{
          width         : "100%",
          // border: 1,
          display       : "flex",
          // alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <Box
          sx={{
            width        : "48%",
            display      : "flex",
            flexDirection: "column"
            // border: 1,
          }}
        >
          <CustomTextField
            // error={
            //   errormsg ? (details.name === "" ? true : false) : false
            // }
            label={"Venture Name*"}
            type={"text"}
            name={"name"}
            value={details.name}
            disabled={true}
          // handledetailsChange={handledetailsChange}
          />
          <CustomSelect
            // error={
            //   errormsg ? (details.location === "" ? "#FF0000" : "") : ""
            // }
            label={"Country*"}
            name={"location"}
            value={details.location}
            disabled={true}
            // options={updatedCountries}
            placeholder={"Select your Country"}
          // handleChange={(e) => {
          //   handleLocationChange("location", e.value);
          // }}
          />
          <CustomTextField
            label={"Website"}
            type={"text"}
            name={"website"}
            value={details.website}
            disabled={true}
          // handledetailsChange={handledetailsChange}
          />
          <CustomTextField
            label={"LinkedIn Company Page"}
            type={"text"}
            name={"linkedin"}
            value={details.linkedin}
            disabled={true}
          // handledetailsChange={handledetailsChange}
          />
          <CustomTextField
            label={"Other Social Media"}
            type={"text"}
            name={"otherSocialMedia"}
            value={details.otherSocialMedia}
            disabled={true}
          // handledetailsChange={handledetailsChange}
          />
        </Box>
        <Box
          sx={{
            width        : "48%",
            // border: 1,
            display      : "flex",
            flexDirection: "column"
          }}
        >
          <label htmlFor="logo" style={{ color: "#707070" }}>
            Logo
          </label>
          <Box sx={{ display: "flex", marginBottom: "10px" }}>
            <Button
              // onClick={handleClick}
              sx={{ textTransform: "none" }}
            >
              <div style={{ border: "1px solid gray" }}>
                {ventureLogo ?
                  <>
                    <Box sx={{}}>
                      <img
                        src={ventureLogo}
                        style={{ width: "100px", height: "93px" }}
                      />
                    </Box>
                  </>
                  :
                  <Box sx={{ padding: "6px" }}>
                    <img
                      src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/lo-removebg-preview.png`}
                      style={{ width: "50px", height: "50px" }}
                    />
                    <Typography
                      sx={{
                        textShadow: "1px 1px #fff",
                        fontWeight: "bold",
                        fontSize  : "8pt",
                        color     : "white"
                      }}
                    >
                      Click to Upload
                    </Typography>
                  </Box>
                }
              </div>
              {/* // )} */}
            </Button>
          </Box>

          <CustomTextField
            label={"Primary Point of Contact"}
            type={"text"}
            name={"businessContact"}
            value={details.businessContact}
            // handledetailsChange={handledetailsChange}
            placeholder={"Enter name of person"}
            disabled={true}
          />
          <CustomTextField
            label={"Primary Business Email"}
            type={"email"}
            name={"businessEmail"}
            value={details.businessEmail}
            disabled={true}
          // handledetailsChange={handledetailsChange}
          />
        </Box>
      </Box>
    </Box>
  );

}
