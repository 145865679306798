import HistoryIcon from "@mui/icons-material/History";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import {
  Box, Typography, Tooltip
} from "@mui/material";
import Divider from "@mui/material/Divider";
import React from "react";
import { useHistory } from "react-router";
import styles from "./MyAccount.module.css";

import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";

const LightTooltip = styled(({ className, ...props }) =>
  <Tooltip {...props} classes={{ popper: className }} />
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    // color: "rgba(0, 0, 0, 0.87)",
    boxShadow      : theme.shadows[1],
    fontSize       : "14px",
    color          : "#FF7F00",
    border         : "1px solid #FF7F00",
    paddingTop     : "5px 10px",
    borderRadius   : "10px",
    fontWeight     : "bold"
  }
}));

export default function MyProfile (props) {

  const history = useHistory();
  const isUATDisabled = true;

  
  return (
    // <Box>
    <Box id={ styles.mainBox }>
      <Box id={ styles.contentBox }>
        <Box id={ styles.subContentBox }>
          <Box id={ styles.leftTabBox }>
            <Box id={ styles.arrowIconBox }></Box>
            {/* my profile box */ }
            <Box
              id={ styles.myProfileButtonBox }
              onClick={ () => {

                history.push("/myprofile");
              
              } }
            >
              { props.myProfileColor ?
                <Box sx={ { marginLeft: "25px" } }>
                  <img
                    src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/user-circle-tabIconBlue.png` }
                    alt=""
                  />
                </Box>
                :
                <Box sx={ { marginLeft: "25px" } }>
                  <img
                    src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/user-circle-tabIcon.png` }
                    alt=""
                  />
                </Box>
              }

              <Typography
                id={
                  props.myProfileColor
                    ? styles.profileAfterClick
                    : styles.profileBeforeClick
                }
              >
                My Profile
              </Typography>
            </Box>
            <Divider />
            {/* ----individual items in tab------ */ }
            <Box
              id={ styles.loginSecurityBtnBox }
              //
              onClick={ () => {

                history.push("/loginsecurity");
              
              } }
            >
              {/* security icons goes here */ }

              { props.securityColor ?
                <Box sx={ { marginLeft: "25px" } }>
                  <img
                    src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/securityTabIconBlue.png` }
                    alt=""
                  />
                </Box>
                :
                <Box sx={ { marginLeft: "25px" } }>
                  <img
                    src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/securityTabIcon.png` }
                    alt=""
                  />
                </Box>
              }
              <Box id={ styles.loginSecurityTypoBox }>
                <Typography
                  id={
                    props.securityColor
                      ? styles.seecurityAfterClick
                      : styles.securityBeforeClick
                  }
                >
                  Login & Security
                </Typography>
              </Box>
            </Box>
            {/* -------- */ }
            <Divider />
            {
              isUATDisabled ? <>
                <LightTooltip placement="top" title="Coming soon">
                  <Box
                    id={ styles.loginSecurityBtnBox }
                    onClick={ () => {

                      // history.push("/mysubscriptions");
              
                    } }
                  >
                    { props.subscriptionColor ?
                      <Box sx={ { marginLeft: "23px" } }>
                        <SubscriptionsIcon sx={ { fontSize: "19px", color: "blue" } } />
                      </Box>
                      :
                      <Box sx={ { marginLeft: "23px" } }>
                        <SubscriptionsIcon
                          sx={ { fontSize: "19px", color: "#808080" } }
                        />
                      </Box>
                    }
                    <Box id={ styles.loginSecurityTypoBox }>
                      <Typography
                        id={
                          props.subscriptionColor
                            ? styles.seecurityAfterClick
                            : styles.securityBeforeClick
                        }
                      >
                  My Subscriptions
                      </Typography>
                    </Box>
                  </Box>
                </LightTooltip>
                {/* -------- */ }
                <Divider />
                {/* ----individual items in tab (billing history)------ */ }
                <LightTooltip placement="top" title="Coming soon">
                  <Box
                    id={ styles.loginSecurityBtnBox }
                    onClick={ () => {

                      // history.push("/billinghistory");
              
                    } }
                  >
                    { props.billingHistoryColor ?
                      <Box sx={ { marginLeft: "23px", marginTop: "2px" } }>
                        <HistoryIcon sx={ { fontSize: "21px", color: "#071AFF" } } />
                      </Box>
                      :
                      <Box sx={ { marginLeft: "23px", marginTop: "2px" } }>
                        <HistoryIcon sx={ { fontSize: "21px", color: "#808080" } } />
                      </Box>
                    }
                    <Box id={ styles.loginSecurityTypoBox }>
                      <Typography
                        id={
                          props.billingHistoryColor
                            ? styles.seecurityAfterClick
                            : styles.securityBeforeClick
                        }
                      >
                  Billing History
                      </Typography>
                    </Box>
                  </Box>
                </LightTooltip>
                {/* -------- */ }
                <Divider />
              </> : <>
                <Box
                  id={ styles.loginSecurityBtnBox }
                  onClick={ () => {

                    history.push("/mysubscriptions");
              
                  } }
                >
                  { props.subscriptionColor ?
                    <Box sx={ { marginLeft: "23px" } }>
                      <SubscriptionsIcon sx={ { fontSize: "19px", color: "blue" } } />
                    </Box>
                    :
                    <Box sx={ { marginLeft: "23px" } }>
                      <SubscriptionsIcon
                        sx={ { fontSize: "19px", color: "#808080" } }
                      />
                    </Box>
                  }
                  <Box id={ styles.loginSecurityTypoBox }>
                    <Typography
                      id={
                        props.subscriptionColor
                          ? styles.seecurityAfterClick
                          : styles.securityBeforeClick
                      }
                    >
                  My Subscriptions
                    </Typography>
                  </Box>
                </Box>
                {/* -------- */ }
                <Divider />
                {/* ----individual items in tab (billing history)------ */ }
                <Box
                  id={ styles.loginSecurityBtnBox }
                  onClick={ () => {

                    history.push("/billinghistory");
              
                  } }
                >
                  { props.billingHistoryColor ?
                    <Box sx={ { marginLeft: "23px", marginTop: "2px" } }>
                      <HistoryIcon sx={ { fontSize: "21px", color: "#071AFF" } } />
                    </Box>
                    :
                    <Box sx={ { marginLeft: "23px", marginTop: "2px" } }>
                      <HistoryIcon sx={ { fontSize: "21px", color: "#808080" } } />
                    </Box>
                  }
                  <Box id={ styles.loginSecurityTypoBox }>
                    <Typography
                      id={
                        props.billingHistoryColor
                          ? styles.seecurityAfterClick
                          : styles.securityBeforeClick
                      }
                    >
                  Billing History
                    </Typography>
                  </Box>
                </Box>
                {/* -------- */ }
                <Divider /></>
            }
            
            {/* ----individual items in tab (subscriptions)------ */ }
            
          </Box>

          <Box id={ styles.rightContentBox }>
            {/* --------------------------------- */ }

            { props.children }

            {/* ---------------------- */ }
          </Box>
        </Box>
      </Box>
    </Box>
  );

}
