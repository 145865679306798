import React, { forwardRef, useEffect, useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box, Dialog, Grid, IconButton, Slide, Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getGtm } from "../../../../services/gtm";
import { getSegments } from "../../../../services/persona";
import SegmentsPersonasDialog from "../../Customer/TargetSegments/SegmentsPersonasDialog";
import ProductMessagingColumns from "../../GTM/Positioning/ProductMessagingColumns";

const Transition = forwardRef(function Transition(props, ref) {

  return <Slide direction="up" ref={ref} {...props} />;

});

export default function PitchGtmValueMessage() {

  const [ segments, setSegments ] = useState([]);
  const [ segmentsDialog, setSegmentsDialog ] = useState([]);
  const [ segmentdialogbox, setSegmentDialog ] = useState(false);

  let { venture } = useSelector(state => {

    return { ...state };
  
  });

  const history = useHistory();

  useEffect(() => {

    fetchGtm();
  
  }, []);

  useEffect(() => {

    fetchSegments();
  
  }, []);

  const fetchSegments = async () => {

    try {

      const response = await getSegments(venture.customer);
      const customer = response.data;

      setSegmentsDialog(customer.segmentsPersonas.segments);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const fetchGtm = async () => {

    try {

      const response = await getGtm(venture.gtm);

      setSegments(response.data.positioning.segments);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const segmentDialogopen = () => {

    setSegmentDialog(true);
  
  };
  const segmentDialogclose = () => {

    setSegmentDialog(false);
  
  };

  return (
    <Box>
      <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
        <Box>
          <Typography
            sx={{ fontSize: "20px", color: "#5F5E5E", fontWeight: "bold" }}
          >
            GTM
          </Typography>
        </Box>
        <ChevronRightIcon sx={{ color: "gray" }} />
        <Box>
          <Typography
            sx={{ fontSize: "20px", color: "#5F5E5E", fontWeight: "bold" }}
          >
            Positioning
          </Typography>
        </Box>
        <ChevronRightIcon sx={{ color: "gray" }} />
        <Box>
          <Typography>Value Messages</Typography>
        </Box>
      </Box>

      <Box>
        <Typography sx={{ fontSize: "12px" }}>
          Create customized ‘value’ messages for each of your target user
          personas. Ideally a 1-liner, speaking directly to the persona.
        </Typography>
      </Box>

      {/* ---------- */}
      <Box sx={{ marginTop: "20px" }}>
        {segments && segments.length > 0 ?
          segments.map((eachSegment, segmentindex) => {

            return (
              <Grid container mb={3}>
                <Typography
                  sx={{
                    color        : "#071AFF",
                    fontWeight   : "bold",
                    textTransform: "capitalize",
                    marginBottom : "10px",
                    cursor       : "pointer"
                  }}
                  component="span"
                  onClick={() => {

                    segmentDialogopen();
                  
                  }}
                >
                  {eachSegment.segment.name}
                </Typography>
                <Dialog
                  open={segmentdialogbox}
                  TransitionComponent={Transition}
                  keepMounted
                  PaperProps={{
                    style: {
                      borderRadius   : "20px",
                      backdropFilter : "blur(10px)",
                      backgroundColor: "rgba(255,255,255,0.80)",
                      margin         : "20px",
                      padding        : "0% 2%",
                      height         : "100%"
                    }
                  }}
                  maxWidth="md"
                  fullWidth
                >
                  <Box sx={{ alignSelf: "flex-end" }}>
                    <IconButton component="span" onClick={segmentDialogclose}>
                      <HighlightOffIcon />
                    </IconButton>
                  </Box>
                  <SegmentsPersonasDialog segments={segmentsDialog} />
                </Dialog>

                <Grid
                  item
                  md={12}
                  sx={{
                    background: "#F8F9FF 0% 0% no-repeat padding-box",
                    boxShadow : "0px 3px 6px #00000029",
                    padding   : "30px",
                    overflowX : "auto",
                    display   : "flex"
                  }}
                  columnGap={5}
                >
                  {eachSegment.personas.length > 0 ?
                    eachSegment.personas.map((eachPersona, personaindex) => {

                      return <ProductMessagingColumns
                        eachSegment={eachSegment}
                        eachPersona={eachPersona}
                        personaindex={personaindex}
                        segmentindex={segmentindex}
                        //   handleChange={handleChange}
                      />;
                    
                    })
                    :
                    <Box
                      sx={{
                        display       : "flex",
                        width         : "100%",
                        alignItems    : "center",
                        justifyContent: "center"
                      }}
                    >
                      <Typography sx={{ textAlign: "center" }}>
                        No Personas Created
                      </Typography>
                    </Box>
                  }
                </Grid>
              </Grid>
            );
          
          })
          :
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                textAlign     : "center",
                minHeight     : "40vh",
                display       : "flex",
                justifyContent: "center",
                alignItems    : "center"
              }}
            >
              <Typography fontWeight="900">
                {/* No valid user personas found. Kindly create relevant user
                segments and personas in the Customer module, to continue
                working on this section. */}
                You have not created any user personas yet. Click
                <a
                  style={{
                    cursor        : "pointer",
                    color         : "blue",
                    textDecoration: "underline"
                  }}
                  onClick={() => {

                    history.push("/gtmsegmentspersonas");
                  
                  }}
                >
                  here
                </a>
                to add customer segments & create user personas. After you’re
                done, return to Positioning section from the GTM Canvas.
              </Typography>
            </Grid>
          </Grid>
        }
      </Box>
    </Box>
  );

}
