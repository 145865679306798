import {
  Box, Grid, IconButton, LinearProgress, Typography
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import BackButton from "../../../../components/BackButton";
import ErrorMessage from "../../../../components/ErrorMessage";
import ModuleSections from "../../../../components/ModuleSections";

import axiosService from "../../../../services/axiosService";
import styles from "./UserJourneyMap.module.css";

const FlowchartUpload = () => {

  const [ flowcharts, setFlowcharts ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ progress, setProgress ] = useState(0);
  const [ sizeerrormsg, setSizeerrormsg ] = useState("");
  const [ fileslengtherrormsg, setFileslengtherrormsg ] = useState("");
  // const [ filestypeerrormsg, setFilestypeerrormsg ] = useState("");
  const [ successmsg, setSuccessmsg ] = useState("");

  const { user, venture } = useSelector(state => ({ ...state }));
  // let ventureid = venture._id;
  let userid = user._id;

  // for uploading flowcharts
  const hiddenFileInput = useRef(null);
  const history = useHistory();

  const handleClick = () => {

    hiddenFileInput.current.click();
  
  };

  const maxSelectFile = event => {

    // create file object
    let files = event.target.files;

    if (files.length > 4) {

      const msg = "Only 4 images can be uploaded at a time";

      setFileslengtherrormsg(msg);
      // discard selected file
      event.target.value = null;
      
      return false;
    
    }
    
    return true;
  
  };

  const checkMimeType = event => {

    let files = event.target.files;
    let err = "";
    const types = [ "image/png", "image/jpeg" ];

    for (var x = 0; x < files.length; x++) {

      if (types.every(type => files[x].type !== type)) {

        err += files[x].type + " is not a supported format\n";
      
      }
    
    }

    if (err !== "") {

      // setFilestypeerrormsg(err);
      event.target.value = null;
      console.error(err);
      
      return false;
    
    }
    
    return true;
  
  };

  const checkFileSize = event => {

    let files = event.target.files;
    let size = 2048;
    let err = "";

    for (var x = 0; x < files.length; x++) {

      let filesize = Math.round(files[x].size / 1024);

      if (filesize > size) {

        err += files[x].name + " is too large\n";
      
      }
    
    }
    if (err !== "") {

      setSizeerrormsg(err);
      event.target.value = null;
      
      return false;
    
    }
    
    return true;
  
  };

  const changeFlowchart = event => {

    event.preventDefault();
    if (maxSelectFile(event) && checkFileSize(event) && checkMimeType(event)) {

      // if return true allow to setState
      setFlowcharts(event.target.files);
    
    } else {

      setLoading(false);
      setTimeout(() => {

        setSizeerrormsg("");
        setFileslengtherrormsg("");
        // setFilestypeerrormsg("");
      
      }, 3000);
      
      return;
    
    }
  
  };

  useEffect(() => {

    if (flowcharts.length) {

      uploadFlowchart();
    
    }
  
  }, [ flowcharts ]);

  const uploadFlowchart = async () => {

    try {

      setLoading(true);
      const formData = new FormData();

      for (let i = 0; i < flowcharts.length; i++) {

        formData.append("flowcharts", flowcharts[i]);
      
      }
      formData.append("customerid", venture.customer);
      formData.append("userid", userid);
      const response = await axiosService.post(
        `${process.env.REACT_APP_ADDRESS}/upload_flowcharts`,
        formData,
        {
          onUploadProgress: ProgressEvent => {

            const { loaded, total } = ProgressEvent;
            const percentage = Math.floor(
              ((loaded / 1000) * 100) / (total / 1000)
            );

            setProgress(percentage);
          
          }
        }
      );

      setSuccessmsg(response.data);
      setFlowcharts([]);
      setTimeout(() => {

        setLoading(false);
        setSuccessmsg("");
      
      }, 3000);
      history.push("/userjourneymaps");
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  return (
    <ModuleSections>
      <div className="box-display-style-for-next-and-back-button-with-justify">
        {/* <Grid item xs={12} sx={{ padding: "30px 60px 0px" }}> */ }
        <div className="box-indiv-header-con">
          <Box id={ styles.box2 }>
            <Box sx={ { display: "flex", alignItems: "center" } }>
              <Typography
                sx={ { fontWeight: "bold", fontSize: "20px", color: "#5F5E5E" } }>
                Customer
              </Typography>
              <ChevronRightIcon sx={ { color: "#707070", margin: "0px 10px" } } />
              <Typography sx={ { fontSize: "16px", color: "#868686" } }>
                User Journey Maps
              </Typography>
            </Box>
          </Box>
        </div>

        <Grid container id={ styles.maingrid2 } sx={ { marginTop: "30px" } }>
          <Box id={ styles.uploadbox } component="span" onClick={ handleClick }>
            <IconButton
              sx={ {
                position: "absolute",
                padding : "0px",
                top     : "5px",
                right   : "10px"
              } }
              onClick={ e => {

                e.stopPropagation();
                history.push("/userjourneymaps");
              
              } }>
              <HighlightOffIcon />
            </IconButton>
            { loading ?
              <Typography>Uploading...</Typography>
              :
              <Typography sx={ { textAlign: "center", fontSize: "12px" } }>
                Upload up to 4 images of user journey maps / process
                flowcharts you created outside this app.
              </Typography>
            }
            { loading ?
              <LinearProgress variant="determinate" value={ progress } />
              :
              <img
                src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/import.png` }
                id={ styles.imgicon }
              />
            }

            { sizeerrormsg || fileslengtherrormsg || successmsg ?
              <>
                { sizeerrormsg && <ErrorMessage errormsg={ sizeerrormsg } /> }
                { fileslengtherrormsg &&
                  <ErrorMessage errormsg={ fileslengtherrormsg } />
                }
                { successmsg &&
                  <>
                    <CheckCircleOutlineIcon
                      sx={ { color: "#108E00", marginLeft: "40%" } }
                      fontSize="large"
                    />
                    <Typography sx={ { fontSize: "12px" } }>
                      { successmsg }
                    </Typography>
                  </>
                }
              </>
              :
              <Typography
                sx={ {
                  fontSize     : "12px",
                  color        : "#071AFF",
                  display      : "flex",
                  flexDirection: "column"
                } }>
                <span>Click to Upload Flowchart</span>
                File must be .png or .jpg format & under 2MB each.
              </Typography>
            }
          </Box>
          <input
            type="file"
            id="flowcharts"
            name="flowcharts"
            ref={ hiddenFileInput }
            onChange={ changeFlowchart }
            style={ { display: "none" } }
            multiple
            accept="image/*"
          />
        </Grid>
        {/* </Grid> */ }
        <div className="ttribe-back-next-button-con">
          <BackButton
            restrictedBackClick={ () => history.push("/userjourneymaps") }
            backClick={ () => history.push("/userjourneymaps") }
          />
          {/* <Button
            style={{
              textTransform: "none",
              fontWeight: "bold",
              marginRight: "30px",
              float: "left",
              color: "#071AFF",
            }}
            onClick={() => }>
            <ArrowLeftIcon style={{ fontSize: "xx-large" }} /> back
          </Button> */}
        </div>
      </div>
    </ModuleSections>
  );

};

export default FlowchartUpload;
