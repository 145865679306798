import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const NextButton = props => {

  const { venture } = useSelector(state => ({ ...state }));

  
  return (
    <>
      <Grid
        item
        sx={ {
          display       : "flex",
          justifyContent: "flex-end",
          margin        : props.margin ? props.margin : "20px 0px"
        } }
      >
        <Box
          sx={ {
            display   : "flex",
            alignItems: "center",
            cursor    : "pointer"
          } }
          // onClick={
          //   venture.userRole === "Commenter"
          //     ? props.restrictedNextClick
          //     : props.nextClick
          // }
          // onClick={props.nextClick}
          onClick={ () => {

            if (venture.userRole === "Commenter") {

              props.restrictedNextClick();
            
            } else {

              props.nextClick();
            
            }
          
          } }
        >
          <Typography
            sx={ { color: "#071AFF", textTransform: "none", fontWeight: "bold" } }
          >
            next
          </Typography>
          <img
            src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/Icon awesome-caret-right.png` }
            alt="right-arrow"
            style={ { width: "8px", marginLeft: "10px" } }
          />
          {/* <ArrowRightIcon style={{ fontSize: "xx-large", color: "#071AFF" }} />*/ }
        </Box>
      </Grid>
    </>
  );

};

export default NextButton;
