/* eslint-disable camelcase */
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  Box, Button,
  Dialog, DialogContent, Grid, IconButton, TextField, Tooltip, Typography
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import BackNextButton from "../../../components/BackNextButton";
import DeleteCancel from "../../../components/DeleteCancel";
import IndividualSectionHeaders from "../../../components/IndividualSectionHeaders";
import ModuleSections from "../../../components/ModuleSections";
import Datas from "../../../Data/Problem.json";
import {
  deleteUserInterviewQuestionsFile, downloadUserInterviewQuestions,
  getUserInterviewDetails, updateUserInterviews
} from "../../../services/problemsolution";

import { makeStyles } from "@mui/styles";
import styles from "./Problem.module.css";
import Question from "./Question";
import UserInterviewQuestionsUploadFile from "./UserInterviewQuestionsUploadFile";

import { useUnmount } from "../../../components/useUnmount";
import {
  createProblemSolutionComment,
  deleteProblemSolutionComment,
  updateProblemSolutionComment
} from "../../../services/comment";

const useStyles = makeStyles(() => ({
  customWidth: {
    maxWidth       : 250,
    fontSize       : 10,
    backgroundColor: "white",
    color          : "#707070",
    boxShadow      : "0px 3px 6px #00000029",
    border         : "1px solid #707070",
    borderRadius   : 10
  }
}));

const UserInterviews = () => {

  const [ questions, setQuestions ] = useState([]);
  const [ comments, setComments ] = useState([]);
  const [ uploadedFile, setUploadedfile ] = useState("");
  const [ uploadedfilename, setUploadedfilename ] = useState("");
  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const [ dialogbox, setDialog ] = useState(false);
  const [ createdialogbox, setCreateDialog ] = useState(false);
  const [ othername, setOthername ] = useState("");
  const [ isdelete, setIsdelete ] = useState(false);
  const [ deletedialog, setDeletedialog ] = useState(false);
  const [ isDataLoading, setIsDataLoading ] = useState(true);

  const history = useHistory();
  // const pathname = history.location.pathname.substring(1);
  const hashvalue = history.location.hash.substring(1);

  const classes = useStyles();
  const content = Datas.guidecontent;

  let { user, venture } = useSelector(state => {

    return { ...state };
  
  });

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  const createDialogopen = () => {

    setCreateDialog(true);
  
  };
  const createDialogclose = () => {

    setCreateDialog(false);
  
  };

  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);
    
    } else {

      setCollapsecomment(true);
    
    }
  
  };

  useEffect(() => {

    fetchSolution();
  
  }, []);

  const fetchSolution = async () => {

    setIsDataLoading(true);

    try {

      const response = await getUserInterviewDetails(
        venture._id,
        venture.problemSolution
      );
      // console.log(response.data);
      const problemsolution = response.data.problemsolution;

      setQuestions(problemsolution.userInterviews.questions);
      setUploadedfile(response.data.uploadedFile);
      setUploadedfilename(response.data.uploadedfilename);
      setComments(problemsolution.comments.problem.userInterviews);
      setIsDataLoading(false);
    
    } catch (error) {

      console.error(error);
      setIsDataLoading(false);
    
    }
  
  };

  // To get the updated usestate value to save in useEffect cleanup
  const detailsRef = useRef(questions);

  detailsRef.current = questions;

  let isSubmitting = false;

  // useUnmount => custom hook to save while navigating
  useUnmount(() => {

    if (isDataLoading === false && isSubmitting === false) {

      handleSave();
    
    }
  
  });

  const handleSave = async () => {

    try {

      await updateUserInterviews(detailsRef.current, venture.problemSolution);
      
      return true;
    
    } catch (error) {

      console.error(error);
      
      return false;
    
    }
  
  };

  const handleSubmit = async () => {

    try {

      isSubmitting = true;

      if (isDataLoading === false) {

        await updateUserInterviews(
          questions,
          venture.problemSolution
        );
      
      }
      // console.log(response.data);

      history.push("/analysissynthesis");
      isSubmitting = false;
    
    } catch (error) {

      isSubmitting = false;
      console.error(error);
    
    }
  
  };

  const handleChange = (name, value, index) => {

    const newQuestions = questions.map((question, questionindex) => {

      if (index === questionindex) {

        return {
          ...question,
          [name]: value
        };
      
      } else {

        return question;
      
      }
    
    });

    setQuestions(newQuestions);
  
  };

  const addRow = async () => {

    setQuestions([
      ...questions,
      {
        key  : othername,
        value: ""
      }
    ]);
    setOthername("");
    await updateUserInterviews(
      [
        ...questions,
        {
          key  : othername,
          value: ""
        }
      ],
      venture.problemSolution
    );
    createDialogclose();
  
  };

  const removeRow = async index => {

    const newquestions = questions.filter((question, i) => index !== i);

    setQuestions(newquestions);
    await updateUserInterviews(newquestions, venture.problemSolution);
    setIsdelete(false);
  
  };

  const downloadQuestions = async () => {

    const response = await downloadUserInterviewQuestions(
      questions,
      venture.problemSolution
    );
    const blobData = new Blob([ response.data ]);
    const url = window.URL.createObjectURL(blobData);
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute(
      "download",
      `${venture.details.name} - User Interviews Questions.pdf`
    );
    document.getElementById("questionsdownload").appendChild(link);
    link.click();
  
  };

  const removeUserInterviewQuestionsFile = async () => {

    await deleteUserInterviewQuestionsFile(venture.problemSolution);
    fetchSolution();
  
  };

  const addComment = async (commentText, replyTo) => {

    try {

      const response = await createProblemSolutionComment(
        commentText,
        user._id,
        "problem",
        "userInterviews",
        venture.problemSolution,
        replyTo,
        window.location.href,
        "Problem > User Interviews"
      );

      setComments(response.data.comments.problem.userInterviews);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateComment = async (commentid, commentText) => {

    try {

      const response = await updateProblemSolutionComment(
        commentText,
        user._id,
        commentid,
        venture._id,
        window.location.href,
        "Problem > User Interviews"
      );

      setComments(response.data.comments.problem.userInterviews);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const deleteComment = async commentid => {

    try {

      const response = await deleteProblemSolutionComment(
        "problem",
        "userInterviews",
        venture.problemSolution,
        commentid,
        user._id,
        window.location.href,
        "Problem > User Interviews"
      );

      setComments(response.data.comments.problem.userInterviews);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  return (
    <ModuleSections
      Collapsecomment={ Collapsecomment }
      comments={ comments }
      addComment={ addComment }
      deleteComment={ deleteComment }
      updateComment={ updateComment }>
      <div className="box-display-style-for-next-and-back-button-with-justify">
        <div className="box-indiv-header-con">
          <IndividualSectionHeaders
            mainheading={ "Problem" }
            subheading={ "User Interviews" }
            headingcontent={
              "We've suggested several questions below. Edit/add to them as you need, and use them as a guide to conduct your user interviews.\nTake good notes!"
            }
            isGtm={ false }
            Dialogopen={ Dialogopen }
            Dialogclose={ Dialogclose }
            dialogbox={ dialogbox }
            collapse_comment_function={ collapse_comment_function }
            content={ content }
            isSaveButton={ true }
            handleSave={ handleSave }
          />
        </div>
        <Grid
          item
          xs={ 12 }
          id={ styles.maingrid }
          onClick={ () => {

            setIsdelete(false);
          
          } }>
          { hashvalue === "upload" ?
            <UserInterviewQuestionsUploadFile fetchSolution={ fetchSolution } />
            :
            <>
              <Box sx={ { display: "flex", marginBottom: "10px" } }>
                <Tooltip
                  disableFocusListener
                  title={
                    "Upload your consolidated interview notes here for future reference. Limit 1 file. Uploading a new file will replace the old one."
                  }
                  placement={ "top" }
                  classes={ { tooltip: classes.customWidth } }>
                  <Button
                    variant="outlined"
                    sx={ {
                      // backgroundColor: "#F0F1FF",
                      borderColor  : "#E1E2E9",
                      color        : "#071AFF",
                      textTransform: "none",
                      fontWeight   : "bold"
                    } }
                    onClick={ () => history.push({ hash: "upload" }) }
                    disabled={ venture.userRole === "Commenter" }>
                    <img
                      src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/${venture.userRole === "Commenter"
                        ? "disabled-upload.png"
                        : "upload.png"
                      }` }
                      style={ {
                        width      : "25px",
                        height     : "25px",
                        marginRight: "5px"
                      } }
                    />
                    Upload Notes
                  </Button>
                </Tooltip>

                { uploadedFile &&
                  <>
                    <span
                      style={ {
                        borderLeft: "1px solid #707070",
                        margin    : "0px 15px"
                      } }
                    />
                    <Box
                      component={ venture.userRole === "Commenter" ? "div" : "a" }
                      sx={ { display: "inline-flex", alignItems: "center" } }
                      href={ uploadedFile }>
                      <img
                        src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/${venture.userRole === "Commenter"
                          ? "disabled-download.png"
                          : "download.png"
                        }` }
                        style={ {
                          width      : "20px",
                          height     : "20px",
                          marginRight: "5px"
                        } }
                      />
                      <Typography
                        sx={ {
                          color:
                            venture.userRole === "Commenter"
                              ? "#b0b0b0"
                              : "#071AFF"
                        } }>
                        { uploadedfilename }
                      </Typography>
                    </Box>
                    { venture.userRole !== "Commenter" &&
                      <IconButton
                        onClick={ () => {

                          setDeletedialog(true);
                        
                        } }>
                        <DeleteIcon
                          fontSize="small"
                          sx={ { color: "#FF5F7C" } }
                        />
                      </IconButton>
                    }
                    <Dialog
                      open={ deletedialog }
                      PaperProps={ {
                        style: {
                          borderRadius  : "20px",
                          backdropFilter: "blur(45px)",
                          background    : "#FFFFFF 0% 0% no-repeat padding-box",
                          boxShadow     : "0px 3px 6px #00000029",
                          border        : "1px solid #E0E1E2"
                        }
                      } }>
                      <DialogContent>
                        <DeleteCancel
                          name={ "Are you sure you want to delete this file?" }
                          deleteClick={ () => {

                            removeUserInterviewQuestionsFile();
                            setDeletedialog(false);
                          
                          } }
                          cancelClick={ () => {

                            setDeletedialog(false);
                          
                          } }
                        />
                      </DialogContent>
                    </Dialog>
                  </>
                }
              </Box>

              { questions.map((question, index) => {

                return <Question
                  question={ question }
                  handleChange={ handleChange }
                  index={ index }
                  removeRow={ removeRow }
                  isdelete={ isdelete }
                />;
              
              }) }
              <Box display="flex">
                <Typography
                  sx={ {
                    textDecoration:
                      venture.userRole === "Commenter"
                        ? "#b0b0b0 underline"
                        : "#071AFF underline"
                  } }>
                  <Button
                    sx={ {
                      fontWeight   : "bold",
                      color        : "#071AFF",
                      textTransform: "none",
                      fontSize     : "16px"
                    } }
                    disabled={ venture.userRole === "Commenter" }
                    onClick={ createDialogopen }>
                    <AddCircleIcon
                      sx={ {
                        color:
                          venture.userRole === "Commenter"
                            ? "#b0b0b0"
                            : "#071AFF",
                        marginRight: "10px"
                      } }
                    />{ " " }
                    Add a question
                  </Button>
                </Typography>
                <Dialog
                  open={ createdialogbox }
                  PaperProps={ {
                    style: {
                      borderRadius  : "20px",
                      backdropFilter: "blur(45px)",
                      background    : "#FFFFFF 0% 0% no-repeat padding-box",
                      boxShadow     : "0px 3px 6px #00000029",
                      border        : "1px solid #707070"
                    }
                  } }
                  BackdropProps={ { invisible: true } }
                  maxWidth="md"
                  fullWidth>
                  <Box sx={ { alignSelf: "flex-end" } }>
                    <IconButton component="span" onClick={ createDialogclose }>
                      <HighlightOffIcon />
                    </IconButton>
                  </Box>

                  <DialogContent
                    sx={ {
                      display      : "flex",
                      flexDirection: "column",
                      alignItems   : "center"
                    } }>
                    <TextField
                      multiline
                      sx={ { width: "100%" } }
                      size="small"
                      label="Enter Question"
                      variant="outlined"
                      value={ othername }
                      onChange={ e => {

                        setOthername(e.target.value.trimStart());
                      
                      } }
                    />
                    <Button
                      variant="contained"
                      size="medium"
                      sx={ { backgroundColor: "#071AFF", marginTop: "20px" } }
                      onClick={ addRow }
                      disabled={ othername === "" }>
                      Create
                    </Button>
                  </DialogContent>
                </Dialog>

                <Typography
                  sx={ {
                    textDecoration:
                      isdelete || venture.userRole === "Commenter"
                        ? "#B0B0B0 underline"
                        : "#FF7F5C underline"
                  } }>
                  <Button
                    sx={ {
                      fontWeight   : "bold",
                      color        : "#FF7F5C",
                      textTransform: "none",
                      fontSize     : "16px"
                    } }
                    onClick={ e => {

                      e.stopPropagation();
                      setIsdelete(true);
                    
                    } }
                    disabled={ venture.userRole === "Commenter" || isdelete }>
                    <RemoveCircleIcon
                      sx={ {
                        color:
                          venture.userRole === "Commenter" || isdelete
                            ? "#B0B0B0"
                            : "#FF7F5C",
                        marginRight: "10px"
                      } }
                    />{ " " }
                    Remove a question
                  </Button>
                </Typography>
              </Box>
              <Box
                sx={ { display: "flex", justifyContent: "flex-end" } }
                id="questionsdownload">
                <Tooltip
                  disableFocusListener
                  title={ "Export questions to .pdf" }
                  placement={ "top" }
                  classes={ { tooltip: classes.customWidth } }>
                  <Button
                    variant="outlined"
                    sx={ {
                      width        : "100px",
                      borderColor  : "#071AFF",
                      color        : "#071AFF",
                      textTransform: "none",
                      borderRadius : "4px"
                    } }
                    onClick={ downloadQuestions }
                    disabled={ venture.userRole === "Commenter" }>
                    Export
                  </Button>
                </Tooltip>
              </Box>
            </>
          }
        </Grid>
        <div className="ttribe-back-next-button-con">
          { hashvalue == "upload" ?
            <BackButton
              restrictedBackClick={ () => history.push("/personaproblemmap") }
              backClick={ () =>
                hashvalue == "upload"
                  ? history.push("/userinterviews")
                  : history.push("/personaproblemmap")
              }
            />
            :
            <BackNextButton
              restrictedBackClick={ () => history.push("/personaproblemmap") }
              backClick={ () =>
                hashvalue == "upload" && isDataLoading === false
                  ? history.push("/userinterviews")
                  : history.push("/personaproblemmap")
              }
              restrictedNextClick={ () => history.push("/analysissynthesis") }
              nextClick={ () => {

                handleSubmit();
              
              } }
            />
          }
        </div>
      </div>
    </ModuleSections>
  );

};

export default UserInterviews;
