/* eslint-disable camelcase */
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box, Button, Dialog,
  DialogContent, Grid, IconButton, TextField,
  Tooltip
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BackNextButton from "../../../../components/BackNextButton";
import IndividualSectionHeaders from "../../../../components/IndividualSectionHeaders";
import ModuleSections from "../../../../components/ModuleSections";
import {
  getOperations,
  updateOperations
} from "../../../../services/operations";

// import MuiTableCell from "@material-ui/core/TableCell";
// import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import BizOp from "./BizOp";
import styles from "./BizOps.module.css";

import { useUnmount } from "../../../../components/useUnmount";
import {
  createOperationsComment,
  deleteOperationsComment,
  updateOperationsComment
} from "../../../../services/comment";

const OpBizOps = () => {

  const [ bizops, setBizops ] = useState([
    {
      key        : "",
      value      : "",
      tooltipData: ""
    }
  ]);
  const [ comments, setComments ] = useState([]);
  const [ dialogbox, setDialog ] = useState(false);
  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const [ createdialogbox, setCreateDialog ] = useState(false);
  const [ customTitle, setCustomTitle ] = useState("");
  const [ isdelete, setIsdelete ] = useState(false);
  const [ isDataLoading, setIsDataLoading ] = useState(true);

  const { user, venture } = useSelector(state => ({ ...state }));
  const history = useHistory();
  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);
    
    } else {

      setCollapsecomment(true);
    
    }
  
  };

  const createDialogopen = () => {

    setCreateDialog(true);
  
  };
  const createDialogclose = () => {

    setCreateDialog(false);
  
  };

  useEffect(() => {

    fetchOperations();
  
  }, []);

  const fetchOperations = async () => {

    setIsDataLoading(true);

    try {

      const response = await getOperations(venture.operations);

      setBizops(response.data.bizOps);
      setComments(response.data.comments.businessOperations);
      setIsDataLoading(false);
    
    } catch (error) {

      console.error(error);
      setIsDataLoading(false);
    
    }
  
  };

  // To get the updated usestate value to save in useEffect cleanup
  const detailsRef = useRef(bizops);

  detailsRef.current = bizops;

  let isSubmitting = false;

  // useUnmount => custom hook to save while navigating
  useUnmount(() => {

    if ( isDataLoading == false && !isSubmitting) {

      handleSave();
    
    }
  
  });

  const handleSave = async () => {

    try {

      await updateOperations(venture.operations, "bizOps", detailsRef.current);
      
      return true;
    
    } catch (error) {

      console.error(error);
      
      return false;
    
    }
  
  };

  const createRow = async () => {

    let newbizops = [ ...bizops ];

    newbizops.push({
      key  : customTitle,
      value: ""
    });
    setCustomTitle("");
    setBizops(newbizops);
    createDialogclose();
    await updateOperations(venture.operations, "bizOps", newbizops);
  
  };

  const removeRow = async bizopsindex => {

    const newbizops = bizops.filter((title, index) => bizopsindex !== index);

    setBizops(newbizops);
    setIsdelete(false);
    await updateOperations(venture.operations, "bizOps", newbizops);
  
  };

  const handleTextChange = (value, bizopindex) => {

    let newBizops = bizops.map((bizop, index) => {

      if (index === bizopindex) {

        return {
          ...bizop,
          ["value"]: value
        };
      
      } else {

        return bizop;
      
      }
    
    });

    setBizops(newBizops);
  
  };

  const handleSubmit = async () => {

    try {

      isSubmitting = true;
      if(isDataLoading == false){
        
        await updateOperations(
          venture.operations,
          "bizOps",
          bizops
        );
  
        history.push("/operationssummary");

      }
      isSubmitting = false;
    
    } catch (error) {

      isSubmitting = false;
      console.error(error);
    
    }
  
  };

  const addComment = async (commentText, replyTo) => {

    try {

      const response = await createOperationsComment(
        commentText,
        user._id,
        "businessOperations",
        "",
        venture.operations,
        replyTo,
        window.location.href,
        "Operations > Business Operations"
      );

      setComments(response.data.comments.businessOperations);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateComment = async (commentid, commentText) => {

    try {

      const response = await updateOperationsComment(
        commentText,
        user._id,
        commentid,
        venture._id,
        window.location.href,
        "Operations > Business Operations"
      );

      setComments(response.data.comments.businessOperations);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const deleteComment = async commentid => {

    try {

      const response = await deleteOperationsComment(
        "businessOperations",
        "",
        venture.operations,
        commentid,
        user._id,
        window.location.href,
        "Operations > Business Operations"
      );

      setComments(response.data.comments.businessOperations);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  // console.log(bizops);

  return (
    <ModuleSections
      Collapsecomment={ Collapsecomment }
      comments={ comments }
      addComment={ addComment }
      deleteComment={ deleteComment }
      updateComment={ updateComment }
    >
      <div className="box-display-style-for-next-and-back-button-with-justify">
        <div className="box-indiv-header-con">
          <IndividualSectionHeaders
            mainheading={ "Operations" }
            subheading={ "Business Operations" }
            headingcontent={ "" }
            isGtm={ false }
            Dialogopen={ Dialogopen }
            Dialogclose={ Dialogclose }
            dialogbox={ dialogbox }
            collapse_comment_function={ collapse_comment_function }
            content={ {
              dialogpurpose: "",
              dialoganalogy: "",
              dialogguide  : []
            } }
            //   isexpand={isexpand}
            isSaveButton={ true }
            handleSave={ handleSave }
          />
        </div>
        <Grid
          item
          xs={ 12 }
          id={ styles.bizOpsMainGrid }
          onClick={ () => {

            setIsdelete(false);
          
          } }
        >

          <Box>
            { bizops.map((bizop, bizopindex) => {

              return <BizOp
                bizop={ bizop }
                bizopindex={ bizopindex }
                isdelete={ isdelete }
                handleTextChange={ handleTextChange }
                removeRow={ removeRow }
              />;
            
            }) }
          </Box>
          <Box sx={ { marginBottom: "10px", marginTop: "20px" } }>
            {/* <Button id={styles.addRowButton} onClick={createDialogopen}>
            <AddCircleIcon fontSize="small" sx={{ marginRight: "10px" }}>
              
            </AddCircleIcon>
            Add Row
          </Button> */}
            <Tooltip title="Add Row" placement="right">
              <IconButton
                id={
                  venture.userRole === "Commenter"
                    ? styles.disabledaddRowButton
                    : styles.addRowButton
                }
                onClick={ createDialogopen }
                disabled={ venture.userRole === "Commenter" }
              >
                <AddCircleIcon sx={ { fontSize: "35px" } }> </AddCircleIcon>
              </IconButton>
            </Tooltip>
            {/* <Button
            id={isdelete ? styles.disableddelRowButton : styles.delRowButton}
            onClick={(e) => {
              e.stopPropagation();
              setIsdelete(true);
            }}
          >
            <RemoveCircleIcon fontSize="small" sx={{ marginRight: "10px" }} />
            Delete Row
          </Button> */}
          </Box>
          <Dialog
            open={ createdialogbox }
            PaperProps={ {
              style: {
                borderRadius  : "20px",
                backdropFilter: "blur(45px)",
                background    : "#FFFFFF 0% 0% no-repeat padding-box",
                boxShadow     : "0px 3px 6px #00000029",
                border        : "1px solid #707070"
              }
            } }
            BackdropProps={ { invisible: true } }
          >
            <Box sx={ { alignSelf: "flex-end" } }>
              <IconButton component="span" onClick={ createDialogclose }>
                <HighlightOffIcon />
              </IconButton>
            </Box>

            <DialogContent>
              <TextField
                size="small"
                label="Enter Title"
                variant="outlined"
                value={ customTitle }
                onChange={ e => {

                  setCustomTitle(e.target.value.trimStart());
                
                } }
              />
              <Button
                variant="contained"
                size="medium"
                sx={ { backgroundColor: "#071AFF", marginLeft: "10px" } }
                // onClick={createRow}
                onClick={ async () => {

                  await createRow();
                
                } }
                disabled={ customTitle ? false : true }
              >
                Create
              </Button>
            </DialogContent>
          </Dialog>
        </Grid>
        <div className="ttribe-back-next-button-con">
          <BackNextButton
            restrictedBackClick={ () => {

              history.push("/team");
            
            } }
            backClick={ () => {

              history.push("/team");
            
            } }
            restrictedNextClick={ () => {

              history.push("/operationssummary");
            
            } }
            nextClick={ () => {

              handleSubmit();
            
            } }
          />

        </div>
      </div>

    </ModuleSections>
  );

};

export default OpBizOps;
