import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  Button, Dialog,
  DialogContent,
  IconButton, Menu, MenuItem, TableBody, TableCell, TableRow
} from "@mui/material";
// import InputBase from "@mui/material/InputBase";
// import { styled } from "@mui/material/styles";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import styles from "./Milestone.module.css";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useLocation } from "react-router-dom";
import CustomMUISelect from "../../../../components/CustomMUISelect";
import CustomTextField from "../../../../components/CustomTextfield";
import DeleteCancel from "../../../../components/DeleteCancel";
import IndividualActivity from "./IndividualActivity";

// const BootstrapInputSelect = styled(InputBase)(({ theme }) => ({
//   "label + &": {
//     marginTop: theme.spacing(3)
//   },
//   "& .MuiInputBase-input": {
//     "&:hover": {
//       border: "1px solid black"
//     },
//     borderRadius: 4,
//     position    : "relative",
//     // backgroundColor: theme.palette.background.paper,
//     // backgroundColor: "#edeeff",
//     // lighter same shade
//     // backgroundColor: "#f2f3ff",
//     border      : "1px solid #e3e5ff",
//     fontSize    : 13,
//     width       : "7vw",
//     padding     : "5px 0px 0px 12px",
//     transition  : theme.transitions.create([ "border-color", "box-shadow" ])
//   }
// }));

// const BootstrapInputActivity = styled(InputBase)(({ theme }) => ({
//   "label + &": {
//     marginTop: theme.spacing(3),
//   },
//   "& .MuiInputBase-input": {
//     borderRadius: 4,
//     position: "relative",
//     // backgroundColor: "#edeeff",
//     // backgroundColor: "#f2f3ff",
//     border: "1px solid #e3e5ff",
//     fontSize: 12,
//     width: "7vw",
//     padding: "5px 0px 0px 12px",
//     transition: theme.transitions.create(["border-color", "box-shadow"]),
//   },
// }));

// const BootstrapInput = styled(InputBase)(({ theme }) => ({
//   "label + &": {
//     marginTop: theme.spacing(3)
//   },
//   "& .MuiInputBase-input": {
//     borderRadius: 4,
//     position    : "relative",
//     // backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
//     // backgroundColor: "#f2f3ff",
//     // backgroundColor: "#edeeff",
//     // backgroundColor: "#edeeff",
//     // backgroundColor: "#edeeff",

//     // border: "1px solid #ced4da",
//     border  : "1px solid #e3e5ff",
//     fontSize: 12,
//     // width: "9vw",
//     // width: "40%",
//     // padding: "10px 12px",
//     padding : "5px 6px"
//     // fontWeight: "bold",
//   }
// }));

// const BootstrapInputChild = styled(InputBase)(({ theme }) => ({
//   "label + &": {
//     marginTop: theme.spacing(3),
//   },
//   "& .MuiInputBase-input": {
//     borderRadius: 4,
//     position: "relative",
//     // backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
//     // backgroundColor: "#edeeff",
//     // backgroundColor: "#f2f3ff",
//     // border: "1px solid #ced4da",
//     border: "1px solid #e3e5ff",
//     fontSize: 11,
//     // width: "9vw",
//     // padding: "10px 12px",
//     // fontWeight: "bold",
//     padding: "5px 6px",
//   },
// }));

export default function MilestoneTableTest (props) {

  const [ deletemilestonedialogbox, setDeleteMilestoneDialog ] = useState(false);
  // const [deleteactivitydialogbox, setDeleteActivityDialog] = useState(false);
  const [ anchorEl, setAnchorEl ] = useState(null);
  // const [deletedialogbox, setDeleteDialog] = useState(false);
  const open = Boolean(anchorEl);
  // const [anchorElChild, setAnchorElChild] = useState(null);
  // const openChild = Boolean(anchorElChild);

  const handleClick = event => {

    setAnchorEl(event.currentTarget);
  
  };
  const handleClose = () => {

    setAnchorEl(null);
  
  };

  const { venture } = useSelector(state => ({ ...state }));

  // const handleClickChild = (event) => {
  //   setAnchorElChild(event.currentTarget);
  // };
  // const handleCloseChild = () => {
  //   setAnchorElChild(null);
  // };

  // const deleteDialogopen = () => {
  //   setDeleteDialog(true);
  // };
  // const deleteDialogclose = () => {
  //   setDeleteDialog(false);
  // };

  const deleteMilestoneDialogopen = () => {

    setDeleteMilestoneDialog(true);
  
  };
  const deleteMilestoneDialogclose = () => {

    setDeleteMilestoneDialog(false);
  
  };

  // const deleteActivityDialogopen = () => {
  //   setDeleteActivityDialog(true);
  // };
  // const deleteActivityDialogclose = () => {
  //   setDeleteActivityDialog(false);
  // };
  const {
    milestone,
    milestoneindex,
    ismilestonedelete
    // isactivitydelete,
    // selectedmilestoneindex
  } = props;

  const statusOptions = [
    {
      value: "Not Started"
    },
    {
      value: "In-Progress"
    },
    {
      value: "Completed"
    },
    {
      value: "On-Hold"
    }
  ];

  const location = useLocation();

  return (
    <>
      <TableBody id={ styles.milestoneTableBody }>
        <TableRow id={ styles.milestoneTableRow }>
          <TableCell id={ styles.tableCell }>
            { ismilestonedelete &&
              <>
                <Button
                  onClick={ () => {
                    // e.stopPropagation();
                    // deleteMilestoneDialogopen();
                  } }
                >
                  <RemoveCircleIcon
                    fontSize="small"
                    id={ styles.removeCircleIconRow }
                  />
                </Button>
              </>
            }
          </TableCell>

          <TableCell id={ styles.tableCellFirst }>
            <CustomTextField
              width={ "90%" }
              value={ milestone.name }
              border={ "1px solid #e3e5ff" }
              name="name"
              fontSize={ "12px" }
              padding={ "2px 0px" }
              handledetailsChange={ e =>
                props.handleMilestonesChange(
                  e.target.name,
                  e.target.value,
                  milestoneindex
                )
              }
              disabled={ location.pathname.includes("pitchdeckintro") }
            />
          </TableCell>
          <TableCell id={ styles.tableCell }>
            <CustomTextField
              disabled={ location.pathname.includes("pitchdeckintro") }
              width={ "88%" }
              value={ moment(milestone.startDate).format("YYYY-MM-DD") }
              type="date"
              border={ "1px solid #e3e5ff" }
              name="startDate"
              fontSize={ "12px" }
              padding={ "2px 0px" }
              // padding={"15px 7px"}
              handledetailsChange={ e => {

                if (
                  milestone.endDate &&
                  new Date(milestone.endDate).valueOf() <
                  new Date(e.target.value).valueOf()
                ) {

                  props.setErrormsg(
                    "End date should be greater than start date"
                  );
                  setTimeout(() => {

                    props.setErrormsg("");
                  
                  }, 5000);
                  
                  return;
                
                }
                props.handleMilestonesChange(
                  e.target.name,
                  e.target.value,
                  milestoneindex
                );
              
              } }
            />
          </TableCell>
          <TableCell id={ styles.tableCell }>
            <CustomTextField
              disabled={ location.pathname.includes("pitchdeckintro") }
              width={ "88%" }
              value={ moment(milestone.endDate).format("YYYY-MM-DD") }
              type="date"
              border={ "1px solid #e3e5ff" }
              name="endDate"
              fontSize={ "12px" }
              padding={ "2px 0px" }
              // padding={"15px 7px"}
              handledetailsChange={ e => {

                if (
                  milestone.startDate &&
                  new Date(e.target.value).valueOf() <
                  new Date(milestone.startDate).valueOf()
                ) {

                  props.setErrormsg(
                    "End date should be greater than start date"
                  );
                  setTimeout(() => {

                    props.setErrormsg("");
                  
                  }, 5000);
                  
                  return;
                
                }
                props.handleMilestonesChange(
                  e.target.name,
                  e.target.value,
                  milestoneindex
                );
              
              } }
            />
          </TableCell>
          <TableCell id={ styles.tableCellLast }>
            <CustomMUISelect
              value={ milestone.status }
              name="status"
              handleChange={ e =>
                props.handleMilestonesChange(
                  e.target.name,
                  e.target.value,
                  milestoneindex
                )
              }
              fontSize={ "13px" }
              // width={"7vw"}
              padding={ "7px 0px 7px 12px" }
              muiOptions={ statusOptions }
              optionFontSize={ "13px" }
              border={ "1px solid #e3e5ff" }
              disabled={ location.pathname.includes("pitchdeckintro") }
            />
            <IconButton
              onClick={ e => {

                handleClick(e);
              
              } }
              disabled={
                venture.userRole === "Commenter" ||
                location.pathname.includes("pitchdeckintro")
              }
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={ anchorEl }
              open={ open }
              onClose={ () => {

                handleClose();
              
              } }
              anchorOrigin={ {
                vertical  : "top",
                horizontal: "right"
              } }
              style={ { top: 5 } }
              sx={ {
                "& .MuiMenu-list": {
                  padding        : "0px",
                  backgroundColor: "#707070",
                  color          : "white"
                }
              } }
            >
              <MenuItem
                sx={ { fontSize: "12px" } }
                onClick={ e => {

                  e.stopPropagation();
                  deleteMilestoneDialogopen();
                  handleClose();
                
                } }
              >
                Delete
              </MenuItem>
            </Menu>
            <Dialog
              open={ deletemilestonedialogbox }
              PaperProps={ {
                style: {
                  borderRadius  : "20px",
                  backdropFilter: "blur(45px)",
                  background    : "#FFFFFF 0% 0% no-repeat padding-box",
                  boxShadow     : "0px 3px 6px #00000029",
                  border        : "1px solid #E0E1E2"
                }
              } }
            >
              <DialogContent>
                <DeleteCancel
                  name={
                    "Deleting the milestone will also delete the activities associated with it. Are you sure you want to continue?"
                  }
                  deleteClick={ e => {

                    e.stopPropagation();
                    props.removeMilestone(milestoneindex);
                    deleteMilestoneDialogclose();
                  
                  } }
                  cancelClick={ () => {

                    deleteMilestoneDialogclose();
                  
                  } }
                />
              </DialogContent>
            </Dialog>
          </TableCell>
        </TableRow>

        { milestone.activities.map((activity, activityindex) => {

          return (
            <IndividualActivity
              activity={ activity }
              handleActivitiesChange={ props.handleActivitiesChange }
              activityindex={ activityindex }
              milestoneindex={ milestoneindex }
              removeActivity={ props.removeActivity }
              statusOptions={ statusOptions }
              setErrormsg={ props.setErrormsg }
            />
          );
        
        }) }
      </TableBody>
      <TableRow>
        <TableCell></TableCell>

        <TableCell>
          <Button
            id={
              venture.userRole === "Commenter" ||
                location.pathname.includes("pitchdeckintro")
                ? styles.disabledActivityButton
                : styles.addActivityButton
            }
            onClick={ () => {

              props.addActivity(milestoneindex);
            
            } }
            disabled={
              venture.userRole === "Commenter" ||
              location.pathname.includes("pitchdeckintro")
            }
          >
            <AddCircleIcon fontSize="small" sx={ { marginRight: "10px" } }>
            </AddCircleIcon>
            Add Activity
          </Button>
        </TableCell>
      </TableRow>
    </>
  );

}
