
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { Country } from "country-state-city";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  createVenture,
  editVenture,
  getVetureById,
  listUserVentures
} from "../../../services/venture";

import BackSubmitButton from "../../../components/BackSubmitButton";
import BoxWithIcon from "../../../components/BoxWithIcon";
import CustomSelect from "../../../components/CustomSelect";
import CustomTextField from "../../../components/CustomTextfield";
import ErrorMessage from "../../../components/ErrorMessage";
import SaveCancelButton from "../../../components/SaveCancelButton";
import SuccessMessage from "../../../components/SuccessMessage";
import VentureData from "../../../Data/CreateVenture.json";
import styles from "./CreateVenture.module.css";
import NextPage from "./NextPage";

import Config from "../../../config/Config";
const data = VentureData;

const CreateProject = props => {

  const [ details, setDetails ] = useState({
    name             : "",
    location         : "",
    website          : "",
    linkedin         : "",
    otherSocialMedia : "",
    businessEmail    : "",
    businessContact  : "",
    currentStatus    : "",
    industryType     : "",
    otherIndustryType: "",
    fundingType      : "",
    otherFundingType : ""
  });

  const userAccess = Config.userAccess;
  const [ logo, setLogo ] = useState(null);
  const [ editlogo, setEditlogo ] = useState(null);
  const [ successmsg, setSuccessmsg ] = useState("");
  const [ errormsg, setErrormsg ] = useState(false);
  const [ savedisabled, setSavedisabled ] = useState(true);
  const [ loading, setLoading ] = useState(false);
  const [ userventures, setUserventures ] = useState([]);

  const ventureid = props.match.params.id;

  const history = useHistory();
  const location = useLocation();
  let dispatch = useDispatch();

  let intendedurl = ventureid ? location.state.intendedurl : "";
  let userRole = ventureid ? location.state.userRole : "";
  let id = Number(location.hash.substring(1));

  // for uploading logos
  const hiddenFileInput = useRef(null);

  const handleClick = () => {

    hiddenFileInput.current.click();
  
  };

  const { user } = useSelector(state => ({ ...state }));
  const userid = user._id;

  const preview = useMemo(() => {

    return logo ? URL.createObjectURL(logo) : null;
  
  }, [ logo ]);

  const handledetailsChange = event => {

    setSavedisabled(false);
    const name = event.target.name;
    const value = event.target.value.trimStart();

    setDetails(values => ({ ...values, [name]: value }));
  
  };

  useEffect(() => {

    listUserVentures(userid).then(res => {

      if (res.data) {

        setUserventures(res.data.userventures);

      }
    
    });

  }, []);

  useEffect(() => {

    if (ventureid) {

      getVetureById(ventureid).then(response => {

        const { venture, logo } = response.data;

        setEditlogo(logo);
        setDetails({
          name             : venture.details.name,
          location         : venture.details.location,
          website          : venture.details.website,
          linkedin         : venture.details.linkedin,
          otherSocialMedia : venture.details.otherSocialMedia,
          businessEmail    : venture.details.businessEmail,
          businessContact  : venture.details.businessContact,
          currentStatus    : venture.details.currentStatus,
          industryType     : venture.details.industryType,
          otherIndustryType: venture.details.otherIndustryType,
          fundingType      : venture.details.fundingType,
          otherFundingType : venture.details.otherFundingType
        });
        // setJourneyQuestions(venture.details.journeyQuestions);
      
      });
    
    }
  
  }, [ ventureid ]);

  // dropdown for location
  const countries = Country.getAllCountries();
  const updatedCountries = countries.map(country => ({
    label: country.name,
    value: country.name,
    ...country
  }));

  // dropdown for current State of the venture
  const stateOfVenture = data.current_status;
  const updatedStateOfVenture = stateOfVenture.map(ventureState => ({
    label: ventureState,
    value: ventureState,
    ...ventureState
  }));

  const industryType = data.industry_type;
  const updatedIndustryType = industryType.map(ventureState => ({
    label: ventureState,
    value: ventureState,
    ...ventureState
  }));

  const fundingType = data.funding_type;
  const updatedFundingType = fundingType.map(ventureState => ({
    label: ventureState,
    value: ventureState,
    ...ventureState
  }));

  const handleLocationChange = (name, value) => {

    setSavedisabled(false);
    setDetails(values => ({ ...values, [name]: value }));
  
  };

  const handleQuestionsChange = () => {

    setSavedisabled(false);
    // setJourneyQuestions((values) => ({ ...values, [name]: value }));
  
  };

  const handleSubmit = async event => {

    try {

      event.preventDefault();
      setLoading(true);
      if (details.name === "" || details.location === "" || details.currentStatus === "") {

        setLoading(false);
        setErrormsg("Please fill in the required (*) fields.");
        setTimeout(() => {

          setErrormsg("");
        
        }, 3000);
        
        return;
      
      }
      const formdata = new FormData();

      formdata.append("details", JSON.stringify(details));
      formdata.append("logo", logo);
      // formdata.append("journeyquestions", JSON.stringify(journeyquestions));
      formdata.append("userid", userid);
      let response;

      if (ventureid) {

        response = await editVenture(formdata, ventureid);
        dispatch({
          type   : "CURRENT_VENTURE",
          payload: {
            _id            : response.data._id,
            userid         : response.data.parentID,
            details        : response.data.details,
            conceptVision  : response.data.conceptVision,
            market         : response.data.market,
            customer       : response.data.customer,
            problemSolution: response.data.problemSolution,
            financials     : response.data.financials,
            gtm            : response.data.gtm,
            operations     : response.data.operations,
            pitchDeck      : response.data.pitchDeck,
            monetisation   : response.data.monetisation,
            userRole       : userRole
          }
        });
        setSuccessmsg("Changes saved");
        setTimeout(() => {

          setSuccessmsg("");
        
        }, 5000);
      
      } else {

        response = await createVenture(formdata);
        dispatch({
          type   : "CURRENT_VENTURE",
          payload: {
            _id            : response.data._id,
            userid         : response.data.parentID,
            details        : response.data.details,
            conceptVision  : {},
            market         : response.data.market,
            customer       : response.data.customer,
            problemSolution: response.data.problemSolution,
            financials     : response.data.financials,
            gtm            : response.data.gtm,
            operations     : response.data.operations,
            pitchDeck      : response.data.pitchDeck,
            monetisation   : response.data.monetisation,
            userRole       : "Owner"
          }
        });
        props.history.push("/venturefinish");
      
      }
      setLoading(false);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const changeLogo = async event => {

    setSavedisabled(false);
    let file = event.target.files[0];

    setLogo(file);
  
  };

  return (
    <BoxWithIcon>
      <div className="box-display-style-starting-content-con">
        <Grid id={styles.maingrid}>
          <form onSubmit={handleSubmit} style={{ margin: "12px 0px", paddingBottom: "1em" }}>
            {id ?
              <NextPage
                ventureid={ventureid ? ventureid : ""}
                handleSubmit={handleSubmit}
                intendedurl={intendedurl}
                userRole={userRole}
                // values={journeyquestions}
                handleChange={handleQuestionsChange}
                successmsg={successmsg}
                savedisabled={savedisabled}
                loading={loading}
              />
              :
              <>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{
                    display       : "flex",
                    flexDirection : "row",
                    paddingLeft   : "0px",
                    rowGap        : "5px",
                    alignItems    : "center",
                    justifyContent: "space-between"
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    component="div"
                    style={{ margin: "1em 0px", fontWeight: "bold" }}
                  >
                    Let's create your venture project
                  </Typography>
                  <div>
                    {successmsg && <SuccessMessage successmsg={successmsg} />}
                    {errormsg && <ErrorMessage errormsg={errormsg} />}
                  </div>
                  <div style={{ textAlign: "center", fontWeight: "bold" }}>

                    { (!preview && !editlogo) && <label htmlFor="logo" id={styles.labeltext}>
                      Logo
                    </label> }
                    
                    <Grid style={{ display: "flex", marginBottom: "10px" }}>
                      <Button
                        onClick={handleClick}
                        sx={{ textTransform: "none" }}
                      >
                        <img
                          alt=""
                          src={preview ? preview : ventureid ? editlogo : preview}
                          className={styles.logoimg}
                        />
                        <input
                          type="file"
                          disabled={ ventureid ? false : (user.access === userAccess.freetier && userventures?.length === 1) || (user.access === userAccess.pitch && userventures?.length === 1) || (user?.access === userAccess.ideation && userventures?.length === 1) ? true : (user.access === userAccess.pro && userventures?.length >= 3) || (user.access === userAccess.admin && userventures?.length >= 3) || userventures?.length >= 3 ? true : false}
                          id="logo"
                          ref={hiddenFileInput}
                          onChange={changeLogo}
                          style={{ display: "none" }}
                          accept="image/*"
                        />
                        {preview || editlogo ?
                          <></>
                          :
                          <div style={{ position: "absolute" }}>
                            <img
                              alt=""
                              src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/lo-removebg-preview.png`}
                              style={{ width: "50px", height: "50px" }}
                            />
                            <Typography id={styles.uploadtext}>
                              Click to Upload
                            </Typography>
                          </div>
                        }
                      </Button>
                    </Grid>
                  </div>
                </Grid>

                <Grid container sx={{ height: "70%" }}>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ display: "flex", flexDirection: "column", rowGap: "5px" }}
                  >
                    <div className="tribe-venture-row-list-con">

                      <CustomTextField
                        error={
                          errormsg ? details.name === "" ? true : false : false
                        }
                        label={"Venture Name*"}
                        type={"text"}
                        name={"name"}
                        value={details.name}
                        disabled={ ventureid ? false : (user.access === userAccess.freetier && userventures?.length === 1) || (user.access === userAccess.pitch && userventures?.length === 1) || (user?.access === userAccess.ideation && userventures?.length === 1) ? true : (user.access === userAccess.pro && userventures?.length >= 3) || (user.access === userAccess.admin && userventures?.length >= 3) || userventures?.length >= 3 ? true : false}
                        handledetailsChange={handledetailsChange}
                        tooltipdata={ !ventureid && ((user?.access === userAccess.freetier && userventures?.length === 1) || (user?.access === userAccess.ideation && userventures?.length === 1) ||
                            (user?.access === userAccess.pitch && userventures?.length === 1)) ?
                          "You can create only one project with the <Starter/ Pitch> Plan. Upgrade to Pro Plan to create up to three projects." :
                          ((user?.access === userAccess.pro && userventures?.length >= 3) ||
                              (user?.access === userAccess.admin && userventures?.length >= 3) ||
                              userventures?.length >= 3) &&
                            "You can create up to three projects with the Pro Plan"}
                      />
                    </div>
                    <div className="tribe-venture-row-list-con">

                      <CustomSelect
                        error={
                          errormsg ? details.location === "" ? "#FF0000" : "" : ""
                        }
                        label={"Country*"}
                        name={"location"}
                        disabled={ ventureid ? false : (user.access === userAccess.freetier && userventures?.length === 1) || (user.access === userAccess.pitch && userventures?.length === 1) || (user?.access === userAccess.ideation && userventures?.length === 1) ? true : (user.access === userAccess.pro && userventures?.length >= 3) || (user.access === userAccess.admin && userventures?.length >= 3) || userventures?.length >= 3 ? true : false}
                        value={details.location}
                        options={updatedCountries}
                        maxMenuHeight={"15vh"}
                        placeholder={"Select your Country"}
                        handleChange={e => {

                          handleLocationChange("location", e.value);
                        
                        }}
                        tooltipdata={ !ventureid && ((user?.access === userAccess.freetier && userventures?.length === 1) || (user?.access === userAccess.ideation && userventures?.length === 1) ||
                            (user?.access === userAccess.pitch && userventures?.length === 1)) ?
                          "You can create only one project with the <Starter/ Pitch> Plan. Upgrade to Pro Plan to create up to three projects." :
                          ((user?.access === userAccess.pro && userventures?.length >= 3) ||
                              (user?.access === userAccess.admin && userventures?.length >= 3) ||
                              userventures?.length >= 3) &&
                            "You can create up to three projects with the Pro Plan"}
                      />
                    </div>
                    <div className="tribe-venture-row-list-con">

                      <CustomSelect
                        error={
                          errormsg ? details?.currentStatus === "" ? "#FF0000" : "" : ""
                        }
                        label={"Current State of my Venture*"}
                        name={"currentStatus"}
                        value={details.currentStatus}
                        titleData={details.currentStatus}
                        placement={"right-start"}
                        options={updatedStateOfVenture}
                        maxMenuHeight={"15vh"}
                        disabled={ ventureid ? false : (user.access === userAccess.freetier && userventures?.length === 1) || (user.access === userAccess.pitch && userventures?.length === 1) || (user?.access === userAccess.ideation && userventures?.length === 1) ? true : (user.access === userAccess.pro && userventures?.length >= 3) || (user.access === userAccess.admin && userventures?.length >= 3) || userventures?.length >= 3 ? true : false}
                        placeholder={"Select your venture state"}
                        handleChange={e => {

                          handleLocationChange("currentStatus", e.value);
                        
                        }}
                        tooltipdata={ !ventureid && ((user?.access === userAccess.freetier && userventures?.length === 1) || (user?.access === userAccess.ideation && userventures?.length === 1) ||
                            (user?.access === userAccess.pitch && userventures?.length === 1)) ?
                          "You can create only one project with the <Starter/ Pitch> Plan. Upgrade to Pro Plan to create up to three projects." :
                          ((user?.access === userAccess.pro && userventures?.length >= 3) ||
                              (user?.access === userAccess.admin && userventures?.length >= 3) ||
                              userventures?.length >= 3) &&
                            "You can create up to three projects with the Pro Plan"}
                      />
                    </div>
                    <div className="tribe-venture-row-list-con">

                      <CustomSelect
                        label={"Industry Focus"}
                        name={"Industry focus"}
                        value={details.industryType}
                        titleData={details.industryType}
                        placement={"right-start"}
                        options={updatedIndustryType}
                        maxMenuHeight={"15vh"}
                        placeholder={"Select your Industry focus"}
                        handleChange={e => {

                          handleLocationChange("industryType", e.value);
                        
                        }}
                      />
                    </div>
                    {
                      details.industryType === "Other" ? <div className="tribe-venture-row-list-con">

                        <CustomTextField
                          label={"Other Industry focus"}
                          type={"text"}
                          name={"otherIndustryType"}
                          value={details.otherIndustryType}
                          handledetailsChange={handledetailsChange}
                        />
                      </div> : <></>
                    }
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{
                      display      : "flex",
                      flexDirection: "column",
                      paddingLeft  : "30px",
                      rowGap       : "5px"
                    }}
                  >
                    <div className="tribe-venture-row-list-con">

                      <CustomSelect
                        label={"Funding status"}
                        name={"Funding status"}
                        value={details.fundingType}
                        titleData={details.fundingType}
                        placement={"right-start"}
                        maxMenuHeight={"15vh"}
                        options={updatedFundingType}
                        placeholder={"Select your funding status"}
                        handleChange={e => {

                          handleLocationChange("fundingType", e.value);
                        
                        }}
                      />
                    </div>
                    {
                      details.fundingType === "Other" ?
                        <div className="tribe-venture-row-list-con">
                          <CustomTextField
                            label={"Other Funding status"}
                            type={"text"}
                            name={"otherFundingType"}
                            value={details.otherFundingType}
                            handledetailsChange={handledetailsChange}
                          />
                        </div> : <></>
                    }
                    <div className="tribe-venture-row-list-con">

                      <Grid style={{
                        display      : "flex",
                        flexDirection: "row",
                        alignItems   : "center",
                        columnGap    : "5px"
                      }}>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          style={{
                            display      : "flex",
                            flexDirection: "column",
                            rowGap       : "5px"
                          }}
                        >
                          <CustomTextField
                            label={"Website"}
                            type={"text"}
                            name={"website"}
                            value={details.website}
                            handledetailsChange={handledetailsChange}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          style={{
                            display      : "flex",
                            flexDirection: "column",
                            rowGap       : "5px"
                          }}
                        >
                          <CustomTextField
                            label={"LinkedIn Company Page"}
                            type={"text"}
                            name={"linkedin"}
                            value={details.linkedin}
                            handledetailsChange={handledetailsChange}
                          />
                        </Grid>
                      </Grid>
                    </div>
                    <div className="tribe-venture-row-list-con">

                      <CustomTextField
                        label={"Primary Point of Contact"}
                        type={"text"}
                        name={"businessContact"}
                        value={details.businessContact}
                        handledetailsChange={handledetailsChange}
                        placeholder={"Enter name of person"}
                      />
                    </div>
                    <div className="tribe-venture-row-list-con">

                      <CustomTextField
                        label={"Primary Business Email"}
                        type={"email"}
                        name={"businessEmail"}
                        value={details.businessEmail}
                        handledetailsChange={handledetailsChange}
                      />
                    </div>
                  </Grid>
                </Grid>
              </>
            }
          </form>
        </Grid>
        <form onSubmit={handleSubmit} className="box-display-style-footer-content-con">
          {

            ventureid ?
              <>{<SaveCancelButton
                disabled={savedisabled || loading}
                saveClick={handleSubmit}
                cancelClick={() => {

                  intendedurl === location.pathname
                    ? history.push("/dashboard")
                    : history.push({
                      pathname: intendedurl,
                      state   : {
                        intendedurl: intendedurl,
                        userRole   : userRole
                      }
                    });
                
                }}
              />}</> : <><BackSubmitButton
                disabled={loading || ventureid ? false : (user.access === userAccess.freetier && userventures?.length === 1) || (user.access === userAccess.pitch && userventures?.length === 1) || (user?.access === userAccess.ideation && userventures?.length === 1) ? true : (user.access === userAccess.pro && userventures?.length >= 3) || (user.access === userAccess.admin && userventures?.length >= 3) || userventures?.length >= 3 ? true : false }
                backClick={() =>
                  history.push("/welcomepage")
                }
                saveClick={handleSubmit}
                tooltipdata={ !ventureid &&
                  ((user?.access === userAccess.freetier && userventures?.length === 1) || (user?.access === userAccess.ideation && userventures?.length === 1) ||
                    (user?.access === userAccess.pitch && userventures?.length === 1)) ?
                  "You can create only one project with the <Starter/ Pitch> Plan. Upgrade to Pro Plan to create up to three projects." :
                  ((user?.access === userAccess.pro && userventures?.length >= 3) ||
                      (user?.access === userAccess.admin && userventures?.length >= 3) ||
                      userventures?.length >= 3) &&
                    "You can create up to three projects with the Pro Plan"}
              /></>
          }

        </form>
      </div>

    </BoxWithIcon>
  );

};

export default CreateProject;
