import React from "react";
import { Box } from "@mui/material";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
// import { useSelector } from "react-redux";
// import "./UploadPitchDeck.css";
// import "./UploadPitchDeck.css";

import "./PitchPdfPageView.css";

export default function PitchPdfPageView (props) {

  const {
    // pdfPages,
    // uploadedPitchName,
    setPdfPages,
    pdfPageNumber,
    setPdfPageNumber,
    uploadedPitchUrl
  } = props;

  // const { user, venture } = useSelector(state => ({ ...state }));

  const onDocumentLoadSuccess = ({ numPages }) => {

    setPdfPages(numPages);
    setPdfPageNumber(1);
  
  };

  return (
    <Box
      sx={ {
        display       : "flex",
        justifyContent: "center",
        alignItems    : "center",
        // height: "31vw",
        // height: "30vw",
        height        : "71vh",
        flexDirection : "column"
        // border: 1,
      } }
    >
      <Document
        // file={"./pdfDoc/todac2.pdf"}
        onLoadSuccess={ onDocumentLoadSuccess }
        file={ uploadedPitchUrl }
      // file={"./pdfDoc/todacSample.pdf"}
      >
        <Page
          className="customClass"
          renderAnnotationLayer={ false }
          height={ 500 }
          scale={ 0.8 }
          pageNumber={ pdfPageNumber }
        />
      </Document>
    </Box>
  );

}
