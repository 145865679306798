import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import jsondata from "../Data/data.json";
import { getVetureById, updatePercent } from "../services/venture";
import BoxWithoutIcon from "./BoxWithoutIcon";
import DialogComponent from "./DialogComponent";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  arrowicon: {
    position: "absolute",
    left    : "46%",
    top     : "47%",
    height  : "70px",
    width   : "100px"
  }
}));

const ModulesSummary = ({ history }) => {

  const hashvalue = history.location.hash.substring(1);
  const pathname = history.location.pathname.substring(1);
  const data = jsondata[pathname];
  const [ details, setDetails ] = useState(data ? data.details : {});

  const classes = useStyles();

  let { user, venture } = useSelector(state => {

    return { ...state };
  
  });

  useEffect(() => {

    getVenture();
  
  }, []);

  const getVenture = async () => {

    try {

      const response = await getVetureById(venture._id);
      const Venture = response.data.venture;

      setDetails(Venture.percentValue[data.modulename]);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const handleChange = async (name, value) => {

    setDetails(values => ({ ...values, [name]: value }));
    await updatePercent(venture._id, data.modulename, name, value);
  
  };

  return (
    <BoxWithoutIcon>
      <Grid
        container
        sx={{ height: "100%", postion: "relative" }}
        onClick={() => {

          if (hashvalue === "instructions") {

            history.push("/marketsummary");
          
          }
        
        }}
      >
        <img
          src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/summary-right.gif`}
          alt="summary-arrow"
          className={classes.arrowicon}
        />
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display   : "flex",
            alignItems: "center",
            justifyContent:
              hashvalue === "instructions" ? "flex-end" : "center"
          }}
        >
          <Box
            sx={{
              height        : "90%",
              width         : "80%",
              display       : "flex",
              flexDirection : "column",
              justifyContent: "space-between"
            }}
          >
            <Box>
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                fontWeight="bold"
                mb={0}
              >
                Congratulations!
              </Typography>
              <Typography fontWeight="bold" variant="body2">
                You have reached the end of the {data.title} module
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="body2"
                gutterBottom
                style={{ marginBottom: "20px", fontSize: "16px" }}
              >
                Use the sliders on the right, to indicate how much of each
                section you have completed. This will help you and your team
                keep track of progress and prioritize sections to work on.
              </Typography>
            </Box>
            <Box>
              <Button
                variant="contained"
                style={{
                  border         : "1px solid #071AFF",
                  backgroundColor: "#F8F9FF",
                  color          : "blue",
                  textTransform  : "capitalize",
                  padding        : "6px 30px",
                  marginRight    : "30px",
                  borderRadius   : "8px",
                  boxShadow      : "none"
                }}
                onClick={() => history.push("/dashboard")}
              >
                Dashboard
              </Button>
              { pathname === "conceptvisionsummary" ? <></> : <Button
                style={{
                  backgroundColor: "#071aff",
                  textTransform  : "capitalize",
                  padding        : "6px 30px",
                  marginRight    : "30px",
                  borderRadius   : "8px",
                  boxShadow      : "none",
                  display:
                    data.nextModule === "solutionintro" &&
                      user.access === "freetier"
                      ? "none"
                      : "initial"
                }}
                variant="contained"
                onClick={() => {

                  if (
                    data.nextModule === "monetisationintro" &&
                    user.access === "ideation"
                  ) {

                    history.push("/operationsintro");
                  
                  } else if (
                    data.nextModule === "gotomarketintro" &&
                    user.access === "ideation"
                  ) {

                    history.push("/pitchdeckintro");
                  
                  } else if (
                    data.nextModule === "solutionintro" &&
                    user.access === "pitch"
                  ) {

                    history.push("/pitchdeckintro");
                  
                  } else {

                    history.push(`/${data.nextModule}`);
                  
                  }
                
                }}
              >
                Next Module
                <ArrowForwardIosIcon
                  sx={{ fontSize: "16px", marginLeft: "5px" }}
                />
              </Button>}
              
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display   : "flex",
            alignItems: "center",
            justifyContent:
              hashvalue === "instructions" ? "flex-start" : "center"
          }}
        >
          <Box
            sx={pathname === "conceptvisionsummary" ? {
              display      : "flex",
              flexDirection: "column",
              width        : "80%",
              height       : "33%",
              justifyContent:
                data.images.length > 2 ? "space-between" : "flex-start",
              alignItems: "center"
            } : {
              display      : "flex",
              flexDirection: "column",
              width        : "80%",
              height       : "90%",
              justifyContent:
                data.images.length > 2 ? "space-between" : "flex-start",
              alignItems: "center"
            }}
          >
            {data.images.map((eachData, index) => {

              return<DialogComponent
                title={eachData.imagetitle}
                imgsrc={eachData.imagesrc}
                sectionname={eachData.sectionname}
                details={details}
                handleChange={handleChange}
                index={index}
                length={data.images.length}
                hashvalue={hashvalue}
              />;
            
            })}
          </Box>
        </Grid>
      </Grid>
    </BoxWithoutIcon>
  );

};

export default ModulesSummary;
