import {
  TextField, Tooltip
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";


const CustomTextField = props => {

  const history = useHistory();
  const pathname = history.location.pathname.substring(1);

  const { venture } = useSelector(state => ({ ...state }));

  
  return (
    <>
      <label
        htmlFor={props.name}
        style={pathname === "createventure" ? {
          color       : "#707070",
          fontSize    : props.labelfontsize,
          marginBottom: "0px"
        } : {
          color       : "#707070",
          fontSize    : props.labelfontsize,
          marginBottom: "5px"
        }}
      >
        {props.label}
      </label>
      {/* <OutlinedInput */}

      {

        props.tooltipdata ? <Tooltip title={props.tooltipdata} placement="top">

    

          <TextField
            // variant="standard"
            // variant="outlined"

            placeholder={props.placeholder ? props.placeholder : ""}
            disabled={
              venture.userRole === "Commenter"
                ? true
                : props.disabled
                  ? props.disabled
                  : false
            }
            type={props.type}
            error={props.error ? props.error : false}
            id={props.name}
            name={props.name}
            value={props.value}
            onChange={props.handledetailsChange}
            onKeyDown={props.onKeyDown}
            // inputProps={{
            //   style: {
            //     padding: props.padding,
            //   },
            // }}
            sx={{
              backgroundColor           : props.backgroundColor,
              "& .MuiOutlinedInput-root": {
                padding: props.padding
              },
              "& .MuiOutlinedInput-input"         : { height: "4px" },
              // marginBottom: "10px",
              "& .MuiOutlinedInput-notchedOutline": {
                boxShadow: props.boxShadow,
                // border: "none",
                border   : `${props.border} !important`,

                borderRadius: props.borderRadius
              },
              "& .MuiInputBase-input": {
                fontSize    : props.fontSize,
                textAlign   : props.textAlign,
                // border: props.border,
                borderRadius: 1
              },
              width: props.width
            }}
          />
        </Tooltip>
          : <TextField
            // variant="standard"
            // variant="outlined"

            placeholder={props.placeholder ? props.placeholder : ""}
            disabled={
              venture.userRole === "Commenter"
                ? true
                : props.disabled
                  ? props.disabled
                  : false
            }
            type={props.type}
            error={props.error ? props.error : false}
            id={props.name}
            name={props.name}
            value={props.value}
            onChange={props.handledetailsChange}
            onKeyDown={props.onKeyDown}
            // inputProps={{
            //   style: {
            //     padding: props.padding,
            //   },
            // }}
            sx={{
              backgroundColor           : props.backgroundColor,
              "& .MuiOutlinedInput-root": {
                padding: props.padding
              },
              "& .MuiOutlinedInput-input"         : { height: "4px" },
              // marginBottom: "10px",
              "& .MuiOutlinedInput-notchedOutline": {
                boxShadow: props.boxShadow,
                // border: "none",
                border   : `${props.border} !important`,

                borderRadius: props.borderRadius
              },
              "& .MuiInputBase-input": {
                fontSize    : props.fontSize,
                textAlign   : props.textAlign,
                // border: props.border,
                borderRadius: 1
              },
              width: props.width
            }}
          />

      }

    </>
  );

};

export default CustomTextField;
