/* eslint-disable react/react-in-jsx-scope */
import axios from "axios";
// import { Redirect } from "react-router-dom";
import { auth } from "../firebase";
// import store from "../store";
// import { signOut } from "@firebase/auth";

axios.interceptors.request.use(async config => {

  // Important: request interceptors **must** return the request.
  // console.log("refreshToken");
  let user = auth.currentUser;

  if (user) {

    const token = await user.getIdToken();

    config.headers.authtoken = token;
    // console.log("req in handle response: ", config);
  
  }
  
  return config;

});
axios.interceptors.response.use(
  response => {

    // console.log(response);
    return response;
  
  },
  function (error) {

    // console.log(error);
    // if (error.response.status === 401) {

    //   // console.log("///unauthorized///");
    //   signOut(auth);
    //   store.dispatch({
    //     type   : "LOGOUT",
    //     payload: null
    //   });
    //   <Redirect to="/login" />;
    
    // }
    
    return Promise.reject(error);
  
  }
);

export default {
  get   : axios.get,
  post  : axios.post,
  put   : axios.put,
  patch : axios.patch,
  delete: axios.delete
};
