import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import React from "react";

const BackSubmitButton = props => {

  return (
    <Grid
      item
      style={{
        textAlign     : "right",
        display       : "flex",
        justifyContent: "space-between",
        margin        : props.margin ? props.margin : "20px 0px"
      }}
    >
      <Box
        sx={{
          display   : "flex",
          alignItems: "center",
          cursor    : "pointer"
        }}
        onClick={props.backClick}
      >
        <ArrowLeftIcon style={{ fontSize: "xx-large", color: "#071AFF" }} />
        <Typography
          sx={{ color: "#071AFF", textTransform: "none", fontWeight: "bold" }}
        >
          back
        </Typography>
      </Box>
      {
        props.tooltipdata ? <Box>
          <Tooltip title={props.tooltipdata}
            placement="top">

            <div>
              <Button
                style={{
                  textTransform  : "none",
                  backgroundColor: "#00000042",
                  // color: "",
                  width          : "130px",
                  height         : "40px"
                }}
                type="submit"
                disabled={props.disabled ? true : false}
              >
                Submit
              </Button>
            </div>

          </Tooltip>

        </Box> : <Button
          style={{
            textTransform  : "none",
            backgroundColor: "#071AFF",
            color          : "white",
            width          : "130px",
            height         : "40px"
          }}
          type="submit"
          disabled={props.disabled ? true : false}
        >
          Submit
        </Button>
      }

    </Grid>
  );

};

export default BackSubmitButton;
