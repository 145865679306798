import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  Box, Dialog, DialogContent, IconButton, TextField, Tooltip, Typography
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import DeleteCancel from "../../../components/DeleteCancel";

import { makeStyles } from "@mui/styles";
import CustomMultilineTextfield from "../../../components/CustomMultilineTextfield";

const useStyles = makeStyles(() => ({
  customWidth: {
    maxWidth       : 500,
    fontSize       : 10,
    backgroundColor: "white",
    color          : "#707070",
    boxShadow      : "0px 3px 6px #00000029",
    border         : "1px solid #707070",
    borderRadius   : 10
  }
}));

const Question = props => {

  const [ deletedialogbox, setDeleteDialog ] = useState(false);
  const [ iseditable, setIseditable ] = useState(false);
  const [ numberOfLines, setNumberofLines ] = useState(2);
  const classes = useStyles();
  const question = props.question;
  const index = props.index;
  const isdelete = props.isdelete;

  let { venture } = useSelector(state => {

    return { ...state };
  
  });

  const deleteDialogopen = () => {

    setDeleteDialog(true);
  
  };
  const deleteDialogclose = () => {

    setDeleteDialog(false);
  
  };

  const numOfLines = textboxRef => {

    // console.log("text ref", textboxRef)
    let h0 = textboxRef.target.style.height;

    textboxRef.target.style.height = "auto";
    let h1 = textboxRef.target.scrollHeight;

    textboxRef.target.style.height = h0;
    // console.log("LINes", Math.ceil(h1 / 23))
    setNumberofLines(Math.ceil(h1 / 23));
  
  };

  return (
    <Box key={ index } display="flex">
      { isdelete &&
        <>
          <Box sx={ { marginRight: "10px" } }>
            <IconButton
              onClick={ e => {

                e.stopPropagation();
                deleteDialogopen();
              
              } }
              sx={ { padding: "0px" } }
            >
              <RemoveCircleIcon
                fontSize="small"
                sx={ {
                  color: "#ff7f5c"
                } }
              />
            </IconButton>
          </Box>
          <Dialog
            open={ deletedialogbox }
            PaperProps={ {
              style: {
                borderRadius  : "20px",
                backdropFilter: "blur(45px)",
                background    : "#FFFFFF 0% 0% no-repeat padding-box",
                boxShadow     : "0px 3px 6px #00000029",
                border        : "1px solid #E0E1E2"
              }
            } }
          >
            <DialogContent>
              <DeleteCancel
                name={ "Are you sure you want to delete this question?" }
                deleteClick={ async e => {

                  e.stopPropagation();
                  await props.removeRow(index);
                  deleteDialogclose();
                
                } }
                cancelClick={ () => {

                  deleteDialogclose();
                
                } }
              />
            </DialogContent>
          </Dialog>
        </>
      }
      <Box sx={ { width: "100%" } }>
        { iseditable ?
          <TextField
            variant="standard"
            sx={ { width: "100%" } }
            value={ question.key }
            onChange={ e => {

              props.handleChange("key", e.target.value, index);
            
            } }
            onBlur={ () => {

              setIseditable(!iseditable);
            
            } }
          />
          : venture.userRole !== "Commenter" ?
            <Tooltip
              disableFocusListener
              title={ "These Questions are editable" }
              placement="bottom"
              classes={ { tooltip: classes.customWidth } }
            >
              <Typography
                component="span"
                onClick={ () => {

                  setIseditable(!iseditable);
                
                } }
              >
                { question.key }
              </Typography>
            </Tooltip>
            :
            <Typography component="span">{ question.key }</Typography>
        }
        {/* <TextField
          variant="outlined"
          size="small"
          sx={{ width: "100%", margin: "10px 0px" }}
          value={question.value}
          onChange={(e) => {
            props.handleChange("value", e.target.value, index);
          }}
        /> */}
        <Box sx={ { margin: "10px 0px" } }>
          <CustomMultilineTextfield
            minRows={ 2 }
            maxRows={ numberOfLines }
            value={ question.value }
            width={ "100%" }
            handledetailsChange={ e => {

              props.handleChange("value", e.target.value, index);
            
            } }
            handleClick={ e =>
              numOfLines(e)
            }
            onMouseEnter={ e => numOfLines(e) }
            onMouseLeave={ () => setNumberofLines(2) }
            handleBlur={ () => setNumberofLines(2) }
            isOverflowHidden
          />
        </Box>
      </Box>
    </Box>
  );

};

export default Question;
