/* eslint-disable camelcase */
import React, { forwardRef, useEffect, useRef, useState } from "react";
import {
  Box, Dialog,
  Grid, IconButton, Slide, Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BackNextButton from "../../../../components/BackNextButton";
import IndividualSectionHeaders from "../../../../components/IndividualSectionHeaders";
import ModuleSections from "../../../../components/ModuleSections";
import Data from "../../../../Data/Gtm.json";
import { getGtm, updateGtmPositioningValues } from "../../../../services/gtm";
import { getProblemSolution } from "../../../../services/problemsolution";
import { getSegments } from "../../../../services/persona";
import SegmentsPersonasDialog from "../../Customer/TargetSegments/SegmentsPersonasDialog";
import ProductMessagingColumns from "./ProductMessagingColumns";

import {
  createGtmComment,
  deleteGtmComment,
  updateGtmComment
} from "../../../../services/comment";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import styles from "./Positioning.module.css";

import { makeStyles } from "@mui/styles";
import { useUnmount } from "../../../../components/useUnmount";

const useStyles = makeStyles(() => ({
  customWidth: {
    maxWidth       : 500,
    fontSize       : 10,
    backgroundColor: "white",
    color          : "#707070",
    boxShadow      : "0px 3px 6px #00000029",
    border         : "1px solid #707070",
    borderRadius   : 10,
    whiteSpace     : "pre-wrap"
  },
  segmentName: {
    color        : "#071AFF",
    fontWeight   : "bold",
    textTransform: "capitalize",
    marginBottom : "10px",
    cursor       : "pointer"
  },
  gridBox: {
    background: "#F8F9FF 0% 0% no-repeat padding-box",
    boxShadow : "0px 3px 6px #00000029",
    padding   : "30px",
    overflowX : "auto",
    display   : "flex"
  },
  upbtn: {
    width     : "25px",
    height    : "25px",
    position  : "sticky",
    float     : "right",
    bottom    : "7%",
    right     : "0%",
    cursor    : "pointer",
    marginLeft: "15px"
  }
}));

const Transition = forwardRef(function Transition (props, ref) {

  return <Slide direction="up" ref={ ref } { ...props } />;

});

const ProductMessaging = () => {

  const [ segments, setSegments ] = useState([]);
  const [ segmentsDialog, setSegmentsDialog ] = useState([]);
  const [ comments, setComments ] = useState([]);
  const [ dialogbox, setDialog ] = useState(false);
  const [ segmentdialogbox, setSegmentDialog ] = useState(false);
  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const [ isDataLoading, setIsDataLoading ] = useState(true);
  // const [showTopBtn, setShowTopBtn] = useState(false);
  let refScrollUp = useRef(null);
  const history = useHistory();
  const classes = useStyles();
  const pathname = history.location.pathname.substring(1);
  const content = Data.gtmpositioning;

  let { user, venture } = useSelector(state => {

    return { ...state };
  
  });

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  const segmentDialogopen = () => {

    setSegmentDialog(true);
  
  };
  const segmentDialogclose = () => {

    setSegmentDialog(false);
  
  };

  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);
    
    } else {

      setCollapsecomment(true);
    
    }
  
  };

  // const handleScroll = (e) => {
  //   const bottom =
  //     e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
  //   if (bottom) {
  //     setShowTopBtn(true);
  //   } else {
  //     setShowTopBtn(false);
  //   }
  // };

  useEffect(() => {

    fetchGtm();
    fetchSegments();
  
  }, []);
  
  const fetchSegments = async () => {

    setIsDataLoading(true);

    try {

      const solutionResponse = await getProblemSolution(venture.problemSolution);
      let fullSegmentData;

      try {

        const response = await getSegments(venture.customer);
        const resSegment = response?.data?.segmentsPersonas?.segments;

        if (resSegment) {
          
          fullSegmentData = resSegment;

        } else {

          setSegmentsDialog([]);
          fullSegmentData = [];

        }


      } catch (error) { console.error(error); }

      const solutionData = solutionResponse?.data?.segments;

      if (solutionData) {

        const solutionGridData = [];

        solutionData?.map(solData => {

          solData?.personas?.map(persona => {

            solutionGridData.push(persona);

          });

        });

        fullSegmentData?.map(eachSegment => {

          eachSegment?.personas?.map(pp => {

            solutionGridData.map(dd => {

              if (dd._id == pp._id) {

                pp.SolutionGrid = dd.SolutionGrid;

              }

            });

          });

        });
        
        setSegmentsDialog(fullSegmentData);

      } else {

        setSegmentsDialog([]);
        setComments([]);

      }

      setIsDataLoading(false);
    
    } catch (error) {

      console.error(error);
      setIsDataLoading(false);
    
    }

  };

  const fetchGtm = async () => {
    
    let fullSegmentData;

    setIsDataLoading(true);
    
    try {
      
      const response = await getGtm(venture.gtm);

      setComments(response.data.comments.positioning.productValueMessage);
      fullSegmentData = response.data.positioning.segments;
      
      try {
        
        const solutionResponse = await getProblemSolution(venture.problemSolution);
        
        const solutionData = solutionResponse?.data?.segments;
        
        if (solutionData) {
          
          const solutionGridData = [];

          solutionData?.map(solData => {

            solData?.personas?.map(persona => {

              solutionGridData.push(persona);

            });

          });

          fullSegmentData?.map(eachSegment => {

            eachSegment?.personas?.map(pp => {

              solutionGridData.map(dd => {
              
                if (dd._id === pp.persona._id) {

                  pp.persona.SolutionGrid = dd.SolutionGrid;

                }

              });

            });

          });

        }

        setSegments(fullSegmentData);


      } catch (error) {

        console.error(error);

      }

      setIsDataLoading(false);

    } catch (error) {

      console.error(error);

      setIsDataLoading(false);
    
    }
  
  };

  // To get the updated usestate value to save in useEffect cleanup
  const detailsRef = useRef(segments);

  detailsRef.current = segments;

  let isSubmitting = false;

  // useUnmount => custom hook to save while navigating
  useUnmount(() => {

    if (isDataLoading == false && !isSubmitting) {

      handleSave();
    
    }
  
  });

  const handleSave = async () => {

    try {

      await updateGtmPositioningValues(
        venture.gtm,
        "segments",
        detailsRef.current
      );
      
      return true;
    
    } catch (error) {

      console.error(error);
      
      return false;
    
    }
  
  };

  const handleChange = (name, value, segmentindex, personaindex) => {

    const newSegments = segments.map((eachSegment, index) => {

      if (index === segmentindex) {

        let newSegment = { ...eachSegment };

        newSegment["personas"] = eachSegment.personas.map(
          (eachPersona, index) => {

            if (index === personaindex) {

              let newpersona = { ...eachPersona };

              newpersona[name] = value;
              
              return newpersona;
            
            } else {

              return eachPersona;
            
            }
          
          }
        );
        
        return newSegment;
      
      } else {

        return eachSegment;
      
      }
    
    });

    setSegments(newSegments);
  
  };

  const handleSubmit = async () => {

    try {

      isSubmitting = true;
      if(isDataLoading == false){

        await updateGtmPositioningValues(venture.gtm, "segments", segments);

      }
      history.push("/positioningsummary");
      isSubmitting = false;
    
    } catch (error) {

      isSubmitting = false;
      console.error(error);
    
    }
  
  };

  // const handleScrollUp = () => {
  //   console.log(refScrollUp);
  //   refScrollUp.current.scrollTop = 0;
  // };

  const addComment = async (commentText, replyTo) => {

    try {

      const response = await createGtmComment(
        commentText,
        user._id,
        "positioning",
        "productValueMessage",
        venture.gtm,
        replyTo,
        window.location.href,
        "GTM > Positioning > Value Messages"
      );

      setComments(response.data.comments.positioning.productValueMessage);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateComment = async (commentid, commentText) => {

    try {

      const response = await updateGtmComment(
        commentText,
        user._id,
        commentid,
        venture._id,
        window.location.href,
        "GTM > Positioning > Value Messages"
      );

      setComments(response.data.comments.positioning.productValueMessage);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const deleteComment = async commentid => {

    try {

      const response = await deleteGtmComment(
        "positioning",
        "productValueMessage",
        venture.gtm,
        commentid,
        user._id,
        window.location.href,
        "GTM > Positioning > Value Messages"
      );

      setComments(response.data.comments.positioning.productValueMessage);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  return (
    <ModuleSections
      Collapsecomment={ Collapsecomment }
      comments={ comments }
      addComment={ addComment }
      deleteComment={ deleteComment }
      updateComment={ updateComment }>
      <div className="box-display-style-for-next-and-back-button-with-justify">
        <div className="box-indiv-header-con">
          <IndividualSectionHeaders
            mainheading={ "Positioning" }
            subheading={ "Value Messages" }
            headingcontent={
              "Create customized ‘value’ messages for each of your target user personas. Ideally a 1-liner, speaking directly to the persona. "
            }
            isGtm={ true }
            // isGtmChevron={true}
            Dialogopen={ Dialogopen }
            Dialogclose={ Dialogclose }
            dialogbox={ dialogbox }
            collapse_comment_function={ collapse_comment_function }
            content={ content }
            tooltip={
              "As a result of using your product/solution, how will their life be better than before?\nWhenever possible, share any metrics than help prove the value of your offering to that customer persona.\n\nContinuing with the Healthvita example used in the previous section, say one of the target personas is COO of a large hospital system, your value message could look like this:\n\nImprove your hospital’s bottom-line and scale more efficiently, by improving patient outcomes and reducing hospitalizations for your cardiac patients, and allowing your doctors more time to see new patients. On average, by using our product, our customers have reduced hospitalizations by 40% and improved patient throughput by 25%.   "
            }
            tooltipWidth="500px"
            isGtmChevron={ pathname.includes("gtm") ? "gtmV" : "customer" }
            isSaveButton={ true }
            handleSave={ handleSave }
          />
        </div>
        <Grid
          item
          xs={ 12 }
          id={ styles.maingrid }
          ref={ refScrollUp }
        // onScroll={handleScroll}
        >
          <Grid sx={ { minHeight: "40vh" } }>
            { segments && segments.length > 0 ?
              segments.map((eachSegment, segmentindex) => {

                return (
                  <Grid container mb={ 3 }>
                    <Typography
                      className={ classes.segmentName }
                      component="span"
                      onClick={ () => {

                        segmentDialogopen();
                      
                      } }>
                      { eachSegment.segment.name }
                    </Typography>
                    <Dialog
                      open={ segmentdialogbox }
                      TransitionComponent={ Transition }
                      keepMounted
                      PaperProps={ {
                        style: {
                          borderRadius   : "20px",
                          backdropFilter : "blur(10px)",
                          backgroundColor: "rgba(255,255,255,0.80)",
                          margin         : "20px",
                          padding        : "0% 2%",
                          height         : "100%"
                        }
                      } }
                      maxWidth="md"
                      fullWidth>
                      <Box sx={ { alignSelf: "flex-end" } }>
                        <IconButton
                          component="span"
                          onClick={ segmentDialogclose }>
                          <HighlightOffIcon />
                        </IconButton>
                      </Box>
                      <SegmentsPersonasDialog segments={ segmentsDialog } />
                    </Dialog>
                    <Grid
                      item
                      md={ 12 }
                      className={ classes.gridBox }
                      columnGap={ 5 }>
                      { eachSegment.personas.length > 0 ?
                        eachSegment.personas.map(
                          (eachPersona, personaindex) => {

                            return <ProductMessagingColumns
                              eachSegment={ eachSegment }
                              eachPersona={ eachPersona }
                              personaindex={ personaindex }
                              segmentindex={ segmentindex }
                              handleChange={ handleChange }
                            />;
                          
                          }
                        )
                        :
                        <Box
                          sx={ {
                            display       : "flex",
                            width         : "100%",
                            alignItems    : "center",
                            justifyContent: "center"
                          } }>
                          <Typography sx={ { textAlign: "center" } }>
                            No Personas Created
                          </Typography>
                        </Box>
                      }
                    </Grid>
                  </Grid>
                );
              
              })
              :
              <Grid container>
                <Grid
                  item
                  xs={ 12 }
                  sx={ {
                    textAlign     : "center",
                    minHeight     : "40vh",
                    display       : "flex",
                    justifyContent: "center",
                    alignItems    : "center"
                  } }>
                  <Typography fontWeight="900">
                    {/* No valid user personas found. Kindly create relevant user
                  segments and personas in the Customer module, to continue
                  working on this section. */}
                    You have not created any user personas yet. Click{ " " }
                    <a
                      style={ {
                        cursor        : "pointer",
                        color         : "blue",
                        textDecoration: "underline"
                      } }
                      onClick={ () => {

                        history.push("/gtmsegmentspersonas");
                      
                      } }>
                      here
                    </a>{ " " }
                    to add customer segments & create user personas. After
                    you’re done, return to Positioning section from the GTM
                    Canvas.
                  </Typography>
                </Grid>
              </Grid>
            }
          </Grid>
          {/* {showTopBtn && (
          <img
            src="/images/up-arrow.png"
            className={classes.upbtn}
            onClick={() => {
              handleScrollUp();
            }}
          />
          )}*/}
        </Grid>
        <div className="ttribe-back-next-button-con">
          <BackNextButton
            restrictedBackClick={ () => {

              history.push("/gtmpositioningprogresstwo");
            
            } }
            backClick={ () => {

              history.push("/gtmpositioningprogresstwo");
            
            } }
            restrictedNextClick={ () => {

              history.push("/positioningsummary");
            
            } }
            nextClick={ () => {

              handleSubmit();
            
            } }
          />
        </div>
      </div>
    </ModuleSections>
  );

};

export default ProductMessaging;
