import {
  Box, Button, FormControlLabel, Grid, IconButton, Radio, RadioGroup,
  Slider, Typography
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";

// import { makeStyles } from "@mui/styles";
import CustomMultilineTextfield from "../../../../components/CustomMultilineTextfield";
import CustomSelect from "../../../../components/CustomSelect";
import { getPersona, updateDemographics } from "../../../../services/persona";
import styles from "./TargetSegments.module.css";

// const useStyles = makeStyles(theme => ({
//   customWidth: {
//     width          : "100%",
//     fontSize       : 10,
//     backgroundColor: "white",
//     color          : "#707070",
//     boxShadow      : "0px 3px 6px #00000029",
//     border         : "1px solid #707070",
//     borderRadius   : 10
//   }
// }));

// const bar = {
//   height         : "4px",
//   width          : "50px",
//   borderRadius   : "50px",
//   backgroundColor: "grey",
//   margin         : "5px 20px",
//   marginLeft     : "0px"
// };
// const bar_blue = {
//   height         : "4px",
//   width          : "50px",
//   borderRadius   : "50px",
//   backgroundColor: "blue",
//   margin         : "5px 20px",
//   marginLeft     : "0px"
// };

const EditDemographics = props => {

  const [ details, setDetails ] = useState({
    gender            : "",
    age               : "20-35",
    qualification     : "",
    occupation        : "",
    ethnicity         : "",
    industry          : "",
    size              : "",
    income            : "$100k-200K",
    demographicfactors: ""
  });
  const [ isedit, setIsedit ] = useState(false);
  const [ isSuccess, setIssuccess ] = useState(false);
  const history = useHistory();

  // const classes = useStyles();

  let { venture } = useSelector(state => {

    return { ...state };
  
  });

  useEffect(() => {

    fetchPersona();

    return () => {

      return handleSave;
    
    };
  
  }, []);

  const fetchPersona = async () => {

    try {

      const response = await getPersona(
        props.personaid,
        venture.problemSolution
      );

      let persona = response.data.persona;

      setDetails(persona.demographics);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  // To get the updated usestate value to save in useEffect cleanup
  const detailsRef = useRef(details);

  detailsRef.current = details;

  // useEffect(() => {
  //   return handleSave;
  // }, []);

  const handleSave = async () => {

    try {

      await updateDemographics(detailsRef.current, props.personaid);
      
      return true;
    
    } catch (error) {

      console.error(error);
      
      return false;
    
    }
  
  };

  const educationArr = [
    { value: "", label: "-None-" },
    { value: "Secondary or High School", label: "Secondary or High School" },
    { value: "Vocational qualification", label: "Vocational qualification" },
    { value: "Bachelor’s degree", label: "Bachelor’s degree" },
    { value: "Master’s degree", label: "Master’s degree" },
    { value: "Doctorate or higher", label: "Doctorate or higher" },
    { value: "Professional degree", label: "Professional degree" },
    { value: "Other", label: "Other" }
  ];

  // const sortedEducationArr = educationArr.sort((a, b) =>
  //   a.label > b.label ? 1 : -1
  // );

  const occupationArr = [
    { value: "Accountant", label: "Accountant" },
    { value: "Analyst", label: "Analyst" },
    { value: "Executive ", label: "Executive " },
    { value: "Manager", label: "Manager" },
    { value: "Investment Banker", label: "Investment Banker" },
    { value: "Clerk", label: "Clerk" },
    { value: "Officer", label: "Officer" },
    { value: "Homemaker", label: "Homemaker" },
    { value: "Planner", label: "Planner" },
    { value: "Architect", label: "Architect" },
    { value: "Engineer", label: "Engineer" },
    { value: "Teacher", label: "Teacher" },
    { value: "Lecturer", label: "Lecturer" },
    { value: "Professor ", label: "Professor " },
    { value: "Principal", label: "Principal" },
    { value: "Administrator", label: "Administrator" },
    { value: "Counselor", label: "Counselor" },
    { value: "Legal Attorney", label: "Legal Attorney" },
    { value: "Medical Practitioner", label: "Medical Practitioner" },
    { value: "Doctor", label: "Doctor" },
    { value: "Therapist", label: "Therapist" },
    { value: "Scientist", label: "Scientist" },
    { value: "Technical Assistant", label: "Technical Assistant" },
    { value: "Intern / Trainee", label: "Intern / Trainee" },
    { value: "Systems Analyst", label: "Systems Analyst" },
    { value: "Systems Engineer", label: "Systems Engineer" },
    { value: "Software Engineer", label: "Software Engineer" },
    { value: "Software Architect", label: "Software Architect" },
    { value: "Programmer Analyst", label: "Programmer Analyst" },
    { value: "Developer", label: "Developer" },
    { value: "Project Manager", label: "Project Manager" },
    { value: "Business Analyst", label: "Business Analyst" },
    { value: "Quality Assurance Analyst", label: "Quality Assurance Analyst" },
    { value: "Exec. & Management Leader", label: "Exec. & Management Leader" },
    { value: "CXO", label: "CXO" },
    { value: "President", label: "President" },
    {
      value: "Founder / Managing Partner",
      label: "Founder / Managing Partner"
    },
    { value: "Event Planner", label: "Event Planner" },
    { value: "Chef", label: "Chef" },
    { value: "General Manager", label: "General Manager" },
    { value: "Assistant / Worker", label: "Assistant / Worker" },
    { value: "Consultant", label: "Consultant" },
    { value: "Contractor", label: "Contractor" },
    { value: "Technician / Operator", label: "Technician / Operator" },
    { value: "Trainer / Tutor", label: "Trainer / Tutor" },
    { value: "Coach", label: "Coach" },
    {
      value: "Customer service / Help desk Executive",
      label: "Customer service / Help desk Executive"
    },
    { value: "Sales Executive", label: "Sales Executive" },
    { value: "Sales representative", label: "Sales representative" },
    { value: "Marketing Executive", label: "Marketing Executive" },
    { value: "Chauffeur / Driver", label: "Chauffeur / Driver" },
    { value: "Geologist", label: "Geologist" },
    { value: "Research Analyst", label: "Research Analyst" },
    { value: "Designer", label: "Designer" },
    { value: "Writer", label: "Writer" },
    { value: "Journalist", label: "Journalist" },
    { value: "Artist", label: "Artist" },
    { value: "Farmer & Grower", label: "Farmer & Grower" },
    { value: "Unemployed", label: "Unemployed" }
  ];

  const sortedoccupationArr = occupationArr.sort((a, b) =>
    a.label > b.label ? 1 : -1
  );

  const industryArr = [
    { value: "Retail", label: "Retail" },
    { value: "Consumer Goods", label: "Consumer Goods" },
    { value: "E-Commerce", label: "E-Commerce" },
    { value: "Automotive", label: "Automotive" },
    {
      value: "Construction & Real estate",
      label: "Construction & Real estate"
    },
    { value: "Information & Education", label: "Information & Education" },
    { value: "Insurance", label: "Insurance" },
    {
      value: "Banking & Financial services",
      label: "Banking & Financial services"
    },
    { value: "Healthcare services", label: "Healthcare services" },
    {
      value: "Life Sciences, Pharmaceuticals & Biotech",
      label: "Life Sciences, Pharmaceuticals & Biotech"
    },
    { value: "Travel & Hospitality", label: "Travel & Hospitality" },
    {
      value: "Mechanical and electrical engineering",
      label: "Mechanical and electrical engineering"
    },
    { value: "Chemicals, Oil & Power", label: "Chemicals, Oil & Power" },
    {
      value: "Manufacturing, Supply chain & Logistics",
      label: "Manufacturing, Supply chain & Logistics"
    },
    { value: "Transport", label: "Transport" },
    { value: "Telecommunications", label: "Telecommunications" },
    {
      value: "Technology, Software & Services",
      label: "Technology, Software & Services"
    },
    { value: "Textile", label: "Textile" },
    { value: "Media & Entertainment", label: "Media & Entertainment" },
    { value: "Utilities  ", label: "Utilities  " },
    { value: "Govt. & Public services", label: "Govt. & Public services" },
    {
      value: "Non Govt. Private Services",
      label: "Non Govt. Private Services"
    },
    { value: "Agriculture", label: "Agriculture" }
  ];

  const sortedIndustryArr = industryArr.sort((a, b) =>
    a.label > b.label ? 1 : -1
  );

  const agemarks = [
    { value: 1, label: "<12" },
    { value: 2, label: "13-19" },
    { value: 3, label: "20-35" },
    { value: 4, label: "36-60" },
    { value: 5, label: ">60" }
  ];

  const handleAgeChange = event => {

    agemarks.map(mark => {

      if (mark.value === event.target.value) {

        handleChange("age", mark.label);
      
      }
    
    });
  
  };

  const handleChange = (name, value) => {

    setDetails(values => ({ ...values, [name]: value }));
  
  };

  const handleAgeValue = value => {

    let newvalue;

    agemarks.map(mark => {

      if (mark.label === value) {

        newvalue = mark.value;
      
      }
    
    });
    
    return newvalue;
  
  };

  const ethnicityArr = [
    { value: "", label: "-None-" },
    { value: "East/ Southeast Asian", label: "East/ Southeast Asian" },
    { value: "South Asian", label: "South Asian" },
    { value: "Black/ African", label: "Black/ African" },
    { value: "Caucasian", label: "Caucasian" },
    { value: "Hispanic/ Latino", label: "Hispanic/ Latino" },
    { value: "Native American", label: "Native American" },
    { value: "Pacific Islander", label: "Pacific Islander" },
    { value: "Mixed Race", label: "Mixed Race" },
    { value: "Other", label: "Other" }
  ];

  const sizeArr = [
    { value: "Self Employed", label: "Self Employed" },
    { value: "1-10 Employees", label: "1-10 Employees" },
    { value: "11-50 Employees", label: "11-50 Employees" },
    { value: "51-200 Employees", label: "51-200 Employees" },
    { value: "201-500 Employees", label: "201-500 Employees" },
    { value: "501-1000 Employees", label: "501-1000 Employees" },
    { value: "1001-5000 Employees", label: "1001-5000 Employees" },
    { value: "5001-10000 Employees", label: "5001-10000 Employees" },
    { value: "10,001+ Employees", label: "10,001+ Employees" }
  ];

  const incomemarks = [
    { value: 1, label: "<$50k" },
    { value: 2, label: "$50k-100K" },
    { value: 3, label: "$100k-200K" },
    { value: 4, label: ">$200k" }
  ];

  const handleIncomeChange = event => {

    incomemarks.map(mark => {

      if (mark.value === event.target.value) {

        handleChange("income", mark.label);
      
      }
    
    });
  
  };

  const pathname = history.location.pathname.substring(1);

  const handleIncomeValue = value => {

    let newvalue;

    incomemarks.map(mark => {

      if (mark.label === value) {

        newvalue = mark.value;
      
      }
    
    });
    
    return newvalue;
  
  };

  const updateDetails = async event => {

    event.stopPropagation();
    try {

      await updateDemographics(details, props.personaid);

      setIsedit(!isedit);
      // history.push(`/userpersonadetails/${props.personaid}`);
      history.push(
        pathname.includes("gtm")
          ? `/gtmuserpersonadetails/${props.personaid}`
          : `/userpersonadetails/${props.personaid}`
      );
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const handleClose = () => {

    // history.push(`/userpersonadetails/${props.personaid}`);
    history.push(
      pathname.includes("gtm")
        ? `/gtmuserpersonadetails/${props.personaid}`
        : `/userpersonadetails/${props.personaid}`
    );
    props.gridScrollUp();
  
  };

  return (
    <Grid id={ styles.individualgrid } component="div" onClick={ handleClose }>
      <Box display="flex" justifyContent="flex-end">
        <Box sx={ { marginRight: "10px", position: "relative" } }>
          { isSuccess &&
            <Box sx={ { position: "absolute", top: "32px", left: "10px" } }>
              <Typography sx={ { color: "#08910B", fontSize: "14px" } }>
                Saved
              </Typography>
            </Box>
          }
          <Button
            variant="contained"
            color="primary"
            sx={ { padding: "3px 12px", textTransform: "capitalize" } }
            onClick={ async e => {

              e.stopPropagation();
              const isSaved = await handleSave();

              if (isSaved) {

                setIssuccess(true);
                setTimeout(() => {

                  setIssuccess(false);
                
                }, 3000);
              
              }
            
            } }
            disabled={ venture.userRole === "Commenter" }
          >
            Save
          </Button>
        </Box>
        <IconButton component="span" onClick={ updateDetails }>
          <HighlightOffIcon />
        </IconButton>
      </Box>
      <Typography id={ styles.individualtitle } gutterBottom>
        Demographics
      </Typography>
      <Box>
        <Typography variant="body2" sx={ { margin: "1em 0px" } } id={ styles.mobileViewFontSize }>
          What is their gender predominantly?
        </Typography>
        <RadioGroup
          row
          aria-label="gender"
          defaultValue={ "Mostly Male" }
          name="gender"
          value={ details.gender }
          onClick={ e => {

            e.stopPropagation();
          
          } }
          onChange={ e => handleChange(e.target.name, e.target.value) }
        >
          <FormControlLabel
            value="Mostly male"
            control={
              <Radio size="small" disabled={ venture.userRole === "Commenter" } />
            }
            label="Mostly male"
          />
          <FormControlLabel
            value="Mostly female"
            control={
              <Radio size="small" disabled={ venture.userRole === "Commenter" } />
            }
            label="Mostly female"
          />
          <FormControlLabel
            value="Other"
            control={
              <Radio size="small" disabled={ venture.userRole === "Commenter" } />
            }
            label="Other"
          />
        </RadioGroup>
      </Box>
      <Box>
        <Typography variant="body2" sx={ { margin: "1em 0px" } } id={ styles.mobileViewFontSize }>
          What is their age range?
        </Typography>
        <Slider
          size="medium"
          max={ 6 }
          defaultValue={ 3 }
          sx={ {
            "& .MuiSlider-rail": {
              backgroundColor: "grey !important"
            },
            "& .MuiSlider-mark": {
              backgroundColor: "grey !important",
              height         : "15px",
              width          : "1px"
            }
          } }
          onClick={ e => {

            e.stopPropagation();
          
          } }
          onChange={ handleAgeChange }
          step={ null }
          marks={ agemarks }
          track={ false }
          value={ handleAgeValue(details.age) }
          disabled={ venture.userRole === "Commenter" }
        />
      </Box>
      <Box
        component="div"
        onClick={ e => {

          e.stopPropagation();
        
        } }
      >
        <Typography variant="body2" sx={ { margin: "1em 0px" } } id={ styles.mobileViewFontSize }>
          What is their educational qualification?
        </Typography>
        {/* <Select
          options={educationArr}
          placeholder="Select Educational Qualification"
          value={
            details.qualification && {
              value: details.qualification,
              label: details.qualification,
            }
          }
          onChange={(e) => {
            handleChange("qualification", e.value);
          }}
        />
      </Box>
      <Box
        component="div"
        onClick={(e) => {
          e.stopPropagation();
        }}
      > */}
        <CustomSelect
          options={ educationArr }
          placeholder="Select Educational Qualification"
          value={ details.qualification }
          handleChange={ e => {

            handleChange("qualification", e.value);
          
          } }
        // maxMenuHeight={150}
        />
        <Typography variant="body2" sx={ { margin: "1em 0px" } } id={ styles.mobileViewFontSize }>
          What is their occupation/title?
        </Typography>
        {/* <Select
          options={[
            { value: "", label: "-None-" },
            ...sortedoccupationArr,
            { value: "Other", label: "Other" },
          ]}
          placeholder="Select Occupation"
          value={
            details.occupation && {
              value: details.occupation,
              label: details.occupation,
            }
          }
          onChange={(e) => {
            handleChange("occupation", e.value);
          }}
        /> */}
        <CustomSelect
          options={ [
            { value: "", label: "-None-" },
            ...sortedoccupationArr,
            { value: "Other", label: "Other" }
          ] }
          placeholder="Select Occupation"
          value={ details.occupation }
          handleChange={ e => {

            handleChange("occupation", e.value);
          
          } }
        />
      </Box>
      <Box
        component="div"
        onClick={ e => {

          e.stopPropagation();
        
        } }
      >
        <Typography variant="body2" sx={ { margin: "1em 0px" } } id={ styles.mobileViewFontSize }>
          What is their ethnicity?
        </Typography>
        {/* <Select
          options={ethnicityArr}
          placeholder="Select Ethnicity"
          value={
            details.ethnicity && {
              value: details.ethnicity,
              label: details.ethnicity,
            }
          }
          onChange={(e) => {
            handleChange("ethnicity", e.value);
          }}
        /> */}
        <CustomSelect
          options={ ethnicityArr }
          placeholder="Select Ethnicity"
          value={ details.ethnicity }
          handleChange={ e => {

            handleChange("ethnicity", e.value);
          
          } }
        />
      </Box>
      <Box
        component="div"
        onClick={ e => {

          e.stopPropagation();
        
        } }
      >
        <Typography variant="body2" sx={ { margin: "1em 0px" } } id={ styles.mobileViewFontSize }>
          What industry do they work in?
        </Typography>
        {/* <Select
          options={[
            { value: "", label: "-None-" },
            ...sortedIndustryArr,
            { value: "Other", label: "Other" },
          ]}
          placeholder="Select Industry"
          value={
            details.industry && {
              value: details.industry,
              label: details.industry,
            }
          }
          onChange={(e) => {
            handleChange("industry", e.value);
          }}
        /> */}
        <CustomSelect
          options={ [
            { value: "", label: "-None-" },
            ...sortedIndustryArr,
            { value: "Other", label: "Other" }
          ] }
          placeholder="Select Industry"
          value={ details.industry }
          handleChange={ e => {

            handleChange("industry", e.value);
          
          } }
        />
      </Box>
      <Box
        component="div"
        onClick={ e => {

          e.stopPropagation();
        
        } }
      >
        <Typography variant="body2" sx={ { margin: "1em 0px" } } id={ styles.mobileViewFontSize }>
          What is the size of their organization?
        </Typography>
        {/* <Select
          options={sizeArr}
          placeholder="Select the size of an organization"
          value={
            details.size && {
              value: details.size,
              label: details.size,
            }
          }
          onChange={(e) => {
            handleChange("size", e.value);
          }}
        /> */}
        <CustomSelect
          options={ sizeArr }
          placeholder="Select the size of an organization"
          value={ details.size }
          handleChange={ e => {

            handleChange("size", e.value);
          
          } }
        />
      </Box>
      <Box
        component="div"
        onClick={ e => {

          e.stopPropagation();
        
        } }
      >
        <Typography variant="body2" sx={ { margin: "1em 0px" } } id={ styles.mobileViewFontSize }>
          What is their income level?
        </Typography>
        <Slider
          size="small"
          max={ 5 }
          defaultValue={ 3 }
          sx={ {
            "& .MuiSlider-rail": {
              backgroundColor: "grey !important"
            },
            "& .MuiSlider-mark": {
              backgroundColor: "grey !important",
              height         : "15px",
              width          : "1px"
            }
          } }
          step={ null }
          marks={ incomemarks }
          track={ false }
          value={ handleIncomeValue(details.income) }
          onChange={ handleIncomeChange }
          disabled={ venture.userRole === "Commenter" }
        />
      </Box>
      <Box>
        <Typography variant="body2" sx={ { margin: "1em 0px" } } id={ styles.mobileViewFontSize }>
          Other relevant demographic factors?
        </Typography>
        {/* <TextField
          multiline
          variant="outlined"
          minRows={2}
          maxRows={2}
          sx={{ width: "100%" }}
          size="small"
          name="demographicfactors"
          value={details.demographicfactors}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => {
            handleChange(e.target.name, e.target.value);
          }}
        /> */}
        <CustomMultilineTextfield
          padding={ "13px 10px" }
          minRows={ 2 }
          maxRows={ 2 }
          width={ "100%" }
          name="demographicfactors"
          value={ details.demographicfactors }
          handleClick={ e => {

            e.stopPropagation();
          
          } }
          handledetailsChange={ e => {

            handleChange(e.target.name, e.target.value);
          
          } }
        />
      </Box>
    </Grid>
  );

};

export default EditDemographics;
