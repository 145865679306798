/* eslint-disable camelcase */
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Card } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import jsondata from "../Data/data.json";
import CardBox from "./CardBox";
import ToggleCardBox from "./ToggleCardBox";
const cardDatas = jsondata.sidebar;

const useStyles = makeStyles({
  root: {
    background            : "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow             : "0px 2px 6px #7070703b",
    border                : "1px solid #CCCCCC",
    overflow              : "auto",
    borderRadius          : "10px",
    width                 : "max-content",
    height                : "81vh",
    opacity               : "1",
    "&::-webkit-scrollbar": {
      width: "5px"
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f1f1f1"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius   : "100px"
    }
  },
  title_text: {
    fontWeight: "600",
    color     : "dimgray",
    fontSize  : "14px",
    margin    : "8px"
  },
  inner_text: {
    color   : "dimgray",
    fontSize: "14px"
  }
});

const Button_style = {
  background   : " #071AFF 0% 0% no-repeat padding-box",
  boxShadow    : " 0px 1px 3px #00000029",
  borderRadius : "10px",
  opacity      : 1,
  display      : "flex",
  width        : "100%",
  padding      : "10px",
  textTransform: "capitalize"
};

function Card_Collapse () {

  const [ CollapseMain, setCollapseMain ] = useState(true);
  const [ scrolltop, setScrolltop ] = useState(0);
  const cardscroll = useRef(null);
  const location = useLocation();


  useEffect(() => {

    if (location.state && location.state.scrolltop) {

      cardscroll.current.scrollTop = location.state.scrolltop;
    
    }
  
  }, [ location ]);

  const collapse_bar_Main = () => {

    setCollapseMain(!CollapseMain);
  
  };
  const classes = useStyles();
  const history = useHistory();
  // const pathname = history.location.pathname.substring(1);

  const handleScroll = () => {

    setScrolltop(cardscroll.current.scrollTop);
  
  };

  return (
    <div
      style={ {
        overflow : "hidden",
        height   : "100%",
        width    : "100%",
        boxShadow: "0px 2px 15px #7070703b"
      } }
    >
      <Card className={ classes.root } ref={ cardscroll } onScroll={ handleScroll }>
        <Collapse
          in={ CollapseMain }
          // timeout="auto"
          collapsedSize={ 80 }
          orientation="horizontal"
        // sx={{
        //   width: "16vw",
        //   "& .MuiCollapse-wrapper": {
        //     width: "-webkit-fill-available",
        //   },
        //   "& .MuiCollapse-wrapperInner": {
        //     width: "-webkit-fill-available",
        //   },
        // }}
        >
          { CollapseMain ?
            <>
              <IconButton
                style={ {
                  position  : "absolute",
                  background: "blue",
                  color     : "white",
                  bottom    : "98%",
                  left      : "94%",
                  width     : "25px",
                  height    : "25px",
                  transform : "rotate(45deg)"
                } }
                onClick={ () => collapse_bar_Main() }
              >
                <AddIcon style={ { fontSize: "15px" } } />
              </IconButton>
              <Box style={ { margin: "15px" } }>
                <Button
                  variant="contained"
                  color="primary"
                  style={ Button_style }
                  onClick={ () => {

                    history.push("/dashboard");
                  
                  } }
                >
                  Dashboard
                </Button>
              </Box>
              { cardDatas.map(data => {

                return (
                  <CardBox
                    data={ data }
                    scrolltop={ scrolltop }
                  />
                );
              
              }) }
            </>
            :
            <>
              <IconButton
                style={ {
                  position  : "absolute",
                  background: "blue",
                  color     : "white",
                  width     : "25px",
                  height    : "25px",
                  bottom    : "98%",
                  left      : "86%"
                } }
                onClick={ () => collapse_bar_Main() }
              >
                <AddIcon style={ { fontSize: "15px" } } />
              </IconButton>
              <Box style={ { margin: "9px" } }>
                <Button
                  id="btn"
                  variant="contained"
                  color="primary"
                  style={ {
                    margin         : "auto",
                    width          : "50px",
                    height         : "50px",
                    fontWeight     : "bold",
                    fontSize       : "18px",
                    backgroundColor: "#071AFF",
                    borderRadius   : "10px",
                    boxShadow      : "0px 1px 3px #00000029"
                  } }
                >
                  D
                </Button>
              </Box>
              { cardDatas.map(data => {

                return <ToggleCardBox data={ data } />;
              
              }) }
            </>
          }
        </Collapse>
      </Card>
    </div>
  );

}

export default Card_Collapse;
