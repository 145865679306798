import React, { useState, useEffect } from "react";
import { Tabs, Tab, Box, Grid, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

import { getBuisnessPlanSummary } from "../../../services/auth";

// tab components
import VentureProfile from "./BizPlanSummaryTabs/VentureProfile";
import PitchDeckOnePage from "../PitchDeck/PitchDeckOnePage";
import FinancialChart from "./BizPlanSummaryTabs/FinancialChart";
import MilesStones from "./BizPlanSummaryTabs/MilesStones";
import Team from "./BizPlanSummaryTabs/Team";


const BusinessPlanSummary = () => {

  const [ value, setValue ] = useState(0);
  const [ loading, setLoading ] = useState(false);
  const [ onePageData, setOnePageData ] = useState([]);
  const [ bizPlanData, setBizPlanData ] = useState([]);
  const { venture } = useSelector(state => ({ ...state }));

  const handleTabChangeEvent = (event, newValue) => {

    setValue(newValue);

  };

  useEffect(() => {

    fetchBusinessPlanSummary();

  }, []);

  const fetchBusinessPlanSummary = () => {

    setLoading(true);

    try {

      getBuisnessPlanSummary(venture._id).then(res => {

        setLoading(false);

        if (res.data) {

          const bizPlanData1 = res.data;

          setBizPlanData(bizPlanData1);

          if (bizPlanData1.one_page_bizplan) {

            setOnePageData(bizPlanData1.one_page_bizplan);

          }

        }

      });

    } catch (error) {

      console.error(error);
      setLoading(false);

    }

  };

  return <>
    {loading ? <>

      <Grid
        container
        justifyContent="center"
        height="100%"
        alignItems="center">
        <Grid item>
          <CircularProgress size="60px" thickness={3} />
        </Grid>
      </Grid>

    </> : <><Box sx={{ width: "100%", bgcolor: "background.paper" }}>

      <Tabs
        className="tribe-biz-summary-tabs-con"
        value={value}
        variant="fullWidth"
        onChange={handleTabChangeEvent}
        centered
      >
        <Tab label="Venture Profile" />
        <Tab label="1 Page Bizplan" />
        <Tab label="Financials" />
        <Tab label="Milestones" />
        <Tab label="Team" />
      </Tabs>
      <div className="tribe-biz-tab-section-main-con">
        {value === 0 && <div><VentureProfile ventureProfileData={bizPlanData?.Venture_profile} /></div>
        }
        {value === 1 && <div><PitchDeckOnePage onePageData={onePageData && onePageData} /></div>
        }
        {value === 2 && <div><FinancialChart financialChartData={bizPlanData["financials_chart_data"]} /></div>
        }
        {value === 3 && <div><MilesStones milesStonesData={bizPlanData["milestones_and_team"]?.milestones} /></div>
        }
        {value === 4 && <div> <Team teamsData={bizPlanData["milestones_and_team"]?.team} /></div>
        }
      </div>
    </Box ></>}</>;


};

export default BusinessPlanSummary;