import {
  Grid, Typography
} from "@mui/material";
import React from "react";
// import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import HelpIconTooltip from "../../../../components/HelpIconTooltip";
import Data from "../../../../Data/Gtm.json";

// import { makeStyles } from "@mui/styles";

// import BackNextButton from "../../../../components/BackNextButton";
import CustomMultilineTextfield from "../../../../components/CustomMultilineTextfield";
import styles from "./TargetSegments.module.css";

// const useStyles = makeStyles(theme => ({
//   customWidth: {
//     maxWidth       : 700,
//     fontSize       : 10,
//     backgroundColor: "white",
//     color          : "#707070",
//     boxShadow      : "0px 3px 6px #00000029",
//     border         : "1px solid #707070",
//     borderRadius   : 10
//   }
// }));

// const bar = {
//   height         : "4px",
//   width          : "50px",
//   borderRadius   : "50px",
//   backgroundColor: "grey",
//   margin         : "5px 20px",
//   marginLeft     : "0px"
// };
// const bar_blue = {
//   height         : "4px",
//   width          : "50px",
//   borderRadius   : "50px",
//   backgroundColor: "blue",
//   margin         : "5px 20px",
//   marginLeft     : "0px"
// };

const OtherDetails = props => {

  // const history = useHistory();
  const location = useLocation();
  // const [Collapsecomment, setCollapsecomment] = useState(false);
  // const [dialogbox, setDialog] = useState(false);

  // const classes = useStyles();

  // let { venture } = useSelector((state) => {
  //   return { ...state };
  // });

  let hash = location.hash.substring(1);
  // let newhash = parseInt(hash);
  // const pathname = history.location.pathname.substring(1);
  // const guideicon = Data.gtmtargetpersonas;

  const data = props.data || Data;
  const details = props.details;

  // const Dialogopen = () => {
  //   setDialog(true);
  // };
  // const Dialogclose = () => {
  //   setDialog(false);
  // };

  // const collapse_comment_function = () => {
  //   if (Collapsecomment) {
  //     setCollapsecomment(false);
  //   } else {
  //     setCollapsecomment(true);
  //   }
  // };

  return (
    <Grid id={styles.maingrid2}>
      {data.questions.map(content => {

        return (
          <Grid container sx={{ margin: "2em 0px" }}>
            <Grid item xs={12} id={styles.questionsGridItem} md={5}>
              <Typography variant="body2" sx={{ color: "#393939" }}>
                {content.question}
                {hash === "5" ?
                  <></>
                  : content.question ===
                  "Other relevant buying behaviours" ?
                    <></>
                    :
                    <HelpIconTooltip
                      tooltipdata={content.tooltip}
                      width={"500px"}
                    />
                }
              </Typography>
            </Grid>
            <Grid item xs={12} md={7}>
              <CustomMultilineTextfield
                width={"100%"}
                padding={"10px 10px"}
                maxRows={data.questions.length > 3 ? 4 : 3}
                minRows={data.questions.length > 3 ? 4 : 3}
                // fontSize={"13px"}
                placeholder={content.placeholder}
                value={details[content.name]}
                handledetailsChange={e => {

                  props.handleChange(content.name, e.target.value);
                
                }}
              />
            </Grid>
          </Grid>
        );
      
      })}

      <Grid container sx={{ margin: "2em 0px" }}>

        <Typography sx={{ fontSize: "11px", fontStyle: "italic" }}>
          *Answer all questions that could be relevant for your product or
          brand. It’s ok to skip questions you can’t answer at the moment. As
          you learn more about your target users, return to these pages to
          further develop the persona.
        </Typography>

      </Grid>
    </Grid>
  );

};

export default OtherDetails;
