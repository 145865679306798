import { TextField } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const CustomMultilineTextfield = props => {

  const { venture } = useSelector(state => ({ ...state }));

  return (
    <div className="multiline-textfield-box">
      <TextField

        multiline
        type="text"
        maxRows={ props.maxRows }
        minRows={ props.minRows }
        sx={ {
          width                     : props.width,
          flex                      : props.flex,
          backgroundColor           : props.backgroundColor,
          "& .MuiOutlinedInput-root": {
            padding: props.padding
          },
          "& .MuiInputBase-input": {
            fontSize  : props.fontSize,
            lineHeight: props.lineHeight,
            height    : props.height,
            overflow  : props.isOverflowHidden ? "hidden" : "auto"
          },
          "& .MuiOutlinedInput-notchedOutline": {
            boxShadow   : props.boxShadow,
            border      : props.border,
            borderRadius: props.borderRadius
          }
        } }
        // inputProps={
        //   { readOnly: props.isReadOnly, }
        // }
        name={ props.name }
        value={ props.value }
        onChange={e => props.handledetailsChange(e) }
        placeholder={ props.placeholder }
        onClick={ props.handleClick }
        onBlur={ props.handleBlur }
        onMouseEnter={ props.onMouseEnter }
        onMouseLeave={ props.onMouseLeave }
        onFocus={ props.onFocus }
        autoFocus={ props.autoFocus }
        disabled={ props.disabled || venture.userRole === "Commenter" }
      />
    </div>
  );

};

export default CustomMultilineTextfield;
