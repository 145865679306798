/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from "react";
import {
  Box, Button, CircularProgress, Dialog,
  DialogContent, Grid, IconButton,
  Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BackNextButton from "../../../../components/BackNextButton";
import { currencies } from "../../../../components/Currencies";
import HelpIconTooltip from "../../../../components/HelpIconTooltip";
import IndividualSectionHeaders from "../../../../components/IndividualSectionHeaders";
import ModuleSections from "../../../../components/ModuleSections";
import Datas from "../../../../Data/Finance.json";
import styles from "./CashFlow.module.css";
import FinancialsNavBar from "../FinancialsNavBar";
import {
  getFinancials, updateFinancials, createOrupdateFinancialCashFlow, updateCashFlow
} from "../../../../services/financials";
import CashFlowTable from "./CashFlowTable";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CustomSelect from "../../../../components/CustomSelect";
import {
  createFinancialsComment,
  deleteFinancialsComment,
  updateFinancialsComment
} from "../../../../services/comment";


const CashFlow = () => {

  const [ loading, setLoading ] = useState(false);
  const [ autosaving, setAutosaving ] = useState(false);
  const [ dialogbox, setDialog ] = useState(false);
  const [ collapseComment, setCollapseComment ] = useState(false);
  const [ errorDialog, setErrorDialog ] = useState(false);
  const [ isExpand, setIsExpand ] = useState(false);
  const [ financials, setFinancials ] = useState({
    plOutlooks          : [],
    frOutlooks          : [],
    cashFlow            : "",
    currentFundingSource: [],
    fundRaising         : {
      totalFundingNeed : 0,
      fundingAllocation: ""
    },
    totalCurrentFunding: 0,
    plOutlooksCurrency : "",
    frOutlooksCurrency : "",
    currency           : ""
  });
  const [ comments, setComments ] = useState([]);
  const [ table, setTable ] = useState(Datas.cashFlow.initialData);
  const [ isedit, setIsedit ] = useState(true);
  const [ isDisable, setIsDisable ] = useState(true);
  const [ selectedindex, setSelectedindex ] = useState(-1);
  const history = useHistory();
  const [ currency, setcurrency ] = useState();
  const { user, venture } = useSelector(state => ({ ...state }));
  const [ isYearExist, setisYearExist ] = useState(false);
  const [ yearList, setYearList ] = useState([]);
  const [ isDataLoading, setIsDataLoading ] = useState(true);

  const collapse_comment_function = () => {

    if (collapseComment) {

      setCollapseComment(false);

    } else {

      setCollapseComment(true);

    }

  };

  const Dialogopen = () => {

    setDialog(true);

  };
  const Dialogclose = () => {

    setDialog(false);

  };

  const errorDialogopen = () => {

    setErrorDialog(true);

  };
  const errorDialogclose = () => {

    setErrorDialog(false);

  };

  useEffect(() => {

    setTable(Datas.cashFlow.initialData);
    fetchFinancials();

  }, []);

  const fetchFinancials = async () => {

    setIsDataLoading(true);

    setLoading(true);
    setIsDisable(true);

    getFinancials(venture.financials, venture._id).then(response => {

      const finance = response.data.finance;

      setFinancials(finance);
      setcurrency(finance?.currency);
      setComments(finance.comments.cashFlow);

      const cashFlowIdFromDoc = response?.data?.finance?.cashFlow;
      const yearArray = finance.financialYears;

      if (finance.financialYears && finance.financialYears.length) {


        setisYearExist(true);
        setYearList(finance.financialYears);

        createOrupdateFinancialCashFlow(venture.financials, venture._id, cashFlowIdFromDoc, yearArray).then(table => {

          if (table.data) {

            const tableData = table?.data?.table;

            setFinancials(s => { return { ...s, cashFlow: table?.data?._id }; });
            setTable(tableData);
            setLoading(false);

          } else {

            setLoading(false);

          }

        }).catch(error => { console.error(error); setLoading(false); });

      } else {

        setisYearExist(false);
        // setLoading(false);

      }

      setLoading(false);

      setIsDisable(false);

      setIsDataLoading(false);

    }).catch(error => {

      setFinancials({
        plOutlooks          : [],
        frOutlooks          : [],
        cashFlow            : "",
        currentFundingSource: [],
        fundRaising         : {
          totalFundingNeed : 0,
          fundingAllocation: ""
        },
        totalCurrentFunding: 0,
        plOutlooksCurrency : "",
        frOutlooksCurrency : "",
        currency           : ""
      });

      setLoading(false);
      setIsDisable(false);

      console.error(error);

      setIsDataLoading(false);

    });

  };

  // To get the updated usestate value to save in useEffect cleanup
  const financialsRef = useRef(financials);

  financialsRef.current = financials;

  const NumberConversionLogic = num => {

    const numString = num.toString();

    let formatedNumber = numString.length > 0 ? numString.charAt(0) === "0" ? numString.slice(1, numString.length) : numString : "0";

    formatedNumber = formatedNumber.replaceAll(",", "").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,").replace(/[^-0-9]/g, "");

    if (formatedNumber.charAt(0) === "-") {

      return `-${formatedNumber.slice(1, formatedNumber.length).replaceAll("-", "")}`;

    } else {

      return formatedNumber.slice(0, formatedNumber.length).replaceAll("-", "");

    }

  };

  const numberWithComma = num => {

    return num.toString().replaceAll(",", "").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

  };

  const removeCommaForCalculation = num => {

    return num && num.toString().replaceAll(",", "");

  };

  const initialRender = useRef(true);

  useEffect(() => {

    if (initialRender.current) {

      initialRender.current = false;

    } else {

      if (table && table.length) {

        const timeoutId = setTimeout(async () => {

          setAutosaving(true);
          setIsDisable(true);
          if (financialsRef.current.cashFlow) {

            updateCashFlow(financialsRef.current.cashFlow, table, venture._id).then(res => {

              if (res) {

                setAutosaving(false);
                setIsDisable(false);
                // setTable(res?.data)

                return true;

              } else {

                setAutosaving(false);
                setIsDisable(false);

              }

            });

          } else {

            setAutosaving(false);
            setIsDisable(false);

          }

        }, 10000);

        return () => clearTimeout(timeoutId);

      }

    }

  }, [ table, financials ]);

  const handleChange = (value, rowIndex, col) => {

    if (financials.currency === "") {

      errorDialogopen();

      return;

    }

    let newTable = [ ...table ];

    // update value
    const numbers = NumberConversionLogic(value);

    newTable[rowIndex]["rowVal"][col]["value"] = numbers;
    let Net_Cash_Flow_from_Operations = [];
    let Net_Cash_Flow_from_Investing_Activities = [];
    let Net_Cash_Flow_from_Financing_Activities = [];
    let Net_Cash_Flow_for_the_Year = [];
    let Total_Cash_Flow_for_the_Year = [];

    newTable.map(ta => {

      if (ta.rowName === "Cash_at_Beginning_of_Year") {

        Total_Cash_Flow_for_the_Year.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "Product_Services") {

        Net_Cash_Flow_from_Operations.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "Other_Income_funds") {

        Net_Cash_Flow_from_Operations.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "Depreciation_Amortization") {

        Net_Cash_Flow_from_Operations.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "General_operating_and_administrative_expenses") {

        Net_Cash_Flow_from_Operations.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "Accounts_Payable") {

        Net_Cash_Flow_from_Operations.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "Accounts_Receivable") {

        Net_Cash_Flow_from_Operations.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "Income_taxes") {

        Net_Cash_Flow_from_Operations.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "Other_Payments") {

        Net_Cash_Flow_from_Operations.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "Net_Cash_Flow_from_Operations") {

        const cashFlowOperationsTotal = Net_Cash_Flow_from_Operations && Net_Cash_Flow_from_Operations.length && Net_Cash_Flow_from_Operations.reduce((a, b) => {

          const aa = removeCommaForCalculation(a);
          const bb = removeCommaForCalculation(b);


          return Number(aa) + Number(bb);

        });
        // ta.rowVal[col]["value"] = isNaN(cashFlowOperationsTotal) ? 0 : cashFlowOperationsTotal

        ta.rowVal[col]["value"] = numberWithComma(cashFlowOperationsTotal);
        Net_Cash_Flow_for_the_Year.push(cashFlowOperationsTotal);

      } else if (ta.rowName === "Capital_Expenditure_plant_Equipment") {

        Net_Cash_Flow_from_Investing_Activities.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "Sales_of_Fixed_Assets") {

        Net_Cash_Flow_from_Investing_Activities.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "Sale_Maturity_of_Investments") {

        Net_Cash_Flow_from_Investing_Activities.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "Purchase_of_property_and_equipment") {

        Net_Cash_Flow_from_Investing_Activities.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "Collection_of_loan_to_other_entities") {

        Net_Cash_Flow_from_Investing_Activities.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "Purchase_of_investment_securities") {

        Net_Cash_Flow_from_Investing_Activities.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "Investing_Activities_Other") {

        Net_Cash_Flow_from_Investing_Activities.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "Net_Cash_Flow_from_Investing_Activities") {

        const investingActivitiesTotal = Net_Cash_Flow_from_Investing_Activities && Net_Cash_Flow_from_Investing_Activities.length && Net_Cash_Flow_from_Investing_Activities.reduce((a, b) => {

          const aa = removeCommaForCalculation(a);
          const bb = removeCommaForCalculation(b);

          return Number(aa) + Number(bb);

        });
        // ta.rowVal[col]["value"] = isNaN(investingActivitiesTotal) ? 0 : investingActivitiesTotal;

        ta.rowVal[col]["value"] = numberWithComma(investingActivitiesTotal);
        Net_Cash_Flow_for_the_Year.push(investingActivitiesTotal);

      } else if (ta.rowName === "Issuance_of_stock") {

        Net_Cash_Flow_from_Financing_Activities.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "Repurchase_of_stock") {

        Net_Cash_Flow_from_Financing_Activities.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "Borrowing_Debt") {

        Net_Cash_Flow_from_Financing_Activities.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "Repayment_of_loans") {

        Net_Cash_Flow_from_Financing_Activities.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "Dividends") {

        Net_Cash_Flow_from_Financing_Activities.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "Financing_Activities_Other") {

        Net_Cash_Flow_from_Financing_Activities.push(ta.rowVal[col]["value"]);

      } else if (ta.rowName === "Net_Cash_Flow_from_Financing_Activities") {

        const financeCashFlowTotal = Net_Cash_Flow_from_Financing_Activities && Net_Cash_Flow_from_Financing_Activities.length && Net_Cash_Flow_from_Financing_Activities.reduce((a, b) => {

          const aa = removeCommaForCalculation(a);
          const bb = removeCommaForCalculation(b);


          return Number(aa) + Number(bb);

        });
        // ta.rowVal[col]["value"] = isNaN(financeCashFlowTotal) ? 0 : financeCashFlowTotal

        ta.rowVal[col]["value"] = numberWithComma(financeCashFlowTotal);
        Net_Cash_Flow_for_the_Year.push(financeCashFlowTotal);

      } else if (ta.rowName === "Net_Cash_Flow_for_the_Year") {

        const netCashFlowOfTheYear = Net_Cash_Flow_for_the_Year && Net_Cash_Flow_for_the_Year.length && Net_Cash_Flow_for_the_Year.reduce((a, b) => {

          const aa = removeCommaForCalculation(a);
          const bb = removeCommaForCalculation(b);


          return Number(aa) + Number(bb);

        });
        // ta.rowVal[col]["value"] = isNaN(netCashFlowOfTheYear) ? 0 : netCashFlowOfTheYear

        ta.rowVal[col]["value"] = numberWithComma(netCashFlowOfTheYear);
        Total_Cash_Flow_for_the_Year.push(netCashFlowOfTheYear);


      } else if (ta.rowName === "Total_Cash_Flow_for_the_Year") {

        const totalCashFlowOfTheYear = Total_Cash_Flow_for_the_Year && Total_Cash_Flow_for_the_Year.length && Total_Cash_Flow_for_the_Year.reduce((a, b) => {

          const aa = removeCommaForCalculation(a);
          const bb = removeCommaForCalculation(b);


          return Number(aa) + Number(bb);

        });
        // ta.rowVal[col]["value"] = isNaN(totalCashFlowOfTheYear) ? 0 : totalCashFlowOfTheYear;

        ta.rowVal[col]["value"] = numberWithComma(totalCashFlowOfTheYear);

      }

    });

    setTable(newTable);

  };

  const handleSubmit = async () => {

    if (financialsRef.current.cashFlow && isDataLoading == false) {

      const tableDatas = table || tableRef;

      setIsDisable(true);
      updateCashFlow(financialsRef.current.cashFlow, tableDatas, venture._id).then(res => {

        if (res) {

          setIsDisable(false);
          // setTable(res?.data)
          // history.push("/balancesheet");

          return true;

        }

      }).catch(error => {

        setIsDisable(false);
        console.error(error);

      });

    }

  };

  const tableRef = useRef(table);

  tableRef.current = table;

  useEffect(() => {

    return () => {

      handleSave();

    };

  }, []);

  const handleSave = async () => {

    setIsDisable(true);

    if (financialsRef.current.cashFlow) {

      const tableData = tableRef.current;

      updateCashFlow(financialsRef.current.cashFlow, tableData, venture._id).then(data => {

        if (data) {

          setIsDisable(false);

          return true;

        }

      }).catch(error => {

        console.error(error);
        setIsDisable(false);

      }
      );

    }

    setIsDisable(false);

  };

  const handleChangeRowHeaders = (value, rowindex) => {

    if (financials.currency === "") {

      errorDialogopen();

      return;

    }

    let newTable = [ ...table ];

    newTable.map(t => {

      if (rowindex === t.rowIndex) {

        t.rowLabel = value;

      }

    });

    setTable(newTable);

  };

  const handleCurrencyChange = e => {

    setIsDisable(true);

    let newfinancials = { ...financials };

    if (e.value) {

      setcurrency(e.value);
      newfinancials["currency"] = e.value;
      setFinancials(newfinancials);
      setIsDisable(false);

    }

    if (newfinancials["currency"] === e.value)

      setIsDisable(true);

    updateFinancials(venture.financials, newfinancials).then(res => {

      if (res.data) {

        setFinancials(res.data);
        setIsDisable(false);

      }

    }).catch(error => {

      setFinancials({
        plOutlooks          : [],
        frOutlooks          : [],
        cashFlow            : "",
        currentFundingSource: [],
        fundRaising         : {
          totalFundingNeed : 0,
          fundingAllocation: ""
        },
        totalCurrentFunding: 0,
        plOutlooksCurrency : "",
        frOutlooksCurrency : "",
        currency           : ""
      });

      console.error(error);

    });


  };

  const currencyOptions = currencies.map(currency => ({
    value: `${currency.symbol} ${currency.code}`,
    label: `${currency.symbol} ${currency.code}`
  }));

  const addComment = async (commentText, replyTo) => {

    try {

      const response = await createFinancialsComment(
        commentText,
        user._id,
        "cashFlow",
        "",
        venture.financials,
        replyTo,
        window.location.href,
        "Finance > Cash Flow"
      );

      setComments(response.data.comments.cashFlow);

    } catch (error) {

      console.error(error);

    }

  };

  const updateComment = async (commentid, commentText) => {

    try {

      const response = await updateFinancialsComment(
        commentText,
        user._id,
        commentid,
        venture._id,
        window.location.href,
        "Finance > Cash Flow"
      );

      setComments(response.data.comments.cashFlow);

    } catch (error) {

      console.error(error);

    }

  };

  const deleteComment = async commentid => {

    try {

      const response = await deleteFinancialsComment(
        "cashFlow",
        "",
        venture.financials,
        commentid,
        user._id,
        window.location.href,
        "Finance > Cash Flow"
      );

      setComments(response.data.comments.cashFlow);

    } catch (error) {

      console.error(error);

    }

  };

  return (
    <ModuleSections
      isexpand={isExpand}
      Collapsecomment={collapseComment}
      comments={comments}
      addComment={addComment}
      deleteComment={deleteComment}
      updateComment={updateComment}>
      <div className="box-display-style-for-next-and-back-button-with-justify">

        <div className="box-indiv-header-con" style={{ background: "white" }}>
          {loading ?
            <></>
            :
            <>
              <FinancialsNavBar />
              <IndividualSectionHeaders
                mainheading={"Finance"}
                subheading={"Cash Flow"}
                headingcontent={
                  "Derive your company's Cashflow"
                }
                isGtm={false}
                Dialogopen={Dialogopen}
                Dialogclose={Dialogclose}
                dialogbox={dialogbox}
                collapse_comment_function={collapse_comment_function}
                content={Datas.cashFlow}
                isexpand={isExpand}
                isSaveButton={true}
                isSaveBtnDisable={isDisable}
                handleSave={handleSave}
                isFinance={venture.userRole === "Commenter" ? false : true}
              />
            </>
          }
        </div>
        <Grid
          item
          id={styles.maingrid}
          xs={12}
          sx={{
            height   : "64%",
            width    : "100%",
            overflowY: "auto",
            overflowX: "auto",
            padding  : "0px 60px 0px"
          }}
          onClick={() => {

            // setIsedit(false);
            setSelectedindex(-1);

          }}>
          {loading ?
            <Grid
              container
              justifyContent="center"
              height="100%"
              alignItems="center">
              <Grid item>
                <CircularProgress size="60px" thickness={3} />
              </Grid>
            </Grid>
            :
            <>
              {isYearExist ?
                <>
                  <Box
                    sx={{
                      display       : "flex",
                      justifyContent: "space-between",
                      alignItems    : "center",
                      margin        : "10px 0px"
                    }}>
                    <Box id={styles.currencyDropdownBox}>
                      <Box id={styles.currencyTypoBox}>
                        <Typography id={styles.currencyTypo}>
                          Select currency
                          <HelpIconTooltip
                            tooltipdata={
                              "Note: Choosing a currency here will only change the symbol on your P&L outlook numbers below. It will not do currency conversion. Please review your numbers if you change your currency setting."
                            }
                          />
                        </Typography>
                      </Box>
                      <Box>
                        <CustomSelect
                          placeholder={"Currency"}
                          disabled={isDisable}
                          fontSize={"14px"}
                          value={currency}
                          name="plOutlooksCurrency"
                          handleChange={e => handleCurrencyChange(e)}
                          options={currencyOptions}></CustomSelect>
                      </Box>
                    </Box>
                    <Box id={styles.currencyTypoBox}>
                      {autosaving &&
                        <Typography sx={{ marginRight: "20px" }}>
                          Auto Saving....
                        </Typography>
                      }
                      <IconButton
                        component="span"
                        onClick={() => {

                          setIsExpand(!isExpand);

                        }}>
                        {isExpand ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
                      </IconButton>
                    </Box>
                  </Box>
                  {/* finance table section */}

                  {/* <FinanceTable /> */}

                  <CashFlowTable
                    table={table}
                    handleChange={handleChange}
                    financials={financials}
                    yearList={yearList}
                    handleChangeRowHeaders={handleChangeRowHeaders}
                    isedit={isedit}
                    selectedindex={selectedindex}
                    setIsedit={setIsedit}
                    setSelectedindex={setSelectedindex}
                  />
                  <Dialog
                    open={errorDialog}
                    PaperProps={{
                      style: {
                        borderRadius  : "20px",
                        backdropFilter: "blur(45px)",
                        background    : "#FFFFFF 0% 0% no-repeat padding-box",
                        boxShadow     : "0px 3px 6px #00000029",
                        border        : "1px solid #707070"
                      }
                    }}
                  // BackdropProps={{ invisible: true }}
                  >
                    <DialogContent>
                      <Box sx={{ textAlign: "center" }}>
                        <Typography gutterBottom>
                            Please enter the financial planning <b>‘Start Year'</b> in the <b>Year</b> drop-down OR select <b>‘Currency’</b> to begin the planning process.
                            Note: The Year update will impact all pages
                        </Typography>
                        <Button
                          variant="contained"
                          size="medium"
                          sx={{
                            backgroundColor: "#071AFF",
                            marginLeft     : "10px"
                          }}
                          onClick={() => {

                            errorDialogclose();

                          }}>
                          OK
                        </Button>
                      </Box>
                    </DialogContent>
                  </Dialog></> : <>
                  <Dialog
                    open={!isYearExist}
                    PaperProps={{
                      style: {
                        borderRadius  : "20px",
                        backdropFilter: "blur(45px)",
                        background    : "#FFFFFF 0% 0% no-repeat padding-box",
                        boxShadow     : "0px 3px 6px #00000029",
                        border        : "1px solid #707070"
                      }
                    }}
                  >
                    <DialogContent>
                      <Box sx={{ textAlign: "center" }}>
                        {venture.userRole === "Commenter" ?
                          <Typography gutterBottom>
                            No content available to display. Contact the project owner
                          </Typography> :
                          <Typography gutterBottom>
                            Please enter the financial <b>‘Start Year'</b> in the <b> P&L Outlook page</b> to begin the process.
                          </Typography>
                        }
                        <Button
                          variant="contained"
                          size="medium"
                          sx={{
                            backgroundColor: "#071AFF",
                            marginLeft     : "10px"
                          }}
                          onClick={() => {

                            venture.userRole === "Commenter" ? history.push("/dashboard") : history.push("/ploutlook");

                          }}>
                          OK
                        </Button>
                      </Box>
                    </DialogContent>
                  </Dialog>
                </>}
              {/* dropdown for the table */}

              {/* back and next button code */}
            </>
          }
        </Grid>
        {/* <div style={{ width: "100%", height: "14%" }}> */}
        <div className="ttribe-back-next-button-con">
          {loading ?
            <></>
            :
            <BackNextButton
              restrictedBackClick={() => {

                history.push("/fundingrunway");

              }}
              backClick={() => {

                handleSubmit();
                history.push("/fundingrunway");

              }}
              restrictedNextClick={() => {

                history.push("/balancesheet");

              }}
              nextClick={() => {

                handleSubmit();
                history.push("/balancesheet");

              }
              }
            />
          }
        </div>
        {/* </div> */}
      </div>
    </ModuleSections>
  );

};

export default CashFlow;
