import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box, Button,
  Dialog,
  DialogContent, IconButton, TextField, Typography
} from "@mui/material";
import React, { useState } from "react";
// import { useHistory } from "react-router-dom";
import PitchMarketSize from "../PitchReferenceLinks/PitchMarketSize";
import SectionHeader from "../SectionHeader";
import styles from "./MarketSize.module.css";

export default function MarketSize({
  logoimg,
  slides,
  isRender,
  setSlides,
  isPublished,
  ...props
}) {

  const [ marketSizeDialogbox, setMarketSizeDialogbox ] = useState(false);

  const slide = slides[isRender];

  // const history = useHistory();
  // const pathname = history.location.pathname.substring(1);

  const handleDetailsChange = (name, value) => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    newSlides[isRender][name] = value;
    setSlides(newSlides);
  
  };

  const handleChange = (sectionName, subSectionName, value) => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    newSlides[isRender][sectionName][subSectionName] = value;
    setSlides(newSlides);
  
  };

  const marketSizeDialogopen = () => {

    setMarketSizeDialogbox(true);
  
  };
  const marketSizeDialogclose = () => {

    setMarketSizeDialogbox(false);
  
  };

  return (
    <Box>
      <SectionHeader
        isEdit={props.isEdit}
        Collapsecomment={props.Collapsecomment}
        collapseCommentFunction={props.collapseCommentFunction}
        mainHead={slide.name}
        handleTitleChange={handleDetailsChange}
        logoimg={logoimg}
        isPublished={isPublished}
      />
      <Box
        id={styles.mainBox}
      >
        <Box
          id={styles.individualItemBox}
        >
          {/* -------- */}
          <Box
            id={styles.circleBox}
          >
            <Box
              id={styles.addressableMarketCircle}
            >
              <Box
                id={styles.circleTypoBox}
              >
                {props.isEdit ?
                  <TextField
                    value={slide.totalAddressableMarket.size}
                    onChange={e => {

                      handleChange(
                        "totalAddressableMarket",
                        "size",
                        e.target.value
                      );
                    
                    }}
                    placeholder="E.g. $1.2 B"
                    inputProps={{ maxLength: 10 }}
                    fullWidth
                    sx={{
                      "& .MuiInputBase-input": {
                        fontSize  : "2.5vh",
                        padding   : "1.5px 0px",
                        border    : "none",
                        color     : "#ffffff",
                        textAlign : "center",
                        fontWeight: "bold"
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "2px dotted #ffffff"
                      }
                    }}
                  />
                  :
                  <Typography
                    sx={{
                      fontSize  : "2.8vh",
                      color     : "#FFFFFF",
                      fontWeight: "bold"
                    }}
                  >
                    {slide.totalAddressableMarket.size
                      ? slide.totalAddressableMarket.size
                      : isPublished
                        ? ""
                        : "E.g. $1.2 B"}
                  </Typography>
                }
              </Box>
            </Box>
          </Box>

          <Box
            id={styles.individualTitleBox}
          >
            {props.isEdit ?
              <TextField
                value={slide.totalAddressableMarket.name}
                onChange={e => {

                  handleChange(
                    "totalAddressableMarket",
                    "name",
                    e.target.value
                  );
                
                }}
                inputProps={{ maxLength: 30 }}
                fullWidth
                multiline
                maxRows={2}
                minRows={2}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "1px 10px"
                  },
                  "& .MuiInputBase-input": {
                    fontSize  : "16px",
                    textAlign : "center",
                    color     : "#393939",
                    fontWeight: "bold"
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "2px dotted #B4B4FA"
                  }
                }}
              />
              :
              <Typography sx={{ fontSize: "2.3vh", color: "#393939", fontWeight: "bold" }}>
                {slide.totalAddressableMarket.name}
              </Typography>
            }
          </Box>
          <Box
            id={styles.individualTextBox}
          >
            {props.isEdit ?
              <TextField
                value={slide.totalAddressableMarket.description}
                onChange={e => {

                  handleChange(
                    "totalAddressableMarket",
                    "description",
                    e.target.value
                  );
                
                }}
                inputProps={{ maxLength: 50 }}
                placeholder="$ or # Revenue per year or Customer Base"
                fullWidth
                multiline
                maxRows={2}
                minRows={2}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "5px 9px"
                  },
                  "& .MuiInputBase-input": {
                    fontSize  : "1.6vh",
                    padding   : "0px 0px",
                    color     : "#707070",
                    textAlign : "center",
                    whiteSpace: "pre-line"

                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border : "2px dotted #B4B4FA",
                    padding: "0px 0px"
                  }
                }}
              />
              :
              <Box
                sx={{
                  width       : "100%",
                  border      : "1px solid #BEBEBE",
                  height      : "60px",
                  borderRadius: "5px",
                  overflowY   : "auto"
                }}
              >
                <Box
                  sx={{
                    margin   : "5px 9px",
                    wordWrap : "break-word",
                    textAlign: "center"
                  }}
                >
                  <Typography
                    sx={{
                      fontSize  : "1.6vh",
                      color     : "#707070",
                      whiteSpace: "pre-line"
                    }}
                  >
                    {slide.totalAddressableMarket.description}
                  </Typography>
                </Box>
              </Box>
            }
          </Box>
        </Box>
        {/* ----2-- */}
        <Box
          sx={{
            width         : "28%",
            display       : "flex",
            flexDirection : "column",
            alignItems    : "center",
            justifyContent: "flex-end"
          }}
        >
          <Box
            sx={{
              width         : "65%",
              height        : "0%",
              display       : "flex",
              justifyContent: "center",
              paddingBottom : "65%"
            }}
          >
            <Box
              sx={{
                width          : "100%",
                height         : "0%",
                borderRadius   : "50%",
                backgroundColor: "#083A9A",
                display        : "flex",
                justifyContent : "center",
                alignItems     : "center",
                paddingBottom  : "100%",
                position       : "relative"
              }}
            >
              <Box
                sx={{
                  position      : "absolute",
                  top           : "43%",
                  width         : "90%",
                  display       : "flex",
                  justifyContent: "center",
                  alignItems    : "center"
                }}
              >
                {props.isEdit ?
                  <TextField
                    value={slide.servicableAvailableMarket.size}
                    onChange={e => {

                      handleChange(
                        "servicableAvailableMarket",
                        "size",
                        e.target.value
                      );
                    
                    }}
                    placeholder="E.g. $240 M"
                    inputProps={{ maxLength: 10 }}
                    fullWidth
                    sx={{
                      "& .MuiInputBase-input": {
                        fontSize  : "20px",
                        padding   : "1.5px 0px",
                        border    : "none",
                        color     : "#ffffff",
                        textAlign : "center",
                        fontWeight: "bold"
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "2px dotted #ffffff"
                      }
                    }}
                  />
                  :
                  <Typography
                    sx={{
                      fontSize  : "2.5vh",
                      color     : "#FFFFFF",
                      fontWeight: "bold"
                    }}
                  >
                    {slide.servicableAvailableMarket.size
                      ? slide.servicableAvailableMarket.size
                      : isPublished
                        ? ""
                        : "E.g. $240 M"}
                  </Typography>
                }
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              textAlign   : "center",
              marginTop   : "10px",
              marginBottom: "10px",
              height      : "7vh"
            }}
          >
            {props.isEdit ?
              <TextField
                value={slide.servicableAvailableMarket.name}
                onChange={e => {

                  handleChange(
                    "servicableAvailableMarket",
                    "name",
                    e.target.value
                  );
                
                }}
                inputProps={{ maxLength: 30 }}
                fullWidth
                multiline
                maxRows={2}
                minRows={2}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "1px 10px"
                  },
                  "& .MuiInputBase-input": {
                    fontSize  : "16px",
                    textAlign : "center",
                    color     : "#393939",
                    fontWeight: "bold"
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "2px dotted #B4B4FA"
                  }
                }}
              />
              :
              <Typography sx={{ fontSize: "2.3vh", color: "#393939", fontWeight: "bold" }}>
                {slide.servicableAvailableMarket.name}
              </Typography>
            }
          </Box>
          <Box sx={{ width: "80%" }}>
            {props.isEdit ?
              <TextField
                value={slide.servicableAvailableMarket.description}
                onChange={e => {

                  handleChange(
                    "servicableAvailableMarket",
                    "description",
                    e.target.value
                  );
                
                }}
                inputProps={{ maxLength: 50 }}
                placeholder="$ or # Revenue per year or Customer Base"
                fullWidth
                multiline
                maxRows={2}
                minRows={2}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "5px 9px"
                  },
                  "& .MuiInputBase-input": {
                    fontSize  : "1.6vh",
                    padding   : "0px 0px",
                    color     : "#707070",
                    textAlign : "center",
                    whiteSpace: "pre-line"
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border : "2px dotted #B4B4FA",
                    padding: "0px 0px"
                  }
                }}
              />
              :
              <Box
                sx={{
                  width       : "100%",
                  border      : "1px solid #BEBEBE",
                  height      : "60px",
                  borderRadius: "5px",
                  overflowY   : "auto"
                }}
              >
                <Box
                  sx={{
                    margin   : "5px 9px",
                    wordWrap : "break-word",
                    textAlign: "center"
                  }}
                >
                  <Typography
                    sx={{
                      fontSize  : "1.6vh",
                      color     : "#707070",
                      whiteSpace: "pre-line"
                    }}
                  >
                    {slide.servicableAvailableMarket.description}
                  </Typography>
                </Box>
              </Box>
            }
          </Box>
        </Box>
        {/* --3--- */}
        <Box
          sx={{
            // border: 1,
            width         : "27%",
            display       : "flex",
            flexDirection : "column",
            alignItems    : "center",
            // justifyContent: "space-between",
            justifyContent: "flex-end"
          }}
        >
          <Box
            sx={{
              width         : "55%",
              height        : "0%",
              //   borderRadius: "50%",
              //   border: 1,
              display       : "flex",
              justifyContent: "center",
              paddingBottom : "55%"
            }}
          >
            <Box
              sx={{
                width          : "100%",
                height         : "0%",
                borderRadius   : "50%",
                border         : 1,
                backgroundColor: "#4A56E3",
                display        : "flex",
                justifyContent : "center",
                alignItems     : "center",
                paddingBottom  : "100%",
                position       : "relative"
              }}
            >
              {/* <Typography
                sx={{ fontSize: "55px", color: "#FFFFFF", fontWeight: "bold" }}
              >
                $16 B
              </Typography> */}
              <Box
                sx={{
                  position      : "absolute",
                  top           : "44%",
                  width         : "90%",
                  display       : "flex",
                  justifyContent: "center",
                  alignItems    : "center"
                }}
              >
                {props.isEdit ?
                  <TextField
                    value={slide.shareOfMarket.size}
                    onChange={e => {

                      handleChange("shareOfMarket", "size", e.target.value);
                    
                    }}
                    placeholder="E.g. $60 M"
                    inputProps={{ maxLength: 10 }}
                    fullWidth
                    sx={{
                      // "& .MuiOutlinedInput-root": {
                      //   padding: "0px 0px",
                      // },
                      "& .MuiInputBase-input": {
                        fontSize  : "16px",
                        padding   : "1.5px 0px",
                        border    : "none",
                        color     : "#ffffff",
                        textAlign : "center",
                        fontWeight: "bold"
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "2px dotted #ffffff"
                      }
                    }}
                  />
                  :
                  <Typography
                    sx={{
                      fontSize  : "2vh",
                      // fontSize: "1.5vw",
                      color     : "#FFFFFF",
                      fontWeight: "bold"
                    }}
                  >
                    {slide.shareOfMarket.size
                      ? slide.shareOfMarket.size
                      : isPublished
                        ? ""
                        : "E.g. $60 M"}
                  </Typography>
                }
                {/* <Typography
                  sx={{
                    fontSize: "2vw",
                    color: "#FFFFFF",
                    fontWeight: "bold",
                  }}
                >
                  $60 M
                </Typography> */}
              </Box>

              {/* market size reference link */}

              {props.isEdit ?
                <Box
                  sx={{ position: "absolute", top: "-50px", right: "-35px" }}
                >
                  <Button
                    style={{
                      // position: "absolute",
                      textTransform : "none",
                      textDecoration: "underline",
                      fontSize      : "12px",
                      // top: "-20px",
                      // right: "-10px",
                      padding       : "0px",
                      fontWeight    : "bold"
                    }}
                    onClick={() => {

                      marketSizeDialogopen();
                    
                    }}
                  >
                    Market Size
                  </Button>
                  <Dialog
                    open={marketSizeDialogbox}
                    onClose={marketSizeDialogclose}
                    PaperProps={{
                      style: {
                        borderRadius   : "20px",
                        backdropFilter : "blur(100px)",
                        backgroundColor: "#ffffff",
                        boxShadow      : "0px 3px 6px #00000029",
                        // border: "1px solid #707070",
                        width          : "70%"
                      }
                    }}
                    // BackdropProps={{ invisible: true }}
                    //
                    maxWidth="false"
                    fullWidth
                  >
                    <DialogContent sx={{ padding: "40px" }}>
                      <Box
                        sx={{
                          width: "100%",
                          // height: "60vh",
                          // display: "flex",
                          // alignItems: "center",
                          // justifyContent: "center",

                          position: "relative"
                        }}
                      >
                        <PitchMarketSize />
                        <IconButton
                          onClick={() => {

                            marketSizeDialogclose();
                          
                          }}
                          sx={{
                            position: "absolute",
                            right   : "-40px",
                            top     : "-40px"
                          }}
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      </Box>
                    </DialogContent>
                  </Dialog>
                </Box>
                :
                <></>
              }
            </Box>
          </Box>

          <Box
            sx={{
              textAlign   : "center",
              marginTop   : "10px",
              marginBottom: "10px",
              height      : "7vh"
              //   border: 1,
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
            }}
          >
            {props.isEdit ?
              <TextField
                value={slide.shareOfMarket.name}
                // placeholder={
                //   placeholders[segmentindex]["personaDescription"]
                // }
                onChange={e => {

                  handleChange("shareOfMarket", "name", e.target.value);
                
                }}
                inputProps={{ maxLength: 30 }}
                fullWidth
                multiline
                maxRows={2}
                minRows={2}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "1px 10px"
                  },
                  "& .MuiInputBase-input": {
                    fontSize  : "16px",
                    textAlign : "center",
                    fontWeight: "bold"
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "2px dotted #B4B4FA"
                  }
                }}
              />
              :
              <Typography sx={{ fontSize: "2.3vh", color: "#393939", fontWeight: "bold" }}>
                {slide.shareOfMarket.name}
              </Typography>
            }
            {/* <Typography sx={{ color: "#393939", fontWeight: "bold" }}>
              Share of Market
            </Typography> */}
          </Box>
          <Box sx={{ width: "80%" }}>
            {props.isEdit ?
              <TextField
                value={slide.shareOfMarket.description}
                onChange={e => {

                  handleChange("shareOfMarket", "description", e.target.value);
                
                }}
                inputProps={{ maxLength: 50 }}
                placeholder="$ or # Revenue per year or Customer Base"
                fullWidth
                multiline
                maxRows={2}
                minRows={2}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "5px 9px"
                    //   border: "1px solid #B4B4FA",
                  },
                  "& .MuiInputBase-input": {
                    fontSize  : "1.6vh",
                    padding   : "0px 0px",
                    // border: "none",
                    color     : "#707070",
                    textAlign : "center",
                    whiteSpace: "pre-line"
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border : "2px dotted #B4B4FA",
                    padding: "0px 0px"
                  }
                }}
              />
              :
              // <TextField
              //   value={slide.shareOfMarket.description}
              //   disabled

              //   fullWidth
              //   multiline
              //   maxRows={2}
              //   minRows={2}
              //   sx={{
              //     "& .MuiOutlinedInput-root": {
              //       padding: "5px 9px",
              //       //   border: "1px solid #B4B4FA",
              //     },
              //     "& .MuiInputBase-input": {
              //       fontSize: "14px",
              //       padding: "0px 0px",
              //       // border: "none",
              //       "-webkit-text-fill-color": `#707070 !important`,
              //       color: `#707070 !important`,
              //       textAlign: "center",
              //     },
              //     "& .MuiOutlinedInput-notchedOutline": {
              //       border: "1px solid #B4B4FA",
              //       padding: "0px 0px",
              //     },
              //   }}
              // />
              <Box
                sx={{
                  width       : "100%",
                  border      : "1px solid #BEBEBE",
                  height      : "60px",
                  borderRadius: "5px",
                  // padding: "10px 15px",
                  overflowY   : "auto"
                }}
              >
                <Box
                  sx={{
                    margin   : "5px 9px",
                    wordWrap : "break-word",
                    textAlign: "center"
                  }}
                >
                  <Typography
                    sx={{
                      fontSize  : "1.6vh",
                      color     : "#707070",
                      lineHeight: "23px",
                      whiteSpace: "pre-line"
                    }}
                  >
                    {slide.shareOfMarket.description}
                  </Typography>
                </Box>
              </Box>
            }
          </Box>
        </Box>
      </Box>
    </Box>
  );

}
