import "./calendar.css";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import {
  Tooltip
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";


const useStyles = makeStyles(() => ({
  customWidth: {
    width          : 200,
    fontSize       : 10,
    backgroundColor: "white",
    color          : "#707070",
    boxShadow      : "0px 3px 6px #00000029",
    border         : "1px solid #707070"
    // borderRadius: 10,
  }
}));

const CampaignCalendar = props => {

  const events = props.events;
  const classes = useStyles();

  const renderMonthWeekEventContent = eventInfo => {

    // console.log("eventinfo", eventInfo);
    return (
      <Tooltip
        title={ eventInfo.event.extendedProps.description }
        disableFocusListener
        placement="top-start"
        classes={ { tooltip: classes.customWidth } }
      >
        <div style={ { display: "flex", alignItems: "center" } }>
          <div
            style={ {
              height         : "7px",
              width          : "7px",
              borderColor    : `${eventInfo.borderColor}`,
              borderRadius   : "50px",
              backgroundColor: `${eventInfo.backgroundColor}`,
              margin         : "0px 4px"
            } }
          ></div>

          <b
          // style={{
          //   textDecoration:
          //     eventInfo.event.extendedProps.isCompleted === "yes"
          //       ? "line-through"
          //       : "none",
          //   textDecorationThickness:
          //     eventInfo.event.extendedProps.isCompleted === "yes"
          //       ? "3px"
          //       : "none",
          //   color:
          //     eventInfo.event.extendedProps.isCompleted === "no" ? "red" : "",
          // }}
          >
            { eventInfo.event.title }
          </b>
        </div>
      </Tooltip>
    );
  
  };

  const renderDayListEventContent = eventInfo => {

    return (
      <Tooltip
        title={ eventInfo.event.extendedProps.description }
        disableFocusListener
        placement="top-start"
        classes={ { tooltip: classes.customWidth } }
      >
        <div style={ { display: "flex", alignItems: "center" } }>
          <div
            style={ {
              height         : "7px",
              width          : "7px",
              borderColor    : `${eventInfo.borderColor}`,
              borderRadius   : "50px",
              backgroundColor: `${eventInfo.backgroundColor}`,
              margin         : "0px 4px"
            } }
          ></div>

          <div style={ { display: "flex", flexDirection: "column" } }>
            <b
            // style={{
            //   textDecoration:
            //     eventInfo.event.extendedProps.isCompleted === "yes"
            //       ? "line-through"
            //       : "none",
            //   textDecorationThickness:
            //     eventInfo.event.extendedProps.isCompleted === "yes"
            //       ? "3px"
            //       : "none",
            //   color:
            //     eventInfo.event.extendedProps.isCompleted === "no"
            //       ? "red"
            //       : "",
            // }}
            >
              { eventInfo.event.title }
            </b>
            <b
            // style={{
            //   textDecoration:
            //     eventInfo.event.extendedProps.isCompleted === "yes"
            //       ? "line-through"
            //       : "none",
            //   textDecorationThickness:
            //     eventInfo.event.extendedProps.isCompleted === "yes"
            //       ? "3px"
            //       : "none",
            //   color:
            //     eventInfo.event.extendedProps.isCompleted === "no"
            //       ? "red"
            //       : "",
            // }}
            >
              { eventInfo.event.extendedProps.description }
            </b>
          </div>
        </div>
      </Tooltip>
    );
  
  };

  const renderEventContent = eventInfo => {

    if (
      eventInfo.view.type === "dayGrid" ||
      eventInfo.view.type === "listWeek"
    ) {

      return renderDayListEventContent(eventInfo);
    
    } else {

      return renderMonthWeekEventContent(eventInfo);
    
    }
  
  };

  return (
    <>
      { events ?
        <>
          <FullCalendar
            plugins={ [
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin,
              bootstrapPlugin
            ] }
            initialView="dayGridMonth"
            buttonIcons={ true }
            headerToolbar={ {
              right: "dayGridMonth dayGridWeek dayGrid listWeek",
              // center: "prev,next",
              left : "title,prev,next"
            } }
            buttonText={ {
              dayGridMonth: "Month",
              dayGridWeek : "Week",
              dayGrid     : "Day",
              listWeek    : "List"
            } }
            eventContent={ renderEventContent }
            // titleFormat={{ year: 'numeric', month: 'short' }}
            // customButtons={{
            //   all: {
            //     text: "ALL",
            //     click: () => fetchEvents(),
            //   },
            //   conference: {
            //     text: "CONFERENCE",
            //     click: () => fetchEventsByCategory("conference"),
            //   },
            //   business: {
            //     text: "BUSINESS",
            //     click: () => fetchEventsByCategory("business"),
            //   },
            //   party: {
            //     text: "PARTY",
            //     click: () => fetchEventsByCategory("party"),
            //   },
            // }}
            events={ events }
            nowIndicator
            displayEventTime={ false }
          // dateClick={handleDateSelect}
          // eventClick={handleEventSelect}
          // eventContent={renderEventContent}
          // eventMouseEnter={(e)=>{console.log(e)}}
          />
        </>
        :
        <></>
      }
    </>
  );

};

export default CampaignCalendar;
