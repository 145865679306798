import { Box } from "@mui/material";
import React from "react";

const line = {
  height         : "5px",
  width          : "2px",
  backgroundColor: "#B1AAAA"
};
// const linelong = {
//   height: "25px",
//   width: "2px",
//   backgroundColor: "#B1AAAA",
// };
const circle = {
  // position:'absolute',
  height      : "5px",
  width       : "5px",
  border      : "2px solid #B1AAAA",
  borderRadius: "50px"
};
const bluecircle = {
  height         : "5px",
  width          : "5px",
  border         : "2px solid blue",
  borderRadius   : "50px",
  backgroundColor: "blue"
};

function DotComponent (props) {

  return (
    <div style={ { marginRight: "5%", marginLeft: "5%" } }>
      <Box
        sx={ { display: "flex", flexDirection: "column", alignItems: "center" } }
      >
        <div style={ line }></div>
        { props.active ?
          <div style={ bluecircle }></div>
          :
          <div style={ circle }></div>
        }
        <div
          style={ {
            height         : props.islastsubtitle ? "10px" : "25px",
            width          : "2px",
            backgroundColor: "#B1AAAA"
          } }
        ></div>
      </Box>
    </div>
  );

}

export default DotComponent;
