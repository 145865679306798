import React from "react";
import {
  Box, TextField, Typography
} from "@mui/material";

import { useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import styles from "./PricingModel.module.css";

export default function PricingModel(props) {

  const { venture } = useSelector(state => ({ ...state }));

  const revenueStreams = props.revenueStreams;
  const revenueStreamIndex = props.revenueStreamIndex;

  const handleOtherPricingModelChange = (index, value) => {

    let newRevenueStreams = [ ...revenueStreams ];

    newRevenueStreams[index]["pricingModel"]["otherPricingModel"] = value;
    props.setRevenueStreams(newRevenueStreams);
  
  };

  const handleClickedPricingModel = (index, name) => {

    let newRevenueStreams = [ ...revenueStreams ];

    newRevenueStreams[index]["pricingModel"]["selectedPricingModel"].push(name);
    props.setRevenueStreams(newRevenueStreams);
  
  };

  const handleRemovePricingModel = (index, name) => {

    let newRevenueStreams = [ ...revenueStreams ];

    newRevenueStreams[index]["pricingModel"]["selectedPricingModel"] =
      newRevenueStreams[index]["pricingModel"]["selectedPricingModel"].filter(
        pricingModel => {

          return pricingModel !== name;
        
        }
      );

    props.setRevenueStreams(newRevenueStreams);
  
  };

  // console.log(revenueStreams);

  // const history = useHistory();

  
  return (
    // <CreatePricingComponent barHighlight="bar2">
    <Box sx={{}}>
      <Box>
        <Typography
          id={styles.pricingTopTypo}
        // sx={{ color: "#5F5E5E" }}
        >
          Pricing Model
        </Typography>
      </Box>
      <Box>
        <Typography
          id={styles.pricingSubTypo}
        // sx={{ fontSize: "12px", color: "#868686" }}
        >
          An effective business model should be convenient and attractive for
          your customer, and at the same time, help you reach your business
          goals. Select the models that best suits your business
        </Typography>
      </Box>

      {/* multi select main box */}
      <Box
        id={styles.multiSelectMainBox}
      // sx={{
      //   width: "100%",
      //   display: "flex",
      //   flexWrap: "wrap",
      //   justifyContent: "space-between",
      //   marginTop: "20px",
      // }}
      >
        {/* -----1------ */}
        <Box
          id={styles.individualSelectBox}
          sx={{
            backgroundColor: revenueStreams[revenueStreamIndex]["pricingModel"][
              "selectedPricingModel"
            ].includes("SaaS")
              ? venture.userRole === "Commenter"
                ? "#DCDCDC"
                : "#FDD3A4"
              : "#ffffff"
            // backgroundColor: revenueStreams[revenueStreamIndex]["pricingModel"][
            //   "selectedPricingModel"
            // ].includes("SaaS")
            //   ? "#FDD3A4"
            //   : "#ffffff",
          }}
          onClick={() => {

            // setSaas(!saas);
            venture.userRole === "Commenter" ?
              <></>
              : revenueStreams[revenueStreamIndex]["pricingModel"][
                "selectedPricingModel"
              ].includes("SaaS") ?
                handleRemovePricingModel(revenueStreamIndex, "SaaS")
                :
                handleClickedPricingModel(revenueStreamIndex, "SaaS")
            ;
          
          }}
        >
          <Box
            id={styles.individualSelectSubBox}
          // sx={{
          //   margin: "0px 10px",
          //   // border: 1,
          //   height: "-webkit-fill-available",
          //   display: "flex",
          //   justifyContent: "space-evenly",
          //   flexDirection: "column",
          //   wordWrap: "break-word",
          // }}
          >
            <Typography
              id={styles.selectBoxTopTypo}
            // sx={{ fontSize: "14px", color: "#5F5E5E", fontWeight: "bold" }}
            >
              SaaS
            </Typography>
            <Typography
              id={styles.selectBoxSubTypo}
            // sx={{ fontSize: "12px", color: "#868686" }}
            >
              Subscriptions to cloud-hosted software
            </Typography>
          </Box>
        </Box>
        {/* ----------- */}
        {/* -----2------ */}
        <Box
          id={styles.individualSelectBox}
          sx={{
            backgroundColor: revenueStreams[revenueStreamIndex]["pricingModel"][
              "selectedPricingModel"
            ].includes("Subscription/Membership")
              ? venture.userRole === "Commenter"
                ? "#DCDCDC"
                : "#FDD3A4"
              : "#ffffff"
          }}
          onClick={() => {

            // setSaas(!saas);
            venture.userRole === "Commenter" ?
              <></>
              : revenueStreams[revenueStreamIndex]["pricingModel"][
                "selectedPricingModel"
              ].includes("Subscription/Membership") ?
                handleRemovePricingModel(
                  revenueStreamIndex,
                  "Subscription/Membership"
                )
                :
                handleClickedPricingModel(
                  revenueStreamIndex,
                  "Subscription/Membership"
                )
            ;
          
          }}
        >
          <Box id={styles.individualSelectSubBox}>
            <Typography id={styles.selectBoxTopTypo}>
              Subscription/Membership
            </Typography>
            <Typography id={styles.selectBoxSubTypo}>
              Product or service sold on a recurring basis
            </Typography>
          </Box>
        </Box>
        {/* ----------- */}
        {/* -----3------ */}
        <Box
          id={styles.individualSelectBox}
          sx={{
            backgroundColor: revenueStreams[revenueStreamIndex]["pricingModel"][
              "selectedPricingModel"
            ].includes("Usage Based")
              ? venture.userRole === "Commenter"
                ? "#DCDCDC"
                : "#FDD3A4"
              : "#ffffff"
          }}
          onClick={() => {

            // setSaas(!saas);
            venture.userRole === "Commenter" ?
              <></>
              : revenueStreams[revenueStreamIndex]["pricingModel"][
                "selectedPricingModel"
              ].includes("Usage Based") ?
                handleRemovePricingModel(revenueStreamIndex, "Usage Based")
                :
                handleClickedPricingModel(revenueStreamIndex, "Usage Based")
            ;
          
          }}
        >
          <Box id={styles.individualSelectSubBox}>
            <Typography id={styles.selectBoxTopTypo}>Usage Based</Typography>
            <Typography id={styles.selectBoxSubTypo}>
              Fee for # of units consumed
            </Typography>
          </Box>
        </Box>
        {/* ----------- */}
        {/* -----4------ */}
        <Box
          id={styles.individualSelectBox}
          sx={{
            backgroundColor: revenueStreams[revenueStreamIndex]["pricingModel"][
              "selectedPricingModel"
            ].includes("Enterprise license")
              ? venture.userRole === "Commenter"
                ? "#DCDCDC"
                : "#FDD3A4"
              : "#ffffff"
          }}
          onClick={() => {

            // setSaas(!saas);

            venture.userRole === "Commenter" ?
              <></>
              : revenueStreams[revenueStreamIndex]["pricingModel"][
                "selectedPricingModel"
              ].includes("Enterprise license") ?
                handleRemovePricingModel(revenueStreamIndex, "Enterprise license")
                :
                handleClickedPricingModel(
                  revenueStreamIndex,
                  "Enterprise license"
                )
            ;
          
          }}
        >
          <Box id={styles.individualSelectSubBox}>
            <Typography id={styles.selectBoxTopTypo}>
              Enterprise License
            </Typography>
            <Typography id={styles.selectBoxSubTypo}>
              Single license. Term limits, renewals
            </Typography>
          </Box>
        </Box>
        {/* ----------- */}

        <Box
          id={styles.secondRowMainBox}
        // sx={{
        //   width: "100%",
        //   display: "flex",
        //   justifyContent: "space-between",
        //   marginTop: "20px",
        // }}
        >
          {/* -----5------ */}
          <Box
            id={styles.individualSelectBox}
            sx={{
              backgroundColor: revenueStreams[revenueStreamIndex][
                "pricingModel"
              ]["selectedPricingModel"].includes("Marketplace")
                ? venture.userRole === "Commenter"
                  ? "#DCDCDC"
                  : "#FDD3A4"
                : "#ffffff"
            }}
            onClick={() => {

              // setSaas(!saas);
              venture.userRole === "Commenter" ?
                <></>
                : revenueStreams[revenueStreamIndex]["pricingModel"][
                  "selectedPricingModel"
                ].includes("Marketplace") ?
                  handleRemovePricingModel(revenueStreamIndex, "Marketplace")
                  :
                  handleClickedPricingModel(revenueStreamIndex, "Marketplace")
              ;
            
            }}
          >
            <Box id={styles.individualSelectSubBox}>
              <Typography id={styles.selectBoxTopTypo}>Marketplace</Typography>
              <Typography id={styles.selectBoxSubTypo}>
                Commission fees (% of sales), listing fees
              </Typography>
            </Box>
          </Box>
          {/* ----------- */}
          {/* -----6------ */}
          <Box
            id={styles.individualSelectBox}
            sx={{
              backgroundColor: revenueStreams[revenueStreamIndex][
                "pricingModel"
              ]["selectedPricingModel"].includes("Transactional")
                ? venture.userRole === "Commenter"
                  ? "#DCDCDC"
                  : "#FDD3A4"
                : "#ffffff"
            }}
            onClick={() => {

              // setSaas(!saas);

              venture.userRole === "Commenter" ?
                <></>
                : revenueStreams[revenueStreamIndex]["pricingModel"][
                  "selectedPricingModel"
                ].includes("Transactional") ?
                  handleRemovePricingModel(revenueStreamIndex, "Transactional")
                  :
                  handleClickedPricingModel(revenueStreamIndex, "Transactional")
              ;
            
            }}
          >
            <Box id={styles.individualSelectSubBox}>
              <Typography id={styles.selectBoxTopTypo}>
                Transactional
              </Typography>
              <Typography id={styles.selectBoxSubTypo}>
                Flat or tiered fees for each or a set of transactions.
              </Typography>
            </Box>
          </Box>
          {/* ----------- */}
          {/* -----7------ */}
          <Box
            id={styles.individualSelectBox}
            sx={{
              backgroundColor: revenueStreams[revenueStreamIndex][
                "pricingModel"
              ]["selectedPricingModel"].includes("Advertising")
                ? venture.userRole === "Commenter"
                  ? "#DCDCDC"
                  : "#FDD3A4"
                : "#ffffff"
            }}
            onClick={() => {

              // setSaas(!saas);
              venture.userRole === "Commenter" ?
                <></>
                : revenueStreams[revenueStreamIndex]["pricingModel"][
                  "selectedPricingModel"
                ].includes("Advertising") ?
                  handleRemovePricingModel(revenueStreamIndex, "Advertising")
                  :
                  handleClickedPricingModel(revenueStreamIndex, "Advertising")
              ;
            
            }}
          >
            <Box id={styles.individualSelectSubBox}>
              <Typography id={styles.selectBoxTopTypo}>Advertising</Typography>
              <Typography
                id={styles.selectBoxSubTypo}
              // sx={{ fontSize: "12px", color: "#868686" }}
              >
                Fee from advertisers
              </Typography>
            </Box>
          </Box>
          {/* ----------- */}
          {/* -----8------ */}
          <Box
            id={styles.individualSelectBox}
            sx={{
              backgroundColor: revenueStreams[revenueStreamIndex][
                "pricingModel"
              ]["selectedPricingModel"].includes("Time & Materials")
                ? venture.userRole === "Commenter"
                  ? "#DCDCDC"
                  : "#FDD3A4"
                : "#ffffff"
            }}
            onClick={() => {

              // setSaas(!saas);
              venture.userRole === "Commenter" ?
                <></>
                : revenueStreams[revenueStreamIndex]["pricingModel"][
                  "selectedPricingModel"
                ].includes("Time & Materials") ?
                  handleRemovePricingModel(revenueStreamIndex, "Time & Materials")
                  :
                  handleClickedPricingModel(
                    revenueStreamIndex,
                    "Time & Materials"
                  )
              ;
            
            }}
          >
            <Box id={styles.individualSelectSubBox}>
              <Typography id={styles.selectBoxTopTypo}>
                Time & Materials
              </Typography>
              <Typography
                id={styles.selectBoxSubTypo}
              // sx={{ fontSize: "12px", color: "#868686" }}
              >
                For Projects or Services based businesses
              </Typography>
            </Box>
          </Box>
          {/* ----------- */}
        </Box>

        {/* bottom box */}
        <Box id={styles.multiselectBottomBox}>
          {/* -----9------ */}
          <Box
            id={styles.individualSelectBox}
            sx={{
              backgroundColor: revenueStreams[revenueStreamIndex][
                "pricingModel"
              ]["selectedPricingModel"].includes("Retail - Online/E-commerce")
                ? venture.userRole === "Commenter"
                  ? "#DCDCDC"
                  : "#FDD3A4"
                : "#ffffff"
            }}
            onClick={() => {

              // setSaas(!saas);
              venture.userRole === "Commenter" ?
                <></>
                : revenueStreams[revenueStreamIndex]["pricingModel"][
                  "selectedPricingModel"
                ].includes("Retail - Online/E-commerce") ?
                  handleRemovePricingModel(
                    revenueStreamIndex,
                    "Retail - Online/E-commerce"
                  )
                  :
                  handleClickedPricingModel(
                    revenueStreamIndex,
                    "Retail - Online/E-commerce"
                  )
              ;
            
            }}
          >
            <Box id={styles.individualSelectSubBox}>
              <Typography id={styles.selectBoxTopTypo}>
                Retail-Online/ <br />
                E-commerce
              </Typography>
              <Typography id={styles.selectBoxSubTypo}>
                {/* For Projects or Services based businesses */}
              </Typography>
            </Box>
          </Box>
          {/* ----------- */}
          <Box
            id={styles.textFieldTypoMainBox}
          // sx={{
          //   width: "73.8%",
          //   // border: 1,
          //   display: "flex",
          //   justifyContent: "space-between",
          // }}
          >
            <Box id={styles.otherTypoMainBox}>
              <Typography
                id={styles.otherTypo}
              // sx={{
              //   color: "#5F5E5E",
              //   fontSize: "14px",
              //   fontWeight: "bold",
              // }}
              >
                Other
              </Typography>
            </Box>
            <Box
              id={styles.textFieldBox}
            // sx={{ width: "90%" }}
            >
              <TextField
                onChange={e => {

                  handleOtherPricingModelChange(
                    revenueStreamIndex,
                    e.target.value
                  );
                
                }}
                disabled={venture.userRole === "Commenter" ? true : false}
                value={
                  revenueStreams[revenueStreamIndex].pricingModel
                    .otherPricingModel
                }
                fullWidth
                multiline
                maxRows={4}
                minRows={4}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "14.5px 16px"
                  },
                  "& .MuiInputBase-input": {
                    fontSize  : "12px",
                    color     : "#707070",
                    lineHeight: "17px"
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #E1E7E9"
                  }
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
    // </CreatePricingComponent>
  );

}
