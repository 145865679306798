import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box, Button,
  Dialog,
  DialogContent, IconButton, TextField, Typography
} from "@mui/material";
// import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import { getSegments } from "../../../../services/persona";
import { getProblemSolution } from "../../../../services/problemsolution";
import SegmentsPersonasDialog from "../../Customer/TargetSegments/SegmentsPersonasDialog";
import ReferenceLinkTooltip from "../PitchReferenceLinks/ReferenceLinkTooltip";
import SectionHeader from "../SectionHeader";

// const useStyles = makeStyles((theme) => ({
//   helperText: {
//     fontSize: "10px",
//     margin: "0px",
//   },
// }));

export default function Customer ({
  logoimg,
  slides,
  isRender,
  setSlides,
  isPublished,
  ...props
}) {

  let { venture } = useSelector(state => {

    return { ...state };
  
  });

  const [ segmentPersonaDialog, setSegmentPersonaDialog ] = useState(false);
  const [ segmentsDialog, setSegmentsDialog ] = useState([]);

  useEffect(() => {

    fetchSegments();
  
  }, []);

  const fetchSegments = async () => {

    try {

      const solutionResponse = await getProblemSolution(venture.problemSolution);
      let fullSegmentData;

      try {

        const response = await getSegments(venture.customer);
        const resSegment = response?.data?.segmentsPersonas?.segments;

        if (resSegment) {
          
          fullSegmentData = resSegment;

        } else {

          setSegmentsDialog([]);
          fullSegmentData = [];

        }


      } catch (error) { console.error(error); }

      const solutionData = solutionResponse?.data?.segments;

      if (solutionData) {

        const solutionGridData = [];

        solutionData?.map(solData => {

          solData?.personas?.map(persona => {

            solutionGridData.push(persona);

          });

        });

        fullSegmentData?.map(eachSegment => {

          eachSegment?.personas?.map(pp => {

            solutionGridData.map(dd => {

              if (dd._id === pp._id) {

                pp.SolutionGrid = dd.SolutionGrid;

              }

            });

          });

        });
        
        setSegmentsDialog(fullSegmentData);

      } else {

        setSegmentsDialog([]);

      }
    
    } catch (error) {

      console.error(error);
    
    }

  };

  const segmentPersonaDialogopen = () => {

    setSegmentPersonaDialog(true);
  
  };
  const segmentPersonaDialogclose = () => {

    setSegmentPersonaDialog(false);
  
  };

  const slide = slides[isRender];

  // const classes = useStyles();

  const handleDetailsChange = (name, value) => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    newSlides[isRender][name] = value;
    setSlides(newSlides);
  
  };

  const placeholders = [
    {
      segmentName: "E.g. School Teacher",
      personaDescription:
        "E.g. 34, Female Lives in Bangalore Tech savvy Teaches Psychology",
      problemsFaced: "E.g. I am unable to connect with the students online"
    },
    {
      segmentName: "E.g. Librarian",
      personaDescription:
        "E.g. 54, Male, Lives in Mumbai Loves books Worked in the Army",
      problemsFaced:
        "E.g. “I have trouble keeping up with changing technology”"
    },
    {
      segmentName: "E.g. Student",
      personaDescription:
        "E.g. 12, female Lives in Delhi, Active on Social Media Short Attention Span",
      problemsFaced:
        "E.g. “I don’t understand the subject being taught in class”"
    }
  ];

  const handleChange = (name, value, columnindex) => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    let newsegments = [ ...newSlides[isRender]["customerSegment"] ];

    newsegments.map((segment, segmentindex) => {

      if (segmentindex === columnindex) {

        newsegments[segmentindex][name] = value;
      
      }
    
    });
    newSlides[isRender]["customerSegment"] = newsegments;
    setSlides(newSlides);
  
  };

  const addSegment = () => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    newSlides[isRender]["customerSegment"].push({
      segmentName       : "",
      personaDescription: "",
      problemsFaced     : ""
    });
    setSlides(newSlides);
  
  };

  const removeSegment = columnindex => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;

    let newsegments = newSlides[isRender]["customerSegment"].filter(
      (segment, segmentindex) => segmentindex !== columnindex
    );

    newSlides[isRender]["customerSegment"] = newsegments;

    setSlides(newSlides);
  
  };

  // const history = useHistory();
  // const pathname = history.location.pathname.substring(1);

  return (
    <Box>
      <Box sx={ {} }>
        <SectionHeader
          isEdit={ props.isEdit }
          Collapsecomment={ props.Collapsecomment }
          collapseCommentFunction={ props.collapseCommentFunction }
          mainHead={ slide.name }
          handleTitleChange={ handleDetailsChange }
          logoimg={ logoimg }
          isPublished={ isPublished }
        />
      </Box>

      <Box sx={ { width: "100%", display: "flex", height: "59vh" } }>
        {/* left img box */ }
        <Box
          sx={ {
            display       : "flex",
            justifyContent: "center",
            alignItems    : "center",
            width         : "20%"
          } }
        >
          <Box
            sx={ {
              height        : "30vh",
              width         : "100%",
              borderRadius  : "3px",
              display       : "flex",
              justifyContent: "center",
              alignItems    : "center"
            } }
          >
            <img
              alt=""
              crossOrigin="anonymous"
              src={ `${process.env.REACT_APP_AWS_ASSETS_URL
              }/images/pitchCustomer.png?time=${new Date().valueOf()}` }
              style={ { width: "154px", height: "154px" } }
            />
          </Box>
        </Box>
        {/* right content box */ }
        <Box
          sx={ {
            width         : "75%",
            display       : "flex",
            justifyContent: "space-evenly",
            marginTop     : "3vh",
            position      : "relative",
            flexDirection : "column"
          } }
        >
          {/* ------head title box------ */ }
          <Box
            sx={ {
              width         : "100%",
              height        : "25px",
              // border: 1,
              display       : "flex",
              justifyContent: "space-evenly",
              alignItems    : "center",
              marginBottom  : "0px"
            } }
          >
            <Box
              sx={ {
                width: "30%",

                justifyContent: "center",
                alignItems    : "center",
                display       : "flex"
              } }
            >
              <Typography
                sx={ {
                  fontSize  : "16px",
                  color     : "#393939",
                  fontWeight: "bold"
                } }
              >
                Customer Segment
              </Typography>
            </Box>
            <Box
              sx={ {
                width         : "30%",
                justifyContent: "center",
                alignItems    : "center",
                display       : "flex"
              } }
            >
              <Typography
                sx={ {
                  fontSize  : "16px",
                  color     : "#393939",
                  fontWeight: "bold"
                } }
              >
                Persona Description
              </Typography>
            </Box>
            <Box
              sx={ {
                width         : "30%",
                justifyContent: "center",
                alignItems    : "center",
                display       : "flex"
              } }
            >
              <Typography
                sx={ {
                  fontSize  : "16px",
                  color     : "#393939",
                  fontWeight: "bold"
                } }
              >
                Problems Faced
              </Typography>
            </Box>
          </Box>
          {/* ----- field row box-------- */ }
          { slide.customerSegment.map((segment, segmentindex) => {

            return (
              <Box
                sx={ {
                  width         : "100%",
                  display       : "flex",
                  justifyContent: "space-evenly",
                  marginBottom  : "1.5vh"
                } }
              >
                <Box sx={ { width: "30%", position: "relative" } }>
                  { props.isEdit ?
                    <>
                      <TextField
                        value={ segment.segmentName }
                        placeholder={ placeholders[segmentindex]["segmentName"] }
                        onChange={ e => {

                          handleChange(
                            "segmentName",
                            e.target.value,
                            segmentindex
                          );
                        
                        } }
                        inputProps={ { maxLength: 25 } }
                        fullWidth
                        sx={ {
                          "& .MuiInputBase-input": {
                            fontSize  : "12px",
                            padding   : "7px 10px",
                            border    : "none",
                            color     : "#707070",
                            whiteSpace: "pre-line"
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "2px dotted #B4B4FA"
                          }
                        } }
                      />
                      { segmentindex > 1 &&
                        <Box
                          sx={ {
                            width          : "15px",
                            height         : "15px",
                            border         : "1px solid #838383",
                            borderRadius   : "50%",
                            zIndex         : 20,
                            position       : "absolute",
                            right          : "-8px",
                            top            : "-8px",
                            display        : "flex",
                            justifyContent : "center",
                            alignItems     : "center",
                            backgroundColor: "#FFFFFF"
                          } }
                          onClick={ () => {

                            removeSegment(segmentindex);
                          
                          } }
                        >
                          <CloseIcon
                            sx={ { fontSize: "15px", color: "#838383" } }
                          />
                        </Box>
                      }
                    </>
                    :
                    <Box
                      sx={ {
                        width       : "100%",
                        border      : "1px solid #BEBEBE",
                        height      : "30px",
                        borderRadius: "5px",
                        overflowY   : "auto"
                      } }
                    >
                      <Box
                        sx={ {
                          margin  : "3px 7px",
                          wordWrap: "break-word"
                        } }
                      >
                        <Typography
                          sx={ {
                            fontSize  : "1.6vh",
                            color     : "#707070",
                            lineHeight: "23px",
                            whiteSpace: "pre-line"
                          } }
                        >
                          { segment.segmentName }
                        </Typography>
                      </Box>
                    </Box>
                  }

                  {/* ---close button box--- */ }
                </Box>
                {/* ---------- */ }
                <Box sx={ { width: "30%" } }>
                  { props.isEdit ?
                    <TextField
                      value={ segment.personaDescription }
                      placeholder={
                        placeholders[segmentindex]["personaDescription"]
                      }
                      onChange={ e => {

                        handleChange(
                          "personaDescription",
                          e.target.value,
                          segmentindex
                        );
                      
                      } }
                      inputProps={ { maxLength: 110 } }
                      fullWidth
                      multiline
                      maxRows={ 5 }
                      minRows={ 5 }
                      sx={ {
                        "& .MuiOutlinedInput-root": {
                          padding: "4px 6px"
                        },
                        "& .MuiInputBase-input": {
                          fontSize  : "12px",
                          color     : "#707070",
                          lineHeight: "1.5",
                          whiteSpace: "pre-line"
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px dotted #B4B4FA"
                        }
                      } }
                    />
                    :
                    <Box
                      sx={ {
                        width       : "100%",
                        border      : "1px solid #BEBEBE",
                        height      : "15vh",
                        borderRadius: "5px",
                        overflowY   : "auto",
                        display     : "flex",
                        alignItems  : "center"
                      } }
                    >
                      <Box
                        sx={ {
                          margin  : "4px 6px",
                          wordWrap: "break-word"
                        } }
                      >
                        <Typography
                          sx={ {
                            fontSize  : "1.6vh",
                            color     : "#707070",
                            lineHeight: "1.5",
                            whiteSpace: "pre-line"
                          } }
                        >
                          { segment.personaDescription }
                        </Typography>
                      </Box>
                    </Box>
                  }
                </Box>
                {/* ---------- */ }
                <Box sx={ { width: "30%" } }>
                  { props.isEdit ?
                    <TextField
                      value={ segment.problemsFaced }
                      placeholder={ placeholders[segmentindex]["problemsFaced"] }
                      onChange={ e => {

                        handleChange(
                          "problemsFaced",
                          e.target.value,
                          segmentindex
                        );
                      
                      } }
                      inputProps={ { maxLength: 110 } }
                      fullWidth
                      multiline
                      maxRows={ 5 }
                      minRows={ 5 }
                      sx={ {
                        "& .MuiOutlinedInput-root": {
                          padding: "4px 6px"
                        },
                        "& .MuiInputBase-input": {
                          fontSize  : "12px",
                          color     : "#707070",
                          lineHeight: "1.5",
                          whiteSpace: "pre-line"
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px dotted #B4B4FA"
                        }
                      } }
                    />
                    :
                    <Box
                      sx={ {
                        width       : "100%",
                        border      : "1px solid #BEBEBE",
                        height      : "15vh",
                        borderRadius: "5px",
                        overflowY   : "auto",
                        display     : "flex",
                        alignItems  : "center"
                      } }
                    >
                      <Box
                        sx={ {
                          margin  : "4px 6px",
                          wordWrap: "break-word"
                        } }
                      >
                        <Typography
                          sx={ {
                            fontSize  : "1.6vh",
                            color     : "#707070",
                            lineHeight: "1.5",
                            whiteSpace: "pre-line"
                          } }
                        >
                          { segment.problemsFaced }
                        </Typography>
                      </Box>
                    </Box>
                  }
                </Box>
              </Box>
            );
          
          }) }

          {/* add icon box */ }
          { props.isEdit ?
            <Box
              sx={ {
                width         : "36px",
                height        : "36px",
                display       : "flex",
                justifyContent: "center",
                alignItems    : "center",
                borderRadius  : "50%",
                position      : "absolute",
                bottom        : "0px",
                right         : "-49px",
                backgroundColor:
                  slide.customerSegment.length === 3 ? "#b0b0b0" : "#071AFF",
                cursor:
                  slide.customerSegment.length === 3 ? "default" : "pointer"
              } }
              onClick={ () => {

                if (slide.customerSegment.length < 3) addSegment();
              
              } }
            >
              <AddIcon sx={ { fontSize: "32px", color: "#FFFFFF" } } />
            </Box>
            :
            <></>
          }

          {/* pitch refrence links */ }
          { props.isEdit ?
            <Box sx={ { position: "absolute", bottom: "-5px", left: "22px" } }>
              <ReferenceLinkTooltip title={ "" }>
                <Button
                  style={ {
                    padding       : "0px",
                    textTransform : "none",
                    fontSize      : "12px",
                    fontWeight    : "bold",
                    textDecoration: "underline"
                  } }
                  onClick={ segmentPersonaDialogopen }
                >
                  View of Segments & Personas
                </Button>
              </ReferenceLinkTooltip>
              <Dialog
                open={ segmentPersonaDialog }
                onClose={ segmentPersonaDialogclose }
                PaperProps={ {
                  style: {
                    borderRadius   : "20px",
                    backdropFilter : "blur(100px)",
                    backgroundColor: "#ffffff",
                    boxShadow      : "0px 3px 6px #00000029",
                    width          : "70%"
                  }
                } }
                maxWidth="false"
                fullWidth
              >
                <DialogContent sx={ { padding: "40px" } }>
                  <Box
                    sx={ {
                      width   : "100%",
                      height  : "60vh",
                      position: "relative"
                    } }
                  >
                    {/* <PitchCustomerPersonas /> */ }
                    <SegmentsPersonasDialog segments={ segmentsDialog } />
                    <IconButton
                      onClick={ () => {

                        segmentPersonaDialogclose();
                      
                      } }
                      sx={ {
                        position: "absolute",
                        right   : "-40px",
                        top     : "-40px"
                      } }
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </Box>
                </DialogContent>
              </Dialog>
            </Box>
            :
            <></>
          }
        </Box>
      </Box>
    </Box>
  );

}
