/* eslint-disable camelcase */
import {
  Box, Dialog,
  DialogContent, Grid, IconButton, Typography
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BackNextButton from "../../../../components/BackNextButton";
import IndividualSectionHeaders from "../../../../components/IndividualSectionHeaders";
import ModuleSections from "../../../../components/ModuleSections";
import Data from "../../../../Data/Gtm.json";
import {
  getGtm,
  updateGtmProductPositioningValues
} from "../../../../services/gtm";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import styles from "./Positioning.module.css";

import { makeStyles } from "@mui/styles";
import CustomMultilineTextfield from "../../../../components/CustomMultilineTextfield";
import HelpIconTooltip from "../../../../components/HelpIconTooltip";

import { useUnmount } from "../../../../components/useUnmount";
import {
  createGtmComment,
  deleteGtmComment,
  updateGtmComment
} from "../../../../services/comment";

const useStyles = makeStyles(() => ({
  customWidth: {
    maxWidth       : 500,
    fontSize       : 10,
    backgroundColor: "white",
    color          : "#707070",
    boxShadow      : "0px 3px 6px #00000029",
    border         : "1px solid #707070",
    borderRadius   : 10,
    whiteSpace     : "pre-wrap"
  },
  maindescription: {
    fontSize: "14px"
  },
  helpicon: {
    color   : "#071AFF",
    fontSize: "15px"
  },
  question: {
    fontWeight     : "bold",
    padding        : "10px 30px",
    color          : "#393939",
    backgroundColor: "#F7F7F7",
    position       : "relative"
  },
  textarea: {
    "& .MuiInputBase-input": {
      fontSize: "16px",
      padding : "10px 20px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    }
  },
  gridbox: {
    boxShadow    : "0px 3px 6px #5F5E5E2B",
    borderRadius : "10px",
    marginBottom : "30px",
    flexDirection: "column"
  },
  summary: {
    color     : "#071AFF",
    float     : "right",
    fontWeight: "bold",
    cursor    : "pointer"
  },
  summarytext: {
    fontSize  : "14px",
    fontWeight: "bold"
  },
  summaryheadingtext: {
    fontSize  : "14px",
    fontWeight: "bold",
    marginTop : "15px"
  }
}));

const ProductPositioning = () => {

  const [ details, setDetails ] = useState("");
  const [ comments, setComments ] = useState([]);
  const [ positioning, setPositioning ] = useState({});
  const [ dialogbox, setDialog ] = useState(false);
  const [ summarydialogbox, setSummaryDialog ] = useState(false);
  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  const pathname = history.location.pathname.substring(1);
  const content = Data.gtmpositioning;
  const data = Data.productpositioning[pathname];
  const [ isDataLoading, setIsDataLoading ] = useState(true);

  let { user, venture } = useSelector(state => {

    return { ...state };
  
  });

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  const summaryDialogopen = () => {

    setSummaryDialog(true);
  
  };
  const summaryDialogclose = () => {

    setSummaryDialog(false);
  
  };

  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);
    
    } else {

      setCollapsecomment(true);
    
    }
  
  };

  useEffect(() => {

    fetchGtm();
  
  }, [ pathname ]);

  const fetchGtm = async () => {

    setIsDataLoading(true);

    try {

      const response = await getGtm(venture.gtm);
      // console.log(response.data);

      setPositioning(response.data.positioning);
      setDetails(
        response.data.positioning.productPositioning[data.sectionname]
      );
      setComments(response.data.comments.positioning[data.commentsectionname]);
      setIsDataLoading(false);
    
    } catch (error) {

      console.error(error);
      setIsDataLoading(false);
    
    }
  
  };
  // To get the updated usestate value to save in useEffect cleanup
  const detailsRef = useRef(details);

  detailsRef.current = details;

  let isSubmitting = false;

  // useUnmount => custom hook to save while navigating
  useUnmount(() => {

    if (isDataLoading == false && !isSubmitting) {

      handleSave();
    
    }
  
  });

  const handleSave = async () => {

    try {

      await updateGtmProductPositioningValues(
        venture.gtm,
        data.sectionname,
        detailsRef.current
      );
      
      return true;
    
    } catch (error) {

      console.error(error);
      
      return false;
    
    }
  
  };

  const handleSubmit = async () => {

    try {

      isSubmitting = true;
      if(isDataLoading == false){
        
        await updateGtmProductPositioningValues(
          venture.gtm,
          data.sectionname,
          details
        );
        
      }
      history.push(`/${data.nexturl}`);
      isSubmitting = false;
    
    } catch (error) {

      isSubmitting = false;
      console.error(error);
    
    }
  
  };

  const addComment = async (commentText, replyTo) => {

    try {

      const response = await createGtmComment(
        commentText,
        user._id,
        "positioning",
        data.commentsectionname,
        venture.gtm,
        replyTo,
        window.location.href,
        "GTM > Positioning"
      );

      setComments(response.data.comments.positioning[data.commentsectionname]);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateComment = async (commentid, commentText) => {

    try {

      const response = await updateGtmComment(
        commentText,
        user._id,
        commentid,
        venture._id,
        window.location.href,
        "GTM > Positioning"
      );

      setComments(response.data.comments.positioning[data.commentsectionname]);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const deleteComment = async commentid => {

    try {

      const response = await deleteGtmComment(
        "positioning",
        data.commentsectionname,
        venture.gtm,
        commentid,
        user._id,
        window.location.href,
        "GTM > Positioning"
      );

      setComments(response.data.comments.positioning[data.commentsectionname]);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  return (
    <ModuleSections
      Collapsecomment={ Collapsecomment }
      comments={ comments }
      addComment={ addComment }
      deleteComment={ deleteComment }
      updateComment={ updateComment }
    >
      <div className="box-display-style-for-next-and-back-button-with-justify">
        <div className="box-indiv-header-con">
          <IndividualSectionHeaders
            // mainheading={"Positioning"}
            // subheading={"Product Positioning"}
            subheading={ "Positioning" }
            headingcontent={
              "Construct customer-facing ‘positioning’ statements for your product/solution."
            }
            isGtm={ true }
            // isGtmChevron={true}
            Dialogopen={ Dialogopen }
            Dialogclose={ Dialogclose }
            dialogbox={ dialogbox }
            collapse_comment_function={ collapse_comment_function }
            content={ content }
            isGtmChevron={ pathname.includes("gtm") ? "gtmV" : "customer" }
            isSaveButton={ true }
            handleSave={ handleSave }
          />
        </div>
        <Grid item xs={ 12 } id={ styles.maingrid }>

          <Typography className={ classes.maindescription }>
            {/* {data.maindescription} */ }
          </Typography>
          <Grid container className={ classes.gridbox }>
            <Typography className={ classes.question }>
              { data.question }
              { pathname === "gtmpositioninglongerdescription" &&
                <>
                  <Typography
                    className={ classes.summary }
                    component="span"
                    onClick={ () => {

                      summaryDialogopen();
                    
                    } }
                  >
                    Summary
                  </Typography>
                  <Dialog
                    open={ summarydialogbox }
                    PaperProps={ {
                      style: {
                        borderRadius  : "20px",
                        backdropFilter: "blur(24px)",
                        background    : "initial",
                        boxShadow     : "0px 3px 6px #00000029",
                        border        : "1px solid #707070"
                      }
                    } }
                    BackdropProps={ { invisible: true } }
                    fullWidth
                    maxWidth="md"
                  >
                    <Box sx={ { alignSelf: "flex-end" } }>
                      <IconButton component="span" onClick={ summaryDialogclose }>
                        <HighlightOffIcon />
                      </IconButton>
                    </Box>

                    <DialogContent>
                      <Typography className={ classes.summaryheadingtext }>
                        *Before State
                      </Typography>
                      <Typography style={{ whiteSpace: "pre-line" }} className={ classes.summarytext }>
                        { positioning.beforeAfterState?.beforeState }
                      </Typography>
                      <Typography className={ classes.summaryheadingtext }>
                        *Negative Consequences
                      </Typography>
                      <Typography style={{ whiteSpace: "pre-line" }} className={ classes.summarytext }>
                        { positioning.beforeAfterState?.negativeConsequences }
                      </Typography>
                      <Typography className={ classes.summaryheadingtext }>
                        *After State
                      </Typography>
                      <Typography style={{ whiteSpace: "pre-line" }} className={ classes.summarytext }>
                        { positioning.beforeAfterState?.afterState }
                      </Typography>
                      <Typography className={ classes.summaryheadingtext }>
                        *Positive Business Outcomes
                      </Typography>
                      <Typography style={{ whiteSpace: "pre-line" }} className={ classes.summarytext }>
                        { positioning.beforeAfterState?.positiveBusinessOutcomes }
                      </Typography>
                      <Typography className={ classes.summaryheadingtext }>
                        *Usecases
                      </Typography>
                      { positioning.usecases?.map(usecase => {

                        return (
                          <Typography style={{ whiteSpace: "pre-line" }} className={ classes.summarytext }>
                            { usecase }
                          </Typography>
                        );
                      
                      }) }
                      <Typography className={ classes.summaryheadingtext }>
                        *Proof Points
                      </Typography>
                      <Typography style={{ whiteSpace: "pre-line" }} className={ classes.summarytext }>
                        { positioning.differentiation?.proofPointOne }
                      </Typography>
                      <Typography style={{ whiteSpace: "pre-line" }} className={ classes.summarytext }>
                        { positioning.differentiation?.proofPointTwo }
                      </Typography>
                      <Typography style={{ whiteSpace: "pre-line" }} className={ classes.summarytext }>
                        { positioning.differentiation?.proofPointThree }
                      </Typography>
                      <Typography className={ classes.summaryheadingtext }>
                        *Differentiators
                      </Typography>
                      <Typography style={{ whiteSpace: "pre-line" }} className={ classes.summarytext }>
                        { positioning.differentiation?.DifferentiatorOne }
                      </Typography>
                      <Typography style={{ whiteSpace: "pre-line" }} className={ classes.summarytext }>
                        { positioning.differentiation?.DifferentiatorTwo }
                      </Typography>
                      <Typography style={{ whiteSpace: "pre-line" }} className={ classes.summarytext }>
                        { positioning.differentiation?.DifferentiatorThree }
                      </Typography>
                    </DialogContent>
                  </Dialog>
                </>
              }
              {/* <Tooltip
              disableFocusListener
              title={data.tooltip}
              placement="right-start"
              classes={{ tooltip: classes.customWidth }}
            >
              <IconButton>
                <HelpIcon className={classes.helpicon}></HelpIcon>
              </IconButton>
            </Tooltip> */}
              <HelpIconTooltip width="500px" tooltipdata={ data.tooltip } />
            </Typography>
            {/* <TextField
            multiline
            minRows={10}
            maxRows={10}
            className={classes.textarea}
            placeholder={data.placeholder}
            value={details}
            onChange={(e) => {
              setDetails(e.target.value);
            }}
          /> */}
            <CustomMultilineTextfield
              minRows={ 10 }
              maxRows={ 10 }
              padding={ "27px 27px" }
              border={ "none" }
              width={ "100%" }
              fontSize={ "0.7em" }
              placeholder={ data.placeholder }
              value={ details }
              handledetailsChange={ e => {

                setDetails(e.target.value);
              
              } }
            />
          </Grid>
        </Grid>
        <div className="ttribe-back-next-button-con">
          <BackNextButton
            restrictedBackClick={ () => {

              history.push(`/${data.backurl}`);
            
            } }
            backClick={ () => {

              history.push(`/${data.backurl}`);
            
            } }
            restrictedNextClick={ () => {

              history.push(`/${data.nexturl}`);
            
            } }
            nextClick={ () => {

              handleSubmit();
            
            } }
          />

        </div>
      </div>
    </ModuleSections>
  );

};

export default ProductPositioning;
