import React, { useEffect, useRef, useState } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
// import CloseIcon from "@mui/icons-material/Close";
import {
  Box, CircularProgress, Grid, IconButton, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Tooltip
} from "@mui/material";
import Divider from "@mui/material/Divider";
// import { styled } from "@mui/material/styles";

import styles from "./WelcomePage.module.css";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { currentUser, updateCollaboratorAccess, updateUserSharedVenture } from "../../services/auth";
import { listUserVentures } from "../../services/venture";
import IndividualInvitedBox from "./IndividualInvitedBox";
import IndividualVentureBox from "./IndividualVentureBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import _ from "lodash";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";

import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";

const LightTooltip = styled(({ className, ...props }) =>
  <Tooltip {...props} classes={{ popper: className }} />
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    // color: "rgba(0, 0, 0, 0.87)",
    boxShadow      : theme.shadows[1],
    fontSize       : "14px",
    color          : "#FF7F00",
    border         : "1px solid #FF7F00",
    paddingTop     : "5px 10px",
    borderRadius   : "10px",
    fontWeight     : "bold"
  }
}));


export default function WelcomePageExistingUser() {

  const [ userventures, setUserventures ] = useState([]);
  const [ sharedventures, setSharedventures ] = useState([]);
  const [ filteredSharedventures, setFilteredSharedventures ] = useState([]);
  const [ loading, setLoading ] = useState(false);

  let dispatch = useDispatch();
  const history = useHistory();

  const { user, venture } = useSelector(state => ({ ...state }));
  const userid = user._id;

  const boxesPerRow = 3;
  const rowsToAddOnViewMoreClick = 1;
  const rowsToDisplayInitially = 1;
  const totalBoxes = filteredSharedventures.length;

  const [ numRowsDisplayed, setNumRowsDisplayed ] = useState(rowsToDisplayInitially);
  const [ sortByDate, setSortByDate ] = useState(true);
  const [ sortByName, setSortByName ] = useState(true);
  const [ isDialogOpen, setIsDialogOpen ] = useState(false);
  const [ isShowVentureDialogOpen, setIsShowVentureDialogOpen ] = useState(false);
  const [ currentSharedVentureDetails, setCurrentSharedVentureDetails ] = useState({ isHide: undefined, selectedSharedVentureId: "", currentVentureName: "", sharedBy: "" });
  const containerRef = useRef(null);

  useEffect(() => {

    if (venture._id) {

      fetchUser();
      dispatch({
        type   : "CURRENT_VENTURE",
        payload: {
          _id            : "",
          userid         : "",
          details        : {},
          conceptVision  : {},
          market         : {},
          customer       : {},
          problemSolution: {},
          financials     : {},
          gtm            : {},
          operations     : {},
          pitchDeck      : {},
          monetisation   : {},
          userRole       : ""
        }
      });
    
    }
  
  }, []);


  useEffect(() => {

    fetchProjects();
  
  }, [ userid ]);

  useEffect(() => {

    handleSortByInviteAt();
    handleSoryByName();

  }, []);

  const fetchUser = () => {

    currentUser("")
      .then(res => {

        dispatch({
          type   : "LOGGED_IN_USER",
          payload: {
            firstname       : res.data.firstname,
            lastname        : res.data.lastname,
            email           : res.data.email,
            token           : user.token,
            subscription    : res.data.subscription,
            access          : res.data.access,
            profileIcon     : res.data.profileIcon,
            userVerified    : res.data.userVerified,
            organizationName: res.data.organizationName,
            location        : res.data.location,
            createdAt       : res.data.createdAt,
            _id             : res.data._id
          }
        });
      
      })
      .catch(err => console.error(err));
  
  };

  const fetchProjects = async () => {

    setLoading(true);
    const response = await listUserVentures(userid);

    setUserventures(response.data.userventures);

    if (response.data.sharedventures) {

      const sVenturesData = response.data.sharedventures;

      if (sVenturesData && sVenturesData.length) {

        const dateAddedDoc = sVenturesData.map(sharedVenture => {

          // if(sharedVenture?.isAccepted) {

          if (sharedVenture.inviteSentAt === undefined) {

            sharedVenture.inviteSentAt = sharedVenture?.venture?.createdAt;

          }
          if (sharedVenture.isHide === undefined || sharedVenture.isHide === false) {

            sharedVenture.isHide = false;

          } else {

            sharedVenture.isHide = true;

          }

          return sharedVenture;

          // }

        });

        setSharedventures(dateAddedDoc);
        setFilteredSharedventures(dateAddedDoc.filter(dd => { if (!dd?.isHide) return dd; }));

      } else {

        setSharedventures([]);

      }

      setLoading(false);

    } else {

      setSharedventures([]);

    }
  
  };

  const handleChange = ventureid => {

    userventures.map(venture => {

      if (venture._id === ventureid) {

        dispatch({
          type   : "CURRENT_VENTURE",
          payload: {
            _id            : venture._id,
            userid         : venture.parentUser,
            details        : venture.details,
            conceptVision  : venture.conceptVision,
            market         : venture.market,
            customer       : venture.customer,
            problemSolution: venture.problemSolution,
            financials     : venture.financials,
            gtm            : venture.gtm,
            operations     : venture.operations,
            pitchDeck      : venture.pitchDeck,
            monetisation   : venture.monetisation,
            userRole       : "Owner"
          }
        });
        history.push("/dashboard");
      
      }
    
    });
  
  };

  const handleSharedVenturesChange = async (e, ventureid) => {

    e.stopPropagation();

    sharedventures.map(async sharedventure => {

      if (sharedventure.venture._id === ventureid) {

        if (sharedventure.isNewventure) {

          await updateCollaboratorAccess(
            "isNewventure",
            false,
            ventureid,
            user._id
          );
        
        }
        dispatch({
          type   : "CURRENT_VENTURE",
          payload: {
            _id            : sharedventure.venture._id,
            userid         : sharedventure.venture.parentUser,
            details        : sharedventure.venture.details,
            conceptVision  : sharedventure.venture.conceptVision,
            market         : sharedventure.venture.market,
            customer       : sharedventure.venture.customer,
            problemSolution: sharedventure.venture.problemSolution,
            financials     : sharedventure.venture.financials,
            gtm            : sharedventure.venture.gtm,
            operations     : sharedventure.venture.operations,
            pitchDeck      : sharedventure.venture.pitchDeck,
            monetisation   : sharedventure.venture.monetisation,
            userRole       : sharedventure.collaboratorRole
          }
        });

        dispatch({
          type   : "LOGGED_IN_USER",
          payload: {
            firstname       : user.firstname,
            lastname        : user.lastname,
            email           : user.email,
            token           : user.token,
            subscription    : user.subscription,
            access          : sharedventure.venture.parentUser.access,
            profileIcon     : user.profileIcon,
            userVerified    : user.userVerified,
            organizationName: user.organizationName,
            location        : user.location,
            createdAt       : user.createdAt,
            _id             : user._id
          }
        });
        history.push("/dashboard");
      
      }
    
    });
  
  };

  let findAccess = access => {

    switch (access) {

    case "freetier":
      return "Starter (free) plan";
    case "ideation":
      return "Custom/ Enterprise plan";
    case "admin":
      return "Custom/ Enterprise plan";
    case "pitch":
      return "Pitch (annual) plan";
    case "pro":
      return "Pro (annual) plan";
    default:
      return "";
    
    }
  
  };

  let subscriptionText = findAccess(user.access);

  const handleSoryByName = () => {

    const sortedDoc = sortByName ? _.orderBy(filteredSharedventures, v => v.venture.details.name.toLowerCase(), "asc") : _.orderBy(filteredSharedventures, v => v.venture.details.name.toLowerCase(), "desc");

    setSortByName(!sortByName);
    // setSharedventures(sortedDoc);
    setFilteredSharedventures(sortedDoc);

  };

  const handleSortByInviteAt = () => {

    const sortedDoc = _.sortBy(filteredSharedventures, sharedVenture => {

      return new Date(sharedVenture?.inviteSentAt).getTime();
    
    });

    if (!sortByDate) { sortedDoc.reverse(); }

    setSortByDate(!sortByDate);
    // setSharedventures(sortedDoc);
    setFilteredSharedventures(sortedDoc);

  };

  const handleHideModelClose = () => {

    setIsDialogOpen(false);

  };

  const handleShowVentureModelClose = () => {

    setIsShowVentureDialogOpen(false);

  };

  const handleHideVenture = (isHide, id) => {

    if (isHide || id) {

      updateUserSharedVenture(userid, id, isHide).then(res => {

        if (res.data) {

          const updatedSharedVenture = filteredSharedventures.map(sharedVenture => {

            res.data.map(resVen => {

              if (resVen._id === sharedVenture._id) {

                sharedVenture.isHide = resVen.isHide;

              }

            });

            return sharedVenture;

          });

          setFilteredSharedventures(updatedSharedVenture);
          setSharedventures(updatedSharedVenture);
          setIsDialogOpen(false);
          setIsShowVentureDialogOpen(false);

        }

        fetchProjects();

      }).catch(error => {

        setIsDialogOpen(false);
        setIsShowVentureDialogOpen(false);
        console.error(error);

      });

    }

  };

  return (
    <Box sx={{ height: "100%" }}>
      <Box
        sx={{
          height         : "81vh",
          margin         : "5vh 10vh",
          boxShadow      : "0px 3px 16px #00000029",
          borderRadius   : "10px",
          display        : "flex",
          backgroundColor: "#ffffff",
          flexDirection  : "column",
          overflowY      : "auto"
        }}
      >
        {loading ?
          <Grid
            container
            justifyContent="center"
            height="100%"
            alignItems="center"
          >
            <Grid item>
              <CircularProgress size="60px" thickness={3} />
            </Grid>
          </Grid>
          :
          <>
            <Box id={styles.existingTypoBox}>
              <Typography id={styles.headTypo}>
                Welcome, {user.firstname} {user.lastname}.
              </Typography>

              <Typography
                sx={{
                  fontSize: "14px",
                  color   : "#6D6D6E"
                }}
              >
                You are on <span>{subscriptionText === "Custom/ Enterprise plan" ? " a " : " the "}</span>
                <LightTooltip placement="bottom" title="Coming soon">
                  <a
                    style={{
                      color     : "#071AFF",
                      fontWeight: "bold",
                      cursor    : "pointer"
                    }}
                    // target="_blank"
                    // href={"/mysubscriptions"} rel="noreferrer"
                  >
                    {
                      subscriptionText
                    }
                  </a>
                </LightTooltip>
              </Typography>
            </Box>
            <Divider></Divider>
            {/* my projects box */}
            <Box id={styles.myProjectBox}>
              <Box id={styles.existingProjectSubBox} fullwidth>
                <Typography id={styles.myProjectTypo}>My Projects</Typography>

                <Box id={styles.myProjectContentBox}>
                  {/* arrow box */}
                  <Box id={styles.arrowBox}>
                    <ArrowLeftIcon id={styles.arrowIcon} />
                  </Box>

                  {/* mid content box*/}
                  <Box style={{ width: "100%" }}>
                    {userventures.length > 0 ?
                      <></>
                      :
                      <Typography id={styles.headSubTypo}>
                        We are thrilled to have you here. Let's get started with
                        your first project
                      </Typography>
                    }
                    <Box id={styles.midContentBox}>

                      {/* project box1 */}
                      {userventures.length > 0 || userventures.length <= 3 ?
                        <>
                          {userventures[0] &&
                            <IndividualVentureBox
                              venture={userventures[0]}
                              handleChange={handleChange}
                              fetchProjects={fetchProjects}
                            />}
                          {
                            userventures[1] &&
                            <IndividualVentureBox
                              venture={userventures[1]}
                              handleChange={handleChange}
                              fetchProjects={fetchProjects}
                            />
                          }

                          {userventures[2] &&
                            <IndividualVentureBox
                              venture={userventures[2]}
                              handleChange={handleChange}
                              fetchProjects={fetchProjects}
                            />}
                        </>
                        :
                        <></>
                      }
                      {/* venture project box */}
                      {userventures.length >= 3 ?
                        <></>
                        :
                        <Box id={styles.newVentureBox}>
                          <Box id={styles.newVentureSubBox}>
                            <IconButton
                              disabled={
                                ((user.access === "freetier" ||
                                  user.access === "ideation" ||
                                  user.access === "pitch") &&
                                  userventures.length >= 1) ||
                                  userventures.length >= 3
                                  ? true
                                  : false
                              }
                              onClick={async () => {

                                const response = await listUserVentures(userid);

                                if (
                                  ((user.access === "freetier" ||
                                    user.access === "ideation" ||
                                    user.access === "pitch") &&
                                    response.data.userventures.length >= 1) ||
                                  response.data.userventures.length >= 3
                                ) {

                                  fetchProjects();
                                
                                } else {

                                  history.push("/createventure");
                                
                                }
                              
                              }}
                            >
                              <AddCircleIcon
                                sx={{
                                  color:
                                    ((user.access === "freetier" ||
                                      user.access === "ideation" ||
                                      user.access === "pitch") &&
                                      userventures.length >= 1) ||
                                      userventures.length >= 3
                                      ? "#00000029"
                                      : "#071AFF",

                                  fontSize: "45px"
                                }}
                              />
                            </IconButton>
                          </Box>
                          <Typography
                            sx={{
                              fontSize  : "14px",
                              fontWeight: "bold",
                              color:
                                ((user.access === "freetier" ||
                                  user.access === "ideation" ||
                                  user.access === "pitch") &&
                                  userventures.length >= 1) ||
                                  userventures.length >= 3
                                  ? "#00000029"
                                  : "#071AFF",
                              textAlign: "center",
                              margin   : "auto"
                            }}
                          >
                            New Venture Project
                          </Typography>
                        </Box>
                      }

                      {/* display msg right side */}

                      {(user.access === "freetier" ||
                        user.access === "ideation" ||
                        user.access === "pitch") &&
                        userventures.length >= 1 ?
                        // {userventures.length === 1 ? (
                        <Box id={styles.sampleProjectBox}>
                          <Typography
                            sx={{
                              fontSize  : "12px",
                              color     : "#5f5e5e",
                              fontWeight: "bold"
                            }}
                          >
                            Your current plan has a 1 project limit. <br />
                            <LightTooltip placement="top" title="Coming soon">
                              <a
                                style={{
                                  color        : "#071AFF",
                                  fontWeight   : "bold",
                                  cursor       : "pointer",
                                  textTransform: "none"
                                }}
                                // href="/mysubscriptions"
                                // target={"_blank"}
                              >
                              Upgrade
                              </a>
                            </LightTooltip>
                            { " to Pro to create multiple projects, and unlock more features."}
                            {/* Upgrade to Pro plan to add more projects */}
                          </Typography>
                        </Box>
                        :
                        <></>
                      }
                    </Box>
                  </Box>
                  {/* right arrow box */}

                  <Box id={styles.arrowBox}>
                    <Box>
                      <ArrowRightIcon id={styles.arrowIcon} />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* divider */}

            <Divider></Divider>

            {/* project invited to box */}

            {sharedventures.length === 0 ?
              <Box id={styles.projectInvitedBox}>
                <Box id={styles.projectInvitedSubBox} fullwidth>
                  <Box id={styles.projectInvitedTypoBox}>
                    <Box id={styles.projectInvitedTypoBox}>
                      <Typography id={styles.projectInvitedTypo}>
                        Projects I am invited to
                      </Typography>
                    </Box>
                  </Box>

                  <Box id={styles.noInvitedContentBox}>
                    {/* arrow box */}

                    <Box id={styles.arrowBox}>
                      <ArrowLeftIcon id={styles.arrowIcon} />
                    </Box>

                    {/* mid content box*/}
                    <Box id={styles.midContentBoxInvited}>
                      <Box id={styles.whiteBoxInvited}>
                        <Box sx={{ width: "50%" }}>
                          <img
                            style={{
                              width    : "192px",
                              height   : "100px",
                              marginTop: "9%"
                            }}
                            // src="./images/projectsInvited.svg"
                            src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/projectsInvited.svg`}
                            alt=""
                          />
                        </Box>
                        <Box id={styles.invtedContentTypo}>
                          <Typography id={styles.whiteBoxTypoInvite}>
                            Nothing to show here yet. Projects you get invited
                            to as an editor or commenter will show here
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    {/* right arrow box */}
                    <Box id={styles.arrowBox}>
                      <ArrowRightIcon id={styles.arrowIcon} />
                    </Box>
                  </Box>
                </Box>
              </Box>
              :
              <Box id={styles.projectInvitedBox}>
                <Box id={styles.projectInvitedSubBox} fullwidth>
                  <Box id={styles.projectInvitedTypoBox}>
                    <Box id={styles.projectInvitedTypoBox}>
                      <Typography id={styles.projectInvitedTypo}>
                        Projects I am invited to
                      </Typography>
                      <div className="tribe-invite-main-con">
                        <div className="tribe-invite-opts-con">
                          <p
                            onClick={handleSoryByName}
                          >
                            Sort by Name (a-z) <FontAwesomeIcon icon={sortByName ? faArrowDown : faArrowUp} />
                          </p>
                          <p
                            onClick={handleSortByInviteAt}
                          >
                            Sort by Invite Date <FontAwesomeIcon icon={sortByDate ? faArrowDown : faArrowUp} />
                          </p>
                          <p
                            onClick={() => setIsShowVentureDialogOpen(true)}>
                            Unhide
                          </p>
                        </div>
                      </div>
                    </Box>
                  </Box>

                  <div className="tribe-indiv-box-container" ref={containerRef}>

                    {
                      filteredSharedventures.length > 0 &&
                      [ ...Array(numRowsDisplayed).keys() ].map((row, rowIndex) => {

                        return <div className="tribe-indiv-row-con"> {[ ...Array(boxesPerRow).keys() ].map((box, boxIndex) => {

                          const index = rowIndex * boxesPerRow + boxIndex;

                          return (
                            index < totalBoxes && <div className="tribe-indiv-box-con" key={index}><IndividualInvitedBox
                              isFromHideDialog={false}
                              sharedventure={filteredSharedventures[index]}
                              handleSharedVenturesChange={handleSharedVenturesChange}
                              setIsDialogOpen={setIsDialogOpen}
                              setCurrentSharedVentureDetails={setCurrentSharedVentureDetails}
                            /></div>

                          );
                        
                        })} </div>;
                      
                      })

                    }

                    {Math.ceil(totalBoxes / boxesPerRow) !== 1 ? <>{numRowsDisplayed < Math.ceil(totalBoxes / boxesPerRow) ?
                      <Box
                        id={styles.viewMoreBox}
                        onClick={() => {

                          setNumRowsDisplayed(numRowsDisplayed + rowsToAddOnViewMoreClick);
                          const container = containerRef.current;

                          container.scrollTop += container.clientHeight;
                          // setViewMore(true);
                        
                        }}
                      >
                        <Typography id={styles.viewMoreTyo}>
                          View more
                          <KeyboardArrowDownIcon />
                        </Typography>
                      </Box>
                      : <Box
                        id={styles.viewLessBox}
                        onClick={() => {

                          setNumRowsDisplayed(rowsToDisplayInitially);
                          const container = containerRef.current;

                          container.scrollTop = 0;
                          // setViewMore(false);
                      
                        }}
                      >
                        <Typography id={styles.viewLessTypo}>
                        View less
                          <KeyboardArrowUpIcon />
                        </Typography>
                      </Box>}</> : <></>
                    }
                  </div>
                </Box>
              </Box>
            }
            {/* ---------- */}
          </>
        }
      </Box>

      {/* Dialog box show shared venture*/}

      <Dialog
        open={isShowVentureDialogOpen}
        onClose={handleShowVentureModelClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
        fullWidth
        PaperProps={{
          style: {
            borderRadius   : "20px",
            backdropFilter : "blur(100px)",
            backgroundColor: "white",
            boxShadow      : "0px 3px 6px #00000029",
            border         : "1px solid #707070"
          }
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Hidden Shared Venture
          {/* <IconButton
            aria-label="close"
            onClick={handleShowVentureModelClose}
            sx={{
              position: "absolute",
              right   : 8,
              top     : 8,
              color   : theme => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton> */}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {sharedventures && sharedventures?.length && sharedventures?.filter(sharedVenture1 => sharedVenture1?.isHide === true).length === 0 ? <>
              <div className="tribe-no-hidden-venture-con">
              There are no hidden ventures
              </div>
            </> : <>{sharedventures && sharedventures.length && sharedventures.filter(sharedVenture1 => sharedVenture1?.isHide === true).map(sharedVenture =>
              <Grid item key={sharedVenture.id} xs={4}>
                <IndividualInvitedBox
                  isFromHideDialog={true}
                  sharedventure={sharedVenture}
                  handleSharedVenturesChange={handleSharedVenturesChange}
                  setIsDialogOpen={setIsDialogOpen}
                  setCurrentSharedVentureDetails={setCurrentSharedVentureDetails}
                  handleHideVenture={handleHideVenture}
                />
              </Grid>
            )}</>}

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleShowVentureModelClose}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog box for hide promt */}
      <Dialog
        open={isDialogOpen}
        onClose={handleHideModelClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius   : "20px",
            backdropFilter : "blur(100px)",
            backgroundColor: "white",
            boxShadow      : "0px 3px 6px #00000029",
            border         : "1px solid #707070"
          }
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Venture Name : <b> {currentSharedVentureDetails.currentVentureName} </b>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to {currentSharedVentureDetails?.isHide ? "hide " : "show "}
            {/* the <b>{currentSharedVentureDetails.currentVentureName}</b> */}
            this
            venture shared by <b>{currentSharedVentureDetails.sharedBy} </b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleHideModelClose}>Cancel</Button>
          <Button onClick={() => handleHideVenture(currentSharedVentureDetails?.isHide, currentSharedVentureDetails?.selectedSharedVentureId)} autoFocus>{currentSharedVentureDetails?.isHide ? "hide" : "show"}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );

}
