import React, { useState } from "react";
// import { Document, Page } from "react-pdf";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import { Box, Button, Typography } from "@mui/material";
// import todac2 from "./todac2.pdf";

export default function SinglePagePdfViewer() {

  const [ numPages, setNumPages ] = useState(null);
  const [ pageNumber, setPageNumber ] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {

    setNumPages(numPages);
    setPageNumber(1);
  
  };

  // const { pdf } = props;

  const changePage = offset => {

    setPageNumber(prevPageNumber => prevPageNumber + offset);
  
  };

  const previousPage = () => {

    changePage(-1);
  
  };

  const nextPage = () => {

    changePage(1);
  
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          //   width: "100%",
          //   border: 1,
          display       : "flex",
          justifyContent: "space-between",
          margin        : "30px 30px 0px 30px"
        }}
      >
        <Box
          // sx={{
          //   width: "25%",
          //   border: 1,
          //   display: "flex",
          //   flexDirection: "column",
          //   padding: "7px",
          //   alignItems: "center",
          //   overflowY: "auto",
          //   height: "68vh",
          //   overflowX: "auto",
          // }}
          sx={{
            height                : "67.5vh",
            border                : "3px solid #071AFF",
            // width: "100%",
            borderRadius          : "5px",
            display               : "flex",
            justifyContent        : "space-evenly",
            flexWrap              : "wrap",
            gap                   : 1,
            paddingTop            : "10px",
            paddingBottom         : "10px",
            overflowY             : "auto",
            "&::-webkit-scrollbar": { display: "none" },
            backgroundColor       : "#FFFFFF",
            boxShadow             : "0px 3px 6px #00000029",
            width                 : "25%"
          }}
        >
          {/* {newArray.map(() => {
            return (
              <Box
                sx={{
                  border: 1,
                  height: "150px",
                  width: "70%",
                  marginTop: "5px",
                }}
              ></Box>
            );
          })} */}
          {Array.from(Array(numPages).keys()).map((element, index) => {

            return (
              <Box
                sx={{
                  border        : 1,
                  height        : "135px",
                  // height: "135px",
                  width         : "70%",
                  marginTop     : "5px",
                  display       : "flex",
                  justifyContent: "center",
                  alignItems    : "center",
                  cursor        : "pointer"
                }}
                onClick={() => {

                  setPageNumber(index + 1);
                
                }}
              >
                <Document
                  // file={"./pdfDoc/todacSample.pdf"}
                  file={"./pdfDoc/todac2.pdf"}
                  // file={"./todac2.pdf"}
                  // file={props.pdf}

                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page
                    width={190}
                    // height={100}
                    // scale={0.2}
                    //   renderMod="svg"
                    pageNumber={index + 1}
                  />
                </Document>
              </Box>
            );
          
          })}
        </Box>
        <Box
          sx={{
            width         : "70%",
            border        : "3px solid #071AFF",
            display       : "flex",
            justifyContent: "center",
            borderRadius  : "5px",
            alignItems    : "center",
            height        : "70vh"
          }}
        >
          <Document
            // file={"./pdfDoc/todacSample.pdf"}
            file={"./pdfDoc/todac2.pdf"}
            // file={"./todac2.pdf"}
            // file={props.pdf}

            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page
              height={500}
              scale={0.8}
              //   renderMod="svg"
              pageNumber={pageNumber}
            />
          </Document>
        </Box>
      </Box>

      <Box
        sx={{
          width         : "100%",
          display       : "flex",
          justifyContent: "center",
          alignItems    : "center"
        }}
      >
        <Typography>{`${pageNumber}/${numPages}`}</Typography>

        <Button disabled={pageNumber <= 1} onClick={previousPage}>
          Prev
        </Button>
        <Button disabled={pageNumber >= numPages} onClick={nextPage}>
          Next
        </Button>
      </Box>
    </Box>
  );

}
