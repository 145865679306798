import React from "react";

import {
  Box, TextField, Typography
} from "@mui/material";
import { useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import HelpIconTooltip from "../../../../../components/HelpIconTooltip";
import styles from "./PriceDiscrimination.module.css";

export default function PriceDiscrimination(props) {

  const { venture } = useSelector(state => ({ ...state }));

  const revenueStreams = props.revenueStreams;
  const revenueStreamIndex = props.revenueStreamIndex;

  const handleChangePriceDiscrimination = (revenueStreamIndex, value) => {

    let newRevenueStreams = [ ...revenueStreams ];

    newRevenueStreams[revenueStreamIndex]["priceDiscrimination"] = value;

    props.setRevenueStreams(newRevenueStreams);
  
  };

  // const history = useHistory();

  
  return (
    // <CreatePricingComponent barHighlight="bar5">
    <Box>
      <Box>
        <Typography
          id={styles.priceDiscriminationTypo}
        //   sx={{ color: "#5F5E5E", fontSize: "16px" }}
        >
          Identify Price Discrimination Opportunities
        </Typography>
      </Box>
      {/* typo img box */}
      <Box
        id={styles.typoImgMainBox}
      // sx={{ width: "100%", display: "flex" }}
      >
        <Box
          id={styles.descTypoBox}
        //   sx={{ width: "65%" }}
        >
          <Typography
            id={styles.descTypo}
          // sx={{ fontSize: "13px", color: "#868686" }}
          >
            Price discrimination means you sell the same product or service at
            different prices to different users or under different
            circumstances. This can be a useful strategy for a product for which
            different customer groups have significantly different price
            sensitivities (i.e. value-perception or ability to pay). Make a list
            of potential price discrimination opportunities you see for your
            venture. Click ? for more guidance.
            <span>
              <HelpIconTooltip
                tooltipdata={
                  "Some pointers: \n​Price discrimination can be based on a variety of factors, and a good strategy to consider when different customer segments may have different price elasticity (willingness or ability to pay), and you have a relatively unique product or reasonable control over its supply.\n​1. Volume (e.g. a discount for buying more)\n2. Time (e.g. extra fee for faster delivery, higher price for last-minute bookings, early-bird discount)\n3. Business/ commercial vs. individual/ private use\n4. Age or other demographics (e.g. senior citizen discount)\n5. Geography/ location\n6. Affiliation (e.g. corporate discount, loyalty program)​\nThis strategy comes with a bit of risk though. Price discrimination is least risky when used with products or services that can not be easily resold or transferred to other consumers, i.e. if the different segments can be easily kept separate by time, physical distance, or nature of use, then the risk of arbitrage will be low. For e.g. airline charging a higher fee for booking a ticket 2 days before a flight, or pricing a t-shirt differently at a shop in New York vs. one in Mexico. ​"
                }
                width={"450px"}
                placement="right"
                padding={"0px"}
              />
            </span>
          </Typography>
        </Box>
        {/* image box */}
        <Box
          id={styles.mainImgBox}
        // sx={{
        //   width: "35%",
        //   //   border: 1,
        //   height: "14vh",
        //   position: "relative",
        // }}
        >
          {/* <Box sx={{ width: "119px", border: 1 }}></Box> */}
          <Box
            id={styles.imgBox}
          // sx={{ position: "absolute", top: "-25px", left: "110px" }}
          >
            <img
              id={styles.img}
              src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/monetisationPriceDiscrimination.svg`}
              // style={{
              //   width: "129px",
              //   height: "143px",
              // }}
              alt=""
            />
          </Box>
        </Box>
      </Box>

      {/* text field box */}
      <Box
        id={styles.textFieldBox}
      // sx={{ width: "100%", marginTop: "5vh" }}
      >
        <TextField
          onChange={e => {

            handleChangePriceDiscrimination(revenueStreamIndex, e.target.value);
          
          }}
          disabled={venture.userRole === "Commenter" ? true : false}
          value={revenueStreams[revenueStreamIndex]["priceDiscrimination"]}
          fullWidth
          multiline
          maxRows={9}
          minRows={9}
          sx={{
            "& .MuiOutlinedInput-root": {
              padding: "14.5px 16px"
            },
            "& .MuiInputBase-input": {
              fontSize  : "12px",
              color     : "#707070",
              lineHeight: "17px"
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #E1E2E9"
            }
          }}
        />
      </Box>
    </Box>
    // </CreatePricingComponent>
  );

}
