/* eslint-disable camelcase */
import { Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BackNextButton from "../../../../components/BackNextButton";
import IndividualSectionHeaders from "../../../../components/IndividualSectionHeaders";
import ModuleSections from "../../../../components/ModuleSections";
import Datas from "../../../../Data/Problem.json";
import StickyNotesBoard from "./StickyNotesBoard";

import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";

import {
  createProblemSolutionComment,
  deleteProblemSolutionComment,
  updateProblemSolutionComment
} from "../../../../services/comment";
import { getProblemSolution } from "../../../../services/problemsolution";
import styles from "../Problem.module.css";

const StickyNotes = () => {

  const [ comments, setComments ] = useState([]);
  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const [ dialogbox, setDialog ] = useState(false);
  const [ isexpand, setIsexpand ] = useState(false);
  const history = useHistory();
  // const pathname = history.location.pathname.substring(1);

  let { user, venture } = useSelector(state => {

    return { ...state };
  
  });

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);
    
    } else {

      setCollapsecomment(true);
    
    }
  
  };

  useEffect(() => {

    fetchProblemSolution();
  
  }, []);

  const fetchProblemSolution = async () => {

    try {

      const response = await getProblemSolution(venture.problemSolution);

      setComments(response.data.comments.problem.analysisSynthesis);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const addComment = async (commentText, replyTo) => {

    try {

      const response = await createProblemSolutionComment(
        commentText,
        user._id,
        "problem",
        "analysisSynthesis",
        venture.problemSolution,
        replyTo,
        window.location.href,
        "Problem > Analysis & Synthesis"
      );

      setComments(response.data.comments.problem.analysisSynthesis);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateComment = async (commentid, commentText) => {

    try {

      const response = await updateProblemSolutionComment(
        commentText,
        user._id,
        commentid,
        venture._id,
        window.location.href,
        "Problem > Analysis & Synthesis"
      );

      setComments(response.data.comments.problem.analysisSynthesis);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const deleteComment = async commentid => {

    try {

      const response = await deleteProblemSolutionComment(
        "problem",
        "analysisSynthesis",
        venture.problemSolution,
        commentid,
        user._id,
        window.location.href,
        "Problem > Analysis & Synthesis"
      );

      setComments(response.data.comments.problem.analysisSynthesis);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  return (
    <ModuleSections
      isexpand={ isexpand }
      Collapsecomment={ Collapsecomment }
      comments={ comments }
      addComment={ addComment }
      deleteComment={ deleteComment }
      updateComment={ updateComment }
    >
      { isexpand ?
        <Grid item xs={ 12 } sx={ { overflowY: "auto", height: "100%" } }>
          <IconButton
            onClick={ () => {

              setIsexpand(false);
            
            } }
            id={ styles.closeexpandicon }
          >
            <CloseFullscreenIcon />
          </IconButton>
          <StickyNotesBoard isexpand={ isexpand } />
        </Grid>
        : <div className="box-display-style-for-next-and-back-button-with-justify">

          <div className="box-indiv-header-con">
            <IndividualSectionHeaders
              mainheading={ "Problem" }
              subheading={ "Analysis & Synthesis" }
              headingcontent={
                "Synthesize, analyze, & derive insights from your user interviews."
              }
              isGtm={ false }
              Dialogopen={ Dialogopen }
              Dialogclose={ Dialogclose }
              dialogbox={ dialogbox }
              collapse_comment_function={ collapse_comment_function }
              content={ Datas.guidecontent }
            />

          </div>
          <Grid item xs={ 12 } id={ styles.maingrid }>

            <StickyNotesBoard isexpand={ isexpand } />
            <IconButton
              id={ styles.openexpandicon }
              onClick={ () => {

                setIsexpand(true);
              
              } }
            >
              <OpenInFullIcon />
            </IconButton>

          </Grid>

          <div className="ttribe-back-next-button-con">

            <BackNextButton
              restrictedBackClick={ () => history.push("/analysissynthesis") }
              backClick={ () => history.push("/analysissynthesis") }
              restrictedNextClick={ () => {

                history.push("/problemsummary");
              
              } }
              nextClick={ () => {

                history.push("/problemsummary");
              
              } }
            />

          </div>
        </div>
      }
    </ModuleSections>
  );

};

export default StickyNotes;
