import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import styles from "./ThankYou.module.css";

export default function ThankYou ({
  logoimg,
  slides,
  isRender,
  setSlides,
  isPublished,
  ...props
}) {

  const slide = slides[isRender];

  // const coverSlide = slides.find((slide) => slide.renderName === "Cover");
  // const history = useHistory();
  // const pathname = history.location.pathname.substring(1);

  const handleDetailsChange = (name, value) => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    newSlides[isRender][name] = value;
    setSlides(newSlides);
  
  };

  return (
    <Box style={{ overFlow: "auto" }}>
      <Box
        id={ styles.headBox }
        // sx={{

        //   width: "100%",
        //   height: "10vh",
        //   marginTop: "10px",
        //   display: "flex",

      //   justifyContent: "center",
      //   alignItems: "center",
      //   position: "relative",
      // }}
      >
        <Typography
          id={ styles.headTypo }
        // sx={{ fontSize: "38px", color: "#393939", fontWeight: "bold" }}
        >
          Thank you!
        </Typography>

        <Box
          // id={styles.headIcon}
          sx={ {
            width         : "62px",
            height        : "62px",
            border        : logoimg ? "" : "2px solid #b4b4fa",
            borderRadius  : "7px",
            position      : "absolute",
            right         : "0px",
            display       : "flex",
            justifyContent: "center",
            alignItems    : "center"
          } }>
          { logoimg &&
            <img
              src={ logoimg }
              style={ { width: "100%", height: "auto", borderRadius: "7px" } }
            />
          }
        </Box>
      </Box>
      {/* ----- */ }
      <Box id={ styles.imgBox }>
        <Box sx={ {} }>
          <img
            id={ styles.img }
            alt=""
            crossOrigin="anonymous"
            src={ `${process.env.REACT_APP_AWS_ASSETS_URL
            }/images/pitchThankYou.png?time=${new Date().valueOf()}` }
          // style={{ width: "170px", height: "170px" }}
          />
        </Box>
      </Box>
      {/* --- */ }
      <Box id={ styles.midHeadBox }>
        <Typography
          id={ styles.midHeadTypo }
        // sx={{ fontSize: "28px", color: "#393939", fontWeight: "bold" }}
        >
          We’d love to hear from you
        </Typography>
      </Box>
      {/* ---- */ }
      <Box id={ styles.contactDetailsBox }>
        {/* <Box sx={ { width: "19%" } }></Box> */}
        {/* <Box
          id={ styles.leftDetails }
        > */}
        {/* <Typography
            id={ styles.leftDetailTypo }
          >
            Name:
          </Typography> */}
        {/* <Typography
            id={ styles.leftDetailTypo }
          // sx={{ fontSize: "14px", color: "#083A9A", fontWeight: "bold" }}
          >
            Email:
          </Typography> */}
        {/* <Typography
            id={ styles.leftDetailTypo }
          // sx={{ fontSize: "14px", color: "#083A9A", fontWeight: "bold" }}
          >
            Website:
          </Typography> */}
        {/* <Typography
            id={ styles.leftDetailTypo }
          // sx={{ fontSize: "14px", color: "#083A9A", fontWeight: "bold" }}
          >
            Contact:
          </Typography> */}
        {/* <Typography
            id={ styles.leftDetailTypo }
          // sx={{ fontSize: "14px", color: "#083A9A", fontWeight: "bold" }}
          >
            Country:
          </Typography> */}
        {/* </Box> */}
        {/* ----- */ }
        <Box
          id={ styles.rightDetailBox }
        >
          <div className="pitch-thank-you-content-con">
            <div className="pitch-thank-you-content-con-row1">
              <Typography
                id={ styles.leftDetailTypo }
              >Name:</Typography>
            </div>
            <div className="pitch-thank-you-content-con-row2" >{ props.isEdit ?
              <TextField
                value={ slide.username }
                onChange={ e => {

                  handleDetailsChange("username", e.target.value);
              
                } }
                placeholder={ "Name of the Contact person" }
                // onChange={(e) => {
                //   handleChange("segmentName", e.target.value, segmentindex);
                // }}
                fullWidth
                inputProps={ { maxLength: 25 } }
                sx={ {
                // "& .MuiOutlinedInput-root": {
                //   padding: "0px 0px",
                // },
                  "& .MuiInputBase-input": {
                    fontSize  : "14px",
                    padding   : "0.5px 20px",
                    border    : "none",
                    fontWeight: "bold",
                    color     : "#707070"
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "2px dotted #B4B4FA"
                  }
                } }
              />
              :
              <Box sx={ { height: "21px" } }>
                <Typography
                  id={ styles.rightDetailTypo }
                  // sx={{ fontSize: "14px", color: "#707070", marginLeft: "20px" }}
                >
                  { slide.username
                    ? slide.username
                    : isPublished
                      ? ""
                      : "Name of the Contact person" }
                </Typography>
              </Box>
            }</div>
          </div>
          <div className="pitch-thank-you-content-con">
            <div className="pitch-thank-you-content-con-row1">
              <Typography
                id={ styles.leftDetailTypo }
              > Email:</Typography>
            </div>
            <div className="pitch-thank-you-content-con-row2">
              {/* -------- */ }
              { props.isEdit ?
                <TextField
                  value={ slide.email }
                  placeholder="info@companyname.com"
                  // disabled
                  onChange={ e => {

                    handleDetailsChange("email", e.target.value);
              
                  } }
                  fullWidth
                  inputProps={ { maxLength: 25 } }
                  sx={ {
                    // "& .MuiOutlinedInput-root": {
                    //   padding: "0px 0px",
                    // },
                    "& .MuiInputBase-input": {
                      fontSize  : "14px",
                      padding   : "0.5px 20px",
                      border    : "none",
                      fontWeight: "bold",
                      color     : "#707070"
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "2px dotted #B4B4FA"
                    }
                  } }
                />
                :
                <Box sx={ { height: "21px" } }>
                  <Typography
                    id={ styles.rightDetailTypo }
                    // sx={{ fontSize: "14px", color: "#707070", marginLeft: "20px" }}
                  >
                    { slide.email
                      ? slide.email
                      : isPublished
                        ? ""
                        : "info@companyname.com" }
                  </Typography>
                </Box>
              }
            </div>
          </div>
          <div className="pitch-thank-you-content-con">
            <div className="pitch-thank-you-content-con-row1">
              <Typography
                id={ styles.leftDetailTypo }
              >
                Website:
              </Typography>
            </div>
            <div className="pitch-thank-you-content-con-row2">
              {/* -------- */ }
              { props.isEdit ?
                <TextField
                  value={ slide.website }
                  placeholder="www.companyname.com"
                  // disabled
                  onChange={ e => {

                    handleDetailsChange("website", e.target.value);
              
                  } }
                  fullWidth
                  inputProps={ { maxLength: 25 } }
                  sx={ {
                    // "& .MuiOutlinedInput-root": {
                    //   padding: "0px 0px",
                    // },
                    "& .MuiInputBase-input": {
                      fontSize  : "14px",
                      padding   : "0.5px 20px",
                      border    : "none",
                      fontWeight: "bold",
                      color     : "#707070"
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "2px dotted #B4B4FA"
                    }
                  } }
                />
                :
                <Box sx={ { height: "21px" } }>
                  <Typography
                    id={ styles.rightDetailTypo }
                    // sx={{ fontSize: "14px", color: "#707070", marginLeft: "20px" }}
                  >
                    { slide.website
                      ? slide.website
                      : isPublished
                        ? ""
                        : "www.companyname.com" }
                  </Typography>
                </Box>
              }
            </div>
          </div>
          <div className="pitch-thank-you-content-con">
            <div className="pitch-thank-you-content-con-row1">
              <Typography
                id={ styles.leftDetailTypo }
                // sx={{ fontSize: "14px", color: "#083A9A", fontWeight: "bold" }}
              >
            Contact:
              </Typography>
            </div>
            <div className="pitch-thank-you-content-con-row2">
              {/* -------- */ }
              { props.isEdit ?
                <TextField
                  value={ slide.contact }
                  onChange={ e => {

                    handleDetailsChange("contact", e.target.value);
              
                  } }
                  placeholder="Contact Number"
                  // placeholder={placeholders[segmentindex]["segmentName"]}
                  // onChange={(e) => {
                  //   handleChange("segmentName", e.target.value, segmentindex);
                  // }}
                  fullWidth
                  inputProps={ { maxLength: 25 } }
                  sx={ {
                    // "& .MuiOutlinedInput-root": {
                    //   padding: "0px 0px",
                    // },
                    "& .MuiInputBase-input": {
                      fontSize  : "14px",
                      padding   : "0.5px 20px",
                      border    : "none",
                      color     : "#707070",
                      fontWeight: "bold"
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "2px dotted #B4B4FA"
                    }
                  } }
                />
                :
                <Box sx={ { height: "21px" } }>
                  <Typography
                    id={ styles.rightDetailTypo }
                    // sx={{ fontSize: "14px", color: "#707070", marginLeft: "20px" }}
                  >
                    { slide.contact
                      ? slide.contact
                      : isPublished
                        ? ""
                        : "Contact Number" }
                  </Typography>
                </Box>
              }
            </div>
          </div>
          <div className="pitch-thank-you-content-con">
            <div className="pitch-thank-you-content-con-row1">
              <Typography
                id={ styles.leftDetailTypo }
                // sx={{ fontSize: "14px", color: "#083A9A", fontWeight: "bold" }}
              >
            Country:
              </Typography>
            </div>
            <div className="pitch-thank-you-content-con-row2">
              { props.isEdit ?
                <TextField
                  value={ slide.country }
                  onChange={ e => {

                    handleDetailsChange("country", e.target.value);
              
                  } }
                  placeholder="Name of the Country"
                  // onChange={(e) => {
                  //   handleChange("segmentName", e.target.value, segmentindex);
                  // }}
                  fullWidth
                  inputProps={ { maxLength: 25 } }
                  sx={ {
                    // "& .MuiOutlinedInput-root": {
                    //   padding: "0px 0px",
                    // },
                    "& .MuiInputBase-input": {
                      fontSize  : "14px",
                      padding   : "0.5px 20px",
                      border    : "none",
                      fontWeight: "bold",
                      color     : "#707070"
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "2px dotted #B4B4FA"
                    }
                  } }
                />
                :
                <Box sx={ { height: "21px" } }>
                  <Typography
                    id={ styles.rightDetailTypo }
                    // sx={{ fontSize: "14px", color: "#707070", marginLeft: "20px" }}
                  >
                    { slide.country
                      ? slide.country
                      : isPublished
                        ? ""
                        : "Name of the Country" }
                  </Typography>
                </Box>
              }
            </div>
          </div>
        </Box>
      </Box>
    </Box>
  );

}
