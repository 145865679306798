import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Box
} from "@mui/material";
import React from "react";
import styles from "./PitchPresentationResponsive.module.css";

export default function PitchPresentationResponsive () {

  return (
    <Box
      id={ styles.mainBox }
    // sx={{
    //   // border: 1,
    //   display: "flex",
    //   justifyContent: "center",
    //   alignItems: "center",
    //   // height: "97vh",
    //   height: "97vh",
    //   flexDirection: "column",
    //   width: "100%",
    // }}
    // www
    >
      <Box
        // ref={ref}
        sx={ {
          display       : "flex",
          justifyContent: "center",
          alignItems    : "center",
          flexDirection : "column",
          // border: 1,
          width         : "78%"
        } }
      >
        {/* presentation main box */ }
        <Box
          id={ styles.presentationContentBox }
        // sx={{
        //   width: "78%",
        //   border: "3px solid #071AFF",
        //   height: "74vh",
        //   borderRadius: "5px",
        //   marginTop: "3vh",
        //   position: "relative",
        //   backgroundColor: "#FFFFFF",
        // }}
        >
          {/* component display window */ }

          {/* navigation button */ }

          {/* left */ }
          <Box
            //   id={styles.leftIconBox}
            id={ styles.leftContentBoxIcon }
          // sx={{
          //   width: "38px",
          //   height: "52px",
          //   boxShadow: "0px 3px 6px #00000029",
          //   display: "flex",
          //   justifyContent: "center",
          //   alignItems: "center",
          //   position: "absolute",
          //   top: "34vh",
          //   left: "-15px",
          //   zIndex: 100,
          //   backgroundColor: "#FFFFFF",
          //   borderRadius: "3px",
          //   cursor: "pointer",
          // }}
          >
            <KeyboardArrowLeftIcon />
          </Box>
          {/* ----- */ }
          <Box
            //   id={styles.rightIconBox}
            id={ styles.rightContentBoxIcon }
          // sx={{
          //   width: "38px",
          //   height: "52px",
          //   boxShadow: "0px 3px 6px #00000029",
          //   display: "flex",
          //   justifyContent: "center",
          //   alignItems: "center",
          //   position: "absolute",
          //   top: "34vh",
          //   right: "-15px",
          //   zIndex: 100,
          //   backgroundColor: "#FFFFFF",
          //   borderRadius: "3px",
          //   cursor: "pointer",
          // }}
          >
            <KeyboardArrowRightIcon />
          </Box>
          {/* ----------- */ }

          {/* copyright msg */ }
        </Box>
        {/* bar box */ }

        {/* bottom nav box */ }
      </Box>
    </Box>
  );

}
