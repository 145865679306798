import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Box, Link, Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
// import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory, useLocation } from "react-router-dom";
import { fetchPdf } from "../../../../services/pitchdeck";
import "./PitchPdfPresentation.css";

export default function PitchPdfPresentation () {

  const [ uploadedPitchUrl, setUploadedPitchUrl ] = useState("");
  const [ pdfPages, setPdfPages ] = useState(null);
  const [ pdfPageNumber, setPdfPageNumber ] = useState(1);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1023px)" });

  let location = useLocation();
  let locationState = location.state;

  // const { user, venture } = useSelector(state => ({ ...state }));

  const useQuery = () => {

    return new URLSearchParams(location.search);
  
  };
  const query = useQuery();
  const id = query.get("id");

  useEffect(() => {

    fetchUploadedPdf();
  
  }, []);

  const fetchUploadedPdf = async () => {

    const response = await fetchPdf(id);
    // const response = await fetchPdf(venture._id);

    setUploadedPitchUrl(response.data.uploadedPitchUrl);
  
  };

  const onDocumentLoadSuccess = ({ numPages }) => {

    setPdfPages(numPages);
    setPdfPageNumber(1);
  
  };

  const previousPage = () => {

    if (pdfPageNumber <= 1) {

      setPdfPageNumber(pdfPages);
    
    } else {

      changePage(-1);
    
    }
  
  };

  const changePage = pageNum => {

    setPdfPageNumber(prevPageNumber => prevPageNumber + pageNum);
  
  };

  const nextPage = () => {

    if (pdfPageNumber === pdfPages) {

      setPdfPageNumber(1);
    
    } else {

      changePage(1);
    
    }
  
  };

  const history = useHistory();

  return (
    <Box
      sx={ {
        height        : "89vh",
        // height: "88vh",
        padding       : "20px 30px 30px 30px",
        // border: 1,
        display       : "flex",
        justifyContent: "center",
        alignItems    : "center",
        flexDirection : "column"
        // border: 1,

        // width: "100%",
      } }
      onClick={ () => {

        // history.push("/pitchdeckintro");
        if (locationState && locationState.fromPitchDeck) {

          history.push("/pitchdeckintro", { fromPitchPdf: true });
        
        }
      
      } }
    >
      <Box
        sx={ {
          height         : "0px",
          width          : "72%",
          // 78
          paddingBottom  : "38.9%",
          // paddingBottom: "40.5%",
          // 41.9
          // paddingBottom: "39.3%",
          // width: "90%",
          // paddingBottom: "40%",
          border         : "2px solid #071AFF",
          position       : "relative",
          borderRadius   : "5px",
          backgroundColor: "#FFFFFF"
          // display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
        } }
        onClick={ e => {

          e.stopPropagation();
        
        } }
      >
        <Box
          sx={ {
            bottom        : 0,
            // left: "3.3%",
            left          : 25,
            // margin: "auto",
            maxHeight     : "100%",
            maxWidth      : "100%",
            right         : 25,
            top           : 0,
            position      : "absolute",
            // width: "67.3vw",
            // height: "20vh",
            display       : "flex",
            justifyContent: "center",
            alignItems    : "center"
            // border: 1,
          } }
        >
          <Document
            // file={"./pdfDoc/todac2.pdf"}
            onLoadSuccess={ onDocumentLoadSuccess }
            file={ uploadedPitchUrl }
          // file={"./pdfDoc/todacSample.pdf"}
          >
            <Page
              // w70 h72
              className="customClassPresentation"
              renderAnnotationLayer={ false }
              // height={200}
              // scale={0.5}

              pageNumber={ pdfPageNumber }
            />
          </Document>
        </Box>

        {/* -----nav buttons-------- */ }

        <Box
          sx={ {
            // width: "38px",
            // height: "52px",
            width          : "4%",
            height         : "8%",
            boxShadow      : "0px 3px 6px #00000029",
            display        : "flex",
            justifyContent : "center",
            alignItems     : "center",
            position       : "absolute",
            // top: "34vh",
            // left: "-15px",
            // zIndex: 100,
            backgroundColor: "#FFFFFF",
            borderRadius   : "3px",
            cursor         : "pointer",
            left           : "-2%",
            top            : "50%"
          } }
          onClick={ () => {

            // e.stopPropagation();
            previousPage();
          
          } }
        >
          <KeyboardArrowLeftIcon />
        </Box>

        <Box
          sx={ {
            width          : "4%",
            height         : "8%",
            // width: "38px",
            // height: "52px",
            boxShadow      : "0px 3px 6px #00000029",
            display        : "flex",
            justifyContent : "center",
            alignItems     : "center",
            position       : "absolute",
            top            : "50%",
            right          : "-2%",
            zIndex         : 100,
            backgroundColor: "#FFFFFF",
            borderRadius   : "3px",
            cursor         : "pointer"
          } }
          onClick={ nextPage }
        >
          <KeyboardArrowRightIcon />
        </Box>
      </Box>

      {/* bottom nav box */ }

      { isTabletOrMobile ?
        <></>
        :
        <Box
          sx={ {
            width         : "72%",
            // border: 1,
            height        : "4vh",
            display       : "flex",
            justifyContent: "space-between",
            alignItems    : "center",
            columnGap     : "8px"
          } }
          onClick={ e => {

            e.stopPropagation();
          
          } }
        >
          {/* -----individual nav box------ */ }
          { Array.from(Array(pdfPages).keys()).map((element, index) => {

            return (
              <Box
                sx={ {
                  // border: 1,
                  display       : "flex",
                  justifyContent: "center",
                  alignItems    : "center",
                  width         : "100%",
                  height        : "30px",
                  cursor        : "pointer"
                } }
                onClick={ e => {

                  e.stopPropagation();
                  setPdfPageNumber(index + 1);
                
                } }
              >
                <Box
                  sx={ {
                    // border: 1,
                    // width: "4.5%",
                    width          : "100%",
                    height         : "4px",
                    backgroundColor: "#071AFF",
                    // backgroundColor:
                    //   props.isRender === props.index ? "#071AFF" : "#071AFF",
                    opacity        : pdfPageNumber - 1 === index ? "1" : "0.4",
                    // border: 1,
                    position       : "relative",
                    // cursor: "pointer",
                    borderRadius   : "3px"
                  } }
                ></Box>
              </Box>
            );
          
          }) }
        </Box>
      }

      { isTabletOrMobile ?
        <></>
        :
        <Box
          sx={ {
            width         : "72%",
            height        : "50px",
            display       : "flex",
            justifyContent: "center",
            alignItems    : "center",
            position      : "relative"
            // border: 1,
          } }
          onClick={ e => {

            e.stopPropagation();
          
          } }
        >
          <Box
            // id={styles.bottomNavSubBox}
            sx={ {
              width         : "7vw",
              height        : "30px",
              // border: 1,
              display       : "flex",
              justifyContent: "space-between",
              alignItems    : "center"
            } }
          >
            <Box
              // id={styles.bottomLeftIconBox}
              sx={ {
                // border: 1,
                display        : "flex",
                justifyContent : "space-between",
                alignItems     : "center",
                borderRadius   : "50%",
                backgroundColor: "#868686",
                cursor         : "pointer"
              } }
              onClick={ previousPage }
            >
              <ChevronLeftIcon
                // id={styles.ChevronLeftIcon}
                sx={ { fontSize: "19px", color: "white" } }
              />
            </Box>
            <Box>
              <Typography
                // id={styles.slideNoTypo}
                sx={ { fontSize: "11px", color: "#393939", fontWeight: "bold" } }
              >
                { pdfPageNumber }/{ pdfPages }
              </Typography>
            </Box>
            <Box
              // id={styles.bottomRightIconBox}
              sx={ {
                // border: 1,
                display        : "flex",
                justifyContent : "space-between",
                alignItems     : "center",
                borderRadius   : "50%",
                backgroundColor: "#868686",
                cursor         : "pointer"
              } }
              onClick={ nextPage }
            >
              <ChevronRightIcon
                sx={ { fontSize: "19px", color: "white" } }
              // id={styles.ChevronRightIcon}
              />
            </Box>
          </Box>

          {/* ------logo img box------ */ }
          <Box
            // id={styles.logoBox}
            sx={ {
              // border: 1,
              position      : "absolute",
              width         : "157px",
              height        : "50px",
              right         : "0px",
              cursor        : "pointer",
              display       : "flex",
              justifyContent: "center",
              alignItems    : "center"
            } }
            onClick={ e => {

              e.stopPropagation();
            
            } }
          >
            <Typography
              sx={ {
                fontSize   : "12px",
                marginRight: "4px",
                // fontWeight: "bold",
                fontStyle  : "italic"
              } }
            >
              Powered by
            </Typography>
            <Typography
              sx={ {
                fontSize      : "12px",
                textDecoration: "underline",
                color         : "#071AFF",
                // fontWeight: "bold",
                fontStyle     : "italic"
              } }
              component={ Link }
            >
              {/* Todac Tribe */ }
              <Link
                target="_blank"
                href={
                  window.location.host === "app.todactribe.com"
                    ? "https://todactribe.com/"
                    : "https://dev.todactribe.com/"
                }
                underline="none"
              >
                TODAC Tribe
              </Link>
            </Typography>
            {/* </a> */ }
          </Box>
        </Box>
      }
    </Box>
  );

}
