import axios from "./axiosService";

// import axios from "axios";

export const createConceptVisionComment = async (
  commentText,
  userid,
  sectionName,
  subSectionName,
  ventureid,
  commentid,
  commentUrl,
  commentSectionName
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/create_conceptVision_comment`,
    {
      commentText,
      userid,
      sectionName,
      subSectionName,
      ventureid,
      commentid,
      commentUrl,
      commentSectionName
    }
  );

};

export const updateConceptVisionComment = async (
  commentText,
  commentuserid,
  commentid,
  ventureid,
  commentUrl,
  commentSectionName
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/update_conceptVision_comment`,
    {
      commentid,
      commentText,
      commentuserid,
      ventureid,
      commentUrl,
      commentSectionName
    }
  );

};

export const deleteConceptVisionComment = async (
  sectionName,
  subSectionName,
  ventureid,
  commentid,
  userid,
  commentUrl,
  commentSectionName
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/delete_conceptVision_comment`,
    {
      sectionName,
      subSectionName,
      ventureid,
      commentid,
      userid,
      commentUrl,
      commentSectionName
    }
  );

};

export const createMarketComment = async (
  commentText,
  userid,
  sectionName,
  subSectionName,
  marketid,
  commentid,
  commentUrl,
  commentSectionName
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/create_market_comment`,
    {
      commentText,
      userid,
      sectionName,
      subSectionName,
      marketid,
      commentid,
      commentUrl,
      commentSectionName
    }
  );

};

export const updateMarketComment = async (
  commentText,
  commentuserid,
  commentid,
  ventureid,
  commentUrl,
  commentSectionName
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/update_market_comment`,
    {
      commentid,
      commentText,
      commentuserid,
      ventureid,
      commentUrl,
      commentSectionName
    }

  );

};

export const deleteMarketComment = async (
  sectionName,
  subSectionName,
  marketid,
  commentid,
  userid,
  commentUrl,
  commentSectionName
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/delete_market_comment`,
    {
      sectionName,
      subSectionName,
      marketid,
      commentid,
      userid,
      commentUrl,
      commentSectionName
    }

  );

};

export const createCustomerComment = async (
  commentText,
  userid,
  sectionName,
  subSectionName,
  customerid,
  commentid,
  commentUrl,
  commentSectionName
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/create_customer_comment`,
    {
      commentText,
      userid,
      sectionName,
      subSectionName,
      customerid,
      commentid,
      commentUrl,
      commentSectionName
    }

  );

};

export const updateCustomerComment = async (
  commentText,
  commentuserid,
  commentid,
  ventureid,
  commentUrl,
  commentSectionName
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/update_customer_comment`,
    {
      commentid,
      commentText,
      commentuserid,
      ventureid,
      commentUrl,
      commentSectionName
    }

  );

};

export const deleteCustomerComment = async (
  sectionName,
  subSectionName,
  customerid,
  commentid,
  userid,
  commentUrl,
  commentSectionName
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/delete_customer_comment`,
    {
      sectionName,
      subSectionName,
      customerid,
      commentid,
      userid,
      commentUrl,
      commentSectionName
    }

  );

};

export const createProblemSolutionComment = async (
  commentText,
  userid,
  sectionName,
  subSectionName,
  problemsolutionid,
  commentid,
  commentUrl,
  commentSectionName
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/create_problemSolution_comment`,
    {
      commentText,
      userid,
      sectionName,
      subSectionName,
      problemsolutionid,
      commentid,
      commentUrl,
      commentSectionName
    }

  );

};

export const updateProblemSolutionComment = async (
  commentText,
  commentuserid,
  commentid,
  ventureid,
  commentUrl,
  commentSectionName
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/update_problemSolution_comment`,
    {
      commentid,
      commentText,
      commentuserid,
      ventureid,
      commentUrl,
      commentSectionName
    }

  );

};

export const deleteProblemSolutionComment = async (
  sectionName,
  subSectionName,
  problemsolutionid,
  commentid,
  userid,
  commentUrl,
  commentSectionName
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/delete_problemSolution_comment`,
    {
      sectionName,
      subSectionName,
      problemsolutionid,
      commentid,
      userid,
      commentUrl,
      commentSectionName
    }

  );

};

export const createPitchdeckComment = async (
  commentText,
  userid,
  commentSectionRendername,
  pitchdeckid,
  commentid,
  commentUrl,
  commentSectionName
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/create_pitchDeck_comment`,
    {
      commentText,
      userid,
      commentSectionRendername,
      pitchdeckid,
      commentid,
      commentUrl,
      commentSectionName
    }

  );

};

export const updatePitchdeckComment = async (
  commentText,
  commentuserid,
  commentid,
  ventureid,
  commentUrl,
  commentSectionName
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/update_pitchDeck_comment`,
    {
      commentid,
      commentText,
      commentuserid,
      ventureid,
      commentUrl,
      commentSectionName
    }

  );

};

export const deletePitchdeckComment = async (
  commentSectionRendername,
  pitchdeckid,
  commentid,
  userid,
  commentUrl,
  commentSectionName
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/delete_pitchDeck_comment`,
    {
      commentSectionRendername,
      pitchdeckid,
      commentid,
      userid,
      commentUrl,
      commentSectionName
    }

  );

};

export const createFinancialsComment = async (
  commentText,
  userid,
  sectionName,
  subSectionName,
  financialsid,
  commentid,
  commentUrl,
  commentSectionName
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/create_financials_comment`,
    {
      commentText,
      userid,
      sectionName,
      subSectionName,
      financialsid,
      commentid,
      commentUrl,
      commentSectionName
    }

  );

};

export const updateFinancialsComment = async (
  commentText,
  commentuserid,
  commentid,
  ventureid,
  commentUrl,
  commentSectionName
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/update_financials_comment`,
    {
      commentid,
      commentText,
      commentuserid,
      ventureid,
      commentUrl,
      commentSectionName
    }

  );

};

export const deleteFinancialsComment = async (
  sectionName,
  subSectionName,
  financialsid,
  commentid,
  userid,
  commentUrl,
  commentSectionName
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/delete_financials_comment`,
    {
      sectionName,
      subSectionName,
      financialsid,
      commentid,
      userid,
      commentUrl,
      commentSectionName
    }

  );

};

export const createGtmComment = async (
  commentText,
  userid,
  sectionName,
  subSectionName,
  gtmid,
  commentid,
  commentUrl,
  commentSectionName
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/create_gtm_comment`,
    {
      commentText,
      userid,
      sectionName,
      subSectionName,
      gtmid,
      commentid,
      commentUrl,
      commentSectionName
    }

  );

};

export const updateGtmComment = async (
  commentText,
  commentuserid,
  commentid,
  ventureid,
  commentUrl,
  commentSectionName
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/update_gtm_comment`,
    {
      commentid,
      commentText,
      commentuserid,
      ventureid,
      commentUrl,
      commentSectionName
    }

  );

};

export const deleteGtmComment = async (
  sectionName,
  subSectionName,
  gtmid,
  commentid,
  userid,
  commentUrl,
  commentSectionName
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/delete_gtm_comment`,
    {
      sectionName,
      subSectionName,
      gtmid,
      commentid,
      userid,
      commentUrl,
      commentSectionName
    }

  );

};

export const createOperationsComment = async (
  commentText,
  userid,
  sectionName,
  subSectionName,
  operationsid,
  commentid,
  commentUrl,
  commentSectionName
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/create_operations_comment`,
    {
      commentText,
      userid,
      sectionName,
      subSectionName,
      operationsid,
      commentid,
      commentUrl,
      commentSectionName
    }

  );

};

export const updateOperationsComment = async (
  commentText,
  commentuserid,
  commentid,
  ventureid,
  commentUrl,
  commentSectionName
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/update_operations_comment`,
    {
      commentid,
      commentText,
      commentuserid,
      ventureid,
      commentUrl,
      commentSectionName
    }

  );

};

export const deleteOperationsComment = async (
  sectionName,
  subSectionName,
  operationsid,
  commentid,
  userid,
  commentUrl,
  commentSectionName
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/delete_operations_comment`,
    {
      sectionName,
      subSectionName,
      operationsid,
      commentid,
      userid,
      commentUrl,
      commentSectionName
    }

  );

};

export const createMonetisationComment = async (
  commentText,
  userid,
  sectionName,
  subSectionName,
  monetisationid,
  commentid,
  commentUrl,
  commentSectionName
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/create_monetisation_comment`,
    {
      commentText,
      userid,
      sectionName,
      subSectionName,
      monetisationid,
      commentid,
      commentUrl,
      commentSectionName
    }
  );

};

export const updateMonetisationComment = async (
  commentid,
  commentText,
  commentuserid,
  ventureid,
  commentUrl,
  commentSectionName
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/update_monetisation_comment`,
    {
      commentid,
      commentText,
      commentuserid,
      ventureid,
      commentUrl,
      commentSectionName
    }
  );

};

export const deleteMonetisationComment = async (
  userid,
  commentid,
  monetisationid,
  sectionName,
  subSectionName,
  commentUrl,
  commentSectionName
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/delete_monetisation_comment`,
    {
      userid,
      commentid,
      monetisationid,
      sectionName,
      subSectionName,
      commentUrl,
      commentSectionName
    }
  );

};

export const createMonetisationRevenueStreamsComment = async (
  commentText,
  userid,
  sectionName,
  monetisationid,
  commentid,
  commentUrl,
  commentSectionName,
  revenueStreamIndex
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/create_monetisation_revenuestreams_comment`,
    {
      commentText,
      userid,
      sectionName,
      monetisationid,
      commentid,
      commentUrl,
      commentSectionName,
      revenueStreamIndex
    }
  );

};

export const updateMonetisationRevenueStreamsComment = async (
  commentid,
  commentText,
  commentuserid,
  ventureid,
  commentUrl,
  commentSectionName
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/update_monetisation_revenuestreams_comment`,
    {
      commentid,
      commentText,
      commentuserid,
      ventureid,
      commentUrl,
      commentSectionName
    }
  );

};

export const deleteMonetisationRevenueStreamsComment = async (
  userid,
  commentid,
  monetisationid,
  sectionName,
  commentUrl,
  commentSectionName,
  revenueStreamIndex
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/delete_monetisation_revenuestreams_comment`,
    {
      userid,
      commentid,
      monetisationid,
      sectionName,
      commentUrl,
      commentSectionName,
      revenueStreamIndex
    }
  );

};

