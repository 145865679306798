import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box, Button, CircularProgress, Divider, Grid, IconButton, InputAdornment, LinearProgress, OutlinedInput, TextField, Tooltip, Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import BoxWithoutIcon from "../../../components/BoxWithoutIcon";
import ErrorMessage from "../../../components/ErrorMessage";
import {
  strengthColor,
  strengthIndicator,
  strengthText
} from "../../../components/PasswordStrength";
import { auth } from "../../../firebase";
// import { GoogleAuthProvider } from "firebase/auth";
import {
  createOrUpdateUser,
  UpdateUserDetails,
  userVerified
} from "../../../services/auth";

import { createUserWithEmailAndPassword, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";


import styles from "./Login.module.css";

const useStyles = makeStyles(() => ({
  customWidth: {
    maxWidth       : 200,
    fontSize       : 10,
    backgroundColor: "white",
    color          : "#707070",
    boxShadow      : "0px 3px 6px #00000029",
    border         : "1px solid #707070",
    borderRadius   : 10
  }
}));

const CollaborationLogin = () => {

  const [ email, setEmail ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ showPassword, setShowpassword ] = useState(false);
  const [ details, setDetails ] = useState({
    firstname          : "",
    lastname           : "",
    password           : "",
    confirmpassword    : "",
    showPassword       : false,
    showConfirmPassword: false
  });
  const [ errormsg, setErrormsg ] = useState("");
  const [ isUserpresent, setIsuserpresent ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  let history = useHistory();
  let location = useLocation();
  let dispatch = useDispatch();

  const useQuery = () => {

    return new URLSearchParams(location.search);
  
  };
  const query = useQuery();

  const ownerName = query.get("ownerName");
  const ventureName = query.get("ventureName");
  const access = query.get("access");
  const inviteEmail = query.get("inviteEmail");

  const classes = useStyles();

  // password strength indicator
  const strength = strengthIndicator(details.password);
  const strengthcolor = strengthColor(strength);
  const strengthtext = strengthText(strength);
  const normalise = strength => ((strength - 1) * 100) / (5 - 1);

  const { user } = useSelector(state => ({ ...state }));

  useEffect(() => {

    if (user) {

      if (user.userVerified && user.token) {

        history.push("/welcomepage");
      
      }
    
    }
  
  }, [ user ]);

  useEffect(() => {

    setEmail(inviteEmail);
    userCheck(inviteEmail);
  
  }, []);

  const userCheck = async email => {

    setLoading(true);
    const response = await userVerified(email);

    if (response.data === "User Verified Already") {

      setIsuserpresent(true);
    
    }
    setLoading(false);
  
  };

  const handledetailsChange = event => {

    const name = event.target.name;
    const value = event.target.value.trimStart();

    setDetails(values => ({ ...values, [name]: value }));
  
  };

  const handleClickShowPassword = () => {

    setDetails({
      ...details,
      showPassword: !details.showPassword
    });
  
  };
  const handleClickShowConfirmPassword = () => {

    setDetails({
      ...details,
      showConfirmPassword: !details.showConfirmPassword
    });
  
  };

  const registerHandleSubmit = async e => {

    e.preventDefault();

    // validation
    if (
      details.password === details.confirmpassword &&
      details.firstname !== "" &&
      details.lastname !== "" &&
      email !== "" &&
      details.password !== "" &&
      details.confirmpassword !== ""
    ) {

      try {

        await createUserWithEmailAndPassword(auth,
          email,
          details.password
        );
        let user = auth.currentUser;
        const idTokenResult = await user.getIdTokenResult();

        createOrUpdateUser()
          .then(() => {

            UpdateUserDetails(details)
              .then(res => {

                dispatch({
                  type   : "LOGGED_IN_USER",
                  payload: {
                    firstname       : res.data.user.firstname,
                    lastname        : res.data.user.lastname,
                    email           : res.data.user.email,
                    subscription    : res.data.user.subscription,
                    token           : idTokenResult.token,
                    access          : res.data.user.access,
                    profileIcon     : res.data.profileUrl,
                    userVerified    : res.data.user.userVerified,
                    organizationName: res.data.user.organizationName,
                    location        : res.data.user.location,
                    createdAt       : res.data.user.createdAt,
                    _id             : res.data.user._id
                  }
                });
              
              })
              .catch(error => {

                //
                console.error(error);
              
              });
            // redirect
            history.push("/welcomepage");
          
          })
          .catch(error => {

            console.error(error);
          
          });
      
      } catch (error) {

        console.error(error);
        setErrormsg("Unexpected error");
        setTimeout(() => {

          setErrormsg("");
        
        }, 5000);
      
      }
    
    } else {

      if (
        details.firstname === "" ||
        details.lastname === "" ||
        email === "" ||
        details.password === "" ||
        details.confirmpassword === "" ||
        details.location === ""
      ) {

        setErrormsg("Please fill in the required (*) fields.");
      
      } else if (
        details.password !== details.confirmpassword ||
        details.password === ""
      ) {

        setErrormsg("The two passwords don't match. Please re-enter them");
      
      } else if (
        !details.password.match(
          "(?!^[0-9]*$)(?!^[a-zA-Z]*$)^([a-zA-Z0-9!#@$%^&*)(+=._-]{6,15})$"
        )
      ) {

        setErrormsg("Password does not meet requirements");
      
      }
      setTimeout(() => {

        setErrormsg("");
      
      }, 5000);
    
    }
  
  };

  const loginHandleSubmit = async e => {

    e.preventDefault();
    try {

      const result = await signInWithEmailAndPassword(auth, email, password);
      const { user } = result;
      const idTokenResult = await user?.getIdTokenResult();

      createOrUpdateUser().then(res => {

        dispatch({
          type   : "LOGGED_IN_USER",
          payload: {
            firstname       : res.data.firstname,
            lastname        : res.data.lastname,
            email           : res.data.email,
            token           : idTokenResult.token,
            subscription    : res.data.subscription,
            access          : res.data.access,
            profileIcon     : res.data.profileIcon,
            userVerified    : res.data.userVerified,
            organizationName: res.data.organizationName,
            location        : res.data.location,
            createdAt       : res.data.createdAt,
            _id             : res.data._id
          }
        });
        if (auth?.currentUser?.emailVerified) {

          history.push("/welcomepage");
        
        } else {

          history.push({
            pathname: "/registerdetails",
            hash    : "google"
          });
        
        }
      
      }).catch(err => console.error(err));

    } catch (error) {

      console.error(error);
      if (error.code === "auth/wrong-password") {

        setErrormsg(
          "Oh! The password you entered is incorrect. If you have forgotten it, click 'Forgot Password' to reset it."
        );
      
      } else {

        setErrormsg(
          "We could not find an account with that email. Kindly verify the email address entered, or try a different email."
        );
      
      }
      setTimeout(() => {

        setErrormsg("");
      
      }, 5000);
    
    }
  
  };

  const googleLogin = async () => {

    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then(async result => {

        const { user } = result;
        const idTokenResult = await user.getIdTokenResult();

        createOrUpdateUser()
          .then(res => {

            dispatch({
              type   : "LOGGED_IN_USER",
              payload: {
                firstname       : res.data.firstname,
                lastname        : res.data.lastname,
                email           : res.data.email,
                token           : idTokenResult.token,
                subscription    : res.data.subscription,
                access          : res.data.access,
                profileIcon     : res.data.profileIcon,
                userVerified    : res.data.userVerified,
                organizationName: res.data.organizationName,
                location        : res.data.location,
                createdAt       : res.data.createdAt,
                _id             : res.data._id
              }
            });

            if (auth?.currentUser?.emailVerified) {

              history.push("/welcomepage");
            
            } else {

              history.push({
                pathname: "/registerdetails",
                hash    : "google"
              });
            
            }
          
          }).catch(err => console.error(err));
      
      }).catch(err => {

        console.error(err);
      
      });
  
  };

  return (
    <BoxWithoutIcon>
      {loading ?
        <Grid
          container
          justifyContent="center"
          height="100%"
          alignItems="center"
        >
          <Grid item>
            <CircularProgress size="60px" thickness={3} />
          </Grid>
        </Grid>
        :
        <Grid
          container
          sx={{
            alignItems    : "center",
            height        : "100%",
            justifyContent: "center"
          }}
        >
          <Grid item md={6} xs={12} id={styles.griditem1}>
            <Box
              sx={{
                display       : "flex",
                justifyContent: "center",
                flexDirection : "column",
                alignItems    : "center"
                // border: 1,
              }}
            >
              <Typography
                gutterBottom
                // id={styles.welcometypo}
                sx={{
                  color       : "#5F5E5E",
                  fontSize    : "20px",
                  fontWeight  : "bold",
                  marginBottom: "10vh"
                }}
              >
                Welcome to the
                <span
                  style={{
                    color: "#071AFF"
                  }}
                >
                  {" TODAC Tribe!"}
                </span>
              </Typography>
              <img
                style={{
                  width  : "420px",
                  height : "260px",
                  padding: "20px 0px 20px 20px"
                }}
                src={
                  isUserpresent
                    ? `${process.env.REACT_APP_AWS_ASSETS_URL}/images/undraw_login_re_4vu2.svg`
                    : `${process.env.REACT_APP_AWS_ASSETS_URL}/images/undraw_authentication_re_svpt.svg`
                }
                alt="login img"
              />
            </Box>
            <Divider
              orientation="vertical"
              variant="middle"
              sx={{
                height     : "320px",
                borderColor: "#dfd7d7"
              }}
            />
          </Grid>
          {isUserpresent ?
            <Grid item md={5} xs={12} id={styles.griditem2}>
              <Typography gutterBottom id={styles.invitetypo}>
                <span id={styles.highlightedtext}>{ownerName}</span> {"has invited you to their venture project, "}
                <span id={styles.highlightedtext}>{ventureName}</span>, {"as "}
                {access === "Editor" ? "a Cofounder" : "a Mentor"}.
              </Typography>
              {errormsg &&
                <ErrorMessage
                  errormsg={errormsg}
                  padding="5px 10px"
                  msgfont="12px"
                />
              }
              <Box
                sx={{
                  height        : "10vh",
                  display       : "flex",
                  justifyContent: "center",
                  alignItems    : "center"
                }}
              >
                <Box
                  sx={{
                    width          : "70%",
                    textAlign      : "center",
                    backgroundColor: "#26B99A34",
                    padding        : "0px 10px 0px 10px",
                    borderRadius   : "4px"
                  }}
                >
                  <Typography sx={{ fontSize: "12px", color: "#0A7655" }}>
                    Looks like you already have an account with us. Login below
                    to continue.
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  // border: 1,
                  width         : "100%",
                  display       : "flex",
                  justifyContent: "center",
                  alignItems    : "center",
                  marginBottom  : "15px"
                }}
              >
                <Box sx={{ width: "70%" }}>
                  <Typography
                    sx={{
                      fontSize    : "12px",
                      fontWeight  : "bold",
                      marginBottom: "3px"
                    }}
                  >
                    Email:
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    // placeholder="First Name"
                    sx={{
                      "& .MuiInputBase-input": {
                        fontSize: "14px",
                        padding : "5px 0px 5px 15px"
                      }
                    }}
                    value={email}
                    disabled
                  />
                  <Typography
                    sx={{
                      fontSize    : "12px",
                      fontWeight  : "bold",
                      marginBottom: "3px",
                      marginTop   : "5px"
                    }}
                  >
                    Enter Password:
                  </Typography>
                  <OutlinedInput
                    error={errormsg ? true : false}
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    size="small"
                    // placeholder="First Name"
                    sx={{
                      "& .MuiInputBase-input": {
                        fontSize: "14px",
                        padding : "5px 0px 5px 15px"
                      }
                    }}
                    value={password}
                    onChange={e => {

                      setPassword(e.target.value);
                    
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {

                            setShowpassword(!showPassword);
                          
                          }}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <Typography
                    sx={{
                      fontSize      : "10px",
                      color         : "blue",
                      textDecoration: "underline",
                      fontWeight    : "bold",
                      marginTop     : "3px",
                      cursor        : "pointer"
                    }}
                    onClick={() => {

                      history.push("/forgotpassword");
                    
                    }}
                  >
                    Forgot password?
                  </Typography>
                </Box>
              </Box>
              <Button
                variant="contained"
                color="primary"
                // id={styles.getstartedbtn}
                sx={{
                  textTransform: "none",
                  borderRadius : "4px",
                  padding      : "5px 50px 5px 50px"
                }}
                onClick={loginHandleSubmit}
              >
                Continue
              </Button>
              <Box>
                <Box
                  sx={{
                    display       : "flex",
                    justifyContent: "center",
                    alignItems    : "center",
                    marginTop     : "15px",
                    marginBottom  : "15px"
                  }}
                >
                  <Typography sx={{ color: "#5F5E5E" }}>-OR-</Typography>
                </Box>
                <Button
                  sx={{
                    background   : "#f8f9ff",
                    border       : "1px solid #cccccc",
                    textTransform: "none",
                    color        : "#947070",
                    fontSize     : "13px",
                    fontWeight   : 400,
                    width        : "240px",
                    height       : "30px",
                    borderRadius : "4px"
                  }}
                  onClick={googleLogin}
                >
                  <img
                    style={{
                      width      : "16px",
                      height     : "16px",
                      margin     : "10px",
                      marginRight: "20px"
                    }}
                    src="images\google_icon.png"
                    alt="google img"
                  />
                  Login with Google
                </Button>
                <Box sx={{ textAlign: "center", marginTop: "4px" }}>
                  <Typography sx={{ fontSize: "9px" }}>
                    Having trouble logging in?
                    <Typography
                      style={{
                        color     : "#071AFF",
                        fontWeight: "bold",
                        cursor    : "pointer",
                        fontSize  : "9px"
                      }}
                      component="a"
                      target="_blank"
                      href="/support"
                    >
                      Contact Support
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            :
            <Grid item md={5} xs={12} id={styles.griditem2}>
              <Typography gutterBottom id={styles.invitetypo}>
                <span id={styles.highlightedtext}>{ownerName}</span> has invited
                you to their venture project,
                <span id={styles.highlightedtext}>{ventureName}</span>, as
                {access === "Editor" ? "an" : "a"} {access}.
              </Typography>
              {errormsg &&
                <ErrorMessage
                  errormsg={errormsg}
                  padding="5px 10px"
                  msgfont="12px"
                />
              }
              <Grid container id={styles.gridcontainer}>
                <Grid item md={11}>
                  <Typography
                    sx={{
                      fontSize    : "12px",
                      fontWeight  : "bold",
                      marginBottom: "3px"
                    }}
                  >
                    Email:
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    sx={{
                      "& .MuiInputBase-input": {
                        fontSize: "14px"
                        // padding: "5px 10px 5px 10px",
                      }
                    }}
                    value={email}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container id={styles.gridcontainer}>
                <Grid item md={5}>
                  <Typography id={styles.containertypo} gutterBottom>
                    Tell us your name
                  </Typography>
                  <OutlinedInput
                    error={errormsg && details.firstname === ""}
                    size="small"
                    placeholder="First Name"
                    value={details.firstname}
                    name="firstname"
                    onChange={handledetailsChange}
                  />
                </Grid>
                <Grid item md={5}>
                  <br />
                  <OutlinedInput
                    error={errormsg && details.lastname === ""}
                    size="small"
                    placeholder="Last Name"
                    value={details.lastname}
                    name="lastname"
                    onChange={handledetailsChange}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                id={styles.gridcontainer}
                sx={{ position: "relative" }}
              >
                <Grid item md={5}>
                  <Typography id={styles.containertypo} gutterBottom>
                    Choose a password
                  </Typography>
                  <Tooltip
                    disableFocusListener
                    title="Password must have at least 6 characters. For a strong password, use upper & lower case letters, numbers, and symbols like !@#$%&*"
                    placement="bottom"
                    classes={{ tooltip: classes.customWidth }}
                  >
                    <OutlinedInput
                      error={
                        errormsg &&
                        (details.password === "" ||
                          details.password !== details.confirmpassword)
                      }
                      size="small"
                      type={details.showPassword ? "text" : "password"}
                      value={details.password}
                      name="password"
                      onChange={handledetailsChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {details.showPassword ?
                              <Visibility />
                              :
                              <VisibilityOff />
                            }
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Tooltip>
                </Grid>
                <Grid item md={5}>
                  <Typography id={styles.containertypo} gutterBottom>
                    Re-enter password
                  </Typography>
                  <OutlinedInput
                    error={
                      errormsg &&
                      (details.confirmpassword === "" ||
                        details.password !== details.confirmpassword)
                    }
                    size="small"
                    type={details.showConfirmPassword ? "text" : "password"}
                    value={details.confirmpassword}
                    name="confirmpassword"
                    onChange={handledetailsChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          edge="end"
                        >
                          {details.showConfirmPassword ?
                            <Visibility />
                            :
                            <VisibilityOff />
                          }
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Grid>
                {details.password ?
                  <Grid
                    sx={{
                      position    : "absolute",
                      left        : "68%",
                      top         : "90%",
                      margin      : "10px",
                      padding     : "5px",
                      width       : "130px",
                      border      : "solid 1px #cccccc",
                      borderRadius: "10px"
                    }}
                  >
                    <Typography sx={{ fontSize: "10px", marginBottom: "10px" }}>
                      Password strength : {strengthtext}
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={normalise(strength)}
                      sx={{
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: strengthcolor
                        },
                        borderRadius: "10px"
                      }}
                    />
                  </Grid>
                  :
                  <></>
                }
              </Grid>
              <Button
                variant="contained"
                color="primary"
                id={styles.getstartedbtn}
                onClick={registerHandleSubmit}
              >Let’s get started!
              </Button>
              <Box id={styles.mainbox}>
                <Typography gutterBottom id={styles.boxtypo}>
                  We take data privacy very seriously and only collect what we
                  need to provide you with the best experience possible.
                </Typography>
                <Typography id={styles.boxtypo}>
                  By signing up with us you are agreeing to our
                  <a id={styles.link} target="_blank"
                    href="https://todactribe.com/terms-and-policies/" rel="noreferrer">Terms of Service</a> and
                  <a id={styles.link} target="_blank"
                    href="https://todactribe.com/terms-and-policies/#1654523572538-b4747bc6-5fb3" rel="noreferrer">Privacy Policy</a>
                </Typography>
              </Box>
            </Grid>
          }
        </Grid>
      }
    </BoxWithoutIcon>
  );

};

export default CollaborationLogin;
