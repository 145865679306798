import axios from "./axiosService";

// import axios from "axios";

export const getMarketdetails = async marketId => {

  return await axios.get(
    `${process.env.REACT_APP_ADDRESS}/get_market/${marketId}`
  );

};

export const updateMarketDetails = async (details, marketId, sectionName) => {

  return await axios.post(`${process.env.REACT_APP_ADDRESS}/update_market`, {
    details,
    marketId,
    sectionName
  });

};

export const updateMarketSizeDetails = async (details, marketId) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/update_market_size`,
    { details, marketId }
  );

};

export const uploadMarketExcel = async formdata => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/upload_marketexcel`,
    formdata
  );

};

export const deleteMarketExcel = async marketId => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/delete_marketexcel`,
    { marketId }
  );

};
