/* eslint-disable camelcase */
import {
  Box, Button,
  Dialog, DialogContent, Grid, IconButton, TextField, Typography
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ModuleSections from "../../../components/ModuleSections";
import Data from "../../../Data/Solution.json";
import {
  getProblemSolution,
  updateDefensibility
} from "../../../services/problemsolution";
import DefensibilityAddRows from "./DefensibilityAddRows";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

// import { makeStyles } from "@mui/styles";
import BackNextButton from "../../../components/BackNextButton";
import IndividualSectionHeaders from "../../../components/IndividualSectionHeaders";
import { useUnmount } from "../../../components/useUnmount";
import {
  createProblemSolutionComment,
  deleteProblemSolutionComment,
  updateProblemSolutionComment
} from "../../../services/comment";
import styles from "./Solution.module.css";

// const useStyles = makeStyles(() => ({
//   customWidth: {
//     maxWidth       : 700,
//     fontSize       : 10,
//     backgroundColor: "white",
//     color          : "#707070",
//     boxShadow      : "0px 3px 6px #00000029",
//     border         : "1px solid #707070",
//     borderRadius   : 10
//   }
// }));

const Defensibility = () => {

  const [ details, setDetails ] = useState([]);
  const [ comments, setComments ] = useState([]);
  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const [ dialogbox, setDialog ] = useState(false);
  const [ createdialogbox, setCreateDialog ] = useState(false);
  const [ othername, setOthername ] = useState("");
  const [ isdelete, setIsdelete ] = useState(false);
  const history = useHistory();
  const [ isDataLoading, setIsDataLoading ] = useState(true);
  // const classes = useStyles();

  const pathname = history.location.pathname.substring(1);
  const content = Data.defensibiltiy;

  let { user, venture } = useSelector(state => {

    return { ...state };
  
  });

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  const createDialogopen = () => {

    setCreateDialog(true);
  
  };
  const createDialogclose = () => {

    setCreateDialog(false);
  
  };

  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);
    
    } else {

      setCollapsecomment(true);
    
    }
  
  };

  const handleChange = (value, detailindex) => {

    let newdetails = details.map((detail, index) =>
      index === detailindex
        ? {
          ...detail,
          ["value"]: value
        }
        : detail
    );

    setDetails(newdetails);
  
  };

  useEffect(() => {

    fetchSolution();
  
  }, []);

  const createRow = async () => {

    details.push({
      key  : othername,
      value: ""
    });
    setOthername("");
    await updateDefensibility(details, venture.problemSolution);
    createDialogclose();
  
  };

  const removeRow = async index => {

    const newdetails = details.filter((detail, i) => index !== i);

    setDetails(newdetails);
    await updateDefensibility(newdetails, venture.problemSolution);
    setIsdelete(false);
  
  };

  const fetchSolution = async () => {

    setIsDataLoading(true);

    try {

      const response = await getProblemSolution(venture.problemSolution);
      // console.log(response.data);

      setDetails(response.data.defensibityStrategy);
      setComments(response.data.comments.solution.defensibilityStrategy);
      setIsDataLoading(false);
    
    } catch (error) {

      console.error(error);
      setIsDataLoading(false);
    
    }
  
  };

  // To get the updated usestate value to save in useEffect cleanup
  const detailsRef = useRef(details);

  detailsRef.current = details;

  let isSubmitting = false;

  // useUnmount => custom hook to save while navigating
  useUnmount(() => {

    if ( isDataLoading == false && isSubmitting == false) {

      handleSave();
    
    }
  
  });

  const handleSave = async () => {

    try {

      await updateDefensibility(detailsRef.current, venture.problemSolution);
      
      return true;
    
    } catch (error) {

      console.error(error);
      
      return false;
    
    }
  
  };

  const handleSubmit = async () => {

    try {

      isSubmitting = true;

      if(isDataLoading == false) {
        
        await updateDefensibility(
          details,
          venture.problemSolution
        );
        // console.log(response.data);
        
      }

      if (pathname.includes("gtm")) {

        history.push("/gtmpersonasolutionmap");
      
      } else {

        history.push("/personasolutionmap");
      
      }
      isSubmitting = false;
    
    } catch (error) {

      isSubmitting = false;
      console.error(error);
    
    }
  
  };

  const addComment = async (commentText, replyTo) => {

    try {

      const response = await createProblemSolutionComment(
        commentText,
        user._id,
        "solution",
        "defensibilityStrategy",
        venture.problemSolution,
        replyTo,
        window.location.href,
        `${pathname.includes("gtm") ? "Value Proposition" : "Solution"
        } > Defensibility Strategy`
      );

      setComments(response.data.comments.solution.defensibilityStrategy);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateComment = async (commentid, commentText) => {

    try {

      const response = await updateProblemSolutionComment(
        commentText,
        user._id,
        commentid,
        venture._id,
        window.location.href,
        `${pathname.includes("gtm") ? "Value Proposition" : "Solution"
        } > Defensibility Strategy`
      );

      setComments(response.data.comments.solution.defensibilityStrategy);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const deleteComment = async commentid => {

    try {

      const response = await deleteProblemSolutionComment(
        "solution",
        "defensibilityStrategy",
        venture.problemSolution,
        commentid,
        user._id,
        window.location.href,
        `${pathname.includes("gtm") ? "Value Proposition" : "Solution"
        } > Defensibility Strategy`
      );

      setComments(response.data.comments.solution.defensibilityStrategy);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  // console.log(details);

  return (
    <ModuleSections
      Collapsecomment={ Collapsecomment }
      comments={ comments }
      addComment={ addComment }
      deleteComment={ deleteComment }
      updateComment={ updateComment }>
      <div className="box-display-style-for-next-and-back-button-with-justify">
        <div className="box-indiv-header-con">
          <IndividualSectionHeaders
            mainheading={
              pathname.includes("gtm") ? "Value Proposition" : "Solution"
            }
            subheading={ "Defensibility Strategy" }
            headingcontent={
              "Discover your strategy to make your product defensible and gain sustained competitive advantage."
            }
            isGtm={ pathname.includes("gtm") ? true : false }
            Dialogopen={ Dialogopen }
            Dialogclose={ Dialogclose }
            dialogbox={ dialogbox }
            collapse_comment_function={ collapse_comment_function }
            content={ content }
            // isGtmChevron={pathname.includes("gtm") ? true : false}
            // isGtmChevron={pathname.includes("gtm") ? "gtmV" : "customer"}
            isGtmChevron={
              pathname.includes("gtm") ?
                "gtmV"
                : pathname.includes("defensibilitystrategy") ?
                  <></>
                  :
                  "customer"
              
            }
            isSaveButton={ true }
            handleSave={ handleSave }
          />
        </div>

        <Grid
          item
          xs={ 12 }
          id={ styles.maingrid }
          onClick={ () => {

            setIsdelete(false);
          
          } }>
          <Grid container columnGap={ 5 } mb={ 1 } mt={ 2 }>
            <Grid item md={ 3 } id={ styles.firstrow }>
              <Typography sx={ { fontWeight: "bold", color: "#393939" } }>
                Defensibility Strategy
              </Typography>
            </Grid>
            <Grid item md={ 8 } id={ styles.firstrow }>
              <Typography sx={ { color: "#5F5E5E", fontSize: "14px" } }>
                How would you employ your chosen strategy to give your project
                an edge over the competition?
              </Typography>
            </Grid>
          </Grid>
          { details.map((detail, index) => {

            return <DefensibilityAddRows
              isdelete={ isdelete }
              detail={ detail }
              index={ index }
              handleChange={ handleChange }
              removeRow={ removeRow }
            />;
          
          }) }
          <Button
            variant="contained"
            sx={ {
              backgroundColor: "#071AFF",
              marginTop      : "10px",
              textTransform  : "none"
            } }
            onClick={ createDialogopen }
            disabled={ venture.userRole === "Commenter" }>
            Add a row
          </Button>

          <Button
            variant="outlined"
            sx={ {
              backgroundColor: "#FFFFFF",
              marginTop      : "10px",
              textTransform  : "none",
              border         : "solid 1px #ff7f5c",
              color          : "#ff7f5c",
              fontWeight     : "bold",
              marginLeft     : "20px"
            } }
            onClick={ e => {

              e.stopPropagation();
              setIsdelete(true);
            
            } }
            disabled={ venture.userRole === "Commenter" || isdelete }>
            <RemoveCircleIcon fontSize="small" sx={ { marginRight: "10px" } } />{ " " }
            Delete Rows
          </Button>
          <Dialog
            open={ createdialogbox }
            PaperProps={ {
              style: {
                borderRadius  : "20px",
                backdropFilter: "blur(45px)",
                background    : "#FFFFFF 0% 0% no-repeat padding-box",
                boxShadow     : "0px 3px 6px #00000029",
                border        : "1px solid #707070"
              }
            } }
            BackdropProps={ { invisible: true } }>
            <Box sx={ { alignSelf: "flex-end" } }>
              <IconButton component="span" onClick={ createDialogclose }>
                <HighlightOffIcon />
              </IconButton>
            </Box>

            <DialogContent>
              <TextField
                size="small"
                label="Enter Name"
                variant="outlined"
                value={ othername }
                onChange={ e => {

                  setOthername(e.target.value.trimStart());
                
                } }
              />
              <Button
                variant="contained"
                size="medium"
                sx={ { backgroundColor: "#071AFF", marginLeft: "10px" } }
                onClick={ createRow }
                disabled={ othername === "" }>
                Create
              </Button>
            </DialogContent>
          </Dialog>
        </Grid>

        <div className="ttribe-back-next-button-con">
          <BackNextButton
            restrictedBackClick={ () => {

              pathname.includes("gtm")
                ? history.push("/gtminanutshell")
                : history.push("/inanutshell");
            
            } }
            backClick={ () => {

              pathname.includes("gtm") && isDataLoading == false
                ? history.push("/gtminanutshell")
                : history.push("/inanutshell");
            
            } }
            restrictedNextClick={ () => {

              if (pathname.includes("gtm") && isDataLoading == false) {

                history.push("/gtmpersonasolutionmap");
              
              } else {

                history.push("/personasolutionmap");
              
              }
            
            } }
            nextClick={ () => {

              handleSubmit();
            
            } }
          />
        </div>
      </div>
    </ModuleSections>
  );

};

export default Defensibility;
