import { CircularProgress, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const LoadingToRedirect = ({ url }) => {

  const [ count, setCount ] = useState(3);
  
  let history = useHistory();

  useEffect(() => {

    const interval = setInterval(() => {

      //
      setCount(currentCount => --currentCount);
    
    }, 1000);
    // redirect once count is equal to 0

    count === 0 && history.push(url);
    // cleanup
    
    return () => clearInterval(interval);
  
  }, [ count, history ]);
  
  return (
    <Grid container justifyContent="center" height="80vh" alignItems="center">
      <CircularProgress size="80px" thickness={ 3 } />
    </Grid>
  );

};

export default LoadingToRedirect;
