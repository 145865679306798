import React from "react";

import {
  Box,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import EyeSlashIcon from "../../../Assets/icons/eye-slash-solid.svg";
import EyeIcon from "../../../Assets/icons/eye-solid.svg";
import styles from "./IndividualNavBox.module.css";

const LightTooltip = styled(({ className, ...props }) =>
  <Tooltip { ...props } classes={ { popper: className } } />
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#393939",

    boxShadow   : theme.shadows[1],
    fontSize    : "11px",
    // color: "#FF7F00",
    // border: "1px solid #FF7F00",
    paddingTop  : "5px 10px",
    borderRadius: "4px",
    // fontWeight: "bold",
    width       : "140px"
  }
}));

export default function IndividualNavBox (props) {

  // const [ anchorEl, setAnchorEl ] = useState(null);

  // const open = Boolean(anchorEl);

  // const handleClick = event => {

  //   setAnchorEl(event.currentTarget);
  
  // };
  // const handleClose = () => {

  //   setAnchorEl(null);
  
  // };

  const publishedImage = props.publishedImages[props.index];

  // ventureName

  // console.log(props.slide);
  const onImgLoad = ({ target: img }) => {

    const { naturalHeight, naturalWidth } = img;
    // console.log("img", naturalHeight, naturalWidth);
    let newPublishedImages = [ ...props.publishedImages ];

    newPublishedImages[props.index] = { ...newPublishedImages[props.index], width: Math.floor(parseInt(naturalWidth) * 0.264583), height: Math.floor(parseInt(naturalHeight) * 0.264583) };
    // newPublishedImages[props.index] = { ...newPublishedImages[props.index], width: naturalWidth, height: naturalHeight }
    props.setPublishedImages(newPublishedImages);
  
  };

  return (
    <Box
      sx={ {
        width : "15vw",
        height: "17vh",
        // border: "1px solid #707070",
        // border:
        //   isRender === box.title
        //     ? "2px solid #071AFF"
        //     : "1px solid #707070",
        border:
          props.isRendername === props.slide.renderName
            ? "2px solid #071AFF"
            : "1px solid #707070",

        marginTop    : "3px",
        marginBottom : "3px",
        display      : "flex",
        // justifyContent: "center",
        // alignItems: "center",
        cursor       : "pointer",
        flexDirection: "column",
        position     : "relative"
      } }
      onClick={ () => {

        props.handleRenderDetails(props.slide.renderName, props.index);
      
      } }
    >
      { publishedImage.isHide &&
        //   {visibility && props.isRender === props.index ? (
        <Box
          id={ styles.visibilityLayoverBox }
        // sx={{
        //   position: "absolute",
        //   top: "0px",
        //   left: "0px",
        //   width: "100%",
        //   height: "100%",
        //   opacity: 0.7,
        //   // backgroundColor: "red",
        //   backgroundColor: "#FFFFFF",
        //   zIndex: 50,
        //   display: "flex",
        //   justifyContent: "center",
        //   alignItems: "center",
        // }}
        >
          <div
            id={ styles.visibilityIcon }
          // sx={{ opacity: 0.7, fontSize: "40px", color: "gray" }}
          />
        </Box>
      }
      { publishedImage.imageUrl ?
        <>
          <img
            onLoad={ onImgLoad }
            src={ publishedImage.imageUrl }
            style={ { width: "100%", height: "100%" } }
          />
          { props.isEdit &&
            <Box
              id={ styles.iconBox }
              // sx={{
              //   // border: 1,
              //   position: "absolute",
              //   right: "5px",
              //   top: "-3px",
              //   height: "18px",
              //   zIndex: 100,
              // }}
              onClick={ e => {

                e.stopPropagation();
                props.handleHideChange(props.index, !publishedImage.isHide);
              
              } }
            >
              <LightTooltip
                placement="top"
                title={
                  publishedImage.isHide
                    ? "Click to show slide in presentation mode"
                    : "Click to hide slide in presentation mode"
                }
              >
                { publishedImage.isHide ?
                  <img
                    src={ EyeSlashIcon }
                    // src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/eye-solid.svg`}
                    alt="checked"
                    style={ { width: "1em", height: "1em" } } />
                  : <img
                    // src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/eye-slash-solid.svg`}
                    src={ EyeIcon }
                    alt="unchecked"
                    style={ { width: "17px", height: "20px" } }
                  /> }
              </LightTooltip>
            </Box>
          }
        </>
        :
        <>
          <Box
            id={ styles.titleBox }
          // sx={{
          //   display: "flex",
          //   justifyContent: "space-between",
          //   // border: 1,
          // }}
          >
            <Box
              sx={ {
                position: "absolute",
                zIndex  : 100,
                top     : "-4px",
                left    : "0px"
              } }
            >
              <Typography
                id={ styles.titleTypo }
              // sx={{
              //   fontSize: "12px",
              //   fontWeight: "bold",
              //   margin: "14px 0px 0px 5px",
              // }}
              >
                { props.slide.name }
              </Typography>
            </Box>
            { props.isEdit &&
              <Box
                id={ styles.iconBox }
                // sx={{
                //   // border: 1,
                //   position: "absolute",
                //   right: "5px",
                //   top: "-3px",
                //   height: "18px",
                //   zIndex: 100,
                // }}
                onClick={ e => {

                  e.stopPropagation();
                  props.handleHideChange(props.index, !props.slide.isHide);
                
                } }
              >

                { publishedImage.isHide ?
                  <img
                    // src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/checked-pitch.png`}
                    src={ EyeSlashIcon }
                    alt="checked"
                    style={ { width: "1em", height: "1em" } } />
                  : <img
                    // src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/unchecked-pitch.png`}
                    src={ EyeIcon }
                    alt="unchecked"
                    style={ { width: "17px", height: "20px" } }
                  /> }

              </Box>
            }
          </Box>
          <Box
            onClick={ () => {

              props.handleRenderDetails(props.slide.renderName, props.index);
            
            } }
            sx={ { height: "100%", width: "100%" } }
          ></Box>
          {/* ----- */ }

          {/* image box */ }
          {/* <Box
        sx={{
          position: "absolute",
          top: "0px",
          right: "0px",
          // width: "100%",
          // height: "100%",
          // zIndex: 10000,
          width: "13vw",
          border: 1,
        }}
      > */}
          <img
            // src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/${props.slide.thumbnailImg}`}
            // src="./images/pitchProblemGrid2.png"
            // src="./images/pitchProblemGrid.png"
            alt=""
            style={ {
              // width: "80%",
              // height: "80%",
              position: "absolute",
              top     : "16px",
              left    : "2px",
              // width: "16.3vw",
              // height: "20vh",
              width   : "14.5vw",
              height  : "14vh"

              // width: "150px",
              // height: "20px",
            } }
            onClick={ () => {

              props.handleRenderDetails(props.slide.renderName, props.index);
            
            } }
          />
          {/* </Box> */ }
          {/* venture name box */ }

          { props.slide.name === "Cover" ?
            <Box
              sx={ {
                position: "absolute",
                zIndex  : 100,
                bottom  : "7.3vh",
                left    : "14px"
              } }
            >
              <Typography sx={ { fontSize: "10px", fontWeight: "bold" } }>
                { props.slide.ventureName }
              </Typography>
            </Box>
            :
            <></>
          }

          { props.slide.coverImg &&
            <Box
              sx={ {
                position: "absolute",
                zIndex  : 100,
                // border: 1,
                width   : "3.8vw",
                height  : "11vh",
                right   : "15px",
                top     : "27px"
              } }
            >
              <img
                alt=""
                src={ props.slide.coverImg }
                style={ {
                  width       : "100%",
                  height      : "100%",
                  borderRadius: "5px",
                  objectFit   : "cover"
                } }
              />
            </Box>
          }
        </>
      }
      {/* ------ */ }
    </Box>
  );

}
