import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box, Button, Divider, Grid, IconButton, InputAdornment, LinearProgress, OutlinedInput, TextField, Tooltip, Typography, DialogTitle, DialogContent, Dialog, CircularProgress
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BoxWithoutIcon from "../../../components/BoxWithoutIcon";
import ErrorMessage from "../../../components/ErrorMessage";
import {
  strengthColor,
  strengthIndicator,
  strengthText
} from "../../../components/PasswordStrength";
import { createUserWithEmailAndPassword, GoogleAuthProvider, getAuth, signInWithPopup, sendEmailVerification, signOut } from "firebase/auth";

// import { signOut } from "firebase/auth"
import {
  createOrUpdateUser,
  UpdateUserDetails,
  userVerified
} from "../../../services/auth";

import styles from "./NewRegister.module.css";

const useStyles = makeStyles(() => ({
  customWidth: {
    maxWidth       : 200,
    fontSize       : 10,
    backgroundColor: "white",
    color          : "#707070",
    boxShadow      : "0px 3px 6px #00000029",
    border         : "1px solid #707070",
    borderRadius   : 10
  }
}));

const NewRegister = () => {

  const auth = getAuth();
  const [ details, setDetails ] = useState({
    email              : "",
    firstname          : "",
    lastname           : "",
    password           : "",
    confirmpassword    : "",
    showPassword       : false,
    showConfirmPassword: false
  });
  const [ errormsg, setErrormsg ] = useState("");
  const [ errorflag, setErrorflag ] = useState(false);
  const [ dialogbox, setDialog ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  let history = useHistory();
  // let location = useLocation();
  let dispatch = useDispatch();

  const classes = useStyles();

  // password strength indicator
  const strength = strengthIndicator(details.password);
  const strengthcolor = strengthColor(strength);
  const strengthtext = strengthText(strength);
  const normalise = strength => ((strength - 1) * 100) / (5 - 1);

  const { user } = useSelector(state => ({ ...state }));

  useEffect(() => {

    if (user) {

      if (user.userVerified && user.token) {

        history.push("/welcomepage");
      
      }
    
    }
  
  }, [ user ]);

  useEffect(() => {

    if (!errorflag) {

      setErrormsg("");
    
    }
  
  }, [ errorflag ]);

  const handleDialog = () => {

    setDialog(!dialogbox);
  
  };

  const handledetailsChange = event => {

    setErrorflag(false);
    const name = event.target.name;
    const value = event.target.value.trimStart();

    setDetails(values => ({ ...values, [name]: value }));
  
  };

  const handleClickShowPassword = () => {

    setDetails({
      ...details,
      showPassword: !details.showPassword
    });
  
  };
  const handleClickShowConfirmPassword = () => {

    setDetails({
      ...details,
      showConfirmPassword: !details.showConfirmPassword
    });
  
  };

  let validator = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
    details.email
  );

  const registerHandleSubmit = async e => {

    // preventing browser from reload
    e.preventDefault();
    setLoading(true);

    // validation
    if (
      details.password === details.confirmpassword &&
      details.firstname !== "" &&
      details.lastname !== "" &&
      details.email !== "" &&
      validator &&
      details.password !== "" &&
      details.confirmpassword !== ""
    ) {

      try {

        const response = await userVerified(details.email);

        if (response.data === "User Verified Already") {

          setLoading(false);
          setErrormsg("An account already exists with this email ID.");
          setErrorflag(true);
          
          return;
        
        }
        await createUserWithEmailAndPassword(auth, details.email, details.password
        );
        try {

          await sendEmailVerification(auth.currentUser).then(() => {

            window.localStorage.setItem("emailForRegistration", details.email);

          }).catch(error => {

            console.error(error);
          
          });

        } catch (error) {

          setLoading(false);
          console.error(error);

        }
        let user = auth.currentUser;
        const idTokenResult = await user?.getIdTokenResult();

        createOrUpdateUser(details).then(() => {

          setLoading(false);

          UpdateUserDetails(details)
            .then(res => {

              dispatch({
                type   : "LOGGED_IN_USER",
                payload: {
                  firstname       : res.data.user.firstname,
                  lastname        : res.data.user.lastname,
                  email           : res.data.user.email,
                  token           : idTokenResult?.token,
                  subscription    : res.data.user.subscription,
                  access          : res.data.user.access,
                  profileIcon     : res.data.profileUrl,
                  userVerified    : res.data.user.userVerified,
                  organizationName: res.data.user.organizationName,
                  location        : res.data.user.location,
                  createdAt       : res.data.user.createdAt,
                  _id             : res.data.user._id
                }
              });
            
            })
            .catch(error => {

              setLoading(false);
              console.error(error);
            
            });
        
        })
          .catch(error => {

            setLoading(false);
            console.error(error);
          
          });

        try {

          setLoading(false);

          await signOut(auth);

          // clear state
          handleDialog();

        } catch (error) {

          setLoading(false);
          console.error(error);

        }

      } catch (error) {

        setLoading(false);
        console.error(error);
        setErrormsg("Unexpected error");
        setErrorflag(true);
      
      }
    
    } else {

      if (
        details.firstname === "" ||
        details.lastname === "" ||
        details.email === "" ||
        details.password === "" ||
        details.confirmpassword === "" ||
        details.location === ""
      ) {

        setLoading(false);
        setErrormsg("Please fill in the required (*) fields.");
      
      } else if (!validator) {

        setLoading(false);
        setErrormsg("Please enter a valid Email ID");
      
      } else if (
        details.password !== details.confirmpassword ||
        details.password === ""
      ) {

        setLoading(false);
        setErrormsg("The two passwords don't match. Please re-enter them");
      
      } else if (
        !details.password.match(
          "(?!^[0-9]*$)(?!^[a-zA-Z]*$)^([a-zA-Z0-9!#@$%^&*)(+=._-]{6,15})$"
        )
      ) {

        setLoading(false);
        setErrormsg("Password does not meet requirements");
      
      }
      setLoading(false);
      setErrorflag(true);
    
    }
  
  };

  const googleLogin = async () => {

    setLoading(true);

    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider).then(async result => {

      const { user } = result;
      const username = user.displayName.split(" ");
      const idTokenResult = await user.getIdTokenResult();

      createOrUpdateUser().then(() => {

        setLoading(false);
        UpdateUserDetails({
          firstname: username[0],
          lastname : username[1]
        }).then(res => {

          dispatch({
            type   : "LOGGED_IN_USER",
            payload: {
              firstname       : res.data.user.firstname,
              lastname        : res.data.user.lastname,
              email           : res.data.user.email,
              token           : idTokenResult.token,
              subscription    : res.data.user.subscription,
              access          : res.data.user.access,
              profileIcon     : res.data.profileUrl,
              userVerified    : res.data.user.userVerified,
              organizationName: res.data.user.organizationName,
              location        : res.data.user.location,
              createdAt       : res.data.user.createdAt,
              _id             : res.data.user._id
            }
          });
        
        })
          .catch(error => {

            //
            console.error(error);
          
          });
        // redirect
        history.push("/welcomepage");
      
      })
        .catch(error => {

          setLoading(false);
          console.error(error);
        
        });
    
    })
      .catch(err => {

        setLoading(false);
        console.error(err);
      
      });
  
  };

  return <>{loading ? <>

    <Grid
      container
      justifyContent="center"
      height="91vh"
      alignItems="center">
      <Grid item>
        <CircularProgress size="60px" thickness={3} />
      </Grid>
    </Grid>

  </> : <>

    <BoxWithoutIcon>
      <Grid
        container
        sx={{
          alignItems    : "center",
          height        : "100%",
          justifyContent: "center"
        }}
      >
        <Grid item md={6} xs={12} id={styles.griditem1}>
          <Box
            id={styles.welcomeTypoBox}
          // sx={{
          //   display: "flex",
          //   justifyContent: "center",
          //   flexDirection: "column",
          //   alignItems: "center",
          //   // border: 1,
          // }}
          >
            <Typography
              gutterBottom
              id={styles.welcometypo}
            // sx={{
            //   color: "#5F5E5E",
            //   fontSize: "20px",
            //   fontWeight: "bold",
            //   marginBottom: "10vh",
            // }}
            >
              Welcome to the
              <span
                style={{
                  color: "#071AFF"
                }}
              >
                {" TODAC Tribe!"}
              </span>
            </Typography>
            <img
              id={styles.img}
              // style={{
              //   width: "420px",
              //   height: "260px",
              //   padding: "20px 0px 20px 20px",
              // }}
              src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/undraw_authentication_re_svpt.svg`}
              alt="login img"
            />
          </Box>
          <Divider
            orientation="vertical"
            variant="middle"
            sx={{
              height     : "320px",
              borderColor: "#dfd7d7"
            }}
          />
        </Grid>

        <Grid item md={5} xs={12} id={styles.griditem2}>
          {/* <Typography gutterBottom id={styles.invitetypo}>
              <span id={styles.highlightedtext}>{ownerName}</span> has invited
              you to their venture project,
              <span id={styles.highlightedtext}>{ventureName}</span>, as
              {access === "Editor" ? "an" : "a"} {access}.
            </Typography> */}
          {errormsg &&
            <ErrorMessage
              errormsg={errormsg}
              padding="5px 10px"
              msgfont="12px"
            />
          }
          <Box
            id={styles.signUpTypoBox}
          // sx={{
          //   // border: 1,
          //   width: "100%",
          //   display: "flex",
          //   justifyContent: "center",
          //   alignItems: "center",
          //   // marginBottom: "20px",
          // }}
          >
            <Typography
              id={styles.signUpTypo}
            // sx={{ fontSize: "22px", color: "#5F5E5E", fontWeight: "bold" }}
            >
              Sign Up!
            </Typography>
          </Box>
          <Box
            id={styles.haveAnAccountTypoBox}
          // sx={{
          //   border: 1,
          //   width: "100%",
          //   display: "flex",
          //   justifyContent: "center",
          //   alignItems: "center",
          //   marginBottom: "10px",
          // }}
          >
            <Typography
              id={styles.haveAnAccountTypo}
            // sx={{ fontSize: "14px", color: "#5F5E5E", fontWeight: "bold" }}
            >
              Already have an account?
            </Typography>
            <Typography
              id={styles.loginTypo}
              // sx={{
              //   fontSize: "14px",
              //   color: "#071AFF",
              //   fontWeight: "bold",
              //   marginLeft: "4px",
              // }}
              onClick={() => {

                history.push("/login");
              
              }}
            >
              Login
            </Typography>
          </Box>
          <Grid container id={styles.gridcontainer}>
            <Grid item md={5}>
              <Typography id={styles.containertypo} gutterBottom>
                Tell us your name*
              </Typography>
              <OutlinedInput
                fullWidth
                error={errormsg && details.firstname === "" ? true : false}
                size="small"
                placeholder="First Name"
                value={details.firstname}
                name="firstname"
                onChange={handledetailsChange}
                sx={{
                  // "& .MuiOutlinedInput-root": {
                  //   padding: "0px 0px",
                  // },
                  "& .MuiInputBase-input": {
                    fontSize: "14px",
                    padding : "5px 10px",
                    border  : "none"
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #E1E2E9"
                  }
                }}
              />
            </Grid>
            <Grid item md={5}>
              {/* <br /> */}
              <Typography id={styles.containertypo2} gutterBottom>
                T
              </Typography>
              <OutlinedInput
                fullWidth
                error={errormsg && details.lastname === "" ? true : false}
                size="small"
                placeholder="Last Name"
                value={details.lastname}
                name="lastname"
                onChange={handledetailsChange}
                sx={{
                  // "& .MuiOutlinedInput-root": {
                  //   padding: "0px 0px",
                  // },
                  "& .MuiInputBase-input": {
                    fontSize: "14px",
                    padding : "5px 10px",
                    border  : "none"
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #E1E2E9"
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid container id={styles.gridcontainer}>
            <Grid item md={11}>
              <Typography
                // id={styles.emailTypo}
                sx={{
                  fontSize    : "12px",
                  fontWeight  : "bold",
                  marginBottom: "3px"
                }}
              >
                Email*
              </Typography>
              <TextField
                error={errormsg && !validator ? true : false}
                fullWidth
                size="small"
                // sx={{
                //   "& .MuiInputBase-input": {
                //     fontSize: "14px",
                //     // padding: "5px 10px 5px 10px",
                //   },
                // }}
                sx={{
                  // "& .MuiOutlinedInput-root": {
                  //   padding: "0px 0px",
                  // },
                  "& .MuiInputBase-input": {
                    fontSize: "14px",
                    padding : "5px 10px",
                    border  : "none"
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #E1E2E9"
                  }
                }}
                value={details.email}
                name="email"
                onChange={handledetailsChange}
              />
            </Grid>
          </Grid>

          <Grid
            container
            id={styles.gridcontainer}
            sx={{ position: "relative" }}
          >
            <Grid item md={5}>
              <Typography id={styles.containertypo} gutterBottom>
                Choose a password*
              </Typography>
              <Tooltip
                disableFocusListener
                title="Password must have at least 6 characters. For a strong password, use upper & lower case letters, numbers, and symbols like !@#$%&*"
                placement="bottom"
                classes={{ tooltip: classes.customWidth }}
              >
                <OutlinedInput
                  error={
                    errormsg &&
                      (details.password === "" ||
                        details.password !== details.confirmpassword)
                      ? true
                      : false
                  }
                  size="small"
                  type={details.showPassword ? "text" : "password"}
                  value={details.password}
                  name="password"
                  onChange={handledetailsChange}
                  sx={{
                    // "& .MuiOutlinedInput-root": {
                    //   padding: "0px 0px",
                    // },
                    "& .MuiInputBase-input": {
                      fontSize: "14px",
                      padding : "5px 10px",
                      border  : "none"
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #E1E2E9"
                    }
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {details.showPassword ?
                          // <VisibilityOff />
                          <Visibility />
                          :
                          <VisibilityOff />
                        }
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Tooltip>
            </Grid>
            <Grid item md={5}>
              <Typography id={styles.containertypo} gutterBottom>
                Re-enter password*
              </Typography>
              <OutlinedInput
                error={
                  errormsg &&
                    (details.confirmpassword === "" ||
                      details.password !== details.confirmpassword)
                    ? true
                    : false
                }
                size="small"
                type={details.showConfirmPassword ? "text" : "password"}
                value={details.confirmpassword}
                name="confirmpassword"
                onChange={handledetailsChange}
                sx={{
                  // "& .MuiOutlinedInput-root": {
                  //   padding: "0px 0px",
                  // },
                  "& .MuiInputBase-input": {
                    fontSize: "14px",
                    padding : "5px 10px",
                    border  : "none"
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #E1E2E9"
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      edge="end"
                    >
                      {details.showConfirmPassword ?
                        // <VisibilityOff />
                        <Visibility />
                        :
                        <VisibilityOff />
                      }
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
            {details.password ?
              <Grid
                id={styles.passStrengthGrid}
              // sx={{
              //   position: "absolute",
              //   left: "68%",
              //   top: "90%",
              //   margin: "10px",
              //   padding: "5px",
              //   width: "130px",
              //   border: "solid 1px #cccccc",
              //   borderRadius: "10px",
              // }}
              >
                <Typography
                  id={styles.strengthTextTypo}
                // sx={{ fontSize: "10px", marginBottom: "10px" }}
                >
                  Password strength : {strengthtext}
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={normalise(strength)}
                  sx={{
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: strengthcolor
                    },
                    borderRadius: "10px"
                  }}
                />
              </Grid>
              :
              <></>
            }
          </Grid>
          <Button
            variant="contained"
            color="primary"
            id={styles.getstartedbtn}
            onClick={registerHandleSubmit}
          >
            Get Started!
          </Button>
          <Box>
            <Box
              id={styles.orBox}
            // sx={{
            //   display: "flex",
            //   justifyContent: "center",
            //   alignItems: "center",
            //   marginTop: "5px",
            //   marginBottom: "5px",
            // }}
            >
              <Typography
                id={styles.orTypo}
              // sx={{ color: "#5F5E5E" }}
              >
                -OR-
              </Typography>
            </Box>
            <Box
              id={styles.googleButtonBox}
            // sx={{
            //   display: "flex",
            //   justifyContent: "center",
            //   alignItems: "center",
            // }}
            >
              <Button
                id={styles.googleButton}
                // sx={{
                //   background: "#f8f9ff",
                //   border: "1px solid #cccccc",
                //   textTransform: "none",
                //   color: "#947070",
                //   fontSize: "12px",
                //   fontWeight: 400,
                //   width: "170px",
                //   // width: "240px",
                //   height: "35px",
                //   borderRadius: "4px",
                // }}
                onClick={googleLogin}
              >
                <img
                  id={styles.googleImg}
                  // style={{
                  //   width: "16px",
                  //   height: "16px",
                  //   margin: "10px",
                  //   marginRight: "10px",
                  // }}
                  src="images\google_icon.png"
                  alt="google img"
                />
                Sign-up with Google
              </Button>
            </Box>
            <Box
              id={styles.troubleLoginBox}
            // sx={{ textAlign: "center", marginTop: "4px" }}
            >
              <Typography sx={{ fontSize: "10px" }}>
                Having trouble logging in?
                <Typography
                  id={styles.contactSupportTypo}
                  // style={{
                  //   color: "#071AFF",
                  //   fontWeight: "bold",
                  //   cursor: "pointer",
                  //   fontSize: "9px",
                  // }}
                  component="a"
                  target="_blank"
                  href="/support"
                >
                  Contact Support
                </Typography>
              </Typography>
            </Box>
          </Box>
          {/* <Box id={styles.mainbox}>
              <Typography gutterBottom id={styles.boxtypo}>
                We take data privacy very seriously and only collect what we
                need to provide you with the best experience possible.
              </Typography>
              <Typography id={styles.boxtypo}>
                By signing up with us you are agreeing to our
                <span id={styles.link}>Terms of Service</span> and
                <span id={styles.link}>Privacy Policy</span>
              </Typography>
            </Box> */}

          <Divider
            id={styles.BottomDivider}
          // sx={{ width: "70%", margin: "10px 0px" }}
          />
          <Box sx={{ textAlign: "center" }}>
            <Typography sx={{ fontSize: "9px" }}>
              By signing up with us you are agreeing to our <br />
              <Typography
                sx={{
                  fontSize      : "9px",
                  color         : "#071AFF",
                  textDecoration: "underline"
                }}
                component="a"
                target="_blank"
                href="https://todactribe.com/terms-and-policies/"
              >
                Terms of Service
              </Typography>
              and
              <Typography
                sx={{
                  fontSize      : "9px",
                  color         : "#071AFF",
                  textDecoration: "underline",
                  marginLeft    : "3px"
                }}
                component="a"
                target="_blank"
                href="https://todactribe.com/terms-and-policies/#1654523572538-b4747bc6-5fb3"
              >
                Privacy Policy
              </Typography>
            </Typography>
          </Box>
        </Grid>

        <Dialog
          onClose={handleDialog}
          open={dialogbox}
          sx={{
            textAlign         : "center",
            "& .MuiPaper-root": { padding: "50px", margin: "auto", alignItems: "center" }
          }}
        >
          <DialogTitle>
            <Typography
              variant="subtitle1"
              gutterBottom
              component="div"
              style={{ fontWeight: "bold", textAlign: "center" }}
            >
              Check Your Inbox
            </Typography>
          </DialogTitle>
          <DialogContent sx={{ width: "35vw", margin: "auto", padding: "12px 0" }} >
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              style={{ width: "250px", height: "100px", margin: "auto" }}
            >
              Let's quickly verify your email ID. Click on the link we sent
              to<br />
              <span style={{ color: "#071AFF", fontWeight: "bold" }}>
                {details.email}
              </span><br />to complete your account setup.
            </Typography>
          </DialogContent>
          <Button
            variant="contained"
            sx={{
              width          : "150px",
              height         : "36px",
              backgroundColor: "#071AFF",
              borderRadius   : "10px"
            }}
            onClick={() => {

              history.push("/login");
            
            }}
          >
            OK
          </Button>
        </Dialog>
      </Grid>
    </BoxWithoutIcon>

  </>}</>

  ;

};

export default NewRegister;
