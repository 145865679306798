import { Box, Card, Grid, Typography } from "@mui/material";
import Slider from "@mui/material/Slider";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

function DialogComponent (props) {

  const history = useHistory();

  let { venture } = useSelector(state => {

    return { ...state };
  
  });
  
  
  const details = props.details;

  return (
    <>
      <Card
        sx={ {
          paddingTop  : "5px",
          margin      : "1vh",
          width       : "100%",
          cursor      : "pointer",
          background  : "#FFFFFF 0% 0% no-repeat padding-box",
          boxShadow   : "0px 0px 6px #00000029",
          borderRadius: "10px",
          marginTop   : props.length > 2 ? "1vh" : "8%"
        } }
        onClick={ () => {

          history.push(`/${props.title.toLowerCase().replace(/[^\w]/g, "")}`);
        
        } }
      >
        <Grid container alignItems="center">
          <Grid item md={ 4 }>
            <img
              alt=""
              style={ {
                objectFit : "scale-down",
                width     : "85px",
                height    : props.length > 3 ? "80px" : "110px",
                marginLeft: "20px"
              } }
              src={ `${process.env.REACT_APP_AWS_ASSETS_URL}${props.imgsrc}` }
            />
          </Grid>
          <Grid
            item
            md={ 8 }
            sx={ { display: "flex", flexDirection: "row", alignItems: "center" } }
          >
            <Box sx={ { width: "100%" } }>
              <Typography
                style={ {
                  fontWeight  : "bold",
                  marginBottom: "10%",
                  fontSize    : "16px"
                } }
              >
                { props.title }
              </Typography>
              <div
                onClick={ e => {

                  e.stopPropagation();
                
                } }
              >
                <Slider
                  key={ props.index }
                  disabled={
                    history.location.pathname.includes("intro") ||
                    venture.userRole === "Commenter"
                  }
                  valueLabelFormat={ value => <div>{ value + "%" }</div> }
                  value={ details ? details[props.sectionname] : 0 }
                  onChangeCommitted={ (e, newValue) => {

                    props.handleChange(props.sectionname, newValue);
                  
                  } }
                  step={ 25 }
                  size="small"
                  valueLabelDisplay="on"
                  sx={ {
                    "& .MuiSlider-valueLabelOpen": {
                      borderRadius   : "5px",
                      color          : "blue",
                      backgroundColor: "#ffffff",
                      boxShadow      : "0px 3px 6px #00000029"
                    }
                  } }
                />
              </div>
            </Box>
            <Typography
              style={ {
                fontWeight : "bold",
                color      : "#071AFF",
                fontSize   : "18px",
                marginRight: "20px"
              } }
            >
              %
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </>
  );

}

export default DialogComponent;
