import React, { useEffect, useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import EventIcon from "@mui/icons-material/Event";
import {
  Box, Table, TableCell, TableContainer, TableHead, TableRow, Typography
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { useSelector } from "react-redux";
import { getOperations } from "../../../../services/operations";
import MilestoneTable from "../../Operations/Milestone/MilestoneTable";
import styles from "./PitchMilestone.module.css";

export default function PitchMilestone() {

  const [ milestones, setMilestones ] = useState([
    {
      name      : "",
      startDate : "",
      endDate   : "",
      status    : "Not Started",
      activities: [
        {
          name     : "",
          startDate: "",
          endDate  : "",
          status   : "Not Started"
        }
      ]
    }
  ]);

  useEffect(() => {

    fetchOperations();
  
  }, []);

  const { venture } = useSelector(state => ({ ...state }));

  const fetchOperations = async () => {

    try {

      const response = await getOperations(venture.operations);

      setMilestones(response.data.milestones);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  return (
    <Box>
      <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
        <Box>
          <Typography
            sx={{ fontSize: "20px", color: "#5F5E5E", fontWeight: "bold" }}
          >
            Operations
          </Typography>
        </Box>

        <ChevronRightIcon sx={{ color: "gray" }} />
        <Box>
          <Typography>Milestones</Typography>
        </Box>
      </Box>

      <Box>
        <Typography sx={{ fontSize: "12px" }}>
          Create an actionable roadmap for your product
        </Typography>
      </Box>

      <Box id={styles.tableBox}>
        <TableContainer>
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none"
              }
            }}
            size="small"
          >
            <TableHead>
              <TableRow id={styles.tableRowHead}>
                <TableCell id={styles.tableCell}>
                  <Typography
                    align="center"
                    id={styles.tableTypographyHead}
                  ></Typography>
                </TableCell>
                <TableCell id={styles.tableCell}>
                  <Typography id={styles.tableTypographyHeadFirst}>
                    Milestone
                  </Typography>
                </TableCell>
                <TableCell align="left" id={styles.tableCell}>
                  <Typography id={styles.tableTypographyHead2}>
                    <EventIcon></EventIcon>
                    Start Date
                  </Typography>
                </TableCell>
                <TableCell align="left" id={styles.tableCell}>
                  <Typography id={styles.tableTypographyHead2}>
                    <EventIcon></EventIcon>
                    End Date
                  </Typography>
                </TableCell>
                <TableCell align="left" id={styles.tableCell}>
                  <Typography id={styles.tableTypographyHead}>
                    Status
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            {milestones.map((milestone, milestoneindex) => {

              return <MilestoneTable
                milestone={milestone}
                milestoneindex={milestoneindex}
              />;
            
            })}
          </Table>
        </TableContainer>

        <Box id={styles.buttonBox}></Box>
        <Box sx={{ height: "3px" }}></Box>
      </Box>
    </Box>
  );

}
