/* eslint-disable camelcase */
import {
  Box, Dialog,
  DialogContent, Grid, IconButton,
  Slider, Tooltip, Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Card_no_collapse from "../../../components/Card";
import { getVetureById, updatePercent } from "../../../services/venture";

import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import Data from "../../../Data/Gtm.json";
import styles from "./GTM.module.css";

const GTMDashboard = ({ history }) => {

  const [ percentvalues, setPercentvalues ] = useState({
    objectives      : 0,
    targetPersonas  : 0,
    channels        : 0,
    valueProposition: 0,
    buyerJourney    : 0,
    positioning     : 0,
    digitalMarketing: 0,
    outcomes        : 0,
    costBudget      : 0
  });
  // const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const [ dialogbox, setDialog ] = useState(false);
  const data1 = Data.GTMdashboard1;
  const data2 = Data.GTMdashboard2;
  const data3 = Data.GTMdashboard3;
  const content = Data.DashboardDialog;

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  // const collapse_comment_function = () => {

  //   if (Collapsecomment) {

  //     setCollapsecomment(false);
    
  //   } else {

  //     setCollapsecomment(true);
    
  //   }
  
  // };

  const { venture } = useSelector(state => ({ ...state }));

  useEffect(() => {

    getVenture();
  
  }, []);

  const getVenture = async () => {

    try {

      const response = await getVetureById(venture._id);
      const Venture = response.data.venture;

      setPercentvalues(Venture.percentValue["gotomarket"]);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateValue = async (newvalue, sectionname) => {

    setPercentvalues(values => ({ ...values, [sectionname]: newvalue }));
    await updatePercent(venture._id, "gotomarket", sectionname, newvalue);
  
  };

  return (
    <div
      style={ {
        alignItems   : "center",
        display      : "flex",
        height       : "83vh",
        marginTop    : "auto",
        marginBottom : "auto",
        verticalAlign: "middle"
      } }
    >
      <Box style={ { margin: "5vh", position: "relative" } }>
        <Card_no_collapse />
      </Box>

      <Grid
        style={ {
          height      : "75vh",
          width       : "-webkit-fill-available",
          paddingRight: "2vw"
        } }
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          style={ {
            position    : "relative",
            height      : "75vh",
            borderRadius: "10px"
          } }
        >
          <Grid item xs={ 12 } id={ styles.dashboardgrid }>
            <Box id={ styles.box2 }>
              <Typography id={ styles.typography1 }>
                Go-To-Market Planning Canvas
              </Typography>
              <Box mr="13%">
                <Tooltip title="View Guide">
                  <img
                    src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/post_add-24px.png` }
                    style={ {
                      marginRight: "1vw",
                      cursor     : "pointer"
                    } }
                    onClick={ Dialogopen }
                  />
                </Tooltip>
                <Dialog
                  open={ dialogbox }
                  PaperProps={ {
                    style: {
                      borderRadius  : "20px",
                      backdropFilter: "blur(45px)",
                      background    : "initial",
                      boxShadow     : "0px 3px 6px #00000029",
                      border        : "1px solid #707070"
                    }
                  } }
                  BackdropProps={ { invisible: true } }
                  fullWidth
                  maxWidth="md"
                >
                  <Box sx={ { alignSelf: "flex-end" } }>
                    <IconButton component="span" onClick={ Dialogclose }>
                      <HighlightOffIcon />
                    </IconButton>
                  </Box>

                  <DialogContent>
                    <Typography fontWeight="900">
                      What are we doing here? Why? What's the Purpose?
                    </Typography>
                    <Typography gutterBottom>
                      { content.dialogpurpose }
                    </Typography>
                    <Typography fontWeight="900">Here's an analogy</Typography>
                    <Typography gutterBottom>
                      { content.dialoganalogy }
                    </Typography>
                    <Typography fontWeight="900">Guide</Typography>
                    <Typography>
                      Before you begin updating the canvas below here are some
                      steps to follow:
                    </Typography>
                    { content.dialogguide.map(guide => {

                      return <Typography >{ guide }</Typography>;
                    
                    }) }
                  </DialogContent>
                </Dialog>
              </Box>
            </Box>
            <Typography variant="body2" id={ styles.typography2 }>
              {/* Right Strategy + Right People + Right Positioning + Right Message
              = Presence & Traction */}
            </Typography>
            <Grid container columnGap={ 5 } mb={ 3 } rowGap={ 3 }>
              { data1.map(d => {

                return (
                  <Grid
                    item
                    md={ d.size }
                    id={ styles.griditem }
                    component="span"
                    onClick={ () => {

                      history.push(
                        `/gtm${d.title.toLowerCase().replace(/[^\w]/g, "")}`
                      );
                    
                    } }
                  >
                    <span id={ styles.gridnumber }>{ d.number }</span>
                    <Typography id={ styles.gridtypography1 }>
                      { d.title }
                    </Typography>
                    <Typography variant="body2" fontSize="10px">
                      { d.description }
                    </Typography>
                    <Box
                      sx={ {
                        display  : "flex",
                        marginTop: d.marginTop
                      } }
                      onClick={ e => {

                        e.stopPropagation();
                      
                      } }
                    >
                      <Slider
                        value={ percentvalues[d.name] }
                        onChangeCommitted={ (e, newValue) => {

                          updateValue(newValue, d.name);
                        
                        } }
                        valueLabelFormat={ value => <div>{ value + "%" }</div> }
                        step={ 25 }
                        size="small"
                        valueLabelDisplay="on"
                        sx={ {
                          "& .MuiSlider-valueLabelOpen": {
                            borderRadius   : "5px",
                            color          : "blue",
                            backgroundColor: "#ffffff",
                            boxShadow      : "0px 3px 6px #00000029"
                          },
                          width: "50%"
                        } }
                        disabled={ venture.userRole === "Commenter" }
                      />
                    </Box>
                    <img
                      src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/${d.image}` }
                      style={ {
                        position: "absolute",
                        width   : "100px",
                        height  : "100px",
                        right   : d.imageright,
                        bottom  : "0"
                      } }
                    />
                  </Grid>
                );
              
              }) }
            </Grid>
            <Grid container columnGap={ 8 } mb={ 3 }>
              { data2.map(d => {

                return (
                  <Grid
                    item
                    md={ d.size }
                    id={ styles.griditem }
                    component="span"
                    onClick={ () => {

                      history.push(
                        `/gtm${d.title.toLowerCase().replace(/[^\w]/g, "")}`
                      );
                    
                    } }
                  >
                    <span id={ styles.gridnumber }>{ d.number }</span>
                    <Typography id={ styles.gridtypography1 }>
                      { d.title }
                    </Typography>
                    <Typography variant="body2" fontSize="10px">
                      { d.description }
                    </Typography>
                    <Box
                      sx={ {
                        display  : "flex",
                        marginTop: d.marginTop
                      } }
                      onClick={ e => {

                        e.stopPropagation();
                      
                      } }
                    >
                      <Slider
                        value={ percentvalues[d.name] }
                        onChangeCommitted={ (e, newValue) => {

                          updateValue(newValue, d.name);
                        
                        } }
                        valueLabelFormat={ value => <div>{ value + "%" }</div> }
                        step={ 25 }
                        size="small"
                        valueLabelDisplay="on"
                        sx={ {
                          "& .MuiSlider-valueLabelOpen": {
                            borderRadius   : "5px",
                            color          : "blue",
                            backgroundColor: "#ffffff",
                            boxShadow      : "0px 3px 6px #00000029"
                          },
                          width: "50%"
                        } }
                        disabled={ venture.userRole === "Commenter" }
                      />
                    </Box>
                    <img
                      src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/${d.image}` }
                      style={ {
                        position: "absolute",
                        width   : "100px",
                        height  : "100px",
                        right   : d.imageright,
                        bottom  : "0"
                      } }
                    />
                  </Grid>
                );
              
              }) }
            </Grid>
            <Grid container columnGap={ 5 } mb={ 3 }>
              { data3.map(d => {

                return (
                  <Grid
                    item
                    md={ d.size }
                    id={ styles.griditem }
                    component="span"
                    onClick={ () => {

                      history.push(
                        `/gtm${d.title.toLowerCase().replace(/[^\w]/g, "")}`
                      );
                    
                    } }
                  >
                    <span id={ styles.gridnumber }>{ d.number }</span>
                    <Typography id={ styles.gridtypography1 }>
                      { d.title }
                    </Typography>
                    <Typography variant="body2" fontSize="10px">
                      { d.description }
                    </Typography>
                    <Box
                      sx={ {
                        display  : "flex",
                        marginTop: d.marginTop
                      } }
                      onClick={ e => {

                        e.stopPropagation();
                      
                      } }
                    >
                      <Slider
                        value={ percentvalues[d.name] }
                        onChangeCommitted={ (e, newValue) => {

                          updateValue(newValue, d.name);
                        
                        } }
                        valueLabelFormat={ value => <div>{ value + "%" }</div> }
                        step={ 25 }
                        size="small"
                        valueLabelDisplay="on"
                        sx={ {
                          "& .MuiSlider-valueLabelOpen": {
                            borderRadius   : "5px",
                            color          : "blue",
                            backgroundColor: "#ffffff",
                            boxShadow      : "0px 3px 6px #00000029"
                          },
                          width: "50%"
                        } }
                        disabled={ venture.userRole === "Commenter" }
                      />
                    </Box>
                    <img
                      src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/${d.image}` }
                      style={ {
                        position: "absolute",
                        width   : "100px",
                        height  : "100px",
                        right   : d.imageright,
                        bottom  : "0"
                      } }
                    />
                  </Grid>
                );
              
              }) }
            </Grid>
          </Grid>
          <Box
            sx={ {
              background    : "#ffffff",
              display       : "flex",
              flexDirection : "column",
              justifyContent: "center",
              alignItems    : "center",
              position      : "absolute",
              top           : " 92%",
              left          : "96%",
              textDecoration: "none"
            } }
            component="a"
            target="_blank"
            href="/support"
          >
            <ChatBubbleOutlineIcon
              sx={ { fontSize: "50px", color: "#071AFF" } }
            />
            <Typography
              noWrap
              sx={ {
                color     : "rgb(97, 92, 92)",
                fontSize  : "10px",
                fontWeight: "bold"
              } }
            >
              Need help?
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );

};

export default GTMDashboard;
