import axios from "./axiosService";

// import axios from "axios";

export const sendFeedbackMail = async details => {

  return await axios.post(`${process.env.REACT_APP_ADDRESS}/send_feedback`, {
    details
  });

};

export const sendSupportMail = async formdata => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/send_support`,
    formdata
  );

};
