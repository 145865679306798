import { Box, Typography } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
// import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Dot from "./DotComponent";

// const useStyles = makeStyles({
//   root: {
//     overflow              : "auto",
//     background            : "#F8F9FF",
//     borderRadius          : "10px",
//     width                 : "max-content",
//     boxShadow             : "0px 0px 6px #00000029",
//     height                : "75vh",
//     opacity               : "1",
//     "&::-webkit-scrollbar": {
//       width: "10px"
//     }
//   },
//   title_text: {
//     fontWeight: "600",
//     color     : "dimgray",
//     fontSize  : "14px",
//     margin    : "8px"
//   },
//   inner_text: {
//     color   : "dimgray",
//     fontSize: "14px"
//   }
// });

function ToggleCardBox (props) {

  const [ collapse, setCollapse ] = useState(true);
  // const classes = useStyles();
  const history = useHistory();
  const data = props.data;
  const pathname = history.location.pathname.substring(1);

  const handleCollapse = () => {

    setCollapse(!collapse);
  
  };

  
  return (
    <>
      <Typography
        onClick={ handleCollapse }
        style={ {
          fontWeight: "600",
          color     : "dimgray",
          margin    : "8px",
          marginLeft: "16px"
        } }
      >
        <IconButton onClick={ handleCollapse } style={ { fontSize: "initial   " } }>
          { collapse ?
            <KeyboardArrowDownIcon
              style={ {
                color     : "blue",
                fontSize  : "160%",
                transform : " rotate(180deg)",
                fontWeight: "600"
              } }
            />
            :
            <KeyboardArrowDownIcon
              style={ {
                color     : "blue",
                fontSize  : "160%",
                fontWeight: "600"
              } }
            />
          }
        </IconButton>
      </Typography>
      <Divider style={ { marginLeft: "10px", marginBottom: "5px" } } />

      <Collapse
        in={ collapse }
        timeout="auto"
        orientation="vertical"
        unmountOnExit
      >
        { data.subtitles.map((subtitle, index) => {

          return (
            <Typography
              style={ {
                color:
                  pathname.includes(
                    subtitle.url.toLowerCase().replace(/[^\w]/g, "")
                  ) && !pathname.includes("gtm")
                    ? "blue"
                    : "#707070",
                marginLeft : "32px",
                marginRight: "8px"
              } }
              noWrap={ true }
            >
              <Box style={ { display: "flex" } }>
                { index === data.subtitles.length - 1 ?
                  pathname.includes(
                    subtitle.url.toLowerCase().replace(/[^\w]/g, "")
                  ) && !pathname.includes("gtm") ?
                    <Dot active={ true } islastsubtitle={ true } />
                    :
                    <Dot islastsubtitle={ true } />
                  
                  : pathname.includes(
                    subtitle.url.toLowerCase().replace(/[^\w]/g, "")
                  ) && !pathname.includes("gtm") ?
                    <Dot active={ true } />
                    :
                    <Dot />
                }
              </Box>
            </Typography>
          );
        
        }) }
      </Collapse>
    </>
  );

}

export default ToggleCardBox;
