import React, { useEffect, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import {
  Box, Dialog,
  DialogContent,
  IconButton, Tooltip,
  Typography
} from "@mui/material";
import { useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import HelpIconTooltip from "../../../../components/HelpIconTooltip";
import Monetisation from "../Monetisation";

import CloseIcon from "@mui/icons-material/Close";
import ErrorMessage from "../../../../components/ErrorMessage";
import { useUnmount } from "../../../../components/useUnmount";
import {
  deleteRevenueStreamImages,
  getMonetisationDetails,
  updateMonetisationDetails
} from "../../../../services/monetisation";
import styles from "./CommercialModel.module.css";
import IndividualRevenueStream from "./IndividualRevenueStream";

export default function CommercialModel() {

  // const [ anchorEl, setAnchorEl ] = useState(null);
  const [ errormsg, setErrormsg ] = useState("");
  const [ revenueStreams, setRevenueStreams ] = useState([]);
  const [ comments, setComments ] = useState([]);
  const [ dialog, setDialog ] = useState(false);
  const [ isDataLoading, setIsDataLoading ] = useState(true);
  let isSubmitting = false;

  const { venture } = useSelector(state => ({ ...state }));
  // console.log(venture.monetisation);
  // console.log(venture.userRole);

  // const history = useHistory();

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  // To get the updated usestate value to save in useEffect cleanup
  const detailsRef = useRef(revenueStreams);

  detailsRef.current = revenueStreams;

  // useUnmount => custom hook to save while navigating
  useUnmount(() => {

    if ( isDataLoading == false && !isSubmitting) {

      handleSave();
    
    }
  
  });

  const handleSave = async () => {

    try {

      if (
        !(
          revenueStreams.findIndex(
            revenueStream => revenueStream.name === ""
          ) >= 0
        )
      ) {

        await updateMonetisationDetails(
          "revenueStreams",
          detailsRef.current,
          venture.monetisation
        );
        
        return true;
      
      } else {

        setErrormsg("Please fill required fields");
      
      }
    
    } catch (error) {

      console.error(error);
      
      return false;
    
    }
  
  };

  useEffect(() => {

    fetchMonetisation();
  
  }, []);

  useEffect(() => {

    setErrormsg("");
  
  }, [ revenueStreams ]);

  const fetchMonetisation = async () => {

    setIsDataLoading(true);

    try {

      const response = await getMonetisationDetails(venture.monetisation);
      // console.log(response);

      setRevenueStreams(response.data.revenueStreams);
      setComments(response.data.comments.commercialModel.revenueStreamNames);

      setIsDataLoading(false);
    
    } catch (error) {

      console.error("error while fetching monetisation", error);
      setIsDataLoading(false);
    
    }
  
  };

  const addRevenueStream = () => {

    let newRevenueStreams = [ ...revenueStreams ];

    newRevenueStreams.push({
      name         : "",
      customerWorth: "",
      pricingModel : {
        selectedPricingModel: [],
        otherPricingModel   : ""
      },
      anchors: {
        priceAnchors: [
          {
            name     : "",
            lowPrice : "",
            highPrice: ""
          },
          {
            name     : "",
            lowPrice : "",
            highPrice: ""
          },
          {
            name     : "",
            lowPrice : "",
            highPrice: ""
          }
        ],
        comparableAnchor: ""
      },
      purchasingDecisionFactors: {
        abilityToPay   : "",
        uniqueness     : "",
        referencePrice : "",
        easeOfSwitching: "",
        otherFactors   : ""
      },
      priceDiscrimination: "",
      costs              : "",
      pricingPlan        : "",
      pricingImage       : "",
      comments           : {
        customerWorth            : [],
        pricingModel             : [],
        anchors                  : [],
        purchasingDecisionFactors: [],
        priceDiscrimination      : [],
        costs                    : [],
        pricingImage             : []
      }
    });

    setRevenueStreams(newRevenueStreams);
  
  };

  // console.log(revenueStreams);

  const removeRevenueStream = async index => {

    try {

      let newRevenueStreams = [ ...revenueStreams ];

      if (newRevenueStreams[index]["pricingImage"]) {

        await deleteRevenueStreamImages(index, venture.monetisation);
      
      }
      let updatedRevenueStreams = newRevenueStreams.filter(
        (revenueStream, revenueStreamIndex) => {

          return revenueStreamIndex !== index;
        
        }
      );

      setRevenueStreams(updatedRevenueStreams);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const handleRevenueStreamChange = (name, value, index) => {

    let newRevenueStream = [ ...revenueStreams ];

    newRevenueStream[index][name] = value;
    setRevenueStreams(newRevenueStream);
  
  };

  const handleSubmit = async () => {

    try {

      if (
        !(
          revenueStreams.findIndex(
            revenueStream => revenueStream.name === ""
          ) >= 0
        )
      ) {

        isSubmitting = true;
        if(isDataLoading == false) {

          await updateMonetisationDetails(
            "revenueStreams",
            revenueStreams,
            venture.monetisation
          );

        }
        isSubmitting = false;
        
        return true;
      
      } else {

        setErrormsg("Please fill required fields");
        
        return false;
      
      }
    
    } catch (error) {

      console.error(error);
      isSubmitting = false;
      
      return false;
    
    }
  
  };

  return  <>
    <Monetisation
      barOne={true}
      mainheading="Monetisation"
      subheading="Commercial Model"
      comments={comments}
      sectionName="commercialModel"
      subSectionName="revenueStreamNames"
      handleSave={handleSave}
      handleSubmit={handleSubmit}
    >
      
      {/* main content box */}
      <Box sx={{}}>
        {/* top typo box */}
        <Box>
          <Box id={styles.topTypoBox}>
            <Typography id={styles.topTypo}>Revenue Streams</Typography>
            <HelpIconTooltip
              placement="top-start"
              tooltipdata="Think of all aspects of your solution you could monetize - based on how a customer would use and experience your solution"
            />
          </Box>
          <Typography id={styles.topSubTypo}>
            A revenue stream is a source from which your venture can earn money.
            This will depend on the types of activities your venture will engage
            in that will create something of value to one or more of your target
            customer segments. If those target customers would be willing to pay
            to own, access, or use that product or service, then you have a
            revenue stream.
          </Typography>
        </Box>
        {/* ------------ */}

        {/* bottom content box */}
        <Box id={styles.bottomContentBox}>
          {/* left box */}
          <Box id={styles.revenueStreamMainBox}>
            <Box>
              <Typography id={styles.revenueStreamTypo}>
                Identify up to 4 revenue streams below
              </Typography>
            </Box>
            {/* revenue streams main box*/}
            <Box sx={{}}>
              {/* revenue streams */}
              {revenueStreams.map((revenueStream, revenueStreamIndex) => {

                return<IndividualRevenueStream
                  removeRevenueStream={removeRevenueStream}
                  revenueStreamIndex={revenueStreamIndex}
                  revenueStream={revenueStream}
                  handleRevenueStreamChange={handleRevenueStreamChange}
                  errormsg={errormsg}
                />;
              
              })}

              {/* ----- */}
            </Box>

            {/* add icon */}

            <Box
              sx={{ display: "flex", marginTop: "10px", alignItems: "center" }}
            >
              <Box
                id={styles.addIconButtonBox}
                sx={{
                  backgroundColor:
                    revenueStreams.length < 4
                      ? venture.userRole === "Commenter"
                        ? "gray"
                        : "#071AFF"
                      : "gray"
                }}
              >
                <Tooltip
                  disableFocusListener
                  title={
                    revenueStreams.length < 4
                      ? "Add Revenue Stream"
                      : "Cannot add more options"
                  }
                  placement="right-start"
                  style={{ top: 3 }}
                >
                  <IconButton
                    onClick={() => {

                      if (revenueStreams.length < 4) {

                        addRevenueStream();
                      
                      }
                    
                    }}
                    disabled={venture.userRole === "Commenter" ? true : false}
                    style={{ padding: "3px" }}
                  >
                    <AddIcon id={styles.addIcon} />
                  </IconButton>
                </Tooltip>
              </Box>
              {errormsg && <ErrorMessage errormsg={errormsg} width={"60%"} />}
            </Box>

            {/* ------ */}
          </Box>
          {/* right box */}
          <Box id={styles.imgTypoMainBox}>
            {/* image box */}
            <Box id={styles.imageBox}>
              <img
                id={styles.img}
                style={{ width: "180px", height: "110px" }}
                src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/monetisationRevenueStreams.svg`}
                alt=""
              />
            </Box>
            {/* example typo box */}
            <Box id={styles.exampleTypoMainBox}>
              <Typography id={styles.exampleTypo}>Example:</Typography>
              <Box>
                <Typography id={styles.exampleContentTypo}>
                  A B2B SaaS venture’s primary revenue source may be
                  subscriptions or software licenses, but they may also earn
                  significant revenue from professional services like custom
                  implementation, premium support etc. They might also want to
                  track revenue streams by channel, such as, D2C sales vs
                  partner-driven sales.
                  <span
                    style={{
                      color     : "#071AFF",
                      fontWeight: "bold",
                      cursor    : "pointer"
                    }}
                    onClick={() => {

                      Dialogopen();
                    
                    }}
                  >
                    View More
                  </span>
                  {/* dialog */}
                  <Dialog
                    open={dialog}
                    onClose={Dialogclose}
                    PaperProps={{
                      style: {
                        borderRadius   : "20px",
                        backdropFilter : "blur(100px)",
                        backgroundColor: "#5F5E5E",
                        boxShadow      : "0px 3px 6px #00000029",
                        border         : "1px solid #707070"
                      }
                    }}
                    maxWidth="xs"
                    fullWidth
                  >
                    <DialogContent sx={{ padding: "10px" }}>
                      <Box
                        sx={{
                          display       : "flex",
                          justifyContent: "space-between"
                        }}
                      >
                        <Typography sx={{ fontSize: "14px", color: "#ffffff" }}>
                          {/* More Revenue Stream Examples */}
                        </Typography>
                        <Box
                          sx={{
                            width          : "21px",
                            height         : "21px",
                            backgroundColor: "#ffffff",
                            display        : "flex",
                            justifyContent : "center",
                            alignItems     : "center",
                            borderRadius   : "50%"
                          }}
                          onClick={Dialogclose}
                        >
                          <CloseIcon sx={{ fontSize: "16px" }} />
                        </Box>
                      </Box>

                      <Box>
                        <Typography sx={{ fontSize: "14px", color: "#ffffff" }}>
                          More Revenue Stream Examples
                        </Typography>
                      </Box>
                      <Box sx={{ marginTop: "20px" }}>
                        <Typography sx={{ fontSize: "14px", color: "#ffffff" }}>
                          1. A content website may consider these as separate
                          revenue streams: reader subscriptions, ads,
                          content-writer subscriptions, editing services, etc.
                        </Typography>
                      </Box>
                      <Box sx={{ marginTop: "20px" }}>
                        <Typography sx={{ fontSize: "14px", color: "#ffffff" }}>
                          2. An edtech firm might have on-demand DIY course
                          content, special live masterclasses, and 1:1 live
                          coaching sessions, each of which could be tracked as a
                          separate revenue stream with different
                          business/pricing models.
                        </Typography>
                      </Box>
                    </DialogContent>
                  </Dialog>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Monetisation>
  </>
  ;

}
