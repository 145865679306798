import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DoneIcon from "@mui/icons-material/Done";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Collapse,
  Grid, IconButton, InputBase, Paper
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import {
  deleteCardById, updateCardById
} from "../../../../services/stickynotes";

const useStyles = makeStyles(theme => ({
  card: {
    padding        : theme.spacing(1, 1, 1, 2),
    margin         : theme.spacing(1),
    wordWrap       : "break-word",
    zIndex         : "-100",
    backgroundColor: props => props.color
    // "&:hover": {
    //   backgroundColor: "#EBECF0",
    // },
  },
  delete: {
    position       : "absolute",
    right          : 0,
    zIndex         : 1000,
    top            : 0,
    backgroundColor: "#EBECF0"
  },
  select: {
    width: "100%"
  },
  button: {
    backgroundColor: "#071AFF",
    marginTop      : theme.spacing(1)
  },
  iconBtn: {
    float: "right"
  }
}));

const StickyNotesCard = ({ task, index, fetchListsCards }) => {

  const [ editable, setEditable ] = useState(false);
  const [ content, setContent ] = useState(task.content);
  const [ color, setColor ] = useState(task.color);
  const [ card, setCard ] = useState(true);
  const [ showDelete, setShowDelete ] = useState(false);
  const [ collapse, setCollapse ] = useState(false);
  const classes = useStyles({ color, editable });

  const colors = [
    "#B8B8FF",
    "#9381ff",
    "#ffd8be",
    "#A5673F",
    "#FECEF1",
    "#8BD7D2"
  ];

  let { venture } = useSelector(state => {

    return { ...state };
  
  });

  return (
    <Draggable
      draggableId={ task._id }
      index={ index }
      isDragDisabled={ venture.userRole === "Commenter" }
    >
      { provided =>
        <div
          { ...provided.draggableProps }
          { ...provided.dragHandleProps }
          ref={ provided.innerRef }
        >
          { card &&
            <Paper
              className={ classes.card }
              onMouseEnter={ () => {

                if (venture.userRole !== "Commenter") setShowDelete(true);
              
              } }
              onMouseLeave={ () => {

                if (venture.userRole !== "Commenter") setShowDelete(false);
              
              } }
              onClick={ () => {

                if (venture.userRole !== "Commenter") setEditable(true);
              
              } }
            >
              { editable ?
                <div>
                  <Collapse
                    in={ collapse }
                    timeout="auto"
                    orientation="vertical"
                    unmountOnExit
                  >
                    <Grid container alignItems="flex-start">
                      { colors.map((individualcolor, index) => {

                        return (
                          <>
                            <Grid
                              key={ index }
                              item
                              md={ 2 }
                              sx={ {
                                backgroundColor: individualcolor,
                                height         : "25px",
                                width          : "25px",
                                display        : "flex",
                                alignItems     : "center",
                                justifyContent : "center"
                              } }
                              onClick={ () => {

                                setColor(individualcolor);
                              
                              } }
                            >
                              { individualcolor === color &&
                                <DoneIcon sx={ { fontSize: "16px" } } />
                              }
                            </Grid>
                          </>
                        );
                      
                      }) }
                    </Grid>
                  </Collapse>
                  { !collapse &&
                    <IconButton
                      onClick={ () => {

                        setCollapse(!collapse);
                      
                      } }
                      className={ classes.iconBtn }
                    >
                      <MoreVertIcon />
                    </IconButton>
                  }
                  <InputBase
                    onChange={ e => {

                      e.preventDefault();
                      setContent(e.target.value);
                    
                    } }
                    multiline
                    fullWidth
                    value={ content }
                    style={ { minHeight: "7px" } }
                    autoFocus
                    onFocus={ e => {

                      const val = e.target.value;

                      e.target.value = "";
                      e.target.value = val;
                    
                    } }
                    onBlur={ async () => {

                      const text = content.trim().replace(/\s+/g, " ");

                      if (text === "") {

                        setContent(task.content);
                        
                        return;
                      
                      }
                      setContent(text);
                      task.content = text;
                      await updateCardById(
                        { content: text, color: color },
                        task._id
                      );
                      setShowDelete(false);
                      fetchListsCards();
                      setCollapse(!collapse);
                      setEditable(false);
                    
                    } }
                  />
                </div>
                :
                <div style={ { position: "relative" } }>
                  <div>{ task.content }</div>
                  { showDelete &&
                    <IconButton
                      className={ classes.delete }
                      size="small"
                      onClick={ async () => {

                        setCard(false);
                        await deleteCardById(task._id);
                      
                      } }
                    >
                      <DeleteForeverIcon
                        fontSize="small"
                        style={ { backgroundColor: "#EBECF0" } }
                      />
                    </IconButton>
                  }
                </div>
              }
            </Paper>
          }
        </div>
      }
    </Draggable>
  );

};

export default StickyNotesCard;

{
  /**/
}
