import moment from "moment";
import React from "react";
import ReactApexChart from "react-apexcharts";

const ApexGanttChart = props => {

  // const dynamicWidth = props.series.length * 100;
  // const chartWidth = dynamicWidth < window.innerWidth ? '100%' : dynamicWidth;

  const convertStringToArray = str => {

    let strArray = [ "Milestone:", ...str.toString().split(" ") ];
    let count = 1;
    let newS = "";
    let newStrArray = [];

    strArray.forEach((s, sIndex) => {

      if (sIndex === strArray.length - 1) {

        newS = newS + `${s} `;
        newStrArray.push(newS);
      
      } else if (count <= 1) {

        newS = newS + `${s} `;
        count += 1;
      
      } else {

        newStrArray.push(newS);
        count = 1;
        newS = "" + `${s} `;
      
      }
    
    });
    // let modifiedStr = newStrArray.map((str, strIndex) => {
    //     if (strIndex === 0) {
    //         return `Milestone: ${str}`
    //     } else {
    //         return str
    //     }
    // })
    
    return newStrArray;
  
  };

  let options = {
    chart: {
      type   : "rangeBar",
      // width: chartWidth,
      toolbar: {
        show : true,
        tools: {
          download   : true,
          selection  : true,
          zoom       : true,
          zoomin     : true,
          zoomout    : true,
          pan        : true,
          reset      : true,
          customIcons: []
        },
        autoSelected: "pan"
      }
      // selection: {
      //     enabled: true
      // }
    },
    colors: [
      function ( { seriesIndex } ) {

        if (props.series[seriesIndex]["status"] === "Not Started") {

          return "rgba(95, 175, 255, 1)";
      
        } else if (props.series[seriesIndex]["status"] === "In-Progress") {

          return "rgba(255, 140, 36, 1)";
      
        } else if (props.series[seriesIndex]["status"] === "Completed") {

          return "rgba(8, 145, 11, 1)";
      
        } else {

          return "rgba(147, 129, 255, 1)";
      
        }
    
      }
    ],
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight : "90%"
        // dataLabels: {
        //     position: 'center',
        //     maxItems: 1000,
        //     hideOverflowingLabels: true,
        //     orientation: "horizontal"
        // }

      }
    },
    dataLabels: {
      enabled   : true,
      textAnchor: "middle",
      offsetX   : 0,
      offsetY   : -3,
      formatter : function (val, opts) {

        // var a = moment(val[0])
        // var b = moment(val[1])
        // var diff = b.diff(a, 'days')
        // return diff + (diff > 1 ? ' days' : ' day')
        return props.series[opts.seriesIndex]["name"];
      
      }
    },
    xaxis: {
      type: "datetime"
    },
    yaxis: {
      labels: {
        show     : true,
        align    : "right",
        // minWidth: 0,
        // maxWidth: 160,
        // offsetX: 0,
        // offsetY: 0,
        // rotate: 0,
        formatter: val => { return convertStringToArray(val); }
      }
    },
    stroke: {
      width: 1
    },
    fill: {
      type   : "solid",
      opacity: 0.9
    },
    legend: {
      show: false
    },
    // annotations: {
    //     position: 'front',
    //     xaxis: props.milestoneRanges
    // },
    tooltip: {
      custom: function ({ seriesIndex }) {

        let a = moment(props.series[seriesIndex]["data"][0]["y"][0]);
        let b = moment(props.series[seriesIndex]["data"][0]["y"][1]);
        let diff = b.diff(a, "days");

        
        return props.series[seriesIndex]["isMilestone"] ? "<div style=\"padding: 10px;\"}}>" +
                    "<p style=\"margin: 1px 0px;\"><span style=\"font-weight: bold;\">Milestone</span>: " + props.series[seriesIndex]["name"] + "</p>" +
                    "<p style=\"margin: 1px 0px;\"><span style=\"font-weight: bold;\">Duration</span>: " + moment(props.series[seriesIndex]["data"][0]["y"][0]).format("MMM D") + "-" + moment(props.series[seriesIndex]["data"][0]["y"][1]).format("D") + " (" + diff + (diff > 1 ? " days" : " day") + ")" + "</p>" +
                    "<p style=\"margin: 1px 0px;\"><span style=\"font-weight: bold;\" />Status</span>: " + props.series[seriesIndex]["status"] + "</p>" +
                    "</div>" : "<div style=\"padding: 10px;\"}}>" +
                        "<p style=\"margin: 1px 0px;\"><span style=\"font-weight: bold;\">Activity</span>: " + props.series[seriesIndex]["name"] + "</p>" +
                        "<p style=\"margin: 1px 0px;\"><span style=\"font-weight: bold;\">Duration</span>: " + moment(props.series[seriesIndex]["data"][0]["y"][0]).format("MMM D") + "-" + moment(props.series[seriesIndex]["data"][0]["y"][1]).format("D") + " (" + diff + (diff > 1 ? " days" : " day") + ")" + "</p>" +
                        "<p style=\"margin: 1px 0px;\"><span style=\"font-weight: bold;\" />Status</span>: " + props.series[seriesIndex]["status"] + "</p>" +
                        "<p style=\"margin: 1px 0px;font-weight: bold\">(Part of Milestone: " + props.series[seriesIndex]["data"][0]["x"] + ")</p>" +
                        "</div>";
      
      }
    }
  };

  return (
    <ReactApexChart options={ options } series={ props.series } type="rangeBar" height={ "400px" } />
  );

};

export default ApexGanttChart;
