import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Box, Button, LinearProgress, Typography
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
import ErrorMessage from "../../../../components/ErrorMessage";
import PitchPdfPageView from "./PitchPdfPageView";

export default function UploadPitchDeck (props) {

  const {
    // uploadedPdf,
    // setUploadedPdf,
    pdfPages,
    pdfPageNumber,
    setPdfPageNumber,
    setPdfPages,
    fetchUploadedPdf,
    uploadedPitchName,
    uploadedPitchUrl,
    uploadPitchSuccess,
    setUploadPitchSuccess
  } = props;

  const [ loading, setLoading ] = useState(false);
  const [ isFileTooLarge, setIsFileTooLarge ] = useState(false);
  const [ progress, setProgress ] = useState(0);
  // const [ successmsg, setSuccessmsg ] = useState("");
  const [ errormsg, setErrormsg ] = useState("");

  const { venture } = useSelector(state => ({ ...state }));


  const onDrop = async (acceptedFiles, rejectionFiles) => {

    setLoading(true);
    try {

      if (rejectionFiles.length > 0 && rejectionFiles[0].file.size > 52428800) {

        setIsFileTooLarge(true);
        setLoading(false);
        setTimeout(() => {

          setIsFileTooLarge(false);
        
        }, 5000);
        
        return;
      
      }

      const formdata = new FormData();

      formdata.append("pitch", acceptedFiles[0]);
      formdata.append("ventureid", venture._id);

      const response = await axios.post(
        `${process.env.REACT_APP_ADDRESS}/upload_pitch`,
        formdata,
        {
          onUploadProgress: ProgressEvent => {

            const { loaded, total } = ProgressEvent;
            const percentage = Math.floor(
              ((loaded / 1000) * 100) / (total / 1000)
            );

            setProgress(percentage);
          
          }
        }
      );

      // setSuccessmsg(response.data);
      setUploadPitchSuccess(response.data);

      fetchUploadedPdf();

      setLoading(false);
    
    } catch (error) {

      setErrormsg("File type not accepted");
      setLoading(false);
      setTimeout(() => {

        setErrormsg("");
      
      }, 5000);
    
    }
  
  };

  return (
    <Box
      sx={ {
        width         : "100%",
        height        : "72vh",
        // border: 1,
        display       : "flex",
        justifyContent: "center",
        alignItems    : "center"
      } }
    >
      { uploadedPitchUrl ?
        <PitchPdfPageView
          setPdfPages={ setPdfPages }
          setPdfPageNumber={ setPdfPageNumber }
          pdfPages={ pdfPages }
          pdfPageNumber={ pdfPageNumber }
          uploadedPitchName={ uploadedPitchName }
          uploadedPitchUrl={ uploadedPitchUrl }
        />
        :
        <Box
          sx={ {
            display       : "flex",
            justifyContent: "center",
            alignItems    : "center",
            height        : "71vh",
            flexDirection : "column",
            // border: 1,
            width         : "100%"
          } }
        >
          <Dropzone
            onDrop={ onDrop }
            minSize={ 0 }
            maxSize={ 52428800 }
            accept=".pdf,application/pdf"
          >
            { ({
              getRootProps,
              getInputProps,
              isDragActive,
              isDragReject
              // fileRejections
            }) => {

              return (
                <Box
                  sx={ {
                    width         : "50%",
                    border        : "2px dotted #071AFF",
                    height        : "30vh",
                    borderRadius  : "8px",
                    display       : "flex",
                    justifyContent: "center",
                    alignItems    : "center",
                    flexDirection : "column"
                  } }
                  { ...getRootProps() }
                >
                  {/* {!isDragActive ? (
                    successmsg ? (
                      <CloudUploadIcon />
                    ) : uploadedPitchUrl ? (
                      <></>
                    ) : (
                      <CloudUploadIcon />
                    )
                  ) : (
                    <></>
                  )} */}
                  <Box>
                    <Box>
                      { uploadPitchSuccess ?
                        // {successmsg && uploadedPitchUrl ? (
                        <>
                          <CheckCircleOutlineIcon
                            sx={ { color: "#108E00", marginLeft: "40%" } }
                            fontSize="large"
                          />
                          <Box sx={ { textAlign: "center" } }>
                            <Typography sx={ { fontSize: "12px" } }>
                              { uploadPitchSuccess }
                            </Typography>
                          </Box>
                        </>
                        :
                        <>
                          { " " }
                          <Typography>
                            { isDragActive ? "" : "Drop your Pitch Deck here" }
                          </Typography>
                        </>
                      }

                      { isDragActive && !isDragReject &&
                        <Box sx={ { textAlign: "center" } }>
                          <Typography>Drop Here</Typography>
                        </Box>
                      }
                      { isDragReject &&
                        <ErrorMessage errormsg={ "File type not accepted" } />
                      }
                      { loading && !isFileTooLarge && !errormsg ?
                        // uploadedPitchUrl && (
                        <LinearProgress
                          variant="determinate"
                          value={ progress }
                        />
                        : errormsg ?
                          <ErrorMessage errormsg={ "File type not accepted" } />
                          : isFileTooLarge ?
                            <ErrorMessage
                              errormsg={
                                "File is too large. Upload a file under 50mb size "
                              }
                            />
                            :
                            <></>
                      }
                    </Box>
                    { loading && uploadPitchSuccess && uploadedPitchUrl ?
                      <></>
                      :
                      <Box
                        sx={ {
                          display      : "flex",
                          flexDirection: "column",
                          alignItems   : "center"
                        } }
                      >
                        <Box sx={ { marginTop: "20px" } }>
                          <Button
                            variant="contained"
                            style={ {
                              fontSize     : "14px",
                              textTransform: "none",
                              paddingTop   : "none",
                              paddingRight : "25px",
                              paddingLeft  : "25px"
                            } }
                          >
                            Upload .pdf
                          </Button>
                        </Box>

                        <Box sx={ { marginTop: "7px" } }>
                          <Typography
                            sx={ { fontSize: "12px", color: "#707070" } }
                          >
                            supports only .pdf, max size 50 MB
                          </Typography>
                        </Box>
                      </Box>
                    }
                  </Box>
                  <input { ...getInputProps() } />
                </Box>
              );
            
            } }
          </Dropzone>
        </Box>
      }
    </Box>
  );

}
