import React, { useEffect, useRef, useState } from "react";
// import { useHistory } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import {
  Box, Divider, IconButton, Tooltip,
  Typography
} from "@mui/material";
import Monetisation from "../../Monetisation";
import IndividualPartnershipDetails from "./IndividualPartnershipDetails";

import { useSelector } from "react-redux";
import { useUnmount } from "../../../../../components/useUnmount";
import {
  getMonetisationDetails,
  updateChannelPartnershipDetails
} from "../../../../../services/monetisation";
import styles from "./PartnershipDetails.module.css";

export default function PartnershipDetails() {

  const [ partnershipDetails, setPartnershipDetails ] = useState([]);
  const [ comments, setComments ] = useState([]);

  let isSubmitting = false;

  const { venture } = useSelector(state => ({ ...state }));
  // console.log(venture.monetisation);

  // To get the updated usestate value to save in useEffect cleanup
  const detailsRef = useRef(partnershipDetails);

  detailsRef.current = partnershipDetails;

  // useUnmount => custom hook to save while navigating
  useUnmount(() => {

    if (!isSubmitting) {

      handleSave();
    
    }
  
  });

  const handleSave = async () => {

    try {

      await updateChannelPartnershipDetails(
        detailsRef.current,
        venture.monetisation
      );
      
      return true;
    
    } catch (error) {

      console.error(error);
      
      return false;
    
    }
  
  };

  useEffect(() => {

    fetchMonetisation();
  
  }, []);

  const fetchMonetisation = async () => {

    try {

      const response = await getMonetisationDetails(venture.monetisation);
      // console.log(response.data.channels.partnershipDetails);

      setPartnershipDetails(response.data.channels.partnershipDetails);
      setComments(response.data.comments.commercialModel.partnershipDetails);
    
    } catch (error) {

      console.error("error while fetching monetisation", error);
    
    }
  
  };

  // console.log(partnershipDetails);

  const addPartnershipDetails = () => {

    let newPartnershipDetails = [ ...partnershipDetails ];

    newPartnershipDetails.push({
      businessModelConsiderations: "",
      givesAndTakes              : "",
      partnerName                : "",
      partnershipModel           : ""
    });

    setPartnershipDetails(newPartnershipDetails);
  
  };

  const removePartnershipDetails = index => {

    let newPartnershipDetails = [ ...partnershipDetails ];

    let updatedPartnershipDetails = newPartnershipDetails.filter(
      
      (partnership, partnershipIndex) => {

        return partnershipIndex !== index;
      
      }
    );
    // console.log(updatedPartnershipDetails);

    setPartnershipDetails(updatedPartnershipDetails);
  
  };

  const handlePartnershipDetailsChange = (name, value, index) => {

    let newPartnershipDetails = [ ...partnershipDetails ];

    newPartnershipDetails[index][name] = value;
    // console.log(name, value, index);
    setPartnershipDetails(newPartnershipDetails);
  
  };

  const handleSubmit = async () => {

    try {

      isSubmitting = true;
      await updateChannelPartnershipDetails(
        partnershipDetails,
        venture.monetisation
      );
      isSubmitting = false;
    
    } catch (error) {

      isSubmitting = false;
      console.error(error);
    
    }
  
  };

  // const history = useHistory();

  return (
    <Monetisation
      barThree={true}
      mainheading="Monetisation"
      subheading="Commercial Model"
      comments={comments}
      sectionName="commercialModel"
      subSectionName="partnershipDetails"
      handleSave={handleSave}
      handleSubmit={handleSubmit}
    >
      <Box>
        <Box>
          <Typography id={styles.partnershipTypo}>
            Partnership details
          </Typography>
        </Box>

        {/* typo img flex */}
        <Box id={styles.flexImgTypoBox}>
          <Box id={styles.topTypoBox}>
            <Typography id={styles.topTypo}>
              In the previous screen, you selected ‘Channel partners’ as a way
              your end-customers can acquire your product/service. Selling with
              or through partners may require a different commercial model,
              compared to directly reaching your customers. List out key
              partners you plan to work with, and make notes regarding the type
              of licensing model, pricing levels/discounting, or other factors
              that you will need to consider for your business model.
            </Typography>
          </Box>
          <Box id={styles.imgBox}>
            <Box id={styles.imgSubBox}>
              <img
                id={styles.img}
                src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/monetisationPartnershipDetails.svg`}
                alt=""
              />
            </Box>
          </Box>
        </Box>

        {/* table main box */}
        <Box id={styles.tableMainBox}>
          {/* head box */}
          <Box id={styles.tableHeadBox}>
            <Box id={styles.tableHeadTypoBox}>
              <Typography id={styles.tableHeadTypo}>Partner Name</Typography>
            </Box>
            <Box id={styles.tableHeadTypoBox}>
              <Typography id={styles.tableHeadTypo}>
                Partnership Model
              </Typography>
            </Box>
            <Box id={styles.tableHeadTypoBox}>
              <Typography id={styles.tableHeadTypo}>Gives & Takes</Typography>
            </Box>
            <Box id={styles.tableHeadTypoBox}>
              <Typography id={styles.tableHeadTypo}>
                Business Model <br /> Considerations
              </Typography>
            </Box>
          </Box>

          {/* ------- */}
          <Box id={styles.dividerBox}>
            <Divider id={styles.divider} />
          </Box>

          {/* table row main box */}
          <Box>
            {partnershipDetails.map((partnership, partnershipIndex) => {

              return<IndividualPartnershipDetails
                removePartnershipDetails={removePartnershipDetails}
                partnership={partnership}
                partnershipIndex={partnershipIndex}
                handlePartnershipDetailsChange={
                  handlePartnershipDetailsChange
                }
              />;
            
            })}
          </Box>

          {/* add button */}
          <Box
            id={styles.addButtonBox}
            sx={{
              backgroundColor:
                partnershipDetails.length < 15
                  ? venture.userRole === "Commenter"
                    ? "gray"
                    : "#071AFF"
                  : "gray"
            }}
          >
            <Tooltip
              title={
                partnershipDetails.length < 15
                  ? "Add another row"
                  : "Cannot add more options"
              }
              placement="right"
            >
              <IconButton
                onClick={() => {

                  if (partnershipDetails.length < 15) {

                    addPartnershipDetails();
                  
                  }
                
                }}
                disabled={venture.userRole === "Commenter" ? true : false}
                style={{ padding: "0px" }}
              >
                <AddIcon id={styles.addIcon} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>

      {/* <Box id={styles.backNextBox}>
        
      </Box> */}
    </Monetisation>
  );

}
