import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box, IconButton, Menu, MenuItem, TextField
} from "@mui/material";
import { useSelector } from "react-redux";
import styles from "./IndividualRevenueStream.module.css";

export default function IndividualRevenueStream(props) {

  const [ anchorEl, setAnchorEl ] = useState(null);

  const { venture } = useSelector(state => ({ ...state }));

  const open = Boolean(anchorEl);

  const handleClick = event => {

    setAnchorEl(event.currentTarget);
  
  };
  const handleClose = () => {

    setAnchorEl(null);
  
  };

  
  return (
    <Box id={styles.individualRevenueBox}>
      {/* textbox */}
      <Box id={styles.textFieldBox}>
        <TextField
          onChange={e => {

            props.handleRevenueStreamChange(
              "name",
              e.target.value,
              props.revenueStreamIndex
            );
          
          }}
          error={
            props.errormsg && props.revenueStream.name === "" ? true : false
          }
          disabled={venture.userRole === "Commenter" ? true : false}
          value={props.revenueStream.name}
          fullWidth
          placeholder="Eg. Subscriptions"
          sx={{
            "& .MuiInputBase-input": {
              fontSize: "12px",
              padding : "7px 10px",
              border  : "none",
              color   : "#707070"
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #B4B4FA"
            }
          }}
        />
      </Box>
      {/* more vert icon */}
      <Box sx={{}}>
        <IconButton
          onClick={e => {

            handleClick(e);
          
          }}
          disabled={venture.userRole === "Commenter" ? true : false}
          style={{ padding: "0px" }}
        >
          <MoreVertIcon
            sx={{
              color: venture.userRole === "Commenter" ? "#B8B8B8" : "gray"
            }}
          />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={()=> {

            handleClose();
          
          }}
          style={{ top: -4 }}
          anchorOrigin={{
            vertical  : "top",
            horizontal: "right"
          }}
          sx={{
            "& .MuiMenu-list": {
              padding        : "0px",
              backgroundColor: "#707070",
              color          : "white"
            }
          }}
        >
          <MenuItem
            onClick={e => {

              e.stopPropagation();

              props.removeRevenueStream(props.revenueStreamIndex);
              handleClose();
            
            }}
            sx={{ fontSize: "12px" }}
          >
            Delete
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );

}
