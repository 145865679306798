import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box, IconButton, Menu, MenuItem, TextField, Tooltip
} from "@mui/material";
import { useSelector } from "react-redux";
import styles from "./IndividualPartnershipDetails.module.css";
// import { makeStyles } from "@mui/styles";

// const useStyles = makeStyles(() => ({
//   customWidth: {
//     maxWidth       : "200px",
//     fontSize       : 12,
//     // backgroundColor: "white",
//     // color: "#707070",
//     backgroundColor: "#707070",
//     color          : "white",
//     boxShadow      : "0px 3px 6px #00000029",
//     border         : "1px solid #707070",
//     borderRadius   : 10,
//     whiteSpace     : "pre-wrap"
//   },
//   helpicon: {
//     color   : "#071AFF",
//     fontSize: "15px"
//   }
// }));

// const classes = useStyles({ width: "200px" });

export default function IndividualPartnershipDetails(props) {

  const [ anchorEl, setAnchorEl ] = useState(null);

  const { venture } = useSelector(state => ({ ...state }));

  const open = Boolean(anchorEl);

  const handleClick = event => {

    setAnchorEl(event.currentTarget);
  
  };
  const handleClose = () => {

    setAnchorEl(null);
  
  };

  return (
    <Box id={styles.IndividualPartnershipMainBox}>
      <Box id={styles.IndividualPartnershipSubBox}>
        <Box id={styles.partnershipTextFieldBox}>
          <Tooltip
            enterTouchDelay={ 0 }
            disableFocusListener
            title={props.partnership.partnerName}
            placement={"top-start"}
          >
            <TextField
              value={props.partnership.partnerName}
              onChange={e => {

                props.handlePartnershipDetailsChange(
                  "partnerName",
                  e.target.value,
                  props.partnershipIndex
                );
            
              }}
              disabled={venture.userRole === "Commenter" ? true : false}
              fullWidth
              sx={{
              // "& .MuiOutlinedInput-root": {
              //   padding: "0px 0px",
              // },
                "& .MuiInputBase-input": {
                  fontSize: "12px",
                  padding : "12px 10px",
                  border  : "none",
                  color   : "#707070"
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none"
                //   border: "1px solid #B4B4FA",
                }
              }}
            />
          </Tooltip>
        </Box>
        <Box id={styles.partnershipTextFieldBox}>
          <Tooltip
            enterTouchDelay={ 0 }
            disableFocusListener
            title={props.partnership.partnershipModel}
            placement={"top-start"}
          >
            <TextField
              value={props.partnership.partnershipModel}
              onChange={e => {

                props.handlePartnershipDetailsChange(
                  "partnershipModel",
                  e.target.value,
                  props.partnershipIndex
                );
            
              }}
              disabled={venture.userRole === "Commenter" ? true : false}
              fullWidth
              sx={{
              // "& .MuiOutlinedInput-root": {
              //   padding: "0px 0px",
              // },
                "& .MuiInputBase-input": {
                  fontSize: "12px",
                  padding : "12px 10px",
                  border  : "none",
                  color   : "#707070"
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none"
                //   border: "1px solid #B4B4FA",
                }
              }}
            />
          </Tooltip>
        </Box>
        <Box id={styles.partnershipTextFieldBox}>
          <Tooltip
            enterTouchDelay={ 0 }
            disableFocusListener
            title={props.partnership.givesAndTakes}
            placement={"top-start"}
          >
            <TextField
              value={props.partnership.givesAndTakes}
              onChange={e => {

                props.handlePartnershipDetailsChange(
                  "givesAndTakes",
                  e.target.value,
                  props.partnershipIndex
                );
            
              }}
              disabled={venture.userRole === "Commenter" ? true : false}
              fullWidth
              sx={{
              // "& .MuiOutlinedInput-root": {
              //   padding: "0px 0px",
              // },
                "& .MuiInputBase-input": {
                  fontSize: "12px",
                  padding : "12px 10px",
                  border  : "none",
                  color   : "#707070"
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none"
                //   border: "1px solid #B4B4FA",
                }
              }}
            />
          </Tooltip>
        </Box>
        <Box id={styles.endTextFieldBox}>
          <Tooltip
            enterTouchDelay={ 0 }
            disableFocusListener
            title={props.partnership.businessModelConsiderations}
            placement={"top-start"}
          >
            <TextField
              value={props.partnership.businessModelConsiderations}
              onChange={e => {

                props.handlePartnershipDetailsChange(
                  "businessModelConsiderations",
                  e.target.value,
                  props.partnershipIndex
                );
            
              }}
              disabled={venture.userRole === "Commenter" ? true : false}
              fullWidth
              sx={{
              // "& .MuiOutlinedInput-root": {
              //   padding: "0px 0px",
              // },
                "& .MuiInputBase-input": {
                  fontSize: "12px",
                  padding : "12px 0px 12px 10px",
                  border  : "none",
                  color   : "#707070"
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none"
                // border: "1px solid #B4B4FA",
                }
              }}
            />
          </Tooltip>

          {/* more vert icon */}
          <Box>
            <IconButton
              onClick={e => {

                handleClick(e);
              
              }}
              disabled={venture.userRole === "Commenter" ? true : false}
              style={{ padding: "0px" }}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={() => {

                handleClose();
              
              }}
              style={{ top: -36, left: -10 }}
              anchorOrigin={{
                vertical  : "top",
                horizontal: "right"
              }}
              sx={{
                "& .MuiMenu-list": {
                  padding        : "2px 7px",
                  backgroundColor: "#707070",
                  color          : "white"
                }
              }}
            >
              <MenuItem
                onClick={e => {

                  e.stopPropagation();

                  props.removePartnershipDetails(props.partnershipIndex);
                  handleClose();
                
                }}
                sx={{ fontSize: "12px", padding: "3px" }}
              >
                Delete Row
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Box>
    </Box>
  );

}
