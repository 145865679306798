import React, { useEffect, useState } from "react";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box, Button, Grid,
  Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import CustomMultilineTextfield from "../../../../components/CustomMultilineTextfield";
import HelpIconTooltip from "../../../../components/HelpIconTooltip";
import jsondata from "../../../../Data/Market.json";
import { getMarketdetails } from "../../../../services/market";
import TitleColumn from "../../Market/MarketCompetition/TitleColumn";
import styles from "./PitchMilestone.module.css";

export default function PitchCompAdvMarket() {

  const [ details, setDetails ] = useState({
    marketPresence: {
      productOfferedToday: [],
      marketReach        : [],
      channels           : [],
      digitalPresence    : [],
      otherMarketPlayers : []
    },
    solutionOfferings: {
      technologyPlatform: [],
      uspValue          : [],
      customersLike     : [],
      customersNotLike  : []
    },
    businessHealth: {
      businessModel               : [],
      pricing                     : [],
      businessGoals               : [],
      historicalGrowth            : [],
      annualRevnues               : [],
      funding                     : [],
      subscribersBaseVsActiveUsage: [],
      growthStrategy              : []
    },
    competitiveThreat: {
      chances     : [],
      acquisitions: [],
      niches      : [],
      weaknesses  : [],
      predictions : []
    }
  });

  const [ marketRender, setMarketRender ] = useState(0);

  const { venture } = useSelector(state => ({ ...state }));

  const data = jsondata.marketcompetition;
  const content = data[marketRender];
  //   const content = data[2];

  useEffect(() => {

    fetchMarketdetails();
  
  }, []);

  const fetchMarketdetails = async () => {

    try {

      const response = await getMarketdetails(venture.market);
      const market = response.data.market;

      setDetails(market.competition);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  
  return (
    <Box>
      <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
        <Box>
          <Typography
            sx={{ fontSize: "20px", color: "#5F5E5E", fontWeight: "bold" }}>
            Market
          </Typography>
        </Box>

        <ChevronRightIcon sx={{ color: "gray" }} />
        <Box>
          <Typography>Competition</Typography>
        </Box>
      </Box>
      <Box>
        <Typography sx={{ fontSize: "12px" }}>
          Understand the competition so you know how customers will benchmark
          your product.
          <br />
          This information will help you with many strategic business decisions.
        </Typography>
      </Box>

      <Grid
        container
        sx={{
          display      : "flex",
          flexDirection: "column",
          overflowX    : "auto",
          marginBottom : "10px",
          //   border: 1,
          marginTop    : "30px"
        }}
        // ref={gridref}
        rowGap={3}>
        <Grid item sx={{ display: "flex" }} columnGap={3}>
          <Box
            sx={{
              flex           : "0 0 200px",
              position       : "sticky",
              left           : 0,
              backgroundColor: "white",
              zIndex         : 5
            }}>
            <Typography>{content.title}</Typography>
          </Box>
          {details[content.name][content.questions[0].name].map(
            (title, index) => {

              return <TitleColumn
                content={content}
                title={title}
                index={index}
                //   isdelete={isdelete}
                //   handleTitleChange={handleTitleChange}
                //   handleRemoveColumn={handleRemoveColumn}
              />;
            
            }
          )}
        </Grid>

        {/* ---- */}

        {content.questions.map(eachQuestion => {

          return (
            <Grid item sx={{ display: "flex" }} columnGap={3}>
              <Box
                sx={{
                  flex           : "0 0 200px",
                  position       : "sticky",
                  left           : 0,
                  backgroundColor: "white",
                  zIndex         : 5
                }}>
                <Typography
                  sx={{
                    fontSize  : "14px",
                    width     : "150px",
                    whiteSpace: "pre-wrap"
                  }}>
                  {eachQuestion.question}
                  {eachQuestion.tooltip &&
                    // <Tooltip
                    //   disableFocusListener
                    //   title={eachQuestion.tooltip}
                    //   placement={"right-start"}
                    //   classes={{ tooltip: classes.customWidth }}
                    // >
                    //   <IconButton
                    //     disableTouchRipple
                    //     disableFocusRipple
                    //     disableRipple
                    //     sx={{ padding: "0px" }}
                    //   >
                    //     <HelpIcon className={classes.helpicon}></HelpIcon>
                    //   </IconButton>
                    // </Tooltip>
                    <HelpIconTooltip tooltipdata={eachQuestion.tooltip} />
                  }
                </Typography>
              </Box>
              {details[content.name][eachQuestion.name].map(row => {

                return (
                  <Box id={styles.othertitles}>
                    <CustomMultilineTextfield
                      width="100%"
                      backgroundColor="white"
                      flex="0 0 300px"
                      // fontSize="14px"
                      maxRows={3}
                      minRows={3}
                      value={row.value}
                      name={eachQuestion.name}
                      // handledetailsChange={(e) => {
                      //   handleChange(e.target.name, e.target.value, index);
                      // }}
                      placeholder={""}
                      disabled={true}
                    />
                  </Box>
                );
              
              })}
            </Grid>
          );
        
        })}

        {/* <BackNextButton
          nextClick={() => {
            // setMarketRender(marketRender + 1);
            if (marketRender === 3) {
              setMarketRender(3);
            } else {
              setMarketRender(marketRender + 1);
            }
          }}
          backClick={() => {
            // setMarketRender(marketRender - 1);
            if (marketRender === 0) {
              setMarketRender(0);
            } else {
              setMarketRender(marketRender - 1);
            }
          }}
        /> */}

        <Box
          sx={{
            display       : "flex",
            justifyContent: "space-between"
          }}>
          <Button
            sx={{ padding: "0px", textTransform: "none", fontWeight: "bold" }}
            disabled={marketRender === 0 && true}
            onClick={() => {

              if (marketRender === 0) {

                setMarketRender(0);
              
              } else {

                setMarketRender(marketRender - 1);
              
              }
            
            }}>
            <ArrowLeftIcon sx={{ fontSize: "35px" }} />
            back
          </Button>
          <Button
            sx={{ padding: "0px", textTransform: "none", fontWeight: "bold" }}
            disabled={marketRender === 3 && true}
            onClick={() => {

              if (marketRender === 3) {

                setMarketRender(3);
              
              } else {

                setMarketRender(marketRender + 1);
              
              }
            
            }}>
            next
            <ArrowRightIcon sx={{ fontSize: "35px" }} />
          </Button>
        </Box>
      </Grid>
    </Box>
  );

}
