import React, { useEffect, useState } from "react";
import { Grid, TextField } from "@mui/material";

// images
import CustomerImg from "../../../Assets/icons/images/pitchC.png";
import SolutionImg from "../../../Assets/icons/images/pitchTheSolution.png";
import DifferntiateImg from "../../../Assets/icons/images/pitchSolutionDifferentiation.png";
import ContactIcon from "../../../Assets/icons/images/Icon_contact.svg";
import EmailIcon from "../../../Assets/icons/images/Icon_Email.svg";
import NameIcon from "../../../Assets/icons/images/Icon_Name.svg";
import WebIcon from "../../../Assets/icons/images/Icon_website.svg";

import HelpIconTooltip from "../../../components/HelpIconTooltip";

const PitchDeckOnePage = props => {

  const onePage = props.onePageData;

  const [ onePageData, setOnePageData ] = useState([]);

  useEffect(() => {

    setOnePageData(onePage);

  }, [ onePage ]);

  const VentureModule = props => {

    const data = props?.data;

    return (
      <>
        <Grid item className="tribe-cover-grid-con tribb-one-page-v-grid-con">
          <div className="tribe-cover-con">
            <h1>{ data?.ventureName }</h1>
            <span>{ data?.oneLineDescription }</span>
          </div>
        </Grid>
        <br />
        <hr></hr>
      </>
    );

  };

  const ProblemStatmentModule = props => {

    const data = props?.data?.problemStatement;

    return <>
      <Grid item className="tribb-one-page-grid-con">
        <div className="tribe-problem-con">
          <h3 className="sub-heading">{ data?.problemName }</h3>
          <p>{ data?.problemStatement }</p>
        </div>
      </Grid>
    </>;

  };

  const CustomerSegmentModule = props => {

    const data = props?.data?.customerSegment;

    return (
      <>
        <Grid
          item
          xs={ 5.6 }
          className="tribb-one-page-grid-con tribe-pitch-small-box-con-left tribe-pitch-customer-con">
          <div className="tribe-pitch-customer-module-con">
            <h3 className="sub-heading">Customer Segments</h3>

            <div className="ttribe-customer-seg-con">
              { data?.segment1 || data?.segment2 || data?.segment3 ? <>
                <div className="ttribe-customer-seg-img-con">
                  <img src={ CustomerImg } alt="" srcSet="" />
                </div>
                <div className="ttribe-customer-seg-name-con">
                  <TextField
                    disabled={ true }
                    className="ttribe-customer-seg-name"
                    value={ data?.segment1 || "" }></TextField>
                  <TextField
                    disabled={ true }
                    className="ttribe-customer-seg-name"
                    value={ data?.segment2 || "" }></TextField>
                  <TextField
                    disabled={ true }
                    className="ttribe-customer-seg-name"
                    value={ data?.segment3 || "" }></TextField>
                </div>
              </> : <>
                <div className="tribe-pitch-one-page-no-info-con">
                  No Information <HelpIconTooltip
                    tooltipdata={ "Update Customer segment in pitch deck slide and publish" }
                  />
                </div>
              </> }

            </div>
          </div>
        </Grid>
      </>
    );
  
  };

  const MarketModule = props => {

    const data = props?.data?.marketSize;

    return (
      <>
        <Grid
          item
          xs={ 5.6 }
          className="tribb-one-page-grid-con tribe-pitch-small-box-con-right ">
          <div className="tribe-market-module-con">
            <h3 className="sub-heading">Market</h3>
            <div style={ { height: "100%" } } >

              { data?.TAM || data?.SAM || data?.SOM ? <>
                <div className="pitch-market-cirle-con">
                  <div className="pitch-market-outer-cir-con">
                    <span>TAM<br />{ data?.TAM }</span>
                    <div className="pitch-market-first-inner-cir-con">
                      <span>SAM<br />{ data?.SAM }</span>
                      <div className="pitch-market-sec-inner-cir-con">
                        <span>SOM<br />{ data?.SOM }</span>
                      </div>
                    </div>
                  </div>
                </div>
              </> : <>
                <div style={ { height: "84%" } } className="tribe-pitch-one-page-no-info-con">
                  No Information <HelpIconTooltip
                    tooltipdata={ "Update Market module details in pitch deck slide and publish" }
                  />
                </div>
              </> }
            </div>
          </div>
        </Grid>
      </>
    );

  };

  const TheSolutionModule = props => {

    const data = props?.data || "";

    return (
      <>
        <Grid
          item
          xs={ 5.6 }
          className="tribb-one-page-grid-con tribe-pitch-small-box-con-left tribe-pitch-customer-con">
          <div className="tribe-pitch-prob-module-con">
            <h3 className="sub-heading">The Solution</h3>
            <div className="ttribe-customer-seg-con">
              { data?.solutionStatement ? <>
                <div className="ttribe-customer-seg-img-con">
                  <img src={ SolutionImg } alt="" srcSet="" />
                </div>
                <div className="vl"></div>
                <div className="ttribe-customer-seg-name-con">
                  <div className="ttribe-solution-name">
                    { data?.solutionStatement || "" }
                  </div>
                </div>
              </> : <>
                <div className="tribe-pitch-one-page-no-info-con">
                  No Information <HelpIconTooltip
                    tooltipdata={ "Update Problem module details in pitch deck slide and publish" }
                  />
                </div>
              </> }

            </div>
          </div>
        </Grid>
      </>
    );
  
  };

  const TheDifferentiationModule = props => {

    const data = props?.data || "";

    return (
      <>
        <Grid
          item
          xs={ 5.6 }
          className="tribb-one-page-grid-con tribe-pitch-small-box-con-right ">
          <div className="tribe-pitch-prob-module-con">
            <h3 className="sub-heading">The Differentiation</h3>
            <div className="ttribe-customer-seg-con">
              {

                data?.differentiation ? <>

                  <div className="ttribe-customer-seg-img-con">
                    <img src={ DifferntiateImg } alt="" srcSet="" />
                  </div>
                  <div className="vl"></div>
                  <div className="ttribe-customer-seg-name-con">
                    <div className="ttribe-solution-name">
                      { data?.differentiation || "" }
                    </div>
                  </div>

                </> : <>
                  <div className="tribe-pitch-one-page-no-info-con">
                    No Information <HelpIconTooltip
                      tooltipdata={ "Update Problem module details in pitch deck slide and publish" }
                    />
                  </div>
                </>

              }

            </div>
          </div>
        </Grid>
      </>
    );
  
  };

  const TheValuePropositionModule = props => {

    const data = props?.data?.valuePropositions || "";

    return (
      <>
        <Grid item className="tribb-one-page-grid-con">
          <Grid container spacing={ 2 } className="tribe-one-page-two-box-con">
            <Grid
              item
              xs={ 11.5 }
              className="tribe-pitch-single-box-con tribe-pitch-customer-con">
              <div className="tribe-pitch-value-prop-modul-con">
                <h3 className="sub-heading">Value Proposition</h3>
                {

                  (data[0]?.value || data[1]?.value || data[2]?.value) && (data[0]?.name || data[1]?.name || data[2]?.name) ? <>
                    <div className="values-con">
                      <div className="tribe-value-module-content-con">
                        <div className="tribe-value-module-content-left">
                          <h4>{ data[0]?.name || "" }</h4>
                        </div>
                        <div className="tribe-value-module-content-right">
                          <div className="tribe-value-right-content-con">
                            <div className="tribe-value-right-content">
                              { data[0]?.value || "" }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tribe-value-module-content-con">
                        <div className="tribe-value-module-content-left">
                          <h4>{ data[1]?.name }</h4>
                        </div>
                        <div className="tribe-value-module-content-right">
                          <div className="tribe-value-right-content">
                            <div className="tribe-value-right-content-con">
                              <div className="tribe-value-right-content">
                                { data[1]?.value }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tribe-value-module-content-con">
                        <div className="tribe-value-module-content-left">
                          <h4>{ data[2]?.name }</h4>
                        </div>
                        <div className="tribe-value-module-content-right">
                          <div className="tribe-value-right-content">
                            <div className="tribe-value-right-content-con">
                              <div className="tribe-value-right-content">
                                { data[2]?.value }
                              </div>
                            </div>
                            {/* {slides[6]?.valuePropositions[2]?.value} */ }
                          </div>
                        </div>
                      </div>
                    </div>
                  </> : <>
                    <div className="tribe-pitch-one-page-no-info-con">
                      No Information <HelpIconTooltip
                        tooltipdata={ "Update Value proposition module details in pitch deck slide and publish" }
                      />
                    </div>
                  </>

                }

              </div>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  
  };

  const TheBusinessModelModule = props => {

    const data = props?.data?.revenues || "";

    return (
      <>
        <Grid item className="tribb-one-page-grid-con">
          <Grid container spacing={ 2 } className="tribe-one-page-two-box-con">
            <Grid
              item
              xs={ 11.5 }
              className="tribe-pitch-single-box-con tribe-pitch-customer-con">
              <div className="tribe-pitch-value-prop-modul-con">
                <h3 className="sub-heading">Buisness Model</h3>
                {

                  (data[0]?.name || data[1]?.name) && (data[0]?.value || data[1]?.value) ? <>

                    <div className="values-con">
                      <div className="tribe-value-module-content-con">
                        <div className="tribe-value-module-content-left">
                          <h4> { data[0]?.name || "" }</h4>
                        </div>
                        <div className="tribe-value-module-content-right">
                          <div className="tribe-value-right-content-con">
                            <div className="tribe-value-right-content">
                              { data[0]?.value || "" }
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="tribe-value-module-content-con">
                        <div className="tribe-value-module-content-left">
                          <h4> { data[1]?.name || "" }</h4>
                        </div>
                        <div className="tribe-value-module-content-right">
                          <div className="tribe-value-right-content-con">
                            <div className="tribe-value-right-content">
                              { data[1]?.value || "" }
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </> : <>

                    <div className="tribe-pitch-one-page-no-info-con">
                      No Information <HelpIconTooltip
                        tooltipdata={ "Update Business model module Revenue details in pitch deck slide and publish" }
                      />
                    </div>
                  </>
                }
              </div>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  
  };

  const TheAskModule = props => {

    const data = props?.data?.theAsk || "";

    return (
      <>
        <Grid
          item
          xs={ 5.6 }
          className="tribb-one-page-grid-con tribe-pitch-small-box-con-left tribe-pitch-customer-con">
          <div className="tribe-ask-fin-module-con">
            <h3 className="sub-heading">The ASK</h3>
            <div className="tribe-ask-con">

              {

                (data?.fundingType?.name || data?.fundingType?.value) &&
                  (data?.customerBase?.name || data?.customerBase?.value) &&
                  (data?.revenueProjection?.name || data?.revenueProjection?.value) &&
                  data?.achieveDescription ? <>

                    <h4>{ data?.fundingType?.name || "" }</h4>
                    <div className="tribe-ask-fun-btn">
                      { data?.fundingType?.value || "" }
                    </div>
                    <p>{ data?.achieveDescription }</p>
                    <div className="tribe-ask-achieve-main-con">
                      <div className="tribe-ask-achieve-con" style={ { marginRight: "22px" } }>
                        <div className="tribe-ask-achieve-btn">
                          { data?.customerBase?.value || "" }
                        </div>
                        <p>{ data?.customerBase?.name || "" }</p>
                      </div>
                      <div className="tribe-ask-achieve-con">
                        <div className="tribe-ask-achieve-btn">
                          { data?.revenueProjection?.value || "" }
                        </div>
                        <p>{ data?.revenueProjection?.name || "" }</p>
                      </div>
                    </div>

                  </> : <>

                    <div className="tribe-pitch-one-page-no-info-con">
                    No Information <HelpIconTooltip
                        tooltipdata={ "Update The Ask module details in pitch deck slide and publish" }
                      />
                    </div>

                  </>

              }

            </div>
          </div>
        </Grid>
      </>
    );
  
  };

  const TheFinanicalModule = props => {

    const data = props?.data?.revenueYears || "";

    return (
      <>
        <Grid
          item
          xs={ 5.6 }
          className="tribb-one-page-grid-con tribe-pitch-small-box-con-right ">
          <div>
            <h3 className="sub-heading">The Financial</h3>
          </div>
          <div className="tribe-fin-module-sub-con">
            {

              (data[0]?.value || data[1]?.value || data[2]?.value) && (data[0]?.name || data[1]?.name || data[2]?.name) ? <>

                <div className="tribe-fin-module-text-con">
                  <h4>{ data[0]?.name || "" }</h4>
                  <p>{ data[0]?.value || "" }</p>
                </div>
                <div className="tribe-fin-module-text-con">
                  <h4>{ data[1]?.name || "" }</h4>
                  <p>{ data[1]?.value || "" }</p>
                </div>
                <div className="tribe-fin-module-text-con">
                  <h4>{ data[2]?.name || "" }</h4>
                  <p>{ data[2]?.value || "" }</p>
                </div>

              </> : <>

                <div className="tribe-pitch-one-page-no-info-con">
                  No Information <HelpIconTooltip
                    tooltipdata={ "Update The Financial module details in pitch deck slide and publish" }
                  />
                </div>

              </>

            }

          </div>
        </Grid>
      </>
    );

  };

  const TheContactInfoModule = props => {

    const data = props?.data?.contactInfo || "";

    return <>

      <Grid
        item
        xs={ 11.5 }
        className="tribe-pitch-footer-box-con tribe-pitch-customer-con">
        <div>
          <h3 className="sub-heading">Contact Information</h3>
        </div>
        <div className="tribe-contact-info-sub-con">
          {

            data?.username || data?.email || data?.contact || data?.website ? <>

              <div className="tribe-contact-module-sub-con">

                <div className="contact-group-1">
                  <img src={ NameIcon } alt="" srcSet="" />
                  <p>{ data?.username }</p>
                </div>

                <div className="contact-group-1">
                  <img src={ EmailIcon } alt="" srcSet="" />
                  <p>{ data?.email }</p>

                </div>


                <div className="contact-group-1">
                  <img src={ ContactIcon } alt="" srcSet="" />
                  <p>{ data?.contact }</p>
                </div>

                <div className="contact-group-1">
                  <img src={ WebIcon } alt="" srcSet="" />
                  <p>{ data?.website }</p>
                </div>


              </div>

            </> : <>

              <div className="tribe-pitch-one-page-no-info-con">
                No Information <HelpIconTooltip
                  tooltipdata={ "Update The Thank you module details in pitch deck slide and publish" }
                />
              </div>


            </>

          }

        </div>
      </Grid>

    </>;
  
  };

  return (
    <div ref={ props.onePageRef }>
      <Grid container className="tribe-pitch-one-page-main-con">
        <VentureModule data={ onePageData[0] } />
        <ProblemStatmentModule data={ onePageData[1] } />
        <hr></hr>

        {/* Customer module & Market module */ }
        <div className="tribe-cus-and-market-con">
          <Grid item className="tribb-one-page-grid-con">
            <Grid container spacing={ 2 } className="tribe-one-page-two-box-con">
              <CustomerSegmentModule data={ onePageData[2] } />
              <MarketModule data={ onePageData[3] } />
            </Grid>
          </Grid>
        </div>

        {/* Problem module solution & Differentiation */ }
        <div>
          <Grid item className="tribb-one-page-grid-con">
            <Grid container spacing={ 2 } className="tribe-one-page-two-box-con">
              <TheSolutionModule data={ onePageData[4] } />
              <TheDifferentiationModule data={ onePageData[5] } />
            </Grid>
          </Grid>
        </div>

        {/* Value proposition module */ }
        <div>
          <TheValuePropositionModule data={ onePageData[6] } />
        </div>

        {/* BusinessModel module */ }
        <div>
          <TheBusinessModelModule data={ onePageData[7] } />
        </div>

        {/* The ASk module & Financial Module */ }
        <div>
          <Grid item className="tribb-one-page-grid-con">
            <Grid container spacing={ 2 } className="tribe-one-page-two-box-con">
              <TheAskModule data={ onePageData[8] } />
              <TheFinanicalModule data={ onePageData[9] } />
            </Grid>
          </Grid>
        </div>

        <hr />

        {/* Contact information module */ }
        <div>
          <Grid item className="tribb-one-page-grid-con">
            <Grid container spacing={ 2 } className="">

              <TheContactInfoModule data={ onePageData[10] } />

            </Grid>
          </Grid>
        </div>
      </Grid>
    </div>
  );

};

export default PitchDeckOnePage;
