/* eslint-disable react/react-in-jsx-scope */
import { Grid } from "@mui/material";

import Img from "../../../../Assets/icons/images/VentureProfile.svg";

import ContactIcon from "../../../../Assets/icons/images/Icon_contact.svg";
import EmailIcon from "../../../../Assets/icons/images/Icon_Email.svg";
import NameIcon from "../../../../Assets/icons/images/Icon_Name.svg";
import WebIcon from "../../../../Assets/icons/images/Icon_website.svg";

import HelpIconTooltip from "../../../../components/HelpIconTooltip";

const VentureProfile = props => {

  const TheContactInfoModule = props => {

    const data = props?.contactInfo || "";

    return <><Grid
      item
      xs={11.5}
      className="tribe-pitch-footer-box-con-summary tribe-pitch-customer-con">
      <div>
        <h3 className="sub-heading">Contact Information <HelpIconTooltip
          tooltipdata={"From pitch deck one page biz plan"}
        /></h3>
      </div>
      <div className="tribe-contact-info-sub-con">
        {

          data?.username || data?.email || data?.contact || data?.website ? <>

            <div className="tribe-contact-module-sub-con">

              <div className="contact-group-1 contact-group-1-bizplan-summary">
                <img src={NameIcon} alt="" srcSet="" />
                <p>{data?.user_name}</p>
              </div>

              <div className="contact-group-1 contact-group-1-bizplan-summary">
                <img src={EmailIcon} alt="" srcSet="" />
                <p>{data?.email}</p>

              </div>


              <div className="contact-group-1 contact-group-1-bizplan-summary">
                <img src={ContactIcon} alt="" srcSet="" />
                <p>{data?.contact}</p>
              </div>

              <div className="contact-group-1 contact-group-1-bizplan-summary">
                <img src={WebIcon} alt="" srcSet="" />
                <p>{data?.website}</p>
              </div>


            </div>

          </> : <>

            <div className="tribe-pitch-one-page-no-info-con">
                                No Information <HelpIconTooltip
                tooltipdata={"Update The Thank you module details in pitch deck slide and publish"}
              />
            </div>


          </>

        }

      </div>
    </Grid>

    </>;
  
  };

  const data = props?.ventureProfileData;

  return <div className="tribe-summary-venture-profile-main-con" >
    <Grid className="tribe-summary-venture-profile-con" container spacing={2}>
      <Grid className="tribe-summary-venture-img-con" xs={5}>
        <div className="tribe-summary-fund-con">
          <div>Current Funding<HelpIconTooltip
            tooltipdata={"From financial module"}
          /></div>
          <span>
            {data?.current_funding || ""}
          </span>
        </div>
        <div>
          <img className="tribe-summary-img" src={Img} alt="" />
        </div>
      </Grid>
      <Grid xs={7}>
        <div className="tribe-summary-description-con">
          <div className="tribe-summary-venture-des-con">
            <div className="title">Venture Description<HelpIconTooltip
              tooltipdata={"From Concept and vision module"}
            /></div>
            <div className="description">
              <span>
                {data?.concept_vision?.venture_description || ""}
              </span>
            </div>
          </div>
          <div className="tribe-summary-vision-stmt-des-con">
            <div className="title">Vision Statement<HelpIconTooltip
              tooltipdata={"From Concept and vision module"}
            /></div>
            <div className="description">
              <span>
                {data?.concept_vision?.vision_statement || ""}
              </span>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
    <TheContactInfoModule contactInfo={data?.contact_info} />
  </div>;

};

export default VentureProfile;