import React, { useState, useEffect } from "react";
import { auth  } from "../../../firebase";
import { GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createOrUpdateUser } from "../../../services/auth";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  Divider,
  InputAdornment,
  IconButton,
  CircularProgress
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import BoxWithoutIcon from "../../../components/BoxWithoutIcon";
import ErrorMessage from "../../../components/ErrorMessage";

import styles from "./Login.module.css";
import { updateUserAccess } from "../../../services/subscription";

const Login = () => {

  const [ email, setEmail ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ emailerrormsg, setEmailErrormsg ] = useState("");
  const [ passworderrormsg, setPasswordErrormsg ] = useState("");
  const [ showpassword, setShowPassword ] = useState(false);
  const [ errorflag, setErrorflag ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const history = useHistory();

  const { user } = useSelector(state => ({ ...state }));

  useEffect(() => {

    if (user) {

      if (user.userVerified && user.token) {

        history.push("/welcomepage");
      
      }
    
    }
  
  }, [ user ]);

  useEffect(() => {

    if (!errorflag) {

      setEmailErrormsg("");
      setPasswordErrormsg("");
    
    }
  
  }, [ errorflag ]);

  let dispatch = useDispatch();

  const handleClickShowPassword = () => {

    setShowPassword(!showpassword);
  
  };

  const handleSubmit = async e => {

    e.preventDefault();
    setLoading(true);
    try {

      if (
        email &&
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
      ) {

        try {

          const result = await signInWithEmailAndPassword(auth, email, password);
          const { user } = result;
          const idTokenResult = await user?.getIdTokenResult();
          const details = { userVerified: auth?.currentUser?.emailVerified };

          try {

            const createOrUpdateResponse = await createOrUpdateUser(details);

            try {
              
              const updateUserAccessResponse = await updateUserAccess(createOrUpdateResponse.data._id);

              dispatch({
                type   : "LOGGED_IN_USER",
                payload: {
                  firstname       : createOrUpdateResponse.data.firstname,
                  lastname        : createOrUpdateResponse.data.lastname,
                  email           : createOrUpdateResponse.data.email,
                  token           : idTokenResult.token,
                  subscription    : updateUserAccessResponse.data.subscription,
                  access          : updateUserAccessResponse.data.access,
                  profileIcon     : createOrUpdateResponse.data.profileIcon,
                  userVerified    : createOrUpdateResponse.data.userVerified,
                  organizationName: createOrUpdateResponse.data.organizationName,
                  location        : createOrUpdateResponse.data.location,
                  createdAt       : createOrUpdateResponse.data.createdAt,
                  _id             : createOrUpdateResponse.data._id
                }
              });

              if (auth?.currentUser?.emailVerified) {
  
                history.push("/welcomepage");
              
              } else {
  
                setLoading(false);
  
                setEmailErrormsg("Please verify your email Id");
                dispatch({
                  type   : "LOGOUT",
                  payload: null
                });
                setErrorflag(true);
              
              }

            } catch (error) { console.error(error); }

          } catch (error) {

            console.error(error);dispatch({
              type   : "LOGOUT",
              payload: null
            });
    
            // Handle the error and show appropriate error messages to the user
            setLoading(false);
            // setEmailErrormsg("Invalid email or password");
            setErrorflag(true);
          
          }

        } catch (error) {

          console.error(error);dispatch({
            type   : "LOGOUT",
            payload: null
          });
  
          // Handle the error and show appropriate error messages to the user
          setLoading(false);
          setEmailErrormsg("Invalid email or password");
          setErrorflag(true);

        }

      
      } else {

        setEmailErrormsg("Please enter a valid email ID.");
        setErrorflag(true);
        setLoading(false);
        dispatch({
          type   : "LOGOUT",
          payload: null
        });
      
      }

      setLoading(false);

      // history.push("/");
    
    } catch (error) {

      setLoading(false);
      console.error(error);
      if (error.code === "auth/wrong-password") {

        setPasswordErrormsg(
          "Oh! The password you entered is incorrect. If you have forgotten it, click 'Forgot Password' to reset it."
        );
        setErrorflag(true);
      
      } else {

        setEmailErrormsg(
          "We could not find an account with that email. Kindly verify the email address entered, or try a different email."
        );
        setErrorflag(true);
      
      }
    
    }
  
  };

  const googleLogin = async () => {

    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider).then(async result => {

      const { user } = result;
      const idTokenResult = await user.getIdTokenResult();

      createOrUpdateUser().then(res => {
        
        updateUserAccess(res?.data?._id).then(response => {

          dispatch({
            type   : "LOGGED_IN_USER",
            payload: {
              firstname       : res.data.firstname,
              lastname        : res.data.lastname,
              email           : res.data.email,
              token           : idTokenResult.token,
              subscription    : response.data.subscription,
              access          : response.data.access,
              profileIcon     : res.data.profileIcon,
              userVerified    : res.data.userVerified,
              organizationName: res.data.organizationName,
              location        : res.data.location,
              createdAt       : res.data.createdAt,
              _id             : res.data._id
            }
          });
          if (auth?.currentUser?.emailVerified) {

            history.push("/welcomepage");
          
          } else {

            setEmailErrormsg("Please verify your email Id");
            dispatch({
              type   : "LOGOUT",
              payload: null
            });
            setErrorflag(true);
          
          }
        
        }).catch(err => {

          console.error(err);
        
        });

      }).catch(err => {

        console.error(err);
      
      });

    }).catch(err => {

      console.error(err);
    
    });
  
  };

  return <>
    {loading ? <>
      <Grid
        container
        justifyContent="center"
        height="91vh"
        alignItems="center">
        <Grid item>
          <CircularProgress size="60px" thickness={3} />
        </Grid>
      </Grid>
    </> : <> <BoxWithoutIcon>
      <Grid
        container
        sx={{ alignItems: "center", height: "100%", justifyContent: "center" }}
      >
        {emailerrormsg && <ErrorMessage errormsg={emailerrormsg} />}
        {passworderrormsg && <ErrorMessage errormsg={passworderrormsg} />}
        <Grid item md={6} xs={12} id={styles.griditem1}>
          <img
            id={styles.img}
            src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/undraw_login_re_4vu2.svg`}
            alt="login img"
          />
          <Divider
            orientation="vertical"
            variant="middle"
            id={styles.divider}
          />
        </Grid>
        <Grid item md={5} xs={12} id={styles.griditem2}>
          <Typography gutterBottom fontWeight="bold">
            Welcome Back!
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              "& .MuiTextField-root": { m: 1, width: "375px", height: "50px" }
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <TextField
                error={emailerrormsg ? true : false}
                type="email"
                label="Your email address"
                autoFocus
                fullWidth
                value={email}
                onChange={e => {

                  setErrorflag(false);
                  setEmail(e.target.value);
                
                }}
              />
            </div>
            <div>
              <TextField
                error={passworderrormsg ? true : false}
                type={showpassword ? "text" : "password"}
                label="Password"
                fullWidth
                value={password}
                onChange={e => {

                  setErrorflag(false);
                  setPassword(e.target.value);
                
                }}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showpassword ?
                          // <VisibilityOff />
                          <Visibility />
                          :
                          <VisibilityOff />
                        }
                      </IconButton>
                    </InputAdornment>
                  
                }}
              />
            </div>
            <Button
              sx={{ float: "right" }}
              onClick={() => {

                history.push("/forgotpassword");
              
              }}
            >
              <Typography id={styles.forgottext}>Forgot Password ?</Typography>
            </Button>
            <Button id={styles.loginbtn} variant="contained" type="submit">
              Login
            </Button>
          </Box>
          <div style={{ width: "40%", margin: "10px 0px" }}>
            <Divider
              sx={{
                "&.MuiDivider-root": {
                  "&::before": {
                    borderTop: "thin solid grey"
                  },
                  "&::after": {
                    borderTop: "thin solid grey"
                  },
                  fontSize: "15px",
                  color   : "#707070"
                }
              }}
            >
              or
            </Divider>
          </div>
          <Button id={styles.signupwithgooglebutton} onClick={googleLogin}>
            <img
              id={styles.googlebuttonicon}
              src="images\google_icon.png"
              alt="google img"
            />
            Login with Google
          </Button>
          <Box sx={{ display: "flex", marginTop: "10px" }}>
            <Typography sx={{ marginTop: "5px" }}>
              Don’t have an Account ?
            </Typography>
            <Typography
              sx={{ textDecoration: "#071AFF underline", marginLeft: "15px" }}
            >
              <Button
                onClick={() => {

                  history.push("/register");
                
                }}
                sx={{
                  fontWeight   : "bold",
                  color        : "#071AFF",
                  textTransform: "capitalize"
                }}
              >
                Sign Up
              </Button>
            </Typography>
          </Box>
          <Box sx={{ display: "flex", marginTop: "25px" }}>
            <Typography sx={{ marginTop: "5px", fontSize: "12px" }}>
              Having trouble logging in ?
            </Typography>
            <Button>
              <Typography
                id={styles.forgottext}
                component="a"
                target="_blank"
                href="/support"
              >
                Contact Support
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </BoxWithoutIcon></>
    }
  </>;


};

export default Login;
