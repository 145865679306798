import {
  Box,
  Button, Grid, IconButton, Radio, Typography
} from "@mui/material";
import React, { useState } from "react";

import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";

import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";

import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import styles from "./Collaborators.module.css";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3)
  },
  "& .MuiInputBase-input": {
    // borderRadius: 4,
    position    : "relative",
    // backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    // border: "1px solid #ced4da",
    borderBottom: "2px solid #ced4da",
    fontSize    : 16,
    // width: "auto",
    width       : "23vw",
    padding     : "2px 12px",
    transition  : theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow"
    ])
  }
}));

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width       : 12,
  height      : 12,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline      : "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5"
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)"
  }
}));

const useStyles = makeStyles({
  root: {
    paddingTop   : 0,
    paddingBottom: 0
  }
});

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display        : "block",
    width          : 12,
    height         : 12,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content        : "\"\""
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3"
  }
});

function BpRadio (props) {

  const classes = useStyles();

  
  return (
    <Radio
      classes={ { root: classes.root } }
      sx={ {
        "&:hover": {
          bgcolor: "transparent"
        }
      } }
      disableRipple
      color="default"
      checkedIcon={ <BpCheckedIcon /> }
      icon={ <BpIcon /> }
      { ...props }
    />
  );

}

export default function Collaborators () {

  const [ rows, setRows ] = useState([]);

  const handleAdd = () => {

    setRows([
      ...rows,
      {
        id   : rows.length + 1,
        email: "",
        radio: ""
      }
    ]);
  
  };

  const handleRemoveClick = i => {

    const list = [ ...rows ];

    list.splice(i, 1);
    setRows(list);
  
  };

  return (
    <Box sx={ { height: "100%" } }>
      <Box id={ styles.mainBox }>
        <Grid container xs={ 12 }>
          <Grid item xs={ 12 } id={ styles.headerGrid }>
            <Box>
              <Typography id={ styles.headTypo }>
                Collaborators
                <Typography id={ styles.subHeadTypo }>
                  Invite people to your project by email
                </Typography>
              </Typography>
            </Box>
            <Box>
              <Button id={ styles.dashboardButton }>
                {/* Dashboard */ }
                <Typography id={ styles.buttonTypo }>Dashboard</Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>

        {/* enter email address */ }
        <Grid container xs={ 12 } sx={ { marginTop: "48px" } }>
          <Grid id={ styles.emailGridItem } item xs={ 12 }>
            <Box id={ styles.textInputBox }>
              <BootstrapInput />
            </Box>
            <Box id={ styles.radioButtonBox }>
              <RadioGroup>
                <FormControlLabel
                  value="commenter"
                  control={ <BpRadio /> }
                  label={
                    <Typography sx={ { fontSize: "14px" } }>Commenter</Typography>
                  }
                />
                <FormControlLabel
                  value="editor"
                  control={ <BpRadio /> }
                  label={
                    <Typography sx={ { fontSize: "14px" } }>Editor</Typography>
                  }
                />
              </RadioGroup>
            </Box>
            <Box id={ styles.inviteButtonBox }>
              <Button
                id={ styles.inviteButton }
                variant="contained"
                onClick={ handleAdd }
              >
                Invite
              </Button>
            </Box>
          </Grid>
        </Grid>

        {/* mapping content */ }

        <Grid container xs={ 12 } spacing={ 1 } id={ styles.rowsGridContainer }>
          {/* grid to be mapped */ }

          { rows.map((row, i) => {

            return (
              <Grid
                // key={i}
                id={ styles.rowGridItem } item xs={ 12 }>
                <Box id={ styles.rowMainBox }>
                  <Box id={ styles.emailBox }>
                    <Typography sx={ { fontSize: "14px" } }>
                      email text
                    </Typography>
                  </Box>

                  <Box id={ styles.rowRadioBox }>
                    <RadioGroup>
                      <FormControlLabel
                        value="commenter"
                        control={ <BpRadio /> }
                        label={
                          <Typography sx={ { fontSize: "14px" } }>
                            Commenter
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="editor"
                        control={ <BpRadio /> }
                        label={
                          <Typography sx={ { fontSize: "14px" } }>
                            Editor
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  </Box>

                  <Box id={ styles.rowDelInviteBox }>
                    <IconButton
                      onClick={ () => handleRemoveClick(i) }
                      sx={ { color: "#ff7f5c" } }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>

                  <Box id={ styles.rowDelInviteBox }>
                    <Button
                      variant="contained"
                      sx={ {
                        textTransform: "none",
                        fontSize     : "12px"
                      } }
                    >
                      Resend Invite
                    </Button>
                  </Box>
                </Box>
              </Grid>
            );
          
          }) }
          {/* ------- */ }
        </Grid>
      </Box>
    </Box>
  );

}
