import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Box, CircularProgress, Grid, Link, Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory, useLocation } from "react-router-dom";
import {
  getPublishedPitchDeckDetails
} from "../../../../services/pitchdeck";
import IndividualHoverBarResponsive from "./IndividualHoverBarResponsive";

export default function PitchPresentationResponsive2 () {

  const [ publishedImages, setPublishedImages ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ currentSlide, setCurrentSlide ] = useState(0);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1023px)" });

  // const { user, venture } = useSelector((state) => ({ ...state }));

  let location = useLocation();
  const history = useHistory();

  let locationState = location.state;

  const useQuery = () => {

    return new URLSearchParams(location.search);
  
  };
  const query = useQuery();

  const id = query.get("id");
  // const ventureName = query.get("ventureName");

  useEffect(() => {

    fetchDetails();
  
  }, []);

  // useEffect(() => {
  //   setCurrentSlideStyle({
  //     backgroundImage: "url('" + publishedImages[currentSlide] + "')",
  //   });
  // }, [currentSlide]);

  const fetchDetails = async () => {

    setLoading(true);
    try {

      const response = await getPublishedPitchDeckDetails(id);
      
      let publishedImagesData = response.data.publishedSlideImages.filter(
        publishedSlideImage => !publishedSlideImage.isHide
      );

      setPublishedImages(publishedImagesData);
      setLoading(false);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const previous = e => {

    e.stopPropagation();
    if (currentSlide > 0) {

      setCurrentSlide(currentSlide - 1);
    
    } else {

      setCurrentSlide(publishedImages.length - 1);
    
    }
  
  };

  const next = e => {

    e.stopPropagation();
    if (currentSlide === publishedImages.length - 1) {

      setCurrentSlide(0);
    
    } else {

      setCurrentSlide(currentSlide + 1);
    
    }
  
  };

  useEffect(() => {

    if (locationState && locationState.fromPitchDeck) {

      const handleEsc = event => {

        if (event.keyCode === 27) {

          // console.log("Close");
          history.push("/pitchdeckintro", {
            isRender    : locationState.isRender,
            isRendername: locationState.isRendername
          });
        
        }
      
      };

      window.addEventListener("keydown", handleEsc);

      return () => {

        window.removeEventListener("keydown", handleEsc);
      
      };
    
    }
  
  }, []);

  // console.log(publishedImages);

  return (
    <Box
      sx={ {
        height        : "88vh",
        padding       : "30px",
        // border: 1,
        display       : "flex",
        justifyContent: "center",
        alignItems    : "center",
        flexDirection : "column"
      } }
      onClick={ () => {

        if (locationState && locationState.fromPitchDeck) {

          history.push("/pitchdeckintro", {
            isRender    : locationState.isRender,
            isRendername: locationState.isRendername
          });
        
        }
      
      } }
    >
      {/* <Box id={styles.contentBox}></Box> */ }
      { loading || publishedImages.length === 0 ?
        <Grid
          container
          justifyContent="center"
          height="90vh"
          alignItems="center"
        >
          <Grid item>
            <CircularProgress size="60px" thickness={ 3 } />
          </Grid>
        </Grid>
        :
        <Box
          sx={ {
            height         : "0px",
            width          : "80%",
            paddingBottom  : "40%",
            // width: "90%",
            // paddingBottom: "40%",
            border         : "2px solid #071AFF",
            position       : "relative",
            borderRadius   : "5px",
            backgroundColor: "#FFFFFF"
          } }
          onClick={ e => {

            e.stopPropagation();
          
          } }
        >
          <img
            style={ {
              bottom   : 0,
              left     : 0,
              margin   : "auto",
              maxHeight: "100%",
              maxWidth : "100%",
              right    : 0,
              top      : 0,
              position : "absolute"
            } }
            alt=""
            src={ publishedImages[currentSlide]["imageUrl"] }
          />

          <Box
            sx={ {
              // width: "38px",
              // height: "52px",
              width          : "4%",
              height         : "8%",
              boxShadow      : "0px 3px 6px #00000029",
              display        : "flex",
              justifyContent : "center",
              alignItems     : "center",
              position       : "absolute",
              // top: "34vh",
              // left: "-15px",
              // zIndex: 100,
              backgroundColor: "#FFFFFF",
              borderRadius   : "3px",
              cursor         : "pointer",
              left           : "-2%",
              top            : "50%"
            } }
            onClick={ previous }
          >
            <KeyboardArrowLeftIcon />
          </Box>

          <Box
            sx={ {
              width          : "4%",
              height         : "8%",
              // width: "38px",
              // height: "52px",
              boxShadow      : "0px 3px 6px #00000029",
              display        : "flex",
              justifyContent : "center",
              alignItems     : "center",
              position       : "absolute",
              top            : "50%",
              right          : "-2%",
              zIndex         : 100,
              backgroundColor: "#FFFFFF",
              borderRadius   : "3px",
              cursor         : "pointer"
            } }
            onClick={ next }
          >
            <KeyboardArrowRightIcon />
          </Box>
          {/* ------------ */ }
        </Box>
      }

      { isTabletOrMobile ?
        <></>
        :
        <Box
          sx={ {
            // border: 1,
            height        : "2rem",
            width         : "80%",
            display       : "flex",
            justifyContent: "space-between",
            alignItems    : "center",
            columnGap     : "8px"
          } }
          onClick={ e => {

            e.stopPropagation();
          
          } }
        >
          {/* -------- */ }
          { publishedImages.map((img, index) => {

            return <IndividualHoverBarResponsive
              img={ img }
              currentSlide={ currentSlide }
              setCurrentSlide={ setCurrentSlide }
              publishedImages={ publishedImages }
              index={ index }
            />;
          
          }) }

          {/* -------- */ }
        </Box>
      }

      {/* ------nav box bottom----- */ }
      { isTabletOrMobile ?
        <></>
        :
        <Box
          sx={ {
            width         : "80%",
            height        : "50px",
            display       : "flex",
            justifyContent: "center",
            alignItems    : "center",
            position      : "relative"
            // border: 1,
          } }
        >
          <Box
            // id={styles.bottomNavSubBox}
            sx={ {
              width         : "7vw",
              height        : "30px",
              // border: 1,
              display       : "flex",
              justifyContent: "space-between",
              alignItems    : "center"
            } }
          >
            <Box
              // id={styles.bottomLeftIconBox}
              sx={ {
                // border: 1,
                display        : "flex",
                justifyContent : "space-between",
                alignItems     : "center",
                borderRadius   : "50%",
                backgroundColor: "#868686",
                cursor         : "pointer"
              } }
              // onClick={() => {
              //   if (isRendername === slides[0].renderName) {
              //     setIsrendername(slides[slides.length - 1].renderName);
              //     setIsRender(slides.length - 1);
              //   } else {
              //     setIsrendername(slides[isRender - 1].renderName);
              //     setIsRender(isRender - 1);
              //   }
              // }}
              onClick={ previous }
            >
              <ChevronLeftIcon
                // id={styles.ChevronLeftIcon}
                sx={ { fontSize: "19px", color: "white" } }
              />
            </Box>
            <Box>
              <Typography
                // id={styles.slideNoTypo}
                sx={ { fontSize: "11px", color: "#393939", fontWeight: "bold" } }
              >
                {/* {isRender + 1}/{`${slides.length}`} */ }
                { currentSlide + 1 }/{ `${publishedImages.length}` }
              </Typography>
            </Box>
            <Box
              // id={styles.bottomRightIconBox}
              sx={ {
                // border: 1,
                display        : "flex",
                justifyContent : "space-between",
                alignItems     : "center",
                borderRadius   : "50%",
                backgroundColor: "#868686",
                cursor         : "pointer"
              } }
              // onClick={() => {
              //   if (isRendername === slides[slides.length - 1].renderName) {
              //     setIsrendername(slides[0].renderName);
              //     setIsRender(0);
              //   } else {
              //     setIsrendername(slides[isRender + 1].renderName);
              //     setIsRender(isRender + 1);
              //   }
              // }}
              onClick={ next }
            >
              <ChevronRightIcon
                sx={ { fontSize: "19px", color: "white" } }
              // id={styles.ChevronRightIcon}
              />
            </Box>
          </Box>

          {/* ------logo img box------ */ }
          <Box
            // id={styles.logoBox}
            sx={ {
              // border: 1,
              position      : "absolute",
              width         : "157px",
              height        : "50px",
              right         : "0px",
              cursor        : "pointer",
              display       : "flex",
              justifyContent: "center",
              alignItems    : "center"
            } }
            onClick={ e => {

              e.stopPropagation();
            
            } }
          >
            {/* <a
                target="_blank"
                href={
                  window.location.host === "app.todactribe.com"
                    ? "https://todactribe.com/"
                    : "https://dev.todactribe.com/"
                }
              > */}
            {/* <img
                  src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/TodacTribe+Logo.png`}
                  alt=""
                  // style={{ width: "87px", height: "50px" }}
                  id={styles.logoImg}
                /> */}

            <Typography
              sx={ {
                fontSize   : "12px",
                marginRight: "4px",
                // fontWeight: "bold",
                fontStyle  : "italic"
              } }
            >
              Powered by
            </Typography>
            <Typography
              sx={ {
                fontSize      : "12px",
                textDecoration: "underline",
                color         : "#071AFF",
                // fontWeight: "bold",
                fontStyle     : "italic"
              } }
              component={ Link }
            >
              {/* Todac Tribe */ }
              <Link
                target="_blank"
                href={
                  window.location.host === "app.todactribe.com"
                    ? "https://todactribe.com/"
                    : "https://dev.todactribe.com/"
                }
                underline="none"
              >
                TODAC Tribe
              </Link>
            </Typography>
            {/* </a> */ }
          </Box>
        </Box>
      }
    </Box>
  );

}
