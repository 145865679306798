import { combineReducers } from "redux";
import { userReducer } from "./userReducer";
import { ventureReducer } from "./ventureReducer";

const rootReducer = combineReducers({
  user   : userReducer,
  venture: ventureReducer
});

export default rootReducer;
