import React, { useEffect, useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box, Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import CustomMultilineTextfield from "../../../../components/CustomMultilineTextfield";
import CustomTextfield from "../../../../components/CustomTextfield";
import HelpIconTooltip from "../../../../components/HelpIconTooltip";
import { getMarketdetails } from "../../../../services/market";
import CustomChart from "../../Market/MarketSize/CustomChart";

export default function PitchMarketSize() {

  const [ details, setDetails ] = useState({
    currency                  : "",
    totalAddressableMarket    : 0,
    segmentedAddressableMarket: 0,
    ourExpectedMarketShare    : 0,
    timeHorizon               : "",
    keyAssumptions            : ""
  });

  const { venture } = useSelector(state => ({ ...state }));

  useEffect(() => {

    fetchMarketdetails();
  
  }, []);

  const fetchMarketdetails = async () => {

    try {

      const response = await getMarketdetails(venture.market);
      const market = response.data.market;

      setDetails(market.size);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const numberWithCommas = num => {

    if (details.currency) {

      let newNum = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      
      return `${details.currency.split(" ")[0].trim()} ${newNum}`;
    
    } else {

      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    
    }
  
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
        <Box>
          <Typography
            sx={{ fontSize: "20px", color: "#5F5E5E", fontWeight: "bold" }}
          >
            Market
          </Typography>
        </Box>

        <ChevronRightIcon sx={{ color: "gray" }} />
        <Box>
          <Typography>Market Size</Typography>
        </Box>
      </Box>

      <Box>
        <Typography sx={{ fontSize: "12px" }}>
          How big is the market opportunity for your solution? Use our
          calculator (or your own) to estimate how much value there really is
          for your venture to capture.
        </Typography>
      </Box>

      <Box
        sx={{
          marginTop     : "20px",
          display       : "flex",
          justifyContent: "space-between"
        }}
      >
        <Box sx={{ width: "45%" }}>
          <Box
            sx={{
              display      : "flex",
              flexDirection: "column",
              border       : "1px solid #bdc9c1",
              borderRadius : "4px"
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
              <Box
                sx={{
                  borderRight: "1px solid #bdc9c1",
                  width      : "14vw",
                  display    : "flex",
                  alignItems : "center"
                }}
              >
                <Typography
                  sx={{ marginLeft: "6px", marginTop: "5px", fontSize: "14px" }}
                >
                  Total Addressable <br />
                  Market (TAM)
                  <HelpIconTooltip
                    tooltipdata={
                      "How big is the largest possible market or the total demand for the category that your product or service belongs to? "
                    }
                  />
                </Typography>
              </Box>
              {/* textfield box */}
              <Box>
                <CustomTextfield
                  value={numberWithCommas(details.totalAddressableMarket)}
                  name="totalAddressableMarket"
                  width={"12vw"}
                  padding={"7px 0px"}
                  border={"none"}
                  disabled={true}
                />
              </Box>
            </Box>

            {/* ---2---- */}

            <Box
              sx={{
                display   : "flex",
                alignItems: "center",
                borderTop : "1px solid #bdc9c1",
                width     : "100%"
              }}
            >
              <Box
                sx={{
                  borderRight: "1px solid #bdc9c1",
                  width      : "14vw",
                  display    : "flex",
                  alignItems : "center"
                }}
              >
                <Typography
                  sx={{ marginLeft: "6px", marginTop: "5px", fontSize: "14px" }}
                >
                  Segmented Addressable
                  <br /> Market (SAM)
                  <HelpIconTooltip
                    tooltipdata={
                      "What subset (segments) of the 'Total Addressable Market' will you target with your product or service? "
                    }
                  />
                </Typography>
              </Box>
              <Box>
                <CustomTextfield
                  value={numberWithCommas(details.segmentedAddressableMarket)}
                  name="segmentedAddressableMarket"
                  width={"12vw"}
                  padding={"7px 0px"}
                  border={"none"}
                  disabled={true}
                />
              </Box>
            </Box>

            {/* -----3------- */}

            <Box
              sx={{
                display   : "flex",
                alignItems: "center",
                borderTop : "1px solid #bdc9c1",
                width     : "100%"
              }}
            >
              <Box
                sx={{
                  borderRight: "1px solid #bdc9c1",
                  width      : "14vw",
                  display    : "flex",
                  alignItems : "center"
                }}
              >
                <Typography
                  sx={{ marginLeft: "6px", marginTop: "5px", fontSize: "14px" }}
                >
                  Our Share of Market <br />(SOM)
                  <HelpIconTooltip
                    tooltipdata={
                      "What portion of the Segmented Addressable Market can your venture realistically capture? "
                    }
                  />
                </Typography>
              </Box>
              <Box>
                <CustomTextfield
                  value={numberWithCommas(details.ourExpectedMarketShare)}
                  name="ourExpectedMarketShare"
                  width={"12vw"}
                  padding={"7px 0px"}
                  border={"none"}
                  disabled={true}
                />
              </Box>
            </Box>
            {/* ----4---- */}
            <Box
              sx={{
                display   : "flex",
                alignItems: "center",
                borderTop : "1px solid #bdc9c1",
                width     : "100%"
              }}
            >
              <Box
                sx={{
                  borderRight: "1px solid #bdc9c1",
                  width      : "14vw",
                  display    : "flex",
                  alignItems : "center"
                }}
              >
                <Typography
                  sx={{ marginLeft: "6px", marginTop: "5px", fontSize: "14px" }}
                >
                  Time horizon
                  <HelpIconTooltip
                    tooltipdata={
                      "What dates are these projections valid for? You should ideally go out about 3yrs. Market share capture won't happen overnight, and markets can grow and decline. These should be factored into your calculations. "
                    }
                  />
                </Typography>
              </Box>
              <Box>
                <CustomTextfield
                  value={details.timeHorizon}
                  name="timeHorizon"
                  width={"12vw"}
                  padding={"0px 0px"}
                  border={"none"}
                  disabled={true}
                />
              </Box>
            </Box>
          </Box>
          {/* --key-- */}
          <Box
            sx={{
              border      : "1px solid #bdc9c1",
              borderRadius: "4px",
              marginTop   : "20px"
            }}
          >
            <Typography sx={{ margin: "10px 0px 10px 6px" }}>
              Key Assumptions
              <HelpIconTooltip
                tooltipdata={
                  "List out your key sources & assumptions behind the numbers you use in these calculations. \nFor e.g. Did you focus on particular geographies? \nWhere did you source the total market numbers, and how recent or forward looking are they? \nWhat industry segments/ categories did you assume for sizing, that are relevant for your venture? \nWhat assumption did you make for your market capture penetration rate? \nWhat risks and data points did you consider to ensure this estimate is reasonable and achievable? \nList out each note as a separate bullet point."
                }
                width={"500px"}
              />
            </Typography>
            <Box sx={{ borderTop: "1px solid #bdc9c1" }}>
              <CustomMultilineTextfield
                value={details.keyAssumptions}
                name="keyAssumptions"
                maxRows={5}
                minRows={5}
                placeholder={
                  "Some examples:\n1. Assumed rollout in 10 cities in year 1, with 5% market share capture in each. Expand to 100 cities by year 3, with average 15% share capture across.\n2. Degree of competitive threat, logistical hurdles, and consumer demand varies in each market, some quite significantly than others, but for this market size estimate we have roughly factored that into our average market penetration assumption of 10%. Penetration may be much higher than average in some markets, and lower in others.\n3. For total addressable market we have also considered products we don’t have yet but plan to launch in year 2.\n4. Market data sourced from WBA Annual Industry report 2020."
                }
                width={"100%"}
                border={"none"}
                // fontSize={"14px"}
                padding={"13px 10px"}
                disabled={true}
              />
            </Box>
          </Box>
        </Box>
        {/* chart */}
        <Box
          sx={{
            border      : "1px solid #bdc9c1",
            width       : "53%",
            borderRadius: "4px"
          }}
        >
          <Box
            sx={{
              display       : "flex",
              alignItems    : "center",
              justifyContent: "center",
              flexDirection : "column",
              height        : "100%"
            }}
          >
            {/* <Typography>Market Size Chart</Typography>
                      <Typography
                        sx={{
                          color: "#FF7F00",
                          border: "1px solid #FF7F00",
                          padding: "8px",
                        }}
                      >
                        Coming Soon
                      </Typography>*/}
            <CustomChart
              totalAddressableMarket={{
                name          : "Total Addressable Market",
                label         : "TAM",
                formattedValue: numberWithCommas(
                  details.totalAddressableMarket
                )
              }}
              segmentedAddressableMarket={{
                name          : "Segmented Addressable Market",
                label         : "SAM",
                formattedValue: numberWithCommas(
                  details.segmentedAddressableMarket
                )
              }}
              ourExpectedMarketShare={{
                name          : "Expected Share of Market",
                label         : "SOM",
                formattedValue: numberWithCommas(
                  details.ourExpectedMarketShare
                )
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );

}
