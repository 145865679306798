import {
  Box, Button, Grid, LinearProgress, Typography
} from "@mui/material";
import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import ErrorMessage from "../../../../components/ErrorMessage";
import ModuleSections from "../../../../components/ModuleSections";

import axiosService from "../../../../services/axiosService";
import styles from "./PlOutlook.module.css";

const UploadFinanceExcels = () => {

  const [ loading, setLoading ] = useState(false);
  const [ isFileTooLarge, setIsFileTooLarge ] = useState(false);
  const [ progress, setProgress ] = useState(0);
  const [ successmsg, setSuccessmsg ] = useState("");
  const [ errormsg, setErrormsg ] = useState("");

  const { venture } = useSelector(state => ({ ...state }));

  const history = useHistory();

  const onDrop = async (acceptedFiles, rejectionFiles) => {

    setLoading(true);
    try {

      // console.log(acceptedFiles, rejectionFiles);
      if (rejectionFiles.length > 0 && rejectionFiles[0].file.size > 2097152) {

        setIsFileTooLarge(true);
        setLoading(false);
        setTimeout(() => {

          setIsFileTooLarge(false);
        
        }, 5000);
        
        return;
      
      }
      const formData = new FormData();

      formData.append("excels", acceptedFiles[0]);
      formData.append("ventureid", venture._id);
      formData.append("financialsid", venture.financials);
      const response = await axiosService.post(
        `${process.env.REACT_APP_ADDRESS}/upload_excels`,
        formData,
        {
          onUploadProgress: ProgressEvent => {

            const { loaded, total } = ProgressEvent;
            const percentage = Math.floor(
              ((loaded / 1000) * 100) / (total / 1000)
            );

            setProgress(percentage);
          
          }
        }
      );
      // console.log(response);

      setSuccessmsg(response.data);
      setTimeout(() => {

        history.push("/ploutlook");
      
      }, 2000);
    
    } catch (error) {

      console.error(error);
      setErrormsg("File type not accepted");
      setLoading(false);
      setTimeout(() => {

        setErrormsg("");
      
      }, 5000);
    
    }
  
  };

  // console.log(progress);

  return (
    <ModuleSections>
      <Grid item xs={ 12 } sx={ { padding: "10px 60px" } }>
        <Box id={ styles.box2 }>
          <Box sx={ { display: "flex", alignItems: "center" } }>
            <Typography sx={ { fontWeight: "bold" } }>Finance</Typography>
            <ChevronRightIcon sx={ { color: "#707070", margin: "0px 10px" } } />
            <Typography>P&L Outlook</Typography>
          </Box>
        </Box>
        <Dropzone
          onDrop={ onDrop }
          accept=" application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          minSize={ 0 }
          maxSize={ 2097152 }
        >
          { ({
            getRootProps,
            getInputProps,
            isDragActive,
            isDragReject
            // fileRejections
          }) => {

            return (
              <Grid
                container
                id={ styles.uploadgrid }
                sx={ { marginTop: "30px" } }
                { ...getRootProps() }
              >
                <Box id={ styles.uploadbox } component="span">
                  { !isDragActive &&
                    <Typography sx={ { textAlign: "center", fontSize: "12px" } }>
                      Upload your files.
                    </Typography>
                  }
                  { isDragActive && !isDragReject &&
                    <Typography>Drop Here</Typography>
                  }
                  { isDragReject &&
                    <ErrorMessage errormsg={ "File type not accepted" } />
                  }
                  { loading && !isFileTooLarge && !errormsg ?
                    <LinearProgress variant="determinate" value={ progress } />
                    : errormsg ?
                      <ErrorMessage errormsg={ "File type not accepted" } />
                      : isFileTooLarge ?
                        <ErrorMessage
                          errormsg={
                            "File is too large. Upload a file under 2mb size "
                          }
                        />
                        :
                        <img
                          src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/import.png` }
                          id={ styles.imgicon }
                        />
                  }

                  { successmsg ?
                    <>
                      <CheckCircleOutlineIcon
                        sx={ { color: "#108E00", marginLeft: "40%" } }
                        fontSize="large"
                      />
                      <Typography sx={ { fontSize: "12px" } }>
                        { successmsg }
                      </Typography>
                    </>
                    :
                    <Typography
                      sx={ {
                        fontSize     : "12px",
                        color        : "#071AFF",
                        display      : "flex",
                        flexDirection: "column"
                      } }
                    >
                      <span>Click to Upload file</span>
                      Limit 1 file. File must be .xlsx format & under 2MB.
                    </Typography>
                  }
                </Box>
                <input { ...getInputProps() } />
              </Grid>
            );
          
          } }
        </Dropzone>
        <Button
          style={ {
            textTransform: "none",
            fontWeight   : "bold",
            marginRight  : "30px",
            float        : "left",
            color        : "#071AFF"
          } }
          onClick={ () => history.push("/ploutlook") }
        >
          <ArrowLeftIcon style={ { fontSize: "xx-large" } } /> back
        </Button>
      </Grid>
    </ModuleSections>
  );

};

export default UploadFinanceExcels;
