import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box, IconButton, Menu, MenuItem, TextField
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import styles from "./IndividualPriceAnchor.module.css";

export default function IndividualPriceAnchor(props) {

  const [ anchorEl, setAnchorEl ] = useState(null);
  const { venture } = useSelector(state => ({ ...state }));

  const open = Boolean(anchorEl);

  const handleClick = event => {

    setAnchorEl(event.currentTarget);
  
  };
  const handleClose = () => {

    setAnchorEl(null);
  
  };

  // console.log(props.priceAnchor);
  return (
    <Box
      id={styles.individualAnchorMainBox}
      // sx={{
      //   width: "100%",
      //   // border: 1,
      //   height: "55px",
      //   marginTop: "10px",
      //   display: "flex",
      //   justifyContent: "center",
      //   alignItems: "center",
      //   backgroundColor: "#F7F7F7",
      //   borderRadius: "4px",
      // }}
    >
      <Box
        id={styles.anchorSubBox}
        // sx={{
        //   width: "100%",
        //   display: "flex",
        //   justifyContent: "space-evenly",
        //   // marginTop: "10px",
        // }}
      >
        {/* -----1----- */}
        <Box
          id={styles.textFieldBox}
          // sx={{
          //   // border: 1,
          //   width: "33%",
          //   textAlign: "center",
          //   display: "flex",
          //   justifyContent: "center",
          //   alignItems: "center",
          //   borderRight: "1px solid #D0D0D0",
          // }}
        >
          <TextField
            onChange={e => {

              props.handleChangePriceAnchors(
                "name",
                props.revenueStreamIndex,
                props.priceAnchorIndex,
                e.target.value
              );
            
            }}
            disabled={venture.userRole === "Commenter" ? true : false}
            value={props.priceAnchor.name}
            fullWidth
            //   placeholder="Eg. Subscriptions"
            sx={{
              // "& .MuiOutlinedInput-root": {
              //   padding: "0px 0px",
              // },
              "& .MuiInputBase-input": {
                fontSize: "12px",
                padding : "12px 10px",
                border  : "none",
                color   : "#707070"
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none"
                //   border: "1px solid #B4B4FA",
              }
            }}
          />
        </Box>
        {/* --------- */}
        {/* -----2----- */}
        <Box
          id={styles.textFieldBox}
          // sx={{
          //   // border: 1,
          //   width: "33%",
          //   textAlign: "center",
          //   display: "flex",
          //   justifyContent: "center",
          //   alignItems: "center",
          //   borderRight: "1px solid #D0D0D0",
          // }}
        >
          <TextField
            onChange={e => {

              props.handleChangePriceAnchors(
                "lowPrice",
                props.revenueStreamIndex,
                props.priceAnchorIndex,
                e.target.value
              );
            
            }}
            disabled={venture.userRole === "Commenter" ? true : false}
            value={props.priceAnchor.lowPrice}
            fullWidth
            //   placeholder="Eg. Subscriptions"
            sx={{
              // "& .MuiOutlinedInput-root": {
              //   padding: "0px 0px",
              // },
              "& .MuiInputBase-input": {
                fontSize: "12px",
                padding : "12px 10px",
                border  : "none",
                color   : "#707070"
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none"
                //   border: "1px solid #B4B4FA",
              }
            }}
          />
        </Box>
        {/* --------- */}
        {/* -----3----- */}
        <Box
          id={styles.textFieldBox3}
          // sx={{
          //   width: "33%",
          //   textAlign: "center",
          //   display: "flex",
          //   justifyContent: "center",
          //   alignItems: "center",
          // }}
        >
          <TextField
            onChange={e => {

              props.handleChangePriceAnchors(
                "highPrice",
                props.revenueStreamIndex,
                props.priceAnchorIndex,
                e.target.value
              );
            
            }}
            disabled={venture.userRole === "Commenter" ? true : false}
            value={props.priceAnchor.highPrice}
            fullWidth
            sx={{
              "& .MuiInputBase-input": {
                fontSize: "12px",
                padding : "12px 10px",
                border  : "none",
                color   : "#707070"
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none"
              }
            }}
          />

          <Box>
            <IconButton
              onClick={e => {

                handleClick(e);
              
              }}
              disabled={venture.userRole === "Commenter" ? true : false}
              style={{ padding: "0px" }}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={() => {

                handleClose();
              
              }}
              style={{ top: -34, left: -14 }}
              anchorOrigin={{
                vertical  : "top",
                horizontal: "right"
              }}
              sx={{
                "& .MuiMenu-list": {
                  padding        : "0px 6px",
                  backgroundColor: "#707070",
                  color          : "white"
                }
              }}
            >
              <MenuItem
                onClick={e => {

                  e.stopPropagation();
                  props.removePriceAnchors(
                    props.revenueStreamIndex,
                    props.priceAnchorIndex
                  );
                  handleClose();
                
                }}
                sx={{ fontSize: "12px", padding: "3px" }}
              >
                Delete Row
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        {/* --------- */}
      </Box>
    </Box>
  );

}
