import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box, Button, Grid, IconButton, InputAdornment, LinearProgress, OutlinedInput, Tooltip, Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Country } from "country-state-city";
import React, { useEffect, useState } from "react";
import {
  isValidPhoneNumber
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import BoxWithoutIcon from "../../../components/BoxWithoutIcon";
import CustomSelect from "../../../components/CustomSelect";
import CustomTextField from "../../../components/CustomTextfield";
import ErrorMessage from "../../../components/ErrorMessage";
import {
  strengthColor,
  strengthIndicator,
  strengthText
} from "../../../components/PasswordStrength";
import { auth } from "../../../firebase";
import { createOrUpdateUser, UpdateUserDetails } from "../../../services/auth";
import styles from "./Register.module.css";

const useStyles = makeStyles(() => ({
  customWidth: {
    maxWidth       : 200,
    fontSize       : 10,
    backgroundColor: "white",
    color          : "#707070",
    boxShadow      : "0px 3px 6px #00000029",
    border         : "1px solid #707070",
    borderRadius   : 10
  }
}));

const RegisterDetails = ({ history }) => {

  const [ details, setDetails ] = useState({
    firstname          : "",
    lastname           : "",
    password           : "",
    confirmpassword    : "",
    location           : "",
    phone              : "",
    organizationName   : "",
    linkedin           : "",
    showPassword       : false,
    showConfirmPassword: false
  });
  const [ email, setEmail ] = useState("");
  const [ errormsg, setErrormsg ] = useState("");
  // const [ countrycode, setCountrycode ] = useState("");
  const [ phonecode, setPhonecode ] = useState("");
  const [ passworderrormsg, setPassworderrormsg ] = useState("");
  const hashvalue = history.location.hash.substring(1);
  const classes = useStyles();

  // password strength indicator
  const strength = strengthIndicator(details.password);
  const strengthcolor = strengthColor(strength);
  const strengthtext = strengthText(strength);
  const normalise = strength => ((strength - 1) * 100) / (5 - 1);
  let location = useLocation();
  const href = hashvalue === "google" ? "" : location.state.href;

  let dispatch = useDispatch();
  const { user } = useSelector(state => ({ ...state }));

  const handledetailsChange = event => {

    const name = event.target.name;
    const value = event.target.value.trimStart();

    setDetails(values => ({ ...values, [name]: value }));
  
  };

  const handleOtherDetailsChange = (name, value) => {

    setDetails(values => ({ ...values, [name]: value }));
  
  };
  const handleClickShowPassword = () => {

    setDetails({
      ...details,
      showPassword: !details.showPassword
    });
  
  };
  const handleClickShowConfirmPassword = () => {

    setDetails({
      ...details,
      showConfirmPassword: !details.showConfirmPassword
    });
  
  };

  // dropdown for location
  const countries = Country.getAllCountries();
  const updatedCountries = countries.map(country => ({
    label: country.name,
    value: country.name,
    // phonecode: country.phonecode.includes("+")
    //   ? `(${country.phonecode})`
    //   : `(+${country.phonecode})`,
    ...country
  }));

  useEffect(() => {

    setEmail(
      hashvalue === "google"
        ? user.email
        : window.localStorage.getItem("emailForRegistration")
    );
  
  }, [ user, history ]);

  const handleSubmit = async e => {

    e.preventDefault();

    // validation
    if (
      details.password === details.confirmpassword &&
      details.firstname !== "" &&
      details.lastname !== "" &&
      email !== "" &&
      details.password !== "" &&
      details.confirmpassword !== "" &&
      details.location !== "" &&
      details.password.match(
        "(?!^[0-9]*$)(?!^[a-zA-Z]*$)^([a-zA-Z0-9!#@$%^&*)(+=._-]{6,15})$"
      )
    ) {

      try {

        if (
          (details.linkedin !== "" &&
            /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/gm.test(
              details.linkedin
            )) ||
          (details.phone !== "" && isValidPhoneNumber(details.phone))
        ) {

          const result = await auth.signInWithEmailLink(email, href);

          if (result.user.emailVerified) {

            // remove user from local Storage
            window.localStorage.removeItem("emailForRegistration");
            // get user id token
            let user = auth.currentUser;

            // changes default to currently typed password
            await user.updatePassword(details.password);
            const idTokenResult = await user.getIdTokenResult();
            // redux store

            createOrUpdateUser()
              .then(() => {

                // dispatch({
                //   type: "LOGGED_IN_USER",
                //   payload: {
                //     name: res.data.name,
                //     email: res.data.email,
                //     token: idTokenResult.token,
                //     role: res.data.role,
                //     _id: res.data._id,
                //   },
                // });

                UpdateUserDetails(details)
                  .then(res => {

                    dispatch({
                      type   : "LOGGED_IN_USER",
                      payload: {
                        firstname       : res.data.user.firstname,
                        lastname        : res.data.user.lastname,
                        email           : res.data.user.email,
                        token           : idTokenResult.token,
                        subscription    : res.data.user.subscription,
                        access          : res.data.user.access,
                        profileIcon     : res.data.profileUrl,
                        userVerified    : res.data.user.userVerified,
                        organizationName: res.data.user.organizationName,
                        location        : res.data.user.location,
                        createdAt       : res.data.user.createdAt,
                        _id             : res.data.user._id
                      }
                    });
                  
                  })
                  .catch(error => {

                    //
                    console.error(error);
                  
                  });
                // redirect
                history.push("/welcomepage");
              
              })
              .catch(error => {

                //
                console.error(error);
              
              });
          
          }
          // console.error("Your email has been added successfully");
        
        } else if (
          details.linkedin === "" &&
          (details.phone === `+${phonecode.replace(/[^0-9\.]+/g, "")}` ||
            details.phone === "")
        ) {

          const result = await auth.signInWithEmailLink(email, href);

          if (result.user.emailVerified) {

            // remove user from local Storage
            window.localStorage.removeItem("emailForRegistration");
            // get user id token
            let user = auth.currentUser;

            // changes default to currently typed password
            await user.updatePassword(details.password);
            const idTokenResult = await user.getIdTokenResult();
            // redux store

            createOrUpdateUser()
              .then(() => {

                // dispatch({
                //   type: "LOGGED_IN_USER",
                //   payload: {
                //     name: res.data.name,
                //     email: res.data.email,
                //     token: idTokenResult.token,
                //     role: res.data.role,
                //     _id: res.data._id,
                //   },
                // });
                UpdateUserDetails(details)
                  .then(res => {

                    dispatch({
                      type   : "LOGGED_IN_USER",
                      payload: {
                        firstname       : res.data.user.firstname,
                        lastname        : res.data.user.lastname,
                        email           : res.data.user.email,
                        token           : idTokenResult.token,
                        subscription    : res.data.user.subscription,
                        access          : res.data.user.access,
                        profileIcon     : res.data.profileUrl,
                        userVerified    : res.data.user.userVerified,
                        organizationName: res.data.user.organizationName,
                        location        : res.data.user.location,
                        createdAt       : res.data.user.createdAt,
                        _id             : res.data.user._id
                      }
                    });
                  
                  })
                  .catch(error => {

                    //
                    console.error(error);
                  
                  });
                // redirect
                history.push("/welcomepage");
              
              })
              .catch(error => {

                //
                console.error(error);
              
              });
          
          }
          console.error("Your email has been added successfully");
        
        } else {

          if (
            details.firstname !== "" &&
            details.lastname !== "" &&
            email !== "" &&
            details.password !== "" &&
            details.confirmpassword !== "" &&
            details.location !== "" &&
            !isValidPhoneNumber(details.phone)
          ) {

            setErrormsg("Please fill valid phone number");
          
          } else {

            setErrormsg("Please enter a valid URL");
          
          }
          setTimeout(() => {

            setErrormsg("");
          
          }, 5000);
        
        }
      
      } catch (error) {

        setErrormsg(
          "The email provided does not match with sign-up email address"
        );
        setTimeout(() => {

          setErrormsg("");
        
        }, 5000);
      
      }
    
    } else {

      if (
        details.password !== details.confirmpassword ||
        details.password === ""
      ) {

        setPassworderrormsg(
          "The two passwords don't match. Please re-enter them"
        );
      
      } else {

        if (
          !details.password.match(
            "(?!^[0-9]*$)(?!^[a-zA-Z]*$)^([a-zA-Z0-9!#@$%^&*)(+=._-]{6,15})$"
          )
        ) {

          setPassworderrormsg("Password does not meet requirements");
        
        }
      
      }
      if (
        details.firstname === "" ||
        details.lastname === "" ||
        email === "" ||
        details.password === "" ||
        details.confirmpassword === "" ||
        details.location === ""
      ) {

        setErrormsg("Please fill in the required (*) fields.");
      
      }
      setTimeout(() => {

        setPassworderrormsg("");
        setErrormsg("");
      
      }, 5000);
    
    }
  
  };

  const handleGoogleSUbmit = async e => {

    e.preventDefault();
    if (
      details.firstname !== "" &&
      details.lastname !== "" &&
      details.location !== ""
    ) {

      if (
        (details.linkedin !== "" &&
          /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/gm.test(
            details.linkedin
          )) ||
        (details.phone !== "" && isValidPhoneNumber(details.phone))
      ) {

        UpdateUserDetails(details)
          .then(res => {

            dispatch({
              type   : "LOGGED_IN_USER",
              payload: {
                firstname       : res.data.user.firstname,
                lastname        : res.data.user.lastname,
                email           : res.data.user.email,
                token           : user.token,
                subscription    : res.data.user.subscription,
                access          : res.data.user.access,
                profileIcon     : res.data.profileUrl,
                userVerified    : res.data.user.userVerified,
                organizationName: res.data.user.organizationName,
                location        : res.data.user.location,
                createdAt       : res.data.user.createdAt,
                _id             : res.data.user._id
              }
            });
          
          })
          .catch(error => {

            console.error(error);
          
          });
        history.push("/welcomepage");
      
      } else if (details.linkedin === "" || details.phone === "") {

        UpdateUserDetails(details)
          .then(res => {

            dispatch({
              type   : "LOGGED_IN_USER",
              payload: {
                firstname       : res.data.user.firstname,
                lastname        : res.data.user.lastname,
                email           : res.data.user.email,
                token           : user.token,
                subscription    : res.data.user.subscription,
                access          : res.data.user.access,
                profileIcon     : res.data.profileUrl,
                userVerified    : res.data.user.userVerified,
                organizationName: res.data.user.organizationName,
                location        : res.data.user.location,
                createdAt       : res.data.user.createdAt,
                _id             : res.data.user._id
              }
            });
          
          })
          .catch(error => {

            console.error(error);
          
          });
        history.push("/welcomepage");
      
      } else {

        if (
          details.firstname !== "" &&
          details.lastname !== "" &&
          details.location !== "" &&
          !isValidPhoneNumber(details.phone)
        ) {

          setErrormsg("Please fill valid phone number");
        
        } else {

          setErrormsg("Please enter a valid URL");
        
        }
        setTimeout(() => {

          setErrormsg("");
        
        }, 5000);
      
      }
    
    } else {

      if (
        details.firstname === "" ||
        details.lastname === "" ||
        details.location === ""
      ) {

        setErrormsg("Please fill in the required (*) fields.");
      
      }
      setTimeout(() => {

        setErrormsg("");
      
      }, 5000);
    
    }
  
  };

  const removeNonNumeric = num => num.toString().replace(/[^0-9+]/g, "");

  return (
    <BoxWithoutIcon>
      <Grid container id={ styles.maingrid }>
        <Grid
          item
          component="form"
          xs={ 12 }
          md={ 7 }
          id={ styles.detailsgrid1 }
          onSubmit={ hashvalue === "google" ? handleGoogleSUbmit : handleSubmit }
        >
          <Typography fontWeight="bold" mb={ "5px" }>
            Finish Signing Up
          </Typography>
          <Grid container columnSpacing={ 5 } id={ styles.detailsgrid2 }>
            <Grid item md={ 6 } id={ styles.detailsgriditem }>
              <CustomTextField
                label={ "First name*" }
                labelfontsize={ "13px" }
                name={ "firstname" }
                type={ "text" }
                error={
                  errormsg ? details.firstname === "" ? true : false : false
                }
                value={ details.firstname }
                handledetailsChange={ handledetailsChange }
              />
            </Grid>
            <Grid item md={ 6 } id={ styles.detailsgriditem }>
              <CustomTextField
                label={ "Last name*" }
                labelfontsize={ "13px" }
                name={ "lastname" }
                type={ "text" }
                error={
                  errormsg ? details.lastname === "" ? true : false : false
                }
                value={ details.lastname }
                handledetailsChange={ handledetailsChange }
              />
            </Grid>
          </Grid>
          <CustomTextField
            label={ "Email*" }
            labelfontsize={ "13px" }
            name={ "email" }
            type={ "text" }
            value={ email }
            handledetailsChange={ e => {

              setEmail(e.target.value.trimStart());
            
            } }
            disabled={
              window.localStorage.getItem("emailForRegistration") ? true : false
            }
          />
          { hashvalue === "google" ?
            <></>
            :
            <Grid container columnSpacing={ 5 } id={ styles.detailsgrid2 }>
              <Grid item md={ 6 } id={ styles.detailsgriditem }>
                <label htmlFor="password" id={ styles.labeltext }>
                  Choose a password*
                </label>
                <Tooltip
                  disableFocusListener
                  title="Password must have at least 6 characters. For a strong password, use upper & lower case letters, numbers, and symbols like !@#$%&*"
                  placement="bottom"
                  classes={ { tooltip: classes.customWidth } }
                >
                  <OutlinedInput
                    error={ passworderrormsg ? true : false }
                    type={ details.showPassword ? "text" : "password" }
                    value={ details.password }
                    name="password"
                    onChange={ handledetailsChange }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={ handleClickShowPassword }
                          edge="end"
                        >
                          { details.showPassword ?
                            <Visibility />
                            :
                            <VisibilityOff />
                          }
                        </IconButton>
                      </InputAdornment>
                    }
                    sx={ {
                      "& .MuiOutlinedInput-input": { height: "0px" },
                      marginBottom               : "10px"
                    } }
                  />
                </Tooltip>
              </Grid>
              <Grid item md={ 6 } id={ styles.detailsgriditem }>
                <label htmlFor="confirmpassword" id={ styles.labeltext }>
                  Retype your Password*
                </label>
                <Tooltip
                  disableFocusListener
                  title="Password must have at least 6 characters. For a strong password, use upper & lower case letters, numbers, and symbols like !@#$%&*"
                  placement="bottom"
                  classes={ { tooltip: classes.customWidth } }
                >
                  <OutlinedInput
                    error={ passworderrormsg ? true : false }
                    type={ details.showConfirmPassword ? "text" : "password" }
                    value={ details.confirmpassword }
                    name="confirmpassword"
                    onChange={ handledetailsChange }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={ handleClickShowConfirmPassword }
                          edge="end"
                        >
                          { details.showConfirmPassword ?
                            <Visibility />
                            :
                            <VisibilityOff />
                          }
                        </IconButton>
                      </InputAdornment>
                    }
                    sx={ {
                      "& .MuiOutlinedInput-input": { height: "0px" },
                      marginBottom               : "10px"
                    } }
                  />
                </Tooltip>
              </Grid>
              { passworderrormsg ?
                <Grid
                  container
                  justifyContent="center"
                  alignContent="center"
                  style={ {
                    width   : "340px",
                    position: "absolute",
                    left    : "100%",
                    margin  : "10px",
                    top     : "0",
                    height  : "100%"
                  } }
                >
                  <Box
                    style={ {
                      display        : "flex",
                      padding        : "10px 20px",
                      border         : "solid 1px #EBBAB9",
                      backgroundColor: "#FFEFEF",
                      color          : "#FD1515",
                      borderRadius   : "4px"
                    } }
                  >
                    <InfoOutlinedIcon
                      fontSize="small"
                      sx={ { marginRight: "10px" } }
                    />
                    <Typography sx={ { color: "#FD1515", fontSize: "14px" } }>
                      { passworderrormsg }
                    </Typography>
                  </Box>
                </Grid>
                : details.password ?
                  <Grid
                    sx={ {
                      position    : "absolute",
                      left        : "100%",
                      top         : "0",
                      margin      : "10px",
                      padding     : "20px 10px",
                      width       : "200px",
                      border      : "solid 1px #cccccc",
                      borderRadius: "10px"
                    } }
                  >
                    <Typography sx={ { fontSize: "13px", marginBottom: "10px" } }>
                    Password strength : { strengthtext }
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={ normalise(strength) }
                      sx={ {
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: strengthcolor
                        },
                        borderRadius: "10px"
                      } }
                    />
                  </Grid>
                  :
                  <></>
              }
            </Grid>
          }
          <CustomSelect
            error={ errormsg ? details.location === "" ? "#FD1515" : "" : "" }
            labelfontsize={ "13px" }
            label={ "Country*" }
            name={ "location" }
            value={ details.location }
            options={ updatedCountries }
            placeholder={ "Select your Location" }
            handleChange={ e => {

              setPhonecode(e.phonecode);
              // setCountrycode(e.isoCode);
              handleOtherDetailsChange("location", e.value);
              handleOtherDetailsChange(
                "phone",
                `+${e.phonecode.replace(/[^0-9\.]+/g, "")}`
              );
            
            } }
          />
          {/* <label htmlFor="phone" id={styles.labeltext}>
            Phone
          </label>
          <Input
            country={countrycode}
            international
            withCountryCallingCode
            value={details.phone}
            onChange={(e) => {
              handleOtherDetailsChange("phone", e);
            }}
            disabled={details.location === "" ? true : false}
            iserror={
              errormsg
                ? details.phone && isValidPhoneNumber(details.phone)
                  ? false
                  : true
                : false
            }
            inputComponent={PhoneNumber}
          />*/}
          <CustomTextField
            error={
              errormsg
                ? details.phone && isValidPhoneNumber(details.phone)
                  ? false
                  : true
                : false
            }
            label={ "Phone" }
            labelfontsize={ "13px" }
            name={ "phone" }
            type={ "text" }
            value={ details.phone }
            handledetailsChange={ e => {

              handleOtherDetailsChange(
                "phone",
                removeNonNumeric(e.target.value)
              );
            
            } }
          />
          <CustomTextField
            label={ "Company/Organization" }
            labelfontsize={ "13px" }
            name={ "organizationName" }
            type={ "text" }
            value={ details.organizationName }
            handledetailsChange={ handledetailsChange }
          />
          <CustomTextField
            error={
              errormsg
                ? details.linkedin &&
                  !/^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/gm.test(
                    details.linkedin
                  )
                  ? true
                  : false
                : false
            }
            label={ "LinkedIn profile" }
            labelfontsize={ "13px" }
            name={ "linkedin" }
            type={ "text" }
            value={ details.linkedin }
            handledetailsChange={ handledetailsChange }
            placeholder={ "https://in.linkedin.com/" }
          />
          <Typography sx={ { fontSize: "13px" } }>
            By clicking Agree & Continue below, I agree that I have read and
            accepted the
            <span
              style={ {
                color     : "#071AFF",
                fontWeight: "bold",
                marginLeft: "5px"
              } }
            >
              Terms & Policies.
            </span>
          </Typography>
          <Grid container>
            <Grid item md={ 5 }>
              <Button
                variant="contained"
                sx={ {
                  width          : "182px",
                  backgroundColor: "#071AFF",
                  marginTop      : "10px",
                  height         : "40px",
                  textTransform  : "capitalize"
                } }
                type="submit"
              >
                Agree & Continue
              </Button>
            </Grid>
            <Grid item md={ 7 } pt={ "10px" }>
              { errormsg && <ErrorMessage errormsg={ errormsg } /> }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </BoxWithoutIcon>
  );

};

export default RegisterDetails;
