/* eslint-disable camelcase */
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box, Dialog, Grid, IconButton, Slide, Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CustomMultilineTextfield from "../../../components/CustomMultilineTextfield";
import HelpIconTooltip from "../../../components/HelpIconTooltip";
import IndividualSectionHeaders from "../../../components/IndividualSectionHeaders";
import ModuleSections from "../../../components/ModuleSections";
import NextButton from "../../../components/NextButton";
import { useUnmount } from "../../../components/useUnmount";
import Data from "../../../Data/Gtm.json";
import {
  createGtmComment,
  deleteGtmComment,
  updateGtmComment
} from "../../../services/comment";
import { getGtm, updateGtmValues } from "../../../services/gtm";
import { getSegments } from "../../../services/persona";
import SegmentsPersonasDialog from "../Customer/TargetSegments/SegmentsPersonasDialog";
import styles from "./GTM.module.css";

const Transition = forwardRef(function Transition (props, ref) {

  return <Slide direction="up" ref={ ref } { ...props } />;

});

const useStyles = makeStyles(() => ({
  customWidth: {
    width          : 200,
    fontSize       : 10,
    backgroundColor: "white",
    color          : "#707070",
    boxShadow      : "0px 3px 6px #00000029",
    border         : "1px solid #707070",
    borderRadius   : 10
  },
  individualBox: {
    boxShadow   : "0px 3px 6px #00000029",
    border      : "1px solid #CCCCCC",
    borderRadius: "10px",
    width       : "100%"

  },
  question: {
    border      : "1px solid #071AFF",
    borderRadius: "10px",
    padding     : "15px 20px",
    fontSize    : "14px",
    fontWeight  : "bold",
    paddingRight: "0px",
    height      : "40px"
  },
  answer: {
    width                               : "100%",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    },
    "& .MuiInputBase-input": {
      fontSize: "12px"
    }
  },
  helpicon: {
    color   : "#5F5E5E",
    fontSize: "18px"
  },
  chevronicon: {
    // position: "absolute",
    width    : "40px",
    // top: "5%",
    // left: "87%",
    // zIndex: 100,
    marginTop: "15px"
  }
}));

const GTMBuyerJourney = () => {

  const [ details, setDetails ] = useState({
    allMeansApproach     : "",
    awarenessInterest    : "",
    interestConsideration: "",
    considerationDecision: ""
  });
  const [ comments, setComments ] = useState([]);
  const [ segments, setSegments ] = useState([]);
  const [ dialogbox, setDialog ] = useState(false);
  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const [ segmentdialogbox, setSegmentDialog ] = useState(false);
  const history = useHistory();
  const pathname = history.location.pathname.substring(1);
  const content = Data[pathname];
  const [ isDataLoading, setIsDataLoading ] = useState(true);

  const classes = useStyles();

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  const segmentDialogopen = () => {

    setSegmentDialog(true);
  
  };
  const segmentDialogclose = () => {

    setSegmentDialog(false);
  
  };

  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);
    
    } else {

      setCollapsecomment(true);
    
    }
  
  };
  const { user, venture } = useSelector(state => ({ ...state }));

  const handledetailsChange = event => {

    const name = event.target.name;
    const value = event.target.value;

    setDetails(values => ({ ...values, [name]: value }));
  
  };

  useEffect(() => {

    fetchSegments();
    fetchGtm();
  
  }, []);

  // console.log(content);

  const fetchGtm = async () => {

    setIsDataLoading(true);

    try {

      const response = await getGtm(venture.gtm);
      // console.log(response.data);
      const gtm = response.data;

      setDetails(gtm[content["sectionname"]]);
      setComments(gtm["comments"][content["sectionname"]]);
      setIsDataLoading(false);
    
    } catch (error) {

      console.error(error);
      setIsDataLoading(false);
    
    }
  
  };

  const fetchSegments = async () => {

    setIsDataLoading(true);

    try {

      const response = await getSegments(venture.customer);
      const customer = response.data;

      setSegments(customer.segmentsPersonas.segments);
      setIsDataLoading(false);
    
    } catch (error) {

      console.error(error);
      setIsDataLoading(false);
    
    }
  
  };

  // To get the updated usestate value to save in useEffect cleanup
  const detailsRef = useRef(details);

  detailsRef.current = details;

  let isSubmitting = false;

  // useUnmount => custom hook to save while navigating
  useUnmount(() => {

    if ( isDataLoading == false && !isSubmitting) {

      handleSave();
    
    }
  
  });

  const handleSave = async () => {

    try {

      await updateGtmValues(
        venture.gtm,
        content["sectionname"],
        detailsRef.current
      );
      
      return true;
    
    } catch (error) {

      console.error(error);
      
      return false;
    
    }
  
  };

  const handleSubmit = async () => {

    try {

      isSubmitting = true;

      if(isDataLoading == false){

        await updateGtmValues(
          venture.gtm,
          content["sectionname"],
          details
        );

      }

      history.push("/buyerjourneysummary");
      isSubmitting = false;
    
    } catch (error) {

      isSubmitting = false;
      console.error(error);
    
    }
  
  };

  const addComment = async (commentText, replyTo) => {

    try {

      const response = await createGtmComment(
        commentText,
        user._id,
        content["sectionname"],
        "",
        venture.gtm,
        replyTo,
        window.location.href,
        `GTM > ${content.modulename}`
      );

      setComments(response.data["comments"][content["sectionname"]]);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateComment = async (commentid, commentText) => {

    try {

      const response = await updateGtmComment(
        commentText,
        user._id,
        commentid,
        venture._id,
        window.location.href,
        `GTM > ${content.modulename}`
      );

      setComments(response.data["comments"][content["sectionname"]]);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const deleteComment = async commentid => {

    try {

      const response = await deleteGtmComment(
        content["sectionname"],
        "",
        venture.gtm,
        commentid,
        user._id,
        window.location.href,
        `GTM > ${content.modulename}`
      );

      setComments(response.data["comments"][content["sectionname"]]);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  return (
    <ModuleSections
      Collapsecomment={ Collapsecomment }
      comments={ comments }
      addComment={ addComment }
      deleteComment={ deleteComment }
      updateComment={ updateComment }>
      <div className="box-display-style-for-next-and-back-button-with-justify">
        <div className="box-indiv-header-con">
          <IndividualSectionHeaders
            mainheading={ content.title }
            subheading={ content.subtitle }
            headingcontent={ content.description }
            isGtm={ true }
            Dialogopen={ Dialogopen }
            Dialogclose={ Dialogclose }
            dialogbox={ dialogbox }
            collapse_comment_function={ collapse_comment_function }
            content={ content }
            isSaveButton={ true }
            handleSave={ handleSave }
          />
        </div>

        <Grid item xs={ 12 } id={ styles.maingrid }>
          <Typography
            variant="subtitle2"
            sx={ { color: "#071AFF", cursor: "pointer", fontWeight: "bold" } }
            component="span"
            onClick={ () => {

              segmentDialogopen();
            
            } }>
            &lt; List of Segments and Personas
          </Typography>
          <Dialog
            open={ segmentdialogbox }
            TransitionComponent={ Transition }
            keepMounted
            PaperProps={ {
              style: {
                borderRadius   : "20px",
                backdropFilter : "blur(10px)",
                backgroundColor: "rgba(255,255,255,0.80)",
                margin         : "20px",
                padding        : "0% 2%",
                height         : "100%"
              }
            } }
            maxWidth="md"
            fullWidth>
            <Box sx={ { alignSelf: "flex-end" } }>
              <IconButton component="span" onClick={ segmentDialogclose }>
                <HighlightOffIcon />
              </IconButton>
            </Box>
            <SegmentsPersonasDialog segments={ segments } />
          </Dialog>

          <Grid container mb={ 2 }>
            { content.questions.map((Question, index) => {

              return index === 3 ?
                <Grid item md={ 3 } key={ index }>
                  <Box className={ classes.individualBox }>
                    <div>

                    </div>
                    <Typography
                      id={ styles.buyersJourneyQuestion }
                      className={ classes.question }>
                      { Question.question }

                      <HelpIconTooltip tooltipdata={ Question.tooltip } />
                    </Typography>
                    {/* <TextField
                    multiline
                    maxRows={10}
                    minRows={10}
                    name={Question.name}
                    value={details[Question.name]}
                    className={classes.answer}
                    placeholder={Question.placeholder}
                    onChange={handledetailsChange}
                  /> */}
                    <CustomMultilineTextfield
                      maxRows={ 10 }
                      minRows={ 10 }
                      width={ "100%" }
                      border={ "none" }
                      fontSize={ "12px" }
                      name={ Question.name }
                      value={ details[Question.name] }
                      placeholder={ Question.placeholder }
                      handledetailsChange={ handledetailsChange }
                    />
                  </Box>
                </Grid>
                :
                <Grid item md={ 3 } sx={ {
                  display   : "flex",
                  alignItems: "flex-start"
                } } key={ index }>
                  <Box className={ classes.individualBox }>
                    <Typography
                      id={ styles.buyersJourneyQuestion }
                      className={ classes.question }>
                      { Question.question }

                      <HelpIconTooltip tooltipdata={ Question.tooltip } />
                    </Typography>
                    {/* <TextField
                    multiline
                    maxRows={10}
                    minRows={10}
                    name={Question.name}
                    value={details[Question.name]}
                    className={classes.answer}
                    placeholder={Question.placeholder}
                    onChange={handledetailsChange}
                  /> */}
                    <CustomMultilineTextfield
                      maxRows={ 10 }
                      minRows={ 10 }
                      width={ "100%" }
                      border={ "none" }
                      fontSize={ "12px" }
                      name={ Question.name }
                      value={ details[Question.name] }
                      placeholder={ Question.placeholder }
                      handledetailsChange={ handledetailsChange }
                    />
                  </Box>
                  <img
                    id={ styles.buyersJourneyRightChevron }
                    src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/right-chevron.png` }
                    alt="chevron"
                    className={ classes.chevronicon }
                  />
                </Grid>
              ;
            
            }) }
          </Grid>
        </Grid>
        <div className="ttribe-back-next-button-con">
          <NextButton
            restrictedNextClick={ () => {

              history.push("/buyerjourneysummary");
            
            } }
            nextClick={ () => {

              handleSubmit();
            
            } }
          />
        </div>
      </div>
    </ModuleSections>
  );

};

export default GTMBuyerJourney;
