import {
  Box,
  Button, Collapse, Divider, TextField, Typography
} from "@mui/material";
import React, { useState } from "react";
// import { useSelector } from "react-redux";
import IndividualComment from "./IndividualComment";

// inner box comment
function CommentBox (props) {

  const comment = props.comment;
  const commentid = props.commentid;
  const index = props.index;
  const [ open, setOpen ] = useState(false);
  const [ replyText, setReplyText ] = useState("");

  const handleClick = () => {

    setOpen(!open);
  
  };

  // const { user, venture } = useSelector(state => ({ ...state }));

  return (
    <div key={ index }>
      <IndividualComment
        comment={ comment }
        deleteComment={ props.deleteComment }
        updateComment={ props.updateComment }
      />
      { comment.responses.length > 0 &&
        <Typography
          sx={ { fontSize: "13px", fontWeight: "bold", marginLeft: "20px" } }
        >
          { comment.responses.length }{ " " }
          { comment.responses.length === 1 ? "reply" : "replies" }
        </Typography>
      }
      { comment.responses.length > 0 &&
        <Box sx={ { marginLeft: "13px" } }>
          { comment.responses.map(reply =>
            <IndividualComment
              comment={ reply }
              deleteComment={ props.deleteComment }
              updateComment={ props.updateComment }
            />
            
          ) }
        </Box>
      }
      <Button
        sx={ {
          fontSize     : "12px",
          textTransform: "lowercase",
          color        : "#707070",
          fontWeight   : "bold"
        } }
        onClick={ handleClick }
        size="small"
      >
        reply
      </Button>
      <Collapse in={ open } timeout="auto" unmountOnExit>
        <Box style={ { padding: "10px" } }>
          {/* <textarea
              placeholder="Write your comment.."
              style={{
                resize: "none",
                borderRadius: "5px",
                width: "95%",
                border: "1px solid #c9c3c3",
                fontSize: "12px",
                color: "red",
              }}
            ></textarea> */}
          <TextField
            sx={ {
              "& .MuiOutlinedInput-root": { borderRadius: "10px" }
            } }
            inputProps={ {
              style: {
                // fontSize: "1.4vh",
                fontSize  : "11px",
                fontWeight: "bold",
                padding   : "0px 0px",
                color     : "#707070"
              }
            } }
            // className={classes.textfield}
            placeholder="Write a reply"
            variant="outlined"
            size="small"
            fullWidth
            multiline
            maxRows={ 2 }
            minRows={ 2 }
            value={ replyText }
            onChange={ e => {

              setReplyText(e.target.value.trimStart());
            
            } }
          // InputProps={{
          //   endAdornment: (
          //     <InputAdornment position="end">
          //       <IconButton edge="end" style={{ paddingBottom: "10px" }}>
          //         <SendIcon />
          //       </IconButton>
          //     </InputAdornment>
          //   ),
          // }}
          />
          <Box style={ { textAlign: "end" } }>
            <Button
              style={ {
                fontSize     : "12px",
                textTransform: "capitalize",
                color        : "grey"
                // padding: "10px",
              } }
              onClick={ handleClick }
            >
              Cancel
            </Button>
            <Button
              style={ {
                fontSize     : "12px",
                textTransform: "capitalize"
                // padding: "10px",
              } }
              onClick={ () => {

                if (replyText !== "") {

                  props.addComment(replyText, commentid);
                  setReplyText("");
                  handleClick();
                
                }
              
              } }
            >
              Post
            </Button>
          </Box>
        </Box>
      </Collapse>
      <Divider
        variant="middle"
        style={ {
          margin      : "10px 10px",
          marginBottom: "0px",
          // borderBottom: "solid 1px",
          borderBottom: "1px solid #e3e5ff"
        } }
      ></Divider>
    </div>
  );

}

export default CommentBox;
