import React from "react";
import { Box, Typography } from "@mui/material";
import SinglePagePdfViewer from "./SinglePagePdfViewer";
// import todac2 from "./todac2.pdf";
import "./PitchPdfViewer.css";

export default function PitchPdfViewer() {

  return (
    <Box sx={{ height: "86vh" }}>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Typography>Pdf viewer</Typography>
      </Box>
      {/* <Box sx={{width:"100%"}}> */}
      <Box sx={{ width: "100%" }}>
        {/* <Box sx={{ display: "flex", justifyContent: "center" }}> */}
        {/* <SinglePagePdfViewer /> */}
        <SinglePagePdfViewer pdf={"/pdfDoc/todac2.pdf"} />
      </Box>
      {/* </Box> */}
    </Box>
  );

}
