import { configureStore } from "@reduxjs/toolkit";
// defaults to localStorage for web
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import {
  createStateSyncMiddleware,
  initMessageListener
} from "redux-state-sync";
import rootReducer from "./reducers";

const persistConfig = {
  key      : "root",
  storage,
  blacklist: [ "user" ]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer   : persistedReducer,
  // devTools: process.env.NODE_ENV !== 'production',
  middleware: [
    createStateSyncMiddleware({
      blacklist: [ "persist/PERSIST", "persist/REHYDRATE" ]
      // whitelist: ["user"]
    })
  ]
});

initMessageListener(store);

export default store;
