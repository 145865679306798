import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  Typography,
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip
} from "@mui/material";
import FirstRowBox from "./RowBoxes/FirstRowBox";
import styles from "./Dashboard2.module.css";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";

const LightTooltip = styled(({ className, ...props }) =>
  <Tooltip {...props} classes={{ popper: className }} />
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    // color: "rgba(0, 0, 0, 0.87)",
    boxShadow      : theme.shadows[1],
    fontSize       : "14px",
    color          : "#FF7F00",
    border         : "1px solid #FF7F00",
    paddingTop     : "5px 10px",
    borderRadius   : "10px",
    fontWeight     : "bold"
  }
}));

export default function ModuleBoxGrid(props) {

  const [ dialog, setDialog ] = useState(false);
  const [ selectedvideo, setSelectedvideo ] = useState("");

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  const { user } = useSelector(state => ({ ...state }));

  const percentvalues = props?.percentvalues;

  const findRestrictedModules = access => {

    switch (access) {

    case "freetier":
      return [
        "Concept & Vision",
        "Market",
        "Solution",
        "Monetisation",
        "Financials",
        "Operations",
        "Go-To-Market",
        "Pitch Deck"
      ];
    case "ideation":
      return [
        "Concept Vision", "Monetisation", "Financials", "Go-To-Market"
      ];
    case "admin":
      return [];
    case "pitch":
      return [
        "Concept Vision",
        "Market",
        "Solution",
        "Monetisation",
        "Financials",
        "Operations",
        "Go-To-Market"
      ];
    case "pro":
      return [];
    default:
      return [];
    
    }
  
  };

  let restrictedModules = findRestrictedModules(user.access);

  return (
    <Grid container spacing={2} xs={12} id={styles.mainGridModulesBox}>
      {/* first row  */}
      <Grid className="tribe-dashbaord-first-con-grid" item xs={12}>
        <Dialog
          open={dialog}
          onClose={Dialogclose}
          PaperProps={{
            style: {
              borderRadius   : "20px",
              backdropFilter : "blur(100px)",
              backgroundColor: "initial",
              boxShadow      : "0px 3px 6px #00000029",
              border         : "1px solid #707070"
            }
          }}
          maxWidth="md"
          fullWidth
        >
          <DialogContent sx={{ padding: "0px" }}>
            <video width="100%" height="100%" controls autoPlay>
              <source
                src={`${process.env.REACT_APP_AWS_ASSETS_URL}/videos/${selectedvideo}`}
                type="video/mp4"
              />
            </video>
          </DialogContent>
        </Dialog>
        <Grid container xs={12} id={styles.gridFirstRow}>
          {/* first row boxes */}
          {/* 1 */}
          <Box
            className={styles.firstRowBox}
          >
            <FirstRowBox
              details={percentvalues["conceptvision"]}
              boxTitle="Concept & Vision"
              boxModulename="conceptVision"
              boxImage={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/concept_dashboard.svg`}
              // buttonText="ASPIRANT"
              // buttonColor="#6168F1"
              // buttonFontSize="12px"
              // buttonPadding="0px 8px"
              imageHeight="32px"
              imageWidth="32px"
              isLocked={false}
            />
          </Box>
          {/* 2 */}
          <Box
            className={
              restrictedModules.includes("Market")
                ? styles.firsthoverBox
                : styles.firstRowBox
            }
          >
            <FirstRowBox
              details={percentvalues["market"]}
              boxTitle="Market"
              boxModulename="market"
              boxImage={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/map.png`}
              // buttonText="ASPIRANT"
              // buttonColor="#6168F1"
              // buttonFontSize="12px"
              // buttonPadding="0px 8px"
              imageHeight="32px"
              imageWidth="32px"
              isLocked={restrictedModules.includes("Market") ? true : false}
            />
            {restrictedModules.includes("Market") &&
              <Box className={styles.firstoverlay}>
                <Typography id={styles.typoOverlay}>
                  Unlock with
                  <LightTooltip placement="top" title="Coming soon">
                    <span
                      style={{
                        color: "#071AFF",

                        cursor: "pointer"
                      }}
                      onClick={() => {

                        // window.open("/mysubscriptions", "_blank");
                    
                      }}
                    >
                      {" Pro "}
                    </span>
                  </LightTooltip>
                  plan!
                </Typography>
                <Box>
                  <IconButton
                    onClick={() => {

                      setSelectedvideo("Market.mp4");
                      Dialogopen();
                    
                    }}
                  >
                    <PlayCircleOutlineIcon sx={{ fontSize: "40px" }} />
                  </IconButton>
                </Box>

                <Typography id={styles.subOverlay}>
                  Play to get a sneak preview
                </Typography>
              </Box>
            }
          </Box>
          {/* 3 */}
          <Box
            className={
              restrictedModules.includes("Customer")
                ? styles.firsthoverBox
                : styles.firstRowBox
            }
          >
            <FirstRowBox
              details={percentvalues["customer"]}
              boxTitle="Customer"
              boxModulename="customer"
              boxImage={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/customerDashboard.png`}
              // buttonText="ASPIRANT"
              // buttonColor="#6168F1"
              // buttonFontSize="12px"
              // buttonPadding="0px 8px"
              imageHeight="41px"
              imageWidth="41px"
              isLocked={restrictedModules.includes("Customer") ? true : false}
            />
            {restrictedModules.includes("Customer") &&
              <Box className={styles.firstoverlay}>
                <Typography id={styles.typoOverlay}>
                  Unlock with
                  <LightTooltip placement="top" title="Coming soon">
                    <span
                      style={{
                        color: "#071AFF",

                        cursor: "pointer"
                      }}
                      onClick={() => {
                      
                        // window.open("/mysubscriptions", "_blank");
                      
                      }}
                    >
                      {" Pro "}
                    </span>
                  </LightTooltip>
                  plan!
                </Typography>
                <Box>
                  <IconButton
                    onClick={() => {

                      setSelectedvideo("Demo_Customer.mov");
                      Dialogopen();
                    
                    }}
                  >
                    <PlayCircleOutlineIcon sx={{ fontSize: "40px" }} />
                  </IconButton>
                </Box>

                <Typography id={styles.subOverlay}>
                  Play to get a sneak preview
                </Typography>
              </Box>
            }
          </Box>


          {/* 4 */}
          <Box
            className={
              restrictedModules.includes("Problem")
                ? styles.firsthoverBox
                : styles.firstRowBox
            }
          >
            <FirstRowBox
              details={percentvalues["problem"]}
              boxTitle="Problem"
              boxModulename="problem"
              boxImage={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/problemDashboard.png`}
              // buttonText="ASPIRANT"
              // buttonColor="#6168F1"
              // buttonFontSize="12px"
              // buttonPadding="0px 8px"
              imageHeight="41px"
              imageWidth="41px"
              isLocked={restrictedModules.includes("Problem") ? true : false}
            />
            {restrictedModules.includes("Problem") &&
              <Box className={styles.firstoverlay}>
                <Typography id={styles.typoOverlay}>
                  Unlock with
                  <LightTooltip placement="top" title="Coming soon">
                    <span
                      style={{
                        color: "#071AFF",
                      
                        cursor: "pointer"
                      }}
                      onClick={() => {
                      
                        // window.open("/mysubscriptions", "_blank");
                      
                      }}
                    >
                      {" Pro "}
                    </span>
                  </LightTooltip>
                  plan!
                </Typography>
                <Box>
                  <IconButton
                    onClick={() => {

                      setSelectedvideo("Demo_Problem.mov");
                      Dialogopen();
                    
                    }}
                  >
                    <PlayCircleOutlineIcon sx={{ fontSize: "40px" }} />
                  </IconButton>
                </Box>

                <Typography id={styles.subOverlay}>
                  Play to get a sneak preview
                </Typography>
              </Box>
            }
          </Box>

          {/* ---------- */}
        </Grid>
      </Grid>
      {/* second row */}
      <Grid item xs={12}>
        <Grid id={styles.gridFirstRow} container xs={12}>
          {/* 1 */}

          <Box
            className={
              restrictedModules.includes("Solution")
                ? styles.firsthoverBox
                : styles.firstRowBox
            }
          >
            <FirstRowBox
              details={percentvalues["solution"]}
              boxTitle="Solution"
              boxModulename="solution"
              boxImage={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/solutionDashboard.png`}
              // buttonText="ASPIRANT"
              // buttonColor="#6168F1"
              // buttonFontSize="12px"
              // buttonPadding="0px 8px"
              imageHeight="36.5px"
              imageWidth="25.44px"
              isLocked={restrictedModules.includes("Solution") ? true : false}
            />
            {restrictedModules.includes("Solution") &&
              <Box className={styles.firstoverlay}>
                <Typography id={styles.typoOverlay}>
                  Unlock with
                  <LightTooltip placement="top" title="Coming soon">
                    <span
                      style={{
                        color: "#071AFF",

                        cursor: "pointer"
                      }}
                      onClick={() => {

                        // window.open("/mysubscriptions", "_blank");
                    
                      }}
                    >
                      {" Pro "}
                    </span>
                  </LightTooltip>
                  plan!
                </Typography>
                <Box>
                  <IconButton
                    onClick={() => {

                      setSelectedvideo("Solution.mp4");
                      Dialogopen();
                    
                    }}
                  >
                    <PlayCircleOutlineIcon sx={{ fontSize: "40px" }} />
                  </IconButton>
                </Box>

                <Typography id={styles.subOverlay}>
                  Play to get a sneak preview
                </Typography>
              </Box>
            }
          </Box>
          {/* 2 */}

          <Box
            className={
              restrictedModules.includes("Monetisation")
                ? styles.firsthoverBox
                : styles.firstRowBox
            }
          >
            <FirstRowBox
              details={percentvalues["monetisation"]}
              boxTitle="Monetisation"
              boxModulename="monetisation"
              boxImage={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/monetisationDashboard.png`}
              // buttonText="FOUNDER"
              // buttonColor="#26B99A"
              // buttonFontSize="14px"
              // buttonPadding="0px 12px"
              imageHeight="50px"
              imageWidth="50px"
              isLocked={
                restrictedModules.includes("Monetisation") ? true : false
              }
            />
            {restrictedModules.includes("Monetisation") &&
              <Box className={styles.overlay}>
                <Typography id={styles.typoOverlay}>
                  Unlock with
                  <LightTooltip placement="top" title="Coming soon">
                    <span
                      style={{
                        color: "#071AFF",
                      
                        cursor: "pointer"
                      }}
                      onClick={() => {
                      
                        // window.open("/mysubscriptions", "_blank");
                      
                      }}
                    >
                      {" Pro "}
                    </span>
                  </LightTooltip>
                  plan!
                </Typography>
                <Box>
                  <IconButton
                    onClick={() => {

                      setSelectedvideo("Monetization.mp4");
                      Dialogopen();
                    
                    }}
                  >
                    <PlayCircleOutlineIcon sx={{ fontSize: "40px" }} />
                  </IconButton>
                </Box>

                <Typography id={styles.subOverlay}>
                  Play to get a sneak preview
                </Typography>
              </Box>
            }
          </Box>
          {/* 3 */}

          <Box
            className={
              restrictedModules.includes("Financials")
                ? styles.firsthoverBox
                : styles.firstRowBox
            }
          >
            <FirstRowBox
              details={percentvalues["financials"]}
              boxTitle="Financials"
              boxModulename="financials"
              boxImage={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/financialsDashboard.png`}
              // buttonText="FOUNDER"
              // buttonColor="#26B99A"
              // buttonFontSize="14px"
              // buttonPadding="0px 12px"
              imageHeight="42px"
              imageWidth="42px"
              isLocked={restrictedModules.includes("Financials") ? true : false}
            />
            {restrictedModules.includes("Financials") &&
              <Box className={styles.overlay}>
                <Typography id={styles.typoOverlay}>
                  Unlock with
                  <LightTooltip placement="top" title="Coming soon">
                    <span
                      style={{
                        color: "#071AFF",
                      
                        cursor: "pointer"
                      }}
                      onClick={() => {
                      
                        // window.open("/mysubscriptions", "_blank");
                      
                      }}
                    >
                      {" Pro "}
                    </span>
                  </LightTooltip>
                  plan!
                </Typography>
                <Box>
                  <IconButton
                    onClick={() => {

                      setSelectedvideo("Financials.mp4");
                      Dialogopen();
                    
                    }}
                  >
                    <PlayCircleOutlineIcon sx={{ fontSize: "40px" }} />
                  </IconButton>
                </Box>

                <Typography id={styles.subOverlay}>
                  Play to get a sneak preview
                </Typography>
              </Box>
            }
          </Box>
          {/* 4 */}

          <Box
            className={
              restrictedModules.includes("Operations")
                ? styles.firsthoverBox
                : styles.firstRowBox
            }
          >
            <FirstRowBox
              details={percentvalues["operations"]}
              boxTitle="Operations"
              boxModulename="operations"
              boxImage={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/operationsDashboard.png`}
              // buttonText="FOUNDER"
              // buttonColor="#26B99A"
              // buttonFontSize="14px"
              // buttonPadding="0px 12px"
              imageHeight="50px"
              imageWidth="50px"
              isLocked={restrictedModules.includes("Operations") ? true : false}
            />
            {restrictedModules.includes("Operations") &&
              <Box className={styles.overlay}>
                <Typography id={styles.typoOverlay}>
                  Unlock with
                  <LightTooltip placement="top" title="Coming soon">
                    <span
                      style={{
                        color: "#071AFF",
                      
                        cursor: "pointer"
                      }}
                      onClick={() => {
                      
                        // window.open("/mysubscriptions", "_blank");
                      
                      }}
                    >
                      {" Pro "}
                    </span>
                  </LightTooltip>
                  plan!
                </Typography>
                <Box>
                  <IconButton
                    onClick={() => {

                      setSelectedvideo("Operations.mp4");
                      Dialogopen();
                    
                    }}
                  >
                    <PlayCircleOutlineIcon sx={{ fontSize: "40px" }} />
                  </IconButton>
                </Box>

                <Typography id={styles.subOverlay}>
                  Play to get a sneak preview
                </Typography>
              </Box>
            }
          </Box>
          {/* ------- */}
        </Grid>
      </Grid>
      {/* third row */}
      <Grid
        item
        xs={12}
      // sx={{ height: "140px" }}
      >
        <Grid id={styles.thirdRowGrid} container xs={12}>
          {/* 1 */}

          <Box
            className={
              restrictedModules.includes("Go-To-Market")
                ? styles.hoverBoxPitch
                : styles.thirdRowBox
            }
          >
            <FirstRowBox
              details={percentvalues["gotomarket"]}
              boxTitle="Go-to-Market"
              boxModulename="gotomarket"
              boxImage={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/gtmDashboard.png`}
              // buttonText="TEAM"
              // buttonColor="#FF7E00"
              // buttonFontSize="14px"
              // buttonPadding="0px 25px"
              imageHeight="60px"
              imageWidth="60px"
              isLocked={
                restrictedModules.includes("Go-To-Market") ? true : false
              }
            />
            {restrictedModules.includes("Go-To-Market") &&
              <Box className={styles.overlayPitch}>
                <Typography id={styles.typoOverlay}>
                  Unlock with
                  <LightTooltip placement="top" title="Coming soon">
                    <span
                      style={{
                        color: "#071AFF",
                      
                        cursor: "pointer"
                      }}
                      onClick={() => {
                      
                        // window.open("/mysubscriptions", "_blank");
                      
                      }}
                    >
                      {" Pro "}
                    </span>
                  </LightTooltip>
                  plan!
                </Typography>
                <Box>
                  <IconButton
                    onClick={() => {

                      setSelectedvideo("GTM.mp4");
                      Dialogopen();
                    
                    }}
                  >
                    <PlayCircleOutlineIcon sx={{ fontSize: "40px" }} />
                  </IconButton>
                </Box>

                <Typography id={styles.subOverlay}>
                  Play to get a sneak preview
                </Typography>
              </Box>
            }
          </Box>
          {/* 2 */}

          <Box
            className={
              restrictedModules.includes("Pitch Deck")
                ? styles.hoverBoxPitch
                : styles.thirdRowBox
            }
          >
            <FirstRowBox
              details={percentvalues["pitchDeck"]}
              boxTitle="Pitch Deck"
              boxModulename="pitchdeck"
              boxImage={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/pitchDeckDashboard.png`}
              // buttonText="TEAM"
              // buttonColor="#FF7E00"
              // buttonFontSize="14px"
              // buttonPadding="0px 25px"
              imageHeight="62px"
              imageWidth="62px"
              isLocked={restrictedModules.includes("Pitch Deck") ? true : false}
            />
            {restrictedModules.includes("Pitch Deck") &&
              <Box className={styles.overlayPitch}>
                <Typography id={styles.typoOverlay}>
                  Unlock with
                  <LightTooltip placement="top" title="Coming soon">
                    <span
                      style={{
                        color: "#071AFF",

                        cursor: "pointer"
                      }}
                      onClick={() => {

                        // window.open("/mysubscriptions", "_blank");
                    
                      }}
                    >
                      {" Pro "}
                    </span>
                  </LightTooltip>
                  plan!
                </Typography>
                <Box>
                  <IconButton
                    onClick={() => {

                      setSelectedvideo("PitchDeck.mov");
                      Dialogopen();
                    
                    }}
                  >
                    <PlayCircleOutlineIcon sx={{ fontSize: "40px" }} />
                  </IconButton>
                </Box>

                <Typography id={styles.subOverlay}>
                  Play to get a sneak preview
                </Typography>
              </Box>
            }
          </Box>
          {/* -------- */}
        </Grid>
      </Grid>
    </Grid>
  );

}
