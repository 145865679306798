import CloseIcon from "@mui/icons-material/Close";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import {
  Box, TextField, Typography
} from "@mui/material";
import React, { useRef } from "react";
import ImageOptions from "../ImageOptions";
import styles from "./Traction.module.css";

const IndividualFeedback = ({
  pathname,
  slide,
  isEdit,
  feedback,
  handleFeedbackChange,
  // setFeedbackProfileImgs,
  profileImg,
  uploadImages,
  feedbackindex,
  removeFeedback,
  deleteUploadedImgs,
  updateIschange
  // handleFeedbackImg,
  // ...props
}) => {

  // for uploading profileImg
  const profileImgFileInput = useRef(null);

  const profileImghandleClick = () => {

    profileImgFileInput.current.click();
  
  };

  // const previewProfileImg = useMemo(() => {
  //   return profileImg ? URL.createObjectURL(profileImg) : null;
  // }, [profileImg]);

  return (
    <Box
      id={ styles.individualCommentBox }
      // sx={{
      //   width: "210px",
      //   height: "205px",
      //   border: "1px solid #B4B4FA",
      //   display: "flex",
      //   flexDirection: "column",
      //   alignItems: "center",
      //   justifyContent: "space-evenly",
      //   borderRadius: "4px",

    // }}
    >
      <FormatQuoteIcon sx={ { fontSize: "20px" } } />
      <Box
        id={ styles.commentContentBox }
      // sx={{ width: "95%", textAlign: "center" }}
      >
        { isEdit ?
          <TextField
            value={ feedback.feedback }
            onChange={ e => {

              handleFeedbackChange("feedback", e.target.value, feedbackindex);
            
            } }
            placeholder="Customer quote / testimonial"
            fullWidth
            multiline
            maxRows={ 4 }
            minRows={ 4 }
            inputProps={ { maxLength: 100 } }
            sx={ {
              "& .MuiOutlinedInput-root": {
                padding: "0px 4px",
                // border: "2px dotted #B4B4FA",
                border : "none"
              },
              "& .MuiInputBase-input": {
                fontSize  : "13px",
                // padding: "7px 10px",
                // border: "none",
                color     : "#707070",
                lineHeight: "20px",
                textAlign : "center",
                whiteSpace: "pre-line"
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "2px dotted #B4B4FA"
              }
            } }
          />
          :
          <Box
            sx={ {
              // border: 1,
              width    : "100%",
              wordWrap : "break-word",
              height   : "15vh",
              overflowY: "auto"
            } }
          >
            <Typography
              id={ styles.commentContent }
            // sx={{ fontSize: "13px", color: "#707070" }}
            >
              { feedback.feedback
                ? feedback.feedback
                : pathname.includes("pitchpresentation")
                  ? ""
                  : "Customer quote / testimonial" }
            </Typography>
          </Box>
        }
      </Box>
      <Box
        id={ styles.divider }
        // sx={{
        //   marginTop: "5px",
        //   display: "flex",

      //   // alignItems: "center",
      //   borderBottom: "1px solid #B1AAAA",
      //   width: "30%",
      // }}
      ></Box>
      { isEdit ?
        <Box
          sx={ {
            width       : "10vh",
            height      : "10vh",
            border      : "2px dotted #B4B4FA",
            borderRadius: "50%",
            position    : "relative"
          } }
        // onClick={profileImghandleClick}
        >
          { profileImg?.imgUrl || feedback.profileImg ?
            <>
              <img
                src={
                  profileImg?.imgUrl
                    ? profileImg?.imgUrl
                    : feedback.profileImg
                      ? feedback.profileImg
                      : profileImg?.imgUrl
                }
                alt="profileImg"
                style={ { width: "100%", height: "100%", borderRadius: "50%" } }
              />
              <Box
                sx={ {
                  position       : "absolute",
                  left           : "40px",
                  bottom         : "0px",
                  display        : "flex",
                  justifyContent : "space-evenly",
                  alignItems     : "center",
                  backgroundColor: "#ffffff"
                } }
              >
                <ImageOptions
                  IconSize="14px"
                  handleClick={ profileImghandleClick }
                  image={ profileImg?.imgUrl || feedback.profileImg }
                  deleteUploadedImgs={ () => {

                    updateIschange();
                    deleteUploadedImgs(
                      `${slide.renderName}.${feedbackindex}`,
                      feedback.profileImg ? true : false
                    );
                    if (feedback.profileImg)
                      handleFeedbackChange("profileImg", "", feedbackindex);
                  
                  } }
                />
              </Box>
            </>
            :
            <Box
              sx={ {
                width         : "100%",
                height        : "100%",
                display       : "flex",
                alignItems    : "center",
                justifyContent: "center"
              } }
              onClick={ profileImghandleClick }
            >
              <img
                src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/upload.png` }
                alt="upload"
                style={ {
                  width : "25px",
                  height: "25px"
                } }
              />
            </Box>
          }
          <input
            type="file"
            id="coverImg"
            ref={ profileImgFileInput }
            onChange={ e => {

              updateIschange();
              uploadImages(
                `${slide.renderName}.${feedbackindex}`,
                e.target.files[0]
              );
            
            } }
            onClick={ e => {

              e.target.value = null;
            
            } }
            style={ { display: "none" } }
            accept="image/*"
          />
        </Box>
        :
        <Box
          sx={ {
            width       : "10vh",
            height      : "10vh",
            border      : "1px solid #B4B4FA",
            borderRadius: "50%"
          } }
        >
          { feedback.profileImg &&
            <img
              src={ feedback.profileImg }
              style={ { width: "100%", height: "100%", borderRadius: "50%" } }
              alt="profileImg"
            />
          }
        </Box>
      }

      <Box sx={ { width: "90%", textAlign: "center" } }>
        { isEdit ?
          <TextField
            value={ feedback.name }
            onChange={ e => {

              handleFeedbackChange("name", e.target.value, feedbackindex);
            
            } }
            fullWidth
            inputProps={ { maxLength: 25 } }
            sx={ {
              "& .MuiInputBase-input": {
                fontSize : "12px",
                padding  : "1px 3px",
                border   : "none",
                color    : "#707070",
                textAlign: "center"
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "2px dotted #B4B4FA"
              }
            } }
          />
          :
          <Typography sx={ { fontSize: "12px" } }>
            { feedback.name ? feedback.name : "name" }
          </Typography>
        }
      </Box>
      { isEdit && feedbackindex > 1 &&
        <Box
          sx={ {
            width          : "15px",
            height         : "15px",
            border         : "1px solid #838383",
            borderRadius   : "50%",
            zIndex         : 20,
            position       : "absolute",
            right          : "-7px",
            top            : "-7px",
            display        : "flex",
            justifyContent : "center",
            alignItems     : "center",
            backgroundColor: "#FFFFFF"
          } }
          onClick={ () => {

            removeFeedback(feedbackindex);
          
          } }
        >
          <CloseIcon sx={ { fontSize: "15px", color: "#838383" } } />
        </Box>
      }
    </Box>
  );

};

export default IndividualFeedback;
