import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box, Dialog, IconButton, Slide, Typography
} from "@mui/material";
import React, { forwardRef, useState } from "react";
import CustomMultilineTextfield from "../../../../components/CustomMultilineTextfield";
import PersonaDetails from "../../Customer/TargetSegments/PersonaDetails";

import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  griditem: {
    // flex: "0 0 100%",
    display   : "flex",
    // flexDirection: "column",
    flex      : "row",
    alignItems: "center",
    width     : "100%"
  },
  persona: {
    cursor    : "pointer",
    textAlign : "center",
    background: "#f8f9ff 0% 0% no-repeat padding-box",
    padding   : "0px 10px",
    "&:hover" : {
      backgroundColor: "#c7c6c6",
      boxShadow      : "0 4px 12px rgb(0 0 0 / 15%)"
    }
  },
  personaName: {
    color        : "#393939",
    textTransform: "capitalize"
  },
  avatarimg: {
    width : "50px",
    height: "50px"
  }
}));

const Transition = forwardRef(function Transition (props, ref) {

  return <Slide direction="up" ref={ ref } { ...props } />;

});

const ProductMessagingColumns = props => {

  const [ personadialogbox, setPersonaDialog ] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  const eachPersona = props.eachPersona;
  const eachSegment = props.eachSegment;
  const segmentindex = props.segmentindex;
  const personaindex = props.personaindex;

  const personaDialogopen = () => {

    setPersonaDialog(true);
  
  };
  const personaDialogclose = () => {

    setPersonaDialog(false);
  
  };

  
  return (
    <Box className={ classes.griditem }>
      <Box
        component="span"
        className={ classes.persona }
        onClick={ () => personaDialogopen() }
      >
        <img
          src={ `${process.env.REACT_APP_AWS_ASSETS_URL}${eachPersona.persona.avatar}` }
          className={ classes.avatarimg }
        />
        <Typography className={ classes.personaName }>
          { eachPersona.persona.name }
        </Typography>
      </Box>
      <Dialog
        open={ personadialogbox }
        TransitionComponent={ Transition }
        keepMounted
        PaperProps={ {
          style: {
            borderRadius   : "20px",
            backdropFilter : "blur(10px)",
            backgroundColor: "rgba(255,255,255,0.80)",
            margin         : "20px"
          }
        } }
        maxWidth="md"
        fullWidth
      >
        <Box sx={ { alignSelf: "flex-end" } }>
          <IconButton
            component="span"
            onClick={ () => {

              history.push({ hash: "" });
              personaDialogclose();
            
            } }
          >
            <HighlightOffIcon />
          </IconButton>
        </Box>
        <PersonaDetails
          eachPersona={ eachPersona.persona }
          segment={ eachSegment.segment }
        />
      </Dialog> <Box style={ { width: "100%" } }>
        <CustomMultilineTextfield
          width="100%"
          borderRadius="10px"
          // fontSize="14px"
          backgroundColor="white"
          minRows={ 5 }
          maxRows={ 5 }
          value={ eachPersona.productValueMessage }
          name={ "productValueMessage" }
          handledetailsChange={ e => {

            props.handleChange(
              "productValueMessage",
              e.target.value,
              segmentindex,
              personaindex
            );
          
          } }
          placeholder={
            personaindex === 0 ?
              "Example: Improve your hospital’s bottom-line and scale more efficiently, by improving patient outcomes and reducing hospitalizations for your cardiac patients, and allowing your doctors more time to see new patients. On average, by using our product, our customers have reduced hospitalizations by 40% and improved patient throughput by 25%. "
              : ""
          }
          disabled={ history.location.pathname.includes("pitchdeckintro") }
        />
      </Box>

    </Box>
  );

};

export default ProductMessagingColumns;
