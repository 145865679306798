import React, { useState } from "react";

import {
  Dialog,
  DialogContent, Grid, IconButton, Typography
} from "@mui/material";

import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import CustomMultilineTextfield from "../../../../components/CustomMultilineTextfield";
import DeleteCancel from "../../../../components/DeleteCancel";

const IndividualUsecase = props => {

  const [ deletedialogbox, setDeleteDialog ] = useState(false);

  const deleteDialogopen = () => {

    setDeleteDialog(true);
  
  };
  const deleteDialogclose = () => {

    setDeleteDialog(false);
  
  };

  const { classes, index, isdelete, data, usecase } = props;

  
  return (
    <Grid container className={ classes.gridbox } key={ index }>
      <Typography className={ classes.question }>
        Use Case { index + 1 }
        { isdelete &&
          <>
            <IconButton
              className={ classes.removeiconbtn }
              onClick={ e => {

                e.stopPropagation();
                deleteDialogopen();
              
              } }>
              <RemoveCircleIcon className={ classes.removeicon } />
            </IconButton>
            <Dialog
              open={ deletedialogbox }
              PaperProps={ {
                style: {
                  borderRadius  : "20px",
                  backdropFilter: "blur(45px)",
                  background    : "#FFFFFF 0% 0% no-repeat padding-box",
                  boxShadow     : "0px 3px 6px #00000029",
                  border        : "1px solid #E0E1E2"
                }
              } }>
              <DialogContent>
                <DeleteCancel
                  name={ "Are you sure you want to delete this usecase?" }
                  deleteClick={ async e => {

                    e.stopPropagation();
                    await props.removeRow(index);
                    props.isedit && props.setIsdelete(false);
                    deleteDialogclose();
                  
                  } }
                  cancelClick={ () => {

                    deleteDialogclose();
                  
                  } }
                />
              </DialogContent>
            </Dialog>
          </>
        }
        {/* <HelpIconTooltip tooltipdata={data.tooltip} width={"500px"} /> */ }
      </Typography>
      {/* <TextField
        multiline
        minRows={4}
        maxRows={4}
        className={classes.textarea}
        placeholder={data.placeholder}
        value={usecase}
        onChange={(e) => {
          props.handleChange(e.target.value, index);
        }}
      /> */}
      { index === 0 ?
        // <TextField
        //   multiline
        //   minRows={4}
        //   maxRows={4}
        //   className={classes.textarea}
        //   placeholder={data.placeholder}
        //   value={usecase}
        //   onChange={(e) => {
        //     props.handleChange(e.target.value, index);
        //   }}
        // />
        <CustomMultilineTextfield
          minRows={ 4 }
          maxRows={ 4 }
          border={ "none" }
          padding={ "20px 30px" }
          width={ "100%" }
          placeholder={ data.placeholder }
          value={ usecase }
          handledetailsChange={ e => {

            props.handleChange(e.target.value, index);
          
          } }
        />
        :
        // <TextField
        //   multiline
        //   minRows={4}
        //   maxRows={4}
        //   className={classes.textarea}
        //   // placeholder={data.placeholder}
        //   value={usecase}
        //   onChange={(e) => {
        //     props.handleChange(e.target.value, index);
        //   }}
        // />
        <CustomMultilineTextfield
          minRows={ 4 }
          maxRows={ 4 }
          border={ "none" }
          padding={ "20px 30px" }
          width={ "100%" }
          // placeholder={data.placeholder}
          value={ usecase }
          handledetailsChange={ e => {

            props.handleChange(e.target.value, index);
          
          } }
        />
      }
    </Grid>
  );

};

export default IndividualUsecase;
