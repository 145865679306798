
import React, { useEffect, useState, useRef } from "react";
import { MenuItem, Select } from "@mui/material";

import HelpIconTooltip from "../../../../components/HelpIconTooltip";

import RevenueProgression from "../../Financials/InsightsDashboard/ChartComponents/PlOutlookCharts/RevenueProgression";
import GrossAndOperationMargin from "../../Financials/InsightsDashboard/ChartComponents/PlOutlookCharts/GrossAndOperationMargin";
import CustomerBaseGrowth from "../../Financials/InsightsDashboard/ChartComponents/PlOutlookCharts/CustomerBaseGrowth";
import OverHeadProgression from "../../Financials/InsightsDashboard/ChartComponents/PlOutlookCharts/OverHeadProgression";
import RunWayVsFundBal from "../../Financials/InsightsDashboard/ChartComponents/FrOutlookCharts/RunWayVsFundBal";
import Config from "../../../../config/Config";

const FinancialChart = props => {

  const YearsList = props?.financialChartData?.yearList;
  const currency = props?.financialChartData?.currency;
  const currentYear = new Date().getFullYear();
  const [ year, setYear ] = useState(YearsList && YearsList.includes(currentYear) ? currentYear : YearsList && YearsList.length ? YearsList[0] : 0);
  const [ selectedData, setSelectedData ] = useState({ totalUsers: [], netIncome: [], grossRevenue: [] });

  const ploutlookTable = props?.financialChartData?.ploutlook;
  const frRunWayTable = props?.financialChartData?.funding_runway;
  const financialConsolidateData = props?.financialChartData?.financial_consolidate_data;
  const totalUsers = financialConsolidateData?.total_users;
  const netIncome = financialConsolidateData?.net_income;
  const grossRevenue = financialConsolidateData?.gross_revenue;

  const chartTitle = Config?.chartTitleList;
  const chartMoreDetails = Config?.chartMoreDetails;

  const scrollTopRef = useRef(null);

  useEffect(() => {

    const financialConsolidateData = props?.financialChartData?.financial_consolidate_data;

    const selectedData = {
      totalUsers  : financialConsolidateData?.total_users.find(d => d.year === year) || financialConsolidateData?.total_users[0] || [],
      netIncome   : financialConsolidateData?.net_income.find(d => d.year === year) || financialConsolidateData?.net_income[0] || [],
      grossRevenue: financialConsolidateData?.gross_revenue.find(d => d.year === year) || financialConsolidateData?.gross_revenue[0] || []
    };

    setSelectedData(selectedData);

    scrollTopRef.current.scrollIntoView({ view: "top", behavior: "smooth" });

  }, []);


  const handleSelect = e => {

    const year = Number(e.target.value);

    setYear(year);

    const selectedData = {
      totalUsers  : totalUsers?.find(d => d.year === year) ?? [],
      netIncome   : netIncome?.find(d => d.year === year) ?? [],
      grossRevenue: grossRevenue?.find(d => d.year === year) ?? []
    };

    setSelectedData(selectedData);
  
  };

  const numberWithCommas = num => {

    if (currency) {

      let newNum = num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      return `${currency?.split(" ")[0]?.trim()} ${newNum}`;

    } else {

      return num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    }
  
  };

  return <div>
    <div ref={scrollTopRef} className="tribe-fin-chart-tab-data-main-con">
      <div className="tribe-year-drop-main-con">
        <div>Year</div>
        <Select
          className="tribe-fin-chart-year-sel"
          value={year}
          onChange={handleSelect}
        >
          {YearsList.map(year => {

            return <MenuItem value={year}>{year}</MenuItem>;
          
          })}
        </Select>
      </div>

      <div className="tribe-fin-chart-tab-data-con">
        <div className="tribe-fin-chart-tab1-users-con">

          <div className="fin-data">{selectedData?.totalUsers?.data?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
          <div>Total Users<HelpIconTooltip
            tooltipdata={"From financial module's P&L outlook table"}
          /></div>

        </div>
        <div className="tribe-fin-chart-tab2-gross-revenue-con">

          <div className="fin-data">{numberWithCommas(selectedData?.grossRevenue?.data)}</div>
          <div>Gross Revenue<HelpIconTooltip
            tooltipdata={"From financial module's P&L outlook table"}
          /></div>

        </div>
        <div className="tribe-fin-chart-tab3-net-income-con">

          <div className="fin-data">{numberWithCommas(selectedData.netIncome?.data)}</div>
          <div>Net Income<HelpIconTooltip
            tooltipdata={"From financial module's P&L outlook table"}
          /></div>

        </div>
      </div>


    </div>
    <div className="tribe-fin-char-sec-main-con">

      <div className="tribe-financial-insight-dashboard-charts-sub-con" >
        <div className="tribe-financial-insight-dashboard-charts-div">
          <RevenueProgression
            table={ploutlookTable}
            currency={currency}
            year={YearsList}
            chartTitle={chartTitle[0]}
            moreDetails={chartMoreDetails.plOutLook}
            hideLink={true}
          />
        </div >
        <div className="tribe-financial-insight-dashboard-charts-div">
          <GrossAndOperationMargin
            table={ploutlookTable}
            currency={currency}
            year={YearsList}
            chartTitle={chartTitle[1]}
            moreDetails={chartMoreDetails.plOutLook}
            hideLink={true}
          />
        </div>
      </div>

    </div>
    <div className="tribe-fin-char-sec-main-con">
      <div className="tribe-financial-insight-dashboard-charts-sub-con" >
        <div className="tribe-financial-insight-dashboard-charts-div">
          <CustomerBaseGrowth
            table={ploutlookTable}
            currency={currency}
            year={YearsList}
            chartTitle={chartTitle[2]}
            moreDetails={chartMoreDetails.plOutLook}
            hideLink={true}
          />
        </div >
        <div className="tribe-financial-insight-dashboard-charts-div">
          <OverHeadProgression
            table={ploutlookTable}
            currency={currency}
            year={YearsList}
            chartTitle={chartTitle[4]}
            moreDetails={chartMoreDetails.plOutLook}
            hideLink={true}
          />
        </div>
      </div>
    </div>

    {/* <div className="tribe-fin-char-sec-main-con"> */}
    <div className="tribe-inside-chart-div-con-for-biz-plan">
      <div className="tribe-financial-insight-dashboard-charts-sub-con" >
        <div className="tribe-financial-insight-dashboard-charts-full-div">
          <RunWayVsFundBal
            table={frRunWayTable}
            currency={currency}
            year={YearsList}
            chartTitle={chartTitle[8]}
            moreDetails={chartMoreDetails.frOutLook}
            hideLink={true}
          />
        </div>
      </div>
    </div>
    {/* </div> */}

  </div>;

};

export default FinancialChart;
