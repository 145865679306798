import React from "react";
import {
  Box, Divider, IconButton, TextField, Typography
} from "@mui/material";

// import { useHistory } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import IndividualPriceAnchor from "./IndividualPriceAnchor";
import styles from "./PriceAnchors.module.css";

export default function PriceAnchors(props) {

  const { venture } = useSelector(state => ({ ...state }));

  const revenueStreams = props.revenueStreams;
  const revenueStreamIndex = props.revenueStreamIndex;

  // console.log(revenueStreams);

  const handleChangeComparableAnchor = (index, value) => {

    let newRevenueStreams = [ ...revenueStreams ];

    newRevenueStreams[index]["anchors"]["comparableAnchor"] = value;

    props.setRevenueStreams(newRevenueStreams);
  
  };

  const addPriceAnchors = index => {

    let newRevenueStreams = [ ...revenueStreams ];

    newRevenueStreams[index]["anchors"]["priceAnchors"].push({
      name     : "",
      lowPrice : "",
      highPrice: ""
    });

    props.setRevenueStreams(newRevenueStreams);
  
  };

  const removePriceAnchors = (revenueStreamIndex, priceAnchorIndex) => {

    let newRevenueStreams = [ ...revenueStreams ];

    newRevenueStreams[revenueStreamIndex]["anchors"]["priceAnchors"] =
      newRevenueStreams[revenueStreamIndex]["anchors"]["priceAnchors"].filter(
        (priceAnchor, AnchorIndex) => {

          return AnchorIndex !== priceAnchorIndex;
        
        }
      );

    props.setRevenueStreams(newRevenueStreams);
  
  };

  const handleChangePriceAnchors = (
    name,
    revenueStreamIndex,
    priceAnchorIndex,
    value
  ) => {

    let newRevenueStreams = [ ...revenueStreams ];

    newRevenueStreams[revenueStreamIndex]["anchors"]["priceAnchors"][
      priceAnchorIndex
    ][name] = value;
    props.setRevenueStreams(newRevenueStreams);
  
  };

  // const history = useHistory();

  // console.log(priceAnchors);
  return (
    // <CreatePricingComponent barHighlight="bar3">
    <Box>
      {/* top typo */}
      <Box>
        <Typography
          id={styles.topTypo}
        //   sx={{ color: "#5F5E5E" }}
        >
          Find your price ‘anchors’
        </Typography>
      </Box>
      {/* sub typo */}
      <Box>
        <Typography
          id={styles.subTypo}
        // sx={{ fontSize: "13px", color: "#868686" }}
        >
          Think about comparable products that your customer might use to
          benchmark your offering. Customers will factor these into their
          perceived “value” of your solution. Consider all relevant alternatives
          that a customer might compare you to. For example, if you’re selling
          designer curtains online, then in addition to considering other
          ecommerce sites selling curtains, for some customer segments or
          markets you might need to focus more on benchmarking against brick &
          mortar stores or custom-tailored options.
        </Typography>

        <Typography
          id={styles.subTypo2}
        // sx={{ fontSize: "13px", color: "#868686", marginTop: "20px" }}
        >
          Look at how these comparable products are priced, and you can use
          these as your price anchors. In some cases, this might be the quickest
          way to narrow down on a price for your product. In other cases it will
          help you do some competitive benchmarking of your pricing against
          other solutions that a customer will either consciously or
          subconsciously compare you with.
        </Typography>
      </Box>

      {/* anchor table main box */}
      <Box
        id={styles.anchorTableMainBox}
      // sx={{ width: "100%", marginTop: "20px" }}
      >
        {/* table head box */}
        <Box
          id={styles.tableHeadBox}
        // sx={{
        //   width: "100%",
        //   display: "flex",
        //   justifyContent: "space-evenly",
        //   //   border: 1,
        // }}
        >
          {/* table head typo box */}
          {/* ----1----- */}
          <Box
            id={styles.tableHeadTypoBox}
          // sx={{
          //   width: "27%",
          //   textAlign: "center",
          //   display: "flex",
          //   justifyContent: "center",
          //   alignItems: "center",
          //   // border: 1,
          // }}
          >
            {/* table head typo */}
            <Typography
              id={styles.tableHeadTypo}
            // sx={{ color: "#5F5E5E", fontSize: "14px", fontWeight: "bold" }}
            >
              Price Anchors
            </Typography>
          </Box>
          {/* ------- */}
          {/* ----2----- */}
          <Box
            id={styles.tableHeadTypoBox}
          // sx={{
          //   width: "27%",
          //   textAlign: "center",
          //   display: "flex",
          //   justifyContent: "center",
          //   alignItems: "center",
          //   // border: 1,
          // }}
          >
            {/* table head typo */}
            <Typography
              id={styles.tableHeadTypo}
            // sx={{ color: "#5F5E5E", fontSize: "14px", fontWeight: "bold" }}
            >
              Low Price
            </Typography>
          </Box>
          {/* ------- */}
          {/* ----3----- */}
          <Box
            id={styles.tableHeadTypoBox}
          // sx={{
          //   width: "27%",
          //   textAlign: "center",
          //   display: "flex",
          //   justifyContent: "center",
          //   alignItems: "center",
          //   // border: 1,
          // }}
          >
            {/* table head typo */}
            <Typography
              id={styles.tableHeadTypo}
            // sx={{ color: "#5F5E5E", fontSize: "14px", fontWeight: "bold" }}
            >
              High Price
            </Typography>
          </Box>
          {/* ------- */}
        </Box>

        {/* divider */}
        <Box
          id={styles.dividerBox}
        // sx={{ marginTop: "10px" }}
        >
          <Divider
            id={styles.divider}
          // sx={{ color: "#B1AAAA" }}
          />
        </Box>

        {/* table row main box */}
        <Box>
          {revenueStreams[revenueStreamIndex]["anchors"]["priceAnchors"].map(
            (priceAnchor, priceAnchorIndex) => {

              return<IndividualPriceAnchor
                removePriceAnchors={removePriceAnchors}
                priceAnchorIndex={priceAnchorIndex}
                revenueStreamIndex={revenueStreamIndex}
                handleChangePriceAnchors={handleChangePriceAnchors}
                priceAnchor={priceAnchor}
              />;
            
            }
          )}
        </Box>

        {/* add button */}
        <Box
          id={styles.addButtonBox}
          sx={{
            // width: "30px",
            // height: "30px",
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
            // borderRadius: "50%",
            // backgroundColor: "#071AFF",
            backgroundColor:
              revenueStreams[revenueStreamIndex]["anchors"]["priceAnchors"]
                .length < 5
                ? venture.userRole === "Commenter"
                  ? "gray"
                  : "#071AFF"
                : "gray"

            // backgroundColor:
            //   venture.userRole === "Commenter" ? "gray" : "#071AFF",
            // marginTop: "10px",
            // cursor: "pointer",
          }}
        // onClick={() => {
        //   // if (revenueStreams.length < 4) {
        //   addPriceAnchors(revenueStreamIndex);
        //   // }
        // }}
        >
          <IconButton
            onClick={() => {

              if (
                revenueStreams[revenueStreamIndex]["anchors"]["priceAnchors"]
                  .length < 5
              ) {

                addPriceAnchors(revenueStreamIndex);
              
              }
            
            }}
            disabled={venture.userRole === "Commenter" ? true : false}
            style={{ padding: "0px" }}
          >
            <AddIcon
              id={styles.addIcon}
            // sx={{ fontSize: "22px", color: "#FFFFFF" }}
            />
          </IconButton>
        </Box>
      </Box>

      {/* bottom typo textfield box */}
      <Box
        id={styles.bottomTypoTextfieldBox}
      // sx={{ width: "100%", marginTop: "20px" }}
      >
        <Typography
          id={styles.bottomTypo}
        // sx={{ color: "#5F5E5E", marginBottom: "10px" }}
        >
          From the above price ‘anchors’, which one do you think is the most
          comparable to your product? Why?
        </Typography>
        <TextField
          onChange={e => {

            handleChangeComparableAnchor(revenueStreamIndex, e.target.value);
          
          }}
          disabled={venture.userRole === "Commenter" ? true : false}
          value={
            revenueStreams[revenueStreamIndex]["anchors"]["comparableAnchor"]
          }
          fullWidth
          multiline
          maxRows={5}
          minRows={5}
          sx={{
            "& .MuiOutlinedInput-root": {
              padding: "14.5px 16px"
            },
            "& .MuiInputBase-input": {
              fontSize  : "14px",
              color     : "#707070",
              lineHeight: "17px"
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #E1E2E9"
            }
          }}
        />
      </Box>
    </Box>
    // </CreatePricingComponent>
  );

}
