import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router";
import { Box, Button, TextField, Typography } from "@mui/material";
import ErrorMessage from "../../../components/ErrorMessage";
import SuccessMessage from "../../../components/SuccessMessage";
import { sendFeedbackMail } from "../../../services/feedbacksupport";
import styles from "./Feedback.module.css";

const useStyles = makeStyles(() => ({
  customInput: {
    "& .MuiInputBase-input": {
      borderRadius: 4,
      fontSize    : 14,
      padding     : "6px 12px"
    }
  },
  multilintCustomInput: {
    "& .MuiInputBase-input": {
      borderRadius: 4,
      fontSize    : 14,
      lineHeight  : "20px"
    },
    "& .MuiOutlinedInput-root": {
      padding: "12px"
    }
  }
}));

export default function Feedback () {

  const [ details, setDetails ] = useState({
    name              : "",
    email             : "",
    overallExperience : "",
    userFeedback      : "",
    userRecommendation: 0
  });
  const [ loading, setLoading ] = useState(false);
  const [ errormsg, setErrormsg ] = useState("");
  const [ successmsg, setSuccessmsg ] = useState("");
  const { user } = useSelector(state => ({ ...state }));
  const classes = useStyles();
  let history = useHistory();

  useEffect(() => {

    if (user.token) {

      setDetails(values => ({
        ...values,
        ["email"]: user.email,
        ["name"] : `${user.firstname} ${user.lastname}`
      }));
    
    }
  
  }, [ user ]);

  const handleChange = (name, value) => {

    setDetails(values => ({ ...values, [name]: value }));
  
  };

  const handleSubmit = async () => {

    if (
      details.email !== "" &&
      details.name !== "" &&
      details.userFeedback !== "" &&
      details.overallExperience !== "" &&
      details.userRecommendation !== 0
    ) {

      try {

        if (
          details.email &&
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(details.email)
        ) {

          setLoading(true);
          await sendFeedbackMail(details);

          setLoading(false);
          setSuccessmsg("Your feedback mail sent successfully!");
          setDetails({
            name              : "",
            email             : "",
            overallExperience : "",
            userFeedback      : "",
            userRecommendation: 0
          });
          setTimeout(() => {

            setSuccessmsg("");
          
          }, 5000);
        
        } else {

          setErrormsg("Please enter a valid email ID.");
          setTimeout(() => {

            setErrormsg("");
          
          }, 5000);
        
        }
      
      } catch (error) {

        setLoading(false);
        setErrormsg("Something went wrong. Try again later");
        setTimeout(() => {

          setErrormsg("");
        
        }, 5000);
      
      }
    
    } else {

      setLoading(false);
      setErrormsg("Please fill required (*) fields");
      setTimeout(() => {

        setErrormsg("");
      
      }, 5000);
    
    }
  
  };

  
  return (
    <Box sx={ { height: "100%" } }>
      <Box id={ styles.mainBox }>
        {/* leftside Box */ }
        <Box id={ styles.leftBox }>
          <Box id={ styles.leftTypoBox }>
            <Typography id={ styles.leftHeadtypo }>Help us improve</Typography>
          </Box>
          <Box sx={ {} }>
            <Box
              sx={ {
                marginLeft: "20px"
              } }
            >
              <img
                id={ styles.leftImg }
                src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/listening-to-feedback.PNG` }
                alt=""
              />
            </Box>
          </Box>
        </Box>
        {/* right side box */ }
        <Box id={ styles.rightBox }>
          <Box id={ styles.mainContentBox }>
            {/* head typo box */ }
            <Box id={ styles.headTypoBox }>
              <Typography id={ styles.headTypo }>Give us Feedback</Typography>
              <Box id={ styles.subHeadTypoBox }>
                <Typography id={ styles.subHeadTypo }>
                  <i>If you need help or want to report an issue, please</i>
                </Typography>
                <Box id={ styles.hyperlinkTypoBox }>
                  <Typography
                    id={ styles.hyperlinkTypo }
                    onClick={ () => {

                      history.push("/support");
                    
                    } }
                  >
                    <i>Contact Support </i>
                  </Typography>
                </Box>
                <Typography id={ styles.subHeadTypo }>
                  <i>instead.</i>
                </Typography>
              </Box>
            </Box>

            {/* name email box */ }
            <Box id={ styles.nameEmailBox }>
              <Box id={ styles.nameBox }>
                <Box id={ styles.nameTypoBox }>
                  <Typography id={ styles.nameTypo }>Name*</Typography>
                </Box>
                <TextField
                  fullWidth
                  className={ classes.customInput }
                  error={
                    errormsg ? details.name === "" ? true : false : false
                  }
                  value={ details.name }
                  name="name"
                  onChange={ e => {

                    handleChange(e.target.name, e.target.value.trimStart());
                  
                  } }
                />
              </Box>
              <Box id={ styles.emailBox }>
                <Box id={ styles.emailTypoBox }>
                  <Typography id={ styles.emailTypo }>Email*</Typography>
                </Box>
                <TextField
                  fullWidth
                  className={ classes.customInput }
                  error={
                    errormsg
                      ? details.email === "" ||
                        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                          details.email
                        )
                        ? true
                        : false
                      : false
                  }
                  name="email"
                  value={ details.email }
                  onChange={ e => {

                    handleChange(e.target.name, e.target.value.trimStart());
                  
                  } }
                />
              </Box>
            </Box>

            {/* overall exp box */ }
            <Box id={ styles.expBox }>
              <Box>
                <Typography id={ styles.expTypo }>
                  How would you rate your overall experience with this app?*
                </Typography>
              </Box>
              {/* emoji box */ }
              <Box
                id={ styles.emojiBox }
                sx={ {
                  border: errormsg
                    ? details.overallExperience === ""
                      ? "solid 1px #FD1515"
                      : "solid 1px #cccccc"
                    : "solid 1px #cccccc"
                } }
              >
                <Box
                  id={
                    details.overallExperience === "Great"
                      ? styles.checkedemoji
                      : ""
                  }
                >
                  <img
                    id={ styles.emojiImg }
                    src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/emoji-great.PNG` }
                    alt=""
                    onClick={ () => {

                      handleChange("overallExperience", "Great");
                    
                    } }
                  />
                </Box>
                <Box
                  id={
                    details.overallExperience === "Good"
                      ? styles.checkedemoji
                      : ""
                  }
                >
                  <img
                    id={ styles.emojiImg }
                    src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/emoji-smile.PNG` }
                    alt=""
                    onClick={ () => {

                      handleChange("overallExperience", "Good");
                    
                    } }
                  />
                </Box>
                <Box
                  id={
                    details.overallExperience === "Okay"
                      ? styles.checkedemoji
                      : ""
                  }
                >
                  <img
                    id={ styles.emojiImg }
                    src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/emoji-indifferent.PNG` }
                    alt=""
                    onClick={ () => {

                      handleChange("overallExperience", "Okay");
                    
                    } }
                  />
                </Box>
                <Box
                  id={
                    details.overallExperience === "Bad"
                      ? styles.checkedemoji
                      : ""
                  }
                >
                  <img
                    id={ styles.emojiImg }
                    src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/emoji-unhappy.PNG` }
                    alt=""
                    onClick={ () => {

                      handleChange("overallExperience", "Bad");
                    
                    } }
                  />
                </Box>
                <Box
                  id={
                    details.overallExperience === "Very Bad"
                      ? styles.checkedemoji
                      : ""
                  }
                >
                  <img
                    id={ styles.emojiImg }
                    src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/emoji-disappointed.PNG` }
                    alt=""
                    onClick={ () => {

                      handleChange("overallExperience", "Very Bad");
                    
                    } }
                  />
                </Box>
              </Box>
            </Box>

            {/* on your mind box */ }

            <Box id={ styles.mindBox }>
              <Box>
                <Typography id={ styles.mindTypo }>
                  Tell us what's on your mind*
                </Typography>
                <Box sx={ { marginTop: "10px" } }>
                  <TextField
                    error={
                      errormsg
                        ? details.userFeedback === ""
                          ? true
                          : false
                        : false
                    }
                    fullWidth
                    multiline
                    placeholder="What did you like about the app? What can be improved? New features you'd like to see?"
                    maxRows={ 4 }
                    minRows={ 4 }
                    className={ classes.multilintCustomInput }
                    name="userFeedback"
                    value={ details.userFeedback }
                    onChange={ e => {

                      handleChange(e.target.name, e.target.value.trimStart());
                    
                    } }
                  />
                </Box>
              </Box>
            </Box>

            {/* recommend box */ }
            <Box id={ styles.recommendBox }>
              <Box>
                <Typography id={ styles.recommendHeadTypo }>
                  How likely are you to recommend us to a friend or a
                  colleague?*
                </Typography>
              </Box>
              <Box
                id={ styles.ratingBox }
                sx={ {
                  border: errormsg
                    ? details.userRecommendation === 0
                      ? "solid 1px #FD1515"
                      : "solid 1px #cccccc"
                    : "solid 1px #cccccc"
                } }
              >
                <Box id={ styles.numbergroup }>
                  { Array(10)
                    .fill(null)
                    .map((data, index) => {

                      return (
                        <Box
                          // key={index}
                          id={ styles.numberBox }
                          sx={ {
                            backgroundColor:
                              details.userRecommendation === index + 1
                                ? "#071AFF"
                                : "white"
                          } }
                          onClick={ () => {

                            handleChange("userRecommendation", index + 1);
                          
                          } }
                        >
                          <Typography
                            id={ styles.numTypo }
                            sx={ {
                              color:
                                details.userRecommendation === index + 1
                                  ? "white"
                                  : "initial"
                            } }
                          >
                            { index + 1 }
                          </Typography>
                        </Box>
                      );
                    
                    }) }
                </Box>
                <Box id={ styles.recommendationtext }>
                  <Typography id={ styles.recommendText }>Unlikely</Typography>
                  <Typography id={ styles.recommendText }>Very Likely</Typography>
                </Box>
              </Box>
            </Box>
            { loading &&
              <Typography sx={ { textAlign: "center", fontWeight: "bold" } }>
                Sending...
              </Typography>
            }
            { errormsg && <ErrorMessage errormsg={ errormsg } /> }
            { successmsg && <SuccessMessage successmsg={ successmsg } /> }
            {/* submit cancel box */ }
            <Box id={ styles.submitCancelBox }>
              <Box id={ styles.submitBox }>
                <Button
                  variant="contained"
                  id={ styles.submitButton }
                  onClick={ handleSubmit }
                  disabled={ loading }
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );

}
