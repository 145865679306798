import React from "react";
import { Box } from "@mui/material";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
// import "./UploadPitchDeck.css";
import "./IndividualPdfNavBox.css";

export default function IndividualPdfNavBox (props) {

  const {
    pdfPages,
    pdfPageNumber,
    setPdfPageNumber,
    setPdfPages,
    // uploadedPdf,
    // uploadedPitchName,
    uploadedPitchUrl
  } = props;

  const onDocumentLoadSuccess = ({ numPages }) => {

    setPdfPages(numPages);
    setPdfPageNumber(1);
  
  };

  
  return (
    <Box
      sx={ {
        // border: 1,
        display      : "flex",
        flexDirection: "column",
        alignItems   : "center"
      } }
    >
      { uploadedPitchUrl ?
        <>
          { Array.from(Array(pdfPages).keys()).map((element, index) => {

            return (
              <Box
                sx={ {
                  border:
                    pdfPageNumber === index + 1
                      ? "2px solid blue"
                      : "1px solid #ffffff",
                  // height: "20vh",
                  // height: "135px",
                  height        : "9vw",
                  width         : "98%",
                  marginTop     : "5px",
                  display       : "flex",
                  justifyContent: "center",
                  alignItems    : "center",
                  cursor        : "pointer"
                } }
                onClick={ () => {

                  setPdfPageNumber(index + 1);
                
                } }
              >
                <Document
                  file={ uploadedPitchUrl }
                  // file={"./pdfDoc/todacSample.pdf"}
                  // file={"./pdfDoc/todac2.pdf"}
                  // file={"./todac2.pdf"}
                  // file={props.pdf}

                  onLoadSuccess={ onDocumentLoadSuccess }
                >
                  <Page
                    renderAnnotationLayer={ false }
                    width={ 180 }
                    pageNumber={ index + 1 }
                  />
                </Document>
              </Box>
            );
          
          }) }
        </>
        :
        <></>
      }
    </Box>
  );

}
