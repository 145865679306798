import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";

const ImageOptions = ({
  // handleClick,
  image,
  deleteUploadedImgs,
  IconSize
  // ...props
}) => {

  const [ anchorEl, setAnchorEl ] = useState(null);
  const open = Boolean(anchorEl);
  const handleIconClick = event => {

    setAnchorEl(event.currentTarget);
  
  };
  const handleClose = () => {

    setAnchorEl(null);
  
  };

  return (
    <>
      <IconButton
        sx={ { padding: "0px" } }
        onClick={ e => {

          e.stopPropagation();
          handleIconClick(e);
        
        } }
      >
        <MoreVertIcon sx={ { fontSize: IconSize, color: "black" } } />
      </IconButton>
      <Menu
        anchorEl={ anchorEl }
        open={ open }
        onClose={ e => {

          e.stopPropagation();
          handleClose();
        
        } }
        anchorOrigin={ {
          vertical  : "top",
          horizontal: "right"
        } }
        sx={ {
          "& .MuiMenu-list": {
            padding        : "0px",
            backgroundColor: "#707070",
            color          : "white"
          }
        } }
      // transformOrigin={{
      //   vertical: 'top',
      //   horizontal: 'start',
      // }}
      >
        {/* <MenuItem
          sx={{ fontSize: "12px" }}
          disableGutters={true}
          onClick={(e) => {
            e.stopPropagation();
            handleClick(e);
            handleClose();
          }}
        >
          <EditIcon
            sx={{ fontSize: "14px", marginRight: "7px", marginLeft: "7px" }}
          />
          <Box sx={{ paddingRight: "7px" }}>Update</Box>
        </MenuItem> */}
        { image &&
          <MenuItem
            sx={ { fontSize: "12px", borderTop: "solid 1px" } }
            disableGutters={ true }
            onClick={ e => {

              e.stopPropagation();
              deleteUploadedImgs();
              handleClose();
            
            } }
          >
            <DeleteIcon
              sx={ { fontSize: "14px", marginRight: "7px", marginLeft: "7px" } }
            />
            <Box>Delete</Box>
            {/* Delete */ }
          </MenuItem>
        }
      </Menu>
    </>
  );

};

export default ImageOptions;
