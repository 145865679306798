import AddIcon from "@mui/icons-material/Add";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box, Button,
  Dialog,
  DialogContent, IconButton
} from "@mui/material";
import React, { useState } from "react";
import IndividualValuePropositions from "../IndividualValuePropositions";
import PitchSolutionHypoFirst from "../PitchReferenceLinks/PitchSolutionHypoFirst";
import ReferenceLinkTooltip from "../PitchReferenceLinks/ReferenceLinkTooltip";
import SectionHeader from "../SectionHeader";
import styles from "./KeyValuePropositions.module.css";

export default function KeyValuePropositions ({
  isEdit,
  logoimg,
  isRender,
  slides,
  setSlides,
  isPublished,
  ...props
}) {

  const [ keyValueSolutionHypoDialog, setKeyValueSolutionHypoDialog ] =
    useState(false);

  const slide = slides[isRender];

  const handleChange = (name, value, columnindex) => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    let newvaluepropositions = [ ...newSlides[isRender]["valuePropositions"] ];

    newvaluepropositions.map((proposition, propositionIndex) => {

      if (propositionIndex === columnindex) {

        newvaluepropositions[propositionIndex][name] = value;
      
      }
      
      return proposition;
    
    });
    newSlides[isRender]["valuePropositions"] = newvaluepropositions;
    setSlides(newSlides);
  
  };

  const addProposition = () => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    newSlides[isRender]["valuePropositions"].push({
      name: `Value Proposition ${newSlides[isRender]["valuePropositions"].length + 1
      }`,
      value: ""
    });
    setSlides(newSlides);
  
  };

  const removeProposition = columnIndex => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    const newProposition = newSlides[isRender]["valuePropositions"].filter(
      (proposition, propositionIndex) => propositionIndex !== columnIndex
    );

    newSlides[isRender]["valuePropositions"] = newProposition;
    setSlides(newSlides);
  
  };

  const handleDetailsChange = (name, value) => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    newSlides[isRender][name] = value;
    setSlides(newSlides);
  
  };

  const KeyValueSolutionHypoDialogopen = () => {

    setKeyValueSolutionHypoDialog(true);
  
  };
  const KeyValueSolutionHypoDialogclose = () => {

    setKeyValueSolutionHypoDialog(false);
  
  };

  return (
    <Box>
      <SectionHeader
        isEdit={ isEdit }
        Collapsecomment={ props.Collapsecomment }
        collapseCommentFunction={ props.collapseCommentFunction }
        mainHead={ slide.name }
        handleTitleChange={ handleDetailsChange }
        logoimg={ logoimg }
        isPublished={ isPublished }
      />
      <Box
        id={ styles.mainBox }
      >
        <Box
          sx={ {
            width : "90%",
            height: "55vh",

            display       : "flex",
            alignItems    : "center",
            justifyContent: "space-around",
            alignContent  : "center",
            flexWrap      : "wrap",
            position      : "relative",
            rowGap        : "5vh"
          } }
        >
          { slide.valuePropositions.map((proposition, propositionIndex) => {

            return <IndividualValuePropositions
              proposition={ proposition }
              isEdit={ isEdit }
              propositionIndex={ propositionIndex }
              handleChange={ handleChange }
              removeProposition={ removeProposition }
            />;
          
          }) }

          {/* add icon box */ }

          { isEdit ?
            <Box
              sx={ {
                width         : "36px",
                height        : "36px",
                display       : "flex",
                justifyContent: "center",
                alignItems    : "center",
                borderRadius  : "50%",
                position      : "absolute",
                bottom        : "20px",
                right         : "-53px",
                backgroundColor:
                  slide.valuePropositions.length === 4 ? "#b0b0b0" : "#071AFF",
                cursor:
                  slide.valuePropositions.length === 4 ? "default" : "pointer"
              } }
              onClick={ () => {

                if (slide.valuePropositions.length < 4) {

                  addProposition();
                
                }
              
              } }
            >
              <AddIcon sx={ { fontSize: "32px", color: "#FFFFFF" } } />
            </Box>
            :
            <></>
          }

          { isEdit ?
            <Box sx={ { position: "absolute", left: "35px", bottom: "0px" } }>
              <ReferenceLinkTooltip
                title={ "Revisit the notes created in the Solution Module" }
              >
                <Button
                  style={ {
                    textTransform : "none",
                    textDecoration: "underline",
                    fontSize      : "12px",
                    padding       : "0px",
                    fontWeight    : "bold"
                  } }
                  onClick={ KeyValueSolutionHypoDialogopen }
                >
                  Solution Hypothesis
                </Button>
              </ReferenceLinkTooltip>
              <Dialog
                open={ keyValueSolutionHypoDialog }
                onClose={ KeyValueSolutionHypoDialogclose }
                PaperProps={ {
                  style: {
                    borderRadius   : "20px",
                    backdropFilter : "blur(100px)",
                    backgroundColor: "#ffffff",
                    boxShadow      : "0px 3px 6px #00000029",
                    width          : "70%"
                  }
                } }
                maxWidth="false"
                fullWidth
              >
                <DialogContent sx={ { padding: "40px" } }>
                  <Box
                    sx={ {
                      width   : "100%",
                      position: "relative"
                    } }
                  >
                    {/* <KeyValueSolutionHypo /> */ }
                    <PitchSolutionHypoFirst />
                    <IconButton
                      onClick={ () => {

                        KeyValueSolutionHypoDialogclose();
                      
                      } }
                      sx={ {
                        position: "absolute",
                        right   : "-40px",
                        top     : "-40px"
                      } }
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </Box>
                </DialogContent>
              </Dialog>
            </Box>
            :
            <></>
          }
        </Box>
      </Box>
    </Box>
  );

}
