import axios from "./axiosService";

// import axios from "axios";

export const getProblemSolution = async problemsolutionid => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/get_problemsolution`,
    { problemsolutionid }
  );

};

export const updateDefensibility = async (details, problemsolutionid) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/update_defensibility`,
    { details, problemsolutionid }
  );

};

export const updateNutshell = async (details, problemsolutionid) => {

  return await axios.post(`${process.env.REACT_APP_ADDRESS}/update_nutshell`, {
    details,
    problemsolutionid
  });

};

export const updateHypothesis = async (details, problemsolutionid) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/update_hypothesis`,
    { details, problemsolutionid }
  );

};

export const updateUserInterviews = async (questions, problemsolutionid) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/update_user_interviews`,
    { questions, problemsolutionid }
  );

};

export const getUserInterviewDetails = async (ventureid, problemsolutionid) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/get_userInterviews`,
    { problemsolutionid, ventureid }
  );

};

export const deleteUserInterviewQuestionsFile = async problemsolutionid => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/delete_user_interviewquestions`,
    { problemsolutionid }
  );

};

export const uploadUserInterviews = async formdata => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/upload_user_interviewquestions`,
    formdata
  );

};

export const downloadUserInterviewQuestions = async (
  questions,
  problemsolutionid
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/download_user_interviewsquestions`,
    { questions, problemsolutionid },
    {
      responseType: "arraybuffer",
      headers     : {
        "Content-Type": "application/json"
      }
    }
  );

};

export const updateSolutionCapability = async (segments, problemsolutionid) => {

  return await axios.post(`${process.env.REACT_APP_ADDRESS}/update_segments`, {
    segments,
    problemsolutionid
  });

};
