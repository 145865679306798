import React, { useEffect, useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box, Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import CustomMultilineTextfield from "../../../../components/CustomMultilineTextfield";
import HelpIconTooltip from "../../../../components/HelpIconTooltip";
import Data from "../../../../Data/Gtm.json";
import { getGtm } from "../../../../services/gtm";

export default function PitchGtmBuyersJourney() {

  const [ details, setDetails ] = useState({
    allMeansApproach     : "",
    awarenessInterest    : "",
    interestConsideration: "",
    considerationDecision: ""
  });

  const content = Data["gtmbuyerjourney"];

  const { venture } = useSelector(state => ({ ...state }));

  useEffect(() => {

    fetchGtm();
  
  }, []);

  const fetchGtm = async () => {

    try {

      const response = await getGtm(venture.gtm);
      const gtm = response.data;

      setDetails(gtm[content["sectionname"]]);
      //   setComments(gtm["comments"][content["sectionname"]]);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  return (
    <Box>
      <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
        <Box>
          <Typography
            sx={{ fontSize: "20px", color: "#5F5E5E", fontWeight: "bold" }}
          >
            GTM
          </Typography>
        </Box>
        <ChevronRightIcon sx={{ color: "gray" }} />
        <Box>
          <Typography>Buyer Journey</Typography>
        </Box>
      </Box>

      <Box>
        <Typography sx={{ fontSize: "12px" }}>
          Build an action plan to reach your target user segments at each stage
          of their buying journey.
        </Typography>
      </Box>

      <Box sx={{ width: "100%", display: "flex", marginTop: "20px" }}>
        {content.questions.map((Question, index) => {

          return index === 3 ?
            <Box sx={{ width: "25%" }}>
              <Box
                sx={{
                  boxShadow   : "0px 3px 6px #00000029",
                  border      : "1px solid #CCCCCC",
                  borderRadius: "10px",
                  width       : "90%"
                }}
              >
                <Typography
                  sx={{
                    border      : "1px solid #071AFF",
                    borderRadius: "10px",
                    padding     : "15px 20px",
                    fontSize    : "14px",
                    fontWeight  : "bold",
                    paddingRight: "0px",
                    height      : "40px"
                  }}
                >
                  {Question.question}

                  <HelpIconTooltip tooltipdata={Question.tooltip} />
                </Typography>
                <CustomMultilineTextfield
                  maxRows={10}
                  minRows={10}
                  width={"100%"}
                  border={"none"}
                  // fontSize={"12px"}
                  name={Question.name}
                  value={details[Question.name]}
                  placeholder={Question.placeholder}
                  disabled={true}
                />
              </Box>
            </Box>
            :
            <Box sx={{ width: "25%", position: "relative" }}>
              <Box
                sx={{
                  boxShadow   : "0px 3px 6px #00000029",
                  border      : "1px solid #CCCCCC",
                  borderRadius: "10px",
                  width       : "90%"
                }}
              >
                <Typography
                  sx={{
                    border      : "1px solid #071AFF",
                    borderRadius: "10px",
                    padding     : "15px 20px",
                    fontSize    : "14px",
                    fontWeight  : "bold",
                    paddingRight: "0px",
                    height      : "40px"
                  }}
                >
                  {Question.question}

                  <HelpIconTooltip tooltipdata={Question.tooltip} />
                </Typography>
                <CustomMultilineTextfield
                  maxRows={10}
                  minRows={10}
                  width={"100%"}
                  border={"none"}
                  // fontSize={"12px"}
                  name={Question.name}
                  value={details[Question.name]}
                  placeholder={Question.placeholder}
                  disabled={true}
                />
              </Box>
              <img
                //   id={styles.buyersJourneyRightChevron}
                src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/right-chevron.png`}
                alt="chevron"
                //   className={classes.chevronicon}
                style={{
                  position: "absolute",
                  width   : "40px",
                  top     : "5%",
                  left    : "87%",
                  zIndex  : 100
                }}
              />
            </Box>
          ;
        
        })}
      </Box>
    </Box>
  );

}
