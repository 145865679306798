/* eslint-disable camelcase */
import {
  Grid
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ModuleSections from "../../../components/ModuleSections";
import Data from "../../../Data/Gtm.json";
import solutiondata from "../../../Data/Solution.json";
import {
  getProblemSolution,
  updateNutshell
} from "../../../services/problemsolution";


// import { makeStyles } from "@mui/styles";
import BackNextButton from "../../../components/BackNextButton";
import IndividualSectionHeaders from "../../../components/IndividualSectionHeaders";
import ProblemSolutionFirst from "../../../components/ProblemSolutionFirst";
import { useUnmount } from "../../../components/useUnmount";
import {
  createProblemSolutionComment,
  deleteProblemSolutionComment,
  updateProblemSolutionComment
} from "../../../services/comment";
import styles from "./Solution.module.css";
// import { set } from "mongoose";

// const useStyles = makeStyles(theme => ({
//   customWidth: {
//     maxWidth       : 500,
//     fontSize       : 10,
//     backgroundColor: "white",
//     color          : "#707070",
//     boxShadow      : "0px 3px 6px #00000029",
//     border         : "1px solid #707070",
//     borderRadius   : 10
//   }
// }));

const Nutshell = () => {

  const [ details, setDetails ] = useState({ solution: "", differentiation: "" });
  const [ comments, setComments ] = useState([]);
  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const [ dialogbox, setDialog ] = useState(false);
  const history = useHistory();
  // const classes = useStyles();
  const pathname = history.location.pathname.substring(1);
  const content = Data.gtmvalueproposition;
  const [ isDataLoading, setIsDataLoading ] = useState(true);

  let { user, venture } = useSelector(state => {

    return { ...state };
  
  });

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);
    
    } else {

      setCollapsecomment(true);
    
    }
  
  };

  const handleChange = (name, value) => {

    setDetails(values => ({ ...values, [name]: value }));
  
  };

  useEffect(() => {

    fetchSolution();
  
  }, []);

  const fetchSolution = async () => {

    setIsDataLoading(true);

    try {

      const response = await getProblemSolution(venture.problemSolution);
      // console.log(response.data);

      setDetails(response.data.nutshell);
      setComments(response.data.comments.solution.nutshell);
      setIsDataLoading(false);
    
    } catch (error) {

      console.error(error);
      setIsDataLoading(false);
    
    }
  
  };

  // To get the updated usestate value to save in useEffect cleanup
  const detailsRef = useRef(details);

  detailsRef.current = details;

  let isSubmitting = false;

  // useUnmount => custom hook to save while navigating
  useUnmount(() => {

    if ( isDataLoading == false && !isSubmitting) {

      handleSave();
    
    }
  
  });
  const handleSave = async () => {

    try {

      await updateNutshell(detailsRef.current, venture.problemSolution);
      
      return true;
    
    } catch (error) {

      console.error(error);
      
      return false;
    
    }
  
  };

  const handleSubmit = async () => {

    try {

      isSubmitting = true;
      if(isDataLoading == false){

        await updateNutshell(details, venture.problemSolution);
        // console.log(response.data);
  
        if (pathname.includes("gtm")) {
  
          history.push("/gtmdefensibilitystrategy");
        
        } else {
  
          history.push("/defensibilitystrategy");
        
        }

      }
      isSubmitting = false;
    
    } catch (error) {

      isSubmitting = false;
      console.error(error);
    
    }
  
  };

  const addComment = async (commentText, replyTo) => {

    try {

      const response = await createProblemSolutionComment(
        commentText,
        user._id,
        "solution",
        "nutshell",
        venture.problemSolution,
        replyTo,
        window.location.href,
        `${pathname.includes("gtm") ? "Value Proposition" : "Solution"
        } > In a Nutshell`
      );

      setComments(response.data.comments.solution.nutshell);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateComment = async (commentid, commentText) => {

    try {

      const response = await updateProblemSolutionComment(
        commentText,
        user._id,
        commentid,
        venture._id,
        window.location.href,
        `${pathname.includes("gtm") ? "Value Proposition" : "Solution"
        } > In a Nutshell`
      );

      setComments(response.data.comments.solution.nutshell);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const deleteComment = async commentid => {

    try {

      const response = await deleteProblemSolutionComment(
        "solution",
        "nutshell",
        venture.problemSolution,
        commentid,
        user._id,
        window.location.href,
        `${pathname.includes("gtm") ? "Value Proposition" : "Solution"
        } > In a Nutshell`
      );

      setComments(response.data.comments.solution.nutshell);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  return (
    <ModuleSections
      Collapsecomment={ Collapsecomment }
      comments={ comments }
      addComment={ addComment }
      deleteComment={ deleteComment }
      updateComment={ updateComment }>
      <div className="box-display-style-for-next-and-back-button-with-justify">
        <div className="box-indiv-header-con">
          <IndividualSectionHeaders
            mainheading={
              pathname.includes("gtm") ? "Value Proposition" : "Solution"
            }
            subheading={ "In a Nutshell" }
            headingcontent={
              "Bring out your solution idea with its unique differentiation."
            }
            isGtm={ pathname.includes("gtm") ? true : false }
            Dialogopen={ Dialogopen }
            Dialogclose={ Dialogclose }
            dialogbox={ dialogbox }
            collapse_comment_function={ collapse_comment_function }
            content={ content }
            // isGtmChevron={pathname.includes("gtm") ? true : false}
            // isGtmChevron={pathname.includes("gtm") ? "gtmV" : "customer"}
            // isGtmChevron={
            //   pathname.includes("gtm") ? (
            //     <></>
            //   ) : pathname.includes("gtm") ? (
            //     "gtmV"
            //   ) : (
            //     "customer"
            //   )
            // }

            isGtmChevron={
              pathname.includes("gtm") ?
                "gtmV"
                : pathname.includes("inanutshell") ?
                  <></>
                  :
                  "customer"
              
            }
            isSaveButton={ true }
            handleSave={ handleSave }
          />
        </div>

        <Grid item xs={ 12 } id={ styles.maingrid }>
          <ProblemSolutionFirst
            content={ solutiondata.definition }
            firstvalue={ details.solution }
            firstname={ "solution" }
            secondvalue={ details.differentiation }
            secondname={ "differentiation" }
            handleChange={ handleChange }
            tooltipWidth={ "700px" }
          />
        </Grid>

        <div className="ttribe-back-next-button-con">
          <BackNextButton
            restrictedBackClick={ () =>
              pathname.includes("gtm") && isDataLoading == false
                ? history.push("/gtmvalueproposition")
                : history.push("/solutionintro")
            }
            backClick={ () =>
              pathname.includes("gtm") && isDataLoading == false
                ? history.push("/gtmvalueproposition")
                : history.push("/solutionintro")
            }
            restrictedNextClick={ () => {

              if (pathname.includes("gtm") && isDataLoading == false) {

                history.push("/gtmdefensibilitystrategy");
              
              } else {

                history.push("/defensibilitystrategy");
              
              }
            
            } }
            nextClick={ () => {

              handleSubmit();

            } }
          />
        </div>
      </div>
    </ModuleSections>
  );

};

export default Nutshell;
