import { Box } from "@mui/material";
import React from "react";
import FlexTextfieldImg from "../FlexTextfieldImg";

export default function Solution ({
  logoimg,
  slides,
  isRender,
  setSlides,
  isPublished,
  ...props
}) {

  const slide = slides[isRender];

  const handleChange = (name, value, columnindex) => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    let newsolutions = [ ...newSlides[isRender]["solutions"] ];

    newsolutions.map((problem, solutionindex) => {

      if (solutionindex === columnindex) {

        newsolutions[solutionindex][name] = value;
      
      }
    
    });
    newSlides[isRender]["solutions"] = newsolutions;
    setSlides(newSlides);
  
  };

  const handleDetailsChange = (name, value) => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    newSlides[isRender][name] = value;
    setSlides(newSlides);
  
  };

  return (
    <Box>
      <FlexTextfieldImg
        isEdit={ props.isEdit }
        Collapsecomment={ props.Collapsecomment }
        collapseCommentFunction={ props.collapseCommentFunction }
        imgOne={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/pitchTheSolution.png` }
        imgTwo={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/pitchSolutionDifferentiation.png` }
        imgThree={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/pitchSolutionLongTermVision.png` }
        logoimg={ logoimg }
        mainTitle={ slide.name }
        onTitleChange={ handleDetailsChange }
        leftTitle={ slide.solutions[0].name }
        midTitle={ slide.solutions[1].name }
        rightTitle={ slide.solutions[2].name }
        leftTxtFieldPlaceholder={
          "Provide a brief and simple explanation of your solution ideas"
        }
        midTxtFieldPlaceholder={
          "Specify how your solution will address your customers’ problem differently & better than others in the market."
        }
        rightTxtFieldPlaceholder={
          "Provide a 1 or 2 liner on where you see your venture in the long term. It should sound achievable, yet bold and inspiring."
        }
        // midTypoBoxWidth={"200px"}
        leftValue={ slide.solutions[0].value }
        midValue={ slide.solutions[1].value }
        rightValue={ slide.solutions[2].value }
        handleValueChange={ handleChange }
        isPublished={ isPublished }
        isRenderName={ props.isRenderName }
        isSolution={ true }
      />
    </Box>
  );

}
