import React from "react";
import { Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  customWidth: {
    maxWidth       : props => props.width ? props.width : "200px",
    fontSize       : 11,
    // backgroundColor: "white",
    // color: "#707070",
    backgroundColor: "#707070",
    color          : "white",
    boxShadow      : "0px 3px 6px #00000029",
    border         : "1px solid #707070",
    borderRadius   : 10,
    whiteSpace     : "pre-wrap"
  },
  helpicon: {
    color   : "#071AFF",
    fontSize: "15px"
  }
}));

export default function ReferenceLinkTooltip(props) {

  const classes = useStyles({ width: props.width });

  
  return (
    <Tooltip
      disableFocusListener
      title={props.title}
      placement={props.placement ? props.placement : "top-start"}
      //   placement={props.placement ? props.placement : "right-start"}
      classes={{ tooltip: classes.customWidth }}
      style={{ padding: props.padding ? props.padding : "" }}
    >
      {props.children}
    </Tooltip>
  );

}
