import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box, Button, Checkbox, FormControlLabel,
  FormGroup, Grid, IconButton, Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CustomMultilineTextfield from "../../../../components/CustomMultilineTextfield";
import {
  getPersona,
  updateIndividualCharacteristics
} from "../../../../services/persona";
import styles from "./TargetSegments.module.css";

const FacebookIcon = styled("span")(() => ({
  width          : 40,
  height         : 40,
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/uncheckedfacebook.png)`,
  backgroundSize : "cover"
}));

const FacebookCheckedIcon = styled(FacebookIcon)({
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/checkedfacebook.png)`
});

const InstagramIcon = styled("span")(() => ({
  width          : 40,
  height         : 40,
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/uncheckedinstagram.png)`,
  backgroundSize : "cover"
}));

const InstagramCheckedIcon = styled(InstagramIcon)({
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/checkedinstagram.png)`
});

const TwitterIcon = styled("span")(() => ({
  width          : 40,
  height         : 40,
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/uncheckedtwitter.png)`,
  backgroundSize : "cover"
}));

const TwitterCheckedIcon = styled(TwitterIcon)({
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/checkedtwitter.png)`
});

const LinkedinIcon = styled("span")(() => ({
  width          : 40,
  height         : 40,
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/uncheckedlinkedin.png)`,
  backgroundSize : "cover"
}));

const LinkedinCheckedIcon = styled(LinkedinIcon)({
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/checkedlinkedin.png)`
});

const PinterestIcon = styled("span")(() => ({
  width          : 40,
  height         : 40,
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/uncheckedpinterest.png)`,
  backgroundSize : "cover"
}));

const PinterestCheckedIcon = styled(PinterestIcon)({
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/checkedpinterest.png)`
});

const EditIndividualCharacteristics = props => {

  const [ details, setDetails ] = useState({
    outcomes             : "",
    goalsOrObjectives    : "",
    motivations          : "",
    decisions            : "",
    jobResponsibilities  : "",
    jobOutcome           : "",
    report               : "",
    tools                : "",
    socialMediaActiveness: [
      {
        icon     : "",
        ispresent: false
      },
      {
        icon     : "",
        ispresent: false
      },
      {
        icon     : "",
        ispresent: false
      },
      {
        icon     : "",
        ispresent: false
      },
      {
        icon     : "",
        ispresent: false
      }
    ],
    informationNeeds       : "",
    relevantCharacteristics: ""
  });
  const [ isedit, setIsedit ] = useState(false);
  const [ isSuccess, setIssuccess ] = useState(false);
  const history = useHistory();

  let { venture } = useSelector(state => {

    return { ...state };
  
  });

  useEffect(() => {

    fetchPersona();

    return () => {

      return handleSave;
    
    };
  
  }, []);

  const fetchPersona = async () => {

    try {

      const response = await getPersona(
        props.personaid,
        venture.problemSolution
      );
      let persona = response.data.persona;

      setDetails(persona.individualCharacteristics);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };
  
  // To get the updated usestate value to save in useEffect cleanup
  const detailsRef = useRef(details);

  detailsRef.current = details;
  const handleSave = async () => {

    try {

      await updateIndividualCharacteristics(
        detailsRef.current,
        props.personaid
      );
      
      return true;
    
    } catch (error) {

      console.error(error);
      
      return false;
    
    }
  
  };

  const handleSocialMediaChange = (e, socialindex, value) => {

    let newdetails = { ...details };
    let newsocialMediaActiveness = details.socialMediaActiveness.map(
      (socialmedia, index) => {

        if (socialindex === index) {

          if (e.target.checked) {

            return {
              icon     : value,
              ispresent: true
            };
          
          } else {

            return {
              icon     : "",
              ispresent: false
            };
          
          }
        
        } else {

          return socialmedia;
        
        }
      
      }
    );

    newdetails.socialMediaActiveness = newsocialMediaActiveness;
    setDetails(newdetails);
  
  };

  const handleChange = (name, value) => {

    setDetails(values => ({ ...values, [name]: value }));
  
  };

  const pathname = history.location.pathname.substring(1);

  const updateDetails = async event => {

    event.stopPropagation();
    try {

      await updateIndividualCharacteristics(
        details,
        props.personaid
      );

      setIsedit(!isedit);
      // history.push(`/userpersonadetails/${props.personaid}`);
      history.push(
        pathname.includes("gtm")
          ? `/gtmuserpersonadetails/${props.personaid}`
          : `/userpersonadetails/${props.personaid}`
      );
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const handleClose = () => {

    // history.push(`/userpersonadetails/${props.personaid}`);
    history.push(
      pathname.includes("gtm")
        ? `/gtmuserpersonadetails/${props.personaid}`
        : `/userpersonadetails/${props.personaid}`
    );
    props.gridScrollUp();
  
  };

  return (
    <Grid id={ styles.individualgrid } component="div" onClick={ handleClose }>
      <Box display="flex" justifyContent="flex-end">
        <Box sx={ { marginRight: "10px", position: "relative" } }>
          { isSuccess &&
            <Box sx={ { position: "absolute", top: "32px", left: "10px" } }>
              <Typography sx={ { color: "#08910B", fontSize: "14px" } }>
                Saved
              </Typography>
            </Box>
          }
          <Button
            variant="contained"
            color="primary"
            sx={ { padding: "3px 12px", textTransform: "capitalize" } }
            onClick={ async e => {

              e.stopPropagation();
              const isSaved = await handleSave();

              if (isSaved) {

                setIssuccess(true);
                setTimeout(() => {

                  setIssuccess(false);
                
                }, 3000);
              
              }
            
            } }
            disabled={ venture.userRole === "Commenter" }
          >
            Save
          </Button>
        </Box>
        <IconButton component="span" onClick={ updateDetails }>
          <HighlightOffIcon />
        </IconButton>
      </Box>
      <Typography id={ styles.individualtitle } gutterBottom>
        Individual Characteristics
      </Typography>
      <Box>
        <Typography variant="body2" id={ styles.mobileViewFontSize }>
          What are they trying to achieve? (jobs-to-do / outcomes)
        </Typography>
        {/* <TextField
          multiline
          variant="outlined"
          minRows={1}
          maxRows={1}
          sx={{ width: "100%" }}
          value={details.outcomes}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => {
            handleChange("outcomes", e.target.value);
          }}
        /> */}
        <CustomMultilineTextfield
          minRows={ 1 }
          maxRows={ 1 }
          width={ "100%" }
          value={ details.outcomes }
          handleClick={ e => {

            e.stopPropagation();
          
          } }
          handledetailsChange={ e => {

            handleChange("outcomes", e.target.value);
          
          } }
        />
      </Box>
      <Box>
        <Typography variant="body2" id={ styles.mobileViewFontSize }>
          What are their goals or objectives?
        </Typography>
        {/* <TextField
          multiline
          variant="outlined"
          minRows={1}
          maxRows={1}
          sx={{ width: "100%" }}
          value={details.goalsOrObjectives}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => {
            handleChange("goalsOrObjectives", e.target.value);
          }}
        /> */}
        <CustomMultilineTextfield
          minRows={ 1 }
          maxRows={ 1 }
          width={ "100%" }
          value={ details.goalsOrObjectives }
          handleClick={ e => {

            e.stopPropagation();
          
          } }
          handledetailsChange={ e => {

            handleChange("goalsOrObjectives", e.target.value);
          
          } }
        />
      </Box>
      <Box>
        <Typography variant="body2" id={ styles.mobileViewFontSize }>
          What are their motivations?
        </Typography>
        {/* <TextField
          multiline
          variant="outlined"
          minRows={1}
          maxRows={1}
          sx={{ width: "100%" }}
          value={details.motivations}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => {
            handleChange("motivations", e.target.value);
          }}
        /> */}
        <CustomMultilineTextfield
          minRows={ 1 }
          maxRows={ 1 }
          width={ "100%" }
          value={ details.motivations }
          handleClick={ e => {

            e.stopPropagation();
          
          } }
          handledetailsChange={ e => {

            handleChange("motivations", e.target.value);
          
          } }
        />
      </Box>
      <Box>
        <Typography variant="body2" id={ styles.mobileViewFontSize }>
          What decisions do they take?
        </Typography>
        {/* <TextField
          multiline
          variant="outlined"
          minRows={1}
          maxRows={1}
          sx={{ width: "100%" }}
          value={details.decisions}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => {
            handleChange("decisions", e.target.value);
          }}
        /> */}
        <CustomMultilineTextfield
          minRows={ 1 }
          maxRows={ 1 }
          width={ "100%" }
          value={ details.decisions }
          handleClick={ e => {

            e.stopPropagation();
          
          } }
          handledetailsChange={ e => {

            handleChange("decisions", e.target.value);
          
          } }
        />
      </Box>
      <Box>
        <Typography variant="body2" id={ styles.mobileViewFontSize }>
          What are their job responsibilities?
        </Typography>
        {/* <TextField
          multiline
          variant="outlined"
          minRows={1}
          maxRows={1}
          sx={{ width: "100%" }}
          value={details.jobResponsibilities}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => {
            handleChange("jobResponsibilities", e.target.value);
          }}
        /> */}
        <CustomMultilineTextfield
          minRows={ 1 }
          maxRows={ 1 }
          width={ "100%" }
          value={ details.jobResponsibilities }
          handleClick={ e => {

            e.stopPropagation();
          
          } }
          handledetailsChange={ e => {

            handleChange("jobResponsibilities", e.target.value);
          
          } }
        />
      </Box>
      <Box>
        <Typography variant="body2" id={ styles.mobileViewFontSize }>
          How is their job outcome measured?
        </Typography>
        {/* <TextField
          multiline
          variant="outlined"
          minRows={1}
          maxRows={1}
          sx={{ width: "100%" }}
          value={details.jobOutcome}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => {
            handleChange("jobOutcome", e.target.value);
          }}
        /> */}
        <CustomMultilineTextfield
          minRows={ 1 }
          maxRows={ 1 }
          width={ "100%" }
          value={ details.jobOutcome }
          handleClick={ e => {

            e.stopPropagation();
          
          } }
          handledetailsChange={ e => {

            handleChange("jobOutcome", e.target.value);
          
          } }
        />
      </Box>
      <Box>
        <Typography variant="body2" id={ styles.mobileViewFontSize }>
          Who do they report to?
        </Typography>
        {/* <TextField
          multiline
          variant="outlined"
          minRows={1}
          maxRows={1}
          sx={{ width: "100%" }}
          value={details.report}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => {
            handleChange("report", e.target.value);
          }}
        /> */}
        <CustomMultilineTextfield
          minRows={ 1 }
          maxRows={ 1 }
          width={ "100%" }
          value={ details.report }
          handleClick={ e => {

            e.stopPropagation();
          
          } }
          handledetailsChange={ e => {

            handleChange("report", e.target.value);
          
          } }
        />
      </Box>
      <Box>
        <Typography variant="body2" id={ styles.mobileViewFontSize }>
          What tools do they need to do their job?
        </Typography>
        {/* <TextField
          multiline
          variant="outlined"
          minRows={1}
          maxRows={1}
          sx={{ width: "100%" }}
          value={details.tools}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => {
            handleChange("tools", e.target.value);
          }}
        /> */}
        <CustomMultilineTextfield
          minRows={ 1 }
          maxRows={ 1 }
          width={ "100%" }
          value={ details.tools }
          handleClick={ e => {

            e.stopPropagation();
          
          } }
          handledetailsChange={ e => {

            handleChange("tools", e.target.value);
          
          } }
        />
      </Box>
      <Box>
        <Typography variant="body2" id={ styles.mobileViewFontSize }>
          Which social media platforms do they actively use?
        </Typography>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                disabled={ venture.userRole === "Commenter" }
                checkedIcon={ <FacebookCheckedIcon /> }
                icon={ <FacebookIcon /> }
                checked={ details.socialMediaActiveness[0].ispresent }
                onClick={ e => {

                  e.stopPropagation();
                
                } }
                onChange={ e => {

                  handleSocialMediaChange(
                    e,
                    0,
                    "/images/checkedfacebook.png"
                  );
                
                } }
                disableRipple
              />
            }
            label=""
          />
          <FormControlLabel
            control={
              <Checkbox
                disabled={ venture.userRole === "Commenter" }
                checkedIcon={ <InstagramCheckedIcon /> }
                icon={ <InstagramIcon /> }
                checked={ details.socialMediaActiveness[1].ispresent }
                onClick={ e => {

                  e.stopPropagation();
                
                } }
                onChange={ e => {

                  handleSocialMediaChange(
                    e,
                    1,
                    "/images/checkedinstagram.png"
                  );
                
                } }
                disableRipple
              />
            }
            label=""
          />
          <FormControlLabel
            control={
              <Checkbox
                disabled={ venture.userRole === "Commenter" }
                checkedIcon={ <TwitterCheckedIcon /> }
                icon={ <TwitterIcon /> }
                checked={ details.socialMediaActiveness[2].ispresent }
                onClick={ e => {

                  e.stopPropagation();
                
                } }
                onChange={ e => {

                  handleSocialMediaChange(
                    e,
                    2,
                    "/images/checkedtwitter.png"
                  );
                
                } }
                disableRipple
              />
            }
            label=""
          />
          <FormControlLabel
            control={
              <Checkbox
                disabled={ venture.userRole === "Commenter" }
                checkedIcon={ <LinkedinCheckedIcon /> }
                icon={ <LinkedinIcon /> }
                checked={ details.socialMediaActiveness[3].ispresent }
                onClick={ e => {

                  e.stopPropagation();
                
                } }
                onChange={ e => {

                  handleSocialMediaChange(
                    e,
                    3,
                    "/images/checkedlinkedin.png"
                  );
                
                } }
                disableRipple
              />
            }
            label=""
          />
          <FormControlLabel
            control={
              <Checkbox
                disabled={ venture.userRole === "Commenter" }
                checkedIcon={ <PinterestCheckedIcon /> }
                icon={ <PinterestIcon /> }
                checked={ details.socialMediaActiveness[4].ispresent }
                onClick={ e => {

                  e.stopPropagation();
                
                } }
                onChange={ e => {

                  handleSocialMediaChange(
                    e,
                    4,
                    "/images/checkedpinterest.png"
                  );
                
                } }
                disableRipple
              />
            }
            label=""
          />
        </FormGroup>
      </Box>
      <Box>
        <Typography variant="body2" id={ styles.mobileViewFontSize }>
          How do they gain relevant information about their needs?
        </Typography>
        {/* <TextField
          multiline
          variant="outlined"
          minRows={1}
          maxRows={1}
          sx={{ width: "100%" }}
          value={details.informationNeeds}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => {
            handleChange("informationNeeds", e.target.value);
          }}
        /> */}
        <CustomMultilineTextfield
          minRows={ 1 }
          maxRows={ 1 }
          width={ "100%" }
          value={ details.informationNeeds }
          handleClick={ e => {

            e.stopPropagation();
          
          } }
          handledetailsChange={ e => {

            handleChange("informationNeeds", e.target.value);
          
          } }
        />
      </Box>
      <Box>
        <Typography variant="body2" id={ styles.mobileViewFontSize }>
          Other relevant characteristics
        </Typography>
        {/* <TextField
          multiline
          variant="outlined"
          minRows={1}
          maxRows={1}
          sx={{ width: "100%" }}
          value={details.relevantCharacteristics}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => {
            handleChange("relevantCharacteristics", e.target.value);
          }}
        /> */}
        <CustomMultilineTextfield
          minRows={ 1 }
          maxRows={ 1 }
          width={ "100%" }
          value={ details.relevantCharacteristics }
          handleClick={ e => {

            e.stopPropagation();
          
          } }
          handledetailsChange={ e => {

            handleChange("relevantCharacteristics", e.target.value);
          
          } }
        />
      </Box>
    </Grid>
  );

};

export default EditIndividualCharacteristics;
