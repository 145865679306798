import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  TextField,
  Box,
  IconButton,
  Dialog,
  DialogContent
} from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import styles from "./MarketCompetition.module.css";
import DeleteCancel from "../../../../components/DeleteCancel";
import { useLocation } from "react-router-dom";

const TitleColumn = props => {

  const [ deletedialogbox, setDeleteDialog ] = useState(false);

  const deleteDialogopen = () => {

    setDeleteDialog(true);
  
  };
  const deleteDialogclose = () => {

    setDeleteDialog(false);
  
  };

  const location = useLocation();

  const { venture } = useSelector(state => ({ ...state }));

  const title = props.title;
  const index = props.index;
  const isdelete = props.isdelete;
  const content = props.content;

  return (
    <Box id={styles.othertitles}>
      {isdelete &&
        <IconButton
          sx={{ padding: "0px", marginRight: "10px" }}
          onClick={e => {

            e.stopPropagation();
            deleteDialogopen();
          
          }}
        >
          <RemoveCircleIcon
            fontSize="small"
            sx={{
              color: "#ff7f5c"
            }}
          />
        </IconButton>
      }
      <TextField
        disabled={
          venture.userRole === "Commenter" || content.title !== "Market Presence" || location.pathname.includes("pitchdeckintro") ? true
            : false
        }
        variant="standard"
        size="small"
        placeholder="Enter competitor name"
        value={title.name}
        onChange={e => {

          props.handleTitleChange(e.target.value, index);
        
        }}
        sx={{
          "& .MuiInputBase-input": {
            color     : "#707070",
            fontWeight: "400",
            textAlign : "center"
          },
          "& .Mui-disabled:before": {
            "-webkit-text-fill-color": "#707070 ",
            borderBottom             : "solid 1px #cccccc !important"
          },
          "& .Mui-disabled": {
            "-webkit-text-fill-color": "#707070 "
          }
        }}
      />
      <Dialog
        open={deletedialogbox}
        PaperProps={{
          style: {
            borderRadius  : "20px",
            backdropFilter: "blur(45px)",
            background    : "#FFFFFF 0% 0% no-repeat padding-box",
            boxShadow     : "0px 3px 6px #00000029",
            border        : "1px solid #E0E1E2"
          }
        }}
      >
        <DialogContent>
          <DeleteCancel
            name={"Are you sure you want to delete this column?"}
            deleteClick={e => {

              e.stopPropagation();
              props.handleRemoveColumn(index);
              deleteDialogclose();
            
            }}
            cancelClick={() => {

              deleteDialogclose();
            
            }}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );

};

export default TitleColumn;
