import React from "react";
import ReactApexChart from "react-apexcharts";

const TeamChart = props => {

  const colors = [
    "#b8da85", "#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0", "#546E7A", "#26a69a", "#D10CE8"
  ];

  let options = {
    chart: {
      // width: 380,
      type   : "pie",
      toolbar: {
        show : true,
        tools: {
          download   : true,
          selection  : true,
          zoom       : true,
          zoomin     : true,
          zoomout    : true,
          pan        : true,
          reset      : true,
          customIcons: []
        },
        autoSelected: "pan"
      }
    },
    title: {
      text : props.name,
      align: "center"
    },
    legend: {
      position: "bottom"
    },
    colors: colors.slice(0, props.chartData.length),
    labels: props.chartLabel
    // responsive: [{
    //     breakpoint: 480,
    //     options: {
    //         chart: {
    //             width: 200
    //         },
    //         legend: {
    //             position: "bottom"
    //         }
    //     }
    // }]
  };

  return (
    <div>
      <ReactApexChart options={ options } series={ props.chartData } type="pie" height={ 450 } />
    </div>

  );

};

export default TeamChart;
