import MoreVertIcon from "@mui/icons-material/MoreVert";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  Button, Checkbox, Dialog,
  DialogContent,
  IconButton, Menu, MenuItem, TableBody, TableCell, TableRow, Typography
} from "@mui/material";
// import InputBase from "@mui/material/InputBase";
// import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CustomMUISelect from "../../../../components/CustomMUISelect";
import CustomTextField from "../../../../components/CustomTextfield";
import DeleteCancel from "../../../../components/DeleteCancel";
import styles from "./Team.module.css";

// const BootstrapInput = styled(InputBase)(({ theme }) => ({
//   "label + &": {
//     marginTop: theme.spacing(3)
//   },
//   "& .MuiInputBase-input": {
//     borderRadius: 4,
//     position    : "relative",
//     // backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
//     // backgroundColor: "#edeeff",
//     // border: "1px solid #ced4da",
//     border      : "1px solid #e3e5ff",
//     fontSize    : 12,
//     width       : "8vw",
//     // width: "40%",
//     // padding: "10px 12px",
//     padding     : "5px 6px"
//     // fontWeight: "bold",
//   }
// }));

// const BootstrapInputSelect = styled(InputBase)(({ theme }) => ({
//   "label + &": {
//     marginTop: theme.spacing(3)
//   },
//   "& .MuiInputBase-input": {
//     "&:hover": {
//       border: "1px solid black"
//     },
//     borderRadius: 4,
//     position    : "relative",
//     border      : "1px solid #e3e5ff",
//     // backgroundColor: theme.palette.background.paper,
//     // backgroundColor: "#edeeff",
//     // border: "1px solid #ced4da",
//     fontSize    : 13,
//     width       : "6vw",
//     padding     : "5px 0px 0px 12px",
//     transition  : theme.transitions.create([ "border-color", "box-shadow" ])
//   }
// }));

const TeamTable = props => {

  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ deletedialogbox, setDeleteDialog ] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = event => {

    setAnchorEl(event.currentTarget);
  
  };
  const handleClose = () => {

    setAnchorEl(null);
  
  };

  const deleteDialogopen = () => {

    setDeleteDialog(true);
  
  };
  const deleteDialogclose = () => {

    setDeleteDialog(false);
  
  };
  const removeNonNumeric = (num, originalvalue) => {

    if (/^\d*\.?\d*$/.test(num.toString())) {

      return num;
    
    } else {

      return originalvalue;
    
    }
  
  };

  const { venture } = useSelector(state => ({ ...state }));

  const teamTypeOptions = [
    {
      value: "Founder"
    },
    {
      value: "Advisor"
    },
    {
      value: "Employee"
    },
    {
      value: "Investor"
    },
    {
      value: "Board"
    },
    {
      value: "Vendor"
    },
    {
      value: "Contractor"
    },
    {
      value: "Consultant"
    },
    {
      value: "Management"
    }
  ];

  const { member, memberindex, isdelete } = props;

  const location = useLocation();

  return (
    <TableBody>
      <TableRow>
        { isdelete &&
          <TableCell>
            <>
              <Button
                onClick={ e => {

                  e.stopPropagation();
                  deleteDialogopen();
                
                } }
              >
                <RemoveCircleIcon
                  fontSize="small"
                  sx={ { marginRight: "10px", color: "#ff7f5c" } }
                />
              </Button>
              <Dialog
                open={ deletedialogbox }
                PaperProps={ {
                  style: {
                    borderRadius  : "20px",
                    backdropFilter: "blur(45px)",
                    background    : "#FFFFFF 0% 0% no-repeat padding-box",
                    boxShadow     : "0px 3px 6px #00000029",
                    border        : "1px solid #E0E1E2"
                  }
                } }
              >
                <DialogContent>
                  <DeleteCancel
                    name={ "Are you sure you want to delete this member ?" }
                    deleteClick={ e => {

                      e.stopPropagation();
                      props.removeMember(memberindex);
                      deleteDialogclose();
                    
                    } }
                    cancelClick={ () => {

                      deleteDialogclose();
                    
                    } }
                  />
                </DialogContent>
              </Dialog>
            </>
          </TableCell>
        }

        <TableCell id={ styles.tableCell }>
          <Typography align="center">{ memberindex + 1 }</Typography>
        </TableCell>
        <TableCell
          // sx={{ "&.MuiTableCell": { padding: "none" } }}
          id={ styles.tableCellName }
          padding="none"
          sx={ { paddingTop: "4px", paddingRight: "none", paddingBottom: "4px" } }
        >
          {/* <BootstrapInput
            sx={{ marginTop: "5px", marginBottom: "5px" }}
            value={member.name}
            name="name"
            onChange={(e) =>
              props.handleMembersChange(
                e.target.name,
                e.target.value,
                memberindex
              )
            }
          /> */}
          <CustomTextField
            width={ "10vw" }
            value={ member.name }
            // type="text"
            border={ "1px solid #e3e5ff" }
            name="name"
            fontSize={ "12px" }
            padding={ "0px 0px" }
            // padding={"15px 7px"}
            handledetailsChange={ e =>
              props.handleMembersChange(
                e.target.name,
                e.target.value,
                memberindex
              )
            }
            disabled={ location.pathname.includes("pitchdeckintro") }
          />
          <IconButton
            onClick={ e => {

              handleClick(e);
            
            } }
            disabled={
              venture.userRole === "Commenter" ||
              location.pathname.includes("pitchdeckintro")
            }
            sx={ { marginTop: "5px", marginBottom: "5px" } }
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={ anchorEl }
            open={ open }
            onClose={ () => {

              handleClose();
            
            } }
            style={ { top: 5 } }
            anchorOrigin={ {
              vertical  : "top",
              horizontal: "right"
            } }
            sx={ {
              "& .MuiMenu-list": {
                padding        : "0px",
                backgroundColor: "#707070",
                color          : "white"
              }
            } }
          // transformOrigin={{
          //   vertical: 'top',
          //   horizontal: 'start',
          // }}
          >
            <MenuItem
              sx={ { fontSize: "12px" } }
              onClick={ e => {

                e.stopPropagation();
                deleteDialogopen();
                handleClose();
              
              } }
            >
              Delete
            </MenuItem>
          </Menu>
          <Dialog
            open={ deletedialogbox }
            PaperProps={ {
              style: {
                borderRadius  : "20px",
                backdropFilter: "blur(45px)",
                background    : "#FFFFFF 0% 0% no-repeat padding-box",
                boxShadow     : "0px 3px 6px #00000029",
                border        : "1px solid #E0E1E2"
              }
            } }
          >
            <DialogContent>
              <DeleteCancel
                name={ "Are you sure you want to delete this member ?" }
                deleteClick={ e => {

                  e.stopPropagation();
                  props.removeMember(memberindex);
                  deleteDialogclose();
                
                } }
                cancelClick={ () => {

                  deleteDialogclose();
                
                } }
              />
            </DialogContent>
          </Dialog>
        </TableCell>
        <TableCell id={ styles.tableCell }>
          <Checkbox
            checked={ member.toBeHired }
            disabled={ venture.userRole === "Commenter" || location.pathname.includes("pitchdeckintro") }
            name="toBeHired"
            sx={ {
              color          : "#e3e5ff",
              "&.Mui-checked": {
                // color: "blue",
                color: venture.userRole === "Commenter" || location.pathname.includes("pitchdeckintro") ? "gray" : "blue"
              }
            } }
            onChange={ e =>
              props.handleMembersChange(
                e.target.name,
                e.target.checked,
                memberindex
              )
            }
          />
        </TableCell>
        <TableCell id={ styles.tableCell }>
          { " " }
          {/* <FormControl
            sx={{
              m: 1,
            }}
            variant="standard"
          >
            <Select
              input={<BootstrapInputSelect />}
              value={member.type}
              name="type"
              onChange={(e) =>
                props.handleMembersChange(
                  e.target.name,
                  e.target.value,
                  memberindex
                )
              }
              
            >
              <MenuItem sx={{ fontSize: "13px" }} value="Founder">
                Founder
              </MenuItem>
              <MenuItem sx={{ fontSize: "13px" }} value={"Advisor"}>
                Advisor
              </MenuItem>
              <MenuItem sx={{ fontSize: "13px" }} value={"Employee"}>
                Employee
              </MenuItem>
              <MenuItem sx={{ fontSize: "13px" }} value={"Investor"}>
                Investor
              </MenuItem>
              <MenuItem sx={{ fontSize: "13px" }} value={"Board"}>
                Board
              </MenuItem>
              <MenuItem sx={{ fontSize: "13px" }} value={"Vendor"}>
                Vendor
              </MenuItem>
              <MenuItem sx={{ fontSize: "13px" }} value={"Contractor"}>
                Contractor
              </MenuItem>
              <MenuItem sx={{ fontSize: "13px" }} value={"Consultant"}>
                Consultant
              </MenuItem>
              <MenuItem sx={{ fontSize: "13px" }} value={"Management"}>
                Management
              </MenuItem>
            </Select>
          </FormControl>  */}
          {/* <CustomMUISelect
            value={member.type}
            name="type"
            handleChange={(e) =>
              props.handleMembersChange(
                e.target.name,
                e.target.value,
                memberindex
              )
            }
            muiOptions={teamTypeOptions}
            fontSize={"13px"}
            // width={"6vw"}
            padding={"4px 0px 4px 12px"}
            optionFontSize={"13px"}
            border={"1px solid #e3e5ff"}
          /> */}

          <CustomMUISelect
            value={ member.type }
            name="type"
            handleChange={ e =>
              props.handleMembersChange(
                e.target.name,
                e.target.value,
                memberindex
              )
            }
            muiOptions={ teamTypeOptions }
            fontSize={ "13px" }
            // width={"6vw"}
            padding={ "4px 0px 4px 12px" }
            optionFontSize={ "13px" }
            border={ "1px solid #e3e5ff" }
            disabled={ location.pathname.includes("pitchdeckintro") }
          />
        </TableCell>
        <TableCell id={ styles.tableCell }>
          {/* <BootstrapInput
            value={member.title}
            name="title"
            onChange={(e) =>
              props.handleMembersChange(
                e.target.name,
                e.target.value,
                memberindex
              )
            }
          /> */}
          <CustomTextField
            width={ "10vw" }
            value={ member.title }
            // type="text"
            border={ "1px solid #e3e5ff" }
            name="title"
            fontSize={ "12px" }
            padding={ "0px 0px" }
            handledetailsChange={ e =>
              props.handleMembersChange(
                e.target.name,
                e.target.value,
                memberindex
              )
            }
            disabled={ location.pathname.includes("pitchdeckintro") }
          />
        </TableCell>
        <TableCell id={ styles.tableCell }>
          { " " }
          {/* <BootstrapInput
            value={member.expertise}
            name="expertise"
            onChange={(e) =>
              props.handleMembersChange(
                e.target.name,
                e.target.value,
                memberindex
              )
            }
          /> */}
          <CustomTextField
            width={ "10vw" }
            value={ member.expertise }
            // type="text"
            border={ "1px solid #e3e5ff" }
            name="expertise"
            fontSize={ "12px" }
            padding={ "0px 0px" }
            handledetailsChange={ e =>
              props.handleMembersChange(
                e.target.name,
                e.target.value,
                memberindex
              )
            }
            disabled={ location.pathname.includes("pitchdeckintro") }
          />
        </TableCell>
        <TableCell id={ styles.tableCell }>
          { " " }
          <Checkbox
            checked={ member.shareholder }
            disabled={ venture.userRole === "Commenter" || location.pathname.includes("pitchdeckintro") }
            name="shareholder"
            sx={ {
              color          : "#e3e5ff",
              "&.Mui-checked": {
                // color: "blue",
                color: venture.userRole === "Commenter" || location.pathname.includes("pitchdeckintro") ? "gray" : "blue"
              }
            } }
            onChange={ e => {

              props.handleMembersChange(
                e.target.name,
                e.target.checked,
                memberindex
              );
            
            } }
          />
        </TableCell>
        <TableCell id={ styles.tableCell }>
          {/* <BootstrapInput
            value={member.sharePercentage}
            name="sharePercentage"
            onChange={(e) => {
              console.log(e);
              props.handleMembersChange(
                e.target.name,
                removeNonNumeric(e.target.value, member.sharePercentage),
                memberindex
              );
            }}
          /> */}
          <CustomTextField
            width={ "10vw" }
            value={ member.sharePercentage }
            // type="text"
            border={ "1px solid #e3e5ff" }
            name="sharePercentage"
            fontSize={ "12px" }
            padding={ "0px 0px" }
            handledetailsChange={ e => {

              props.handleMembersChange(
                e.target.name,
                removeNonNumeric(e.target.value, member.sharePercentage),
                memberindex
              );
            
            } }
            disabled={ location.pathname.includes("pitchdeckintro") }
          />
        </TableCell>
        <TableCell id={ styles.tableCell }>
          {/* <BootstrapInput
            value={member.otherDetails}
            name="otherDetails"
            onChange={(e) =>
              props.handleMembersChange(
                e.target.name,
                e.target.value,
                memberindex
              )
            }
          /> */}
          <CustomTextField
            width={ "10vw" }
            value={ member.otherDetails }
            // type="text"
            border={ "1px solid #e3e5ff" }
            name="otherDetails"
            fontSize={ "12px" }
            padding={ "0px 0px" }
            handledetailsChange={ e =>
              props.handleMembersChange(
                e.target.name,
                e.target.value,
                memberindex
              )
            }
            disabled={ location.pathname.includes("pitchdeckintro") }
          />
        </TableCell>
        <TableCell>
          {/* <BootstrapInput
            value={member.linkedinProfile}
            name="linkedinProfile"
            onChange={(e) =>
              props.handleMembersChange(
                e.target.name,
                e.target.value,
                memberindex
              )
            }
          /> */}
          <CustomTextField
            width={ "10vw" }
            value={ member.linkedinProfile }
            // type="text"
            border={ "1px solid #e3e5ff" }
            name="linkedinProfile"
            fontSize={ "12px" }
            padding={ "0px 0px" }
            handledetailsChange={ e =>
              props.handleMembersChange(
                e.target.name,
                e.target.value,
                memberindex
              )
            }
            disabled={ location.pathname.includes("pitchdeckintro") }
          />
        </TableCell>
      </TableRow>
    </TableBody>
  );

};

export default TeamTable;
