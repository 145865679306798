/* eslint-disable camelcase */
import {
  Box, Dialog,
  DialogContent,
  DialogContentText, Grid, Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CustomMultilineTextfield from "../../../components/CustomMultilineTextfield";
import HelpIconTooltip from "../../../components/HelpIconTooltip";
import IndividualSectionHeaders from "../../../components/IndividualSectionHeaders";
import ModuleSections from "../../../components/ModuleSections";
import NextButton from "../../../components/NextButton";
import { useUnmount } from "../../../components/useUnmount";
import Data from "../../../Data/Gtm.json";
import {
  createGtmComment,
  deleteGtmComment,
  updateGtmComment
} from "../../../services/comment";
import { getGtm, updateGtmValues } from "../../../services/gtm";

const useStyles = makeStyles(theme => ({
  maingrid: {
    padding: "20px 50px 0px"
  },
  gridbox: {
    boxShadow    : "0px 3px 6px #5F5E5E2B",
    borderRadius : "10px",
    flexDirection: "column"
  },
  heading: {
    fontWeight: "bold",
    color     : "#393939"
  },
  iconbtn: {
    paddingTop   : "0px",
    paddingBottom: "0px"
  },
  helpicon: {
    color   : "#071AFF",
    fontSize: "15px"
  },
  headingBox: {
    display       : "flex",
    justifyContent: "space-between",
    alignItems    : "center",
    marginBottom  : theme.spacing(2)
  },
  gtmbtnTypography: {
    color         : "#071AFF",
    textDecoration: "underline",
    marginRight   : "3vw"
  },
  gtmbtn: {
    textTransform: "none",
    fontWeight   : "bold",
    color        : "#071AFF"
  },
  image: {
    width       : "25px",
    height      : "25px",
    cursor      : "pointer",
    background  : "#F0F0F0 0% 0% no-repeat padding-box",
    boxShadow   : "0px 0px 6px #071AFF27",
    borderRadius: "5px",
    padding     : "3px"
  },
  question: {
    fontWeight: "bold",
    padding   : "20px 30px",
    color     : "#393939"
  },
  textarea: {
    backgroundColor: "#F7F7F7",
    borderRadius   : 10,
    padding        : "20px 30px"
  }
}));

const GTMSinglemodules = () => {

  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const [ comments, setComments ] = useState([]);
  const [ open, setOpen ] = useState(false);
  const [ value, setValue ] = useState("");
  const classes = useStyles();
  const history = useHistory();
  const pathname = history.location.pathname.substring(1);
  const content = Data[pathname];
  const [ isDataLoading, setIsDataLoading ] = useState(true);

  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);
    
    } else {

      setCollapsecomment(true);
    
    }
  
  };

  // const handleClickOpen = () => {

  //   setOpen(true);
  
  // };

  const handleClose = () => {

    setOpen(false);
  
  };
  const { user, venture } = useSelector(state => ({ ...state }));

  useEffect(() => {

    fetchGtm();
  
  }, []);

  // console.log(content);

  const fetchGtm = async () => {

    setIsDataLoading(true);

    try {

      const response = await getGtm(venture.gtm);
      // console.log(response.data);
      const gtm = response.data;

      setValue(gtm[content["sectionname"]]);
      setComments(gtm["comments"][content["sectionname"]]);
      setIsDataLoading(false);
    
    } catch (error) {

      console.error(error);
      setIsDataLoading(false);
    
    }
  
  };

  // To get the updated usestate value to save in useEffect cleanup
  const detailsRef = useRef(value);

  detailsRef.current = value;

  let isSubmitting = false;

  // useUnmount => custom hook to save while navigating
  useUnmount(() => {

    if ( isDataLoading == false && !isSubmitting) {

      handleSave();
    
    }
  
  });

  const handleSave = async () => {

    try {

      await updateGtmValues(
        venture.gtm,
        content["sectionname"],
        detailsRef.current
      );
      
      return true;
    
    } catch (error) {

      console.error(error);
      
      return false;
    
    }
  
  };

  const handleSubmit = async () => {

    try {

      isSubmitting = true;
      if(isDataLoading == false){

        await updateGtmValues(
          venture.gtm,
          content["sectionname"],
          value
        );
        
      }

      history.push(`/${content.nextURL}`);
      isSubmitting = false;
    
    } catch (error) {

      isSubmitting = false;
      console.error(error);
    
    }
  
  };

  const addComment = async (commentText, replyTo) => {

    try {

      const response = await createGtmComment(
        commentText,
        user._id,
        content["sectionname"],
        "",
        venture.gtm,
        replyTo,
        window.location.href,
        `GTM > ${content.modulename}`
      );

      setComments(response.data["comments"][content["sectionname"]]);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateComment = async (commentid, commentText) => {

    try {

      const response = await updateGtmComment(
        commentText,
        user._id,
        commentid,
        venture._id,
        window.location.href,
        `GTM > ${content.modulename}`
      );

      setComments(response.data["comments"][content["sectionname"]]);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const deleteComment = async commentid => {

    try {

      const response = await deleteGtmComment(
        content["sectionname"],
        "",
        venture.gtm,
        commentid,
        user._id,
        window.location.href,
        `GTM > ${content.modulename}`
      );

      setComments(response.data["comments"][content["sectionname"]]);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  return (
    <ModuleSections
      Collapsecomment={ Collapsecomment }
      comments={ comments }
      addComment={ addComment }
      deleteComment={ deleteComment }
      updateComment={ updateComment }>
      <div className="box-display-style-for-next-and-back-button-with-justify">
        <div className="box-indiv-header-con">
          <IndividualSectionHeaders
            mainheading={ "GTM" }
            subheading={ content.modulename }
            headingcontent={ content.description }
            isGtm={ true }
            collapse_comment_function={ collapse_comment_function }
            content={ content }
            notGuide={ true }
            isSaveButton={ true }
            handleSave={ handleSave }
          />
        </div>
        <Grid item xs={ 12 } className={ classes.maingrid }>
          <Box sx={ { height: "49vh" } }>
            <Grid container className={ classes.gridbox }>
              <Typography
                className={ classes.question }
                BackdropProps={ { invisible: true } }>
                { content.question }{ " " }
                {/* <IconButton className={classes.iconbtn}>
                <HelpIcon
                  className={classes.helpicon}
                  onMouseEnter={handleClickOpen}
                ></HelpIcon>
                
              </IconButton> */}
                <HelpIconTooltip
                  tooltipdata={ content.dialogtext }
                  width={ "500px" }
                />
                <Dialog
                  open={ open }
                  onClose={ handleClose }
                  PaperProps={ {
                    style: {
                      background    : "#FFFFFF 0% 0% no-repeat padding-box",
                      boxShadow     : "0px 3px 6px #00000029",
                      border        : "1px solid #CCCCCC",
                      borderRadius  : "20px",
                      opacity       : "1",
                      backdropFilter: "blur(24px)"
                    }
                  } }
                  BackdropProps={ { invisible: true } }>
                  <DialogContent>
                    <DialogContentText>
                      { content.dialogtext }
                      <Typography fontWeight="bold">Example: </Typography>
                      { content.dialogtextexample.map(text =>
                        <Typography gutterBottom>{ text }</Typography>
                      ) }
                    </DialogContentText>
                  </DialogContent>
                </Dialog>
              </Typography>
              {/* <TextField
              multiline
              // minRows={10}
              // maxRows={10}
              minRows={9}
              maxRows={9}
              InputProps={{
                className: classes.textarea,
              }}
              placeholder={content.placeholder}
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            /> */}
              <CustomMultilineTextfield
                minRows={ 9 }
                maxRows={ 9 }
                fontSize={ "0.7em" }
                backgroundColor={ "#F7F7F7" }
                borderRadius={ "10px" }
                placeholder={ content.placeholder }
                value={ value }
                handledetailsChange={ e => {

                  setValue(e.target.value);
                
                } }
                padding={ "20px 30px" }
                width={ "100%" }
              />
            </Grid>
          </Box>
        </Grid>
        <div className="ttribe-back-next-button-con">
          <NextButton
            restrictedNextClick={ () => {

              history.push(`/${content.nextURL}`);
            
            } }
            nextClick={ () => {

              handleSubmit();
            
            } }
          />
        </div>
      </div>
    </ModuleSections>
  );

};

export default GTMSinglemodules;
