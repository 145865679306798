/* eslint-disable no-cond-assign */
export default function midString(prev, next) {

  var p;
  var n;
  var pos;
  let str;

  for (pos = 0; p === n; pos++) {

    // find leftmost non-matching character
    p = pos < prev.length ? prev.charCodeAt(pos) : 96;
    n = pos < next.length ? next.charCodeAt(pos) : 123;
  
  }
  // copy identical part of string
  str = prev.slice(0, pos - 1);
  if (p === 96) {

    // prev string equals beginning of next
    while (n === 97) {

      // next character is 'a'
      // get char from next
      n = pos < next.length ? next.charCodeAt(pos++) : 123;
      // insert an 'a' to match the 'a'
      str += "a";
    
    }
    if (n === 98) {

      // next character is 'b'
      // insert an 'a' to match the 'b'
      str += "a";
      // set to end of alphabet
      n = 123;
    
    }
  
  } else if (p + 1 === n) {

    // found consecutive characters
    // insert character from prev
    str += String.fromCharCode(p);
    // set to end of alphabet
    n = 123;
    while ((p = pos < prev.length ? prev.charCodeAt(pos++) : 96) === 122) {

      // p='z'
      // insert 'z' to match 'z'
      str += "z";
    
    }
  
  }
  // append middle character
  
  return str + String.fromCharCode(Math.ceil((p + n) / 2));

}
