import {
  Box, Button, Grid, Typography
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import Data from "../../../../Data/Gtm.json";
import IndividualUsecase from "./IndividualUsecase";

import { makeStyles } from "@mui/styles";
import HelpIconTooltip from "../../../../components/HelpIconTooltip";

const useStyles = makeStyles(() => ({
  customWidth: {
    maxWidth       : 500,
    fontSize       : 10,
    backgroundColor: "white",
    color          : "#707070",
    boxShadow      : "0px 3px 6px #00000029",
    border         : "1px solid #707070",
    borderRadius   : 10,
    whiteSpace     : "pre-wrap"
  },
  helpicon: {
    color   : "#071AFF",
    fontSize: "15px"
  },
  question: {
    fontWeight     : "bold",
    padding        : "10px 30px",
    color          : "#393939",
    backgroundColor: "#F7F7F7",
    position       : "relative"
  },
  textarea: {
    "& .MuiInputBase-input": {
      fontSize: "16px",
      padding : "10px 20px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    }
  },
  gridbox: {
    boxShadow    : "0px 3px 6px #5F5E5E2B",
    borderRadius : "10px",
    marginBottom : "30px",
    flexDirection: "column"
  },
  addbtn: {
    backgroundColor: "#071AFF",
    textTransform  : "capitalize",
    borderRadius   : "10px"
  },
  removebtn: {
    backgroundColor: "#FFFFFF",
    textTransform  : "capitalize",
    border         : props =>
      props.isdelete ? "solid 1px #B0B0B0" : "solid 1px #ff7f5c",
    color       : props => props.isdelete ? "#B0B0B0" : "#ff7f5c",
    cursor      : props => props.isdelete ? "default" : "pointer",
    fontWeight  : "bold",
    marginLeft  : "20px",
    borderRadius: "10px"
  },
  removeiconbtn: {
    position: "absolute",
    right   : "97%",
    bottom  : "60%",
    color   : "#ff7f5c"
  },
  removeicon: {
    fontSize: "30px"
  }
}));

const EditUsecases = props => {

  // const [ dialogbox, setDialog ] = useState(false);
  // const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const [ isdelete, setIsdelete ] = useState(false);
  // const history = useHistory();
  const classes = useStyles({ isdelete });
  // const content = Data.gtmpositioning;
  const data = Data.positioningusecase;
  const usecases = props.usecases;

  let { venture } = useSelector(state => {

    return { ...state };
  
  });

  // const Dialogopen = () => {

  //   setDialog(true);
  
  // };
  // const Dialogclose = () => {

  //   setDialog(false);
  
  // };

  // const collapse_comment_function = () => {

  //   if (Collapsecomment) {

  //     setCollapsecomment(false);
    
  //   } else {

  //     setCollapsecomment(true);
    
  //   }
  
  // };

  return (
    <Grid
      item
      xs={ 12 }
      onClick={ () => {

        setIsdelete(false);
      
      } }
    >
      {/* <IndividualSectionHeaders
        mainheading={"Positioning"}
        subheading={"Use Case"}
        headingcontent={
          "Review Hero's plan - Real world use case scenarios for your product."
        }
        isGtm={false}
        Dialogopen={Dialogopen}
        Dialogclose={Dialogclose}
        dialogbox={dialogbox}
        collapse_comment_function={collapse_comment_function}
        content={content}
        isexpand={true}
        notGuide={true}
      /> */}
      <Box sx={ { marginBottom: "30px" } }>
        <Typography sx={ { fontWeight: "bold", fontSize: "1rem" } }>
          Give your hero a plan. Jot down some use cases or real-world scenarios
          that show how they can use your product to address their problem/need.
          <HelpIconTooltip tooltipdata={ data.tooltip } width={ "500px" } />
        </Typography>
      </Box>
      { usecases.map((usecase, index) => {

        return <IndividualUsecase
          isdelete={ isdelete }
          index={ index }
          classes={ classes }
          removeRow={ props.removeRow }
          data={ data }
          usecase={ usecase }
          handleChange={ props.handleUsecases }
          setIsdelete={ setIsdelete }
          isedit={ true }
        />;
      
      }) }
      <Grid container justifyContent="flex-end" mb={ 2 }>
        <Button
          variant="contained"
          className={ classes.addbtn }
          onClick={ () => {

            props.addRow();
          
          } }
          disabled={ venture.userRole === "Commenter" }
        >
          Add Usecase
        </Button>
        <Button
          variant="outlined"
          className={ classes.removebtn }
          onClick={ e => {

            e.stopPropagation();
            setIsdelete(!isdelete);
          
          } }
          disabled={ venture.userRole === "Commenter" }
        >
          Delete Usecase
        </Button>
      </Grid>
    </Grid>
  );

};

export default EditUsecases;
