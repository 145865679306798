import React from "react";
import {
  Box,
  Grid,
  OutlinedInput,
  TextField,
  InputAdornment
} from "@mui/material";
import { useSelector } from "react-redux";

import { useLocation } from "react-router-dom";
import "./BalanceSheet.module.css";

import HelpIconTooltip from "../../../../components/HelpIconTooltip";

const BalanceSheetTable = ({
  table,
  handleChange,
  financials,
  handleChangeRowHeaders,
  isedit,
  selectedindex,
  setIsedit,
  setSelectedindex,
  yearList
}) => {

  const { venture } = useSelector(state => ({ ...state }));

  const numberWithComma = num => {

    const numberWithComma = num && num.toString().replaceAll(",", "").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

    
    return numberWithComma;

  };

  const HandleYearRow = () => {

    if (yearList && yearList.length) {

      return <>{yearList?.map(year =>
        <div className="tribe-table-fin-header-col">
          {year}
        </div>
      )}</>;

    } else {

      return <>
        <div className="tribe-table-fin-header-col">1</div>
        <div className="tribe-table-fin-header-col">2</div>
        <div className="tribe-table-fin-header-col">3</div>
        <div className="tribe-table-fin-header-col">4</div>
        <div className="tribe-table-fin-header-col">5</div>
      </>;
    
    }

  };

  const location = useLocation();

  return (
    <div>
      <Grid
        container
        direction="column"
        rowSpacing={1}
        sx={{
          height: "100%",
          width : "100%"
        }}
      >
        <Grid item>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            justifyContent="space-between"
          >
            <Grid item sx={{ width: "20%" }}></Grid>
            {<HandleYearRow />}
          </Grid>
        </Grid>
        <div style={{ width: "20%", marginTop: "-3%" }}>
          <Grid
            item
            xs={12}
            sx={{
              color: "#888888"
            }}
          >
            <Box
              sx={{
                color: "#393939"
              }}
            >
              <strong>Assets</strong>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              color: "#888888"
            }}
          >
            <Box
              sx={{
                color: "#393939"
              }}
            >
              <strong>Current Assets</strong>
            </Box>
          </Grid>
        </div>

        {table && table.length && table?.slice(0, 7).map((row, rowindex) => {

          return (
            <Grid item>
              <Grid
                container
                direction="row"
                wrap="nowrap"
                justifyContent="space-between"
                alignItems="center"
              >
                {isedit && selectedindex === rowindex ?
                  <Box sx={{ width: "20%" }}>
                    <TextField
                      type="text"
                      sx={{
                        "& .MuiInputBase-input": {
                          fontSize: 11,
                          padding : "6px"
                        }
                      }}
                      onClick={e => {

                        e.stopPropagation();
                      
                      }}
                      value={row.rowLabel}
                      onChange={e => {

                        e.stopPropagation();
                        handleChangeRowHeaders(e.target.value, rowindex);
                      
                      }}
                    />
                  </Box>
                  :
                  <Grid
                    item
                    sx={{
                      width      : "20%",
                      height     : "100%",
                      fontSize   : 12,
                      color      : "#393939",
                      wordBreak  : "break-all",
                      marginRight: "10px"
                    }}
                    alignItems="center"
                    // className={classes.cellRow}
                    onClick={e => {

                      e.stopPropagation();
                      if (
                        venture.userRole !== "Commenter" &&
                        !location.pathname.includes("pitchdeckintro")
                      ) {

                        setIsedit(true);
                        setSelectedindex(rowindex);
                      
                      }
                    
                    }}
                  >
                    {row.rowLabel}
                    {row.rowName === "total_current_assets" &&
                      <HelpIconTooltip
                        tooltipdata={"Sum of all the above values in 'Current Assets'"}
                        width="60%" />
                    }
                  </Grid>
                }
                {row.rowVal.map((col, colindex) => {

                  return (
                    <Grid sx={{ width: "20%" }} item >
                      {location.pathname.includes("pitchdeckintro") ?
                        <OutlinedInput
                          type="text"
                          className="cus-cash-flow-row"
                          disabled={true}
                          startAdornment={
                            <InputAdornment position="start">
                              {financials.currency.split("")[0]}
                            </InputAdornment>
                          }
                          value={numberWithComma(col.value)}
                        />
                        : col.isOutput && venture.userRole !== "Commenter" ?
                          <OutlinedInput
                            className="cus-cash-flow-row"
                            type="text"
                            startAdornment={
                              <InputAdornment position="start">
                                {financials.currency.split("")[0]}
                              </InputAdornment>
                            }
                            value={numberWithComma(col.value)}
                            onChange={e => {

                              handleChange(e.target.value, rowindex, colindex);
                            
                            }}
                          />
                          :
                          <OutlinedInput
                            type="text"
                            className="cus-cash-flow-row-disabled"
                            startAdornment={
                              <InputAdornment position="start">
                                {financials.currency.split("")[0]}
                              </InputAdornment>
                            }
                            disabled={true}
                            value={numberWithComma(col.value)}
                          />
                      }
                    </Grid>
                  );
                
                })}
              </Grid>
            </Grid>
          );
        
        })}
        <Grid
          item
          xs={12}
          sx={{
            color: "#888888"
          }}
        >
          <Box
            sx={{
              color: "#393939"
            }}
          >
            <strong>Fixed Assets</strong>
          </Box>
        </Grid>
        {table && table.length && table?.slice(7, 13).map((row, rowindex) => {

          return (
            <Grid item>
              <Grid
                container
                direction="row"
                wrap="nowrap"
                justifyContent="space-between"
                alignItems="center"
              >
                {isedit && selectedindex === rowindex + 7 ?
                  <Box sx={{ width: "20%" }}>
                    <TextField
                      type="text"
                      sx={{
                        "& .MuiInputBase-input": {
                          fontSize: 11,
                          padding : "6px"
                        }
                      }}
                      onClick={e => {

                        e.stopPropagation();
                      
                      }}
                      value={row.rowLabel}
                      onChange={e => {

                        e.stopPropagation();
                        handleChangeRowHeaders(e.target.value, rowindex + 7);
                      
                      }}
                    />
                  </Box>
                  :
                  <Grid
                    item
                    sx={{
                      width      : "20%",
                      height     : "100%",
                      fontSize   : 12,
                      color      : "#393939",
                      wordBreak  : "break-all",
                      marginRight: "10px"
                    }}
                    alignItems="center"
                    // className={classes.cellRow}
                    onClick={e => {

                      e.stopPropagation();
                      if (
                        venture.userRole !== "Commenter" &&
                        !location.pathname.includes("pitchdeckintro")
                      ) {

                        setIsedit(true);
                        setSelectedindex(rowindex + 7);
                      
                      }
                    
                    }}
                  >
                    {row.rowLabel}
                    {row.rowName === "total_fixed_assets" &&
                      <HelpIconTooltip
                        tooltipdata={"Sum of all the above values in 'Fixed Assets'"}
                        width="60%" />
                    }
                  </Grid>
                }
                {row.rowVal.map((col, colindex) => {

                  return (
                    <Grid sx={{ width: "20%" }} item>
                      {location.pathname.includes("pitchdeckintro") ?
                        <OutlinedInput
                          disabled={true}
                          type="text"
                          startAdornment={
                            <InputAdornment position="start">
                              {financials.currency.split("")[0]}
                            </InputAdornment>
                          }
                          className="cus-cash-flow-row"
                          value={numberWithComma(col.value)}

                        />
                        : col.isOutput && venture.userRole !== "Commenter" ?
                          <OutlinedInput

                            className="cus-cash-flow-row"
                            type="text"
                            startAdornment={
                              <InputAdornment position="start">
                                {financials.currency.split("")[0]}
                              </InputAdornment>
                            }
                            value={numberWithComma(col.value)}
                            onChange={e => {

                              handleChange(
                                e.target.value,
                                rowindex + 7,
                                colindex
                              );
                            
                            }}
                          />
                          :
                          <OutlinedInput
                            className="cus-cash-flow-row-disabled"
                            type="text"
                            disabled={true}
                            startAdornment={
                              <InputAdornment position="start">
                                {financials.currency.split("")[0]}
                              </InputAdornment>
                            }
                            value={numberWithComma(col.value)}

                          />
                      }
                    </Grid>
                  );
                
                })}
              </Grid>
            </Grid>
          );
        
        })}
        <Grid
          item
          alignItems="center"
          xs={12}
          sx={{
            color: "#393939"
          }}
        >
          <Box>
            <strong>Other Assets</strong>
          </Box>
        </Grid>
        {table && table.length && table?.slice(13, 17).map((row, rowindex) => {

          return (
            <Grid item>
              <Grid
                container
                direction="row"
                wrap="nowrap"
                justifyContent="space-between"
                alignItems="center"
              >
                {isedit && selectedindex === rowindex + 13 ?
                  <Box sx={{ width: "20%" }}>
                    <TextField
                      type="text"
                      sx={{
                        "& .MuiInputBase-input": {
                          fontSize: 11,
                          padding : "6px"
                        }
                      }}
                      onClick={e => {

                        e.stopPropagation();
                      
                      }}
                      value={row.rowLabel}
                      onChange={e => {

                        e.stopPropagation();
                        handleChangeRowHeaders(e.target.value, rowindex + 13);
                      
                      }}
                    />
                  </Box>
                  :
                  <Grid
                    item
                    sx={{
                      width      : "20%",
                      height     : "100%",
                      fontSize   : 12,
                      color      : "#393939",
                      wordBreak  : "break-all",
                      marginRight: "10px"
                    }}
                    alignItems="center"
                    // className={classes.cellRow}
                    onClick={e => {

                      e.stopPropagation();
                      if (
                        venture.userRole !== "Commenter" &&
                        !location.pathname.includes("pitchdeckintro")
                      ) {

                        setIsedit(true);
                        setSelectedindex(rowindex + 13);
                      
                      }
                    
                    }}
                  >
                    {row.rowLabel}
                    {row.rowName === "total_other_assets" &&
                      <HelpIconTooltip
                        tooltipdata={"Sum of all the above values in 'Other Assets'"}
                        width="60%" />
                    }
                    {row.rowName === "total_assets" &&
                      <HelpIconTooltip
                        tooltipdata={"Current Assets + Fixed Assets + Other Assets"}
                        width="60%" />
                    }
                  </Grid>
                }
                {row.rowVal.map((col, colindex) => {

                  return (
                    <Grid sx={{ width: "20%" }} item >
                      {location.pathname.includes("pitchdeckintro") ?
                        <OutlinedInput
                          disabled={true}
                          type="text"
                          className="cus-cash-flow-row"
                          startAdornment={
                            <InputAdornment position="start">
                              {financials.currency.split("")[0]}
                            </InputAdornment>
                          }
                          value={numberWithComma(col.value)}

                        />
                        : col.isOutput && venture.userRole !== "Commenter" ?
                          <OutlinedInput

                            className="cus-cash-flow-row"
                            type="text"
                            startAdornment={
                              <InputAdornment position="start">
                                {financials.currency.split("")[0]}
                              </InputAdornment>
                            }
                            value={numberWithComma(col.value)}
                            onChange={e => {

                              handleChange(
                                e.target.value,
                                rowindex + 13,
                                colindex
                              );
                            
                            }}
                          />
                          :
                          <OutlinedInput
                            className="cus-cash-flow-row-disabled"
                            type="text"
                            startAdornment={
                              <InputAdornment position="start">
                                {financials.currency.split("")[0]}
                              </InputAdornment>
                            }
                            disabled={true}
                            value={numberWithComma(col.value)}

                          />
                      }
                    </Grid>
                  );
                
                })}
              </Grid>
            </Grid>
          );
        
        })}
        <Grid
          item
          alignItems="center"
          xs={12}
          sx={{
            color: "#393939"
          }}
        >
          <Box>
            <strong>Liabilities and Owner's Equity</strong>
          </Box>
        </Grid>
        <Grid
          item
          alignItems="center"
          xs={12}
          sx={{
            color: "#393939"
          }}
        >
          <Box>
            <strong>Current Liabilities</strong>
          </Box>
        </Grid>
        {table && table.length && table?.slice(17, 24).map((row, rowindex) => {

          return (
            <Grid item>
              <Grid
                container
                direction="row"
                wrap="nowrap"
                justifyContent="space-between"
                alignItems="center"
              >
                {isedit && selectedindex === rowindex + 17 ?
                  <Box sx={{ width: "20%" }}>
                    <TextField
                      type="text"
                      sx={{
                        "& .MuiInputBase-input": {
                          fontSize: 11,
                          padding : "6px"
                        }
                      }}
                      onClick={e => {

                        e.stopPropagation();
                      
                      }}
                      value={row.rowLabel}
                      onChange={e => {

                        e.stopPropagation();
                        handleChangeRowHeaders(e.target.value, rowindex + 17);
                      
                      }}
                    />
                  </Box>
                  :
                  <Grid
                    item
                    sx={{
                      width      : "20%",
                      height     : "100%",
                      fontSize   : 12,
                      color      : "#393939",
                      wordBreak  : "break-all",
                      marginRight: "10px"
                    }}
                    alignItems="center"
                    // className={classes.cellRow}
                    onClick={e => {

                      e.stopPropagation();
                      if (
                        venture.userRole !== "Commenter" &&
                        !location.pathname.includes("pitchdeckintro")
                      ) {

                        setIsedit(true);
                        setSelectedindex(rowindex + 17);
                      
                      }
                    
                    }}
                  >
                    {row.rowLabel}
                    {row.rowName === "total_current_liabilities" &&
                      <HelpIconTooltip
                        tooltipdata={"Sum of all the above values in 'Current Liabilities'"}
                        width="60%" />
                    }
                  </Grid>
                }
                {row.rowVal.map((col, colindex) => {

                  return (
                    <Grid sx={{ width: "20%" }} item >
                      {location.pathname.includes("pitchdeckintro") ?
                        <OutlinedInput
                          className="cus-cash-flow-row"
                          type="text"
                          disabled={true}
                          startAdornment={
                            <InputAdornment position="start">
                              {financials.currency.split("")[0]}
                            </InputAdornment>
                          }
                          value={numberWithComma(col.value)}

                        />
                        : col.isOutput && venture.userRole !== "Commenter" ?
                          <OutlinedInput
                            type="text"
                            className="cus-cash-flow-row"
                            startAdornment={
                              <InputAdornment position="start">
                                {financials.currency.split("")[0]}
                              </InputAdornment>
                            }
                            value={numberWithComma(col.value)}
                            onChange={e => {

                              handleChange(
                                e.target.value,
                                rowindex + 17,
                                colindex
                              );
                            
                            }}
                          />
                          :
                          <OutlinedInput
                            disabled={true}
                            type="text"
                            className="cus-cash-flow-row-disabled"
                            vstartAdornment={
                              <InputAdornment position="start">
                                {financials.currency.split("")[0]}
                              </InputAdornment>
                            }
                            value={numberWithComma(col.value)}

                          />
                      }
                    </Grid>
                  );
                
                })}
              </Grid>
            </Grid>
          );
        
        })}
        <Grid
          item
          alignItems="center"
          xs={12}
          sx={{
            color: "#393939"
          }}
        >
          <Box>
            <strong>Long Term Liabilities</strong>
          </Box>
        </Grid>
        {table && table.length && table?.slice(24, 28).map((row, rowindex) => {

          return (
            <Grid item>
              <Grid
                container
                direction="row"
                wrap="nowrap"
                justifyContent="space-between"
                alignItems="center"
              >
                {isedit && selectedindex === rowindex + 24 ?
                  <Box sx={{ width: "20%" }}>
                    <TextField
                      type="text"
                      sx={{
                        "& .MuiInputBase-input": {
                          fontSize: 11,
                          padding : "6px"
                        }
                      }}
                      onClick={e => {

                        e.stopPropagation();
                      
                      }}
                      value={row.rowLabel}
                      onChange={e => {

                        e.stopPropagation();
                        handleChangeRowHeaders(e.target.value, rowindex + 24);
                      
                      }}
                    />
                  </Box>
                  :
                  <Grid
                    item
                    sx={{
                      width      : "20%",
                      height     : "100%",
                      fontSize   : 12,
                      color      : "#393939",
                      wordBreak  : "break-all",
                      marginRight: "10px"
                    }}
                    alignItems="center"
                    // className={classes.cellRow}
                    onClick={e => {

                      e.stopPropagation();
                      if (
                        venture.userRole !== "Commenter" &&
                        !location.pathname.includes("pitchdeckintro")
                      ) {

                        setIsedit(true);
                        setSelectedindex(rowindex + 24);
                      
                      }
                    
                    }}
                  >
                    {row.rowLabel}
                    {row.rowName === "total_long_term_liabilities" &&
                      <HelpIconTooltip
                        tooltipdata={"Sum of all the above values in 'Long Term Liabilities'"}
                        width="60%" />
                    }
                  </Grid>
                }
                {row.rowVal.map((col, colindex) => {

                  return (
                    <Grid sx={{ width: "20%" }} item >
                      {location.pathname.includes("pitchdeckintro") ?
                        <OutlinedInput
                          disabled={true}
                          type="text"
                          className="cus-cash-flow-row"
                          startAdornment={
                            <InputAdornment position="start">
                              {financials.currency.split("")[0]}
                            </InputAdornment>
                          }
                          value={numberWithComma(col.value)}
                        />
                        : col.isOutput && venture.userRole !== "Commenter" ?
                          <OutlinedInput
                            className="cus-cash-flow-row"
                            type="text"
                            startAdornment={
                              <InputAdornment position="start">
                                {financials.currency.split("")[0]}
                              </InputAdornment>
                            }
                            value={numberWithComma(col.value)}
                            onChange={e => {

                              handleChange(
                                e.target.value,
                                rowindex + 24,
                                colindex
                              );
                            
                            }}
                          />
                          :
                          <OutlinedInput
                            className="cus-cash-flow-row-disabled"
                            type="text"
                            startAdornment={
                              <InputAdornment position="start">
                                {financials.currency.split("")[0]}
                              </InputAdornment>
                            }
                            value={numberWithComma(col.value)}

                          />
                      }
                    </Grid>
                  );
                
                })}
              </Grid>
            </Grid>
          );
        
        })}
        <Grid
          item
          alignItems="center"
          xs={12}
          sx={{
            color: "#393939"
          }}
        >
          <Box>
            <strong>Owner's Equity</strong>
          </Box>
        </Grid>
        {table && table.length && table?.slice(28, 34).map((row, rowindex) => {

          return (
            <Grid item>
              <Grid
                container
                direction="row"
                wrap="nowrap"
                justifyContent="space-between"
                alignItems="center"
              >
                {isedit && selectedindex === rowindex + 28 ?
                  <Box sx={{ width: "20%" }}>
                    <TextField
                      type="text"
                      sx={{
                        "& .MuiInputBase-input": {
                          fontSize: 11,
                          padding : "6px"
                        }
                      }}
                      onClick={e => {

                        e.stopPropagation();
                      
                      }}
                      value={row.rowLabel}
                      onChange={e => {

                        e.stopPropagation();
                        handleChangeRowHeaders(e.target.value, rowindex + 28);
                      
                      }}
                    />
                  </Box>
                  :
                  <Grid
                    item
                    sx={{
                      width      : "20%",
                      height     : "100%",
                      fontSize   : 12,
                      color      : "#393939",
                      wordBreak  : "break-all",
                      marginRight: "10px"
                    }}
                    alignItems="center"
                    // className={classes.cellRow}
                    onClick={e => {

                      e.stopPropagation();
                      if (
                        venture.userRole !== "Commenter" &&
                        !location.pathname.includes("pitchdeckintro")
                      ) {

                        setIsedit(true);
                        setSelectedindex(rowindex + 28);
                      
                      }
                    
                    }}
                  >
                    {row.rowLabel}
                    {row.rowName === "total_equity" &&
                      <HelpIconTooltip
                        tooltipdata={"Sum of all the above values in 'Owner's Equity'"}
                        width="60%" />
                    }
                    {row.rowName === "total_liabilities_and_owners_Equity" &&
                      <HelpIconTooltip
                        tooltipdata={"Total Liabilities and Owner's Equity = Current Liabilities + Long Term Liabilities + Owner's Equity"}
                        width="60%" />
                    }
                  </Grid>
                }
                {row.rowVal.map((col, colindex) => {

                  return (
                    <Grid sx={{ width: "20%" }} item >
                      {location.pathname.includes("pitchdeckintro") ?
                        <OutlinedInput
                          className="cus-cash-flow-row"
                          type="text"
                          disabled={true}
                          startAdornment={
                            <InputAdornment position="start">
                              {financials.currency.split("")[0]}
                            </InputAdornment>
                          }
                          value={numberWithComma(col.value)}

                        />
                        : col.isOutput && venture.userRole !== "Commenter" ?
                          <OutlinedInput
                            className="cus-cash-flow-row"
                            type="text"
                            startAdornment={
                              <InputAdornment position="start">
                                {financials.currency.split("")[0]}
                              </InputAdornment>
                            }
                            value={numberWithComma(col.value)}
                            onChange={e => {

                              handleChange(
                                e.target.value,
                                rowindex + 28,
                                colindex
                              );
                            
                            }}
                          />
                          :
                          <OutlinedInput
                            disabled={true}
                            type="text"
                            className="cus-cash-flow-row-disabled"
                            startAdornment={
                              <InputAdornment position="start">
                                {financials.currency.split("")[0]}
                              </InputAdornment>
                            }
                            value={numberWithComma(col.value)}

                          />
                      }
                    </Grid>
                  );
                
                })}
              </Grid>
            </Grid>
          );
        
        })}
      </Grid>
    </div>
  );

};

export default BalanceSheetTable;
