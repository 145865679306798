import {
  Box, Button,
  Dialog,
  DialogContent, IconButton, TextField, Typography
} from "@mui/material";
import React, { useState } from "react";
// import { useHistory } from "react-router-dom";
import styles from "./FlexTextfieldImg.module.css";
import PitchProblemConceptVision from "./PitchReferenceLinks/PitchProblemConceptVision";
import PitchProblemHypothesis from "./PitchReferenceLinks/PitchProblemHypothesis";

import PitchSolutionHypoFirst from "./PitchReferenceLinks/PitchSolutionHypoFirst";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ReferenceLinkTooltip from "./PitchReferenceLinks/ReferenceLinkTooltip";

export default function FlexTextfieldImg(props) {

  const [ problemDialog, setProblemDialog ] = useState(false);
  const [ conceptVisionDialog, setConceptVisionDialog ] = useState(false);
  const [ rootCauseDialog, setRouteCauseDialog ] = useState(false);
  const [ solutionHypoDialog, setSolutionHypoDialog ] = useState(false);
  const [ solutionHypoFirstDialog, setSolutionHypoFirstDialog ] = useState(false);
  const [ solutionConceptVisionDialog, setSolutionConceptVisionDialog ] =
    useState(false);

  // const history = useHistory();
  // const pathname = history.location.pathname.substring(1);

  const ProblemDialogopen = () => {

    setProblemDialog(true);
  
  };
  const ProblemDialogclose = () => {

    setProblemDialog(false);
  
  };
  const ConceptVisionDialogopen = () => {

    setConceptVisionDialog(true);
  
  };
  const ConceptVisionDialogclose = () => {

    setConceptVisionDialog(false);
  
  };
  const RootCauseDialogopen = () => {

    setRouteCauseDialog(true);
  
  };
  const RootCauseDialogclose = () => {

    setRouteCauseDialog(false);
  
  };

  // eslint-disable-next-line no-unused-vars
  const SolutionHypoDialogopen = () => {

    setSolutionHypoDialog(true);
  
  };
  
  const SolutionHypoDialogclose = () => {

    setSolutionHypoDialog(false);
  
  };
  const SolutionHypoFirstDialogopen = () => {

    setSolutionHypoFirstDialog(true);
  
  };
  const SolutionHypoFirstDialogclose = () => {

    setSolutionHypoFirstDialog(false);
  
  };
  const SolutionConceptVisionDialogopen = () => {

    setSolutionConceptVisionDialog(true);
  
  };
  const SolutionConceptVisionDialogclose = () => {

    setSolutionConceptVisionDialog(false);
  
  };

  
  return (
    <Box sx={{}}>
      {/* header box */}
      <Box
        id={styles.headBox}
      >
        {props.isEdit ?
          <Box sx={{ width: "80%" }}>
            <TextField
              value={props.mainTitle}
              onChange={e => {

                props.onTitleChange("name", e.target.value);
              
              }}
              inputProps={{ maxLength: 25 }}
              // helperText={`${props.mainTitle.length}/25`}
              fullWidth
              sx={{
                // "& .MuiOutlinedInput-root": {
                //   padding: "0px 0px",
                // },
                "& .MuiInputBase-input": {
                  fontSize  : "38px",
                  padding   : "1px 10px",
                  border    : "none",
                  color     : "#393939",
                  fontWeight: "bold"
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "2px dotted #B4B4FA"
                }
              }}
            />
          </Box>
          :
          <Box>
            <Typography
              id={styles.titleTypo}
            >
              {/* The Problem */}
              {props.mainTitle}
            </Typography>
          </Box>
        }

        <Box
          id={styles.iconMainBox}
        >
          <Box
            sx={{
              height        : "62px",
              width         : "62px",
              border        : props.logoimg ? "" : "2px solid #B4B4FA",
              borderRadius  : "7px",
              display       : "flex",
              justifyContent: "center",
              alignItems    : "center"
            }}
          >
            {props.logoimg &&
              <img
                src={props.logoimg}
                style={{ width: "100%", height: "auto", borderRadius: "7px" }}
                alt="logo"
              />
            }
          </Box>
          <Box sx={{ marginLeft: "10px" }}>
            {props.Collapsecomment || props.isPublished ?
              <></>
              :
              <img
                src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/Icon awesome-comment-dots.png`}
                style={{
                  cursor: "pointer"
                }}
                alt="comment"
                onClick={props.collapseCommentFunction}
              />
            }
          </Box>
        </Box>
      </Box>

      {/* content box */}
      <Box
        id={styles.contentBox}
      >
        <Box
          id={styles.individualProblemBox}
        >
          {/* 1st box */}
          <Box sx={{}}>
            <img
              src={props.imgOne + "?time=" + new Date().valueOf()}
              crossOrigin="anonymous"
              alt=""
              style={{ width: "80px", height: "80px", borderRadius: "4px" }}
            />
          </Box>
          <Box
            id={styles.problemTitleBox}
          >
            {props.isEdit ?
              <TextField
                value={props.leftTitle}
                onChange={e => {

                  props.handleValueChange("name", e.target.value, 0);
                
                }}
                inputProps={{ maxLength: 25 }}
                fullWidth
                multiline
                maxRows={1}
                minRows={1}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "0px 10px",
                    border : "none"
                  },
                  "& .MuiInputBase-input": {
                    fontSize  : "16px",
                    color     : "#393939",
                    textAlign : "center",
                    fontWeight: "bold"
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "2px dotted #B4B4FA"
                  }
                }}
              />
              :
              <Box
                id={styles.titleTypoBox}
              >
                <Typography
                  id={styles.subTitleTypo}
                >
                  {props.leftTitle}
                </Typography>
              </Box>
            }
          </Box>
          {props.isEdit ?
            <Box sx={{ width: "100%", position: "relative" }}>
              <TextField
                placeholder={props.leftTxtFieldPlaceholder}
                //   "Type the problem(s) or need(s) that you’ve identified in market as brief pointers"

                sx={{
                  "& .MuiInputBase-input": {
                    fontSize  : "14px",
                    color     : "#707070",
                    whiteSpace: "pre-line"
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "2px dotted #B4B4FA"
                  }
                }}
                multiline
                fullWidth
                maxRows={6}
                minRows={6}
                value={props.leftValue}
                onChange={e => {

                  props.handleValueChange("value", e.target.value, 0);
                
                }}
                inputProps={{ maxLength: 150 }}
              />
              {props.problemSlide &&
                <Box sx={{ position: "absolute", top: "-20px", right: "0px" }}>
                  <ReferenceLinkTooltip
                    title={"Revisit the notes created in the Problem Module"}
                  >
                    <Button
                      style={{
                        textTransform : "none",
                        textDecoration: "underline",
                        fontSize      : "12px",
                        padding       : "0px",
                        fontWeight    : "bold"
                      }}
                      onClick={ProblemDialogopen}
                    >
                      Problem Hypothesis
                    </Button>
                  </ReferenceLinkTooltip>
                  {/* </Tooltip> */}
                  <Dialog
                    open={problemDialog}
                    onClose={ProblemDialogclose}
                    PaperProps={{
                      style: {
                        borderRadius   : "20px",
                        backdropFilter : "blur(100px)",
                        backgroundColor: "#ffffff",
                        boxShadow      : "0px 3px 6px #00000029",
                        width          : "80%"
                      }
                    }}
                    maxWidth="false"
                    fullWidth
                  >
                    <DialogContent sx={{ padding: "40px" }}>
                      <Box
                        sx={{
                          width   : "100%",
                          position: "relative"
                        }}
                      >
                        {/* <CoverEditVenture /> */}
                        <PitchProblemHypothesis />
                        <IconButton
                          onClick={() => {

                            ProblemDialogclose();
                          
                          }}
                          sx={{
                            position: "absolute",
                            right   : "-40px",
                            top     : "-40px"
                          }}
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      </Box>
                    </DialogContent>
                  </Dialog>
                </Box>
              }

              {props.isSolution &&
                <Box sx={{ position: "absolute", top: "-20px", right: "0px" }}>
                  <ReferenceLinkTooltip
                    title={"Revisit the notes created in the Solution Module"}
                  >
                    <Button
                      style={{
                        textTransform : "none",
                        textDecoration: "underline",
                        fontSize      : "12px",
                        padding       : "0px",
                        fontWeight    : "bold"
                      }}
                      onClick={SolutionHypoFirstDialogopen}
                    >
                      Solution Hypothesis
                    </Button>
                  </ReferenceLinkTooltip>
                  <Dialog
                    open={solutionHypoFirstDialog}
                    onClose={SolutionHypoFirstDialogclose}
                    PaperProps={{
                      style: {
                        borderRadius   : "20px",
                        backdropFilter : "blur(100px)",
                        backgroundColor: "#ffffff",
                        boxShadow      : "0px 3px 6px #00000029",
                        width          : "70%"
                      }
                    }}
                    maxWidth="false"
                    fullWidth
                  >
                    <DialogContent sx={{ padding: "40px" }}>
                      <Box
                        sx={{
                          width   : "100%",
                          position: "relative"
                        }}
                      >
                        <PitchSolutionHypoFirst />
                        <IconButton
                          onClick={() => {

                            SolutionHypoFirstDialogclose();
                          
                          }}
                          sx={{
                            position: "absolute",
                            right   : "-40px",
                            top     : "-40px"
                          }}
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      </Box>
                    </DialogContent>
                  </Dialog>
                </Box>
              }
            </Box>
            :
            <Box
              sx={{
                width       : "100%",
                border      : "1px solid #BEBEBE",
                height      : "20vh",
                borderRadius: "5px",
                overflowY   : "auto",
                display     : "flex",
                alignItems  : "center"
              }}
            >
              <Box
                sx={{
                  margin  : "10px 10px",
                  wordWrap: "break-word"
                }}
              >
                <Typography
                  sx={{
                    fontSize  : "1.6vh",
                    color     : "#707070",
                    lineHeight: "1.5",
                    whiteSpace: "pre-line"
                  }}
                >
                  {props.leftValue}
                </Typography>
              </Box>
            </Box>
          }
        </Box>
        <Box
          id={styles.individualProblemBox}
        ><Box>
            <img
              src={props.imgTwo + "?time=" + new Date().valueOf()}
              crossOrigin="anonymous"
              alt=""
              style={{ width: "80px", height: "80px", borderRadius: "4px" }}
            />
          </Box>
          <Box
            id={styles.problemTitleBox}
          >
            {props.isEdit ?
              <TextField
                value={props.midTitle}
                onChange={e => {

                  props.handleValueChange("name", e.target.value, 1);
                
                }}
                inputProps={{ maxLength: 25 }}
                fullWidth
                multiline
                maxRows={1}
                minRows={1}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "0px 10px",
                    border : "none"
                  },
                  "& .MuiInputBase-input": {
                    fontSize  : "16px",
                    color     : "#393939",
                    textAlign : "center",
                    fontWeight: "bold"
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "2px dotted #B4B4FA"
                  }
                }}
              />
              :
              <Box id={styles.titleTypoBox}>
                <Typography
                  id={styles.subTitleTypo}
                >
                  {props.midTitle}
                </Typography>
              </Box>
            }
          </Box>
          {props.isEdit ?
            <Box sx={{ width: "100%", position: "relative" }}>
              <TextField
                placeholder={props.midTxtFieldPlaceholder}
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: "14px",
                    color   : "#707070"
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "2px dotted #B4B4FA"
                  }
                }}
                multiline
                fullWidth
                maxRows={6}
                minRows={6}
                value={props.midValue}
                onChange={e => {

                  props.handleValueChange("value", e.target.value, 1);
                
                }}
                inputProps={{ maxLength: 150 }}
              />
              {props.problemSlide &&
                <Box sx={{ position: "absolute", top: "-20px", right: "0px" }}>
                  <ReferenceLinkTooltip
                    title={
                      "Revisit the notes created in the Concept & Vision module"
                    }
                  >
                    <Button
                      style={{
                        textTransform : "none",
                        textDecoration: "underline",
                        fontSize      : "12px",
                        padding       : "0px",
                        fontWeight    : "bold"
                      }}
                      onClick={ConceptVisionDialogopen}
                    >
                      Concept & Vision
                    </Button>
                  </ReferenceLinkTooltip>
                  <Dialog
                    open={conceptVisionDialog}
                    onClose={ConceptVisionDialogclose}
                    PaperProps={{
                      style: {
                        borderRadius   : "20px",
                        backdropFilter : "blur(100px)",
                        backgroundColor: "#ffffff",
                        boxShadow      : "0px 3px 6px #00000029",
                        width          : "70%"
                      }
                    }}
                    maxWidth="false"
                    fullWidth
                  >
                    <DialogContent sx={{ padding: "40px" }}>
                      <Box
                        sx={{
                          width   : "100%",
                          position: "relative"
                        }}
                      >
                        <PitchProblemConceptVision />
                        <IconButton
                          onClick={() => {

                            ConceptVisionDialogclose();
                          
                          }}
                          sx={{
                            position: "absolute",
                            right   : "-40px",
                            top     : "-40px"
                          }}
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      </Box>
                    </DialogContent>
                  </Dialog>
                </Box>
              }

              {props.isSolution &&
                <Box sx={{ position: "absolute", top: "-20px", right: "0px" }}>
                  <Dialog
                    open={solutionHypoDialog}
                    onClose={SolutionHypoDialogclose}
                    PaperProps={{
                      style: {
                        borderRadius   : "20px",
                        backdropFilter : "blur(100px)",
                        backgroundColor: "#ffffff",
                        boxShadow      : "0px 3px 6px #00000029",
                        width          : "70%"
                      }
                    }}
                    maxWidth="false"
                    fullWidth
                  >
                    <DialogContent sx={{ padding: "40px" }}>
                      <Box
                        sx={{
                          width   : "100%",
                          position: "relative"
                        }}
                      >
                        <PitchSolutionHypoFirst />
                        <IconButton
                          onClick={() => {

                            SolutionHypoDialogclose();
                          
                          }}
                          sx={{
                            position: "absolute",
                            right   : "-40px",
                            top     : "-40px"
                          }}
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      </Box>
                    </DialogContent>
                  </Dialog>
                </Box>
              }
            </Box>
            :
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  width       : "100%",
                  border      : "1px solid #BEBEBE",
                  height      : "20vh",
                  borderRadius: "5px",
                  overflowY   : "auto",
                  display     : "flex",
                  alignItems  : "center"
                }}
              >
                <Box
                  sx={{
                    margin  : "10px 10px",
                    wordWrap: "break-word"
                  }}
                >
                  <Typography
                    sx={{
                      fontSize  : "1.6vh",
                      color     : "#707070",
                      lineHeight: "1.5",
                      whiteSpace: "pre-line"
                    }}
                  >
                    {props.midValue}
                  </Typography>
                </Box>
              </Box>
            </Box>
          }
        </Box>
        <Box
          id={styles.individualProblemBox}
        >
          <Box>
            <img
              src={props.imgThree + "?time=" + new Date().valueOf()}
              crossOrigin="anonymous"
              alt=""
              style={{ width: "80px", height: "80px", borderRadius: "4px" }}
            />
          </Box>
          <Box
            id={styles.problemTitleBox}
          >
            {props.isEdit ?
              <TextField
                value={props.rightTitle}
                onChange={e => {

                  props.handleValueChange("name", e.target.value, 2);
                
                }}
                inputProps={{ maxLength: 25 }}
                fullWidth
                multiline
                maxRows={1}
                minRows={1}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "0px 10px",
                    border : "none"
                  },
                  "& .MuiInputBase-input": {
                    fontSize  : "16px",
                    color     : "#393939",
                    textAlign : "center",
                    fontWeight: "bold"
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "2px dotted #B4B4FA"
                  }
                }}
              />
              :
              <Box id={styles.titleTypoBox}>
                <Typography
                  id={styles.subTitleTypo}
                >
                  {props.rightTitle}
                </Typography>
              </Box>
            }
          </Box>
          {props.isEdit ?
            <Box sx={{ width: "100%", position: "relative" }}>
              <TextField
                placeholder={props.rightTxtFieldPlaceholder}
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: "14px",
                    color   : "#707070"
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "2px dotted #B4B4FA"
                  }
                }}
                multiline
                fullWidth
                maxRows={6}
                minRows={6}
                value={props.rightValue}
                onChange={e => {

                  props.handleValueChange("value", e.target.value, 2);
                
                }}
                inputProps={{ maxLength: 150 }}
              />
              {props.problemSlide &&
                <Box sx={{ position: "absolute", top: "-20px", right: "0px" }}>
                  <ReferenceLinkTooltip
                    title={
                      "Revisit the notes created in the Root Cause section of the Problem module"
                    }
                  >
                    <Button
                      style={{
                        textTransform : "none",
                        textDecoration: "underline",
                        fontSize      : "12px",
                        padding       : "0px",
                        fontWeight    : "bold"
                      }}
                      onClick={RootCauseDialogopen}
                    >
                      Root Cause
                    </Button>
                  </ReferenceLinkTooltip>
                  <Dialog
                    open={rootCauseDialog}
                    onClose={RootCauseDialogclose}
                    PaperProps={{
                      style: {
                        borderRadius   : "20px",
                        backdropFilter : "blur(100px)",
                        backgroundColor: "#ffffff",
                        boxShadow      : "0px 3px 6px #00000029",
                        width          : "80%"
                      }
                    }}
                    maxWidth="false"
                    fullWidth
                  >
                    <DialogContent sx={{ padding: "40px" }}>
                      <Box
                        sx={{
                          width   : "100%",
                          position: "relative"
                        }}
                      >
                        {/* <PitchProblemRootCause /> */}
                        <PitchProblemHypothesis />
                        <IconButton
                          onClick={() => {

                            RootCauseDialogclose();
                          
                          }}
                          sx={{
                            position: "absolute",
                            right   : "-40px",
                            top     : "-40px"
                          }}
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      </Box>
                    </DialogContent>
                  </Dialog>
                </Box>
              }

              {props.isSolution &&
                <Box sx={{ position: "absolute", top: "-20px", right: "0px" }}>
                  <ReferenceLinkTooltip
                    title={
                      "Revisit the notes created in the Concept & Vision module"
                    }
                  >
                    <Button
                      style={{
                        textTransform : "none",
                        textDecoration: "underline",
                        fontSize      : "12px",
                        padding       : "0px",
                        fontWeight    : "bold"
                      }}
                      onClick={SolutionConceptVisionDialogopen}
                    >
                      Concept & Vision
                    </Button>
                  </ReferenceLinkTooltip>
                  <Dialog
                    open={solutionConceptVisionDialog}
                    onClose={SolutionConceptVisionDialogclose}
                    PaperProps={{
                      style: {
                        borderRadius   : "20px",
                        backdropFilter : "blur(100px)",
                        backgroundColor: "#ffffff",
                        boxShadow      : "0px 3px 6px #00000029",
                        width          : "70%"
                      }
                    }}
                    maxWidth="false"
                    fullWidth
                  >
                    <DialogContent sx={{ padding: "40px" }}>
                      <Box
                        sx={{
                          width   : "100%",
                          position: "relative"
                        }}
                      >
                        <PitchProblemConceptVision />
                        <IconButton
                          onClick={() => {

                            SolutionConceptVisionDialogclose();
                          
                          }}
                          sx={{
                            position: "absolute",
                            right   : "-40px",
                            top     : "-40px"
                          }}
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      </Box>
                    </DialogContent>
                  </Dialog>
                </Box>
              }
            </Box>
            :
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  width       : "100%",
                  border      : "1px solid #BEBEBE",
                  height      : "20vh",
                  borderRadius: "5px",
                  overflowY   : "auto",
                  display     : "flex",
                  alignItems  : "center"
                  
                }}
              >
                <Box
                  sx={{
                    margin: "10px 10px"
                  }}
                >
                  <Typography
                    sx={{
                      fontSize  : "1.6vh",
                      color     : "#707070",
                      lineHeight: "1.5",
                      whiteSpace: "pre-line"
                    }}
                  >
                    {props.rightValue}
                  </Typography>
                </Box>
              </Box>
            </Box>
          }
        </Box>
      </Box>
    </Box>
  );

}
