/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from "react";
import {
  Box, CircularProgress, Grid, Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import BackButton from "../../../../components/BackButton";
import BackNextButton from "../../../../components/BackNextButton";
import IndividualSectionHeaders from "../../../../components/IndividualSectionHeaders";
import ModuleSections from "../../../../components/ModuleSections";
import { useUnmount } from "../../../../components/useUnmount";
import {
  createMonetisationRevenueStreamsComment,
  deleteMonetisationRevenueStreamsComment,
  updateMonetisationRevenueStreamsComment
} from "../../../../services/comment";
import {
  getMonetisationDetails,
  updateMonetisationDetails
} from "../../../../services/monetisation";
import Costs from "./CreateEditPricing/Costs";
import CustomerWorth from "./CreateEditPricing/CustomerWorth";
import PriceAnchors from "./CreateEditPricing/PriceAnchors";
import PriceDiscrimination from "./CreateEditPricing/PriceDiscrimination";
import PriceSensitive from "./CreateEditPricing/PriceSensitive";
import PricingModel from "./CreateEditPricing/PricingModel";
import ProposePricingPlan from "./CreateEditPricing/ProposePricingPlan";
import styles from "./CreatePricingComponent.module.css";
import PricingIndividualNavBar from "../../../../components/PricingIndividualNavBar";

export default function CreatePricingComponent() {

  const [ revenueStreams, setRevenueStreams ] = useState([]);
  const [ comments, setComments ] = useState({
    customerWorth            : [],
    pricingModel             : [],
    anchors                  : [],
    purchasingDecisionFactors: [],
    priceDiscrimination      : [],
    costs                    : [],
    pricingImage             : []
  });
  // eslint-disable-next-line no-unused-vars
  const [ isexpand, setIsexpand ] = useState(false);
  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const [ dialogbox, setDialog ] = useState(false);
  let isSubmitting = false;

  const { user, venture } = useSelector(state => ({ ...state }));

  const history = useHistory();
  const location = useLocation();

  const useQuery = () => {

    return new URLSearchParams(location.search);
  
  };
  const query = useQuery();
  const revenueStreamIndex = Number(query.get("index"));
  const hashValue = query.get("hashvalue");
  const [ isDataLoading, setIsDataLoading ] = useState(true);

  // To get the updated usestate value to save in useEffect cleanup
  const detailsRef = useRef(revenueStreams);

  detailsRef.current = revenueStreams;

  // useUnmount => custom hook to save while navigating
  useUnmount(() => {

    if (isDataLoading == false && !isSubmitting) {

      handleSave();
    
    }
  
  });

  const handleSave = async () => {

    try {

      await updateMonetisationDetails(
        "revenueStreams",
        detailsRef.current,
        venture.monetisation
      );
      
      return true;
    
    } catch (error) {

      console.error(error);
      
      return false;
    
    }
  
  };

  useEffect(() => {

    fetchMonetisation();
  
  }, []);

  const fetchMonetisation = async () => {

    setIsDataLoading(true);

    try {

      const response = await getMonetisationDetails(venture.monetisation);
      // console.log(response);

      setRevenueStreams(response.data.revenueStreams);
      setComments(response.data.revenueStreams[revenueStreamIndex]["comments"]);
      setIsDataLoading(false);
    
    } catch (error) {

      console.error("error while fetching monetisation", error);
      setIsDataLoading(false);
    
    }
  
  };

  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);
    
    } else {

      setCollapsecomment(true);
    
    }
  
  };

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  const handleUpdateMonetisationDetails = async () => {

    try {

      isSubmitting = true;
      if(isDataLoading == false){

        await updateMonetisationDetails(
          "revenueStreams",
          revenueStreams,
          venture.monetisation
        );
        
      }
      isSubmitting = false;
    
    } catch (error) {

      isSubmitting = false;
      console.error("error while updating monetisation", error);
    
    }
  
  };

  const addComment = async (commentText, replyTo) => {

    try {

      const response = await createMonetisationRevenueStreamsComment(
        commentText,
        user._id,
        hashValue,
        venture.monetisation,
        replyTo,
        window.location.href,
        "Monetisation > Pricing",
        revenueStreamIndex
      );

      setComments(response.data.revenueStreams[revenueStreamIndex]["comments"]);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateComment = async (commentid, commentText) => {

    try {

      const response = await updateMonetisationRevenueStreamsComment(
        commentid,
        commentText,
        user._id,
        venture._id,
        window.location.href,
        "Monetisation > Pricing"
      );

      setComments(response.data.revenueStreams[revenueStreamIndex]["comments"]);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const deleteComment = async commentid => {

    try {

      const response = await deleteMonetisationRevenueStreamsComment(
        user._id,
        commentid,
        venture.monetisation,
        hashValue,
        window.location.href,
        "Monetisation > Pricing",
        revenueStreamIndex
      );

      setComments(response.data.revenueStreams[revenueStreamIndex]["comments"]);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  // console.log("comments", comments);
  return (
    <ModuleSections
      isexpand={isexpand}
      Collapsecomment={Collapsecomment}
      comments={comments[hashValue]}
      addComment={addComment}
      deleteComment={deleteComment}
      updateComment={updateComment}>
      {revenueStreams.length === 0 ?  <div className="box-display-style-for-next-and-back-button-with-justify">
        <Grid id={styles.pricingComponentMainGridLoading} item xs={12}>
          <Grid item>
            <CircularProgress size="60px" thickness={3} />
          </Grid>
        </Grid>
      </div>
        
        :
        <div className="box-display-style-for-next-and-back-button-with-justify">
          <div className="box-indiv-header-con">
            <Box id={styles.topNavMainBox}>
              <Box
                id={styles.individualNavMainBox}
                onClick={() => {

                  handleUpdateMonetisationDetails();
                  history.push({
                    search: `?index=${revenueStreamIndex}&hashvalue=customerWorth`
                  });
                
                }}>
                <PricingIndividualNavBar
                  highlight={hashValue === "customerWorth" ? true : false}
                  slideNo={"1"}
                />
              </Box>
              <Box
                id={styles.individualNavMainBox}
                onClick={() => {

                  handleUpdateMonetisationDetails();

                  history.push({
                    search: `?index=${revenueStreamIndex}&hashvalue=pricingModel`
                  });
                
                }}>
                <PricingIndividualNavBar
                  highlight={hashValue === "pricingModel" ? true : false}
                  slideNo={"2"}
                />
              </Box>
              <Box
                id={styles.individualNavMainBox}
                onClick={() => {

                  handleUpdateMonetisationDetails();
                  history.push({
                    search: `?index=${revenueStreamIndex}&hashvalue=anchors`
                  });
                
                }}>
                <PricingIndividualNavBar
                  highlight={hashValue === "anchors" ? true : false}
                  slideNo={"3"}
                />
              </Box>
              <Box
                id={styles.individualNavMainBox}
                onClick={() => {

                  handleUpdateMonetisationDetails();
                  history.push({
                    search: `?index=${revenueStreamIndex}&hashvalue=purchasingDecisionFactors`
                  });
                
                }}>
                <PricingIndividualNavBar
                  highlight={
                    hashValue === "purchasingDecisionFactors" ? true : false
                  }
                  slideNo={"4"}
                />
              </Box>
              <Box
                id={styles.individualNavMainBox}
                onClick={() => {

                  handleUpdateMonetisationDetails();
                  history.push({
                    search: `?index=${revenueStreamIndex}&hashvalue=priceDiscrimination`
                  });
                
                }}>
                <PricingIndividualNavBar
                  highlight={hashValue === "priceDiscrimination" ? true : false}
                  slideNo={"5"}
                />
              </Box>
              <Box
                id={styles.individualNavMainBox}
                onClick={() => {

                  handleUpdateMonetisationDetails();
                  history.push({
                    search: `?index=${revenueStreamIndex}&hashvalue=costs`
                  });
                
                }}>
                <PricingIndividualNavBar
                  highlight={hashValue === "costs" ? true : false}
                  slideNo={"6"}
                />
              </Box>
              <Box
                id={styles.individualNavMainBox}
                onClick={() => {

                  handleUpdateMonetisationDetails();
                  history.push({
                    search: `?index=${revenueStreamIndex}&hashvalue=pricingImage`
                  });
                
                }}>
                <PricingIndividualNavBar
                  highlight={hashValue === "pricingImage" ? true : false}
                  slideNo={"7"}
                />
              </Box>
            </Box>

            <Box>
              <IndividualSectionHeaders
                mainheading={""}
                subheading={
                  <Typography sx={{ fontWeight: "bold" }}>
                    Pricing for Revenue Stream :
                    <span style={{ fontStyle: "italic" }}>
                      {revenueStreams[revenueStreamIndex]["name"]}
                    </span>
                  </Typography>
                }
                headingcontent={""}
                isGtm={false}
                Dialogopen={Dialogopen}
                Dialogclose={Dialogclose}
                dialogbox={dialogbox}
                collapse_comment_function={collapse_comment_function}
                isexpand={isexpand}
                content={{
                  dialogpurposetitle: "What are we doing in this section?",
                  dialogpurpose:
                    "With the previous section you got clarity on what your revenue streams will be, and how your customers will procure your product or service. Now you need to define a pricing model, with specific price points, by taking into consideration various factors, such as, your product’s value to the customer, your competitors, general market & economy trends, et cetera.\nFor this section, we’ve designed a neat and simple pricing wizard, where each step will help seed your brain with important inputs. Start with some hypothesis – i.e. pricing model & price points you think might work, then as you gather a bit more insight or data with each step in the wizard, refine this hypothesis. By the end of the wizard, you should feel comfortable enough to jot down a pricing model with either specific price points, or price ranges that you may want to test in the market.  ",
                  dialoganalogytitle:
                    "Why do I need to go through this exercise?",
                  dialoganalogy:
                    "Many companies spend little effort on pricing, assigning pricing to marketing.  Marketing is usually focused on enhancing demand, rather than maximizing profits through price.  Yet, the return from pricing investment on a venture’s profitability often exceeds returns seen from marketing or product design investments.  If you’re in a lower-margin businesses, pricing becomes more crucial since a small change in price can have a significant impact on profitability. If you’re in a high-margin business, it becomes important to know if and how you’ll sustain that in the long-run and maintain high customer loyalty.\nPricing is both a science and an art. The longer you are in business, the more data-driven you can get with your pricing. Be prepared to go through multiple iterations as you learn more about the market or get direct customer feedback. Return to the pricing wizard as often as you need to help you review and refresh the fundamental inputs that influenced your pricing decision.",
                  dialogguide: []
                }}
                isSaveButton={true}
                handleSave={handleSave}
              />
            </Box>
          </div>
          <Grid
            id={styles.pricingComponentMainGrid}
            item
            xs={12}
            sx={{
              "&::-webkit-scrollbar"            : { width: "2px" },
              "&::-webkit-scrollbar-track"      : { background: "#f1f1f1" },
              "&::-webkit-scrollbar-thumb"      : { background: "#888" },
              "&::-webkit-scrollbar-thumb:hover": { background: "#888" }
            }}>
            {/* top nav main box */}

            <Box sx={{}}>
              {hashValue === "customerWorth" ?
                <CustomerWorth
                  revenueStreams={revenueStreams}
                  setRevenueStreams={setRevenueStreams}
                  revenueStreamIndex={revenueStreamIndex}
                  handleUpdateMonetisationDetails={
                    handleUpdateMonetisationDetails
                  }
                />
                : hashValue === "pricingModel" ?
                  <PricingModel
                    revenueStreams={revenueStreams}
                    setRevenueStreams={setRevenueStreams}
                    revenueStreamIndex={revenueStreamIndex}
                    handleUpdateMonetisationDetails={
                      handleUpdateMonetisationDetails
                    }
                  />
                  : hashValue === "anchors" ?
                    <PriceAnchors
                      revenueStreams={revenueStreams}
                      setRevenueStreams={setRevenueStreams}
                      revenueStreamIndex={revenueStreamIndex}
                      handleUpdateMonetisationDetails={
                        handleUpdateMonetisationDetails
                      }
                    />
                    : hashValue === "purchasingDecisionFactors" ?
                      <PriceSensitive
                        revenueStreams={revenueStreams}
                        setRevenueStreams={setRevenueStreams}
                        revenueStreamIndex={revenueStreamIndex}
                        handleUpdateMonetisationDetails={
                          handleUpdateMonetisationDetails
                        }
                      />
                      : hashValue === "priceDiscrimination" ?
                        <PriceDiscrimination
                          revenueStreams={revenueStreams}
                          setRevenueStreams={setRevenueStreams}
                          revenueStreamIndex={revenueStreamIndex}
                          handleUpdateMonetisationDetails={
                            handleUpdateMonetisationDetails
                          }
                        />
                        : hashValue === "costs" ?
                          <Costs
                            revenueStreams={revenueStreams}
                            setRevenueStreams={setRevenueStreams}
                            revenueStreamIndex={revenueStreamIndex}
                            handleUpdateMonetisationDetails={
                              handleUpdateMonetisationDetails
                            }
                          />
                          : hashValue === "pricingImage" ?
                            <ProposePricingPlan
                              revenueStreams={revenueStreams}
                              setRevenueStreams={setRevenueStreams}
                              revenueStreamIndex={revenueStreamIndex}
                              handleUpdateMonetisationDetails={
                                handleUpdateMonetisationDetails
                              }
                              fetchMonetisation={fetchMonetisation}
                            />
                            :
                            <></>
              }
            </Box>
          </Grid>
          <div className="ttribe-back-next-button-con">
            {
              hashValue == "customerWorth" ? <BackNextButton
                restrictedBackClick={() => {

                  history.push("/pricing");
                
                }}
                backClick={() => {

                  handleUpdateMonetisationDetails();
                  history.push("/pricing");
                
                }}
                restrictedNextClick={() => {

                  history.push({
                    search: `?index=${revenueStreamIndex}&hashvalue=pricingModel`
                  });
                  history.push({
                    search: `?index=${revenueStreamIndex}&hashvalue=pricingModel`
                  });
                
                }}
                nextClick={() => {

                  handleUpdateMonetisationDetails();
                  history.push({
                    search: `?index=${revenueStreamIndex}&hashvalue=pricingModel`
                  });
                
                }}
              /> : hashValue == "pricingModel" ? <BackNextButton
                restrictedBackClick={() => {

                  history.push({
                    search: `?index=${revenueStreamIndex}&hashvalue=customerWorth`
                  });
                
                }}
                backClick={() => {

                  handleUpdateMonetisationDetails();
                  history.push({
                    search: `?index=${revenueStreamIndex}&hashvalue=customerWorth`
                  });
                
                }}
                restrictedNextClick={() => {

                  history.push({
                    search: `?index=${revenueStreamIndex}&hashvalue=anchors`
                  });
                
                }}
                nextClick={() => {

                  handleUpdateMonetisationDetails();
                  history.push({
                    search: `?index=${revenueStreamIndex}&hashvalue=anchors`
                  });
                
                }}
              /> : hashValue == "anchors" ? <BackNextButton
                restrictedBackClick={() => {

                  history.push({
                    search: `?index=${revenueStreamIndex}&hashvalue=pricingModel`
                  });
                
                }}
                backClick={() => {

                  handleUpdateMonetisationDetails();
                  history.push({
                    search: `?index=${revenueStreamIndex}&hashvalue=pricingModel`
                  });
                
                }}
                restrictedNextClick={() => {

                  history.push({
                    search: `?index=${revenueStreamIndex}&hashvalue=purchasingDecisionFactors`
                  });
                
                }}
                nextClick={() => {

                  handleUpdateMonetisationDetails();
                  history.push({
                    search: `?index=${revenueStreamIndex}&hashvalue=purchasingDecisionFactors`
                  });
                
                }}
              /> : hashValue == "purchasingDecisionFactors" ? <BackNextButton
                restrictedBackClick={() => {

                  history.push({
                    search: `?index=${revenueStreamIndex}&hashvalue=anchors`
                  });
                
                }}
                backClick={() => {

                  handleUpdateMonetisationDetails();
                  history.push({
                    search: `?index=${revenueStreamIndex}&hashvalue=anchors`
                  });
                
                }}
                restrictedNextClick={() => {

                  history.push({
                    search: `?index=${revenueStreamIndex}&hashvalue=priceDiscrimination`
                  });
                
                }}
                nextClick={() => {

                  handleUpdateMonetisationDetails();
                  history.push({
                    search: `?index=${revenueStreamIndex}&hashvalue=priceDiscrimination`
                  });
                
                }}
              /> : hashValue == "priceDiscrimination" ? <BackNextButton
                restrictedBackClick={() => {

                  history.push({
                    search: `?index=${revenueStreamIndex}&hashvalue=purchasingDecisionFactors`
                  });
                
                }}
                backClick={() => {

                  handleUpdateMonetisationDetails();
                  history.push({
                    search: `?index=${revenueStreamIndex}&hashvalue=purchasingDecisionFactors`
                  });
                
                }}
                restrictedNextClick={() => {

                  history.push({
                    search: `?index=${revenueStreamIndex}&hashvalue=costs`
                  });
                
                }}
                nextClick={() => {

                  handleUpdateMonetisationDetails();
                  history.push({
                    search: `?index=${revenueStreamIndex}&hashvalue=costs`
                  });
                
                }}
              /> : hashValue == "costs" ? <BackNextButton
                restrictedBackClick={() => {

                  history.push({
                    search: `?index=${revenueStreamIndex}&hashvalue=priceDiscrimination`
                  });
                
                }}
                backClick={() => {

                  handleUpdateMonetisationDetails();
                  history.push({
                    search: `?index=${revenueStreamIndex}&hashvalue=priceDiscrimination`
                  });
                
                }}
                restrictedNextClick={() => {

                  history.push({
                    search: `?index=${revenueStreamIndex}&hashvalue=pricingImage`
                  });
                
                }}
                nextClick={() => {

                  handleUpdateMonetisationDetails();
                  history.push({
                    search: `?index=${revenueStreamIndex}&hashvalue=pricingImage`
                  });
                
                }}
              /> : hashValue == "pricingImage" ? <BackButton
                restrictedBackClick={() => {

                  history.push({
                    search: `?index=${revenueStreamIndex}&hashvalue=costs`
                  });
                
                }}
                backClick={() => {

                  handleUpdateMonetisationDetails();
                  history.push({
                    search: `?index=${revenueStreamIndex}&hashvalue=costs`
                  });
                
                }}
              /> : <></>
            }

          </div>
        </div>
      }
    </ModuleSections>
  );

}
