import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import React from "react";
import { useHistory } from "react-router-dom";
import BoxWithIcon from "../../../components/BoxWithIcon";

const GTMIntro = () => {

  const history = useHistory();

  return (
    <BoxWithIcon>
      <Grid container sx={ { height: "99%" } }>
        <Grid
          item
          xs={ 12 }
          md={ 6 }
          sx={ {
            display       : "flex",
            alignItems    : "center",
            justifyContent: "center"
          } }
        >
          <Box
            sx={ {
              height        : "90%",
              width         : "80%",
              display       : "flex",
              flexDirection : "column",
              justifyContent: "space-between"
            } }
          >
            <Box>
              <Typography
                gutterBottom
                component="div"
                style={ {
                  fontWeight  : "bold",
                  color       : "#5F5E5E",
                  fontSize    : "25px",
                  marginBottom: "20px"
                } }
              >
                Go-To-Market
              </Typography>
              <Box>
                <Typography
                  gutterBottom
                  component="div"
                  mt={ 4 }
                  style={ {
                    fontWeight: "bold",
                    color     : "#5F5E5E",
                    fontSize  : "18px"
                  } }
                >
                  Goal of this Module
                </Typography>
                <Typography
                  gutterBottom
                  style={ {
                    marginBottom: "20px",
                    color       : "#393939",
                    fontSize    : "16px"
                  } }
                >
                  {/* When launching a new product, any founder would want a solid
                  go-to-market (GTM) strategy, to build traction, visibility and
                  scale adoption. And, this module does exactly that, in guiding
                  founders with an end to end GTM approach, from Strategy to
                  Execution. The GTM canvas can be used for startups, B2B
                  businesses, and typically any new venture. */}
                  As you get ready to launch your product, you will need a solid
                  Go-to-Market (GTM) strategy to build visibility and ensure
                  rapid user adoption. Building an execution-ready GTM plan can
                  seem like hard work, but we’ve made it easy for you with this
                  module! <br />
                  <br />
                  Our GTM canvas is not just for startups or businesses
                  launching new products. It’s designed to help anyone who wants
                  to accelerate market adoption and sales growth for their
                  product.
                </Typography>
              </Box>
            </Box>
            <Box>
              <Button
                variant="contained"
                style={ {
                  border         : "1px solid #071AFF",
                  backgroundColor: "#FFFFFF",
                  color          : "blue",
                  marginRight    : "30px",
                  borderRadius   : "8px",
                  textTransform  : "capitalize",
                  padding        : "6px 30px",
                  fontSize       : "18px"
                } }
                disableElevation
                onClick={ () => {

                  history.push("/dashboard");
                
                } }
              >
                Dashboard
              </Button>
              <Button
                style={ {
                  marginRight    : "30px",
                  borderRadius   : "8px",
                  backgroundColor: "#071AFF",
                  textTransform  : "capitalize",
                  padding        : "6px 20px",
                  fontSize       : "18px"
                } }
                disableElevation
                variant="contained"
                onClick={ () => history.push("/gotomarketcanvas") }
              >
                Get Started
                <NavigateNextIcon />
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={ 12 }
          md={ 6 }
          sx={ {
            display       : "flex",
            alignItems    : "center",
            justifyContent: "center"
          } }
        >
          <img
            src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/Go-to-Market - undraw_maker_launch_re_rq81.svg` }
            style={ {
              width       : "70%",
              height      : "70%",
              padding     : "20px",
              boxShadow   : "0 4px 12px rgb(0 0 0 / 15%)",
              borderRadius: "8px"
            } }
          />
        </Grid>
      </Grid>
    </BoxWithIcon>
  );

};

export default GTMIntro;
