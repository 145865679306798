import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box, Button, Grid, IconButton, Typography
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CustomMultilineTextfield from "../../../../components/CustomMultilineTextfield";
import {
  getPersona,
  updatePsychographics
} from "../../../../services/persona";
import styles from "./TargetSegments.module.css";

const EditPsychographics = props => {
  
  const [ details, setDetails ] = useState({
    personalityTraits   : "",
    opinionsOrBeliefs   : "",
    inspiresOrLifeGoals : "",
    interestOrHobbies   : "",
    psychographicFactors: ""
  });
  const [ isSuccess, setIssuccess ] = useState(false);
  const [ isedit, setIsedit ] = useState(false);
  const history = useHistory();

  let { venture } = useSelector(state => {

    return { ...state };
  
  });

  useEffect(() => {

    fetchPersona();

    return () => {

      return handleSave;

    };
  
  }, []);

  
  const fetchPersona = async () => {

    try {

      const response = await getPersona(
        props.personaid,
        venture.problemSolution
      );
      let persona = response.data.persona;
      setDetails(persona.psychographics);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  
  // useEffect(() => {
    
  //   return () => {
  //     handleSave();
  //   }
  // }, [])
      
  // To get the updated usestate value to save in useEffect cleanup
  const detailsRef = useRef(details);
  detailsRef.current = details;

  const handleSave = async () => {

    try {

      await updatePsychographics(detailsRef.current, props.personaid);
      
      return true;
    
    } catch (error) {

      console.error(error);
      
      return false;
    
    }
  
  };

  const handleChange = (name, value) => {
    
    setDetails(values => ({ ...values, [name]: value }));
  
  };

  const pathname = history.location.pathname.substring(1);

  const updateDetails = async event => {

    event.stopPropagation();
    try {

      await updatePsychographics(details, props.personaid);
      setIsedit(!isedit);
      // history.push(`/userpersonadetails/${props.personaid}`);
      history.push(
        pathname.includes("gtm")
          ? `/gtmuserpersonadetails/${props.personaid}`
          : `/userpersonadetails/${props.personaid}`
      );
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const handleClose = () => {

    // history.push(`/userpersonadetails/${props.personaid}`);
    history.push(
      pathname.includes("gtm")
        ? `/gtmuserpersonadetails/${props.personaid}`
        : `/userpersonadetails/${props.personaid}`
    );
    props.gridScrollUp();
  
  };

  return (
    <Grid id={ styles.individualgrid } component="div" onClick={ handleClose }>
      <Box display="flex" justifyContent="flex-end">
        <Box sx={ { marginRight: "10px", position: "relative" } }>
          { isSuccess &&
            <Box sx={ { position: "absolute", top: "32px", left: "10px" } }>
              <Typography sx={ { color: "#08910B", fontSize: "14px" } }>
                Saved
              </Typography>
            </Box>
          }
          <Button
            variant="contained"
            color="primary"
            sx={ { padding: "3px 12px", textTransform: "capitalize" } }
            onClick={ async e => {

              e.stopPropagation();
              const isSaved = await handleSave();
              if (isSaved) {

                setIssuccess(true);
                setTimeout(() => {

                  setIssuccess(false);
                
                }, 3000);
              
              }
            
            } }
            disabled={ venture.userRole === "Commenter" }
          >
            Save
          </Button>
        </Box>
        <IconButton component="span" onClick={ updateDetails }>
          <HighlightOffIcon />
        </IconButton>
      </Box>
      <Typography id={ styles.individualtitle } gutterBottom>
        Psychographics
      </Typography>
      <Box>
        <Typography variant="body2" sx={ { margin: "1em 0px" } } id={ styles.mobileViewFontSize }>
          What are their personality traits?
        </Typography>
        {/* <TextField
          multiline
          variant="outlined"
          minRows={2}
          maxRows={2}
          sx={{ width: "100%" }}
          value={details.personalityTraits}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => {
            handleChange("personalityTraits", e.target.value);
          }}
        /> */}
        <CustomMultilineTextfield
          minRows={ 2 }
          maxRows={ 2 }
          width={ "100%" }
          padding={ "15px 13px" }
          value={ details.personalityTraits }
          handleClick={ e => {

            e.stopPropagation();
          
          } }
          handledetailsChange={ e => {

            handleChange("personalityTraits", e.target.value);
          
          } }
        />
      </Box>
      <Box>
        <Typography variant="body2" sx={ { margin: "1em 0px" } } id={ styles.mobileViewFontSize }>
          What are their opinions or beliefs or attitude ?
        </Typography>
        {/* <TextField
          multiline
          variant="outlined"
          minRows={2}
          maxRows={2}
          sx={{ width: "100%" }}
          value={details.opinionsOrBeliefs}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => {
            handleChange("opinionsOrBeliefs", e.target.value);
          }}
        /> */}
        <CustomMultilineTextfield
          minRows={ 2 }
          maxRows={ 2 }
          width={ "100%" }
          padding={ "15px 13px" }
          value={ details.opinionsOrBeliefs }
          handleClick={ e => {

            e.stopPropagation();
          
          } }
          handledetailsChange={ e => {

            handleChange("opinionsOrBeliefs", e.target.value);
          
          } }
        />
      </Box>
      <Box>
        <Typography variant="body2" sx={ { margin: "1em 0px" } } id={ styles.mobileViewFontSize }>
          What inspires them or their life goals?
        </Typography>
        {/* <TextField
          multiline
          variant="outlined"
          minRows={2}
          maxRows={2}
          sx={{ width: "100%" }}
          value={details.inspiresOrLifeGoals}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => {
            handleChange("inspiresOrLifeGoals", e.target.value);
          }}
        /> */}
        <CustomMultilineTextfield
          minRows={ 2 }
          maxRows={ 2 }
          width={ "100%" }
          padding={ "15px 13px" }
          value={ details.inspiresOrLifeGoals }
          handleClick={ e => {

            e.stopPropagation();
          
          } }
          handledetailsChange={ e => {

            handleChange("inspiresOrLifeGoals", e.target.value);
          
          } }
        />
      </Box>
      <Box>
        <Typography variant="body2" sx={ { margin: "1em 0px" } } id={ styles.mobileViewFontSize }>
          What is their interest or hobbies?
        </Typography>
        {/* <TextField
          multiline
          variant="outlined"
          minRows={2}
          maxRows={2}
          sx={{ width: "100%" }}
          value={details.interestOrHobbies}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => {
            handleChange("interestOrHobbies", e.target.value);
          }}
        /> */}
        <CustomMultilineTextfield
          minRows={ 2 }
          maxRows={ 2 }
          width={ "100%" }
          padding={ "15px 13px" }
          value={ details.interestOrHobbies }
          handleClick={ e => {

            e.stopPropagation();
          
          } }
          handledetailsChange={ e => {

            handleChange("interestOrHobbies", e.target.value);
          
          } }
        />
      </Box>
      <Box>
        <Typography variant="body2" sx={ { margin: "1em 0px" } } id={ styles.mobileViewFontSize }>
          Other relevant psychographic factors
        </Typography>
        {/* <TextField
          multiline
          variant="outlined"
          minRows={2}
          maxRows={2}
          sx={{ width: "100%" }}
          value={details.psychographicFactors}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => {
            handleChange("psychographicFactors", e.target.value);
          }}
        /> */}
        <CustomMultilineTextfield
          minRows={ 2 }
          maxRows={ 2 }
          width={ "100%" }
          padding={ "15px 13px" }
          value={ details.psychographicFactors }
          handleClick={ e => {

            e.stopPropagation();
          
          } }
          handledetailsChange={ e => {

            handleChange("psychographicFactors", e.target.value);
          
          } }
        />
      </Box>
    </Grid>
  );

};

export default EditPsychographics;
