import axios from "./axiosService";

// import axios from "axios";

export const getListsByProblemSolutionId = async problemsolutionid => {

  return await axios.get(
    `${process.env.REACT_APP_ADDRESS}/${problemsolutionid}/lists`
  );

};

export const getCardsByProblemSolutionId = async problemsolutionid => {

  return await axios.get(
    `${process.env.REACT_APP_ADDRESS}/${problemsolutionid}/cards`
  );

};

export const createNewCard = async details => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/create_card`,
    details
  );

};

export const updateCardById = async (details, cardid) => {

  return await axios.patch(
    `${process.env.REACT_APP_ADDRESS}/${cardid}/card`,
    details
  );

};

export const deleteCardById = async cardid => {

  return await axios.delete(`${process.env.REACT_APP_ADDRESS}/${cardid}/card`);

};

export const createNewList = async details => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/create_list`,
    details
  );

};

export const updateListById = async (details, listid) => {

  return await axios.patch(
    `${process.env.REACT_APP_ADDRESS}/${listid}/list`,
    details
  );

};

export const deleteListById = async listid => {

  return await axios.delete(`${process.env.REACT_APP_ADDRESS}/${listid}/list`);

};
