import axios from "./axiosService";

// import axios from "axios";

export const getMonetisationDetails = async monetisationId => {

  return await axios.get(
    `${process.env.REACT_APP_ADDRESS}/fetch_monetisation/${monetisationId}`
  );

};

export const updateMonetisationDetails = async (
  detailsName,
  detailsValue,
  monetisationId
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/update_monetisationdetails`,
    {
      detailsName,
      detailsValue,
      monetisationId
    }
  );

};

export const updateChannels = async (
  selectedChannels,
  otherChannels,
  monetisationId
) => {

  return await axios.post(`${process.env.REACT_APP_ADDRESS}/update_channels`, {
    selectedChannels,
    otherChannels,
    monetisationId
  });

};

export const updateChannelPartnershipDetails = async (
  partnershipDetails,
  monetisationId
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/update_channel_partnershipdetails`,
    {
      partnershipDetails,
      monetisationId
    }
  );

};

export const deleteRevenueStreamImages = async (
  revenueStreamIndex, monetisationId
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/delete_revenuestream_pricingimage`,
    {
      revenueStreamIndex, monetisationId
    }
  );

};
