import { Box, Grid, Typography } from "@mui/material";
import React from "react";

// icons
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
// components
import CardNoCollapse from "./Card";
import CommentContainer from "./CommentContainer";

const ModuleSections = props => {

  return (
    <div
      style={ {
        alignItems   : "center",
        display      : "flex",
        height       : "91vh",
        marginTop    : "auto",
        marginBottom : "auto",
        verticalAlign: "middle"
      } }
    >
      { props.isexpand ?
        <></>
        :
        <>
          <Box style={ { margin: "5vh 4vh 5vh", position: "relative" } }>
            <CardNoCollapse
            />
          </Box>
        </>
      }

      <Grid
        style={ {
          height      : "100%",
          width       : "100%",
          paddingRight: "2.5vw",
          paddingLeft : props.isexpand ? "2vw" : "0px",
          marginTop   : "5vh",
          marginBottom: "5vh",
          overflow    : "hidden"
        } }
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          style={ {
            position    : "relative",
            height      : "81vh",
            borderRadius: "10px",
            background  : "#FFFFFF 0% 0% no-repeat padding-box",
            boxShadow   : "0px 3px 16px #00000029"
          } }
        >
          { props.children }
          <Box
            sx={ {
              background    : "#ffffff",
              display       : "flex",
              flexDirection : "column",
              justifyContent: "center",
              alignItems    : "center",
              position      : "absolute",
              top           : " 92%",
              left          : "96%",
              textDecoration: "none"
            } }
            component="a"
            target="_blank"
            href="/support"
          >
            <ChatBubbleOutlineIcon
              sx={ { fontSize: "50px", color: "#071AFF" } }
            />
            <Typography
              noWrap
              sx={ {
                color     : "rgb(97, 92, 92)",
                fontSize  : "10px",
                fontWeight: "bold"
              } }
            >
              Need help?
            </Typography>
          </Box>
        </Grid>
      </Grid>
      { props.isexpand ?
        <></>
        :
        <>
          <CommentContainer
            collapse={ props.Collapsecomment }
            comments={ props.comments }
            addComment={ props.addComment }
            deleteComment={ props.deleteComment }
            updateComment={ props.updateComment }
          ></CommentContainer>
        </>
      }
    </div>
  );

};

export default ModuleSections;
