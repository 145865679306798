import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Typography,
  Box,
  Button,
  FormControl,
  IconButton,
  Dialog,
  DialogContent,
  Select,
  MenuItem,
  Divider,
  TextField
} from "@mui/material";
import { getVetureById } from "../../../services/venture";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import CloseIcon from "@mui/icons-material/Close";

import ModuleBoxGrid from "./ModuleBoxGrid";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import styles from "./Dashboard2.module.css";
import InputBase from "@mui/material/InputBase";

import SingleMember from "./SingleMember";
import { inviteCollaborator, exportBuisnessPlanSummary } from "../../../services/auth";
import { getFinancials } from "../../../services/financials";
import SuccessMessage from "../../../components/SuccessMessage";
import ErrorMessage from "../../../components/ErrorMessage";
import { useLocation } from "react-router-dom";
// import { useHistory } from "react-router-dom";
// import CustomMultilineTextfield from "../../../components/CustomMultilineTextfield";
import { createConceptVisionComment, deleteConceptVisionComment, updateConceptVisionComment } from "../../../services/comment";
import IndividualProjectNotes from "./IndividualProjectNotes";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BusinessPlanSummary from "./BusinessPlanSummary";

const LightTooltip = styled(({ className, ...props }) =>
  <Tooltip {...props} classes={{ popper: className }} />
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,

    boxShadow   : theme.shadows[1],
    fontSize    : "11px",
    color       : "#FF7F00",
    border      : "1px solid #FF7F00",
    paddingTop  : "5px 10px",
    borderRadius: "10px",
    fontWeight  : "bold"
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3)
  },
  "& .MuiInputBase-input": {
    border      : "1px solid #e3e5ff",
    borderRadius: 4,
    position    : "relative",
    fontSize    : "14px",
    padding     : "5px 6px 5px 20px"
  }
}));

const BootstrapInputSelect = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3)
  },
  "& .MuiInputBase-input": {
    border      : "1px solid #e3e5ff",
    borderRadius: 4,
    position    : "relative",
    fontSize    : "14px",
    width       : "9vw",
    padding     : "5px 0px 0px 12px",
    transition  : theme.transitions.create([ "border-color", "box-shadow" ]),
    height      : "25px"
  }
}));

export default function Dashboard() {

  const [ dialog, setDialog ] = useState(false);
  const [ collaboratorEmail, setCollaboratorEmail ] = useState("");
  const [ collaboratorRole, setCollaboratorRole ] = useState("Commenter");
  const [ successmsg, setSuccessmsg ] = useState("");
  const [ errormsg, setErrormsg ] = useState("");
  const [ sharedUsers, setSharedUsers ] = useState([]);
  const [ sharedEmails, setSharedEmails ] = useState([]);
  const [ projectNotesDialog, setProjectNotesDialog ] = useState(false);
  const [ viewBizPlanSummaryDialog, setViewBizPlanSummaryDialog ] = useState(false);
  const [ commentText, setCommentText ] = useState("");
  const [ projectNotes, setProjectNotes ] = useState([]);
  const [ posting, setPosting ] = useState(false);
  
  const [ yearList, setYearList ] = useState([]);
  const [ yearDialog, setYearDialog ] = useState(false);
  const [ isExportBtnDisabled, setIsExportBtnDisabled ] = useState(false);
  const [ selectedYear, setSelectedYear ] = useState(yearList && yearList.length > 0 ? yearList[0] : new Date().getFullYear());

  // const history = useHistory();

  // const [data, setData] = useState();
  const [ percentvalues, setPercentvalues ] = useState({
    conceptVision: {},
    market       : {},
    customer     : {},
    problem      : {},
    solution     : {},
    monetisation : {},
    financials   : {},
    operations   : {},
    gotomarket   : {},
    pitchdeck    : {}
  });
  const { user, venture } = useSelector(state => ({ ...state }));

  let location = useLocation();
  let dispatch = useDispatch();

  const useQuery = () => {

    return new URLSearchParams(location.search);
  
  };
  const query = useQuery();

  const ventureId = query.get("ventureId");
  const role = query.get("role");

  useEffect(() => {

    if (ventureId) {

      fetchVenture(ventureId);

      setSelectedYear(yearList && yearList.length > 0 ? yearList[0] : new Date().getFullYear());
    
    } else {

      getVenture();
    
    }
  
  }, []);

  const fetchVenture = async ventureId => {

    try {

      const response = await getVetureById(ventureId);
      const Venture = response.data.venture;

      dispatch({
        type   : "CURRENT_VENTURE",
        payload: {
          _id            : Venture._id,
          userid         : Venture.parentUser,
          details        : Venture.details,
          conceptVision  : Venture.conceptVision,
          market         : Venture.market,
          customer       : Venture.customer,
          problemSolution: Venture.problemSolution,
          financials     : Venture.financials,
          gtm            : Venture.gtm,
          operations     : Venture.operations,
          pitchDeck      : Venture.pitchDeck,
          monetisation   : Venture.monetisation,
          userRole       : role
        }
      });
      // setData(Venture);
      setPercentvalues(Venture.percentValue);
      setSharedUsers(Venture.sharedUsers);
      const emails = Venture.sharedUsers.map(
        shareduser => shareduser.user.email
      );

      setSharedEmails(emails);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const getVenture = async () => {

    try {

      const response = await getVetureById(venture._id);
      const Venture = response.data.venture;

      setPercentvalues(Venture.percentValue);
      setSharedUsers(Venture.sharedUsers);
      setProjectNotes(Venture.comments.ventureNotes);
      const emails = Venture.sharedUsers.map(
        shareduser => shareduser.user.email
      );

      setSharedEmails(emails);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const Dialogopen = () => {

    setDialog(true);
  
  };

  const Dialogclose = () => {

    setDialog(false);
  
  };
  const yearDialogopen = () => {

    setYearDialog(true);
  
  };

  const yearDialogclose = () => {

    setYearDialog(false);
  
  };

  const collaboratorInvite = async () => {

    try {

      let userslimit =
        user.access === "pitch" ? 5 : user.access === "pro" ? 10 : 20;

      if (sharedUsers.length === userslimit) {

        setErrormsg("Please delete a collaborator to invite new one");
        setTimeout(() => {

          setErrormsg("");
        
        }, 5000);
      
      } else if (sharedEmails.includes(collaboratorEmail)) {

        setErrormsg(
          `You have already invited ${collaboratorEmail} to this project`
        );
        setCollaboratorEmail("");
        setCollaboratorRole("Commenter");
        setTimeout(() => {

          setErrormsg("");
        
        }, 5000);
      
      } else if (collaboratorEmail === user.email) {

        setErrormsg(
          "This is your account’s email ID and ‘Owner’ of this project. We cannot add it as a collaborator."
        );
        setCollaboratorEmail("");
        setCollaboratorRole("Commenter");
        setTimeout(() => {

          setErrormsg("");
        
        }, 5000);
      
      } else {

        await inviteCollaborator(
          collaboratorEmail,
          venture._id,
          collaboratorRole,
          user._id
        );
        getVenture();
        setCollaboratorEmail("");
        setCollaboratorRole("Commenter");
        setSuccessmsg("Invite sent");
        setTimeout(() => {

          setSuccessmsg("");
        
        }, 5000);
      
      }
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  // let validator = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
  //   collaboratorEmail
  // );

  let findAccess = access => {

    switch (access) {

    case "freetier":
      return "Starter (free) plan";
    case "ideation":
      return "Custom/ Enterprise plan";
    case "admin":
      return "Custom/ Enterprise plan";
    case "pitch":
      return "Pitch (annual) plan";
    case "pro":
      return "Pro (annual) plan";
    default:
      return "";
    
    }
  
  };

  let subscriptionText = findAccess(user.access);

  const addComment = async () => {

    try {

      setPosting(true);
      const response = await createConceptVisionComment(
        commentText,
        user._id,
        "ventureNotes",
        "",
        venture._id,
        "",
        window.location.href,
        "Dashboard"
      );

      setProjectNotes(response.data.comments.ventureNotes);
      setCommentText("");
      setPosting(false);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateComment = async (commentid, commentText) => {

    try {

      const response = await updateConceptVisionComment(
        commentText,
        user._id,
        commentid,
        venture._id,
        window.location.href,
        "Dashboard"
      );

      setProjectNotes(response.data.comments.ventureNotes);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const deleteComment = async commentid => {

    try {

      const response = await deleteConceptVisionComment(
        "ventureNotes",
        "",
        venture._id,
        commentid,
        user._id,
        window.location.href,
        "Dashboard"
      );

      setProjectNotes(response.data.comments.ventureNotes);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const handleYearDialogBox = async () => {

    try {

      getFinancials(venture.financials, venture._id).then(response => {

        const finance = response.data.finance;

        if (finance.financialYears && finance.financialYears.length) {

          setYearList(finance.financialYears);

        } else {
            
          setYearList([]);
        
        }

      });

      yearDialogopen();
    
    } catch (error) { console.error(error); }
  
  };

  const handleExportBusinessPlanSummary = async (id, year) => {

    setIsExportBtnDisabled(true);
    
    exportBuisnessPlanSummary(id, year).then(response => {

      setYearDialog(false);
      setIsExportBtnDisabled(false);
      
      // const blobData = new Blob([ response ]);
      // const url = window.URL.createObjectURL(blobData);
      // const link = document.createElement("a");
      // link.href = url;
      // link.setAttribute(
      //   "download",
      //   venture.details.name + " Business Plan Summary.pdf"
      // );
      // document.body.appendChild(link);
      // link.click();
      // link.remove();
      // link.parentNode.removeChild(link);

      const byteCharacters = atob(response);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {

        byteNumbers[i] = byteCharacters.charCodeAt(i);
      
      }

      const byteArray = new Uint8Array(byteNumbers);

      const blob = new Blob([ byteArray ], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = venture.details.name + " Business Plan Summary.pdf";
      document.body.appendChild(a);
      a.click();
      a.remove();

      a.parentNode.removeChild(a);
      
    
    }).catch(error => {
      
      setYearDialog(false);
      setIsExportBtnDisabled(false);
      console.error(error);
    
    });

  
  };

  return (
    <Box sx={{ height: "81vh", margin: "5vh 10vh" }}>
      <Box id={styles.headBox}>
        <Box>
          <Typography sx={{ fontWeight: "bold" }}>
            Hello, {user.firstname} {user.lastname}
          </Typography>{venture.userRole === "Owner" ? <Typography sx={{ fontSize: "14px" }}>
            You are on
            <span>
              {subscriptionText === "Custom/ Enterprise plan" ? " a " : " the "}
            </span>
            <LightTooltip placement="top" title="Coming soon">
              <a
                style={{
                  color     : "#071AFF",
                  fontWeight: "bold",
                  cursor    : "pointer"
                }}
                // href={"/mysubscriptions"}
                // target={"_blank"} rel="noreferrer"
              >
                {subscriptionText}
              </a>
            </LightTooltip>
          </Typography> : <Typography sx={{ fontSize: "14px" }}>
            You are a collaborator with <span style={{ color: "#071aff", fontWeight: "600" }}>{venture.userRole === "Commenter" ? "Mentor" : venture.userRole === "Editor" ? "Cofounder" : venture.userRole}</span> access
          </Typography>}
          {/* -------- */}
        </Box>

        <Dialog
          open={viewBizPlanSummaryDialog}
          onClose={() => {

            setViewBizPlanSummaryDialog(false);
          
          }}
          PaperProps={{
            style: {
              borderRadius   : "20px",
              backdropFilter : "blur(100px)",
              backgroundColor: "white",
              boxShadow      : "0px 3px 6px #00000029",
              border         : "1px solid #707070",
              height         : "88vh"
              // width: "88vw"
            }
          }}
          maxWidth="xl"
          fullWidth
        >
          <div className="tribe-biz-dialog-title-con" style={{ background: "white" }} id="alert-dialog-title">
            <div className="tribe-biz-plan-title-con">
              {`${venture.details.name} Business Plan Summary`}
            </div>
            <IconButton
              aria-label="close"
              onClick={() => {

                setViewBizPlanSummaryDialog(false);
              
              }}
              sx={{
                position: "absolute",
                right   : 8,
                top     : 8,
                color   : theme => theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <DialogContent id={styles.viewBizSummaryDialog}>

            <BusinessPlanSummary />

          </DialogContent>
        </Dialog>

        <Box id={styles.headBoxItem}>
          <Dialog
            open={projectNotesDialog}
            onClose={() => {

              setProjectNotesDialog(false);
            
            }}
            PaperProps={{
              style: {
                borderRadius   : "20px",
                backdropFilter : "blur(100px)",
                backgroundColor: "white",
                boxShadow      : "0px 3px 6px #00000029",
                border         : "1px solid #707070"
              }
            }}
            maxWidth="md"
            fullWidth
          >
            <DialogContent id={styles.projectNotesDialog}>
              <Box>
                <Box>
                  <Typography id={styles.projectNotesText}>Project Notes & Comments:</Typography>
                  <Typography sx={{ fontSize: "14px", marginBottom: "5px" }}>Use this section to add general notes, feedback, or comments for this project.</Typography>
                  <Divider sx={{ borderColor: "#BEC3FF" }} />
                  <Box sx={{ margin: "10px 0px" }}>
                    <Box id={styles.textFieldBox}>
                      {user.profileIcon ?
                        <img src={user.profileIcon} alt="" id={styles.imgIcon} />
                        :
                        <AccountCircleIcon id={styles.defaultProfile} />
                      }
                      <TextField
                        multiline
                        type="text"
                        maxRows={3}
                        minRows={3}
                        sx={{
                          width                  : "90%",
                          "& .MuiInputBase-input": {
                            fontSize  : "14px",
                            lineHeight: "20px"
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: "9px"
                          }
                        }}
                        value={commentText}
                        onChange={e => {

                          setCommentText(e.target.value.trimStart());
                        
                        }}
                        placeholder="Add a note or comment"
                      />
                    </Box>
                    <Box id={styles.postBtns}>
                      <Typography
                        sx={{
                          color      : "#071AFF",
                          marginRight: "20px",
                          cursor     : commentText !== "" && !posting ? "pointer" : "default"
                        }}
                        onClick={() => { if (commentText !== "" && !posting) addComment(); }}
                      >Post</Typography>
                      {/* <Typography sx={{ cursor: "pointer", color: "#5F5E5E" }} onClick={() => setCommentText("")}>Cancel</Typography>*/}
                    </Box>
                  </Box>
                  <Divider sx={{ borderColor: "#BEC3FF" }} />
                </Box>
                <Box id={styles.previousNotesBox}>
                  <Typography fontSize={"14px"}>Previous Notes</Typography>
                  {projectNotes.length > 0 ? projectNotes.map(projectNote => {

                    return <IndividualProjectNotes
                      projectNote={projectNote}
                      deleteComment={deleteComment}
                      updateComment={updateComment}
                    />;
                  
                  }) :
                    <Typography sx={{ textAlign: "center" }}>No Notes</Typography>
                  }
                </Box>
                <Divider sx={{ borderColor: "#BEC3FF" }} />
              </Box>

            </DialogContent>
          </Dialog>
          {/* ------------------ */}

          <Dialog
            open={yearDialog}
            onClose={yearDialogclose}
            PaperProps={{
              style: {
                borderRadius   : "20px",
                backdropFilter : "blur(100px)",
                backgroundColor: "white",
                boxShadow      : "0px 3px 6px #00000029",
                border         : "1px solid #707070"
              }
            }}
            maxWidth="xs"
          >
            <DialogContent sx={{ padding: "10px" }}>
              <Box>
                <Box sx={{ margin: "20px 27px 0px 27px" }}>
                  <Typography
                    sx={{
                      fontSize  : "14px",
                      color     : "#5F5E5E",
                      fontWeight: "bold"
                    }}
                  >
                    { yearList && yearList.length > 0 ? "Please select the year that you would like to view in your export" : "Please enter finanical year in financial module to export data based on the year."}
                  </Typography>

                  <Grid
                    container
                    sx={{
                      marginTop   : "20px",
                      marginBottom: "20px"
                    }}
                  >
                    <Grid
                      item
                      md={3}
                      sx={{
                        display       : "flex",
                        justifyContent: "center",
                        alignItems    : "center"
                      }}
                    >
                      <FormControl variant="standard">
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Select
                            input={<BootstrapInputSelect />}
                            value={selectedYear}
                            onChange={e => {

                              setSelectedYear(e.target.value);
                            
                            }}
                          >

                            { yearList && yearList.map(year => {

                              return <MenuItem
                                sx={{ fontSize: "14px" }}
                                value={year}
                              >
                                {year}
                              </MenuItem>;
                          
                            })}
                          </Select>
                          <Button
                            variant="contained"
                            size="medium"
                            sx={{
                              backgroundColor: "#071AFF",
                              marginLeft     : "10px"
                            }}
                            disabled={isExportBtnDisabled} onClick={()=>handleExportBusinessPlanSummary(venture._id, selectedYear)}>
                            Export
                          </Button>
                        </Box>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </DialogContent>
          </Dialog>

          {/* ------------------ */}

          {
            venture.userRole === "Commenter" ?
              <Button
                className="tribe-dashboard-view-biz-plan-summary"
                onClick={() => setViewBizPlanSummaryDialog(true)}
              >
                View Business Plan Summary
              </Button> : venture.userRole === "Owner" ? <Button
                className="tribe-dashboard-view-biz-plan-summary"
                onClick={() => handleYearDialogBox()}
              >
                Export Business Plan
              </Button> : <></>
          }

          <Box sx={{ cursor: "pointer", display: "flex" }} onClick={() => {

            setProjectNotesDialog(true);
          
          }}>
            <img
              src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/Icon-awesome-sticky-note.png`}
              alt="note"
              style={{
                width      : "20px",
                height     : "20px",
                marginRight: "10px"
              }}
            />
            <Typography sx={{ textDecoration: "underline" }}>
              Project Notes
              {/* </IconButton> */}
            </Typography>

          </Box>
          <Box
            sx={{
              borderRight: "2px solid #707070",
              margin     : "0px 10px"
            }}
          >

          </Box>
          {venture.userRole === "Owner" && user.access !== "freetier" ?
            <Box onClick={Dialogopen}>
              <Typography id={styles.typoWrapper}>
                <PersonAddIcon id={styles.inviteicon} />
                Invite
                <Box id={styles.lockIconBox}>
                  <LockOpenIcon />
                </Box>
                {/* </IconButton> */}
              </Typography>
            </Box>
            :
            <Box>
              <LightTooltip
                placement="top"
                title={
                  user.access === "freetier" && venture.userRole === "Owner"
                    ? "Subscribe to unlock"
                    : "Only the project owner can add/remove collaborators"
                }
              >
                <Typography id={styles.typoWrapper}>
                  <PersonAddIcon id={styles.inviteicon} />
                  Invite
                  <Box id={styles.lockIconBox}>
                    <LockIcon />
                  </Box>
                  {/* </IconButton> */}
                </Typography>
              </LightTooltip>
            </Box>
          }

          {/* invite dialog content */}

          <Dialog
            open={dialog}
            // onClick={Dialogclose}
            onClose={Dialogclose}
            PaperProps={{
              style: {
                borderRadius   : "20px",
                backdropFilter : "blur(100px)",
                backgroundColor: "white",
                boxShadow      : "0px 3px 6px #00000029",
                border         : "1px solid #707070"
              }
            }}
            maxWidth="md"
            fullWidth
          >
            <DialogContent sx={{ padding: "10px" }}>
              <Box>
                <Box sx={{ margin: "20px 27px 0px 27px" }}>
                  <Typography
                    sx={{
                      fontSize  : "14px",
                      color     : "#5F5E5E",
                      fontWeight: "bold"
                    }}
                  >
                    Invite collaborators to work with you on your venture
                  </Typography>

                  <Grid
                    container
                    sx={{
                      marginTop   : "20px",
                      marginBottom: "20px"
                    }}
                  >
                    {/* text box */}
                    <Grid
                      item
                      md={6}
                      sx={{
                        display       : "flex",
                        justifyContent: "center",
                        alignItems    : "center"
                      }}
                    >
                      <BootstrapInput
                        fullWidth
                        placeholder="Enter email address"
                        value={collaboratorEmail}
                        onChange={e => {

                          setCollaboratorEmail(e.target.value.trimStart());
                        
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={3}
                      sx={{
                        display       : "flex",
                        justifyContent: "center",
                        alignItems    : "center"
                      }}
                    >
                      <FormControl variant="standard">
                        <Tooltip placement="right-start" title={collaboratorRole === "Commenter" ? "As a mentor, user will have access to view the business plan data with ability to post comments to support advisory and coaching" : "As a Cofounder, user will have the editable access to you business plan to support cocreation and contribution to business planning process for your start-up"}>
                          <Select
                            input={<BootstrapInputSelect />}
                            value={collaboratorRole}
                            onChange={e => {

                              setCollaboratorRole(e.target.value);
                            
                            }}
                          >
                            {/* <MenuItem sx={{ fontSize: "14px" }} value={""}>
                              -None-
                            </MenuItem> */}
                            {/*  */}

                            <MenuItem
                              sx={{ fontSize: "14px" }}
                              value={"Commenter"}
                            >
                              Mentor
                            </MenuItem>
                            {/* </Tooltip> */}
                            {/* <Tooltip placement="right-start" title=""> */}
                            <MenuItem sx={{ fontSize: "14px" }} value={"Editor"}>
                              Cofounder
                            </MenuItem>
                            {/* </Tooltip> */}
                          </Select>
                        </Tooltip>
                      </FormControl>
                    </Grid>
                    {/* button box */}
                    <Grid
                      item
                      md={3}
                      sx={{
                        display       : "flex",
                        justifyContent: "center",
                        alignItems    : "center"
                      }}
                    >
                      <Button
                        variant="contained"
                        sx={{ textTransform: "none" }}
                        style={{
                          maxWidth: "150px",
                          minWidth: "150px"
                        }}
                        disabled={
                          collaboratorEmail &&
                            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                              collaboratorEmail
                            )
                            ? false
                            : true
                        }
                        onClick={collaboratorInvite}
                      >
                        Invite
                      </Button>
                    </Grid>
                  </Grid>

                  {/* Invite sent */}

                  {successmsg ?
                    <SuccessMessage
                      successmsg={successmsg}
                      padding="5px 10px"
                    />
                    : errormsg ?
                      <ErrorMessage errormsg={errormsg} padding="5px 10px" />
                      :
                      <Box
                        sx={{
                          borderBottom: "1px solid #E1E2E9",
                          position    : "relative"
                        }}
                      ></Box>
                  }

                  <Box
                    sx={{
                      marginTop: "15px",

                      height   : "270px",
                      overflowY: "auto"
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize  : "14px",
                        color     : "#5F5E5E",
                        fontWeight: "bold"
                      }}
                    >
                      List of members who have access to this project
                    </Typography>

                    {/* single member box */}
                    {sharedUsers.map(shareduser => {

                      return <SingleMember
                        sharedUser={shareduser.user}
                        collaboratorRole={shareduser.collaboratorRole}
                        isAccepted={shareduser.isAccepted}
                        getVenture={getVenture}
                        setSuccessmsg={setSuccessmsg}
                      />;
                    
                    })}

                    {/* ------------ */}
                  </Box>
                </Box>
              </Box>
            </DialogContent>
          </Dialog>

          {/* commented out for beta launch */}

          {/* <Box id={styles.dividerBox}>
            <span className={styles.divider}></span>
          </Box> */}
          {/* <Typography id={styles.typoHead}>All Modules</Typography>
          <Box id={styles.dividerBox}>
            <span className={styles.divider}></span>
          </Box>
          <Typography id={styles.typoComp}>Modules Complete</Typography>
          <Box id={styles.dividerBox}>
            <span className={styles.divider}></span>
          </Box> */}
          {/* <Typography
            sx={{
              marginLeft: "10px",

              fontWeight: "bold",
            }}
          >
            Modules Pending
          </Typography> */}
          {/* <IconButton>
             <PlayCircleIcon sx={{ fontSize: "40px" }} />
          </IconButton>*/}
        </Box>
      </Box>

      {/* module box section */}

      <Box id={styles.gridMainBox}>
        <ModuleBoxGrid percentvalues={percentvalues} />
      </Box>
    </Box>
  );

}
