import React, { useEffect, useState } from "react";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import {
  Box, Button, Dialog, DialogActions, DialogContent, IconButton, TextField, Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SuccessMessage from "../../../../components/SuccessMessage";
import axiosService from "../../../../services/axiosService";

const DiagramFlow = props => {

  const [ successmsg, setSuccessmsg ] = useState("");
  const [ fileName, setFileName ] = useState("");
  const history = useHistory();
  const url = "https://embed.diagrams.net/?embed=1&ui=atlas&spin=1&proto=json";
  let flowchart = null;
  let name = null;
  const { venture } = useSelector(state => ({ ...state }));

  useEffect(() => {

    setFileName(props?.filename);
    load();
  
  });
  useEffect(() => {

    return () => {

      if (window.location.hash === "") {

        props.handleFilename("");
        props.isEditoropen(false);
        localStorage.removeItem(".draft-" + name);
      
      }
    
    };
  
  }, []);
  let load = () => {

    flowchart = document.getElementById("image").getAttribute("src");
    start();
  
  };
  let start = () => {

    // name =
    //   window.location.hash.length > 1
    //     ? window.location.hash.substring(1)
    //     : "default";
    name = "default";
    var current = localStorage.getItem(name);

    if (current != null) {

      var entry = JSON.parse(current);

      document.getElementById("image").setAttribute("src", entry.data);
    
    } else {

      document.getElementById("image").setAttribute("src", flowchart);
    
    }
  
  };
  let edit = image => {

    let isexiting = false;
    // document.getElementById("image").setAttribute("src",null);

    document.getElementById("image").setAttribute("hidden", "true");
    // image taken in PNG Format
    let iframe = document.createElement("iframe");

    iframe.src = url;
    iframe.id = "iframe";
    iframe.style.height = "90%";
    iframe.style.width = "100%";
    // iframe.style.position='relative';
    // iframe.style.top='0';
    // iframe.style.bottom='0';
    // iframe.style.left='0';
    // iframe.style.right='0';
    const close = () => {

      document.getElementById("image").removeAttribute("hidden");
      window.removeEventListener("message", receive);
      document.getElementById("diagramflow").removeChild(iframe);
      props.handleExpand(false);
      props.isEditoropen(false);
      props.isMainLoader();
      history.push("/userjourneymaps");
    
    };
    let draft = localStorage.getItem(".draft-" + name);

    if (draft != null) {

      draft = JSON.parse(draft);
    
    }
    let receive = async evt => {

      if (evt.data.length > 0) {

        const msg = JSON.parse(evt.data);

        if (msg.event === "init") {

          if (draft != null) {

            iframe.contentWindow?.postMessage(
              JSON.stringify({
                action  : "load",
                autosave: 1,
                // noExitBtn: 1,
                xml     : draft.xml
              }),
              "*"
            );
            iframe.contentWindow?.postMessage(
              JSON.stringify({ action: "status", modified: true }),
              "*"
            );
          
          } else {

            iframe.contentWindow?.postMessage(
              JSON.stringify({
                action  : "load",
                autosave: 1,
                // noExitBtn: 1,
                xmlpng  : image
              }),
              "*"
            );
          
          }
        
        } else if (msg.event === "export") {

          localStorage.setItem(
            name,
            JSON.stringify({ lastModified: new Date(), data: msg.data })
          );
          image = msg.data;
          let id = window.location.hash.substring(1);
          let flowchartid = localStorage.getItem("flowchartid");

          if ((id === "new" || id === "edit") && !flowchartid) {

            const response = await axiosService.post(
              `${process.env.REACT_APP_ADDRESS}/create_flowchart`,
              {
                image     : image,
                filename  : fileName,
                userid    : props.userid,
                customerid: props.customerid,
                type      : id === "new" ? "normal" : "template"
              }
            );

            localStorage.setItem("flowchartid", response.data._id);
            // props.handleFilename("");
            // props.isMainLoader();
          
          } else if (flowchartid) {

            await axiosService.post(
              `${process.env.REACT_APP_ADDRESS}/update_flowchart`,
              {
                image      : image,
                flowchartid: flowchartid,
                userid     : props.userid,
                customerid : props.customerid
              }
            );
            // props.handleFilename("");
            // props.isMainLoader();
          
          } else {

            await axiosService.post(
              `${process.env.REACT_APP_ADDRESS}/update_flowchart`,
              {
                image      : image,
                flowchartid: id,
                userid     : props.userid,
                customerid : props.customerid
              }
            );
            // props.handleFilename("");
            // props.isMainLoader();
          
          }
          if (isexiting) {

            document.getElementById("image").setAttribute("src", image);
            draft = null;
            localStorage.removeItem(".draft-" + name);
            localStorage.removeItem("flowchartid");
            flowchart = image;
            close();
          
          } else {

            iframe.contentWindow?.postMessage(
              JSON.stringify({ action: "status", modified: false }),
              "*"
            );
            setSuccessmsg("Changes saved");
            setTimeout(() => {

              setSuccessmsg("");
            
            }, 5000);
          
          }
        
        } else if (msg.event === "autosave") {

          localStorage.setItem(
            ".draft-" + name,
            JSON.stringify({ lastModified: new Date(), xml: msg.xml })
          );
        
        } else if (msg.event === "save") {

          iframe.contentWindow.postMessage(
            JSON.stringify({
              action: "export",
              format: "xmlpng",
              xml   : msg.xml,
              spin  : "Updating page"
            }),
            "*"
          );
          localStorage.setItem(
            ".draft-" + name,
            JSON.stringify({ lastModified: new Date(), xml: msg.xml })
          );
          // image = msg.data;
          // document.getElementById("image").setAttribute("src", image);
          // flowchart = image;
        
        } else if (msg.event === "exit") {

          isexiting = true;
          if (draft != null) {

            iframe.contentWindow.postMessage(
              JSON.stringify({
                action: "export",
                format: "xmlpng",
                xml   : msg.xml,
                spin  : "Saving page"
              }),
              "*"
            );
            props.handleFilename("");
          
          } else {

            props.handleFilename("");
            draft = null;
            localStorage.removeItem(".draft-" + name);
            localStorage.removeItem("flowchartid");
            close();
          
          }
        
        }
      
      }
    
    };

    window.addEventListener("message", receive);
    iframe.setAttribute("src", url);
    document.getElementById("diagramflow").appendChild(iframe);
  
  };

  return (
    <div
      id="diagramflow"
      style={ {
        display      : "flex",
        flexDirection: "column",
        alignItems   : "flex-end",
        margin       : "auto",
        height       : "100%"
      } }>
      <Box
        sx={ {
          display       : "flex",
          marginBottom  : "10px",
          width         : "60%",
          justifyContent: "space-between",
          alignItems    : "center"
        } }>
        <Box>
          <Typography sx={ { fontSize: "22px", fontWeight: "bold" } }>
            { fileName }
          </Typography>
        </Box>
        <Box sx={ { display: "flex", alignItems: "center" } }>
          { successmsg &&
            <SuccessMessage successmsg={ successmsg } msgfont={ "12px" } />
          }
          { !props.isopen &&
            <>
              { props.flowchartType !== "upload" &&
                <Button
                  variant="contained"
                  disabled={
                    props.flowchartType === "upload" ||
                      venture.userRole === "Commenter"
                      ? true
                      : false
                  }
                  sx={ {
                    color          : "white",
                    backgroundColor: "#071AFF",
                    marginRight    : "20px",
                    display        : window.innerWidth < 1024 ? "none" : "block",
                    height         : "100%"
                  } }
                  onClick={ () => {

                    edit(flowchart);
                    props.isEditoropen(true);
                  
                  } }>
                  Edit
                </Button>
              }
              <Button
                onClick={ () => {

                  history.push("/userjourneymaps");
                
                } }
                variant="outlined"
                sx={ {
                  background: "#F0F1FF 0% 0% no-repeat padding-box",
                  border    : "1px solid #071AFF",
                  color     : "#071AFF",
                  height    : "100%"
                } }
                disableElevation>
                Close
              </Button>
            </>
          }
          { props.isexpand ?
            <IconButton
              style={ { display: window.innerWidth < 1024 ? "none" : "block" } }
              onClick={ () => {

                props.handleExpand(false);
              
              } }>
              <CloseFullscreenIcon />
            </IconButton>
            :
            <IconButton
              style={ { display: window.innerWidth < 1024 ? "none" : "block" } }
              onClick={ () => {

                props.handleExpand(true);
              
              } }>
              <OpenInFullIcon />
            </IconButton>
          }
        </Box>
      </Box>
      { window.innerWidth < 1024 &&
        (window.location.hash !== `#${props.id}` ||
          window.location.hash === "#new" ||
          window.location.hash === "#edit") ?
        <div className="ttribe-flow-chart-warning-msg-con">
          <Box>
            <Typography style={ { fontSize: "26px", fontWeight: "600" } }>
              This section uses flowcharts to design customer user journeys.
              Kindly switch to a desktop or a laptop for the best experience.
            </Typography>
          </Box>
          <style>{ "#iframe {display: none}" }</style>
        </div>
        :
        <>
          <span id="iframespan"></span>
        </>
      }

      <img
        className="ttribe-flowchart-iframe-img"
        style={
          window.innerWidth < 1024 &&
            (window.location.hash !== `#${props.id}` ||
              window.location.hash === "#new" ||
              window.location.hash === "#edit") ? { display: "none" } : { width: "100%" }
        }
        id="image"
        src={ props.value }
        alt=" "
      // onError={(e) => e.target.style.display='none' }
      // onClick={() => {
      //   edit(flowchart);
      //   props.isEditoropen(true);
      // }}
      />
      <Dialog
        open={ props.dialogopen }
        style={ { display: window.innerWidth < 1024 ? "none" : "block" } }
        // sx={{
        //   "& .MuiDialog-container": {
        //     alignItems: "center",
        //   },
        // }}
        fullWidth>
        <DialogContent>
          <Typography>Enter a file name for your flowchart</Typography>
          <TextField
            autoFocus
            margin="dense"
            name="filename"
            value={ fileName }
            onChange={ e => {

              props.handleFilename(e.target.value.trimStart());
            
            } }
            size="small"
            type="text"
            fullWidth
            variant="outlined"
          />
          { props.flowchartnames.includes(fileName.toLowerCase()) &&
            <Typography
              sx={ { color: "red", textAlign: "center", marginTop: "5px" } }>
              Name already exists, try with new one.
            </Typography>
          }
        </DialogContent>
        <DialogActions>
          <Button
            onClick={ () => {

              props.handleDialog();
              edit(flowchart);
              props.isEditoropen(true);
            
            } }
            variant="contained"
            disabled={
              fileName === "" ||
                props.flowchartnames.includes(fileName.toLowerCase())
                ? true
                : false
            }>
            OK
          </Button>
          <Button
            onClick={ () => {

              props.handleDialog();
              props.handleFilename("");
              history.push("/userjourneymaps");
            
            } }
            disableElevation
            variant="outlined"
            sx={ {
              background: "#F0F1FF 0% 0% no-repeat padding-box",
              border    : "1px solid #071AFF",
              color     : "#071AFF"
              // "&:hover": {
              //   background: "#F0F1FF 0% 0% no-repeat padding-box",
              //   border: "1px solid #071AFF",
              //   color: "#071AFF",
              // },
            } }>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

};

export default DiagramFlow;
