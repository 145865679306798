import React, { useEffect, useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box, Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import CustomMultilineTextfield from "../../../../components/CustomMultilineTextfield";
import HelpIconTooltip from "../../../../components/HelpIconTooltip";
import Data from "../../../../Data/Gtm.json";
import { getGtm } from "../../../../services/gtm";

export default function CoverOneLiner() {

  const [ positioningOneLiner, setPositioningOneLiner ] = useState("");

  const data = Data.productpositioning["gtmpositioningoneliner"];

  let { venture } = useSelector(state => {

    return { ...state };
  
  });

  useEffect(() => {

    fetchGtm();
  
  });

  const fetchGtm = async () => {

    try {

      const response = await getGtm(venture.gtm);

      setPositioningOneLiner(
        response.data.positioning.productPositioning.oneLiner
      );
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  return (
    <Box>
      <Box
        sx={{
          width     : "100%",
          display   : "flex",
          alignItems: "center"
          // paddingLeft: "10px",
        }}
      >
        <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
          GTM
        </Typography>
        <ChevronRightIcon sx={{ color: "gray" }} />
        <Typography>Positioning</Typography>
      </Box>

      <Box>
        <Typography sx={{ fontSize: "12px" }}>
          Construct customer-facing ‘positioning’ statements for your
          product/solution.
        </Typography>
      </Box>

      <Box sx={{ marginTop: "50px", width: "100%" }}>
        <Box
          sx={{
            boxShadow    : "0px 3px 6px #5F5E5E2B",
            borderRadius : "10px",
            marginBottom : "30px",
            flexDirection: "column",
            width        : "100%"
          }}
        >
          <Typography
            sx={{
              fontWeight     : "bold",
              padding        : "10px 30px",
              color          : "#393939",
              backgroundColor: "#F7F7F7",
              position       : "relative"
            }}
          >
            Product positioning in 1 line.
            <HelpIconTooltip
              tooltipdata={
                "This is your 30-second product pitch – a 1-liner that you can use when someone asks what your venture does. Imagine this as the heading on your business’ landing page, or the beginning of a sales pitch to a customer, or an elevator pitch to a potential investor.\n\nExample:\nHealthvita is a multi-platform, multi-device connected app that helps healthcare providers monitor and proactively guide their cardiac patients, to improve patient outcomes, reduce hospitalization, and improve the bottom-line. "
              }
              width={"500px"}
            />
          </Typography>

          <Box sx={{ width: "100%" }}>
            <CustomMultilineTextfield
              minRows={10}
              width="100%"
              maxRows={10}
              padding={"27px 27px"}
              border={"none"}
              placeholder={data.placeholder}
              value={positioningOneLiner}
              disabled={true}
            //   handledetailsChange={(e) => {
            //     setDetails(e.target.value);
            //   }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );

}
