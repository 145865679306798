import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box, Dialog,
  Grid, IconButton, Slide, Typography
} from "@mui/material";
import React, { forwardRef, useState } from "react";
import { useHistory } from "react-router-dom";
import CustomMultilineTextfield from "../../../components/CustomMultilineTextfield";
import PersonaDetails from "../Customer/TargetSegments/PersonaDetails";
import styles from "./Solution.module.css";

const Transition = forwardRef(function Transition (props, ref) {

  return <Slide direction="up" ref={ ref } { ...props } />;

});

const SolutionsGridRow = props => {

  const [ personadialogbox, setPersonaDialog ] = useState(false);
  const history = useHistory();
  // const props.eachPersona = props.eachPersona;
  // const props.eachSegment = props.eachSegment;
  const segmentindex = props.segmentindex;
  const personaindex = props.personaindex;

  const personaDialogopen = () => {

    setPersonaDialog(true);
  
  };
  const personaDialogclose = () => {

    setPersonaDialog(false);
  
  };

  
  return (
    <Grid item sx={ { display: "flex" } } md={ 12 } columnGap={ 3 }>
      <Box
        component="span"
        id={ styles.persona }
        onClick={ () => personaDialogopen() }
      >
        <img
          src={ `${process.env.REACT_APP_AWS_ASSETS_URL}${props.eachPersona?.avatar}` }
          width="50px"
          height="50px"
        />
        <Typography sx={ { color: "#393939", textTransform: "capitalize" } }>
          { props.eachPersona?.name }
        </Typography>
      </Box>
      <Dialog
        open={ personadialogbox }
        TransitionComponent={ Transition }
        keepMounted
        PaperProps={ {
          style: {
            borderRadius   : "20px",
            backdropFilter : "blur(10px)",
            backgroundColor: "rgba(255,255,255,0.80)",
            margin         : "20px"
          }
        } }
        maxWidth="md"
        fullWidth
      >
        <Box sx={ { alignSelf: "flex-end" } }>
          <IconButton
            component="span"
            onClick={ () => {

              history.push({ hash: "" });
              personaDialogclose();
            
            } }
          >
            <HighlightOffIcon />
          </IconButton>
        </Box>
        <PersonaDetails
          eachPersona={ props.eachPersona }
          segment={ props.eachSegment }
        />
      </Dialog>
      {/* <TextField
        type="text"
        multiline
        maxRows={3}
        minRows={3}
        sx={{
          width: "100%",
          flex: "0 0 250px",
          "& .MuiOutlinedInput-input.MuiInputBase-input": {
            fontSize: "14px",
          },
          backgroundColor: "white",
        }}
        value={eachPersona.SolutionGrid.problemOrNeed}
        onChange={(e) => {
          props.handleChange(
            "problemOrNeed",
            e.target.value,
            segmentindex,
            personaindex
          );
        }}
      /> */}
      <Box id={ styles.problemMapCauseAndProbGrid }>

        <CustomMultilineTextfield
          disabled={ true }
          // fontSize={"14px"}
          flex={ "0 0 250px" }
          backgroundColor={ "white" }
          maxRows={ 3 }
          minRows={ 3 }
          width={ "100%" }
          value={ props.eachPersona.SolutionGrid.problemOrNeed }
          handledetailsChange={ e => {

            props.handleChange(
              "problemOrNeed",
              e.target.value,
              segmentindex,
              personaindex
            );
          
          } }
        />
      </Box>

      <Box id={ styles.problemMapCauseAndProbGrid }>

        <CustomMultilineTextfield
          disabled={ true }
          // fontSize={"14px"}
          // flex={"0 0 250px"}
          backgroundColor={ "white" }
          maxRows={ 3 }
          minRows={ 3 }
          width={ "100%" }
          value={ props.eachPersona.SolutionGrid.rootCause }
          handledetailsChange={ e => {

            props.handleChange(
              "rootCause",
              e.target.value,
              segmentindex,
              personaindex
            );
          
          } }
        />
      </Box>

      <Box id={ styles.problemMapCauseAndProbGrid }>

        <CustomMultilineTextfield
          disabled={ true }
          // fontSize={"14px"}
          // flex={"0 0 250px"}
          backgroundColor={ "white" }
          maxRows={ 3 }
          minRows={ 3 }
          width={ "100%" }
          value={ props.eachPersona.SolutionGrid.SolutionCapability }
          handledetailsChange={ e => {

            props.handleChange(
              "SolutionCapability",
              e.target.value,
              segmentindex,
              personaindex
            );
          
          } }
        />
      </Box>
      { props.eachPersona.SolutionGrid.others.length > 0 ?
        props.eachPersona.SolutionGrid.others.map((other, index) => {

          return <Box key={index} id={ styles.problemMapCauseAndProbGrid }>
            <CustomMultilineTextfield
              // fontSize={"14px"}
              // flex={"0 0 250px"}
              backgroundColor={ "white" }
              maxRows={ 3 }
              minRows={ 3 }
              width={ "100%" }
              value={ other.value }
              handledetailsChange={ e => {

                props.handleOtherChange(
                  e.target.value,
                  segmentindex,
                  personaindex,
                  index
                );
              
              } }
            />
          </Box>

          ;
        
        })
        :
        <></>
      }
    </Grid>
  );

};

export default SolutionsGridRow;
