import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import ReactApexChart from "react-apexcharts";

import {
  Box, Dialog, DialogContent, IconButton, Tooltip
} from "@mui/material";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";

const TotalCashFlowByYear = props => {

  const [ chartdata, setChartdata ] = useState([]);
  const currency = props?.currency || "- I";
  const YearsList = props?.year;
  const [ dialogbox, setDialogBox ] = useState(false);
  const history = useHistory();
  const chartTitle = props.chartTitle;
  const moreDetails = props.moreDetails;
  const tableRow = "Total_Cash_Flow_for_the_Year";

  const chartRef = useRef(null);

  useEffect(() => {

    updateChartdata(tableRow);

  }, [ props?.table ]);

  const DataConvertToNumber = data =>{

    return  Number(data.replaceAll(",", ""));

  };

  const updateChartdata = chartTitle => {

    let data = [];
    let count = 0;
    const table = props?.table;

    if (table && table.length > 0) {

      table.map(row => {

        if (chartTitle.includes(row.rowName)) {

          let chartvalues = [];

          row.rowVal.map(column => {

            chartvalues.push(DataConvertToNumber(column.value));

          });
          data.push({
            name: row.rowName,
            data: chartvalues
          });

          count = count + 1;
        
        }
      
      });

      setChartdata(data);

    } else {

      setChartdata([ { name: "No Data", data: [] } ]);
            
    }



  };

  const numberWithCommas = num => {

    if (currency) {

      let newNum = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      return `${currency?.split(" ")[0].trim()} ${newNum}`;

    } else {

      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    }
  
  };


  let options = {
    chart: {
      type   : "bar",
      toolbar: {
        show : true,
        tools: {
          download   : true,
          selection  : false,
          zoom       : false,
          zoomin     : false,
          zoomout    : false,
          pan        : false,
          reset      : false,
          customIcons: []
        },
        autoSelected: "pan"
      }
    },
    noData: {
      text         : "No Data to show",
      align        : "center",
      verticalAlign: "middle"
    },
    colors     : [ "#6057ff", "#ff4a33" ],
    plotOptions: {
      bar: {
        horizontal : false,
        columnWidth: "70%",
        endingShape: "rounded"
      }
    },
    dataLabels: {
      // enabled: props.isMultibar ? false : true,
      enabled  : false,
      formatter: function (val) {

        return numberWithCommas(val);
      
      }
    },
    stroke: {
      show  : true,
      width : 2,
      colors: [ "transparent" ]
    },
    xaxis: {
      categories: YearsList && YearsList,
      title     : {
        text   : "Time Period", offsetX: 0,
        offsetY: 90
      },
      position: "bottom"
    },
    yaxis: {
      title: {
        text: `Cash Flow ${currency.split(" ")[0]}`
        // text: "check"
      },
      labels: {
        formatter: val => { return numberWithCommas(val); }
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {

          return `${numberWithCommas(val)} `;
        
        }
      }
    }
  };

  return <div className="tribe-chart-page-con">
    <div className="tribe-fin-chart-year-sel-main-con">
      <div className="tribe-fin-chart-year-sel-title-con"><p> <span >{chartTitle}</span></p></div>
      <Tooltip
        enterTouchDelay={0}
        disableFocusListener
        title="View"
        placement="top"
      >
        <IconButton
          component="span"
          onClick={() => {

            setDialogBox(true);
          
          }}>
          <OpenInFullIcon />
        </IconButton>
      </Tooltip>
    </div>
    <ReactApexChart ref={chartRef} options={options} series={chartdata} type="bar" height="100%" width="95%" className="tribe-apex-chart-con" />
    <div className="fin-chart-more-detail-con">
      <p>For details click <span onClick={() => history.push(moreDetails.url)}>{moreDetails.page}</span></p>
    </div>
    <Dialog
      open={dialogbox}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        style: {
          borderRadius  : "20px",
          backdropFilter: "blur(45px)",
          background:
                        "#FFFFFF 0% 0% no-repeat padding-box",
          boxShadow: "0px 3px 6px #00000029",
          border   : "1px solid #707070"
        }
      }}
    >
      <DialogContent>
        <Box sx={{ textAlign: "center" }}>

          <div className="tribe-fin-chart-year-sel-main-con">
            <div className="tribe-fin-chart-year-sel-title-con"><p> <span>{chartTitle}</span></p></div>
            <Tooltip
              enterTouchDelay={0}
              disableFocusListener
              title="Close"
              placement="right"
            >
              <IconButton
                component="span"
                onClick={() => {

                  setDialogBox(false);
                
                }}>
                <HighlightOffIcon />
              </IconButton>
            </Tooltip>
          </div>
          <ReactApexChart options={options} series={chartdata} type="bar" height="400px" width="95%" className="tribe-apex-chart-con" />
          <div className="fin-chart-more-detail-con">
            <p>For details click <span onClick={() => history.push(moreDetails.url)}>{moreDetails.page}</span></p>
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  </div>;

};

export default TotalCashFlowByYear;
