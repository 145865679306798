import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { Box, Typography } from "@mui/material";
import React from "react";

const BoxWithIcon = props => {

  const margintop = props.margintop;

  
  return (
    <div style={ { height: "100%" } }>
      <Box
        sx={ {
          height         : "81vh",
          // border: "solid 1px grey",
          margin         : margintop ? `${margintop} 100px` : "5vh 4vh 5vh",
          borderRadius   : "5px",
          overflowY      : "auto",
          backgroundColor: "#ffffff"
        } }
      >
        { props.children }
        <Box sx={ { height: "1%", display: "flex", alignItems: "center" } }>
          <Box
            sx={ {
              position       : "absolute",
              right          : "1vw",
              backgroundColor: "white"
            } }
          >
            <Box
              sx={ {
                display       : "flex",
                flexDirection : "column",
                justifyContent: "center",
                alignItems    : "center",
                textDecoration: "none"
              } }
              component="a"
              target="_blank"
              href="/support"
            >
              <ChatBubbleOutlineIcon
                sx={ { fontSize: "50px", color: "#071AFF" } }
              />
              <Typography sx={ { fontSize: "10px", fontWeight: "bold" } }>
                Need help?
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );

};

export default BoxWithIcon;
