import AddIcon from "@mui/icons-material/Add";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box, Button,
  Dialog,
  DialogContent, IconButton
} from "@mui/material";
import React, { useState } from "react";
import IndividualTeamMember from "../IndividualTeamMember";
import PitchTeam from "../PitchReferenceLinks/PitchTeam";
import SectionHeader from "../SectionHeader";
import styles from "./Team.module.css";

export default function Team ({
  logoimg,
  slides,
  isRender,
  setSlides,
  uploadedImgs,
  uploadImages,
  id,
  removeUploadImage,
  deleteUploadedImgs,
  isPublished,
  ...props
}) {

  const [ teamDialogbox, setTeamDialogbox ] = useState(false);

  const slide = slides[isRender];

  const handleTeamChange = (name, value, columnindex) => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;

    let newteammembers = [ ...newSlides[isRender]["teamMembers"] ];

    newteammembers.map((teamMember, teamMemberIndex) => {

      if (teamMemberIndex === columnindex) {

        newteammembers[teamMemberIndex][name] = value;
      
      }
      
      return teamMember;
    
    });
    newSlides[isRender]["teamMembers"] = newteammembers;
    setSlides(newSlides);
  
  };

  const addTeamMember = () => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    newSlides[isRender]["teamMembers"].push({
      name       : `Person ${slide.teamMembers.length + 1}`,
      title      : `Title ${slide.teamMembers.length + 1}`,
      profileInfo: "",
      profileImg : ""
    });
    setSlides(newSlides);
  
  };

  const removeTeamMember = columnindex => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;

    let newteammembers = newSlides[isRender]["teamMembers"].filter(
      (teamMember, teamMemberIndex) => teamMemberIndex !== columnindex
    );

    newSlides[isRender]["teamMembers"] = newteammembers;
    removeUploadImage(`${slide.renderName}.${columnindex}`);

    setSlides(newSlides);
  
  };

  const handleDetailsChange = (name, value) => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    newSlides[isRender][name] = value;
    setSlides(newSlides);
  
  };

  const updateIschange = () => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    setSlides(newSlides);
  
  };

  const teamDialogopen = () => {

    setTeamDialogbox(true);
  
  };
  const teamDialogclose = () => {

    setTeamDialogbox(false);
  
  };

  return (
    <Box>
      <SectionHeader
        isEdit={ props.isEdit }
        Collapsecomment={ props.Collapsecomment }
        collapseCommentFunction={ props.collapseCommentFunction }
        mainHead={ slide.name }
        handleTitleChange={ handleDetailsChange }
        logoimg={ logoimg }
        isPublished={ isPublished }
      />
      <Box
        id={ styles.contentBox }
      >
        {/* ----- */ }

        { slide.teamMembers.map((teamMember, teamMemberIndex) => {

          return <IndividualTeamMember
            isEdit={ props.isEdit }
            teamMember={ teamMember }
            teamMemberIndex={ teamMemberIndex }
            slide={ slide }
            handleTeamChange={ handleTeamChange }
            removeTeamMember={ removeTeamMember }
            uploadImages={ uploadImages }
            profileImg={
              id
                ? {}
                : uploadedImgs.find(
                  img =>
                    img.imgName === `${slide.renderName}.${teamMemberIndex}`
                )
            }
            deleteUploadedImgs={ deleteUploadedImgs }
            updateIschange={ updateIschange }
          />;
        
        }) }

        { props.isEdit ?
          <Box
            sx={ {
              width         : "36px",
              height        : "36px",
              display       : "flex",
              justifyContent: "center",
              alignItems    : "center",
              borderRadius  : "50%",
              position      : "absolute",
              bottom        : "25px",
              right         : "0px",
              backgroundColor:
                slide.teamMembers.length === 5 ? "#b0b0b0" : "#071AFF",
              cursor: slide.teamMembers.length === 5 ? "default" : "pointer"
            } }
            onClick={ () => {

              if (slide.teamMembers.length < 5) addTeamMember();
            
            } }
          >
            <AddIcon
              id={ styles.addIcon }
            />
          </Box>
          :
          <></>
        }

        {/* reference link */ }

        { props.isEdit ?
          <Box sx={ { position: "absolute", bottom: "0px", left: "10px" } }>
            <Button
              style={ {
                textTransform : "none",
                textDecoration: "underline",
                fontSize      : "12px",
                padding       : "0px",
                fontWeight    : "bold"
              } }
              onClick={ () => {

                teamDialogopen();
              
              } }
            >
              Team
            </Button>
            <Dialog
              open={ teamDialogbox }
              onClose={ teamDialogclose }
              PaperProps={ {
                style: {
                  borderRadius   : "20px",
                  backdropFilter : "blur(100px)",
                  backgroundColor: "#ffffff",
                  boxShadow      : "0px 3px 6px #00000029",
                  width          : "70%"
                }
              } }
              maxWidth="false"
              fullWidth
            >
              <DialogContent sx={ { padding: "40px" } }>
                <Box
                  sx={ {
                    width   : "100%",
                    position: "relative"
                  } }
                >
                  <PitchTeam />
                  <IconButton
                    onClick={ () => {

                      teamDialogclose();
                    
                    } }
                    sx={ {
                      position: "absolute",
                      right   : "-40px",
                      top     : "-40px"
                    } }
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Box>
              </DialogContent>
            </Dialog>
          </Box>
          :
          <></>
        }
      </Box>
    </Box>
  );

}
