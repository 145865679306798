/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from "react";
import BackupIcon from "@mui/icons-material/Backup";
import {
  Box, Button, CircularProgress, Dialog,
  DialogContent, FormControl, Grid, IconButton, InputLabel,
  ListSubheader, MenuItem, Paper, Select, Tooltip,
  Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BackNextButton from "../../../../components/BackNextButton";
import { currencies } from "../../../../components/Currencies";
import ErrorMessage from "../../../../components/ErrorMessage";
import HelpIconTooltip from "../../../../components/HelpIconTooltip";
import IndividualSectionHeaders from "../../../../components/IndividualSectionHeaders";
import ModuleSections from "../../../../components/ModuleSections";
import Datas from "../../../../Data/Finance.json";
import styles from "./PlOutlook.module.css";

import FinancialsNavBar from "../FinancialsNavBar";

import {
  deleteExcel, getFinancials, updateFinancialPloutlook, updateFinancialFroutlook,
  updateFinancials, updatePloutlook
} from "../../../../services/financials";
// import PlChart from "./PlChart";
import PlTable from "./PlTable";

import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseIcon from "@mui/icons-material/Close";

// import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import CustomSelect from "../../../../components/CustomSelect";
import CustomTextField from "../../../../components/CustomTextfield";
import {
  createFinancialsComment,
  deleteFinancialsComment,
  updateFinancialsComment
} from "../../../../services/comment";
import IndividualExcel from "./IndividualExcel";

const useStyles = makeStyles(() => ({
  customWidth: {
    maxWidth       : 200,
    fontSize       : 12,
    backgroundColor: "white",
    color          : "#707070",
    boxShadow      : "0px 3px 6px #00000029",
    border         : "1px solid #707070",
    borderRadius   : 10
  }
}));

const PlOutlook = () => {

  const [ loading, setLoading ] = useState(false);
  const [ autosaving, setAutosaving ] = useState(false);
  const [ dialogbox, setDialog ] = useState(false);
  const [ startDialogbox, setStartDialogbox ] = useState(false);
  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  // const [ errorDialog, setErrorDialog ] = useState(false);
  const [ isexpand, setIsexpand ] = useState(false);
  const [ financials, setFinancials ] = useState({
    plOutlooks          : [],
    frOutlooks          : [],
    currentFundingSource: [],
    fundRaising         : {
      totalFundingNeed : 0,
      fundingAllocation: ""
    },
    totalCurrentFunding: 0,
    plOutlooksCurrency : "",
    frOutlooksCurrency : "",
    currency           : ""
  });
  const [ excels, setExcels ] = useState([]);
  const [ comments, setComments ] = useState([]);
  const [ currentploutlook, setCurrentPloutlook ] = useState({});
  const [ currency, setcurrency ] = useState();
  const [ table, setTable ] = useState(Datas.ploutlook.initialdata);
  const [ selectedyear, setSelectedyear ] = useState();
  const [ year, setYear ] = useState("");
  const [ years, setYears ] = useState([]);
  const [ yearDialog, setYearDialog ] = useState(false);
  const [ currencyDialog, setCurrencyDialog ] = useState(false);
  const [ errormessage, setErrorMessage ] = useState("");
  const [ isedit, setIsedit ] = useState(false);
  const [ selectedindex, setSelectedindex ] = useState(-1);
  const [ yearList, setYearList ] = useState([]);

  const history = useHistory();
  const classes = useStyles();
  const [ isDragging, setIsDragging ] = useState(false);
  const [ startY, setStartY ] = useState(0);
  const [ startX, setStartX ] = useState(0);
  const [ currentY, setCurrentY ] = useState(0);
  const [ currentX, setCurrentX ] = useState(0);
  const [ scrollTop, setScrollTop ] = useState(0);
  const [ scrollLeft, setScrollLeft ] = useState(0);
  const [ isDataLoading, setIsDataLoading ] = useState(true);
  const containerRef = useRef(null);

  const { user, venture } = useSelector(state => ({ ...state }));

  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);
    
    } else {

      setCollapsecomment(true);
    
    }
  
  };

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  const handleMouseDown = event => {

    if (!isDragging) {

      setIsDragging(true);
      setStartY(event.clientY);
      setStartX(event.clientX);
      setCurrentY(event.clientY);
      setCurrentX(event.clientX);
      setScrollTop(containerRef.current.scrollTop);
      setScrollLeft(containerRef.current.scrollLeft);
    
    }
  
  };

  const handleMouseMove = event => {

    if (!isDragging) {

      return;
    
    }
    if (currentY !== event.clientY || currentX !== event.clientX) {

      setCurrentY(event.clientY);
      setCurrentX(event.clientX);

      requestAnimationFrame(() => {

        containerRef.current.scrollTop = scrollTop + startY - currentY;
        containerRef.current.scrollLeft = scrollLeft + startX - currentX;
      
      });
    
    }
  
  };

  const handleMouseUp = () => {

    setIsDragging(false);
  
  };

  const yearDialogopen = () => {

    setYearDialog(true);
  
  };
  const yearDialogclose = () => {

    setYearDialog(false);
  
  };
  // function for Chart Dialog

  const currencyDialogopen = () => {

    setCurrencyDialog(true);
  
  };

  const currencyDialogclose = () => {

    setCurrencyDialog(false);
  
  };

  // const errorDialogopen = () => {

  //   setErrorDialog(true);
  
  // };
  // const errorDialogclose = () => {

  //   setErrorDialog(false);
  
  // };

  const removeExcel = async index => {

    await deleteExcel(index, venture.financials);
    const newexcels = excels.filter((excel, i) => index !== i);

    setExcels(newexcels);

  };

  useEffect(() => {

    fetchFinancials();
    
  
  }, []);

  const fetchFinancials = async () => {

    setIsDataLoading(true);

    try {

      setLoading(true);
      const response = await getFinancials(venture.financials, venture._id);

      setFinancials(response.data.finance);
      setExcels(response.data.excels);
      setComments(response.data.finance.comments.plOutlook);

      if(response.data.finance.financialYears && response.data.finance.financialYears.length == 0){
          
        setStartDialogbox(true);
        
      } else {
        
        setStartDialogbox(false);

      }

      // yearList && yearList.length ? setStartDialogbox(false) : ;
      if (response.data.finance.plOutlooks.length > 0) {

        

        let newyears = [];

        response.data.finance.plOutlooks.map(ploutlook => {

          newyears.push(ploutlook.year);
        
        });

        setYearList(response.data.finance.financialYears);
        setYears(newyears);
        setYear(response.data.finance.plOutlooks[0].year);
        setCurrentPloutlook(response.data.finance.plOutlooks[0]);
        setcurrency(response?.data?.finance?.currency ? response?.data?.finance?.currency : response?.data?.finance?.plOutlooksCurrency);

        const tableData = response.data.finance.plOutlooks[0].plOutlook.table;

        setTable(tableData);

      }


      setLoading(false);

      setIsDataLoading(false);
    
    } catch (error) { console.error(error); setIsDataLoading(false); }
  
  };


  // To get the updated usestate value to save in useEffect cleanup
  const financialsRef = useRef(financials);

  financialsRef.current = financials;

  useEffect(() => {

    return handleSave;
  
  }, []);

  const handleSave = async () => {

    try {

      await Promise.all(
        financialsRef.current.plOutlooks.map(ploutlook => {

          const tableData = ploutlook.plOutlook.table;

          
          return updatePloutlook(
            ploutlook.plOutlook._id,
            tableData,
            venture._id
          );
        
        })
      );

      await updateFinancials(venture.financials, financialsRef.current);
      
      return true;
    
    } catch (error) {

      console.error(error);
      
      return false;
    
    }
  
  };

  const handleSelect = e => {

    setYear(e.target.value);
    financials.plOutlooks.map(ploutlook => {

      if (ploutlook.year === e.target.value) {

        setCurrentPloutlook(ploutlook);
        const tableData = ploutlook.plOutlook.table;

        setTable(tableData);
        
        return;
      
      }
    
    });
  
  };

  const removeNonNumeric = num => num.toString().replace(/[^0-9]/g, "");

  const initialRender = useRef(true);

  useEffect(() => {

    if (initialRender.current) {

      initialRender.current = false;
    
    } else {

      if (Object.keys(currentploutlook).length !== 0) {

        const timeoutId = setTimeout(async () => {

          setAutosaving(true);
          await Promise.all(
            financials.plOutlooks.map(ploutlook => {

              const tableData = ploutlook.plOutlook.table;

              
              return updatePloutlook(
                ploutlook.plOutlook._id,
                tableData,
                venture._id
              );
            
            })
          );

          await updateFinancials(venture.financials, financials);
          setAutosaving(false);
        
        }, 20000);

        return () => clearTimeout(timeoutId);
      
      }
    
    }
  
  }, [
    table, currentploutlook, financials
  ]);

  const handleChange = (value, rowindex, colindex) => {

    if (financials.plOutlooks.length === 0) {

      setStartDialogbox(true);
      
    } else if (financials.currency === "") {

      currencyDialogopen();
      
    } else {

      let newTable = [ ...table ];
      let q = 0,
        t = 16;

      if (colindex < 3) {

        q = 3;
    
      } else if (colindex < 7) {

        q = 7;
    
      } else if (colindex < 11) {

        q = 11;
    
      } else if (colindex < 15) {

        q = 15;
    
      }

      // update value
      newTable[rowindex]["rowVal"][colindex]["value"] = Number(
        removeNonNumeric(value)
      );

      // update quarter
      newTable[rowindex]["rowVal"][q]["value"] =
      newTable[rowindex]["rowVal"][q - 1]["value"] +
      newTable[rowindex]["rowVal"][q - 2]["value"] +
      newTable[rowindex]["rowVal"][q - 3]["value"];

      // update Total
      newTable[rowindex]["rowVal"][t]["value"] =
      newTable[rowindex]["rowVal"][0]["value"] +
      newTable[rowindex]["rowVal"][1]["value"] +
      newTable[rowindex]["rowVal"][2]["value"] +
      newTable[rowindex]["rowVal"][4]["value"] +
      newTable[rowindex]["rowVal"][5]["value"] +
      newTable[rowindex]["rowVal"][6]["value"] +
      newTable[rowindex]["rowVal"][8]["value"] +
      newTable[rowindex]["rowVal"][9]["value"] +
      newTable[rowindex]["rowVal"][10]["value"] +
      newTable[rowindex]["rowVal"][12]["value"] +
      newTable[rowindex]["rowVal"][13]["value"] +
      newTable[rowindex]["rowVal"][14]["value"];

      // To update  value columns
      updateValues(newTable, colindex);

      // To update respective quarter columns
      updateValues(newTable, q);

      // To update total columns
      updateValues(newTable, t);

      setTable(newTable);
    
    }
  
  };

  const updateValues = (newtable, col) => {

    // To Find GrossProfit
    newtable[3]["rowVal"][col]["value"] =
      newtable[1]["rowVal"][col]["value"] - newtable[2]["rowVal"][col]["value"];

    // To Find % Revenues
    newtable[4]["rowVal"][col]["value"] = Math.round(
      (newtable[3]["rowVal"][col]["value"] /
        newtable[1]["rowVal"][col]["value"]) *
      100
    );

    // To Find Total operating costs
    newtable[9]["rowVal"][col]["value"] =
      newtable[5]["rowVal"][col]["value"] +
      newtable[6]["rowVal"][col]["value"] +
      newtable[7]["rowVal"][col]["value"] +
      newtable[8]["rowVal"][col]["value"];

    // To Find EBIT
    newtable[10]["rowVal"][col]["value"] =
      newtable[3]["rowVal"][col]["value"] - newtable[9]["rowVal"][col]["value"];

    // To Find Total Operating Margin %
    newtable[11]["rowVal"][col]["value"] = Math.round((newtable[10]["rowVal"][col]["value"] / newtable[1]["rowVal"][col]["value"]) * 100);

    // To Find NetIncome
    newtable[14]["rowVal"][col]["value"] =
      newtable[10]["rowVal"][col]["value"] -
      (newtable[12]["rowVal"][col]["value"] +
        newtable[13]["rowVal"][col]["value"]);
  
  };

  const updateYear = async () => {

    if (String(selectedyear).length !== 4) {

      setErrorMessage("Please enter valid year");

      setTimeout(() => {

        setErrorMessage("");
      
      }, 3000);
    
    } else {

      setErrorMessage("");

      if (selectedyear > 1000 && selectedyear < 4000) {

        await updateFinancialPloutlook(
          selectedyear,
          venture.financials,
          venture._id
        );
        await updateFinancialFroutlook(
          selectedyear,
          venture.financials,
          venture._id
        );
        setStartDialogbox(false);

        fetchFinancials();
      
      } else {

        setErrorMessage("Please enter valid year");
        setTimeout(() => {

          setErrorMessage("");
        
        }, 3000);
      
      }
    
    }

  };

  const handleSubmit = async () => {

    try {

      if(isDataLoading == false){

        await Promise.all(
          financials.plOutlooks.map(ploutlook => {

            const tableData = ploutlook.plOutlook.table;

            
            return updatePloutlook(
              ploutlook.plOutlook._id,
              tableData,
              venture._id
            );

          })
        );

        await updateFinancials(venture.financials, financials);
        history.push("/fundingrunway");

      }
    
    } catch (error) {

      console.error(error);

    }

  };

  const handleChangeRowHeaders = (value, rowindex) => {

    if (financials.plOutlooks.length === 0) {

      setStartDialogbox(true);

    } else if (financials.currency === "") {

      currencyDialogopen();

    } else {

      let newfinancials = { ...financials };

      newfinancials.plOutlooks.map(ploutlook => {

        let newploutlook = { ...ploutlook };
        let newTable = [ ...newploutlook.plOutlook.table ];

        newTable[rowindex]["rowName"] = value;

        return newploutlook;

      });

      setFinancials(newfinancials);

    }

  };

  const currencyOptions = currencies.map(currency => ({
    value: `${currency.symbol} ${currency.code}`,
    label: `${currency.symbol} ${currency.code}`
  }));

  const addComment = async (commentText, replyTo) => {

    try {

      const response = await createFinancialsComment(
        commentText,
        user._id,
        "plOutlook",
        "",
        venture.financials,
        replyTo,
        window.location.href,
        "Finance > P&L Outlook"
      );

      setComments(response.data.comments.plOutlook);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateComment = async (commentid, commentText) => {

    try {

      const response = await updateFinancialsComment(
        commentText,
        user._id,
        commentid,
        venture._id,
        window.location.href,
        "Finance > P&L Outlook"
      );

      setComments(response.data.comments.plOutlook);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const deleteComment = async commentid => {

    try {

      const response = await deleteFinancialsComment(
        "plOutlook",
        "",
        venture.financials,
        commentid,
        user._id,
        window.location.href,
        "Finance > P&L Outlook"
      );

      setComments(response.data.comments.plOutlook);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  return (
    <ModuleSections
      isexpand={isexpand}
      Collapsecomment={Collapsecomment}
      comments={comments}
      addComment={addComment}
      deleteComment={deleteComment}
      updateComment={updateComment}>

      <div className="box-display-style-for-next-and-back-button-with-justify">
        <div className="box-indiv-header-con" style={{ height: "14%", background: "white" }}>
          {loading ?
            <></>
            :
            <>
              <FinancialsNavBar />
              <IndividualSectionHeaders
                mainheading={"Finance"}
                subheading={"P&L Outlook"}
                headingcontent={
                  "Derive the Profit and Loss progression for your company"
                }
                isGtm={false}
                Dialogopen={Dialogopen}
                Dialogclose={Dialogclose}
                dialogbox={dialogbox}
                collapse_comment_function={collapse_comment_function}
                content={Datas.ploutlook}
                isexpand={isexpand}
                isFinance={ venture.userRole === "Commenter" ? false : true}
                isSaveButton={true}
                handleSave={handleSave}
              />

            </>
          }
        </div>
        {/* </div> */}

        <Grid
          item
          id={styles.maingrid}
          xs={12}
          sx={{
            height   : "64%",
            width    : "100%",
            overflowY: "auto",
            overflowX: "auto",
            padding  : "0px 60px 0px"
          }}
          onClick={() => {

            setIsedit(false);
            setSelectedindex(-1);
          
          }}>

          {loading ?
            <Grid
              container
              justifyContent="center"
              height="100%"
              alignItems="center">
              <Grid item>
                <CircularProgress size="60px" thickness={3} />
              </Grid>
            </Grid>
            :
            <>
              {startDialogbox && yearList.length == 0 ? <>
                <Dialog
                  open={startDialogbox}
                  PaperProps={{
                    style: {
                      borderRadius  : "20px",
                      backdropFilter: "blur(45px)",
                      background    : "#FFFFFF 0% 0% no-repeat padding-box",
                      boxShadow     : "0px 3px 6px #00000029",
                      border        : "1px solid #707070"
                    }
                  }}
                >
                  <div className="tribe-fin-close-dialog-title-con" style={{ background: "white" }} id="alert-dialog-title">
                    <div className="tribe-fin-plan-title-con">
                      {/* {`${venture.details.name} Business Plan Summary`} */}
                    </div>
                    <IconButton
                      aria-label="close"
                      onClick={() => {

                        setStartDialogbox(false);
              
                      }}
                      sx={{
                        position: "absolute",
                        right   : 0,
                        top     : 0,
                        color   : theme => theme.palette.grey[500]
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <DialogContent>
                    <Box sx={venture.userRole === "Commenter" ? { textAlign: "left" } : { textAlign: "left" }}>
                      {
                        venture.userRole === "Commenter" ? <div style={{ textAlign: "center" }}>
                          <Typography gutterBottom>No content available to display. Contact the project owner</Typography>
                          <Button
                            variant="contained"
                            size="small"
                            sx={{
                              color       : "white",
                              marginBottom: "0px"
                            }}
                            onClick={() => history.push("/dashboard")}>
                          OK
                          </Button>
                        </div> :
                          <><Typography gutterBottom>
                        Please enter the financial planning <b>‘Start Year'</b> <br /> to begin the process.
                          </Typography>
                          <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", columnGap: "1em" }}>
                            <Box>
                              <CustomTextField
                                width={"100%"}
                                padding={"3px 5px"}
                                type="number"
                                placeholder="Enter start year"
                                handledetailsChange={e => setSelectedyear(e.target.value)}
                                onKeyDown={e => {

                                  if (e.key !== "Escape") {

                                    // Prevents autoselecting item while typing (default Select behaviour)
                                    e.stopPropagation();
                              
                                  }
                            
                                }}
                              />

                            </Box>
                            <Button
                              variant="contained"
                              size="small"
                              sx={{
                                color       : "white",
                                marginBottom: "0px"
                              }}
                              onClick={() => updateYear()}>
                          ADD
                            </Button>
                          </div></>
                      }
                      
                      {errormessage && <ErrorMessage errormsg={errormessage} />}
                    </Box>

                  </DialogContent>
                </Dialog>
              </> : <>

                {/* dropdown for the table */}
                <Box
                  sx={{
                    display       : "flex",
                    justifyContent: "space-between",
                    alignItems    : "center",
                    margin        : "10px 0px"
                  }}>
                  <Box id={styles.currencyDropdownBox}>
                    <Box id={styles.currencyTypoBox}>
                      <Typography id={styles.currencyTypo}>
                        Select currency
                        <HelpIconTooltip
                          tooltipdata={
                            "Note: Choosing a currency here will only change the symbol on your P&L outlook numbers below. It will not do currency conversion. Please review your numbers if you change your currency setting."
                          }
                        />
                      </Typography>
                    </Box>
                    <Box>
                      <CustomSelect
                        placeholder={"Currency"}
                        fontSize={"14px"}
                        value={currency}
                        name="plOutlooksCurrency"
                        handleChange={e => {

                          setcurrency(e.value);
                          let newfinancials = { ...financials };

                          newfinancials["currency"] = e.value;
                          setFinancials(newfinancials);
                        
                        }}
                        options={currencyOptions}></CustomSelect>
                    </Box>
                  </Box>
                  <Box id={styles.currencyTypoBox}>
                    {autosaving &&
                      <Typography sx={{ marginRight: "20px" }}>
                        Auto Saving....
                      </Typography>
                    }
                    <Box
                      sx={{
                        width:
                          financials.plOutlooks.length > 0 ? "200px" : "initial",
                        display       : "flex",
                        alignItems    : "center",
                        justifyContent: "space-evenly",
                        marginRight   : "5px",
                        marginTop     : "5px"
                      }}>
                      {financials.plOutlooks.length > 0 ?
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Year
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Year"
                            value={year}
                            onChange={handleSelect}
                            disabled={venture.userRole === "Commenter"}>
                            <ListSubheader>
                              <Typography
                                sx={{ fontWeight: "bold", color: "#393939" }}>
                                Projected Years
                              </Typography>
                            </ListSubheader>
                            {years.map((year, index) => {

                              return <MenuItem key={index} value={year}>{year}</MenuItem>;
                            
                            })}
                            <ListSubheader>
                              <Typography
                                sx={{ fontWeight: "bold", color: "#393939" }}>
                                Start Year(Editable)
                              </Typography>
                              <Box>
                                <CustomTextField
                                  width={"100%"}
                                  padding={"3px 5px"}
                                  type="number"

                                  handledetailsChange={e =>
                                    setSelectedyear(e.target.value)
                                  }
                                  onKeyDown={e => {

                                    if (e.key !== "Escape") {

                                      // Prevents autoselecting item while typing (default Select behaviour)
                                      e.stopPropagation();
                                    
                                    }
                                  
                                  }}
                                />
                                <Button
                                  variant="contained"
                                  size="small"
                                  sx={{
                                    // color: "#071AFF",
                                    color       : "white",
                                    marginBottom: "20px"
                                  }}
                                  onClick={yearDialogopen}>
                                  ADD
                                </Button>
                              </Box>
                              <Dialog
                                open={yearDialog}
                                PaperProps={{
                                  style: {
                                    borderRadius  : "20px",
                                    backdropFilter: "blur(45px)",
                                    background:
                                      "#FFFFFF 0% 0% no-repeat padding-box",
                                    boxShadow: "0px 3px 6px #00000029",
                                    border   : "1px solid #707070"
                                  }
                                }}
                              // BackdropProps={{ invisible: true }}
                              >
                                <DialogContent>
                                  <Box sx={{ textAlign: "center" }}>
                                    <Typography gutterBottom>
                                      Do you want to continue to edit the year?
                                    </Typography>
                                    <Typography gutterBottom>
                                      Note: Changing the editable year may result in a data loss. Make sure you have a copy of the data for the years that will be impacted due to this change. And, this change will impact all the pages in ‘Financials’ module
                                    </Typography>
                                    <Button
                                      variant="contained"
                                      size="medium"
                                      sx={{
                                        backgroundColor: "#071AFF",
                                        marginLeft     : "10px"
                                      }}
                                      onClick={() => {

                                        updateYear();
                                        yearDialogclose();
                                      
                                      }}>
                                      Yes
                                    </Button>
                                    <Button
                                      variant="contained"
                                      size="medium"
                                      sx={{
                                        backgroundColor: "#071AFF",
                                        marginLeft     : "10px"
                                      }}
                                      onClick={() => {

                                        yearDialogclose();
                                      
                                      }}>
                                      No
                                    </Button>
                                  </Box>
                                </DialogContent>
                              </Dialog>
                            </ListSubheader>
                          </Select>
                        </FormControl>
                        :
                        <>
                          <Tooltip
                            disableFocusListener
                            title="Enter Start Year in YYYY format"
                            placement="bottom"
                            classes={{ tooltip: classes.customWidth }}>
                            <CustomTextField
                              width={"60%"}
                              placeholder="Enter Start Year"
                              padding={"3px 5px"}
                              type="number"
                              handledetailsChange={e =>
                                setSelectedyear(e.target.value)
                              }
                            />
                          </Tooltip>
                          <Button
                            variant="contained"
                            size="medium"
                            disableElevation
                            sx={{
                              // color: "#071AFF",
                              color: "white"
                            }}
                            onClick={() => updateYear()}
                            disabled={venture.userRole === "Commenter"}>
                            ADD
                          </Button>
                        </>
                      }
                    </Box>

                    <IconButton
                      component="span"
                      onClick={() => {

                        setIsexpand(!isexpand);
                      
                      }}>
                      {isexpand ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
                    </IconButton>
                  </Box>
                </Box>
                {/* finance table section */}

                {/* <FinanceTable /> */}

                <div
                  className="tribe-pl-table-scrollable-container tribe-ploutlook-table-con"
                  ref={containerRef}
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                >
                  <PlTable
                    table={table}
                    handleChange={handleChange}
                    financials={financials}
                    handleChangeRowHeaders={handleChangeRowHeaders}
                    isedit={isedit}
                    selectedindex={selectedindex}
                    setIsedit={setIsedit}
                    setSelectedindex={setSelectedindex}
                  />
                </div>

                <Dialog
                  open={currencyDialog}
                  PaperProps={{
                    style: {
                      borderRadius  : "20px",
                      backdropFilter: "blur(45px)",
                      background    : "#FFFFFF 0% 0% no-repeat padding-box",
                      boxShadow     : "0px 3px 6px #00000029",
                      border        : "1px solid #707070"
                    }
                  }}
                // BackdropProps={{ invisible: true }}
                >
                  <DialogContent>
                    <Box sx={{ textAlign: "center" }}>
                      <Typography gutterBottom>
                        Please select <b>‘Currency’</b> to begin the planning process.
                      </Typography>
                      <Button
                        variant="contained"
                        size="medium"
                        sx={{
                          backgroundColor: "#071AFF",
                          marginLeft     : "10px"
                        }}
                        onClick={() => {

                          currencyDialogclose();
                        
                        }}>
                        OK
                      </Button>
                    </Box>
                  </DialogContent>
                </Dialog>
                <hr></hr>
                <Typography variant="body2" id={styles.typography3}>
                  Download any of the below pre-defined templates to plan your
                  revenues and expenses
                </Typography>
                <Grid container sx={{ mb: 10 }}>
                  <Grid
                    xs={3}
                    sx={{
                      display       : "flex",
                      justifyContent: "center"
                    }}>
                    <Box
                      sx={{
                        width         : 190,
                        height        : 178,
                        textDecoration: "none"
                      }}
                      component={venture.userRole === "Commenter" ? "div" : "a"}
                      href={`${process.env.REACT_APP_AWS_ASSETS_URL}/download/RevenuePlanTEMPLATE.xlsx`}
                      download>
                      <Paper elevation={3}>
                        <Box
                          sx={{
                            display       : "flex",
                            justifyContent: "flex-end"
                          }}>
                          <img
                            src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/cloudDownloadIcon.png`}
                            alt=""
                            style={{ margin: "5px" }}
                          />
                        </Box>

                        <Grid
                          item
                          xs={12}
                          justifyContent="space-around"
                          align="center"
                          justify="center"
                          alignItems="center"
                          sx={{ marginTop: "7px", marginBottom: "5px" }}>
                          <img
                            src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/revenues&ExpIcon.jpg`}
                            alt=""
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            textAlign: "center",
                            padding  : "5px"
                          }}>
                          <Typography variant="body2" id={styles.typography3}>
                            Generic Revenue Plan
                          </Typography>
                        </Grid>
                      </Paper>
                    </Box>
                  </Grid>
                  <Grid
                    xs={3}
                    sx={{
                      display       : "flex",
                      justifyContent: "center"
                    }}>
                    <Box
                      sx={{
                        width         : 190,
                        height        : 178,
                        textDecoration: "none"
                      }}
                      component={venture.userRole === "Commenter" ? "div" : "a"}
                      href={`${process.env.REACT_APP_AWS_ASSETS_URL}/download/B2BRevenuePlanAccountwiseTEMPLATE.xlsx`}
                      download>
                      <Paper elevation={3}>
                        <Box
                          sx={{
                            display       : "flex",
                            justifyContent: "flex-end"
                          }}>
                          <img
                            src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/cloudDownloadIcon.png`}
                            alt=""
                            style={{ margin: "5px" }}
                          />
                        </Box>
                        <Grid
                          item
                          xs={12}
                          justifyContent="space-around"
                          align="center"
                          justify="center"
                          alignItems="center"
                          sx={{ marginTop: "7px", marginBottom: "5px" }}>
                          <img
                            src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/revenues&ExpIcon.jpg`}
                            alt=""
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            textAlign: "center",
                            padding  : "5px"
                          }}>
                          <Typography variant="body2" id={styles.typography3}>
                            B2B Revenue Plan
                          </Typography>
                        </Grid>
                      </Paper>
                    </Box>
                  </Grid>
                  <Grid
                    xs={3}
                    sx={{
                      display       : "flex",
                      justifyContent: "center"
                    }}>
                    <Box
                      sx={{
                        width         : 190,
                        height        : 178,
                        textDecoration: "none"
                      }}
                      component={venture.userRole === "Commenter" ? "div" : "a"}
                      href={`${process.env.REACT_APP_AWS_ASSETS_URL}/download/RevenuePlanSAASSubscriptionsTEMPLATE.xlsx`}
                      download>
                      <Paper elevation={3}>
                        <Box
                          sx={{
                            display       : "flex",
                            justifyContent: "flex-end"
                          }}>
                          <img
                            src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/cloudDownloadIcon.png`}
                            alt=""
                            style={{ margin: "5px" }}
                          />
                        </Box>

                        <Grid
                          item
                          xs={12}
                          justifyContent="space-around"
                          align="center"
                          justify="center"
                          alignItems="center"
                          sx={{ marginTop: "7px", marginBottom: "5px" }}>
                          <img
                            src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/revenues&ExpIcon.jpg`}
                            alt=""
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            textAlign: "center",
                            padding  : "5px"
                          }}>
                          <Typography variant="body2" id={styles.typography3}>
                            SAAS Revenue Plan
                          </Typography>
                        </Grid>
                      </Paper>
                    </Box>
                  </Grid>
                  <Grid
                    xs={3}
                    sx={{
                      display       : "flex",
                      justifyContent: "center"
                    }}>
                    <Box
                      sx={{
                        width         : 190,
                        height        : 178,
                        textDecoration: "none"
                      }}
                      component={venture.userRole === "Commenter" ? "div" : "a"}
                      href={`${process.env.REACT_APP_AWS_ASSETS_URL}/download/ExpensesPlanTEMPLATE.xlsx`}
                      download>
                      <Paper elevation={3}>
                        <Box
                          sx={{
                            display       : "flex",
                            justifyContent: "flex-end"
                          }}>
                          <img
                            src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/cloudDownloadIcon.png`}
                            alt=""
                            style={{ margin: "5px" }}
                          />
                        </Box>

                        <Grid
                          item
                          xs={12}
                          justifyContent="space-around"
                          align="center"
                          justify="center"
                          alignItems="center"
                          sx={{ marginTop: "7px", marginBottom: "5px" }}>
                          <img
                            src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/revenues&ExpIcon.jpg`}
                            alt=""
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            textAlign: "center",
                            padding  : "5px"
                          }}>
                          <Typography variant="body2" id={styles.typography3}>
                            Expenses
                          </Typography>
                        </Grid>
                      </Paper>
                    </Box>
                  </Grid>
                </Grid>

                {/* upload file code goes here */}
                <Box>
                  <Box>
                    <Typography variant="body2" id={styles.typography3}>
                      Upload File
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      textAlign      : "center",
                      border         : "3px dashed #eeeeee",
                      backgroundColor: "#fafafa",
                      color          : "#bdbdbd",
                      marginRight    : "30px",
                      cursor:
                        venture.userRole === "Commenter" ? "default" : "pointer",
                      marginBottom: "10px"
                    }}
                    onClick={() => {

                      if (venture.userRole === "Commenter") return;

                      excels.length < 4
                        ? history.push("/financeexcelsupload")
                        : setErrorMessage(
                          "Please delete a file below and then upload a new file"
                        );
                      setTimeout(() => {

                        setErrorMessage("");
                      
                      }, 3000);
                    
                    }}>
                    <Box
                      sx={{
                        display       : "flex",
                        justifyContent: "center",
                        alignItems    : "center"
                      }}>
                      <BackupIcon sx={{ color: "blue", margin: "5px" }} />

                      <Typography variant="body2" sx={{ margin: "5px" }}>
                        <p>Drag & Drop files here</p>
                      </Typography>
                    </Box>
                  </Box>
                  {errormessage && <ErrorMessage errormsg={errormessage} />}
                  <Grid container mb={1}>
                    {excels.map((excel, index) => {

                      return <div key={index}>
                        <IndividualExcel
                          excel={excel}
                          index={index}
                          removeExcel={removeExcel}
                        />
                      </div>;
                    
                    })}
                  </Grid>
                </Box>

              </>}

              {/* back and next button code */}
            </>
          }
        </Grid>
        <div className="ttribe-back-next-button-con">
          {loading ?
            <></>
            :
            <BackNextButton
              restrictedBackClick={() => {

                history.push("/financialsintro");
              
              }}
              backClick={() => {

                history.push("/financialsintro");
              
              }}
              restrictedNextClick={() => {

                history.push("/fundingrunway");
              
              }}
              nextClick={() => {

                if (Object.keys(currentploutlook).length !== 0) {

                  handleSubmit();
                
                } else {

                  history.push("/fundingrunway");
                
                }
              
              }}
            />
          }
        </div>
      </div>
    </ModuleSections>
  );

};

export default PlOutlook;
