import axios from "./axiosService";

// import axios from "axios";

export const getGtm = async gtmid => {

  return await axios.post(`${process.env.REACT_APP_ADDRESS}/get_gtm`, {
    gtmid
  });

};

export const updateGtmPercent = async (gtmid, modulename, percentvalue) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/update_gtm_percent`,
    { gtmid, modulename, percentvalue }
  );

};

export const updateGtmValues = async (gtmid, modulename, value) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/update_gtm_values`,
    { gtmid, modulename, value }
  );

};

export const updateGtmPositioningValues = async (
  gtmid,
  modulename,
  details
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/update_gtmpositioning_values`,
    { gtmid, modulename, details }
  );

};

export const updateGtmProductPositioningValues = async (
  gtmid,
  sectionname,
  details
) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/update_gtmproductpositioning_values`,
    { gtmid, sectionname, details }
  );

};
