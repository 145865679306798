import { Grid, Typography } from "@mui/material";
import React from "react";
import HelpIconTooltip from "../../../../components/HelpIconTooltip";

import styles from "./MarketFactsFigures.module.css";

import CustomMultilineTextfield from "../../../../components/CustomMultilineTextfield";

const FinalPage = props => {

  const content = props.content;
  const details = props.details;

  return (
    <Grid item xs={12} sx={{ padding: "0px 60px 0px", overflowY: "auto" }}>
      <Grid container sx={{ height: "46vh" }} columnGap={12}>
        {content.questions.map(data => {

          return (
            <Grid mb={1} item md={5}>
              <Typography id={styles.finaltypography}>
                {data.question}
                <HelpIconTooltip tooltipdata={data.tooltip} />
              </Typography>

              <CustomMultilineTextfield
                width={"100%"}
                name={data.name}
                value={details[content.subName][data.name]}
                handledetailsChange={props.handledetailsChange}
                padding={"6px 5px"}
                maxRows={2}
                minRows={2}
                fontSize={"13px"}
                border={{ borderTop: "none" }}
              />
            </Grid>
          );
        
        })}
      </Grid>
    </Grid>
  );

};

export default FinalPage;
