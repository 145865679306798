import {
  Box, Button, TextField, Typography
} from "@mui/material";
import React, { useRef } from "react";
import ErrorMessage from "../../../../components/ErrorMessage";
import ImageOptions from "../ImageOptions";
import SectionHeader from "../SectionHeader";
import styles from "./TheProduct.module.css";

export default function TheProduct2({
  logoimg,
  slides,
  isRender,
  setSlides,
  productImgOne,
  productImgTwo,
  uploadImages1,
  uploadImages2,
  deleteUploadedImgs,
  isPublished,
  ...props
}) {

  const slide = slides[isRender];

  const handleChange = (name, value, columnindex) => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;

    let newproducthighlights = [ ...newSlides[isRender]["productHighlightsTwo"] ];

    newproducthighlights.map((productHighlight, productHighlightIndex) => {

      if (productHighlightIndex === columnindex) {

        newproducthighlights[productHighlightIndex][name] = value;
      
      }
      
      return productHighlight;
    
    });
    newSlides[isRender]["productHighlightsTwo"] = newproducthighlights;

    setSlides(newSlides);
  
  };

  const handleDetailsChange = (name, value) => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    newSlides[isRender][name] = value;
    setSlides(newSlides);
  
  };

  // for uploading productImg
  const productImgOneFileInput = useRef(null);

  const productImgOnehandleClick = () => {

    productImgOneFileInput.current.click();
    props.setErrormsg("");
  
  };

  const productImgTwoFileInput = useRef(null);

  const productImgTwohandleClick = () => {

    productImgTwoFileInput.current.click();
    props.setErrormsg2("");
  
  };

  return (
    <Box>
      <SectionHeader
        isEdit={props.isEdit}
        Collapsecomment={props.Collapsecomment}
        collapseCommentFunction={props.collapseCommentFunction}
        mainHead={slide.name}
        handleTitleChange={handleDetailsChange}
        logoimg={logoimg}
        isPublished={isPublished}
      />
      <div style={{ display: "flex", flexDirection: "column", rowGap: "3vh", marginTop: "3vh" }}>
        <Box
          id={styles.highlightMainBox}
        >
          {/* ----1---- */}
          {slide.productHighlightsTwo.map(
            (productHighlight, productHighlightIndex) => {

              return (
                <Box
                  id={styles.productHighlight}
                >
                  {props.isEdit ?
                    <Box sx={{ marginBottom: "4px" }}>
                      <TextField
                        value={productHighlight.name}
                        onChange={e => {

                          handleChange(
                            "name",
                            e.target.value,
                            productHighlightIndex
                          );
                        
                        }}
                        inputProps={{ maxLength: 25 }}
                        fullWidth
                        sx={{
                          "& .MuiInputBase-input": {
                            fontSize  : "16px",
                            padding   : "1px 8px",
                            border    : "none",
                            color     : "#393939",
                            fontWeight: "bold"
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "2px dotted #B4B4FA"
                          }
                        }}
                      />
                    </Box>
                    :
                    <Box
                      id={styles.highlightTypoBox}
                    >
                      <Typography
                        id={styles.highlightTypo}
                      >
                        {productHighlight.name}
                      </Typography>
                    </Box>
                  }

                  {props.isEdit ?
                    <TextField
                      value={productHighlight.value}
                      onChange={e => {

                        handleChange(
                          "value",
                          e.target.value,
                          productHighlightIndex
                        );
                      
                      }}
                      inputProps={{ maxLength: 150 }}
                      fullWidth
                      multiline
                      maxRows={4}
                      minRows={4}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          padding: "0px 3px",
                          border : "none"
                        },
                        "& .MuiInputBase-input": {
                          fontSize  : "12px",
                          color     : "#707070",
                          lineHeight: "20px",
                          whiteSpace: "pre-line"
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border      : "2px dotted #B4B4FA",
                          borderRadius: "7px"
                        }
                      }}
                    />
                    :

                    <Box
                      sx={{
                        width       : "100%",
                        border      : "1px solid #BEBEBE",
                        height      : "11vh",
                        borderRadius: "5px",
                        overflowY   : "auto",
                        display     : "flex",
                        alignItems  : "center"
                      }}>
                      <Box
                        sx={{
                          margin  : "5px 8px",
                          wordWrap: "break-word"
                        }}>
                        <Typography
                          sx={{
                            fontSize  : "1.5vh",
                            color     : "#707070",
                            lineHeight: "1.5",
                            whiteSpace: "pre-line"
                          }}>
                          {productHighlight.value}
                        </Typography>
                      </Box>
                    </Box>
                  }
                </Box>
              );
            
            }
          )}
        </Box>
        {/* -----=== */}
        <Box
          id={styles.imgMainBox}
        >
          <Box
            id={styles.Img}
          >
            {/* replace image box */}
            {props.isEdit ?
              <>
                {productImgOne?.imgUrl || slide.productImageOne ?
                  <Box
                    sx={{
                      display       : "flex",
                      justifyContent: "center",
                      alignItems    : "center",
                      width         : "100%",
                      height        : "100%"
                    }}>
                    <img
                      src={
                        productImgOne?.imgUrl
                          ? productImgOne?.imgUrl
                          : slide.productImageOne
                            ? slide.productImageOne
                            : productImgOne?.imgUrl
                      }
                      alt="productImgOne"
                      style={{
                        width    : "100%",
                        height   : "100%",
                        objectFit: "cover"
                      }}
                    />
                    <Box
                      sx={{
                        position       : "absolute",
                        right          : "1px",
                        top            : "1px",
                        display        : "flex",
                        justifyContent : "space-evenly",
                        alignItems     : "center",
                        backgroundColor: "#ffffff",
                        opacity        : "0.5"
                        /* border-radius: 5px; */
                      }}>
                      <ImageOptions
                        IconSize="22px"
                        handleClick={productImgOnehandleClick}
                        image={productImgOne?.imgUrl || slide.productImageOne}
                        deleteUploadedImgs={() => {

                          let newSlides = [ ...slides ];

                          newSlides[isRender]["isChanged"] = true;
                          setSlides(newSlides);
                          deleteUploadedImgs(
                            `${slide.renderName}.productImageOne`,
                            slide.productImageOne ? true : false
                          );
                          if (slide.productImageOne)
                            handleDetailsChange("productImageOne", "");
                        
                        }}
                      />
                    </Box>
                  </Box>
                  :
                  <Box
                    sx={{
                      width         : "100%",
                      height        : "100%",
                      display       : "flex",
                      flexDirection : "column",
                      alignItems    : "center",
                      justifyContent: "center"
                    }}>
                    {props.errorMsg && <ErrorMessage errormsg={props.errorMsg} />}
                    <Typography>Upload a product image.</Typography>
                    <Button
                      variant="contained"
                      style={{
                        fontSize     : "14px",
                        textTransform: "none",
                        // padding: "10px 19px",
                        margin       : "10px"
                      }}
                      disabled={props.isEdit ? false : true}
                      onClick={productImgOnehandleClick}>
                      Upload Image
                    </Button>
                    <Typography sx={{ fontSize: "12px" }}>
                      {"Image must be .png or .jpg format and < 10MB."}
                    </Typography>
                  </Box>
                }

                <input
                  type="file"
                  id="coverImg"
                  ref={productImgOneFileInput}
                  onChange={e => {

                    uploadImages1(
                      `${slide.renderName}.productImageOne`,
                      e.target.files[0]
                    );
                    let newSlides = [ ...slides ];

                    newSlides[isRender]["isChanged"] = true;
                    setSlides(newSlides);
                  
                  }}
                  onClick={e => {

                    e.target.value = null;
                  
                  }}
                  style={{ display: "none" }}
                  accept="image/*"
                />
              </>
              : slide.productImageOne ?
                <Box
                  sx={{
                    display       : "flex",
                    justifyContent: "center",
                    alignItems    : "center",
                    width         : "100%",
                    height        : "100%"
                  }}>
                  <img
                    src={slide.productImageOne}
                    alt="productImgOne"
                    style={{
                    // sdsdsdsd
                      width    : "100%",
                      height   : "100%",
                      objectFit: "cover"
                    }}
                  />
                </Box>
                :
                <></>
            }

            {/* ------ */}
          </Box>
          <Box
            id={styles.Img}
          >
            {/* replace image box */}
            {props.isEdit ?
              <>
                {productImgTwo?.imgUrl || slide.productImageTwo ?
                  <Box
                    sx={{
                      display       : "flex",
                      justifyContent: "center",
                      alignItems    : "center",
                      width         : "100%",
                      height        : "100%"
                    }}>
                    <img
                      src={
                        productImgTwo?.imgUrl
                          ? productImgTwo?.imgUrl
                          : slide.productImageTwo
                            ? slide.productImageTwo
                            : productImgTwo?.imgUrl
                      }
                      alt="productImgTwo"
                      style={{
                        width    : "100%",
                        height   : "100%",
                        objectFit: "cover"
                      }}
                    />
                    <Box
                      sx={{
                        position       : "absolute",
                        right          : "1px",
                        top            : "1px",
                        display        : "flex",
                        justifyContent : "space-evenly",
                        alignItems     : "center",
                        backgroundColor: "#ffffff",
                        opacity        : "0.5"
                      }}>
                      <ImageOptions
                        IconSize="22px"
                        handleClick={productImgTwohandleClick}
                        image={productImgTwo?.imgUrl || slide.productImageTwo}
                        deleteUploadedImgs={() => {

                          let newSlides = [ ...slides ];

                          newSlides[isRender]["isChanged"] = true;
                          setSlides(newSlides);
                          deleteUploadedImgs(
                            `${slide.renderName}.productImageTwo`,
                            slide.productImageTwo ? true : false
                          );
                          if (slide.productImageTwo)
                            handleDetailsChange("productImageTwo", "");
                        
                        }}
                      />
                    </Box>
                  </Box>
                  :
                  <Box
                    sx={{
                      width         : "100%",
                      height        : "100%",
                      display       : "flex",
                      flexDirection : "column",
                      alignItems    : "center",
                      justifyContent: "center"
                    }}>
                    {props.errorMsg2 && <ErrorMessage errormsg={props.errorMsg2} />}
                    <Typography>Upload a product image.</Typography>
                    <Button
                      variant="contained"
                      style={{
                        fontSize     : "14px",
                        textTransform: "none",
                        // padding: "10px 19px",
                        margin       : "10px"
                      }}
                      disabled={props.isEdit ? false : true}
                      onClick={productImgTwohandleClick}>
                      Upload Image
                    </Button>
                    <Typography sx={{ fontSize: "12px" }}>
                      {"Image must be .png or .jpg format and < 10MB."}
                    </Typography>
                  </Box>
                }

                <input
                  type="file"
                  id="coverImg"
                  ref={productImgTwoFileInput}
                  onChange={e => {

                    uploadImages2(
                      `${slide.renderName}.productImageTwo`,
                      e.target.files[0]
                    );
                    let newSlides = [ ...slides ];

                    newSlides[isRender]["isChanged"] = true;
                    setSlides(newSlides);
                  
                  }}
                  onClick={e => {

                    e.target.value = null;
                  
                  }}
                  style={{ display: "none" }}
                  accept="image/*"
                />
              </>
              : slide.productImageTwo ?
                <Box
                  sx={{
                    display       : "flex",
                    justifyContent: "center",
                    alignItems    : "center",
                    width         : "100%",
                    height        : "100%"
                  }}>
                  <img
                    src={slide.productImageTwo}
                    alt="productImgTwo"
                    style={{
                      width    : "100%",
                      height   : "100%",
                      objectFit: "cover"
                    }}
                  />
                </Box>
                :
                <></>
            }

            {/* ----- */}
          </Box>
        </Box>
      </div>
    </Box>
  );

}
