import React, { useState, useEffect } from "react";
import { Grid, Button, Dialog, DialogContent, Box, IconButton } from "@mui/material";
import DataTable from "react-data-table-component";
import Checkbox from "@mui/material/Checkbox";

import Img from "../../../../Assets/icons/images/Team.svg";
import ApexChart from "../../Operations/Team/TeamChart";

const customStyles = {

  headRow: {
    style: {
      background  : "#ece8f7",
      fontSize    : "1.375em",
      borderRadius: "10px",
      minHeight   : "6vh"

    }
  },
  headCells: {
    style: {
      fontSize  : "1.8vh",
      fontWeight: 500,
      color     : "#000000de"
    }
  },
  rows: {
    style: {
      minHeight: "6vh",
      fontSize : "1.6vh"
    }
  }

};


const Team = props => {

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [ teamData, setTeamData ] = useState();
  const [ chartData, setChartdata ] = useState([]);
  const [ chartLabel, setChartlabel ] = useState([]);
  const [ sharePercentagechartDialog, setsharePercentageChartDialog ] = useState(false);
  const [ teamDistributionchartDialog, setteamDistributionChartDialog ] = useState(false);

  useEffect(() => {

    const data1 = props?.teamsData;

    setTeamData(data1);

  }, []);

  const columns = [
    {
      id      : "name",
      name    : "Name",
      selector: row => { return row.name; },
      sortable: true
      // center: true
    },
    {
      id      : "toBeHired",
      name    : "To Be Hired",
      selector: row => { return row.toBeHired ? <Checkbox {...label} disabled defaultChecked size="small" /> : <Checkbox {...label} disabled size="small" />; },
      sortable: true,
      center  : true
    },
    {
      id      : "type",
      name    : "Type",
      selector: row => { return row.type; },
      sortable: true
      // center: true
    },
    {
      id      : "title",
      name    : "Title",
      selector: row => row.title,
      sortable: true
      // center: true
    },
    {
      id      : "expertise",
      name    : "Expertise",
      selector: row => row.expertise,
      sortable: true
      // center: true
    },
    {
      id      : "shareholder",
      name    : "Shareholder",
      selector: row => { return row.shareholder ? <Checkbox {...label} disabled defaultChecked size="small" /> : <Checkbox {...label} disabled size="small" />; },
      sortable: true,
      center  : true
    },
    {
      id      : "sharePercentage",
      name    : "Share Percentage",
      selector: row => row.sharePercentage,
      sortable: true,
      center  : true
    }
    // {
    //     id: "otherDetails",
    //     name: "Other Details",
    //     selector: (row) => row.otherDetails,
    //     sortable: true
    //     // center: true
    // },
    // {
    //     id: "linkedinProfile",
    //     name: "Linkedin Profile",
    //     selector: (row) => row.linkedinProfile,
    //     sortable: true
    //     // center: true
    // }
  ];

  const sharePercentageChartDialogopen = () => {

    setsharePercentageChartDialog(true);
  
  };
  const sharePercentageChartDialogclose = () => {

    setsharePercentageChartDialog(false);
  
  };

  const teamDistributionChartDialogopen = () => {

    setteamDistributionChartDialog(true);
  
  };
  const teamDistributionChartDialogclose = () => {

    setteamDistributionChartDialog(false);
  
  };

  const generateSharePercentageChartData = () => {

    let data = [];
    let label = [];
    let totalPercent = 0;

    teamData.map(member => {

      if (member.shareholder) {

        totalPercent += member.sharePercentage * 1;
        data.push(member.sharePercentage * 1);
        label.push(member.name);
      
      }
    
    });
    data.push((100 - totalPercent) * 1);
    label.push("Remaining share %");
    setChartdata(data);
    setChartlabel(label);
  
  };

  const generateTeamDistributionChartData = () => {

    let data = [];
    let types = [
      "Founder",
      "Advisor",
      "Employee",
      "Investor",
      "Board",
      "Vendor",
      "Contractor",
      "Consultant",
      "Management"
    ];

    types.map(type => {

      let count = 0;

      teamData.map(member => {

        if (type === member.type) {

          count += 1;
        
        }
      
      });
      data.push(count);
    
    });
    setChartdata(data);
    setChartlabel(types);
  
  };

  return <div className="tribe-summary-venture-profile-main-con" >
    <Grid className="tribe-summary-venture-profile-con" container spacing={2}>
      <Grid className="tribe-summary-venture-img-con" xs={4}>
        <Button onClick={() => { generateTeamDistributionChartData(); teamDistributionChartDialogopen(); }} className="tribe-summary-view-chart">
          <span>Team Distribution</span>
        </Button>
        <Button onClick={() => { generateSharePercentageChartData(); sharePercentageChartDialogopen(); }} className="tribe-summary-view-chart">
          <span>Shareholder Distribution</span>
        </Button>
        <div>
          <img className="tribe-summary-img" src={Img} alt="" />
        </div>
      </Grid>
      <Grid xs={8}>
        <div className="tribe-summary-milestones-table-con">
          <DataTable className="tribe-milestons-table" data={teamData || []} defaultSortAsc={false} defaultSortFieldId="name" striped customStyles={customStyles} columns={columns} pagination fixedHeader />
        </div>
      </Grid>
    </Grid>
    <Dialog
      open={teamDistributionchartDialog}
      onClose={teamDistributionChartDialogclose}
      PaperProps={{
        style: {
          borderRadius   : "20px",
          backdropFilter : "blur(100px)",
          backgroundColor: "white",
          boxShadow      : "0px 3px 6px #00000029",
          border         : "1px solid #b8bddb"
        }
      }}
      BackdropProps={{ invisible: true }}
      maxWidth="md"
      fullWidth>
      <Box sx={{ alignSelf: "flex-end" }}>
        <IconButton
          component="span"
          onClick={teamDistributionChartDialogclose}>
          {/* <HighlightOffIcon /> */}
        </IconButton>
      </Box>
      <DialogContent>
        <ApexChart
          chartData={chartData}
          chartLabel={chartLabel}
          name={"Team Distribution"}
        />
      </DialogContent>
    </Dialog>
    <Dialog
      open={sharePercentagechartDialog}
      onClose={sharePercentageChartDialogclose}
      PaperProps={{
        style: {
          borderRadius   : "20px",
          backdropFilter : "blur(100px)",
          backgroundColor: "white",
          boxShadow      : "0px 3px 6px #00000029",
          border         : "1px solid #b8bddb"
        }
      }}
      BackdropProps={{ invisible: true }}
      maxWidth="md"
      fullWidth>
      <Box sx={{ alignSelf: "flex-end" }}>
        <IconButton
          component="span"
          onClick={sharePercentageChartDialogclose}>
          {/* <HighlightOffIcon /> */}
        </IconButton>
      </Box>
      <DialogContent>
        <ApexChart
          chartData={chartData}
          chartLabel={chartLabel}
          name={"Shareholder Distribution"}
        />
      </DialogContent>
    </Dialog>

  </div>;

};

export default Team;