/* eslint-disable camelcase */
import {
  Box, Button, Dialog,
  DialogContent, Grid, IconButton, Typography
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { currencies } from "../../../../components/Currencies";
import CustomSelect from "../../../../components/CustomSelect";
import CustomTextfield from "../../../../components/CustomTextfield";

// icons

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

import DeleteCancel from "../../../../components/DeleteCancel";
import HelpIconTooltip from "../../../../components/HelpIconTooltip";
import ModuleSections from "../../../../components/ModuleSections";
import jsondata from "../../../../Data/Market.json";

import styles from "./MarketSize.module.css";

import { makeStyles } from "@mui/styles";
import BackButton from "../../../../components/BackButton";
import BackNextButton from "../../../../components/BackNextButton";
import UploadFile from "./UploadFile";

import DeleteIcon from "@mui/icons-material/Delete";
// import InputBase from "@mui/material/InputBase";
// import { styled } from "@mui/material/styles";
import CustomMultilineTextfield from "../../../../components/CustomMultilineTextfield";
import { useUnmount } from "../../../../components/useUnmount";
import {
  createMarketComment,
  deleteMarketComment,
  updateMarketComment
} from "../../../../services/comment";
import {
  deleteMarketExcel,
  getMarketdetails,
  updateMarketSizeDetails
} from "../../../../services/market";
import CustomChart from "./CustomChart";

const useStyles = makeStyles(() => ({
  customWidth: {
    // width: 200,
    fontSize       : 10,
    backgroundColor: "white",
    color          : "#707070",
    boxShadow      : "0px 3px 6px #00000029",
    border         : "1px solid #707070",
    borderRadius   : 10
  },
  input: {
    height         : 30,
    backgroundColor: "#ffffff",
    boxShadow      : "0px 3px 6px #00000029",
    borderRadius   : 3
  },
  textarea: {
    backgroundColor: "#ffffff",
    boxShadow      : "0px 3px 6px #00000029",
    borderRadius   : 3
  }
}));

const MarketSize = ({ history }) => {

  const [ details, setDetails ] = useState({
    currency                  : "",
    totalAddressableMarket    : 0,
    segmentedAddressableMarket: 0,
    ourExpectedMarketShare    : 0,
    timeHorizon               : "",
    keyAssumptions            : ""
  });
  const [ comments, setComments ] = useState([]);
  const [ excel, setExcel ] = useState("");
  const [ excelname, setExcelname ] = useState("");
  const [ dialogbox, setDialog ] = useState(false);
  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const [ deletedialog, setDeletedialog ] = useState(false);
  const [ isSuccess, setIssuccess ] = useState(false);
  const [ errorDialog, setErrorDialog ] = useState(false);
  const [ isDataLoading, setIsDataLoading ] = useState(true);
  
  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);
    
    } else {

      setCollapsecomment(true);
    
    }
  
  };

  const data = jsondata.marketsize;

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  const errorDialogopen = () => {

    setErrorDialog(true);
  
  };
  const errorDialogclose = () => {

    setErrorDialog(false);
  
  };

  const classes = useStyles();

  const { user, venture } = useSelector(state => ({ ...state }));
  // console.log("user from welcome", user);

  const hashvalue = history.location.hash.substring(1);

  useEffect(() => {

    fetchMarketdetails();
  
  }, []);

  // To get the updated usestate value to save in useEffect cleanup
  const detailsRef = useRef(details);

  detailsRef.current = details;

  let isSubmitting = false;

  // useUnmount => custom hook to save while navigating
  useUnmount(() => {

    if ( isDataLoading == false && !isSubmitting) {

      handleSave();
    
    }
  
  });

  const handleSave = async () => {

    try {

      await updateMarketSizeDetails(detailsRef.current, venture.market);
      
      return true;
    
    } catch (error) {

      console.error(error);
      
      return false;
    
    }
  
  };

  const fetchMarketdetails = async () => {

    setIsDataLoading(true);

    try {

      const response = await getMarketdetails(venture.market);
      const market = response.data.market;

      setDetails(market.size);
      setExcel(response.data.excel);
      setExcelname(response.data.excelname);
      setComments(market.comments.size);
      setIsDataLoading(false);
    
    } catch (error) {

      console.error(error);
      setIsDataLoading(false);
    
    }
  
  };

  const removeMarketExcel = async () => {

    await deleteMarketExcel(venture.market);
    fetchMarketdetails();
  
  };

  const handledetailsChange = (name, value) => {

    setDetails(values => ({ ...values, [name]: value }));
  
  };

  const handleSubmit = async () => {

    try {

      isSubmitting = true;
      if(isDataLoading == false){

        await updateMarketSizeDetails(details, venture.market);

      }

      isSubmitting = false;
    
    } catch (error) {

      isSubmitting = false;
      console.error(error);
    
    }
  
  };

  const numberWithCommas = num => {

    if (details.currency) {

      let newNum = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      
      return `${details.currency.split(" ")[0].trim()} ${newNum}`;
    
    } else {

      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    
    }
  
  };

  const removeNonNumeric = num => num.toString().replace(/[^0-9]/g, "");

  const addComment = async (commentText, replyTo) => {

    try {

      const response = await createMarketComment(
        commentText,
        user._id,
        "size",
        "",
        venture.market,
        replyTo,
        window.location.href,
        "Market > Size"
      );

      setComments(response.data.comments.size);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateComment = async (commentid, commentText) => {

    try {

      const response = await updateMarketComment(
        commentText,
        user._id,
        commentid,
        venture._id,
        window.location.href,
        "Market > Size"
      );

      setComments(response.data.comments.size);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const deleteComment = async commentid => {

    try {

      const response = await deleteMarketComment(
        "size",
        "",
        venture.market,
        commentid,
        user._id,
        window.location.href,
        "Market > Size"
      );

      setComments(response.data.comments.size);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  // console.log(details);

  const currencyOptions = currencies.map(currency => ({
    value: `${currency.symbol} ${currency.code}`,
    label: `${currency.symbol} ${currency.code}`
  }));

  return (
    <ModuleSections
      Collapsecomment={ Collapsecomment }
      comments={ comments }
      addComment={ addComment }
      deleteComment={ deleteComment }
      updateComment={ updateComment }
    // handleSave={async () => {
    //   await updateMarketSizeDetails(details, venture.market);
    // }}
    >
      <div className="box-display-style-for-next-and-back-button-with-justify">
        <div className="box-indiv-header-con">
          <Box id={ styles.box2 }>
            <Box sx={ { display: "flex", alignItems: "center" } }>
              <Typography
                sx={ { fontWeight: "bold", fontSize: "20px", color: "#5F5E5E" } }>
                Market
              </Typography>
              <ChevronRightIcon sx={ { color: "#707070", margin: "0px 10px" } } />
              <Typography sx={ { fontSize: "16px", color: "#868686" } }>
                Market Size
              </Typography>
            </Box>
            <Box sx={ { display: "flex", alignItems: "center" } }>
              { !hashvalue &&
                <Box sx={ { marginRight: "10px", position: "relative" } }>
                  { isSuccess &&
                    <Box
                      sx={ { position: "absolute", top: "32px", left: "10px" } }>
                      <Typography sx={ { color: "#08910B", fontSize: "14px" } }>
                        Saved
                      </Typography>
                    </Box>
                  }
                  <Button
                    variant="contained"
                    color="primary"
                    sx={ { padding: "3px 12px", textTransform: "capitalize" } }
                    onClick={ async () => {

                      const isSaved = await handleSave();

                      if (isSaved) {

                        setIssuccess(true);
                        setTimeout(() => {

                          setIssuccess(false);
                        
                        }, 3000);
                      
                      }
                    
                    } }
                    disabled={ venture.userRole === "Commenter" }>
                    Save
                  </Button>
                </Box>
              }
              <Tooltip enterTouchDelay={ 0 } title="View Guide">
                <img
                  src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/post_add-24px.png` }
                  alt=""
                  style={ {
                    marginRight: "1vw",
                    cursor     : "pointer"
                  } }
                  onClick={ Dialogopen }
                />
              </Tooltip>
              <Dialog
                open={ dialogbox }
                PaperProps={ {
                  style: {
                    borderRadius   : "20px",
                    backdropFilter : "blur(100px)",
                    backgroundColor: "initial",
                    boxShadow      : "0px 3px 6px #00000029",
                    border         : "1px solid #707070"
                  }
                } }
                BackdropProps={ { invisible: true } }
                maxWidth="md"
                fullWidth>
                <Box sx={ { alignSelf: "flex-end" } }>
                  <IconButton component="span" onClick={ Dialogclose }>
                    <HighlightOffIcon />
                  </IconButton>
                </Box>

                <DialogContent>
                  <Typography fontWeight="900">
                    What are we doing here? Why? What's the Purpose?
                  </Typography>
                  <Typography gutterBottom>{ data.dialogpurpose }</Typography>
                  <Typography fontWeight="900">
                    How to proceed with this section:
                  </Typography>
                  <Typography gutterBottom>{ data.dialogeproceed }</Typography>
                </DialogContent>
              </Dialog>
              <img
                alt=""
                src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/Icon awesome-comment-dots.png` }
                style={ {
                  cursor: "pointer"
                } }
                onClick={ () => collapse_comment_function() }
              />
            </Box>
          </Box>
          { hashvalue !== "upload" ?
            <Typography variant="body2" id={ styles.typography2 }>
              {/* Estimate market potential of your solution (# of Units + Value) */ }
              How big is the market opportunity for your solution? Use our
              calculator (or your own) to estimate how much value there really
              is for your venture to capture.
            </Typography>
            :
            <></>
          }
        </div>
        <Grid item xs={ 12 } id={ styles.maingrid }>
          { hashvalue === "upload" ?
            <UploadFile fetchMarketdetails={ fetchMarketdetails } />
            :
            <>
              {/* ---------------------------- */ }
              <Grid container id={ styles.grid1 }>
                <Box sx={ { display: "flex" } }>
                  {/* <a
                  href={`${process.env.REACT_APP_AWS_ASSETS_URL}/download/TodacTribeApp_MarketSizeCalculator.xlsx`}
                  download
                  style={{ textDecoration: "none" }}
        >*/}
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    id={ styles.downloadbtn }
                    disabled={ venture.userRole === "Commenter" }
                    download
                    href={ `${process.env.REACT_APP_AWS_ASSETS_URL}/download/TodacTribeApp_MarketSizeCalculator.xlsx` }>
                    Download Calculator{ " " }
                    <FileDownloadOutlinedIcon id={ styles.icon } />
                  </Button>
                  {/* </a>*/ }
                  <Button
                    variant="outlined"
                    size="medium"
                    disabled={ venture.userRole === "Commenter" }
                    sx={ {
                      backgroundColor:
                        venture.userRole === "Commenter"
                          ? "#b0b0b0"
                          : "#f0f0f0",
                      border:
                        venture.userRole === "Commenter"
                          ? "none"
                          : "1px solid #071aff",
                      borderRadius: "8px",
                      color:
                        venture.userRole === "Commenter"
                          ? "#b0b0b0"
                          : "#071aff",
                      boxShadow    : "none",
                      textTransform: "none",
                      height       : "35px",
                      fontSize     : "10px"
                    } }
                    onClick={ () => history.push({ hash: "upload" }) }>
                    Upload your Calculations{ " " }
                    <FileUploadOutlinedIcon id={ styles.icon } fontSize="small" />
                  </Button>
                  <HelpIconTooltip
                    tooltipdata={
                      "For easy reference, you may upload a file with your calculations here.(Limit 1 file. Uploading a new file will replace the old one.)"
                    }
                  />
                  { excel ?
                    venture.userRole !== "Commenter" ?
                      <>
                        <span className={ styles.divider1 } />
                        <Box
                          component="a"
                          sx={ {
                            display            : "inline-flex",
                            textDecorationColor: "#707070",
                            alignItems         : "center"
                          } }
                          href={ excel }>
                          <InsertDriveFileIcon
                            fontSize="small"
                            sx={ { color: "#707070", marginRight: "5px" } }
                          />
                          <Typography>{ excelname }</Typography>
                          <FileDownloadOutlinedIcon
                            fontSize="small"
                            id={ styles.downloadicon }
                          />
                        </Box>
                        <IconButton
                          onClick={ () => {

                            setDeletedialog(true);
                          
                          } }>
                          <DeleteIcon
                            fontSize="small"
                            sx={ { color: "#707070" } }
                          />
                        </IconButton>
                        <Dialog
                          open={ deletedialog }
                          PaperProps={ {
                            style: {
                              borderRadius  : "20px",
                              backdropFilter: "blur(45px)",
                              background    : "#FFFFFF 0% 0% no-repeat padding-box",
                              boxShadow     : "0px 3px 6px #00000029",
                              border        : "1px solid #E0E1E2"
                            }
                          } }>
                          <DialogContent>
                            <DeleteCancel
                              name={
                                "Are you sure you want to delete this file?"
                              }
                              deleteClick={ () => {

                                removeMarketExcel();
                                setDeletedialog(false);
                              
                              } }
                              cancelClick={ () => {

                                setDeletedialog(false);
                              
                              } }
                            />
                          </DialogContent>
                        </Dialog>
                      </>
                      :
                      <>
                        <span className={ styles.divider1 } />
                        <Box
                          sx={ {
                            display            : "inline-flex",
                            textDecorationColor: "#707070",
                            alignItems         : "center"
                          } }>
                          <InsertDriveFileIcon
                            fontSize="small"
                            sx={ { color: "#707070", marginRight: "5px" } }
                          />
                          <Typography>{ excelname }</Typography>
                          <FileDownloadOutlinedIcon
                            fontSize="small"
                            id={ styles.downloadicon }
                          />
                        </Box>
                      </>
                    
                    :
                    <></>
                  }
                </Box>

                <Box id={ styles.midContentBox }>
                  {/* ------currency dropdown box */ }
                  <Box id={ styles.currencyDropdownBox }>
                    <Box id={ styles.currencyTypoBox }>
                      <Typography id={ styles.currencyTypo }>
                        Select currency
                        <HelpIconTooltip
                          tooltipdata={
                            "Note: Choosing a currency here will only change the symbol on your market size numbers below. It will not do currency conversion. Please review your numbers if you change your currency setting."
                          }
                        />
                      </Typography>
                    </Box>
                    <Box>
                      {/* <FormControl variant="standard">
                      <Select
                        input={<BootstrapInputCurrency />}
                        displayEmpty
                        renderValue={
                          details.currency !== ""
                            ? undefined
                            : () => (
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                    marginTop: "3px",
                                    color: "#B8B8B8",
                                  }}
                                >
                                  Currency
                                </Typography>
                              )
                        }
                        value={details.currency}
                        name="currency"
                        onChange={(e) => {
                          handledetailsChange(e.target.name, e.target.value);
                        }}
                      >
                        {currencies.map((currency) => {
                          return (
                            <MenuItem
                              value={`${currency.symbol} ${currency.code}`}
                            >
                              {currency.symbol} {currency.code}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl> */}
                      <CustomSelect
                        placeholder={ "Currency" }
                        fontSize={ "14px" }
                        value={ details.currency }
                        name="currency"
                        handleChange={ e => {

                          handledetailsChange("currency", e.value);
                        
                        } }
                        options={ currencyOptions }></CustomSelect>
                    </Box>
                    <Dialog
                      open={ errorDialog }
                      PaperProps={ {
                        style: {
                          borderRadius  : "20px",
                          backdropFilter: "blur(45px)",
                          background    : "#FFFFFF 0% 0% no-repeat padding-box",
                          boxShadow     : "0px 3px 6px #00000029",
                          border        : "1px solid #707070"
                        }
                      } }
                    // BackdropProps={{ invisible: true }}
                    >
                      <DialogContent>
                        <Box sx={ { textAlign: "center" } }>
                          <Typography gutterBottom>
                            Please select currency to begin the planning
                            process.
                          </Typography>
                          <Button
                            variant="contained"
                            size="medium"
                            sx={ {
                              backgroundColor: "#071AFF",
                              marginLeft     : "10px"
                            } }
                            onClick={ () => {

                              errorDialogclose();
                            
                            } }>
                            OK
                          </Button>
                        </Box>
                      </DialogContent>
                    </Dialog>
                  </Box>

                  {/* mid content box */ }
                  <Box id={ styles.midContentSubBox }>
                    <Box sx={ { width: "45%" } }>
                      {/* left side first box */ }
                      <Box id={ styles.leftFirstBox }>
                        {/* 1st row */ }
                        <Box id={ styles.rowBox }>
                          <Box id={ styles.typoBoxRow }>
                            <Typography id={ styles.secondRowTypo }>
                              Total Addressable <br />
                              Market (TAM)
                              <HelpIconTooltip
                                tooltipdata={
                                  "How big is the largest possible market or the total demand for the category that your product or service belongs to? "
                                }
                              />
                            </Typography>
                          </Box>
                          <Box>
                            {/* <BootstrapInput
                            fullWidth
                            disabled={details.currency === ""}
                            value={numberWithCommas(
                              details.totalAddressableMarket
                            )}
                            name="totalAddressableMarket"
                            onChange={(e) => {
                              handledetailsChange(
                                e.target.name,
                                Number(removeNonNumeric(e.target.value))
                              );
                            }}
                          /> */}

                            <CustomTextfield
                              // disabled={details.currency === ""}
                              value={ numberWithCommas(
                                details.totalAddressableMarket
                              ) }
                              handledetailsChange={ e => {

                                if (details.currency === "") {

                                  errorDialogopen();
                                
                                } else {

                                  handledetailsChange(
                                    e.target.name,
                                    Number(removeNonNumeric(e.target.value))
                                  );
                                
                                }
                              
                              } }
                              name="totalAddressableMarket"
                              width={ "12vw" }
                              padding={ "7px 0px" }
                              border={ "none" }
                            />
                          </Box>
                        </Box>
                        {/* 2 row */ }
                        <Box id={ styles.secondRowBox }>
                          <Box id={ styles.typoBoxRow }>
                            <Typography id={ styles.secondRowTypo }>
                              Segmented Addressable
                              <br /> Market (SAM)
                              <HelpIconTooltip
                                tooltipdata={
                                  "What subset (segments) of the 'Total Addressable Market' will you target with your product or service? "
                                }
                              />
                            </Typography>
                          </Box>
                          <Tooltip
                            enterTouchDelay={ 0 }
                            disableFocusListener
                            title="SAM value should be less than TAM value"
                            placement="right"
                            classes={ { tooltip: classes.customWidth } }>
                            <Box>
                              {/* <BootstrapInput
                            fullWidth
                            disabled={details.currency === ""}
                            value={numberWithCommas(
                              details.segmentedAddressableMarket
                            )}
                            name="segmentedAddressableMarket"
                            onChange={(e) => {
                              handledetailsChange(
                                e.target.name,
                                Number(removeNonNumeric(e.target.value))
                              );
                            }}
                          /> */}

                              <CustomTextfield
                                // disabled={details.currency === ""}
                                value={ numberWithCommas(
                                  details.segmentedAddressableMarket
                                ) }
                                handledetailsChange={ e => {

                                  if (details.currency === "") {

                                    errorDialogopen();
                                  
                                  } else {

                                    if (
                                      Number(removeNonNumeric(e.target.value)) <
                                      details.totalAddressableMarket
                                    ) {

                                      handledetailsChange(
                                        e.target.name,
                                        Number(removeNonNumeric(e.target.value))
                                      );
                                    
                                    }
                                  
                                  }
                                
                                } }
                                name="segmentedAddressableMarket"
                                width={ "12vw" }
                                padding={ "7px 0px" }
                                border={ "none" }
                              />
                            </Box>
                          </Tooltip>
                        </Box>
                        {/* 3rd row */ }
                        <Box id={ styles.secondRowBox }>
                          <Box id={ styles.typoBoxRow }>
                            <Typography id={ styles.secondRowTypo }>
                              Our Share of Market <br />
                              (SOM)
                              <HelpIconTooltip
                                tooltipdata={
                                  "What portion of the Segmented Addressable Market can your venture realistically capture? "
                                }
                              />
                            </Typography>
                          </Box>
                          <Tooltip
                            enterTouchDelay={ 0 }
                            disableFocusListener
                            title="SOM value should be less than SAM and TAM values"
                            placement="right"
                            classes={ { tooltip: classes.customWidth } }>
                            <Box>
                              {/* <BootstrapInput
                            disabled={details.currency === ""}
                            fullWidth
                            value={numberWithCommas(
                              details.ourExpectedMarketShare
                            )}
                            name="ourExpectedMarketShare"
                            onChange={(e) => {
                              handledetailsChange(
                                e.target.name,
                                Number(removeNonNumeric(e.target.value))
                              );
                            }}
                          /> */}
                              <CustomTextfield
                                // disabled={details.currency === ""}
                                value={ numberWithCommas(
                                  details.ourExpectedMarketShare
                                ) }
                                handledetailsChange={ e => {

                                  if (details.currency === "") {

                                    errorDialogopen();
                                  
                                  } else {

                                    if (
                                      Number(removeNonNumeric(e.target.value)) <
                                      details.segmentedAddressableMarket
                                    ) {

                                      handledetailsChange(
                                        e.target.name,
                                        Number(removeNonNumeric(e.target.value))
                                      );
                                    
                                    }
                                  
                                  }
                                
                                } }
                                name="ourExpectedMarketShare"
                                width={ "12vw" }
                                padding={ "7px 0px" }
                                border={ "none" }
                              />
                            </Box>
                          </Tooltip>
                        </Box>
                        {/* 4th row box */ }
                        <Box id={ styles.secondRowBox }>
                          {/* <Box sx={{ borderRight: "1px solid #bdc9c1" }}> */ }
                          <Box id={ styles.typoBoxRow }>
                            <Typography id={ styles.rowTitleTypo }>
                              Time horizon
                              <HelpIconTooltip
                                tooltipdata={
                                  "What dates are these projections valid for? You should ideally go out about 3yrs. Market share capture won't happen overnight, and markets can grow and decline. These should be factored into your calculations. "
                                }
                              />
                            </Typography>
                          </Box>
                          {/* </Box> */ }
                          <Box>
                            {/* <BootstrapInput
                            disabled={details.currency === ""}
                            fullWidth
                            value={details.timeHorizon}
                            name="timeHorizon"
                            onChange={(e) => {
                              handledetailsChange(
                                e.target.name,
                                e.target.value
                              );
                            }}
                          /> */}
                            <CustomTextfield
                              // disabled={details.currency === ""}
                              value={ details.timeHorizon }
                              handledetailsChange={ e => {

                                if (details.currency === "") {

                                  errorDialogopen();
                                
                                } else {

                                  handledetailsChange(
                                    e.target.name,
                                    e.target.value
                                  );
                                
                                }
                              
                              } }
                              name="timeHorizon"
                              width={ "12vw" }
                              padding={ "0px 0px" }
                              border={ "none" }
                            />
                          </Box>
                        </Box>
                      </Box>
                      {/* key assumptions box */ }
                      <Box id={ styles.keyAssumptionsBox }>
                        <Typography id={ styles.keyTypo }>
                          Key Assumptions
                          <HelpIconTooltip
                            tooltipdata={
                              "List out your key sources & assumptions behind the numbers you use in these calculations. \nFor e.g. Did you focus on particular geographies? \nWhere did you source the total market numbers, and how recent or forward looking are they? \nWhat industry segments/ categories did you assume for sizing, that are relevant for your venture? \nWhat assumption did you make for your market capture penetration rate? \nWhat risks and data points did you consider to ensure this estimate is reasonable and achievable? \nList out each note as a separate bullet point."
                            }
                            width={ "500px" }
                          />
                        </Typography>
                        <Box sx={ { borderTop: "1px solid #bdc9c1" } }>
                          {/* <BootstrapInputKey
                          disabled={details.currency === ""}
                          multiline
                          maxRows={5}
                          minRows={5}
                          value={details.keyAssumptions}
                          name="keyAssumptions"
                          onChange={(e) => {
                            handledetailsChange(e.target.name, e.target.value);
                          }}
                          placeholder={
                            "Some examples:\n1. Assumed rollout in 10 cities in year 1, with 5% market share capture in each. Expand to 100 cities by year 3, with average 15% share capture across.\n2. Degree of competitive threat, logistical hurdles, and consumer demand varies in each market, some quite significantly than others, but for this market size estimate we have roughly factored that into our average market penetration assumption of 10%. Penetration may be much higher than average in some markets, and lower in others.\n3. For total addressable market we have also considered products we don’t have yet but plan to launch in year 2.\n4. Market data sourced from WBA Annual Industry report 2020."
                          }
                        /> */}
                          <CustomMultilineTextfield
                            value={ details.keyAssumptions }
                            name="keyAssumptions"
                            // disabled={details.currency === ""}
                            maxRows={ 5 }
                            minRows={ 5 }
                            handledetailsChange={ e => {

                              handledetailsChange(
                                e.target.name,
                                e.target.value
                              );
                            
                            } }
                            placeholder={
                              "Some examples:\n1. Assumed rollout in 10 cities in year 1, with 5% market share capture in each. Expand to 100 cities by year 3, with average 15% share capture across.\n2. Degree of competitive threat, logistical hurdles, and consumer demand varies in each market, some quite significantly than others, but for this market size estimate we have roughly factored that into our average market penetration assumption of 10%. Penetration may be much higher than average in some markets, and lower in others.\n3. For total addressable market we have also considered products we don’t have yet but plan to launch in year 2.\n4. Market data sourced from WBA Annual Industry report 2020."
                            }
                            width={ "100%" }
                            border={ "none" }
                            fontSize={ "14px" }
                            padding={ "13px 10px" }
                          />
                        </Box>
                      </Box>
                    </Box>
                    {/* chart Box */ }
                    <Box id={ styles.chartBox }>
                      <Box
                        sx={ {
                          display       : "flex",
                          alignItems    : "center",
                          justifyContent: "center",
                          flexDirection : "column",
                          height        : "100%"
                        } }>
                        {/* <Typography>Market Size Chart</Typography>
                      <Typography
                        sx={{
                          color: "#FF7F00",
                          border: "1px solid #FF7F00",
                          padding: "8px",
                        }}
                      >
                        Coming Soon
                      </Typography>*/}
                        <CustomChart
                          totalAddressableMarket={ {
                            name          : "Total Addressable Market",
                            label         : "TAM",
                            formattedValue: numberWithCommas(
                              details.totalAddressableMarket
                            )
                          } }
                          segmentedAddressableMarket={ {
                            name          : "Segmented Addressable Market",
                            label         : "SAM",
                            formattedValue: numberWithCommas(
                              details.segmentedAddressableMarket
                            )
                          } }
                          ourExpectedMarketShare={ {
                            name          : "Expected Share of Market",
                            label         : "SOM",
                            formattedValue: numberWithCommas(
                              details.ourExpectedMarketShare
                            )
                          } }
                        />
                      </Box>
                      {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography id={styles.refreshChartTypo}>
                        Refresh Chart
                      </Typography>
                      <Box sx={{ marginLeft: "5px" }}>
                        <RefreshIcon sx={{ color: "#071AFF" }} />
                      </Box>
                        </Box>*/}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </>
          }
        </Grid>

        <div className="ttribe-back-next-button-con">
          { hashvalue === "upload" ?
            <BackButton
              restrictedBackClick={ () => history.push("/marketsize") }
              backClick={ () => history.push("/marketsize") }
            />
            :
            <BackNextButton
              restrictedBackClick={ () => {

                // handleSubmit();
                history.push({
                  pathname: "/competition",
                  hash    : "3"
                });
              
              } }
              backClick={ () => {

                handleSubmit();
                history.push({
                  pathname: "/competition",
                  hash    : "3"
                });
              
              } }
              restrictedNextClick={ () => {

                // handleSubmit();
                history.push({
                  pathname: "/marketsummary"
                });
              
              } }
              nextClick={ () => {

                handleSubmit();
                history.push({
                  pathname: "/marketsummary"
                });
              
              } }
            />
          }
        </div>
      </div>
    </ModuleSections>
  );

};

export default MarketSize;
