import {
  Box, Typography, Tooltip
} from "@mui/material";
import React from "react";
import styles from "./PricingIndividualNavBar.module.css";

export default function PricingIndividualNavBar(props) {

  return (
    // <Box
    //   sx={{
    //     display: "flex",
    //     width: "13%",
    //     alignItems: "center",
    //     border: 1,
    //     justifyContent: "space-between",
    //   }}
    // >
    <>
      <Box

        id={styles.barBox}
        sx={{
          // width: "75%",
          border         : props.highlight ? "1px solid #071AFF" : "1px solid #868686",
          backgroundColor: props.highlight ? "#071AFF" : "#868686"
        }}
      ></Box>
      {props.toolTipData ? <Tooltip placement="top" title={props.toolTipData}>

        <Box
          id={styles.circleBox}
          sx={{
            backgroundColor: props.highlight ? "#071AFF" : "#ffffff",
            border         : props.highlight ? "1px solid #071AFF" : "1px solid #868686"
          }}
        >
          <Typography
            id={styles.circleBoxTypo}
            sx={{
              color: props.highlight ? "#ffffff" : "#868686"
            }}
          >
            {props.slideNo}
          </Typography>
        </Box>

      </Tooltip> : <Box
        id={styles.circleBox}
        sx={{
          backgroundColor: props.highlight ? "#071AFF" : "#ffffff",
          border         : props.highlight ? "1px solid #071AFF" : "1px solid #868686"
        }}
      >
        <Typography
          id={styles.circleBoxTypo}
          sx={{
            color: props.highlight ? "#ffffff" : "#868686"
          }}
        >
          {props.slideNo}
        </Typography>
      </Box>}
      {/* <Tooltip placement="top" title={props.toolTipData}>



      </Tooltip> */}


    </>
    // </Box>
  );

}
