
import React from "react";
import { useHistory } from "react-router-dom";

import { Box } from "@mui/material";

import PricingIndividualNavBar from "../../../components/PricingIndividualNavBar";
import styles from "./FinancialNavBar.module.css";

const Financials = () => {

  const history = useHistory();
  const pathName = history.location.pathname;

  return <div>
    <Box id={styles.topNavMainBox}>
      <Box
        id={styles.individualNavMainBox}
        onClick={() => {

          history.push("ploutlook");
        
        }}>
        <PricingIndividualNavBar
          highlight={pathName === "/ploutlook" ? true : false}
          slideNo={"1"}
          toolTipData={"'P&L Outlook"}
        />
      </Box>
      <Box
        id={styles.individualNavMainBox}
        onClick={() => {

          history.push("fundingrunway");
        
        }}>
        <PricingIndividualNavBar
          highlight={pathName === "/fundingrunway" ? true : false}
          slideNo={"2"}
          toolTipData={"Funding and Runway"}
        />
      </Box>
      <Box
        id={styles.individualNavMainBox}
        onClick={() => {

          history.push("cashflow");
        
        }}>
        <PricingIndividualNavBar
          highlight={pathName === "/cashflow" ? true : false}
          slideNo={"3"}
          toolTipData={"Cash Flow"}
        />
      </Box>
      <Box
        id={styles.individualNavMainBox}
        onClick={() => {

          history.push("balancesheet");
        
        }}>
        <PricingIndividualNavBar
          highlight={
            pathName === "/balancesheet" ? true : false
          }
          slideNo={"4"}
          toolTipData={"Balance Sheet"}
        />
      </Box>
      <Box
        id={styles.individualNavMainBox}
        onClick={() => {

          history.push("insightsdashboard");
        
        }}>
        <PricingIndividualNavBar
          highlight={pathName === "/insightsdashboard" ? true : false}
          slideNo={"5"}
          toolTipData={"Insights DashBoard"}
        />
      </Box>
    </Box>
  </div>;

};

export default Financials;
