import {
  FormControlLabel, FormLabel, OutlinedInput, Radio, RadioGroup, Typography
} from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import SuccessMessage from "../../../components/SuccessMessage";
import VentureData from "../../../Data/CreateVenture.json";
const data = VentureData.createproject;
const journeydata = VentureData.journeyvalues;

// const bar = {
//   height: "4px",
//   width: "100px",
//   borderRadius: "50px",
//   backgroundColor: "grey",
//   margin: "0px 20px",
//   marginLeft: "0px",
// };
// const bar_blue = {
//   height: "4px",
//   width: "100px",
//   borderRadius: "50px",
//   backgroundColor: "blue",
//   margin: "0px 20px",
//   marginLeft: "0px",
// };

const NextPage = props => {

  // const history = useHistory();
  const location = useLocation();
  const hashvalue = location.hash;
  let id = Number(location.hash.substring(1));
  let page = id - 1;

  // let pathname = location.pathname.substring(1);

  return (
    <>
      <Typography
        variant="subtitle1"
        gutterBottom
        component="div"
        style={ { marginTop: "10px", marginBottom: "30px" } }
      >
        Help us understand where you currently are in your journey from ideation
        to market success.
      </Typography>
      <FormLabel
        component="legend"
        sx={ { fontWeight: "bold", marginBottom: "10px" } }
      >
        { data[page].question }
      </FormLabel>
      <RadioGroup
        name={ journeydata[page] }
        value={ props.values[journeydata[page]] }
        onChange={ e => {

          props.handleChange(e.target.name, e.target.value);
        
        } }
        sx={ { height: "59%", flexDirection: "row" } }
      >
        { data[page].answers.map(answer => {

          if (answer === "Other") {

            return (
              <>
                <FormControlLabel
                  value={ answer }
                  control={ <Radio /> }
                  label={
                    <>
                      <span style={ { marginRight: "10px" } }>Other</span>
                      <OutlinedInput
                        placeholder="Please specify"
                        name={ journeydata[page] }
                        onClick={ () => {

                          props.handleChange(journeydata[page], "Other");
                        
                        } }
                        onChange={ e => {

                          props.handleChange(
                            journeydata[page] + "Other",
                            e.target.value
                          );
                        
                        } }
                        value={ props.values[journeydata[page] + "Other"] }
                        size="small"
                      />
                    </>
                  }
                />
              </>
            );
          
          } else {

            return (
              <FormControlLabel
                // key={i}
                value={ answer }
                control={ <Radio /> }
                label={ answer }
                style={ hashvalue === "#1" ? { width: "75%" } : hashvalue === "#5" ? { width: "45%" } : { width: "30%" } }
                sx={ { marginBottom: "15px" } }
              />
            );
          
          }
        
        }) }
      </RadioGroup>
      { props.successmsg && <SuccessMessage successmsg={ props.successmsg } /> }

    </>
  );

};

export default NextPage;
