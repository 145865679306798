import AddIcon from "@mui/icons-material/Add";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box, Button,
  Dialog,
  DialogContent, IconButton
} from "@mui/material";
import React, { useState } from "react";
// import { useHistory } from "react-router-dom";
import IndividualMilestone from "../IndividualMilestone";
import PitchMilestone from "../PitchReferenceLinks/PitchMilestone";
import SectionHeader from "../SectionHeader";
import styles from "./Milestone.module.css";

export default function Milestone({
  logoimg,
  slides,
  isRender,
  setSlides,
  isPublished,
  ...props
}) {

  const [ milestoneDialogbox, setMilestoneDialog ] = useState(false);

  const slide = slides[isRender];

  const handleChange = (name, value, columnindex) => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;

    let newmilestones = [ ...newSlides[isRender]["milestones"] ];

    newmilestones.map((milestone, milestoneindex) => {

      if (milestoneindex === columnindex) {

        newmilestones[milestoneindex][name] = value;
      
      }
    
    });
    newSlides[isRender]["milestones"] = newmilestones;

    setSlides(newSlides);
  
  };

  const addMilestone = () => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;

    newSlides[isRender]["milestones"].push({
      name : `Milestone ${newSlides[isRender]["milestones"].length + 1}`,
      value: ""
    });

    setSlides(newSlides);
  
  };

  const removeMilestone = columnindex => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;

    let newmilestones = newSlides[isRender]["milestones"].filter(
      (milestone, milestoneindex) => milestoneindex !== columnindex
    );

    newSlides[isRender]["milestones"] = newmilestones;

    setSlides(newSlides);
  
  };

  const handleDetailsChange = (name, value) => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    newSlides[isRender][name] = value;
    setSlides(newSlides);
  
  };

  // const history = useHistory();
  // const pathname = history.location.pathname.substring(1);

  const milestoneDialogopen = () => {

    setMilestoneDialog(true);
  
  };
  const milestoneDialogclose = () => {

    setMilestoneDialog(false);
  
  };

  
  return (
    <Box>
      <SectionHeader
        isEdit={props.isEdit}
        Collapsecomment={props.Collapsecomment}
        collapseCommentFunction={props.collapseCommentFunction}
        mainHead={slide.name}
        handleTitleChange={handleDetailsChange}
        logoimg={logoimg}
        isPublished={isPublished}
      />
      <Box
        id={styles.milestoneMainBox}
      >
        <Box
          id={styles.borderBox}
        >
          <Box
            id={styles.milestoneFlex}
          >
            {/* individual milestone box */}
            {slide.milestones.map((milestone, milestoneindex) => {

              return <IndividualMilestone
                isEdit={props.isEdit}
                milestoneindex={milestoneindex}
                milestone={milestone}
                handleChange={handleChange}
                removeMilestone={removeMilestone}
              />;
            
            })}
            {/* ---- */}
          </Box>
          {props.isEdit ?
            <Box
              sx={{
                width         : "36px",
                height        : "36px",
                display       : "flex",
                justifyContent: "center",
                alignItems    : "center",
                borderRadius  : "50%",
                position      : "absolute",
                bottom        : "-240px",
                right         : "0px",
                backgroundColor:
                  slide.milestones.length === 5 ? "#b0b0b0" : "#071AFF",
                cursor: slide.milestones.length === 5 ? "default" : "pointer"
              }}
              onClick={() => {

                if (slide.milestones.length < 5) addMilestone();
              
              }}
            >
              <AddIcon sx={{ fontSize: "32px", color: "#FFFFFF" }} />
            </Box>
            :
            <></>
          }
        </Box>
        {/* reference link */}

        {props.isEdit ?
          <Box sx={{ position: "absolute", left: "125px", bottom: "-150px" }}>
            <Button
              style={{
                position      : "absolute",
                textTransform : "none",
                textDecoration: "underline",
                fontSize      : "12px",
                top           : "-20px",
                right         : "-10px",
                padding       : "0px",
                fontWeight    : "bold"
              }}
              onClick={() => {

                milestoneDialogopen();
              
              }}
            >
              Milestone
            </Button>
            <Dialog
              open={milestoneDialogbox}
              onClose={milestoneDialogclose}
              PaperProps={{
                style: {
                  borderRadius   : "20px",
                  backdropFilter : "blur(100px)",
                  backgroundColor: "#ffffff",
                  boxShadow      : "0px 3px 6px #00000029",
                  // border: "1px solid #707070",
                  width          : "70%"
                }
              }}
              // BackdropProps={{ invisible: true }}
              //
              maxWidth="false"
              fullWidth
            >
              <DialogContent sx={{ padding: "40px" }}>
                <Box
                  sx={{
                    width   : "100%",
                    // height: "60vh",
                    // display: "flex",
                    // alignItems: "center",
                    // justifyContent: "center",
                    position: "relative"
                  }}
                >
                  <PitchMilestone />
                  <IconButton
                    onClick={() => {

                      milestoneDialogclose();
                    
                    }}
                    sx={{
                      position: "absolute",
                      right   : "-40px",
                      top     : "-40px"
                    }}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Box>
              </DialogContent>
            </Dialog>
          </Box>
          :
          <></>
        }
      </Box>
    </Box>
  );

}
