import React from "react";
import {
  Box, TextField, Typography
} from "@mui/material";

import { useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import HelpIconTooltip from "../../../../../components/HelpIconTooltip";
import styles from "./Costs.module.css";

export default function Costs(props) {

  const { venture } = useSelector(state => ({ ...state }));

  const revenueStreams = props.revenueStreams;
  const revenueStreamIndex = props.revenueStreamIndex;

  const handleCostsChange = (revenueStreamIndex, value) => {

    let newRevenueStreams = [ ...revenueStreams ];

    newRevenueStreams[revenueStreamIndex]["costs"] = value;

    props.setRevenueStreams(newRevenueStreams);
  
  };

  // const history = useHistory();

  
  return (
    // <CreatePricingComponent barHighlight="bar6">
    <Box>
      {/* top typo box */}
      <Box>
        <Typography
          id={styles.topTypo}
        // sx={{ color: "#5F5E5E" }}
        >
          Costs
        </Typography>
      </Box>

      {/* typo img box */}
      <Box
        id={styles.typoImgMainBox}
      // sx={{ width: "100%", display: "flex" }}
      >
        <Box
          id={styles.typoBox}
        // sx={{ width: "70%" }}
        >
          <Typography
            id={styles.descTypo}
          // sx={{ color: "#868686", fontSize: "13px" }}
          >
            To measure profitability of your business & pricing model, make a
            list of all direct expenses that you will incur to produce & deliver
            your product or service to a customer, along with a $ cost estimate
            for each. The goal here is not an official accounting statement, but
            more to know that your business model will be financially
            sustainable, and that the prices you think the market will be
            willing to pay, will not just cover your cost of making & delivering
            the product at a reasonable scale, but allow for attractive margins
            as well.
            <span style={{ marginLeft: "3px" }}>
              <HelpIconTooltip
                placement="top-start"
                width="450px"
                tooltipdata={
                  "Depending on the type of business, your direct costs could include personnel costs, infrastructure costs (for e.g. cloud hosting if you’re building a software product), 3rd party software or other tools used, raw materials, packaging, and any other relevant costs that can be directly tied to creating, delivering, and supporting your product or service.\n\nWhen deriving the cost estimates, note economies of scale that could help you reduce costs, and choose a unit that’ll make the most sense for your venture & help with your business planning. For e.g. if selling t-shirts, it could be per t-shirt or per 100 t-shirts sold. If selling software, it could be per-customer per-month, and so on. ​​​​"
                }
                padding={"0px"}
              />
            </span>
          </Typography>
        </Box>
        {/* image box */}
        <Box
          id={styles.imgBox}
        // sx={{
        //   width: "30%",
        //   display: "flex",
        //   justifyContent: "center",
        //   alignItems: "center",
        // }}
        >
          <img
            id={styles.img}
            src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/monetisationCost.svg`}
            // style={{ width: "122px", height: "79px" }}
            alt=""
          />
        </Box>
      </Box>

      {/* text field box */}
      <Box
        id={styles.textFieldBox}
      // sx={{ width: "100%", marginTop: "5vh" }}
      >
        <TextField
          onChange={e => {

            handleCostsChange(revenueStreamIndex, e.target.value);
          
          }}
          disabled={venture.userRole === "Commenter" ? true : false}
          value={revenueStreams[revenueStreamIndex]["costs"]}
          fullWidth
          multiline
          maxRows={9}
          minRows={9}
          sx={{
            "& .MuiOutlinedInput-root": {
              padding: "14.5px 16px"
            },
            "& .MuiInputBase-input": {
              fontSize  : "12px",
              color     : "#707070",
              lineHeight: "17px"
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #E1E2E9"
            }
          }}
        />
      </Box>
    </Box>
    // </CreatePricingComponent>
  );

}
