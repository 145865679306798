import CloseIcon from "@mui/icons-material/Close";
import {
  Box, TextField, Typography
} from "@mui/material";
import React from "react";

export default function IndividualValuePropositions ({
  proposition,
  isEdit,
  propositionIndex,
  handleChange,
  removeProposition
  // ...props
}) {

  // const history = useHistory();
  // const pathname = history.location.pathname.substring(1);
  return (
    <Box
      sx={ {
        display      : "flex",
        flexDirection: "column",
        width        : "45%",

        alignItems: "center",
        position  : "relative"
      } }
    >
      <Box
        sx={ {
          display       : "flex",
          justifyContent: "center",
          alignItems    : "center",
          marginBottom  : "5px",
          width         : "100%"
        } }
      >
        { isEdit ?
          <TextField
            value={ proposition.name }
            onChange={ e => {

              handleChange("name", e.target.value, propositionIndex);
            
            } }
            inputProps={ { maxLength: 25 } }
            fullWidth
            sx={ {
              "& .MuiInputBase-input": {
                fontSize  : "16px",
                padding   : "0px 10px",
                border    : "none",
                textAlign : "center",
                fontWeight: "bold"
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "2px dotted #B4B4FA"
              }
            } }
          />
          :
          <Typography sx={ { fontSize: "1.8vh", color: "#393939", fontWeight: "bold" } }>
            { proposition.name }
          </Typography>
        }
      </Box>
      <Box sx={ { width: "100%" } }>
        { isEdit ?
          <TextField
            value={ proposition.value }
            onChange={ e => {

              handleChange("value", e.target.value, propositionIndex);
            
            } }
            inputProps={ { maxLength: 150 } }
            placeholder="Specify the unique & compelling benefits or outcomes that your solution will deliver to customers."
            fullWidth
            multiline
            maxRows={ 5 }
            minRows={ 5 }
            sx={ {
              "& .MuiOutlinedInput-root": {
                padding: "7px 10px"
              },
              "& .MuiInputBase-input": {
                fontSize  : "14px",
                color     : "#707070",
                whiteSpace: "pre-line"
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "2px dotted #B4B4FA"
              }
            } }
          />
          :
          <Box
            sx={ {
              width       : "100%",
              border      : "1px solid #BEBEBE",
              height      : "15vh",
              borderRadius: "5px",
              overflowY   : "auto",
              display     : "flex",
              alignItems  : "center"
            } }
          >
            <Box
              sx={ {
                margin  : "5px 8px",
                wordWrap: "break-word"
              } }
            >
              <Typography
                sx={ {
                  fontSize  : "1.5vh",
                  color     : "#707070",
                  lineHeight: "1.5",
                  whiteSpace: "pre-line"
                } }
              >
                { proposition.value }
              </Typography>
            </Box>
          </Box>
        }
      </Box>
      {/* close button */ }
      { propositionIndex > 2 && isEdit &&
        <Box
          sx={ {
            width          : "15px",
            height         : "15px",
            border         : "1px solid #838383",
            borderRadius   : "50%",
            zIndex         : 20,
            position       : "absolute",
            right          : "-7px",
            top            : "-7px",
            display        : "flex",
            justifyContent : "center",
            alignItems     : "center",
            backgroundColor: "#FFFFFF"
          } }
          onClick={ () => {

            removeProposition(propositionIndex);
          
          } }
        >
          <CloseIcon sx={ { fontSize: "15px", color: "#838383" } } />
        </Box>
      }
    </Box>
  );

}
