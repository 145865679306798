import { Box, Button, Grid, Typography } from "@mui/material";

import React from "react";
// import BackButton from "../../../components/BackButton";

import styles from "./ConceptVision.module.css";

const FinishPage = ({ history }) => {

  return <>
    <Box sx={ {
      height         : "81vh",
      margin         : "5vh 10vh 5vh",
      borderRadius   : "5px",
      // overflowY: "auto",
      backgroundColor: "#ffffff",
      position       : "relative"
    } } >
      <div className="box-display-style-starting-content-con">
        <Grid
          sx={ {
            height        : "100%",
            display       : "flex",
            flexDirection : "column",
            justifyContent: "center",
            padding       : "40px"
          } }
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item md={ 6 } sx={ { textAlign: "center" } }>
              <img
                src="images/undraw_ride_a_bicycle_re_6tjy.svg"
                alt=""
                id={ styles.finishimg }
              />
            </Grid>
            <Grid item md={ 6 } sx={ { textAlign: "center" } }>
              <Typography variant="h4" fontWeight="bold" mb={ 5 } fontSize="42px">
                Congratulations.
              </Typography>
              <Typography variant="body1" mb={ 5 } textAlign="center">
                <span style={ { display: "block", marginTop: "10px" } }>
                It’s time to begin your business planning journey!
                </span>
                {/* <span style={ { display: "block", marginBottom: "10px" } }>
                  These should be your guiding principles at every step now as you
                  plan, launch, and grow your venture.
                </span> */}
                <span style={ { display: "block" } }>
                  Click the button below to go to your planning dashboard.
                </span>
              </Typography>
              <Button
                variant="contained"
                style={ {
                  backgroundColor: "#071AFF",
                  borderRadius   : "8px",
                  textTransform  : "capitalize"
                } }
                onClick={ () => {

                  history.push("/welcomepage");
                
                } }
              >
                Kickstart your Venture Plan!
              </Button>
            </Grid>
          </Grid>

        </Grid>
        {/* <div className="box-display-style-footer-content-con">
          <BackButton
            restrictedBackClick={ () =>
              history.push(history.push("/createventure"))
            }
            backClick={ () =>
              history.push("/createventure")
            }
          />
        </div> */}

      </div>

    </Box>

  </>
  // <BoxWithoutIcon marginRight={"150px"}>

  // </BoxWithoutIcon>
  ;

};

export default FinishPage;
