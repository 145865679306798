/* eslint-disable camelcase */
import {
  Box, Grid, Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ErrorMessage from "../../../../components/ErrorMessage";
import ModuleSections from "../../../../components/ModuleSections";
import Data from "../../../../Data/Gtm.json";
import {
  createSegment,
  deletePersona,
  deleteSegment, getSegments
} from "../../../../services/persona";
import IndividualSegments from "./IndividualSegments";

import BackNextButton from "../../../../components/BackNextButton";
import CustomTextField from "../../../../components/CustomTextfield";
import IndividualSectionHeaders from "../../../../components/IndividualSectionHeaders";
import {
  createCustomerComment,
  deleteCustomerComment,
  updateCustomerComment
} from "../../../../services/comment";

const SegmentsPersonas = () => {

  const [ segments, setSegments ] = useState([]);
  const [ comments, setComments ] = useState([]);
  const [ name, setName ] = useState("");
  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const [ dialogbox, setDialog ] = useState(false);
  const [ errormsg, setErrormsg ] = useState("");
  const [ isAdd, setIsadd ] = useState(false);
  const [ isremovepersona, setIsremovepersona ] = useState(false);
  const [ isremovesegment, setIsremovesegment ] = useState(false);
  const [ isdeleteindex, setIsdeleteindex ] = useState(0);
  const [ ispersonacreated, setIspersonacreated ] = useState(false);
  const [ issegmentcreated, setIssegmentcreated ] = useState(false);
  const [ isDataLoading, setIsDataLoading ] = useState(true);
  const history = useHistory();
  // const dispatch = useDispatch();

  const pathname = history.location.pathname.substring(1);
  const guideicon = Data.gtmtargetpersonas;

  let { user, venture } = useSelector(state => {

    return { ...state };
  
  });

  useEffect(() => {

    setTimeout(() => {

      fetchSegments();

    }, 500);

  }, []);

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);
    
    } else {

      setCollapsecomment(true);
    
    }
  
  };

  const fetchSegments = async () => {

    setIsDataLoading(true);

    try {

      const response = await getSegments(venture.customer);

      localStorage.removeItem("personaid");
      localStorage.removeItem("segmentid");
      const customer = response.data;

      if (customer) {

        setSegments(customer.segmentsPersonas.segments);
        setComments(customer.comments.segmentsPersonas);
        let count = 0;

        customer.segmentsPersonas.segments.map((segment, index) => {

          if (segment.personas.length > 0) {

            count += 1;
          
          }
          if (index === response.data.length - 1 && count > 0) {

            setIspersonacreated(true);
          
          } else {

            setIspersonacreated(false);
          
          }
        
        });
      
      }

      setIsDataLoading(false);
    
    } catch (error) {

      console.error(error);
      setIsDataLoading(false);
    
    }
  
  };

  const createPersona = (id, name) => {

    localStorage.setItem("segmentid", id);
    localStorage.setItem("name", name);
    if (pathname.includes("gtm")) {

      history.push({ pathname: "/gtmuserpersona", hash: "0" });
    
    } else {

      history.push({ pathname: "/userpersona", hash: "0" });
    
    }
  
  };

  const removePersona = async (personaid, segmentid) => {

    try {

      await deletePersona(personaid, segmentid, venture.customer);
      localStorage.removeItem("personaid");
      // localStorage.removeItem("segmentid");
      setIsremovepersona(false);
      fetchSegments();
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const removeSegment = async segmentid => {

    try {

      await deleteSegment(segmentid, venture.customer);
      localStorage.removeItem("segmentid");
      setIsremovesegment(false);
      fetchSegments();
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const createSegments = async () => {

    try {

      if (name !== "") {

        setIssegmentcreated(true);
        await createSegment(
          name,
          venture.customer,
          venture._id
        );

        setName("");
        setIssegmentcreated(false);
        fetchSegments();
      
      } else {

        setErrormsg("Please fill the required field");
        setTimeout(() => {

          setErrormsg("");
        
        }, 5000);
      
      }
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const addComment = async (commentText, replyTo) => {

    try {

      const response = await createCustomerComment(
        commentText,
        user._id,
        "segmentsPersonas",
        "",
        venture.customer,
        replyTo,
        window.location.href,
        "Customer > Segments & Personas"
      );

      setComments(response.data.comments.segmentsPersonas);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateComment = async (commentid, commentText) => {

    try {

      const response = await updateCustomerComment(
        commentText,
        user._id,
        commentid,
        venture._id,
        window.location.href,
        "Customer > Segments & Personas"
      );

      setComments(response.data.comments.segmentsPersonas);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };
  

  const deleteComment = async commentid => {

    try {

      const response = await deleteCustomerComment(
        "segmentsPersonas",
        "",
        venture.customer,
        commentid,
        user._id,
        window.location.href,
        "Customer > Segments & Personas"
      );

      setComments(response.data.comments.segmentsPersonas);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  return (
    <ModuleSections
      Collapsecomment={ Collapsecomment }
      comments={ comments }
      addComment={ addComment }
      deleteComment={ deleteComment }
      updateComment={ updateComment }>
      <div className="box-display-style-for-next-and-back-button-with-justify">
        <div className="box-indiv-header-con">
          <IndividualSectionHeaders
            marginBottom={ "15px" }
            mainheading={ pathname.includes("gtm") ? "GTM" : "Customer" }
            subheading={
              pathname.includes("gtm")
                ? "Segments & Personas"
                : "Segments & Personas"
            }
            headingcontent={
              "Segment your customers into groups and create realistic user personas."
            }
            isGtm={ pathname.includes("gtm") ? true : false }
            Dialogopen={ Dialogopen }
            Dialogclose={ Dialogclose }
            dialogbox={ dialogbox }
            collapse_comment_function={ collapse_comment_function }
            content={ guideicon }
            isGtmChevron={ "none" }
          />
        </div>
        <Grid
          item
          xs={ 12 }
          sx={ { height: "99%", overflowY: "auto", padding: "0px 60px 0px" } }
          onClick={ () => {

            setIsremovepersona(false);
            setIsremovesegment(false);
          
          } }>
          { ispersonacreated &&
            <Typography
              variant="body2"
              sx={ { fontSize: "14px", marginBottom: "5px" } }>
              You can create up to five customer segments and three user
              personas per segment. Click to view/edit details.
            </Typography>
          }
          { segments.length > 0 ?
            <>
              <Grid
                sx={ {
                  // height: "100%",
                  overflowY   : "auto",
                  margin      : "20px 0px 0px",
                  paddingRight: "20px"
                } }>
                { segments.map((segment, segmentindex) => {

                  return (
                    <IndividualSegments
                      // key={ segmentindex }
                      segment={ segment }
                      segmentindex={ segmentindex }
                      isremovesegment={ isremovesegment }
                      removeSegment={ removeSegment }
                      isremovepersona={ isremovepersona }
                      isdeleteindex={ isdeleteindex }
                      removePersona={ removePersona }
                      createPersona={ createPersona }
                      setIsdeleteindex={ setIsdeleteindex }
                      setIsremovepersona={ setIsremovepersona }
                      fetchSegments={ fetchSegments }
                      pathname={ pathname }
                    />
                  );
                
                }) }
                { isAdd &&
                  <>
                    <hr style={ { margin: "20px 0px" } } />
                    <Grid container mb={ 2 }>
                      <Grid
                        item
                        md={ 2 }
                        sx={ {
                          display       : "flex",
                          alignItems    : "center",
                          justifyContent: "center"
                        } }>
                        <Typography>Enter a Segment name</Typography>
                      </Grid>
                      <Grid item md={ 10 }>
                        <CustomTextField
                          handledetailsChange={ e => {

                            setName(e.target.value.trimStart());
                          
                          } }
                          onKeyDown={ e => {

                            if (e.key === "Enter") {

                              e.preventDefault();
                              if (
                                segments.length === 4 &&
                                name.length > 0 &&
                                !issegmentcreated
                              ) {

                                createSegments();
                                setIsadd(false);
                              
                              } else if (
                                segments.length < 5 &&
                                !issegmentcreated
                              ) {

                                createSegments();
                              
                              }
                            
                            }
                          
                          } }
                          value={ name }
                          error={ errormsg ? true : false }
                          width={ "82%" }
                          padding={ "10px 0px" }
                          borderRadius={ "10px" }
                        />
                      </Grid>
                    </Grid>
                  </>
                }
                { errormsg && <ErrorMessage errormsg={ errormsg } /> }
                <Box sx={ { display: "flex", marginTop: "15px" } }>
                  <Typography
                    sx={ {
                      color:
                        segments.length < 5 && venture.userRole !== "Commenter"
                          ? "#071AFF"
                          : "#b0b0b0",
                      cursor:
                        segments.length < 5 && venture.userRole !== "Commenter"
                          ? "pointer"
                          : "default"
                    } }
                    component="span"
                    onClick={ () => {

                      if (venture.userRole !== "Commenter") {

                        if (
                          segments.length === 4 &&
                          name !== "" &&
                          !issegmentcreated
                        ) {

                          createSegments();
                          setIsadd(false);
                        
                        } else if (
                          segments.length < 4 &&
                          name !== "" &&
                          !issegmentcreated
                        ) {

                          createSegments();
                        
                        } else if (segments.length < 5 && !issegmentcreated) {

                          if (name) {

                            createSegments();
                            setIsadd(true);
                          
                          } else {

                            if (isAdd) {

                              createSegments();
                            
                            } else {

                              setIsadd(true);
                            
                            }
                          
                          }
                        
                        }
                      
                      }
                    
                    } }>
                    Add a new Segment
                  </Typography>
                  { segments.length > 0 &&
                    <Typography
                      sx={ {
                        color:
                          isremovesegment || venture.userRole === "Commenter"
                            ? "#B0B0B0"
                            : "#FF7F00",
                        cursor:
                          isremovesegment || venture.userRole === "Commenter"
                            ? "default"
                            : "pointer",
                        marginLeft: "20px"
                      } }
                      component="span"
                      onClick={ e => {

                        e.stopPropagation();
                        if (
                          !isremovepersona &&
                          !isremovesegment &&
                          venture.userRole !== "Commenter"
                        ) {

                          setIsremovesegment(true);
                        
                        }
                      
                      } }>
                      Delete Segment
                    </Typography>
                  }
                </Box>
              </Grid>
            </>
            :
            <>
              <Grid sx={ { height: "65%" } }>
                <Grid container sx={ { margin: "20px 0px" } }>
                  <Grid
                    item
                    md={ 2 }
                    sx={ {
                      display   : "flex",
                      alignItems: "center"
                      // justifyContent: "center",
                    } }>
                    <Typography>Enter a Segment name</Typography>
                  </Grid>
                  <Grid item md={ 10 }>
                    <CustomTextField
                      handledetailsChange={ e => {

                        setName(e.target.value);
                      
                      } }
                      onKeyDown={ e => {

                        if (e.key === "Enter") {

                          e.preventDefault();
                          createSegments();
                          setIsadd(true);
                        
                        }
                      
                      } }
                      value={ name }
                      error={ errormsg ? true : false }
                      width={ "82%" }
                      padding={ "10px 0px" }
                      borderRadius={ "10px" }
                    />
                  </Grid>
                </Grid>
                { errormsg && <ErrorMessage errormsg={ errormsg } /> }
                <Typography
                  sx={ {
                    color:
                      venture.userRole !== "Commenter" ? "#071AFF" : "#b0b0b0",
                    cursor:
                      venture.userRole !== "Commenter" ? "pointer" : "default"
                  } }
                  component="span"
                  onClick={ () => {

                    if (venture.userRole !== "Commenter") {

                      createSegments();
                      setIsadd(true);
                    
                    }
                  
                  } }>
                  Add a new Segment
                </Typography>
              </Grid>
            </>
          }
        </Grid>
        <div className="ttribe-back-next-button-con">
          <BackNextButton
            restrictedBackClick={ () =>
              pathname.includes("gtm") && isDataLoading === false
                ? history.push("/gtmtargetpersonas")
                : history.push("/customerintro")
            }
            backClick={ () =>
              pathname.includes("gtm") && isDataLoading === false
                ? history.push("/gtmtargetpersonas")
                : history.push("/customerintro")
            }
            restrictedNextClick={ () =>
              pathname.includes("gtm") && isDataLoading === false
                ? history.push("/targetpersonassummary")
                : history.push("/userjourneymaps")
            }
            nextClick={ () =>
              pathname.includes("gtm") && isDataLoading === false
                ? history.push("/targetpersonassummary")
                : history.push("/userjourneymaps")
            }
          />
        </div>
      </div>
    </ModuleSections>
  );

};

export default SegmentsPersonas;
