import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box, Checkbox, FormControlLabel, FormGroup, Grid, IconButton,
  Radio, RadioGroup, Slider, TextField, Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { createRef, useEffect, useRef, useState } from "react";
import Select from "react-select";

import { makeStyles } from "@mui/styles";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./TargetSegments.module.css";

const FacebookIcon = styled("span")(() => ({
  width          : 40,
  height         : 40,
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/uncheckedfacebook.png)`,
  backgroundSize : "cover"
}));

const FacebookCheckedIcon = styled(FacebookIcon)({
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/checkedfacebook.png)`
});

const InstagramIcon = styled("span")(() => ({
  width          : 40,
  height         : 40,
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/uncheckedinstagram.png)`,
  backgroundSize : "cover"
}));

const InstagramCheckedIcon = styled(InstagramIcon)({
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/checkedinstagram.png)`
});

const TwitterIcon = styled("span")(() => ({
  width          : 40,
  height         : 40,
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/uncheckedtwitter.png)`,
  backgroundSize : "cover"
}));

const TwitterCheckedIcon = styled(TwitterIcon)({
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/checkedtwitter.png)`
});

const LinkedinIcon = styled("span")(() => ({
  width          : 40,
  height         : 40,
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/uncheckedlinkedin.png)`,
  backgroundSize : "cover"
}));

const LinkedinCheckedIcon = styled(LinkedinIcon)({
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/checkedlinkedin.png)`
});

const PinterestIcon = styled("span")(() => ({
  width          : 40,
  height         : 40,
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/uncheckedpinterest.png)`,
  backgroundSize : "cover"
}));

const PinterestCheckedIcon = styled(PinterestIcon)({
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/checkedpinterest.png)`
});

const useStyles = makeStyles({
  multiLineEllipsis: {
    // height: "30vh",
    overflow            : "hidden",
    textOverflow        : "ellipsis",
    display             : "-webkit-box",
    "-webkit-line-clamp": props => props.numberLines,
    "-webkit-box-orient": "vertical"
    // "@media (min-height: 600px) and (max-height: 728px)": {
    //   "-webkit-line-clamp": 5,
    // },
  }
});

const PersonaDetails = props => {

  const [ solutioncapability, setSolutioncapability ] = useState("");
  const [ problemorneed, setProblemorneed ] = useState("");
  const [ numberLines, setNumberLines ] = useState(9);
  const [ problemViewmore, setProblemviewmore ] = useState(false);
  const solutionViewmore = false;
  const gridref = useRef();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles({ numberLines });
  const problemRef = createRef();
  const solutionRef = createRef();
  const problemSolutionRef = useRef();

  useEffect(() => {

    if (problemSolutionRef.current) {

      let lines = Math.round(problemSolutionRef.current.clientHeight / 21);

      setNumberLines(lines - 1);
    
    }
  
  }, [ problemSolutionRef ]);

  useEffect(() => {

    if (problemRef.current && solutionRef.current) {

      if (problemRef.current.clientHeight < problemRef.current.scrollHeight)
        setProblemviewmore(true);
      if (solutionRef.current.clientHeight < solutionRef.current.scrollHeight)
        setProblemviewmore(true);
    
    }
  
  }, [ problemRef, solutionRef ]);

  const hashvalue = location.hash.substring(1);

  // let { user, venture } = useSelector((state) => {
  //   return { ...state };
  // });

  useEffect(() => {

    setProblemorneed(props.eachPersona?.SolutionGrid?.problemOrNeed);
    setSolutioncapability(props.eachPersona?.SolutionGrid?.SolutionCapability);
  
  }, []);

  const gridScrollUp = () => {

    gridref.current.scrollTop = 0;
  
  };
  const educationArr = [
    { value: "Bachelor's Degree", label: "Bachelor's Degree" },
    { value: "Professional Degree", label: "Professional Degree" },
    { value: "Master's Degree", label: "Master's Degree" },
    { value: "Doctorate", label: "Doctorate" },
    { value: "Other", label: "Other" }
  ];

  const sortedEducationArr = educationArr.sort((a, b) =>
    a.label > b.label ? 1 : -1
  );

  const occupationArr = [
    { value: "Accountant", label: "Accountant" },
    { value: "Analyst", label: "Analyst" },
    { value: "Executive ", label: "Executive " },
    { value: "Manager", label: "Manager" },
    { value: "Investment Banker", label: "Investment Banker" },
    { value: "Clerk", label: "Clerk" },
    { value: "Officer", label: "Officer" },
    { value: "Homemaker", label: "Homemaker" },
    { value: "Planner", label: "Planner" },
    { value: "Architect", label: "Architect" },
    { value: "Engineer", label: "Engineer" },
    { value: "Teacher", label: "Teacher" },
    { value: "Lecturer", label: "Lecturer" },
    { value: "Professor ", label: "Professor " },
    { value: "Principal", label: "Principal" },
    { value: "Administrator", label: "Administrator" },
    { value: "Counselor", label: "Counselor" },
    { value: "Legal Attorney", label: "Legal Attorney" },
    { value: "Medical Practitioner", label: "Medical Practitioner" },
    { value: "Doctor", label: "Doctor" },
    { value: "Therapist", label: "Therapist" },
    { value: "Scientist", label: "Scientist" },
    { value: "Technical Assistant", label: "Technical Assistant" },
    { value: "Intern / Trainee", label: "Intern / Trainee" },
    { value: "Systems Analyst", label: "Systems Analyst" },
    { value: "Systems Engineer", label: "Systems Engineer" },
    { value: "Software Engineer", label: "Software Engineer" },
    { value: "Software Architect", label: "Software Architect" },
    { value: "Programmer Analyst", label: "Programmer Analyst" },
    { value: "Developer", label: "Developer" },
    { value: "Project Manager", label: "Project Manager" },
    { value: "Business Analyst", label: "Business Analyst" },
    { value: "Quality Assurance Analyst", label: "Quality Assurance Analyst" },
    { value: "Exec. & Management Leader", label: "Exec. & Management Leader" },
    { value: "CXO", label: "CXO" },
    { value: "President", label: "President" },
    {
      value: "Founder / Managing Partner",
      label: "Founder / Managing Partner"
    },
    { value: "Event Planner", label: "Event Planner" },
    { value: "Chef", label: "Chef" },
    { value: "General Manager", label: "General Manager" },
    { value: "Assistant / Worker", label: "Assistant / Worker" },
    { value: "Consultant", label: "Consultant" },
    { value: "Contractor", label: "Contractor" },
    { value: "Technician / Operator", label: "Technician / Operator" },
    { value: "Trainer / Tutor", label: "Trainer / Tutor" },
    { value: "Coach", label: "Coach" },
    {
      value: "Customer service / Help desk Executive",
      label: "Customer service / Help desk Executive"
    },
    { value: "Sales Executive", label: "Sales Executive" },
    { value: "Sales representative", label: "Sales representative" },
    { value: "Marketing Executive", label: "Marketing Executive" },
    { value: "Chauffeur / Driver", label: "Chauffeur / Driver" },
    { value: "Geologist", label: "Geologist" },
    { value: "Research Analyst", label: "Research Analyst" },
    { value: "Designer", label: "Designer" },
    { value: "Writer", label: "Writer" },
    { value: "Journalist", label: "Journalist" },
    { value: "Artist", label: "Artist" },
    { value: "Farmer & Grower", label: "Farmer & Grower" },
    { value: "Unemployed", label: "Unemployed" }
  ];

  const sortedoccupationArr = occupationArr.sort((a, b) =>
    a.label > b.label ? 1 : -1
  );

  const industryArr = [
    { value: "Retail", label: "Retail" },
    { value: "Consumer Goods", label: "Consumer Goods" },
    { value: "E-Commerce", label: "E-Commerce" },
    { value: "Automotive", label: "Automotive" },
    {
      value: "Construction & Real estate",
      label: "Construction & Real estate"
    },
    { value: "Information & Education", label: "Information & Education" },
    { value: "Insurance", label: "Insurance" },
    {
      value: "Banking & Financial services",
      label: "Banking & Financial services"
    },
    { value: "Healthcare services", label: "Healthcare services" },
    {
      value: "Life Sciences, Pharmaceuticals & Biotech",
      label: "Life Sciences, Pharmaceuticals & Biotech"
    },
    { value: "Travel & Hospitality", label: "Travel & Hospitality" },
    {
      value: "Mechanical and electrical engineering",
      label: "Mechanical and electrical engineering"
    },
    { value: "Chemicals, Oil & Power", label: "Chemicals, Oil & Power" },
    {
      value: "Manufacturing, Supply chain & Logistics",
      label: "Manufacturing, Supply chain & Logistics"
    },
    { value: "Transport", label: "Transport" },
    { value: "Telecommunications", label: "Telecommunications" },
    {
      value: "Technology, Software & Services",
      label: "Technology, Software & Services"
    },
    { value: "Textile", label: "Textile" },
    { value: "Media & Entertainment", label: "Media & Entertainment" },
    { value: "Utilities  ", label: "Utilities  " },
    { value: "Govt. & Public services", label: "Govt. & Public services" },
    {
      value: "Non Govt. Private Services",
      label: "Non Govt. Private Services"
    },
    { value: "Agriculture", label: "Agriculture" }
  ];

  const sortedIndustryArr = industryArr.sort((a, b) =>
    a.label > b.label ? 1 : -1
  );

  const agemarks = [
    { value: 1, label: "<12" },
    { value: 2, label: "13-19" },
    { value: 3, label: "20-35" },
    { value: 4, label: "36-60" },
    { value: 5, label: ">60" }
  ];

  const ethnicityArr = [
    { value: "Asian", label: "Asian" },
    { value: "White", label: "White" },
    { value: "African", label: "African" },
    { value: "Mixed", label: "Mixed" },
    { value: "Other", label: "Other" }
  ];

  const sizeArr = [
    { value: "Self Employed", label: "Self Employed" },
    { value: "1-10 Employees", label: "1-10 Employees" },
    { value: "11-50 Employees", label: "11-50 Employees" },
    { value: "51-200 Employees", label: "51-200 Employees" },
    { value: "201-500 Employees", label: "201-500 Employees" },
    { value: "501-1000 Employees", label: "501-1000 Employees" },
    { value: "1001-5000 Employees", label: "1001-5000 Employees" },
    { value: "5001-10000 Employees", label: "5001-10000 Employees" },
    { value: "10,001+ Employees", label: "10,001+ Employees" }
  ];

  const incomemarks = [
    { value: 1, label: "<$50k" },
    { value: 2, label: "$50k-100K" },
    { value: 3, label: "$100k-200K" },
    { value: 4, label: ">$200k" }
  ];

  const handleIncomeValue = value => {

    let newvalue;

    incomemarks.map(mark => {

      if (mark.label === value) {

        newvalue = mark.value;
      
      }
    
    });
    
    return newvalue;
  
  };

  const handleAgeValue = value => {

    let newvalue;

    agemarks.map(mark => {

      if (mark.label === value) {

        newvalue = mark.value;
      
      }
    
    });
    
    return newvalue;
  
  };

  return (
    <Grid item xs={ 12 } id={ styles.maingrid } pb={ 3 } ref={ gridref }>
      <>
        { props.eachPersona && props.segment ?
          <>
            <Box
              sx={ {
                display       : "flex",
                justifyContent: "space-between",
                width         : "85%"
              } }>
              <Typography
                sx={ {
                  display      : "inline-flex",
                  alignSelf    : "center",
                  fontWeight   : "900",
                  color        : "#393939",
                  textTransform: "capitalize"
                } }>
                Persona named { props.eachPersona && props.eachPersona?.name }
              </Typography>
            </Box>
            <Grid container columnGap={ 4 }>
              <Grid
                item
                md={ 3 }
                sx={ {
                  background: "#ffffff 0% 0% no-repeat padding-box",
                  border    : "1px solid #f0f0f0"
                } }>
                <Box id={ styles.detailsbox }>
                  <img
                    src={ `${process.env.REACT_APP_AWS_ASSETS_URL}${props.eachPersona?.avatar}` }
                    width="90px"
                    height="120px"
                  />
                  <Typography id={ styles.detailstypography1 }>Name</Typography>
                  <Typography
                    sx={ { fontSize: "14px", textTransform: "capitalize" } }>
                    { props.eachPersona && props.eachPersona?.name }
                  </Typography>
                </Box>
                <Box id={ styles.detailsbox }>
                  <Typography id={ styles.detailstypography1 }>
                    Segment Name
                  </Typography>
                  <Typography sx={ { fontSize: "14px" } }>
                    { props.segment?.name }
                  </Typography>
                </Box>
                <Box id={ styles.detailsbox }>
                  <Typography id={ styles.detailstypography1 }>Type</Typography>
                  <Typography sx={ { fontSize: "14px" } }>
                    { props.eachPersona?.type }
                  </Typography>
                </Box>
                <Box id={ styles.detailsbox }>
                  <Typography id={ styles.detailstypography1 }>
                    Age range
                  </Typography>
                  <Typography sx={ { fontSize: "14px" } }>
                    { props.eachPersona?.demographics?.age }
                  </Typography>
                </Box>
                <Box id={ styles.detailsbox }>
                  <Typography id={ styles.detailstypography1 }>
                    Highest level of Education
                  </Typography>
                  <Typography sx={ { fontSize: "14px" } }>
                    { props.eachPersona?.demographics?.qualification }
                  </Typography>
                </Box>
                <Box id={ styles.detailsbox }>
                  <Typography id={ styles.detailstypography1 }>
                    Occupation
                  </Typography>
                  <Typography sx={ { fontSize: "14px" } }>
                    { props.eachPersona.demographics.occupation }
                  </Typography>
                </Box>
                <Box id={ styles.detailsbox }>
                  <Typography id={ styles.detailstypography1 }>
                    Industry
                  </Typography>
                  <Typography sx={ { fontSize: "14px" } }>
                    { props.eachPersona.demographics.industry }
                  </Typography>
                </Box>
                <Box id={ styles.detailsbox }>
                  <Typography id={ styles.detailstypography1 }>
                    Geography
                  </Typography>
                  { props.eachPersona?.geographics?.location && props.eachPersona?.geographics?.state ?
                    <Typography sx={ { fontSize: "14px" } }>
                      { props.eachPersona?.geographics?.location },{ " " }
                      { props.eachPersona?.geographics?.state }
                    </Typography>
                    : props.eachPersona.geographics.location &&
                    props.eachPersona.geographics.state === "" ?
                      <Typography sx={ { fontSize: "14px" } }>
                        { props.eachPersona.geographics.location }
                      </Typography>
                      :
                      <Typography sx={ { fontSize: "14px" } }>None</Typography>
                  }
                </Box>
                <Box id={ styles.detailsbox }>
                  <Typography id={ styles.detailstypography1 }>
                    Social Media
                  </Typography>
                  <Grid container mt={ 2 } justifyContent="space-evenly">
                    { props.eachPersona?.individualCharacteristics?.socialMediaActiveness?.map(
                      (socialmedia, index) => {

                        if (socialmedia.ispresent) {

                          return (
                            <img
                              // key={ index }
                              src={ socialmedia.icon }
                              alt={ index }
                              style={ { width: "30px", height: "30px" } }
                            />
                          );
                        
                        }
                      
                      }
                    ) }
                  </Grid>
                </Box>
              </Grid>
              <Grid item md={ 8 }>
                { hashvalue ?
                  hashvalue === "demographics" ?
                    <Grid id={ styles.individualgrid } component="div">
                      <Box display="flex" justifyContent="flex-end">
                        <IconButton
                          component="span"
                          onClick={ () => {

                            history.push({ hash: "" });
                          
                          } }>
                          <HighlightOffIcon />
                        </IconButton>
                      </Box>
                      <Typography id={ styles.individualtitle } gutterBottom>
                        Demographics
                      </Typography>
                      <Box>
                        <Typography variant="body2" fontSize="16px">
                          What is their gender predominantly?
                        </Typography>
                        <RadioGroup
                          row
                          aria-label="gender"
                          defaultValue={ "Mostly Male" }
                          name="gender"
                          value={ props.eachPersona?.demographics?.gender }>
                          <FormControlLabel
                            disabled
                            value="Mostly male"
                            control={ <Radio size="small" /> }
                            label="Mostly Male"
                          />
                          <FormControlLabel
                            disabled
                            value="Mostly female"
                            control={ <Radio size="small" /> }
                            label="Mostly Female"
                          />
                          <FormControlLabel
                            disabled
                            value="Other"
                            control={ <Radio size="small" /> }
                            label="Other"
                          />
                        </RadioGroup>
                      </Box>
                      <Box>
                        <Typography variant="body2" fontSize="16px">
                          What is their age range?
                        </Typography>
                        <Slider
                          size="small"
                          max={ 6 }
                          defaultValue={ 3 }
                          disabled
                          sx={ {
                            "& .MuiSlider-rail": {
                              backgroundColor: "grey !important"
                            },
                            "& .MuiSlider-mark": {
                              backgroundColor: "grey !important",
                              height         : "15px",
                              width          : "1px"
                            }
                          } }
                          step={ null }
                          marks={ agemarks }
                          track={ false }
                          value={ handleAgeValue(props.eachPersona?.demographics?.age) }
                        />
                      </Box>
                      <Box component="div">
                        <Typography variant="body2" fontSize="16px">
                          What is their educational qualification?
                        </Typography>
                        <Select
                          isDisabled
                          options={ sortedEducationArr }
                          placeholder="Select Educational Qualification"
                          value={
                            props.eachPersona.demographics.qualification && {
                              value: props.eachPersona?.demographics?.qualification,
                              label: props.eachPersona?.demographics?.qualification
                            }
                          }
                        />
                      </Box>
                      <Box component="div">
                        <Typography variant="body2" fontSize="16px">
                          What is their occupation/title?
                        </Typography>
                        <Select
                          isDisabled
                          options={ [
                            ...sortedoccupationArr,
                            { value: "Other", label: "Other" }
                          ] }
                          placeholder="Select Occupation"
                          value={
                            props.eachPersona.demographics.occupation && {
                              value: props.eachPersona?.demographics?.occupation,
                              label: props.eachPersona?.demographics?.occupation
                            }
                          }
                        />
                      </Box>
                      <Box component="div">
                        <Typography variant="body2" fontSize="16px">
                          What is their ethnicity?
                        </Typography>
                        <Select
                          isDisabled
                          options={ ethnicityArr }
                          placeholder="Select Ethnicity"
                          value={
                            props.eachPersona.demographics.ethnicity && {
                              value: props.eachPersona?.demographics?.ethnicity,
                              label: props.eachPersona?.demographics?.ethnicity
                            }
                          }
                        />
                      </Box>
                      <Box component="div">
                        <Typography variant="body2" fontSize="16px">
                          What industry do they work in?
                        </Typography>
                        <Select
                          isDisabled
                          options={ [
                            ...sortedIndustryArr,
                            { value: "Other", label: "Other" }
                          ] }
                          placeholder="Select Industry"
                          value={
                            props.eachPersona.demographics.industry && {
                              value: props.eachPersona?.demographics?.industry,
                              label: props.eachPersona?.demographics?.industry
                            }
                          }
                        />
                      </Box>
                      <Box component="div">
                        <Typography variant="body2" fontSize="16px">
                          What is the size of their organization?
                        </Typography>
                        <Select
                          isDisabled
                          options={ sizeArr }
                          placeholder="Select the size of an organization"
                          value={
                            props.eachPersona.demographics.size && {
                              value: props.eachPersona?.demographics?.size,
                              label: props.eachPersona?.demographics?.size
                            }
                          }
                        />
                      </Box>
                      <Box component="div">
                        <Typography variant="body2" fontSize="16px">
                          What is their income level?
                        </Typography>
                        <Slider
                          disabled
                          size="small"
                          max={ 5 }
                          defaultValue={ 3 }
                          sx={ {
                            "& .MuiSlider-rail": {
                              backgroundColor: "grey !important"
                            },
                            "& .MuiSlider-mark": {
                              backgroundColor: "grey !important",
                              height         : "15px",
                              width          : "1px"
                            }
                          } }
                          step={ null }
                          marks={ incomemarks }
                          track={ false }
                          value={ handleIncomeValue(props.eachPersona?.demographics?.income) }
                        />
                      </Box>
                      <Box>
                        <Typography variant="body2" fontSize="16px">
                          Other relevant demographic factors?
                        </Typography>
                        <TextField
                          disabled
                          multiline
                          variant="outlined"
                          minRows={ 2 }
                          maxRows={ 2 }
                          sx={ { width: "100%" } }
                          size="small"
                          name="demographicfactors"
                          value={ props.eachPersona.demographics.demographicfactors }
                        />
                      </Box>
                    </Grid>
                    : hashvalue === "psychographics" ?
                      <Grid id={ styles.individualgrid } component="div">
                        <Box display="flex" justifyContent="flex-end">
                          <IconButton
                            component="span"
                            onClick={ () => {

                              history.push({ hash: "" });
                            
                            } }>
                            <HighlightOffIcon />
                          </IconButton>
                        </Box>
                        <Typography id={ styles.individualtitle } gutterBottom>
                        Psychographics
                        </Typography>
                        <Box>
                          <Typography variant="body2" fontSize="16px">
                          What are their personality traits?
                          </Typography>
                          <TextField
                            disabled
                            multiline
                            variant="outlined"
                            minRows={ 2 }
                            maxRows={ 2 }
                            sx={ { width: "100%" } }
                            value={ props.eachPersona.psychographics.personalityTraits }
                          />
                        </Box>
                        <Box>
                          <Typography variant="body2" fontSize="16px">
                          What are their opinions or beliefs or attitude ?
                          </Typography>
                          <TextField
                            disabled
                            multiline
                            variant="outlined"
                            minRows={ 2 }
                            maxRows={ 2 }
                            sx={ { width: "100%" } }
                            value={ props.eachPersona.psychographics.opinionsOrBeliefs }
                          />
                        </Box>
                        <Box>
                          <Typography variant="body2" fontSize="16px">
                          What inspires them or their life goals?
                          </Typography>
                          <TextField
                            disabled
                            multiline
                            variant="outlined"
                            minRows={ 2 }
                            maxRows={ 2 }
                            sx={ { width: "100%" } }
                            value={ props.eachPersona.psychographics.inspiresOrLifeGoals }
                          />
                        </Box>
                        <Box>
                          <Typography variant="body2" fontSize="16px">
                          What is their interest or hobbies?
                          </Typography>
                          <TextField
                            disabled
                            multiline
                            variant="outlined"
                            minRows={ 2 }
                            maxRows={ 2 }
                            sx={ { width: "100%" } }
                            value={ props.eachPersona.psychographics.interestOrHobbies }
                          />
                        </Box>
                        <Box>
                          <Typography variant="body2" fontSize="16px">
                          Other relevant psychographic factors
                          </Typography>
                          <TextField
                            disabled
                            multiline
                            variant="outlined"
                            minRows={ 2 }
                            maxRows={ 2 }
                            sx={ { width: "100%" } }
                            value={ props.eachPersona.psychographics.psychographicFactors }
                          />
                        </Box>
                      </Grid>
                      : hashvalue === "buyingbehaviour" ?
                        <Grid id={ styles.individualgrid } component="div">
                          <Box display="flex" justifyContent="flex-end">
                            <IconButton
                              component="span"
                              onClick={ () => {

                                history.push({ hash: "" });
                              
                              } }>
                              <HighlightOffIcon />
                            </IconButton>
                          </Box>
                          <Typography id={ styles.individualtitle } gutterBottom>
                        Buying Behaviour
                          </Typography>
                          <Box>
                            <Typography variant="body2" fontSize="16px">
                          When and why they buy a product or solution?
                            </Typography>
                            <TextField
                              disabled
                              multiline
                              variant="outlined"
                              minRows={ 2 }
                              maxRows={ 2 }
                              sx={ { width: "100%" } }
                              value={ props.eachPersona.buyingBehaviour.buySolution }
                            />
                          </Box>
                          <Box>
                            <Typography variant="body2" fontSize="16px">
                          How much & how often do they buy?
                            </Typography>
                            <TextField
                              disabled
                              multiline
                              variant="outlined"
                              minRows={ 2 }
                              maxRows={ 2 }
                              sx={ { width: "100%" } }
                              value={ props.eachPersona.buyingBehaviour.oftenBuy }
                            />
                          </Box>
                          <Box>
                            <Typography variant="body2" fontSize="16px">
                          How loyal are they to the brand?
                            </Typography>
                            <TextField
                              disabled
                              multiline
                              variant="outlined"
                              minRows={ 2 }
                              maxRows={ 2 }
                              sx={ { width: "100%" } }
                              value={ props.eachPersona.buyingBehaviour.loyal }
                            />
                          </Box>
                          <Box>
                            <Typography variant="body2" fontSize="16px">
                          What influences their buying decision?
                            </Typography>
                            <TextField
                              disabled
                              multiline
                              variant="outlined"
                              minRows={ 2 }
                              maxRows={ 2 }
                              sx={ { width: "100%" } }
                              value={ props.eachPersona.buyingBehaviour.influences }
                            />
                          </Box>
                          <Box>
                            <Typography variant="body2" fontSize="16px">
                          Other relevant buying behaviours
                            </Typography>
                            <TextField
                              disabled
                              multiline
                              variant="outlined"
                              minRows={ 2 }
                              maxRows={ 2 }
                              sx={ { width: "100%" } }
                              value={ props.eachPersona.buyingBehaviour.buyingBehaviours }
                            />
                          </Box>
                        </Grid>
                        : hashvalue === "individualcharacteristics" ?
                          <Grid id={ styles.individualgrid } component="div">
                            <Box display="flex" justifyContent="flex-end">
                              <IconButton
                                component="span"
                                onClick={ () => {

                                  history.push({ hash: "" });
                                
                                } }>
                                <HighlightOffIcon />
                              </IconButton>
                            </Box>
                            <Typography id={ styles.individualtitle } gutterBottom>
                        Individual Characteristics
                            </Typography>
                            <Box>
                              <Typography variant="body2" fontSize="16px">
                          What are they trying to achieve? (jobs-to-do /
                          outcomes)
                              </Typography>
                              <TextField
                                disabled
                                multiline
                                variant="outlined"
                                minRows={ 1 }
                                maxRows={ 1 }
                                sx={ { width: "100%" } }
                                value={ props.eachPersona.individualCharacteristics.outcomes }
                              />
                            </Box>
                            <Box>
                              <Typography variant="body2" fontSize="16px">
                          What Are Their Goals or Objectives?
                              </Typography>
                              <TextField
                                disabled
                                multiline
                                variant="outlined"
                                minRows={ 1 }
                                maxRows={ 1 }
                                sx={ { width: "100%" } }
                                value={
                                  props.eachPersona.individualCharacteristics.goalsOrObjectives
                                }
                              />
                            </Box>
                            <Box>
                              <Typography variant="body2" fontSize="16px">
                          What are their motivations?
                              </Typography>
                              <TextField
                                disabled
                                multiline
                                variant="outlined"
                                minRows={ 1 }
                                maxRows={ 1 }
                                sx={ { width: "100%" } }
                                value={ props.eachPersona.individualCharacteristics.motivations }
                              />
                            </Box>
                            <Box>
                              <Typography variant="body2" fontSize="16px">
                          What decisions do they take?
                              </Typography>
                              <TextField
                                disabled
                                multiline
                                variant="outlined"
                                minRows={ 1 }
                                maxRows={ 1 }
                                sx={ { width: "100%" } }
                                value={ props.eachPersona.individualCharacteristics.decisions }
                              />
                            </Box>
                            <Box>
                              <Typography variant="body2" fontSize="16px">
                          What are their job responsibilities?
                              </Typography>
                              <TextField
                                disabled
                                multiline
                                variant="outlined"
                                minRows={ 1 }
                                maxRows={ 1 }
                                sx={ { width: "100%" } }
                                value={
                                  props.eachPersona.individualCharacteristics
                                    .jobResponsibilities
                                }
                              />
                            </Box>
                            <Box>
                              <Typography variant="body2" fontSize="16px">
                          How is their Job outcome measured?
                              </Typography>
                              <TextField
                                disabled
                                multiline
                                variant="outlined"
                                minRows={ 1 }
                                maxRows={ 1 }
                                sx={ { width: "100%" } }
                                value={ props.eachPersona.individualCharacteristics.jobOutcome }
                              />
                            </Box>
                            <Box>
                              <Typography variant="body2" fontSize="16px">
                          Who do they report to?
                              </Typography>
                              <TextField
                                disabled
                                multiline
                                variant="outlined"
                                minRows={ 1 }
                                maxRows={ 1 }
                                sx={ { width: "100%" } }
                                value={ props.eachPersona.individualCharacteristics.report }
                              />
                            </Box>
                            <Box>
                              <Typography variant="body2" fontSize="16px">
                          What Tools Do They Need to Do Their Job?
                              </Typography>
                              <TextField
                                disabled
                                multiline
                                variant="outlined"
                                minRows={ 1 }
                                maxRows={ 1 }
                                sx={ { width: "100%" } }
                                value={ props.eachPersona.individualCharacteristics.tools }
                              />
                            </Box>
                            <Box>
                              <Typography variant="body2" fontSize="16px">
                          Which social media platforms do they actively use?
                              </Typography>
                              <FormGroup row>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      disabled
                                      checkedIcon={ <FacebookCheckedIcon /> }
                                      icon={ <FacebookIcon /> }
                                      checked={
                                        props.eachPersona.individualCharacteristics
                                          .socialMediaActiveness[0].ispresent
                                      }
                                      disableRipple
                                    />
                                  }
                                  label=""
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      disabled
                                      checkedIcon={ <InstagramCheckedIcon /> }
                                      icon={ <InstagramIcon /> }
                                      checked={
                                        props.eachPersona.individualCharacteristics
                                          .socialMediaActiveness[1].ispresent
                                      }
                                      disableRipple
                                    />
                                  }
                                  label=""
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      disabled
                                      checkedIcon={ <TwitterCheckedIcon /> }
                                      icon={ <TwitterIcon /> }
                                      checked={
                                        props.eachPersona.individualCharacteristics
                                          .socialMediaActiveness[2].ispresent
                                      }
                                      disableRipple
                                    />
                                  }
                                  label=""
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      disabled
                                      checkedIcon={ <LinkedinCheckedIcon /> }
                                      icon={ <LinkedinIcon /> }
                                      checked={
                                        props.eachPersona.individualCharacteristics
                                          .socialMediaActiveness[3].ispresent
                                      }
                                      disableRipple
                                    />
                                  }
                                  label=""
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      disabled
                                      checkedIcon={ <PinterestCheckedIcon /> }
                                      icon={ <PinterestIcon /> }
                                      checked={
                                        props.eachPersona.individualCharacteristics
                                          .socialMediaActiveness[4].ispresent
                                      }
                                      disableRipple
                                    />
                                  }
                                  label=""
                                />
                              </FormGroup>
                            </Box>
                            <Box>
                              <Typography variant="body2" fontSize="16px">
                          How do they gain relevant information about their
                          needs?
                              </Typography>
                              <TextField
                                disabled
                                multiline
                                variant="outlined"
                                minRows={ 1 }
                                maxRows={ 1 }
                                sx={ { width: "100%" } }
                                value={
                                  props.eachPersona.individualCharacteristics.informationNeeds
                                }
                              />
                            </Box>
                            <Box>
                              <Typography variant="body2" fontSize="16px">
                          Other relevant characteristics
                              </Typography>
                              <TextField
                                disabled
                                multiline
                                variant="outlined"
                                minRows={ 1 }
                                maxRows={ 1 }
                                sx={ { width: "100%" } }
                                value={
                                  props.eachPersona.individualCharacteristics
                                    .relevantCharacteristics
                                }
                              />
                            </Box>
                          </Grid>
                          : hashvalue === "problem" ?
                            <Grid
                              id={ styles.individualgrid }
                              component="div"
                              // onClick={() => {
                              //   history.push(`/userpersonadetails/${personaid}`);
                              // }}
                            >
                              <Box display="flex" justifyContent="flex-end">
                                <IconButton
                                  component="span"
                                  onClick={ () => {

                                    history.push({ hash: "" });
                                  
                                  } }>
                                  <HighlightOffIcon />
                                </IconButton>
                              </Box>
                              <Typography id={ styles.individualtitle } gutterBottom>
                        Problem
                              </Typography>
                              <Typography sx={ { height: "100vh" } }>
                                { problemorneed }
                              </Typography>
                            </Grid>
                            : hashvalue === "solution" ?
                              <Grid
                                id={ styles.individualgrid }
                                component="div"
                                // onClick={() => {
                                //   history.push(`/userpersonadetails/${personaid}`);
                                // }}
                              >
                                <Box display="flex" justifyContent="flex-end">
                                  <IconButton
                                    component="span"
                                    onClick={ () => {

                                      history.push({ hash: "" });
                                    
                                    } }>
                                    <HighlightOffIcon />
                                  </IconButton>
                                </Box>
                                <Typography id={ styles.individualtitle } gutterBottom>
                        Solution
                                </Typography>
                                <Typography sx={ { height: "100vh" } }>
                                  { solutioncapability }
                                </Typography>
                              </Grid>
                              :
                              <></>
                  
                  :
                  <Grid container justifyContent="space-around" rowGap={ 2 }>
                    <Grid item md={ 5 } id={ styles.detailsgriditem1 } p={ 2 }>
                      <Typography id={ styles.individualheading } gutterBottom>
                        Demographics
                      </Typography>
                      <Box mb={ 1 }>
                        <Typography id={ styles.individualquestion } gutterBottom>
                          Ethnicity
                        </Typography>
                        <Typography id={ styles.individualanswer } gutterBottom>
                          { props.eachPersona.demographics.ethnicity }
                        </Typography>
                      </Box>
                      <Box mb={ 1 }>
                        <Typography id={ styles.individualquestion } gutterBottom>
                          Highest Level of Education
                        </Typography>
                        <Typography id={ styles.individualanswer } gutterBottom>
                          { props.eachPersona.demographics.qualification }
                        </Typography>
                      </Box>
                      <Box mb={ 1 }>
                        <Typography id={ styles.individualquestion } gutterBottom>
                          Income Level
                        </Typography>
                        <Typography id={ styles.individualanswer } gutterBottom>
                          { props.eachPersona.demographics.income }
                        </Typography>
                      </Box>
                      <img
                        src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/full-screen.png` }
                        id={ styles.individualexpand }
                        onClick={ () => {

                          history.push({ hash: "demographics" });
                          gridScrollUp();
                        
                        } }
                      />
                    </Grid>
                    <Grid item md={ 5 } id={ styles.detailsgriditem1 } p={ 2 }>
                      <Typography id={ styles.individualheading } gutterBottom>
                        Psychographics
                      </Typography>
                      <Box mb={ 1 }>
                        <Typography id={ styles.individualquestion } gutterBottom>
                          Personality Traits
                        </Typography>
                        <Typography id={ styles.individualanswer } gutterBottom>
                          { props.eachPersona.psychographics.personalityTraits }
                        </Typography>
                      </Box>
                      <Box mb={ 1 }>
                        <Typography id={ styles.individualquestion } gutterBottom>
                          Opinions/Beliefs/Attitude
                        </Typography>
                        <Typography id={ styles.individualanswer } gutterBottom>
                          { props.eachPersona.psychographics.opinionsOrBeliefs }
                        </Typography>
                      </Box>
                      <Box mb={ 1 }>
                        <Typography id={ styles.individualquestion } gutterBottom>
                          Life Goals
                        </Typography>
                        <Typography id={ styles.individualanswer } gutterBottom>
                          { props.eachPersona.psychographics.inspiresOrLifeGoals }
                        </Typography>
                      </Box>
                      <img
                        src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/full-screen.png` }
                        id={ styles.individualexpand }
                        onClick={ () => {

                          history.push({ hash: "psychographics" });
                          gridScrollUp();
                        
                        } }
                      />
                    </Grid>
                    <Grid item md={ 5 } id={ styles.detailsgriditem1 } p={ 2 }>
                      <Typography id={ styles.individualheading } gutterBottom>
                        Buying Behaviour
                      </Typography>
                      <Box mb={ 1 }>
                        <Typography id={ styles.individualquestion } gutterBottom>
                          When & Why
                        </Typography>
                        <Typography id={ styles.individualanswer } gutterBottom>
                          { props.eachPersona.buyingBehaviour.buySolution }
                        </Typography>
                      </Box>
                      <Box mb={ 1 }>
                        <Typography id={ styles.individualquestion } gutterBottom>
                          How much & How often
                        </Typography>
                        <Typography id={ styles.individualanswer } gutterBottom>
                          { props.eachPersona.buyingBehaviour.oftenBuy }
                        </Typography>
                      </Box>
                      <Box mb={ 1 }>
                        <Typography id={ styles.individualquestion } gutterBottom>
                          Brand Loyalty
                        </Typography>
                        <Typography id={ styles.individualanswer } gutterBottom>
                          { props.eachPersona.buyingBehaviour.loyal }
                        </Typography>
                      </Box>
                      <Box mb={ 1 }>
                        <Typography id={ styles.individualquestion } gutterBottom>
                          Influences
                        </Typography>
                        <Typography id={ styles.individualanswer } gutterBottom>
                          { props.eachPersona.buyingBehaviour.influences }
                        </Typography>
                      </Box>
                      <img
                        src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/full-screen.png` }
                        id={ styles.individualexpand }
                        onClick={ () => {

                          history.push({ hash: "buyingbehaviour" });
                          gridScrollUp();
                        
                        } }
                      />
                    </Grid>
                    <Grid item md={ 5 } id={ styles.detailsgriditem1 } p={ 2 }>
                      <Typography id={ styles.individualheading } gutterBottom>
                        Individual Characteristics
                      </Typography>
                      <Box mb={ 1 }>
                        <Typography id={ styles.individualquestion } gutterBottom>
                          Outcome
                        </Typography>
                        <Typography id={ styles.individualanswer } gutterBottom>
                          { props.eachPersona.individualCharacteristics.outcomes }
                        </Typography>
                      </Box>
                      <Box mb={ 1 }>
                        <Typography id={ styles.individualquestion } gutterBottom>
                          Goals and Objectives
                        </Typography>
                        <Typography id={ styles.individualanswer } gutterBottom>
                          { props.eachPersona.individualCharacteristics.goalsOrObjectives }
                        </Typography>
                      </Box>
                      <Box mb={ 1 }>
                        <Typography id={ styles.individualquestion } gutterBottom>
                          Motivations
                        </Typography>
                        <Typography id={ styles.individualanswer } gutterBottom>
                          { props.eachPersona.individualCharacteristics.motivations }
                        </Typography>
                      </Box>
                      <Box mb={ 1 }>
                        <Typography id={ styles.individualquestion } gutterBottom>
                          Decisions
                        </Typography>
                        <Typography id={ styles.individualanswer } gutterBottom>
                          { props.eachPersona.individualCharacteristics.decisions }
                        </Typography>
                      </Box>
                      <img
                        src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/full-screen.png` }
                        id={ styles.individualexpand }
                        onClick={ () => {

                          history.push({ hash: "individualcharacteristics" });
                          gridScrollUp();
                        
                        } }
                      />
                    </Grid>
                    <Grid
                      item
                      md={ 5 }
                      // id={styles.detailsgriditem1}
                      p={ 1 }
                      sx={ {
                        height         : "40vh",
                        backgroundColor: problemorneed
                          ? "#ffffff"
                          : "#cccccc !important",
                        border: problemorneed
                          ? "1px solid #f0f0f0"
                          : "1px solid #B1AAAA !important",
                        display      : "flex",
                        flexDirection: "column"
                      } }>
                      <Box sx={ { height: "30vh" } }>
                        <Typography fontWeight="bold" gutterBottom>
                          Problem
                        </Typography>
                        <Typography
                          sx={ { fontSize: "12px" } }
                          className={ classes.multiLineEllipsis }
                          ref={ problemRef }>
                          { problemorneed }
                        </Typography>
                        { problemViewmore &&
                          <Typography
                            sx={ {
                              fontSize: "12px",
                              color   : "#071AFF",
                              cursor  : "pointer"
                            } }
                            onClick={ () => {

                              if (problemorneed) {

                                history.push({ hash: "problem" });
                                gridScrollUp();
                              
                              }
                            
                            } }>
                            View more...
                          </Typography>
                        }
                      </Box>
                      <img
                        src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/full-screen.png` }
                        id={ styles.individualexpand }
                        onClick={ () => {

                          if (problemorneed) {

                            history.push({ hash: "problem" });
                            gridScrollUp();
                          
                          }
                        
                        } }
                      />
                    </Grid>
                    <Grid
                      item
                      md={ 5 }
                      p={ 1 }
                      sx={ {
                        height         : "40vh",
                        backgroundColor: solutioncapability
                          ? "#ffffff"
                          : "#cccccc !important",
                        border: solutioncapability
                          ? "1px solid #f0f0f0"
                          : "1px solid #B1AAAA !important",
                        display      : "flex",
                        flexDirection: "column"
                      } }>
                      <Box sx={ { height: "30vh" } }>
                        <Typography fontWeight="bold" gutterBottom>
                          Solution
                        </Typography>
                        <Typography
                          sx={ { fontSize: "12px" } }
                          className={ classes.multiLineEllipsis }
                          ref={ solutionRef }>
                          { solutioncapability }
                        </Typography>
                        { solutionViewmore &&
                          <Typography
                            sx={ {
                              fontSize: "12px",
                              color   : "#071AFF",
                              cursor  : "pointer"
                            } }
                            onClick={ () => {

                              if (solutioncapability) {

                                history.push({ hash: "solution" });
                                gridScrollUp();
                              
                              }
                            
                            } }>
                            View more...
                          </Typography>
                        }
                      </Box>
                      <img
                        src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/full-screen.png` }
                        id={ styles.individualexpand }
                        onClick={ () => {

                          if (solutioncapability) {

                            history.push({ hash: "solution" });
                            gridScrollUp();
                          
                          }
                        
                        } }
                      />
                    </Grid>
                  </Grid>
                }
              </Grid>
            </Grid>
          </>
          :
          <></>
        }
      </>
    </Grid>
  );

};

export default PersonaDetails;
