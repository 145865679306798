import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Box, Dialog,
  DialogContent, IconButton, Menu,
  MenuItem,
  TextField, Typography
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import DeleteCancel from "./DeleteCancel";

// inner box comment
function IndividualComment (props) {

  const comment = props.comment;
  const [ isedit, setIsedit ] = useState(false);
  const [ commentText, setCommentText ] = useState("");
  const [ anchorEl, setAnchorEl ] = React.useState(null);
  const [ deletedialog, setDeletedialog ] = useState(false);
  const menuOpen = Boolean(anchorEl);

  const handleClickMenu = event => {

    setAnchorEl(event.currentTarget);
  
  };
  const handleCloseMenu = () => {

    setAnchorEl(null);
  
  };

  const deleteDialogopen = () => {

    setDeletedialog(true);
  
  };
  const deleteDialogclose = () => {

    setDeletedialog(false);
  
  };

  const { user, venture } = useSelector(state => ({ ...state }));

  return (
    <Box sx={ { display: "flex", flexDirection: "column" } } key={ comment._id }>
      <Box
        p={ 1 }
        sx={ {
          display   : "flex",
          // flexDirectiom: "column",
          // justifyContent: "space-between",
          alignItems: "center"
        } }
      >
        { comment?.user?.profileIcon ?
          <Box
            sx={ {
              width : "35px",
              height: "35px"
            } }
          >
            <img
              // style={{ width: "30%", borderRadius: "50%", height: "30%" }}
              style={ {
                // width: "24%",
                borderRadius: "50px",
                // height: "24%",
                border      : "1px solid #707070",
                width       : "35px",
                height      : "35px"
              } }
              src={ comment.user.profileIcon }
              alt=""
            />
          </Box>
          :
          // <img
          //   src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/user-profile.png`}
          //   alt=""
          //   style={{
          //     width: "24%",
          //     borderRadius: "50%",
          //     height: "24%",
          //     border: "1px solid #707070",
          //   }}
          // />
          <AccountCircleIcon sx={ { fontSize: "35px", color: "#707070" } } />
        }

        <Box
          pl={ 1 }
          // pr={1}
          sx={ {
            width         : "100%",
            display       : "flex",
            // flexDirection: "column",
            // border: 1,
            justifyContent: "space-between"
          } }
        >
          <Box sx={ { width: "6vw", overflowWrap: "break-word" } }>
            <Typography
              // nowrap
              variant="subtitle1"
              // gutterBottom
              component="div"
              style={ {
                // fontSize: "74%",
                fontWeight: "bold",
                // paddingTop: "14px",
                fontSize  : "12px"
              } }
            >
              { comment?.user?.firstname } { comment?.user?.lastname }
            </Typography>
          </Box>
          {/* ----------------- */ }
          <Box>
            { user._id === comment?.user?._id && !isedit ?
              <Box
                // mt={-2}
                // mt={-1}
                sx={ {
                  display       : "flex",
                  flexDirection : "column",
                  alignItems    : "center",
                  justifyContent: "center"
                } }
              >
                <IconButton
                  disableTouchRipple
                  disableFocusRipple
                  onClick={ e => {

                    e.stopPropagation();
                    handleClickMenu(e);
                  
                  } }
                  sx={ { padding: "0px", marginRight: "8px" } }
                // pl={5}
                >
                  <MoreHorizIcon
                    p={ 1 }
                    sx={ { color: "#707070" } }
                    fontSize="medium"
                  />
                </IconButton>

                <Menu
                  anchorEl={ anchorEl }
                  open={ menuOpen }
                  onClose={ e => {

                    e.stopPropagation();
                    handleCloseMenu();
                  
                  } }
                  anchorOrigin={ {
                    vertical  : "bottom",
                    horizontal: "left"
                  } }
                  sx={ {
                    "& .MuiMenu-list": {
                      padding        : "0px",
                      backgroundColor: "#707070",
                      color          : "white"
                    }
                  } }
                >
                  <MenuItem
                    sx={ { fontSize: "12px" } }
                    // disableGutters="true"
                    onClick={ () => {

                      setCommentText(comment?.text);
                      handleCloseMenu();
                      setIsedit(true);
                    
                    } }
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    sx={ { fontSize: "12px", borderTop: "solid 1px" } }
                    onClick={ async () => {

                      deleteDialogopen();
                      handleCloseMenu();
                    
                    } }
                  >
                    <Box sx={ { paddingRight: "7px" } }>Delete</Box>
                  </MenuItem>
                </Menu>
              </Box>
              : venture.userRole === "Owner" && !isedit ?
                <Box
                // mt={-2}
                // mt={-1}
                  sx={ {
                    display       : "flex",
                    flexDirection : "column",
                    alignItems    : "center",
                    justifyContent: "center"
                  } }
                >
                  <IconButton
                    disableTouchRipple
                    disableFocusRipple
                    onClick={ e => {

                      e.stopPropagation();
                      handleClickMenu(e);
                    
                    } }
                    sx={ { padding: "0px", marginRight: "8px" } }
                    // pl={5}
                  >
                    <MoreHorizIcon
                      p={ 1 }
                      sx={ { color: "#707070" } }
                      fontSize="medium"
                    />
                  </IconButton>

                  <Menu
                    anchorEl={ anchorEl }
                    open={ menuOpen }
                    onClose={ e => {

                      e.stopPropagation();
                      handleCloseMenu();
                    
                    } }
                    anchorOrigin={ {
                      vertical  : "bottom",
                      horizontal: "left"
                    } }
                    sx={ {
                      "& .MuiMenu-list": {
                        padding        : "0px",
                        backgroundColor: "#707070",
                        color          : "white"
                      }
                    } }
                  >
                    <MenuItem
                      sx={ { fontSize: "12px" } }
                      onClick={ async () => {

                        deleteDialogopen();
                        handleCloseMenu();
                      
                      } }
                    >
                      <Box sx={ { paddingRight: "7px" } }>Delete</Box>
                    </MenuItem>
                  </Menu>
                </Box>
                :
                <></>
            }
            <Dialog
              open={ deletedialog }
              PaperProps={ {
                style: {
                  borderRadius  : "20px",
                  backdropFilter: "blur(45px)",
                  background    : "#FFFFFF 0% 0% no-repeat padding-box",
                  boxShadow     : "0px 3px 6px #00000029",
                  border        : "1px solid #E0E1E2"
                }
              } }
            >
              <DialogContent>
                <DeleteCancel
                  name={
                    "Deleting this comment will also delete all replies on this comment. Do you want to continue?"
                  }
                  deleteClick={ async e => {

                    e.stopPropagation();
                    await props.deleteComment(comment._id);
                    deleteDialogclose();
                  
                  } }
                  cancelClick={ () => {

                    deleteDialogclose();
                  
                  } }
                />
              </DialogContent>
            </Dialog>
            {/* ;;;;;;;; */ }

            <Typography
              variant="body2"
              // gutterBottom
              // style={{ fontSize: "1.6vh" }}
              style={ { fontSize: "10px", paddingRight: "5px" } }
            >
              <span style={ { float: "right" } }>
                { moment(comment.createdAt).format("D MMM") }
              </span>
            </Typography>
          </Box>
          {/* ----------------- */ }
          {/* <Typography
            variant="body2"
            // gutterBottom
            // style={{ fontSize: "1.6vh" }}
            style={{ fontSize: "10px" }}
          >
            <span style={{ float: "right" }}>
              {moment(comment.createdAt).format("D MMM")}
            </span>
          </Typography> */}
        </Box>
      </Box>
      <Box
        onBlur={ () => {

          setIsedit(false);
        
        } }
      >
        { isedit ?
          <TextField
            sx={ {
              "& .MuiOutlinedInput-root": { borderRadius: "10px" },
              padding                   : "8px 0px",
              paddingLeft               : "8px",
              width                     : "90%"
            } }
            inputProps={ {
              style: {
                // fontSize: "1.4vh",
                fontSize  : "10px",
                fontWeight: "bold",
                padding   : "0px 0px",
                lineHeight: "15px",
                color     : "#707070"
              }
            } }
            // className={classes.textfield}
            variant="outlined"
            size="small"
            fullWidth
            multiline
            maxRows={ 2 }
            minRows={ 2 }
            value={ commentText }
            onChange={ e => {

              setCommentText(e.target.value);
            
            } }
            onBlur={ () => {

              if (commentText !== "") {

                props.updateComment(comment._id, commentText);
                setIsedit(false);
              
              }
            
            } }
            onKeyDown={ e => {

              if (e.key === "Enter") {

                e.preventDefault();
                if (commentText !== "") {

                  props.updateComment(comment._id, commentText);
                  setIsedit(false);
                
                }
              
              }
            
            } }
          />
          :
          <Box sx={ { width: "12.8vw", overflowWrap: "break-word" } }>
            <Typography
              p={ 1 }
              variant="body2"
              gutterBottom
              style={ { fontSize: "10px" } }
            // style={{ fontSize: "1.5vh" }}
            >
              { comment.text }
            </Typography>
          </Box>
        }
      </Box>
      {/* <Box
        // mt={-2}
        mt={-1}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <IconButton
          disableTouchRipple
          disableFocusRipple
          onClick={(e) => {
            e.stopPropagation();
            handleClickMenu(e);
          }}
          sx={{ padding: "0px", marginRight: "8px" }}
          // pl={5}
        >
          
          <MoreHorizIcon p={1} sx={{ color: "#707070" }} fontSize="medium" />
        </IconButton>
       
        <Menu
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={(e) => {
            e.stopPropagation();
            handleCloseMenu();
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sx={{
            "& .MuiMenu-list": {
              padding: "0px",
              backgroundColor: "#707070",
              color: "white",
            },
          }}
          
        >
          <MenuItem
            sx={{ fontSize: "12px" }}
            // disableGutters="true"
            onClick={(e) => {
              setCommentText(comment.text);
              handleCloseMenu();
              setIsedit(true);
            }}
          >
          
            Edit
          </MenuItem>
          <MenuItem
            sx={{ fontSize: "12px", borderTop: "solid 1px" }}
            
            onClick={async () => {
              await props.deleteComment(comment._id);
              handleCloseMenu();
            }}
          >
           
            <Box sx={{ paddingRight: "7px" }}>Delete</Box>
          
          </MenuItem>
        </Menu>
        
      </Box> */}
    </Box>
  );

}

export default IndividualComment;
