import axios from "./axiosService";

// import axios from "axios";

export const getVentureFlowcharts = async customerid => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/get_ventureflowchart`,
    { customerid }
  );

};

export const getTemplates = async () => {

  return await axios.get(`${process.env.REACT_APP_ADDRESS}/get_template`);

};

export const getFlowchartById = async (flowchartid, customerid) => {

  return await axios.post(`${process.env.REACT_APP_ADDRESS}/userflowchart`, {
    flowchartid,
    customerid
  });

};

export const deleteFlowchartById = async (customerid, flowchartid) => {

  return await axios.post(`${process.env.REACT_APP_ADDRESS}/delete_flowchart`, {
    customerid,
    flowchartid
  });

};
