import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box, Button,
  Dialog,
  DialogContent, IconButton, TextField, Typography
} from "@mui/material";
import React, { useState } from "react";
// import { useHistory } from "react-router-dom";
import PitchBusinessModelMonetisation from "../PitchReferenceLinks/PitchBusinessModelMonetisation";
import ReferenceLinkTooltip from "../PitchReferenceLinks/ReferenceLinkTooltip";
import SectionHeader from "../SectionHeader";
import styles from "./BusinessModel.module.css";

export default function BusinessModel ({
  logoimg,
  slides,
  isRender,
  setSlides,
  isPublished,
  ...props
}) {

  const [ pitchBusinessModelDialog, setPitchBusinessModelDialog ] =
    useState(false);
  const slide = slides[isRender];

  const handleChange = (name, value, columnindex) => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;

    let newrevenues = [ ...newSlides[isRender]["revenues"] ];

    newrevenues.map((revenue, revenueindex) => {

      if (revenueindex === columnindex) {

        newrevenues[revenueindex][name] = value;
      
      }
    
    });
    newSlides[isRender]["revenues"] = newrevenues;

    setSlides(newSlides);
  
  };

  const addRevenue = () => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;

    newSlides[isRender]["revenues"].push({
      name : `Revenue Stream ${newSlides[isRender]["revenues"].length + 1}`,
      value: ""
    });

    setSlides(newSlides);
  
  };

  const removeRevenue = columnindex => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    let newrevenues = newSlides[isRender]["revenues"].filter(
      (revenue, revenueindex) => revenueindex !== columnindex
    );

    newSlides[isRender]["revenues"] = newrevenues;

    setSlides(newSlides);
  
  };

  const handleDetailsChange = (name, value) => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    newSlides[isRender][name] = value;
    setSlides(newSlides);
  
  };

  // const history = useHistory();
  // const pathname = history.location.pathname.substring(1);

  const PitchBusinessModelDialogopen = () => {

    setPitchBusinessModelDialog(true);
  
  };
  const PitchBusinessModelDialogclose = () => {

    setPitchBusinessModelDialog(false);
  
  };

  return (
    <Box>
      <SectionHeader
        isEdit={ props.isEdit }
        Collapsecomment={ props.Collapsecomment }
        collapseCommentFunction={ props.collapseCommentFunction }
        mainHead={ slide.name }
        handleTitleChange={ handleDetailsChange }
        logoimg={ logoimg }
        isPublished={ isPublished }
      />
      <Box
        id={ styles.mainBox }
        // sx={{
        //   width: "100%",
        //   height: "60vh",

      //   display: "flex",
      //   alignItems: "center",
      //   justifyContent: "space-between",
      //   // border: 1,
      // }}
      >
        {/* ---1----- */ }
        <Box
          sx={ {
            width         : "100%",
            display       : "flex",
            alignItems    : "center",
            justifyContent: "center",

            position: "relative"
          } }
        >
          { slide.revenues.map((revenue, revenueindex) => {

            return (
              <Box
                id={ styles.individualRevenueBox }
              // sx={{
              //   display: "flex",
              //   flexDirection: "column",
              //   width: "100%",
              //   alignItems: "center",
              //   width:
              //     slide.revenues.length === 2
              //       ? "50%"
              //       : slide.revenues.length === 3
              //       ? "33%"
              //       : slide.revenues.length === 4
              //       ? "25%"
              //       : "100%",
              // }}
              >
                <Box
                  id={ styles.revenueTypoBox }
                // sx={{
                //   display: "flex",
                //   justifyContent: "center",
                //   alignItems: "center",
                //   marginBottom: "20px",
                //   width: "90%",
                // }}
                >
                  { props.isEdit ?
                    <Box sx={ { width: "100%", position: "relative" } }>
                      <TextField
                        value={ revenue.name }
                        onChange={ e => {

                          handleChange("name", e.target.value, revenueindex);
                        
                        } }
                        inputProps={ { maxLength: 20 } }
                        fullWidth
                        sx={ {
                          // "& .MuiOutlinedInput-root": {
                          //   padding: "0px 0px",
                          // },
                          "& .MuiInputBase-input": {
                            fontSize  : "16px",
                            padding   : "1px 10px",
                            border    : "none",
                            textAlign : "center",
                            color     : "#393939",
                            fontWeight: "bold"
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "2px dotted #B4B4FA"
                          }
                        } }
                      />
                      { revenueindex > 1 &&
                        <Box
                          sx={ {
                            width          : "15px",
                            height         : "15px",
                            border         : "1px solid #838383",
                            borderRadius   : "50%",
                            zIndex         : 20,
                            position       : "absolute",
                            right          : "-7px",
                            top            : "-10px",
                            display        : "flex",
                            justifyContent : "center",
                            alignItems     : "center",
                            backgroundColor: "#FFFFFF"
                          } }
                          onClick={ () => {

                            removeRevenue(revenueindex);
                          
                          } }
                        >
                          <CloseIcon
                            sx={ { fontSize: "15px", color: "#838383" } }
                          />
                        </Box>
                      }
                    </Box>
                    :
                    <Typography
                      id={ styles.revenueTypo }
                    // sx={{ color: "#393939", fontWeight: "bold" }}
                    >
                      { revenue.name }
                    </Typography>
                  }
                </Box>
                <Box
                  id={ styles.textFieldbox }
                // sx={{ width: "90%" }}
                >
                  { props.isEdit ?
                    <TextField
                      value={ revenue.value }
                      onChange={ e => {

                        handleChange("value", e.target.value, revenueindex);
                      
                      } }
                      placeholder="Describe the revenue source, what channel you will generate these revenues through, and how you will price or package the offering. "
                      fullWidth
                      multiline
                      maxRows={
                        slide.revenues.length > 3
                          ? 9
                          : slide.revenues.length === 3
                            ? 7
                            : 6
                      }
                      minRows={
                        slide.revenues.length > 3
                          ? 9
                          : slide.revenues.length === 3
                            ? 7
                            : 6
                      }
                      // minRows={slide.revenues.length > 3 ? 9 : 6}
                      inputProps={ { maxLength: 150 } }
                      sx={ {
                        // "& .MuiOutlinedInput-root": {
                        //   // padding: "0px 0px",
                        //   border: "1px solid #B4B4FA",
                        // },
                        "& .MuiInputBase-input": {
                          fontSize  : "15px",
                          padding   : "3px 3px",
                          // border: "none",
                          color     : "#707070",
                          // lineHeight: "17px",
                          whiteSpace: "pre-line"
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px dotted #B4B4FA"
                        }
                      } }
                    />
                    :
                    // <TextField
                    //   value={revenue.value}
                    //   disabled
                    //   fullWidth
                    //   multiline
                    //   maxRows={slide.revenues.length > 3 ? 8 : 5}
                    //   minRows={slide.revenues.length > 3 ? 8 : 5}
                    //   sx={{
                    //     "& .MuiInputBase-input": {
                    //       fontSize: "13px",
                    //       padding: "7px 10px",
                    //       "-webkit-text-fill-color": `#707070 !important`,
                    //       color: `#707070 !important`,
                    //     },
                    //     "& .MuiOutlinedInput-notchedOutline": {
                    //       border: "1px solid #B4B4FA",
                    //     },
                    //   }}
                    // />
                    <Box
                      sx={ {
                        width : "100%",
                        border: "1px solid #BEBEBE",
                        height:
                          slide.revenues.length > 3
                            ? "25vh"
                            : slide.revenues.length === 3
                              ? "22vh"
                              : "15vh",
                        // height: "167px",
                        borderRadius: "5px",
                        // padding: "10px 15px",
                        overflowY   : "auto"
                      } }
                    >
                      <Box
                        sx={ {
                          margin  : "12px",
                          wordWrap: "break-word"
                        } }
                      >
                        <Typography
                          sx={ {
                            fontSize  : "1.1em",
                            color     : "#707070",
                            // lineHeight: "17px",
                            whiteSpace: "pre-line"
                          } }
                        >
                          { revenue.value }
                        </Typography>
                      </Box>
                    </Box>
                  }
                </Box>
              </Box>
            );
          
          }) }

          { props.isEdit ?
            <Box sx={ { position: "absolute", top: "-25px", left: "14px" } }>
              <ReferenceLinkTooltip
                title={ "Revisit the notes created in the Monetization module" }
              >
                <Button
                  style={ {
                    // position: "absolute",
                    textTransform : "none",
                    textDecoration: "underline",
                    fontSize      : "12px",
                    // top: "-20px",
                    // right: "-10px",
                    padding       : "0px",
                    fontWeight    : "bold"
                  } }
                  onClick={ PitchBusinessModelDialogopen }
                >
                  Monetization &gt; Pricing
                </Button>
              </ReferenceLinkTooltip>
              <Dialog
                open={ pitchBusinessModelDialog }
                onClose={ PitchBusinessModelDialogclose }
                PaperProps={ {
                  style: {
                    borderRadius   : "20px",
                    backdropFilter : "blur(100px)",
                    backgroundColor: "#ffffff",
                    boxShadow      : "0px 3px 6px #00000029",
                    // border: "1px solid #707070",
                    width          : "70%"
                  }
                } }
                // BackdropProps={{ invisible: true }}
                //
                maxWidth="false"
                fullWidth
              >
                <DialogContent sx={ { padding: "40px" } }>
                  <Box
                    sx={ {
                      width   : "100%",
                      // height: "60vh",
                      // display: "flex",
                      // alignItems: "center",
                      // justifyContent: "center",
                      position: "relative"
                    } }
                  >
                    <PitchBusinessModelMonetisation />
                    <IconButton
                      onClick={ () => {

                        PitchBusinessModelDialogclose();
                      
                      } }
                      sx={ {
                        position: "absolute",
                        right   : "-40px",
                        top     : "-40px"
                      } }
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </Box>
                </DialogContent>
              </Dialog>
            </Box>
            :
            <></>
          }
        </Box>

        {/* ------- */ }
        {/* add button */ }
        { props.isEdit ?
          <Box
            sx={ {
              width         : "46px",
              height        : "46px",
              // border: 1,
              display       : "flex",
              justifyContent: "center",
              alignItems    : "center",
              borderRadius  : "50%",
              position      : "absolute",
              bottom        : "20px",
              right         : "20px",
              backgroundColor:
                slide.revenues.length === 4 ? "#b0b0b0" : "#071AFF",
              cursor: slide.revenues.length === 4 ? "default" : "pointer"
            } }
            onClick={ () => {

              if (slide.revenues.length < 4) addRevenue();
            
            } }
          >
            <AddIcon sx={ { fontSize: "34px", color: "#FFFFFF" } } />
          </Box>
          :
          <></>
        }
      </Box>
    </Box>
  );

}
