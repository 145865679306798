import {
  Box, Button, TextField, Typography
} from "@mui/material";
import React, { useRef } from "react";
import ErrorMessage from "../../../../components/ErrorMessage";
import ImageOptions from "../ImageOptions";
import SectionHeader from "../SectionHeader";
import styles from "./TheProduct.module.css";

export default function TheProduct ({
  logoimg,
  slides,
  isRender,
  setSlides,
  productImg,
  uploadImages,
  deleteUploadedImgs,
  isPublished,
  ...props
}) {

  const slide = slides[isRender];

  const handleChange = (name, value, columnindex) => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;

    let newproducthighlights = [ ...newSlides[isRender]["productHighlightsOne"] ];

    newproducthighlights.map((productHighlight, productHighlightIndex) => {

      if (productHighlightIndex === columnindex) {

        newproducthighlights[productHighlightIndex][name] = value;
      
      }
      
      return productHighlight;
    
    });
    newSlides[isRender]["productHighlightsOne"] = newproducthighlights;

    setSlides(newSlides);
  
  };

  const handleDetailsChange = (name, value) => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    newSlides[isRender][name] = value;
    setSlides(newSlides);
  
  };

  // for uploading productImg
  const productImgFileInput = useRef(null);

  const productImghandleClick = () => {

    props.setErrormsg("");
    productImgFileInput.current.click();
  
  };

  // const previewProductImg = useMemo(() => {
  //   return productOneImg ? URL.createObjectURL(productOneImg) : null;
  // }, [productOneImg]);

  return (
    <Box sx={ { marginTop: "10px" } }>
      <SectionHeader
        isEdit={ props.isEdit }
        Collapsecomment={ props.Collapsecomment }
        collapseCommentFunction={ props.collapseCommentFunction }
        mainHead={ slide.name }
        handleTitleChange={ handleDetailsChange }
        logoimg={ logoimg }
        isPublished={ isPublished }
      />
      <Box
        id={ styles.productMainBox }
      // sx={{
      //   // border: 1,
      //   width: "100%",
      //   height: "60vh",
      //   display: "flex",
      //   justifyContent: "space-between",
      //   alignItems: "center",
      // }}
      >
        {/* left content box */ }
        <Box
          id={ styles.leftContentBox }
        // sx={{
        //   width: "50%",
        //   // border: 1,
        //   display: "flex",
        //   height: "100%",
        //   flexDirection: "column",
        //   justifyContent: "space-evenly",
        // }}
        >
          { slide.productHighlightsOne.map(
            (productHighlight, productHighlightIndex) => {

              return (
                <Box sx={ { marginTop: "5px" } }>
                  <Box sx={ { marginBottom: "5px" } }>
                    { props.isEdit ?
                      <TextField
                        fullWidth
                        sx={ {
                          // "& .MuiOutlinedInput-root": {
                          //   padding: "0px 0px",
                          // },
                          "& .MuiInputBase-input": {
                            fontSize  : "16px",
                            padding   : "1px 10px",
                            border    : "none",
                            color     : "#393939",
                            fontWeight: "bold"
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "2px dotted #B4B4FA"
                          }
                        } }
                        inputProps={ { maxLength: 50 } }
                        value={ productHighlight.name }
                        onChange={ e => {

                          handleChange(
                            "name",
                            e.target.value,
                            productHighlightIndex
                          );
                        
                        } }
                      />
                      :
                      <Typography
                        id={ styles.productTitleTypo }
                      // sx={{
                      //   marginBottom: "5px",
                      //   color: "#393939",
                      //   fontWeight: "bold",
                      //   marginLeft: "5px",
                      // }}
                      >
                        { productHighlight.name }
                      </Typography>
                    }
                  </Box>
                  { props.isEdit ?
                    <TextField
                      fullWidth
                      multiline
                      maxRows={ 4 }
                      minRows={ 4 }
                      sx={ {
                        "& .MuiOutlinedInput-root": {
                          padding: "3px 3px"
                        },
                        "& .MuiInputBase-input": {
                          fontSize  : "13px",
                          // padding: "2px 2px",
                          color     : "#707070",
                          lineHeight: "17px",
                          whiteSpace: "pre-line"
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px dotted #B4B4FA"
                        }
                      } }
                      inputProps={ { maxLength: 200 } }
                      value={ productHighlight.value }
                      onChange={ e => {

                        handleChange(
                          "value",
                          e.target.value,
                          productHighlightIndex
                        );
                      
                      } }
                    />
                    :
                    // <TextField
                    //   disabled
                    //   fullWidth
                    //   multiline
                    //   maxRows={3}
                    //   minRows={3}
                    //   sx={{
                    //     "& .MuiOutlinedInput-root": {
                    //       padding: "7px 14px",
                    //     },
                    //     "& .MuiInputBase-input": {
                    //       fontSize: "14px",
                    //       // padding: "2px 2px",
                    //       "-webkit-text-fill-color": `#707070 !important`,
                    //       color: `#707070 !important`,
                    //       // fontWeight: "bold",
                    //     },
                    //     "& .MuiOutlinedInput-notchedOutline": {
                    //       border: "1px solid #B4B4FA",
                    //     },
                    //   }}
                    //   value={productHighlight.value}
                    // />
                    <Box
                      sx={ {
                        width         : "100%",
                        border        : "1px solid #BEBEBE",
                        height        : "15vh",
                        borderRadius  : "5px",
                        // padding: "10px 15px",
                        overflowY     : "auto",
                        display       : "flex",
                        justifyContent: "flex-start",
                        alignItems    : "center"
                      } }
                    >
                      <Box
                        sx={ {
                          margin  : "12px",
                          wordWrap: "break-word"
                        } }
                      >
                        <Typography
                          sx={ {
                            fontSize  : "1.5vh",
                            color     : "#707070",
                            whiteSpace: "pre-line"
                            // lineHeight: "23px",
                          } }
                        >
                          { productHighlight.value }
                        </Typography>
                      </Box>
                    </Box>
                  }
                </Box>
              );
            
            }
          ) }
        </Box>
        {/* right content box */ }
        <Box
          id={ styles.productImgBox }
        // sx={{
        //   width: "35%",
        //   height: "95%",
        //   border: "1px solid #B4B4FA",
        //   borderRadius: "7px",
        //   boxShadow: "0px 3px 6px #00000029",
        //   backgroundColor: "#B4B4FA",
        // }}
        >
          { props.isEdit ?
            <>
              { productImg?.imgUrl || slide.productImage ?
                <>
                  <img
                    src={
                      productImg?.imgUrl
                        ? productImg?.imgUrl
                        : slide.productImage
                          ? slide.productImage
                          : productImg?.imgUrl
                    }
                    style={ {
                      width    : "100%",
                      height   : "100%",
                      objectFit: "cover"
                    } }
                  />
                  <Box
                    sx={ {
                      position       : "absolute",
                      right          : "1px",
                      top            : "1px",
                      display        : "flex",
                      justifyContent : "space-evenly",
                      alignItems     : "center",
                      backgroundColor: "#ffffff",
                      opacity        : "0.5"
                      /* border-radius: 5px; */
                    } }
                  // onClick={productImghandleClick}
                  >
                    <ImageOptions
                      IconSize="22px"
                      handleClick={ productImghandleClick }
                      image={ productImg?.imgUrl || slide.productImage }
                      deleteUploadedImgs={ () => {

                        let newSlides = [ ...slides ];

                        newSlides[isRender]["isChanged"] = true;
                        setSlides(newSlides);
                        deleteUploadedImgs(
                          `${slide.renderName}.productImage`,
                          slide.productImage ? true : false
                        );
                        if (slide.productImage)
                          handleDetailsChange("productImage", "");
                      
                      } }
                    />
                  </Box>
                </>
                :
                <Box
                  sx={ {
                    width         : "100%",
                    height        : "100%",
                    display       : "flex",
                    flexDirection : "column",
                    alignItems    : "center",
                    justifyContent: "center"
                  } }
                >
                  { props.errorMsg &&
                    <ErrorMessage errormsg={ props.errorMsg } />
                  }
                  <Typography>Upload a product image.</Typography>
                  <Button
                    variant="contained"
                    style={ {
                      fontSize     : "14px",
                      textTransform: "none",
                      // padding: "10px 19px",
                      margin       : "10px"
                    } }
                    disabled={ props.isEdit ? false : true }
                    onClick={ productImghandleClick }
                  >
                    Upload Image
                  </Button>
                  <Typography sx={ { fontSize: "12px" } }>
                    { "Image must be .png or .jpg format and < 10MB." }
                  </Typography>
                </Box>
              }
              <input
                type="file"
                id="productOneImg"
                ref={ productImgFileInput }
                onChange={ e => {

                  uploadImages(
                    `${slide.renderName}.productImage`,
                    e.target.files[0]
                  );
                  let newSlides = [ ...slides ];

                  newSlides[isRender]["isChanged"] = true;
                  setSlides(newSlides);
                
                } }
                onClick={ e => {

                  e.target.value = null;
                
                } }
                style={ { display: "none" } }
                accept="image/*"
              />
            </>
            : slide.productImage ?
              <img
                src={ slide.productImage }
                style={ { width: "100%", height: "100%", objectFit: "cover" } }
              />
              :
              <></>
          }
          {/* {props.isEdit ? (
            <Box
              sx={{
                position: "absolute",
                // border: 1,
                width: "100%",
                bottom: "0px",
                height: "10%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  // border: "1px solid #B4B4FA",
                  width: "60%",
                  borderRadius: "7px",
                  display: "flex",
                  alignItems: "center",
                  // border: 1,
                  justifyContent: "center",
                  backgroundColor: "#FFFFFF",
                }}
              >
              
                <TextField
                  fullWidth
                  sx={{
                    // "& .MuiOutlinedInput-root": {
                    //   padding: "0px 0px",
                    // },
                    "& .MuiInputBase-input": {
                      fontSize: "14px",
                      padding: "8px 10px",
                      border: "none",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "2px dotted #B4B4FA",
                    },
                  }}
                />
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                position: "absolute",
                // border: 1,
                width: "100%",
                bottom: "0px",
                height: "10%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  border: "1px solid #B4B4FA",
                  width: "60%",
                  borderRadius: "7px",
                  display: "flex",
                  alignItems: "center",
                  // border: 1,
                  justifyContent: "center",
                  backgroundColor: "#FFFFFF",
                }}
              >
                <Typography sx={{ fontSize: "12px", color: "#5F5E5E" }}>
                  Product Image - Description
                </Typography>
              </Box>
            </Box>
          )} */}
        </Box>
      </Box>
    </Box>
  );

}
