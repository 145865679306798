import React from "react";
// import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import List from "./List";



const useStyles = makeStyles(theme => ({
  root: {
    flexDirection: "column",
    overflow     : "auto",
    OverflowY    : "hidden",

    height: "100%"
  },
  listContainer: {
    display  : "flex",
    marginTop: theme.spacing(0.5)
  },
  wrapper: {
    marginTop: theme.spacing(1)
  },
  editable: {
    marginLeft     : theme.spacing(1),
    height         : "38px",
    padding        : theme.spacing(0, 1, 0, 1),
    boxShadow      : "inset 0 0 0 2px #0079bf",
    borderRadius   : 6,
    backgroundColor: "#EBECF0",
    position       : "fixed",
    marginTop      : theme.spacing(4.5)
  }
}));

const Board = ({ milestones }) => {

  const classes = useStyles({});

  // let { user, venture } = useSelector(state => {

  //   return { ...state };
  
  // });


  return (
    <Grid container className={classes.root}>
      <div
        className={classes.listContainer}
      >
        {milestones.length > 0 &&
          milestones.map((milestone, milestoneindex) => {

            return <List
              milestone={milestone}
              milestoneindex={milestoneindex}
            />;
          
          })}
      </div>
    </Grid >
  );

};

export default Board;
