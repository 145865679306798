import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles(theme => ({
  add: {
    textTransform  : "none",
    margin         : theme.spacing(0.2, 1, 1, 1),
    justifyContent : props => props.type === "card" ? "center" : "left",
    fontWeight     : "bold",
    backgroundColor: props =>
      props.type !== "card" ? "hsla(0,0%,100%,.24)" : "inherit",
    "&:hover": {
      opacity        : 1,
      backgroundColor: "rgba(9,30,66,.08)"
    }
    // textShadow: (props) =>
    //   !props.noshadow &&
    //   (props.type === "menu" || props.type === "list") &&
    //   "2px 2px black",
  },
  width: props => ({
    width: props.width,
    color: props.color
  })
}));

const AddItem = ({
  btnText,
  handleClick,
  type,
  icon,
  width,
  color,
  noshadow,
  isdisabled
}) => {

  const classes = useStyles({ type, width, color, noshadow });

  
  return (
    <>
      { type === "card" ?
        <Button
          className={ `${classes.add} ${classes.width}` }
          onClick={ handleClick }
          disabled={ isdisabled }
        >
          { icon }
        </Button>
        :
        <Button
          className={ `${classes.add} ${classes.width}` }
          onClick={ handleClick }
          disabled={ isdisabled }
        >
          { icon } { btnText }
        </Button>
      }
    </>
  );

};

export default AddItem;
