/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import IosShareIcon from "@mui/icons-material/IosShare";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography
} from "@mui/material";
import CommentContainer from "../../../components/CommentContainer";
import IndividualNavBox from "./IndividualNavBox";
import styles from "./PitchDeck.module.css";
import BusinessModel from "./Sections/BusinessModel";
import Competition from "./Sections/Competition";
import CompetitiveAdvantage from "./Sections/CompetitiveAdvantage";
import Cover from "./Sections/Cover";
import Customer from "./Sections/Customer";
import GTMStrategiesIdeas from "./Sections/GTMStrategiesIdeas";
import KeyValuePropositions from "./Sections/KeyValuePropositions";
import MarketSize from "./Sections/MarketSize";
import Milestone from "./Sections/Milestone";
import Opportunity from "./Sections/Opportunity";
import PitchFinancials from "./Sections/PitchFinancials";
import Problem from "./Sections/Problem";
import Solution from "./Sections/Solution";
import Team from "./Sections/Team";
import ThankYou from "./Sections/ThankYou";
import TheAsk from "./Sections/TheAsk";
import TheProduct from "./Sections/TheProduct";
import TheProduct2 from "./Sections/TheProduct2";
import Traction from "./Sections/Traction";

import LinearProgress, {
  linearProgressClasses
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import html2canvas from "html2canvas";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import SuccessMessage from "../../../components/SuccessMessage";
import {
  createPitchdeckComment,
  deletePitchdeckComment,
  updatePitchdeckComment
} from "../../../services/comment";
import {
  exportPitchDeck,
  getPitchDeckDetails,
  updatePitchDeckDetails,
  updatePublishPitchDeckDetails,
  exportPitchDeckOnePage
} from "../../../services/pitchdeck";
import { getVetureById } from "../../../services/venture";
import PitchDeckOnePage from "./PitchDeckOnePage";
import UploadPitchDeck from "./UploadPitchDeck/UploadPitchDeck";

import GetAppIcon from "@mui/icons-material/GetApp";
import Dropzone from "react-dropzone";
import axiosService from "../../../services/axiosService";
import { fetchPdf } from "../../../services/pitchdeck";
import IndividualPdfNavBox from "./UploadPitchDeck/IndividualPdfNavBox";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height                                     : 10,
  borderRadius                               : 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius   : 5,
    backgroundColor: "#017AFF"
  }
}));

const PitchDeck = () => {

  const [ logoimg, setLogoimg ] = useState("");
  const [ year, setYear ] = useState("");
  const [ copyrightsName, setCopyrightsName ] = useState("");
  const [ slides, setSlides ] = useState([]);
  const [ comments, setComments ] = useState([]);
  const [ publishedImages, setPublishedImages ] = useState([]);
  const [ renderDetails, setRenderDetails ] = useState({
    isRender    : 0,
    isRendername: ""
  });
  const [ publishedrenderDetails, setPublishedRenderDetails ] = useState({
    isRender    : 0,
    isRendername: ""
  });
  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const [ isEdit, setIsEdit ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ isChanged, setIschanged ] = useState(false);
  const [ isSaving, setIssaving ] = useState(false);
  const [ isPublished, setIspublished ] = useState(false);
  const [ isExported, setIsexported ] = useState(false);
  const [ isOnePage, setIsOnePage ] = useState(false);
  // const [ isSelect, setIsSelect ] = useState();
  const [ successmsg, setSuccessmsg ] = useState("");
  const [ alignment, setAlignment ] = useState("0");
  const [ errormsg, setErrormsg ] = useState("");
  const [ errormsg2, setErrormsg2 ] = useState("");

  const processingRef = useRef(1);

  const [ uploadedImgs, setUploadedimgs ] = useState([]);
  const [ deletedImgs, setDeletedimgs ] = useState([]);

  let locationPdf = useLocation();
  let locationStatePdf = locationPdf.state;

  // pitch pdf upload
  const [ isUpload, setIsUpload ] = useState(
    locationStatePdf && (locationStatePdf.fromPitchPdf ? true : false)
  );
  // const [isUpload, setIsUpload] = useState(false);
  const [ pdfPages, setPdfPages ] = useState(null);
  const [ pdfPageNumber, setPdfPageNumber ] = useState(1);
  const [ uploadedPdf, setUploadedPdf ] = useState(false);
  // const [isUpload, setIsUpload] = useState("");
  const [ uploadedPitchName, setUploadedPitchName ] = useState("");
  const [ uploadedPitchUrl, setUploadedPitchUrl ] = useState("");
  const [ anchorEl, setAnchorEl ] = useState(null);
  const open = Boolean(anchorEl);

  // ----------pitch pdf update-------------
  // const [ pdfLoading, setPdfLoading ] = useState(false);
  // const [ isPdfTooLarge, setIsPdfTooLarge ] = useState(false);
  // const [ pdfProgress, setPdfProgress ] = useState(0);
  // const [ pdfSuccessmsg, setPdfSuccessmsg ] = useState("");
  // const [ pdfErrormsg, setPdfErrormsg ] = useState("");
  const [ updateRender, setUpdateRender ] = useState(false);
  const [ isCopied, setIsCopied ] = useState(false);

  const [ uploadPitchSuccess, setUploadPitchSuccess ] = useState("");
  const [ onePageData, setOnePageData ] = useState([]);

  const handleAlignment = (event, newAlignment) => {

    setAlignment(newAlignment);
  
  };

  const onUpdate = async (acceptedFiles, rejectionFiles) => {

    // setPdfLoading(true);
    try {

      if (rejectionFiles.length > 0 && rejectionFiles[0].file.size > 52428800) {

        // setIsPdfTooLarge(true);
        // setPdfLoading(false);
        setTimeout(() => {

          // setIsPdfTooLarge(false);
        
        }, 5000);
        
        return;
      
      }
      const formdata = new FormData();

      formdata.append("pitch", acceptedFiles[0]);
      formdata.append("ventureid", venture._id);

      await axiosService.post(
        `${process.env.REACT_APP_ADDRESS}/upload_pitch`,
        formdata,
        {
          // onUploadProgress: ProgressEvent => {

          // const { loaded, total } = ProgressEvent;
          // const percentage = Math.floor(
          //   ((loaded / 1000) * 100) / (total / 1000)
          // );

          // setPdfProgress(percentage);
          
          // }
        }
      );

      // setPdfSuccessmsg(response.data);
      setUpdateRender(true);
    
    } catch (error) {
      
      console.error(error);

      // setPdfErrormsg("File type not accepted");
      // setPdfLoading(false);
      // setTimeout(() => {

      //   setPdfErrormsg("");
      
      // }, 15000);
    
    }
  
  };

  // -------------------fetching pdf-------------------------

  const handleClick = event => {

    setAnchorEl(event.currentTarget);
  
  };

  const handleClose = () => {

    setAnchorEl(null);
  
  };

  const fetchUploadedPdf = async () => {

    const response = await fetchPdf(venture._id);

    setUploadedPitchName(response.data.uploadedPitchName);
    setUploadedPitchUrl(response.data.uploadedPitchUrl);
  
  };

  const deleteUploadedPdf = async ventureId => {

    await axiosService.delete(
      `${process.env.REACT_APP_ADDRESS}/delete_uploadedpitch/${ventureId}`
    );
  
  };

  // ----------------------------------------

  const changePage = pageNum => {

    setPdfPageNumber(prevPageNumber => prevPageNumber + pageNum);
    setErrormsg("");
    setErrormsg2("");
  
  };

  const previousPage = () => {

    if (pdfPageNumber <= 1) {

      setPdfPageNumber(pdfPages);
      setErrormsg("");
      setErrormsg2("");
    
    } else {

      changePage(-1);
      setErrormsg("");
      setErrormsg2("");
    
    }
  
  };

  const nextPage = () => {

    if (pdfPageNumber === pdfPages) {

      setPdfPageNumber(1);
    
    } else {

      changePage(1);
      setErrormsg("");
      setErrormsg2("");
    
    }
  
  };

  const ref = useRef();
  const history = useHistory();
  const location = useLocation();
  let locationState = location.state;

  // html to image
  const boxContentRef = useRef(null);
  const onePageRef = useRef(null);
  const rightArrowElement = useRef(null);
  const leftArrowElement = useRef(null);

  const normalise = value => ((value - 1) * 100) / (20 - 1);

  const useQuery = () => {

    return new URLSearchParams(location.search);
  
  };
  const query = useQuery();

  const queryisRender = query.get("isRender");
  const queryisRendername = query.get("isRendername");

  const [ share, setShare ] = useState(false);

  useEffect(() => {

    const checkIfClickedOutside = e => {

      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (share && ref.current && !ref.current.contains(e.target)) {

        setShare(false);
      
      }
    
    };

    document.addEventListener("mousedown", checkIfClickedOutside);
    
    return () => {

      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    
    };
  
  }, [ share ]);

  const editLogoImg = uploadedImgs.find(img => img.imgName === "logo");
  const { user, venture } = useSelector(state => ({ ...state }));
  
  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);
    
    } else {

      setCollapsecomment(true);
    
    }
  
  };

  useEffect(() => {

    fetchDetails();
    fetchUploadedPdf();
  
  }, []);

  useEffect(() => {

    fetchUploadedPdf();
    setUpdateRender(false);
  
  }, [ updateRender ]);

  useEffect(() => {

    setIschanged(true);
  
  }, [
    slides, year, copyrightsName, logoimg, uploadedImgs, deletedImgs
  ]);

  useEffect(() => {

    if (!isEdit && isPublished) handlePublishedPitchDeck();
  
  }, [ isEdit, isPublished ]);

  useEffect(() => {

    const onePageData = [];

    if (slides) {

      slides?.map(list => {

        if (list.renderName === "Cover") {

          onePageData.push({
            ventureName       : list.ventureName ? list.ventureName : "New Ventrue",
            oneLineDescription: list.oneLineDescription
              ? list.oneLineDescription
              : ""
          });
        
        }

        if (list.renderName === "The Problem") {

          onePageData.push({
            problemStatement: {
              problemName: list.problem[0]?.name
                ? list.problem[0]?.name
                : "The Problem",
              problemStatement: list.problem[0]?.value
                ? list.problem[0]?.value
                : ""
            }
          });
        
        }

        if (list.renderName === "The Customer") {

          if (list.customerSegment) {

            onePageData.push({
              customerSegment: {
                segment1: list.customerSegment[0]?.segmentName
                  ? list.customerSegment[0]?.segmentName
                  : "",
                segment2: list.customerSegment[1]?.segmentName
                  ? list.customerSegment[1]?.segmentName
                  : "",
                segment3: list.customerSegment[2]?.segmentName
                  ? list.customerSegment[2]?.segmentName
                  : ""
              }
            });
          
          } else {

            onePageData.push({
              customerSegment: {
                segment1: "",
                segment2: "",
                segment3: ""
              }
            });
          
          }
        
        }

        if (list.renderName === "Market Size") {

          if (
            list.totalAddressableMarket ||
            list.servicableAvailableMarket ||
            list.shareOfMarket
          ) {

            onePageData.push({
              marketSize: {
                TAM: list.totalAddressableMarket?.size
                  ? list.totalAddressableMarket?.size
                  : "",
                SAM: list.servicableAvailableMarket?.size
                  ? list.servicableAvailableMarket?.size
                  : "",
                SOM: list.shareOfMarket?.size ? list.shareOfMarket?.size : ""
              }
            });
          
          }
        
        }

        if (list.renderName === "The Solution") {

          if (list.solutions) {

            onePageData.push({
              solutionStatement: list.solutions[0]?.value
                ? list.solutions[0]?.value
                : ""
            });
            onePageData.push({
              differentiation: list.solutions[1]?.value
                ? list.solutions[1]?.value
                : ""
            });
          
          } else {

            onePageData.push({ solutionStatement: "" });
            onePageData.push({ differentiation: "" });
          
          }
        
        }

        if (list.renderName === "Key Value Propositions") {

          if (list.valuePropositions) {

            onePageData.push({
              valuePropositions: [
                list.valuePropositions[0] ? list.valuePropositions[0] : "",
                list.valuePropositions[1] ? list.valuePropositions[1] : "",
                list.valuePropositions[2] ? list.valuePropositions[2] : ""
              ]
            });
          
          } else {

            onePageData.push({ valuePropositions: [] });
          
          }
        
        }

        if (list.renderName === "Business Model") {

          if (list.revenues) {

            onePageData.push({
              revenues: [
                list?.revenues[0] ? list?.revenues[0] : "",
                list?.revenues[1] ? list?.revenues[1] : ""
              ]
            });
          
          } else {

            onePageData.push({
              revenues: []
            });
          
          }
        
        }

        if (list.renderName === "The Ask") {

          if (
            list?.fundingType ||
            list?.achieveDescription ||
            list?.revenueProjection ||
            list?.customerBase
          ) {

            onePageData.push({
              theAsk: {
                fundingType       : list?.fundingType ? list?.fundingType : "",
                achieveDescription: list?.achieveDescription
                  ? list?.achieveDescription
                  : "",
                revenueProjection: list?.revenueProjection
                  ? list?.revenueProjection
                  : "",
                customerBase: list?.customerBase ? list?.customerBase : ""
              }
            });
          
          } else {

            onePageData.push({
              theAsk: {
                fundingType       : "",
                achieveDescription: "",
                revenueProjection : "",
                customerBase      : ""
              }
            });
          
          }
        
        }

        if (list.renderName === "Financials") {

          if (list.revenueYears) {

            onePageData.push({
              revenueYears: list?.revenueYears ? list?.revenueYears : []
            });
          
          } else {

            onePageData.push({
              revenueYears: []
            });
          
          }
        
        }

        if (list.renderName === "Thank you") {

          onePageData.push({
            contactInfo: {
              username: list?.username ? list?.username : "",
              email   : list?.email ? list?.email : "",
              contact : list?.contact ? list?.contact : "",
              website : list?.website ? list?.website : ""
            }
          });
        
        }
      
      });
    
    }
    setOnePageData(onePageData);
  
  }, [ slides ]);

  const fetchDetails = async () => {

    if (!isChanged && !isPublished) setLoading(true);
    try {

      const response = await getPitchDeckDetails(venture.pitchDeck);
      let slidesData = response.data.slides;
      let year = response.data.year;
      let publishedImagesData = response.data.publishedSlideImages;

      setUploadedimgs([]);
      setDeletedimgs([]);
      setSlides(slidesData);
      if (renderDetails.isRendername === "" && renderDetails.isRender === 0) {

        let newrenderdetails = {
          isRender:
            locationState && locationState.isRender
              ? locationState.isRender
              : queryisRender
                ? queryisRender
                : 0,
          isRendername:
            locationState && locationState.isRendername
              ? locationState.isRendername
              : queryisRendername
                ? queryisRendername
                : slidesData[0].renderName
        };

        setRenderDetails(newrenderdetails);
      
      }
      if (!response.data.isPopulated) {

        const res = await getVetureById(venture._id);
        let newuploadedImgs = [];

        if (res.data.logo) {

          let file = await fetch(res.data.logo)
            .then(r => r.blob())
            .then(
              blobFile =>
                new File([ blobFile ], "ventureLogo", { type: "image/png" })
            );

          newuploadedImgs.push({
            imgName: "logo",
            img    : file,
            imgUrl : URL.createObjectURL(file)
          });
          setUploadedimgs(newuploadedImgs);
        
        }
        let newslides = [ ...slidesData ];
        let coverSlideIndex = newslides.findIndex(
          slide => slide.renderName === "Cover"
        );

        newslides[coverSlideIndex]["ventureName"] =
          res.data.venture.details.name;
        newslides[coverSlideIndex]["website"] =
          res.data.venture.details.website;
        newslides[coverSlideIndex]["email"] =
          res.data.venture.details.businessEmail;
        const formdata = new FormData();

        newuploadedImgs.map(uploadedImg => {

          formdata.append(uploadedImg.imgName, uploadedImg.img);
        
        });
        formdata.append("deletedFiles", JSON.stringify(deletedImgs));
        formdata.append("slides", JSON.stringify(newslides));
        formdata.append(
          "publishedSlideImages",
          JSON.stringify(publishedImagesData)
        );
        formdata.append("year", new Date().getFullYear());
        formdata.append("copyrightsName", copyrightsName);
        formdata.append("onePageData", onePageData);
        formdata.append("pitchdeckid", venture.pitchDeck);
        const response = await updatePitchDeckDetails(formdata);

        year = response.data.year;
        // await updatePublishPitchDeckDetails(
        //   venture.pitchDeck,
        //   venture.publishedPitchDeck
        // );
      
      }
      // isSavingflag to not overwrite published images data after clicking save as draft
      setPublishedImages(response.data.publishedSlideImages);
      setYear(year);
      setCopyrightsName(response.data.copyrightsName);
      setLogoimg(response.data.logo);
      setComments(response.data.comments);
      setLoading(false);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const handleRenderDetails = (isrendername, isrender) => {

    let newrenderdetails = {
      isRender    : isrender,
      isRendername: isrendername
    };

    setRenderDetails(newrenderdetails);
    setErrormsg("");
    setErrormsg2("");
  
  };

  const handleHideChange = (index, value) => {

    let newPublishedImages = [ ...publishedImages ];
    let newSlides = [ ...slides ];

    newPublishedImages[index]["isHide"] = value;
    newSlides[index]["isChanged"] = true;
    setSlides(newSlides);
    setPublishedImages(newPublishedImages);
  
  };

  const removeNonNumeric = num => num.toString().replace(/[^0-9]/g, "");

  const removeUploadImage = name => {

    let newuploadedImgs = [ ...uploadedImgs ];

    setUploadedimgs(
      newuploadedImgs.filter(uploadedImg => uploadedImg.imgName !== name)
    );
  
  };

  // const removeDeletedImage = (name) => {
  //   let newdeletedimgs = [...deletedImgs];

  //   setDeletedimgs(newdeletedimgs.filter((deletedimg) => deletedimg !== name));
  // };

  const copyToClipboard = e => {

    e.stopPropagation();
    if (isOnePage) {

      if (navigator && navigator.clipboard && navigator.clipboard.writeText) {

        setIsCopied(true);
        // setIsCopied(true);
        setTimeout(() => {

          setIsCopied(false);
        
        }, 1000);
        
        return navigator.clipboard.writeText(
          `${window.location.origin}/pitch-one-page-presentation?id=${
            venture.pitchDeck
          }&ventureName=${venture.details.name.split(" ").join("")}`
        );
      
      }
      // setIsCopied(false);
      
      return Promise.reject("The Clipboard API is not available.");
    
    } else {

      if (isUpload) {

        if (navigator && navigator.clipboard && navigator.clipboard.writeText) {

          setIsCopied(true);
          setTimeout(() => {

            setIsCopied(false);
          
          }, 1000);
          
          return navigator.clipboard.writeText(
            `${window.location.origin}/pitchpdfpresentation?id=${
              venture._id
            }&ventureName=${venture.details.name.split(" ").join("")}`
          );
        
        }
        
        return Promise.reject("The Clipboard API is not available.");
      
      } else {

        if (navigator && navigator.clipboard && navigator.clipboard.writeText) {

          setIsCopied(true);
          // setIsCopied(true);
          setTimeout(() => {

            setIsCopied(false);
          
          }, 1000);
          
          return navigator.clipboard.writeText(
            `${window.location.origin}/pitchpresentation?id=${
              venture.pitchDeck
            }&ventureName=${venture.details.name.split(" ").join("")}`
          );
        
        }
        // setIsCopied(false);
        
        return Promise.reject("The Clipboard API is not available.");
      
      }
    
    }
  
  };
  // const copyToClipboard = () => {
  //   if (navigator && navigator.clipboard && navigator.clipboard.writeText)
  //     return navigator.clipboard.writeText(
  //       `${window.location.origin}/pitchpresentation?id=${
  //         venture.pitchDeck
  //       }&ventureName=${venture.details.name.split(" ").join("")}`
  //     );
  //   return Promise.reject("The Clipboard API is not available.");
  // };

  const validateSelectedFile = selectedFile => {

    // 10MB
    const MAX_FILE_SIZE = 10240;

    const fileSizeKiloBytes = selectedFile.size / 1024;

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {

      return true;
    
    } else {

      return false;
    
    }
  
  };

  const uploadImages = async (name, image) => {

    if (validateSelectedFile(image)) {

      setErrormsg("File size is greater than maximum limit i.e. 10MB");
      // setTimeout(() => {
      //   setErrormsg("");
      // }, 15000);
      
      return;
    
    }
    // removeDeletedImage(name);
    let newuploadedImgs = [ ...uploadedImgs ];
    let isPresent = false;

    await Promise.all(
      newuploadedImgs.map((uploadedImg, index) => {

        if (uploadedImg.imgName === name) {

          newuploadedImgs[index] = {
            ...newuploadedImgs[index],
            ["img"]   : image,
            ["imgUrl"]: URL.createObjectURL(image)
          };
          isPresent = true;
        
        }
        setErrormsg("");
        
        return uploadedImg;
      
      })
    );
    if (!isPresent) {

      setErrormsg("");
      newuploadedImgs.push({
        imgName: name,
        img    : image,
        imgUrl : URL.createObjectURL(image)
      });
    
    }

    setErrormsg("");
    setUploadedimgs(newuploadedImgs);
  
  };

  const uploadImages2 = async (name, image) => {

    if (validateSelectedFile(image)) {

      setErrormsg2("File size is greater than maximum limit i.e. 10MB");
      // setTimeout(() => {
      //   setErrormsg2("");
      // }, 15000);
      
      return;
    
    }
    // removeDeletedImage(name);
    let newuploadedImgs = [ ...uploadedImgs ];
    let isPresent = false;

    await Promise.all(
      newuploadedImgs.map((uploadedImg, index) => {

        if (uploadedImg.imgName === name) {

          newuploadedImgs[index] = {
            ...newuploadedImgs[index],
            ["img"]   : image,
            ["imgUrl"]: URL.createObjectURL(image)
          };
          isPresent = true;
          setErrormsg2("");
        
        }
        
        return uploadedImg;
      
      })
    );
    if (!isPresent) {

      newuploadedImgs.push({
        imgName: name,
        img    : image,
        imgUrl : URL.createObjectURL(image)
      });
      setErrormsg2("");
    
    }

    setErrormsg2("");
    setUploadedimgs(newuploadedImgs);
  
  };

  const deleteUploadedImgs = (name, isfileUploadedDatabase) => {

    let newdeletedimgs = [ ...deletedImgs ];
    let isfileUploadedLocally = uploadedImgs.find(
      uploadedImg => uploadedImg.imgName === name
    );

    if (isfileUploadedLocally) {

      removeUploadImage(name);
    
    }
    if (isfileUploadedDatabase) {

      newdeletedimgs.push(name);
    
    }
    setDeletedimgs(newdeletedimgs);
  
  };

  const handleSubmit = async e => {

    try {

      e.preventDefault();
      setIssaving(true);
      const formdata = new FormData();

      uploadedImgs.map(uploadedImg => {

        formdata.append(uploadedImg.imgName, uploadedImg.img);
      
      });
      // formdata.append("allowedSlides", JSON.stringify(allowedSlides));
      formdata.append("deletedFiles", JSON.stringify(deletedImgs));
      formdata.append("slides", JSON.stringify(slides));
      formdata.append("onePageData", JSON.stringify(onePageData));
      formdata.append("publishedSlideImages", JSON.stringify(publishedImages));
      formdata.append("year", year);
      formdata.append("copyrightsName", copyrightsName);
      formdata.append("pitchdeckid", venture.pitchDeck);
      await updatePitchDeckDetails(formdata);
      await fetchDetails();
      setUploadedimgs([]);
      setDeletedimgs([]);
      setSuccessmsg("Saved");
      setIschanged(false);
      setIssaving(false);
      setTimeout(() => {

        setSuccessmsg("");
      
      }, 5000);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const handlePublishedPitchDeck = async () => {

    try {

      let newSlides = [ ...slides ];
      let slideImages = [];
      const formdata = new FormData();

      uploadedImgs.map(uploadedImg => {

        formdata.append(uploadedImg.imgName, uploadedImg.img);
      
      });
      formdata.append("deletedFiles", JSON.stringify(deletedImgs));
      formdata.append("slides", JSON.stringify(slides));
      formdata.append("publishedSlideImages", JSON.stringify(publishedImages));
      formdata.append("year", year);
      formdata.append("copyrightsName", copyrightsName);
      formdata.append("pitchdeckid", venture.pitchDeck);
      formdata.append("onePageData", JSON.stringify(onePageData));
      await updatePitchDeckDetails(formdata);
      await fetchDetails();
      let i = 0;
      const nextStep = async () => {

        if (i >= slides.length) {

          // console.log("array =======>", slideImages);
          await updatePublishPitchDeckDetails(
            venture.pitchDeck,
            publishedImages,
            newSlides,
            slideImages,
            onePageData
          );
          await fetchDetails();
          setIschanged(false);
          boxContentRef.current.style.border = "3px solid #071AFF";
          rightArrowElement.current.style.display = "flex";
          leftArrowElement.current.style.display = "flex";
          processingRef.current =
            processingRef.current + (20 - processingRef.current);
          setRenderDetails(publishedrenderDetails);
          setIsEdit(true);
          setIspublished(false);
          setSuccessmsg("Published");
          processingRef.current = 1;
          setTimeout(() => {

            setSuccessmsg("");
          
          }, 5000);
          
          return;
        
        }

        let newrenderdetails = {
          isRender    : i,
          isRendername: slides[i].renderName
        };

        setRenderDetails(newrenderdetails);
        boxContentRef.current.style.border = "none";
        rightArrowElement.current.style.display = "none";
        leftArrowElement.current.style.display = "none";
        let element = boxContentRef.current;

        if (slides[i].isChanged) {

          html2canvas(element, {
            backgroundColor: "none",
            scale          : 2,
            windowWidth    : "2050px",
            windowHeight   : "1080px",
            logging        : true,
            allowTaint     : false,
            // to enable cross origin perms
            useCORS        : true
            // width: 1024,
            // height: 600
          }).then(canvas => {

            const data = canvas.toDataURL("image/jpg");

            slideImages.push({
              renderName: slides[i].renderName,
              data      : data
            });
            processingRef.current = processingRef.current + 1;
            newSlides[i]["isChanged"] = false;
            i++;
            nextStep();
          
          });
        
        } else {

          i++;
          nextStep();
        
        }
      
      };

      nextStep();
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const addComment = async (commentText, replyTo) => {

    try {

      const response = await createPitchdeckComment(
        commentText,
        user._id,
        slides[renderDetails.isRender]["renderName"],
        venture.pitchDeck,
        replyTo,
        `${window.location.href}?isRender=${renderDetails.isRender}&isRendername=${renderDetails.isRendername}`,
        `Pitch Deck > ${slides[renderDetails.isRender]["name"]}`
      );

      setComments(response.data.comments);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateComment = async (commentid, commentText) => {

    try {

      const response = await updatePitchdeckComment(
        commentText,
        user._id,
        commentid,
        venture._id,
        `${window.location.href}?isRender=${renderDetails.isRender}&isRendername=${renderDetails.isRendername}`,
        `Pitch Deck > ${slides[renderDetails.isRender]["name"]}`
      );

      setComments(response.data.comments);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const deleteComment = async commentid => {

    try {

      const response = await deletePitchdeckComment(
        slides[renderDetails.isRender]["renderName"],
        venture.pitchDeck,
        commentid,
        user._id,
        `${window.location.href}?isRender=${renderDetails.isRender}&isRendername=${renderDetails.isRendername}`,
        `Pitch Deck > ${slides[renderDetails.isRender]["name"]}`
      );

      setComments(response.data.comments);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  // console.log("isedit", isEdit);
  // console.log(
  //   "render details========>",
  //   renderDetails.isRender,
  //   renderDetails.isRendername
  // );
  // console.log("slides", slides);
  // console.log("process time", processingRef.current);

  const OnePageExportPdf = async () => {

    setIsexported(true);

    exportPitchDeckOnePage(venture.pitchDeck).then(response => {

      setIsexported(false);
      const blobData = new Blob([ response.data ]);
      const url = window.URL.createObjectURL(blobData);
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute(
        "download",
        `${venture.details.name} -One-Page-Biz-Plan.pdf`
      );
      document.getElementById("pitchdeckexport").appendChild(link);
      link.click();
    
    });
  
  };

  const exportPdf = async () => {

    try {

      setIsexported(true);
      const response = await exportPitchDeck(venture.pitchDeck);
      const blobData = new Blob([ response.data ]);
      const url = window.URL.createObjectURL(blobData);
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", `${venture.details.name} - Pitch Deck.pdf`);
      document.getElementById("pitchdeckexport").appendChild(link);
      link.click();
      setIsexported(false);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  return (
    <Box sx={{ width: "100%" }}>
      {loading ?
        <Grid
          container
          justifyContent="center"
          height="90vh"
          alignItems="center">
          <Grid item>
            <CircularProgress size="60px" thickness={3} />
          </Grid>
        </Grid>
        :
        <>
          <Dialog fullScreen open={isPublished}>
            <Box
              sx={{
                height        : "100%",
                display       : "flex",
                alignItems    : "center",
                justifyContent: "center"
              }}>
              {/* <CircularProgress size="60px" thickness={3} />*/}
              <Box sx={{ width: "500px", textAlign: "center" }}>
                <Typography
                  sx={{
                    marginBottom: "20px",
                    fontSize    : "20px",
                    fontWeight  : "bold"
                  }}>
                  Publishing......
                </Typography>
                <Box sx={{ position: "relative" }}>
                  <BorderLinearProgress
                    variant="determinate"
                    color="primary"
                    value={normalise(processingRef.current)}
                  />
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      position: "absolute",
                      top     : "-5px",
                      left    : "510px"
                    }}>{`${Math.round(
                      normalise(processingRef.current)
                    )}%`}</Typography>
                </Box>
              </Box>
            </Box>
          </Dialog>

          <Dialog fullScreen open={isExported}>
            <Box
              sx={{
                height        : "100%",
                display       : "flex",
                alignItems    : "center",
                justifyContent: "center"
              }}>
              {/* <CircularProgress size="60px" thickness={3} />*/}
              <Box sx={{ width: "500px", textAlign: "center" }}>
                <Typography
                  sx={{
                    marginBottom: "20px",
                    fontSize    : "20px",
                    fontWeight  : "bold"
                  }}>
                  Exporting...
                </Typography>
                <Box>
                  <LinearProgress />
                </Box>
              </Box>
            </Box>
          </Dialog>

          <div className="tribe-pitch-deck-main-header">
            <Box id={styles.leftHeadBox}>
              <Typography id={styles.mainTypo}>Pitch Deck</Typography>
            </Box>

            {/* right side header box */}
            <div id={styles.rightHeadBox}>
              {/* pitch and upload pitch tab */}

              {/* ------------- */}
              {isEdit ?
                <Box id={styles.buttonBox}>
                  <Box mr={1}>
                    {isSaving &&
                      <Typography sx={{ color: "#08910B" }}>
                        Saving...
                      </Typography>
                    }
                    {successmsg &&
                      <SuccessMessage
                        successmsg={successmsg}
                        padding={"5px 10px"}
                      />
                    }
                  </Box>
                  {/* exit button */}
                  <Box
                    id={styles.exitButtonBox}
                    onClick={() => {

                      fetchDetails();
                      setIsEdit(false);
                    
                    }}>
                    <ExitToAppIcon id={styles.exitIcon} />
                    <Typography id={styles.buttonTypo}>Exit</Typography>
                  </Box>

                  {/* divider */}
                  <Box id={styles.buttonDivider}></Box>

                  {/*   Save as Draft button */}
                  <Box
                    id={styles.saveDraftButtonBox}
                    onClick={e => {

                      if (isChanged) handleSubmit(e);
                    
                    }}>
                    <Typography
                      // id={styles.buttonTypo}
                      sx={{
                        fontSize  : "14px",
                        color     : isChanged ? "#071AFF" : "#b0b0b0",
                        fontWeight: "bold"
                      }}>
                      Save as Draft
                    </Typography>
                  </Box>
                  {/* divider */}
                  <Box id={styles.buttonDivider}></Box>
                  {/* publish button */}
                  <Button
                    // disabled
                    variant="contained"
                    color="primary"
                    id={styles.publishButtonBox}
                    onClick={() => {

                      setPublishedRenderDetails(renderDetails);
                      let slideIndex = slides.findIndex(
                        slide => slide.isChanged
                      );
                      // console.log("slide index", slideIndex);

                      if (slideIndex >= 0) {

                        let newrenderdetails = {
                          isRender    : slideIndex,
                          isRendername: slides[slideIndex].renderName
                        };

                        setRenderDetails(newrenderdetails);
                      
                      }
                      setIsEdit(false);
                      setIspublished(true);
                    
                    }}
                    disabled={
                      slides.findIndex(slide => slide.isChanged) === -1
                        ? true
                        : false
                    }>
                    <Typography id={styles.publishBtnTypo}>Publish</Typography>
                  </Button>
                </Box>
                :
                <Box
                  id={styles.buttonBox}
                  sx={
                    {
                      // border: 1,
                    }
                  }>
                  {/* edit button */}
                  {isUpload ?
                    <Dropzone
                      onDrop={onUpdate}
                      minSize={0}
                      maxSize={52428800}
                      accept=".pdf,application/pdf">
                      {({
                        getRootProps,
                        getInputProps
                        // isDragActive,
                        // isDragReject,
                        // fileRejections
                      }) => {

                        return (
                          <Button
                            {...getRootProps()}
                            id={styles.editButtonBox}
                            variant="contained"
                            // onClick={() => {
                            //   setIsEdit(!isEdit);
                            //   setIschanged(false);
                            // }}
                            color="primary"
                            // disabled={venture.userRole === "Commenter"}
                            disabled={uploadedPitchUrl ? false : true}>
                            <EditIcon id={styles.editIcon} />
                            <Typography id={styles.editButtonTypo}>
                              Update
                            </Typography>
                            <input {...getInputProps()} />
                          </Button>
                        );
                      
                      }}
                    </Dropzone>
                    :
                    <div
                      style={
                        isOnePage ? { display: "none" } : { display: "unset" }
                      }>
                      <Button
                        id={styles.editButtonBox}
                        variant="contained"
                        onClick={() => {

                          setIsEdit(!isEdit);
                          setIschanged(false);
                        
                        }}
                        color="primary"
                        disabled={venture.userRole === "Commenter"}>
                        <EditIcon id={styles.editIcon} />
                        <Typography id={styles.editButtonTypo}>Edit</Typography>
                      </Button>
                    </div>
                  }
                  {/* </Box> */}
                  {/* divider */}
                  <Box
                    id={styles.buttonDivider}
                    style={
                      isOnePage ? { display: "none" } : { display: "unset" }
                    }></Box>
                  {/* share button */}
                  <Box ref={ref} id={styles.shareButtonBox}>
                    {isUpload ?
                      <Box sx={{ width: "100%" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            width         : "100%",
                            display       : "flex",
                            justifyContent: "space-evenly",
                            alignItems    : "center",
                            textTransform : "none",

                            padding: "3px 0px"
                          }}
                          onClick={() => {

                            setShare(!share);
                          
                          }}
                          disabled={uploadedPitchUrl ? false : true}
                          // disabled
                        >
                          <IosShareIcon
                            id={styles.shareIcon}
                            // sx={{ color: "#FFFFFF" }}
                          />
                          <Typography id={styles.shareButtonTypo}>
                            Share
                          </Typography>
                        </Button>
                      </Box>
                      :
                      <Tooltip
                        title={
                          venture.userRole === "Commenter"
                            ? ""
                            : publishedImages.length > 0 &&
                              publishedImages[0].imageUrl
                              ? ""
                              : "Edit & Publish your Pitch Deck to enable these features"
                        }
                        placement="top">
                        <Box sx={{ width: "100%" }}>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              width         : "100%",
                              display       : "flex",
                              justifyContent: "space-evenly",
                              alignItems    : "center",
                              textTransform : "none",

                              padding: "3px 0px"
                            }}
                            onClick={() => {

                              setShare(!share);
                            
                            }}
                            disabled={
                              venture.userRole === "Commenter" ||
                              (publishedImages.length > 0 &&
                              publishedImages[0].imageUrl
                                ? false
                                : true)
                            }
                            // disabled
                          >
                            <IosShareIcon
                              id={styles.shareIcon}
                              // sx={{ color: "#FFFFFF" }}
                            />
                            <Typography id={styles.shareButtonTypo}>
                              Share
                            </Typography>
                          </Button>
                        </Box>
                      </Tooltip>
                    }

                    {/* </LightTooltip>*/}
                    {/* </Box> */}
                    {/* </IconButton> */}
                    {/* share pop up */}
                    {share ?
                      <>
                        {isOnePage ?
                          <>
                            <Box
                              sx={{
                                position       : "absolute",
                                bottom         : "-155px",
                                right          : "-20px",
                                width          : "40vw",
                                height         : "130px",
                                border         : "1px solid #E1E2E9",
                                backgroundColor: "white",
                                zIndex         : 50,
                                borderRadius   : "11px",
                                boxShadow      : "0px 3px 6px #071AFF17"
                              }}>
                              <Box
                                sx={{
                                  margin: "17px"
                                }}>
                                {/* head title box */}
                                <Box>
                                  <Typography sx={{ fontSize: "18px" }}>
                                    {isOnePage
                                      ? " Share this One Page Pitch Deck"
                                      : "Share this Pitch Deck"}
                                  </Typography>
                                </Box>
                                {/* text field box */}
                                <Box
                                  sx={{
                                    // border: 1,
                                    marginTop : "10px",
                                    // width: "",
                                    display   : "flex",
                                    alignItems: "center",
                                    position  : "relative"
                                  }}>
                                  <Box sx={{ width: "83%" }}>
                                    <TextField
                                      fullWidth
                                      sx={{
                                        "& .MuiInputBase-input": {
                                          fontSize: "14px",
                                          padding : "7px 10px",
                                          border  : "none",
                                          color   : "#535353"
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                          border      : "1px solid #B4B4FA",
                                          borderRadius: "6px"
                                        }
                                      }}
                                      value={
                                        isUpload
                                          ? uploadedPitchUrl
                                            ? `${
                                              window.location.origin
                                            }/pitch-one-page-presentation?id=${
                                              venture._id
                                            }&ventureName=${venture.details.name
                                              .split(" ")
                                              .join("")}`
                                            : ""
                                          : `${
                                            window.location.origin
                                          }/pitch-one-page-presentation?id=${
                                            venture.pitchDeck
                                          }&ventureName=${venture.details.name
                                            .split(" ")
                                            .join("")}`
                                      }
                                      disabled
                                    />
                                  </Box>
                                  {/* button */}
                                  <Box
                                    sx={{
                                      position: "absolute",
                                      right   : "3px",
                                      top     : "-0.5px"
                                    }}>
                                    {isCopied &&
                                      <Box
                                        sx={{
                                          position: "absolute",
                                          top     : "32px",
                                          left    : "10px"
                                        }}>
                                        <Typography
                                          sx={{
                                            color   : "#08910B",
                                            fontSize: "14px"
                                          }}>
                                          Link copied!
                                        </Typography>
                                      </Box>
                                    }
                                    <Button
                                      variant="outlined"
                                      style={{
                                        padding        : "4px 0px",
                                        textTransform  : "none",
                                        fontSize       : "14px",
                                        fontWeight     : "bold",
                                        width          : "100px",
                                        backgroundColor: "white",
                                        borderRadius   : "6px"
                                      }}
                                      onClick={e => copyToClipboard(e)}>
                                      Copy link
                                    </Button>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </>
                          :
                          <>
                            <Box
                              sx={{
                                position       : "absolute",
                                bottom         : "-155px",
                                right          : "-20px",
                                width          : "40vw",
                                height         : "130px",
                                border         : "1px solid #E1E2E9",
                                backgroundColor: "white",
                                zIndex         : 50,
                                borderRadius   : "11px",
                                boxShadow      : "0px 3px 6px #071AFF17"
                              }}>
                              <Box
                                sx={{
                                  margin: "17px"
                                }}>
                                {/* head title box */}
                                <Box>
                                  <Typography sx={{ fontSize: "18px" }}>
                                    {isOnePage
                                      ? " Share this One Page Pitch Deck"
                                      : "Share this Pitch Deck"}
                                  </Typography>
                                </Box>
                                {/* text field box */}
                                <Box
                                  sx={{
                                    // border: 1,
                                    marginTop : "10px",
                                    // width: "",
                                    display   : "flex",
                                    alignItems: "center",
                                    position  : "relative"
                                  }}>
                                  <Box sx={{ width: "83%" }}>
                                    <TextField
                                      // value={"Name"}
                                      fullWidth
                                      sx={{
                                        "& .MuiInputBase-input": {
                                          fontSize: "14px",
                                          padding : "7px 10px",
                                          border  : "none",
                                          color   : "#535353"
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                          border      : "1px solid #B4B4FA",
                                          borderRadius: "6px"
                                        }
                                      }}
                                      value={
                                        isUpload
                                          ? uploadedPitchUrl
                                            ? `${
                                              window.location.origin
                                            }/pitchpdfpresentation?id=${
                                              venture._id
                                            }&ventureName=${venture.details.name
                                              .split(" ")
                                              .join("")}`
                                            : ""
                                          : `${
                                            window.location.origin
                                          }/pitchpresentation?id=${
                                            venture.pitchDeck
                                          }&ventureName=${venture.details.name
                                            .split(" ")
                                            .join("")}`
                                      }
                                      disabled
                                    />
                                  </Box>
                                  {/* button */}
                                  <Box
                                    sx={{
                                      position: "absolute",
                                      right   : "3px",
                                      top     : "-0.5px"
                                    }}>
                                    {isCopied &&
                                      <Box
                                        sx={{
                                          position: "absolute",
                                          top     : "32px",
                                          left    : "10px"
                                        }}>
                                        <Typography
                                          sx={{
                                            color   : "#08910B",
                                            fontSize: "14px"
                                          }}>
                                          Link copied!
                                        </Typography>
                                      </Box>
                                    }
                                    <Button
                                      variant="outlined"
                                      style={{
                                        padding        : "4px 0px",
                                        textTransform  : "none",
                                        fontSize       : "14px",
                                        fontWeight     : "bold",
                                        width          : "100px",
                                        backgroundColor: "white",
                                        borderRadius   : "6px"
                                      }}
                                      onClick={e => copyToClipboard(e)}>
                                      Copy link
                                    </Button>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </>
                        }
                      </>
                      :
                      <></>
                    }
                    {/* --------- */}
                  </Box>
                  {isOnePage ?
                    <>
                      <Box id={styles.buttonDivider}></Box>
                      <Box id="pitchdeckexport">
                        <Tooltip
                          title={
                            venture.userRole === "Commenter"
                              ? ""
                              : publishedImages.length > 0 &&
                                publishedImages[0].imageUrl
                                ? "Export to pdf"
                                : "Edit & Publish your Pitch Deck to enable these features"
                          }
                          placement="top">
                          <Box id={styles.shareButtonBox}>
                            <Button
                              style={{
                                width         : "100%",
                                display       : "flex",
                                justifyContent: "space-evenly",
                                alignItems    : "center",
                                textTransform : "none",

                                padding: "3px 0px"
                              }}
                              variant="contained"
                              color="primary"
                              onClick={() => {

                                OnePageExportPdf();
                              
                              }}
                              disabled={
                                venture.userRole === "Commenter" ||
                                (publishedImages.length > 0 &&
                                publishedImages[0].imageUrl
                                  ? false
                                  : true)
                              }>
                              <GetAppIcon
                                id={styles.shareIcon}
                                // sx={{ color: "#FFFFFF" }}
                              />
                              <Typography id={styles.shareButtonTypo}>
                                Export
                              </Typography>
                            </Button>
                          </Box>
                        </Tooltip>
                      </Box>
                    </>
                    :
                    <>
                      {!isUpload &&
                        <>
                          <Box id={styles.buttonDivider}></Box>
                          <Box id="pitchdeckexport">
                            <Tooltip
                              title={
                                venture.userRole === "Commenter"
                                  ? ""
                                  : publishedImages.length > 0 &&
                                    publishedImages[0].imageUrl
                                    ? "Export to pdf"
                                    : "Edit & Publish your Pitch Deck to enable these features"
                              }
                              placement="top">
                              <Box id={styles.shareButtonBox}>
                                <Button
                                  style={{
                                    width         : "100%",
                                    display       : "flex",
                                    justifyContent: "space-evenly",
                                    alignItems    : "center",
                                    textTransform : "none",

                                    padding: "3px 0px"
                                  }}
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {

                                    exportPdf();
                                  
                                  }}
                                  disabled={
                                    venture.userRole === "Commenter" ||
                                    (publishedImages.length > 0 &&
                                    publishedImages[0].imageUrl
                                      ? false
                                      : true)
                                  }>
                                  <GetAppIcon
                                    id={styles.shareIcon}
                                    // sx={{ color: "#FFFFFF" }}
                                  />
                                  <Typography id={styles.shareButtonTypo}>
                                    Export
                                  </Typography>
                                </Button>
                              </Box>
                            </Tooltip>
                          </Box>
                        </>
                      }
                    </>
                  }
                  {/* divider */}
                  <Box
                    id={styles.buttonDivider}
                    style={
                      isOnePage ? { display: "none" } : { display: "unset" }
                    }></Box>
                  {/* play button */}
                  <div
                    style={
                      isOnePage ? { display: "none" } : { display: "unset" }
                    }>
                    {isUpload ?
                      <Button
                        // disabled
                        disabled={uploadedPitchUrl ? false : true}
                        variant="contained"
                        color="primary"
                        id={styles.playButtonBox}
                        onClick={() => {

                          // pitchpdfpresentation
                          history.push(
                            `/pitchpdfpresentation?id=${venture._id}&ventureName=${venture.details.name}`,
                            {
                              fromPitchDeck: true
                            }
                          );
                        
                        }}>
                        <PlayCircleIcon id={styles.playIcon} />
                        <Typography id={styles.playButtonTypo}>Play</Typography>
                      </Button>
                      :
                      <Tooltip
                        title={
                          venture.userRole === "Commenter"
                            ? ""
                            : publishedImages.length > 0 &&
                              publishedImages[0].imageUrl
                              ? ""
                              : "Edit & Publish your Pitch Deck to enable these features"
                        }
                        placement="top">
                        <Box>
                          <Button
                            // disabled
                            variant="contained"
                            color="primary"
                            id={styles.playButtonBox}
                            onClick={() => {

                              // pitchpdfpresentation

                              history.push(
                                `/pitchpresentation?id=${venture.pitchDeck}&ventureName=${venture.details.name}`,
                                {
                                  isRender     : renderDetails.isRender,
                                  isRendername : renderDetails.isRendername,
                                  fromPitchDeck: true
                                }
                              );
                            
                            }}
                            disabled={
                              publishedImages.length > 0 &&
                              publishedImages[0].imageUrl
                                ? false
                                : true
                            }>
                            <PlayCircleIcon id={styles.playIcon} />
                            <Typography id={styles.playButtonTypo}>
                              Play
                            </Typography>
                          </Button>
                        </Box>
                      </Tooltip>
                    }
                  </div>
                  {/* </Box> */}
                </Box>
              }
            </div>
          </div>

          <hr />

          <Box sx={{ margin: "0px 30px 0px 20px" }}>
            <Box
              // id={styles.mainBox}
              sx={{
                display       : "flex",
                columnGap     : 2,
                justifyContent: "space-between"
              }}>
              <Box
                id={styles.leftFlex}
                style={isOnePage ? { width: "unset" } : { width: "18%" }}>
                {/* header box */}

                {!isOnePage ?
                  <>
                    {/* left side main box */}
                    {/* -------- */}
                    {slides.length > 0 &&
                      <Box id={styles.navBox}>
                        {isUpload ?
                          <IndividualPdfNavBox
                            pdfPages={pdfPages}
                            pdfPageNumber={pdfPageNumber}
                            setPdfPageNumber={setPdfPageNumber}
                            setPdfPages={setPdfPages}
                            uploadedPdf={uploadedPdf}
                            uploadedPitchName={uploadedPitchName}
                            uploadedPitchUrl={uploadedPitchUrl}
                          />
                          :
                          <>
                            {slides.map((slide, index) => {

                              return <IndividualNavBox
                                publishedImages={publishedImages}
                                handleRenderDetails={handleRenderDetails}
                                isRender={renderDetails.isRender}
                                isRendername={renderDetails.isRendername}
                                slide={slide}
                                handleHideChange={handleHideChange}
                                index={index}
                                isEdit={isEdit}
                                setPublishedImages={setPublishedImages}
                              />;
                            
                            })}
                          </>
                        }
                        {/* </Box> */}
                      </Box>
                    }
                    {/* </Box> */}
                    {/* ------------ */}
                  </>
                  :
                  <>
                    <div></div>
                  </>
                }
              </Box>
              <Box
                id={styles.rightFlex}
                style={isOnePage ? { width: "100%" } : { width: "82%" }}>
                <Box
                  className="tribe-pitch-deck-nav-button-group"
                  sx={{
                    width     : "70%",
                    height    : "6%",
                    display   : "flex",
                    marginLeft: "1em"
                  }}>
                  <ToggleButtonGroup
                    fullWidth={true}
                    value={alignment}
                    exclusive
                    onChange={handleAlignment}
                    aria-label="text alignment">
                    <ToggleButton
                      value="0"
                      onClick={() => {

                        setIsUpload(false);
                        setIsOnePage(false);
                        // setIsSelect(false);
                        setLoading(true);
                        setTimeout(() => {

                          setLoading(false);
                        
                        }, 700);
                      
                      }}>
                      Tribe Pitch
                    </ToggleButton>

                    {
                      publishedImages.length > 0 &&
                      publishedImages[0].imageUrl ?
                        <ToggleButton
                          value="1"
                          disabled={
                            publishedImages.length > 0 &&
                            publishedImages[0].imageUrl
                              ? false
                              : true
                          }
                          onClick={() => {

                            setIsUpload(false);
                            setIsOnePage(true);
                            // setIsSelect(true);
                            setIsEdit(false);

                            setLoading(true);
                            setTimeout(() => {

                              setLoading(false);
                            
                            }, 700);
                          
                          }}>
                          One Page Biz-Plan
                        </ToggleButton>
                        :
                        <>
                          <Tooltip
                            placement="top"
                            title={
                              publishedImages.length > 0 &&
                              publishedImages[0].imageUrl
                                ? ""
                                : "Edit and Publish your Pitch Deck to enable this view"
                            }>
                            <span className="tribe-pitch-deck-disabled-tab-span">
                              One Page Biz-Plan
                            </span>
                          </Tooltip>
                        </>
                      
                    }
                    {venture.userRole !== "Commenter" ?
                      <ToggleButton
                        value="2"
                        disabled={
                          venture.userRole === "Commenter" ? true : false
                        }
                        onClick={() => {

                          setIsUpload(true);
                          setIsOnePage(false);
                          // setIsSelect(false);
                          setLoading(true);
                          setTimeout(() => {

                            setLoading(false);
                          
                          }, 700);
                        
                        }}>
                        <span>Upload my Pitch</span>
                      </ToggleButton>
                      :
                      <>
                        <Tooltip
                          placement="top"
                          title={
                            venture.userRole === "Commenter" &&
                            "Only the project owner and the collaborator with editor access can upload here"
                          }>
                          <span className="tribe-pitch-deck-disabled-tab-span">
                            Upload my Pitch
                          </span>
                        </Tooltip>
                      </>
                    }
                    <Box sx={{ position: "relative", left: "1%" }}>
                      {uploadedPitchName ?
                        <Box
                          sx={{
                            position: "absolute",
                            top     : "8px",
                            right   : "-2px"
                          }}>
                          <IconButton
                            onClick={e => {

                              handleClick(e);
                            
                            }}
                            sx={{ padding: "0px" }}>
                            <MoreVertIcon sx={{ color: "gray" }} />
                          </IconButton>

                          {/* pitch menu item */}
                          <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={() => {

                              handleClose();
                            
                            }}
                            style={{ top: -3 }}
                            anchorOrigin={{
                              vertical  : "top",
                              horizontal: "right"
                            }}
                            sx={{
                              "& .MuiMenu-list": {
                                padding        : "2px 7px",
                                backgroundColor: "#707070",
                                color          : "white"
                              }
                            }}>
                            <MenuItem
                              onClick={e => {

                                e.stopPropagation();
                                deleteUploadedPdf(venture._id);
                                setUploadedPitchUrl("");
                                setUploadedPitchName("");
                                setUploadPitchSuccess("");

                                handleClose();
                              
                              }}
                              sx={{ fontSize: "12px", padding: "3px" }}>
                              Delete
                            </MenuItem>
                          </Menu>
                          {/* ----------- */}
                        </Box>
                        :
                        <></>
                      }
                    </Box>
                  </ToggleButtonGroup>
                </Box>

                {/* right side main box */}
                {slides.length > 0 &&
                  <Box
                    id={styles.rightMainBox}
                    style={
                      isOnePage
                        ? { overflowY: "auto", height: "75vh" }
                        : { overflowY: "unset", height: "74vh" }
                    }
                    ref={boxContentRef}>
                    {/* left right buttons */}
                    {!isOnePage ?
                      <>
                        <Box
                          id={styles.leftIconBox}
                          ref={leftArrowElement}
                          onClick={() => {

                            if (isUpload) {

                              previousPage();
                            
                            } else {

                              if (
                                renderDetails.isRendername ===
                                slides[0].renderName
                              ) {

                                handleRenderDetails(
                                  slides[slides.length - 1].renderName,
                                  slides.length - 1
                                );
                              
                              } else {

                                handleRenderDetails(
                                  slides[renderDetails.isRender - 1].renderName,
                                  renderDetails.isRender - 1
                                );
                              
                              }
                            
                            }
                          
                          }}>
                          <KeyboardArrowLeftIcon />
                        </Box>
                        <Box
                          id={styles.rightIconBox}
                          ref={rightArrowElement}
                          onClick={() => {

                            if (isUpload) {

                              nextPage();
                            
                            } else {

                              if (
                                renderDetails.isRendername ===
                                slides[slides.length - 1].renderName
                              ) {

                                handleRenderDetails(slides[0].renderName, 0);
                              
                              } else {

                                handleRenderDetails(
                                  slides[renderDetails.isRender + 1].renderName,
                                  renderDetails.isRender + 1
                                );
                              
                              }
                            
                            }
                          
                          }}>
                          <KeyboardArrowRightIcon />
                        </Box>
                      </>
                      :
                      <></>
                    }

                    {/* ----------- */}

                    {/* right content box */}
                    <Box id={styles.rightContentBox}>
                      {isOnePage ?
                        <PitchDeckOnePage
                          onePageData={onePageData}
                          publishedImages={publishedImages}
                          onePageRef={onePageRef}
                        />
                        : isUpload ?
                          <UploadPitchDeck
                            setPdfPageNumber={setPdfPageNumber}
                            setPdfPages={setPdfPages}
                            pdfPages={pdfPages}
                            pdfPageNumber={pdfPageNumber}
                            uploadedPdf={uploadedPdf}
                            setUploadedPdf={setUploadedPdf}
                            fetchUploadedPdf={fetchUploadedPdf}
                            uploadedPitchName={uploadedPitchName}
                            uploadedPitchUrl={uploadedPitchUrl}
                            uploadPitchSuccess={uploadPitchSuccess}
                            setUploadPitchSuccess={setUploadPitchSuccess}
                          />
                          :
                          <>
                            {renderDetails.isRendername === "Cover" ?
                              <Cover
                                errorMsg={errormsg}
                                setErrormsg={setErrormsg}
                                isEdit={isEdit}
                                slides={slides}
                                isRender={renderDetails.isRender}
                                setSlides={setSlides}
                                logo={uploadedImgs.find(
                                  img => img.imgName === "logo"
                                )}
                                coverImg={uploadedImgs.find(
                                  img =>
                                    img.imgName ===
                                  `${
                                    slides[renderDetails.isRender].renderName
                                  }.coverImg`
                                )}
                                logoimg={logoimg}
                                setLogoimg={setLogoimg}
                                uploadImages={uploadImages}
                                deleteUploadedImgs={deleteUploadedImgs}
                                isPublished={isPublished}
                              />
                              : renderDetails.isRendername === "The Problem" ?
                                <Problem
                                  isEdit={isEdit}
                                  Collapsecomment={Collapsecomment}
                                  collapseCommentFunction={
                                    collapse_comment_function
                                  }
                                  slides={slides}
                                  setSlides={setSlides}
                                  logoimg={
                                    editLogoImg ? editLogoImg.imgUrl : logoimg
                                  }
                                  isRender={renderDetails.isRender}
                                  isPublished={isPublished}
                                />
                                : renderDetails.isRendername === "The Customer" ?
                                  <Customer
                                    isEdit={isEdit}
                                    Collapsecomment={Collapsecomment}
                                    collapseCommentFunction={
                                      collapse_comment_function
                                    }
                                    slides={slides}
                                    setSlides={setSlides}
                                    logoimg={
                                      editLogoImg ? editLogoImg.imgUrl : logoimg
                                    }
                                    isRender={renderDetails.isRender}
                                    isPublished={isPublished}
                                  />
                                  : renderDetails.isRendername ===
                            "The Opportunity" ?
                                    <Opportunity
                                      isEdit={isEdit}
                                      Collapsecomment={Collapsecomment}
                                      collapseCommentFunction={
                                        collapse_comment_function
                                      }
                                      slides={slides}
                                      setSlides={setSlides}
                                      logoimg={
                                        editLogoImg ? editLogoImg.imgUrl : logoimg
                                      }
                                      isRender={renderDetails.isRender}
                                      isPublished={isPublished}
                                    />
                                    : renderDetails.isRendername === "Market Size" ?
                                      <MarketSize
                                        isEdit={isEdit}
                                        Collapsecomment={Collapsecomment}
                                        collapseCommentFunction={
                                          collapse_comment_function
                                        }
                                        slides={slides}
                                        setSlides={setSlides}
                                        logoimg={
                                          editLogoImg ? editLogoImg.imgUrl : logoimg
                                        }
                                        isRender={renderDetails.isRender}
                                        isPublished={isPublished}
                                      />
                                      : renderDetails.isRendername === "The Solution" ?
                                        <Solution
                                          isEdit={isEdit}
                                          Collapsecomment={Collapsecomment}
                                          collapseCommentFunction={
                                            collapse_comment_function
                                          }
                                          slides={slides}
                                          setSlides={setSlides}
                                          logoimg={
                                            editLogoImg ? editLogoImg.imgUrl : logoimg
                                          }
                                          isRender={renderDetails.isRender}
                                          isPublished={isPublished}
                                          isRenderName={renderDetails.isRendername}
                                        />
                                        : renderDetails.isRendername ===
                            "Key Value Propositions" ?
                                          <KeyValuePropositions
                                            isEdit={isEdit}
                                            Collapsecomment={Collapsecomment}
                                            collapseCommentFunction={
                                              collapse_comment_function
                                            }
                                            slides={slides}
                                            setSlides={setSlides}
                                            logoimg={
                                              editLogoImg ? editLogoImg.imgUrl : logoimg
                                            }
                                            isRender={renderDetails.isRender}
                                            isPublished={isPublished}
                                          />
                                          : renderDetails.isRendername ===
                            "The Product Layout - 1" ?
                                            <TheProduct
                                              errorMsg={errormsg}
                                              setErrormsg={setErrormsg}
                                              isEdit={isEdit}
                                              Collapsecomment={Collapsecomment}
                                              collapseCommentFunction={
                                                collapse_comment_function
                                              }
                                              slides={slides}
                                              setSlides={setSlides}
                                              logoimg={
                                                editLogoImg ? editLogoImg.imgUrl : logoimg
                                              }
                                              isRender={renderDetails.isRender}
                                              productImg={uploadedImgs.find(
                                                img =>
                                                  img.imgName ===
                                  `${
                                    slides[renderDetails.isRender].renderName
                                  }.productImage`
                                              )}
                                              uploadImages={uploadImages}
                                              deleteUploadedImgs={deleteUploadedImgs}
                                              isPublished={isPublished}
                                            />
                                            : renderDetails.isRendername ===
                            "The Product Layout - 2" ?
                                              <TheProduct2
                                                errorMsg={errormsg}
                                                errorMsg2={errormsg2}
                                                setErrormsg={setErrormsg}
                                                setErrormsg2={setErrormsg2}
                                                isEdit={isEdit}
                                                Collapsecomment={Collapsecomment}
                                                collapseCommentFunction={
                                                  collapse_comment_function
                                                }
                                                slides={slides}
                                                setSlides={setSlides}
                                                logoimg={
                                                  editLogoImg ? editLogoImg.imgUrl : logoimg
                                                }
                                                isRender={renderDetails.isRender}
                                                productImgOne={uploadedImgs.find(
                                                  img =>
                                                    img.imgName ===
                                  `${
                                    slides[renderDetails.isRender].renderName
                                  }.productImageOne`
                                                )}
                                                productImgTwo={uploadedImgs.find(
                                                  img =>
                                                    img.imgName ===
                                  `${
                                    slides[renderDetails.isRender].renderName
                                  }.productImageTwo`
                                                )}
                                                uploadImages1={uploadImages}
                                                uploadImages2={uploadImages2}
                                                deleteUploadedImgs={deleteUploadedImgs}
                                                isPublished={isPublished}
                                              />
                                              : renderDetails.isRendername === "Business Model" ?
                                                <BusinessModel
                                                  isEdit={isEdit}
                                                  Collapsecomment={Collapsecomment}
                                                  collapseCommentFunction={
                                                    collapse_comment_function
                                                  }
                                                  slides={slides}
                                                  setSlides={setSlides}
                                                  logoimg={
                                                    editLogoImg ? editLogoImg.imgUrl : logoimg
                                                  }
                                                  isRender={renderDetails.isRender}
                                                  isPublished={isPublished}
                                                />
                                                : renderDetails.isRendername === "Traction" ?
                                                  <Traction
                                                    isEdit={isEdit}
                                                    Collapsecomment={Collapsecomment}
                                                    collapseCommentFunction={
                                                      collapse_comment_function
                                                    }
                                                    slides={slides}
                                                    setSlides={setSlides}
                                                    logoimg={
                                                      editLogoImg ? editLogoImg.imgUrl : logoimg
                                                    }
                                                    isRender={renderDetails.isRender}
                                                    uploadedImgs={uploadedImgs}
                                                    uploadImages={uploadImages}
                                                    removeUploadImage={removeUploadImage}
                                                    deleteUploadedImgs={deleteUploadedImgs}
                                                    isPublished={isPublished}
                                                  />
                                                  : renderDetails.isRendername === "Milestone" ?
                                                    <Milestone
                                                      isEdit={isEdit}
                                                      Collapsecomment={Collapsecomment}
                                                      collapseCommentFunction={
                                                        collapse_comment_function
                                                      }
                                                      slides={slides}
                                                      setSlides={setSlides}
                                                      logoimg={
                                                        editLogoImg ? editLogoImg.imgUrl : logoimg
                                                      }
                                                      isRender={renderDetails.isRender}
                                                      isPublished={isPublished}
                                                    />
                                                    : renderDetails.isRendername ===
                            "Go-to-Market Strategies & Ideas" ?
                                                      <GTMStrategiesIdeas
                                                        isEdit={isEdit}
                                                        Collapsecomment={Collapsecomment}
                                                        collapseCommentFunction={
                                                          collapse_comment_function
                                                        }
                                                        slides={slides}
                                                        setSlides={setSlides}
                                                        logoimg={
                                                          editLogoImg ? editLogoImg.imgUrl : logoimg
                                                        }
                                                        isRender={renderDetails.isRender}
                                                        isPublished={isPublished}
                                                      />
                                                      : renderDetails.isRendername === "Team" ?
                                                      // <ThankYou />
                                                        <Team
                                                          isEdit={isEdit}
                                                          Collapsecomment={Collapsecomment}
                                                          collapseCommentFunction={
                                                            collapse_comment_function
                                                          }
                                                          slides={slides}
                                                          setSlides={setSlides}
                                                          logoimg={
                                                            editLogoImg ? editLogoImg.imgUrl : logoimg
                                                          }
                                                          isRender={renderDetails.isRender}
                                                          uploadedImgs={uploadedImgs}
                                                          uploadImages={uploadImages}
                                                          removeUploadImage={removeUploadImage}
                                                          deleteUploadedImgs={deleteUploadedImgs}
                                                          isPublished={isPublished}
                                                        />
                                                        : renderDetails.isRendername === "Competition" ?
                                                          <Competition
                                                            isEdit={isEdit}
                                                            Collapsecomment={Collapsecomment}
                                                            collapseCommentFunction={
                                                              collapse_comment_function
                                                            }
                                                            slides={slides}
                                                            setSlides={setSlides}
                                                            logoimg={
                                                              editLogoImg ? editLogoImg.imgUrl : logoimg
                                                            }
                                                            isRender={renderDetails.isRender}
                                                            isPublished={isPublished}
                                                          />
                                                          : renderDetails.isRendername ===
                            "Competitive Advantage" ?
                                                            <CompetitiveAdvantage
                                                              isEdit={isEdit}
                                                              Collapsecomment={Collapsecomment}
                                                              collapseCommentFunction={
                                                                collapse_comment_function
                                                              }
                                                              slides={slides}
                                                              setSlides={setSlides}
                                                              logoimg={
                                                                editLogoImg ? editLogoImg.imgUrl : logoimg
                                                              }
                                                              isRender={renderDetails.isRender}
                                                              isPublished={isPublished}
                                                            />
                                                            : renderDetails.isRendername === "The Ask" ?
                                                              <TheAsk
                                                                isEdit={isEdit}
                                                                Collapsecomment={Collapsecomment}
                                                                collapseCommentFunction={
                                                                  collapse_comment_function
                                                                }
                                                                slides={slides}
                                                                setSlides={setSlides}
                                                                logoimg={
                                                                  editLogoImg ? editLogoImg.imgUrl : logoimg
                                                                }
                                                                isRender={renderDetails.isRender}
                                                                isPublished={isPublished}
                                                              />
                                                              : renderDetails.isRendername === "Financials" ?
                                                                <PitchFinancials
                                                                  errorMsg={errormsg}
                                                                  setErrormsg={setErrormsg}
                                                                  isEdit={isEdit}
                                                                  Collapsecomment={Collapsecomment}
                                                                  collapseCommentFunction={
                                                                    collapse_comment_function
                                                                  }
                                                                  slides={slides}
                                                                  setSlides={setSlides}
                                                                  logoimg={
                                                                    editLogoImg ? editLogoImg.imgUrl : logoimg
                                                                  }
                                                                  isRender={renderDetails.isRender}
                                                                  chartImg={uploadedImgs.find(
                                                                    img =>
                                                                      img.imgName ===
                                  `${
                                    slides[renderDetails.isRender].renderName
                                  }.chartImage`
                                                                  )}
                                                                  uploadImages={uploadImages}
                                                                  deleteUploadedImgs={deleteUploadedImgs}
                                                                  isPublished={isPublished}
                                                                />
                                                                : renderDetails.isRendername === "Thank you" ?
                                                                  <ThankYou
                                                                    isEdit={isEdit}
                                                                    slides={slides}
                                                                    setSlides={setSlides}
                                                                    logoimg={
                                                                      editLogoImg ? editLogoImg.imgUrl : logoimg
                                                                    }
                                                                    isRender={renderDetails.isRender}
                                                                    isPublished={isPublished}
                                                                  />
                                                                  :
                                                                  <></>
                            }
                          </>
                      }
                    </Box>
                    {/* --copyright text-- */}
                    {isUpload || isOnePage ?
                      <></>
                      :
                      <>
                        {renderDetails.isRendername === "Cover" ?
                          <Box
                            sx={{
                              position: "absolute",
                              bottom  : "0px",
                              right   : "34px",
                              display : "flex"
                            }}>
                            <Typography
                              sx={{ fontSize: "12px", marginRight: "2px" }}>
                              Copyright ©
                            </Typography>
                            <Box>
                              {isEdit ?
                                <Box sx={{ display: "flex" }}>
                                  <TextField
                                    // type="number"
                                    value={year}
                                    placeholder={"Enter year"}
                                    inputProps={{ maxLength: 6 }}
                                    // InputProps={{ inputProps: { max: 6, min: 4 } }}
                                    // inputProps={{ min: 4, max: 6 }}
                                    fullWidth
                                    sx={{
                                      // "& .MuiOutlinedInput-root": {
                                      //   padding: "0px 0px",
                                      // },
                                      "& .MuiInputBase-input": {
                                        fontSize : "12px",
                                        padding  : "0px 5px",
                                        border   : "none",
                                        textAlign: "center"
                                      },
                                      "& .MuiOutlinedInput-notchedOutline": {
                                        border: "2px dotted #B4B4FA"
                                      }
                                    }}
                                    style={{ marginRight: "3px", width: "40%" }}
                                    onChange={e => {

                                      let newSlides = [ ...slides ];
                                      let coverSlideIndex = newSlides.findIndex(
                                        slide => slide.renderName === "Cover"
                                      );

                                      newSlides[coverSlideIndex][
                                        "isChanged"
                                      ] = true;
                                      setSlides(newSlides);
                                      setYear(removeNonNumeric(e.target.value));
                                    
                                    }}
                                  />
                                  <TextField
                                    value={copyrightsName}
                                    placeholder={"Enter copyrights name"}
                                    fullWidth
                                    inputProps={{ maxLength: 25 }}
                                    sx={{
                                      // "& .MuiOutlinedInput-root": {
                                      //   padding: "0px 0px",
                                      // },
                                      "& .MuiInputBase-input": {
                                        fontSize : "12px",
                                        padding  : "0px 5px",
                                        border   : "none",
                                        textAlign: "center"
                                      },
                                      "& .MuiOutlinedInput-notchedOutline": {
                                        border: "2px dotted #B4B4FA"
                                      }
                                    }}
                                    onChange={e => {

                                      let newSlides = [ ...slides ];
                                      let coverSlideIndex = newSlides.findIndex(
                                        slide => slide.renderName === "Cover"
                                      );

                                      newSlides[coverSlideIndex][
                                        "isChanged"
                                      ] = true;
                                      setSlides(newSlides);
                                      setCopyrightsName(e.target.value);
                                    
                                    }}
                                  />
                                </Box>
                                :
                                <Typography sx={{ fontSize: "12px" }}>
                                  {year} {copyrightsName}.
                                </Typography>
                              }
                              {/* <Typography sx={{ fontSize: "12px" }}>
                          
                          YYYY venture name.
                        </Typography> */}
                            </Box>
                            <Typography
                              sx={{ fontSize: "12px", marginLeft: "2px" }}>
                              All Rights Reserved
                            </Typography>
                          </Box>
                          :
                          <Box
                            sx={{
                              position: "absolute",
                              bottom  : "0px",
                              right   : "34px",
                              display : "flex"
                            }}>
                            <Typography
                              sx={{ fontSize: "12px", marginRight: "2px" }}>
                              Copyright ©
                            </Typography>
                            <Typography sx={{ fontSize: "12px" }}>
                              {year} {copyrightsName}.
                            </Typography>
                            <Typography
                              sx={{ fontSize: "12px", marginLeft: "2px" }}>
                              All Rights Reserved
                            </Typography>
                          </Box>
                        }
                      </>
                    }
                    {/* ------- */}
                  </Box>
                }
              </Box>
              <Box id={styles.commentBox}>
                <Box
                  sx={{
                    height    : "6%",
                    // border: "1px solid red",
                    width     : "100%",
                    display   : "flex",
                    alignItems: "center"
                  }}></Box>
                <Box sx={{ position: "relative" }}>
                  <CommentContainer
                    minWidth={"13vw"}
                    pitch={true}
                    collapse={Collapsecomment}
                    comments={
                      comments.length > 0
                        ? comments[
                          comments.findIndex(
                            i => i.renderName === renderDetails.isRendername
                          )
                        ]["comments"]
                        : []
                    }
                    addComment={addComment}
                    deleteComment={deleteComment}
                    updateComment={updateComment}
                    height="72vh"></CommentContainer>

                  {Collapsecomment ?
                    <Box
                      id={styles.closeIconBox}
                      onClick={collapse_comment_function}>
                      <CloseIcon id={styles.closeIcon} />
                    </Box>
                    :
                    <></>
                  }
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      }
    </Box>
  );

};

export default PitchDeck;
