/* eslint-disable camelcase */
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import IndividualSectionHeaders from "../../../../components/IndividualSectionHeaders";
import ModuleSections from "../../../../components/ModuleSections";
import Data from "../../../../Data/Gtm.json";
import IndividualBoard from "./IndividualBoard";

import { getGtm, updateGtmValues } from "../../../../services/gtm";

import BackNextButton from "../../../../components/BackNextButton";
import {
  createGtmComment,
  deleteGtmComment,
  updateGtmComment
} from "../../../../services/comment";
import styles from "./Positioning.module.css";

const PositioningStoryBoard = () => {

  const [ positioning, setPositioning ] = useState({
    productPositioning: {},
    beforeAfterState  : {},
    segments          : [],
    research          : {},
    usecases          : [],
    differentiation   : {}
  });
  const [ comments, setComments ] = useState([]);
  const [ dialogbox, setDialog ] = useState(false);
  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const history = useHistory();
  const content = Data.gtmpositioning;
  const boards = Data.positioningstoryboard;
  let { user, venture } = useSelector(state => {

    return { ...state };
  
  });

  const pathname = history.location.pathname.substring(1);

  useEffect(() => {

    fetchGtm();
  
  }, []);

  const fetchGtm = async () => {

    try {

      const response = await getGtm(venture.gtm);
      // console.log(response.data);

      setPositioning(response.data.positioning);
      setComments(response.data.comments.positioning.storyBoard);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);
    
    } else {

      setCollapsecomment(true);
    
    }
  
  };

  const handleBeforeAfterState = (value, name) => {

    let newpositioning = { ...positioning };

    newpositioning.beforeAfterState[name] = value;
    setPositioning(newpositioning);
  
  };

  const handleUsecases = (value, index) => {

    let newpositioning = { ...positioning };

    newpositioning.usecases[index] = value;
    setPositioning(newpositioning);
  
  };

  const addRow = () => {

    let newpositioning = { ...positioning };

    newpositioning.usecases.push("");
    setPositioning(newpositioning);
  
  };

  const removeRow = index => {

    let newpositioning = { ...positioning };

    newpositioning.usecases.splice(index, 1);
    setPositioning(newpositioning);
  
  };

  const handleDifferentiation = (value, name) => {

    let newpositioning = { ...positioning };

    newpositioning.differentiation[name] = value;
    setPositioning(newpositioning);
  
  };

  const handleSubmit = async () => {

    try {

      await updateGtmValues(
        venture.gtm,
        "positioning",
        positioning
      );
      // console.log(response.data);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const addComment = async (commentText, replyTo) => {

    try {

      const response = await createGtmComment(
        commentText,
        user._id,
        "positioning",
        "storyBoard",
        venture.gtm,
        replyTo,
        window.location.href,
        "GTM > Positioning > ‘Hero Transformation’ Story"
      );

      setComments(response.data.comments.positioning.storyBoard);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateComment = async (commentid, commentText) => {

    try {

      const response = await updateGtmComment(
        commentText,
        user._id,
        commentid,
        venture._id,
        window.location.href,
        "GTM > Positioning > ‘Hero Transformation’ Story"
      );

      setComments(response.data.comments.positioning.storyBoard);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const deleteComment = async commentid => {

    try {

      const response = await deleteGtmComment(
        "positioning",
        "storyBoard",
        venture.gtm,
        commentid,
        user._id,
        window.location.href,
        "GTM > Positioning > ‘Hero Transformation’ Story"
      );

      setComments(response.data.comments.positioning.storyBoard);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  return (
    <ModuleSections
      Collapsecomment={ Collapsecomment }
      comments={ comments }
      addComment={ addComment }
      deleteComment={ deleteComment }
      updateComment={ updateComment }>
      <div className="box-display-style-for-next-and-back-button-with-justify">
        <div className="box-indiv-header-con">
          <IndividualSectionHeaders
            mainheading={ "Positioning" }
            subheading={ "‘Hero Transformation’ Story" }
            headingcontent={
              "Craft a ‘Hero Transformation’ story for your customer. Click on the pictures to edit the story."
            }
            isGtm={ true }
            Dialogopen={ Dialogopen }
            Dialogclose={ Dialogclose }
            dialogbox={ dialogbox }
            collapse_comment_function={ collapse_comment_function }
            content={ content }
            // isGtmChevron={true}
            isGtmChevron={ pathname.includes("gtm") ? "gtmV" : "customer" }
          />
        </div>
        <Grid item xs={ 12 } id={ styles.maingrid }>
          {/* <Typography
          sx={{ marginBottom: "20px", fontStyle: "italic", fontSize: "13px" }}
        >
          *Click on the pictures to edit the story.
        </Typography> */}

          <Grid id={ styles.contentGrid } container mb={ 2 }>
            { boards.map((board, index) => {

              // console.log(index);
              return index === 1 || index === 5 || index === 9 ?
                <Grid item md={ 2 } id={ styles.arrowgrid }>
                  <img src={ board.imgsrc } alt="arrow" id={ styles.arrowimg } />
                </Grid>
                : index === 3 || index === 7 ?
                  <Grid item md={ 12 }>
                    <img
                      src={ board.imgsrc }
                      alt="arrows2"
                      id={ styles.rowarrowimg }
                    />
                  </Grid>
                  : board.sectionname === "usecases" ?
                    <IndividualBoard
                      board={ board }
                      positioning={ positioning }
                      handleUsecases={ handleUsecases }
                      addRow={ addRow }
                      removeRow={ removeRow }
                      handleSubmit={ handleSubmit }
                    />
                    : board.sectionname === "differentiation" ?
                      <IndividualBoard
                        board={ board }
                        positioning={ positioning }
                        handleDifferentiation={ handleDifferentiation }
                        handleSubmit={ handleSubmit }
                      />
                      :
                      <IndividualBoard
                        board={ board }
                        positioning={ positioning }
                        handleChange={ handleBeforeAfterState }
                        handleSubmit={ handleSubmit }
                      />
              ;
            
            }) }
          </Grid>
        </Grid>
        <div className="ttribe-back-next-button-con">
          <BackNextButton
            restrictedBackClick={ () => {

              history.push("/gtmpositioning");
            
            } }
            backClick={ () => {

              history.push("/gtmpositioning");
            
            } }
            restrictedNextClick={ () => {

              history.push("/gtmpositioningbeforeafter");
            
            } }
            nextClick={ () => {

              history.push("/gtmpositioningbeforeafter");
            
            } }
          />
        </div>
      </div>
    </ModuleSections>
  );

};

export default PositioningStoryBoard;
