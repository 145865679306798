/* eslint-disable camelcase */
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box,
  // DialogContent,
  // Button,
  // Tooltip,
  // TextField,
  Dialog,
  Grid, IconButton, Slide, Typography
} from "@mui/material";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BackNextButton from "../../../components/BackNextButton";
import IndividualSectionHeaders from "../../../components/IndividualSectionHeaders";
import ModuleSections from "../../../components/ModuleSections";
import Datas from "../../../Data/Problem.json";
import { getSegments } from "../../../services/persona";
import {
  getProblemSolution,
  updateSolutionCapability
} from "../../../services/problemsolution";
import SegmentsPersonasDialog from "../Customer/TargetSegments/SegmentsPersonasDialog";

import PersonaRowGrid from "./PersonaRowGrid";
import styles from "./Problem.module.css";

import { useUnmount } from "../../../components/useUnmount";
import {
  createProblemSolutionComment,
  deleteProblemSolutionComment,
  updateProblemSolutionComment
} from "../../../services/comment";
// import { set } from "lodash";

const Transition = forwardRef(function Transition (props, ref) {

  return <Slide direction="up" ref={ ref } { ...props } />;

});

const PersonaProblemMap = () => {

  const [ segments, setSegments ] = useState([]);
  const [ segmentsDialog, setSegmentsDialog ] = useState([]);
  const [ comments, setComments ] = useState([]);
  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const [ dialogbox, setDialog ] = useState(false);
  const [ segmentdialogbox, setSegmentDialog ] = useState(false);
  const [ isDataLoading, setIsDataLoading ] = useState(true);
  // const [showTopBtn, setShowTopBtn] = useState(false);
  const history = useHistory();
  // const pathname = history.location.pathname.substring(1);

  let refScrollUp = useRef(null);

  let { user, venture } = useSelector(state => {

    return { ...state };
  
  });

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  const segmentDialogopen = () => {

    setSegmentDialog(true);
  
  };
  const segmentDialogclose = () => {

    setSegmentDialog(false);
  
  };

  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);
    
    } else {

      setCollapsecomment(true);
    
    }
  
  };

  useEffect(() => {

    fetchSolution();
  
  }, []);


  // const handleScroll = (e) => {
  //   const bottom =
  //     e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
  //   if (bottom) {
  //     setShowTopBtn(true);
  //   } else {
  //     setShowTopBtn(false);
  //   }
  // };

  const fetchSolution = async () => {

    setIsDataLoading(true);

    try {

      const solutionResponse = await getProblemSolution(venture.problemSolution);
      let fullSegmentData;

      try {

        const response = await getSegments(venture.customer);
        const resSegment = response?.data?.segmentsPersonas?.segments;

        if (resSegment) {

          setSegmentsDialog(resSegment);
          fullSegmentData = resSegment;

        } else {

          setSegmentsDialog([]);
          fullSegmentData = [];

        }


      } catch (error) { console.error(error); }

      const solutionData = solutionResponse?.data?.segments;

      if (solutionData) {

        const solutionGridData = [];

        solutionData?.map(solData => {

          solData?.personas?.map(persona => {

            solutionGridData.push(persona);

          });

        });

        fullSegmentData?.map(eachSegment => {

          eachSegment?.personas?.map(pp => {

            solutionGridData.map(dd => {

              if (dd._id == pp._id) {

                pp.SolutionGrid = dd.SolutionGrid;

              }

            });

          });

        });

        setSegments(fullSegmentData);
        setComments(solutionResponse.data.comments.solution.solutionGrid);

      } else {

        setSegments([]);
        setComments([]);

      }

      setIsDataLoading(false);
    
    } catch (error) {

      console.error(error);
      setIsDataLoading(false);

    }

  };


  // To get the updated usestate value to save in useEffect cleanup
  const detailsRef = useRef(segments);

  detailsRef.current = segments;

  let isSubmitting = false;

  // useUnmount => custom hook to save while navigating
  useUnmount(() => {

    if (isDataLoading == false && !isSubmitting) {

      handleSave();
    
    }
  
  });

  const handleSave = async () => {

    try {

      await updateSolutionCapability(
        detailsRef.current,
        venture.problemSolution
      );
      
      return true;
    
    } catch (error) {

      console.error(error);
      
      return false;
    
    }
  
  };

  const handleChange = (name, value, segmentindex, personaindex) => {

    const newSegments = segments.map((eachSegment, index) => {

      if (index === segmentindex) {

        let newSegment = { ...eachSegment };

        newSegment["personas"] = eachSegment.personas.map(
          (eachPersona, index) => {

            if (index === personaindex) {

              let newpersona = { ...eachPersona };

              newpersona["SolutionGrid"] = {
                ...eachPersona["SolutionGrid"],
                [name]: value
              };
              
              return newpersona;
            
            } else {

              return eachPersona;
            
            }
          
          }
        );
        
        return newSegment;
      
      } else {

        return eachSegment;
      
      }
    
    });

    setSegments(newSegments);
  
  };

  const handleSubmit = async () => {

    try {

      isSubmitting = true;
      if(isDataLoading == false){

        await updateSolutionCapability(
          segments,
          venture.problemSolution
        );

      }
      // console.log(response.data);

      history.push("/userinterviews");
      isSubmitting = false;
    
    } catch (error) {

      isSubmitting = false;
      console.error(error);
    
    }
  
  };

  // const handleScrollUp = () => {
  //   refScrollUp.current.scrollTop = 0;
  // };

  const addComment = async (commentText, replyTo) => {

    try {

      const response = await createProblemSolutionComment(
        commentText,
        user._id,
        "problem",
        "personaProblemMap",
        venture.problemSolution,
        replyTo,
        window.location.href,
        "Problem > Persona-Problem Map"
      );

      setComments(response.data.comments.problem.personaProblemMap);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateComment = async (commentid, commentText) => {

    try {

      const response = await updateProblemSolutionComment(
        commentText,
        user._id,
        commentid,
        venture._id,
        window.location.href,
        "Problem > Persona-Problem Map"
      );

      setComments(response.data.comments.problem.personaProblemMap);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const deleteComment = async commentid => {

    try {

      const response = await deleteProblemSolutionComment(
        "problem",
        "personaProblemMap",
        venture.problemSolution,
        commentid,
        user._id,
        window.location.href,
        "Problem > Persona-Problem Map"
      );

      setComments(response.data.comments.problem.personaProblemMap);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  return (
    <ModuleSections
      Collapsecomment={ Collapsecomment }
      comments={ comments }
      addComment={ addComment }
      deleteComment={ deleteComment }
      updateComment={ updateComment }>
      <div className="box-display-style-for-next-and-back-button-with-justify">
        <div className="box-indiv-header-con">
          <IndividualSectionHeaders
            mainheading={ "Problem" }
            subheading={ "Persona-Problem Map" }
            headingcontent={
              "Identify, and map problems or needs against your target personas."
            }
            isGtm={ false }
            Dialogopen={ Dialogopen }
            Dialogclose={ Dialogclose }
            dialogbox={ dialogbox }
            collapse_comment_function={ collapse_comment_function }
            content={ Datas.guidecontent }
            // tooltip={"persona problem"}
            isSaveButton={ true }
            handleSave={ handleSave }
          />
        </div>
        <Grid
          item
          xs={ 12 }
          id={ styles.maingrid }
          ref={ refScrollUp }
        // onScroll={handleScroll}
        >
          <Box sx={ { minHeight: "40vh" } }>
            { segments && segments.length > 0 ?
              segments.map((eachSegment, segmentindex) => {

                return (
                  <Box key={ segmentindex } sx={ { marginBottom: "20px" } }>
                    <Box sx={ { display: "flex" } }>
                      <Typography
                        sx={ {
                          color     : "#393939",
                          fontSize  : "16px",
                          marginLeft: "20px"
                        } }>
                        Target Segment -
                      </Typography>
                      <Typography
                        sx={ {
                          color        : "#071AFF",
                          fontWeight   : "bold",
                          textTransform: "capitalize",
                          fontSize     : "16px",
                          cursor       : "pointer"
                        } }
                        component="span"
                        onClick={ () => {

                          segmentDialogopen();
                        
                        } }>
                        { eachSegment.name }
                      </Typography>
                    </Box>
                    <Dialog
                      open={ segmentdialogbox }
                      TransitionComponent={ Transition }
                      keepMounted
                      PaperProps={ {
                        style: {
                          borderRadius   : "20px",
                          backdropFilter : "blur(10px)",
                          backgroundColor: "rgba(255,255,255,0.80)",
                          margin         : "20px",
                          padding        : "0% 2%",
                          height         : "100%"
                        }
                      } }
                      maxWidth="md"
                      fullWidth>
                      <Box sx={ { alignSelf: "flex-end" } }>
                        <IconButton
                          component="span"
                          onClick={ segmentDialogclose }>
                          <HighlightOffIcon />
                        </IconButton>
                      </Box>
                      <SegmentsPersonasDialog segments={ segmentsDialog } />
                    </Dialog>
                    <Grid container id={ styles.gridbox } rowGap={ 2 }>
                      { eachSegment.personas.length > 0 ?
                        <>
                          <Grid item sx={ { display: "flex" } } columnGap={ 3 }>
                            <Typography id={ styles.columntypography }>
                              User Persona
                            </Typography>
                            <Typography id={ styles.othercolumntypographys }>
                              Problem/Need
                            </Typography>
                            <Typography id={ styles.othercolumntypographys }>
                              Root Cause
                            </Typography>
                          </Grid>

                          { eachSegment.personas.map(
                            (eachPersona, personaindex) => {

                              return <PersonaRowGrid
                                eachPersona={ eachPersona }
                                eachSegment={ eachSegment }
                                personaindex={ personaindex }
                                segmentindex={ segmentindex }
                                handleChange={ handleChange }
                              />;
                            
                            }
                          ) }
                        </>
                        :
                        <Box>
                          <Typography sx={ { textAlign: "center" } }>
                            No Personas Created
                          </Typography>
                        </Box>
                      }
                    </Grid>
                  </Box>
                );
              
              })
              :
              <Grid container>
                <Grid
                  item
                  xs={ 12 }
                  sx={ {
                    textAlign     : "center",
                    minHeight     : "40vh",
                    display       : "flex",
                    justifyContent: "center",
                    alignItems    : "center"
                  } }>
                  <Typography fontWeight="900">
                    No valid user personas found. Kindly create relevant user
                    segments and personas in the Customer module, to continue
                    working on this section.
                  </Typography>
                </Grid>
              </Grid>
            }
          </Box>
          {/* {showTopBtn && (
          <img
            src="/images/up-arrow.png"
            id={styles.upbtn}
            onClick={() => {
              handleScrollUp();
            }}
          />
          )}*/}
        </Grid>

        <div className="ttribe-back-next-button-con">
          <BackNextButton
            restrictedBackClick={ () => history.push("/hypothesis") }
            backClick={ () => history.push("/hypothesis") }
            restrictedNextClick={ () => history.push("/userinterviews") }
            nextClick={ () => {

              handleSubmit();
            
            } }
          />
        </div>
      </div>
    </ModuleSections>
  );

};

export default PersonaProblemMap;
