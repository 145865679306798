import {
  Box, TextField, Typography
} from "@mui/material";
import React from "react";
import styles from "./SectionHeader.module.css";

export default function SectionHeader (props) {

  return (
    <Box sx={ { marginTop: "10px" } }>
      <Box
        id={ styles.mainTitleBox }
      // sx={{
      //   display: "flex",
      //   justifyContent: "space-between",
      //   // border: 1,
      //   alignItems: "center",
      // }}
      >
        <Box
          id={ styles.titleBox }
        // sx={{ width: "80%" }}
        >
          { props.isEdit ?
            <TextField
              value={ props.mainHead }
              onChange={ e => {

                props.handleTitleChange("name", e.target.value);
              
              } }
              inputProps={ { maxLength: 25 } }
              // helperText={`${props.mainHead.length}/25`}
              fullWidth
              sx={ {
                // "& .MuiOutlinedInput-root": {
                //   padding: "0px 0px",
                // },
                "& .MuiInputBase-input": {
                  fontSize  : "38px",
                  padding   : "1px 10px",
                  border    : "none",
                  color     : "#393939",
                  fontWeight: "bold"
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "2px dotted #B4B4FA"
                }
              } }
            />
            :
            <Typography
              id={ styles.titleTypo }
            // sx={{ fontSize: "38px", color: "#393939", fontWeight: "bold" }}
            >
              { props.mainHead }
            </Typography>
          }
        </Box>
        <Box
          id={ styles.headerIconMainBox }
        // sx={{ display: "flex" }}
        >
          <Box
            // id={styles.iconBox}
            sx={ {
              height        : "62px",
              width         : "62px",
              border        : props.logoimg ? "" : "2px solid #B4B4FA",
              // border: "2px solid #B4B4FA",
              // boxShadow: "0px 3px 6px #00000029",
              borderRadius  : "7px",
              display       : "flex",
              justifyContent: "center",
              alignItems    : "center"
            } }
          >
            { props.logoimg &&
              <img
                src={ props.logoimg }
                style={ { width: "100%", height: "auto", borderRadius: "7px" } }
                alt=""
              />
            }
          </Box>
          <Box
            id={ styles.commentIconBox }
          // sx={{ marginLeft: "10px" }}
          >
            { props.Collapsecomment || props.isPublished ?
              <></>
              :
              <img
                src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/Icon awesome-comment-dots.png` }
                style={ {
                  cursor: "pointer"
                } }
                onClick={ props.collapseCommentFunction }
                alt=""
              // onClick={collapse_comment_function}
              />
            }
          </Box>
        </Box>
      </Box>
    </Box>
  );

}
