import React from "react";

import {
  Grid,
  Typography,
  Dialog,
  DialogContent,
  IconButton,
  Box,
  Tooltip
} from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";
import moment from "moment";
import { makeStyles } from "@mui/styles";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

import "./calendar.css";
const useStyles = makeStyles(() => ({
  customWidth: {
    width          : 200,
    fontSize       : 10,
    backgroundColor: "white",
    color          : "#707070",
    boxShadow      : "0px 3px 6px #00000029",
    border         : "1px solid #707070"
    // borderRadius: 10,
  }
}));

const ContentCalendar = props => {

  const classes = useStyles();
  const events = props.events;
  const selectedevents = props.selectedevents;
  const selecteddate = props.selecteddate;

  const renderMonthWeekEventContent = eventInfo => {

    return (
      <Tooltip
        title={ eventInfo.event.extendedProps.description }
        disableFocusListener
        placement="right-end"
        classes={ { tooltip: classes.customWidth } }
      >
        <div style={ { display: "flex", alignItems: "center" } }>
          <div
            style={ {
              height         : "7px",
              width          : "7px",
              borderColor    : `${eventInfo.borderColor}`,
              borderRadius   : "50px",
              backgroundColor: `${eventInfo.backgroundColor}`,
              margin         : "0px 4px"
            } }
          ></div>

          <b
            style={ {
              textDecoration:
                eventInfo.event.extendedProps.isCompleted === "yes"
                  ? "line-through"
                  : "none",
              textDecorationThickness:
                eventInfo.event.extendedProps.isCompleted === "yes"
                  ? "3px"
                  : "none",
              color:
                eventInfo.event.extendedProps.isCompleted === "no" ? "red" : ""
            } }
          >
            { eventInfo.event.title }
          </b>
        </div>
      </Tooltip>
    );
  
  };

  const renderDayListEventContent = eventInfo => {

    return (
      <Tooltip
        title={ eventInfo.event.extendedProps.description }
        disableFocusListener
        placement="right-end"
        classes={ { tooltip: classes.customWidth } }
      >
        <div style={ { display: "flex", alignItems: "center" } }>
          <div
            style={ {
              height         : "7px",
              width          : "7px",
              borderColor    : `${eventInfo.borderColor}`,
              borderRadius   : "50px",
              backgroundColor: `${eventInfo.backgroundColor}`,
              margin         : "0px 4px"
            } }
          ></div>

          <div style={ { display: "flex", flexDirection: "column" } }>
            <b
              style={ {
                textDecoration:
                  eventInfo.event.extendedProps.isCompleted === "yes"
                    ? "line-through"
                    : "none",
                textDecorationThickness:
                  eventInfo.event.extendedProps.isCompleted === "yes"
                    ? "3px"
                    : "none",
                color:
                  eventInfo.event.extendedProps.isCompleted === "no"
                    ? "red"
                    : ""
              } }
            >
              { eventInfo.event.title }
            </b>
            <b
              style={ {
                textDecoration:
                  eventInfo.event.extendedProps.isCompleted === "yes"
                    ? "line-through"
                    : "none",
                textDecorationThickness:
                  eventInfo.event.extendedProps.isCompleted === "yes"
                    ? "3px"
                    : "none",
                color:
                  eventInfo.event.extendedProps.isCompleted === "no"
                    ? "red"
                    : ""
              } }
            >
              { eventInfo.event.extendedProps.description }
            </b>
          </div>
        </div>
      </Tooltip>
    );
  
  };

  const renderEventContent = eventInfo => {

    if (
      eventInfo.view.type === "dayGrid" ||
      eventInfo.view.type === "listWeek"
    ) {

      return renderDayListEventContent(eventInfo);
    
    } else {

      return renderMonthWeekEventContent(eventInfo);
    
    }
  
  };

  return (
    <>
      { events ?
        <>
          <FullCalendar
            plugins={ [
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin,
              bootstrapPlugin
            ] }
            initialView="dayGridMonth"
            buttonIcons={ true }
            headerToolbar={ {
              right: "dayGridMonth dayGridWeek dayGrid listWeek",
              // center: "prev,next",
              left : "title,prev,next"
            } }
            buttonText={ {
              dayGridMonth: "Month",
              dayGridWeek : "Week",
              dayGrid     : "Day",
              listWeek    : "List"
            } }
            // titleFormat={{ year: 'numeric', month: 'short' }}
            // customButtons={{
            //   all: {
            //     text: "ALL",
            //     click: () => fetchEvents(),
            //   },
            //   conference: {
            //     text: "CONFERENCE",
            //     click: () => fetchEventsByCategory("conference"),
            //   },
            //   business: {
            //     text: "BUSINESS",
            //     click: () => fetchEventsByCategory("business"),
            //   },
            //   party: {
            //     text: "PARTY",
            //     click: () => fetchEventsByCategory("party"),
            //   },
            // }}
            events={ events }
            nowIndicator
            displayEventTime={ false }
            dateClick={ props.handleDateSelect }
            eventClick={ props.handleEventSelect }
            eventContent={ renderEventContent }
            dayCellContent={ () => {
              // console.log(args);
              // return (
              //   <Tooltip
              //     title={"click to update"}
              //     disableFocusListener
              //     placement="right-end"
              //     classes={{ tooltip: classes.customWidth }}
              //   />
              // );
            } }
            // dayCellDidMount={(info) => {
            //   console.log(info);
            //   const parent = info.el.parentElement;
            //   console.log(parent);
            //   const tooltip = (
            //     <Tooltip
            //       title={"click to update"}
            //       disableFocusListener
            //       placement="right-end"
            //       classes={{ tooltip: classes.customWidth }}
            //     >
            //       <div dangerouslySetInnerHTML={{ __html: parent.innerHTML }} />
            //     </Tooltip>
            //   );
            //   ReactDOM.render(tooltip, parent, () => {
            //     parent.style.visibility = "visible";
            //   });
            //   return info;
            // }}
            // eventDidMount={(info) => {
            //   const parent = info.el.parentElement;
            //   const tooltip = (
            //     <Tooltip
            //       title={info.event.extendedProps.objective}
            //       disableFocusListener
            //       placement="right-end"
            //       classes={{ tooltip: classes.customWidth }}
            //     >
            //       <div dangerouslySetInnerHTML={{ __html: parent.innerHTML }} />
            //     </Tooltip>
            //   );
            //   ReactDOM.render(tooltip, parent, () => {
            //     parent.style.visibility = "visible";
            //   });
            //   info.el.parentNode.appendChild(
            //     <Tooltip
            //       title={info.event.extendedProps.objective}
            //       disableFocusListener
            //       placement="right-start"
            //       classes={{ tooltip: classes.customWidth }}
            //     ></Tooltip>
            //   );
            //   return info;
            // }}

          // eventMouseEnter={(e)=>{console.log(e)}}
          />
          <Dialog
            open={ props.contentdialogbox }
            PaperProps={ {
              style: {
                borderRadius   : "20px",
                backdropFilter : "blur(30px)",
                padding        : "50px",
                backgroundColor: "initial",
                boxShadow      : "none",
                border         : "1px solid #707070",
                width          : "100%"
              }
            } }
            BackdropProps={ { invisible: true } }
          >
            <Box sx={ { alignSelf: "flex-end" } }>
              <IconButton onClick={ props.contentDialogclose }>
                <HighlightOffOutlinedIcon />
              </IconButton>
            </Box>
            <DialogContent
              sx={ {
                background  : "#FFFFFF 0% 0% no-repeat padding-box",
                border      : "1px solid #707070",
                borderRadius: "12px"
              } }
            >
              <Typography sx={ { fontSize: "20px", color: "#393939" } }>
                { moment(selecteddate).format("MMM D") }
              </Typography>
              <Grid container direction="column">
                { selectedevents.length > 0 ?
                  selectedevents.map(event => {

                    return (
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item md={ 5 }>
                          <Typography
                            sx={ {
                              color:
                                event.isCompleted === "no" ? "red" : "#000000",
                              fontSize: "14px",
                              textDecoration:
                                event.isCompleted === "yes"
                                  ? "3px line-through"
                                  : "none"
                            } }
                          >
                            { event.title }:
                          </Typography>
                        </Grid>
                        <Grid item md={ 5 }>
                          <Typography
                            sx={ {
                              color:
                                event.isCompleted === "no" ? "red" : "#393939",
                              fontSize: "14px",
                              textDecoration:
                                event.isCompleted === "yes"
                                  ? "3px line-through"
                                  : "none"
                            } }
                          >
                            { event.description }
                          </Typography>
                        </Grid>
                        { event.isCompleted ?
                          <Grid item md={ 2 }>
                            <Tooltip title="Completed">
                              <IconButton
                                onClick={ () => {

                                  props.handleComplete(
                                    event.id,
                                    "",
                                    selecteddate
                                  );
                                
                                } }
                              >
                                <CheckCircleIcon sx={ { color: "#707070" } } />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Pending">
                              <IconButton
                                onClick={ () => {

                                  props.handleComplete(
                                    event.id,
                                    "",
                                    selecteddate
                                  );
                                
                                } }
                              >
                                <HighlightOffOutlinedIcon
                                  sx={ { color: "#707070" } }
                                />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          :
                          <Grid item md={ 2 }>
                            <Tooltip title="Completed">
                              <IconButton
                                onClick={ () => {

                                  props.handleComplete(
                                    event.id,
                                    "yes",
                                    selecteddate
                                  );
                                
                                } }
                              >
                                <CheckCircleIcon sx={ { color: "#008105" } } />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Pending">
                              <IconButton
                                onClick={ () => {

                                  props.handleComplete(
                                    event.id,
                                    "no",
                                    selecteddate
                                  );
                                
                                } }
                              >
                                <HighlightOffOutlinedIcon
                                  sx={ { color: "#FF0707" } }
                                />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        }
                      </Grid>
                    );
                  
                  })
                  :
                  <Typography sx={ { textAlign: "center" } }>
                    NO EVENTS
                  </Typography>
                }
              </Grid>
            </DialogContent>
          </Dialog>
        </>
        :
        <></>
      }
    </>
  );

};

export default ContentCalendar;
