import { Grid } from "@mui/material";
import React from "react";

const Bar = props => {

  return (
    <Grid container style={ { alignItems: "center" } } columnGap={ props.length > 10 ? 0 : 5 }>
      { Array(props.length)
        .fill(null)
        .map((content, index) => {

          return index === props.hashvalue ?
            <Grid item md={ 1 }>
              <h6
                style={ {
                  height         : "4px",
                  width          : "100px",
                  borderRadius   : "50px",
                  backgroundColor: "blue",
                  margin         : "5px 20px 5px 0px"
                } }
              />
            </Grid>
            :
            <Grid item md={ 1 }>
              <h6
                style={ {
                  height         : "4px",
                  width          : "100px",
                  borderRadius   : "50px",
                  backgroundColor: "#B1AAAA",
                  margin         : "5px 20px 5px 0px"
                } }
              />
            </Grid>
          ;
        
        }) }
    </Grid>
  );

};

export default Bar;
