import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const BackNextButton = props => {

  const { venture } = useSelector(state => ({ ...state }));

  
  return (
    <Grid
      item
      style={ {
        textAlign     : "right",
        display       : "flex",
        justifyContent: "space-between",
        margin        : props.margin ? props.margin : "20px 0px"
      } }
    >
      <Box
        sx={ {
          display   : "flex",
          alignItems: "center",
          cursor    : "pointer"
        } }
        onClick={ () => {

          if (venture.userRole === "Commenter") {

            props.restrictedBackClick();
          
          } else {

            props.backClick();
          
          }
        
        } }
      // onClick={props.backClick}
      >
        <img
          src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/back-icon.png` }
          alt="left-arrow"
          style={ { width: "8px", marginRight: "10px" } }
        />
        {/* <ArrowLeftIcon style={{ fontSize: "xx-large", color: "#071AFF" }} />*/ }
        <Typography className="ttribe-back-and-next-btn-text"
          sx={ { color: "#071AFF", textTransform: "none", fontWeight: "bold" } }
        >
          back
        </Typography>
      </Box>
      <Box
        sx={ {
          display    : "flex",
          alignItems : "center",
          cursor     : "pointer",
          marginRight: "1rem"
        } }
        // onClick={
        //   venture.userRole === "Commenter"
        //     ? props.restrictedNextClick
        //     : props.nextClick
        // }
        // onClick={props.nextClick}
        onClick={ () => {

          if (venture.userRole === "Commenter") {

            props.restrictedNextClick();
          
          } else {

            props.nextClick();
          
          }
        
        } }
      >
        <Typography className="ttribe-back-and-next-btn-text"
          sx={ { color: "#071AFF", textTransform: "none", fontWeight: "bold" } }
        >
          next
        </Typography>
        <img
          src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/Icon awesome-caret-right.png` }
          alt="right-arrow"
          style={ { width: "8px", marginLeft: "10px" } }
        />
        {/* <ArrowRightIcon style={{ fontSize: "xx-large", color: "#071AFF" }} />*/ }
      </Box>
    </Grid>
  );

};

export default BackNextButton;
