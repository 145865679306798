import AddIcon from "@mui/icons-material/Add";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box, Button,
  Dialog,
  DialogContent, Grid, IconButton, TextField, Typography
} from "@mui/material";
import React, { useRef, useState } from "react";
import ErrorMessage from "../../../../components/ErrorMessage";
import ImageOptions from "../ImageOptions";
import IndividualYear from "../IndividualYear";
import PitchFinancialPlOutlook from "../PitchReferenceLinks/PitchFinancialPlOutlook";
import SectionHeader from "../SectionHeader";

export default function PitchFinancials ({
  logoimg,
  slides,
  isRender,
  setSlides,
  chartImg,
  uploadImages,
  deleteUploadedImgs,
  isPublished,
  ...props
}) {

  const [ pitchFinancialdialog, setPitchFinancialdialog ] = useState(false);

  const PitchFinancialDialogOpen = () => {

    setPitchFinancialdialog(true);
  
  };
  const PitchFinancialDialogClose = () => {

    setPitchFinancialdialog(false);
  
  };

  const slide = slides[isRender];

  const handleChange = (name, value, columnindex) => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    let newrevenueyears = [ ...newSlides[isRender]["revenueYears"] ];

    newrevenueyears.map((revenueYear, revenueYearIndex) => {

      if (revenueYearIndex === columnindex) {

        newrevenueyears[revenueYearIndex][name] = value;
      
      }
      
      return revenueYear;
    
    });
    newSlides[isRender]["revenueYears"] = newrevenueyears;
    setSlides(newSlides);
  
  };

  const addYear = () => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    newSlides[isRender]["revenueYears"].push({
      name : `Year ${slide.revenueYears.length + 1}`,
      value: ""
    });
    setSlides(newSlides);
  
  };

  const removeYear = columnindex => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    let newrevenueyears = newSlides[isRender]["revenueYears"].filter(
      (revenueYear, revenueYearIndex) => revenueYearIndex !== columnindex
    );

    newSlides[isRender]["revenueYears"] = newrevenueyears;
    setSlides(newSlides);
  
  };

  const handleDetailsChange = (name, value) => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    newSlides[isRender][name] = value;
    setSlides(newSlides);
  
  };

  // for uploading chartImg
  const chartImgFileInput = useRef(null);

  const chartImghandleClick = () => {

    chartImgFileInput.current.click();
    props.setErrormsg("");
  
  };

  
  return (
    <Box>
      <SectionHeader
        isEdit={ props.isEdit }
        Collapsecomment={ props.Collapsecomment }
        collapseCommentFunction={ props.collapseCommentFunction }
        mainHead={ slide.name }
        handleTitleChange={ handleDetailsChange }
        logoimg={ logoimg }
        isPublished={ isPublished }
      />
      <Box
        // id={styles.mainBox}
        sx={ {
          width : "100%",
          height: "60vh",

          display       : "flex",
          justifyContent: "space-evenly",
          alignItems    : "center",

          position: "relative"
        } }
      >
        {/* year box */ }
        <Grid
          sx={ {
            // border: 1,
            width         : "22%",
            height        : "96%",
            display       : "flex",
            flexDirection : "column",
            justifyContent: "space-evenly",
            position      : "relative"
          } }
        >
          {/* ----- */ }
          { slide.revenueYears.map((revenueYear, revenueYearIndex) => {

            return <IndividualYear
              isEdit={ props.isEdit }
              revenueYear={ revenueYear }
              revenueYearIndex={ revenueYearIndex }
              removeYear={ removeYear }
              handleChange={ handleChange }
            />;
          
          }) }
          { props.isEdit &&
            <Box
              sx={ {
                width         : "36px",
                height        : "36px",
                // border: 1,
                display       : "flex",
                justifyContent: "center",
                alignItems    : "center",
                borderRadius  : "50%",
                position      : "absolute",
                bottom        : "17px",
                right         : "-66px",
                backgroundColor:
                  slide.revenueYears.length === 5 ? "#B0B0B0" : "#071AFF",
                cursor: slide.revenueYears.length === 5 ? "default" : "pointer"
              } }
              onClick={ () => {

                if (slide.revenueYears.length < 5) addYear();
              
              } }
            >
              <AddIcon sx={ { fontSize: "32px", color: "#FFFFFF" } } />
            </Box>
          }
        </Grid>

        {/* chart Grid */ }
        <Grid
          sx={ {
            width         : "50%",
            height        : "90%",
            display       : "flex",
            justifyContent: "center",
            alignItems    : "center",
            flexDirection : "column"
          } }
        >
          <Box
            sx={ {
              // border: 1,
              display       : "flex",
              justifyContent: "center",
              alignItems    : "center",
              width         : "100%",
              height        : "31px",
              marginBottom  : "6px"
            } }
          >
            { props.isEdit ?
              <TextField
                value={ slide.financialChartHeading }
                onChange={ e => {

                  handleDetailsChange("financialChartHeading", e.target.value);
                
                } }
                fullWidth
                sx={ {
                  // "& .MuiOutlinedInput-root": {
                  //   padding: "0px 0px",
                  // },
                  "& .MuiInputBase-input": {
                    fontSize : "18px",
                    padding  : "2px 10px",
                    border   : "none",
                    textAlign: "center"
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "2px dotted #B4B4FA"
                  }
                } }
              />
              :
              <Typography
                sx={ { fontSize: "17px", fontWeight: "bold", color: "#393939" } }
              >
                { slide.financialChartHeading }
              </Typography>
            }
          </Box>
          <Grid
            sx={ {
              // border: 1,
              border        : "1px solid #b4b4fa",
              width         : "100%",
              height        : "90%",
              display       : "flex",
              justifyContent: "center",
              // alignItems: "center",
              boxShadow     : "0px 3px 6px #00000029",
              borderRadius  : "7px",
              // backgroundColor: "#b4b4fa",
              position      : "relative"
            } }
          >
            {/* ------ */ }
            { props.isEdit ?
              <>
                { chartImg?.imgUrl || slide.chartImage ?
                  <>
                    <img
                      src={
                        chartImg?.imgUrl
                          ? chartImg?.imgUrl
                          : slide.chartImage
                            ? slide.chartImage
                            : chartImg?.imgUrl
                      }
                      style={ {
                        width    : "100%",
                        height   : "100%",
                        objectFit: "cover"
                      } }
                    />
                    <Box
                      sx={ {
                        position       : "absolute",
                        right          : "1px",
                        top            : "1px",
                        display        : "flex",
                        justifyContent : "space-evenly",
                        alignItems     : "center",
                        backgroundColor: "#ffffff",
                        opacity        : "0.5"
                        /* border-radius: 5px; */
                      } }
                    // onClick={productImghandleClick}
                    >
                      <ImageOptions
                        IconSize="22px"
                        handleClick={ chartImghandleClick }
                        image={ chartImg?.imgUrl || slide.chartImage }
                        deleteUploadedImgs={ () => {

                          let newSlides = [ ...slides ];

                          newSlides[isRender]["isChanged"] = true;
                          setSlides(newSlides);
                          deleteUploadedImgs(
                            `${slide.renderName}.chartImage`,
                            slide.chartImage ? true : false
                          );
                          if (slide.chartImage)
                            handleDetailsChange("chartImage", "");
                        
                        } }
                      />
                    </Box>
                  </>
                  :
                  <Box
                    sx={ {
                      width         : "40%",
                      display       : "flex",
                      justifyContent: "center",
                      alignItems    : "center",
                      flexDirection : "column"
                    } }
                  >
                    <Box sx={ { textAlign: "center" } }>
                      { props.errorMsg && <ErrorMessage errormsg={ props.errorMsg } /> }
                      <Typography sx={ { color: "#707070", fontSize: "11px" } }>
                        Upload a simple chart showing your revenue projections
                        for the next 3-5 years.
                      </Typography>
                    </Box>
                    {/* -------- */ }
                    <Button
                      variant="contained"
                      style={ {
                        fontSize     : "14px",
                        textTransform: "none",
                        padding      : "10px 19px",
                        marginTop    : "10px"
                      } }
                      onClick={ chartImghandleClick }
                    >
                      Upload Chart
                    </Button>
                    {/* ------- */ }
                    <Box sx={ { textAlign: "center", marginTop: "8px" } }>
                      <Typography sx={ { fontSize: "9px" } }>
                        { "Image must be .png or .jpg format and < 10MB." }
                      </Typography>
                    </Box>
                  </Box>
                }
                <input
                  type="file"
                  id="chartImg"
                  ref={ chartImgFileInput }
                  onChange={ e => {

                    uploadImages(
                      `${slide.renderName}.chartImage`,
                      e.target.files[0]
                    );
                    let newSlides = [ ...slides ];

                    newSlides[isRender]["isChanged"] = true;
                    setSlides(newSlides);
                  
                  } }
                  onClick={ e => {

                    e.target.value = null;
                  
                  } }
                  style={ { display: "none" } }
                  accept="image/*"
                />
              </>
              : slide.chartImage ?
                <img
                  src={ slide.chartImage }
                  style={ { width: "100%", height: "100%", objectFit: "cover" } }
                />
                :
                <></>
            }

            {/* -------- */ }
          </Grid>
        </Grid>
        {/* ---chart grid end---- */ }

        {/* reference link button */ }

        {/* const [pitchFinancialdialog, setPitchFinancialdialog] = useState(false);

const PitchFinancialDialogOpen = () => {
  setPitchFinancialdialog(true);
};
const PitchFinancialDialogClose = () => {
  setPitchFinancialdialog(false);
}; */}

        { props.isEdit ?
          <Box sx={ { position: "absolute", bottom: "-10px", left: "100px" } }>
            {/* <ReferenceLinkTooltip
              title={
                "Revisit the notes created in Funding Section of the Financial Module"
              }
            > */}
            <Button
              style={ {
                // position: "absolute",
                textTransform : "none",
                textDecoration: "underline",
                fontSize      : "12px",
                // top: "-30px",
                // right: "-5px",
                padding       : "0px",
                fontWeight    : "bold"
              } }
              onClick={ PitchFinancialDialogOpen }
            >
              P&L Outlook
            </Button>
            {/* </ReferenceLinkTooltip> */ }
            <Dialog
              open={ pitchFinancialdialog }
              onClose={ PitchFinancialDialogClose }
              PaperProps={ {
                style: {
                  borderRadius   : "20px",
                  backdropFilter : "blur(100px)",
                  backgroundColor: "#ffffff",
                  boxShadow      : "0px 3px 6px #00000029",
                  // border: "1px solid #707070",
                  width          : "70%"
                }
              } }
              // BackdropProps={{ invisible: true }}
              //
              maxWidth="false"
              fullWidth
            >
              <DialogContent sx={ { padding: "40px" } }>
                <Box
                  sx={ {
                    width   : "100%",
                    // height: "60vh",
                    // display: "flex",
                    // alignItems: "center",
                    // justifyContent: "center",
                    position: "relative"
                  } }
                >
                  {/* <PitchFinancialsFunding /> */ }
                  <PitchFinancialPlOutlook />
                  <IconButton
                    onClick={ () => {

                      PitchFinancialDialogClose();
                    
                    } }
                    sx={ {
                      position: "absolute",
                      right   : "-40px",
                      top     : "-40px"
                    } }
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Box>
              </DialogContent>
            </Dialog>
          </Box>
          :
          <></>
        }
      </Box>
    </Box>
  );

}
