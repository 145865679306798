import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box, Dialog,
  DialogContent, IconButton, Menu,
  MenuItem, Typography
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DeleteCancel from "../../../../components/DeleteCancel";

import {
  deleteDigitalCampaignById,
  deleteDigitalContentById
} from "../../../../services/digitalmarket";
import styles from "./DigitalMarketing.module.css";

const IndividualDetailsBox = props => {

  const [ anchorEl, setAnchorEl ] = React.useState(null);
  const [ deletedialog, setDeletedialog ] = useState(false);

  const deleteDialogopen = () => {

    setDeletedialog(true);
  
  };
  const deleteDialogclose = () => {

    setDeletedialog(false);
  
  };
  const open = Boolean(anchorEl);
  const handleClick = event => {

    setAnchorEl(event.currentTarget);
  
  };
  const handleClose = () => {

    setAnchorEl(null);
  
  };
  let { venture } = useSelector(state => {

    return { ...state };
  
  });

  const history = useHistory();
  const details = props.details;
  const name = props.name;

  const deleteDetails = async id => {

    try {

      if (name === "content") {

        await deleteDigitalContentById(id);
      
      } else {

        await deleteDigitalCampaignById(id);
      
      }
      props.fetchGtmdigitalmarketing();
      handleClose();
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  return (
    <Box
      id={ styles.contentbox }
      component="span"
      onClick={ () => {

        history.push({ hash: details._id });
        props.editDialogopen();
      
      } }
    >
      <Typography
        sx={ {
          fontSize    : "12px",
          color       : "#3BBAA9",
          borderBottom: "1px solid #B1AAAA"
        } }
      >
        { name === "content" ? "Concept Name" : "Campaign Name" }
      </Typography>
      <Typography sx={ { fontSize: "12px" } }>{ details.name }</Typography>
      { venture.userRole !== "Commenter" &&
        <>
          <IconButton
            id={ styles.threedot }
            onClick={ e => {

              e.stopPropagation();
              handleClick(e);
            
            } }
          >
            <MoreVertIcon fontSize="small" />
          </IconButton>
          <Menu
            anchorEl={ anchorEl }
            open={ open }
            onClose={ e => {

              e.stopPropagation();
              handleClose();
            
            } }
            anchorOrigin={ {
              vertical  : "top",
              horizontal: "right"
            } }
            sx={ {
              "& .MuiMenu-list": {
                padding        : "0px",
                backgroundColor: "#707070",
                color          : "white"
              }
            } }
          // transformOrigin={{
          //   vertical: 'top',
          //   horizontal: 'start',
          // }}
          >
            <MenuItem
              sx={ { fontSize: "12px" } }
              onClick={ e => {

                e.stopPropagation();
                deleteDialogopen();
              
              } }
            >
              Delete { name === "content" ? "content" : "campaign" }
            </MenuItem>
          </Menu>
          <Dialog
            open={ deletedialog }
            onClick={ e => {

              e.stopPropagation();
            
            } }
            PaperProps={ {
              style: {
                borderRadius  : "20px",
                backdropFilter: "blur(45px)",
                background    : "#FFFFFF 0% 0% no-repeat padding-box",
                boxShadow     : "0px 3px 6px #00000029",
                border        : "1px solid #E0E1E2"
              }
            } }
          >
            <DialogContent>
              <DeleteCancel
                name={ `Are you sure you want to delete this ${name} ?` }
                deleteClick={ async e => {

                  e.stopPropagation();
                  await deleteDetails(details._id);
                  deleteDialogclose();
                
                } }
                cancelClick={ e => {

                  e.stopPropagation();
                  deleteDialogclose();
                  handleClose();
                
                } }
              />
            </DialogContent>
          </Dialog>
        </>
      }
    </Box>
  );

};

export default IndividualDetailsBox;
