import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box, Button, Dialog,
  DialogContent, IconButton, Menu,
  MenuItem, TextField, Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Country } from "country-state-city";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  isValidPhoneNumber
} from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from "react-router";
import Select, { createFilter } from "react-select";
import DeleteCancel from "../../../components/DeleteCancel";
import ErrorMessage from "../../../components/ErrorMessage";
import SuccessMessage from "../../../components/SuccessMessage";
import {
  deleteUserProfileIcon,
  getUserById,
  UpdateUserDetails,
  uploadUserProfileIcon
} from "../../../services/auth";
import MyAccount from "./MyAccount";
import styles from "./MyProfile.module.css";

const useStyles = makeStyles(() => ({
  CustomInputWide: {
    "& .MuiInputBase-input": {
      borderRadius: 4,
      fontSize    : 14,
      // width: "42vw",
      width       : "100%",
      padding     : "6px 12px"
    }
  },
  CustomInput: {
    "& .MuiInputBase-input": {
      borderRadius: 4,
      fontSize    : 14,
      width       : "100%",
      padding     : "6px 12px"
    }
  }
}));

export default function MyProfile () {

  const [ User, setUser ] = useState({
    firstname       : "",
    lastname        : "",
    email           : "",
    location        : "",
    phone           : "",
    organizationName: "",
    linkedin        : ""
  });
  const [ profileIcon, setProfileIcon ] = useState(null);
  const [ editprofileIcon, setEditprofileIcon ] = useState(null);
  const [ isedit, setIsedit ] = useState(false);
  const [ phonecode, setPhonecode ] = useState("");
  // const [ countrycode, setCountrycode ] = useState("");
  const [ errormsg, setErrormsg ] = useState("");
  const [ successmsg, setSuccessmsg ] = useState("");
  const { user } = useSelector(state => ({ ...state }));

  const [ anchorEl, setAnchorEl ] = React.useState(null);
  const [ deletedialog, setDeletedialog ] = useState(false);

  const deleteDialogopen = () => {

    setDeletedialog(true);
  
  };
  const deleteDialogclose = () => {

    setDeletedialog(false);
  
  };
  const open = Boolean(anchorEl);
  const handleIconClick = event => {

    setAnchorEl(event.currentTarget);
  
  };
  const handleClose = () => {

    setAnchorEl(null);
  
  };

  // const history = useHistory();
  const dispatch = useDispatch();

  const classes = useStyles();

  useEffect(() => {

    fetchUser();
  
  }, []);

  useEffect(() => {

    if (User.location) setUserphonecode();
  
  }, [ User.location ]);

  useEffect(() => {

    if (profileIcon) uploadProfileIcon();
  
  }, [ profileIcon ]);

  const fetchUser = async () => {

    const response = await getUserById(user._id);

    setUser(response.data.details);
    setEditprofileIcon(response.data.profileIcon);
  
  };

  const handleChange = event => {

    const name = event.target.name;
    const value = event.target.value;

    setUser(values => ({ ...values, [name]: value }));
  
  };

  const handleOtherDetailsChange = (name, value) => {

    setUser(values => ({ ...values, [name]: value }));
  
  };

  // dropdown for location
  const countries = Country.getAllCountries();
  const updatedCountries = countries.map(country => ({
    label: country.name,
    value: country.name,
    // phonecode: country.phonecode.includes("+")
    //   ? `(${country.phonecode})`
    //   : `(+${country.phonecode})`,
    ...country
  }));

  const setUserphonecode = () => {

    const matches = updatedCountries.filter(country => {

      return country.name === User.location;
    
    });

    setPhonecode(matches[0].phonecode);
  
  };

  const hiddenFileInput = useRef(null);

  const handleClick = () => {

    hiddenFileInput.current.click();
  
  };

  const preview = useMemo(() => {

    return profileIcon ? URL.createObjectURL(profileIcon) : null;
  
  }, [ profileIcon ]);

  const changeProfileIcon = async event => {

    let file = event.target.files[0];

    setProfileIcon(file);
  
  };

  const handleSubmit = async e => {

    e.preventDefault();
    if (User.firstname !== "" && User.lastname !== "" && User.location !== "") {

      if (
        (User.linkedin !== "" &&
          /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/gm.test(
            User.linkedin
          )) ||
        (User.phone && isValidPhoneNumber(User.phone))
      ) {

        UpdateUserDetails(User)
          .then(res => {

            dispatch({
              type   : "LOGGED_IN_USER",
              payload: {
                firstname       : res.data.user.firstname,
                lastname        : res.data.user.lastname,
                email           : res.data.user.email,
                token           : user.token,
                subscription    : res.data.user.subscription,
                access          : res.data.user.access,
                profileIcon     : res.data.profileUrl,
                userVerified    : res.data.user.userVerified,
                organizationName: res.data.user.organizationName,
                location        : res.data.user.location,
                createdAt       : res.data.user.createdAt,
                _id             : res.data.user._id
              }
            });
            setUser(res.data.user);
            setEditprofileIcon(res.data.profileUrl);
            setIsedit(false);
            setSuccessmsg("Changes saved!");
            setTimeout(() => {

              setSuccessmsg("");
            
            }, 5000);
          
          })
          .catch(error => {

            //
            console.error(error);
          
          });
      
      } else if (
        User.linkedin === "" &&
        (User.phone === `+${phonecode.replace(/[^0-9\.]+/g, "")}` ||
          User.phone === "")
      ) {

        UpdateUserDetails(User)
          .then(res => {

            dispatch({
              type   : "LOGGED_IN_USER",
              payload: {
                firstname       : res.data.user.firstname,
                lastname        : res.data.user.lastname,
                email           : res.data.user.email,
                token           : user.token,
                subscription    : res.data.user.subscription,
                access          : res.data.user.access,
                profileIcon     : res.data.profileUrl,
                userVerified    : res.data.user.userVerified,
                organizationName: res.data.user.organizationName,
                location        : res.data.user.location,
                createdAt       : res.data.user.createdAt,
                _id             : res.data.user._id
              }
            });
            setUser(res.data.user);
            setEditprofileIcon(res.data.profileUrl);
            setIsedit(false);
            setSuccessmsg("Changes saved!");
            setTimeout(() => {

              setSuccessmsg("");
            
            }, 5000);
          
          })
          .catch(error => {

            //
            console.error(error);
          
          });
      
      } else {

        if (
          User.firstname !== "" &&
          User.lastname !== "" &&
          User.location !== "" &&
          !isValidPhoneNumber(User.phone)
        ) {

          setErrormsg("Please fill valid phone number");
        
        } else {

          setErrormsg("Please enter a valid URL");
        
        }
        setTimeout(() => {

          setErrormsg("");
        
        }, 5000);
      
      }
    
    } else {

      if (
        User.firstname === "" ||
        User.lastname === "" ||
        User.location === ""
      ) {

        setErrormsg("Please fill in the required (*) fields.");
      
      }
      setTimeout(() => {

        setErrormsg("");
      
      }, 5000);
    
    }
  
  };

  const deleteProfileIcon = async () => {

    try {

      const res = await deleteUserProfileIcon(user._id);

      setEditprofileIcon(res.data.profileUrl);

      dispatch({
        type   : "LOGGED_IN_USER",
        payload: {
          firstname       : res.data.user.firstname,
          lastname        : res.data.user.lastname,
          email           : res.data.user.email,
          token           : user.token,
          subscription    : res.data.user.subscription,
          access          : res.data.user.access,
          profileIcon     : res.data.profileUrl,
          userVerified    : res.data.user.userVerified,
          organizationName: res.data.user.organizationName,
          location        : res.data.user.location,
          createdAt       : res.data.user.createdAt,
          _id             : res.data.user._id
        }
      });
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const uploadProfileIcon = async () => {

    try {

      const formdata = new FormData();

      formdata.append("userid", user._id);
      formdata.append("profileIcon", profileIcon);
      const res = await uploadUserProfileIcon(formdata);

      setEditprofileIcon(res.data.profileUrl);
      setProfileIcon(null);

      dispatch({
        type   : "LOGGED_IN_USER",
        payload: {
          firstname       : res.data.user.firstname,
          lastname        : res.data.user.lastname,
          email           : res.data.user.email,
          token           : user.token,
          subscription    : res.data.user.subscription,
          access          : res.data.user.access,
          profileIcon     : res.data.profileUrl,
          userVerified    : res.data.user.userVerified,
          organizationName: res.data.user.organizationName,
          location        : res.data.user.location,
          createdAt       : res.data.user.createdAt,
          _id             : res.data.user._id
        }
      });
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const removeNonNumeric = num => num.toString().replace(/[^0-9+]/g, "");

  // console.log(profileIcon);
  // console.log(preview, editprofileIcon);

  return (
    <MyAccount myProfileColor={ true }>
      { isedit ?
        <Box id={ styles.editMainBox }>
          <Box sx={ { marginTop: "18px" } } id={ styles.editHeadBox }>
            <Typography id={ styles.editHeadTypo }>My Profile</Typography>
          </Box>

          {/* circle icon */ }

          <Box id={ styles.accountIconBox }>
            { preview || editprofileIcon ?
              <Box sx={ { position: "relative" } }>
                <img
                  src={ preview ? preview : editprofileIcon }
                  id={ styles.profileIcon }
                  alt=""
                />
                <IconButton
                  sx={ { padding: "0px" } }
                  onClick={ e => {

                    e.stopPropagation();
                    handleIconClick(e);
                  
                  } }
                >
                  <MoreVertIcon id={ styles.MoreVert } />
                </IconButton>
                <Menu
                  anchorEl={ anchorEl }
                  open={ open }
                  onClose={ e => {

                    e.stopPropagation();
                    handleClose();
                  
                  } }
                  anchorOrigin={ {
                    vertical  : "top",
                    horizontal: "right"
                  } }
                  sx={ {
                    "& .MuiMenu-list": {
                      padding        : "0px",
                      backgroundColor: "#707070",
                      color          : "white"
                    }
                  } }
                // transformOrigin={{
                //   vertical: 'top',
                //   horizontal: 'start',
                // }}
                >
                  <MenuItem
                    sx={ { fontSize: "12px" } }
                    disableGutters={ true }
                    onClick={ e => {

                      e.stopPropagation();
                      handleClick(e);
                      handleClose();
                    
                    } }
                  >
                    <EditIcon id={ styles.deleteIcon } />
                    <Box sx={ { paddingRight: "7px" } }>Update</Box>
                  </MenuItem>
                  { editprofileIcon &&
                    <MenuItem
                      sx={ { fontSize: "12px", borderTop: "solid 1px" } }
                      disableGutters={ true }
                      onClick={ e => {

                        e.stopPropagation();
                        deleteDialogopen();
                      
                      } }
                    >
                      <DeleteIcon id={ styles.deleteIcon } />
                      <Box>Delete</Box>
                      {/* Delete */ }
                    </MenuItem>
                  }
                </Menu>
                <Dialog
                  open={ deletedialog }
                  PaperProps={ {
                    style: {
                      borderRadius  : "20px",
                      backdropFilter: "blur(45px)",
                      background    : "#FFFFFF 0% 0% no-repeat padding-box",
                      boxShadow     : "0px 3px 6px #00000029",
                      border        : "1px solid #E0E1E2"
                    }
                  } }
                >
                  <DialogContent>
                    <DeleteCancel
                      name={ "Are you sure you want to delete your profile picture ?" }
                      deleteClick={ async e => {

                        e.stopPropagation();
                        deleteProfileIcon();
                        deleteDialogclose();
                        handleClose();
                      
                      } }
                      cancelClick={ e => {

                        e.stopPropagation();
                        deleteDialogclose();
                        handleClose();
                      
                      } }
                    />
                  </DialogContent>
                </Dialog>
              </Box>
              :
              <Box sx={ { position: "relative" } }>
                <img
                  src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/user-profile.png` }
                  id={ styles.defaultprofileIcon }
                  alt=""
                />
                <IconButton
                  sx={ { padding: "0px" } }
                  onClick={ e => {

                    e.stopPropagation();
                    handleIconClick(e);
                  
                  } }
                >
                  <MoreVertIcon id={ styles.MoreVert } />
                </IconButton>
                <Menu
                  anchorEl={ anchorEl }
                  open={ open }
                  onClose={ e => {

                    e.stopPropagation();
                    handleClose();
                  
                  } }
                  anchorOrigin={ {
                    vertical  : "top",
                    horizontal: "right"
                  } }
                  sx={ {
                    "& .MuiMenu-list": {
                      padding        : "0px",
                      backgroundColor: "#707070",
                      color          : "white"
                    }
                  } }
                // transformOrigin={{
                //   vertical: 'top',
                //   horizontal: 'start',
                // }}
                >
                  <MenuItem
                    sx={ { fontSize: "12px" } }
                    disableGutters="true"
                    onClick={ e => {

                      e.stopPropagation();
                      handleClick(e);
                    
                    } }
                  >
                    <EditIcon id={ styles.deleteIcon } />
                    <Box sx={ { paddingRight: "7px" } }>Update</Box>
                  </MenuItem>
                </Menu>
              </Box>
            }
            <input
              type="file"
              id="profileIcon"
              ref={ hiddenFileInput }
              onChange={ changeProfileIcon }
              style={ { display: "none" } }
              accept="image/*"
            />
          </Box>

          {/* name box */ }
          <Box sx={ { width: "44vw" } }>
            <Box id={ styles.editNameBox }>
              <Box sx={ { marginRight: "4px" } }>
                <Typography id={ styles.editFieldHeadTypo }>
                  First Name*
                </Typography>
                <Box sx={ { width: "21vw" } }>
                  <TextField
                    name={ "firstname" }
                    value={ User.firstname }
                    onChange={ handleChange }
                    className={ classes.CustomInput }
                    fullWidth
                    error={
                      errormsg ? User.firstname === "" ? true : false : false
                    }
                  />
                </Box>
              </Box>
              <Box sx={ { marginLeft: "4px" } }>
                <Typography id={ styles.editFieldHeadTypo }>
                  Last Name*
                </Typography>
                <Box sx={ { width: "21vw" } }>
                  <TextField
                    name={ "lastname" }
                    value={ User.lastname }
                    onChange={ handleChange }
                    className={ classes.CustomInput }
                    error={
                      errormsg ? User.lastname === "" ? true : false : false
                    }
                    fullWidth
                  />
                </Box>
              </Box>
            </Box>
            <Box id={ styles.textFieldBox }>
              <Typography id={ styles.editFieldHeadTypo }>Email*</Typography>

              {/* <Box sx={{ border: 1, width: "44vw" }}> */ }
              <TextField
                value={ User.email }
                disabled
                className={ classes.CustomInputWide }
                fullWidth
              />
              {/* </Box> */ }
            </Box>
            <Box id={ styles.textFieldBox }>
              <Typography id={ styles.editFieldHeadTypo }>Country*</Typography>
              <Select
                value={
                  User.location && {
                    value: User.location,
                    label: User.location
                  }
                }
                onChange={ e => {

                  setPhonecode(e.phonecode);
                  // setCountrycode(e.isoCode);
                  handleOtherDetailsChange("location", e.value);
                  handleOtherDetailsChange(
                    "phone",
                    `+${e.phonecode.replace(/[^0-9\.]+/g, "")}`
                  );
                
                } }
                options={ updatedCountries }
                placeholder={ "Select your Location" }
                maxMenuHeight={ 150 }
                filterOption={ createFilter({
                  matchFrom: "start"
                }) }
                styles={ {
                  control: styles => ({
                    ...styles,
                    height      : "0px",
                    borderColor : styles.borderColor,
                    marginBottom: "10px",
                    width       : "44vw"
                  }),
                  menu: styles => ({ ...styles, width: "44vw" })
                } }
              />
            </Box>
            <Box id={ styles.textFieldBox }>
              <Typography id={ styles.editFieldHeadTypo }>Phone</Typography>

              {/* <Input
                country={countrycode}
                fullWidth
                international
                withCountryCallingCode
                value={User.phone}
                onChange={(e) => {
                  handleOtherDetailsChange("phone", e);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Backspace") {
                    // 👇️ your logic here
                    console.log("Backspace key pressed ✅");
                    if (
                      phonecode.replace(/[^0-9\.]+/g, "") ===
                      User.phone.replace(/[^0-9\.]+/g, "")
                    ) {
                      console.log(e);
                    }
                  }
                }}
                disabled={User.location === "" ? true : false}
                width={"42vw"}
                iserror={
                  errormsg
                    ? User.phone && isValidPhoneNumber(User.phone)
                      ? false
                      : true
                    : false
                }
                inputComponent={PhoneNumber}
              />*/}
              <TextField
                error={
                  errormsg
                    ? User.phone && isValidPhoneNumber(User.phone)
                      ? false
                      : true
                    : false
                }
                name={ "phone" }
                value={ User.phone }
                onChange={ e => {

                  setUser({
                    ...User,
                    ["phone"]: removeNonNumeric(e.target.value)
                  });
                
                } }
                className={ classes.CustomInputWide }
                disabled={ User.location === "" ? true : false }
                fullWidth
              />
            </Box>
            <Box id={ styles.textFieldBox1 }>
              <Typography id={ styles.editFieldHeadTypo }>
                Company/ Organization
              </Typography>

              <TextField
                name={ "organizationName" }
                value={ User.organizationName }
                onChange={ handleChange }
                className={ classes.CustomInputWide }
                fullWidth
              />
            </Box>
            <Box id={ styles.textFieldBox2 } mb={ 1 }>
              <Typography id={ styles.editFieldHeadTypo }>
                LinkedIn Profile
              </Typography>

              <TextField
                name={ "linkedin" }
                value={ User.linkedin }
                onChange={ handleChange }
                fullWidth
                error={
                  errormsg
                    ? User.linkedin &&
                      !/^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/gm.test(
                        User.linkedin
                      )
                      ? true
                      : false
                    : false
                }
                placeholder={ "https://in.linkedin.com/" }
                className={ classes.CustomInputWide }
              />
            </Box>
          </Box>
          { errormsg && <ErrorMessage errormsg={ errormsg } /> }
          { successmsg && <SuccessMessage successmsg={ successmsg } /> }
          <Box id={ styles.editButtonFlexBox }>
            <Box sx={ { marginBottom: "10px" } } id={ styles.editButtonBox }>
              <Button
                onClick={ handleSubmit }
                style={ { textTransform: "none" } }
                variant="contained"
              >
                Save Changes
              </Button>
            </Box>
          </Box>
        </Box>
        :
        <Box id={ styles.myProfileMainBox }>
          <Box sx={ { marginTop: "15px" } } id={ styles.headBox }>
            <Typography id={ styles.myProfileTypo }>My Profile</Typography>
            <IconButton
              id={ styles.editIconBtn }
              onClick={ () => {

                setIsedit(true);
              
              } }
            >
              <EditIcon id={ styles.editIcon } />
            </IconButton>
          </Box>

          {/* circle icon */ }

          <Box id={ styles.circleIconBox }>
            { User.profileIcon ?
              <img src={ editprofileIcon } id={ styles.profileIcon } alt="" />
              :
              <img
                src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/user-profile.png` }
                id={ styles.defaultprofileIcon }
                alt=""
                style={ { padding: "10px" } }
              />
            }
          </Box>

          {/* name box */ }

          <Box id={ styles.nameBox }>
            <Box id={ styles.firstNameBox }>
              <Typography id={ styles.fieldHeadTypo }>First Name</Typography>
              <Typography id={ styles.fieldTypo }>{ User.firstname }</Typography>
            </Box>
            <Box id={ styles.firstNameBox }>
              <Typography id={ styles.fieldHeadTypo }>Last Name</Typography>
              <Typography id={ styles.fieldTypo }>{ User.lastname }</Typography>
            </Box>
          </Box>
          <Box id={ styles.fieldBox }>
            <Typography id={ styles.fieldHeadTypo }>Email</Typography>
            <Typography id={ styles.fieldTypo }>{ User.email }</Typography>
          </Box>
          <Box id={ styles.fieldBox }>
            <Typography id={ styles.fieldHeadTypo }>Country</Typography>
            <Typography id={ styles.fieldTypo }>{ User.location }</Typography>
          </Box>
          <Box id={ styles.fieldBox }>
            <Typography id={ styles.fieldHeadTypo }>Phone</Typography>
            <Typography id={ styles.fieldTypo }>{ User.phone }</Typography>
          </Box>
          <Box id={ styles.fieldBox }>
            <Typography id={ styles.fieldHeadTypo }>
              Company/ Organization
            </Typography>
            { User.organizationName ?
              <Typography id={ styles.fieldTypo }>
                { User.organizationName }
              </Typography>
              :
              <br />
            }
          </Box>
          <Box id={ styles.fieldBox }>
            <Typography id={ styles.fieldHeadTypo }>LinkedIn Profile</Typography>
            { User.linkedin ?
              <Typography id={ styles.fieldTypo }>{ User.linkedin }</Typography>
              :
              <br />
            }
          </Box>
          { successmsg && <SuccessMessage successmsg={ successmsg } /> }
        </Box>
      }
    </MyAccount>
  );

}
