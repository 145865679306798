import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import {
  Box, Dialog,
  DialogContent, Grid, IconButton, Typography
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import DeleteCancel from "../../../../components/DeleteCancel";
import styles from "./PlOutlook.module.css";

const IndividualExcel = ({ excel, index, removeExcel }) => {

  const [ deletedialog, setDeletedialog ] = useState(false);

  const deleteDialogopen = () => {

    setDeletedialog(true);
  
  };
  const deleteDialogclose = () => {

    setDeletedialog(false);
  
  };

  const { venture } = useSelector(state => ({ ...state }));

  return (
    <Grid item md={ 6 } key={ index }>
      { venture.userRole !== "Commenter" ?
        <Box sx={ { display: "inline-flex", alignItems: "center" } }>
          <a
            href={ excel.fileurl }
            style={ {
              textDecorationColor: "#707070",
              display            : "flex"
            } }
          >
            <InsertDriveFileIcon fontSize="small" sx={ { color: "#707070" } } />
            <Typography variant="body2">{ excel.filename }</Typography>
            <FileDownloadOutlinedIcon
              fontSize="small"
              id={ styles.downloadicon }
            />
          </a>
          <IconButton
            onClick={ () => {

              deleteDialogopen();
            
            } }
          >
            <DeleteIcon fontSize="small" sx={ { color: "#FF7F5C" } } />
          </IconButton>
          <Dialog
            open={ deletedialog }
            PaperProps={ {
              style: {
                borderRadius  : "20px",
                backdropFilter: "blur(45px)",
                background    : "#FFFFFF 0% 0% no-repeat padding-box",
                boxShadow     : "0px 3px 6px #00000029",
                border        : "1px solid #E0E1E2"
              }
            } }
          >
            <DialogContent>
              <DeleteCancel
                name={ "Are you sure you want to delete this excel ?" }
                deleteClick={ async () => {

                  await removeExcel(index);
                  deleteDialogclose();
                
                } }
                cancelClick={ () => {

                  deleteDialogclose();
                
                } }
              />
            </DialogContent>
          </Dialog>
        </Box>
        :
        <Box sx={ { display: "inline-flex", alignItems: "center" } }>
          <a
            style={ {
              textDecorationColor: "#707070",
              display            : "flex"
            } }
          >
            <InsertDriveFileIcon fontSize="small" sx={ { color: "#707070" } } />
            <Typography variant="body2">{ excel.filename }</Typography>
            <FileDownloadOutlinedIcon
              fontSize="small"
              id={ styles.downloadicon }
            />
          </a>
        </Box>
      }
    </Grid>
  );

};

export default IndividualExcel;
