import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box, Button, Grid, IconButton, InputAdornment, LinearProgress, TextField, Tooltip, Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import ErrorMessage from "../../../components/ErrorMessage";
import {
  strengthColor,
  strengthIndicator,
  strengthText
} from "../../../components/PasswordStrength";
import { auth } from "../../../firebase";
import { confirmPasswordReset } from "firebase/auth";

import { makeStyles } from "@mui/styles";
import styles from "./ForgotPassword.module.css";

const useStyles = makeStyles(() => ({
  customWidth: {
    maxWidth       : 200,
    fontSize       : 10,
    backgroundColor: "white",
    color          : "#707070",
    boxShadow      : "0px 3px 6px #00000029",
    border         : "1px solid #707070",
    borderRadius   : 10
  }
}));

const ResetPassword = () => {

  const [ password, setPassword ] = useState("");
  const [ confirmpassword, setConfirmPassword ] = useState("");
  const [ showpassword, setShowPassword ] = useState(false);
  const [ showconfirmpassword, setShowConfirmPassword ] = useState(false);
  const [ passworderrormsg, setPassworderrormsg ] = useState("");
  const [ errorflag, setErrorflag ] = useState(false);
  const [ errorLink, setErrorLink ] = useState(false);
  let history = useHistory();
  let location = useLocation();
  const classes = useStyles();
  const oobCode = location.state.oobCode;
  // const { user } = useSelector(state => {

  //   return { ...state };
  
  // });

  // password strength indicator
  const strength = strengthIndicator(password);
  const strengthcolor = strengthColor(strength);
  const strengthtext = strengthText(strength);
  const normalise = strength => ((strength - 1) * 100) / (5 - 1);

  const handleClickShowPassword = () => {

    setShowPassword(!showpassword);
  
  };
  const handleClickShowConfirmPassword = () => {

    setShowConfirmPassword(!showconfirmpassword);
  
  };

  // const useQuery = () => {
  //   return new URLSearchParams(location.search);
  // };
  // const query = useQuery();

  // useEffect(() => {
  //   if (user && user.token) history.push("/");
  // }, [user, history]); // user as dependency

  useEffect(() => {

    if (!errorflag) {

      setPassworderrormsg("");
    
    }
  
  }, [ errorflag ]);


  const handleSubmit = async e => {

    e.preventDefault();
    if (
      password === confirmpassword &&
      password.match(
        "(?!^[0-9]*$)(?!^[a-zA-Z]*$)^([a-zA-Z0-9!#@$%^&*)(+=._-]{6,15})$"
      )
    ) {

      await confirmPasswordReset(auth, oobCode, password).then(() => {

        history.push("/login");
        
      }).catch(error => {

        if (error.code === "auth/invalid-action-code" ) {

          setPassworderrormsg("Something went wrong in reset link. So please try Forgot Password again");
          setErrorLink(true);

          console.error(error.code);
        
        }
        
      });
    
    } else {

      if (password !== confirmpassword) {

        setPassworderrormsg(
          "The two passwords don't match. Please re-enter them."
        );
      
      } else {

        if (
          !password.match(
            "(?!^[0-9]*$)(?!^[a-zA-Z]*$)^([a-zA-Z0-9!#@$%^&*)(+=._-]{6,15})$"
          )
        ) {

          setPassworderrormsg("Password does not meet requirements");
        
        }
      
      }
      setErrorflag(true);
    
    }
  
  };

  
  return (
    <div style={ { height: "100%" } }>
      <Box id={ styles.mainbox }>
        <Grid container id={ styles.maingrid2 }>
          { passworderrormsg && <ErrorMessage errormsg={ passworderrormsg } /> }
          {
            !errorLink ? <Typography variant="h6" gutterBottom sx={ { fontWeight: "bold" } }>
            Please reset your password
            </Typography> : <Button
              sx={{ float: "center" }}
              onClick={() => history("/forgotpassword")}
            >
              <Typography id={styles.btnforgot}
              >Forgot Password</Typography>
            </Button>
          }
          
          <Box
            component="form"
            onSubmit={ handleSubmit }
            sx={ {
              "& .MuiTextField-root": { m: 1, width: "375px", height: "50px" },
              position              : "relative"
            } }
            noValidate
            autoComplete="off"
          >
            <div>
              <Tooltip
                disableFocusListener
                title="Password must have at least 6 characters. For a strong password, use upper & lower case letters, numbers, and symbols like !@#$%&*"
                placement="top"
                classes={ { tooltip: classes.customWidth } }
              >
                <TextField
                  type={ showpassword ? "text" : "password" }
                  placeholder="Enter your password"
                  fullWidth
                  value={ password }
                  onChange={ e => {

                    setErrorflag(false);
                    setPassword(e.target.value);
                  
                  } }
                  InputProps={ {
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={ handleClickShowPassword }
                          edge="end"
                        >
                          { showpassword ? <Visibility /> : <VisibilityOff /> }
                        </IconButton>
                      </InputAdornment>
                    
                  } }
                />
              </Tooltip>
            </div>
            <div>
              <Tooltip
                disableFocusListener
                title="Password must have at least 6 characters. For a strong password, use upper & lower case letters, numbers, and symbols like !@#$%&*"
                placement="top"
                classes={ { tooltip: classes.customWidth } }
              >
                <TextField
                  type={ showconfirmpassword ? "text" : "password" }
                  placeholder="Confirm your password"
                  fullWidth
                  value={ confirmpassword }
                  onChange={ e => {

                    setErrorflag(false);
                    setConfirmPassword(e.target.value);
                  
                  } }
                  InputProps={ {
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={ handleClickShowConfirmPassword }
                          edge="end"
                        >
                          { showconfirmpassword ?
                            <Visibility />
                            :
                            <VisibilityOff />
                          }
                        </IconButton>
                      </InputAdornment>
                    
                  } }
                />
              </Tooltip>
            </div>
            <Button
              id={ styles.btn }
              variant="contained"
              disabled={ password.length < 6 && password !== confirmpassword }
              type="submit"
            >
              Reset Password
            </Button>
            { password &&
              <Grid
                sx={ {
                  position    : "absolute",
                  left        : "100%",
                  top         : "0px",
                  padding     : "10px",
                  width       : "150px",
                  border      : "solid 1px #cccccc",
                  borderRadius: "10px"
                } }
              >
                <Typography sx={ { fontSize: "12px", marginBottom: "10px" } }>
                  Password strength : { strengthtext }
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={ normalise(strength) }
                  sx={ {
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: strengthcolor
                    },
                    borderRadius: "10px"
                  } }
                />
              </Grid>
            }
          </Box>
        </Grid>
        <Typography id={ styles.endtext }>
          Clicking reset password will take you to the login screen where you
          can enter the new password to access your account
        </Typography>
      </Box>
    </div>
  );

};

export default ResetPassword;
