/* eslint-disable camelcase */
// import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box, Dialog,
  DialogContent, Grid, IconButton, Tooltip, Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
// import CommentContainer from "../../../components/CommentContainer";
import ConceptVisionData from "../../../Data/ConceptVision.json";
import {
  createConceptVisionDetails,
  getVetureById
} from "../../../services/venture";

import BackButton from "../../../components/BackButton";
import BackNextButton from "../../../components/BackNextButton";
import Bar from "../../../components/Bar";
import CustomMultilineTextfield from "../../../components/CustomMultilineTextfield";
import NextButton from "../../../components/NextButton";
import SaveCancelButton from "../../../components/SaveCancelButton";
import SuccessMessage from "../../../components/SuccessMessage";
import {
  createConceptVisionComment,
  deleteConceptVisionComment,
  updateConceptVisionComment
} from "../../../services/comment";
import styles from "./ConceptVision.module.css";
import NextPage from "./NextPage";

import ModuleSections from "../../../components/ModuleSections";

const ConceptVisionDetails = () => {

  const [ details, setDetails ] = useState({
    businessIdea   : "",
    longTerm       : "",
    productBuild   : "",
    target         : "",
    describeSuccess: ""
  });
  const [ comments, setComments ] = useState({
    tabOne: [],
    tabTwo: []
  });
  let location = useLocation();
  let history = useHistory();
  let dispatch = useDispatch();
  const [ dialogbox, setDialog ] = useState(false);
  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  // const [helpdialogbox, setHelpdialog] = useState(false);
  const [ successmsg, setSuccessmsg ] = useState("");
  const [ savedisabled, setSavedisabled ] = useState(true);

  const { user, venture } = useSelector(state => ({ ...state }));
  let ventureid = venture._id;


  useEffect(() => {

    fetchVenture();

  }, []);

  let hash = Number(location.hash.substring(1));
  let pathname = location.pathname.substring(1);
  let intendedurl =
    location.state && pathname.includes("edit")
      ? location.state.intendedurl
      : "";

  const datas = ConceptVisionData["first"];
  const content = ConceptVisionData["dialogbox"];

  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);

    } else {

      setCollapsecomment(true);

    }

  };

  const Dialogopen = () => {

    setDialog(true);

  };
  const Dialogclose = () => {

    setDialog(false);

  };

  const fetchVenture = async () => {

    try {

      const response = await getVetureById(venture._id);

      setDetails(response.data.venture.conceptVision);
      setComments(response.data.venture.comments.conceptVision);

    } catch (error) {

      console.error(error);

    }

  };

  const handledetailsChange = event => {

    setSavedisabled(false);
    const name = event.target.name;
    const value = event.target.value;

    setDetails(values => ({ ...values, [name]: value }));

  };

  const handleSubmit = async () => {

    try {

      const response = await createConceptVisionDetails(details, ventureid);

      dispatch({
        type   : "CURRENT_VENTURE",
        payload: {
          _id            : response.data._id,
          userid         : response.data.parentID,
          details        : response.data.details,
          conceptVision  : response.data.conceptVision,
          market         : response.data.market,
          customer       : response.data.customer,
          problemSolution: response.data.problemSolution,
          financials     : response.data.financials,
          gtm            : response.data.gtm,
          operations     : response.data.operations,
          pitchDeck      : response.data.pitchDeck,
          monetisation   : response.data.monetisation,
          userRole       : venture.userRole
        }
      });
      if (!pathname.includes("edit")) {

        history.push("/conceptvisionsummary");

      } else {

        setSuccessmsg("Changes saved");
        setTimeout(() => {

          setSuccessmsg("");

        }, 3000);

      }

    } catch (error) {

      console.error(error);

    }

  };

  const addComment = async (commentText, replyTo) => {

    try {

      const response = await createConceptVisionComment(
        commentText,
        user._id,
        "conceptVision",
        hash === 1 ? "tabTwo" : "tabOne",
        venture._id,
        replyTo,
        window.location.href,
        "Concept & Vision"
      );

      setComments(response.data.comments.conceptVision);

    } catch (error) {

      console.error(error);

    }

  };

  const updateComment = async (commentid, commentText) => {

    try {

      const response = await updateConceptVisionComment(
        commentText,
        user._id,
        commentid,
        venture._id,
        window.location.href,
        "Concept & Vision"
      );

      setComments(response.data.comments.conceptVision);

    } catch (error) {

      console.error(error);

    }

  };

  const deleteComment = async commentid => {

    try {

      const response = await deleteConceptVisionComment(
        "conceptVision",
        hash === 1 ? "tabTwo" : "tabOne",
        venture._id,
        commentid,
        user._id,
        window.location.href,
        "Concept & Vision"
      );

      setComments(response.data.comments.conceptVision);

    } catch (error) {

      console.error(error);

    }

  };

  return <ModuleSections
    Collapsecomment={Collapsecomment}
    comments={pathname == "conceptvision" && hash === 0 ? comments["tabOne"] : pathname == "conceptvision" && hash === 1 ? comments["tabTwo"] : comments[content.name]}
    addComment={addComment}
    deleteComment={deleteComment}
    updateComment={updateComment}>

    <div className="box-display-style-for-next-and-back-button-with-justify">
      <div className="box-indiv-header-con">
        <Bar length={2} hashvalue={hash} />
        <Box
          style={{
            display       : "flex",
            justifyContent: "space-between",
            marginTop     : "10px"
          }}>
          <Typography style={{ fontWeight: "bold" }}>
            Concept & Vision
          </Typography>
          <Box>
            <Tooltip title="View Guide">
              <img
                src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/post_add-24px.png`}
                style={{
                  marginRight: "1vw",
                  cursor     : "pointer"
                }}
                alt=""
                onClick={Dialogopen}
              />
            </Tooltip>

            <Dialog
              open={dialogbox}
              PaperProps={{
                style: {
                  borderRadius   : "20px",
                  backdropFilter : "blur(100px)",
                  backgroundColor: "initial",
                  boxShadow      : "0px 3px 6px #00000029",
                  border         : "1px solid #707070"
                }
              }}
              BackdropProps={{ invisible: true }}
              maxWidth="md"
              fullWidth>
              <Box sx={{ alignSelf: "flex-end" }}>
                <IconButton component="span" onClick={Dialogclose}>
                  <HighlightOffIcon />
                </IconButton>
              </Box>

              <DialogContent>
                <Typography fontWeight="900">
                  What are we doing here? Why? What's the Purpose?
                </Typography>
                <Typography gutterBottom>
                  {content.dialogpurpose}
                </Typography>
                <Typography fontWeight="900">Here's an analogy</Typography>
                <Typography gutterBottom>
                  {content.dialoganalogy}
                </Typography>
                <Typography fontWeight="900">Guide</Typography>
                <Typography>
                  &quot;Before you begin updating the canvas below here are
                  some steps to follow:
                </Typography>
                {content.dialogguide.map(guide => {

                  return <Typography>{guide}</Typography>;

                })}
              </DialogContent>
            </Dialog>
            <img
              src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/Icon awesome-comment-dots.png`}
              style={{
                cursor: "pointer"
              }}
              alt=""
              onClick={collapse_comment_function}
            />
          </Box>
        </Box>
      </div>
      <Grid item xs={12} id={styles.maingrid}>
        <form onSubmit={handleSubmit} style={{ margin: "0.5em" }}>
          {hash === 1 ?
            <NextPage
              handledetailsChange={handledetailsChange}
              details={details}
              handleSubmit={handleSubmit}
              intendedurl={intendedurl}
              pathname={pathname}
              successmsg={successmsg}
              savedisabled={savedisabled}
              setSuccessmsg={setSuccessmsg}
            />
            :
            <>
              <Grid container direction="row">
                <Grid item md={8}>
                  {datas.map(data => {

                    return (
                      <Box id={styles.individualbox}>
                        <Box>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            fontSize={"14px"}
                            sx={{ marginBottom: "10px" }}>
                            {data.mainquestion}
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{
                              fontSize    : "14px",
                              marginBottom: "10px"
                            }}>
                            <i>{data.subquestion}</i>
                          </Typography>
                        </Box>
                        <CustomMultilineTextfield
                          width="-webkit-fill-available"
                          borderRadius="10px"
                          // fontSize="0.75em"
                          lineHeight="2em"
                          height="9rem"
                          padding="10px 14px"
                          maxRows={4}
                          minRows={4}
                          name={data.name}
                          value={details[data.name]}
                          handledetailsChange={handledetailsChange}
                          placeholder={data.placeholder}
                        />
                      </Box>
                    );

                  })}
                </Grid>
                <Grid
                  item
                  xs={4}
                  md={4}
                  style={{
                    display       : "flex",
                    flexDirection : "column",
                    justifyContent: "center",
                    alignItems    : "center"
                  }}>
                  <img
                    src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/undraw_Taking_notes_re_bnaf.svg`}
                    style={{ width: "250px", height: "250px" }}
                    alt=""
                  />
                </Grid>
              </Grid>
              {successmsg && <SuccessMessage successmsg={successmsg} />}
            </>
          }
        </form>
      </Grid>

      <div className="ttribe-back-next-button-con">

        <form
          onSubmit={handleSubmit}
        // className="box-display-style-footer-content-con"
        >
          {pathname.includes("edit") ?
            <SaveCancelButton
              disabled={savedisabled}
              saveClick={handleSubmit}
              cancelClick={() => {

                intendedurl === location.pathname
                  ? history.push("/dashboard")
                  : history.push({
                    pathname: intendedurl,
                    state   : { intendedurl: intendedurl }
                  });

              }}
            />
            :
            <></>
          }
          {hash === 1 ?
            <>
              {" "}
              {pathname.includes("edit") ?
                <BackButton
                  restrictedBackClick={() => {

                    pathname.includes("edit")
                      ? history.push({
                        pathname: "/editconceptvisiondetails",
                        state   : { intendedurl: intendedurl }
                      })
                      : history.push("/conceptvision");

                  }}
                  backClick={() => {

                    pathname.includes("edit")
                      ? history.push({
                        pathname: "/editconceptvisiondetails",
                        state   : { intendedurl: intendedurl }
                      })
                      : history.push("/conceptvision");

                  }}
                />
                :
                <BackNextButton
                  restrictedBackClick={() => {

                    pathname.includes("edit")
                      ? history.push({
                        pathname: "/editconceptvisiondetails",
                        state   : { intendedurl: intendedurl }
                      })
                      : history.push("/conceptvision");

                  }}
                  backClick={() => {

                    pathname.includes("edit")
                      ? history.push({
                        pathname: "/editconceptvisiondetails",
                        state   : { intendedurl: intendedurl }
                      })
                      : history.push("/conceptvision");

                  }}
                  restrictedNextClick={() => {

                    if (!pathname.includes("edit")) {

                      history.push("/conceptvisionsummary");

                    } else {

                      setSuccessmsg("Changes saved");
                      setTimeout(() => {

                        setSuccessmsg("");

                      }, 3000);

                    }

                  }}
                  nextClick={handleSubmit}
                />
              }
            </>
            :
            <NextButton
              restrictedNextClick={() => {

                history.push({
                  hash : "1",
                  state: { intendedurl: intendedurl }
                });

              }}
              nextClick={() =>
                history.push({
                  hash : "1",
                  state: { intendedurl: intendedurl }
                })
              }
            />
          }
        </form>

      </div>
    </div>


  </ModuleSections>;

};

export default ConceptVisionDetails;
