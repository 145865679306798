import axios from "./axiosService";

// import axios from "axios";

export const getOperations = async operationsid => {

  return await axios.post(`${process.env.REACT_APP_ADDRESS}/get_operations`, {
    operationsid
  });

};

export const updateOperations = async (operationsid, sectionname, details) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/update_operations`,
    {
      operationsid,
      sectionname,
      details
    }
  );

};
