const ventureState = {
  _id            : "",
  userid         : "",
  details        : {},
  conceptVision  : {},
  market         : {},
  customer       : {},
  problemSolution: {},
  financials     : {},
  gtm            : {},
  operations     : {},
  pitchDeck      : {},
  monetisation   : {},
  userRole       : ""
  // percentValue:{
  //   pitchDeck:{
  //     status: 0
  //   }
  // }

};

export const ventureReducer = (state = ventureState, action) => {

  // the state can visible in redux chrome
  switch (action.type) {

  case "CURRENT_VENTURE":
    return action.payload;
  case "LOGOUT":
    return ventureState;
    // case "PITCHDECK_STATUS_VALUE":
    //   state.percentValue = { ...state.percentValue, pitchDeck: {status: action.payload } };
    //   // state.percentValue.pitchDeck.status = action.payload;
    //   return state;
  default:
    return state;
  
  }

};
