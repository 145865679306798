/* eslint-disable camelcase */
import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BackNextButton from "../../../components/BackNextButton";
import IndividualSectionHeaders from "../../../components/IndividualSectionHeaders";
import ModuleSections from "../../../components/ModuleSections";
import Datas from "../../../Data/Problem.json";

import {
  createProblemSolutionComment,
  deleteProblemSolutionComment
} from "../../../services/comment";
import { getProblemSolution } from "../../../services/problemsolution";
import styles from "./Problem.module.css";

const AffinityMapping = () => {

  const [ comments, setComments ] = useState([]);
  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const [ dialogbox, setDialog ] = useState(false);
  const history = useHistory();
  // const pathname = history.location.pathname.substring(1);

  const content = Datas.affinitymapping;

  let { user, venture } = useSelector(state => {

    return { ...state };
  
  });

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);
    
    } else {

      setCollapsecomment(true);
    
    }
  
  };

  useEffect(() => {

    fetchProblemSolution();
  
  }, []);

  const fetchProblemSolution = async () => {

    try {

      const response = await getProblemSolution(venture.problemSolution);

      setComments(response.data.comments.problem.analysisSynthesis);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const addComment = async (commentText, replyTo) => {

    try {

      await createProblemSolutionComment(
        commentText,
        user._id,
        "problem",
        "analysisSynthesis",
        venture.problemSolution,
        replyTo,
        window.location.href,
        "Analysis & Synthesis"
      );
      fetchProblemSolution();
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const deleteComment = async commentid => {

    try {

      await deleteProblemSolutionComment(
        "problem",
        "analysisSynthesis",
        venture.problemSolution,
        commentid
      );
      fetchProblemSolution();
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  return (
    <ModuleSections
      Collapsecomment={ Collapsecomment }
      comments={ comments }
      addComment={ addComment }
      deleteComment={ deleteComment }
      fetchDetails={ fetchProblemSolution }
      sectionName="Analysis & Synthesis"
    >
      <div className="box-display-style-for-next-and-back-button-with-justify">
        <div className="box-indiv-header-con">
          <IndividualSectionHeaders
            mainheading={ "Problem" }
            subheading={ "Analysis & Synthesis" }
            headingcontent={
              // "Synthesize, analyze, & derive insights from your user interviews."
              "Analyze, synthesize, and derive insights from your research, discussions, and user interviews"
            }
            isGtm={ false }
            isexpand={ true }
            Dialogopen={ Dialogopen }
            Dialogclose={ Dialogclose }
            dialogbox={ dialogbox }
            collapse_comment_function={ collapse_comment_function }
            content={ Datas.guidecontent }
          />

        </div>

        <Grid item xs={ 12 } id={ styles.maingrid }>

          <Grid container id={ styles.affinitycontainer }>
            <Grid item md={ 6 } id={ styles.scrollleftgrid }>
              <Typography id={ styles.affinityheading }>
                { content.heading }
              </Typography>
              <Typography id={ styles.affinitycontent }>
                { content.maincontent }
              </Typography>
              <Typography id={ styles.affinitycontent }>
                {/* What will you do here: */ }
                Follow these steps:
              </Typography>
              { content.subcontent.map(contents =>
                <Typography id={ styles.affinitycontent }>{ contents }</Typography>
              ) }
            </Grid>
            <Grid item md={ 5 } id={ styles.rightgrid }>
              <img
                src={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/undraw_our_solution_re_8yk6.svg` }
                id={ styles.affinityimg }
              />
            </Grid>
          </Grid>

        </Grid>

        <div className="ttribe-back-next-button-con" >
          <BackNextButton
            restrictedBackClick={ () => history.push("/userinterviews") }
            backClick={ () => history.push("/userinterviews") }
            restrictedNextClick={ () => {

              history.push("/analysissynthesisnotes");
            
            } }
            nextClick={ () => {

              history.push("/analysissynthesisnotes");
            
            } }
          />

        </div>
      </div>

    </ModuleSections>
  );

};

export default AffinityMapping;
