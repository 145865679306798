import { Box, Typography } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { makeStyles, styled } from "@mui/styles";
import React from "react";

const LightTooltip = styled(({ className, ...props }) =>
  <Tooltip enterTouchDelay={ 0 } { ...props } classes={ { popper: className } } />
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color          : "rgba(0, 0, 0, 0.87)",
    // boxShadow: theme.shadows[1],
    border         : "solid 1px grey",
    fontSize       : 11
  }
}));

const useStyles = makeStyles(() => ({
  outer: {
    width       : "80%",
    height      : "90%",
    position    : "relative",
    // border: "1px solid black",
    borderRadius: "50%"
  },
  inner: {
    top         : "30%",
    left        : "15%",
    width       : "70%",
    height      : "70%",
    position    : "relative",
    // border: "1px solid black",
    borderRadius: "50%"
  },
  innerText: {
    position      : "absolute",
    top           : "5%",
    left          : "50%",
    transform     : "translateX(-50%)",
    textAlign     : "center",
    cursor        : "default",
    fontWeight    : "bold",
    // width: "100%",
    "&:last-child": {
      top      : "50%",
      left     : "50%",
      transform: "translate(-50%, -50%)"
    }
  },
  innerLabel: {
    display: "block"
  },
  tooltipBox: {
    display       : "flex",
    flexDirection : "column",
    alignItems    : "center",
    justifyContent: "center"
  }
}));

const CustomChart = ({
  totalAddressableMarket,
  segmentedAddressableMarket,
  ourExpectedMarketShare
}) => {

  const classes = useStyles();


  const tooltipContent = details =>
    <Box className={ classes.tooltipBox }>
      <Typography sx={ { borderBottom: "solid 1px grey", fontSize: "14px" } }>
        { details.name }
      </Typography>
      <Typography sx={ { fontSize: "14px" } }>
        { details.formattedValue }
      </Typography>
    </Box>

  ;

  return (
    <div className={ classes.outer } style={ { backgroundColor: "#224AFB" } }>
      <LightTooltip
        title={ tooltipContent(totalAddressableMarket) }
        placement="right-start">
        <span className={ classes.innerText } style={ { color: "white" } }>
          { totalAddressableMarket.label }
          <span className={ classes.innerLabel }>
            { totalAddressableMarket.formattedValue }
          </span>
        </span>
      </LightTooltip>
      <div className={ classes.inner } style={ { backgroundColor: "#98ABFF" } }>
        <LightTooltip
          title={ tooltipContent(segmentedAddressableMarket) }
          placement="right-start">
          <span className={ classes.innerText }>
            { segmentedAddressableMarket.label }
            <span className={ classes.innerLabel }>
              { segmentedAddressableMarket.formattedValue }
            </span>
          </span>
        </LightTooltip>
        <div className={ classes.inner } style={ { backgroundColor: "#DBE2FF" } }>
          <LightTooltip
            title={ tooltipContent(ourExpectedMarketShare) }
            placement="right-start">
            <span className={ classes.innerText }>
              { ourExpectedMarketShare.label }
              <span className={ classes.innerLabel }>
                { ourExpectedMarketShare.formattedValue }
              </span>
            </span>
          </LightTooltip>
        </div>
      </div>
    </div>
  );

};

export default CustomChart;
