import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import ReactApexChart from "react-apexcharts";

import {
  Box, Dialog, DialogContent, IconButton, Tooltip
} from "@mui/material";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";

const CashFlowBreakDown = props => {

  const [ chartdata, setChartdata ] = useState([]);
  const currency = props?.currency || "- I";
  const YearsList = props?.year;
  const [ dialogbox, setDialogBox ] = useState(false);
  const history = useHistory();
  const chartTitle = props.chartTitle;
  const moreDetails = props.moreDetails;
  const tableRow = [
    "Net_Cash_Flow_from_Operations", "Net_Cash_Flow_from_Investing_Activities", "Net_Cash_Flow_from_Financing_Activities"
  ];

  useEffect(() => {

    updateChartdata(tableRow);

  }, [ props?.table ]);

  const DataConvertToNumber = data =>{

    return  Number(data.replaceAll(",", ""));

  };

  const updateChartdata = () => {

    let data = [];
    const table = props?.table;
    let operations = [];
    let investingActivities = [];
    let financialActivities = [];

    if(table && table.length > 0){

      table.map(row => {
    
        if (row.rowName === tableRow[0]) {
    
          row.rowVal.map(column => {
    
            operations.push(DataConvertToNumber(column.value));
    
          });
    
        } else if(row.rowName === tableRow[1]) {
    
          row.rowVal.map(column => {
    
            investingActivities.push(DataConvertToNumber(column.value));
    
          });
    
        } else if(row.rowName === tableRow[2]) {
    
          row.rowVal.map(column => {

            financialActivities.push(DataConvertToNumber(column.value));
    
          });
    
        }
    
      });
    
      data.push({
        name: "Operations",
        data: operations
      },
      {
        name: "Investing Activities",
        data: investingActivities
      },
      {
        name: "Financing Activites",
        data: financialActivities
      });
    
      setChartdata(data);
    
    } else {
            
      setChartdata([ { name: "No Data", data: [] } ]);
    
    }


  };

  const numberWithCommas = num => {

    if (currency) {

      let newNum = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      return `${currency?.split(" ")[0].trim()} ${newNum}`;

    } else {

      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      // return num;

    }
  
  };

  let options = {
    chart: {
      type   : "bar",
      toolbar: {
        show : true,
        tools: {
          download   : true,
          selection  : false,
          zoom       : false,
          zoomin     : false,
          zoomout    : false,
          pan        : false,
          reset      : false,
          customIcons: []
        },
        autoSelected: "pan"
      }
    },
    noData: {
      text         : "No Data to show",
      align        : "center",
      verticalAlign: "middle"
    },
    colors: [
      "#6666ff", "#FFB266", "#99CCFF"
    ],
    plotOptions: {
      bar: {
        horizontal : true,
        columnWidth: "70%",
        endingShape: "rounded",
        dataLabels : {
          position: "top"
        }
      }
    },
    dataLabels: {
      // enabled: props.isMultibar ? false : true,
      enabled  : false,
      formatter: function (val) {

        return numberWithCommas(val);
      
      }
    },
    stroke: {
      show  : true,
      width : 2,
      colors: [ "transparent" ]
    },
    xaxis: {
      categories: YearsList && YearsList,
      title     : {
        text   : `Cash Flow ${currency.split(" ")[0]}`, offsetX: 0,
        offsetY: 0
        // text: "check"
      },
      position: "bottom",
      labels  : {
        formatter: val => { return numberWithCommas(val); }
      }
    },
    yaxis: {
      title: {
        text: "Time Period"
      }
      // labels: {
      //     formatter: (val) => { return numberWithCommas(val) },
      // },
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (vall) {

          return `${numberWithCommas(vall)} `;
        
        }
      }
    }
  };

  return <div className="tribe-chart-page-con">
    <div className="tribe-fin-chart-year-sel-main-con">
      <div className="tribe-fin-chart-year-sel-title-con"><p> <span >{chartTitle}</span></p></div>
      <Tooltip
        enterTouchDelay={0}
        disableFocusListener
        title="View"
        placement="top"
      >
        <IconButton
          component="span"
          onClick={() => {

            setDialogBox(true);
          
          }}>
          <OpenInFullIcon />
        </IconButton>
      </Tooltip>
    </div>
    <ReactApexChart options={options} series={chartdata} type="bar" height="100%" width="100%" className="tribe-apex-chart-con" />
    <div className="fin-chart-more-detail-con">
      <p>For details click <span onClick={() => history.push(moreDetails.url)}>{moreDetails.page}</span></p>
    </div>
    <Dialog
      open={dialogbox}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        style: {
          borderRadius  : "20px",
          backdropFilter: "blur(45px)",
          background:
                        "#FFFFFF 0% 0% no-repeat padding-box",
          boxShadow: "0px 3px 6px #00000029",
          border   : "1px solid #707070"
        }
      }}
    >
      <DialogContent>
        <Box sx={{ textAlign: "center" }}>

          <div className="tribe-fin-chart-year-sel-main-con">
            <div className="tribe-fin-chart-year-sel-title-con"><p> <span>{chartTitle}</span></p></div>
            <Tooltip
              enterTouchDelay={0}
              disableFocusListener
              title="Close"
              placement="right"
            >
              <IconButton
                component="span"
                onClick={() => {

                  setDialogBox(false);
                
                }}>
                <HighlightOffIcon />
              </IconButton>
            </Tooltip>
          </div>
          <ReactApexChart options={options} series={chartdata} type="bar" height="400px" width="95%" className="tribe-apex-chart-con" />
          <div className="fin-chart-more-detail-con">
            <p>For details click <span onClick={() => history.push(moreDetails.url)}>{moreDetails.page}</span></p>
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  </div>;

};

export default CashFlowBreakDown;
