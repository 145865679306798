import React from "react";
import {
  Box, TextField, Typography
} from "@mui/material";
// import { useHistory } from "react-router-dom";
import SectionHeader from "../SectionHeader";

export default function Competition({
  logoimg,
  slides,
  isRender,
  setSlides,
  isPublished,
  ...props
}) {

  const slide = slides[isRender];

  // const history = useHistory();
  // const pathname = history.location.pathname.substring(1);

  const handleCompetitionNameChange = (value, columnindex) => {

    let newslides = [ ...slides ];

    newslides[isRender]["isChanged"] = true;

    newslides[isRender]["competitions"].map((competition, competitionIndex) => {

      if (competitionIndex === columnindex) {

        newslides[isRender]["competitions"][competitionIndex]["name"] = value;
      
      }
      
      return competition;
    
    });

    setSlides(newslides);
  
  };

  const handleCompetitionRowNameChange = (value, index) => {

    let newslides = [ ...slides ];

    newslides[isRender]["isChanged"] = true;

    newslides[isRender]["competitions"].map((competition, competitionIndex) => {

      newslides[isRender]["competitions"][competitionIndex]["rows"].map(
        (row, rowIndex) => {

          if (rowIndex === index) {

            newslides[isRender]["competitions"][competitionIndex]["rows"][
              rowIndex
            ]["name"] = value;
          
          }
          
          return row;
        
        }
      );
      
      return competition;
    
    });

    setSlides(newslides);
  
  };

  const handleCompetitionRowValueChange = (value, columnindex, index) => {

    let newslides = [ ...slides ];

    newslides[isRender]["isChanged"] = true;

    newslides[isRender]["competitions"].map((competition, competitionIndex) => {

      if (competitionIndex === columnindex) {

        newslides[isRender]["competitions"][competitionIndex]["rows"].map(
          (row, rowIndex) => {

            if (rowIndex === index) {

              newslides[isRender]["competitions"][competitionIndex]["rows"][
                rowIndex
              ]["value"] = value;
            
            }
            
            return row;
          
          }
        );
      
      }
      
      return competition;
    
    });

    setSlides(newslides);
  
  };

  const handleDetailsChange = (name, value) => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    newSlides[isRender][name] = value;
    setSlides(newSlides);
  
  };

  return (
    <Box>
      <SectionHeader
        isEdit={props.isEdit}
        Collapsecomment={props.Collapsecomment}
        collapseCommentFunction={props.collapseCommentFunction}
        mainHead={slide.name}
        handleTitleChange={handleDetailsChange}
        logoimg={logoimg}
        isPublished={isPublished}
      />
      <Box
        sx={{
          width     : "100%",
          height    : "64vh",
          display   : "flex",
          alignItems: "center"
        }}
      >
        {/* main flex */}
        <Box sx={{ display: "flex", columnGap: "10px", width: "100%" }}>
          {/* left side title box */}
          <Box
            sx={{
              width         : "20%",
              display       : "flex",
              flexDirection : "column",
              justifyContent: "space-between"
            }}
          >
            <Box
              sx={{
                display      : "flex",
                flexDirection: "column",
                width        : "100%",
                rowGap       : "6px"
              }}
            >
              <Box sx={{ height: "5vh", width: "100%" }}></Box>
              <Box
                sx={{
                  height        : "9vh",
                  width         : "100%",
                  display       : "flex",
                  justifyContent: "center",
                  alignItems    : "center",
                  marginBottom  : "6px"
                }}
              >
                {props.isEdit ?
                  <Box sx={{ width: "100%" }}>
                    <TextField
                      value={slide.competitions[0].rows[0].name}
                      onChange={e => {

                        handleCompetitionRowNameChange(e.target.value, 0);
                      
                      }}
                      fullWidth
                      inputProps={{ maxLength: 40 }}
                      multiline
                      maxRows={2}
                      minRows={2}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          padding: "1px 10px",
                          border : "none",
                          height : "9vh"
                        },
                        "& .MuiInputBase-input": {
                          fontSize : "1.5vh",
                          color    : "#707070",
                          textAlign: "center"
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px dotted #B4B4FA"
                        }
                      }}
                    />
                  </Box>
                  :
                  <Typography
                    sx={{
                      fontSize : "1.5vh",
                      color    : "#393939",
                      textAlign: "left"
                    }}
                  >
                    {slide.competitions[0].rows[0].name}
                  </Typography>
                }
              </Box>

              <Box
                sx={{
                  height        : "9vh",
                  width         : "100%",
                  display       : "flex",
                  justifyContent: "center",
                  alignItems    : "center"
                }}
              >
                {props.isEdit ?
                  <Box sx={{ width: "100%" }}>
                    <TextField
                      value={slide.competitions[0].rows[1].name}
                      onChange={e => {

                        handleCompetitionRowNameChange(e.target.value, 1);
                      
                      }}
                      fullWidth
                      multiline
                      inputProps={{ maxLength: 40 }}
                      maxRows={2}
                      minRows={2}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          padding: "0px 10px",
                          border : "none",
                          height : "9vh"
                        },
                        "& .MuiInputBase-input": {
                          fontSize : "1.5vh",
                          color    : "#707070",
                          textAlign: "center"
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px dotted #B4B4FA"
                        }
                      }}
                    />
                  </Box>
                  :
                  <Typography
                    sx={{
                      fontSize: "1.5vh",
                      color   : "#393939"
                    }}
                  >
                    {slide.competitions[0].rows[1].name}
                  </Typography>
                }
              </Box>

              <Box
                sx={{
                  height        : "10vh",
                  // border: 1,
                  width         : "100%",
                  display       : "flex",
                  justifyContent: "center",
                  alignItems    : "center",
                  marginBottom  : "6px"
                }}
              >
                {props.isEdit ?
                  <Box sx={{ width: "100%" }}>
                    <TextField
                      value={slide.competitions[0].rows[2].name}
                      onChange={e => {

                        handleCompetitionRowNameChange(e.target.value, 2);
                      
                      }}
                      fullWidth
                      multiline
                      maxRows={2}
                      minRows={2}
                      inputProps={{ maxLength: 40 }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          padding: "0px 10px",
                          border : "none",
                          height : "9vh"
                        },
                        "& .MuiInputBase-input": {
                          fontSize : "1.5vh",
                          color    : "#707070",
                          textAlign: "center"
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px dotted #B4B4FA"
                        }
                      }}
                    />
                  </Box>
                  :
                  <Typography sx={{ fontSize: "1.5vh", color: "#393939" }}>
                    {slide.competitions[0].rows[2].name}
                  </Typography>
                }
              </Box>

              <Box
                sx={{
                  height        : "9vh",
                  // border: 1,
                  width         : "100%",
                  display       : "flex",
                  justifyContent: "center",
                  alignItems    : "center",
                  marginBottom  : "6px"
                }}
              >
                {props.isEdit ?
                  <Box sx={{ width: "100%" }}>
                    <TextField
                      value={slide.competitions[0].rows[3].name}
                      onChange={e => {

                        handleCompetitionRowNameChange(e.target.value, 3);
                      
                      }}
                      inputProps={{ maxLength: 40 }}
                      fullWidth
                      multiline
                      maxRows={2}
                      minRows={2}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          padding: "0px 10px",
                          border : "none",
                          height : "9vh"
                        },
                        "& .MuiInputBase-input": {
                          fontSize : "1.5vh",
                          color    : "#707070",
                          textAlign: "center"
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px dotted #B4B4FA"
                        }
                      }}
                    />
                  </Box>
                  :
                  <Typography sx={{ fontSize: "1.5vh", color: "#393939" }}>
                    {slide.competitions[0].rows[3].name}
                  </Typography>
                }
              </Box>
            </Box>
            <Box
              sx={{
                height        : "90px",
                display       : "flex",
                justifyContent: "center",
                alignItems    : "center",
                marginBottom  : "10px"
                // border: 1,
              }}
            >
              <Typography
                sx={{ color: "#393939", fontSize: "21px", fontWeight: "bold" }}
              >
                {slide.competitions[0].rows[4].name}
              </Typography>
            </Box>
          </Box>
          {/* right box */}
          <Box sx={{ width: "80%", display: "flex", columnGap: "10px" }}>
            {/* 1 */}
            {slide.competitions.map((competition, competitionIndex) => {

              return (
                <Box
                  sx={{
                    display       : "flex",
                    flexDirection : "column",
                    justifyContent: "space-between",
                    width         : "32%"
                  }}
                >
                  <Box
                    sx={{
                      display      : "flex",
                      flexDirection: "column",
                      rowGap       : "5px",
                      width        : "100%"
                    }}
                  >
                    <Box
                      sx={{
                        height        : "5vh",
                        width         : "100%",
                        display       : "flex",
                        justifyContent: "center",
                        alignItems    : "center"
                      }}
                    >
                      {props.isEdit ?
                        <Box>
                          <TextField
                            value={competition.name}
                            onChange={e => {

                              handleCompetitionNameChange(
                                e.target.value,
                                competitionIndex
                              );
                            
                            }}
                            fullWidth
                            inputProps={{ maxLength: 20 }}
                            sx={{
                              "& .MuiInputBase-input": {
                                fontSize  : "21px",
                                padding   : "1px 10px",
                                border    : "none",
                                color     : "#393939",
                                textAlign : "center",
                                fontWeight: "bold"
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "2px dotted #B4B4FA"
                              }
                            }}
                          />
                        </Box>
                        :
                        <Typography
                          sx={{
                            color     : "#393939",
                            fontSize  : "21px",
                            fontWeight: "bold"
                          }}
                        >
                          {competition.name}
                        </Typography>
                      }
                    </Box>
                    {competition.rows.slice(0, 4).map((row, rowIndex) => {

                      return (
                        <Box sx={{ height: "10vh", width: "100%" }}>
                          {props.isEdit ?
                            <TextField
                              value={row.value}
                              onChange={e => {

                                handleCompetitionRowValueChange(
                                  e.target.value,
                                  competitionIndex,
                                  rowIndex
                                );
                              
                              }}
                              fullWidth
                              multiline
                              inputProps={{ maxLength: 105 }}
                              maxRows={3}
                              minRows={3}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  padding: "2px 3px",
                                  height : "9vh"
                                },
                                "& .MuiInputBase-input": {
                                  fontSize  : "0.8em",
                                  lineHeight: "1.5",
                                  color     : "#707070",
                                  whiteSpace: "pre-line"
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border      : "2px dotted #919AFF",
                                  borderRadius: "4px"
                                }
                              }}
                            />
                            :
                            <Box
                              sx={{
                                width       : "100%",
                                border      : "1px solid #BEBEBE",
                                height      : "9vh",
                                borderRadius: "5px",
                                // padding: "10px 15px",
                                overflowY   : "auto",
                                display     : "flex",
                                alignItems  : "center"
                              }}
                            >
                              <Box
                                sx={{
                                  margin  : "0px 3px",
                                  wordWrap: "break-word"
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize  : "1.5vh",
                                    color     : "#707070",
                                    lineHeight: "1.5",
                                    whiteSpace: "pre-line"
                                  }}
                                >
                                  {row.value}
                                </Typography>
                              </Box>
                            </Box>
                          }
                        </Box>
                      );
                    
                    })}
                  </Box>
                  <Box sx={{ width: "100%", marginBottom: "10px" }}>
                    {props.isEdit ?
                      <TextField
                        value={competition.rows[4].value}
                        onChange={e => {

                          handleCompetitionRowValueChange(
                            e.target.value,
                            competitionIndex,
                            4
                          );
                        
                        }}
                        // placeholder="Enter key details of your competitor’s product offering and how much they have managed to penetrate the market"
                        fullWidth
                        multiline
                        maxRows={4}
                        minRows={4}
                        inputProps={{ maxLength: 105 }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            padding: "3px 3px"
                            // border: "1px solid #B4B4FA",
                          },
                          "& .MuiInputBase-input": {
                            fontSize  : "0.8em",
                            // padding: "7px 10px",
                            lineHeight: "1.3",
                            color     : "#707070",
                            whiteSpace: "pre-line",
                            height    : "9vh"
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            // border: "2px solid #B4B4FA",
                            border      : "2px dotted #919AFF",
                            borderRadius: "4px"
                          }
                        }}
                      />
                      :
                      // <TextField
                      //   value={competition.rows[4].value}
                      //   disabled
                      //   // placeholder="Enter key details of your competitor’s product offering and how much they have managed to penetrate the market"
                      //   placeholder={
                      //     pathname.includes("pitchpresentation") ? "" : ""
                      //   }
                      //   fullWidth
                      //   multiline
                      //   maxRows={4}
                      //   minRows={4}
                      //   sx={{
                      //     "& .MuiOutlinedInput-root": {
                      //       padding: "3px 3px",
                      //       // border: "1px solid #B4B4FA",
                      //     },
                      //     "& .MuiInputBase-input": {
                      //       fontSize: "11px",
                      //       // padding: "7px 10px",
                      //       lineHeight: "22px",
                      //       "-webkit-text-fill-color": `#707070 !important`,
                      //       color: `#707070 !important`,
                      //       // fontWeight: "bold",
                      //     },
                      //     "& .MuiOutlinedInput-notchedOutline": {
                      //       // border: "2px solid #B4B4FA",
                      //       border: "1px solid #919AFF",
                      //       borderRadius: "4px",
                      //     },
                      //   }}
                      // />
                      <Box
                        sx={{
                          width       : "100%",
                          border      : "1px solid #BEBEBE",
                          height      : "94px",
                          borderRadius: "5px",
                          // padding: "10px 15px",
                          overflowY   : "auto",
                          display     : "flex",
                          alignItems  : "center"
                        }}
                      >
                        <Box
                          sx={{
                            margin  : "3px 3px",
                            wordWrap: "break-word"
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize  : "1.5vh",
                              color     : "#707070",
                              lineHeight: "1.5",
                              whiteSpace: "pre-line"
                            }}
                          >
                            {competition.rows[4].value}
                          </Typography>
                        </Box>
                      </Box>
                    }
                  </Box>
                </Box>
              );
            
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );

}
