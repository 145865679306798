import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import HelpIcon from "@mui/icons-material/Help";
import {
  Box, Grid, IconButton, Tooltip, Typography
} from "@mui/material";
import React from "react";

import { makeStyles } from "@mui/styles";
import CustomMultilineTextfield from "./CustomMultilineTextfield";

const useStyles = makeStyles(() => ({
  customWidth: {
    maxWidth       : 500,
    fontSize       : 10,
    backgroundColor: "white",
    color          : "#707070",
    boxShadow      : "0px 3px 6px #00000029",
    border         : "1px solid #707070",
    borderRadius   : 10,
    whiteSpace     : "pre-wrap"
  },
  individualBox: {
    border      : "1px solid #707070",
    borderRadius: "13px"
  },
  question: {
    border      : "1px solid #071AFF",
    borderRadius: "10px",
    padding     : "15px 20px 0px",
    fontSize    : "14px",
    fontWeight  : "bold",
    paddingRight: "0px",
    height      : "70px"
  },
  answer: {
    width                               : "100%",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    },
    "& .MuiInputBase-input": {
      fontSize: "12px"
    }
  },
  helpicon: {
    color   : "#071AFF",
    fontSize: "18px"
  }
}));

const ProblemSolutionFirst = props => {

  const content = props.content;
  const classes = useStyles();

  
  return (
    <Grid container mb={ 2 }>
      <Grid item md={ 5 }>
        <Typography
          sx={ { padding: "15px 30px", fontSize: "18px", fontWeight: "bold" } }
        >
          { content.first.label }
        </Typography>
        <Box className={ classes.individualBox }>
          <Typography className={ classes.question }>
            { content.first.question }
            <Tooltip
              disableFocusListener
              title={ content.first.tooltip }
              placement={ "right-start" }
              classes={ { tooltip: classes.customWidth } }
            >
              <IconButton disableTouchRipple disableFocusRipple>
                <HelpIcon className={ classes.helpicon }></HelpIcon>
              </IconButton>
            </Tooltip>
          </Typography>
          {/* <TextField
            multiline
            maxRows={9}
            minRows={9}
            name={props.firstname}
            value={props.firstvalue}
            className={classes.answer}
            placeholder={content.first.placeholder}
            onChange={(e) => {
              props.handleChange(props.firstname, e.target.value);
            }}
          /> */}
          <CustomMultilineTextfield
            // fontSize={"12px"}
            width={ "100%" }
            border={ "none" }
            maxRows={ 9 }
            minRows={ 9 }
            name={ props.firstname }
            value={ props.firstvalue }
            placeholder={ content.first.placeholder }
            handledetailsChange={ e => {

              props.handleChange(props.firstname, e.target.value);
            
            } }
          />
        </Box>
      </Grid>
      <Grid
        item
        md={ 2 }
        sx={ { display: "flex", justifyContent: "center", alignItems: "center" } }
      >
        <ArrowRightAltIcon sx={ { fontSize: "50px", color: "#071AFF" } } />
      </Grid>
      <Grid item md={ 5 }>
        <Typography
          sx={ { padding: "15px 30px", fontSize: "18px", fontWeight: "bold" } }
        >
          { content.second.label }
        </Typography>
        <Box className={ classes.individualBox }>
          <Typography className={ classes.question }>
            { content.second.question }
            <Tooltip
              disableFocusListener
              title={ content.second.tooltip }
              placement={ "right-start" }
              classes={ { tooltip: classes.customWidth } }
            >
              <IconButton disableTouchRipple disableFocusRipple>
                <HelpIcon className={ classes.helpicon }></HelpIcon>
              </IconButton>
            </Tooltip>
          </Typography>
          {/* <TextField
            multiline
            maxRows={9}
            minRows={9}
            name={props.secondname}
            value={props.secondvalue}
            className={classes.answer}
            placeholder={content.second.placeholder}
            onChange={(e) => {
              props.handleChange(props.secondname, e.target.value);
            }}
          /> */}
          <CustomMultilineTextfield
            // fontSize={"12px"}
            width={ "100%" }
            border={ "none" }
            maxRows={ 9 }
            minRows={ 9 }
            name={ props.secondname }
            value={ props.secondvalue }
            placeholder={ content.second.placeholder }
            handledetailsChange={ e => {

              props.handleChange(props.secondname, e.target.value);
            
            } }
          />
        </Box>
      </Grid>
    </Grid>
  );

};

export default ProblemSolutionFirst;
