/* eslint-disable camelcase */
import React, { createRef, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Typography,
  CircularProgress
} from "@mui/material";
import { Country, State } from "country-state-city";
import { useSelector } from "react-redux";

import Card_no_collapse from "../../../../components/Card";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { makeStyles } from "@mui/styles";
import { useHistory, useLocation } from "react-router-dom";
import CustomSelect from "../../../../components/CustomSelect";
import NextButton from "../../../../components/NextButton";
import Data from "../../../../Data/data.json";
import {
  downloadPersona,
  updatePersona,
  getPersona
} from "../../../../services/persona";

import EditBuyingBehaviour from "./EditBuyingBehaviour";
import EditDemographics from "./EditDemographics";
import EditIndividualCharacteristics from "./EditIndividualCharacteristics";
import EditPsychographics from "./EditPsychographics";
import styles from "./TargetSegments.module.css";


const useStyles = makeStyles({
  multiLineEllipsis: {
    overflow            : "hidden",
    textOverflow        : "ellipsis",
    display             : "-webkit-box",
    "-webkit-line-clamp": props => props.numberLines,
    "-webkit-box-orient": "vertical"
  }
});

const UserPersonaDetails = props => {

  const [ persona, setPersona ] = useState({
    type                     : "",
    name                     : "",
    avatar                   : "",
    demographics             : {},
    geographics              : {},
    psychographics           : {},
    buyingBehaviour          : {},
    individualCharacteristics: {},
    parentSegment            : {}
  });
  const [ solutioncapability, setSolutioncapability ] = useState("");
  const [ problemorneed, setProblemorneed ] = useState("");
  const [ isupdate, setIsupdate ] = useState("");
  const [ updatedialog, setUpdateDialog ] = useState(false);
  const [ numberLines, setNumberLines ] = useState(5);
  const [ problemViewmore, setProblemviewmore ] = useState(false);
  const solutionViewmore = false;
  const [ isDownloading, setIsDownloading ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const gridref = useRef();
  const personaid = props.match.params.id;
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles({ numberLines });
  const problemRef = createRef();
  const solutionRef = createRef();
  const problemSolutionRef = useRef();

  const pathname = history.location.pathname.substring(1);

  const avatars = Data.avatarimages;

  const hashvalue = location.hash.substring(1);

  let { venture } = useSelector(state => {

    return { ...state };
  
  });

  useEffect(() => {

    fetchPersona();
    // fetchSolution();
    localStorage.removeItem("name");
    localStorage.removeItem("segmentid");
    localStorage.removeItem("personaid");
  
  }, [ hashvalue ]);

  const gridScrollUp = () => {

    gridref.current.scrollTop = 0;
  
  };

  const countries = Country.getAllCountries();
  const updatedCountries = countries.map(country => ({
    label: country.name,
    value: country.name,
    ...country
  }));

  const updatedStates = countryId => {

    let statesOptions = [
      {
        label: "-None-",
        value: ""
      }
    ];

    State.getStatesOfCountry(countryId).map(state => {

      statesOptions.push({
        label: state.name,
        value: state.name,
        ...state
      });
    
    });
    
    return statesOptions;
  
  };

  const fetchPersona = async () => {

    setLoading(true);
    try {

      const response = await getPersona(personaid, venture.problemSolution);

      setPersona(response.data.persona);
      setProblemorneed(response.data.problemOrNeed);
      setSolutioncapability(response.data.SolutionCapability);
      setLoading(false);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const downloadPersonaSummary = async () => {

    setIsDownloading(true);
    const response = await downloadPersona(personaid, venture.problemSolution);
    const blobData = new Blob([ response.data ]);
    const url = window.URL.createObjectURL(blobData);
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute(
      "download",
      `${venture.details.name} - Persona Summary - ${persona.name}.pdf`
    );
    document.getElementById("personasummarydownload").appendChild(link);
    link.click();
    setIsDownloading(false);
  
  };

  const updatePersonaDetails = async () => {

    try {

      await updatePersona(personaid, persona, venture._id);
      setIsupdate("");
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  useEffect(() => {

    if (problemSolutionRef.current) {

      let lines = Math.round(problemSolutionRef.current.clientHeight / 21);

      setNumberLines(lines - 1);
    
    }
  
  }, [ problemSolutionRef ]);

  useEffect(() => {

    if (problemRef.current && solutionRef.current) {

      if (problemRef.current.clientHeight < problemRef.current.scrollHeight)
        setProblemviewmore(true);
      if (solutionRef.current.clientHeight < solutionRef.current.scrollHeight)
        setProblemviewmore(true);
    
    }
  
  }, [ problemRef, solutionRef ]);

  return (
    <div
      style={{
        alignItems   : "center",
        display      : "flex",
        height       : "85vh",
        marginTop    : "auto",
        marginBottom : "auto",
        verticalAlign: "middle"
      }}>
      <Box style={{ margin: "5vh", position: "relative" }}>
        <Card_no_collapse />
      </Box>

      {
        loading ? <Grid
          container
          justifyContent="center"
          height="100%"
          alignItems="center">
          <Grid item>
            <CircularProgress size="60px" thickness={3} />
          </Grid>
        </Grid> : <><Grid
          style={{
            height      : "75vh",
            width       : "-webkit-fill-available",
            paddingRight: "2vw"
          }}
          container
          direction="row"
          justifyContent="center"
          alignItems="center">
          <Grid
            container
            style={{
              position    : "relative",
              height      : "75vh",
              borderRadius: "10px"
            }}>
            <Grid item xs={12} id={styles.maingrid} ref={gridref}>
              <Box
                sx={{
                  display       : "flex",
                  justifyContent: "space-between",
                  width         : "92%"
                }}>
                <Typography
                  sx={{
                    display      : "inline-flex",
                    alignSelf    : "center",
                    fontWeight   : "900",
                    color        : "#393939",
                    textTransform: "capitalize"
                  }}>
                  Persona named {persona && persona.name}
                </Typography>
                <Box sx={{ display: "flex" }}>
                  {pathname.includes("gtm") &&
                    <Typography
                      sx={{
                        color         : "#071AFF",
                        textDecoration: "underline",
                        marginRight   : "1vw"
                      }}>
                      <Button
                        onClick={() => {

                          history.push("/gotomarketcanvas");
                        
                        }}
                        sx={{
                          textTransform: "unset",
                          fontWeight   : "bold",
                          color        : "#071AFF"
                        }}>
                        GTM Canvas
                      </Button>
                    </Typography>
                  }
                  {venture.userRole === "Commenter" ?
                    <Box display="flex" id="personasummarydownload">
                      <Button
                        variant="outlined"
                        size="small"
                        // id={styles.personadownloadbtn}
                        sx={{
                          backgroundColor:
                            venture.userRole === "Commenter"
                              ? "#b0b0b0"
                              : "#f8f9ff",
                          boxShadow: "0px 3px 6px #00000029",
                          border:
                            venture.userRole === "Commenter"
                              ? "none"
                              : "1px solid #071aff",
                          borderRadius: "6px",
                          color:
                            venture.userRole === "Commenter"
                              ? "#b0b0b0"
                              : "#071aff",
                          width : "130px",
                          height: "35px"
                        }}
                        disabled={
                          venture.userRole === "Commenter" || isDownloading
                        }>
                        Download
                      </Button>
                    </Box>
                    :
                    <Box display="flex" id="personasummarydownload">
                      <Button
                        variant="outlined"
                        size="small"
                        // id={styles.personadownloadbtn}
                        onClick={downloadPersonaSummary}
                        sx={{
                          backgroundColor:
                            venture.userRole === "Commenter"
                              ? "#b0b0b0"
                              : "#f8f9ff",
                          boxShadow: "0px 3px 6px #00000029",
                          border:
                            venture.userRole === "Commenter"
                              ? "none"
                              : "1px solid #071aff",
                          borderRadius: "6px",
                          color:
                            venture.userRole === "Commenter"
                              ? "#b0b0b0"
                              : "#071aff",
                          width : "130px",
                          height: "35px"
                        }}
                        disabled={
                          venture.userRole === "Commenter" || isDownloading
                        }>
                        Download
                      </Button>
                    </Box>
                  }
                </Box>
              </Box>
              <Typography
                variant="subtitle2"
                sx={{ color: "#071AFF", cursor: "pointer" }}
                component="span"
                onClick={() => {

                  pathname.includes("gtm")
                    ? history.push("/gtmsegmentspersonas")
                    : history.push("/segmentspersonas");
                
                }}>
                &lt; List of Segments and Personas
              </Typography>
              <Grid container columnGap={4}>
                <Grid
                  item
                  md={3}
                  sx={{
                    background: "#ffffff 0% 0% no-repeat padding-box",
                    border    : "1px solid #f0f0f0"
                  }}>
                  <Box id={styles.detailsbox}>
                    <Box sx={{ position: "relative" }}>
                      {venture.userRole !== "Commenter" &&
                        <img
                          src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/pen-icon.png`}
                          alt="pen-icon"
                          style={{
                            position: "absolute",
                            right   : "60px",
                            width   : "15px",
                            height  : "15px",
                            cursor  : "pointer"
                          }}
                          onClick={() => {

                            updatePersonaDetails();
                            setUpdateDialog(true);
                          
                          }}
                        />
                      }
                      <img
                        src={`${process.env.REACT_APP_AWS_ASSETS_URL}${persona && persona.avatar
                        }`}
                        width="90px"
                        height="120px"
                        alt=""
                      />
                      <Dialog
                        open={updatedialog}
                        onClick={() => {

                          setUpdateDialog(false);
                        
                        }}
                        keepMounted
                        PaperProps={{
                          style: {
                            borderRadius   : "20px",
                            backdropFilter : "blur(10px)",
                            backgroundColor: "rgba(255,255,255,0.80)",
                            margin         : "20px"
                          }
                        }}
                        maxWidth="md"
                        fullWidth>
                        <DialogContent>
                          <Grid container sx={{ textAlign: "center" }}>
                            {avatars &&
                              avatars.map(avatar => {

                                return (
                                  <Grid
                                    // key={index}
                                    item md={3}>
                                    <img
                                      src={`${process.env.REACT_APP_AWS_ASSETS_URL}${avatar}`}
                                      id={styles.groupimgs}
                                      alt=""
                                      onClick={async () => {

                                        await updatePersona(personaid, {
                                          ...persona,
                                          "avatar": avatar
                                        });
                                        setPersona(values => ({
                                          ...values,
                                          "avatar": avatar
                                        }));
                                        setUpdateDialog(false);
                                      
                                      }}
                                    />
                                  </Grid>
                                );
                              
                              })}
                          </Grid>
                        </DialogContent>
                      </Dialog>
                    </Box>
                    <Typography id={styles.detailstypography1}>Name</Typography>
                    {isupdate === "name" ?
                      <TextField
                        variant="standard"
                        size="small"
                        value={persona.name}
                        onChange={e => {

                          setPersona(values => ({
                            ...values,
                            "name": e.target.value
                          }));
                        
                        }}
                        onKeyDown={e => {

                          if (e.key === "Enter") {

                            e.preventDefault();
                            updatePersonaDetails();
                          
                          }
                        
                        }}
                        onBlur={() => {

                          updatePersonaDetails();
                        
                        }}
                        sx={{
                          "& .MuiInputBase-input": {
                            textAlign: "center"
                          }
                        }}
                      />
                      :
                      <Typography
                        sx={{ fontSize: "14px", textTransform: "capitalize" }}
                        onClick={() => {

                          if (venture.userRole !== "Commenter") {

                            setIsupdate("name");
                          
                          }
                        
                        }}>
                        {persona?.name}
                      </Typography>
                    }
                  </Box>
                  <Box id={styles.detailsbox}>
                    <Typography id={styles.detailstypography1}>
                      Segment Name
                    </Typography>
                    <Typography id={styles.mobileViewFontSize}>
                      {persona?.parentSegment?.name}
                    </Typography>
                  </Box>
                  <Box id={styles.detailsbox}>
                    <Typography id={styles.detailstypography1}>Type</Typography>
                    <Typography id={styles.mobileViewFontSize}>
                      {persona.type}
                    </Typography>
                  </Box>
                  <Box id={styles.detailsbox}>
                    <Typography id={styles.detailstypography1}>
                      Age range
                    </Typography>
                    <Typography id={styles.mobileViewFontSize}>
                      {persona.demographics.age}
                    </Typography>
                  </Box>
                  <Box id={styles.detailsbox}>
                    <Typography id={styles.detailstypography1}>
                      Highest level of Education
                    </Typography>
                    <Typography id={styles.mobileViewFontSize}>
                      {persona.demographics.qualification}
                    </Typography>
                  </Box>
                  <Box id={styles.detailsbox}>
                    <Typography id={styles.detailstypography1}>
                      Occupation
                    </Typography>
                    <Typography id={styles.mobileViewFontSize}>
                      {persona.demographics.occupation}
                    </Typography>
                  </Box>
                  <Box id={styles.detailsbox}>
                    <Typography id={styles.detailstypography1}>
                      Industry
                    </Typography>
                    <Typography id={styles.mobileViewFontSize}>
                      {persona.demographics.industry}
                    </Typography>
                  </Box>
                  <Box id={styles.detailsbox}>
                    <Typography id={styles.detailstypography1}>
                      Geography
                    </Typography>
                    {isupdate === "geographics" ?
                      <Box sx={{ marginTop: "10px" }}>
                        <Box style={{ marginBottom: "10px" }}>
                          <CustomSelect
                            placeholder="Select Location"
                            options={[
                              { value: "", label: "-None-" },
                              ...updatedCountries
                            ]}
                            value={persona.geographics.location}
                            handleChange={e => {

                              setPersona(values => ({
                                ...values,
                                "geographics": {
                                  ...values.geographics,
                                  "location": e.value,
                                  "isoCode" : e.isoCode,
                                  "state"   : ""
                                }
                              }));
                            
                            }}
                            onBlur={() => {

                              updatePersonaDetails();
                            
                            }}
                            maxMenuHeight={150}
                          />
                        </Box>
                        <span>
                          <CustomSelect
                            placeholder="Select state/region"
                            value={persona.geographics.state}
                            handleChange={e => {

                              setPersona(values => ({
                                ...values,
                                "geographics": {
                                  ...values.geographics,
                                  "state": e.value
                                }
                              }));
                            
                            }}
                            onBlur={() => {

                              updatePersonaDetails();
                            
                            }}
                            options={updatedStates(
                              persona.geographics.isoCode
                                ? persona.geographics.isoCode
                                : null
                            )}
                            noOptionsMessage={() => "Not Available"}
                            maxMenuHeight={150}
                          />
                        </span>
                      </Box>
                      : persona.geographics.location &&
                      persona.geographics.state ?
                        <Typography
                          id={styles.mobileViewFontSize}
                          onClick={() => {

                            if (venture.userRole !== "Commenter") {

                              setIsupdate("geographics");
                            
                            }
                          
                          }}>
                          {persona.geographics.location},{" "}
                          {persona.geographics.state}
                        </Typography>
                        : persona.geographics.location &&
                      persona.geographics.state === "" ?
                          <Typography
                            id={styles.mobileViewFontSize}
                            onClick={() => {

                              if (venture.userRole !== "Commenter") {

                                setIsupdate("geographics");
                              
                              }
                            
                            }}>
                            {persona.geographics.location}
                          </Typography>
                          :
                          <Typography
                            id={styles.mobileViewFontSize}
                            onClick={() => {

                              if (venture.userRole !== "Commenter") {

                                setIsupdate("geographics");
                              
                              }
                            
                            }}>
                        None
                          </Typography>
                    }
                  </Box>
                  <Box id={styles.detailsbox}>
                    <Typography id={styles.detailstypography1}>
                      Social Media
                    </Typography>
                    <Grid container mt={2} justifyContent="space-evenly">
                      {persona.individualCharacteristics.socialMediaActiveness?.map(
                        (socialmedia, index) => {

                          if (socialmedia.ispresent) {

                            return (
                              <img
                                // key={index}
                                src={socialmedia.icon}
                                alt={index}
                                style={{ width: "30px", height: "30px" }}
                              />
                            );
                          
                          }
                        
                        }
                      )}
                    </Grid>
                  </Box>
                </Grid>
                <Grid item md={8}>
                  {hashvalue ?
                    hashvalue === "demographics" ?
                      <EditDemographics
                        personaid={personaid}
                        gridScrollUp={gridScrollUp}
                      />
                      : hashvalue === "psychographics" ?
                        <EditPsychographics
                          personaid={personaid}
                          gridScrollUp={gridScrollUp}
                        />
                        : hashvalue === "buyingbehaviour" ?
                          <EditBuyingBehaviour
                            personaid={personaid}
                            gridScrollUp={gridScrollUp}
                          />
                          : hashvalue === "individualcharacteristics" ?
                            <EditIndividualCharacteristics
                              personaid={personaid}
                              gridScrollUp={gridScrollUp}
                            />
                            : hashvalue === "problem" ?
                              <Grid
                                id={styles.individualgrid}
                                component="div"
                              >
                                <Box display="flex" justifyContent="flex-end">
                                  <IconButton
                                    component="span"
                                    onClick={() => {

                                      history.push(
                                        pathname.includes("gtm")
                                          ? `/gtmuserpersonadetails/${personaid}`
                                          : `/userpersonadetails/${personaid}`
                                      );
                                    
                                    }}>
                                    <HighlightOffIcon />
                                  </IconButton>
                                </Box>
                                <Typography id={styles.individualtitle} gutterBottom>
                          Problem
                                </Typography>
                                <Typography sx={{ height: "100vh" }}>
                                  {problemorneed}
                                </Typography>
                              </Grid>
                              : hashvalue === "solution" ?
                                <Grid
                                  id={styles.individualgrid}
                                  component="div"
                                  // onClick={() => {
                                  //   history.push(`/userpersonadetails/${personaid}`);
                                  // }}
                                >
                                  <Box display="flex" justifyContent="flex-end">
                                    <IconButton
                                      component="span"
                                      onClick={() => {

                                        history.push(
                                          pathname.includes("gtm")
                                            ? `/gtmuserpersonadetails/${personaid}`
                                            : `/userpersonadetails/${personaid}`
                                        );

                                        // history.push(`/userpersonadetails/${personaid}`);
                                      
                                      }}>
                                      <HighlightOffIcon />
                                    </IconButton>
                                  </Box>
                                  <Typography id={styles.individualtitle} gutterBottom>
                          Solution
                                  </Typography>
                                  <Typography sx={{ height: "100vh" }}>
                                    {solutioncapability}
                                  </Typography>
                                </Grid>
                                :
                                <></>
                    
                    :
                    <Grid container justifyContent="space-around" rowGap={2}>
                      <Grid item md={5} id={styles.detailsgriditem1} p={2}>
                        <Typography id={styles.individualheading} gutterBottom>
                          Demographics
                        </Typography>
                        <Box mb={1}>
                          <Typography id={styles.individualquestion} gutterBottom>
                            Ethnicity
                          </Typography>
                          <Typography id={styles.individualanswer} gutterBottom>
                            {persona.demographics.ethnicity}
                          </Typography>
                        </Box>
                        <Box mb={1}>
                          <Typography id={styles.individualquestion} gutterBottom>
                            Highest Level of Education
                          </Typography>
                          <Typography id={styles.individualanswer} gutterBottom>
                            {persona.demographics.qualification}
                          </Typography>
                        </Box>
                        <Box mb={1}>
                          <Typography id={styles.individualquestion} gutterBottom>
                            Income Level
                          </Typography>
                          <Typography id={styles.individualanswer} gutterBottom>
                            {persona.demographics.income}
                          </Typography>
                        </Box>
                        <img
                          src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/full-screen.png`}
                          id={styles.individualexpand}
                          alt=""
                          onClick={() => {

                            history.push({ hash: "demographics" });
                            gridScrollUp();
                          
                          }}
                        />
                      </Grid>
                      <Grid item md={5} id={styles.detailsgriditem1} p={2}>
                        <Typography id={styles.individualheading} gutterBottom>
                          Psychographics
                        </Typography>
                        <Box mb={1}>
                          <Typography id={styles.individualquestion} gutterBottom>
                            Personality Traits
                          </Typography>
                          <Typography id={styles.individualanswer} gutterBottom>
                            {persona.psychographics.personalityTraits}
                          </Typography>
                        </Box>
                        <Box mb={1}>
                          <Typography id={styles.individualquestion} gutterBottom>
                            Opinions/Beliefs/Attitude
                          </Typography>
                          <Typography id={styles.individualanswer} gutterBottom>
                            {persona.psychographics.opinionsOrBeliefs}
                          </Typography>
                        </Box>
                        <Box mb={1}>
                          <Typography id={styles.individualquestion} gutterBottom>
                            Life Goals
                          </Typography>
                          <Typography id={styles.individualanswer} gutterBottom>
                            {persona.psychographics.inspiresOrLifeGoals}
                          </Typography>
                        </Box>
                        <img
                          src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/full-screen.png`}
                          id={styles.individualexpand}
                          alt=""
                          onClick={() => {

                            history.push({ hash: "psychographics" });
                            gridScrollUp();
                          
                          }}
                        />
                      </Grid>
                      <Grid item md={5} id={styles.detailsgriditem1} p={2}>
                        <Typography id={styles.individualheading} gutterBottom>
                          Buying Behaviour
                        </Typography>
                        <Box mb={1}>
                          <Typography id={styles.individualquestion} gutterBottom>
                            When & Why
                          </Typography>
                          <Typography id={styles.individualanswer} gutterBottom>
                            {persona.buyingBehaviour.buySolution}
                          </Typography>
                        </Box>
                        <Box mb={1}>
                          <Typography id={styles.individualquestion} gutterBottom>
                            How much & How often
                          </Typography>
                          <Typography id={styles.individualanswer} gutterBottom>
                            {persona.buyingBehaviour.oftenBuy}
                          </Typography>
                        </Box>
                        <Box mb={1}>
                          <Typography id={styles.individualquestion} gutterBottom>
                            Brand Loyalty
                          </Typography>
                          <Typography id={styles.individualanswer} gutterBottom>
                            {persona.buyingBehaviour.loyal}
                          </Typography>
                        </Box>
                        <Box mb={1}>
                          <Typography id={styles.individualquestion} gutterBottom>
                            Influences
                          </Typography>
                          <Typography id={styles.individualanswer} gutterBottom>
                            {persona.buyingBehaviour.influences}
                          </Typography>
                        </Box>
                        <img
                          src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/full-screen.png`}
                          id={styles.individualexpand}
                          alt=""
                          onClick={() => {

                            history.push({ hash: "buyingbehaviour" });
                            gridScrollUp();
                          
                          }}
                        />
                      </Grid>
                      <Grid item md={5} id={styles.detailsgriditem1} p={2}>
                        <Typography id={styles.individualheading} gutterBottom>
                          Individual Characteristics
                        </Typography>
                        <Box mb={1}>
                          <Typography id={styles.individualquestion} gutterBottom>
                            Outcome
                          </Typography>
                          <Typography id={styles.individualanswer} gutterBottom>
                            {persona.individualCharacteristics.outcomes}
                          </Typography>
                        </Box>
                        <Box mb={1}>
                          <Typography id={styles.individualquestion} gutterBottom>
                            Goals and Objectives
                          </Typography>
                          <Typography id={styles.individualanswer} gutterBottom>
                            {persona.individualCharacteristics.goalsOrObjectives}
                          </Typography>
                        </Box>
                        <Box mb={1}>
                          <Typography id={styles.individualquestion} gutterBottom>
                            Motivations
                          </Typography>
                          <Typography id={styles.individualanswer} gutterBottom>
                            {persona.individualCharacteristics.motivations}
                          </Typography>
                        </Box>
                        <Box mb={1}>
                          <Typography id={styles.individualquestion} gutterBottom>
                            Decisions
                          </Typography>
                          <Typography id={styles.individualanswer} gutterBottom>
                            {persona.individualCharacteristics.decisions}
                          </Typography>
                        </Box>
                        <img
                          src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/full-screen.png`}
                          id={styles.individualexpand}
                          alt=""
                          onClick={() => {

                            history.push({ hash: "individualcharacteristics" });
                            gridScrollUp();
                          
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        md={5}
                        // id={styles.detailsgriditem1}
                        p={1}
                        sx={{
                          height         : "40vh",
                          backgroundColor: problemorneed
                            ? "#ffffff"
                            : "#cccccc !important",
                          border: problemorneed
                            ? "1px solid #f0f0f0"
                            : "1px solid #B1AAAA !important",
                          display      : "flex",
                          flexDirection: "column"
                        }}>
                        <Box
                          id={styles.problemSolutionbox}
                          ref={problemSolutionRef}>
                          <Typography fontWeight="bold" gutterBottom>
                            Problem
                          </Typography>
                          <Typography
                            sx={{ fontSize: "12px" }}
                            className={classes.multiLineEllipsis}
                            ref={problemRef}>
                            {problemorneed}
                          </Typography>
                          {problemViewmore &&
                            <Typography
                              sx={{
                                fontSize: "12px",
                                color   : "#071AFF",
                                cursor  : "pointer"
                              }}
                              onClick={() => {

                                if (problemorneed) {

                                  history.push({ hash: "problem" });
                                  gridScrollUp();
                                
                                }
                              
                              }}>
                              View more...
                            </Typography>
                          }
                        </Box>
                        <img
                          src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/full-screen.png`}
                          id={styles.individualexpand}
                          alt=""
                          onClick={() => {

                            if (problemorneed) {

                              history.push({ hash: "problem" });
                              gridScrollUp();
                            
                            }
                          
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        md={5}
                        p={1}
                        sx={{
                          height         : "40vh",
                          backgroundColor: solutioncapability
                            ? "#ffffff"
                            : "#cccccc !important",
                          border: solutioncapability
                            ? "1px solid #f0f0f0"
                            : "1px solid #B1AAAA !important",
                          display      : "flex",
                          flexDirection: "column"
                        }}>
                        <Box
                          id={styles.problemSolutionbox}
                          ref={problemSolutionRef}>
                          <Typography fontWeight="bold" gutterBottom>
                            Solution
                          </Typography>
                          <Typography
                            sx={{ fontSize: "12px" }}
                            className={classes.multiLineEllipsis}
                            ref={solutionRef}>
                            {solutioncapability}
                          </Typography>
                          {solutionViewmore &&
                            <Typography
                              sx={{
                                fontSize: "12px",
                                color   : "#071AFF",
                                cursor  : "pointer"
                              }}
                              onClick={() => {

                                if (solutioncapability) {

                                  history.push({ hash: "solution" });
                                  gridScrollUp();
                                
                                }
                              
                              }}>
                              View more...
                            </Typography>
                          }
                        </Box>
                        <img
                          src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/full-screen.png`}
                          id={styles.individualexpand}
                          alt=""
                          onClick={() => {

                            if (solutioncapability) {

                              history.push({ hash: "solution" });
                              gridScrollUp();
                            
                            }
                          
                          }}
                        />
                      </Grid>
                    </Grid>
                  }
                </Grid>
              </Grid>
              {!hashvalue &&

                <NextButton
                  restrictedNextClick={() => {

                    history.push(
                      pathname.includes("gtm")
                        ? "/gtmsegmentspersonas"
                        : "/segmentspersonas"
                    );
                  
                  }}
                  nextClick={() =>
                    history.push(
                      pathname.includes("gtm")
                        ? "/gtmsegmentspersonas"
                        : "/segmentspersonas"
                    )
                  }
                />
              }
            </Grid>
          </Grid>
        </Grid></>
      }

    </div>
  );

};

export default UserPersonaDetails;
