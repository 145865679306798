import { Box, Button, Typography } from "@mui/material";
import React from "react";

const DeleteCancel = props => {

  return (
    <Box sx={ { textAlign: "center" } }>
      <Typography sx={ { color: "#071AFF", marginBottom: "20px" } }>
        { props.name }
      </Typography>
      <Button
        variant="contained"
        size="medium"
        disableElevation
        sx={ {
          backgroundColor: "#071AFF",
          marginLeft     : "10px",
          borderColor    : "#071AFF",
          textTransform  : "none",
          "&:hover"      : {
            backgroundColor: "#071AFF",
            borderColor    : "#071AFF"
          }
        } }
        onClick={ props.deleteClick }
      >
        Delete
      </Button>
      <Button
        variant="outlined"
        size="medium"
        disableElevation
        sx={ {
          backgroundColor: "#EBF7FF",
          borderColor    : "#071AFF",
          color          : "#071AFF",
          marginLeft     : "10px",
          textTransform  : "none",
          "&:hover"      : {
            backgroundColor: "#EBF7FF",
            borderColor    : "#071AFF"
          }
        } }
        onClick={ props.cancelClick }
      >
        Cancel
      </Button>
      <Typography
        sx={ { color: "#071AFF", fontSize: "12px", marginTop: "20px" } }
      >
        You won’t be able to undo this change once you click delete.
      </Typography>
    </Box>
  );

};

export default DeleteCancel;
