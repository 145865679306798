import { Box } from "@mui/system";
import React from "react";

const BoxWithoutIcon = props => {

  return (
    <div style={ { height: "100%" } }>
      <Box
        sx={ {
          height         : "81vh",
          backgroundColor: "#ffffff",
          margin         : props.marginRight ? `40px ${props.marginRight}` : "5vh 4vh 5vh",
          borderRadius   : "5px",
          overflowY      : "auto"
        } }
      >
        { props.children }
      </Box>
    </div>
  );

};

export default BoxWithoutIcon;
