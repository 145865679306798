import {
  MenuItem, Select
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
// import Select, { createFilter } from "react-select";

export default function CustomMUISelect (props) {

  const { venture } = useSelector(state => ({ ...state }));

  return (
    <>
      {/* <FormControl sx={{ m: 1 }} variant="outlined"> */ }
      <Select
        fullWidth
        variant="standard"
        disableUnderline
        disabled={ props.disabled || venture.userRole === "Commenter" }
        value={ props.value }
        onChange={ props.handleChange }
        placeholder={ props.placeholder }
        noOptionsMessage={ props.noOptionsMessage }
        onBlur={ props.onBlur }
        name={ props.name }
        sx={ {
          "& .MuiInputBase-input": {
            //   "&:hover": {
            //     // border: "1px solid black",
            //   },
            borderRadius: 1,
            position    : "relative",

            border: props.border,

            fontSize: props.fontSize,
            width   : props.width,

            padding: props.padding
          }
        } }
      >
        { props.muiOptions.map(option => {

          return (
            <MenuItem
              sx={ { fontSize: props.optionFontSize } }
              value={ option.value }
            >
              { option.value }
            </MenuItem>
          );
        
        }) }
      </Select>
      {/* </FormControl> */ }
    </>
  );

}
