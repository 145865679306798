import React, { useEffect, useState } from "react";
import {
  Box,
  Button, Dialog,
  DialogContent, FormControl, Grid, IconButton, Menu, MenuItem, Select, Tooltip, Typography
} from "@mui/material";
import { useSelector } from "react-redux";

import { styled } from "@mui/material/styles";

import InputBase from "@mui/material/InputBase";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  deleteCollaboratorAccess,
  inviteCollaborator,
  updateCollaboratorAccess
} from "../../../services/auth";
import styles from "./SingleMember.module.css";

const BootstrapInputSelect2 = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3)
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position    : "relative",
    fontSize    : "14px",
    width       : "8vw",
    padding     : "5px 0px 0px 12px",
    transition  : theme.transitions.create([ "border-color", "box-shadow" ]),
    height      : "30px",
    border      : "1px solid #E1E2E9"
    // textAlign: "center",
  }
}));

export default function SingleMember(props) {

  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ deletedialogbox, setDeleteDialog ] = useState(false);
  const [ updateCollaboratordialogbox, setUpdateCollaboratorDialog ] =
    useState(false);
  const [ sending, setSending ] = useState(false);
  const [ collabRole, setCollabRole ] = useState("");

  const { user, venture } = useSelector(state => ({ ...state }));

  const open = Boolean(anchorEl);

  const mentorToolTipData = "As a mentor, user will have access to view the business plan data with ability to post comments to support advisory and coaching";
  const coFounderToolTipData = "As a Cofounder, user will have the editable access to you business plan to support cocreation and contribution to business planning process for your start-up";

  const handleClick = event => {

    setAnchorEl(event.currentTarget);
  
  };

  const handleClose = () => {

    setAnchorEl(null);
  
  };

  const deleteDialogopen = () => {

    setDeleteDialog(true);
  
  };
  const deleteDialogclose = () => {

    setDeleteDialog(false);
  
  };

  const updateCollaboratorDialogopen = () => {

    setUpdateCollaboratorDialog(true);
  
  };
  const updateCollaboratorDialogclose = () => {

    setUpdateCollaboratorDialog(false);
  
  };

  const sharedUser = props.sharedUser;
  const collaboratorRole = props.collaboratorRole;

  useEffect(() => {

    setCollabRole(collaboratorRole);
  
  }, []);

  const collaboratorInvite = async () => {

    try {

      setSending(true);
      await inviteCollaborator(
        sharedUser.email,
        venture._id,
        collabRole,
        user._id
      );
      setSending(false);
      props.setSuccessmsg("Invite sent");
      setTimeout(() => {

        props.setSuccessmsg("");
      
      }, 5000);
    
    } catch (error) {

      console.error(error);
      setSending(false);
    
    }
  
  };

  const changeCollaborator = async isSendmail => {

    try {

      await updateCollaboratorAccess(
        "collaboratorRole",
        collabRole,
        venture._id,
        sharedUser._id,
        isSendmail
      );
      props.getVenture();
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const deleteCollaborator = async isSendmail => {

    try {

      await deleteCollaboratorAccess(
        venture._id,
        sharedUser._id,
        user._id,
        isSendmail
      );
      props.getVenture();
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const handleRoleChange = e => {

    setCollabRole(e.target.value);
    updateCollaboratorDialogopen();

  };

  return (
    <Grid container id={styles.mainGrid}>
      <Grid item md={1} id={styles.individualGriditem}>
        {sharedUser.profileIcon ?
          <img src={sharedUser.profileIcon} id={styles.imageBox} />
          :
          // <img
          //   src={`${process.env.REACT_APP_AWS_ASSETS_URL}/images/user-profile.png`}
          //   id={styles.imageBox}
          //   alt=""
          // />
          <AccountCircleIcon sx={{ fontSize: "50px", color: "#5F5E5E" }} />
        }
      </Grid>

      <Grid item md={4}>
        <Typography id={styles.nameTypo}>
          {sharedUser.firstname} {sharedUser.lastname}
        </Typography>
        <Typography id={styles.emailTypo}>{sharedUser.email}</Typography>
      </Grid>
      {/* email box */}
      {/* <Box sx={{ display: "flex", justifyContent: "space-evenly" }}> */}
      {/* select box */}
      <Grid
        item
        md={7}
        sx={{ display: "flex", justifyContent: "space-evenly" }}
      >
        <Grid item id={styles.individualGriditem}>
          <FormControl variant="standard">
            <Tooltip placement="right-start" title={collaboratorRole === "Editor" ? coFounderToolTipData : mentorToolTipData}>

              <Select
                value={collaboratorRole}
                input={<BootstrapInputSelect2 />}
                onChange={e => handleRoleChange(e)}
              >
                <MenuItem id={styles.menuItemTypo} value={"Commenter"}>mentor</MenuItem>
                <MenuItem id={styles.menuItemTypo} value={"Editor"}>Cofounder</MenuItem>
              </Select>

            </Tooltip>
          </FormControl>
          <Dialog
            open={updateCollaboratordialogbox}
            onClose={updateCollaboratorDialogclose}
            PaperProps={{
              style: {
                borderRadius  : "13px",
                backdropFilter: "blur(45px)",
                background    : "#FFFFFF 0% 0% no-repeat padding-box",
                boxShadow     : "0px 3px 6px #00000029",
                border        : "1px solid #071AFF45"
              }
            }}
          >
            <DialogContent>
              <Typography
                sx={{
                  fontSize : "14px",
                  color    : "#5F5E5E",
                  textAlign: "center"
                }}
              >
                You have changed this user’s role. Do you want to notify them
                via email?
              </Typography>

              <Box
                sx={{
                  display       : "flex",
                  justifyContent: "space-evenly",
                  marginTop     : "25px"
                }}
              >
                <Button
                  variant="outlined"
                  sx={{ textTransform: "none" }}
                  style={{ fontSize: "14px" }}
                  onClick={() => {

                    changeCollaborator(false);
                    updateCollaboratorDialogclose();
                  
                  }}
                >
                  Don't Send
                </Button>
                <Button
                  variant="contained"
                  sx={{ textTransform: "none" }}
                  style={{ fontSize: "14px" }}
                  onClick={() => {

                    changeCollaborator(true);
                    updateCollaboratorDialogclose();
                  
                  }}
                >
                  Send Email
                </Button>
              </Box>
            </DialogContent>
          </Dialog>
        </Grid>
        {/* resend invite */}
        {/* <Grid
        item
        md={5}
        sx={{ display: "flex", justifyContent: "space-evenly", border: 1 }}
      > */}
        <Grid
          item
          sx={{
            display       : "flex",
            justifyContent: "center",
            alignItems    : "center",
            marginLeft    : "10px"
          }}
        >
          <Typography
            id={styles.resendTypo}
            onClick={() => {

              if (!sending) collaboratorInvite();
            
            }}
          >
            resend invite
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            display       : "flex",
            justifyContent: "center",
            // width: "30%",
            // border: 1,
            alignItems    : "center",
            marginLeft    : "10px"
          }}
        // id={styles.individualGriditem}
        >
          {props.isAccepted ?
            <Box
              sx={{
                // border: 1,
                // width: "90px",
                width          : "67px",
                // height: "25px",
                height         : "19px",
                display        : "flex",
                justifyContent : "center",
                alignItems     : "center",
                borderRadius   : "4px",
                backgroundColor: "rgba(10, 118, 85,.1)"
                // backgroundColor: "#43e0b0",
                // backgroundColor: "#F0F0F0",
              }}
            >
              <Typography
                sx={{ fontSize: "12px", color: "#0A7655", fontWeight: "bold" }}
              >
                active
              </Typography>
            </Box>
            :
            <Box
              sx={{
                // border: 1,
                // width: "90px",
                width          : "67px",
                // height: "25px",
                height         : "19px",
                display        : "flex",
                justifyContent : "center",
                alignItems     : "center",
                borderRadius   : "4px",
                backgroundColor: "#E1E2E9"
              }}
            >
              <Typography
                sx={{ fontSize: "12px", color: "#868686", fontWeight: "bold" }}
              >
                pending
              </Typography>
            </Box>
          }
          {/* pending */}
        </Grid>
        {/* MoreVertIcon */}
        <Grid item id={styles.individualGriditem}>
          <IconButton
            onClick={e => {

              handleClick(e);
            
            }}
          // sx={{ marginLeft: "3%" }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={() => {

              handleClose();
            
            }}
            anchorOrigin={{
              vertical  : "top",
              horizontal: "right"
            }}
            style={{ top: 4 }}
            sx={{
              "& .MuiMenu-list": {
                padding        : "0px",
                backgroundColor: "#707070",
                color          : "white"
              }
            }}
          // transformOrigin={{
          //   vertical: 'top',
          //   horizontal: 'start',
          // }}
          >
            <MenuItem
              sx={{ fontSize: "12px" }}
              onClick={e => {

                e.stopPropagation();
                deleteDialogopen();
                handleClose();
              
              }}
            >
              Delete
            </MenuItem>
          </Menu>
          <Dialog
            open={deletedialogbox}
            onClose={deleteDialogclose}
            PaperProps={{
              style: {
                borderRadius  : "13px",
                backdropFilter: "blur(45px)",
                background    : "#FFFFFF 0% 0% no-repeat padding-box",
                boxShadow     : "0px 3px 6px #00000029",
                border        : "1px solid #071AFF45"
              }
            }}
          >
            <DialogContent>
              <Typography
                sx={{
                  fontSize : "14px",
                  color    : "#5F5E5E",
                  textAlign: "center"
                }}
              >
                The user will not be able to access the project
                <br /> any longer. Would you like to send a notification ?
              </Typography>

              <Box
                sx={{
                  display       : "flex",
                  justifyContent: "space-evenly",
                  marginTop     : "25px"
                }}
              >
                <Button
                  variant="outlined"
                  sx={{ textTransform: "none" }}
                  style={{ fontSize: "14px" }}
                  onClick={() => {

                    deleteCollaborator(false);
                    deleteDialogclose();
                  
                  }}
                >
                  Don't Send
                </Button>
                <Button
                  variant="contained"
                  sx={{ textTransform: "none" }}
                  style={{ fontSize: "14px" }}
                  onClick={() => {

                    deleteCollaborator(true);
                    deleteDialogclose();
                  
                  }}
                >
                  Send Email
                </Button>
              </Box>
            </DialogContent>
          </Dialog>
        </Grid>
      </Grid>
    </Grid>
  );

}
