import axios from "./axiosService";

// import axios from "axios";

export const getVetureById = async ventureid => {

  return await axios.post(`${process.env.REACT_APP_ADDRESS}/get_venture`, {
    ventureid
  });

};

export const createVenture = async formdata => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/create_venture`,
    formdata
  );

};

export const editVenture = async (formdata, ventureid) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/edit_venture/${ventureid}`,
    formdata
  );

};

export const createConceptVisionDetails = async (details, ventureid) => {

  return await axios.post(`${process.env.REACT_APP_ADDRESS}/concept_vision`, {
    details,
    ventureid
  });

};

export const listUserVentures = async userid => {

  return await axios.post(`${process.env.REACT_APP_ADDRESS}/user_ventures`, {
    userid
  });

};

export const updatePercent = async (
  ventureid,
  modulename,
  sectionname,
  percentvalue
) => {

  return await axios.post(`${process.env.REACT_APP_ADDRESS}/update_percent`, {
    ventureid,
    modulename,
    sectionname,
    percentvalue
  });

};

export const deleteVenture = async (ventureid, userid, venturename) => {

  return await axios.post(`${process.env.REACT_APP_ADDRESS}/delete_venture`, {
    ventureid,
    userid,
    venturename
  });

};
