/* eslint-disable camelcase */
import { Box, Typography } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
// import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Dot from "./DotComponent";

// const useStyles = makeStyles({
//   root: {
//     overflow    : "auto",
//     background  : "#F8F9FF",
//     borderRadius: "10px",
//     width       : "max-content",
//     boxShadow   : "0px 0px 6px #00000029",
//     height      : "82vh",
//     opacity     : "1"
//   },
//   title_text: {
//     fontWeight: "600",
//     color     : "dimgray",
//     fontSize  : "14px",
//     margin    : "0px 8px",
//     width     : "200px"
//   },
//   inner_text: {
//     color   : "dimgray",
//     fontSize: "14px"
//   }
// });

const LightTooltip = styled(({ className, ...props }) =>
  <Tooltip {...props} classes={{ popper: className }} />
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    // color: "rgba(0, 0, 0, 0.87)",
    boxShadow      : theme.shadows[1],
    fontSize       : "12px",
    color          : "#FF7F00",
    border         : "1px solid #FF7F00",
    paddingTop     : "5px",
    borderRadius   : "10px",
    fontWeight     : "bold"
  }
}));

function CardBox(props) {

  const data = props.data;
  const [ collapse, setCollapse ] = useState(true);
  // const classes = useStyles();
  const history = useHistory();
  const pathname = history.location.pathname.substring(1);

  let { user } = useSelector(state => {

    return { ...state };
  
  });

  const findRestrictedModules = access => {

    switch (access) {

    case "freetier":
      return [
        "Market",
        "Solution",
        "Monetisation",
        "Financials",
        "Operations",
        "Go-To-Market",
        "Pitch Deck"
      ];
    case "ideation":
      return [
        "Monetisation", "Financials", "Go-To-Market"
      ];
    case "admin":
      return [];
    case "pitch":
      return [
        "Market",
        "Solution",
        "Monetisation",
        "Financials",
        "Operations",
        "Go-To-Market"
      ];
    case "pro":
      return [];
    default:
      return [];
    
    }
  
  };

  let restrictedModules = findRestrictedModules(user.access);

  
  return (
    <>
      {restrictedModules.includes(data.title) ?
        <>
          <Typography
            // className={classes.title_text}
            sx={{
              cursor    : "default", fontWeight: "600",
              color     : "dimgray",
              fontSize  : "14px",
              margin    : "0px 8px",
              width     : "200px"
            }}>
            <LightTooltip placement="right-start" title="Upgrade to Pro">
              {/* <LightTooltip placement="right-start" title="Coming soon"> */}
              <span>{data.title}</span>
            </LightTooltip>
            <IconButton
              style={{ fontSize: "initial" }}
              onClick={e => {

                e.stopPropagation();
                setCollapse(!collapse);
              
              }}
            >
              {collapse ?
                <KeyboardArrowDownIcon
                  style={{
                    color     : "blue",
                    fontSize  : "160%",
                    transform : " rotate(180deg)",
                    fontWeight: "600"
                  }}
                />
                :
                <KeyboardArrowDownIcon
                  style={{
                    color     : "blue",
                    fontSize  : "160%",
                    fontWeight: "600"
                  }}
                />
              }
            </IconButton>
          </Typography>
          <Divider style={{ marginLeft: "10px", marginBottom: "5px" }} />
          <Collapse
            in={collapse}
            timeout="auto"
            orientation="vertical"
          // unmountOnExit
          >
            {data.subtitles.map((subtitle, index) => {

              return (
                <Typography
                  style={{
                    color:
                      pathname.includes(
                        subtitle.url.toLowerCase().replace(/[^\w]/g, "")
                      ) && !pathname.includes("gtm")
                        ? "blue"
                        : "#707070",
                    marginLeft : "8px",
                    marginRight: "8px",
                    height     : "34px",
                    // color: "dimgray",
                    fontSize   : "14px"
                  }}
                  // className={classes.inner_text}
                // noWrap={true}
                >
                  <Box
                    style={{ display: "flex", cursor: "default" }}
                    component="span"
                  >
                    {index === data.subtitles.length - 1 ?
                      pathname.includes(
                        subtitle.url.toLowerCase().replace(/[^\w]/g, "")
                      ) && !pathname.includes("gtm") ?
                        <Dot active={true} islastsubtitle={true} />
                        :
                        <Dot islastsubtitle={true} />
                      
                      : pathname.includes(
                        subtitle.url.toLowerCase().replace(/[^\w]/g, "")
                      ) && !pathname.includes("gtm") ?
                        <Dot active={true} />
                        :
                        <Dot />
                    }
                    {subtitle.name === "Summary" ?
                      <LightTooltip
                        placement="right-start"
                        title="Upgrade to Pro"
                      >
                        {/* <LightTooltip placement="right-start" title="Coming soon"> */}
                        <i>{subtitle.name}</i>
                      </LightTooltip>
                      :
                      <LightTooltip
                        placement="right-start"
                        title="Upgrade to Pro"
                      >
                        {/* <LightTooltip placement="right-start" title="Coming soon"> */}
                        <span>{subtitle.name}</span>
                      </LightTooltip>
                    }
                  </Box>
                </Typography>
              );
            
            })}
          </Collapse>
        </>
        :
        <>
          <Typography
            onClick={() => {

              history.push({
                pathname: `/${data.title
                  .toLowerCase()
                  .replace(/[^\w]/g, "")}intro`,
                state: { scrolltop: props.scrolltop }
              });
            
            }}
            // className={classes.title_text}
            sx={{
              cursor    : "pointer", fontWeight: "600",
              color     : "dimgray",
              fontSize  : "14px",
              margin    : "0px 8px",
              width     : "200px"
            }}
          >
            {data.title}
            <IconButton
              style={{ fontSize: "initial" }}
              onClick={e => {

                e.stopPropagation();
                setCollapse(!collapse);
              
              }}
            >
              {collapse ?
                <KeyboardArrowDownIcon
                  style={{
                    color     : "blue",
                    fontSize  : "160%",
                    transform : " rotate(180deg)",
                    fontWeight: "600"
                  }}
                />
                :
                <KeyboardArrowDownIcon
                  style={{
                    color     : "blue",
                    fontSize  : "160%",
                    fontWeight: "600"
                  }}
                />
              }
            </IconButton>
          </Typography>
          <Divider style={{ marginLeft: "10px", marginBottom: "5px" }} />
          <Collapse
            in={collapse}
            timeout="auto"
            orientation="vertical"
          // unmountOnExit
          >
            {data.subtitles.map((subtitle, index) => {

              return (
                <Typography
                  style={{
                    color:
                      pathname.includes(
                        subtitle.url.toLowerCase().replace(/[^\w]/g, "")
                      ) && !pathname.includes("gtm")
                        ? "blue"
                        : "#707070",
                    marginLeft : "8px",
                    marginRight: "8px",
                    height     : "34px",
                    // color: "dimgray",
                    fontSize   : "14px"
                  }}
                  // className={classes.inner_text}
                // noWrap={true}
                >
                  <Box
                    style={{ display: "flex", cursor: "pointer" }}
                    component="span"
                    onClick={() => {

                      history.push({
                        pathname: `/${subtitle.url
                          .toLowerCase()
                          .replace(/[^\w]/g, "")}`,
                        state: {
                          scrolltop: props.scrolltop
                        }
                      });
                    
                    }}
                  >
                    {index === data.subtitles.length - 1 ?
                      pathname.includes(
                        subtitle.url.toLowerCase().replace(/[^\w]/g, "")
                      ) && !pathname.includes("gtm") ?
                        <Dot active={true} islastsubtitle={true} />
                        :
                        <Dot islastsubtitle={true} />
                      
                      : pathname.includes(
                        subtitle.url.toLowerCase().replace(/[^\w]/g, "")
                      ) && !pathname.includes("gtm") ?
                        <Dot active={true} />
                        :
                        <Dot />
                    }
                    {subtitle.name === "Summary" ?
                      <i>{subtitle.name}</i>
                      :
                      <>{subtitle.name}</>
                    }
                  </Box>
                </Typography>
              );
            
            })}
          </Collapse>
        </>
      }
    </>
  );

}

export default CardBox;
