import {
  Box,
  Button, Card, Collapse, Divider, Grid, TextField, Typography
} from "@mui/material";
// import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import CommentBox from "./CommentBox";

// const comment_data = [
//   {
//     imagesrc   : `${process.env.REACT_APP_AWS_ASSETS_URL}/images/profile.jpeg`,
//     person_name: "Luke Skywalker",
//     date       : "23 Jul",
//     designation: "Co-founder",
//     content:
//       "our time is limited, so don't waste it living someone else's life. Don't be trapped by dogma – which is living with the results of other people's thinking. -Steve Jobs"
//   },
//   {
//     imagesrc   : `${process.env.REACT_APP_AWS_ASSETS_URL}/images/profile.jpeg`,
//     person_name: "Senthil",
//     date       : "25 Jul",
//     designation: "Co-founder",
//     content:
//       " – Life is what happens when you're busy making other plans. -John Lennon  "
//   },
//   {
//     imagesrc   : `${process.env.REACT_APP_AWS_ASSETS_URL}/images/profile.jpeg`,
//     person_name: "Guna",
//     date       : "25 Jul",
//     designation: "Co-founder",
//     content:
//       "Spread love everywhere you go. Let no one ever come to you without leaving happier"
//   },
//   {
//     imagesrc   : `${process.env.REACT_APP_AWS_ASSETS_URL}/images/profile.jpeg`,
//     person_name: "Velu",
//     date       : "25 Jul",
//     designation: "Co-founder",
//     content    : "Whoever is happy will make others happy too. -Anne Frank  "
//   }
// ];

// const UseStyles = makeStyles({
//   Cardstyles: {
//     marginRight           : "18px",
//     borderRadius          : "10px",
//     // border: "solid 1px",
//     border                : "1px solid #e3e5ff",
//     // border: "1px solid #CCCCCC",
//     boxShadow             : "0px 3px 16px #3939392B",
//     height                : props => props.height ? props.height : "85vh",
//     overflow              : "auto",
//     "&::-webkit-scrollbar": {
//       width: "5px"
//     },
//     "&::-webkit-scrollbar-track": {
//       backgroundColor: "#f1f1f1"
//     },
//     "&::-webkit-scrollbar-thumb": {
//       backgroundColor: "#888",
//       borderRadius   : "100px"
//     }
//   }
// });

function CommentContainer (props) {

  // const classes = UseStyles({ height: props.height });

  const [ commentText, setCommentText ] = useState("");
  const [ mainComment, setMainComment ] = useState(false);
  const [ posting, setPosting ] = useState(false);

  const comments = props.comments;

  // const getThreadedComments = (data) => {
  //
  //   const comments = [];
  //   for (let comment of data) {
  //     if (comment.responseTo) {
  //       const index = comments.findIndex((i) => i._id === comment.responseTo);
  //       comments[index].responses.push(comment);
  //     } else {
  //       comments.push({ ...comment, responses: [] });
  //     }
  //   }
  //   return comments;
  // };

  // const threadedComments = getThreadedComments(comments);

  return (
    <Collapse
      style={ {
        minWidth: props.collapse
          ? props.minWidth
            ? props.minWidth
            : "16vw"
          : "0vw",
        maxWidth: "16vw"
      } }
      in={ props.collapse }
      orientation="horizontal"
    >
      <Card
        style={ props.height ? { height: props.height } : { height: "80vh" }}
        sx={ {
          marginRight : props.pitch ? "0px" : "1em",
          marginTop   : "5vh",
          marginBottom: "5vh",
          borderRadius: "10px",
          // width: "15vw",
          // border: "solid 1px",
          border      : "1px solid #e3e5ff",
          // border: "1px solid #CCCCCC",
          boxShadow   : "0px 3px 16px #3939392B",
          
          overflow              : "auto",
          "&::-webkit-scrollbar": {
            width: "5px"
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#f1f1f1"
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
            borderRadius   : "100px"
          }
        } }
      >
        {/* <Card className={classes.Cardstyles}> */ }
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            item
            style={ {
              backgroundColor: "white",
              padding        : "10px",
              position       : "sticky",
              top            : "0",
              width          : "100%",
              textAlign      : "center",
              zIndex         : "100"
            } }
          >
            <Typography variant="subtitle1" gutterBottom component="div">
              Comments
            </Typography>

            { mainComment ?
              <Box>
                <TextField
                  sx={ {
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px"
                    }
                  } }
                  inputProps={ {
                    style: {
                      // fontSize: "1.4vh",
                      fontSize  : "11px",
                      fontWeight: "bold",
                      // padding: "5px 10px",
                      color     : "#707070"
                    }
                  } }
                  // className={classes.textfield}
                  placeholder="Write a comment"
                  variant="outlined"
                  size="small"
                  autoFocus
                  multiline
                  maxRows={ 2 }
                  minRows={ 2 }
                  fullWidth
                  onChange={ e => {

                    setCommentText(e.target.value.trimStart());
                  
                  } }
                  onKeyDown={ async e => {

                    if (e.key === "Enter") {

                      e.preventDefault();
                      if (commentText !== "" && !posting) {

                        setPosting(true);
                        await props.addComment(commentText, "");
                        setCommentText("");
                        setMainComment(false);
                        setPosting(false);
                      
                      }
                    
                    }
                  
                  } }
                  value={ commentText }
                // InputProps={{
                //   endAdornment: (
                //     <InputAdornment position="end">
                //       <IconButton edge="end" style={{ paddingBottom: "10px" }}>
                //         <SendIcon />
                //       </IconButton>
                //     </InputAdornment>
                //   ),
                // }}
                />
                <Box style={ { textAlign: "end" } }>
                  <Button
                    style={ {
                      fontSize     : "12px",
                      textTransform: "capitalize",
                      color        : "grey"
                      // padding: "10px",
                    } }
                    onClick={ () => {

                      setMainComment(false);
                      setCommentText("");
                    
                    } }
                  >
                    Cancel
                  </Button>
                  <Button
                    style={ {
                      fontSize     : "12px",
                      textTransform: "capitalize"
                      // padding: "10px",
                    } }
                    disabled={ posting }
                    onClick={ async e => {

                      e.preventDefault();
                      if (commentText !== "" && !posting) {

                        setPosting(true);
                        await props.addComment(commentText, "");
                        setCommentText("");
                        setMainComment(false);
                        setPosting(false);
                      
                      }
                    
                    } }
                  >
                    Post
                  </Button>
                </Box>
              </Box>
              :
              <TextField
                sx={ {
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px"
                  }
                } }
                inputProps={ {
                  style: {
                    // fontSize: "1.4vh",
                    fontSize  : "11px",
                    fontWeight: "bold",
                    padding   : "5px 10px",
                    color     : "#707070"
                  }
                } }
                // className={classes.textfield}
                placeholder="Write a comment"
                variant="outlined"
                size="small"
                fullWidth
                onChange={ e => {

                  setCommentText(e.target.value.trimStart());
                
                } }
                // onKeyDown={async (e) => {
                //   if (e.key === "Enter") {
                //     e.preventDefault();
                //     await props.addComment(commentText, "");
                //     setCommentText("");
                //   }
                // }}
                value={ commentText }
                onClick={ () => {

                  setMainComment(true);
                
                } }
              // InputProps={{
              //   endAdornment: (
              //     <InputAdornment position="end">
              //       <IconButton edge="end" style={{ paddingBottom: "10px" }}>
              //         <SendIcon />
              //       </IconButton>
              //     </InputAdornment>
              //   ),
              // }}
              />
            }

            <Divider
              variant="middle"
              style={ {
                margin      : "10px 0px",
                marginBottom: "0px",
                // borderBottom: "solid 1px",
                borderBottom: "1px solid #e3e5ff"
              } }
            ></Divider>
          </Grid>

          <Grid container justifyContent="center">
            { comments &&
              comments.map((comment, index) => {

                return <div key={index}>
                  <CommentBox
                    index={ index }
                    comment={ comment }
                    addComment={ props.addComment }
                    commentid={ comment._id }
                    deleteComment={ props.deleteComment }
                    updateComment={ props.updateComment }
                  />
                </div>;
              
              }) }
          </Grid>
        </Grid>
      </Card>
    </Collapse>
  );

}

export default CommentContainer;
