import CloseIcon from "@mui/icons-material/Close";
import { Box, TextField, Typography } from "@mui/material";
import React from "react";
// import { useHistory } from "react-router-dom";
import styles from "./IndividualYear.module.css";

export default function IndividualYear ({
  isEdit,
  revenueYear,
  revenueYearIndex,
  removeYear,
  handleChange
}) {

  // const history = useHistory();
  // const pathname = history.location.pathname.substring(1);

  
  return (
    <Box
      id={ styles.yearBox }
    >
      <Box
        id={ styles.yearTypoBox }
      >
        { isEdit ?
          <TextField
            value={ revenueYear.name }
            onChange={ e => {

              handleChange("name", e.target.value, revenueYearIndex);
            
            } }
            fullWidth
            inputProps={ { maxLength: 15 } }
            sx={ {

              "& .MuiInputBase-input": {
                fontSize  : "16px",
                padding   : "0px 0px",
                border    : "none",
                textAlign : "center",
                color     : "#393939",
                fontWeight: "bold"
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border : "2px dotted #919AFF",
                padding: "0px 0px"
              }
            } }
          />
          :
          <Typography
            id={ styles.yearTypo }
          // sx={{ color: "#393939", fontWeight: "bold" }}
          >
            { revenueYear.name }
          </Typography>
        }
        {/* close button */ }
        { isEdit && revenueYearIndex > 2 &&
          <Box
            sx={ {
              width          : "15px",
              height         : "15px",
              border         : "1px solid #838383",
              borderRadius   : "50%",
              zIndex         : 20,
              position       : "absolute",
              right          : "-5px",
              top            : "-11px",
              display        : "flex",
              justifyContent : "center",
              alignItems     : "center",
              backgroundColor: "#FFFFFF"
            } }
            onClick={ () => {

              removeYear(revenueYearIndex);
            
            } }>
            <CloseIcon sx={ { fontSize: "15px", color: "#838383" } } />
          </Box>
        }
      </Box>
      <Box
        id={ styles.textFieldBox }
      // sx={{ width: "100%", height: "50px" }}
      >
        { isEdit ?
          <TextField
            value={ revenueYear.value }
            onChange={ e => {

              handleChange("value", e.target.value, revenueYearIndex);
            
            } }
            placeholder="Enter revenue  E.g. $100,000"
            fullWidth
            inputProps={ { maxLength: 20 } }
            sx={ {
              "& .MuiOutlinedInput-root": {
                padding: "0px 0px"
              },
              "& .MuiInputBase-input": {
                fontSize  : "1.5vh",
                padding   : "10px 5px",
                border    : "none",
                color     : "#707070",
                textAlign : "center",
                whiteSpace: "pre-line"
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border : "2px dotted #919AFF",
                padding: "0px 0px"
              }
            } }
          />
          :
          // <TextField
          //   value={revenueYear.value}
          //   fullWidth
          //   disabled
          //   sx={{
          //     "& .MuiOutlinedInput-root": {
          //       padding: "0px 0px",
          //     },
          //     "& .MuiInputBase-input": {
          //       fontSize: "14px",
          //       padding: "10px 10px",
          //       border: "none",
          //       "-webkit-text-fill-color": `#707070 !important`,
          //       color: `#707070 !important`,
          //       // fontWeight: "bold",
          //       textAlign: "center",
          //     },
          //     "& .MuiOutlinedInput-notchedOutline": {
          //       border: "1px solid #919AFF",
          //       padding: "0px 0px",
          //     },
          //   }}
          // />
          <Box
            sx={ {
              width       : "100%",
              border      : "1px solid #BEBEBE",
              height      : "38px",
              borderRadius: "5px",
              // padding: "10px 15px",
              overflowY   : "auto"
            } }>
            <Box
              sx={ {
                margin   : "6px 5px",
                wordWrap : "break-word",
                textAlign: "center"
              } }>
              <Typography
                sx={ {
                  fontSize  : "1.5vh",
                  color     : "#707070",
                  lineHeight: "23px",
                  whiteSpace: "pre-line"
                } }>
                { revenueYear.value }
              </Typography>
            </Box>
          </Box>
        }
      </Box>
    </Box>
  );

}
