import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box, Button, Dialog,
  DialogContent, Divider, IconButton, TextField, Typography
} from "@mui/material";
import React, { useState } from "react";
// import { useHistory } from "react-router-dom";
import PitchFinancialsFunding from "../PitchReferenceLinks/PitchFinancialsFunding";
import ReferenceLinkTooltip from "../PitchReferenceLinks/ReferenceLinkTooltip";
import SectionHeader from "../SectionHeader";

export default function TheAsk ({
  logoimg,
  slides,
  isRender,
  setSlides,
  isPublished,
  ...props
}) {

  const [ askFundingdialogbox, setAskFunding ] = useState(false);

  const AskFundingDialogopen = () => {

    setAskFunding(true);
  
  };
  const AskFundingDialogclose = () => {

    setAskFunding(false);
  
  };

  const slide = slides[isRender];

  // const history = useHistory();
  // const pathname = history.location.pathname.substring(1);

  const handleInvestmentAllocationChange = (name, value, columnindex) => {

    let newslides = [ ...slides ];

    newslides[isRender]["isChanged"] = true;

    newslides[isRender]["investmentAllocations"].map(
      (investmentAllocation, investmentAllocationIndex) => {

        if (investmentAllocationIndex === columnindex) {

          newslides[isRender]["investmentAllocations"][
            investmentAllocationIndex
          ][name] = value;
        
        }
        
        return investmentAllocation;
      
      }
    );
    setSlides(newslides);
  
  };

  const handleFundingDetailsChange = (name, value, section) => {

    let newslides = [ ...slides ];

    newslides[isRender]["isChanged"] = true;

    newslides[isRender][section][name] = value;

    setSlides(newslides);
  
  };

  const handleDetailsChange = (name, value) => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    newSlides[isRender][name] = value;
    setSlides(newSlides);
  
  };

  const allocationPlaceholders = [
    "E.g. Marketing",
    "E.g. Product Development",
    "E.g. Sales"
  ];

  return  <div style={{ overFlow: "auto" }}>
    
    <Box >
      <SectionHeader
        isEdit={ props.isEdit }
        Collapsecomment={ props.Collapsecomment }
        collapseCommentFunction={ props.collapseCommentFunction }
        mainHead={ slide.name }
        handleTitleChange={ handleDetailsChange }
        logoimg={ logoimg }
        isPublished={ isPublished }
      />
      <Box
        sx={ {
          width : "100%",
          height: "60vh",

          display       : "flex",
          flexDirection : "column",
          justifyContent: "center",

          position: "relative"
        } }
      >
        {/* ------ */ }
        <Box
          sx={ {
            display       : "flex",
            justifyContent: "space-evenly",
            // marginTop: "2vh",
            // border: 1,
            width         : "100%"
          } }
        >
          <Box
            sx={ {
              // border: 1,
              width        : "25%",
              display      : "flex",
              // justifyContent: "center",
              alignItems   : "center",
              flexDirection: "column"
              // height: "145px",
            } }
          >
            {/* ----------- */ }
            <Box
              sx={ {
                height        : "30px",
                // border: 1,
                width         : "100%",
                display       : "flex",
                justifyContent: "center",
                // alignItems: "center",
                marginTop     : "15px"
              } }
            >
              { props.isEdit ?
                <TextField
                  value={ slide.fundingType.name }
                  onChange={ e => {

                    handleFundingDetailsChange(
                      "name",
                      e.target.value,
                      "fundingType"
                    );
                  
                  } }
                  // disabled
                  // placeholder="Enter a key strategy of how you plan to compete in the market, why will your target users choose you over any competitors or substitutes"
                  inputProps={ { maxLength: 15 } }
                  fullWidth
                  multiline
                  maxRows={ 1 }
                  minRows={ 1 }
                  sx={ {
                    "& .MuiOutlinedInput-root": {
                      padding: "0px 10px"
                    },
                    "& .MuiInputBase-input": {
                      fontSize  : "19px",
                      //   padding: "7px 10px",
                      color     : "#393939",
                      textAlign : "center",
                      fontWeight: "bold"
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "2px dotted #B4B4FA"
                    }
                  } }
                // style={{ marginTop: "20px" }}
                />
                :
                <Box sx={ { textAlign: "center" } }>
                  <Typography
                    sx={ {
                      color     : "#393939",
                      fontSize  : "1.7vh",
                      fontWeight: "bold"
                    } }
                  >
                    { slide.fundingType.name }
                  </Typography>
                </Box>
              }
            </Box>
            {/* circle box */ }
            <Box
              sx={ {
                width         : "90%",
                height        : "100px",
                display       : "flex",
                justifyContent: "center",
                alignItems    : "center"
                // border: 1,
              } }
            >
              <Box
                sx={ {
                  width          : "100%",
                  // border: 1,
                  // borderRadius: "50%",
                  // height: "100%",
                  display        : "flex",
                  justifyContent : "center",
                  alignItems     : "center",
                  backgroundColor: "#083A9A",
                  height         : "70px",
                  borderRadius   : "11px"
                } }
              >
                <Box sx={ { width: "90%", textAlign: "center" } }>
                  { props.isEdit ?
                    <TextField
                      value={ slide.fundingType.value }
                      onChange={ e => {

                        handleFundingDetailsChange(
                          "value",
                          e.target.value,
                          "fundingType"
                        );
                      
                      } }
                      placeholder="E.g. $5M"
                      fullWidth
                      inputProps={ { maxLength: 15 } }
                      sx={ {
                        // "& .MuiOutlinedInput-root": {
                        //   padding: "0px 0px",
                        // },
                        "& .MuiInputBase-input": {
                          fontSize : "1.2vw",
                          padding  : "1.5px 0px",
                          border   : "none",
                          color    : "#ffffff",
                          textAlign: "center"
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px dotted #ffffff"
                        }
                      } }
                    />
                    :
                    <Typography
                      sx={ {
                        fontSize  : "1.2vw",
                        // fontSize: "2.5vw",
                        color     : "#FFFFFF",
                        fontWeight: "bold"
                      } }
                    >
                      { slide.fundingType.value
                        ? slide.fundingType.value
                        : isPublished
                          ? ""
                          : "E.g. $5M" }
                    </Typography>
                  }
                </Box>
              </Box>
            </Box>
            {/* ----- */ }
            {/* circle bottom typo */ }
            <Box
              sx={ {
                height        : "50px",
                // border: 1,
                width         : "100%",
                display       : "flex",
                justifyContent: "center",
                // alignItems: "center",
                marginTop     : "5px"
              } }
            >
              {/* {props.isEdit ? (
                <TextField
                  value={slide.fundingType.name}
                  onChange={(e) => {
                    handleFundingDetailsChange(
                      "name",
                      e.target.value,
                      "fundingType"
                    );
                  }}
                  // disabled
                  // placeholder="Enter a key strategy of how you plan to compete in the market, why will your target users choose you over any competitors or substitutes"
                  fullWidth
                  multiline
                  maxRows={2}
                  minRows={2}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "0px 10px",
                    },
                    "& .MuiInputBase-input": {
                      fontSize: "16px",
                      //   padding: "7px 10px",
                      color: "#707070",
                      textAlign: "center",
                      fontWeight: "bold",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "2px dotted #B4B4FA",
                    },
                  }}
                  // style={{ marginTop: "20px" }}
                />
              ) : (
                <Box sx={{ textAlign: "center" }}>
                  <Typography
                    sx={{
                      color: "#707070",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    {slide.fundingType.name}
                  </Typography>
                </Box>
              )} */}
            </Box>
          </Box>
          {/* arrow box */ }
          {/* ------------- */ }
          <Box
            sx={ {
              width: "3vh",
              // height: "13vh",

              display       : "flex",
              justifyContent: "center",
              alignItems    : "center",
              // border: 1,
              height        : "190px"
            } }
          >
            <ArrowForwardIcon sx={ { fontSize: "35px", color: "#083A9A" } } />
            {/* fontSize: "27px", */ }
          </Box>
          {/* -------------------------- */ }
          <Box
            sx={ {
              // border: 1,
              display      : "flex",
              width        : "60%",
              flexDirection: "column"
            } }
          >
            <Box
              sx={ {
                width         : "100%",
                // border: 1,
                display       : "flex",
                justifyContent: "center",
                height        : "30px",
                marginTop     : "15px"
              } }
            >
              <Typography
                sx={ { color: "#393939", fontWeight: "bold", fontSize: "1.8vh" } }
              >
                { slide.achieveDescription }
              </Typography>
            </Box>
            <Box sx={ { display: "flex" } }>
              <Box
                sx={ {
                  width        : "90%",
                  // width: "25%",
                  // border: 1,
                  display      : "flex",
                  // justifyContent: "center",
                  alignItems   : "center",
                  flexDirection: "column",
                  marginRight  : "15px"
                } }
              >
                {/* top head */ }
                {/* -------- */ }
                {/* <Box sx={{ height: "50px", border: 1, marginTop: "10px" }}></Box> */ }
                {/* -------- */ }
                <Box
                  sx={ {
                    // border: 1,
                    height    : "100px",
                    display   : "flex",
                    alignItems: "center",
                    width     : "100%"
                  } }
                >
                  <Box
                    sx={ {
                      width          : "100%",
                      height         : "67px",
                      // border: 1,
                      borderRadius   : "11px",
                      display        : "flex",
                      justifyContent : "center",
                      alignItems     : "center",
                      backgroundColor: "#ffffff",
                      // border: "2px solid #083A9A",
                      border         : props.isEdit
                        ? "2px dotted #083A9A"
                        : "2px solid #083A9A",
                      paddingLeft : "3px",
                      paddingRight: "3px"
                    } }
                  >
                    { props.isEdit ?
                      <TextField
                        value={ slide.customerBase.value }
                        onChange={ e => {

                          handleFundingDetailsChange(
                            "value",
                            e.target.value,
                            "customerBase"
                          );
                        
                        } }
                        placeholder="E.g. 10k users in 12mo"
                        // disabled
                        fullWidth
                        inputProps={ { maxLength: 25 } }
                        sx={ {
                          // "& .MuiOutlinedInput-root": {
                          //   padding: "0px 0px",
                          // },
                          "& .MuiInputBase-input": {
                            fontSize  : "1.2vw",
                            // fontSize: "1.9vw",
                            padding   : "12px 2px",
                            border    : "none",
                            textAlign : "center",
                            color     : "#083A9A",
                            fontWeight: "bold"
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            // border: "1px solid #B4B4FA",
                            border: "none"
                          }
                        } }
                      />
                      :
                      <Typography
                        sx={ {
                          fontSize  : "1.2vw",
                          color     : "#083A9A",
                          // textAlign: "center",
                          fontWeight: "bold"
                        } }
                      >
                        { slide.customerBase.value
                          ? slide.customerBase.value
                          : isPublished
                            ? ""
                            : "E.g. 10k users in 12mo" }
                      </Typography>
                    }
                  </Box>
                </Box>
                {/* <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <Typography sx={{ fontSize: "21px", color: "#393939" }}>
                Customer Base
              </Typography>
            </Box> */}
                {/* <Box sx={{ textAlign: "center" }}>
              <Typography>Customer Base in X timeline</Typography>
            </Box> */}
                <Box
                  sx={ {
                    height        : "50px",
                    // border: 1,
                    width         : "100%",
                    display       : "flex",
                    justifyContent: "center",
                    // alignItems: "center",
                    marginTop     : "5px"
                  } }
                >
                  { props.isEdit ?
                    <TextField
                      value={ slide.customerBase.name }
                      onChange={ e => {

                        handleFundingDetailsChange(
                          "name",
                          e.target.value,
                          "customerBase"
                        );
                      
                      } }
                      // disabled
                      // placeholder="Enter a key strategy of how you plan to compete in the market, why will your target users choose you over any competitors or substitutes"
                      placeholder="Enter other relevant details here for this goal"
                      fullWidth
                      inputProps={ { maxLength: 20 } }
                      multiline
                      maxRows={ 2 }
                      minRows={ 2 }
                      sx={ {
                        "& .MuiOutlinedInput-root": {
                          padding: "0px 10px"
                        },
                        "& .MuiInputBase-input": {
                          fontSize  : "16px",
                          //   padding: "7px 10px",
                          color     : "#707070",
                          textAlign : "center",
                          fontWeight: "bold",
                          whiteSpace: "pre-line"
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px dotted #B4B4FA"
                        }
                      } }
                    />
                    :
                    // <Box sx={{ textAlign: "center", width: "100%" }}>

                  //   <TextField
                  //     disabled

                  //     value={slide.customerBase.name}
                  //     fullWidth

                    //     placeholder={
                    //       pathname.includes("pitchpresentation")
                    //         ? ""
                    //         : "Enter other relevant details here for this goal"
                    //     }
                    //     inputProps={{ maxLength: 20 }}
                    //     multiline
                    //     maxRows={2}
                    //     minRows={2}
                    //     sx={{
                    //       "& .MuiOutlinedInput-root": {
                    //         padding: "0px 10px",
                    //       },
                    //       "& .MuiInputBase-input": {
                    //         fontSize: "16px",
                    //         //   padding: "7px 10px",
                    //         color: "#707070",
                    //         textAlign: "center",
                    //         fontWeight: "bold",
                    //         "-webkit-text-fill-color": `#707070 !important`,
                    //         color: `#707070 !important`,
                    //       },
                    //       "& .MuiOutlinedInput-notchedOutline": {
                    //         // border: "2px dotted #B4B4FA",
                    //         border: "none",
                    //       },
                    //     }}
                    //   />
                    // </Box>
                    <Box
                      sx={ {
                        width       : "100%",
                        // border: "1px solid #BEBEBE",
                        height      : "48px",
                        borderRadius: "5px",
                        // padding: "10px 15px",
                        overflowY   : "auto"
                      } }
                    >
                      <Box
                        sx={ {
                          margin   : "0px 7px",
                          wordWrap : "break-word",
                          textAlign: "center"
                        } }
                      >
                        <Typography
                          sx={ {
                            fontSize  : "16px",
                            color     : "#707070",
                            lineHeight: "23px",
                            fontWeight: "bold",
                            // opacity: 0.3,
                            opacity   : slide.customerBase.name ? 1 : 0.4,
                            whiteSpace: "pre-line"
                          } }
                        >
                          { slide.customerBase.name
                            ? slide.customerBase.name
                            : "Enter other relevant details here for this goal" }
                        </Typography>
                      </Box>
                    </Box>
                  }
                </Box>
              </Box>
              {/* arrow box */ }
              {/* <Box
            sx={{
              width: "3vh",
              height: "13vh",

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ArrowForwardIcon sx={{ fontSize: "27px", color: "#083A9A" }} />
          </Box> */}
              <Box
                sx={ {
                  // border: 1,
                  width        : "90%",
                  // width: "25%",
                  display      : "flex",
                  // justifyContent: "center",
                  alignItems   : "center",
                  flexDirection: "column"
                  // height: "145px",
                } }
              >
                {/* circle box */ }
                <Box
                  sx={ {
                    width         : "100%",
                    height        : "100px",
                    // border: 1,
                    // marginBottom: "10px",
                    display       : "flex",
                    justifyContent: "center",
                    alignItems    : "center"
                  } }
                >
                  <Box
                    sx={ {
                      width : "100%",
                      border: props.isEdit
                        ? "2px dotted #083A9A"
                        : "2px solid #083A9A",
                      // border: "2px solid #083A9A",
                      borderRadius   : "11px",
                      height         : "67px",
                      display        : "flex",
                      justifyContent : "center",
                      alignItems     : "center",
                      backgroundColor: "#FFFFFF",
                      // backgroundColor: "#4A56E3",
                      marginTop      : "1px"
                    } }
                  >
                    <Box sx={ { width: "100%", textAlign: "center" } }>
                      { props.isEdit ?
                        <TextField
                          value={ slide.revenueProjection.value }
                          onChange={ e => {

                            handleFundingDetailsChange(
                              "value",
                              e.target.value,
                              "revenueProjection"
                            );
                          
                          } }
                          placeholder="E.g. $5M MRR in 6mo"
                          fullWidth
                          inputProps={ { maxLength: 25 } }
                          sx={ {
                            // "& .MuiOutlinedInput-root": {
                            //   padding: "0px 0px",
                            // },
                            "& .MuiInputBase-input": {
                              fontSize  : "1.2vw",
                              padding   : "1.5px 0px",
                              border    : "none",
                              color     : "#083A9A",
                              textAlign : "center",
                              fontWeight: "bold"
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none"
                              // border: "2px dotted #083A9A",
                            }
                          } }
                        />
                        :
                        <Typography
                          sx={ {
                            fontSize  : "1.2vw",
                            // fontSize: "1.9vw",
                            color     : "#083A9A",
                            fontWeight: "bold"
                          } }
                        >
                          { slide.revenueProjection.value
                            ? slide.revenueProjection.value
                            : isPublished
                              ? ""
                              : "E.g. $5M MRR in 6mo" }
                        </Typography>
                      }
                    </Box>
                  </Box>
                </Box>
                {/* ----- */ }
                {/* circle bottom typo */ }
                <Box
                  sx={ {
                    height        : "50px",
                    // border: 1,
                    width         : "100%",
                    display       : "flex",
                    justifyContent: "center",
                    // alignItems: "center",
                    marginTop     : "5px"
                  } }
                >
                  { props.isEdit ?
                    <TextField
                      value={ slide.revenueProjection.name }
                      onChange={ e => {

                        handleFundingDetailsChange(
                          "name",
                          e.target.value,
                          "revenueProjection"
                        );
                      
                      } }
                      // disabled
                      // placeholder="Enter a key strategy of how you plan to compete in the market, why will your target users choose you over any competitors or substitutes"
                      placeholder="Enter other relevant details here for this goal"
                      fullWidth
                      inputProps={ { maxLength: 20 } }
                      multiline
                      maxRows={ 2 }
                      minRows={ 2 }
                      sx={ {
                        "& .MuiOutlinedInput-root": {
                          padding: "0px 10px"
                        },
                        "& .MuiInputBase-input": {
                          fontSize                 : "16px",
                          //   padding: "7px 10px",
                          color                    : "#707070",
                          textAlign                : "center",
                          fontWeight               : "bold",
                          "-webkit-text-fill-color": "#707070 !important",
                          // color                    : "#707070 !important",
                          whiteSpace               : "pre-line"
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px dotted #B4B4FA"
                        }
                      } }
                    />
                    :
                    // <Box sx={{ textAlign: "center", width: "100%" }}>

                  //   <TextField
                  //     disabled

                    //     placeholder={
                    //       pathname.includes("pitchpresentation")
                    //         ? ""
                    //         : "Enter other relevant details here for this goal"
                    //     }
                    //     // placeholder="Enter other relevant details here for this goal"
                    //     value={slide.revenueProjection.name}
                    //     fullWidth
                    //     inputProps={{ maxLength: 20 }}
                    //     multiline
                    //     maxRows={2}
                    //     minRows={2}
                    //     sx={{
                    //       "& .MuiOutlinedInput-root": {
                    //         padding: "0px 10px",
                    //       },
                    //       "& .MuiInputBase-input": {
                    //         fontSize: "16px",
                    //         //   padding: "7px 10px",
                    //         color: "#707070",
                    //         textAlign: "center",
                    //         fontWeight: "bold",
                    //         "-webkit-text-fill-color": `#707070 !important`,
                    //         color: `#707070 !important`,
                    //       },
                    //       "& .MuiOutlinedInput-notchedOutline": {
                    //         border: "none",
                    //         // border: "2px dotted #B4B4FA",
                    //       },
                    //     }}
                    //   />
                    // </Box>
                    <Box
                      sx={ {
                        width       : "100%",
                        // border: "1px solid #BEBEBE",
                        height      : "48px",
                        borderRadius: "5px",
                        // padding: "10px 15px",
                        overflowY   : "auto"
                      } }
                    >
                      <Box
                        sx={ {
                          margin   : "0px 7px",
                          wordWrap : "break-word",
                          textAlign: "center"
                        } }
                      >
                        <Typography
                          sx={ {
                            fontSize  : "16px",
                            color     : "#707070",
                            lineHeight: "23px",
                            fontWeight: "bold",
                            // opacity: 0.3,
                            opacity   : slide.revenueProjection.name ? 1 : 0.4,
                            whiteSpace: "pre-line"
                          } }
                        >
                          { slide.revenueProjection.name
                            ? slide.revenueProjection.name
                            : "Enter other relevant details here for this goal" }
                        </Typography>
                      </Box>
                    </Box>
                  }
                </Box>
              </Box>
            </Box>
          </Box>
          {/* --------------- */ }
        </Box>
        {/* divider */ }
        <Box sx={ { marginTop: "2.5vh" } }>
          <Divider />
        </Box>
        {/* ---bottom content--- */ }
        <Box
          sx={ {
            // display: "flex",
            // justifyContent: "space-evenly",
            // alignItems: "center",
            // border: 1,
            marginTop: "3.1vh",
            position : "relative"
          } }
        >
          {/* head title */ }
          <Box
            sx={ {
              // border: 1,
              display       : "flex",
              justifyContent: "center",
              alignItems    : "center"
            } }
          >
            <Typography
              sx={ { fontSize: "1.9vh", color: "#393939", fontWeight: "bold" } }
            >
              Investment Allocation (annual)
            </Typography>
          </Box>

          {/* ------ */ }
          <Box
            sx={ {
              width         : "100%",
              display       : "flex",
              justifyContent: "space-evenly",
              alignItems    : "center",
              marginTop     : "3vh"
              // border: 1,
            } }
          >
            { slide.investmentAllocations.map(
              (investmentAllocation, investmentAllocationIndex) => {

                return (
                  <Box sx={ { width: "25%" } }>
                    <Box
                      sx={ {
                        width         : "100%",
                        display       : "flex",
                        justifyContent: "center",
                        alignItems    : "center",
                        // border: 1,
                        height        : "25px",
                        marginBottom  : "6px"
                      } }
                    >
                      { props.isEdit ?
                        <TextField
                          value={ investmentAllocation.name }
                          onChange={ e => {

                            handleInvestmentAllocationChange(
                              "name",
                              e.target.value,
                              investmentAllocationIndex
                            );
                          
                          } }
                          placeholder={
                            allocationPlaceholders[investmentAllocationIndex]
                          }
                          // disabled
                          inputProps={ { maxLength: 20 } }
                          fullWidth
                          sx={ {
                            // "& .MuiOutlinedInput-root": {
                            //   padding: "0px 0px",
                            // },
                            "& .MuiInputBase-input": {
                              fontSize  : "13px",
                              padding   : "2px 10px",
                              border    : "none",
                              textAlign : "center",
                              color     : "#393939",
                              fontWeight: "bold"
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "2px dotted #B4B4FA"
                            }
                          } }
                        />
                        :
                        // <Box sx={{ width: "100%" }}>
                        //   <TextField
                        //     disabled
                        //     value={
                        // investmentAllocation.name
                        //   ? investmentAllocation.name
                        //   : pathname.includes("pitchpresentation")
                        //   ? ""
                        //   : allocationPlaceholders[
                        //       investmentAllocationIndex
                        //     ]
                        //     }
                        //     placeholder={
                        //       pathname.includes("pitchpresentation")
                        //         ? ""
                        // : allocationPlaceholders[
                        //     investmentAllocationIndex
                        //   ]
                        //     }
                        //     inputProps={{ maxLength: 20 }}
                        //     fullWidth
                        //     sx={{
                        //       "& .MuiInputBase-input": {
                        //         fontSize: "13px",
                        //         padding: "2px 10px",
                        //         border: "none",
                        //         textAlign: "center",
                        //         color: "#393939",
                        //         fontWeight: "bold",
                        //         "-webkit-text-fill-color": `#393939 !important`,
                        //         color: `#393939 !important`,
                        //       },
                        //       "& .MuiOutlinedInput-notchedOutline": {
                        //         border: "none",
                        //       },
                        //     }}
                        //   />
                        // </Box>
                        <Box
                          sx={ {
                            width         : "100%",
                            // border: "1px solid #BEBEBE",
                            // height: "20px",
                            borderRadius  : "5px",
                            // padding: "10px 15px",
                            overflowY     : "auto",
                            display       : "flex",
                            justifyContent: "center",
                            alignItems    : "center"
                          } }
                        >
                          <Box
                            sx={ {
                              margin  : "0px 0px",
                              wordWrap: "break-word"
                            } }
                          >
                            <Typography
                              sx={ {
                                fontSize  : "1.5vh",
                                color     : "#393939",
                                // lineHeight: "23px",
                                fontWeight: "bold"
                              } }
                            >
                              { investmentAllocation.name
                                ? investmentAllocation.name
                                : isPublished
                                  ? ""
                                  : allocationPlaceholders[
                                    investmentAllocationIndex
                                  ] }
                            </Typography>
                          </Box>
                        </Box>
                      }
                    </Box>
                    <Box sx={ { width: "100%" } }>
                      { props.isEdit ?
                        <TextField
                          value={ investmentAllocation.value }
                          onChange={ e => {

                            handleInvestmentAllocationChange(
                              "value",
                              e.target.value,
                              investmentAllocationIndex
                            );
                          
                          } }
                          // placeholder="Eg: $500k (vendor team)"
                          // value={segment.personaDescription}
                          // placeholder={
                          //   placeholders[segmentindex]["personaDescription"]
                          // }
                          // onChange={(e) => {
                          //   handleChange(
                          //     "personaDescription",
                          //     e.target.value,
                          //     segmentindex
                          //   );
                          // }}
                          fullWidth
                          multiline
                          inputProps={ { maxLength: 50 } }
                          maxRows={ 3 }
                          minRows={ 3 }
                          sx={ {
                            "& .MuiOutlinedInput-root": {
                              padding: "4px 10px"
                            },
                            "& .MuiInputBase-input": {
                              fontSize  : "12px",
                              color     : "#707070",
                              lineHeight: "15px",
                              whiteSpace: "pre-line"
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "2px dotted #B4B4FA"
                            }
                          } }
                        />
                        :
                        // <TextField
                        //   value={investmentAllocation.value}
                        //   disabled

                        //   fullWidth
                        //   multiline
                        //   maxRows={2}
                        //   minRows={2}
                        //   sx={{
                        //     "& .MuiOutlinedInput-root": {
                        //       padding: "4px 10px",
                        //     },
                        //     "& .MuiInputBase-input": {
                        //       fontSize: "13px",
                        //       "-webkit-text-fill-color": `#707070 !important`,
                        //       color: `#707070 !important`,
                        //       // fontWeight: "bold",
                        //     },
                        //     "& .MuiOutlinedInput-notchedOutline": {
                        //       border: "1px solid #B4B4FA",
                        //     },
                        //   }}
                        // />
                        <Box
                          sx={ {
                            width         : "100%",
                            border        : "1px solid #BEBEBE",
                            height        : "8vh",
                            borderRadius  : "5px",
                            // padding: "10px 15px",
                            overflowY     : "auto",
                            display       : "flex",
                            justifyContent: "center",
                            alignItems    : "center"
                          } }
                        >
                          <Box
                            sx={ {
                              margin  : "4px 6px",
                              wordWrap: "break-word"
                            } }
                          >
                            <Typography
                              sx={ {
                                fontSize  : "1.5vh",
                                color     : "#707070",
                                lineHeight: "15px",
                                whiteSpace: "pre-line"
                              } }
                            >
                              { investmentAllocation.value }
                            </Typography>
                          </Box>
                        </Box>
                      }
                    </Box>
                  </Box>
                );
              
              }
            ) }
          </Box>

          {/* </Box> */ }
          {/* --- */ }

          { props.isEdit ?
            <Box sx={ { position: "absolute", left: "65px", bottom: "-20px" } }>
              <ReferenceLinkTooltip
                title={
                  "Revisit the notes created in Funding Section of the Financial Module"
                }
              >
                <Button
                  style={ {
                    // position: "absolute",
                    textTransform : "none",
                    textDecoration: "underline",
                    fontSize      : "12px",
                    // top: "-20px",
                    // right: "-10px",
                    padding       : "0px",
                    fontWeight    : "bold",
                    whiteSpace    : "pre-line"
                  } }
                  onClick={ AskFundingDialogopen }
                >
                  Funding
                </Button>
              </ReferenceLinkTooltip>
              <Dialog
                open={ askFundingdialogbox }
                onClose={ AskFundingDialogclose }
                PaperProps={ {
                  style: {
                    borderRadius   : "20px",
                    backdropFilter : "blur(100px)",
                    backgroundColor: "#ffffff",
                    boxShadow      : "0px 3px 6px #00000029",
                    // border: "1px solid #707070",
                    width          : "70%"
                  }
                } }
                // BackdropProps={{ invisible: true }}
                //
                maxWidth="false"
                fullWidth
              >
                <DialogContent sx={ { padding: "40px" } }>
                  <Box
                    sx={ {
                      width   : "100%",
                      // height: "60vh",
                      // display: "flex",
                      // alignItems: "center",
                      // justifyContent: "center",
                      position: "relative"
                    } }
                  >
                    <PitchFinancialsFunding />
                    <IconButton
                      onClick={ () => {

                        AskFundingDialogclose();
                      
                      } }
                      sx={ {
                        position: "absolute",
                        right   : "-40px",
                        top     : "-40px"
                      } }
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </Box>
                </DialogContent>
              </Dialog>
            </Box>
            :
            <></>
          }
        </Box>
      </Box>
    </Box>
  </div>
  ;

}
