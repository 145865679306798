import React from "react";
// import { useSelector } from "react-redux";
import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Card from "./Card";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  root: {
    width          : "100%",
    backgroundColor: "#ffddc6",
    marginLeft     : theme.spacing(1),
    wordWrap       : "break-word"
  },
  scroll: {
    maxHeight             : "500px",
    overflow              : "auto",
    overflowX             : "hidden",
    // overflowY: 'auto',
    margin                : 0,
    padding               : 0,
    listStyle             : "none",
    height                : "100%",
    "&::-webkit-scrollbar": {
      width: "0.4em"
    },
    "&::-webkit-scrollbar-track": {
      boxShadow      : "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline        : "1px solid green"
    }
  },
  title: {
    padding   : theme.spacing(1, 1, 1, 1),
    marginLeft: theme.spacing(1.5)
    // fontWeight: "bold",
  },
  wrapper: {
    flex       : "0 0 300px",
    marginRight: "16px",
    marginTop  : theme.spacing(1)
  },
  editable: {
    marginLeft  : theme.spacing(-1),
    wordWrap    : "break-word",
    padding     : theme.spacing(0, 1, 0, 1),
    boxShadow   : "inset 0 0 0 2px #0079bf",
    // width: "100%",
    borderRadius: 4
  },
  name: {
    fontSize  : "17px",
    fontWeight: "bold"
  },
  heading: {
    fontWeight: "bold"
  },
  statusText: {
    backgroundColor: props => props.statusColor,
    padding        : "0px 5px"
  },
  subContent: {
    fontSize: "15px"
  }
}));

const List = ({ milestone, milestoneindex }) => {

  // let { user, venture } = useSelector(state => {

  //   return { ...state };
  
  // });
  const statusColors = {
    "Not Started": "#5fafff",
    "In-Progress": "#ff8c24",
    "Completed"  : "#08910B",
    "On-Hold"    : "#9381ff"
  };
  const classes = useStyles({ statusColor: statusColors[milestone.status] });


  return (
    <div className={classes.wrapper} key={milestoneindex}>
      <div >
        <Paper
          elevation={0}
          className={classes.root}
        >
          <div
            className={classes.title}
          >
            <div style={{ position: "relative" }}>
              <div className={classes.name}>{milestone.name}</div>
              <div className={classes.subContent}><span className={classes.heading}>Date:</span> {moment(milestone.startDate).format("l")} - {moment(milestone.endDate).format("l")}</div>
              <div className={classes.subContent}><span className={classes.heading}>Status:</span> <span className={classes.statusText}>{milestone.status}</span></div>
            </div>
          </div>
          <div >
            <div className={classes.scroll}>
              {milestone.activities.length > 0 && milestone.activities.map((activity, activityindex) => {

                return <Card
                  activity={activity}
                  activityindex={activityindex}
                />;
              
              })}

            </div>
          </div>
        </Paper>
      </div>
    </div>
  );

};

export default List;
