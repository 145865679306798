/* eslint-disable camelcase */
import {
  Button, Grid
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BackNextButton from "../../../../components/BackNextButton";
import IndividualSectionHeaders from "../../../../components/IndividualSectionHeaders";
import ModuleSections from "../../../../components/ModuleSections";
import { useUnmount } from "../../../../components/useUnmount";
import Data from "../../../../Data/Gtm.json";
import {
  createGtmComment,
  deleteGtmComment,
  updateGtmComment
} from "../../../../services/comment";
import { getGtm, updateGtmPositioningValues } from "../../../../services/gtm";
import IndividualUsecase from "./IndividualUsecase";
import styles from "./Positioning.module.css";

const useStyles = makeStyles(() => ({
  customWidth: {
    maxWidth       : 500,
    fontSize       : 10,
    backgroundColor: "white",
    color          : "#707070",
    boxShadow      : "0px 3px 6px #00000029",
    border         : "1px solid #707070",
    borderRadius   : 10,
    whiteSpace     : "pre-wrap"
  },
  helpicon: {
    color   : "#071AFF",
    fontSize: "15px"
  },
  question: {
    fontWeight     : "bold",
    padding        : "10px 30px",
    color          : "#393939",
    backgroundColor: "#F7F7F7",
    position       : "relative"
  },
  textarea: {
    "& .MuiInputBase-input": {
      fontSize: "16px",
      padding : "10px 20px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    }
  },
  gridbox: {
    boxShadow    : "0px 3px 6px #5F5E5E2B",
    borderRadius : "10px",
    marginBottom : "30px",
    flexDirection: "column"
  },
  addbtn: {
    backgroundColor: "#071AFF",
    textTransform  : "capitalize",
    borderRadius   : "10px"
  },
  removebtn: {
    backgroundColor: "#FFFFFF",
    textTransform  : "capitalize",
    border         : "solid 1px #ff7f5c",
    color          : "#ff7f5c",
    cursor         : "pointer",
    fontWeight     : "bold",
    marginLeft     : "20px",
    borderRadius   : "10px",
    "&:hover"      : {
      border: "solid 1px #ff7f5c"
    }
  },
  removeiconbtn: {
    position: "absolute",
    right   : "97%",
    bottom  : "60%",
    color   : "#ff7f5c"
  },
  removeicon: {
    fontSize: "30px"
  }
}));

const Usecase = () => {

  const [ usecases, setUsecases ] = useState([]);
  const [ comments, setComments ] = useState([]);
  const [ dialogbox, setDialog ] = useState(false);
  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const [ isdelete, setIsdelete ] = useState(false);
  const history = useHistory();
  const classes = useStyles({ isdelete });
  const content = Data.gtmpositioning;
  const data = Data.positioningusecase;
  const [ isDataLoading, setIsDataLoading ] = useState(true);

  let { user, venture } = useSelector(state => {

    return { ...state };
  
  });

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);
    
    } else {

      setCollapsecomment(true);
    
    }
  
  };

  useEffect(() => {

    fetchGtm();
  
  }, []);

  const fetchGtm = async () => {

    setIsDataLoading(true);

    try {

      const response = await getGtm(venture.gtm);
      // console.log(response.data);

      setUsecases(response.data.positioning.usecases);
      setComments(response.data.comments.positioning.usecases);
      setIsDataLoading(false);
    
    } catch (error) {

      console.error(error);
      setIsDataLoading(false);
    
    }
  
  };

  // To get the updated usestate value to save in useEffect cleanup
  const detailsRef = useRef(usecases);

  detailsRef.current = usecases;

  let isSubmitting = false;

  // useUnmount => custom hook to save while navigating
  useUnmount(() => {

    if (isDataLoading == false && !isSubmitting) {

      handleSave();
    
    }
  
  });

  const handleSave = async () => {

    try {

      await updateGtmPositioningValues(
        venture.gtm,
        "usecases",
        detailsRef.current
      );
      
      return true;
    
    } catch (error) {

      console.error(error);
      
      return false;
    
    }
  
  };

  const handleChange = (value, index) => {

    const newusecases = [ ...usecases ];

    newusecases[index] = value;
    setUsecases(newusecases);
  
  };

  const addRow = async () => {

    const newusecases = [ ...usecases ];

    newusecases.push("");
    await updateGtmPositioningValues(venture.gtm, "usecases", newusecases);
    setUsecases(newusecases);
  
  };

  const removeRow = async index => {

    const newusecases = [ ...usecases ];

    newusecases.splice(index, 1);
    setUsecases(newusecases);
    await updateGtmPositioningValues(venture.gtm, "usecases", newusecases);
    setIsdelete(false);
  
  };

  const handleSubmit = async () => {

    try {

      isSubmitting = true;
      if(isDataLoading == false){

        await updateGtmPositioningValues(venture.gtm, "usecases", usecases);
        
      }
      history.push("/gtmpositioningdifferentiation");
      isSubmitting = false;
    
    } catch (error) {

      isSubmitting = false;
      console.error(error);
    
    }
  
  };

  // console.log(usecases);
  const pathname = history.location.pathname.substring(1);

  const addComment = async (commentText, replyTo) => {

    try {

      const response = await createGtmComment(
        commentText,
        user._id,
        "positioning",
        "usecases",
        venture.gtm,
        replyTo,
        window.location.href,
        "GTM > Positioning > Use Cases"
      );

      setComments(response.data.comments.positioning.usecases);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateComment = async (commentid, commentText) => {

    try {

      const response = await updateGtmComment(
        commentText,
        user._id,
        commentid,
        venture._id,
        window.location.href,
        "GTM > Positioning > Use Cases"
      );

      setComments(response.data.comments.positioning.usecases);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const deleteComment = async commentid => {

    try {

      const response = await deleteGtmComment(
        "positioning",
        "usecases",
        venture.gtm,
        commentid,
        user._id,
        window.location.href,
        "GTM > Positioning > Use Cases"
      );

      setComments(response.data.comments.positioning.usecases);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  return (
    <ModuleSections
      Collapsecomment={ Collapsecomment }
      comments={ comments }
      addComment={ addComment }
      deleteComment={ deleteComment }
      updateComment={ updateComment }>
      <div className="box-display-style-for-next-and-back-button-with-justify">
        <div className="box-indiv-header-con">
          <IndividualSectionHeaders
            mainheading={ "Positioning" }
            subheading={ "Use Cases" }
            headingcontent={
              "Give your hero a plan. Jot down some use cases or real-world scenarios that show how they can use your product to address their problem/need."
            }
            isGtm={ true }
            // isGtmChevron={true}
            Dialogopen={ Dialogopen }
            Dialogclose={ Dialogclose }
            dialogbox={ dialogbox }
            collapse_comment_function={ collapse_comment_function }
            content={ content }
            isGtmChevron={ pathname.includes("gtm") ? "gtmV" : "customer" }
            tooltip={
              "A use case is a specific real-world situation in which your product can be used. It describes how a user/customer could use your product to accomplish a goal.\n\nA good use case should contain 3 elements:\n1. Actor: The user or group of users interacting with the product\n2. System: The process in which they’re using the product to achieve an outcome\n3. Goal: The positive outcome the users are trying to achieve.\n\nFor a more sophisticated use case description, you may also include elements such as:\n● Other stakeholders: who have a vested interest in the positive outcomes, even if not direct users of the product\n● Prerequisites: conditions which must be met or assumptions that have be validated before a use case can be performed\n● Triggers: events that occur for a use case to initiate\n\nExample:\nMachinery technicians at a food packaging factory can remotely track health & performance of equipment via sensors installed on each machine and the web-app. Plant managers and machinery operators whose processes are affected by unexpected machine failures or downtimes, can improve productivity and meet output targets more effectively with proactive repairs and maintenance that reduce downtimes. Machine technicians will receive automated alerts regarding equipment performance issues that can be customized & get automatic reminders for regular maintenance, and a way to track & update status of these tasks."
            }
            tooltipWidth={ "500px" }
            isSaveButton={ true }
            handleSave={ handleSave }
          />
        </div>
        <Grid
          item
          xs={ 12 }
          id={ styles.maingrid }
          onClick={ () => {

            setIsdelete(false);
          
          } }>
          { usecases.map((usecase, index) => {

            return <IndividualUsecase
              isdelete={ isdelete }
              index={ index }
              classes={ classes }
              removeRow={ removeRow }
              data={ data }
              usecase={ usecase }
              handleChange={ handleChange }
            />;
          
          }) }
          <Grid container justifyContent="flex-end" mb={ 2 }>
            <Button
              variant="contained"
              className={ classes.addbtn }
              onClick={ () => {

                addRow();
              
              } }
              disabled={ venture.userRole === "Commenter" }>
              Add Usecase
            </Button>

            <Button
              variant="outlined"
              className={ classes.removebtn }
              onClick={ e => {

                e.stopPropagation();
                setIsdelete(true);
              
              } }
              disabled={ venture.userRole === "Commenter" || isdelete }>
              Delete Usecase
            </Button>
          </Grid>
        </Grid>
        <div className="ttribe-back-next-button-con">
          <BackNextButton
            restrictedBackClick={ () => {

              history.push("/gtmpositioningresearch");
            
            } }
            backClick={ () => {

              history.push("/gtmpositioningresearch");
            
            } }
            restrictedNextClick={ () => {

              history.push("/gtmpositioningdifferentiation");
            
            } }
            nextClick={ () => {

              handleSubmit();
            
            } }
          />
        </div>
      </div>
    </ModuleSections>
  );

};

export default Usecase;
