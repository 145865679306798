/* eslint-disable camelcase */
import {
  Grid, Typography
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BackNextButton from "../../../../components/BackNextButton";
import CustomMultilineTextfield from "../../../../components/CustomMultilineTextfield";
import IndividualSectionHeaders from "../../../../components/IndividualSectionHeaders";
import ModuleSections from "../../../../components/ModuleSections";
import Data from "../../../../Data/Gtm.json";
import { getGtm, updateGtmPositioningValues } from "../../../../services/gtm";

import styles from "./Positioning.module.css";

import { makeStyles } from "@mui/styles";
import HelpIconTooltip from "../../../../components/HelpIconTooltip";

import { useUnmount } from "../../../../components/useUnmount";
import {
  createGtmComment,
  deleteGtmComment,
  updateGtmComment
} from "../../../../services/comment";

const useStyles = makeStyles(() => ({
  customWidth: {
    maxWidth       : 500,
    fontSize       : 10,
    backgroundColor: "white",
    color          : "#707070",
    boxShadow      : "0px 3px 6px #00000029",
    border         : "1px solid #707070",
    borderRadius   : 10,
    whiteSpace     : "pre-wrap"
  },
  helpicon: {
    color   : "#071AFF",
    fontSize: "15px"
  },
  question: {
    color   : "#393939",
    fontSize: "15px"
    // fontWeight: "bold",
  }
}));

const Differentiation = () => {

  const [ details, setDetails ] = useState({
    proofPointOne      : "",
    proofPointTwo      : "",
    proofPointThree    : "",
    DifferentiatorOne  : "",
    DifferentiatorTwo  : "",
    DifferentiatorThree: ""
  });
  const [ comments, setComments ] = useState([]);
  const [ dialogbox, setDialog ] = useState(false);
  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  const content = Data.gtmpositioning;
  const data = Data.positioningdifferentiation;
  const [ isDataLoading, setIsDataLoading ] = useState(true);

  let { user, venture } = useSelector(state => {

    return { ...state };
  
  });

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);
    
    } else {

      setCollapsecomment(true);
    
    }
  
  };

  useEffect(() => {

    fetchGtm();
  
  }, []);

  const fetchGtm = async () => {

    setIsDataLoading(true);

    try {

      const response = await getGtm(venture.gtm);
      // console.log(response.data);

      setDetails(response.data.positioning.differentiation);
      setComments(response.data.comments.positioning.differentiation);
      setIsDataLoading(false);
    
    } catch (error) {

      console.error(error);
      setIsDataLoading(false);
    
    }
  
  };

  // To get the updated usestate value to save in useEffect cleanup
  const detailsRef = useRef(details);

  detailsRef.current = details;

  let isSubmitting = false;

  // useUnmount => custom hook to save while navigating
  useUnmount(() => {

    if (isDataLoading == false && !isSubmitting) {

      handleSave();
    
    }
  
  });

  const handleSave = async () => {

    try {

      await updateGtmPositioningValues(
        venture.gtm,
        "differentiation",
        detailsRef.current
      );
      
      return true;
    
    } catch (error) {

      console.error(error);
      
      return false;
    
    }
  
  };

  const handleChange = e => {

    setDetails(values => ({ ...values, [e.target.name]: e.target.value }));
  
  };

  const handleSubmit = async () => {

    try {

      isSubmitting = true;
      if(isDataLoading == false){

        await updateGtmPositioningValues(venture.gtm, "differentiation", details);
        
      }
      history.push("/gtmpositioningprogressone");
      isSubmitting = false;
    
    } catch (error) {

      isSubmitting = false;
      console.error(error);
    
    }
  
  };

  const pathname = history.location.pathname.substring(1);

  const addComment = async (commentText, replyTo) => {

    try {

      const response = await createGtmComment(
        commentText,
        user._id,
        "positioning",
        "differentiation",
        venture.gtm,
        replyTo,
        window.location.href,
        "GTM > Positioning > Proof Points & Differentiators"
      );

      setComments(response.data.comments.positioning.differentiation);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateComment = async (commentid, commentText) => {

    try {

      const response = await updateGtmComment(
        commentText,
        user._id,
        commentid,
        venture._id,
        window.location.href,
        "GTM > Positioning > Proof Points & Differentiators"
      );

      setComments(response.data.comments.positioning.differentiation);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const deleteComment = async commentid => {

    try {

      const response = await deleteGtmComment(
        "positioning",
        "differentiation",
        venture.gtm,
        commentid,
        user._id,
        window.location.href,
        "GTM > Positioning > Proof Points & Differentiators"
      );

      setComments(response.data.comments.positioning.differentiation);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  return (
    <ModuleSections
      Collapsecomment={ Collapsecomment }
      comments={ comments }
      addComment={ addComment }
      deleteComment={ deleteComment }
      updateComment={ updateComment }>
      <div className="box-display-style-for-next-and-back-button-with-justify">
        <div className="box-indiv-header-con">
          <IndividualSectionHeaders
            mainheading={ "Positioning" }
            subheading={ "Proof Points & Differentiators " }
            headingcontent={
              "How will your product help your customer? Draft ‘Proof Point’ and ‘Differentiator’ messages. "
            }
            isGtm={ true }
            Dialogopen={ Dialogopen }
            Dialogclose={ Dialogclose }
            dialogbox={ dialogbox }
            collapse_comment_function={ collapse_comment_function }
            content={ content }
            // isGtmChevron={true}
            isGtmChevron={ pathname.includes("gtm") ? "gtmV" : "customer" }
            isSaveButton={ true }
            handleSave={ handleSave }
          />
        </div>
        <Grid item xs={ 12 } id={ styles.maingrid }>
          { data.map((question, index) => {

            return (
              <Grid container mb={ 2 } key={ index }>
                <Grid item md={ 3 }>
                  <Typography className={ classes.question }>
                    { question.label }
                    { question.tooltip &&
                      // <Tooltip
                      //   disableFocusListener
                      //   title={question.tooltip}
                      //   placement="right-start"
                      //   classes={{ tooltip: classes.customWidth }}
                      // >
                      //   <IconButton>
                      //     <HelpIcon className={classes.helpicon}></HelpIcon>
                      //   </IconButton>
                      // </Tooltip>
                      <HelpIconTooltip
                        width="500px"
                        tooltipdata={ question.tooltip }
                      />
                    }
                  </Typography>
                </Grid>
                <Grid item md={ 8 }>
                  <CustomMultilineTextfield
                    width="100%"
                    borderRadius="5px"
                    // fontSize="16px"
                    minRows={ 4 }
                    maxRows={ 4 }
                    value={ details[question.name] }
                    name={ question.name }
                    handledetailsChange={ handleChange }
                  // placeholder={question.placeholder}
                  />
                </Grid>
              </Grid>
            );
          
          }) }
        </Grid>
        <div className="ttribe-back-next-button-con">
          <BackNextButton
            restrictedBackClick={ () => {

              history.push("/gtmpositioningusecases");
            
            } }
            backClick={ () => {

              history.push("/gtmpositioningusecases");
            
            } }
            restrictedNextClick={ () => {

              history.push("/gtmpositioningprogressone");
            
            } }
            nextClick={ () => {

              handleSubmit();
            
            } }
          />
        </div>
      </div>
    </ModuleSections>
  );

};

export default Differentiation;
