import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const config = {
  apiKey           : process.env.REACT_APP_apiKey,
  authDomain       : process.env.REACT_APP_authDomain,
  projectId        : process.env.REACT_APP_projectId,
  storageBucket    : process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId            : process.env.REACT_APP_appId,
  measurementId    : process.env.REACT_APP_measurementId
};

// initialize firebase app

const app = initializeApp(config);

// export

export const auth = getAuth(app);
export default app;
