import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box, Dialog,
  Grid, IconButton, Slide, Typography
} from "@mui/material";
import React, { forwardRef, useState } from "react";
import { useHistory } from "react-router-dom";
import CustomMultilineTextfield from "../../../components/CustomMultilineTextfield";
import PersonaDetails from "../Customer/TargetSegments/PersonaDetails";
import styles from "./Solution.module.css";

const Transition = forwardRef(function Transition (props, ref) {

  return <Slide direction="up" ref={ ref } { ...props } />;

});

const PersonaSolutionMapRow = props => {

  const [ personadialogbox, setPersonaDialog ] = useState(false);
  const history = useHistory();

  const eachPersona = props.eachPersona;
  const eachSegment = props.eachSegment;
  const segmentindex = props.segmentindex;
  const personaindex = props.personaindex;

  const personaDialogopen = () => {

    setPersonaDialog(true);
  
  };
  const personaDialogclose = () => {

    setPersonaDialog(false);
  
  };

  
  return (
    <Grid item>
      <Grid container justifyContent="space-around">
        <Grid item md={ 2 } id={ styles.personagrid }>
          <Box
            component="span"
            id={ styles.persona }
            onClick={ () => personaDialogopen() }
          >
            <img
              src={ `${process.env.REACT_APP_AWS_ASSETS_URL}${eachPersona.avatar}` }
              width="50px"
              height="50px"
            />
            <Typography sx={ { color: "#393939", textTransform: "capitalize" } }>
              { eachPersona.name }
            </Typography>
          </Box>
          <Dialog
            open={ personadialogbox }
            TransitionComponent={ Transition }
            keepMounted
            PaperProps={ {
              style: {
                borderRadius   : "20px",
                backdropFilter : "blur(10px)",
                backgroundColor: "rgba(255,255,255,0.80)",
                margin         : "20px"
              }
            } }
            maxWidth="md"
            fullWidth
          >
            <Box sx={ { alignSelf: "flex-end" } }>
              <IconButton
                component="span"
                onClick={ () => {

                  history.push({ hash: "" });
                  personaDialogclose();
                
                } }
              >
                <HighlightOffIcon />
              </IconButton>
            </Box>
            <PersonaDetails
              eachPersona={ eachPersona }
              segment={ eachSegment }
            />
          </Dialog>
        </Grid>
        <Grid item md={ 9 }>
          {/* <TextField
            type="text"
            multiline
            maxRows={2}
            minRows={2}
            placeholder="Describe your solution ideas or feature sets that will address this persona’s problems/ needs."
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-input.MuiInputBase-input": {
                fontSize: "14px",
              },
              backgroundColor: "white",
            }}
            value={eachPersona.SolutionGrid.SolutionCapability}
            onChange={(e) => {
              props.handleChange(
                "SolutionCapability",
                e.target.value,
                segmentindex,
                personaindex
              );
            }}
          /> */}
          <CustomMultilineTextfield
            value={ eachPersona?.SolutionGrid?.SolutionCapability }
            placeholder="Describe your solution ideas or feature sets that will address this persona’s problems/ needs."
            maxRows={ 2 }
            minRows={ 2 }
            width={ "100%" }
            // fontSize={"14px"}
            backgroundColor={ "white" }
            handledetailsChange={ e => {

              props.handleChange(
                "SolutionCapability",
                e.target.value,
                segmentindex,
                personaindex
              );
            
            } }
          />
        </Grid>
      </Grid>
    </Grid>
  );

};

export default PersonaSolutionMapRow;
