import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const ErrorMessage = props => {
  
  const Width = props.width;

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      style={ { width: Width ? Width : "100%" } }
    >
      <Box
        style={ {
          display        : "flex",
          padding        : props.padding ? props.padding : "10px 20px",
          border         : "solid 1px #EBBAB9",
          backgroundColor: "#FFEFEF",
          color          : "#FD1515",
          borderRadius   : "4px"
        } }
      >
        <Typography
          sx={ {
            color   : "#FD1515",
            fontSize: props.msgfont ? props.msgfont : "14px"
            // fontWeight: "bold",
          } }
        >
          { props.errormsg }
        </Typography>
      </Box>
    </Grid>
  );

};

export default ErrorMessage;
