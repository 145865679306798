import React from "react";
import app from "../../firebase";
import { applyActionCode, getAuth } from "firebase/auth";
import {
  Button,
  Grid,
  Box,
  Typography
} from "@mui/material";
import BoxWithoutIcon from "../../components/BoxWithoutIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
// import UndrawMessage from "../../assets/images/undraw_message_sent_re.svg";

const RedirectPage = ({ history, location }) => {

  const auth = getAuth(app);
  const useQuery = () => {

    return new URLSearchParams(location.search);
  
  };
  const query = useQuery();
  const href = window.location.href;
  const oobCode = query.get("oobCode");
  const mode = query.get("mode");

  const handleVerifyEmail = () => {

    applyActionCode(auth, oobCode).then(() => {

      return;

    });
  
  };

  return (
    <>
      {mode === "signIn" ?
        history.push({
          pathname: "/register",
          state   : { href: href }
        })
        : mode === "verifyEmail" ? <>{handleVerifyEmail()}

          <BoxWithoutIcon>
            <Grid
              style={{
                display      : "flex",
                flexDirection: "column",
                alignItems   : "center",
                paddingTop   : "50px",
                height       : "100%"
              }}
            >
              <Box
                style={{
                  display      : "flex",
                  alignItems   : "center",
                  flexDirection: "column"
                }}
              >
                {/* <UndrawMessage
                style={{ width: "150px", height: "150px", margin: "10px 0px" }}
              /> */}
                <Typography variant="subtitle1" gutterBottom component="div" fontWeight="bold">
                  {/* Verify your email by clicking on the link sent to your account. */}
                  <FontAwesomeIcon icon={faCircleCheck} className="t360-modal-content-fa-icon" /> Your account has been sucessfully verified.
                </Typography>
                <Button
                  style={{ cursor: "pointer", color: "#8129CE", marginTop: "10px", textTransform: "capitalize", fontWeight: "bold" }}
                  onClick={() => { history.push("/login"); }}
                >
                Login
                </Button>
              </Box>
            </Grid>
          </BoxWithoutIcon>
        </> :
          history.push({
            pathname: "/resetpassword",
            state   : { oobCode: oobCode }
          })
      }
    </>
  );

};

export default RedirectPage;
