import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import ReactApexChart from "react-apexcharts";

import {
  Box, FormControl, Dialog,
  DialogContent, MenuItem, Select, IconButton, Tooltip
} from "@mui/material";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";

const BurnRateVsFundBal = props => {

  const [ chartdata, setChartdata ] = useState([]);
  const [ selectedchartdata, setSelectedChartdata ] = useState([]);
  const currency = props?.currency || "- I";
  // const { venture } = useSelector(state => ({ ...state }));
  const YearsList = props?.year;
  const [ dialogbox, setDialogBox ] = useState(false);
  const currentYear = new Date().getFullYear();
  const [ year, setYear ] = useState(YearsList && YearsList.includes(currentYear) ? currentYear : YearsList[0]);
  const history = useHistory();
  const chartTitle = props.chartTitle;
  const moreDetails = props.moreDetails;
  const tableRow = [ "Monthly Burn Rate", "Funding Balance" ];

  useEffect(() => {

    updateChartdata([ "monthlyBurnRate", "fundingBalance" ]);

  }, [ props?.table ]);

  const updateChartdata = chartTitle => {

    let data = [];
    const allTableData = props?.table;

    if (allTableData && allTableData.length > 0) {

      allTableData.map(frData => {

        data.push({
          year: frData?.year,
          name: chartTitle[1],
          type: "bar",
          data: frData?.frOutlook?.fundingBalance
        });
        data.push({
          year: frData?.year,
          name: chartTitle[0],
          type: "line",
          data: frData?.frOutlook?.monthlyBurnRate
        });
    
      });
    
      setYear(YearsList && YearsList[0]);
      
      if (data && data.length) {
        
        setChartdata(data);
        setSelectedChartdata([ data[0], data[1] ]);

      }

    } else {

      setChartdata([ { name: " No Data", data: [] } ]);
      setYear("");
      setSelectedChartdata([ { name: " No Data", data: [] } ]);

    }

  };

  const numberWithCommas = num => {

    if (currency) {

      let newNum = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      return newNum;

    } else {

      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    }
  
  };

  const handleSelect = e => {

    setYear(e.target.value);
    const selectedData = [];

    if (chartdata) {

      chartdata.map(d => {

        if (d.year === Number(e.target.value)) {

          selectedData.push({ name: d.name, data: d.data });

        }
      
      });

      setSelectedChartdata(selectedData);

    }
  
  };

  let options = {
    chart: {
      // height: 350,
      type   : "line",
      toolbar: {
        show : true,
        tools: {
          download   : true,
          selection  : false,
          zoom       : false,
          zoomin     : false,
          zoomout    : false,
          pan        : false,
          reset      : false,
          customIcons: []
        },
        autoSelected: "pan"
      }
    },
    noData: {
      text         : "No Data to show",
      align        : "center",
      verticalAlign: "middle"
    },
    colors : [ "#ff4a33", "#87BFFF" ],
    // plotOptions: {
    //     bar: {
    //         horizontal: false,
    //         columnWidth: '70%',
    //         endingShape: 'rounded'
    //     },
    // },
    markers: {
      size              : [ 6, 6 ],
      showNullDataPoints: false
    },
    dataLabels: {
      // enabled: props.isMultibar ? false : true,
      enabled  : false,
      formatter: function (val) {

        return numberWithCommas(val);
      
      }
    },
    stroke: {
      width: [ 0, 4 ],
      curve: "straight"
    },
    xaxis: {
      categories: [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ],
      title: {
        text   : "Time Period", offsetX: 0,
        offsetY: 90
      },
      position: "bottom"
    },
    yaxis: [
      {
        title: {
        //   text: `${tableRow[1]} ${currency.split(" ")[0].trim()}`,
          text: tableRow[1]
        },
        labels: {
          formatter: val => { return numberWithCommas(val); }
        }
      }, {
        opposite: true,
        // title: { text: `${tableRow[0]} ${currency.split(" ")[0].trim()}`},
        title   : { text: tableRow[0] },
        labels  : {
          formatter: val => { return numberWithCommas(val);}
        }
      }
    ],
    // yaxis: {
    //     title: {
    //         text: `Cost ${currency.split(" ")[0]}`
    //         // text: "check"
    //     },
    //     labels: {
    //         formatter: (val) => { return numberWithCommas(val) },
    //     },
    // },
    // fill: {
    //     opacity: 1
    // },
    tooltip: {
      y: {
        formatter: function (val) {

          return `${numberWithCommas(val)} `;
        
        }
      }
    }
  };

  return <div className="tribe-chart-page-con">
    <div className="tribe-fin-chart-year-sel-main-con">
      <div className="tribe-fin-chart-year-sel-title-con"><p> <span >{chartTitle}</span></p></div>
      <FormControl className="tribe-fin-chart-year-sel-con" fullWidth>
        <Select
          className="tribe-fin-chart-year-sel"
          value={year}
          onChange={handleSelect}
          // disabled={venture.userRole === "Commenter"}
        >
          {YearsList.map(year => {

            return <MenuItem value={year}>{year}</MenuItem>;
          
          })}
        </Select>
      </FormControl>
      <Tooltip
        enterTouchDelay={0}
        disableFocusListener
        title="View"
        placement="top"
      >
        <IconButton
          component="span"
          onClick={() => {

            setDialogBox(true);
          
          }}>
          <OpenInFullIcon />
        </IconButton>
      </Tooltip>
    </div>
    <ReactApexChart options={options} series={selectedchartdata} type="line" height="95%" width="95%" className="tribe-apex-chart-con"/>
    <div className="fin-chart-more-detail-con">
      <p>For details click <span onClick={() => history.push(moreDetails.url)}>{moreDetails.page}</span></p>
    </div>
    <Dialog
      open={dialogbox}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        style: {
          borderRadius  : "20px",
          backdropFilter: "blur(45px)",
          background:
                        "#FFFFFF 0% 0% no-repeat padding-box",
          boxShadow: "0px 3px 6px #00000029",
          border   : "1px solid #707070"
        }
      }}
    >
      <DialogContent>
        <Box sx={{ textAlign: "center" }}>

          <div className="tribe-fin-chart-year-sel-main-con">
            <div className="tribe-fin-chart-year-sel-title-con"><p> <span>{chartTitle}</span></p></div>
            <FormControl className="tribe-fin-chart-year-sel-con" fullWidth>
              <Select
                className="tribe-fin-chart-year-sel"
                value={year}
                onChange={handleSelect}
                // disabled={venture.userRole === "Commenter"}
              >
                {YearsList.map(year => {

                  return <MenuItem value={year}>{year}</MenuItem>;
                
                })}
              </Select>
            </FormControl>
            <Tooltip
              enterTouchDelay={0}
              disableFocusListener
              title="Close"
              placement="right"
            >
              <IconButton
                component="span"
                onClick={() => {

                  setDialogBox(false);
                
                }}>
                <HighlightOffIcon />
              </IconButton>
            </Tooltip>
          </div>
          <ReactApexChart options={options} series={selectedchartdata} type="line" height="350px" width="95%" className="tribe-apex-chart-con"/>
          <div className="fin-chart-more-detail-con">
            <p>For details click <span onClick={() => history.push(moreDetails.url)}>{moreDetails.page}</span></p>
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  </div>;

};

export default BurnRateVsFundBal;
