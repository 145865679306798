/* eslint-disable camelcase */
import ArrowRightSharpIcon from "@mui/icons-material/ArrowRightSharp";
import {
  Box, Grid, Typography
} from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import BackNextButton from "../../../../components/BackNextButton";
import IndividualSectionHeaders from "../../../../components/IndividualSectionHeaders";
import ModuleSections from "../../../../components/ModuleSections";
import Data from "../../../../Data/Gtm.json";
import styles from "./GtmPositioningProgress.module.css";

export default function GtmPositioningProgress () {

  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const [ dialogbox, setDialog ] = useState(false);
  const history = useHistory();
  const pathname = history.location.pathname.substring(1);
  const content = Data[pathname];

  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);
    
    } else {

      setCollapsecomment(true);
    
    }
  
  };

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  return (
    <ModuleSections Collapsecomment={ Collapsecomment }>
      <div className="box-display-style-for-next-and-back-button-with-justify">
        <div className="box-indiv-header-con">
          <IndividualSectionHeaders
            mainheading={ content.title }
            subheading={ content.subtitle }
            // headingcontent={content.description}
            isGtm={ true }
            Dialogopen={ Dialogopen }
            Dialogclose={ Dialogclose }
            dialogbox={ dialogbox }
            collapse_comment_function={ collapse_comment_function }
            content={ content }
            isGtmChevron={ "none" }
            isexpand={ true }
          />
        </div>
        <Grid container xs={ 12 } id={ styles.mainGrid }>
          <Box id={ styles.progressContentBox }>
            {/* head text box */ }

            <Box
              sx={ {
                margin      : "10px 60px 5px",
                textAlign   : "center",
                marginBottom: "20px",
                marginTop   : "40px"
              } }>
              { pathname.includes("gtmpositioningprogressone") ?
                <Typography>
                  Great! You’ve crafted an impressive transformation story for
                  your customer. Now let’s define positioning statements for
                  your product. Click [Next] to continue.
                </Typography>
                :
                <Typography>
                  We’re almost there. Keeping in mind the work you’ve done in
                  the last two steps, let’s think about each of your target user
                  personas, and craft value proposition messages that will
                  appeal to each one. Click [Next] to continue.
                </Typography>
              }
            </Box>

            {/* --------------- */ }

            {/* top text content box */ }
            <Box id={ styles.textContentBoxTop }>
              <Box id={ styles.textTypoBox }>
                <Typography id={ styles.textTypo }>
                  {/* The Hero-Your Customer */ }
                  Craft Your ‘Hero Transformation’ Story
                </Typography>
              </Box>

              <Box id={ styles.midTextContentBox }>
                <Typography id={ styles.midTextTypo }>
                  Derive Positioning Statements
                </Typography>
              </Box>

              <Box id={ styles.textTypoBox }>
                <Typography id={ styles.textTypo }>
                  {/* Meets a Guide-You */ }
                  Create Value Messages For Your Users
                </Typography>
              </Box>
            </Box>

            {/* --------------- */ }

            <Box id={ styles.imagesMainBox }>
              <Box
                onClick={ () => history.push("/gtmpositioningstory") }
                sx={ { cursor: "pointer" } }>
                <img
                  id={ styles.positioning1 }
                  src="/images/positioning-1.png"
                  alt=""
                />
              </Box>

              <Box id={ styles.dashBorderBox }>
                <Box id={ styles.dashBorderTypoBox }>
                  { pathname.includes("gtmpositioningprogressone") ?
                    <Typography id={ styles.dashBorderTypo }>
                      {/* You are here */ }
                    </Typography>
                    :
                    <></>
                  }
                </Box>
                <Box id={ styles.dashBorder }>
                  <ArrowRightSharpIcon id={ styles.arrowIcon } />
                </Box>
              </Box>
              <Box
                onClick={ () => history.push("/gtmpositioninglongerdescription") }
                sx={ { cursor: "pointer" } }>
                { pathname.includes("gtmpositioningprogressone") ?
                  <img
                    id={ styles.positioningBlue1 }
                    src="/images/positioningBlue-2.png"
                    alt=""
                  />
                  :
                  <img
                    style={ { width: "90px" } }
                    src="/images/positioning-2.png"
                    alt=""
                  />
                }
              </Box>

              <Box id={ styles.dashBorderBox }>
                <Box id={ styles.dashBorderTypoBox }>
                  { pathname.includes("gtmpositioningprogresstwo") ?
                    <Typography id={ styles.dashBorderTypo }>
                      {/* You are here */ }
                    </Typography>
                    :
                    <></>
                  }
                </Box>
                <Box id={ styles.dashBorder }>
                  <ArrowRightSharpIcon id={ styles.rightArrowIcon } />
                </Box>
              </Box>
              <Box
                onClick={ () => history.push("/gtmpositioningmessaging") }
                sx={ { cursor: "pointer" } }>
                { pathname.includes("gtmpositioningprogresstwo") ?
                  <img
                    id={ styles.positioningBlue1 }
                    src="/images/positioningBlue-3.png"
                    alt=""
                  />
                  :
                  <img
                    style={ { width: "90px" } }
                    src="/images/positioning-3.png"
                    alt=""
                  />
                }
              </Box>
            </Box>
            {/* text content box */ }
            {/* <Box id={styles.textContentBox}>
            <Box id={styles.textTypoBoxBottom}>
              <Typography id={styles.textTypoBottom}>
             
                Use the Storyboard to build your product use-case scenarios, its
                differentiation and target customer before-after states.
              </Typography>
            </Box>

            <Box id={styles.midTextContentBox}>
              <Typography id={styles.midTextTypoBottom}>
           
                Derive your product or brand's <br /> positioning statements
              </Typography>
            </Box>

            <Box id={styles.textTypoBoxBottom}>
              <Typography id={styles.textTypoBottom}>
            
                Add effective messaging to reach your target personas.
              </Typography>
            </Box>
          </Box> */}

            {/* ------------- */ }
          </Box>
        </Grid>
        <div className="ttribe-back-next-button-con">
          <BackNextButton
            restrictedBackClick={ () => {

              {

                pathname.includes("gtmpositioningprogressone")
                  ? history.push("/gtmpositioningdifferentiation")
                  : history.push("/gtmpositioningoneliner");
              
              }
            
            } }
            backClick={ () => {

              // history.push("/gtmpositioningdifferentiation");

              {

                pathname.includes("gtmpositioningprogressone")
                  ? history.push("/gtmpositioningdifferentiation")
                  : history.push("/gtmpositioningoneliner");
              
              }
            
            } }
            restrictedNextClick={ () => {

              {

                pathname.includes("gtmpositioningprogressone")
                  ? history.push("/gtmpositioninglongerdescription")
                  : history.push("/gtmpositioningmessaging");
              
              }
            
            } }
            nextClick={ () => {

              {

                pathname.includes("gtmpositioningprogressone")
                  ? history.push("/gtmpositioninglongerdescription")
                  : history.push("/gtmpositioningmessaging");
              
              }
            
            } }
          />
        </div>
      </div>
    </ModuleSections>
  );

}
