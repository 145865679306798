import axios from "./axiosService";

// import axios from "axios";

export const getDigitalMarket = async gtmid => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/get_digitalmarketing`,
    { gtmid }
  );

};

export const createDigitalContent = async (details, gtmid, parentVenture) => {

  return await axios.post(`${process.env.REACT_APP_ADDRESS}/create_content`, {
    details,
    gtmid,
    parentVenture
  });

};

export const createDigitalCampaign = async (details, gtmid, parentVenture) => {

  return await axios.post(`${process.env.REACT_APP_ADDRESS}/create_campaign`, {
    details,
    gtmid,
    parentVenture
  });

};

export const updateContent = async (
  details,
  digitalcontentid,
  contenttypeindex,
  parentVenture
) => {

  return await axios.post(`${process.env.REACT_APP_ADDRESS}/update_content`, {
    details,
    digitalcontentid,
    contenttypeindex,
    parentVenture
  });

};

export const updateCampaign = async (details, campaignid) => {

  return await axios.post(`${process.env.REACT_APP_ADDRESS}/update_campaign`, {
    details,
    campaignid
  });

};

export const setContent = async (contentid, complete) => {

  return await axios.post(`${process.env.REACT_APP_ADDRESS}/set_content`, {
    contentid,
    complete
  });

};

export const updateDigitalContent = async (details, contentid, parentVenture) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/update_digitalcontent`,
    { details, contentid, parentVenture }
  );

};

export const getDigitalContent = async contentid => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/get_digitalcontent`,
    { contentid }
  );

};

export const updateDigitalCampaign = async (details, campaignid, parentVenture) => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/update_digitalcampaign`,
    { details, campaignid, parentVenture }
  );

};

export const getDigitalCampaign = async campaignid => {

  return await axios.post(
    `${process.env.REACT_APP_ADDRESS}/get_digitalcampaign`,
    { campaignid }
  );

};

export const deleteDigitalCampaignById = async digitalcampaignid => {

  return await axios.delete(
    `${process.env.REACT_APP_ADDRESS}/campaign/${digitalcampaignid}`
  );

};

export const deleteDigitalContentById = async digitalcontentid => {

  return await axios.delete(
    `${process.env.REACT_APP_ADDRESS}/content/${digitalcontentid}`
  );

};
