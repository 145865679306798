/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ModuleSections from "../../../components/ModuleSections";
import { Grid, Box } from "@mui/material";
import IndividualSectionHeaders from "../../../components/IndividualSectionHeaders";
import BackNextButton from "../../../components/BackNextButton";
import styles from "./Monetisation.module.css";
import {
  createMonetisationComment,
  deleteMonetisationComment,
  updateMonetisationComment
} from "../../../services/comment";
import { useSelector } from "react-redux";

export default function Monetisation(props) {

  const [ comments, setComments ] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [ isexpand, setIsexpand ] = useState(false);
  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const [ dialogbox, setDialog ] = useState(false);

  const { user, venture } = useSelector(state => ({ ...state }));
  const history = useHistory();

  useEffect(() => {

    setComments(props.comments);
  
  }, [ props ]);

  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);
    
    } else {

      setCollapsecomment(true);
    
    }
  
  };

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  const addComment = async (commentText, replyTo) => {

    try {

      const response = await createMonetisationComment(
        commentText,
        user._id,
        props.sectionName,
        props.subSectionName,
        venture.monetisation,
        replyTo,
        window.location.href,
        `${props.mainheading} > ${props.subheading}`
      );

      setComments(
        response.data.comments[props.sectionName][props.subSectionName]
      );
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateComment = async (commentid, commentText) => {

    try {

      const response = await updateMonetisationComment(
        commentid,
        commentText,
        user._id,
        venture._id,
        window.location.href,
        `${props.mainheading} > ${props.subheading}`
      );

      setComments(
        response.data.comments[props.sectionName][props.subSectionName]
      );
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const deleteComment = async commentid => {

    try {

      const response = await deleteMonetisationComment(
        user._id,
        commentid,
        venture.monetisation,
        props.sectionName,
        props.subSectionName,
        window.location.href,
        `${props.mainheading} > ${props.subheading}`
      );

      setComments(
        response.data.comments[props.sectionName][props.subSectionName]
      );
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  
  return (
    <ModuleSections
      isexpand={isexpand}
      Collapsecomment={Collapsecomment}
      comments={comments}
      addComment={addComment}
      deleteComment={deleteComment}
      updateComment={updateComment}>
      <div className="box-display-style-for-next-and-back-button-with-justify">
        <div className="box-indiv-header-con">
          <Box id={styles.topBarMainBox}>
            <Box
              id={styles.bar}
              sx={{
                border: props.barOne ? "2px solid blue" : "2px solid #B1AAAA",

                backgroundColor: props.barOne ? "blue" : "#B1AAAA"
              }}></Box>
            <Box
              id={styles.bar}
              sx={{
                border: props.barTwo ? "2px solid blue" : "2px solid #B1AAAA",

                backgroundColor: props.barTwo ? "blue" : "#B1AAAA"
              }}></Box>
            <Box
              id={styles.bar}
              sx={{
                border: props.barThree ? "2px solid blue" : "2px solid #B1AAAA",

                backgroundColor: props.barThree ? "blue" : "#B1AAAA"
              }}></Box>
            <Box
              id={styles.bar}
              sx={{
                border: props.barFour ? "2px solid blue" : "2px solid #B1AAAA",

                backgroundColor: props.barFour ? "blue" : "#B1AAAA"
              }}></Box>
          </Box>
          <Box sx={{}}>
            <IndividualSectionHeaders
              mainheading={props.mainheading}
              subheading={props.subheading}
              headingcontent={""}
              isGtm={false}
              Dialogopen={Dialogopen}
              Dialogclose={Dialogclose}
              dialogbox={dialogbox}
              collapse_comment_function={collapse_comment_function}
              isexpand={isexpand}
              content={{
                dialogpurposetitle: "What is this section about? ",
                dialogpurpose:
                  "Monetization literally means converting something into money. For a business, it defines how your venture will make money from its assets, goods, or services.\nThe first step is to figure out what your commercial model will be, i.e. what activities or goods / services have value for customers that can help you generate revenue, and how customers will acquire your offerings from you. For e.g. will you sell, rent, lease? Would customers pay just once, or on a recurring basis, or only for how much they use when they use it? Will customers buy directly from you or from other merchants? \nThis section will walk you through a few steps to help you get clarity on questions like these. Don’t worry about specific price points yet. You’ll get to that in the next section.",
                dialoganalogytitle:
                  "Why do I need to go through this exercise?",
                dialoganalogy:
                  "Unless you are planning a philanthropic/ charity venture, you will need a plan for how and from where you will generate revenues to run and grow your business. If you plan to raise funds, ‘How will you make money?’ will be among the top questions, if not the first, that any investor will ask. Also, if you’re in a software or a digitally-enabled business, your commercial model will often impact product design decisions and engineering roadmap as well.  So, we designed this section to step you through a structured process to help bring clarity to your business model and give you confidence that your time & sweat will lead to a commercially viable venture. Good luck!  ",
                dialogguide: []
              }}
              isSaveButton={true}
              handleSave={props.handleSave}
            />
          </Box>
        </div>
        <Grid item xs={12} id={styles.monetisationMainGrid}>
          <Box sx={{}}>{props.children}</Box>
        </Grid>

        <div className="ttribe-back-next-button-con">
          {history.location.pathname == "/commercialmodel" ?
            <BackNextButton
              restrictedBackClick={() => {

                history.push("/monetisationintro");
              
              }}
              backClick={() => {

                history.push("/monetisationintro");
              
              }}
              restrictedNextClick={() => {

                history.push("/channels");
              
              }}
              nextClick={async () => {

                const response = await props.handleSubmit();

                if (response) history.push("/channels");
              
              }}
            />
            : history.location.pathname == "/channels" ?
              <BackNextButton
                restrictedBackClick={() => {

                  history.push("/commercialmodel");
                
                }}
                backClick={() => {

                  props.handleSubmit();
                  history.push("/commercialmodel");
                
                }}
                restrictedNextClick={() => {

                  history.push("/partnershipdetails");
                
                }}
                nextClick={() => {

                  props.handleSubmit();

                  props.selectedChannels.includes("Channel Partners")
                    ? history.push("/partnershipdetails")
                    : history.push("/businessgoals");
                
                }}
              />
              : history.location.pathname == "/businessgoals" ?
                <BackNextButton
                  restrictedBackClick={() => {

                    history.push("/partnershipdetails");
                  
                  }}
                  backClick={() => {

                    props.handleSubmit();
                    props.selectedChannels.includes("Channel Partners")
                      ? history.push("/partnershipdetails")
                      : history.push("/channels");
                  
                  }}
                  restrictedNextClick={() => {

                    history.push("/pricing");
                  
                  }}
                  nextClick={() => {

                    props.handleSubmit();
                    history.push("/pricing");
                  
                  }}
                />
                :
                history.location.pathname == "/partnershipdetails" ?
                  <BackNextButton
                    restrictedBackClick={() => {

                      history.push("/channels");
                    
                    }}
                    backClick={() => {

                      props.handleSubmit();
                      history.push("/channels");
                      // props.selectedChannels.includes("Channel Partners")
                      //   ? history.push("/partnershipdetails")
                      //   : history.push("/channels");
                    
                    }}
                    restrictedNextClick={() => {

                      history.push("/businessgoals");
                    
                    }}
                    nextClick={() => {

                      props.handleSubmit();
                      history.push("/businessgoals");
                    
                    }}
                  />
                  :
                  <></>
            
          }
        </div>
      </div>
    </ModuleSections>
  );

}
