/* eslint-disable camelcase */
import {
  Grid
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ModuleSections from "../../../components/ModuleSections";
import Datas from "../../../Data/Problem.json";
import {
  getProblemSolution,
  updateHypothesis
} from "../../../services/problemsolution";

import BackNextButton from "../../../components/BackNextButton";
import IndividualSectionHeaders from "../../../components/IndividualSectionHeaders";
import ProblemSolutionFirst from "../../../components/ProblemSolutionFirst";

// import { useUnmount } from "../../../components/useUnmount";
import {
  createProblemSolutionComment,
  deleteProblemSolutionComment,
  updateProblemSolutionComment
} from "../../../services/comment";
import styles from "./Problem.module.css";

const Hypothesis = () => {

  const [ details, setDetails ] = useState({ problem: "", rootCause: "" });
  const [ comments, setComments ] = useState([]);
  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const [ dialogbox, setDialog ] = useState(false);
  const [ isDataLoading, setIsDataLoading ] = useState(true);
  const history = useHistory();
  // const pathname = history.location.pathname.substring(1);

  let { user, venture } = useSelector(state => {

    return { ...state };
  
  });

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);
    
    } else {

      setCollapsecomment(true);
    
    }
  
  };

  const handleChange = (name, value) => {

    setDetails(values => ({ ...values, [name]: value }));
  
  };

  // To get the updated usestate value to save in useEffect cleanup
  const detailsRef = useRef(details);

  detailsRef.current = details;

  let isSubmitting = false;

  useEffect(() => {

    fetchProblemSolution();

    return () => {

      if (isDataLoading == false) {

        if (isSubmitting == false) {
    
          handleSave();

        }
  
      }

    
    };
  
  }, []);

  const fetchProblemSolution = async () => {

    setIsDataLoading(true);

    try {

      const response = await getProblemSolution(venture.problemSolution);
      // console.log(response.data);

      setDetails(response.data.hypothesis);
      setComments(response.data.comments.problem.hypothesis);
      setIsDataLoading(false);
    
    } catch (error) {

      console.error(error);
      setIsDataLoading(false);
    
    }
  
  };

  // console.log(details);


  const handleSave = async () => {

    try {

      await updateHypothesis(detailsRef.current, venture.problemSolution);
      
      return true;
    
    } catch (error) {

      console.error(error);
      
      return false;
    
    }
  
  };

  const handleSubmit = async () => {

    try {

      isSubmitting = true;

      if (isDataLoading == false) {

        await updateHypothesis(details, venture.problemSolution);

      }

      isSubmitting = false;

      history.push("/personaproblemmap");
    
    } catch (error) {

      isSubmitting = false;
      console.error(error);
    
    }
  
  };

  const addComment = async (commentText, replyTo) => {

    try {

      const response = await createProblemSolutionComment(
        commentText,
        user._id,
        "problem",
        "hypothesis",
        venture.problemSolution,
        replyTo,
        window.location.href,
        "Problem > Hypothesis"
      );

      setComments(response.data.comments.problem.hypothesis);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateComment = async (commentid, commentText) => {

    try {

      const response = await updateProblemSolutionComment(
        commentText,
        user._id,
        commentid,
        venture._id,
        window.location.href,
        "Problem > Hypothesis"
      );

      setComments(response.data.comments.problem.hypothesis);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const deleteComment = async commentid => {

    try {

      const response = await deleteProblemSolutionComment(
        "problem",
        "hypothesis",
        venture.problemSolution,
        commentid,
        user._id,
        window.location.href,
        "Problem > Hypothesis"
      );

      setComments(response.data.comments.problem.hypothesis);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  return (
    <ModuleSections
      Collapsecomment={ Collapsecomment }
      comments={ comments }
      addComment={ addComment }
      deleteComment={ deleteComment }
      updateComment={ updateComment }>
      <div className="box-display-style-for-next-and-back-button-with-justify">
        <div className="box-indiv-header-con">
          <IndividualSectionHeaders
            mainheading={ "Problem" }
            subheading={ "Hypothesis" }
            headingcontent={
              "Define the overarching problem that you've identified in the market."
            }
            isGtm={ false }
            Dialogopen={ Dialogopen }
            Dialogclose={ Dialogclose }
            dialogbox={ dialogbox }
            collapse_comment_function={ collapse_comment_function }
            content={ Datas.guidecontent }
            isSaveButton={ true }
            handleSave={ handleSave }
          />
        </div>
        <Grid item xs={ 12 } id={ styles.maingrid }>
          <ProblemSolutionFirst
            content={ Datas.hypothesis }
            firstvalue={ details.problem }
            firstname={ "problem" }
            secondvalue={ details.rootCause }
            secondname={ "rootCause" }
            handleChange={ handleChange }
          />
        </Grid>

        <div className="ttribe-back-next-button-con">
          <BackNextButton
            restrictedBackClick={ () => history.push("/problemintro") }
            backClick={ () => history.push("/problemintro") }
            restrictedNextClick={ () => history.push("/personaproblemmap") }
            nextClick={ () => {

              handleSubmit();
            
            } }
          />
        </div>
      </div>
    </ModuleSections>
  );

};

export default Hypothesis;
