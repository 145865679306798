import {
  Box, Grid, Typography
} from "@mui/material";
import React from "react";
// import { useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import CustomMultilineTextfield from "../../../../components/CustomMultilineTextfield";
import Data from "../../../../Data/Gtm.json";

import HelpIconTooltip from "../../../../components/HelpIconTooltip";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  customWidth: {
    maxWidth       : 500,
    fontSize       : 10,
    backgroundColor: "white",
    color          : "#707070",
    boxShadow      : "0px 3px 6px #00000029",
    border         : "1px solid #707070",
    borderRadius   : 10,
    whiteSpace     : "pre-wrap"
  },
  helpicon: {
    color   : "#071AFF",
    fontSize: "15px"
  },
  question: {
    color   : "#393939",
    fontSize: "15px"
    // fontWeight: "bold",
  }
}));

const EditDifferentiation = props => {

  // const [ dialogbox, setDialog ] = useState(false);
  // const [ Collapsecomment, setCollapsecomment ] = useState(false);
  // const history = useHistory();
  const classes = useStyles();
  // const content = Data.gtmpositioning;
  const data = Data.positioningdifferentiation;

  const details = props.details;

  // let { user, venture } = useSelector(state => {

  //   return { ...state };
  
  // });

  // const Dialogopen = () => {

  //   setDialog(true);
  
  // };
  // const Dialogclose = () => {

  //   setDialog(false);
  
  // };

  // const collapse_comment_function = () => {

  //   if (Collapsecomment) {

  //     setCollapsecomment(false);
    
  //   } else {

  //     setCollapsecomment(true);
    
  //   }
  
  // };

  return (
    <Grid item xs={ 12 }>
      {/* <IndividualSectionHeaders
        // mainheading={"Positioning"}
        // subheading={"Research"}
        // headingcontent={
        //   "Find out What (and Where) your customers are looking for"
        // }
        isGtm={false}
        Dialogopen={Dialogopen}
        Dialogclose={Dialogclose}
        dialogbox={dialogbox}
        collapse_comment_function={collapse_comment_function}
        content={content}
        isexpand={true}
        notGuide={true}
      /> */}
      <Box sx={ { marginBottom: "30px" } }>
        <Typography sx={ { fontWeight: "bold", fontSize: "1rem" } }>
          How will your product help your customer? Draft ‘Proof Point’ and
          ‘Differentiator’ messages.{ " " }
        </Typography>
      </Box>
      { data.map((question, index) => {

        return (
          <Grid container mb={ 2 } key={ index }>
            <Grid item md={ 3 }>
              <Typography className={ classes.question }>
                { question.label }
                { question.tooltip &&
                  <HelpIconTooltip
                    width="500px"
                    tooltipdata={ question.tooltip }
                  />
                }
              </Typography>
            </Grid>
            <Grid item md={ 8 }>
              <CustomMultilineTextfield
                width="100%"
                borderRadius="5px"
                // fontSize="12px"
                minRows={ 4 }
                maxRows={ 4 }
                value={ details[question.name] }
                name={ question.name }
                handledetailsChange={ e => {

                  props.handleDifferentiation(e.target.value, e.target.name);
                
                } }
              // placeholder={question.placeholder}
              />
            </Grid>
          </Grid>
        );
      
      }) }
    </Grid>
  );

};

export default EditDifferentiation;
