import {
  Checkbox, FormControlLabel,
  FormGroup, Grid, Tooltip, Typography
} from "@mui/material";
import React from "react";
// import { useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import HelpIconTooltip from "../../../../components/HelpIconTooltip";
// import Data from "../../../../Data/Gtm.json";

import { styled } from "@mui/material/styles";
// import { makeStyles } from "@mui/styles";
// import BackNextButton from "../../../../components/BackNextButton";
import CustomMultilineTextfield from "../../../../components/CustomMultilineTextfield";
import styles from "./TargetSegments.module.css";

// const useStyles = makeStyles(theme => ({
//   customWidth: {
//     width          : "100%",
//     fontSize       : 10,
//     backgroundColor: "white",
//     color          : "#707070",
//     boxShadow      : "0px 3px 6px #00000029",
//     border         : "1px solid #707070",
//     borderRadius   : 10
//   }
// }));

// const bar = {
//   height         : "4px",
//   width          : "50px",
//   borderRadius   : "50px",
//   backgroundColor: "grey",
//   margin         : "5px 20px",
//   marginLeft     : "0px"
// };
// const bar_blue = {
//   height         : "4px",
//   width          : "50px",
//   borderRadius   : "50px",
//   backgroundColor: "blue",
//   margin         : "5px 20px",
//   marginLeft     : "0px"
// };

const FacebookIcon = styled("span")(() => ({
  width          : 40,
  height         : 40,
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/uncheckedfacebook.png)`,
  backgroundSize : "cover"
}));

const FacebookCheckedIcon = styled(FacebookIcon)({
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/checkedfacebook.png)`
});

const InstagramIcon = styled("span")(() => ({
  width          : 40,
  height         : 40,
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/uncheckedinstagram.png)`,
  backgroundSize : "cover"
}));

const InstagramCheckedIcon = styled(InstagramIcon)({
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/checkedinstagram.png)`
});

const TwitterIcon = styled("span")(() => ({
  width          : 40,
  height         : 40,
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/uncheckedtwitter.png)`,
  backgroundSize : "cover"
}));

const TwitterCheckedIcon = styled(TwitterIcon)({
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/checkedtwitter.png)`
});

const LinkedinIcon = styled("span")(() => ({
  width          : 40,
  height         : 40,
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/uncheckedlinkedin.png)`,
  backgroundSize : "cover"
}));

const LinkedinCheckedIcon = styled(LinkedinIcon)({
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/checkedlinkedin.png)`
});

const PinterestIcon = styled("span")(() => ({
  width          : 40,
  height         : 40,
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/uncheckedpinterest.png)`,
  backgroundSize : "cover"
}));

const PinterestCheckedIcon = styled(PinterestIcon)({
  backgroundImage: `url(${process.env.REACT_APP_AWS_ASSETS_URL}/images/checkedpinterest.png)`
});

const IndividualCharacteristicsthird = props => {

  // const history = useHistory();
  // const [ Collapsecomment, setCollapsecomment ] = useState(false);
  // const [ dialogbox, setDialog ] = useState(false);

  // const classes = useStyles();

  // let { venture } = useSelector(state => {

  //   return { ...state };
  
  // });

  // const pathname = history.location.pathname.substring(1);
  // const guideicon = Data.gtmtargetpersonas;

  // const Dialogopen = () => {

  //   setDialog(true);
  
  // };
  // const Dialogclose = () => {

  //   setDialog(false);
  
  // };

  // const collapse_comment_function = () => {

  //   if (Collapsecomment) {

  //     setCollapsecomment(false);
    
  //   } else {

  //     setCollapsecomment(true);
    
  //   }
  
  // };

  const details = props.details;

  return (
    <Grid
      id={styles.maingrid2}
    >
      <Grid container id={styles.gridContainer} sx={{ marginTop: "15px" }} mb={2}>
        <Grid item xs={12} md={5}>
          <Typography variant="body2" sx={{ color: "#393939" }}>
            Which social media platforms do they actively <br /> use?
            <HelpIconTooltip
              tooltipdata={
                "Research which social media platforms this target persona is using the most. You’ll need this data mostly when preparing your digital marketing plans with content, campaigns etc. and deciding where to invest the most time/effort/money."
              }
              width={"500px"}
            />
          </Typography>
        </Grid>
        <Grid item xs={12} md={7}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Tooltip title="Facebook">
                  <Checkbox
                    checkedIcon={<FacebookCheckedIcon />}
                    icon={<FacebookIcon />}
                    name="sunday"
                    checked={details.socialMediaActiveness[0].ispresent}
                    onChange={e => {

                      props.handleSocialMediaChange(
                        e,
                        0,
                        "/images/checkedfacebook.png"
                      );
                    
                    }}
                    disableRipple
                  />
                </Tooltip>
              }
              label=""
            />
            <FormControlLabel
              control={
                <Tooltip title="Instagram">
                  <Checkbox
                    checkedIcon={<InstagramCheckedIcon />}
                    icon={<InstagramIcon />}
                    name="monday"
                    checked={details.socialMediaActiveness[1].ispresent}
                    onChange={e => {

                      props.handleSocialMediaChange(
                        e,
                        1,
                        "/images/checkedinstagram.png"
                      );
                    
                    }}
                    disableRipple
                  />
                </Tooltip>
              }
              label=""
            />
            <FormControlLabel
              control={
                <Tooltip title="Twitter">
                  <Checkbox
                    checkedIcon={<TwitterCheckedIcon />}
                    icon={<TwitterIcon />}
                    name="tuesday"
                    checked={details.socialMediaActiveness[2].ispresent}
                    onChange={e => {

                      props.handleSocialMediaChange(
                        e,
                        2,
                        "/images/checkedtwitter.png"
                      );
                    
                    }}
                    disableRipple
                  />
                </Tooltip>
              }
              label=""
            />
            <FormControlLabel
              control={
                <Tooltip title="Linkedin">
                  <Checkbox
                    checkedIcon={<LinkedinCheckedIcon />}
                    icon={<LinkedinIcon />}
                    name="wednesday"
                    checked={details.socialMediaActiveness[3].ispresent}
                    onChange={e => {

                      props.handleSocialMediaChange(
                        e,
                        3,
                        "/images/checkedlinkedin.png"
                      );
                    
                    }}
                    disableRipple
                  />
                </Tooltip>
              }
              label=""
            />
            <FormControlLabel
              control={
                <Tooltip title="Pinterest">
                  <Checkbox
                    checkedIcon={<PinterestCheckedIcon />}
                    icon={<PinterestIcon />}
                    name="thursday"
                    checked={details.socialMediaActiveness[4].ispresent}
                    onChange={e => {

                      props.handleSocialMediaChange(
                        e,
                        4,
                        "/images/checkedpinterest.png"
                      );
                    
                    }}
                    disableRipple
                  />
                </Tooltip>
              }
              label=""
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid container id={styles.gridContainer} mb={2}>
        <Grid item xs={12} md={5}>
          <Typography variant="body2" sx={{ color: "#393939" }}>
            How do they gain relevant information about their needs?
            <HelpIconTooltip
              tooltipdata={
                "Consider all possible channels and methods that your target persona uses today to gain information that they need for their personal lives or professional lives, as relevant for your venture. \nFor example, an interior designer may rely heavily on home improvement magazines, follow designers & influencers on Instagram and Pinterest, or do a lot of window shopping to stay in the know for their job. "
              }
              width={"500px"}
            />
          </Typography>
        </Grid>
        <Grid item xs={12} md={7}>
          <CustomMultilineTextfield
            padding={"16px 15px"}
            maxRows={3}
            minRows={3}
            width={"100%"}
            fontSize={"13px"}
            value={details["informationNeeds"]}
            handledetailsChange={e => {

              props.handleChange("informationNeeds", e.target.value);
            
            }}
          />
        </Grid>
      </Grid>
      <Grid container id={styles.gridContainer} mb={2}>
        <Grid item xs={12} md={5}>
          <Typography variant="body2" sx={{ color: "#393939" }}>
            Other relevant characteristics
          </Typography>
        </Grid>
        <Grid item xs={12} md={7}>
          <CustomMultilineTextfield
            padding={"16px 15px"}
            maxRows={3}
            minRows={3}
            width={"100%"}
            fontSize={"13px"}
            value={details["relevantCharacteristics"]}
            handledetailsChange={e => {

              props.handleChange("relevantCharacteristics", e.target.value);
            
            }}
          />
        </Grid>
      </Grid>
      <Grid container id={styles.gridContainer} mb={2}>
        <Typography sx={{ fontSize: "11px", fontStyle: "italic" }}>
          *Answer all questions that could be relevant for your product or
          brand. It’s ok to skip questions you can’t answer at the moment. As
          you learn more about your target users, return to these pages to
          further develop the persona
        </Typography>
      </Grid>
    </Grid>
  );

};

export default IndividualCharacteristicsthird;
