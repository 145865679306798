/* eslint-disable camelcase */
import {
  Box, Button,
  Dialog, DialogContent, Grid, IconButton, Slide, TextField, Tooltip, Typography
} from "@mui/material";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ModuleSections from "../../../components/ModuleSections";
import Data from "../../../Data/Gtm.json";
import { getSegments } from "../../../services/persona";
import {
  getProblemSolution,
  updateSolutionCapability
} from "../../../services/problemsolution";

import SegmentsPersonasDialog from "../Customer/TargetSegments/SegmentsPersonasDialog";
import GridTitleColumn from "./GridTitleColumn";
import SolutionsGridRow from "./SolutionsGridRow";

// import HelpIcon from "@mui/icons-material/Help";
// import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
// import InsertCommentRoundedIcon from "@mui/icons-material/InsertCommentRounded";
// import ArrowRightSharpIcon from "@mui/icons-material/ArrowRightSharp";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
// import ArrowRightIcon from "@mui/icons-material/ArrowRight";
// import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { makeStyles } from "@mui/styles";
import BackNextButton from "../../../components/BackNextButton";
import IndividualSectionHeaders from "../../../components/IndividualSectionHeaders";
import { useUnmount } from "../../../components/useUnmount";
import {
  createProblemSolutionComment,
  deleteProblemSolutionComment,
  updateProblemSolutionComment
} from "../../../services/comment";
import styles from "./Solution.module.css";

const useStyles = makeStyles(() => ({
  customWidth: {
    width          : 200,
    fontSize       : 10,
    backgroundColor: "white",
    color          : "#707070",
    boxShadow      : "0px 3px 6px #00000029",
    border         : "1px solid #707070",
    borderRadius   : 10
  }
}));

const Transition = forwardRef(function Transition (props, ref) {

  return <Slide direction="up" ref={ ref } { ...props } />;

});

const SolutionsGrid = () => {

  const [ segments, setSegments ] = useState([]);
  const [ segmentsDialog, setSegmentsDialog ] = useState([]);
  const [ comments, setComments ] = useState([]);
  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const [ dialogbox, setDialog ] = useState(false);
  const [ createdialogbox, setCreateDialog ] = useState(false);
  const [ segmentdialogbox, setSegmentDialog ] = useState(false);
  const [ othername, setOthername ] = useState("");
  const [ isdelete, setIsdelete ] = useState(false);
  const [ isDataLoading, setIsDataLoading ] = useState(true);
  // const [showTopBtn, setShowTopBtn] = useState(false);

  const history = useHistory();
  const classes = useStyles();
  let gridref = useRef(null);
  let sampleref = useRef(null);
  let refScrollUp = useRef(null);
  const pathname = history.location.pathname.substring(1);
  const content = Data.gtmvalueproposition;

  let { user, venture } = useSelector(state => {

    return { ...state };
  
  });

  useEffect(() => {

    // fetchSegments();
    fetchSolution();
  
  }, []);

  // const fetchSegments = async () => {
  //   try {
  //     const response = await getSegments(venture.customer);
  //     const customer = response.data;
  //     setSegmentsDialog(customer.segmentsPersonas.segments);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  const createDialogopen = () => {

    setCreateDialog(true);
  
  };
  const createDialogclose = () => {

    setCreateDialog(false);
  
  };

  const segmentDialogopen = () => {

    setSegmentDialog(true);
  
  };
  const segmentDialogclose = () => {

    setSegmentDialog(false);
  
  };

  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);
    
    } else {

      setCollapsecomment(true);
    
    }
  
  };

  // const handleScroll = (e) => {
  //   const bottom =
  //     e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
  //   if (bottom) {
  //     setShowTopBtn(true);
  //   } else {
  //     setShowTopBtn(false);
  //   }
  // };

  const addColumn = async () => {

    const newSegments = segments.map(eachSegment => {

      let newSegment = { ...eachSegment };

      newSegment["personas"] = eachSegment.personas.map(
        eachPersona => {

          let newpersona = { ...eachPersona };

          newpersona["SolutionGrid"] = {
            ...eachPersona["SolutionGrid"],
            ["others"]: [
              ...eachPersona["SolutionGrid"].others,
              { name: othername, value: "" }
            ]
          };
          
          return newpersona;
        
        }
      );
      
      return newSegment;
    
    });

    setSegments(newSegments);
    setOthername("");
    await updateSolutionCapability(
      newSegments,
      venture.problemSolution
    );

    gridref.current.scrollLeft += gridref.current.scrollWidth;
    createDialogclose();
  
  };

  const removeColumn = async otherindex => {

    const newSegments = segments.map(eachSegment => {

      let newSegment = { ...eachSegment };

      newSegment["personas"] = eachSegment.personas.map(
        eachPersona => {

          let newpersona = { ...eachPersona };

          newpersona["SolutionGrid"] = {
            ...eachPersona["SolutionGrid"],
            ["others"]: eachPersona["SolutionGrid"].others.filter(
              (other, i) => otherindex !== i
            )
          };
          
          return newpersona;
        
        }
      );
      
      return newSegment;
    
    });

    setSegments(newSegments);
    await updateSolutionCapability(
      newSegments,
      venture.problemSolution
    );

    setIsdelete(false);
    gridref.current.scrollLeft += gridref.current.scrollWidth;
  
  };

  const handleChange = (name, value, segmentindex, personaindex) => {

    const newSegments = segments.map((eachSegment, index) => {

      if (index === segmentindex) {

        let newSegment = { ...eachSegment };

        newSegment["personas"] = eachSegment.personas.map(
          (eachPersona, index) => {

            if (index === personaindex) {

              let newpersona = { ...eachPersona };

              newpersona["SolutionGrid"] = {
                ...eachPersona["SolutionGrid"],
                [name]: value
              };
              
              return newpersona;
            
            } else {

              return eachPersona;
            
            }
          
          }
        );
        
        return newSegment;
      
      } else {

        return eachSegment;
      
      }
    
    });

    setSegments(newSegments);
  
  };

  const handleOtherChange = (value, segmentindex, personaindex, otherindex) => {

    const newSegments = segments.map((eachSegment, index) => {

      if (index === segmentindex) {

        let newSegment = { ...eachSegment };

        newSegment["personas"] = eachSegment.personas.map(
          (eachPersona, index) => {

            if (index === personaindex) {

              let newpersona = { ...eachPersona };
              let newothers = eachPersona["SolutionGrid"].others.map(
                (other, index) =>
                  index === otherindex
                    ? {
                      ...other,
                      ["value"]: value
                    }
                    : other
              );

              newpersona["SolutionGrid"] = {
                ...eachPersona["SolutionGrid"],
                ["others"]: newothers
              };
              
              return newpersona;
            
            } else {

              return eachPersona;
            
            }
          
          }
        );
        
        return newSegment;
      
      } else {

        return eachSegment;
      
      }
    
    });

    setSegments(newSegments);
  
  };

  const fetchSolution = async () => {

    setIsDataLoading(true);

    try {

      const solutionResponse = await getProblemSolution(venture.problemSolution);
      let fullSegmentData;

      try {

        const response = await getSegments(venture.customer);
        const resSegment = response?.data?.segmentsPersonas?.segments;

        if (resSegment) {

          setSegmentsDialog(resSegment);
          fullSegmentData = resSegment;

        } else {

          setSegmentsDialog([]);
          fullSegmentData = [];

        }


      } catch (error) { console.error(error); }

      const solutionData = solutionResponse?.data?.segments;

      if (solutionData) {

        const solutionGridData = [];

        solutionData?.map(solData => {

          solData?.personas?.map(persona => {

            solutionGridData.push(persona);

          });

        });

        fullSegmentData?.map(eachSegment => {

          eachSegment?.personas?.map(pp => {

            solutionGridData.map(dd => {

              if (dd._id == pp._id) {

                pp.SolutionGrid = dd.SolutionGrid;

              }

            });

          });

        });

        setSegments(fullSegmentData);
        setComments(solutionResponse.data.comments.solution.solutionGrid);

      } else {

        setSegments([]);
        setComments([]);

      }

      setIsDataLoading(false);
    
    } catch (error) {

      console.error(error);
      setIsDataLoading(false);
    
    }

  };

  // To get the updated usestate value to save in useEffect cleanup
  const detailsRef = useRef(segments);

  detailsRef.current = segments;

  let isSubmitting = false;

  // useUnmount => custom hook to save while navigating
  useUnmount(() => {

    if (!isSubmitting) {

      handleSave();
    
    }
  
  });

  const handleSave = async () => {

    try {

      await updateSolutionCapability(
        detailsRef.current,
        venture.problemSolution
      );
      
      return true;
    
    } catch (error) {

      console.error(error);
      
      return false;
    
    }
  
  };

  const handleSubmit = async () => {

    try {

      isSubmitting = true;
      if(isDataLoading == false){

        await updateSolutionCapability(
          segments,
          venture.problemSolution
        );
        // console.log(response.data);

      }

      history.push("/solutionsummary");
      isSubmitting = false;
    
    } catch (error) {

      isSubmitting = false;
      console.error(error);
    
    }
  
  };

  // const handleScrollUp = () => {
  //   refScrollUp.current.scrollTop = 0;
  // };

  const addComment = async (commentText, replyTo) => {

    try {

      const response = await createProblemSolutionComment(
        commentText,
        user._id,
        "solution",
        "solutionGrid",
        venture.problemSolution,
        replyTo,
        window.location.href,
        "Solution > Solution Grid"
      );

      setComments(response.data.comments.solution.solutionGrid);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateComment = async (commentid, commentText) => {

    try {

      const response = await updateProblemSolutionComment(
        commentText,
        user._id,
        commentid,
        venture._id,
        window.location.href,
        "Solution > Solution Grid"
      );

      setComments(response.data.comments.solution.solutionGrid);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const deleteComment = async commentid => {

    try {

      const response = await deleteProblemSolutionComment(
        "solution",
        "solutionGrid",
        venture.problemSolution,
        commentid,
        user._id,
        window.location.href,
        "Solution > Solution Grid"
      );

      setComments(response.data.comments.solution.solutionGrid);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  // console.log(segments);
  return (
    <ModuleSections
      Collapsecomment={ Collapsecomment }
      comments={ comments }
      addComment={ addComment }
      deleteComment={ deleteComment }
      updateComment={ updateComment }>
      <div className="box-display-style-for-next-and-back-button-with-justify">
        <div className="box-indiv-header-con">
          <IndividualSectionHeaders
            mainheading={ "Solution" }
            subheading={ "Solution Grid" }
            headingcontent={
              "Review your solution ideas to start planning your product build."
            }
            isGtm={ false }
            Dialogopen={ Dialogopen }
            Dialogclose={ Dialogclose }
            dialogbox={ dialogbox }
            collapse_comment_function={ collapse_comment_function }
            content={ content }
            isSaveButton={ true }
            handleSave={ handleSave }
          />
        </div>
        <Grid
          item
          xs={ 12 }
          id={ styles.maingrid }
          ref={ refScrollUp }
          onClick={ () => {

            setIsdelete(false);
          
          } }
        // onScroll={handleScroll}
        >
          <Grid sx={ { marginTop: "20px", minHeight: "40vh" } }>
            { segments.length > 0 && segments[0].personas.length > 0 &&
              <Tooltip
                title="Add more columns to track other useful information like Build Status, Priority, Owner, Comments, etc.’"
                disableFocusListener
                placement="right-start"
                classes={ { tooltip: classes.customWidth } }>
                <IconButton
                  id={ styles.addcolumnbtn }
                  onClick={ createDialogopen }
                  disabled={ venture.userRole === "Commenter" }>
                  <AddCircleIcon
                    sx={ {
                      fontSize: "30px",
                      color:
                        venture.userRole === "Commenter" ? "b0b0b0" : "#071AFF"
                    } }
                  />
                </IconButton>
              </Tooltip>
            }
            <Dialog
              open={ createdialogbox }
              PaperProps={ {
                style: {
                  borderRadius  : "20px",
                  backdropFilter: "blur(45px)",
                  background    : "#FFFFFF 0% 0% no-repeat padding-box",
                  boxShadow     : "0px 3px 6px #00000029",
                  border        : "1px solid #707070"
                }
              } }
              BackdropProps={ { invisible: true } }>
              <Box sx={ { alignSelf: "flex-end" } }>
                <IconButton component="span" onClick={ createDialogclose }>
                  <HighlightOffIcon />
                </IconButton>
              </Box>

              <DialogContent>
                <TextField
                  size="small"
                  label="Enter Name"
                  variant="outlined"
                  value={ othername }
                  onChange={ e => {

                    setOthername(e.target.value);
                  
                  } }
                />
                <Button
                  variant="contained"
                  size="medium"
                  sx={ { backgroundColor: "#071AFF", marginLeft: "10px" } }
                  onClick={ addColumn }>
                  Create
                </Button>
              </DialogContent>
            </Dialog>
            { segments.length <= 0 ?
              <Grid container>
                <Grid
                  item
                  xs={ 12 }
                  sx={ {
                    textAlign     : "center",
                    minHeight     : "40vh",
                    display       : "flex",
                    justifyContent: "center",
                    alignItems    : "center"
                  } }>
                  <Typography fontWeight="900">
                    No valid user personas found. Kindly create relevant user
                    segments and personas in the Customer module, to continue
                    working on this section.
                  </Typography>
                </Grid>
              </Grid>
              :
              <>
                { segments && segments.map((eachSegment, segmentindex) => {

                  return (
                    <Box sx={ { marginBottom: "20px" } }>
                      <Box>
                        <Typography
                          sx={ {
                            color        : "#071AFF",
                            fontWeight   : "bold",
                            textTransform: "capitalize",
                            fontSize     : "16px",
                            cursor       : "pointer"
                          } }
                          component="span"
                          onClick={ () => {

                            segmentDialogopen();
                          
                          } }>
                          { eachSegment.name }
                        </Typography>
                        { segmentindex === 0 &&
                          segments[0].personas.length > 0 &&
                          segments[0].personas[0].SolutionGrid.others.length >
                          0 &&
                            <Button
                              variant="text"
                              sx={ {
                                textTransform: "none",
                                color        : isdelete ? "#B0B0B0" : "#ff7f5c",
                                fontWeight   : "bold",
                                marginLeft   : "20px"
                              } }
                              onClick={ e => {

                                e.stopPropagation();
                                setIsdelete(true);
                              
                              } }
                              disabled={ venture.userRole === "Commenter" }>
                              <RemoveCircleIcon
                                fontSize="small"
                                sx={ { marginRight: "10px" } }
                              />{ " " }
                              Delete Column
                            </Button>
                        }
                      </Box>
                      <Dialog
                        open={ segmentdialogbox }
                        TransitionComponent={ Transition }
                        keepMounted
                        PaperProps={ {
                          style: {
                            borderRadius   : "20px",
                            backdropFilter : "blur(10px)",
                            backgroundColor: "rgba(255,255,255,0.80)",
                            margin         : "20px",
                            padding        : "0% 2%",
                            height         : "100%"
                          }
                        } }
                        maxWidth="md"
                        fullWidth>
                        <Box sx={ { alignSelf: "flex-end" } }>
                          <IconButton
                            component="span"
                            onClick={ segmentDialogclose }>
                            <HighlightOffIcon />
                          </IconButton>
                        </Box>
                        <SegmentsPersonasDialog segments={ segmentsDialog } />
                      </Dialog>
                      <Grid
                        container
                        id={ styles.gridbox }
                        ref={ segmentindex === 0 ? gridref : sampleref }
                        rowGap={ 2 }>
                        { eachSegment.personas.length > 0 ?
                          <>
                            <Grid item sx={ { display: "flex" } } columnGap={ 3 }>
                              <Typography id={ styles.columntypography }>
                                User Persona
                              </Typography>
                              <Typography id={ styles.othercolumntypographys }>
                                Problem/Need
                              </Typography>
                              <Typography id={ styles.othercolumntypographys }>
                                Root Cause
                              </Typography>
                              <Typography id={ styles.othercolumntypographys }>
                                Solution Capabilities
                              </Typography>
                              { eachSegment.personas[0].SolutionGrid.others
                                .length > 0 ?
                                eachSegment.personas[0].SolutionGrid.others.map(
                                  (other, index) => {

                                    return<GridTitleColumn
                                      other={ other }
                                      index={ index }
                                      isdelete={ isdelete }
                                      removeColumn={ removeColumn }
                                    />;
                                    
                                  
                                  }
                                )
                                :
                                <></>
                              }
                            </Grid>
                            { eachSegment.personas.map(
                              (eachPersona, personaindex) => {

                                return <SolutionsGridRow
                                  eachPersona={ eachPersona }
                                  eachSegment={ eachSegment }
                                  personaindex={ personaindex }
                                  segmentindex={ segmentindex }
                                  handleChange={ handleChange }
                                  handleOtherChange={ handleOtherChange }
                                />;
                              
                              }
                            ) }
                          </>
                          :
                          <Box>
                            <Typography sx={ { textAlign: "center" } }>
                              No Personas Created
                            </Typography>
                          </Box>
                        }
                      </Grid>
                    </Box>
                  );
                
                }) }
              </>
            }
          </Grid>
          {/* {showTopBtn && (
          <img
            src="/images/up-arrow.png"
            id={styles.upbtn}
            onClick={() => {
              handleScrollUp();
            }}
          />
          )}*/}
        </Grid>
        <div className="ttribe-back-next-button-con">
          <BackNextButton
            restrictedBackClick={ () =>
              pathname.includes("gtm") && isDataLoading == false
                ? history.push("/gtmpersonasolutionmap")
                : history.push("/personasolutionmap")
            }
            backClick={ () =>
              pathname.includes("gtm") && isDataLoading == false
                ? history.push("/gtmpersonasolutionmap")
                : history.push("/personasolutionmap")
            }
            restrictedNextClick={ () => {

              history.push("/solutionsummary");
            
            } }
            nextClick={ () => {

              handleSubmit();
            
            } }
          />
        </div>
      </div>
    </ModuleSections>
  );

};

export default SolutionsGrid;
