import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import {
  Box, Slider, Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { updatePercent } from "../../../../services/venture";
import styles from "../Dashboard2.module.css";

export default function FirstRowBox({
  details,
  boxTitle,
  boxImage,
  boxModulename,
  imageHeight,
  imageWidth,
  isLocked
}) {

  const history = useHistory();
  let { venture } = useSelector(state => {

    return { ...state };
  
  });

  const [ value, setValue ] = useState(details?.status);

  const handleChange = async (event, newValue) => {

    event.preventDefault();
    setValue(newValue);
    await updatePercent(venture._id, "pitchDeck", "status", newValue);
  
  };

  useEffect(() => {

    setValue(details?.status || 0);
  
  }, [ details ]);


  const calculateAveragePercent = () => {

    let total = 0;

    if (details) {

      Object.keys(details).map(detailkey => {

        total += details[detailkey];
      
      });
      
      return Math.round(total / Object.keys(details).length);
    
    } else {

      return 0;
    
    }
  
  };

  return boxModulename !== "pitchdeck" ? <Box
    id={styles.moduleMainBox}
    onClick={event => {

      event.preventDefault();
      history.push(`/${boxTitle.toLowerCase().replace(/[^\w]/g, "")}intro`);

    }}
  >
    <Box id={styles.titleBoxModule}>
      <Typography sx={{ fontWeight: "bold", marginTop: "6px" }}>
        {boxTitle}
      </Typography>
      <Box>
        <img
          style={{ height: `${imageHeight}`, width: `${imageWidth}` }}
          src={boxImage}
          alt=""
        />
      </Box>
    </Box>

    <Box id={styles.BottomBoxModule}>
      <Box id={styles.sliderBox}>
        <Slider
          size="small"
          valueLabelFormat={value => <div>{value + "%"}</div>}
          value={
            boxModulename !== "pitchdeck" &&
              details ? Object.keys(details).length > 0
                ? calculateAveragePercent()
                : 0 : 0
          }
          aria-label="Small"
          valueLabelDisplay="on"
          sx={{
            "& .MuiSlider-valueLabel": {
              backgroundColor: "unset",
              color          : "black",
              fontWeight     : "bold",
              fontSize       : "12px"
            }
          }}
        />
      </Box>
      <Box id={styles.buttonBox}>
        <Box id={styles.lockIconBox}>
          {isLocked ? <LockIcon /> : <LockOpenIcon />}
        </Box>
      </Box>
    </Box>
  </Box> : <Box
    id={styles.moduleMainBox}
    onClick={event => {

      if (event.target.parentElement.className.includes("ttribe-pitch-deck-slider-con")) {

        event.preventDefault();
        
        return;
      
      }
      history.push(`/${boxTitle.toLowerCase().replace(/[^\w]/g, "")}intro`);
    
    }}
  >
    <Box id={styles.titleBoxModule}>
      <Typography sx={{ fontWeight: "bold", marginTop: "6px" }}>
        {boxTitle}
      </Typography>
      <Box>
        <img
          style={{ height: `${imageHeight}`, width: `${imageWidth}` }}
          src={boxImage}
          alt=""
        />
      </Box>
    </Box>

    <Box id={styles.BottomBoxModule}>
      <Box id={styles.sliderBox} className="ttribe-pitch-deck-slider-con" > {value ?
        <Slider
          size="small"
          valueLabelFormat={() => value == 50 ? "In Progress" : value == 100 ? <div style={{ marginRight: "60px" }}>Completed</div> : <div style={{ marginLeft: "50px" }}>Not Started</div>}
          value={value}
          aria-label="Small"
          step={50}
          valueLabelDisplay="on"
          onChangeCommitted={handleChange}
          disabled={venture.userRole === "Commenter" ? true : false}
          sx={{
            "& .MuiSlider-valueLabel": {
              backgroundColor: "unset",
              color          : "black",
              fontWeight     : "bold",
              fontSize       : "12px"
            }
          }}
        /> : <Slider
          size="small"
          valueLabelFormat={() => <div style={{ marginLeft: "50px" }}>Not Started</div>}
          value={0}
          aria-label="Small"
          step={50}
          valueLabelDisplay="on"
          onChangeCommitted={handleChange}
          disabled={venture.userRole === "Commenter" ? true : false}
          sx={{
            "& .MuiSlider-valueLabel": {
              backgroundColor: "unset",
              color          : "black",
              fontWeight     : "bold",
              fontSize       : "12px"
            }
          }}
        />}
      </Box>
      <Box id={styles.buttonBox}>
        <Box id={styles.lockIconBox}>
          {isLocked ? <LockIcon /> : <LockOpenIcon />}
        </Box>
      </Box>
    </Box>
  </Box>

  ;

}
