import React, { useEffect, useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box, CircularProgress, FormControl, InputLabel,
  ListSubheader, MenuItem, Select, Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import Datas from "../../../../Data/Finance.json";
import { getFinancials } from "../../../../services/financials";
import PlTable from "../../Financials/PlOutlook/PlTable";

export default function PitchFinancialPlOutlook() {

  const [ loading, setLoading ] = useState(false);
  const [ financials, setFinancials ] = useState({
    plOutlooks          : [],
    frOutlooks          : [],
    currentFundingSource: [],
    fundRaising         : {
      totalFundingNeed : 0,
      fundingAllocation: ""
    },
    totalCurrentFunding: 0,
    plOutlooksCurrency : "",
    frOutlooksCurrency : ""
  });
  const [ table, setTable ] = useState(Datas.ploutlook.initialdata);
  // const [ currentploutlook, setCurrentPloutlook ] = useState({});
  const [ year, setYear ] = useState("");
  const [ years, setYears ] = useState([]);

  const { venture } = useSelector(state => ({ ...state }));

  useEffect(() => {

    fetchFinancials();
  
  }, []);

  const fetchFinancials = async () => {

    try {

      setLoading(true);
      const response = await getFinancials(venture.financials, venture._id);

      setFinancials(response.data.finance);
      if (response.data.finance.plOutlooks.length > 0) {

        let newyears = [];

        response.data.finance.plOutlooks.map(ploutlook => {

          newyears.push(ploutlook.year);
        
        });
        setYears(newyears);
        setYear(response.data.finance.plOutlooks[0].year);
        // setCurrentPloutlook(response.data.finance.plOutlooks[0]);
        setTable(response.data.finance.plOutlooks[0].plOutlook.table);
      
      }
      setLoading(false);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const handleSelect = e => {

    setYear(e.target.value);
    financials.plOutlooks.map(ploutlook => {

      if (ploutlook.year === e.target.value) {

        // setCurrentPloutlook(ploutlook);
        setTable(ploutlook.plOutlook.table);
        
        return;
      
      }
    
    });
  
  };

  return (
    <Box>
      <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
        <Box>
          <Typography
            sx={{ fontSize: "20px", color: "#5F5E5E", fontWeight: "bold" }}
          >
            Finance
          </Typography>
        </Box>

        <ChevronRightIcon sx={{ color: "gray" }} />
        <Box>
          <Typography>P&L Outlook</Typography>
        </Box>
      </Box>
      <Box>
        <Typography sx={{ fontSize: "12px" }}>
          Derive the Profit and Loss progression for your company
        </Typography>
      </Box>
      {/* year select */}
      <Box
        sx={{
          width         : "100%",
          //   border: 1,
          display       : "flex",
          justifyContent: "flex-end"
        }}
      >
        <Box
          sx={{
            width         : financials.plOutlooks.length > 0 ? "200px" : "initial",
            display       : "flex",
            alignItems    : "center",
            justifyContent: "space-evenly",
            marginRight   : "5px"
          }}
        >
          {financials.plOutlooks.length > 0 ?
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Year</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Year"
                value={year}
                onChange={handleSelect}
                disabled={venture.userRole === "Commenter"}
              >
                <ListSubheader>
                  <Typography sx={{ fontWeight: "bold", color: "#393939" }}>
                    Projected Years
                  </Typography>
                </ListSubheader>
                {years.map(year => {

                  return <MenuItem value={year}>{year}</MenuItem>;
                
                })}
              </Select>
            </FormControl>
            :
            <></>
          }
        </Box>
      </Box>
      {loading ?
        <Box
          sx={{
            width         : "100%",
            height        : "100%",
            // border: 1,
            display       : "flex",
            justifyContent: "center",
            alignItems    : "center"
          }}
        >
          <Box>
            <CircularProgress size="60px" thickness={3} />
          </Box>
        </Box>
        :
        <Box sx={{ marginTop: "20px" }}>
          <PlTable table={table} financials={financials} />
        </Box>
      }
      {/* <PlTable table={table} financials={financials} /> */}
    </Box>
  );

}
