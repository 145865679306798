import React, { useEffect, useRef, useState } from "react";
// import { useHistory } from "react-router-dom";
import {
  Box,
  Typography,
  TextField
} from "@mui/material";
import Monetisation from "../../Monetisation";
// import BackNextButton from "../../../../../components/BackNextButton";
import styles from "./Channels.module.css";
import { useSelector } from "react-redux";
import {
  getMonetisationDetails,
  updateChannels
} from "../../../../../services/monetisation";
import { useUnmount } from "../../../../../components/useUnmount";

export default function Channels() {

  const [ otherChannels, setOtherChannels ] = useState("");
  const [ selectedChannels, setSelectedChannels ] = useState([]);
  const [ comments, setComments ] = useState([]);
  const [ isDataLoading, setIsDataLoading ] = useState(true);
  let isSubmitting = false;

  // const history = useHistory();

  const { venture } = useSelector(state => ({ ...state }));
  // venture.monetisation

  // To get the updated usestate value to save in useEffect cleanup
  const otherChannelsRef = useRef(otherChannels);
  const selectedChannelsRef = useRef(selectedChannels);

  otherChannelsRef.current = otherChannels;
  selectedChannelsRef.current = selectedChannels;

  // useUnmount => custom hook to save while navigating
  useUnmount(() => {

    if ( isDataLoading == false && !isSubmitting) {

      handleSave();
    
    }
  
  });

  const handleSave = async () => {

    try {

      await updateChannels(
        selectedChannelsRef.current,
        otherChannelsRef.current,
        venture.monetisation
      );
      
      return true;
    
    } catch (error) {

      console.error(error);
      
      return false;
    
    }
  
  };

  useEffect(() => {

    fetchMonetisation();
  
  }, []);

  const fetchMonetisation = async () => {

    setIsDataLoading(true);

    try {

      const response = await getMonetisationDetails(venture.monetisation);

      setOtherChannels(response.data.channels.otherChannels);
      setSelectedChannels(response.data.channels.selectedChannels);
      setComments(response.data.comments.commercialModel.channels);
      setIsDataLoading(false);
    
    } catch (error) {

      console.error("error while fetching monetisation", error);
      setIsDataLoading(false);
    
    }
  
  };

  const handleOtherChannelsChange = value => {

    let newOtherChannels = [ ...otherChannels ];

    newOtherChannels = value;
    setOtherChannels(newOtherChannels);
  
  };

  const handleClickedChannles = name => {

    setSelectedChannels([ ...selectedChannels, name ]);
  
  };

  const handleRemoveChannels = name => {

    let newSelectedChannels = selectedChannels.filter( selectedChannel => {

      return selectedChannel !== name;
      
    });

    setSelectedChannels(newSelectedChannels);
  
  };

  const handleSubmit = async () => {

    try {

      isSubmitting = true;
      if(isDataLoading == false) {

        await updateChannels(
          selectedChannels,
          otherChannels,
          venture.monetisation
        );

      }
      isSubmitting = false;
    
    } catch (error) {

      isSubmitting = false;
      console.error(error);
    
    }
  
  };

  // console.log(otherChannels);
  // console.log(selectedChannels);
  return (
    <Monetisation
      barTwo={true}
      mainheading="Commercial Model"
      subheading="Channels"
      comments={comments}
      sectionName="commercialModel"
      subSectionName="channels"
      handleSave={handleSave}
      handleSubmit={handleSubmit}
      selectedChannels={selectedChannels}
    >
      <Box>
        <Box>
          <Typography id={styles.headTypo}>
            Where will people buy your solution?
          </Typography>
        </Box>

        {/* multi select main box */}
        <Box id={styles.multiSelectMainBox}>
          <Box
            id={styles.multiBox}
            sx={{
              backgroundColor: selectedChannels.includes("D2C through Website")
                ? venture.userRole === "Commenter"
                  ? "#DCDCDC"
                  : "#FDD3A4"
                : "#ffffff"
            }}
            onClick={() => {

              venture.userRole === "Commenter" ?
                <></>
                : selectedChannels.includes("D2C through Website") ?
                  handleRemoveChannels("D2C through Website")
                  :
                  handleClickedChannles("D2C through Website")
              ;
            
            }}
          >
            <Typography id={styles.multiBoxTypo}>
              D2C through Website
            </Typography>
          </Box>
          <Box
            id={styles.multiBox}
            sx={{
              backgroundColor: selectedChannels.includes("Resellers")
                ? venture.userRole === "Commenter"
                  ? "#DCDCDC"
                  : "#FDD3A4"
                : "#ffffff"
            }}
            onClick={() => {

              venture.userRole === "Commenter" ?
                <></>
                : selectedChannels.includes("Resellers") ?
                  handleRemoveChannels("Resellers")
                  :
                  handleClickedChannles("Resellers")
              ;
            
            }}
          >
            <Typography id={styles.multiBoxTypo}>Resellers</Typography>
          </Box>
          <Box
            id={styles.multiBox}
            sx={{
              backgroundColor: selectedChannels.includes("Channel Partners")
                ? venture.userRole === "Commenter"
                  ? "#DCDCDC"
                  : "#FDD3A4"
                : "#ffffff"
            }}
            onClick={() => {

              venture.userRole === "Commenter" ?
                <></>
                : selectedChannels.includes("Channel Partners") ?
                  handleRemoveChannels("Channel Partners")
                  :
                  handleClickedChannles("Channel Partners")
              ;
            
            }}
          >
            <Typography id={styles.multiBoxTypo}>Channel Partners</Typography>
          </Box>
          <Box
            id={styles.multiBox}
            sx={{
              backgroundColor: selectedChannels.includes("Direct/Field Sales")
                ? venture.userRole === "Commenter"
                  ? "#DCDCDC"
                  : "#FDD3A4"
                : "#ffffff"
            }}
            onClick={() => {

              venture.userRole === "Commenter" ?
                <></>
                : selectedChannels.includes("Direct/Field Sales") ?
                  handleRemoveChannels("Direct/Field Sales")
                  :
                  handleClickedChannles("Direct/Field Sales")
              ;
            
            }}
          >
            <Typography id={styles.multiBoxTypo}>Direct/Field Sales</Typography>
          </Box>
          <Box
            id={styles.multiBoxBottom}
            sx={{
              backgroundColor: selectedChannels.includes("Retail Stores")
                ? venture.userRole === "Commenter"
                  ? "#DCDCDC"
                  : "#FDD3A4"
                : "#ffffff"
            }}
            onClick={() => {

              venture.userRole === "Commenter" ?
                <></>
                : selectedChannels.includes("Retail Stores") ?
                  handleRemoveChannels("Retail Stores")
                  :
                  handleClickedChannles("Retail Stores")
              ;
            
            }}
          >
            <Typography id={styles.multiBoxTypo}>Retail Stores</Typography>
          </Box>
          <Box
            id={styles.multiBoxBottom}
            sx={{
              backgroundColor: selectedChannels.includes("Wholesale")
                ? venture.userRole === "Commenter"
                  ? "#DCDCDC"
                  : "#FDD3A4"
                : "#ffffff"
            }}
            onClick={() => {

              venture.userRole === "Commenter" ?
                <></>
                : selectedChannels.includes("Wholesale") ?
                  handleRemoveChannels("Wholesale")
                  :
                  handleClickedChannles("Wholesale")
              ;
            
            }}
          >
            <Typography id={styles.multiBoxTypo}>Wholesale</Typography>
          </Box>
          <Box
            id={styles.multiBoxBottom}
            sx={{
              backgroundColor: selectedChannels.includes("Agent Network")
                ? venture.userRole === "Commenter"
                  ? "#DCDCDC"
                  : "#FDD3A4"
                : "#ffffff"
            }}
            onClick={() => {

              venture.userRole === "Commenter" ?
                <></>
                : selectedChannels.includes("Agent Network") ?
                  handleRemoveChannels("Agent Network")
                  :
                  handleClickedChannles("Agent Network")
              ;
            
            }}
          >
            <Typography id={styles.multiBoxTypo}>Agent Network</Typography>
          </Box>
        </Box>
        {/* ------------- */}

        {/* other channels main box */}
        <Box id={styles.otherChannelsMainBox}>
          <Box id={styles.otherChannelsTypoBox}>
            <Typography id={styles.otherChannelsTypo}>
              Other Channels
            </Typography>
          </Box>
          <Box id={styles.textFieldBox}>
            <TextField
              onChange={e => {

                handleOtherChannelsChange(e.target.value);
              
              }}
              disabled={venture.userRole === "Commenter" ? true : false}
              value={otherChannels}
              placeholder="Enter other Channel details here.."
              fullWidth
              multiline
              maxRows={5}
              minRows={5}
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "4px 6px"
                },
                "& .MuiInputBase-input": {
                  fontSize  : "12px",
                  color     : "#707070",
                  lineHeight: "17px"
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #B4B4FA"
                }
              }}
            />
          </Box>
        </Box>
      </Box>
    </Monetisation>
  );

}
