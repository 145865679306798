import React, { useEffect, useState } from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box, Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import CustomMultilineTextfield from "../../../../components/CustomMultilineTextfield";
import HelpIconTooltip from "../../../../components/HelpIconTooltip";
import { getProblemSolution } from "../../../../services/problemsolution";

export default function PitchSolutionHypoFirst() {

  const [ details, setDetails ] = useState({ solution: "", differentiation: "" });

  let { venture } = useSelector(state => {

    return { ...state };
  
  });

  useEffect(() => {

    fetchSolution();
  
  }, []);

  const fetchSolution = async () => {

    try {

      const response = await getProblemSolution(venture.problemSolution);

      setDetails(response.data.nutshell);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  return (
    <Box>
      <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
        <Box>
          <Typography
            sx={{ fontSize: "20px", color: "#5F5E5E", fontWeight: "bold" }}
          >
            Solution
          </Typography>
        </Box>
        <ChevronRightIcon sx={{ color: "gray" }} />
        <Box>
          <Typography>In a Nutshell</Typography>
        </Box>
      </Box>
      <Box>
        <Typography sx={{ fontSize: "12px" }}>
          Bring out your solution idea with its unique differentiation.
        </Typography>
      </Box>

      {/* --------- */}
      <Box sx={{ width: "100%", display: "flex" }}>
        <Box sx={{ width: "45%" }}>
          <Box sx={{}}>
            <Typography
              sx={{
                padding   : "15px 30px",
                fontSize  : "18px",
                fontWeight: "bold"
              }}
            >
              The Solution
            </Typography>
            <Box sx={{ border: "1px solid #707070", borderRadius: "13px" }}>
              <Box
                sx={{
                  borderBottom: "1px solid #707070",
                  borderRadius: "13px",
                  width       : "100%"
                  // height: "80px",
                }}
              >
                <Typography
                  sx={{
                    fontSize  : "14px",
                    fontWeight: "bold",
                    padding   : "20px "
                  }}
                >
                  What is your solution to address the problem or <br />
                  need?
                  <HelpIconTooltip
                    tooltipdata={
                      "By now, you have clarity on the market gap or customer pain that is worth solving. How is your venture going to address that? Give a brief, simple explanation of your solution. Avoid unnecessary jargon or complex technical terms."
                    }
                    width="500px"
                  />
                </Typography>
              </Box>

              <Box sx={{}}>
                <CustomMultilineTextfield
                  // fontSize={"12px"}
                  width={"100%"}
                  border={"none"}
                  maxRows={9}
                  minRows={9}
                  value={details.solution}
                  disabled={true}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width         : "10%",
            // border: 1,
            display       : "flex",
            justifyContent: "center",
            alignItems    : "center"
          }}
        >
          <ArrowRightAltIcon sx={{ fontSize: "50px", color: "#071AFF" }} />
        </Box>
        <Box sx={{ width: "45%" }}>
          <Box sx={{}}>
            <Typography
              sx={{
                padding   : "15px 30px",
                fontSize  : "18px",
                fontWeight: "bold"
              }}
            >
              The Differentiation
            </Typography>
            <Box sx={{ border: "1px solid #707070", borderRadius: "13px" }}>
              <Box
                sx={{
                  borderBottom: "1px solid #707070",
                  borderRadius: "13px",
                  width       : "100%"
                  // height: "80px",
                }}
              >
                <Typography
                  sx={{
                    fontSize  : "14px",
                    fontWeight: "bold",
                    padding   : "20px "
                  }}
                >
                  How is your solution different or better than the one that
                  already exists in the market?
                  <HelpIconTooltip
                    tooltipdata={
                      "What unique value does your solution create? It's time to make the benefits and outcome of your solution very clear and detail as to why its worth investing in. Your unique value proposition is critical especially if you want investors to get on board with your idea and your business. But, it should be something anyone can understand. Don't get too technical, or overly complicate it. \nTry to cover: \n1. How does your solution solve the market needs or problems and/or improve their current situation \n2. What are the benefits of choosing your solution. \n3. Why should anyone choose your solution over other alternatives? This template might help you draft a clear value proposition statement:  Our  [product/service]  helps  [customer segment]  who want to  [do this job]  by  [verb & customer pain]  and  [verb & customer pain]  unlike  [alternatives], with  [competing value proposition]."
                    }
                    width="500px"
                  />
                </Typography>
              </Box>

              <Box sx={{}}>
                <CustomMultilineTextfield
                  // fontSize={"12px"}
                  width={"100%"}
                  border={"none"}
                  maxRows={9}
                  minRows={9}
                  value={details.differentiation}
                  disabled={true}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );

}
