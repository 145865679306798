/* eslint-disable camelcase */
import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import HelpIconTooltip from "../../../../components/HelpIconTooltip";
import IndividualSectionHeaders from "../../../../components/IndividualSectionHeaders";
import ModuleSections from "../../../../components/ModuleSections";
import jsondata from "../../../../Data/Market.json";
import FinalPage from "./FinalPage";

import styles from "./MarketFactsFigures.module.css";

// import { makeStyles } from "@mui/styles";
import BackNextButton from "../../../../components/BackNextButton";
import CustomMultilineTextfield from "../../../../components/CustomMultilineTextfield";
import NextButton from "../../../../components/NextButton";
import { useUnmount } from "../../../../components/useUnmount";
import {
  createMarketComment,
  deleteMarketComment,
  updateMarketComment
} from "../../../../services/comment";
import {
  getMarketdetails,
  updateMarketDetails
} from "../../../../services/market";

// const useStyles = makeStyles(() => ({
//   customWidth: {
//     width          : 200,
//     fontSize       : 10,
//     backgroundColor: "white",
//     color          : "#707070",
//     boxShadow      : "0px 3px 6px #00000029",
//     border         : "1px solid #707070",
//     borderRadius   : 10
//   }
// }));

const MarketFactsFigures = ({ history }) => {

  const [ details, setDetails ] = useState({
    tabOne: {
      products    : "",
      primaryUsers: "",
      keyProducers: ""
    },
    tabTwo: {
      financialStats   : "",
      productionVolumes: "",
      otherStates      : ""
    },
    tabThree: {
      technology   : "",
      social       : "",
      political    : "",
      regulatory   : "",
      economic     : "",
      environmental: ""
    }
  });
  const [ comments, setComments ] = useState({
    tabOne  : [],
    tabTwo  : [],
    tabThree: []
  });
  const [ Collapsecomment, setCollapsecomment ] = useState(false);
  const [ dialogbox, setDialog ] = useState(false);
  const [ isDataLoading, setIsDataLoading ] = useState(true);
  const collapse_comment_function = () => {

    if (Collapsecomment) {

      setCollapsecomment(false);
    
    } else {

      setCollapsecomment(true);
    
    }
  
  };

  const Dialogopen = () => {

    setDialog(true);
  
  };
  const Dialogclose = () => {

    setDialog(false);
  
  };

  const data = jsondata.marketfactsfigures;
  // const classes = useStyles();

  const { user, venture } = useSelector(state => ({ ...state }));
  // console.log("user from welcome", user);
  // let ventureid = venture._id;

  const hashvalue = history.location.hash.substring(1)
    ? history.location.hash.substring(1)
    : "tabOne";
  const content = data[hashvalue];
  const guideicon = data["guideicon"];

  useEffect(() => {

    fetchMarketdetails();
  
  }, []);

  // To get the updated usestate value to save in useEffect cleanup
  const detailsRef = useRef(details);

  detailsRef.current = details;

  let isSubmitting = false;

  // useUnmount => custom hook to save while navigating
  useUnmount(() => {

    if (!isSubmitting && !isDataLoading) {

      handleSave();
    
    }
  
  });

  const handleSave = async () => {

    try {

      await updateMarketDetails(
        detailsRef.current,
        venture.market,
        "factsFigures"
      );
      
      return true;
    
    } catch (error) {

      console.error(error);
      
      return false;
    
    }
  
  };

  const fetchMarketdetails = async () => {

    setIsDataLoading(true);

    try {

      const response = await getMarketdetails(venture.market);
      const market = response.data.market;

      setDetails(market.factsFigures);
      setComments(market.comments.factsFigures);
      setIsDataLoading(false);
    
    } catch (error) {

      console.error(error);
      setIsDataLoading(false);
    
    }
  
  };

  const handledetailsChange = event => {

    const name = event.target.name;
    const value = event.target.value;
    let newDetails = { ...details };

    newDetails[content.subName][name] = value;
    setDetails(newDetails);
  
  };

  const handleSubmit = async () => {

    try {

      isSubmitting = true;

      if(isDataLoading == false) {

        await updateMarketDetails(
          details,
          venture.market,
          "factsFigures"
        );
      
      }

      history.push("/competition");
      isSubmitting = false;
    
    } catch (error) {

      isSubmitting = false;
      console.error(error);
    
    }
  
  };

  const addComment = async (commentText, replyTo) => {

    try {

      const response = await createMarketComment(
        commentText,
        user._id,
        "factsFigures",
        hashvalue,
        venture.market,
        replyTo,
        window.location.href,
        "Market > Facts & Figures"
      );

      setComments(response.data.comments.factsFigures);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const updateComment = async (commentid, commentText) => {

    try {

      const response = await updateMarketComment(
        commentText,
        user._id,
        commentid,
        venture._id,
        window.location.href,
        "Market > Facts & Figures"
      );

      setComments(response.data.comments.factsFigures);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  const deleteComment = async commentid => {

    try {

      const response = await deleteMarketComment(
        "factsFigures",
        hashvalue,
        venture.market,
        commentid,
        user._id,
        window.location.href,
        "Market > Facts & Figures"
      );

      setComments(response.data.comments.factsFigures);
    
    } catch (error) {

      console.error(error);
    
    }
  
  };

  // console.log(details);

  return (
    <ModuleSections
      Collapsecomment={Collapsecomment}
      comments={comments[hashvalue]}
      addComment={addComment}
      deleteComment={deleteComment}
      updateComment={updateComment}
      // handleSave={async () => {
      //   await updateMarketDetails(details, venture.market, "factsFigures");
      // }}
    >
      <div className="box-display-style-for-next-and-back-button-with-justify">
        <div className="box-indiv-header-con">
          {hashvalue == "tabThree" ?
            <>
              <Box id={styles.box1}>
                <h6 id={styles.bar} />
                <h6 id={styles.bar} />
                <h6 id={styles.barblue} />
              </Box>
              <IndividualSectionHeaders
                mainheading={"Market"}
                subheading={"Facts & Figures"}
                headingcontent={content.description}
                isGtm={false}
                Dialogopen={Dialogopen}
                Dialogclose={Dialogclose}
                dialogbox={dialogbox}
                collapse_comment_function={collapse_comment_function}
                content={guideicon}
                isSaveButton={true}
                handleSave={handleSave}
              />
            </>
            :
            <>
              <Box id={styles.box1}>
                <h6 id={hashvalue === "tabTwo" ? styles.bar : styles.barblue} />
                <h6 id={hashvalue === "tabTwo" ? styles.barblue : styles.bar} />
                <h6 id={styles.bar} />
              </Box>
              <IndividualSectionHeaders
                mainheading={"Market"}
                subheading={"Facts & Figures"}
                headingcontent={content.description}
                isGtm={false}
                Dialogopen={Dialogopen}
                Dialogclose={Dialogclose}
                dialogbox={dialogbox}
                collapse_comment_function={collapse_comment_function}
                content={guideicon}
                isSaveButton={true}
                handleSave={handleSave}
              />
            </>
          }
        </div>

        {hashvalue == "tabThree" ?
          <>
            <FinalPage
              content={content}
              guideicon={guideicon}
              collapse_comment_function={collapse_comment_function}
              details={details}
              handledetailsChange={handledetailsChange}
              handleSubmit={handleSubmit}
              handleSave={handleSave}
            />
          </>
          :
          <>
            <Grid
              item
              xs={12}
              sx={{ padding: "0px 60px 0px", overflowY: "auto" }}>
              <Grid sx={{ height: "65%" }} id={styles.questionsContainer}>
                {content.questions.map((data, index) => {

                  return (
                    <Grid sx={{
                      display   : "flex",
                      alignItems: "center"
                    }} className="ttribe-market-ff-text-title-con" key={index} container>
                      <Typography id={styles.typography3} variant="body2">
                        {data.question}
                        <HelpIconTooltip tooltipdata={data.tooltip} />
                      </Typography>
                      <Box id={styles.othertitles}>
                        <CustomMultilineTextfield
                          width="100%"
                          borderRadius="10px"
                          // fontSize="14px"
                          maxRows={2}
                          minRows={2}
                          name={data.name}
                          value={details[content.subName][data.name]}
                          handledetailsChange={handledetailsChange}
                        />
                      </Box>
                    </Grid>
                  );
                
                })}
              </Grid>
            </Grid>
          </>
        }

        <div className="ttribe-back-next-button-con">
          {hashvalue === "tabThree" ?
            <BackNextButton
              backClick={() => history.push({ hash: "tabTwo" })}
              nextClick={handleSubmit}
              restrictedBackClick={() => history.push({ hash: "tabTwo" })}
              restrictedNextClick={() => history.push("/competition")}
            />
            : hashvalue === "tabTwo" ?
              <BackNextButton
                backClick={() => history.push("/factsfigures")}
                nextClick={async () => {

                  isSubmitting = true;
                  if(isDataLoading == false){

                    await updateMarketDetails(
                      details,
                      venture.market,
                      "factsFigures"
                    );

                  }
                  history.push({ hash: "tabThree" });
                  isSubmitting = false;
                
                }}
                restrictedBackClick={() => history.push("/factsfigures")}
                restrictedNextClick={() => history.push({ hash: "tabThree" })}
              />
              :
              <NextButton
                restrictedNextClick={() => history.push({ hash: "tabTwo" })}
                nextClick={async () => {

                  isSubmitting = true;

                  if(isDataLoading == false){

                    await updateMarketDetails(
                      details,
                      venture.market,
                      "factsFigures"
                    );
                  
                  }
                  history.push({ hash: "tabTwo" });
                  isSubmitting = false;
                
                }}
              />
          }
        </div>
      </div>
    </ModuleSections>
  );

};

export default MarketFactsFigures;
