import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import ReactApexChart from "react-apexcharts";

import {
  Box, Dialog, DialogContent, IconButton, Tooltip
} from "@mui/material";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";

const MarginProgressionByYear = props => {

  const [ chartdata, setChartdata ] = useState([]);
  const currency = props?.currency || "- I";
  const YearsList = props?.year;
  const [ dialogbox, setDialogBox ] = useState(false);
  const history = useHistory();
  const chartTitle = props?.chartTitle;
  const moreDetails = props?.moreDetails;
  const tableRow = [ "Gross Profit %", "Operating Margin %" ];

  useEffect(() => {

    updateChartdata(tableRow);

  }, [ props?.table ]);

  const updateChartdata = chartTitle => {

    let tempTableData = [];
    let data = [];
    let count = 0;
    const allTableData = props?.table;

    if(allTableData && allTableData.length > 0) {

      allTableData.map(plData => {

        tempTableData.push(plData.plOutlook.table);
    
      });
    
      tempTableData?.map(d => {
    
        d?.map(row => {
    
          let chartvalues = [];

          if (chartTitle.includes(row.rowName)) {
    
            row.rowVal.map((column, colindex) => {
    
              if (colindex === 16) {

                chartvalues.push(column.value);
              
              }
            
            });
            data.push({
              name: row.rowName,
              data: chartvalues
            });
    
            count = count + 1;
          
          }
    
        });
      
      });
    
      let cogsData = [];
      let totalOpsCosts = [];

      data?.map(d => {
    
        if(d.name === tableRow[0]){
    
          cogsData.push(d.data[0]);
        
        }
        if(d.name === tableRow[1]){
    
          totalOpsCosts.push(d.data[0]);
    
        }
      
      });
    
      const formatedData = [ { name: tableRow[0], data: cogsData }, { name: tableRow[1], data: totalOpsCosts } ];

      setChartdata(formatedData);

    } else {

      setChartdata([ { name: " No Data", data: [ 0 ] } ]);

    }

  };

  const numberWithCommas = num => {

    if (currency) {

      let newNum = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      return `${currency?.split(" ")[0].trim()} ${newNum}`;

    } else {

      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    }
  
  };

  let options = {
    chart: {
      type      : "line",
      dropShadow: {
        enabled: true,
        color  : "#000",
        top    : 18,
        left   : 7,
        blur   : 10,
        opacity: 0.2
      },
      zoom: {
        enabled: false
      }
      // toolbar: {
      //     show: true,
      //     tools: {
      //         download: true,
      //         selection: false,
      //         zoom: false,
      //         zoomin: false,
      //         zoomout: false,
      //         pan: false,
      //         reset: false,
      //         customIcons: []
      //     },
      //     autoSelected: 'pan'
      // },
    },
    colors: [ "#00C18D", "#6057ff" ],
    title : {
      // text: "Revenues",
      // align: 'center',
      //   margin: 10,
      //   offsetX: 0,
      //   offsetY: 0,
      //   floating: false,
      //   style: {
      //     fontSize: '14px',
      //     fontWeight: 'bold',
      //     fontFamily: undefined,
      //     color: '#263238'
      //   },
    },
    // plotOptions: {
    //     bar: {
    //         horizontal: false,
    //         columnWidth: '70%',
    //         endingShape: 'rounded'
    //     },
    // },
    dataLabels: {
      // enabled: props.isMultibar ? false : true,
      enabled  : false,
      formatter: function (val) {

        return numberWithCommas(val);
      
      }
    },
    stroke: {
      curve: "straight"
    },
    grid: {
      row: {
        // colors: ["#f3f3f3", "white"], // takes an array which will be repeated on columns
        opacity: 0.3
      }
    },
    xaxis: {
      categories: YearsList && YearsList,
      title     : {
        text   : "Time Period", offsetX: 0,
        offsetY: 90
      },
      position: "bottom"
    },
    yaxis: {
      title: {
        text: "Growth %"
      },
      labels: {
        formatter: val => { return `${val} %`; }
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {

          return `${numberWithCommas(val)} `;
        
        }
      }
    }
  };

  return <div className="tribe-chart-page-con">
    <div className="tribe-fin-chart-year-sel-main-con">
      <div className="tribe-fin-chart-year-sel-title-con"><p> <span >{chartTitle}</span></p></div>
      <Tooltip
        enterTouchDelay={0}
        disableFocusListener
        title="View"
        placement="top"
      >
        <IconButton
          component="span"
          onClick={() => {

            setDialogBox(true);
          
          }}>
          <OpenInFullIcon />
        </IconButton>
      </Tooltip>
    </div>
    <ReactApexChart options={options} series={chartdata} type="line"  height="95%" width="95%" className="tribe-apex-chart-con" />
    <div className="fin-chart-more-detail-con">
      <p>For details click <span onClick={() => history.push(moreDetails.url)}>{moreDetails.page}</span></p>
    </div>
    <Dialog
      open={dialogbox}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        style: {
          borderRadius  : "20px",
          backdropFilter: "blur(45px)",
          background:
                        "#FFFFFF 0% 0% no-repeat padding-box",
          boxShadow: "0px 3px 6px #00000029",
          border   : "1px solid #707070"
        }
      }}
    >
      <DialogContent>
        <Box sx={{ textAlign: "center" }}>

          <div className="tribe-fin-chart-year-sel-main-con">
            <div className="tribe-fin-chart-year-sel-title-con"><p> <span>{chartTitle}</span></p></div>
            <Tooltip
              enterTouchDelay={0}
              disableFocusListener
              title="Close"
              placement="right"
            >
              <IconButton
                component="span"
                onClick={() => {

                  setDialogBox(false);
                
                }}>
                <HighlightOffIcon />
              </IconButton>
            </Tooltip>
          </div>
          <ReactApexChart options={options} series={chartdata} type="line"  height="350px" width="95%" className="tribe-apex-chart-con" />
          <div className="fin-chart-more-detail-con">
            <p>For details click <span onClick={() => history.push(moreDetails.url)}>{moreDetails.page}</span></p>
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  </div>;

};

export default MarginProgressionByYear;
