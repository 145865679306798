import {
  Grid,
  Typography
} from "@mui/material";
import React from "react";
// import { useHistory } from "react-router";

// import { makeStyles } from "@mui/styles";
import CustomMultilineTextfield from "../../../components/CustomMultilineTextfield";
import SuccessMessage from "../../../components/SuccessMessage";
import ConceptVisionData from "../../../Data/ConceptVision.json";
import styles from "./ConceptVision.module.css";

// const boxtext = {
//   border: "1px solid dimgray",
//   borderRadius: "10px",
//   width: "-webkit-fill-available",
//   padding: "10px 20px",
//   margin: "2px",
//   height: "235px",
//   resize: "none",
// };

// const useStyles = makeStyles((theme) => ({
//   customWidth: {
//     width: 200,
//     fontSize: 10,
//     backgroundColor: "white",
//     color: "#707070",
//     boxShadow: "0px 3px 6px #00000029",
//     border: "1px solid #707070",
//     borderRadius: 10,
//   },
// }));

const NextPage = props => {

  // let history = useHistory();

  // const classes = useStyles();
  const details = props.details;
  const datas = ConceptVisionData["second"];

  
  return (
    <>
      <Grid container id={ styles.nextgrid }>
        { datas.map((data, index) => {

          return (
            <Grid
              // key={ index }
              item
              md={ 4 }
              id={ styles.griditem }
              sx={ { paddingRight: index == 2 ? "0px" : "40px" } }>
              <Typography
                variant="body2"
                style={ {
                  fontSize    : "14px",
                  fontWeight  : "bold",
                  // height: "50px",
                  marginBottom: "10px"
                } }>
                { data.mainquestion }
              </Typography>
              <Typography
                variant="body2"
                style={ { fontSize: "12px", height: "65px" } }
                gutterBottom>
                <i>{ data.subquestion }</i>
              </Typography>
              <CustomMultilineTextfield
                width="-webkit-fill-available"
                borderRadius="10px"
                fontSize="0.75em"
                lineHeight="20px"
                maxRows={ 10 }
                minRows={ 10 }
                name={ data.name }
                value={ details[data.name] }
                handledetailsChange={ props.handledetailsChange }
                placeholder={ data.placeholder }
              />
            </Grid>
          );
        
        }) }
      </Grid>
      { props.successmsg && <SuccessMessage successmsg={ props.successmsg } /> }
    </>
  );

};

export default NextPage;
