import { Box } from "@mui/material";
import React from "react";
import FlexTextfieldImg from "../FlexTextfieldImg";

export default function Problem ({
  logoimg,
  slides,
  isRender,
  setSlides,
  isPublished,
  ...props
}) {

  const slide = slides[isRender];

  const handleChange = (name, value, columnindex) => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    let newproblem = [ ...newSlides[isRender]["problem"] ];

    newproblem.map((problem, problemindex) => {

      if (problemindex === columnindex) {

        newproblem[problemindex][name] = value;
      
      }
    
    });
    newSlides[isRender]["problem"] = newproblem;
    setSlides(newSlides);
  
  };

  const handleDetailsChange = (name, value) => {

    let newSlides = [ ...slides ];

    newSlides[isRender][name] = value;
    newSlides[isRender]["isChanged"] = true;
    setSlides(newSlides);
  
  };

  return (
    <Box>
      <FlexTextfieldImg
        isEdit={ props.isEdit }
        Collapsecomment={ props.Collapsecomment }
        collapseCommentFunction={ props.collapseCommentFunction }
        logoimg={ logoimg }
        imgOne={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/pitchTheProblem.png` }
        imgTwo={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/pitchFaceTheProblem.png` }
        imgThree={ `${process.env.REACT_APP_AWS_ASSETS_URL}/images/pitchProblemExist.png` }
        mainTitle={ slide.name }
        onTitleChange={ handleDetailsChange }
        leftTitle={ slide.problem[0].name }
        midTitle={ slide.problem[1].name }
        rightTitle={ slide.problem[2].name }
        leftTxtFieldPlaceholder={
          "Type the problem(s) or need(s) that you’ve identified in market as brief pointers"
        }
        midTxtFieldPlaceholder={
          "Key user groups who feel the problem and will benefit from your solution"
        }
        rightTxtFieldPlaceholder={
          "Explain why the problem(s) remains unsolved in the market"
        }
        // midTypoBoxWidth={"200px"}
        leftValue={ slide.problem[0].value }
        midValue={ slide.problem[1].value }
        rightValue={ slide.problem[2].value }
        handleValueChange={ handleChange }
        isPublished={ isPublished }
        problemSlide={ true }
      />
    </Box>
  );

}
