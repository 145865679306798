import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box, Button,
  Dialog,
  DialogContent, IconButton, TextField, Typography
} from "@mui/material";
import React, { useState } from "react";
// import { useHistory } from "react-router-dom";
import PitchGtmBuyersJourney from "../PitchReferenceLinks/PitchGtmBuyersJourney";
import PitchGtmValueMessage from "../PitchReferenceLinks/PitchGtmValueMessage";
import ReferenceLinkTooltip from "../PitchReferenceLinks/ReferenceLinkTooltip";
import SectionHeader from "../SectionHeader";
import styles from "./GTMStrategiesIdeas.module.css";

export default function GTMStrategiesIdeas({
  isEdit,
  logoimg,
  isRender,
  slides,
  setSlides,
  isPublished,
  ...props
}) {

  const [ pitchGtmBuyersJourney, setPitchGtmBuyersJourney ] = useState(false);
  const [ pitchGtmValueMessage, setPitchGtmValueMessage ] = useState(false);

  const slide = slides[isRender];

  const handleChange = (name, value, columnindex) => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;

    let newstratergies = [ ...newSlides[isRender]["stratergies"] ];

    newstratergies.map((stratergy, stratergyIndex) => {

      if (stratergyIndex === columnindex) {

        newstratergies[stratergyIndex][name] = value;
      
      }
      
      return stratergy;
    
    });
    newSlides[isRender]["stratergies"] = newstratergies;

    setSlides(newSlides);
  
  };

  const handleDetailsChange = (name, value) => {

    let newSlides = [ ...slides ];

    newSlides[isRender]["isChanged"] = true;
    newSlides[isRender][name] = value;
    setSlides(newSlides);
  
  };

  // const history = useHistory();
  // const pathname = history.location.pathname.substring(1);

  const stratergiesPlaceholder = [
    "Indicate how you plan to acquire buyers/ customers, keeping in mind the buyer journey they will go through.",
    "Describe how you will message your solution’s key value props/ benefits to your target audience. "
  ];

  const PitchGtmBuyersJourneyDialogopen = () => {

    setPitchGtmBuyersJourney(true);
  
  };
  const PitchGtmBuyersJourneyDialogclose = () => {

    setPitchGtmBuyersJourney(false);
  
  };
  const PitchGtmValueMessageDialogopen = () => {

    setPitchGtmValueMessage(true);
  
  };
  const PitchGtmValueMessageDialogclose = () => {

    setPitchGtmValueMessage(false);
  
  };

  return (
    <Box>
      <SectionHeader
        isEdit={isEdit}
        Collapsecomment={props.Collapsecomment}
        collapseCommentFunction={props.collapseCommentFunction}
        mainHead={slide.name}
        handleTitleChange={handleDetailsChange}
        logoimg={logoimg}
        isPublished={isPublished}
      />
      <Box
        id={styles.mainBox}
      >
        <Box
          id={styles.imgbox}
        >
          <Box>
            <img
              crossOrigin="anonymous"
              src={`${process.env.REACT_APP_AWS_ASSETS_URL
              }/images/pitchGTMStrategies.png?time=${new Date().valueOf()}`}
              alt=""
              id={styles.img}
            />
          </Box>
        </Box>
        <Box
          id={styles.rightContentBox}
        >
          {slide.stratergies.map((stratergy, stratergyIndex) => {

            return (
              <Box >
                <Box
                  sx={{
                    width       : "100%",
                    marginBottom: "4px"
                  }}
                >
                  {isEdit ?
                    <TextField
                      value={stratergy.name}
                      onChange={e => {

                        handleChange("name", e.target.value, stratergyIndex);
                      
                      }}
                      fullWidth
                      inputProps={{ maxLength: 50 }}
                      sx={{
                        "& .MuiInputBase-input": {
                          fontSize  : "16px",
                          padding   : "2px 10px",
                          border    : "none",
                          color     : "#393939",
                          fontWeight: "bold",
                          whiteSpace: "pre-line"
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px dotted #B4B4FA"
                        }
                      }}
                    />
                    :
                    <Typography
                      id={styles.textBoxTitle}
                    >
                      {stratergy.name}
                    </Typography>
                  }
                </Box>
                {isEdit ?
                  <Box sx={{ position: "relative" }}>
                    <TextField
                      value={stratergy.value}
                      onChange={e => {

                        handleChange("value", e.target.value, stratergyIndex);
                      
                      }}
                      placeholder={stratergiesPlaceholder[stratergyIndex]}
                      fullWidth
                      multiline
                      maxRows={5}
                      minRows={5}
                      inputProps={{ maxLength: 400 }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          padding: "7px 10px"
                        },
                        "& .MuiInputBase-input": {
                          fontSize: "1.9vh",
                          color   : "#707070"

                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "2px dotted #B4B4FA"
                        }
                      }}
                    />
                    {stratergyIndex === 0 &&
                      <Box
                        sx={{
                          position: "absolute",
                          right   : "0px",
                          top     : "-31px"
                        }}
                      >
                        <ReferenceLinkTooltip
                          title={
                            "Revisit the notes created in GTM module’s buyer journey"
                          }
                        >
                          <Button
                            style={{
                              textTransform : "none",
                              textDecoration: "underline",
                              fontSize      : "12px",
                              padding       : "0px",
                              fontWeight    : "bold"
                            }}
                            onClick={PitchGtmBuyersJourneyDialogopen}
                          >
                            Buyer Journey
                          </Button>
                        </ReferenceLinkTooltip>
                        <Dialog
                          open={pitchGtmBuyersJourney}
                          onClose={PitchGtmBuyersJourneyDialogclose}
                          PaperProps={{
                            style: {
                              borderRadius   : "20px",
                              backdropFilter : "blur(100px)",
                              backgroundColor: "#ffffff",
                              boxShadow      : "0px 3px 6px #00000029",
                              width          : "70%"
                            }
                          }}
                          maxWidth="false"
                          fullWidth
                        >
                          <DialogContent sx={{ padding: "40px" }}>
                            <Box
                              sx={{
                                width   : "100%",
                                position: "relative"
                              }}
                            >
                              <PitchGtmBuyersJourney />
                              <IconButton
                                onClick={() => {

                                  PitchGtmBuyersJourneyDialogclose();
                                
                                }}
                                sx={{
                                  position: "absolute",
                                  right   : "-40px",
                                  top     : "-40px"
                                }}
                              >
                                <HighlightOffIcon />
                              </IconButton>
                            </Box>
                          </DialogContent>
                        </Dialog>
                      </Box>
                    }
                    {stratergyIndex === 1 &&
                      <Box
                        sx={{
                          position: "absolute",
                          right   : "0px",
                          top     : "-31px"
                        }}
                      >
                        <ReferenceLinkTooltip
                          title={
                            "Revisit the notes created in GTM module’s positioning value messaging"
                          }
                        >
                          <Button
                            style={{
                              textTransform : "none",
                              textDecoration: "underline",
                              fontSize      : "12px",
                              padding       : "0px",
                              fontWeight    : "bold"
                            }}
                            onClick={PitchGtmValueMessageDialogopen}
                          >
                            Value Message
                          </Button>
                        </ReferenceLinkTooltip>
                        <Dialog
                          open={pitchGtmValueMessage}
                          onClose={PitchGtmValueMessageDialogclose}
                          PaperProps={{
                            style: {
                              borderRadius   : "20px",
                              backdropFilter : "blur(100px)",
                              backgroundColor: "#ffffff",
                              boxShadow      : "0px 3px 6px #00000029",
                              width          : "70%"
                            }
                          }}
                          maxWidth="false"
                          fullWidth
                        >
                          <DialogContent sx={{ padding: "40px" }}>
                            <Box
                              sx={{
                                width   : "100%",
                                position: "relative"
                              }}
                            >
                              <PitchGtmValueMessage />
                              <IconButton
                                onClick={() => {

                                  PitchGtmValueMessageDialogclose();
                                
                                }}
                                sx={{
                                  position: "absolute",
                                  right   : "-40px",
                                  top     : "-40px"
                                }}
                              >
                                <HighlightOffIcon />
                              </IconButton>
                            </Box>
                          </DialogContent>
                        </Dialog>
                      </Box>
                    }
                  </Box>
                  :
                  <Box
                    sx={{
                      width       : "100%",
                      border      : "1px solid #BEBEBE",
                      height      : "18vh",
                      borderRadius: "5px",
                      overflowY   : "auto",
                      display     : "flex",
                      alignItems  : "center"
                    }}
                  >
                    <Box
                      sx={{
                        margin  : "5px 8px",
                        wordWrap: "break-word"
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize  : "1.9vh",
                          color     : "#707070",
                          lineHeight: "1.6em",
                          whiteSpace: "pre-line"
                        }}
                      >
                        {stratergy.value}
                      </Typography>
                    </Box>
                  </Box>
                }
              </Box>
            );
          
          })}
        </Box>
      </Box>
    </Box>
  );

}
