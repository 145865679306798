import React from "react";
import { useSelector } from "react-redux";
// import { useLocation } from "react-router-dom";
import Select, { createFilter } from "react-select";

export default function CustomMultiSelect (props) {

  const { venture } = useSelector(state => ({ ...state }));

  // const location = useLocation();

  return (
    <>
      <Select
        placeholder={ props.placeholder }
        options={ props.options }
        // formatOptionLabel={formatOptionLabel}
        isDisabled={ venture.userRole === "Commenter" }
        components={ props.components }
        closeMenuOnSelect={ props.closeMenuOnSelect }
        hideSelectedOptions={ props.hideSelectedOptions }
        isClearable={ props.isClearable }
        filterOption={ createFilter({
          matchFrom: "start"
        }) }
        styles={ {
          control: styles => ({
            ...styles,
            border         : "2px solid #CCCCCC",
            backgroundColor: "#ffffff",
            borderRadius   : 5
          }),
          valueContainer: styles => ({
            ...styles,
            maxHeight: 50,
            overflowY: "auto"
          })
        } }
        isMulti
        maxMenuHeight={ props.maxMenuHeight }
        value={ props.value }
        onChange={ props.handleChange }
      />
    </>
  );

}
